// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const TimetStyles = StyleSheet.create({
  time: {
    width: 411,
    padding: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: `rgba(100, 100, 100, 0.1)`,
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
  },
  timeLabel: {
    fontWeight: 'bold',
    lineHeight: 30,
  },
  timeSeparatorLabel: {
    fontWeight: 'bold',
    lineHeight: 30,
    marginLeft: 8,
    marginRight: 8,
  },
  timeColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    textAlign: 'center',
    width: 278,
  },
  timeText: {
    borderWidth: 1,
    borderColor: `rgba(100, 100, 100, 0.1)`,
    borderRadius: 4,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 6,
    paddingBottom: 6,
    width: 52,
  },
});
