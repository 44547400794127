import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';
import { ErrorStyles } from './errors.style';

export function ErrorsComponent(properties: { prefix: string; errors: string[] }) {
  if (properties.errors.length === 0) return null;

  return (
    <View style={ErrorStyles.container}>
      {properties.errors.map((error, index) => {
        return (
          <View
            key={index}
            style={[
              ErrorStyles.errorContainer,
              index === 0 ? ErrorStyles.cornerTopError : ErrorStyles.middleTopError,
              index === properties.errors.length - 1
                ? ErrorStyles.cornerBottomError
                : ErrorStyles.middleBottomError,
            ]}
          >
            <View style={ErrorStyles.circle} />
            <TextComponent
              lines={2}
              translate
              bold
              text={`${properties.prefix}.${error}`}
              style={ErrorStyles.errorText}
            />
          </View>
        );
      })}
    </View>
  );
}
