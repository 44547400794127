import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { LanguagesRoutes } from '../../languages.routes';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';

export function DisableLanguageScreen(properties: {
  navigation: NavigationProp<LanguagesRoutes>;
  route: RouteProp<LanguagesRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.languages.disable">
      <FormComponent
        prefix="access.languages.disable.form"
        padding
        repository={{
          send: () => {
            return new LanguageAccessRepository()
              .disable({
                languageId: properties.route.params.languageId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
