// Types

import { ComparisonEnum } from '@validator/types/database.type';

/**
 * Check if the comparison is valid for the type of data
 * number can be compared with equals, lt, gt, lte, gte
 * string can be equal or containing
 * date can be equal lower or greater
 */
export function isComparisonValid(
  fieldType: string,
  operator: unknown
): operator is ComparisonEnum {
  return (
    (fieldType === 'string' &&
      (operator === ComparisonEnum.EQUALS || operator === ComparisonEnum.CONTAINS)) ||
    (fieldType === 'number' &&
      (operator === ComparisonEnum.EQUALS ||
        operator === ComparisonEnum.LT ||
        operator === ComparisonEnum.GT ||
        operator === ComparisonEnum.LTE ||
        operator === ComparisonEnum.GTE)) ||
    (fieldType === 'boolean' && operator === ComparisonEnum.EQUALS) ||
    (fieldType === 'date' &&
      (operator === ComparisonEnum.EQUALS ||
        operator === ComparisonEnum.LT ||
        operator === ComparisonEnum.GT ||
        operator === ComparisonEnum.LTE ||
        operator === ComparisonEnum.GTE))
  );
}
