import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { TouchableOpacity } from 'react-native';
import { ButtonStyles } from './button.styles';
import { ButtonProperties } from './button.properties';

/**
 * ButtonComponent renders a button with accompanying text and icon.
 * The component allows the user to trigger an action.
 *
 * @param {ButtonProperties} properties - The properties object containing the text, icon, and onPress function of the button.
 *
 * @returns {JSX.Element} The rendered JSX element containing the button, text, and icon.
 *
 * @example
 * <ButtonComponent prefix="Continue" icon="arrow-right" onPress={() => console.log('Button pressed')} />
 *
 */

export function ButtonComponent(properties: ButtonProperties) {
  return (
    <TouchableOpacity onPress={properties.onPress} style={ButtonStyles.button}>
      <TextComponent translate text={`${properties.prefix}`} bold style={ButtonStyles.buttonText} />
      <IconComponent name={properties.icon || 'arrow-right'} iconStyle={ButtonStyles.buttonIcon} />
    </TouchableOpacity>
  );
}
