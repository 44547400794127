import { useNavigation } from '@react-navigation/native';
import { HeaderStyles } from './header.styles';
import { TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { HeaderProperties } from './header.properties';
import { useTranslation } from 'react-i18next';

export function HeaderComponent(properties: HeaderProperties) {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <View style={HeaderStyles.headerContainer}>
      <IconComponent
        name={properties.icon}
        containerstyle={HeaderStyles.headerIconContainer}
        iconStyle={HeaderStyles.headerIconStyle}
      />
      <View style={HeaderStyles.haeaderTextsContainer}>
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          bold
          translate
          text={`${properties.prefix}.title`}
          style={HeaderStyles.title}
        />
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          text={`${properties.prefix}.subtitle`}
          style={HeaderStyles.subtitle}
        />
      </View>
      <TouchableOpacity
        onPress={() => {
          if (
            !properties.confirmationMessage ||
            confirm(t(`${properties.prefix}.confirmationMessage`) || '')
          )
            navigation.goBack();
        }}
        style={HeaderStyles.timesContainer}
      >
        <IconComponent name={'times'} iconStyle={HeaderStyles.timeIcon} />
      </TouchableOpacity>
    </View>
  );
}
