import { PatientsRoutes } from '../../patient.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { useState } from 'react';
import { ActionComponent } from '@components/action/action.component';
import { SelectClinicStep } from '@steps/clinic/selectClinic.step';
import { SelectLeadStep } from '@steps/lead/selectLead.step';

export function CreatePatientScreen(properties: {
  route: RouteProp<PatientsRoutes, 'patientCreate'>;
  navigation: NavigationProp<PatientsRoutes>;
}) {
  /**
   * Clinic Selected
   * @description Para simplificar los formularios almacenamos el estado de la clínica
   * para facilitar la carga de los selectores dependientes de la clínica.
   */
  const [clinicId, setClinicId] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();

  /**
   * Lead Selected
   * @description Para simplificar los formularios almacenamos el estado del lead
   * para facilitar la carga de los selectores dependientes del lead.
   */
  const [leadId, setLeadId] = useState<string>();
  const [leadName, setLeadName] = useState<string>();

  return (
    <ModalComponent icon="user-plus" prefix="crm.patients.create" flex={false} multiWorkspace>
      {!clinicId ? (
        <SelectClinicStep
          clinicId={clinicId}
          onSuccess={({ clinicId, clinicName }) => {
            setClinicId(clinicId);
            setClinicName(clinicName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setClinicId(undefined);
            setLeadId(undefined);
          }}
          prefix="crm.leads.update.actions.create.steps.clinic.form"
          bubble={clinicName}
        />
      )}
      {!leadId && clinicId ? (
        <SelectLeadStep
          clinicId={clinicId}
          onSuccess={({ leadId, leadName }) => {
            setLeadId(leadId);
            setLeadName(leadName);
          }}
        />
      ) : clinicId ? (
        <ActionComponent
          onPress={() => setLeadId(undefined)}
          prefix="crm.leads.update.actions.create.steps.lead.form"
          bubble={leadName}
        />
      ) : null}
      {clinicId && leadId && (
        <FormComponent<PatientCrmType>
          prefix="crm.patients.create.form"
          padding
          multiworkspace
          repository={{
            send: (settings) => {
              return new PatientCrmRepository()
                .create({
                  item: {
                    ...settings.item,
                    leadId,
                    clinicId,
                    warrantyAgreement: settings.item.warrantyAgreement ?? false,
                    consentCheck: settings.item.consentCheck ?? false,
                  },
                })
                .then(() => {
                  properties.navigation.navigate('patientList');
                });
            },
          }}
          fields={[
            {
              type: 'text',
              name: 'description',
              required: true,
              validations: ['description'],
              description: true,
              lines: 6,
              multiWorkspace: true,
            },
            {
              type: 'checkbox',
              name: 'warrantyAgreement',
              required: true,
              description: true,
              multiWorkspace: true,
            },
            {
              type: 'checkbox',
              name: 'consentCheck',
              required: true,
              description: true,
              multiWorkspace: true,
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
