import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';

export class ProgramTechniqueCrmRepository extends AuthorizedRepository {
  async list(properties: { search?: string; programId: string }): Promise<{
    items: TechniqueCrmType[];
    total: number;
  }> {
    return this.fetch('programTechnique/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          created: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { programId: string; techniqueId: string }): Promise<void> {
    return this.fetch(`programTechnique/create`, {
      method: 'POST',
      body: properties,
    });
  }

  async delete(properties: { programId: string; techniqueId: string }): Promise<void> {
    return this.fetch(`programTechnique/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
