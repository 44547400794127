import { ScrollView, Text, View, useWindowDimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TwoYearColumnLeadsComparativeStyles } from './twoYearColumnLeadsComparative.styles';
import { ItemByDepartmentYearsComparativeType } from '../newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ComparativeYearsLeadCountRow } from '../newLeadsByDepartmentYearsComparative/components/comparativeYearsLeadCountRow';
import { TextComponent } from '@components/text/text.component';
import { BoardNoDataComponent } from '../../../../components/noData/boardNoData.component';

export function TwoYearColumnLeadsComparative(properties: {
  firstYear: number;
  secondYear: number;
  data: ItemByDepartmentYearsComparativeType[];
  interventionType?: boolean;
}) {
  const { t } = useTranslation();

  const { phone } = useDeviceHook();

  const departments = Array.from(new Set(properties.data.map((item) => item.department_name)));
  const formattedData = departments.map((department) => {
    const departmentData = properties.data
      .filter((item) => item.department_name === department)
      .sort((a, b) => {
        if (a.month === b.month) {
          return a.year - b.year;
        }
        return a.month - b.month;
      });

    const totalFirstYear = departmentData
      .filter((item) => item.year === properties.firstYear)
      .reduce((acc, item) => acc + item.total, 0);

    const totalSecondYear = departmentData
      .filter((item) => item.year === properties.secondYear)
      .reduce((acc, item) => acc + item.total, 0);

    return {
      department_name: department,
      total_leads: [totalFirstYear, totalSecondYear],
    };
  });

  const LeadCountTableColumn = (settings: {
    header: {
      month?: string;
      subheaders: number[];
    };
  }) => {
    return (
      <View style={[TwoYearColumnLeadsComparativeStyles.headerColumnContainer]}>
        {settings.header.month && (
          <Text style={TwoYearColumnLeadsComparativeStyles.headerText}>
            {t(`board.months.${settings.header.month}`)}
          </Text>
        )}

        <View
          style={{
            flexDirection: 'row',
            gap: 15,
            justifyContent: 'center',
          }}
        >
          {settings.header.subheaders.map((item) => (
            <Text key={item} style={TwoYearColumnLeadsComparativeStyles.headerText}>
              {item}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  if (properties.data.length === 0) {
    return <BoardNoDataComponent />;
  }

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column' }}>
        <View style={{ flex: 1 }}>
          <View style={TwoYearColumnLeadsComparativeStyles.headerContainer}>
            {!phone && <View style={TwoYearColumnLeadsComparativeStyles.headerSpaceHeader} />}

            {[{ month: undefined, subheaders: [properties.firstYear, properties.secondYear] }].map(
              (item) => (
                <LeadCountTableColumn key={item.month} header={item} />
              )
            )}
          </View>

          {formattedData.map((item, index) => (
            <ComparativeYearsLeadCountRow
              lastYear={properties.secondYear}
              last={index === formattedData.length - 1}
              firstAttemptRow={
                properties.interventionType ? index === formattedData.length - 3 : undefined
              }
              isLossRow={
                properties.interventionType ? index === formattedData.length - 2 : undefined
              }
              key={item.department_name}
              item={item}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}
