import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ItemByDepartmentYearsComparativeType } from '../../types/itemByDepartmentYearsComparative.type';

export class LeadDetailBoardOvobankRepository extends AuthorizedRepository {
  //ClinicId es opcional porque esta funcion se puede usar para obtener los datos
  //de todas las clinicas o solo el de una en especifico
  newLeadCountYearsComparative = async (properties: {
    clinicId?: string;
    firstYear: number;
    secondYear: number;
    funnelId?: string;
  }): Promise<ItemByDepartmentYearsComparativeType[]> => {
    return this.fetch('board/leadDetail/totalLeadsComparingYearsOvobank', {
      method: 'GET',
      params: properties,
    });
  };

  leadsEligibleByYear = async (properties: {
    year: number;
    clinicId: string;
    funnelId?: string;
  }): Promise<any> => {
    return this.fetch('board/leadDetail/leadsEligibleByYearOvobank', {
      method: 'GET',
      params: properties,
    });
  };

  leadsEligibleByYarAndMonth = async (properties: {
    year: number;
    clinicId: string;
    funnelId?: string;
    month: number;
  }): Promise<any> => {
    return this.fetch('board/leadDetail/leadsEligibleByYearAndMonthOvobank', {
      method: 'GET',
      params: properties,
    });
  };
}
