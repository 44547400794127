import { StyleSheet } from 'react-native';

export const LeadDisabledStyles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(252, 246, 237)',
    borderWidth: 1,
    borderColor: 'rgb(255, 165, 0)',
    borderRadius: 4,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  iconWrapper: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    backgroundColor: 'rgb(250, 229, 203)',
  },
  icon: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 40,
    color: 'rgb(255, 165, 0)',
  },
  textContainer: {
    flex: 1,
  },
  title: {
    color: 'rgb(96, 96, 96)',
    fontSize: 12,
    fontWeight: 'bold',
  },
  description: {
    color: 'rgb(159, 158, 156)',
    fontSize: 10,
    fontWeight: '500',
  },
  dateBadge: {
    color: '#FFA500',
    fontSize: 11,
    textAlign: 'center',
    lineHeight: 20,
    backgroundColor: '#FFEAC7',
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 100,
    marginRight: 8,
  },
});
