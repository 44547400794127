// Modules
import { StyleSheet } from 'react-native';

/**
 * Action Styles
 * @constant {StyleSheet} ActionStyles
 */
export const ActionSelectableStyles = StyleSheet.create({
  action: {
    padding: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 20,
    maxHeight: 20,
  },
  actionText: {
    color: '#666',
    fontSize: 13,
  },
  actionIcon: {
    color: 'rgba(0,0,0,0.2)',
    fontSize: 20,
  },
  bubblesContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  bubble: {
    borderRadius: 15,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
  },
  bubbleText: {
    color: '#eee',
    fontSize: 13,
  },
});
