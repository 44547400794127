import { useUserState } from '@states/user/user.state';
import { useEffect, useState } from 'react';
import { ValidateSessionRepository } from '../../screens/unauthorized/repositories/session/validate.session.repositor';
import { UserRepository } from '@human/access/repositories/session/user.repository';
import { useTranslation } from 'react-i18next';
import { DefaultLanguage } from '@constants/defaultLanguage.constant';

export function useValidateHook() {
  const { user, update, logout } = useUserState();
  const { i18n } = useTranslation();

  const [isAuthenticated, setIsAuthentiated] = useState<boolean>(false);
  const retrieveUser = async () => {
    new UserRepository().retrieve().then((res) => {
      const selectedIdiom =
        res.idioms.find((idiom) => idiom.toLowerCase() === user.selectedIdiom)?.toLowerCase() ??
        res.idioms[0]?.toLowerCase() ??
        DefaultLanguage;

      update({
        user: {
          selfie: res.selfie,
          name: res.name,
          surname: res.surname,
          email: res.email,
          idioms: res.idioms,
          selectedIdiom,
        },
        permissions: res.permissions,
      });

      i18n.changeLanguage(selectedIdiom);
    });
  };
  useEffect(() => {
    if (!user.accessToken) {
      setIsAuthentiated(false);
      return;
    }

    new ValidateSessionRepository()
      .validate({
        accessToken: user.accessToken,
      })
      .then((response) => {
        setIsAuthentiated(response);

        retrieveUser();
      })
      .catch(() => {
        //CLEAR SESSION
        setIsAuthentiated(false);
        logout();
      });
  }, [user.accessToken]);

  return isAuthenticated;
}
