import { TextInput, TouchableOpacity, View } from 'react-native';
import DateTimePicker, { DateType } from 'react-native-ui-datepicker';
import dayjs from 'dayjs';

import { DateInputImplementation } from './date.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useEffect, useState } from 'react';
import { DateInputValidations } from './date.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { DateInputStyles } from './date.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { ButtonComponent } from '@components/button/button.component';
import { DateConstants } from './date.constants';
import { TimeComponent } from './components/time.component';
import { useTranslation } from 'react-i18next';

export function DateInput<ITEM>(properties: DateInputImplementation<ITEM>) {
  const [focus, setFocus] = useState(false);
  const [hour, setHour] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [date, setDate] = useState<DateType | null>();
  const onChange = (date: string | undefined) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: date,
    };
    properties.onChange(newValue);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (
      properties.values[properties.name] &&
      !isNaN(Date.parse(properties.values[properties.name] as string))
    ) {
      setDate(properties.values[properties.name] as string);
      setHour(new Date(properties.values[properties.name] as string).getHours());
      setMinutes(new Date(properties.values[properties.name] as string).getMinutes());
    }
  }, [properties.values]);

  const errors =
    properties.validations &&
    DateInputValidations({
      validations: properties.validations,
      value: date as string,
      required: properties.required,
    });

  const renderNullableIcon = () => {
    if (!properties.nullable) {
      return null;
    }

    if (!date) {
      return null;
    }

    return (
      <TouchableOpacity
        onPress={() => {
          setDate(null);
          onChange(undefined);
        }}
        style={DateInputStyles.cancelStatusContainer}
      >
        <IconComponent name="times" iconStyle={[DateInputStyles.statusStyle]} />
      </TouchableOpacity>
    );
  };

  const renderCaretIcon = () => {
    if (properties.nullable && date) {
      return null;
    }

    return (
      <IconComponent
        name={'calendar'}
        containerstyle={DateInputStyles.iconStatusContainer}
        iconStyle={[DateInputStyles.statusStyle]}
      />
    );
  };

  return (
    <>
      <TouchableOpacity
        disabled={properties.readonly}
        onPress={() => {
          if (properties.readonly) return;
          setFocus(!focus);
        }}
        style={[DateInputStyles.statusContainer]}
      >
        {renderCaretIcon()}
        <TextInput
          value={date ? dayjs(date).format(properties.format) : ''}
          placeholder={
            date ? dayjs(date).format(properties.format) : t('calendar.placeholder') || ''
          }
          editable={false}
          style={[
            DateInputStyles.input,
            properties.readonly ? DateInputStyles.inputReadonly : null,

            focus ? DateInputStyles.inputFocus : DateInputStyles.inputUnfocus,
          ]}
        />

        {renderNullableIcon()}
      </TouchableOpacity>
      {focus && !properties.readonly && (
        <View style={DateInputStyles.calendar}>
          <DateTimePicker
            mode="single"
            date={date}
            firstDayOfWeek={1}
            timePicker={false}
            locale={DateConstants.locale}
            headerButtonColor="rgb(237, 53, 145)"
            selectedItemColor="rgb(237, 53, 145)"
            onChange={(params) => {
              if (properties.readonly) return;
              setDate(params.date);
            }}
          />
          <TimeComponent hour={hour} minutes={minutes} setHour={setHour} setMinutes={setMinutes} />
          <View style={DateInputStyles.button}>
            <ButtonComponent
              onPress={() => {
                if (date) {
                  setFocus(false);
                  setDate(dayjs(date).hour(hour).minute(minutes));
                  onChange(dayjs(dayjs(date).hour(hour).minute(minutes)).format(properties.format));
                }
              }}
              prefix={t('calendar.save')}
            />
          </View>
        </View>
      )}

      {errors && <ErrorsComponent prefix={'form.date.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
