import { NavigationComponent } from '@components/navigation/navigation.component';
import { BulletinsRoutes } from './bulletins.routes';
import { CreateBulletinsScreen } from './screens/create/create.bulletin.screen';
import { ListBulletinsScreen } from './screens/list/list.bulletin.screen';
import { DeleteBulletinScreen } from './screens/delete/delete.bulletin.screen';
import { UpdateBulletinScreen } from './screens/update/update.bulletin.screen';
import { PasswordDeleteBulletinScreen } from './screens/delete/password.delete.bulletin.screen';
import { EnableBulletinScreen } from './screens/enable/enable.bulletin.screen';
import { DisableBulletinScreen } from './screens/disable/disable.bulletin.screen';

const Navigation = NavigationComponent<BulletinsRoutes>();

export function BulletinsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListBulletinsScreen} />
      <Navigation.Screen name="create" component={CreateBulletinsScreen} />
      <Navigation.Screen name="update" component={UpdateBulletinScreen} />
      <Navigation.Screen name="delete" component={DeleteBulletinScreen} />
      <Navigation.Screen name="disable" component={DisableBulletinScreen} />
      <Navigation.Screen name="enable" component={EnableBulletinScreen} />
      <Navigation.Screen name="password" component={PasswordDeleteBulletinScreen} />
    </Navigation.Navigator>
  );
}
