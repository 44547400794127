import { ContractAccessType } from '@human/access/types/contract.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ContractAccessRepository extends AuthorizedRepository {
  async list(properties: { search?: string }): Promise<{
    items: ContractAccessType[];
    total: number;
  }> {
    return this.fetch('contract/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((contract: any) => ({
        id: contract.id,
        clinicName: contract.clinic.name,
        clinic: {
          clinicId: contract.clinic.id,
          name: contract.clinic.name,
        },
      })),
      total: data.total,
    }));
  }

  async listByUserId(properties: { userId: string; search?: string }): Promise<{
    items: ContractAccessType[];
    total: number;
  }> {
    return this.fetch('contract/findByUserId', {
      params: {
        userId: properties.userId,
        search: properties.search || '',
      },
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((contract: any) => ({
        id: contract.id,
        status: contract.status,
        clinicName: contract.clinic.name,
        clinic: {
          clinicId: contract.clinic.id,
          name: contract.clinic.name,
        },
      })),
      total: data.total,
    }));
  }

  async findByClinic(properties: { userId: string; clinicId: string }): Promise<{ id: string }> {
    return this.fetch('contract/findByClinic', {
      method: 'GET',
      params: {
        userId: properties.userId,
        clinicId: properties.clinicId,
      },
    }).then((contract) => ({
      id: contract.id,
    }));
  }

  async create(
    properties: Partial<{
      clinicId: string;
      userId: string;
    }>
  ): Promise<Partial<ContractAccessType>> {
    return this.fetch('contract/create', {
      method: 'POST',
      body: {
        clinicId: properties.clinicId,
        userId: properties.userId,
      },
    }).then((contract) => ({
      id: contract.id,
    }));
  }

  async createAll(properties: { userId: string }): Promise<boolean> {
    return this.fetch('contract/createAll', {
      method: 'POST',
      body: properties,
    });
  }

  async disable(properties: { contractId: string; userId: string }): Promise<boolean> {
    return this.fetch('contract/disable', {
      method: 'PUT',
      params: {
        contractId: properties.contractId,
        userId: properties.userId,
      },
    }).then(() => true);
  }

  async enable(properties: { contractId: string; userId: string }): Promise<boolean> {
    return this.fetch('contract/enable', {
      method: 'PUT',
      params: {
        contractId: properties.contractId,
        userId: properties.userId,
      },
    }).then(() => true);
  }

  async pick(properties: { contractId: string; userId: string }) {
    return this.fetch('contract/pick', {
      method: 'GET',
      params: {
        contractId: properties.contractId,
        userId: properties.userId,
      },
    }).then((contract) => ({
      id: contract.id,
      clinic: {
        clinicId: contract.clinic.id,
        name: contract.clinic.name,
      },
    }));
  }
}
