import { View, Text } from 'react-native';
import { EligibleChartProperties } from './eligibleChart.properties';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export function EligibleChartComponent(properties: EligibleChartProperties) {
  const { lead_eligible_count, lead_no_eligible_count, lead_medic_attended_count } =
    properties.data;

  const { t } = useTranslation();

  const data = {
    labels: ['Apto', 'No Apto'],
    datasets: [
      {
        data: [lead_eligible_count, lead_no_eligible_count],
        borderColor: ['#283038', '#cfd6dd'],
        backgroundColor: ['#283038', '#cfd6dd'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        gap: 20,
      }}
    >
      <Text
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: [{ translateX: '-50%' }, { translateY: '-50%' }], // Añadimos transform
          textAlign: 'center',
          fontSize: 18,
          color: '#999999',
        }}
      >
        {t('leadEvents.attended')}
        <Text
          style={{
            fontSize: 18,
            fontWeight: '700',
          }}
        >{`\n${lead_medic_attended_count}`}</Text>
      </Text>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: '65%',
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                color: '#283038',
                padding: 10,
                font: {
                  size: 12,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        }}
      />
    </View>
  );
}
