import { View } from 'react-native';
import { LeadDisabledProperties } from './lead.disabled.properties';
import { TextComponent } from '@components/text/text.component';
import { LeadDisabledStyles } from './lead.disabled.styles';
import { IconComponent } from '@components/icon/icon.component';
import { AgoComponent } from '@components/ago/ago.component';

export function LeadDisabledComponent(properties: LeadDisabledProperties) {
  return (
    <View style={LeadDisabledStyles.container}>
      <View style={LeadDisabledStyles.contentContainer}>
        <IconComponent
          name="exclamation-triangle"
          containerstyle={LeadDisabledStyles.iconWrapper}
          iconStyle={LeadDisabledStyles.icon}
        />
        <View style={LeadDisabledStyles.textContainer}>
          <TextComponent text="Lead inactivo" style={LeadDisabledStyles.title} />
          <TextComponent
            text="El lead se encuentra en estado inactivo"
            style={LeadDisabledStyles.description}
          />
        </View>
        <AgoComponent date={properties.statusUpdated} style={LeadDisabledStyles.dateBadge} />
      </View>
    </View>
  );
}
