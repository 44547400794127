import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';

export function DeleteScopeScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'deleteScope'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.scopes.delete">
      <FormComponent
        prefix="crm.scopes.delete.form"
        padding
        repository={{
          send: () => {
            return new ActivityCrmRepository()
              .deleteScope({
                activityId: properties.route.params.activityId,
                departmentId: properties.route.params.departmentId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
