import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ResultOvoBankATPEventCrmRepository extends AuthorizedRepository {
  async pick(properties: { resultId: string; activityId: string }): Promise<{
    converted: boolean;
    appointed: boolean;
    attended: boolean;
    comercialAttended: boolean;
    medicalAppointed: boolean;
  }> {
    return this.fetch('result/pickOvobankATPEvent', {
      method: 'GET',
      params: properties,
    });
  }

  async update(properties: {
    id: string;
    item: Partial<{
      activityId: string;
      converted: boolean;
      appointed: boolean;
      attended: boolean;
      comercialAttended: boolean;
      medicalAppointed: boolean;
    }>;
  }) {
    return this.fetch(`result/updateOvobankATPEvent?id=${properties.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }
}
