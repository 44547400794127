import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';
import { ServicesRoutes } from '../../service.routes';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateServiceScreen(properties: {
  navigation: NavigationProp<ServicesRoutes>;
  route: RouteProp<ServicesRoutes, 'serviceUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="user" prefix="crm.services.update">
      <FormComponent<any>
        padding
        readonly={!hasPermissionUtilty({ name: 'crm.services.editor' })}
        prefix="crm.services.update.form"
        repository={{
          send: (data) => {
            return new ServiceCrmRepository()
              .update({
                id: data.item.id,
                item: data.item,
              })
              .then(() => {
                showSuccess();
              });
          },
          get: () => {
            return new ServiceCrmRepository().pick({
              serviceId: properties.route.params.serviceId,
            });
          },
        }}
        fields={[
          {
            name: 'name',
            description: true,
            type: 'text',
            validations: ['name'],
            required: true,
          },
          {
            name: 'price',
            description: true,
            type: 'float',
            validations: ['price'],
          },
          {
            name: 'nextActivityId',
            type: 'select',
            description: true,
            required: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return activity.name;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository()
                .list({
                  order: 'name',
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((activities) =>
                  activities.items.map((activity) => ({
                    value: activity.id,
                    label: activity.name,
                  }))
                );
            },
          },
          {
            name: 'nextActivityDelay',
            description: true,
            type: 'number',
            required: false,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
