import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { CrmSections } from '@human/crm/crm.sections';
import { ResultsRoutes } from '../../result.routes';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { IconType } from '@components/icon/icon.types';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListResultsScreen(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultList'>;
}) {
  const { phone } = useDeviceHook();

  const columns: ColumnProperties<ResultCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.results.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('resultUpdate', {
          resultId: row.id,
          activityId: row.activityId,
          level: 1,
        });
      },
    },
    {
      type: 'iconText',
      name: 'name',
      color: (row) => row.color,
      text: (row) => row.name,
      icon: (row) => row.icon as IconType,
    },
    {
      name: 'activityName',
      type: 'text',
      width: 500,
      onRender: (row) => row.activityName,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.results.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'resultDisable' : 'resultEnable', {
          resultId: row.id,
          activityId: row.activityId,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.results.icon} prefix={'crm.results.list'}>
      <TableComponent<ResultCrmType, []>
        prefix={'crm.results.list'}
        suffix={'list'}
        scrollable={phone ?? undefined}
        repository={(settings) => {
          return new ResultCrmRepository().list({
            order: settings.order || 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
