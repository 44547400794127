import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';
import { ContractAccessType } from '@human/access/types/contract.access.type';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';

export function CreateContractScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'contractCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="access.users.update.contracts.create"
    >
      <FormComponent<ContractAccessType>
        padding
        prefix="access.users.update.contracts.create.form"
        repository={{
          send: (settings) => {
            return new ContractAccessRepository()
              .create({
                clinicId: settings.item.clinicId,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'clinicId',
            required: true,
            pick: (properties: { id: string }) => {
              return new ClinicAccessRepository().pick({ id: properties.id }).then((clinic) => {
                return `${clinic.name}`;
              });
            },
            repository: ({ search }) => {
              return new ClinicAccessRepository()
                .list({ search, limit: 100, active: true })
                .then((contracts) =>
                  contracts.items.map((contract: any) => ({
                    label: contract.name,
                    value: contract.id,
                  }))
                );
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
