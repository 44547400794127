import { View } from 'react-native';
import { Line } from 'react-chartjs-2';
import { SaleFunnelPerDepartmentChartProperties } from './saleFunnelPerDeparmentChart.properties';
import { useTranslation } from 'react-i18next';

export function SaleFunnelPerDepartmentChart(properties: SaleFunnelPerDepartmentChartProperties) {
  const { t } = useTranslation();
  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  const data = {
    labels: ['Total', ...Object.values(leadEvent)],
    datasets: properties.data.map((department, i) => {
      return {
        label: department.department_name,
        data: [
          department.total_leads,
          department.lead_contacted_count,
          department.lead_appointed_count,
          department.lead_informed_count,
          department.lead_medical_appointed_count,
          department.lead_medic_attended_count,
          department.lead_comercial_attended_count,
          department.lead_medical_evaluated_count,
          department.lead_converted_count,
        ],
        fill: false,
        backgroundColor: department.department_name === 'Total' ? '#283038)' : properties.colors[i],
      };
    }),
  };

  return (
    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 4 }}>
      <Line
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              borderWidth: 4, // Grosor de la línea
            },
            point: {
              radius: 6, // Tamaño de los puntos
              hoverRadius: 8, // Tamaño de los puntos al hacer hover
            },
          },
        }}
      />
    </View>
  );
}
