import { BoardRoutes } from '../../board.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvoclinicFunnelsScreen } from './ovoclinicFunnels/ovoclinicFunnels.screen';
import { OvobankFunnelsScreen } from './ovobankFunnels/ovobankFunnels.screen';

export type leadperdepartmentandmonth = {
  department_id: string;
  department_name: string;
  language_id: string;
  lead_converted_count: number;
  lead_medic_attended_count: number;
  lead_comercial_attended_count: number;
  lead_appointed_count: number;
  lead_informed_count: number;
  lead_contacted_count: number;
  lead_medical_appointed_count: number;
  lead_medical_evaluated_count: number;
  total_leads: number;
};

export type leadperdepartmentandmonthandevent = {
  department_id: string;
  department_name: string;
  lead_count: number;
};

export default function SaleFunnelScreenWeb(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'saleFunnel'>;
}) {
  return Workspace.select({
    ovoclinic: <OvoclinicFunnelsScreen {...properties} />,
    ovobank: <OvobankFunnelsScreen {...properties} />,
  });
}
