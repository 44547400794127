import { NavigationComponent } from '@components/navigation/navigation.component';
import { TimetableRoutes } from './timetables.routes';
import { ListTimetablesScreen } from './screens/list/list.timetables.screen';
import { CreateTimetableScreen } from './screens/create/create.timetable.screen';
import { DeleteTimetableScreen } from './screens/delete/delete.timetable.screen';
import { UpdateTimetableScreen } from './screens/update/update.timetable.screen';
import { CreateTimetableDayScreen } from './screens/timetableDay/create/create.timetableDay.screen';
import { DeleteTimetableDayScreen } from './screens/timetableDay/delete/delete.timetableDay.screen';
import { UpdateTimetableDayScreen } from './screens/timetableDay/update/update.timetableDay.screen';
import { DisableTimetableScreen } from './screens/disable/disable.timetable.screen';
import { EnableTimetableScreen } from './screens/enable/enable.timetable.screen';

const Stack = NavigationComponent<TimetableRoutes>();

export function TimetablesSection() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={ListTimetablesScreen} />
      <Stack.Screen name="create" component={CreateTimetableScreen} />
      <Stack.Screen name="update" component={UpdateTimetableScreen} />
      <Stack.Screen name="delete" component={DeleteTimetableScreen} />
      <Stack.Screen name="disable" component={DisableTimetableScreen} />
      <Stack.Screen name="enable" component={EnableTimetableScreen} />

      <Stack.Screen name="timetableDayCreate" component={CreateTimetableDayScreen} />
      <Stack.Screen name="timetableDayUpdate" component={UpdateTimetableDayScreen} />
      <Stack.Screen name="timetableDayDelete" component={DeleteTimetableDayScreen} />
    </Stack.Navigator>
  );
}
