import { StyleSheet } from 'react-native';

export const ButtonColumnStyles = StyleSheet.create({
  container: {
    width: '100',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonWrapper: {
    backgroundColor: 'rgb(17, 27, 34)',
    borderRadius: 4,
  },
  buttonContainer: {
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  iconContainer: {
    height: 30,
    width: 30,
    backgroundColor: '#00000029',
    borderLeftColor: '#00000017',
    borderLeftWidth: 1,
  },
  icon: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 30,
    color: '#fff',
  },
  buttonLabel: {
    numberOfLines: 1,
    elipsisMode: 'tail',
    color: '#fff',
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 15,
    marginLeft: 15,
  },
});
