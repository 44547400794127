import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { PanelComponent } from '@components/panel/panel.component';
import { RowComponent } from '@components/row/row.component';
import { SpaceComponent } from '@components/space/space.component';
import { useEffect, useState } from 'react';
import { ActivityCrmType } from '@human/crm/types/activity.crm.type';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { TableComponent } from '@components/table/table.component';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { CoversComponent } from '../../../../../../../../../components/covers/covers.component';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { DashboardRoutes } from '../../dashboard.routes';
import { useDeviceHook } from '@hooks/device/device.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { getTimeDiff } from '@utils/timeDiff/getTimeDiff.utility';
import { useTranslation } from 'react-i18next';
import { IconType } from '@components/icon/icon.types';
import { ContactsComponent } from '@components/contacts/contacts.component';

export function DashboardScreen(properties: {
  route: RouteProp<DashboardRoutes, 'dashboard'>;
  navigation: NavigationProp<DashboardRoutes>;
}) {
  const [activities, setActivities] = useState<ActivityCrmType[]>([]);

  const { desktop, laptop } = useDeviceHook();

  const { t } = useTranslation();

  useEffect(() => {
    new ActivityCrmRepository()
      .list({
        order: 'position',
        direction: 'asc',
        page: 0,
        limit: 100,
      })
      .then((data) => {
        setActivities(data.items);
      });
  }, []);
  return (
    <SceneComponent prefix={''} icon={'ad'}>
      {(desktop || laptop) && <ContactsComponent {...properties} />}
      <RowComponent>
        <PanelComponent multiWorkspace prefix="crm.tasks.list" icon="tasks">
          <TableComponent<
            {
              id: string;
              status: boolean;
              contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
              subject: string;
              activityId: string;
              activityName: string;
              activityColor: string;
              activityIcon: string;
              contactSurname: string;
              leadId: string;
              patientId?: string;
              clinicId: string;
              clinicName: string;
              contactId: string;
              contactName: string;
              responsibleName: string;
              responsibleSurname: string;
              resultId: string;
              resultName: string;
              started: Date;
              finished: Date;
              created: Date;
              selfie: string;
            },
            []
          >
            prefix={'crm.tasks.list'}
            suffix={'list'}
            repository={(settings) =>
              new ActionCrmRepository().list({
                page: settings.page,
                limit: settings.limit,
                completed: false,
                search: settings.search,
              })
            }
            columns={[
              {
                type: 'icon',
                renderIcon: () =>
                  hasPermissionUtilty({ name: 'crm.tasks.editor' }) ? 'pencil-alt' : 'eye',
                onPress: (row) => {
                  properties.navigation.navigate('actionUpdate', {
                    activityId: row.activityId,
                    actionId: row.id,
                    clinicId: row.clinicId,
                    leadId: row.leadId,
                    patientId: row.patientId,
                    contactId: row.contactId,
                    level: 1,
                  });
                },
              },
              {
                type: 'iconText',
                name: 'activity',
                color: (row) => row.activityColor,
                text: (row) => row.activityName,
                icon: (row) => row.activityIcon as IconType,
              },
              {
                type: 'taskStatus',
                name: 'resultId',
                width: 150,
                background: () => 'white',
                color: (row) => (row.resultId ? 'rgba(186, 224, 144, 0.40)' : '#FCEEE2'),
                textColor: (row) => (row.resultId ? 'rgb(40, 167, 69)' : '#f0ad4e'),
                onRender: (row) =>
                  row.resultId
                    ? t('common.taskProgress.COMPLETED')
                    : t('common.taskProgress.PENDING'),
              },
              {
                type: 'text',
                name: 'resultName',
                width: 200,
                onRender: (row) => row.resultName ?? t('common.tasks.noResult'),
                color: (row) => (row.resultName ? 'black' : 'grey'),
              },
              {
                type: 'text',
                name: 'contact',
                onRender: (row) => `${row.contactName} ${row.contactSurname}`,
                width: 200,
              },
              {
                type: 'contactTime',
                name: 'contactTime',
                width: 150,
                onRender: (row) => row.contactTime,
              },
              {
                type: 'clinic',
                name: 'clinic',
                onRender: (row) => row.clinicName,
                width: 200,
              },
              {
                type: 'text',
                name: 'subject',
                onRender: (row) => row.subject ?? '',
                width: 250,
              },
              {
                type: 'user',
                name: 'user',
                onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
                onRenderSelfie: (row) => row.selfie,
                width: 200,
              },
              {
                type: 'date',
                name: 'started',
                renderDate: (row) => row.started,
              },
              {
                type: 'text',
                name: 'finished',
                width: 200,
                onRender: (row) => getTimeDiff({ started: row.started, finished: row.finished }),
              },
            ]}
          />
        </PanelComponent>
      </RowComponent>
      <SpaceComponent />
      <RowComponent>
        <PanelComponent prefix="crm.dashboard.leads" icon="user-ninja">
          <TableComponent<LeadCrmType, []>
            prefix={'crm.leads.list'}
            suffix={'list'}
            repository={(settings) =>
              new LeadCrmRepository().list({
                page: settings.page,
                limit: settings.limit,
                direction: settings.direction,
                order: 'created',
              })
            }
            columns={[
              {
                type: 'icon',
                icon: hasPermissionUtilty({ name: 'crm.leads.editor' }) ? 'pencil-alt' : 'eye',
                onPress: (row) => {
                  properties.navigation.navigate('leadUpdate', {
                    id: row.id,
                    clinicId: row.clinicId,
                    contactId: row.contactId,
                  });
                },
              },
              {
                type: 'text',
                name: 'name',
                onRender: (row) => row.contactName,
                width: 200,
              },
              {
                type: 'clinic',
                name: 'clinicName',
                onRender: (row) => row.clinicName,
                width: 200,
              },
              {
                type: 'text',
                name: 'campaignName',
                width: 200,
                onRender: (row) => row.campaignName,
              },
              {
                type: 'date',
                name: 'created',
                renderDate: (row) => row.created,
              },
            ]}
          />
        </PanelComponent>
      </RowComponent>
      <SpaceComponent />

      <PanelComponent prefix="crm.dashboard.campaigns" icon="bullhorn">
        <CoversComponent
          repository={() => {
            return new CampaignCrmRepository()
              .listImageCampaigns({
                page: 0,
                limit: 10,
              })
              .then((campaigns) => {
                return {
                  items: campaigns.items.map((campaign) => ({
                    image: campaign.image || '',
                    title: campaign.name,
                    subtitle: campaign.code,
                    count: campaign.leads || 0,
                    detail: campaign.funnelName || '',
                    onPress: () =>
                      properties.navigation.navigate('campaignUpdate', {
                        id: campaign.id,
                        languageId: campaign.languageId,
                      }),
                  })),
                  total: campaigns.total,
                };
              });
          }}
        />
      </PanelComponent>
    </SceneComponent>
  );
}
