import i18n from 'i18next';
import { EsLocale } from './locales/es.locale';
import { EnLocale } from './locales/en.locale';
import { FrLocale } from './locales/fr.locale';
import { ItLocale } from './locales/it.locale';
import { PtLocale } from './locales/pt.locale';

import { initReactI18next } from 'react-i18next';
import { DefaultLanguage } from '@constants/defaultLanguage.constant';

const resources = {
  es: {
    translation: EsLocale,
  },
  fr: {
    translation: FrLocale,
  },
  en: {
    translation: EnLocale,
  },
  it: {
    translation: ItLocale,
  },
  pt: {
    translation: PtLocale,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: DefaultLanguage,
  resources,
  debug: false,
});

export default i18n;
