import { NavigationProp, RouteProp } from '@react-navigation/native';
import { MaintenancesRoutes } from '../../maintenance.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { MaintenanceCrmRepository } from '@human/crm/repositories/maintenance/maintenance.repository';

export function EnableMaintenanceScreen(properties: {
  navigation: NavigationProp<MaintenancesRoutes>;
  route: RouteProp<MaintenancesRoutes, 'enableMaintenance'>;
}) {
  return (
    <ModalComponent icon="unlock" prefix="crm.maintenances.enable">
      <FormComponent
        prefix="crm.maintenances.enable.form"
        padding
        repository={{
          send: () => {
            return new MaintenanceCrmRepository()
              .enable({
                maintenanceId: properties.route.params.maintenanceId,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
