import { NavigationComponent } from '@components/navigation/navigation.component';
import { TreatmentsRoutes } from './treatment.routes';
import { CreateTreatmentScreen } from './screens/create/create.treatment.screen';
import { UpdateTreatmentScreen } from './screens/update/update.treatment.screen';
import { DisableTreatmentScreen } from './screens/disable/disable.treatment.screen';
import { EnableTreatmentScreen } from './screens/enable/enable.treatment.screen';
import { ListTreatmentsScreen } from './screens/list/list.treatment.screen';
import { CreateMaintenanceScreen } from '../maintenance/screens/create/create.maintenance.screen';
import { UpdateMaintenanceScreen } from '../maintenance/screens/update/update.maintenance.screen';
import { CreateInterventionScreen } from '../interventions/screens/create/create.intervention.screen';
import { CreateInterventionTechniqueScreen } from '../interventions/screens/update/parts/create.interventionTechnique.screen';
import { DeleteInterventionTechniqueScreen } from '../interventions/screens/update/parts/delete.interventionTechnique.screen';
import { UpdateInterventionScreen } from '../interventions/screens/update/update.intervention.screen';
import { CreateProcedureScreen } from '../procedure/screens/create/create.procedure.screen';
import { DeleteProceduresScreen } from '../procedure/screens/delete/delete.procedures.screen';
import { UpdateProcedureScreen } from '../procedure/screens/update/update.procedure.screen';
import { DisableInterventionScreen } from '../interventions/screens/disable/disable.intervention.screen';
import { EnableInterventionScreen } from '../interventions/screens/enable/enable.intervention.screen';

const Navigation = NavigationComponent<TreatmentsRoutes>();

export function TreatmentsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="listTreatment" component={ListTreatmentsScreen} />
      <Navigation.Screen name="createTreatment" component={CreateTreatmentScreen} />
      <Navigation.Screen name="updateTreatment" component={UpdateTreatmentScreen} />
      <Navigation.Screen name="disableTreatment" component={DisableTreatmentScreen} />
      <Navigation.Screen name="enableTreatment" component={EnableTreatmentScreen} />
      <Navigation.Screen name="createMaintenance" component={CreateMaintenanceScreen} />
      <Navigation.Screen name="updateMaintenance" component={UpdateMaintenanceScreen} />
      <Navigation.Screen name="createIntervention" component={CreateInterventionScreen} />
      <Navigation.Screen name="updateIntervention" component={UpdateInterventionScreen} />
      <Navigation.Screen name="enableIntervention" component={EnableInterventionScreen} />
      <Navigation.Screen name="disableIntervention" component={DisableInterventionScreen} />
      <Navigation.Screen
        name="createInterventionTechnique"
        component={CreateInterventionTechniqueScreen}
      />
      <Navigation.Screen
        name="deleteInterventionTechnique"
        component={DeleteInterventionTechniqueScreen}
      />
      <Navigation.Screen name="createProcedure" component={CreateProcedureScreen} />
      <Navigation.Screen name="deleteProcedure" component={DeleteProceduresScreen} />
      <Navigation.Screen name="updateProcedure" component={UpdateProcedureScreen} />
    </Navigation.Navigator>
  );
}
