import { isGeoJSONObject } from 'geojson-validation';
import { View, TextInput } from 'react-native';
import { JsonInputImplementation } from './json.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useEffect, useState } from 'react';
import { ErrorsComponent } from '../errors/errors.component';
import { JsonInputStyles } from './json.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function JsonInput<ITEM>(properties: JsonInputImplementation<ITEM>) {
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState<string[] | undefined>();
  const [value, setValue] = useState<string>();

  const onChange = (text: string) => {
    if (properties.readonly) return;
    setErrors(undefined);
    setValue(text);
    onKeyPress(text);
  };

  const onKeyPress = (text: string) => {
    const key = properties.name;
    try {
      if (text && isGeoJSONObject(JSON.parse(text))) {
        // @ts-ignore
        const newValue: Partial<ITEM> = {
          [key]: JSON.parse(text),
        };
        properties.onChange(newValue);
      }
    } catch (e) {
      setErrors(['geojson']);
    }
  };

  useEffect(() => {
    setValue(JSON.stringify(properties.value[properties.name]));
  }, [properties.value]);

  return (
    <>
      <View style={JsonInputStyles.container}>
        {properties.icon && (
          <IconComponent
            name={properties.icon}
            containerstyle={JsonInputStyles.iconContainer}
            iconStyle={JsonInputStyles.iconStyle}
          />
        )}
        <TextInput
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          focusable={!properties.readonly}
          editable={!properties.readonly}
          numberOfLines={10}
          multiline
          secureTextEntry={properties.secure}
          value={value}
          style={[
            JsonInputStyles.input,
            properties.icon ? JsonInputStyles.inputIcon : JsonInputStyles.inputNoIcon,
            !properties.readonly && focus
              ? JsonInputStyles.inputFocus
              : JsonInputStyles.inputUnfocus,
            properties.readonly ? JsonInputStyles.readonly : {},
          ]}
          onChangeText={onChange}
        />
      </View>
      {errors && <ErrorsComponent prefix={'form.json.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
