import { View, Text, Dimensions } from 'react-native';
import React from 'react';
import { ComparativeYearsLeadCountRows } from './comparativeYearsLeadCountRow.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

export function ComparativeYearsLeadCountRow(properties: {
  item: {
    department_name: string;
    total_leads: number[];
  };
  firstAttemptRow: boolean | undefined;
  isLossRow: boolean | undefined;
  lastYear: number;
  last: boolean;
}) {
  const { phone } = useDeviceHook();

  const lastYearIsActual = new Date().getFullYear() === properties.lastYear;
  return (
    <View
      style={[
        phone
          ? ComparativeYearsLeadCountRows.rowContainerMobile
          : ComparativeYearsLeadCountRows.rowContainer,
        properties.last && ComparativeYearsLeadCountRows.rowContainerLast,
        properties.isLossRow && ComparativeYearsLeadCountRows.rowContainerLoss,
        properties.firstAttemptRow && ComparativeYearsLeadCountRows.rowContainerFirstAttempt,
      ]}
    >
      <View
        style={
          phone
            ? ComparativeYearsLeadCountRows.subRowSpaceColumnContainerMobile
            : ComparativeYearsLeadCountRows.subRowSpaceColumnContainer
        }
      >
        <Text
          style={[
            ComparativeYearsLeadCountRows.subRowSpaceText,
            (properties.isLossRow || properties.firstAttemptRow || properties.last) && {
              fontWeight: '500',
            },
            properties.isLossRow && ComparativeYearsLeadCountRows.subRowLossText,
            phone && { width: 100 },
          ]}
        >
          {properties.item.department_name}
        </Text>
      </View>

      {properties.item.total_leads.map((total, index) => (
        <>
          <View style={phone ? { width: 50 } : ComparativeYearsLeadCountRows.subRowColumnContainer}>
            <Text
              style={[
                phone && { textAlign: 'center', width: 50 },
                ComparativeYearsLeadCountRows.subRowText,
                (index % 2 === 0 || (lastYearIsActual && index / 2 >= new Date().getMonth() + 1)) &&
                  ComparativeYearsLeadCountRows.subRowFutureText,
                properties.isLossRow && ComparativeYearsLeadCountRows.subRowLossText,
                // new Date().getMonth() < 0 && ComparativeYearsLeadCountRows.subRowFutureText,
              ]}
            >
              {index % 2 !== 0 && lastYearIsActual && index / 2 >= new Date().getMonth() + 1
                ? '-'
                : total}
            </Text>
          </View>
          {index % 2 !== 0 && index !== properties.item.total_leads.length - 1 && (
            <View style={ComparativeYearsLeadCountRows.subRowDivider} />
          )}
        </>
      ))}
    </View>
  );
}
