import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ProcedureCrmRepository } from '@human/crm/repositories/procedure/procedure.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ProceduresRoutes } from '../../procedure.routes';
import { ProcedureCrmType } from '@human/crm/types/procedure.crm.type';

export function UpdateProcedureScreen(properties: {
  navigation: NavigationProp<ProceduresRoutes>;
  route: RouteProp<ProceduresRoutes, 'updateProcedure'>;
}) {
  return (
    <ModalComponent icon="edit" prefix="crm.procedures.update">
      <FormComponent<ProcedureCrmType>
        prefix="crm.procedures.update.form"
        padding
        repository={{
          send: (data) => {
            return new ProcedureCrmRepository()
              .update({
                techniqueId: properties.route.params.techniqueId,
                clinicId: properties.route.params.clinicId,
                treatmentId: properties.route.params.treatmentId,
                patientId: properties.route.params.patientId,
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
          get: () => {
            return new ProcedureCrmRepository().pick({
              techniqueId: properties.route.params.techniqueId,
              clinicId: properties.route.params.clinicId,
              treatmentId: properties.route.params.treatmentId,
              patientId: properties.route.params.patientId,
            });
          },
        }}
        fields={[
          {
            name: 'price',
            type: 'float',
            required: true,
            validations: ['price'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
