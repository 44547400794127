// Modules
import { StyleSheet } from 'react-native';

/**
 * Pair input Styles
 * @constant {StyleSheet} PairInputStyles
 */
export const PairInputStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 30,
  },
});
