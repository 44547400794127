import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CrmSections } from '@human/crm/crm.sections';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ActionsRoutes } from '../../actions.routes';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { UpdateActionEndingPart } from './parts/update.action.actionEnding.part';
import { LeadProfileComponent } from '@components/leadProfile/leadProfile.component';
import { PatientProfileComponent } from '@components/patientProfile/patientProfile.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { UpdateActionAlertsPart } from './parts/update.action.alerts.part';

export function UpdateActionScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionUpdate'>;
}) {
  const patientId = properties.route.params.patientId;
  const { showSuccess } = useSuccessToast();

  return (
    <>
      {patientId ? (
        <PatientProfileComponent
          patientId={patientId}
          clinicId={properties.route.params.clinicId}
          contactId={properties.route.params.contactId}
          leadId={properties.route.params.leadId}
        />
      ) : (
        <LeadProfileComponent
          {...properties}
          leadId={properties.route.params.leadId}
          clinicId={properties.route.params.clinicId}
          contactId={properties.route.params.contactId}
        />
      )}

      <ModalComponent
        level={properties.route.params.level}
        icon={CrmSections.tasks.icon}
        prefix="crm.leads.update.actions.update"
      >
        <TabsComponent
          prefix="crm.leads.update.actions.update"
          tabs={[
            {
              name: 'action',
              icon: CrmSections.actions.icon,
              content: (settings) => (
                <FormComponent<Partial<ActionCrmType>>
                  readonly={!hasPermissionUtilty({ name: 'crm.tasks.editor' })}
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  prefix="crm.leads.update.actions.update.form"
                  repository={{
                    send: (params) => {
                      if (patientId) {
                        return new ActionCrmRepository()
                          .updatePatientAction({
                            item: {
                              ...params.item,
                              patientId: properties.route.params.patientId || '',
                              clinicId: properties.route.params.clinicId,
                            },
                          })
                          .then(() => {
                            settings?.setIsEditing(false);
                            showSuccess();
                          });
                      }

                      return new ActionCrmRepository()
                        .update({
                          item: {
                            ...params.item,
                            leadId: properties.route.params.leadId,
                            clinicId: properties.route.params.clinicId,
                          },
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                          properties.navigation.setParams({
                            ...properties.route.params,
                            activityId: params.item.activityId,
                          });
                        });
                    },
                    get: () =>
                      new ActionCrmRepository().pick({
                        actionId: properties.route.params.actionId,
                        clinicId: properties.route.params.clinicId,
                        leadId: properties.route.params.leadId,
                      }),
                  }}
                  fields={[
                    {
                      type: 'text',
                      name: 'subject',
                      description: true,
                      validations: ['title'],
                    },
                    {
                      type: 'select',
                      name: 'activityId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new ActivityCrmRepository()
                          .pick({ activityId: properties.id })
                          .then((activity) => {
                            return activity.name;
                          });
                      },
                      repository: ({ search }) => {
                        return new ActivityCrmRepository()
                          .list({
                            direction: 'asc',
                            limit: 100,
                            order: 'id',
                            page: 0,
                            search,
                            active: true,
                          })
                          .then((activities) =>
                            activities.items.map((activity) => ({
                              label: activity.name,
                              value: activity.id,
                            }))
                          );
                      },
                    },
                    {
                      type: 'select',
                      name: 'responsibleId',
                      required: true,
                      description: true,
                      readonlyField: !hasPermissionUtilty({ name: 'crm.tasks.updateResponsible' }),
                      pick: (props: { id: string }) => {
                        return new CoworkerCrmRepository()
                          .pick({
                            clinicId: properties.route.params.clinicId,
                            coworkerId: props.id,
                          })
                          .then((coworker) => {
                            return `${coworker.name} ${coworker.surname}`;
                          });
                      },
                      repository: ({ search }) => {
                        return new CoworkerCrmRepository()
                          .list({
                            clinicId: properties.route.params.clinicId,
                            direction: 'asc',
                            limit: 100,
                            order: 'id',
                            page: 0,
                            search,
                            active: true,
                          })
                          .then((leads) =>
                            leads.items.map((user) => ({
                              label: `${user.name} ${user.surname}`,
                              value: user.id,
                            }))
                          );
                      },
                    },
                    {
                      type: 'date',
                      name: 'started',
                      required: true,
                      description: true,
                      format: 'YYYY-MM-DD HH:mm:ss',
                    },
                    {
                      type: 'text',
                      name: 'observation',
                      lines: 3,
                      description: true,
                      validations: ['observation'],
                    },
                  ]}
                />
              ),
            },
            UpdateActionEndingPart(properties),
            UpdateActionAlertsPart(properties),
          ]}
        />
      </ModalComponent>
    </>
  );
}
