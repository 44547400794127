import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { TemplateParamsRoutes } from '../../templateParams.routes';
import { TemplateParamsAccessRepository } from '@human/access/repositories/template/templateParams/templateParams.repository';
import { TemplateKeyAccessRepository } from '@human/access/repositories/template/templateKey/templateKey.repository';
import { ModalComponent } from '@components/modal/modal.component';

export function CreateTemplateParamsScreen(properties: {
  navigation: NavigationProp<TemplateParamsRoutes>;
  route: RouteProp<TemplateParamsRoutes, 'createTemplateParam'>;
}) {
  return (
    <ModalComponent
      icon="file-alt"
      prefix="access.templates.templateParams.create"
      level={properties.route.params.level}
    >
      <FormComponent<{ templateKeyId: string; order: number }>
        prefix={'access.templates.templateParams.create.form'}
        padding
        repository={{
          send: (data) => {
            return new TemplateParamsAccessRepository()
              .create({
                item: {
                  templateKeyId: data.item.templateKeyId,
                  templateId: properties.route.params.templateId,
                  order: data.item.order,
                },
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            name: 'templateKeyId',
            type: 'select',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new TemplateKeyAccessRepository()
                .list({
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((response) => {
                  return response.items.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }));
                });
            },
          },
          {
            name: 'order',
            type: 'number',
            description: true,
            required: true,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
