import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionsRoutes } from '../../../actions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { addDays } from '@utils/addDays/addDays.utility';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function UpdateActionTemplatePart(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'updateActionTemplate'>;
}) {
  return (
    <>
      <ModalComponent icon="file-alt" prefix="access.templates.send">
        <FormComponent<{ id: string; landbotId: string }>
          prefix={'access.templates.send.form'}
          padding
          repository={{
            get: () => {
              return new TemplateAccessRepository()
                .pick({
                  templateId: properties.route.params.templateId,
                })
                .then((template) => {
                  return {
                    id: template.id,
                    landbotId: properties.route.params.landbotId,
                  };
                });
            },
            send: (data) => {
              return new ContactCrmRepository()
                .sendTemplate({
                  contactId: properties.route.params.contactId,
                  templateId: data.item.id || '',
                  landbotId: data.item.landbotId || '',
                  responsibleId: properties.route.params.responsibleId,
                  clinicId: properties.route.params.clinicId,
                })
                .then(() => {
                  properties.navigation.goBack();
                  if (properties.route.params.nextActivityId)
                    properties.navigation.navigate('actionNextResultUpdate', {
                      actionId: properties.route.params.actionId,
                      clinicId: properties.route.params.clinicId,
                      leadId: properties.route.params.leadId,
                      level: properties.route.params.level,
                      activityId: properties.route.params.nextActivityId,
                      started: properties.route.params.nextActivityId
                        ? addDays(new Date(), properties.route.params.nextActivityDelay)
                        : new Date(),
                    });
                });
            },
          }}
          fields={[
            {
              name: 'id',
              type: 'select',
              description: true,
              required: true,
              repository: ({ search }) => {
                return new TemplateAccessRepository()
                  .list({
                    page: 0,
                    limit: 100,
                    search,
                    active: true,
                  })
                  .then((response) => {
                    return response.items.map((item) => ({
                      label: item.label,
                      value: item.id,
                    }));
                  });
              },
              pick: (props: { id: string }) => {
                return new TemplateAccessRepository()
                  .pick({ templateId: props.id })
                  .then((template) => {
                    return `${template.label}`;
                  });
              },
            },
            {
              name: 'landbotId',
              type: 'text',
              description: true,
              validations: ['code'],
            },
          ]}
        />
      </ModalComponent>
    </>
  );
}
