import { StyleSheet } from 'react-native';

export const PassportStyles = StyleSheet.create({
  container: {
    position: 'relative',
    left: 0,
    right: 0,
    backgroundColor: '#f8f8f8',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  primaryContactDetailsContainer: {
    padding: 20,
    paddingTop: 10,
    gap: 20,
    flexDirection: 'row',
  },
  primaryContactDetailsContainerLaptop: {
    padding: 8,
    gap: 8,
  },
  initialCharactersContainer: {
    width: 50,
    height: 50,
    backgroundColor: '#e0e0e0',
    borderRadius: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  initialCharactersText: {
    color: 'black',
    fontSize: 24,
  },
  contactNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  contactNameAndSurname: {
    flexDirection: 'row',
    marginLeft: 5,
    gap: 5,
  },
  contactName: {
    fontSize: 18,
    fontWeight: '500',
  },
  contactInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  passportIcon: {
    fontSize: 18,
    color: 'gray',
  },
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  fieldContainer: {
    flex: 1,
    padding: 10,
    gap: 5,
    marginHorizontal: 10,
  },
  titleText: {
    color: '#9E9E9E',
    fontWeight: '500',
  },
  contentText: {
    color: '#111',
    fontWeight: '500',
    fontSize: 12,
  },
  consentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  consentChipContainer: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 15,
  },
  consentText: {
    fontSize: 12,
    color: 'gray',
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  columnDivider: {
    width: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  campaignContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  touchable: {
    overflow: 'hidden',
    height: 120,
    width: 120,
    backgroundColor: '#000',
    borderRadius: 10,
  },
  imageBackground: {
    height: 120,
    width: 120,
    borderRadius: 10,
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0.4,
    height: 120,
    width: 120,
    borderRadius: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
    zIndex: 2,
    marginTop: 'auto',
  },
  hashText: {
    fontWeight: '100',
    fontSize: 14,
    opacity: 0.5,
    color: '#fff',
  },
  campaignCode: {
    fontWeight: '400',
    fontSize: 14,
    color: '#fff',
  },
  campaignTitle: {
    color: '#fff',
    fontSize: 16,
    marginLeft: 10,
    marginBottom: 10,
    zIndex: 2,
    fontWeight: '700',
  },
  imageIconContainer: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageIcon: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'rgba(237, 53, 145, 0.5)',
  },
});
