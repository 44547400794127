import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { TreatmentsRoutes } from '../../../treatment.routes';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
export function FormUpdateTreatmentScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'updateTreatment'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <>
      <FormComponent<TreatmentCrmType>
        prefix="crm.treatments.update.form"
        readonly={!hasPermissionUtilty({ name: 'crm.treatments.editor' })}
        repository={{
          send: (settings) => {
            return new TreatmentCrmRepository()
              .update({
                item: settings.item,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => showSuccess());
          },
          get: async () =>
            new TreatmentCrmRepository().pick({
              treatmentId: properties.route.params.treatmentId,
              patientId: properties.route.params.patientId,
              clinicId: properties.route.params.clinicId,
            }),
        }}
        fields={[
          {
            type: 'select',
            name: 'phaseId',
            description: true,
            required: true,
            pick: (settings: { id: string }) => {
              return new PhaseCrmRepository()
                .pick({
                  phaseId: settings.id,
                })
                .then((phase) => phase.name);
            },
            repository: (props: { search?: string }) => {
              return new ProgramPhaseCrmRepository()
                .list({
                  search: props.search,
                  programId: properties.route.params.programId || '',
                })
                .then((phases) => {
                  return phases.items.map((phase) => ({
                    label: phase.phaseName,
                    value: phase.phaseId,
                  }));
                });
            },
          },
          {
            type: 'number',
            name: 'discount',
            description: true,
          },
          {
            type: 'date',
            name: 'finished',
            description: true,
            format: 'YYYY-MM-DD HH:mm:ss',
          },
        ]}
      />
    </>
  );
}
