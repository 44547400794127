import { NavigationComponent } from '@components/navigation/navigation.component';
import { ServicesRoutes } from './service.routes';
import { ListServicesScreen } from './screens/list/list.service.screen';
import { CreateServiceScreen } from './screens/create/create.service.screen';
import { UpdateServiceScreen } from './screens/update/update.service.screen';
import { DisableServiceScreen } from './screens/disable/disable.service.screen';
import { EnableServiceScreen } from './screens/enable/enable.service.screen';

const Navigation = NavigationComponent<ServicesRoutes>();

export function ServicesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="serviceList" component={ListServicesScreen} />
      <Navigation.Screen name="serviceCreate" component={CreateServiceScreen} />
      <Navigation.Screen name="serviceUpdate" component={UpdateServiceScreen} />
      <Navigation.Screen name="serviceDisable" component={DisableServiceScreen} />
      <Navigation.Screen name="serviceEnable" component={EnableServiceScreen} />
    </Navigation.Navigator>
  );
}
