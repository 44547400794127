// Modules
import { StyleSheet } from 'react-native';

/**
 * Table Styles
 * @constant {StyleSheet} TableStyles
 */
export const TableStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
  },
  header: {
    width: 100,
    alignContent: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    paddingVertical: 10,
    flex: 1,
    flexDirection: 'row',
    borderStyle: 'dashed',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
  },
  headerIconContainer: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerIcon: {
    fontSize: 22,
    width: 42,
    textAlign: 'center',

    color: 'rgba(0, 0, 0, 0.5)',
  },
  headerTextName: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    paddingLeft: 15,
    opacity: 0.5,
    fontSize: 10,
  },
  headerTextNameActive: {},
  headerTextNameInactive: {},
  orderIconContainer: {
    marginLeft: 'auto',
    borderRadius: 120,
    marginRight: 10,
    height: 18,
    width: 18,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.56)',
  },
  orderIcon: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  orderIconActive: {
    color: '#000',
  },
  orderIconInactive: {
    color: '#aaa',
  },
  tableContainer: {
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 23.84,
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: 5,
  },
  enabledTableContainer: {
    backgroundColor: '#fff',
  },
  disabledTableContainer: {
    backgroundColor: '#EEEEEE',
    opacity: 0.6,
  },
  columnContainer: {
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  wrapper: {
    zIndex: 0,
  },
  loaderContainer: {
    marginTop: 50,
  },
  filter: {
    height: 500,
    width: 400,
    backgroundColor: 'red',
    position: 'absolute',
    zIndex: 1,
  },
  filtersContainer: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  searchContainer: {
    flex: 1,
    borderRadius: 5,
  },
  periodContainer: {
    zIndex: 10,
    borderRadius: 5,
    flexGrow: 0,
  },
  completedContainer: {},
});
