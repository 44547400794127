import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { LinealBoardType } from '../../types/lineal.board.type';
import { ItemByDepartmentYearsComparativeType } from '../../screens/leads/components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';

export class SaleBoardRepository extends AuthorizedRepository {
  async calculateCostByLeadByCampaign(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    startDate?: {
      start: Date;
      end: Date;
    };
    endDate?: {
      start: Date;
      end: Date;
    };
  }): Promise<{
    data: {
      label: string;
      value: number;
    }[][];
    extraInfo: {
      label: string;
      value: number;
    }[];
  }> {
    return this.fetch('board/sale/calculateCostByLeadByCampaign', {
      method: 'GET',
      params: properties,
    });
  }

  async calculateCostByLeadByClinic(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    // dates?: {
    //   start: Date;
    //   end: Date;
    // };
    month?: number;
  }): Promise<{
    data: {
      label: string;
      value: number;
    }[][];
    extraInfo: {
      label: string;
      value: number;
    }[];
  }> {
    return this.fetch('board/sale/calculateCostByLeadByClinic', {
      method: 'GET',
      params: properties,
    });
  }

  async calculateCostByLeadByFunnel(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{
    data: {
      label: string;
      value: number;
    }[][];
    extraInfo: {
      label: string;
      value: number;
    }[];
  }> {
    return this.fetch('board/sale/calculateCostByLeadByFunnel', {
      method: 'GET',
      params: properties,
    });
  }

  async calculateCostByLeadByLanguage(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{
    data: {
      label: string;
      value: number;
    }[][];
    extraInfo: {
      label: string;
      value: number;
    }[];
  }> {
    return this.fetch('board/sale/calculateCostByLeadByLanguage', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadByStateByClinic(properties: {
    languageId?: string;
    funnelId?: string;
    campaignId?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<any> {
    return this.fetch('board/sale/calculateTreatmentTechniqueServiceByClinic', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadByStateByLanguage(properties: {
    languageId?: string;
    funnelId?: string;
    campaignId?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<any> {
    return this.fetch('board/sale/calculateTreatmentTechniqueServiceByLanguage', {
      method: 'GET',
      params: properties,
    });
  }

  //SQL QUERY
  async listTreatmentTechniqueAndServiceByLanguage(properties: { month?: number }) {
    return this.fetch('board/sale/treatmentTechniqueServiceByLanguage', {
      method: 'GET',
      params: properties,
    });
  }

  async listTreatmentTechniqueAndServiceByClinic(properties: { month?: number }) {
    return this.fetch('board/sale/treatmentTechniqueServiceByClinic', {
      method: 'GET',
      params: properties,
    });
  }

  async newSaleCountYearsComparative(properties: {
    clinicId: string;
    firstYear: number;
    secondYear: number;
  }): Promise<ItemByDepartmentYearsComparativeType[]> {
    return this.fetch('board/saleDetail/saleLeadsComparingYears', {
      method: 'GET',
      params: properties,
    });
  }
}
