import { NavigationComponent } from '@components/navigation/navigation.component';
import { MaintenancesRoutes } from './maintenance.routes';
import { CrmRoutes } from '@human/crm/crm.routes';
import { RouteProp } from '@react-navigation/native';
import { ListMaintenancesScreen } from './screens/list/list.maintenances.screen';
import { CreateMaintenanceScreen } from './screens/create/create.maintenance.screen';
import { UpdateMaintenanceScreen } from './screens/update/update.maintenance.screen';
import { DisableMaintenanceScreen } from './screens/disable/disable.maintenance.screen';
import { EnableMaintenanceScreen } from './screens/enable/enable.maintenance.screen';

const Navigation = NavigationComponent<MaintenancesRoutes>();

export function MaintenanceSection(properties: { route: RouteProp<CrmRoutes, 'maintenances'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="listMaintenance" component={ListMaintenancesScreen} />
      <Navigation.Screen name="createMaintenance" component={CreateMaintenanceScreen} />
      <Navigation.Screen name="updateMaintenance" component={UpdateMaintenanceScreen} />
      <Navigation.Screen name="enableMaintenance" component={EnableMaintenanceScreen} />
      <Navigation.Screen name="disableMaintenance" component={DisableMaintenanceScreen} />
    </Navigation.Navigator>
  );
}
