import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';

export class InterventionTechniqueCrmRepository extends AuthorizedRepository {
  async list(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
    interventionId: string;
    search?: string;
  }): Promise<{ items: TechniqueCrmType[]; total: number }> {
    return this.fetch('interventionTechnique/find', {
      params: properties,
      method: 'GET',
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.techniqueId,
          name: item.techniqueName,
          price: item.techniquePrice,
          created: item.techniqueCreated,
        })),
        total: data.total,
      };
    });
  }

  async delete(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
    interventionId: string;
    techniqueId: string;
  }): Promise<void> {
    return this.fetch('interventionTechnique/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async create(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
    interventionId: string;
    techniqueId: string;
  }): Promise<void> {
    return this.fetch('interventionTechnique/create', {
      method: 'POST',
      body: properties,
    });
  }
}
