import { ValidationNumberInput } from './number.properties';

export function TextInputValidations(properties: {
  validations: ValidationNumberInput[];
  required?: boolean;
  value?: number;
}) {
  const errors: string[] = [];

  /**
   * movementLimit Validation
   * 1. Must be a integer
   */
  if (typeof properties.value !== 'number') {
    errors.push('valueMustBeAnInteger');
  } else if (Number.isNaN(properties.value)) {
    errors.push('valueMustBeAValidNumber');
  } else if (!Number.isInteger(properties.value)) {
    errors.push('valueMustBeAnInteger');
  }

  if (properties.validations.includes('positive')) {
    if (!properties.value) {
      errors.push('valueMustBePositive');
    } else if (properties.value < 0) {
      errors.push('valueMustBePositive');
    }
  }

  if (properties.required && properties.value === null) {
    errors.push('value is required');
  }

  return errors;
}
