import { FormComponent } from '@components/form/form.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { CreateActionProperties } from './create.action.properties';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';
import { useUserState } from '@states/user/user.state';

export function FormActionScreen(properties: {
  navigation: CreateActionProperties['navigation'];
  clinicId: string;
  leadId: string;
  started?: Date;
}) {
  const { user } = useUserState();
  return (
    <FormComponent<Partial<ActionCrmType>>
      padding
      prefix="crm.leads.update.actions.create.form"
      repository={{
        send: (settings) => {
          return new ActionCrmRepository()
            .create({
              item: {
                ...settings.item,
                leadId: properties.leadId,
                clinicId: properties.clinicId,
              },
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
        get: async () => {
          const contract = await new ContractAccessRepository().findByClinic({
            userId: user.id,
            clinicId: properties.clinicId,
          });

          if (properties.started) {
            return {
              started: properties.started,
              responsibleId: contract.id,
            };
          }

          return {
            responsibleId: contract.id,
          };
        },
      }}
      fields={[
        {
          type: 'text',
          name: 'subject',
          description: true,
          validations: ['title'],
        },
        {
          type: 'select',
          name: 'activityId',
          required: true,
          description: true,
          pick: (props: { id: string }) => {
            return new ActivityCrmRepository().pick({ activityId: props.id }).then((activity) => {
              return `${activity.name}`;
            });
          },
          repository: ({ search }) => {
            return new ActivityCrmRepository()
              .list({
                direction: 'asc',
                limit: 100,
                order: 'id',
                page: 0,
                search,
                active: true,
              })
              .then((activities) =>
                activities.items.map((activity) => ({
                  label: activity.name,
                  value: activity.id,
                }))
              );
          },
        },
        {
          type: 'select',
          name: 'responsibleId',
          required: true,
          description: true,
          pick: (props: { id: string }) => {
            return new ContractAccessRepository()
              .pick({ userId: user.id, contractId: props.id })
              .then((contract) =>
                new CoworkerCrmRepository()
                  .pick({
                    coworkerId: contract.id,
                    clinicId: properties.clinicId,
                  })
                  .then((coworker) => {
                    return `${coworker.name} ${coworker.surname}`;
                  })
              );
          },
          repository: ({ search }) => {
            return new CoworkerCrmRepository()
              .list({
                clinicId: properties.clinicId,
                direction: 'asc',
                limit: 100,
                order: 'email',
                page: 0,
                search,
                active: true,
              })
              .then((leads) =>
                leads.items.map((user) => ({
                  label: `${user.name} ${user.surname}`,
                  value: user.id,
                }))
              );
          },
        },
        {
          type: 'date',
          description: true,
          required: true,
          name: 'started',
          format: 'YYYY-MM-DD HH:mm:ss',
        },
      ]}
    />
  );
}
