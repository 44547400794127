import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ProcedureCrmType } from '@human/crm/types/procedure.crm.type';
import { ProcedureCrmRepository } from '@human/crm/repositories/procedure/procedure.repository';
import { ProceduresRoutes } from '../../procedure.routes';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
export function CreateProcedureScreen(properties: {
  navigation: NavigationProp<ProceduresRoutes>;
  route: RouteProp<ProceduresRoutes, 'createProcedure'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="tasks"
      prefix="crm.procedures.create"
    >
      <FormComponent<ProcedureCrmType>
        prefix="crm.procedures.create.form"
        padding
        repository={{
          send: (settings) => {
            return new ProcedureCrmRepository()
              .create({
                item: settings.item,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'techniqueId',
            description: true,
            required: true,
            repository: (settings) =>
              new TechniqueCrmRepository()
                .list({
                  search: settings.search,
                  limit: 100,
                  active: true,
                })
                .then((data) => {
                  return data.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }));
                }),
          },
        ]}
      />
    </ModalComponent>
  );
}
