import { CSSProperties } from 'react';
import { StyleSheet } from 'react-native';

export const SlicerStylesWeb: CSSProperties = {
  flex: 1,
  fontFamily: 'Lato-Bold, sans-serif',
  width: 300,
  position: 'absolute',
  right: 10,
  backgroundColor: 'white',
  borderRadius: 5,
  zIndex: 1,
  padding: 20,
};

export const SlicerStyles = StyleSheet.create({
  iconsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    paddingHorizontal: 15,
    borderRadius: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
    cursor: 'pointer',
  },
  cancelButton: {
    backgroundColor: 'rgb(209, 209, 209)',
    borderColor: 'rgb(209, 209, 209)',
  },
  cancelButtonIcon: {
    color: 'rgb(180, 180, 180)',
    fontSize: 20,
    fontWeight: 'bold',
  },
  applyButton: {
    backgroundColor: 'rgb(237, 53, 145)',
    borderColor: 'rgb(237, 53, 145)',
  },
  appluButtonIcon: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
  },
});
