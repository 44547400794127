import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../../result.routes';
import { TabType } from '@components/tabs/tabs.types';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { FormComponent } from '@components/form/form.component';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';
import { ResultTemplateCrmRepository } from '@human/crm/repositories/resultTemplate/resultTemplate.repository';

export function UpdateResultTemplate(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();

  return {
    name: 'template',
    icon: 'file-alt',
    content: (settings) => (
      <FormComponent<{ templateId: string; landbotId: string }>
        prefix="crm.results.update.form"
        onChange={(data) => {
          if (!data.isLocal) return;
          settings?.setIsEditing(true);
        }}
        repository={{
          get: () => {
            return new ResultTemplateCrmRepository().pick({
              activityId: properties.route.params.activityId,
              resultId: properties.route.params.resultId,
            });
          },
          send: (data) => {
            return new ResultTemplateCrmRepository()
              .update({
                id: properties.route.params.resultId,
                item: { ...data.item, activityId: properties.route.params.activityId },
              })
              .then(() => {
                settings?.setIsEditing(false);
                showSuccess();
              });
          },
        }}
        fields={[
          {
            name: 'templateId',
            type: 'select',
            description: true,
            nullable: true,
            pick: (props: { id: string }) => {
              return new TemplateAccessRepository()
                .pick({ templateId: props.id })
                .then((template) => {
                  return `${template.label}`;
                });
            },
            repository: ({ search }) =>
              new TemplateAccessRepository()
                .list({
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((res) => {
                  return res.items.map((template) => {
                    return {
                      label: `${template.label}`,
                      value: template.id,
                    };
                  });
                }),
          },
          {
            name: 'landbotId',
            type: 'text',
            description: true,
            validations: ['code'],
          },
        ]}
      />
    ),
  };
}
