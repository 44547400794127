import { SettingAccessType } from '@human/access/types/setting.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class SettingAccessRepository extends AuthorizedRepository {
  async pick() {
    return this.fetch('setting/pick', {
      method: 'GET',
    }).then((data) => ({
      loginEnabled: data.loginEnabled,
      attemptLimitEnabled: data.attemptLimitEnabled,
      movementLimitEnabled: data.movementLimitEnabled,
    }));
  }

  async update(properties: { item: Partial<SettingAccessType> }) {
    return this.fetch('setting/update', {
      method: 'PUT',
      body: properties.item,
    }).then(() => {});
  }
}
