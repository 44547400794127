// Modules
import { StyleSheet } from 'react-native';

/**
 * LeadDetail Styles
 * @constant {StyleSheet} LeadDetailStyles
 */
export const LeadDetailStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section: {
    gap: 10,
    paddingVertical: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 18,
  },
  text: {
    color: '#eee',
  },
  mainSection: {
    paddingVertical: 10,
    gap: 30,
  },
  gapSection: {
    gap: 10,
  },
  label: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 15,
  },
  spacer: {
    width: 15,
    height: 15,
  },
  tag: {
    backgroundColor: '#66666666',
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 5,
    alignItems: 'center',
  },
});
