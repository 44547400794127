import { ItemTableType } from '@components/table/table.component';
import { OrderTableType } from '@components/table/table.properties';
import { SelectableColumnImplementation } from './selectable.column.properties';
import { SelectableColumnStyles } from './selectable.column.styles';
import { TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';

export function SelectableColumnComponent<
  ITEM extends ItemTableType,
  ORDER extends OrderTableType<ITEM>,
>(props: SelectableColumnImplementation<ITEM, ORDER>) {
  const selected = props.selected(props.item);

  return (
    <View style={SelectableColumnStyles.container}>
      {selected && <View style={SelectableColumnStyles.line} />}
      <TouchableOpacity
        onPress={() => props.onSelect(props.item)}
        style={[SelectableColumnStyles.checkbox, selected ? SelectableColumnStyles.checked : {}]}
      >
        {selected && (
          <IconComponent
            name="check"
            iconStyle={SelectableColumnStyles.checkedIcon}
            containerstyle={SelectableColumnStyles.checkedIconContainer}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}
