import { FunnelsRoutes } from './funnels.routes';
import { ListFunnelScreen } from './screens/list/list.funnel.screen';
import { CreateFunnelScreen } from './screens/create/create.funnel.screen';
import { UpdateFunnelScreen } from './screens/update/update.funnel.screen';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { DisableFunnelScreen } from './screens/disable/disable.funnel.screen';
import { EnableFunnelScreen } from './screens/enable/enable.funnel.screen';

const Navigation = NavigationComponent<FunnelsRoutes>();
export function FunnelsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListFunnelScreen} />
      <Navigation.Screen name="create" component={CreateFunnelScreen} />
      <Navigation.Screen name="disable" component={DisableFunnelScreen} />
      <Navigation.Screen name="enable" component={EnableFunnelScreen} />
      <Navigation.Screen name="update" component={UpdateFunnelScreen} />
    </Navigation.Navigator>
  );
}
