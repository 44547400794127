import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class TemplateKeyAccessRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: {
      id: string;
      name: string;
    }[];
    total: number;
  }> {
    return this.fetch(`templateKey/find`, {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((templateKey: any) => ({
          id: templateKey.id,
          name: templateKey.name,
        })),
        total: data.total,
      };
    });
  }
}
