import { IconType } from '@components/icon/icon.types';
import { ProfileRoutes } from './profile.routes';

export const ProfileSections: {
  [key in keyof ProfileRoutes]: {
    name: string;
    icon: IconType;
    keywords: string[];
    department: string;
    status?: boolean;
    supressBadge?: boolean;
  };
} = {
  account: {
    department: 'account',
    name: 'account',
    icon: 'user',
    keywords: ['account'],
    supressBadge: true,
  },
  changePassword: {
    department: 'account',
    name: 'changePassword',
    icon: 'lock',
    keywords: ['changePassword'],
    supressBadge: true,
  },
};
