import { OrderTableType } from '@components/table/table.properties';
import { BarColumnImplementation } from './bar.column.properties';
import { View, Text, TouchableOpacity } from 'react-native';
import { BarColumnStyles } from './bar.column.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';

export function BarColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  props: BarColumnImplementation<ITEM, ORDER>
): JSX.Element {
  const total = props.onTotal?.(props.item) ?? 0;
  const max = props.onMax?.(props.items) ?? 0;

  const width = `${(total / max) * 100}%`;
  const content = (
    <View style={BarColumnStyles.contentContainer}>
      <View style={BarColumnStyles.barContainer}>
        {total > 0 && <View style={[BarColumnStyles.bar, { width: width }]} />}
      </View>
      <TextComponent lines={1} bold text={`${total}`} style={BarColumnStyles.total} />
    </View>
  );

  if (props.onPress) {
    return (
      <TouchableOpacity
        onPress={() => props.onPress?.(props.item)}
        style={BarColumnStyles.container}
      >
        {content}
        <IconComponent name="angle-right" iconStyle={BarColumnStyles.icon} />
      </TouchableOpacity>
    );
  }
  return <View style={BarColumnStyles.container}>{content}</View>;
}
