import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BulletinsRoutes } from '../../bulletins.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { BulletinAccesRepository } from '@human/access/repositories/bulletin/bulletin.repository';

export function PasswordDeleteBulletinScreen(properties: {
  navigation: NavigationProp<BulletinsRoutes>;
  route: RouteProp<BulletinsRoutes, 'password'>;
}) {
  return (
    <ModalComponent
      icon="lock"
      prefix="access.bulletins.delete.passwordForm"
      level={properties.route.params.level}
    >
      <FormComponent<{ password: string }>
        prefix="access.bulletins.delete.passwordForm"
        padding
        repository={{
          get: async () => {
            return {
              password: __DEV__ ? process.env.EXPO_PUBLIC_DEV_PASSWORD : '',
            };
          },
          send: (data) =>
            new BulletinAccesRepository()
              .delete({
                bulletinId: properties.route.params.bulletinId,
                clinicId: properties.route.params.clinicId,
                password: data.item.password,
              })
              .then(() => properties.navigation.goBack()),
        }}
        fields={[
          {
            name: 'password',
            icon: 'lock',
            type: 'text',
            secure: true,
            validations: ['password'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
