export const parseCodeErrors = (
  backErrors: { field: string; code: string }[],
  errors: string[]
) => {
  return backErrors.filter((error) => {
    return !errors?.find((er) => {
      if (er.includes('code')) {
        return (
          er.toLocaleLowerCase().includes(error.code.toLocaleLowerCase()) &&
          er.toLocaleLowerCase().includes('code')
        );
      } else if (er.includes('title')) {
        return (
          er.toLocaleLowerCase().includes(error.code.toLocaleLowerCase()) &&
          (er.toLocaleLowerCase().includes('name') || er.toLocaleLowerCase().includes('title'))
        );
      } else if (er.includes('name')) {
        return (
          er.toLocaleLowerCase().includes(error.code.toLocaleLowerCase()) &&
          (er.toLocaleLowerCase().includes('name') || er.toLocaleLowerCase().includes('title'))
        );
      } else if (er.includes('password')) {
        return (
          er.toLocaleLowerCase().includes(error.code.toLocaleLowerCase()) &&
          er.toLocaleLowerCase().includes('password')
        );
      } else {
        return (
          er.toLocaleLowerCase().includes(error.code.toLocaleLowerCase()) &&
          er.toLocaleLowerCase().includes(error.field.toLocaleLowerCase())
        );
      }
    });
  });
};
