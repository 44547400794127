// Modules
import { StyleSheet } from 'react-native';

/**
 * Image Input Styles
 * @constant {StyleSheet} ImageInputStyles
 */
export const ImageInputStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  iconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  readonly: {
    opacity: 0.4,
  },
  imageSelected: {
    aspectRatio: 1,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: 'rgba(237, 53, 145, 0.1)',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 10,
  },
  imageSelectedButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: 'rgb(255, 255, 255)',
    width: 40,
    height: 40,
    borderRadius: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageSelectedIcon: {
    color: '#aaa',
    fontSize: 20,
  },

  iconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    imageAlign: 'center',
    lineHeight: 27,
  },
  input: {
    borderRadius: 5,
    alignContent: 'center',
    backgroundColor: 'white',
    height: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
  },
  inputIcon: {
    paddingLeft: 50,
  },
  inputNoIcon: {
    paddingLeft: 10,
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },

  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: 9,
    borderBottomEndRadius: 5,
    borderBottomStartRadius: 5,
    paddingTop: 15,
    marginTop: -6,
    zIndex: -1,
  },
  imageContainer: {
    width: 101,
    height: 101,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: 'rgba(237, 53, 145, 0.1)',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3.84,
    elevation: 5,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
});
