import { NavigationComponent } from '@components/navigation/navigation.component';
import { CreateClinicScreen } from './screens/create/create.clinic.screen';
import { ListClinicsScreen } from './screens/list/list.clinics.screen';
import { UpdateClinicScreen } from './screens/update/update.clinic.screen';
import { ClinicsRoutes } from './clinics.routes';
import { DeleteClinicScreen } from './screens/delete/delete.clinic.screen';
import { CreateDepartmentScreen } from '../departments/screens/create/create.department.screen';
import { UpdateDepartmentScreen } from '../departments/screens/update/update.department.screen';
import { PasswordDeleteClinicScreen } from './screens/delete/password.delete.clinic.screen';
import { DisableClinicScreen } from './screens/disable/disable.clinic.screen';
import { EnableClinicScreen } from './screens/enable/enable.clinic.screen';
import { DisableDepartmentScreen } from '../departments/screens/disable/disable.department.screen';
import { EnableDepartmentScreen } from '../departments/screens/enable/enable.department.screen';
import { CreateMemberScreen } from '../departments/screens/members/create/create.member.screen';

const Navigation = NavigationComponent<ClinicsRoutes>();

export function ClinicsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListClinicsScreen} />
      <Navigation.Screen name="create" component={CreateClinicScreen} />
      <Navigation.Screen name="update" component={UpdateClinicScreen} />
      <Navigation.Screen name="delete" component={DeleteClinicScreen} />
      <Navigation.Screen name="disable" component={DisableClinicScreen} />
      <Navigation.Screen name="enable" component={EnableClinicScreen} />
      <Navigation.Screen name="departmentCreate" component={CreateDepartmentScreen} />
      <Navigation.Screen name="departmentUpdate" component={UpdateDepartmentScreen} />
      <Navigation.Screen name="departmentDisable" component={DisableDepartmentScreen} />
      <Navigation.Screen name="departmentEnable" component={EnableDepartmentScreen} />
      <Navigation.Screen name="password" component={PasswordDeleteClinicScreen} />
      <Navigation.Screen name="memberCreate" component={CreateMemberScreen} />
    </Navigation.Navigator>
  );
}
