import { NavigationComponent } from '@components/navigation/navigation.component';
import { ListCreateTaskMassivelysScreen } from './screens/list/list.create.task.massively.screen';
import { CreateTaskMassivelyRoutes } from './createTaskMassively.routes';
import { CreateTaskMassivelySelectActivity } from './screens/selectActivity/create.task.massively.select.activity.screen';

const Navigation = NavigationComponent<CreateTaskMassivelyRoutes>();

export function CreateTaskMassivelySection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListCreateTaskMassivelysScreen} />
      <Navigation.Screen name="createTasks" component={CreateTaskMassivelySelectActivity} />
    </Navigation.Navigator>
  );
}
