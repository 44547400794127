import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { CreateActionProperties } from './create.action.properties';
import { FormActionScreen } from './form.action.screen';

export function CreateActionScreen(properties: CreateActionProperties) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon={CrmSections.tasks.icon}
      prefix="crm.leads.update.actions.create"
    >
      <FormActionScreen
        {...properties}
        clinicId={properties.route.params.clinicId}
        leadId={properties.route.params.leadId}
        started={properties.route.params.started}
      />
    </ModalComponent>
  );
}
