import { MapCrmType } from '@human/crm/types/map.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class MapCrmRepository extends AuthorizedRepository {
  async list(): Promise<{ items: MapCrmType[] }> {
    return this.fetch('map/find', {
      method: 'GET',
      params: {
        limit: 50,
      },
    }).then((data) => {
      return {
        items: data.map((item: any) => ({
          leadId: item.leadId,
          clinicId: item.clinicId,
          contactId: item.contactId,
          contactName: item.contactName,
          contactSurname: item.contactSurname,
          contactLatitude: item.contactLatitude,
          contactLongitude: item.contactLongitude,
          activityId: item.activityId,
          activityName: item.activityName,
          activityColor: item.activityColor,
          activityIcon: item.activityIcon,
          created: item.created,
        })),
      };
    });
  }
}
