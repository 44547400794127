import { ValidationFloatInput } from './float.properties';

export function TextInputValidations(properties: {
  validations: ValidationFloatInput[];
  required?: boolean;
  value?: number;
}) {
  const errors: string[] = [];

  /**
   * movementLimit Validation
   * 1. Must be a integer
   */
  if (properties.validations.includes('floatPositive')) {
    if (typeof properties.value !== 'number') {
      errors.push('valueMustBeAnInteger');
    } else if (Number.isNaN(properties.value)) {
      errors.push('valueMustBeAValidNumber');
    }

    if (properties.validations.includes('floatPositive')) {
      if (!properties.value || properties.value < 0) {
        errors.push('valueMustBePositive');
      }
    }

    if (properties.required && properties.value === null) {
      errors.push('value is required');
    }
  }

  if (properties.validations.includes('price')) {
    if (typeof properties.value !== 'number') {
      errors.push('valueMustBeAnInteger');
    } else if (Number.isNaN(properties.value)) {
      errors.push('valueMustBeAValidNumber');
    }

    if (properties.validations.includes('floatPositive')) {
      if (properties.value && properties.value <= 0) {
        errors.push('valueMustBePositive');
      }
    }

    if (properties.required && properties.value === null) {
      errors.push('value is required');
    }
  }

  return errors;
}
