import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { LeadsRoutes } from '../../leads.routes';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';

export function DisableLeadsScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.leads.disable">
      <FormComponent<{
        reasonId: string;
      }>
        prefix="crm.leads.disable.form"
        padding
        repository={{
          send: (data) => {
            return new LeadCrmRepository()
              .disable({
                leadId: properties.route.params.id,
                clinicId: properties.route.params.clinicId,
                reasonId: data.item.reasonId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'reasonId',
            nullable: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ReasonCrmRepository().pick({ id: properties.id }).then((reason) => {
                return `${reason.name}`;
              });
            },
            repository: ({ search }) =>
              new ReasonCrmRepository()
                .list({
                  search,
                  direction: 'desc',
                  limit: 100,
                  page: 0,
                  active: true,
                })
                .then((reasons) =>
                  reasons.items.map((reason) => ({
                    value: reason.id,
                    label: reason.name,
                  }))
                ),
          },
        ]}
      />
    </ModalComponent>
  );
}
