import { View } from 'react-native';
import { IconComponent } from '../../../../../icon/icon.component';
import { StatusColumnImplementation, StatusColumnProperties } from './status.column.properties';
import { StatusColumnStyles } from './status.column.styles';

export function StatusColumnComponent<T, R>(properties: StatusColumnImplementation<T, R>) {
  const status = properties.renderStatus(properties.item);

  return (
    <View
      style={[
        StatusColumnStyles.container,
        status ? StatusColumnStyles.containerStatus : StatusColumnStyles.containerNoStatus,
      ]}
    >
      <IconComponent
        name={properties.icon || status ? 'check' : 'times'}
        containerstyle={StatusColumnStyles.iconContainer}
        iconStyle={[
          StatusColumnStyles.icon,
          status ? StatusColumnStyles.iconStatus : StatusColumnStyles.iconNoStatus,
        ]}
      />
    </View>
  );
}
