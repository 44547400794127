import { calculatePercentage } from '@utils/percentage/getPercentage.utility';
import { GeneralTableStyles } from '../../general.table.styles';
import { GeneralTableRowProperties } from './general.table.row.properties';
import { View, Text } from 'react-native';

export function GeneralTableRow(properties: GeneralTableRowProperties) {
  return (
    <>
      {/**ENERO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_january}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_january}
          {`(${calculatePercentage(properties.clinicData.total_leads_january, properties.clinicData.total_vi_january)}%)`}
        </Text>
      </View>
      {/**FEBRERO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_february}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_february}
          {`(${calculatePercentage(properties.clinicData.total_leads_february, properties.clinicData.total_vi_february)}%)`}
        </Text>
      </View>
      {/**MARZO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_march}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_march}
          {`(${calculatePercentage(properties.clinicData.total_leads_march, properties.clinicData.total_vi_march)}%)`}
        </Text>
      </View>
      {/**ABRIL */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_april}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_april}
          {`(${calculatePercentage(properties.clinicData.total_leads_april, properties.clinicData.total_vi_april)}%)`}
        </Text>
      </View>
      {/**MAYO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_may}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_may}
          {`(${calculatePercentage(properties.clinicData.total_leads_may, properties.clinicData.total_vi_may)}%)`}
        </Text>
      </View>
      {/**JUNIO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_june}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_june}
          {`(${calculatePercentage(properties.clinicData.total_leads_june, properties.clinicData.total_vi_june)}%)`}
        </Text>
      </View>
      {/**JULIO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_july}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {`(${calculatePercentage(properties.clinicData.total_leads_july, properties.clinicData.total_vi_july)}%)`}
        </Text>
      </View>
      {/**AGOSTO */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_august}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_august}
          {`(${calculatePercentage(properties.clinicData.total_leads_august, properties.clinicData.total_vi_august)}%)`}
        </Text>
      </View>
      {/**SEPTIEMBRE */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_september}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_september}
          {`(${calculatePercentage(properties.clinicData.total_leads_september, properties.clinicData.total_vi_september)}%)`}
        </Text>
      </View>
      {/**OCTUBRE */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_october}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_october}
          {`(${calculatePercentage(properties.clinicData.total_leads_october, properties.clinicData.total_vi_october)}%)`}
        </Text>
      </View>
      {/**NOVIEMBRE */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_november}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_november}
          {`(${calculatePercentage(properties.clinicData.total_leads_november, properties.clinicData.total_vi_november)}%)`}
        </Text>
      </View>
      {/**DICIEMBRE */}
      <View style={GeneralTableStyles.rowContainer}>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_leads_december}
        </Text>
        <Text style={GeneralTableStyles.tableDataText}>
          {properties.clinicData.total_vi_december}
          {`(${calculatePercentage(properties.clinicData.total_leads_december, properties.clinicData.total_vi_december)}%)`}
        </Text>
      </View>
    </>
  );
}
