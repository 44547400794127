import { isString } from '../isString/is.string.validator';

/** check if date is valid */
export function isISODate(value: unknown): value is string {
  return (
    isString(value) &&
    new Date(value) instanceof Date &&
    !isNaN(new Date(value).getTime().valueOf())
  );
}
