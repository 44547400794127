import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';

export function BoardNoDataComponent() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <TextComponent style={{ fontSize: 18 }} text={'board.noData.title'} translate light />
      <TextComponent
        style={{ fontSize: 14, textAlign: 'center' }}
        text={'board.noData.description'}
        translate
        light
      />
    </View>
  );
}
