import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PasswordProfileRepository } from '../../repositories/password/password.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ImageBackground } from 'react-native';

export function ChangePasswordScreen() {
  const { showSuccess } = useSuccessToast();
  return (
    <ImageBackground
      source={require('./../../../../../../../../assets/images/ovoprofile.jpg')}
      style={{ flex: 1, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 10 }}
      resizeMode="cover"
    >
      <ModalComponent transparent icon="lock" prefix="profile.changePassword">
        <FormComponent<any>
          padding
          prefix="profile.changePassword.form"
          repository={{
            send: (data) => {
              if (data.item.password !== data.item.repeatPassword) {
                throw new Error(
                  JSON.stringify({
                    errors: [
                      {
                        field: 'password',
                        code: 'passwordsDoNotMatch',
                      },
                      {
                        field: 'repeatPassword',
                        code: 'passwordsDoNotMatch',
                      },
                    ],
                  })
                );
              }
              return new PasswordProfileRepository()
                .changePassword({ password: data.item.password })
                .then(() => showSuccess());
            },
          }}
          fields={[
            {
              type: 'text',
              name: 'password',
              required: true,
              validations: ['password'],
              description: true,
              secure: true,
            },
            {
              type: 'text',
              name: 'repeatPassword',
              required: true,
              validations: ['password'],
              description: true,
              secure: true,
            },
          ]}
        />
      </ModalComponent>
    </ImageBackground>
  );
}
