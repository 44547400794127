import { FormComponent } from '@components/form/form.component';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { ActivityScopeCrmType } from '@human/crm/types/activityScope.crm.type';
import { NavigationProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';

export function CreateActivityScopeForm(properties: {
  clinicId: string;
  activityId: string;
  navigation: NavigationProp<ActivitiesRoutes>;
}) {
  return (
    <FormComponent<ActivityScopeCrmType & { departmentIds: string[] }>
      prefix={'crm.scopes.create.form'}
      padding
      repository={{
        send: (settings) => {
          return new ActivityCrmRepository()
            .createManyScopes({
              activityId: properties.activityId,
              clinicId: properties.clinicId,
              departmentIds: settings.item.departmentIds!,
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
      }}
      fields={[
        {
          name: 'departmentIds',
          type: 'select',
          required: true,
          multiple: true,
          repository: ({ search }) => {
            return new DepartmentAccessRepository()
              .listByClinic({
                direction: 'asc',
                page: 0,
                limit: 100,
                clinicId: properties.clinicId,
                search,
                active: true,
              })
              .then((response) => {
                return response.items.map((item) => ({
                  label: item.name,
                  value: item.id,
                }));
              });
          },
        },
      ]}
    />
  );
}
