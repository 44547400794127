import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { ScheduleAccessRepository } from '@human/access/repositories/schedule/schedule.repository';
import { ScheduleAccessType } from '@human/access/types/schedule.access.type';

export function CreateScheduleScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'scheduleCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="access.users.update.schedules.create"
    >
      <FormComponent<ScheduleAccessType>
        padding
        prefix="access.users.update.schedules.create.form"
        repository={{
          send: (settings) => {
            return new ScheduleAccessRepository()
              .create({
                userId: properties.route.params.userId,
                timetableId: settings.item.timetableId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'timetableId',
            description: true,
            required: true,
            pick: (props: { id: string }) => {
              return new TimetableAccessRepository()
                .pick({ timetableId: props.id })
                .then((timetable) => {
                  return `${timetable.name}`;
                });
            },
            repository: ({ search }) =>
              new TimetableAccessRepository()
                .list({ search, limit: 100, active: true })
                .then((timetables) =>
                  timetables.items.map((timetable: any) => ({
                    label: timetable.name,
                    value: timetable.id,
                  }))
                ),
          },
        ]}
      />
    </ModalComponent>
  );
}
