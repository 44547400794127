import { NavigationProp, RouteProp } from '@react-navigation/native';

import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PaymentCrmType } from '@human/crm/types/payment.crm.type';
import { PaymentCrmRepository } from '@human/crm/repositories/payment/payment.repository';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';

export function CreatePaymentScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'paymentCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="crm.patients.update.payments.create"
    >
      <FormComponent<PaymentCrmType>
        padding
        prefix="crm.patients.update.payments.create.form"
        repository={{
          send: (settings) => {
            return new PaymentCrmRepository()
              .create({
                item: {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  receiptId: properties.route.params.receiptId,
                  price: settings.item.price,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'float',
            name: 'price',
            required: true,
            validations: ['price'],
          },
        ]}
      />
    </ModalComponent>
  );
}
