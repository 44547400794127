import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReasonsRoutes } from '../../reason.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { ReasonCrmType } from '@human/crm/types/reason.crm.type';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListReasonScreen(properties: {
  navigation: NavigationProp<ReasonsRoutes>;
  route: RouteProp<ReasonsRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.reasons.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }
  const columns: ColumnProperties<ReasonCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.reasons.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          id: row.id,
        });
      },
    },
    {
      type: 'iconText',
      name: 'name',
      color: (row) => row.color,
      text: (row) => row.name,
      icon: (row) => row.icon as IconType,
    },
    {
      type: 'icon',
      renderIcon: () => 'envelope',
      renderColor: () => '#fff',
      renderBackground: (row) => {
        return row.notify === true ? 'green' : 'red';
      },
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.reasons.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent prefix={'crm.reasons.list'} icon={CrmSections.reasons.icon} links={links}>
      <TableComponent<ReasonCrmType, []>
        prefix={'crm.reasons.list'}
        suffix={'list'}
        repository={(settings) =>
          new ReasonCrmRepository().list({
            direction: settings.direction,
            search: settings.search,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
