import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ItemByDepartmentYearsComparativeType } from '../../../../types/itemByDepartmentYearsComparative.type';
import { ChartData } from 'chart.js';

export function ExpectedInterventionsTwoYearLine(properties: {
  data: ItemByDepartmentYearsComparativeType[];
  year: number;
}) {
  const colors = {
    'Garantias (Primer intento)': '#A5A5A5',
    'Perdidas (+1 intento)': '#FF3B3A',
    Total: '#333333',
  };

  const comparingRows = Object.values(
    properties.data
      .filter(
        (row) =>
          row.department_name === 'Garantias (Primer intento)' ||
          row.department_name === 'Perdidas (+1 intento)' ||
          row.department_name === 'Total'
      )
      .reduce(
        (acc, item) => {
          const { department_name, year, total } = item;

          // Crear una clave única para cada combinación de department_name y year
          const key = `${department_name}-${year}`;

          // Si ya existe esa clave, sumamos el total actual
          if (acc[key]) {
            acc[key].total += total;
          } else {
            // Si no existe, lo inicializamos con el total y año
            acc[key] = { department_name, year, total };
          }

          return acc;
        },
        {} as {
          [key: string]: {
            department_name: string;
            year: number;
            total: number;
          };
        }
      )
  );

  const years = Array.from({ length: 2 }, (_, i) => properties.year - 1 + i);

  const legend = new Set(comparingRows.map((department) => department.department_name));

  const data: ChartData<'line', number[], string> = {
    labels: years.map((year) => year.toString()),
    datasets: Array.from(legend).map((departmentName) => {
      return {
        label: departmentName,
        data: years.map((year) => {
          const row = comparingRows.find(
            (row) => row.year === year && row.department_name === departmentName
          );
          return row ? row.total : 0;
        }),
        fill: false,
        borderColor: colors[departmentName as keyof typeof colors],
        backgroundColor: colors[departmentName as keyof typeof colors],
      };
    }),
  };

  return (
    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 4, flex: 1 }}>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'start',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              borderWidth: 4, // Grosor de la línea
            },
            point: {
              radius: 6, // Tamaño de los puntos
              hoverRadius: 8, // Tamaño de los puntos al hacer hover
            },
          },
        }}
      />
    </View>
  );
}
