import { createDrawerNavigator } from '@react-navigation/drawer';
import { ProfileRoutes } from './profile.routes';
import { View } from 'react-native';
import { MenuComponent } from '@components/menu/menu.component';
import { ProfileSections } from './profile.sections';
import { AccountScreen } from './sections/account/account.screen';
import { AuthorizedSpaces } from '../../authorized.spaces';
import { RouteProp } from '@react-navigation/native';
import { useDeviceHook } from '@hooks/device/device.hook';
import { HeaderComponent } from '@components/menu/components/header/header.component';
import { ChangePasswordScreen } from './sections/password/changePassword.screen';

const Drawer = createDrawerNavigator<ProfileRoutes>();
export function ProfileSpace(properties: { route: RouteProp<AuthorizedSpaces, 'profile'> }) {
  const { phone, tablet } = useDeviceHook();

  return (
    <View style={{ flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
      <Drawer.Navigator
        drawerContent={(props) => {
          return (
            <MenuComponent brand="profile" {...props} sections={ProfileSections} type="profile" />
          );
        }}
        screenOptions={{
          headerTitle: () => <HeaderComponent type={'profile'} brand={'human'} />,
          drawerType: phone || tablet ? 'front' : 'permanent',
          swipeEnabled: phone || tablet,
          drawerStyle: {
            width: phone ? 300 : 400,
          },
          headerTintColor: 'rgb(237, 53, 145)',
          headerShown: phone || tablet,
        }}
      >
        <Drawer.Screen name="account" component={AccountScreen} />
        <Drawer.Screen name="changePassword" component={ChangePasswordScreen} />
      </Drawer.Navigator>
    </View>
  );
}
