import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CauseRoutes } from '../../cause.routes';
import { FormComponent } from '@components/form/form.component';
import { CauseCrmType } from '@human/crm/types/cause.crm.type';
import { CauseCrmRepository } from '@human/crm/repositories/cause/cause.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateCauseScreen(properties: {
  navigation: NavigationProp<CauseRoutes>;
  route: RouteProp<CauseRoutes, 'causeUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="tasks"
      width={660}
      flex={false}
      prefix="crm.causes.update"
    >
      <FormComponent<CauseCrmType>
        padding
        prefix={'crm.causes.update.form'}
        readonly={!hasPermissionUtilty({ name: 'crm.causes.editor' })}
        repository={{
          get: () => {
            return new CauseCrmRepository().pick({
              causeId: properties.route.params.causeId,
              phaseId: properties.route.params.phaseId,
            });
          },
          send: (settings) => {
            return new CauseCrmRepository()
              .update({
                item: settings.item,
                causeId: properties.route.params.causeId,
                phaseId: properties.route.params.phaseId,
              })
              .then(() => {
                showSuccess();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['title'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            required: true,
            validations: ['description'],
            lines: 6,
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
            required: true,
          },
          {
            name: 'color',
            type: 'color',
            description: true,
            required: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
