import { NavigationProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../contacts.routes';
import { ModalComponent } from '@components/modal/modal.component';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvobankContactForm } from './form/ovobankContact.form';
import { ContactForm } from './form/contact.form';

export function CreateContactScreen(properties: { navigation: NavigationProp<ContactsRoutes> }) {
  const form = Workspace.select({
    ovobank: OvobankContactForm(properties),
    ovoclinic: ContactForm(properties),
  });

  return (
    <ModalComponent multiWorkspace icon="user" prefix="crm.contacts.create">
      {form}
    </ModalComponent>
  );
}
