import { PermissionType } from 'types/permission.type';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface UserState {
  user: {
    id: string;
    sessionId: string;
    accessToken: string;
    expires: Date;
    selfie?: string;
    name?: string;
    surname?: string;
    email?: string;
    username?: string;
    idioms: string[];
    selectedIdiom: string;
  };

  permissions: PermissionType;

  login: (properties: { user: UserState['user']; permissions: UserState['permissions'] }) => void;
  logout: () => void;
  update: (properties: {
    user: Partial<UserState['user']>;
    permissions: Partial<UserState['permissions']>;
  }) => void;
}

export const useUserState = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        login: (properties) =>
          set({
            user: properties.user,
            permissions: properties.permissions,
          }),
        logout: () => {
          set({
            user: {
              id: '',
              sessionId: '',
              accessToken: '',
              expires: new Date(),
              idioms: [],
              selectedIdiom: 'es',
            },
            permissions: {},
          });
        },
        update: (properties: {
          user: Partial<UserState['user']>;
          permissions: Partial<UserState['permissions']>;
        }) =>
          set((state) => ({
            user: {
              ...state.user,
              ...properties.user,
            },
            permissions: {
              ...state.permissions,
              ...properties.permissions,
            },
          })),
        user: {
          id: '',
          sessionId: '',
          accessToken: '',
          expires: new Date(),
          idioms: [],
          selectedIdiom: 'es',
        },
        permissions: {},
      }),
      { name: 'userStore', storage: createJSONStorage(() => AsyncStorage) }
    )
  )
);
