import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { PhasesRoutes } from '../../phase.routes';

export function DisablePhaseScreen(properties: {
  navigation: NavigationProp<PhasesRoutes>;
  route: RouteProp<PhasesRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.phases.disable">
      <FormComponent
        prefix="crm.phases.disable.form"
        padding
        repository={{
          send: () => {
            return new PhaseCrmRepository()
              .disable({
                phaseId: properties.route.params.phaseId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
