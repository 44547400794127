import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ReceiptCrmRepository } from '@human/crm/repositories/receipt/receipt.repository';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';

export function CreateReceiptScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'receiptCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="crm.patients.update.receipts.create"
    >
      <FormComponent<{}>
        padding
        prefix="crm.patients.update.receipts.create.form"
        repository={{
          send: () => {
            return new ReceiptCrmRepository()
              .create({
                item: {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
