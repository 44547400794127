import { CampaignsRoutes } from './campaigns.routes';
import { ListCampaignScreen } from './screens/list/list.campaign.screen';
import { CreateCampaignScreen } from './screens/create/create.campaign.screen';
import { DisableCampaignScreen } from './screens/disable/disable.campaign.screen';
import { UpdateCampaignScreen } from './screens/update/update.campaign.screen';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { CreateManagerCampaignScreen } from './screens/update/parts/create.manager.campaign.screen';
import { DeleteManagerCampaignPart } from './screens/update/parts/delete.manager.campaign.update.part';
import { EnableCampaignScreen } from './screens/enable/enable.campaign.sceen';
import { CreateParticipantCampaignScreen } from './screens/update/parts/create.participant.campaign.screen';
import { DeleteParticipantCampaignPart } from './screens/update/parts/delete.participant.campaign.screen';
import { DeleteCampaignScreen } from './screens/delete/delete.campaign.screen';

const Navigation = NavigationComponent<CampaignsRoutes>();
export function CampaignsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="campaignList" component={ListCampaignScreen} />
      <Navigation.Screen name="campaignCreate" component={CreateCampaignScreen} />
      <Navigation.Screen name="campaignDisable" component={DisableCampaignScreen} />
      <Navigation.Screen name="campaignEnable" component={EnableCampaignScreen} />
      <Navigation.Screen name="campaignUpdate" component={UpdateCampaignScreen} />
      <Navigation.Screen name="campaignDelete" component={DeleteCampaignScreen} />
      <Navigation.Screen name="createManager" component={CreateManagerCampaignScreen} />
      <Navigation.Screen name="deleteManager" component={DeleteManagerCampaignPart} />
      <Navigation.Screen name="createParticipant" component={CreateParticipantCampaignScreen} />
      <Navigation.Screen name="deleteParticipant" component={DeleteParticipantCampaignPart} />
    </Navigation.Navigator>
  );
}
