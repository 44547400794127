import { LicenseAccessType } from '@human/access/types/license.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class LicenseAccessRepository extends AuthorizedRepository {
  async list(properties: { groupId: string; userId: string; search?: string }): Promise<{
    items: LicenseAccessType[];
    total: number;
    search?: string;
  }> {
    return this.fetch('license/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((license: any) => ({
        id: license.id,
        roleId: license.role.id,
        role: {
          name: license.role.name,
        },
      })),
      total: data.total,
    }));
  }

  async create(
    properties: Partial<{
      roleId: string;
      userId: string;
    }>
  ): Promise<Partial<LicenseAccessType>> {
    return this.fetch('license/create', {
      method: 'POST',
      body: {
        roleId: properties.roleId,
        userId: properties.userId,
      },
    }).then((license) => ({
      id: license.id,
    }));
  }

  async delete(properties: { licenseId: string; userId: string }): Promise<void> {
    return this.fetch('license/delete', {
      method: 'DELETE',
      body: {
        licenseId: properties.licenseId,
        userId: properties.userId,
      },
    }).then(() => {});
  }
}
