// Modules
import { StyleSheet } from 'react-native';

/**
 * Tabs Styles
 * @constant {StyleSheet} TabsStyles
 */
export const TabsStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabContainer: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    backgroundColor: '#ccc',
    height: 50,
    maxHeight: 50,
    overflowX: 'scroll',
  },
  tabContainerContent: {
    gap: 5,
  },
  tabIndicator: {
    height: 0,
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  tab: {
    padding: 7,
    borderBottomWidth: 4,
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    gap: 7,
    paddingLeft: 10,
    paddingRight: 15,
    backgroundColor: '#ddd',
  },
  largeTabsInactive: {
    paddingLeft: 15,
  },
  tabActive: {
    backgroundColor: '#eee',
    borderBottomColor: '#eee',
  },
  tabInactive: {
    backgroundColor: '#ddd',
    borderBottomColor: '#ddd',
  },
  tabIcon: {
    fontSize: 20,
    fontWeight: 'bold',
    opacity: 0.5,
  },
  tabIconActive: {
    color: 'rgb(237, 53, 145)',
  },
  tabIconInactive: {
    color: '#777',
  },
  tabText: {
    textAlign: 'center',
  },
  tabTextActive: {
    color: '#333',
  },
  tabTextInactive: {
    color: '#777',
  },
  content: {
    padding: 20,
    gap: 20,
    flex: 1,
    backgroundColor: '#eee',
  },
  description: {
    backgroundColor: '#fff',
    padding: 20,
    paddingVertical: 14,
    borderLeftColor: 'rgb(129, 59, 94)',
    borderLeftWidth: 4,
    maxWidth: 450,
  },
  descriptionMobile: {
    padding: 10,
    fontSize: 10,
  },
});
