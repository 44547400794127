import { NavigationProp } from '@react-navigation/native';
import { ProgramsRoutes } from '../../../../programs.routes';
import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
import { ScrollView } from 'react-native';

export function PhasesUpdateProgramPart(properties: {
  programId: string;
  navigation: NavigationProp<ProgramsRoutes>;
}) {
  return (
    <ScrollView>
      <TableComponent<
        { phaseId: string; phaseName: string; phaseDescription: string; phaseCreated: Date },
        []
      >
        key={'phases'}
        prefix={'crm.programs.update.phases.list'}
        suffix={'list'}
        repository={(settings) =>
          new ProgramPhaseCrmRepository().list({
            programId: properties.programId,
            search: settings.search,
          })
        }
        columns={[
          {
            type: 'icon',
            icon: 'trash',
            onPress: (row) => {
              properties.navigation.navigate('programPhaseDelete', {
                programId: properties.programId,
                phaseId: row.phaseId,
                level: 1,
              });
            },
          },
          {
            type: 'text',
            name: 'phaseName',
            width: 200,
            onRender: (row) => row.phaseName,
          },
          {
            type: 'date',
            name: 'phaseCreated',
            renderDate: (row) => row.phaseCreated,
          },
        ]}
      />
      <ButtonComponent
        icon="plus"
        prefix="crm.programs.update.phases.create.title"
        onPress={() => {
          properties.navigation.navigate('programPhaseCreate', {
            programId: properties.programId,
            level: 1,
          });
        }}
      />
    </ScrollView>
  );
}
