import { TabType } from '@components/tabs/tabs.types';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ActionsRoutes } from '../../../actions.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { useEffect, useState } from 'react';
import { addDays } from '@utils/addDays/addDays.utility';
import { ButtonComponent } from '@components/button/button.component';
import { View } from 'react-native';
import { UpdateActionActionEndingStyles } from './update.action.actionEnding.styles';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ActivityCommunicationCrmType } from '@human/crm/types/activityCommunicationChannel.type';
import { ActivityCommunicationChannelCrmRepository } from '@human/crm/repositories/activityCommunicationChannel/activityCommunicationChannel.repository';

export function UpdateActionEndingPart(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionUpdate'>;
}): TabType {
  const [action, setAction] = useState<Partial<ActionCrmType>>();
  const [commsChannel, setCommsChannel] = useState<ActivityCommunicationCrmType[]>([]);

  useEffect(() => {
    new ActionCrmRepository()
      .pick({
        actionId: properties.route.params.actionId,
        clinicId: properties.route.params.clinicId,
        leadId: properties.route.params.leadId,
      })
      .then((action) => {
        new ActivityCommunicationChannelCrmRepository()
          .list({
            activityId: action.activityId ?? '',
            limit: 100,
            page: 0,
          })
          .then((commsChannel) => {
            setCommsChannel(commsChannel.items);
            if (commsChannel.items.length > 0 && !action.communicationChannelId) {
              setAction({
                ...action,
                communicationChannelId: commsChannel.items[0].channelId,
              });
              return;
            }

            setAction(action);
          });
      });
  }, []);

  const handleSubmit = () => {
    new ActionCrmRepository()
      .addResult({
        actionId: properties.route.params.actionId,
        clinicId: properties.route.params.clinicId,
        leadId: properties.route.params.leadId,
        resultId: action?.resultId ?? '',
        communicationChannelId: action?.communicationChannelId,
      })
      .then((action) => {
        properties.navigation.goBack();
        if (action.templateId) {
          properties.navigation.navigate('updateActionTemplate', {
            templateId: action.templateId,
            contactId: action.contactId,
            actionId: action.id,
            activityId: action.nextActivityId,
            level: properties.route.params.level,
            nextActivityDelay: action.nextActivityDelay,
            nextActivityId: action.nextActivityId,
            clinicId: action.clinicId,
            leadId: action.leadId,
            responsibleId: action.responsibleId,
            landbotId: action.landbotId,
          });
          return;
        }

        if (action.appointed) {
          properties.navigation.navigate('updateActionAddSpecialist', {
            clinicId: action.clinicId,
            leadId: action.leadId,
            nextActivityDelay: action.nextActivityDelay,
            nextActivityId: action.nextActivityId,
            actionId: action.id,
            level: properties.route.params.level,
            started: action.nextActivityId
              ? addDays(new Date(), action.nextActivityDelay)
              : new Date(),
          });
          return;
        }
        if (action.disableLead) {
          properties.navigation.navigate('actionDisableLead', {
            actionId: action.id,
            activityId: action.nextActivityId,
            level: properties.route.params.level,
            nextActivityDelay: action.nextActivityDelay,
            nextActivityId: action.nextActivityId,
            clinicId: action.clinicId,
            leadId: action.leadId,
          });
          return;
        }
        if (action.converted) {
          properties.navigation.navigate('updateActionCreateTreatment', {
            clinicId: action.clinicId,
            patientId: action.patientId,

            actionId: action.id,
            leadId: action.leadId,
            level: properties.route.params.level + 1,
            activityId: action.nextActivityId,
            started: action.nextActivityId
              ? addDays(new Date(), action.nextActivityDelay)
              : new Date(),
          });
          return;
        }

        if (action.nextActivityId) {
          properties.navigation.navigate('actionNextResultUpdate', {
            actionId: action.id,
            clinicId: action.clinicId,
            leadId: action.leadId,
            level: properties.route.params.level,
            activityId: action.nextActivityId,
            started: action.nextActivityId
              ? addDays(new Date(), action.nextActivityDelay)
              : new Date(),
          });
        }
      });
  };

  return {
    name: 'actionEnding',
    icon: 'check-circle',
    content: () => (
      <>
        <FieldContainer
          prefix="crm.leads.update.actions.update.actionEnding.form"
          name={'result'}
          type="select"
          description
          required
        >
          <SelectInput<{ resultId: string }>
            type={'select'}
            name="resultId"
            required
            readonly={!hasPermissionUtilty({ name: 'crm.tasks.editor' })}
            value={{
              resultId: action?.resultId,
            }}
            pick={(settings) => {
              return new ResultCrmRepository()
                .pick({
                  resultId: action?.resultId ?? '',
                  activityId: properties.route.params.activityId,
                })
                .then((result) => result.name);
            }}
            description
            onChange={(value, option) => {
              if (!value.resultId) return;
              setAction({
                ...action,
                resultId: value.resultId,
              });
            }}
            repository={(settings) => {
              return new ResultCrmRepository()
                .findByActivity({
                  activityId: properties.route.params.activityId,
                  direction: 'asc',
                  limit: 100,
                  page: 0,
                  search: settings.search,
                  active: true,
                })
                .then((results) =>
                  results.items.map((result) => ({
                    label: result.name,
                    value: result.id,
                  }))
                );
            }}
          />
        </FieldContainer>
        {commsChannel.length > 1 && (
          <FieldContainer
            prefix="crm.leads.update.actions.update.actionEnding.form"
            name={'communicationChannel'}
            type="select"
            description
            required
          >
            <SelectInput<{ channel: string }>
              type={'select'}
              name="channel"
              required
              value={{
                channel: action?.communicationChannelId ?? '',
              }}
              description
              onChange={(value, option) => {
                if (!value.channel) return;
                setAction({
                  ...action,
                  communicationChannelId: value.channel,
                });
              }}
              pick={async ({ id }) => {
                return commsChannel.find((channel) => channel.channelId === id)?.channelName ?? '';
              }}
              repository={async (settings) => {
                return commsChannel.map((channel) => ({
                  label: channel.channelName,
                  value: channel.channelId,
                }));
              }}
            />
          </FieldContainer>
        )}
        <View style={UpdateActionActionEndingStyles.submitContainer}>
          <ButtonComponent
            icon="plus"
            prefix="crm.leads.update.actions.update.actionEnding.form.submitNextSuggestion"
            onPress={() => handleSubmit()}
          />
        </View>
      </>
    ),
  };
}
