/**
 * Comparison Type
 * @description Comparison type for database queries
 */
export enum ComparisonEnum {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  LT = 'lt',
  GT = 'gt',
  LTE = 'lte',
  GTE = 'gte'
}