// Modules
import { StyleSheet } from 'react-native';

/**
 * Bubble Styles
 * @constant {StyleSheet} BubbleStyles
 */
export const BubbleStyles = StyleSheet.create({
  container: {
    height: 46,
    width: 46,
    borderRadius: 210,
    alignContent: 'center',
    justifyContent: 'center',
    borderColor: 'rgba(255, 255, 255, 0.4)',
    alignItems: 'center',
  },
  containerPrimary: {
    backgroundColor: 'rgb(237, 53, 145)',
  },
  containerSecondary: {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  containerPrimaryBorder: {
    borderWidth: 4,
  },
  containerSecondaryBorder: {
    borderWidth: 0,
  },
  counterText: {
    color: '#fff',
    fontSize: 9,
    position: 'absolute',
    top: -6,
    right: -6,
    backgroundColor: 'rgb(237, 53, 145)',
    height: 22,
    width: 22,
    borderRadius: 122,
    textAlign: 'center',
    lineHeight: 20,
    fontWeight: 'bold',
  },
  icon: {
    color: '#fff',
    fontSize: 24,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  textPrimary: {
    color: '#fff',
  },
  textSecondary: {
    color: 'rgb(237, 53, 145)',
  },
  iconCheckContainer: {
    position: 'absolute',
    bottom: -16,
    height: 25,

    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    width: 25,
    left: 7,
    borderWidth: 2,
    borderColor: 'rgba(255, 255, 255, 0.2)',
    backgroundColor: 'rgb(18, 11, 31)',
    borderRadius: 100,
  },
  iconCheckStyle: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'rgb(237, 53, 145)',
  },
});
