import { ErrorComponent } from '@components/error/error.component';
import { ErrorsComponent } from '@components/form/components/fields/inputs/errors/errors.component';

export function ExceptionsComponent(properties: {
  name: any;
  prefix: string;
  exceptions: {
    field: string;
    code: string;
  }[];
}) {
  let filter = properties.exceptions?.filter?.((exception) => exception.field === properties.name);

  // TODO: Por alguna razon esta llegando un objeto en lugar de un array. Esto es un parche para evitar que se rompa la aplicacion
  if (!filter) {
    let errors = properties.exceptions as any;
    errors = errors.errors;
    if (!errors) return null;
    if (!Array.isArray(errors)) return null;
    filter = errors.filter((exception) => exception.field === properties.name);
  }

  if (!filter) return null;
  return (
    <>
      <ErrorsComponent
        prefix={properties.prefix}
        errors={filter.map((exception) => exception.code)}
      />
    </>
  );
}
