import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { ClinicAccessType } from '../../../../types/clinic.access.type';
import { ClinicsRoutes } from '../../clinics.routes';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListClinicsScreen(properties: {
  navigation: NavigationProp<ClinicsRoutes>;
  route: RouteProp<ClinicsRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.clinics.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }
  const { phone } = useDeviceHook();

  const columns: ColumnProperties<ClinicAccessType, ['name']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.clinics.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', { clinicId: row.id });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      order: 'name',
      onRender: (row) => row.name,
    },
    {
      name: 'city',
      type: 'text',
      width: 200,
      onRender: (row) => row.city,
    },
    {
      name: 'document',
      type: 'text',
      width: 200,
      onRender: (row) => row.document,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.clinics.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          clinicId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={AccessSections.clinics.icon} prefix={'access.clinics.list'} links={links}>
      <TableComponent<ClinicAccessType, ['name']>
        prefix={'access.clinics.list'}
        suffix={'list'}
        search
        scrollable={phone}
        repository={(properties) => {
          return new ClinicAccessRepository().list({
            direction: properties.direction,
            page: properties.page,
            limit: properties.limit,
            search: properties.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
