import { SplashProperties } from './splash.properties';
import { SplashWebStyles } from './splash.styles.web';

/**
 * SplashComponent
 * @description SplashComponent renders a splash screen with a video background.
 *
 * @param {SplashProperties} properties - The properties object containing the type of the splash screen.
 *
 * @returns {JSX.Element} The rendered JSX element containing the splash screen.
 *
 * @example
 * <SplashComponent type="horse" />
 *
 */

export function SplashComponent() {
  const getVideo = () => {
    switch (process.env.EXPO_PUBLIC_WORKSPACE) {
      case 'ovoclinic':
        return require('../../../assets/videos/clinic.mp4');
      case 'ovobank':
        return require('../../../assets/videos/ovobank.mp4');
      default:
        return require('../../../assets/videos/horse.mp4');
    }
  };

  return (
    <video
      style={SplashWebStyles}
      src={getVideo()}
      autoPlay
      loop
      muted
      controls={false}
      playsInline
    ></video>
  );
}
