import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../departments.routes';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccessSections } from '@human/access/access.sections';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { DepartmentAccessType } from '@human/access/types/department.access.type';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { ListDepartmentMemberPart } from './parts/list.department.member.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <ModalComponent
      icon={AccessSections.departments.icon}
      flex={false}
      prefix="access.departments.update"
      width={700}
      level={properties.route.params.level}
    >
      <TabsComponent
        prefix="access.departments.update"
        tabs={[
          {
            icon: AccessSections.departments.icon,
            name: 'department',
            content: (settings) => (
              <FormComponent<DepartmentAccessType>
                key={'department'}
                onChange={(data) => {
                  if (!data.isLocal) return;
                  settings?.setIsEditing(true);
                }}
                readonly={!hasPermissionUtilty({ name: 'access.departments.editor' })}
                prefix="access.departments.update.form"
                repository={{
                  get: () => {
                    return new DepartmentAccessRepository().pick({
                      id: properties.route.params.id,
                      clinicId: properties.route.params.clinicId,
                    });
                  },
                  send: (data) => {
                    return new DepartmentAccessRepository()
                      .update({
                        item: data.item,
                      })
                      .then(() => {
                        settings?.setIsEditing(false);
                        showSuccess();
                      });
                  },
                }}
                fields={[
                  {
                    type: 'text',
                    name: 'name',
                    description: true,
                    required: true,
                    validations: ['name'],
                  },
                  {
                    type: 'select',
                    name: 'languageId',
                    description: true,
                    required: true,
                    pick: (properties: { id: string }) => {
                      return new LanguageAccessRepository()
                        .pick({ languageId: properties.id })
                        .then((language) => {
                          return `${language.countryNameEs}`;
                        });
                    },
                    repository: ({ search }) =>
                      new LanguageAccessRepository()
                        .list({
                          order: 'name',
                          direction: 'asc',
                          page: 0,
                          limit: 100,
                          search,
                          active: true,
                        })
                        .then((languages) =>
                          languages.items.map((language) => ({
                            value: language.id,
                            label: language.name,
                          }))
                        ),
                  },
                  {
                    type: 'select',
                    name: 'responsibleId',
                    description: true,
                    required: true,
                    pick: (settings: { id: string }) => {
                      return new CoworkerCrmRepository()
                        .pick({
                          clinicId: properties.route.params.clinicId,
                          coworkerId: settings.id,
                        })
                        .then((coworker) => {
                          return `${coworker.name} ${coworker.surname}`;
                        });
                    },
                    repository: ({ search }) =>
                      new CoworkerCrmRepository()
                        .list({
                          direction: 'asc',
                          page: 0,
                          limit: 100,
                          order: 'name',
                          clinicId: properties.route.params.clinicId,
                          search,
                          active: true,
                        })
                        .then((users) =>
                          users.items.map((user) => ({
                            value: user.id,
                            label: `${user.name} ${user.surname}`,
                          }))
                        ),
                  },
                ]}
              />
            ),
          },
          {
            icon: 'users',
            name: 'members',
            content: () => (
              <ListDepartmentMemberPart
                navigation={properties.navigation}
                route={properties.route}
              />
            ),
          },
        ]}
      />
    </ModalComponent>
  );
}
