import { NavigationComponent } from '@components/navigation/navigation.component';
import { UpdateDepartmentScreen } from './screens/update/update.department.screen';
import { DepartmentsRoutes } from './departments.routes';
import { ListDepartmentScreen } from './screens/list/list.department.screen';
import { DisableDepartmentScreen } from './screens/disable/disable.department.screen';
import { EnableDepartmentScreen } from './screens/enable/enable.department.screen';
import { CreateMemberScreen } from './screens/members/create/create.member.screen';
import { DeleteMemberScreen } from './screens/members/delete/delete.member.screen';

const Navigation = NavigationComponent<DepartmentsRoutes>();

export function DepartmentsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListDepartmentScreen} />
      <Navigation.Screen name="departmentUpdate" component={UpdateDepartmentScreen} />
      <Navigation.Screen name="departmentDisable" component={DisableDepartmentScreen} />
      <Navigation.Screen name="departmentEnable" component={EnableDepartmentScreen} />
      <Navigation.Screen name="memberCreate" component={CreateMemberScreen} />
      <Navigation.Screen name="memberDelete" component={DeleteMemberScreen} />
    </Navigation.Navigator>
  );
}
