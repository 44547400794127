import { AvatarUploaderComponent } from '@components/avatarUploader/avatarUploader.component.web';
import { AvatarInputImplementation } from './avatar.implementation';

export function AvatarInput<ITEM>(properties: AvatarInputImplementation<ITEM>) {
  return (
    <AvatarUploaderComponent
      onSubmit={async (file) => {
        const key = properties.name;

        properties.onChange({
          ...properties.value,
          [key]: file,
        });
      }}
      file={properties.value[properties.name] as string}
    />
  );
}
