import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { PurchaseCrmRepository } from '@human/crm/repositories/purchase/purchase.repository';
import { PurchaseCrmType } from '@human/crm/types/purchase.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PurchasesRoutes } from '../../purchases.routes';

export function UpdatePurchaseScreen(properties: {
  navigation: NavigationProp<PurchasesRoutes>;
  route: RouteProp<PurchasesRoutes, 'updatePurchase'>;
}) {
  return (
    <ModalComponent icon="money-bill" flex={false} prefix="crm.purchases.update">
      <FormComponent<PurchaseCrmType>
        key={'purchase'}
        prefix="crm.purchases.update.form"
        padding
        repository={{
          send: (data) => {
            return new PurchaseCrmRepository()
              .update({
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                purchaseId: properties.route.params.purchaseId,
                price: data.item.price,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
          get: () => {
            return new PurchaseCrmRepository().pick({
              purchaseId: properties.route.params.purchaseId,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
            });
          },
        }}
        fields={[
          {
            type: 'float',
            name: 'price',
            description: true,
            validations: ['price'],
          },
        ]}
      />
    </ModalComponent>
  );
}
