import { View, Text, useWindowDimensions } from 'react-native';
import { LeadComparativeChartProperties } from './leadComparativeChart.properties';
import { NewLeadsByDepartmentChart } from '../../../components/newLeadsByDepartmentChart/newLeadsByDepartmentChart';
import { TwoYearColumnLeadsCirle } from '../../../components/twoYearColumnLeadsCirle/twoYearColumnLeadsCircle';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '@components/loader/loader.component';
import { BoardStyles } from '../../../../common/board.styles';

export function LeadComparativeChart(properties: LeadComparativeChartProperties) {
  const { t } = useTranslation();

  return (
    <View style={BoardStyles.graphicsContainer}>
      <View
        style={{
          gap: 30,
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 5,
          flex: 2,
          minHeight: 500,
        }}
      >
        <View>
          <Text style={BoardStyles.graphicTitle}>
            {t('board.leads.totalLeads.comparativeGraphicTitle')}
          </Text>
        </View>

        {properties.clinicId && properties.languagueId && !properties.loading ? (
          <NewLeadsByDepartmentChart
            selectedDepartment={properties.languagueId}
            departmentName={
              properties.languages.find((language) => language.value === properties.languagueId)
                ?.label!
            }
            firstYear={properties.optionA}
            secondYear={properties.optionB}
            data={properties.data}
          />
        ) : (
          <LoaderComponent loading={true} />
        )}
      </View>
      <View
        style={{
          gap: 30,
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 5,
          flex: 1,
          minHeight: 500,
        }}
      >
        <View>
          <Text style={BoardStyles.graphicTitle}>
            {' '}
            {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
          </Text>
        </View>

        {properties.clinicId && properties.languagueId && !properties.loading ? (
          <TwoYearColumnLeadsCirle
            selectedDepartment={properties.languagueId}
            departmentName={
              properties.languages.find((language) => language.value === properties.languagueId)
                ?.label as string
            }
            firstYear={properties.optionA}
            secondYear={properties.optionB}
            data={properties.data}
          />
        ) : (
          <LoaderComponent loading={true} />
        )}
      </View>
    </View>
  );
}
