import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { TimetableAccessType } from '@human/access/types/timetable.access.type';
import { useTranslation } from 'react-i18next';
import { TimetableRoutes } from '../../timetables.routes';

export function CreateTimetableScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'create'>;
}) {
  const { t } = useTranslation();
  return (
    <ModalComponent icon="lock" prefix="access.timetables.create">
      <FormComponent<TimetableAccessType>
        padding
        prefix="access.timetables.create.form"
        repository={{
          send: (settings) => {
            return new TimetableAccessRepository()
              .create({
                item: {
                  ...settings.item,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            required: true,
            description: true,
            validations: ['name'],
          },
        ]}
      />
    </ModalComponent>
  );
}
