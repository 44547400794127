import { TouchableOpacity, View } from 'react-native';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { IconComponent } from '@components/icon/icon.component';
import { FilterCheckBoxInputStyle } from './filterCheckBox.input.styles';
import { TextComponent } from '@components/text/text.component';
import { FilterCheckBoxInputImplementation } from './filterCheckBox.input.implementation';

export function FilterCheckBoxInput(properties: FilterCheckBoxInputImplementation) {
  const value = properties.value as boolean;

  const onChange = (checked: boolean) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: checked,
    };
    properties.onChange(newValue);
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          onChange(!value);
        }}
        style={FilterCheckBoxInputStyle.container}
      >
        <TextComponent
          translate
          text={`${properties.prefix}.${properties.title}`}
          style={[FilterCheckBoxInputStyle.label, FilterCheckBoxInputStyle.labelNoIcon]}
        />
        <View style={FilterCheckBoxInputStyle.checkBoxContainer}>
          <View
            style={[
              FilterCheckBoxInputStyle.checkBoxCheckContainer,
              // value
              //   ? FilterCheckBoxInputStyle.checkBoxCheckActive
              //   :
              FilterCheckBoxInputStyle.checkBoxCheckInactive,
            ]}
          >
            <View
              style={[
                FilterCheckBoxInputStyle.checkBoxCheckIconContainer,
                value
                  ? FilterCheckBoxInputStyle.checkBoxCheckIconActive
                  : FilterCheckBoxInputStyle.checkBoxCheckIconInactive,
              ]}
            >
              <IconComponent
                name={value ? 'check' : 'times'}
                containerstyle={{
                  height: 20,
                  width: 20,
                }}
                iconStyle={{
                  color: 'white',
                  fontSize: 14,
                  textAlign: 'center',
                  lineHeight: 20,
                }}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
