import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BoardRoutes } from '../../../board.routes';
import { useTranslation } from 'react-i18next';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import { useEffect, useState } from 'react';
import { ClinicAccessType } from '@human/access/types/clinic.access.type';
import { EligibleNoEligibleComparationType } from '../components/eligibleChart/eligible.type';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { MarketingOvobankRepository } from '../../../repositories/marketing/marketing.ovobank.repository';
import { MarketingBoardRepository } from '../../../repositories/marketing/marketing.repository';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { LoaderComponent } from '@components/loader/loader.component';
import { SceneComponent } from '@components/scene/scene.component';
import { BoardHeaderComponent } from '../../../components/header/board.header.component';
import { SaleFunnelStyles } from '../sale.funnel.styles';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { LeadsScreenStyles } from '../../leads/leads.screen.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { SaleFunnelPerDepartmentTable } from '../components/saleFunnelPerDepartmentTable/saleFunnelPerDepartmentTable.component';
import { SaleFunnelPerDepartmentChart } from '../components/saleFunnelPerDepartmentChart/saleFunnelPerDepartmentChart.component';
import { EligibleChartComponent } from '../components/eligibleChart/eligibleChart.component';
import { BarChartComponent } from '../components/barChart/barChart.component';
import { leadperdepartmentandmonth } from '../sale.funnel.screen.web';
import { colors } from '../../../components/components.utils';

export function OvobankFunnelsScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'saleFunnel'>;
}) {
  const { t } = useTranslation();
  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  const [month, setMonth] = useState<number>(new Date().getMonth());

  const [clinics, setClinics] = useState<ClinicAccessType[]>();
  const [clinicId, setClinicId] = useState<string>();
  const [languages, setLanguages] = useState<
    {
      label: string;
      value: string;
    }[]
  >();
  // Con esto se selecciona el departamento en ovobank
  // Que en realidad son clinicas
  const [clinicOptions, setClinicOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >();
  const [languageId, setLanguageId] = useState<string>();

  const [data, setData] = useState<leadperdepartmentandmonth[]>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [departmentData, setDepartmentData] = useState<leadperdepartmentandmonth>();
  const [loadingDepartmentData, setLoadingDepartmentData] = useState<boolean>(false);

  const [eligibleData, setEligibleData] = useState<EligibleNoEligibleComparationType>();
  const [loadingEligibleData, setLoadingEligibleData] = useState<boolean>(false);
  const [eligibleByDepartment, setEligibleByDepartment] =
    useState<EligibleNoEligibleComparationType>();
  const [loadingEligibleByDepartment, setLoadingEligibleByDepartment] = useState<boolean>(false);

  useEffect(() => {
    if (!clinicId) {
      new ClinicAccessRepository()
        .list({
          page: 0,
          direction: 'asc',
          limit: 100,
        })
        .then((data) => {
          setClinicId(data.items[0].id);
          setClinics(data.items);
          setClinicOptions(
            data.items.map((clinic) => {
              return {
                label: clinic.name,
                value: clinic.id,
              };
            })
          );
        });
      return;
    }
    setLoadingData(true);
    setLoadingEligibleData(true);
    setLoadingDepartmentData(true);
    setLoadingEligibleByDepartment(true);
    //CON ESTA QUERY SACAMOS LOS DATOS DE LOS LEADS EN GENERAL
    new MarketingOvobankRepository()
      .leadStateAndMonth({
        month: month + 1,
      })
      .then((data: any) => {
        setData(data);
        setLoadingData(false);
      });
    new MarketingOvobankRepository()
      .eligibleNoEligibleCount({
        month: month + 1,
      })
      .then((data: any) => {
        setEligibleData(data);
        setLoadingEligibleData(false);
      });
    //CON ESTA QUERY SACAMOS LOS DATOS DE LOS LEADS POR DEPARTAMENTO
    new MarketingOvobankRepository()
      .leadStateAndMonth({
        month: month + 1,
        clinicId,
      })
      .then((data) => {
        setDepartmentData(data);
        setLoadingDepartmentData(false);
      });
    new MarketingOvobankRepository()
      .eligibleNoEligibleCount({
        month: month + 1,
        clinicId,
      })
      .then((data) => {
        setEligibleByDepartment(data);
        setLoadingEligibleByDepartment(false);
      });
  }, [clinicId, month]);

  useEffect(() => {
    if (!clinicId) return;
    new LanguageAccessRepository()
      .list({
        limit: 100,
      })
      .then((language) => {
        setLanguages(
          language.items.map((lang) => {
            return {
              label: lang.name,
              value: lang.id,
            };
          })
        );
        setLanguageId(language.items[0].id);
      });
  }, [clinicId]);

  useEffect(() => {
    if (!languageId || !clinicId) return;
    setLoadingDepartmentData(true);
    new MarketingBoardRepository()
      .leadsStateAndMonthByClinicDepartment({
        clinicId,
        month: month + 1,
        languageId,
      })
      .then((data) => {
        setDepartmentData(data);
        setLoadingDepartmentData(false);
      });
  }, [clinicId, month, languageId]);

  const handleExport = (type: 'yearly' | 'monthly' | 'byEvent') => {
    if (data && confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      handleExportTableCSV({
        headers: ['Departamento', 'Total Leads', ...Object.values(leadEvent)],
        data: data,
        type,
        fileName: 'sale_funnel.csv',
        eventFields: [
          'total_leads',
          'lead_contacted_count',
          'lead_appointed_count',
          'lead_informed_count',
          'lead_medical_appointed_count',
          'lead_medic_attended_count',
          'lead_comercial_attended_count',
          'lead_medical_evaluated_count',
          'lead_converted_count',
        ],
      });
    }
    return;
  };

  const topGraphicsTitle = `${t('board.saleFunnel.totalSales.graphicTitle')} /${clinics && clinics.find((clinic) => clinic.id === clinicId)?.name} /${t(`access.users.list.month.${month}`)}`;
  const bottomGraphicsTitle = `${t('board.saleFunnel.totalSales.graphicTitle')} /${clinics && clinics.find((clinic) => clinic.id === clinicId)?.name} /${languages?.length && languages.find((language) => language.value === languageId)?.label} /${t(`access.users.list.month.${month}`)}`;

  if (!clinics || !clinicId || !languageId) {
    return <LoaderComponent loading />;
  }

  return (
    <SceneComponent prefix={'board.saleFunnel'} icon={'search'} links={[]}>
      <View style={SaleFunnelStyles.container}>
        <BoardHeaderComponent
          title="board.sections.saleFunnel.title"
          description="board.sections.saleFunnel.description"
        />
        <View style={SaleFunnelStyles.filtersContainer}>
          <View style={[SaleFunnelStyles.graphicContainer, { zIndex: 1, flex: 2 }]}>
            <View style={SaleFunnelStyles.filterContainer}>
              <View style={SaleFunnelStyles.filterTextContainer}>
                <Text style={SaleFunnelStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.month')}
                </Text>
                <Text style={SaleFunnelStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.monthSubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={month.toString()}
                  name="month"
                  onChange={(value: any) => {
                    setMonth(parseInt(value.month));
                  }}
                  options={Array.from({ length: 12 }, (_, i) => {
                    return {
                      label: t(`access.users.list.month.${i}`),
                      value: i.toString(),
                    };
                  })}
                  hideSearch
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ gap: 10, flex: 2, paddingRight: 20 }}>
            <View style={SaleFunnelStyles.minHeightGraphic}>
              <View style={SaleFunnelStyles.graphicContainerHeader}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
                {Platform.OS === 'web' && (
                  <TouchableOpacity
                    style={LeadsScreenStyles.uploadBox}
                    onPress={() => handleExport('byEvent')}
                  >
                    <View style={LeadsScreenStyles.buttonContainer}>
                      <TextComponent
                        text={t('board.leads.totalLeads.export')}
                        style={LeadsScreenStyles.buttonLabel}
                      />
                      <IconComponent
                        name="upload"
                        containerstyle={LeadsScreenStyles.iconContainer}
                        iconStyle={LeadsScreenStyles.icon}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
              {data && !loadingData && <SaleFunnelPerDepartmentTable data={data} total={true} />}
            </View>
            <View style={SaleFunnelStyles.minHeightGraphic}>
              <View style={SaleFunnelStyles.graphicContainerHeader}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
              </View>
              {data && !loadingData && (
                <SaleFunnelPerDepartmentChart data={data} colors={colors || []} />
              )}
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={[SaleFunnelStyles.minHeightGraphic, { flex: 1 }]}>
              <View style={[SaleFunnelStyles.graphicContainerHeader, { paddingTop: 30 }]}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
              </View>
              {eligibleData && !loadingEligibleData ? (
                <EligibleChartComponent data={eligibleData} />
              ) : (
                <LoaderComponent loading />
              )}
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'column', paddingTop: 50 }}>
          <View style={{ marginBottom: 10, width: 250, zIndex: 1 }}>
            <View style={SaleFunnelStyles.filterTextContainer}>
              <Text style={SaleFunnelStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.department')}
              </Text>
              <Text style={SaleFunnelStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.departmentSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={clinicId}
                name="clinic"
                onChange={(value: any) => {
                  setClinicId(value.clinic);
                }}
                pick={async ({ id }) =>
                  new ClinicAccessRepository()
                    .pick({
                      id,
                    })
                    .then((res) => res.name ?? '')
                }
                hideSearch
                repository={async () => clinicOptions ?? []}
              />
            </View>
          </View>
          <View style={[SaleFunnelStyles.graphicContainer, SaleFunnelStyles.minHeightGraphic]}>
            {departmentData && !loadingDepartmentData ? (
              <View style={{ flex: 2 }}>
                <View style={{ flex: 1 }}>
                  <View style={SaleFunnelStyles.graphicContainerHeader}>
                    <Text style={SaleFunnelStyles.graphicTitle}>{bottomGraphicsTitle}</Text>
                  </View>
                  <BarChartComponent
                    data={departmentData}
                    leadEvent={leadEvent}
                    selectedDepartmentName={languages?.find((d) => d.value === languageId)?.label}
                    total={true}
                  />
                </View>
              </View>
            ) : (
              <LoaderComponent loading />
            )}
            <View style={[SaleFunnelStyles.minHeightGraphic, { flex: 1 }]}>
              {eligibleByDepartment && !loadingEligibleByDepartment ? (
                <EligibleChartComponent data={eligibleByDepartment} />
              ) : (
                <LoaderComponent loading />
              )}
            </View>
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
