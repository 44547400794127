import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { Image } from 'react-native';
import { AvatarProperties } from './avatar.properties';
import { AvatarStyles } from './avatar.styles';
import { useState } from 'react';
import { useUserState } from '@states/user/user.state';
import { isString } from '@validator/index';
import { UserRepository } from '@human/access/repositories/session/user.repository';

export function AvatarComponent(properties: AvatarProperties) {
  const [hover, setHover] = useState(false);
  const { user, permissions, update } = useUserState();
  const upload = () => {
    // create file input
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e: any) => {
      if (!e.target) return;
      if (!e.target.files) return;
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result && isString(reader.result)) {
          new UserRepository().addSelfie({ selfie: reader.result });
          update({ user: { ...user, selfie: reader.result }, permissions });
        }
      };
      reader.onerror = function (error) {};
    };

    // trigger file input
    input.click();
  };

  return (
    <div
      style={properties.small ? AvatarStyles.smallContainer : AvatarStyles.bigContainer}
      onClick={upload}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <div
          style={{
            ...AvatarStyles.hoverContainer,
            cursor: 'pointer',
            backdropFilter: 'blur(3px)',
          }}
        >
          <IconComponent name="camera" iconStyle={AvatarStyles.hoverIcon} />
        </div>
      )}
      {properties.selfie ? (
        <Image source={{ uri: user.selfie }} style={AvatarStyles.image} />
      ) : (
        <IconComponent
          name="user"
          containerstyle={AvatarStyles.iconContainer}
          iconStyle={AvatarStyles.icon}
        />
      )}
      {!properties.small && (
        <TextComponent text={properties.companyCode} bold style={AvatarStyles.ovoText} />
      )}
    </div>
  );
}
