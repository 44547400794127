import { NavigationComponent } from '@components/navigation/navigation.component';
import { SpecialistsRoutes } from './specialists.routes';
import { ListSpecialistsScreen } from './screens/list/list.specialists.screen';
import { CreateSpecialistsScreen } from './screens/create/create.specialists.screen';
import { UpdateSpecialistScreen } from './screens/update/update.specialist.screen';
import { SpecialistCreateContractScreen } from './screens/contractCreate/specialist.contract.create.screen';
import { SpecialistContractDeleteScreen } from './screens/contractDelete/specialist.contractDelete.screen';
import { DisableSpecialistScreen } from './screens/disable/disable.specialists.screen';
import { EnableSpecialistScreen } from './screens/enable/enable.specialist.screen';

const Navigation = NavigationComponent<SpecialistsRoutes>();

export function SpecialistsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListSpecialistsScreen} />
      <Navigation.Screen name="create" component={CreateSpecialistsScreen} />
      <Navigation.Screen name="update" component={UpdateSpecialistScreen} />
      <Navigation.Screen name="contractCreate" component={SpecialistCreateContractScreen} />
      <Navigation.Screen name="contractDelete" component={SpecialistContractDeleteScreen} />
      <Navigation.Screen name="disable" component={DisableSpecialistScreen} />
      <Navigation.Screen name="enable" component={EnableSpecialistScreen} />
    </Navigation.Navigator>
  );
}
