import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SpecialistsRoutes } from '../../specialists.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';

export function SpecialistContractDeleteScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes, 'contractDelete'>;
  route: RouteProp<SpecialistsRoutes, 'contractDelete'>;
}) {
  return (
    <ModalComponent
      icon="trash"
      prefix="access.specialists.update.contracts.delete"
      level={properties.route.params.level}
    >
      <FormComponent
        prefix="access.specialists.update.contracts.delete.form"
        padding
        repository={{
          send: () => {
            return new SpecialistAccessRepository()
              .deleteContract({
                specialistId: properties.route.params.specialistId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
