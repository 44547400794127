import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TimetableAccessType } from '@human/access/types/timetable.access.type';

export class TimetableAccessRepository extends AuthorizedRepository {
  async create(properties: { item: Partial<TimetableAccessType> }) {
    return this.fetch('timetable/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async list(properties: {
    direction?: 'ASC' | 'DESC';
    page?: number;
    limit?: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: TimetableAccessType[];
    total: number;
  }> {
    return this.fetch('timetable/find', {
      params: properties,
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((timetable: TimetableAccessType) => timetable),
      total: data.total,
    }));
  }

  async pick(properties: { timetableId: string }) {
    return this.fetch('timetable/pick', {
      params: properties,
      method: 'GET',
    }).then((timetable) => timetable);
  }

  async delete(properties: { timetableId: string }) {
    return this.fetch('timetable/delete', {
      method: 'DELETE',
      body: {
        timetableId: properties.timetableId,
      },
    }).then(() => true);
  }

  async disable(properties: { timetableId: string }) {
    return this.fetch('timetable/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { timetableId: string }) {
    return this.fetch('timetable/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async update(properties: { item: Partial<TimetableAccessType> }) {
    return this.fetch('timetable/update', {
      method: 'PUT',
      body: properties.item,
    });
  }
}
