export function getWorkspaceUtility() {
  return process.env.EXPO_PUBLIC_WORKSPACE ?? 'not_found';
}

type WorkspaceName = 'ovoclinic' | 'ovobank';

const currentWorkspace: WorkspaceName = getWorkspaceUtility() as WorkspaceName;

interface WorkspaceSelectConfig {
  ovoclinic: React.ReactElement;
  ovobank: React.ReactElement;
}

const Workspace = {
  select(config: WorkspaceSelectConfig): React.ReactElement {
    if (config[currentWorkspace]) {
      return config[currentWorkspace]!;
    }
    return config.ovoclinic;
  },
};

export default Workspace;
