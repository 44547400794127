import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { TreatmentsRoutes } from '../../../treatment.routes';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
export function FormCreateTreatmentScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'createTreatment'>;
  programId: string;
}) {
  return (
    <FormComponent<TreatmentCrmType>
      prefix="crm.treatments.create.form"
      padding
      repository={{
        send: (settings) => {
          return new TreatmentCrmRepository()
            .create({
              item: settings.item,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              programId: properties.programId,
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
      }}
      fields={[
        {
          type: 'select',
          name: 'phaseId',
          description: true,
          repository: (props: { search?: string }) => {
            return new ProgramPhaseCrmRepository()
              .list({
                search: props.search,
                programId: properties.programId,
              })
              .then((phases) => {
                return phases.items.map((phase) => ({
                  label: phase.phaseName,
                  value: phase.phaseId,
                }));
              });
          },
        },
        {
          type: 'number',
          name: 'discount',
          description: true,
        },
      ]}
    />
  );
}
