export const months = [
  { month: 'jan' },
  { month: 'feb' },
  { month: 'mar' },
  { month: 'apr' },
  { month: 'may' },
  { month: 'jun' },
  { month: 'jul' },
  { month: 'aug' },
  { month: 'sep' },
  { month: 'oct' },
  { month: 'nov' },
  { month: 'dec' },
];
