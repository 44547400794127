import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class TemplateScopeAccessRepository extends AuthorizedRepository {
  async create(properties: {
    item: Partial<{ templateId: string; departmentId: string; clinicId: string }>;
  }) {
    return this.fetch('scope/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async delete(properties: {
    templateId: string;
    departmentId: string;
    clinicId: string;
  }): Promise<void> {
    return this.fetch('scope/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async list(properties: {
    page?: number;
    limit?: number;
    search?: string;
    templateId: string;
  }): Promise<{
    items: {
      departmentId: string;
      clinicId: string;
      departmentName: string;
    }[];
    total: number;
  }> {
    return this.fetch('scope/find', {
      params: properties,
      method: 'GET',
    }).then((data) => ({
      items: data.items.map(
        (template: { departmentId: string; clinicId: string; departmentName: string }) => ({
          departmentId: template.departmentId,
          clinicId: template.clinicId,
          departmentName: template.departmentName,
        })
      ),
      total: data.total,
    }));
  }
}
