import { LeadComparativeTableProperties } from './leadComparativeTable.properties';
import { useTranslation } from 'react-i18next';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { NewLeadsByDepartmentYearsComparative } from '../../../components/newLeadsByDepartmentYearsComparative/newLeadsByDepartmentYearsComparative';
import { LoaderComponent } from '@components/loader/loader.component';
import { IconComponent } from '@components/icon/icon.component';
import { TwoYearColumnLeadsComparative } from '../../../components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative';
import { TextComponent } from '@components/text/text.component';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { BoardStyles } from '../../../../common/board.styles';

export function LeadComparativeTable(properties: LeadComparativeTableProperties) {
  const { t } = useTranslation();

  const handleExport = (type: 'yearly' | 'monthly') => {
    if (confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      const headers =
        type === 'monthly'
          ? Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`))
          : [properties.optionA.toString(), properties.optionB.toString()];

      handleExportTableCSV({
        headers: ['Departamento', ...headers],
        data: properties.data,
        type,
        fileName: type === 'monthly' ? 'monthly_leads.csv' : 'yearly_leads.csv',
      });
    }
    return;
  };

  return (
    <View style={BoardStyles.graphicsContainer}>
      <View style={[BoardStyles.graphicContainer, { flex: 7 }]}>
        <View style={BoardStyles.graphicContainerHeader}>
          <Text style={BoardStyles.graphicTitle}>
            {t('board.leads.totalLeads.comparativeGraphicTitle')}
          </Text>
          {Platform.OS === 'web' && (
            <TouchableOpacity style={BoardStyles.uploadBox} onPress={() => handleExport('monthly')}>
              <View style={BoardStyles.buttonContainer}>
                <TextComponent
                  text={t('board.leads.totalLeads.export')}
                  style={BoardStyles.buttonLabel}
                />
                <IconComponent
                  name="upload"
                  containerstyle={BoardStyles.iconContainer}
                  iconStyle={BoardStyles.icon}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
        {properties.clinicId && !properties.loading ? (
          <NewLeadsByDepartmentYearsComparative
            data={properties.data}
            firstYear={properties.optionA}
            secondYear={properties.optionB}
          />
        ) : (
          <LoaderComponent loading />
        )}
      </View>
      <View style={BoardStyles.graphicContainer}>
        <View style={BoardStyles.graphicContainerHeader}>
          <Text style={BoardStyles.graphicTitle}>
            {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
          </Text>
          {Platform.OS === 'web' && (
            <TouchableOpacity style={BoardStyles.uploadBox} onPress={() => handleExport('yearly')}>
              <View style={BoardStyles.buttonContainer}>
                <TextComponent
                  text={t('board.leads.totalLeads.csv')}
                  style={BoardStyles.buttonLabel}
                />
                <IconComponent
                  name="upload"
                  containerstyle={BoardStyles.iconContainer}
                  iconStyle={BoardStyles.icon}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
        {properties.clinicId && !properties.loading ? (
          <TwoYearColumnLeadsComparative
            data={properties.data}
            firstYear={properties.optionA}
            secondYear={properties.optionB}
          />
        ) : (
          <LoaderComponent loading />
        )}
      </View>
    </View>
  );
}
