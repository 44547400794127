import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FunnelsRoutes } from '../../funnels.routes';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateFunnelScreen(properties: {
  navigation: NavigationProp<FunnelsRoutes>;
  route: RouteProp<FunnelsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="user" prefix="crm.funnels.update">
      <FormComponent<any>
        padding
        prefix="crm.funnels.update.form"
        readonly={!hasPermissionUtilty({ name: 'crm.funnels.editor' })}
        repository={{
          send: (data) => {
            return new FunnelCrmRepository()
              .update({
                item: data.item,
              })
              .then(() => {
                showSuccess();
              });
          },
          get: () => {
            return new FunnelCrmRepository().pick({
              id: properties.route.params.id,
            });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            required: true,
            description: true,
            validations: ['name'],
          },
          {
            type: 'select',
            name: 'channelId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ChannelCrmRepository().pick({ id: properties.id }).then((channel) => {
                return `${channel.name}`;
              });
            },
            repository: ({ search }) => {
              return new ChannelCrmRepository()
                .list({
                  search,
                  direction: 'desc',
                  page: 0,
                  limit: 100,
                  active: true,
                })
                .then((channels) =>
                  channels.items.map((channel) => ({
                    value: channel.id,
                    label: channel.name,
                  }))
                );
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
