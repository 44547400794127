import { useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { TextComponent } from '@components/text/text.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { PatientProfileStyles } from './patientProfile.styles';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { PatientDetailScreen } from './components/patientDetail.screen';
import { TaggedNotesComponent } from '@components/taggedNotes/tagged.notes.component';
import { PatientNoteCrmRepository } from '@human/crm/repositories/patientNote/patientNote.repository';
import { ActionSelectableComponent } from './components/actionSelectable/actionSelectable.component';
import { LeadNoteCrmRepository } from '@human/crm/repositories/leadNote/leadNote.repository';
import { NotesComponent } from '@components/notes/notes.component';

export function PatientProfileComponent(properties: {
  patientId: string;
  leadId: string;
  clinicId: string;
  contactId: string;
}) {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [patient, setPatient] = useState<{
    id: string;
    status: boolean;
    contactName: string;
    contactSurname: string;
    contactEmails: { id: string; email: string; contactId: string }[];
    contactPhones: { id: string; phone: string; prefix: string; contactId: string }[];
    contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
    clinicName: string;
    campaignName: string;
    originCampaignName: string;
    warrantyAgreement: boolean;
    consentCheck: boolean;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const [sourceFilter, setSourceFilter] = useState<'lead' | 'Paciente'>('Paciente');
  const [tagFilter, setTagFilter] = useState<string>();

  const loadData = () => {
    setLoading(true);
    new PatientCrmRepository()
      .pickProfile({ patientId: properties.patientId, clinicId: properties.clinicId })
      .then((patient) => {
        setPatient(patient);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading || !patient) {
    return <LoaderComponent loading={loading} />;
  }

  return (
    <View style={[PatientProfileStyles.container, collapse ? PatientProfileStyles.collapse : null]}>
      <View style={PatientProfileStyles.headerContainer}>
        <View style={PatientProfileStyles.header}>
          <View style={PatientProfileStyles.headerContent}>
            <View style={PatientProfileStyles.profilePicture}>
              <TextComponent
                text={`${patient?.contactName[0].toUpperCase()} ${patient?.contactSurname ? patient?.contactSurname[0].toUpperCase() : ''}`}
                style={PatientProfileStyles.profilePictureText}
                bold
              />
            </View>
            <View style={PatientProfileStyles.headerText}>
              <View style={PatientProfileStyles.headerNameContainer}>
                <TextComponent
                  text={`${patient?.contactName} ${patient?.contactSurname}`}
                  lines={1}
                  style={PatientProfileStyles.headerName}
                  bold
                />
              </View>
              <View style={PatientProfileStyles.status}>
                <View
                  style={[
                    PatientProfileStyles.statusIndicator,
                    patient.status
                      ? PatientProfileStyles.enableStatusIndicator
                      : PatientProfileStyles.disableStatusIndicator,
                  ]}
                />
                <TextComponent
                  text={
                    patient.status
                      ? 'crm.patients.update.actions.patient.fields.active'
                      : 'crm.patients.update.actions.patient.fields.inactive'
                  }
                  multiWorkspace
                  translate
                  style={PatientProfileStyles.statusText}
                  bold
                />
              </View>
            </View>
          </View>
          <Pressable
            style={PatientProfileStyles.closeIcon}
            onPress={() => {
              setCollapse(!collapse);
            }}
          >
            <IconComponent
              name={collapse ? 'angle-up' : 'angle-down'}
              iconStyle={PatientProfileStyles.closeShape}
              containerstyle={PatientProfileStyles.close}
            />
          </Pressable>
        </View>
      </View>

      {!collapse && (
        <TabsComponent
          darkMode
          prefix="crm.patients.update.actions.patient"
          disabledDescription
          tabs={[
            {
              icon: 'user',
              name: 'details',
              content: () => <PatientDetailScreen patient={patient} />,
            },
            {
              name: 'notes',
              icon: 'sticky-note',
              content: () => (
                <>
                  <ActionSelectableComponent
                    title="common.notes.noteType.title"
                    bubbles={['common.notes.noteType.patient', 'common.notes.noteType.lead']}
                    onChange={(value: any) => setSourceFilter(value)}
                    firstSelected
                    multiWorkspace
                  />
                  {sourceFilter === 'Paciente' ? (
                    <>
                      <ActionSelectableComponent
                        title="common.notes.filter.title"
                        bubbles={[
                          'common.notes.filter.tags.medical',
                          'common.notes.filter.tags.nursing',
                          'common.notes.filter.tags.followUp',
                        ]}
                        onChange={(value) => setTagFilter(value)}
                        dissmisable
                      />

                      <TaggedNotesComponent
                        repository={{
                          get: () => {
                            return new PatientNoteCrmRepository().findByPatient({
                              patientId: properties.patientId,
                              clinicId: properties.clinicId,
                              tag: tagFilter,
                            });
                          },
                          send: (settings: { text: string; tag: string }) =>
                            new PatientNoteCrmRepository().create({
                              item: {
                                text: settings.text,
                                tag: settings.tag,
                                patientId: properties.patientId,
                                clinicId: properties.clinicId,
                              },
                            }),
                          update: (settings: { text: string; noteId: string; tag: string }) =>
                            new PatientNoteCrmRepository().update({
                              text: settings.text,
                              patientNoteId: settings.noteId,
                              patientId: properties.patientId,
                              clinicId: properties.clinicId,
                              tag: settings.tag,
                            }),
                          delete: (noteId: string) =>
                            new PatientNoteCrmRepository().delete({
                              patientNoteId: noteId,
                              patientId: properties.patientId,
                              clinicId: properties.clinicId,
                            }),
                        }}
                      />
                    </>
                  ) : (
                    <NotesComponent
                      darkMode
                      source="LEAD"
                      repository={{
                        get: () =>
                          new LeadNoteCrmRepository().findByLead({
                            leadId: properties.leadId,
                            clinicId: properties.clinicId,
                          }),
                        send: (text: string) =>
                          new LeadNoteCrmRepository().create({
                            item: {
                              text,
                              leadId: properties.leadId,
                              clinicId: properties.clinicId,
                            },
                          }),
                        update: (settings: { text: string; noteId: string }) =>
                          new LeadNoteCrmRepository().update({
                            text: settings.text,
                            leadNoteId: settings.noteId,
                            leadId: properties.leadId,
                            clinicId: properties.clinicId,
                          }),
                        delete: (noteId: string) =>
                          new LeadNoteCrmRepository().delete({
                            leadNoteId: noteId,
                            leadId: properties.leadId,
                            clinicId: properties.clinicId,
                          }),
                      }}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      )}
    </View>
  );
}
