import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvoclinicLeadsScreen } from './ovoclinicLeads/ovoclinicLeads.screen';
import { OvobankLeadsScreen } from './ovobankLeads/ovobankLeads.screen';

export default function LeadsScreenWeb(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'leads'>;
}) {
  return Workspace.select({
    ovoclinic: <OvoclinicLeadsScreen {...properties} />,
    ovobank: <OvobankLeadsScreen {...properties} />,
  });
}
