import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import 'react-native-gesture-handler';

import { MainRoutes } from './screens/main.routes';
import { AuthorizedScreen } from './screens/authorized/authorized.screen';
import { UnauthorizedSpace } from './screens/unauthorized/unauthorized.space';
import { useValidateHook } from '@hooks/session/validate.hook';

const Stack = createStackNavigator<MainRoutes>();

export function MainApplication() {
  const isAuthenticated = useValidateHook();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      {!isAuthenticated && <Stack.Screen name="unauthorized" component={UnauthorizedSpace} />}
      {isAuthenticated && <Stack.Screen name="authorized" component={AuthorizedScreen} />}
    </Stack.Navigator>
  );
}
