import { ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Text, View, useWindowDimensions } from 'react-native';

export function ExpecteInterventionDepartmentCircle(properties: {
  data: {
    total: number;
    total_loss: number;
    total_first_attempt: number;
  };
  title: string;
  labelTraduction: string;
}) {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const isEmpty = properties.data.total === 0;
  const comparingData = {
    total_loss: properties.data.total_loss,
    total_first_attempt: properties.data.total_first_attempt,
  };

  const doughnutData: ChartData<'doughnut', number[], unknown> = {
    labels: !isEmpty
      ? Object.keys(comparingData).map((key) => t(`${properties.labelTraduction}.${key}`))
      : [],
    datasets: [
      {
        label: 'Expected Interventions',
        data: isEmpty ? [100] : Object.values(comparingData),
        borderColor: isEmpty ? ['rgba(211, 211, 211, 0.5)'] : '#1A242B',
        backgroundColor: isEmpty ? ['rgba(245, 245, 245, 0.9)'] : ['#FF3B3A', '#1A242B'],
        borderWidth: 1,
        hoverBackgroundColor: isEmpty ? ['#b0b0b0'] : ['#FF3B3A', '#1A242B'],
      },
    ],
  };

  return (
    <View
      style={{
        borderRadius: 4,
        height: height * 0.3,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        gap: 20,
      }}
    >
      <Text
        style={{
          fontSize: 14,
          color: '#999999',
          fontWeight: '700',
        }}
      >
        {properties.title}
      </Text>
      <Text
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%', // Cambiamos a 50%
          transform: [{ translateX: '-50%' }, { translateY: '-50%' }], // Añadimos transform
          textAlign: 'center',
          fontSize: 18,
          color: '#999999',
        }}
      >
        {t('board.intervention.expectedInterventionsSegmentionCountType.total')}
        <Text
          style={{
            fontSize: 18,
            fontWeight: '700',
          }}
        >{`\n${properties.data.total}`}</Text>
      </Text>
      <Doughnut
        data={doughnutData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: '65%',
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                color: '#283038',
                padding: 10,
                font: {
                  size: 12,
                },
              },
            },
            tooltip: {
              enabled: !isEmpty,
              callbacks: {
                label: (tooltipItem: any) =>
                  `${tooltipItem.label}: ${tooltipItem.raw}  ${tooltipItem.raw === 1 ? t('board.intervention.one') : t('board.intervention.many')}`,
              },
            },
          },
        }}
      />
    </View>
  );
}
