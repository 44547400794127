import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { TouchableOpacity } from 'react-native';
import { SubmitProperties } from './submit.properties';
import { SubmitStyles } from './submit.styles';
import { useState } from 'react';
import { LoaderComponent } from '@components/loader/loader.component';

/**
 * Submit Component
 * @description Submit component renderiza un Boton. La particularidad es que el SubmitComponent recibe un repository.
 * Lo cual hará que cuando se presione el botón haga dicha llamada asincróna. Ademas, va a gestionar su propio estado de loading para que no tengamos que hacerlo en el componente padre.
 */
export function SubmitComponent(properties: SubmitProperties) {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  return (
    <TouchableOpacity
      onPress={() => {
        setSubmitLoading(true);
        properties.repository().finally(() => {
          setSubmitLoading(false);
        });
      }}
      style={SubmitStyles.container}
    >
      {submitLoading ? (
        <LoaderComponent loading={submitLoading} style={{ backgroundColor: 'white' }} />
      ) : (
        <>
          <TextComponent
            translate
            text={`${properties.prefix}`}
            bold
            style={SubmitStyles.submitText}
          />
          <IconComponent
            name={properties.icon ?? 'arrow-right'}
            iconStyle={SubmitStyles.submitIcon}
          />
        </>
      )}
    </TouchableOpacity>
  );
}
