import { NavigationComponent } from '@components/navigation/navigation.component';
import { ListRolesScreen } from './screens/list/list.roles.screen';
import { RolesRoutes } from './roles.routes';
import { RouteProp } from '@react-navigation/native';
import { AccessRoutes } from '@human/access/access.routes';
import { CreateRoleScreen } from './screens/create/create.role.screen';
import { UpdateRoleScreen } from './screens/update/update.role.screen';
import { DisableRoleScreen } from './screens/disable/disable.role.screen';
import { EnableRoleScreen } from './screens/enable/enable.role.screen';

const Stack = NavigationComponent<RolesRoutes>();

export function RolesSection(properties: { route: RouteProp<AccessRoutes, 'roles'> }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="list"
        component={ListRolesScreen}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Stack.Screen
        name="create"
        component={CreateRoleScreen}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Stack.Screen
        name="update"
        component={UpdateRoleScreen}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Stack.Screen name="disable" component={DisableRoleScreen} />
      <Stack.Screen name="enable" component={EnableRoleScreen} />
    </Stack.Navigator>
  );
}
