import { ModalComponent } from '@components/modal/modal.component';
import { LeadsRoutes } from '../../leads.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { LeadImportAttemptCrmRepository } from '@human/crm/repositories/leadImportAttempt/leadImportAttempt.repository';

export function DeleteLeadImportScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'deleteImport'>;
}) {
  return (
    <ModalComponent
      icon="user-ninja"
      prefix="crm.leads.import.delete"
      level={properties.route.params.level}
    >
      <FormComponent
        prefix={'crm.leads.import.delete.form'}
        padding
        repository={{
          send: () => {
            return new LeadImportAttemptCrmRepository()
              .delete({
                id: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
