import { ButtonComponent } from '@components/button/button.component';
import { FieldContainer } from '@components/form/components/fields/field.container';
import { CheckboxInput } from '@components/form/components/fields/inputs/checkbox/checkbox.input';
import { TextInput } from '@components/form/components/fields/inputs/text/text.input';
import { TabType } from '@components/tabs/tabs.types';
import { ReasonCrmType } from '@human/crm/types/reason.crm.type';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateReasonNotificationPart(properties: {
  notify: boolean;
  setNotify: (notify: boolean) => void;
  subject: string;
  setSubject: (subject: string) => void;
  message: string;
  setMessage: (message: string) => void;
  onSubmit: () => void;
}): TabType {
  return {
    name: 'notification',
    icon: 'bell',
    content: (settings) => (
      <>
        <FieldContainer
          prefix="crm.reasons.update.reason.notification.form"
          type="checkbox"
          name="notify"
          description
        >
          <CheckboxInput<{ notify: boolean }>
            prefix="crm.reasons.update.reason.notification.form"
            name={'notify'}
            type={'checkbox'}
            values={{
              notify: properties.notify,
            }}
            readonly={!hasPermissionUtilty({ name: 'crm.reasons.editor' })}
            required
            description
            onChange={(value) => {
              properties.setNotify(value.notify ?? false);
            }}
          />
        </FieldContainer>
        {properties.notify && (
          <>
            <FieldContainer
              prefix="crm.reasons.update.reason.notification.form"
              type="text"
              required
              name="subject"
              description
            >
              <TextInput<{ subject: string }>
                value={{
                  subject: properties.subject,
                }}
                name={'subject'}
                type={'text'}
                required
                description
                onChange={(value) => {
                  properties.setSubject(value.subject ?? '');
                }}
                validations={['subject']}
                readonly={!hasPermissionUtilty({ name: 'crm.reasons.editor' })}
              />
            </FieldContainer>
            <FieldContainer
              prefix="crm.reasons.update.reason.notification.form"
              type="text"
              required
              name="message"
              description
            >
              <TextInput<{ message: string }>
                value={{
                  message: properties.message,
                }}
                name={'message'}
                type={'text'}
                required
                description
                onChange={(value) => {
                  properties.setMessage(value.message ?? '');
                }}
                validations={['message']}
                readonly={!hasPermissionUtilty({ name: 'crm.reasons.editor' })}
              />
            </FieldContainer>
          </>
        )}
        {hasPermissionUtilty({ name: 'crm.reasons.editor' }) && (
          <ButtonComponent
            prefix="crm.reasons.update.reason.notification.form.submit"
            onPress={() => {
              properties.onSubmit();
            }}
          />
        )}
      </>
    ),
  };
}
