import { StyleSheet } from 'react-native';

export const TagStyles = StyleSheet.create({
  container: {
    backgroundColor: '#eeeeee',
    borderRadius: 10,
  },
  tagText: {
    padding: 8,
    color: '#fff',
  },
  selected: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: 20,
    backgroundColor: '#00000029',
    borderRadius: 100,
  },
});
