export const tags = [
  {
    name: 'Médico',
    color: '#FFD9B3', // Durazno pastel
  },
  {
    name: 'Enfermeria',
    color: '#B3FFCC', // Verde menta
  },
  {
    name: 'Seguimiento',
    color: '#B3D1FF', // Azul pastel
  },
];
