import { NavigationComponent } from '@components/navigation/navigation.component';
import { CommunicationChannelsRoutes } from './communicationChannels.routes';
import { ListCommunicationChannelsScreen } from './screens/list/list.communicationChannels.screen';
import { CreateCommunicationChannelsScreen } from './screens/create/create.communicationChannels.screen';
import { UpdateCommunicationChannelsScreen } from './screens/update/update.communicationChannels.screen';
import { EnableCommunicationChannelScreen } from './screens/enable/enable.communicationChannels.screen';
import { DisableCommunicationChannelScreen } from './screens/disable/disable.communicationChannels.screen';

const Navigation = NavigationComponent<CommunicationChannelsRoutes>();

export function CommunicationChannelsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListCommunicationChannelsScreen} />
      <Navigation.Screen name="create" component={CreateCommunicationChannelsScreen} />
      <Navigation.Screen name="update" component={UpdateCommunicationChannelsScreen} />
      <Navigation.Screen name="enable" component={EnableCommunicationChannelScreen} />
      <Navigation.Screen name="disable" component={DisableCommunicationChannelScreen} />
    </Navigation.Navigator>
  );
}
