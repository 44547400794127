import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ProcedureCrmType } from '@human/crm/types/procedure.crm.type';

export class ProcedureCrmRepository extends AuthorizedRepository {
  async list(properties: {
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
    active?: boolean;
  }): Promise<{ items: ProcedureCrmType[]; total: number }> {
    return this.fetch('procedure/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: ProcedureCrmType) => ({
          techniqueId: item.techniqueId,
          techniqueName: item.techniqueName,
          price: item.price,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: {
    item: Partial<ProcedureCrmType>;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<void> {
    return this.fetch('procedure/create', {
      method: 'POST',
      body: {
        ...properties.item,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
      },
    });
  }

  async delete(properties: {
    clinicId: string;
    patientId: string;
    treatmentId: string;
    techniqueId: string;
  }): Promise<void> {
    return this.fetch('procedure/delete', {
      method: 'DELETE',
      body: {
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
        techniqueId: properties.techniqueId,
      },
    });
  }

  async pick(properties: {
    clinicId: string;
    patientId: string;
    treatmentId: string;
    techniqueId: string;
  }): Promise<Partial<ProcedureCrmType>> {
    return this.fetch('procedure/pick', {
      method: 'GET',
      params: {
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
        techniqueId: properties.techniqueId,
      },
    });
  }

  async update(properties: {
    clinicId: string;
    patientId: string;
    treatmentId: string;
    techniqueId: string;
    item: Partial<ProcedureCrmType>;
  }): Promise<void> {
    return this.fetch('procedure/update', {
      method: 'PUT',
      body: {
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
        techniqueId: properties.techniqueId,
        ...properties.item,
      },
    });
  }
}
