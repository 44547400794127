import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { AnalyticType } from '../type/analytic.type';

export class AnalyticRepository extends AuthorizedRepository {
  async find(): Promise<AnalyticType> {
    return this.fetch('analytics/find', {
      method: 'GET',
    }).then((data) => ({
      lastWeekLeads: data.lastWeekLeads,
      thisWeekLeads: data.thisWeekLeads,
      lastWeekActivities: data.lastWeekTasks,
      thisWeekActivities: data.thisWeekTasks,
      lastWeekResults: data.lastWeekResults,
      thisWeekResults: data.thisWeekResults,
    }));
  }
}
