import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TabType } from '@components/tabs/tabs.types';
import { UserRepository } from '@human/access/repositories/session/user.repository';
import { UsersRoutes } from '../../../users.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';

export function ChangePasswordUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
  showSuccess: (label?: string) => void;
}): TabType {
  return {
    name: 'changePassword',
    icon: 'lock',
    content: () => (
      <FormComponent<{
        password: string;
        repeatPassword: string;
      }>
        prefix="profile.changePassword.form"
        repository={{
          send: (data) => {
            if (data.item.password !== data.item.repeatPassword) {
              throw new Error(
                JSON.stringify({
                  errors: [
                    {
                      field: 'password',
                      code: 'passwordsDoNotMatch',
                    },
                    {
                      field: 'repeatPassword',
                      code: 'passwordsDoNotMatch',
                    },
                  ],
                })
              );
            }
            return new UserRepository()
              .changePassword({
                password: data.item.password || '',
                userId: properties.route.params.userId,
              })
              .then(() => properties.showSuccess());
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'password',
            required: true,
            validations: ['password'],
            description: true,
            secure: true,
          },
          {
            type: 'text',
            name: 'repeatPassword',
            required: true,
            validations: ['password'],
            description: true,
            secure: true,
          },
        ]}
      />
    ),
  };
}
