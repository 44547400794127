import { View } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AccessRoutes } from './access.routes';
import { PerformanceComponent } from '../../../../../components/performance/performance.component';
import { MenuComponent } from '../../../../../components/menu/menu.component';
import { AccessSections } from './access.sections';
import { UsersSection } from './sections/users/users.section';
import { ClinicsSection } from './sections/clinics/clinics.section';
import { RouteProp } from '@react-navigation/native';
import { AuthorizedSpaces } from '../../authorized.spaces';
import { RolesSection } from './sections/roles/roles.section';
import { LanguagesSection } from './sections/languages/languages.section';
import { CountriesSection } from './sections/countries/countries.section';
import { SettingsScreen } from './sections/settings/settings.screen';
import { useDeviceHook } from '@hooks/device/device.hook';
import { DepartmentsSection } from './sections/departments/departments.section';
import { BulletinsSection } from './sections/bulletins/bulletins.section';
import { TimetablesSection } from './sections/timetables/timetables.section';
import { HeaderComponent } from '@components/menu/components/header/header.component';
import { SpecialistsSection } from './sections/specialists/specialists.sections';
import { useNotificationChecker } from '@hooks/notification/useNotificationChecker';
import { CommunicationChannelsSection } from './sections/communicationChannels/communicationChannels.section';
import { OvopointsSection } from './sections/ovopoints/ovopoints.section';
import { TemplatesSections } from './sections/templates/templates.sections';

const Drawer = createDrawerNavigator<AccessRoutes>();
export function AccessSpace(properties: { route: RouteProp<AuthorizedSpaces, 'access'> }) {
  const { phone, tablet, laptop, desktop } = useDeviceHook();
  useNotificationChecker();

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <Drawer.Navigator
        drawerContent={(props) => {
          return <MenuComponent brand="human" {...props} sections={AccessSections} type="access" />;
        }}
        screenOptions={{
          headerTitle: () => <HeaderComponent type={'access'} brand={'human'} />,
          drawerStyle: {
            width: phone ? 300 : 400,
          },
          headerShown: phone || tablet,
          swipeEnabled: phone || tablet,
          drawerType: phone || tablet ? 'front' : 'permanent',
          headerTintColor: 'rgb(237, 53, 145)',
        }}
      >
        <Drawer.Screen
          name="users"
          component={UsersSection}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="roles"
          component={RolesSection}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen name="timetables" component={TimetablesSection} />
        <Drawer.Screen name="clinics" component={ClinicsSection} />
        <Drawer.Screen name="bulletins" component={BulletinsSection} />
        <Drawer.Screen name="departments" component={DepartmentsSection} />
        <Drawer.Screen name="templates" component={TemplatesSections} />
        <Drawer.Screen
          name="languages"
          component={LanguagesSection}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen name="countries" component={CountriesSection} />
        <Drawer.Screen name="settings" component={SettingsScreen} />
        <Drawer.Screen name="specialists" component={SpecialistsSection} />
        <Drawer.Screen name="communicationChannels" component={CommunicationChannelsSection} />
        <Drawer.Screen name="ovopoints" component={OvopointsSection} />
      </Drawer.Navigator>
      {(desktop || laptop) && <PerformanceComponent />}
    </View>
  );
}
