import axios from 'axios';
import { apiUrl } from '@environment/apiUrl.utility';
import { useUserState } from '@states/user/user.state';
import { isObject } from '@validator/index';

type OptionFetchRepository = Omit<RequestInit, 'body'> & {
  params?: {
    [key: string]:
      | string
      | number
      | boolean
      | string[]
      | {
          key?: string;
          start: Date;
          end: Date;
        };
  };
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
};

type OptionFetchMultipartRepository = Omit<RequestInit, 'body'> & {
  formData?: FormData;
};

export class Repository {
  headers = {
    'Content-Type': 'application/json',
  };
  fetch: (url: string, options: OptionFetchRepository) => Promise<any>;
  fetchMultiPart: (url: string, options: OptionFetchMultipartRepository) => Promise<any>;
  constructor() {
    this.fetch = (url: string, options: OptionFetchRepository) => {
      let path = `${apiUrl}/${url}`;
      if (options.params) {
        path = `${path}?${Object.keys(options.params)
          .filter((key) => options.params?.[key] !== undefined && options.params?.[key] !== '')
          .map((key) => {
            const ar = options.params && options.params[key];
            if (isObject(ar) && 'start' in ar && 'end' in ar) {
              return `filterDateStart=${ar.start.toISOString()}&filterDateEnd=${ar.end.toISOString()}`;
            } else if (Array.isArray(ar)) {
              return Array.isArray(ar) && ar.map((param) => `${key}=${param}`).join('&');
            } else {
              return `${key}=${options.params?.[key]}`;
            }
          })
          .join('&')}`;
      }
      if (options.headers) {
        this.headers = { ...this.headers, ...options.headers };
      }
      return axios(path, {
        method: options.method,
        data: JSON.stringify(options.body),
        headers: this.headers,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if ('response' in error && 'status' in error.response && error.response.status === 401) {
            useUserState.setState({
              user: { id: '', sessionId: '', accessToken: '', expires: new Date(), idioms: [] },
            });
            return;
          }
          if (error.response) {
            throw new Error(JSON.stringify(error.response.data));
          }
        });
    };

    this.fetchMultiPart = (url: string, options: OptionFetchMultipartRepository) => {
      let path = `${apiUrl}/${url}`;

      return axios
        .post(path, options.formData, {
          headers: {
            ...this.headers,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if ('response' in error && 'status' in error.response && error.response.status === 401) {
            window.location.href = '/';
            useUserState.setState({ user: undefined });
            return;
          }
          if (error.response) {
            throw new Error(JSON.stringify(error.response.data));
          }
        });
    };
  }
}
