import { NavigationComponent } from '@components/navigation/navigation.component';
import { CrmRoutes } from '@human/crm/crm.routes';
import { TechniqueRoutes } from './technique.routes';
import { RouteProp } from '@react-navigation/native';
import { ListTechniqueScreen } from './screens/list/list.technique.screen';
import { CreateTechniqueScreen } from './screens/create/create.technique.screen';
import { UpdateTechniqueScreen } from './screens/update/update.technique.screen';
import { EnableTechniqueScreen } from './screens/enable/enable.technique.screen';
import { DisableTechniqueScreen } from './screens/disable/disable.technique.screen';

const Navigation = NavigationComponent<TechniqueRoutes>();

export function TechniquesSection(properties: { route: RouteProp<CrmRoutes, 'techniques'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListTechniqueScreen} />
      <Navigation.Screen name="techniqueCreate" component={CreateTechniqueScreen} />
      <Navigation.Screen name="update" component={UpdateTechniqueScreen} />
      <Navigation.Screen name="enable" component={EnableTechniqueScreen} />
      <Navigation.Screen name="disable" component={DisableTechniqueScreen} />
    </Navigation.Navigator>
  );
}
