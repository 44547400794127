import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PaymentCrmRepository } from '@human/crm/repositories/payment/payment.repository';
import { PaymentCrmType } from '@human/crm/types/payment.crm.type';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdatePaymentScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'paymentUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="crm.patients.update.payments.update"
    >
      <FormComponent<PaymentCrmType>
        padding
        prefix="crm.patients.update.payments.update.form"
        repository={{
          send: (settings) => {
            return new PaymentCrmRepository()
              .update({
                id: properties.route.params.paymentId,
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
                receiptId: properties.route.params.receiptId,
                item: settings.item,
              })
              .then(() => {
                showSuccess();
              });
          },
          get: async () => {
            return new PaymentCrmRepository().pick({
              id: properties.route.params.paymentId,
              patientId: properties.route.params.patientId,
              clinicId: properties.route.params.clinicId,
              receiptId: properties.route.params.receiptId,
            });
          },
        }}
        fields={[
          {
            type: 'float',
            name: 'price',
            required: true,
            validations: ['price'],
          },
        ]}
      />
    </ModalComponent>
  );
}
