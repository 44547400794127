import { View, Text } from 'react-native';
import React from 'react';
import { InterventionYearCountRowStyles } from './interventionYearCountRow';
import { useDeviceHook } from '@hooks/device/device.hook';

export function InterventionsYearCountRow(properties: {
  item: {
    department_name: string;
    total_leads: number[];
  };
  firstAttemptRow: boolean;
  isLossRow: boolean;
  last: boolean;
}) {
  const { phone } = useDeviceHook();

  return (
    <View
      style={[
        InterventionYearCountRowStyles.rowContainer,
        properties.last && InterventionYearCountRowStyles.rowContainerLast,
        properties.isLossRow && InterventionYearCountRowStyles.rowContainerLoss,
        properties.firstAttemptRow && InterventionYearCountRowStyles.rowContainerFirstAttempt,
      ]}
    >
      <View
        style={[phone ? { width: 75 } : InterventionYearCountRowStyles.subRowSpaceColumnContainer]}
      >
        <Text
          style={[
            InterventionYearCountRowStyles.subRowSpaceText,
            (properties.isLossRow || properties.firstAttemptRow || properties.last) && {
              fontWeight: '500',
            },
            properties.isLossRow && InterventionYearCountRowStyles.subRowLossText,
          ]}
        >
          {properties.item.department_name}
        </Text>
      </View>

      <View
        style={{
          flex: 10,
          flexDirection: 'row',
          gap: 2,
        }}
      >
        {properties.item.total_leads.map((total, index) => (
          <>
            <View style={InterventionYearCountRowStyles.subRowColumnContainer}>
              <Text
                style={[
                  { width: 75, textAlign: 'center' },
                  InterventionYearCountRowStyles.subRowText,
                  index > new Date().getMonth() && InterventionYearCountRowStyles.subRowFutureText,
                  properties.isLossRow && InterventionYearCountRowStyles.subRowLossText,
                  // new Date().getMonth() < 0 && InterventionYearCountRowStyles.subRowFutureText,
                ]}
              >
                {total}
              </Text>
            </View>
          </>
        ))}
      </View>
    </View>
  );
}
