import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessType } from '../../../../types/country.access.type';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListCountriesScreen(properties: {
  navigation: NavigationProp<CountriesRoutes>;
  route: RouteProp<CountriesRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.countries.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }
  const { desktop } = useDeviceHook();

  const columns: ColumnProperties<CountryAccessType, ['countryNameEs']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.countries.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', { id: row.countryId });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      order: 'countryNameEs',
      onRender: (row) => row.countryNameEs,
    },

    {
      name: 'code',
      type: 'code',
      width: 100,
      onRender: (row) => row.code,
    },
    {
      name: 'coin',
      type: 'text',
      width: 100,
      onRender: (row) => row.coin,
    },

    {
      name: 'updated',
      type: 'date',
      renderDate: (row) => row.updated && new Date(row.updated),
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => new Date(row.created),
    },
  ];

  if (hasPermissionUtilty({ name: 'access.countries.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.countryId,
        });
      },
    });
  }

  return (
    <SceneComponent
      icon={AccessSections.countries.icon}
      prefix={'access.countries.list'}
      links={links}
    >
      <TableComponent<CountryAccessType, ['countryNameEs']>
        pagination={true}
        search
        scrollable={!desktop ?? undefined}
        prefix={'access.countries.list'}
        suffix={'list'}
        repository={(properties) => {
          return new CountryAccessRepository().list({
            direction: properties.direction,
            page: properties.page,
            limit: properties.limit,
            search: properties.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
