import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { DocumentCrmRepository } from '@human/crm/repositories/document/document.repository';
import { useTranslation } from 'react-i18next';

export function DocumentDeletePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientDocumentDelete'>;
}) {
  const { t } = useTranslation();
  return (
    <ModalComponent icon="trash" prefix="crm.patients.update.tabs.treatment.document.delete">
      <FormComponent
        padding
        prefix="crm.patients.update.tabs.treatment.document.delete"
        repository={{
          send: async () => {
            return new DocumentCrmRepository()
              .delete({
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                documentId: properties.route.params.documentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
