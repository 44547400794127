import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { dayjs } from '@utils/dayjs/dayjs.config';
export class CampaignCrmRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: CampaignCrmType[];
    total: number;
  }> {
    return this.fetch('campaign/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          leads: item.leads,
          image: item.image,
          code: item.code,
          languageId: item.languageId,
          funnelName: item.funnelName,
          languageName: item.languageName,
          status: item.status,
          activeLeads: item.activeLeads,
          inactiveLeads: item.inactiveLeads,
          participantsClinicNames: item.participantsClinicNames,
          managerNames: item.managerNames,
        })),
        total: data.total,
      };
    });
  }

  async listByFunnel(properties: { funnelId: string; search?: string }): Promise<{
    items: CampaignCrmType[];
  }> {
    return this.fetch('campaign/findByFunnel', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          leads: item.leads,
          image: item.image,
          code: item.code,
          funnelName: item.funnelName,
          languageName: item.languageName,
          status: item.status,
        })),
      };
    });
  }

  async listImageCampaigns(settings: {
    search?: string;
    limit: number;
    page: number;
  }): Promise<{ items: CampaignCrmType[]; total: number }> {
    return this.fetch('campaign/findImageCampaigns', {
      method: 'GET',
      params: settings,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          leads: item.leads,
          image: item.image,
          code: item.code,
          funnelName: item.funnelName,
          languageName: item.languageName,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  async pick(data: { id: string }): Promise<Partial<CampaignCrmType>> {
    return this.fetch(`campaign/pick`, {
      method: 'GET',
      params: {
        campaignId: data.id,
      },
    }).then((data) => {
      return {
        id: data.id,
        name: data.name,
        facebookId: data.facebookId,
        code: data.code,
        image: data.image,
        funnelId: data.funnelId,
        languageId: data.languageId,
        firstActivityId: data.firstActivityId,
        status: data.status,
        startDate: data.startDate,
        endDate: data.endDate,
      };
    });
  }

  async create(data: { item: Partial<CampaignCrmType> }): Promise<void> {
    return this.fetch('campaign/create', {
      method: 'POST',
      body: {
        ...data.item,
        startDate: data.item.startDate
          ? dayjs(data.item.startDate).tz('Europe/Madrid').toISOString()
          : undefined,
        endDate: data.item.endDate
          ? dayjs(data.item.endDate).tz('Europe/Madrid').toISOString()
          : undefined,
      },
    });
  }

  async update(data: { item: Partial<CampaignCrmType> }): Promise<void> {
    return this.fetch('campaign/update', {
      method: 'PUT',
      body: {
        ...data.item,
        startDate: data.item.startDate
          ? dayjs(data.item.startDate).tz('Europe/Madrid').toISOString()
          : undefined,
        endDate: data.item.endDate
          ? dayjs(data.item.endDate).tz('Europe/Madrid').toISOString()
          : undefined,
      },
    });
  }

  async enable(properties: { campaignId: string }): Promise<boolean> {
    return this.fetch('campaign/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: Partial<{ id: string }>): Promise<void> {
    return this.fetch('campaign/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async delete(properties: Partial<{ campaignId: string }>): Promise<void> {
    return this.fetch('campaign/delete', {
      method: 'DELETE',
      params: properties,
    });
  }
}
