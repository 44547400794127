import { StyleSheet } from 'react-native';

export const GeneralTableStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  clinicNameColumn: {
    justifyContent: 'flex-start',
  },
  headerPlaceholder: {
    height: 100,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  clinicNameRow: {
    padding: 10,
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  monthHeaderRow: {
    flexDirection: 'row',
    height: 100,
  },
  dataRow: {
    flexDirection: 'row',
  },
  columnContainer: {
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 15,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    width: 150,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  columnSubtitleContainer: {
    flexDirection: 'row',
    gap: 10,
  },
  columnSubtitle: {
    textAlign: 'center',
    color: '#999',
    fontWeight: '300',
    fontSize: 12,
  },
  rowContainer: {
    flexDirection: 'row',
    padding: 10,
    width: 150,
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  tableDataText: {
    fontWeight: '300',
    fontSize: 12,
  },
  total: {
    backgroundColor: '#EDEDED',
  },
});
