import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';
import { HistoricalProperties } from './historical.properties';
import { NoteComponent } from '../note.component';
import { HistoricalStyles } from './historical.styles';
import { t } from 'i18next';

export function HistoricalComponent(properties: HistoricalProperties) {
  return (
    <View style={HistoricalStyles.container}>
      {properties.logs.map((log) => {
        return (
          <View key={log.id} style={{}}>
            <View style={HistoricalStyles.header}>
              <TextComponent
                style={HistoricalStyles.name}
                text={`${log.author.name} ${log.author.surname}: `}
              ></TextComponent>
              {!log.status ? (
                <TextComponent style={{}} text={t('historical.deleted')} />
              ) : (
                <TextComponent
                  style={{}}
                  text={log.creation ? t('historical.created') : t('historical.updated')}
                />
              )}
            </View>

            {properties.message && (
              <NoteComponent
                blockActions
                key={log.id}
                message={{
                  ...properties.message,
                  text: log.text,
                  status: true,
                  authorEmail: log.author.email,
                  authorName: log.author.name,
                  authorSurname: log.author.surname,
                  updated: new Date(log.created),
                }}
                onEdit={(properties: { noteId: string; newText: string }) => {
                  throw new Error();
                }}
                onDelete={(properties: { noteId: string }) => {
                  throw new Error();
                }}
              />
            )}
          </View>
        );
      })}
    </View>
  );
}
