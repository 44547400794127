import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReceiptCrmRepository } from '@human/crm/repositories/receipt/receipt.repository';
import { ReceiptCrmType } from '@human/crm/types/receipt.crm.type';
import { PatientsRoutes } from '../../../../patient.routes';
import { TabsComponent } from '@components/tabs/tabs.component';
import { PaymentsUpdateReceiptPart } from './parts/payments.update.receipt.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateReceiptScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'receiptUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="crm.patients.update.receipts.update"
    >
      <TabsComponent
        prefix="crm.patients.update.receipts.update"
        tabs={[
          {
            name: 'receipt',
            icon: 'receipt',
            content: () => {
              return (
                <FormComponent<ReceiptCrmType>
                  prefix="crm.patients.update.receipts.update.form"
                  repository={{
                    send: (settings) => {
                      return new ReceiptCrmRepository()
                        .update({
                          clinicId: properties.route.params.clinicId,
                          id: properties.route.params.receiptId,
                          patientId: properties.route.params.patientId,
                          item: settings.item,
                        })
                        .then(() => {
                          showSuccess();
                        });
                    },
                    get: async () => {
                      return new ReceiptCrmRepository().pick({
                        id: properties.route.params.receiptId,
                        patientId: properties.route.params.patientId,
                        clinicId: properties.route.params.clinicId,
                      });
                    },
                  }}
                  fields={[]}
                />
              );
            },
          },
          PaymentsUpdateReceiptPart(properties),
        ]}
      />
    </ModalComponent>
  );
}
