import { StyleSheet } from 'react-native';

export const filterBadgeStyles = StyleSheet.create({
  container: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 5,
    padding: 5,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  iconContainer: {
    padding: 5,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 10,
  },
});
