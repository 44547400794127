import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ResultCCventCrmRepository extends AuthorizedRepository {
  pick(properties: { resultId: string; activityId: string }): Promise<{
    contacted: boolean;
    appointed: boolean;
    informed: boolean;
  }> {
    return this.fetch('result/pickCCEvent', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: {
    id: string;
    item: Partial<{
      activityId: string;
      contacted: boolean;
      appointed: boolean;
      informed: boolean;
      medicalAppointed: boolean;
    }>;
  }) {
    return this.fetch(`result/updateCCEvent?id=${properties.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }
}
