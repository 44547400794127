import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { ChannelsRoutes } from '../../channels.routes';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';

export function DisableChannelScreen(properties: {
  navigation: NavigationProp<ChannelsRoutes>;
  route: RouteProp<ChannelsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.channels.disable">
      <FormComponent
        prefix="crm.channels.disable.form"
        padding
        repository={{
          send: () => {
            return new ChannelCrmRepository()
              .disable({
                channelId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
