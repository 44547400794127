import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { UsersRoutes } from '../../users.routes';
import { UserAccessType } from '../../../../types/user.access.type';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { IconType } from '@components/icon/icon.types';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useTranslation } from 'react-i18next';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function ListUsersScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.users.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }
  const { phone } = useDeviceHook();
  const { t } = useTranslation();
  const { showSuccess } = useSuccessToast();

  const columns: ColumnProperties<UserAccessType, ['email', 'phone']>[] = [
    {
      type: 'icon',
      icon: 'clone',
      onPress: (row) => {
        if (!confirm(t('access.users.list.cloneConfirm') ?? 'Clone user')) {
          return;
        }
        new UserAccessRepository()
          .clone({
            userId: row.id,
          })
          .then(() => {
            showSuccess(t('access.users.list.cloneSuccess') ?? 'User cloned');
          });
      },
    },
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.users.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          userId: row.id,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      order: 'email',
      onRender: (row) => `${row.name} ${row.surname}`,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          userId: row.id,
        });
      },
    },
    {
      name: 'email',
      type: 'text',
      width: 200,
      order: 'email',
      onRender: (row) => row.email,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          userId: row.id,
        });
      },
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
      filter: 'range',
      max: new Date(new Date().setDate(new Date().getDate() + 1)),
      // min: new Date(),
    },
    {
      type: 'date',
      name: 'expiration',
      renderDate: (row) => row.expiration,
    },
    {
      type: 'date',
      name: 'lastMove',
      renderDate: (row) => row.expiration,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.users.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          userId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={AccessSections.users.icon} prefix={'access.users.list'} links={links}>
      <TableComponent<UserAccessType, ['email', 'phone']>
        prefix={'access.users.list'}
        suffix={'list'}
        search
        scrollable={phone ?? undefined}
        repository={(settings) => {
          return new UserAccessRepository().list({
            order: settings.order || 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            dates: settings.dates,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
