import { StyleSheet } from 'react-native';

export const RiskIndicatorStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 7,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  textNormal: {
    color: '#333',
  },
  textDanger: {
    color: 'rgb(255, 0, 85)',
  },
  textWarning: {
    color: 'rgb(255, 149, 0)',
  },
  textSuccess: {
    color: 'rgb(0, 255, 0)',
  },
});
