import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { MaintenancesRoutes } from '../../maintenance.routes';
import { FormComponent } from '@components/form/form.component';
import { MaintenanceCrmRepository } from '@human/crm/repositories/maintenance/maintenance.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { MaintenanceCrmType } from '@human/crm/types/maintenance.crm.type';
import { ActionUpdateMaintenancePart } from './parts/action.update.maintenance.part';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { LoaderComponent } from '@components/loader/loader.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateMaintenanceScreen(properties: {
  navigation: NavigationProp<MaintenancesRoutes>;
  route: RouteProp<MaintenancesRoutes, 'updateMaintenance'>;
}) {
  const { t } = useTranslation();
  const { showSuccess } = useSuccessToast();

  const [maintenance, setMaintenance] = useState<Partial<MaintenanceCrmType>>();

  useEffect(() => {
    new MaintenanceCrmRepository()
      .pick({
        maintenanceId: properties.route.params.maintenanceId,
        clinicId: properties.route.params.clinicId,
        patientId: properties.route.params.patientId,
        treatmentId: properties.route.params.treatmentId,
      })
      .then((data) => setMaintenance(data));
  }, []);
  if (!maintenance) return <LoaderComponent loading />;
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="user-edit"
      flex={false}
      prefix="crm.maintenances.update"
    >
      <TabsComponent
        prefix="crm.maintenances.update"
        tabs={[
          {
            name: 'maintenance',
            icon: 'user',
            content: (settings) => {
              return (
                <FormComponent<MaintenanceCrmType>
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  key={'maintenance'}
                  prefix="crm.maintenances.update.form"
                  readonly={!hasPermissionUtilty({ name: 'crm.maintenances.editor' })}
                  repository={{
                    send: (data) => {
                      return new MaintenanceCrmRepository()
                        .update({
                          item: data.item,
                          clinicId: properties.route.params.clinicId,
                          patientId: properties.route.params.patientId,
                          treatmentId: properties.route.params.treatmentId,
                          maintenanceId: properties.route.params.maintenanceId,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: async () => maintenance,
                  }}
                  fields={[
                    {
                      type: 'select',
                      name: 'serviceId',
                      required: true,
                      description: true,
                      pick: (props: { id: string }) =>
                        new ServiceCrmRepository()
                          .pick({ serviceId: props.id })
                          .then((data) => data.name),
                      repository: () =>
                        new ServiceCrmRepository()
                          .list({
                            direction: 'asc',
                            page: 0,
                            limit: 100,
                            order: 'name',
                            active: true,
                          })
                          .then((data) =>
                            data.items.map((item) => ({ label: item.name, value: item.id }))
                          ),
                    },
                    {
                      type: 'float',
                      name: 'amount',
                      required: true,
                      description: true,
                    },
                    {
                      type: 'date',
                      name: 'estimatedPaymentDate',
                      format: 'YYYY-MM-DD',
                      required: true,
                      description: true,
                    },
                    {
                      type: 'select',
                      name: 'paymentStatus',
                      options: [
                        { label: t('common.paymentStatus.PAID'), value: 'PAID' },
                        { label: t('common.paymentStatus.PENDING'), value: 'PENDING' },
                      ],
                      required: true,
                      description: true,
                    },
                  ]}
                />
              );
            },
          },
          ActionUpdateMaintenancePart({
            actionId: maintenance.actionId,
            navigation: properties.navigation,
            route: properties.route,
            onActionUpdate: showSuccess,
          }),
        ]}
      />
    </ModalComponent>
  );
}
