import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useState } from 'react';
import { CreateTaskMassivelyRoutes } from '../../createTaskMassively.routes';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { useTranslation } from 'react-i18next';

export function ListCreateTaskMassivelysScreen(properties: {
  navigation: NavigationProp<CreateTaskMassivelyRoutes>;
  route: RouteProp<CreateTaskMassivelyRoutes, 'list'>;
}) {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<{ id: string; clinicId: string }[]>([]);

  const columns: ColumnProperties<LeadCrmType, ['contactName', 'created']>[] = [
    {
      type: 'selectable',
      selected: (row) => selectedItems.some((item) => item.id === row.id),
      onSelect: (item) => {
        setSelectedItems((items) =>
          items.some((selectedItem) => selectedItem.id === item.id)
            ? items.filter((selectedItem) => selectedItem.id !== item.id)
            : [...items, item]
        );
      },
    },
    {
      type: 'text',
      name: 'name',
      order: 'contactName',
      onRender: (row) => row.contactName,
      width: 200,
    },
    {
      type: 'clinic',
      name: 'clinicName',
      onRender: (row) => row.clinicName,
      width: 200,
    },
    {
      type: 'text',
      name: 'lastAction',
      width: 200,
      onRender: (row) => row.lastPendingAction?.activity.name ?? '',
    },
    {
      type: 'text',
      name: 'subject',
      width: 200,
      onRender: (row) => row.lastPendingAction?.subject ?? '',
    },
    {
      type: 'date',
      name: 'lastActionDate',
      order: 'created',
      renderDate: (row) =>
        row.lastPendingAction?.started ? new Date(row.lastPendingAction.started) : undefined,
    },
    {
      type: 'text',
      name: 'campaignName',
      width: 200,
      onRender: (row) => row.campaignName,
    },
    {
      type: 'text',
      name: 'phones',
      width: 200,
      // last phone added to the contact
      onRender: (row) =>
        row.phones && row.phones.length > 0
          ? `${row.phones[row.phones.length - 1].prefix} ${row.phones[row.phones.length - 1].phone}`
          : '',
    },
    {
      type: 'text',
      name: 'emails',
      width: 200,
      // last email added to the contact
      onRender: (row) =>
        row.emails && row.emails.length > 0 ? `${row.emails[row.emails.length - 1].email}` : '',
    },
    {
      type: 'date',
      filter: 'range',
      max: new Date(new Date().setDate(new Date().getDate() + 1)),
      // min: new Date(),
      name: 'created',
      order: 'created',
      renderDate: (row) => row.created,
    },
  ];

  return (
    <SceneComponent icon="tasks" prefix="crm.createTaskMassively.list">
      <TableComponent<LeadCrmType, ['contactName', 'created']>
        onSelectAllClick={(items) => {
          setSelectedItems(items);
        }}
        avoidRefresh
        dates
        prefix="crm.createTaskMassively.list"
        scrollable
        suffix="list"
        repository={(settings) =>
          new LeadCrmRepository().list({
            direction: settings.direction,
            order: settings.order || 'created',
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            dates: settings.dates,
            planned: settings.toggle,
            lastAction: true,
          })
        }
        columns={columns}
        actions={[
          {
            icon: 'stream',
            label: 'createTaskMassively.title',
            onPress: () => {
              if (selectedItems.length === 0) {
                alert(t('crm.createTaskMassively.list.noItemSelected'));
                return;
              }

              properties.navigation.navigate('createTasks', {
                leadsData: selectedItems.map((item) => ({
                  leadId: item.id,
                  clinicId: item.clinicId,
                })),
              });
            },
          },
        ]}
      />
    </SceneComponent>
  );
}
