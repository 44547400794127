import { ScrollView, Text, View } from 'react-native';
import { SubordinatePerformanceMonthStyle } from './subordinatePerformanceMonth.styles';
import { useTranslation } from 'react-i18next';

export function SubordinatePerformanceTable(properties: {
  data: {
    total: number;
  }[];
}) {
  const { t } = useTranslation();

  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column' }}>
        <View style={{ flex: 1, backgroundColor: 'white' }}>
          <View style={{ height: 20 }} />

          {Object.values(leadEvent).map((title, i) => (
            <View style={[SubordinatePerformanceMonthStyle.rowContainer]}>
              <View style={SubordinatePerformanceMonthStyle.subRowSpaceColumnContainer}>
                <Text style={[SubordinatePerformanceMonthStyle.subRowSpaceText]}>{title}</Text>
              </View>

              <View style={SubordinatePerformanceMonthStyle.subRowColumnContainer}>
                <Text style={[SubordinatePerformanceMonthStyle.subRowText]}>
                  {properties.data[i + 2].total}
                </Text>
              </View>
              <View style={SubordinatePerformanceMonthStyle.subRowDivider} />
            </View>
          ))}
        </View>
      </ScrollView>
    </>
  );
}
