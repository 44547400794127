import { TabType } from '@components/tabs/tabs.types';
import { TaggedNotesComponent } from '@components/taggedNotes/tagged.notes.component';
import { PatientNoteCrmRepository } from '@human/crm/repositories/patientNote/patientNote.repository';

export function NotesUpdatePatientPart(properties: {
  patientId: string;
  clinicId: string;
}): TabType {
  return {
    name: 'notes',
    icon: 'sticky-note',
    content: () => (
      <TaggedNotesComponent
        repository={{
          get: () =>
            new PatientNoteCrmRepository().findByPatient({
              patientId: properties.patientId,
              clinicId: properties.clinicId,
            }),
          send: (settings: { text: string; tag: string }) =>
            new PatientNoteCrmRepository().create({
              item: {
                text: settings.text,
                tag: settings.tag,
                patientId: properties.patientId,
                clinicId: properties.clinicId,
              },
            }),
          update: (settings: { text: string; noteId: string; tag: string }) =>
            new PatientNoteCrmRepository().update({
              text: settings.text,
              patientNoteId: settings.noteId,
              patientId: properties.patientId,
              clinicId: properties.clinicId,
              tag: settings.tag,
            }),
          delete: (noteId: string) =>
            new PatientNoteCrmRepository().delete({
              patientNoteId: noteId,
              patientId: properties.patientId,
              clinicId: properties.clinicId,
            }),
        }}
      />
    ),
  };
}
