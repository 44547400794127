import { SafeAreaView, View } from 'react-native';
import { BubbleComponent } from './components/bubble/bubble.component';
import { useTranslation } from 'react-i18next';
import { DockStyles } from './dock.styles';
import { useNavigation } from '@react-navigation/native';
import { useUserState } from '@states/user/user.state';
import { useNotificationState } from '@states/notification/notification.state';
import { DefaultLanguage } from '@constants/defaultLanguage.constant';

/**
 * Dock Component
 * @description menú lateral para navegar a pantallas de soporte y configuración de la APP.
 * @returns {JSX.Element}
 */

export function DockComponent() {
  const { i18n } = useTranslation();
  const { user, update, permissions } = useUserState();
  const { count } = useNotificationState();

  const renderIdiomBubbles = () => {
    return user.idioms.length ? (
      user.idioms.map((language) => (
        <BubbleComponent
          key={language}
          text={language}
          primary={i18n.language === language.toLowerCase()}
          onPress={() => {
            update({
              user: {
                ...user,
                selectedIdiom: language.toLowerCase(),
              },
              permissions,
            });
            i18n.changeLanguage(language.toLowerCase());
          }}
        />
      ))
    ) : (
      <BubbleComponent
        text={DefaultLanguage.toUpperCase()}
        primary={i18n.language === DefaultLanguage}
        onPress={() => {
          i18n.changeLanguage(DefaultLanguage);
        }}
      />
    );
  };

  const navigation = useNavigation<any>();
  return (
    <View style={DockStyles.container}>
      <SafeAreaView />
      <BubbleComponent
        primary
        icon="arrow-left"
        onPress={() => {
          navigation.navigate('desktop');
        }}
      />
      <BubbleComponent icon="plus" onPress={() => {}} />
      <BubbleComponent
        icon="bell"
        counter={count}
        onPress={() => {
          navigation.navigate('notifications');
        }}
      />
      <BubbleComponent icon="bug" onPress={() => {}} />

      <View style={DockStyles.line} />
      {renderIdiomBubbles()}
      <View style={DockStyles.lineTop} />
      <BubbleComponent
        icon="book"
        onPress={() => {
          navigation.navigate('information');
        }}
      />
      <BubbleComponent
        icon="info-circle"
        onPress={() => {
          navigation.navigate('faq');
        }}
      />
      <BubbleComponent icon="copyright" onPress={() => {}} />
      <BubbleComponent icon="times" onPress={() => {}} />
    </View>
  );
}
