// Modules
import { StyleSheet } from 'react-native';

/**
 * Action Styles
 * @constant {StyleSheet} ActionStyles
 */
export const ActionStyles = StyleSheet.create({
  action: {
    paddingHorizontal: 20,
    padding: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.758)',
    borderColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: 1,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    height: 42,
    maxHeight: 42,
  },
  actionText: {
    color: '#666',
  },
  actionIcon: {
    color: 'rgba(0,0,0,0.2)',
    fontSize: 20,
  },
  bubble: {
    backgroundColor: 'rgb(237, 53, 145)',
    color: 'rgb(255, 255, 255)',
    borderRadius: 50,
    padding: 5,
    paddingHorizontal: 10,
    fontSize: 12,
  },
});
