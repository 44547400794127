import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TechniqueRoutes } from '../../technique.routes';
import { FormComponent } from '@components/form/form.component';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';

export function CreateTechniqueScreen(properties: {
  navigation: NavigationProp<TechniqueRoutes>;
  route: RouteProp<TechniqueRoutes, 'techniqueCreate'>;
}) {
  return (
    <ModalComponent icon="tasks" prefix="crm.techniques.create">
      <FormComponent<Partial<TechniqueCrmType>>
        prefix="crm.techniques.create.form"
        padding
        repository={{
          send: (settings) => {
            return new TechniqueCrmRepository()
              .create({
                item: {
                  ...settings.item,
                },
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            name: 'price',
            type: 'float',
            required: true,
            validations: ['price'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            required: true,
            validations: ['description'],
            lines: 6,
          },
        ]}
      />
    </ModalComponent>
  );
}
