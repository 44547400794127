import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ProgramsRoutes } from '../../../../programs.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
import { ProgramTechniqueCrmRepository } from '@human/crm/repositories/programTechnique/programTechnique.repository';

export function CreateProgramTechniqueScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programTechniqueCreate'>;
}) {
  return (
    <ModalComponent icon={CrmSections.programs.icon} prefix="crm.programs.update.techniques.create">
      <FormComponent<Partial<TechniqueCrmType>>
        padding
        repository={{
          send: (data) => {
            return new ProgramTechniqueCrmRepository()
              .create({
                programId: properties.route.params.programId,
                techniqueId: data.item.id!,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        prefix="crm.programs.update.techniques.create.form"
        fields={[
          {
            name: 'id',
            type: 'select',
            required: true,
            repository: ({ search }) => {
              return new TechniqueCrmRepository()
                .list({
                  search,
                  limit: 100,
                  active: true,
                })
                .then((response) => {
                  return response.items.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }));
                });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
