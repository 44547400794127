export const FrLocale = {
  logotype: {
    horse: 'Ovocheval',
    human: 'Ovoclinique',
    ovobank: 'Ovobanque',
    ovoclinic: 'Ovoclinique',
  },
  calendar: {
    hour: 'Heure',
    minutes: ':',
    save: 'Garder',
    placeholder: 'Sélectionnez une date',
  },
  historical: {
    deleted: 'Supprimé',
    created: 'Créé',
    updated: 'Mis à jour',
  },
  chat: {
    placeholder: 'Écrire un message',
    title: 'Contacter le chat',
  },
  slicer: {
    confirm: 'Sélectionner',
  },
  export: {
    title: 'Exporter',
    description: 'Exporter les données du tableau vers un fichier CSV',
  },
  import: {
    title: 'Matière',
  },
  transfer: {
    title: 'Transfert',
  },
  createTaskMassively: {
    title: 'Créer des tâches',
  },
  leadEvents: {
    informed: 'V.I commercial réalisé',
    contacted: 'Contacté',
    attended: 'V.I médicale réalisée',
    appointed: 'Publicité V.I citée',
    medicalAppointed: 'Cité V.I Medical',
    medicalEvaluated: 'Évaluation médicale',
    comercialAttended: 'Budget respecté',
    converted: 'Converti',
  },
  form: {
    tabChange: {
      title: 'Vous modifiez un formulaire. ',
    },
    update: {
      successToast: 'Formulaire mis à jour avec succès',
    },
    checkbox: {
      errors: {
        required: 'Vous devez accepter de continuer',
      },
    },
    selectable: {
      placeholder: {
        title: 'Sélectionnez une option',
        empty: "Il n'y a aucun résultat",
      },
    },
    date: {
      placeholder: 'Filtrer par date',
    },
    json: {
      errors: {
        geojson: 'Format GeoJSON invalide',
      },
    },
    text: {
      errors: {
        notString: 'La valeur doit être une chaîne de texte',
        ipIsRequired: "L'adresse IP est requise",
        ipMustBeAString: "L'adresse IP doit être une chaîne de texte",
        ipMustBeAValidIpAddress: "L'adresse IP doit être valide",
        countryNameEn: 'Le nom est obligatoire',
        countryNameEs: 'Le nom est obligatoire',
        nameIsRequired: 'Le nom est obligatoire',
        nameMustBeAString: 'Le nom doit être une chaîne de texte',
        nameMustNotContainNumbers: 'Le nom ne doit pas contenir de chiffres',
        nameMustNotContainSpecialCharacters: 'Le nom ne doit pas contenir de caractères spéciaux',
        nameMustBeAtMost50CharactersLong: 'Le nom doit comporter un maximum de 50 caractères',
        dniIsRequired: "Une pièce d'identité est requise",
        dniMustBeAtLeast8CharactersLong: "L'identifiant doit comporter au moins 8 caractères",
        dniMustBeAtMost20CharactersLong: "L'identifiant doit comporter un maximum de 20 caractères",
        surnameIsRequired: 'Le nom de famille est obligatoire',
        surnameMustBeAString: 'Le nom de famille doit être une chaîne de texte',
        surnameMustNotContainNumbers: 'Le nom de famille ne doit pas contenir de chiffres',
        surnameMustNotContainSpecialCharacters:
          'Le nom de famille ne doit pas contenir de caractères spéciaux',
        surnameMustBeAtMost50CharactersLong:
          'Le nom de famille doit comporter au maximum 50 caractères',
        emailIsRequired: "L'e-mail est requis",
        emailMustBeAString: "L'e-mail doit être une chaîne de texte",
        emailMustBeAValidEmail: "L'e-mail doit être valide",
        emailMustBeAtLeast3CharactersLong: "L'e-mail doit comporter au moins 3 caractères",
        emailMustBeAtMost50CharactersLong: "L'email doit comporter au maximum 30 caractères",
        emailMustContainAnAtSymbol: "L'e-mail doit contenir un symbole @",
        emailMustNotContainWhitespace: "L'e-mail ne doit pas contenir d'espaces",
        passwordIsRequired: 'Le mot de passe est requis',
        passwordMustBeAString: 'Le mot de passe doit être une chaîne de texte',
        passwordMustBeAtLeast8CharactersLong: 'Le mot de passe doit contenir au moins 8 caractères',
        passwordMustBeAtMost30CharactersLong:
          'Le mot de passe doit comporter un maximum de 30 caractères',
        passwordMustContainAnUppercaseLetter: 'Le mot de passe doit contenir une lettre majuscule',
        passwordMustContainALowercaseLetter: 'Le mot de passe doit contenir une lettre minuscule',
        passwordMustContainANumber: 'Le mot de passe doit contenir un chiffre',
        passwordMustContainASpecialCharacter: 'Le mot de passe doit contenir un caractère spécial',
        passwordMustNotContainWhitespace: "Le mot de passe ne doit pas contenir d'espaces vides",
        passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
        valueMustBeAnInteger: 'La valeur doit être un entier',
        valueMustBeAValidNumber: 'La valeur doit être un nombre valide',
        valueMustBePositive: 'La valeur doit être positive',
        TEXT_TOO_SHORT: 'Le texte est trop court',
        WRONG_EMAIL: "Le format de l'e-mail est incorrect",
        REQUIRED: 'Ce champ est obligatoire',
        titleMustBeAString: 'Le titre doit être une chaîne de texte',
        titleIsRequired: 'Le titre est requis',
        titleMustBeAtLeast3CharactersLong: 'Le titre doit comporter au moins 3 caractères',
        titleMustBeAtMost100CharactersLong: 'Le titre doit comporter un maximum de 100 caractères',
        codeMustBeAString: 'Le code doit être une chaîne de texte',
        codeIsRequired: 'Le code est obligatoire',
        codeMustBeAtLeast3CharactersLong: 'Le code doit comporter au moins 3 caractères',
        codeMustBeAtLeast10CharactersLong: 'Le code doit comporter au moins 10 caractères',
        codeMustBeAtMost30CharactersLong: 'Le code doit comporter un maximum de 30 caractères',
        prefixIsRequired: 'Le préfixe est obligatoire',
        prefixInvalidLength: 'Le préfixe doit être composé de 2 ou 3 chiffres',
        prefixMustContainOnlyNumbers: 'Le préfixe ne doit contenir que des chiffres',
        prefixFirstCharacterMustBePlusSign: 'Le préfixe doit commencer par le signe +',
        prefixMustBeAtLeast1CharactersLong: 'Le préfixe comporte au moins 1 chiffre',
        prefixMustBeAtMost3CharactersLong: 'Le préfixe comporte au maximum 3 chiffres',
        countryCodeMustBeAString: 'Le code du pays doit être une chaîne de texte',
        countryCodeIsRequired: 'Le code du pays est requis',
        countryCodeMustBe2CharactersLong: 'Le code du pays doit comporter 2 caractères',
        countryCodeMustBeUppercase: 'Le code du pays doit être en majuscules',
        phoneIsRequired: 'Le téléphone est requis',
        phoneMustBeAString: 'Le téléphone doit être une chaîne de texte',
        phoneMustContainOnlyNumbers: 'Le téléphone ne doit contenir que des chiffres',
        phoneMustNotContainWhitespace: "Le téléphone ne doit pas contenir d'espaces vides",
        phoneMustBeAtLeast9CharactersLong:
          'Le numéro de téléphone doit comporter au moins 9 chiffres',
        phoneMustBeAtMost12CharactersLong:
          'Le numéro de téléphone doit comporter au maximum 12 chiffres',
        extensionIsRequired: 'Une prolongation est requise',
        extensionMustContainOnlyNumbers: "L'extension doit contenir uniquement des chiffres",
        extensionMustBeAtLeast4CharactersLong: "L'extension doit comporter au moins 4 chiffres",
        descriptionIsRequired: 'Une description est requise',
        descriptionMustBeAString: 'La description doit être une chaîne de texte',
        descriptionMustBeAtMost500CharactersLong:
          'La description doit comporter un maximum de 500 caractères',
        observationIsRequired: 'Une observation est requise',
        observationMustBeAString: "L'observation doit être une chaîne de texte",
        observationMustBeAtLeast1CharactersLong:
          "L'observation doit comporter au moins 1 caractère",
        observationMustBeAtMost150CharactersLong:
          'Le commentaire doit comporter au maximum 150 caractères',
        observationMustBeAtMost500CharactersLong:
          'Le commentaire doit comporter un maximum de 500 caractères',
        usernameIsRequired: "Le nom d'utilisateur est requis",
        usernameMustBeAString: "Le nom d'utilisateur doit être une chaîne de texte",
        usernameMustBeAtLeast3CharactersLong:
          "Le nom d'utilisateur doit comporter au moins 3 caractères",
        usernameMustBeAtMost30CharactersLong:
          "Le nom d'utilisateur doit comporter un maximum de 30 caractères",
        usernameMustNotContainSpecialCharacters:
          "Le nom d'utilisateur ne doit pas contenir de caractères spéciaux",
        usernameMustNotContainWhitespace: "Le nom d'utilisateur ne doit pas contenir d'espaces",
        zipIsRequired: 'Le code postal est requis',
        zipMustBeAString: 'Le code postal doit être une chaîne de texte',
        zipMustBe5CharactersLong: 'Le code postal doit comporter 5 caractères',
        zipMustContainOnlyNumbers: 'Le code postal ne doit contenir que des chiffres',
        zipMustNotContainWhitespace: "Le code postal ne doit pas contenir d'espaces vides",
        zipMustNotContainSpecialCharacters:
          'Le code postal ne doit pas contenir de caractères spéciaux',
        streetIsRequired: 'La rue est obligatoire',
        streetMustBeAString: 'La rue doit être une chaîne de texte',
        streetMustBeAtLeast8CharactersLong: 'La rue doit comporter au moins 8 caractères',
        cityIsRequired: 'La ville est obligatoire',
        cityMustBeAString: 'La ville doit être une chaîne de texte',
        stateIsRequired: "L'État est tenu",
        stateMustBeAString: 'Le statut doit être une chaîne de texte',
        coinIsRequired: 'La devise est requise',
        coinMustBeAString: 'La devise doit être une chaîne de texte',
        flagIsRequired: 'Le drapeau est obligatoire',
        flagMustBeAString: 'Le drapeau doit être une chaîne de texte',
        subjectIsRequired: "L'affaire est requise",
        subjectMustBeAString: 'Le sujet doit être une chaîne de texte',
        subjectMustBeAtLeast3CharactersLong: 'Le sujet doit comporter au moins 3 caractères',
        subjectMustBeAtMost30CharactersLong: 'Le sujet doit comporter au maximum 30 caractères',
        messageIsRequired: 'Le message est obligatoire',
        messageMustBeAString: 'Le message doit être une chaîne de texte',
        messageMustBeAtLeast3CharactersLong: 'Le message doit comporter au moins 3 caractères',
        messageMustBeAtMost500CharactersLong:
          'Le message doit comporter un maximum de 500 caractères',
        hourIsRequired: 'Il faut du temps',
        hourMustBeValidFormat: "L'heure doit être dans un format valide : HH:MM",
        documentIsRequired: 'Le document est obligatoire',
        documentMustBeAString: 'Le document doit être une chaîne de texte',
        documentMustBeAtLeast8CharactersLong: 'Le document doit comporter au moins 8 caractères',
        codeMustBeAtLeast2CharactersLong: 'Le code doit comporter au moins 2 caractères',
        required: 'Ce champ est obligatoire',
        name: {
          notString: 'Le nom doit être une chaîne de texte',
          tooShort: 'Le nom est trop court',
          tooLong: 'Le nom est trop long',
          invalidCharacters: 'Le nom contient des caractères invalides',
        },
        email: {
          notValid: "L'e-mail n'est pas valide",
          notString: "L'e-mail doit être une chaîne de texte",
        },
        phone: {
          invalid: "Le téléphone n'est pas valide",
          notString: 'Le téléphone doit être une chaîne de texte',
        },
        surname: {
          notString: 'Le nom de famille doit être une chaîne de texte',
          tooShort: 'Le nom de famille est trop court',
          tooLong: 'Le nom de famille est trop long',
          invalidCharacters: 'Le nom de famille contient des caractères non valides',
        },
        boolean: {
          notBoolean: 'La valeur doit être un booléen',
          truthy: {
            notTruthy: 'La valeur doit être vraie',
          },
        },
        password: {
          notString: 'Le mot de passe doit être une chaîne de texte',
          tooShort: 'Le mot de passe est trop court',
          tooLong: 'Le mot de passe est trop long',
          invalidCharacters: 'Le mot de passe contient des caractères invalides',
          noUpperCase: 'Le mot de passe doit contenir au moins une lettre majuscule',
          noLowerCase: 'Le mot de passe doit contenir au moins une lettre minuscule',
          noNumber: 'Le mot de passe doit contenir au moins un chiffre',
          noSpecialCharacter: 'Le mot de passe doit contenir au moins un caractère spécial',
        },
        number: {
          notNumber: 'La valeur doit être un nombre',
        },
        positive: {
          notPositive: 'La valeur doit être positive',
          notNumber: 'La valeur doit être un nombre',
        },
        price: {
          notNumber: 'La valeur doit être un nombre',
          notPositive: 'La valeur doit être positive',
          notString: 'La valeur doit être une chaîne de texte',
        },
        negative: {
          notNegative: 'La valeur doit être négative',
          notNumber: 'La valeur doit être un nombre',
        },
        uuid: {
          notUuid: 'La valeur doit être un UUID',
          notString: 'La valeur doit être une chaîne de texte',
          empty: 'La valeur ne peut pas être vide',
        },
        object: {
          notValid: 'La valeur doit être un objet',
        },
        description: {
          notString: 'La description doit être une chaîne de texte',
          tooShort: 'La description est trop courte',
          tooLong: 'La description est trop longue',
        },
        observation: {
          notString: "L'observation doit être une chaîne de texte",
          tooShort: "L'observation est trop courte",
          tooLong: "L'observation est trop longue",
        },
        date: {
          notValid: "La date n'est pas valide",
          notString: 'La date doit être une chaîne de texte',
        },
        color: {
          notString: 'La couleur doit être une chaîne de texte',
          notHex: 'La couleur doit être un code hexadécimal',
        },
        code: {
          notString: 'Le code doit être une chaîne de texte',
          tooShort: 'Le code est trop court',
          tooLong: 'Le code est trop long',
        },
        image: {
          notString: "L'image doit être une chaîne de texte",
          notBase64: "L'image doit être un code base64",
        },
        phonePrefix: {
          notString: 'Le préfixe doit être une chaîne de texte',
          notValid: "Le préfixe n'est pas valide",
        },
        countryCode: {
          notString: 'Le code du pays doit être une chaîne de texte',
          notValid: "Le code du pays n'est pas valide",
        },
        zip: {
          notString: 'Le code postal doit être une chaîne de texte',
          notLength: 'Le code postal doit comporter 5 caractères',
          notNumber: 'Le code postal ne doit contenir que des chiffres',
          hasSpace: "Le code postal ne doit pas contenir d'espaces vides",
          hasLetter: 'Le code postal ne doit pas contenir de lettres',
          hasSymbol: 'Le code postal ne doit pas contenir de symboles',
        },
        title: {
          notString: 'Le titre doit être une chaîne de texte',
          tooShort: 'Le titre est trop court',
          tooLong: 'Le titre est trop long',
        },
        username: {
          notString: "Le nom d'utilisateur doit être une chaîne de texte",
          tooShort: "Le nom d'utilisateur est trop court",
          tooLong: "Le nom d'utilisateur est trop long",
          invalidCharacters: "Le nom d'utilisateur contient des caractères invalides",
        },
        ip: {
          notString: "L'adresse IP doit être une chaîne de texte",
          notValid: "L'adresse IP n'est pas valide",
        },
        state: {
          notString: 'Le statut doit être une chaîne de texte',
        },
        city: {
          notString: 'La ville doit être une chaîne de texte',
        },
        street: {
          notString: 'La rue doit être une chaîne de texte',
        },
        coin: {
          notString: 'La devise doit être une chaîne de texte',
        },
        flag: {
          notString: 'Le drapeau doit être une chaîne de texte',
        },
      },
    },
  },
  analytics: {
    title: 'Analytique',
    filter: 'Comparaison entre la semaine dernière et cette semaine',
    activities: {
      title: 'Tâches',
    },
    leads: {
      title: 'Conduit',
    },
    results: {
      title: 'Résultats',
    },
  },
  history: {
    title: 'Enregistrer',
  },
  performance: {
    title: 'Performance',
  },
  sessions: {
    title: 'Séances actives',
  },
  security: {
    title: 'Système sécurisé',
  },
  launch: {
    title: "L'avenir de la procréation assistée",
    subtitle: 'Traçabilité et sécurité pour votre clinique',
    description: {
      ovoclinic:
        'Logiciel sécurisé appartenant à Ovoclinic pour la gestion des cliniques de procréation assistée. ',
      ovobank: "Logiciel sécurisé appartenant à Ovobank pour la gestion des banques d'ovules. ",
    },
    crm: {
      description: "Espace pour la gestion des clients et l'amélioration de la relation avec eux. ",
    },
    clinic: {
      description:
        'Tout ce dont vous avez besoin pour gérer votre clinique, hôpital ou centre médical. ',
    },
    access: {
      description: "Contrôle d'accès et gestion des utilisateurs. ",
    },
    board: {
      description: 'Tableau de contrôle et suivi des indicateurs. ',
    },
    tory: {
      description: 'Résultats cliniques et traçabilité des tests de laboratoire. ',
    },
    profile: {
      description: 'Gestion des informations personnelles et du compte. ',
    },
  },
  access: {
    menu: {
      users: 'Utilisateurs',
      roles: 'Rôles',
      specialists: 'Spécialistes',
      clinics: 'Cliniques',
      planning: 'Planification',
      timetables: 'Horaires',
      bulletins: 'Nouvelles',
      departments: 'Départements',
      languages: 'Langues',
      countries: 'Pays',
      settings: 'Configuration',
      directory: 'Annuaire',
      locations: 'Emplacements',
      globalization: 'Mondialisation',
      communications: 'Communications',
      communicationChannels: 'Voies de communication',
      ovopoints: 'Ovopoints',
      landbot: 'Robot terrestre',
      templates: 'Modèles',
    },
    users: {
      list: {
        chart: {
          user: 'Utilisateurs',
          movement: 'Mouvements',
        },
        month: {
          '0': 'Janvier',
          '1': 'Février',
          '2': 'Mars',
          '3': 'Avril',
          '4': 'Peut',
          '5': 'Juin',
          '6': 'Juillet',
          '7': 'Août',
          '8': 'Septembre',
          '9': 'Octobre',
          '10': 'Novembre',
          '11': 'Décembre',
          '12': 'Total',
        },
        year: {
          '0': '2020',
          '1': '2021',
          '2': '2022',
          '3': '2023',
          '4': '2024',
          '5': '2025',
          '6': '2026',
          '7': '2027',
          '8': '2028',
          '9': '2029',
          '10': '2030',
          '11': '2031',
        },
        columns: {
          name: 'Nom',
          email: 'E-mail',
          phone: 'Téléphone',
          status: 'État',
          createdAt: 'Créé',
          updatedAt: 'Mis à jour',
          expiration: 'Expiration',
          created: 'Création',
          lastMove: 'Dernier coup',
        },
        title: 'Utilisateurs',
        description: 'Gestion du personnel et leur accès au système',
        create: 'Utilisateur',
        one: 'Utilisateur',
        many: 'Utilisateurs',
        cloneConfirm: 'Êtes-vous sûr de vouloir cloner cet utilisateur ?',
        cloneSuccess: 'Utilisateur cloné avec succès',
      },
      create: {
        title: 'Nouvel utilisateur',
        subtitle: "Enregistrement d'un nouvel utilisateur dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'utilisateur",
          },
          surname: {
            title: 'Noms de famille',
            description: "Nom de famille de l'utilisateur",
          },
          password: {
            title: 'Mot de passe',
            description:
              'Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre.',
          },
          username: {
            title: "Nom d'utilisateur",
            description: "Nom d'utilisateur pour accéder au système",
          },
          email: {
            title: 'E-mail',
            description: "Adresse e-mail où l'utilisateur recevra les notifications du système",
          },
          phone: {
            title: 'Téléphone',
            description: "Numéro de contact de l'utilisateur",
          },
          extension: {
            title: 'Extension',
            description: "Extension d'identification au sein du département",
          },
          signature: {
            title: 'Mot de passe',
            description: 'Mot de passe utilisateur',
          },
          expiration: {
            title: "Date d'expiration",
            description: "Date d'expiration du compte de l'utilisateur",
          },
          color: {
            title: 'Couleur',
            description: "Couleur de l'utilisateur dans le système",
          },
          icon: {
            title: 'Icône',
            label: "Sélectionner l'icône",
            description: "Icône d'identification de l'utilisateur dans le système",
            search: {
              placeholder: 'Icône de recherche',
            },
          },
          status: {
            title: 'Accès au système',
            description: "Activer ou désactiver l'accès au système pour cet utilisateur",
            label: "Autoriser l'accès au système",
          },
          movementLimit: {
            title: 'Limite de mouvement',
            description: 'Nombre de requêtes autorisées pour cet utilisateur',
          },
          attemptLimit: {
            title: 'Limite de tentative',
            description: 'Nombre de tentatives autorisées pour se connecter',
          },
          submit: 'Créer un utilisateur',
        },
      },
      delete: {
        title: "Désactiver l'utilisateur",
        subtitle: 'Cette action est réversible',
        form: {
          status: {
            title: 'Etes-vous sûr de vouloir désactiver cet utilisateur ?',
            description:
              "Désactiver un utilisateur signifie qu'il ne pourra pas accéder au système. ",
            label: 'Oui, je souhaite désactiver cet utilisateur',
          },
          submit: 'Désactiver',
        },
        passwordForm: {
          title: 'Entrez votre mot de passe',
          subtitle: "Pour désactiver l'utilisateur, entrez votre mot de passe",
          password: {
            title: 'Mot de passe',
            description: "Mot de passe de l'utilisateur qui effectue l'action",
          },
          submit: 'Confirmer',
        },
      },
      enable: {
        title: "Activer l'utilisateur",
        subtitle: "Cette action permet à l'utilisateur d'accéder au système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cet utilisateur ?',
            description: "L'activation d'un utilisateur signifie qu'il pourra accéder au système. ",
            label: 'Oui, je souhaite activer cet utilisateur',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'utilisateur",
        subtitle: "Cette action empêche l'utilisateur d'accéder au système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le désactiver ?',
            description:
              "Désactiver un utilisateur signifie qu'il ne pourra pas accéder au système. ",
            label: 'Oui, je souhaite désactiver cet utilisateur',
          },
          submit: 'Désactiver',
        },
      },
      update: {
        access: 'Accéder',
        account: {
          tab: 'Compte',
          description: 'Informations sur le compte utilisateur',
        },
        tabs: {
          account: {
            title: 'Compte',
            description: "Détails du compte personnel de l'utilisateur ayant accès au système.",
          },
          licenses: {
            title: 'Licences',
            description:
              "Une licence détermine les actions qu'un utilisateur peut effectuer et l'accès dont il dispose au système. ",
          },
          contracts: {
            title: 'Contrats',
            description: "Un contrat est un accord entre l'utilisateur et la clinique. ",
          },
          movements: {
            title: 'Trafic',
            description: 'Le système surveille les mouvements des utilisateurs. ',
          },
          attempts: {
            title: 'Tentatives',
            description:
              "Pour garantir la sécurité du système, les tentatives d'accès au système sont surveillées.",
          },
          languages: {
            title: 'Langues',
            description: "Langues parlées par l'utilisateur",
          },
          timetables: {
            title: 'Horaires',
            description:
              "Les horaires sont les intervalles de temps pendant lesquels l'utilisateur a accès au système.",
          },
          changePassword: {
            title: 'Mot de passe',
            description: 'Changer le mot de passe utilisateur',
          },
          activity: {
            title: 'Activité',
            description:
              "Si une activité est définie, lorsque la personne responsable du Lead sera affectée à cet utilisateur, une tâche sera créée automatiquement avec l'activité qui a été définie.",
          },
          integrations: {
            title: 'Intégrations',
            description:
              'Configuration au niveau utilisateur pour pouvoir réaliser les différentes intégrations mises en œuvre dans le CRM. ',
          },
        },
        schedules: {
          create: {
            title: 'Ajouter un horaire',
            subtitle: "Ajouter un horaire à l'utilisateur",
            form: {
              timetableId: {
                title: 'Sélectionnez une heure',
                description: "Modèle de temps dans lequel l'utilisateur a accès au système",
              },
              submit: 'Ajouter un horaire',
            },
          },
          delete: {
            title: 'Dissocier le calendrier',
            subtitle: 'Dissocier le planning utilisateur',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                label: "Oui, je souhaite dissocier ce planning de l'utilisateur",
              },
              submit: 'Dissocier le calendrier',
            },
          },
        },
        languages: {
          add: 'Ajouter une langue',
          create: {
            title: 'Ajouter une langue',
            subtitle: "Ajouter la langue parlée par l'utilisateur",
            form: {
              languageId: {
                title: 'Langue',
                description: "Langue parlée par l'utilisateur",
              },
              submit: 'Ajouter',
            },
          },
          delete: {
            title: 'Supprimer la langue',
            subtitle: 'Supprimer la langue de cet utilisateur',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                description: 'Cette action est irréversible.',
                label: 'Oui, je veux supprimer cette langue',
              },
              submit: 'Confirmer',
            },
          },
        },
        roles: 'Rôles',
        title: "Mettre à jour l'utilisateur",
        subtitle: "Modification des données d'un utilisateur dans le système",
        licenses: {
          tab: 'Licences',
          description:
            "Les licences définissent les actions que les utilisateurs peuvent effectuer au niveau du groupe, c'est-à-dire qu'il s'agit d'autorisations qui n'impliquent pas les cliniques.",
          add: 'Ajouter',
          create: {
            title: 'Nouvelle licence',
            subtitle: "Enregistrement d'une nouvelle licence dans le système.",
            form: {
              roleId: {
                title: 'Rôle',
                description:
                  "Le rôle est l'ensemble des autorisations dont l'utilisateur disposera dans le système",
              },
              submit: 'Créer une licence',
            },
          },
          delete: {
            title: 'Supprimer la licence',
            subtitle: 'Cette action est irréversible',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                description: 'Vous préférerez peut-être désactiver cette licence',
                label: 'Oui, je veux supprimer cette licence',
              },
              submit: 'Supprimer la licence',
            },
          },
        },
        permissions: {
          add: 'Ajouter une licence',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Robot terrestre',
              description: 'Vous êtes un utilisateur de LandBot',
              label: 'Oui, vous êtes un utilisateur LandBot',
            },
            submit: 'Mettre à jour les intégrations',
          },
        },
        contracts: {
          tab: 'Contrats',
          description: "Les contrats sont des accords entre l'utilisateur et la clinique. ",
          add: 'Ajouter un contrat',
          addAll: 'Ajouter tous les contrats',
          create: {
            title: 'Nouveau contrat',
            subtitle: "Enregistrement d'un nouveau contrat dans le système.",
            form: {
              clinicId: {
                title: 'Clinique',
              },
              submit: 'Créer un contrat',
            },
          },
          disable: {
            title: 'Désactiver le contrat',
            subtitle: "Cette action empêche l'utilisateur d'accéder à la clinique",
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le désactiver ?',
              },
              submit: 'Désactiver le contrat',
            },
          },
          enable: {
            title: 'Activer le contrat',
            subtitle: "Cette action permet à l'utilisateur d'accéder à la clinique",
            form: {
              status: {
                title: "Êtes-vous sûr de vouloir l'activer ?",
              },
              submit: 'Activer le contrat',
            },
          },
        },
        timetables: {
          add: 'Ajouter un horaire',
          create: {
            title: 'Nouvel horaire',
            subtitle: "Enregistrer un nouvel horaire pour l'utilisateur",
            form: {
              day: {
                title: 'Jour',
                description: "Jour de la semaine où l'utilisateur a accès au système",
              },
              started: {
                title: 'Commencer',
                description: "Heure de début à laquelle l'utilisateur a accès au système",
              },
              ended: {
                title: 'Fin',
                description: "Heure de fin à laquelle l'utilisateur a accès au système",
              },
              submit: 'Créer un planning',
            },
          },
          delete: {
            title: 'Supprimer le planning',
            subtitle: 'Supprimer le planning utilisateur',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                label: 'Oui, je souhaite supprimer ce planning',
              },
              submit: 'Supprimer le planning',
            },
          },
        },
        movements: {
          tab: 'Mouvements',
          description: "Les mouvements sont les requêtes que l'utilisateur adresse au système. ",
        },
        attempts: {
          tab: 'Tentatives',
          description: "Les tentatives sont des accès au login de l'utilisateur. ",
        },
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'utilisateur",
          },
          surname: {
            title: 'Noms de famille',
            description: "Nom de famille de l'utilisateur",
          },
          password: {
            title: 'Mot de passe',
            description:
              'Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre.',
          },
          username: {
            title: "Nom d'utilisateur",
            description: "Nom d'utilisateur pour accéder au système",
          },
          email: {
            title: 'E-mail',
            description: "E-mail de l'utilisateur pour se connecter au système",
          },
          phone: {
            title: 'Téléphone',
            description: "Numéro de contact de l'utilisateur",
          },
          extension: {
            title: 'Extension',
            description: "Extension d'identification au sein du département",
          },
          signature: {
            title: 'Mot de passe',
            description: 'Mot de passe utilisateur',
          },
          expiration: {
            title: "Date d'expiration",
            description: "Date d'expiration du compte de l'utilisateur",
          },
          color: {
            title: 'Couleur',
            description: "Couleur de l'utilisateur dans le système",
          },
          status: {
            title: 'Accès au système',
            description: "Activer ou désactiver l'accès au système pour cet utilisateur",
            label: "Autoriser l'accès au système",
          },
          movementLimit: {
            title: 'Limite de mouvement',
            description: 'Nombre de requêtes autorisées pour cet utilisateur pendant 24 heures',
          },
          attemptLimit: {
            title: 'Limite de tentative',
            description: 'Nombre de tentatives de connexion autorisées pendant 24 heures',
          },
          submit: "Mettre à jour l'utilisateur",
        },
        activity: {
          form: {
            activityId: {
              title: 'Activité',
            },
            submit: 'Activité de mise à jour',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Mettre à jour les ovopoints',
        subtitle: "Modifier les ovopoints d'un utilisateur dans le système",
        form: {
          appointedPoints: {
            title: 'Piste citée',
            description: 'Ovopoints pour prendre rendez-vous',
          },
          attendedPoints: {
            title: "Le responsable s'est présenté au rendez-vous médical",
            description: 'Ovopoints pour assister à un rendez-vous médical',
          },
          commercialAttendedPoints: {
            title: 'Le responsable a eu un rendez-vous commercial',
            description: "Des Ovopoints pour assister à un rendez-vous d'affaires",
          },
          informedPoints: {
            title: 'Responsable informé',
            description: 'Ovopoints pour informer un lead',
          },
          contactedPoints: {
            title: 'Responsable contacté',
            description: 'Ovopoints pour contacter un lead',
          },
          convertedPoints: {
            title: 'Convertir le prospect',
            description: {
              ovoclinic: 'Ovopoints pour convertir un lead en patient',
              ovobank: 'Ovopoints pour convertir un lead en donateur',
            },
          },
          submit: 'Mettre à jour les ovopoints',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Voie de communication',
        title: 'Voies de communication',
        description: 'Gestion des canaux de communication',
        one: 'Voie de communication',
        many: 'Voies de communication',
        columns: {
          name: 'Nom',
          created: 'Date de création',
        },
      },
      create: {
        title: 'Nouvelle voie de communication',
        subtitle: "Enregistrement d'un nouveau canal de communication dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom du chemin de communication',
          },
          submit: 'Créer un canal de communication',
        },
      },
      update: {
        title: 'Mettre à jour le chemin de communication',
        subtitle: "Modification des données d'un chemin de communication dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom du chemin de communication',
          },
          submit: 'Mettre à jour le chemin de communication',
        },
      },
      enable: {
        title: 'Activer le chemin de communication',
        subtitle: 'Activer le chemin de communication dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce canal de communication ?',
            label: 'Oui, je souhaite activer ce canal de communication',
          },
          submit: 'Activer le chemin de communication',
        },
      },
      disable: {
        title: 'Désactiver le chemin de communication',
        subtitle: 'Désactiver le chemin de communication dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce chemin de communication ?',
            label: 'Oui, je souhaite désactiver ce canal de communication',
          },
          submit: 'Désactiver le chemin de communication',
        },
      },
    },
    specialists: {
      list: {
        create: 'Spécialiste',
        title: 'Spécialiste',
        description: 'Gestion spécialisée',
        one: 'Spécialiste',
        many: 'Spécialistes',
        columns: {
          name: 'Nom',
          surname: 'Noms de famille',
          dni: 'IDENTIFIANT',
          type: 'Gars',
        },
      },
      create: {
        title: 'Nouveau spécialiste',
        subtitle: "Enregistrement d'un nouveau spécialiste dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom du spécialiste',
          },
          surname: {
            title: 'Noms de famille',
            description: 'Nom du spécialiste',
          },
          dni: {
            title: 'IDENTIFIANT',
            description: "Pièce d'identité spécialisée",
          },
          type: {
            title: 'Gars',
            description: 'Type de spécialiste',
          },
          submit: 'Créer un spécialiste',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Spécialiste',
            description: 'Données personnelles du spécialiste',
          },
          contracts: {
            title: 'Contrats',
            description: 'Contrats spécialisés',
          },
        },
        contracts: {
          add: 'Ajouter un contrat',
          list: {
            one: 'Contracter',
            many: 'Contrats',
          },
          create: {
            title: 'Nouveau contrat',
            subtitle: "Enregistrement d'un nouveau contrat de spécialiste dans le système",
            form: {
              clinicId: {
                title: 'Clinique',
                description: 'Clinique à laquelle appartient le contrat',
              },
              submit: 'Créer un contrat',
            },
          },
          delete: {
            title: 'Supprimer le contrat',
            subtitle: 'Supprimer le contrat de ce spécialiste',
            form: {
              submit: 'Supprimer le contrat',
            },
          },
        },
        title: 'Spécialiste des mises à jour',
        subtitle: "Modification des données d'un spécialiste dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom du spécialiste',
          },
          surname: {
            title: 'Noms de famille',
            description: 'Nom du spécialiste',
          },
          dni: {
            title: 'IDENTIFIANT',
            description: "Pièce d'identité spécialisée",
          },
          type: {
            title: 'Gars',
            description: 'Type de spécialiste',
          },
          submit: 'Spécialiste des mises à jour',
        },
      },
      enable: {
        title: 'Activer le spécialiste',
        subtitle: 'Cette action permet au spécialiste du système',
        form: {
          submit: 'Activer le spécialiste',
        },
      },
      disable: {
        title: 'Désactiver le spécialiste',
        subtitle: 'Cette action désactive le spécialiste du système',
        form: {
          submit: 'Désactiver le spécialiste',
        },
      },
    },
    templates: {
      list: {
        title: 'Modèles',
        description: 'Gestion des modèles pour Landbot',
        create: 'Modèle',
        columns: {
          label: 'Nom',
        },
        one: 'Modèle',
        many: 'Modèles',
      },
      create: {
        title: 'Nouveau modèle',
        subtitle: "Enregistrement d'un nouveau modèle pour Landbot",
        form: {
          label: {
            title: 'Nom',
            description: 'Nom du modèle',
          },
          landbotId: {
            title: 'Identifiant du robot terrestre',
            description: 'ID Landbot auquel le modèle est associé',
          },
          submit: 'Créer un modèle',
        },
      },
      update: {
        title: 'Mettre à jour le modèle',
        subtitle: 'Modification des données du modèle pour Landbot',
        tabs: {
          template: {
            title: 'Modèle',
            description: 'Détails du modèle',
          },
          templateScope: {
            title: 'Portée',
            description: 'Portée du modèle',
          },
          templateParam: {
            title: 'Paramètres',
            description: 'Paramètres du modèle',
          },
        },
        form: {
          label: {
            title: 'Nom',
            description: 'Nom du modèle',
          },
          landbotId: {
            title: 'Identifiant du robot terrestre',
            description: 'ID Landbot auquel le modèle est associé',
          },
          id: {
            title: 'Modèle',
            description: 'Modèle à utiliser dans Landbot pour envoyer le message',
          },
          submit: 'Mettre à jour le modèle',
        },
      },
      disable: {
        title: 'Désactiver le modèle',
        subtitle: 'Désactiver le modèle pour Landbot',
        form: {
          submit: 'Désactiver le modèle',
        },
      },
      enable: {
        title: 'Activer le modèle',
        subtitle: 'Activer le modèle pour Landbot',
        form: {
          submit: 'Activer le modèle',
        },
      },
      delete: {
        title: 'Supprimer le modèle',
        subtitle: 'Supprimer le modèle Landbot',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            label: 'Oui, je souhaite supprimer ce modèle',
          },
          submit: 'Supprimer le modèle',
        },
      },
      send: {
        title: 'Envoyer le modèle',
        subtitle: 'Envoyer le modèle au contact',
        form: {
          id: {
            title: 'Modèle',
            description: 'Modèle à utiliser dans Landbot pour envoyer le message',
          },
          landbotId: {
            title: 'Identifiant du robot terrestre',
            description: 'ID du bot Landbot auquel le modèle est associé',
          },
          submit: 'Envoyer le modèle',
        },
      },
      templateScopes: {
        list: {
          title: 'Portées',
          description: 'Gestion des portées des modèles',
          create: 'Portée',
          columns: {
            label: 'Nom',
          },
          one: 'Portée',
          many: 'Portées',
        },
        create: {
          title: 'Nouvelle portée',
          subtitle: "Enregistrement d'une nouvelle étendue pour les modèles",
          form: {
            label: {
              title: 'Nom',
              description: 'Nom de la portée',
            },
            departmentId: {
              title: 'Département',
              description: "Département auquel le modèle s'appliquera",
            },
            submit: 'Créer une portée',
          },
        },
        update: {
          title: 'Portée de la mise à jour',
          subtitle: 'Modification des données dans une portée de modèle',
          form: {
            label: {
              title: 'Nom',
              description: 'Nom de la portée',
            },
            submit: 'Portée de la mise à jour',
          },
        },
        delete: {
          title: 'Supprimer la portée',
          subtitle: 'Supprimer la portée de ce modèle',
          form: {
            status: {
              title: 'Êtes-vous sûr de vouloir le supprimer ?',
              label: 'Oui, je souhaite supprimer cette étendue',
            },
            submit: 'Supprimer la portée',
          },
        },
        add: 'Ajouter une portée',
      },
      templateParams: {
        list: {
          title: 'Paramètres',
          description: 'Gestion des paramètres du modèle',
          create: 'Paramètre',
          columns: {
            label: 'Nom',
            order: 'Commande',
            templateKeyName: 'Paramètre',
          },
          one: 'Paramètre',
          many: 'Paramètres',
        },
        create: {
          title: 'Nouveau paramètre',
          subtitle: "Enregistrement d'un nouveau paramètre pour les modèles",
          form: {
            templateKeyId: {
              title: 'Paramètre',
              description: 'Paramètres possibles à attacher au modèle',
            },
            order: {
              title: 'Commande',
              description: 'Ordre dans lequel le paramètre sera affiché dans le modèle',
            },
            submit: 'Créer un paramètre',
          },
        },
        delete: {
          title: 'Supprimer le paramètre',
          subtitle: 'Supprimer le paramètre de ce modèle',
          form: {
            status: {
              title: 'Êtes-vous sûr de vouloir le supprimer ?',
              label: 'Oui, je souhaite supprimer ce paramètre',
            },
            submit: 'Supprimer le paramètre',
          },
        },
        add: 'Ajouter un paramètre',
      },
    },
    languages: {
      list: {
        create: 'Langue',
        title: 'Langue',
        description: 'Gestion des langues',
        one: 'Langue',
        many: 'Langues',
        columns: {
          name: 'Nom',
          country: 'Pays',
          code: 'Code',
        },
      },
      create: {
        title: 'Nouvelle langue',
        subtitle: 'Enregistrer une nouvelle langue dans le système',
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de la langue',
          },
          status: {
            title: 'État',
            label: 'État',
          },
          countryCode: {
            title: 'Pays',
            description: 'Pays auquel appartient la langue',
          },
          code: {
            title: 'Code',
            description: 'Code de langue',
          },
          submit: 'Créer une langue',
        },
      },
      update: {
        title: 'Mettre à jour la langue',
        subtitle: 'Modification des données linguistiques dans le système',
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de la langue',
          },
          countryCode: {
            title: 'Pays',
            description: 'Pays auquel appartient la langue',
          },
          code: {
            title: 'Code',
            description: 'Code de langue',
          },
          submit: 'Mettre à jour la langue',
        },
      },
      enable: {
        title: 'Activer la langue',
        subtitle: 'Activer la langue dans le système',
        form: {
          status: {
            title: 'Etes-vous sûr de vouloir activer cette langue ?',
            label: 'Oui, je veux activer cette langue',
          },
          submit: 'Activer la langue',
        },
      },
      disable: {
        title: 'Désactiver la langue',
        subtitle: 'Désactiver la langue dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette langue ?',
            label: 'Oui, je veux désactiver cette langue',
          },
          submit: 'Désactiver la langue',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'Nous avons de bonnes nouvelles',
      },
      create: {
        title: 'Nouvelles nouvelles',
        subtitle: 'Enregistrez les nouvelles nouvelles dans la clinique',
        steps: {
          clinic: {
            form: {
              title: 'Clinique',
              clinicId: {
                title: 'Clinique',
                description: 'Clinique à laquelle appartient la nouvelle',
              },
            },
          },
          actor: {
            form: {
              title: 'Copain',
              actorId: {
                title: 'Copain',
                description: "Partenaire, l'actualité concerne",
              },
            },
          },
        },
        form: {
          description: {
            title: 'Description',
            description:
              'Pour célébrer les réalisations au sein de la clinique, vous pouvez partager des informations telles que : des histoires de réussite, une reconnaissance personnelle et une gratitude.',
          },
          image: {
            title: 'Image',
            description: "Image d'actualité",
          },
          submit: 'Créer des actualités',
        },
      },
      update: {
        title: "Détails de l'actualité",
        subtitle: "Détails de l'actualité de la clinique",
        form: {
          clinicId: {
            title: 'Clinique',
            description: 'Clinique à laquelle appartient la nouvelle',
          },
          authorName: {
            title: 'Auteur',
            description: 'Auteur de la nouvelle',
          },
          actorName: {
            title: 'Copain',
            description: "Partenaire, l'actualité concerne",
          },
          description: {
            title: 'Description',
            description:
              'Pour célébrer les réalisations au sein de la clinique, vous pouvez partager des informations telles que : des histoires de réussite, une reconnaissance personnelle et une gratitude.',
          },
          image: {
            title: 'Image',
            description: "Image d'actualité",
          },
          submit: "Mettre à jour l'actualité",
        },
      },
      list: {
        title: 'Nouvelles',
        description: 'Actualités de la clinique',
        create: 'Nouvelles',
        one: 'Nouvelles',
        many: 'Nouvelles',
        columns: {
          clinicName: 'Clinique',
          actorName: 'Copain',
          authorName: 'Auteur',
          created: 'Création',
        },
      },
      delete: {
        title: 'Désactiver les actualités',
        subtitle: 'Désactiver les actualités de la clinique',
        button: 'Désactiver',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette actualité ?',
            label: 'Oui, je veux désactiver cette actualité',
          },
          submit: 'Désactiver',
        },
        passwordForm: {
          title: 'Entrez votre mot de passe',
          subtitle: 'Pour désactiver les actualités, entrez votre mot de passe',
          password: {
            title: 'Mot de passe',
            description: "Mot de passe de l'utilisateur qui effectue l'action",
          },
          submit: 'Confirmer',
        },
      },
      enable: {
        title: 'Activer les actualités',
        subtitle: 'Activer les actualités de la clinique',
        button: 'Activer',
        form: {
          status: {
            title: 'Etes-vous sûr de vouloir activer cette actualité ?',
            label: 'Oui, je veux activer cette actualité',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver les actualités',
        subtitle: 'Désactiver les actualités de la clinique',
        button: 'Désactiver',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette actualité ?',
            label: 'Oui, je veux désactiver cette actualité',
          },
          submit: 'Désactiver',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Rôle',
          created: 'Création',
        },
        title: 'Rôles',
        description: 'Gestion des rôles et de leurs permissions',
        create: 'Rôle',
        one: 'Rôle',
        many: 'Rôles',
      },
      create: {
        title: 'Nouveau rôle',
        subtitle: "Enregistrement d'un nouveau rôle dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du rôle",
          },
          general: {
            accordion: 'Autorisations générales',
            title: 'Autorisations générales',
            description: 'Autorisations générales pour le rôle',
          },
          viewDisabledRecords: {
            title: 'Afficher les journaux désactivés',
            description: "Cette option permet l'affichage des logs désactivés",
            label: "Activer l'affichage des journaux désactivés",
          },
          viewDisabledLeadRecords: {
            title: 'Afficher les prospects désactivés',
            description: "Cette option permet l'affichage des leads désactivés",
            label: "Activer l'affichage des leads désactivés",
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Afficher les patients handicapés',
              ovobank: 'Afficher les donateurs handicapés',
            },
            description: {
              ovoclinic: "Cette option permet l'affichage des patients handicapés",
              ovobank: "Cette option permet l'affichage des donneurs handicapés",
            },
            label: {
              ovoclinic: "Activer l'affichage des patients handicapés",
              ovobank: "Activer l'affichage des donneurs handicapés",
            },
          },
          access: {
            accordion: 'Accès OVO',
            title: 'Autorisation au module OVOAccess',
            description: "Cette option permet d'accéder au module OVOAccess",
            label: "Activer l'accès",
          },
          user: {
            accordion: 'Utilisateurs',
          },
          accessUser: {
            title: 'Voir les utilisateurs',
            description: "Cette option permet l'affichage des utilisateurs dans le système",
            label: "Activer l'affichage utilisateur",
          },
          accessUserCreator: {
            title: 'Créer des utilisateurs',
            description: "Cette option permet la création d'utilisateurs dans le système",
            label: "Activer la création d'utilisateurs",
          },
          accessUserEditor: {
            title: 'Modifier les utilisateurs',
            description: 'Cette option permet de modifier les utilisateurs dans le système',
            label: "Activer la modification par l'utilisateur",
          },
          accessUserDestructor: {
            title: 'Supprimer des utilisateurs',
            description: 'Cette option permet la suppression des utilisateurs dans le système',
            label: 'Activer la suppression des utilisateurs',
          },
          accessUserChangePassword: {
            title: 'Changer le mot de passe',
            description:
              'Cette option permet de changer le mot de passe des utilisateurs du système',
            label: 'Activer le changement de mot de passe',
          },
          role: {
            accordion: 'Rôles',
          },
          accessRole: {
            title: 'Afficher les rôles',
            description: "Cette option permet l'affichage des rôles dans le système",
            label: "Activer l'affichage des rôles",
          },
          accessRoleCreator: {
            title: 'Créer des rôles',
            description: 'Cette option permet la création de rôles dans le système',
            label: 'Activer la création de rôles',
          },
          accessRoleEditor: {
            title: 'Modifier les rôles',
            description: 'Cette option permet de modifier les rôles dans le système',
            label: 'Activer la modification des rôles',
          },
          accessRoleDestructor: {
            title: 'Supprimer des rôles',
            description: 'Cette option permet la suppression de rôles dans le système',
            label: 'Activer la suppression de rôle',
          },
          timetable: {
            accordion: 'Horaires',
          },
          accessTimetable: {
            title: 'Voir les horaires',
            description: "Cette option permet l'affichage des horaires dans le système",
            label: "Activer l'affichage du planning",
          },
          accessTimetableCreator: {
            title: 'Créer des horaires',
            description: 'Cette option permet la création de plannings dans le système',
            label: 'Activer la création de planning',
          },
          accessTimetableEditor: {
            title: 'Modifier les horaires',
            description: "Cette option permet l'édition des horaires dans le système",
            label: 'Activer la modification du planning',
          },
          accessTimetableDestructor: {
            title: 'Supprimer les horaires',
            description: 'Cette option permet la suppression des plannings dans le système',
            label: 'Activer la suppression du planning',
          },
          bulletin: {
            accordion: 'Nouvelles',
          },
          accessBulletin: {
            title: 'Voir les actualités',
            description: "Cette option permet l'affichage des actualités dans le système",
            label: "Activer l'affichage des actualités",
          },
          accessBulletinCreator: {
            title: 'Créer des actualités',
            description: 'Cette option permet la création de nouvelles dans le système',
            label: 'Activer la création de nouvelles',
          },
          accessBulletinEditor: {
            title: "Modifier l'actualité",
            description: "Cette option permet l'édition des actualités dans le système",
            label: "Activer l'édition des actualités",
          },
          accessBulletinDestructor: {
            title: "Supprimer l'actualité",
            description: 'Cette option permet la suppression des actualités dans le système',
            label: 'Activer la suppression des actualités',
          },
          accessClinic: {
            title: 'Voir les cliniques',
            description: 'Cette option permet la visualisation des cliniques dans le système',
            label: 'Activer la visualisation de la clinique',
          },
          accessClinicCreator: {
            title: 'Créer des cliniques',
            description: 'Cette option permet la création de cliniques dans le système',
            label: 'Activer la création de cliniques',
          },
          accessClinicEditor: {
            title: 'Modifier les cliniques',
            description: 'Cette option permet de modifier les cliniques dans le système',
            label: 'Activer la modification de la clinique',
          },
          accessClinicDestructor: {
            title: 'Supprimer des cliniques',
            description: 'Cette option permet la suppression de cliniques dans le système',
            label: 'Activer la suppression de la clinique',
          },
          department: {
            accordion: 'Départements',
          },
          accessDepartment: {
            title: 'Voir les départements',
            description: 'Cette option permet la visualisation des départements du système',
            label: "Activer l'affichage du département",
          },
          accessDepartmentCreator: {
            title: 'Créer des départements',
            description: 'Cette option permet la création de départements dans le système',
            label: 'Activer la création de département',
          },
          accessDepartmentEditor: {
            title: 'Modifier les départements',
            description: "Cette option permet l'édition des départements dans le système",
            label: 'Activer la modification du service',
          },
          accessDepartmentDestructor: {
            title: 'Supprimer des départements',
            description: 'Cette option permet la suppression de départements dans le système',
            label: 'Activer la suppression des départements',
          },
          language: {
            accordion: 'Langues',
          },
          accessLanguage: {
            title: 'Voir les langues',
            description: "Cette option permet l'affichage des langues dans le système",
            label: "Activer l'affichage de la langue",
          },
          accessLanguageCreator: {
            title: 'Créer des langues',
            description: 'Cette option permet la création de langues dans le système',
            label: 'Activer la création de langue',
          },
          accessLanguageEditor: {
            title: 'Modifier les langues',
            description: "Cette option permet l'édition des langues dans le système",
            label: 'Activer la modification de la langue',
          },
          accessLanguageDestructor: {
            title: 'Supprimer des langues',
            description: 'Cette option permet la suppression des langues sur le système',
            label: 'Activer la suppression de la langue',
          },
          country: {
            accordion: 'Pays',
          },
          accessCountry: {
            title: 'Voir les pays',
            description: "Cette option permet l'affichage des pays dans le système",
            label: "Activer l'affichage du pays",
          },
          accessCountryCreator: {
            title: 'Créer des pays',
            description: 'Cette option permet de créer des pays dans le système',
            label: 'Activer la création de pays',
          },
          accessCountryEditor: {
            title: 'Modifier les pays',
            description: "Cette option permet l'édition des pays dans le système",
            label: 'Activer la modification du pays',
          },
          accessCountryDestructor: {
            title: 'Supprimer des pays',
            description: 'Cette option permet de supprimer des pays dans le système',
            label: 'Activer la suppression du pays',
          },
          accessSettings: {
            title: 'Afficher les paramètres',
            description: "Cette option permet l'affichage des paramètres dans le système",
            label: "Activer l'affichage de la configuration",
          },
          specialists: {
            accordion: 'Spécialistes',
          },
          accessSpecialist: {
            title: 'Voir les spécialistes',
            description: "Cette option permet d'afficher les spécialistes du système",
            label: 'Permettre la visualisation des spécialistes',
          },
          accessSpecialistCreator: {
            title: 'Créer des spécialistes',
            description: 'Cette option permet la création de spécialistes du système',
            label: 'Permettre la création de spécialistes',
          },
          accessSpecialistEditor: {
            title: 'Modifier les spécialistes',
            description: "Cette option permet l'édition des spécialistes du système",
            label: "Activer l'édition spécialisée",
          },
          accessSpecialistDestructor: {
            title: 'Éliminer les spécialistes',
            description: 'Cette option permet de supprimer des spécialistes du système',
            label: 'Activer la suppression spécialisée',
          },
          commsChannels: {
            accordion: 'Canaux de communication',
          },
          accessCommsChannel: {
            title: 'Voir les canaux de communication',
            description:
              'Cette option permet de visualiser les canaux de communication dans le système',
            label: 'Permettre la visualisation des canaux de communication',
          },
          accessCommsChannelCreator: {
            title: 'Créer des canaux de communication',
            description:
              'Cette option permet la création de canaux de communication dans le système',
            label: 'Permettre la création de canaux de communication',
          },
          accessCommsChannelEditor: {
            title: 'Modifier les canaux de communication',
            description:
              "Cette option permet l'édition des canaux de communication dans le système",
            label: 'Activer la modification des canaux de communication',
          },
          accessCommsChannelDestructor: {
            title: 'Éliminer les canaux de communication',
            description:
              "Cette option permet d'éliminer les canaux de communication dans le système",
            label: 'Activer la suppression des canaux de communication',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: "Cette option permet l'affichage des ovopoints dans le système",
            label: "Activer l'affichage des ovopoints",
          },
          accessOvopointEditor: {
            title: 'Modifier les ovopoints',
            description: "Cette option permet l'édition des ovopoints dans le système",
            label: 'Activer la modification des ovopoints',
          },
          templates: {
            accordion: 'Modèles',
          },
          accessTemplate: {
            title: 'Voir les modèles WhatsApp',
            description: "Cette option permet l'affichage des modèles WhatsApp",
            label: "Activer l'affichage du modèle",
          },
          accessTemplateCreator: {
            title: 'Créer des modèles WhatsApp',
            description: 'Cette option permet la création de modèles WhatsApp',
            label: 'Activer les modèles',
          },
          accessTemplateEditor: {
            title: 'Modifier les modèles WhatsApp',
            description: 'Cette option permet de modifier les modèles WhatsApp',
            label: 'Activer la modification du modèle',
          },
          accessTemplateDestructor: {
            title: 'Supprimer les modèles WhatsApp',
            description: 'Cette option permet la suppression des modèles WhatsApp',
            label: 'Activer la suppression du modèle',
          },
          crm: {
            accordion: 'OVO CRM',
            title: "Autorisation d'application",
            description: "Cette option permet d'accéder au module OVOCrm",
            label: "Activer l'accès",
          },
          tasks: {
            accordion: 'Tâches',
          },
          crmCalendar: {
            title: 'Calendrier',
            description: "Cette option permet l'affichage du calendrier sur le système",
            label: "Activer l'affichage du calendrier",
          },
          crmTask: {
            title: 'Tâches',
            description: "Cette option permet l'affichage des tâches dans le système",
            label: "Activer l'affichage des tâches",
          },
          crmTaskCreator: {
            title: 'Créer des tâches',
            description: 'Cette option permet la création de tâches dans le système',
            label: 'Activer la création de tâches',
          },
          crmTaskEditor: {
            title: 'Modifier les tâches',
            description: 'Cette option permet de modifier les tâches dans le système',
            label: 'Activer la modification des tâches',
          },
          crmTaskDestructor: {
            title: 'Supprimer des tâches',
            description: 'Cette option permet la suppression de tâches dans le système',
            label: 'Activer la suppression des tâches',
          },
          crmTaskSeeAll: {
            title: 'Voir toutes les tâches',
            description: "Cette option permet l'affichage de toutes les tâches du système",
            label: "Activer l'affichage de toutes les tâches",
          },
          crmTaskSeeCoworkers: {
            title: 'Afficher les tâches des collègues',
            description: "Cette option permet d'afficher les tâches des collègues dans le système",
            label: "Activer l'affichage des tâches homologues",
          },
          crmTaskUpdateResponsible: {
            title: 'Mettre à jour la personne affectée à une tâche',
            description: "Cette option permet de changer la personne responsable d'une tâche",
            label: "Activer l'édition du gestionnaire de tâches",
          },
          leads: {
            accordion: 'Conduit',
          },
          crmLead: {
            title: 'Conduit',
            description: 'Cette option permet la visualisation des leads dans le système',
            label: 'Activer la visualisation des prospects',
          },
          crmLeadCreator: {
            title: 'Créer des prospects',
            description: 'Cette option permet la création de leads dans le système',
            label: 'Activer la création de leads',
          },
          crmLeadEditor: {
            title: 'Modifier les prospects',
            description: 'Cette option permet la modification des leads dans le système',
            label: 'Activer la modification des leads',
          },
          crmLeadDestructor: {
            title: 'Supprimer des prospects',
            description: 'Cette option permet la suppression des leads dans le système',
            label: 'Activer la suppression des leads',
          },
          crmLeadSeeAll: {
            title: 'Voir toutes les pistes',
            description: "Cette option permet l'affichage de toutes les pistes du système",
            label: "Activer l'affichage de toutes les pistes",
          },
          crmLeadSeeCoworkers: {
            title: 'Voir les pistes de collègues',
            description: "Cette option permet d'afficher les leads des collègues",
            label: 'Activer la visualisation des leads de vos collègues',
          },
          crmLeadUpdateCampaign: {
            title: 'Mettre à jour la campagne attribuée aux prospects',
            description: 'Cette option permet le changement de campagne à plusieurs leads',
            label: 'Activer la modification de la campagne à prospects',
          },
          patients: {
            accordion: {
              ovoclinic: 'Patients',
              ovobank: 'Donateurs',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Patients',
              ovobank: 'Donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la visualisation des patients dans le système',
              ovobank: "Cette option permet l'affichage des donateurs dans le système",
            },
            label: {
              ovoclinic: 'Activer la visualisation des patients',
              ovobank: "Activer l'affichage des donateurs",
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Créer des patients',
              ovobank: 'Créer des donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la création de patients dans le système',
              ovobank: 'Cette option permet la création de donateurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la création de patients',
              ovobank: 'Activer la création de donateurs',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Modifier les patients',
              ovobank: 'Modifier les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de modifier les patients dans le système',
              ovobank: 'Cette option permet de modifier les donateurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la modification des patients',
              ovobank: 'Activer la modification des donateurs',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Supprimer des patients',
              ovobank: 'Supprimer les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la suppression de patients dans le système',
              ovobank: 'Cette option permet la suppression des donneurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la suppression des patients',
              ovobank: 'Activer la suppression des donneurs',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Voir tous les patients',
              ovobank: 'Voir tous les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de visualiser tous les patients du système',
              ovobank: "Cette option permet d'afficher tous les donateurs dans le système",
            },
            label: {
              ovoclinic: 'Permettre la visualisation de tous les patients',
              ovobank: "Activer l'affichage de tous les donateurs",
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Voir les patients de collègues',
              ovobank: 'Voir les pairs donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de visualiser des patients pairs',
              ovobank: "Cette option permet l'affichage des pairs donneurs",
            },
            label: {
              ovoclinic: 'Permettre la visualisation des patients par des collègues',
              ovobank: 'Permettre la visualisation des pairs donateurs',
            },
          },
          contacts: {
            accordion: 'Contacts',
          },
          crmContact: {
            title: 'Contacts',
            description: "Cette option permet l'affichage des contacts dans le système",
            label: "Activer l'affichage des contacts",
          },
          crmContactCreator: {
            title: 'Créer des contacts',
            description: 'Cette option permet la création de contacts dans le système',
            label: 'Activer la création de contacts',
          },
          crmContactEditor: {
            title: 'Modifier les contacts',
            description: 'Cette option permet de modifier les contacts dans le système',
            label: 'Activer la modification des contacts',
          },
          crmContactDestructor: {
            title: 'Supprimer des contacts',
            description: 'Cette option permet la suppression des contacts dans le système',
            label: 'Activer la suppression des contacts',
          },
          activity: {
            accordion: 'Activités',
          },
          crmActivity: {
            title: 'Activités',
            description: 'Cette option permet la visualisation des activités dans le système',
            label: "Activer l'affichage des activités",
          },
          crmActivityCreator: {
            title: 'Créer des activités',
            description: "Cette option permet la création d'activités dans le système",
            label: "Activer la création d'activité",
          },
          crmActivityEditor: {
            title: 'Modifier les activités',
            description: 'Cette option permet de modifier les activités dans le système',
            label: 'Activer la modification des activités',
          },
          crmActivityDestructor: {
            title: 'Supprimer des activités',
            description: 'Cette option permet la suppression des activités dans le système',
            label: 'Activer la suppression des activités',
          },
          crmActivitySeeAll: {
            title: 'Voir toutes les activités',
            description: 'Cette option permet de visualiser toutes les activités du système',
            label: 'Activer la visualisation de toutes les activités',
          },
          results: {
            accordion: 'Résultats',
          },
          crmResult: {
            title: 'Résultats',
            description: "Cette option permet l'affichage des résultats dans le système",
            label: "Activer l'affichage des résultats",
          },
          crmResultCreator: {
            title: 'Créer des résultats',
            description: 'Cette option permet la création des résultats dans le système',
            label: 'Activer la création de résultats',
          },
          crmResultEditor: {
            title: 'Modifier les résultats',
            description: "Cette option permet d'éditer les résultats dans le système",
            label: 'Activer la modification des résultats',
          },
          crmResultDestructor: {
            title: 'Supprimer les résultats',
            description: 'Cette option permet la suppression des résultats dans le système',
            label: 'Activer la suppression des résultats',
          },
          crmResultCCEvent: {
            title: "Modifier les jalons du centre d'appels",
            description:
              'Cette option permet la modification des jalons du Call Center dans le système',
            label: "Activer la modification des jalons du centre d'appels",
          },
          crmResultATPCEvent: {
            title: 'Modifier les jalons commerciaux ATP',
            description:
              'Cette option permet de modifier les jalons ATP Comercial dans le système.',
            label: 'Activer la modification des jalons commerciaux ATP',
          },
          crmResultATPIEvent: {
            title: "Modifier les jalons de l'ATP International",
            description:
              'Cette option permet la modification des jalons ATP International dans le système',
            label: 'Activer la modification des jalons ATP International',
          },
          crmResultReactivity: {
            title: 'Modifier la réactivité',
            description: "Cette option permet d'éditer la réactivité dans le système",
            label: 'Activer la modification de la réactivité',
          },
          reasons: {
            accordion: 'Raisons',
          },
          crmReason: {
            title: 'Raisons',
            description: "Cette option permet l'affichage des motifs dans le système",
            label: "Activer l'affichage du motif",
          },
          crmReasonCreator: {
            title: 'Créer des motifs',
            description: 'Cette option permet de créer des motifs dans le système',
            label: 'Activer la création de motifs',
          },
          crmReasonEditor: {
            title: 'Modifier les motifs',
            description: 'Cette option permet de modifier les motifs dans le système',
            label: 'Activer la modification du motif',
          },
          crmReasonDestructor: {
            title: 'Supprimer les motifs',
            description: 'Cette option permet la suppression des motifs dans le système',
            label: 'Activer la suppression du motif',
          },
          campaigns: {
            accordion: 'Campagnes',
          },
          crmCampaign: {
            title: 'Campagnes',
            description: 'Cette option permet la visualisation des campagnes dans le système',
            label: "Activer l'affichage de la campagne",
          },
          crmCampaignCreator: {
            title: 'Créer des campagnes',
            description: 'Cette option permet la création de campagnes dans le système',
            label: 'Activer la création de campagnes',
          },
          crmCampaignEditor: {
            title: 'Modifier les campagnes',
            description: "Cette option permet l'édition des campagnes dans le système",
            label: 'Activer la modification de la campagne',
          },
          crmCampaignDestructor: {
            title: 'Supprimer des campagnes',
            description: 'Cette option permet la suppression des campagnes dans le système',
            label: 'Activer la suppression de la campagne',
          },
          funnels: {
            accordion: 'Entonnoirs',
          },
          crmFunnel: {
            title: 'Entonnoirs',
            description: 'Cette option permet la visualisation des entonnoirs dans le système',
            label: "Activer la visualisation de l'entonnoir",
          },
          crmFunnelCreator: {
            title: 'Créer des entonnoirs',
            description: "Cette option permet la création d'entonnoirs dans le système",
            label: "Activer la création d'entonnoir",
          },
          crmFunnelEditor: {
            title: 'Modifier les entonnoirs',
            description: 'Cette option permet de modifier les entonnoirs dans le système',
            label: "Activer la modification de l'entonnoir",
          },
          crmFunnelDestructor: {
            title: 'Supprimer les entonnoirs',
            description: 'Cette option permet la suppression des entonnoirs dans le système',
            label: "Activer la suppression de l'entonnoir",
          },
          channels: {
            accordion: 'Canaux',
          },
          crmChannel: {
            title: 'Canaux',
            description: 'Cette option permet la visualisation des chaînes sur le système',
            label: "Activer l'affichage des chaînes",
          },
          crmChannelCreator: {
            title: 'Créer des chaînes',
            description: 'Cette option permet la création de canaux dans le système',
            label: 'Activer la création de chaînes',
          },
          crmChannelEditor: {
            title: 'Modifier les chaînes',
            description: 'Cette option permet de modifier les canaux dans le système',
            label: 'Activer la modification des chaînes',
          },
          crmChannelDestructor: {
            title: 'Supprimer des chaînes',
            description: 'Cette option permet la suppression de chaînes dans le système',
            label: 'Activer la suppression des chaînes',
          },
          programs: {
            accordion: 'Traitements',
          },
          crmProgram: {
            title: 'Traitements',
            description:
              "Cette option permet l'affichage des traitements du catalogue dans le système",
            label: "Activer l'affichage des traitements du catalogue",
          },
          crmProgramCreator: {
            title: 'Créer des traitements',
            description: 'Cette option permet la création de traitements dans le système',
            label: 'Activer la création de traitements',
          },
          crmProgramEditor: {
            title: 'Modifier les traitements',
            description: "Cette option permet l'édition des traitements dans le système",
            label: 'Activer la modification du traitement',
          },
          crmProgramDestructor: {
            title: 'Supprimer les traitements',
            description: 'Cette option permet la suppression des traitements dans le système',
            label: 'Activer la suppression du traitement',
          },
          services: {
            accordion: 'Services',
          },
          crmService: {
            title: 'Services',
            description: 'Cette option permet la visualisation des services dans le système',
            label: "Activer l'affichage du service",
          },
          crmServiceCreator: {
            title: 'Créer des services',
            description: 'Cette option permet la création de services dans le système',
            label: 'Activer la création de services',
          },
          crmServiceEditor: {
            title: 'Modifier les services',
            description: 'Cette option permet de modifier les services dans le système',
            label: 'Activer la modification du service',
          },
          crmServiceDestructor: {
            title: 'Supprimer des services',
            description: 'Cette option permet la suppression de services sur le système',
            label: 'Activer la suppression du service',
          },
          techniques: {
            accordion: 'Techniques',
          },
          crmTechnique: {
            title: 'Techniques complémentaires',
            description:
              'Cette option permet de visualiser des techniques complémentaires dans le système',
            label: 'Permettre la visualisation de techniques complémentaires',
          },
          crmTechniqueCreator: {
            title: 'Créer des techniques complémentaires',
            description:
              'Cette option permet de créer des techniques complémentaires dans le système',
            label: 'Permettre la création de techniques complémentaires',
          },
          crmTechniqueEditor: {
            title: 'Modifier des techniques complémentaires',
            description:
              "Cette option permet l'édition de techniques complémentaires dans le système",
            label: 'Permettre l’édition de techniques complémentaires',
          },
          crmTechniqueDestructor: {
            title: 'Éliminer les techniques complémentaires',
            description:
              'Cette option permet de supprimer des techniques complémentaires dans le système',
            label: 'Activer la suppression des techniques complémentaires',
          },
          maintenances: {
            accordion: 'Entretien',
          },
          crmMaintenance: {
            title: 'Entretien',
            description: 'Cette option permet de visualiser la maintenance dans le système',
            label: 'Activer la visualisation de la maintenance',
          },
          crmMaintenanceCreator: {
            title: 'Créer une maintenance',
            description: 'Cette option permet de créer une maintenance dans le système',
            label: 'Activer la création de maintenance',
          },
          crmMaintenanceEditor: {
            title: 'Modifier la maintenance',
            description: "Cette option permet d'éditer la maintenance dans le système",
            label: 'Activer la modification de maintenance',
          },
          crmMaintenanceDestructor: {
            title: 'Éliminer la maintenance',
            description: "Cette option permet d'éliminer la maintenance dans le système",
            label: 'Activer la suppression pour maintenance',
          },
          treatments: {
            accordion: 'Traitements',
          },
          crmTreatment: {
            title: 'Traitements',
            description: 'Cette option permet la visualisation des traitements dans le système',
            label: 'Permettre la visualisation des traitements',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Créer des traitements pour le patient',
              ovobank: 'Créer des traitements pour donneurs',
            },
            description: {
              ovoclinic:
                'Cette option permet la création de traitements de patients dans le système',
              ovobank: 'Cette option permet la création de traitements donneurs dans le système',
            },
            label: {
              ovoclinic: 'Permettre la création de traitements pour le patient',
              ovobank: 'Permettre la création de traitements pour le donneur',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Modifier les traitements pour le patient',
              ovobank: 'Modifier les traitements des donneurs',
            },
            description: {
              ovoclinic:
                "Cette option permet l'édition des traitements des patients dans le système",
              ovobank: "Cette option permet l'édition des traitements des donneurs dans le système",
            },
            label: {
              ovoclinic: 'Activer la modification des traitements pour le patient',
              ovobank: 'Activer la modification du traitement pour le donneur',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Supprimer des traitements pour le patient',
              ovobank: 'Supprimer les traitements des donneurs',
            },
            description: {
              ovoclinic:
                'Cette option permet la suppression des traitements des patients dans le système',
              ovobank:
                'Cette option permet la suppression des traitements des donneurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la suppression des traitements pour le patient',
              ovobank: 'Activer la suppression des traitements pour le donneur',
            },
          },
          phases: {
            accordion: 'Étapes',
          },
          crmPhase: {
            title: 'Étapes',
            description: 'Cette option permet de visualiser les étapes du système',
            label: 'Activer la visualisation de la scène',
          },
          crmPhaseCreator: {
            title: 'Créer des étapes',
            description: 'Cette option permet de créer des étapes dans le système',
            label: 'Activer la mise en scène',
          },
          crmPhaseEditor: {
            title: 'Modifier les étapes',
            description: "Cette option permet l'édition des étapes dans le système",
            label: "Activer l'édition de scène",
          },
          crmPhaseDestructor: {
            title: 'Supprimer des étapes',
            description: "Cette option permet d'éliminer des étapes dans le système",
            label: "Activer la suppression d'étape",
          },
          causes: {
            accordion: 'Raisons',
          },
          crmCause: {
            title: "Raisons de l'annulation",
            description: "Cette option permet d'afficher les motifs d'annulation dans le système",
            label: "Activer l'affichage des motifs d'annulation",
          },
          crmCauseCreator: {
            title: "Créer des motifs d'annulation",
            description: "Cette option permet de créer des motifs d'annulation dans le système",
            label: "Activer la création de motifs d'annulation",
          },
          crmCauseEditor: {
            title: "Modifier les motifs d'annulation",
            description: "Cette option permet de modifier les motifs d'annulation dans le système.",
            label: "Activer la modification des motifs d'annulation",
          },
          crmCauseDestructor: {
            title: "Éliminer les motifs d'annulation",
            description: "Cette option permet de supprimer les motifs d'annulation dans le système",
            label: "Activer la suppression des motifs d'annulation",
          },
          cycles: {
            accordion: 'Cycles de traitement',
          },
          crmCycle: {
            title: 'Cycles de traitement',
            description:
              'Cette option permet la visualisation des cycles de traitement dans le système',
            label: 'Permettre la visualisation des cycles de traitement',
          },
          crmCycleCreator: {
            title: 'Créer des cycles de traitement',
            description: 'Cette option permet la création de cycles de traitement dans le système',
            label: 'Permettre la création de cycles de traitement',
          },
          crmCycleEditor: {
            title: 'Modifier les cycles de traitement',
            description: "Cette option permet d'éditer les cycles de traitement dans le système",
            label: "Activer l'édition des cycles de traitement",
          },
          crmCycleDestructor: {
            title: 'Supprimer des cycles de traitement',
            description:
              'Cette option permet la suppression des cycles de traitement dans le système',
            label: 'Activer la suppression des cycles de traitement',
          },
          clinic: {
            accordion: 'OVOClinique',
            title: 'OVOClinique',
            description: "Cette option permet d'accéder au module OVOClinic",
            label: "Activer l'accès",
          },
          board: {
            accordion: 'Tableau OVO',
            title: 'Tableau OVO',
            description: "Cette option permet d'accéder au module OVOBoard",
            label: "Activer l'accès",
          },
          tory: {
            accordion: 'OVOtory',
            title: 'OVOtory',
            description: "Cette option permet d'accéder au module OVOTory",
            label: "Activer l'accès",
          },
          profile: {
            accordion: 'Profil OVO',
            title: 'Profil OVO',
            description: "Cette option permet d'accéder au module OVOProfile",
            label: "Activer l'accès",
          },
          status: {
            title: 'Rôle actif',
            description: 'Activer ou désactiver le rôle dans le système',
            label: 'Rôle actif',
          },
          submit: 'Créer un rôle',
        },
      },
      update: {
        title: 'Mettre à jour le rôle',
        subtitle: "Modifier les données d'un rôle dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du rôle",
          },
          general: {
            accordion: 'Général',
            title: 'Autorisations générales',
            description: 'Autorisations générales pour le rôle',
          },
          viewDisabledRecords: {
            title: 'Afficher les journaux désactivés',
            description: "Cette option permet l'affichage des logs désactivés",
            label: "Activer l'affichage des journaux désactivés",
          },
          viewDisabledLeadRecords: {
            title: 'Afficher les prospects désactivés',
            description: "Cette option permet l'affichage des leads désactivés",
            label: "Activer l'affichage des leads désactivés",
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Afficher les patients handicapés',
              ovobank: 'Afficher les donateurs handicapés',
            },
            description: {
              ovoclinic: "Cette option permet l'affichage des patients handicapés",
              ovobank: "Cette option permet l'affichage des donneurs handicapés",
            },
            label: {
              ovoclinic: "Activer l'affichage des patients handicapés",
              ovobank: "Activer l'affichage des donneurs handicapés",
            },
          },
          access: {
            accordion: 'Accès OVO',
            title: 'Autorisation au module OVOAccess',
            description: "Cette option permet d'accéder au module OVOAccess",
            label: "Activer l'accès",
          },
          user: {
            accordion: 'Utilisateurs',
          },
          accessUser: {
            title: 'Voir les utilisateurs',
            description: "Cette option permet l'affichage des utilisateurs dans le système",
            label: "Activer l'affichage utilisateur",
          },
          accessUserCreator: {
            title: 'Créer des utilisateurs',
            description: "Cette option permet la création d'utilisateurs dans le système",
            label: "Activer la création d'utilisateurs",
          },
          accessUserEditor: {
            title: 'Modifier les utilisateurs',
            description: 'Cette option permet de modifier les utilisateurs dans le système',
            label: "Activer la modification par l'utilisateur",
          },
          accessUserDestructor: {
            title: 'Supprimer des utilisateurs',
            description: 'Cette option permet la suppression des utilisateurs dans le système',
            label: 'Activer la suppression des utilisateurs',
          },
          accessUserChangePassword: {
            title: 'Changer le mot de passe',
            description:
              'Cette option permet de changer le mot de passe des utilisateurs du système',
            label: 'Activer le changement de mot de passe',
          },
          role: {
            accordion: 'Rôles',
          },
          accessRole: {
            title: 'Afficher les rôles',
            description: "Cette option permet l'affichage des rôles dans le système",
            label: "Activer l'affichage des rôles",
          },
          accessRoleCreator: {
            title: 'Créer des rôles',
            description: 'Cette option permet la création de rôles dans le système',
            label: 'Activer la création de rôles',
          },
          accessRoleEditor: {
            title: 'Modifier les rôles',
            description: 'Cette option permet de modifier les rôles dans le système',
            label: 'Activer la modification des rôles',
          },
          accessRoleDestructor: {
            title: 'Supprimer des rôles',
            description: 'Cette option permet la suppression de rôles dans le système',
            label: 'Activer la suppression de rôle',
          },
          timetable: {
            accordion: 'Horaires',
          },
          accessTimetable: {
            title: 'Voir les horaires',
            description: "Cette option permet l'affichage des horaires dans le système",
            label: "Activer l'affichage du planning",
          },
          accessTimetableCreator: {
            title: 'Créer des horaires',
            description: 'Cette option permet la création de plannings dans le système',
            label: 'Activer la création de planning',
          },
          accessTimetableEditor: {
            title: 'Modifier les horaires',
            description: "Cette option permet l'édition des horaires dans le système",
            label: 'Activer la modification du planning',
          },
          accessTimetableDestructor: {
            title: 'Supprimer les horaires',
            description: 'Cette option permet la suppression des plannings dans le système',
            label: 'Activer la suppression du planning',
          },
          bulletin: {
            accordion: 'Nouvelles',
          },
          accessBulletin: {
            title: 'Voir les actualités',
            description: "Cette option permet l'affichage des actualités dans le système",
            label: "Activer l'affichage des actualités",
          },
          accessBulletinCreator: {
            title: 'Créer des actualités',
            description: 'Cette option permet la création de nouvelles dans le système',
            label: 'Activer la création de nouvelles',
          },
          accessBulletinEditor: {
            title: "Modifier l'actualité",
            description: "Cette option permet l'édition des actualités dans le système",
            label: "Activer l'édition des actualités",
          },
          accessBulletinDestructor: {
            title: "Supprimer l'actualité",
            description: 'Cette option permet la suppression des actualités dans le système',
            label: 'Activer la suppression des actualités',
          },
          accessClinic: {
            title: 'Voir les cliniques',
            description: 'Cette option permet la visualisation des cliniques dans le système',
            label: 'Activer la visualisation de la clinique',
          },
          accessClinicCreator: {
            title: 'Créer des cliniques',
            description: 'Cette option permet la création de cliniques dans le système',
            label: 'Activer la création de cliniques',
          },
          accessClinicEditor: {
            title: 'Modifier les cliniques',
            description: 'Cette option permet de modifier les cliniques dans le système',
            label: 'Activer la modification de la clinique',
          },
          accessClinicDestructor: {
            title: 'Supprimer des cliniques',
            description: 'Cette option permet la suppression de cliniques dans le système',
            label: 'Activer la suppression de la clinique',
          },
          department: {
            accordion: 'Départements',
          },
          accessDepartment: {
            title: 'Voir les départements',
            description: 'Cette option permet la visualisation des départements du système',
            label: "Activer l'affichage du département",
          },
          accessDepartmentCreator: {
            title: 'Créer des départements',
            description: 'Cette option permet la création de départements dans le système',
            label: 'Activer la création de département',
          },
          accessDepartmentEditor: {
            title: 'Modifier les départements',
            description: "Cette option permet l'édition des départements dans le système",
            label: 'Activer la modification du service',
          },
          accessDepartmentDestructor: {
            title: 'Supprimer des départements',
            description: 'Cette option permet la suppression de départements dans le système',
            label: 'Activer la suppression des départements',
          },
          language: {
            accordion: 'Langues',
          },
          accessLanguage: {
            title: 'Voir les langues',
            description: "Cette option permet l'affichage des langues dans le système",
            label: "Activer l'affichage de la langue",
          },
          accessLanguageCreator: {
            title: 'Créer des langues',
            description: 'Cette option permet la création de langues dans le système',
            label: 'Activer la création de langue',
          },
          accessLanguageEditor: {
            title: 'Modifier les langues',
            description: "Cette option permet l'édition des langues dans le système",
            label: 'Activer la modification de la langue',
          },
          accessLanguageDestructor: {
            title: 'Supprimer des langues',
            description: 'Cette option permet la suppression des langues sur le système',
            label: 'Activer la suppression de la langue',
          },
          country: {
            accordion: 'Pays',
          },
          accessCountry: {
            title: 'Voir les pays',
            description: "Cette option permet l'affichage des pays dans le système",
            label: "Activer l'affichage du pays",
          },
          accessCountryCreator: {
            title: 'Créer des pays',
            description: 'Cette option permet de créer des pays dans le système',
            label: 'Activer la création de pays',
          },
          accessCountryEditor: {
            title: 'Modifier les pays',
            description: "Cette option permet l'édition des pays dans le système",
            label: 'Activer la modification du pays',
          },
          accessCountryDestructor: {
            title: 'Supprimer des pays',
            description: 'Cette option permet de supprimer des pays dans le système',
            label: 'Activer la suppression du pays',
          },
          accessSettings: {
            title: 'Afficher les paramètres',
            description: "Cette option permet l'affichage des paramètres dans le système",
            label: "Activer l'affichage de la configuration",
          },
          specialists: {
            accordion: 'Spécialistes',
          },
          accessSpecialist: {
            title: 'Voir les spécialistes',
            description: "Cette option permet d'afficher les spécialistes du système",
            label: 'Permettre la visualisation des spécialistes',
          },
          accessSpecialistCreator: {
            title: 'Créer des spécialistes',
            description: 'Cette option permet la création de spécialistes du système',
            label: 'Permettre la création de spécialistes',
          },
          accessSpecialistEditor: {
            title: 'Modifier les spécialistes',
            description: "Cette option permet l'édition des spécialistes du système",
            label: "Activer l'édition spécialisée",
          },
          accessSpecialistDestructor: {
            title: 'Éliminer les spécialistes',
            description: 'Cette option permet de supprimer des spécialistes du système',
            label: 'Activer la suppression spécialisée',
          },
          commsChannels: {
            accordion: 'Canaux de communication',
          },
          accessCommsChannel: {
            title: 'Voir les canaux de communication',
            description:
              'Cette option permet de visualiser les canaux de communication dans le système',
            label: 'Permettre la visualisation des canaux de communication',
          },
          accessCommsChannelCreator: {
            title: 'Créer des canaux de communication',
            description:
              'Cette option permet la création de canaux de communication dans le système',
            label: 'Permettre la création de canaux de communication',
          },
          accessCommsChannelEditor: {
            title: 'Modifier les canaux de communication',
            description:
              "Cette option permet l'édition des canaux de communication dans le système",
            label: 'Activer la modification des canaux de communication',
          },
          accessCommsChannelDestructor: {
            title: 'Éliminer les canaux de communication',
            description:
              "Cette option permet d'éliminer les canaux de communication dans le système",
            label: 'Activer la suppression des canaux de communication',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: "Cette option permet l'affichage des ovopoints dans le système",
            label: "Activer l'affichage des ovopoints",
          },
          accessOvopointEditor: {
            title: 'Modifier les ovopoints',
            description: "Cette option permet l'édition des ovopoints dans le système",
            label: 'Activer la modification des ovopoints',
          },
          templates: {
            accordion: 'Modèles',
          },
          accessTemplate: {
            title: 'Voir les modèles WhatsApp',
            description: "Cette option permet l'affichage des modèles WhatsApp",
            label: "Activer l'affichage du modèle",
          },
          accessTemplateCreator: {
            title: 'Créer des modèles WhatsApp',
            description: 'Cette option permet la création de modèles WhatsApp',
            label: 'Activer les modèles',
          },
          accessTemplateEditor: {
            title: 'Modifier les modèles WhatsApp',
            description: 'Cette option permet de modifier les modèles WhatsApp',
            label: 'Activer la modification du modèle',
          },
          accessTemplateDestructor: {
            title: 'Supprimer les modèles WhatsApp',
            description: 'Cette option permet la suppression des modèles WhatsApp',
            label: 'Activer la suppression du modèle',
          },
          crm: {
            accordion: 'OVO CRM',
            title: "Autorisation d'application",
            description: "Cette option permet d'accéder au module OVOCrm",
            label: "Activer l'accès",
          },
          tasks: {
            accordion: 'Tâches',
          },
          crmCalendar: {
            title: 'Calendrier',
            description: "Cette option permet l'affichage du calendrier sur le système",
            label: "Activer l'affichage du calendrier",
          },
          crmTask: {
            title: 'Tâches',
            description: "Cette option permet l'affichage des tâches dans le système",
            label: "Activer l'affichage des tâches",
          },
          crmTaskCreator: {
            title: 'Créer des tâches',
            description: 'Cette option permet la création de tâches dans le système',
            label: 'Activer la création de tâches',
          },
          crmTaskEditor: {
            title: 'Modifier les tâches',
            description: 'Cette option permet de modifier les tâches dans le système',
            label: 'Activer la modification des tâches',
          },
          crmTaskDestructor: {
            title: 'Supprimer des tâches',
            description: 'Cette option permet la suppression de tâches dans le système',
            label: 'Activer la suppression des tâches',
          },
          crmTaskSeeAll: {
            title: 'Voir toutes les tâches',
            description: "Cette option permet l'affichage de toutes les tâches du système",
            label: "Activer l'affichage de toutes les tâches",
          },
          crmTaskSeeCoworkers: {
            title: 'Afficher les tâches des collègues',
            description: "Cette option permet d'afficher les tâches des collègues dans le système",
            label: "Activer l'affichage des tâches homologues",
          },
          crmTaskUpdateResponsible: {
            title: 'Mettre à jour la personne affectée à une tâche',
            description: "Cette option permet de changer la personne responsable d'une tâche",
            label: "Activer l'édition du gestionnaire de tâches",
          },
          leads: {
            accordion: 'Conduit',
          },
          crmLead: {
            title: 'Conduit',
            description: 'Cette option permet la visualisation des leads dans le système',
            label: 'Activer la visualisation des prospects',
          },
          crmLeadCreator: {
            title: 'Créer des prospects',
            description: 'Cette option permet la création de leads dans le système',
            label: 'Activer la création de leads',
          },
          crmLeadEditor: {
            title: 'Modifier les prospects',
            description: 'Cette option permet la modification des leads dans le système',
            label: 'Activer la modification des leads',
          },
          crmLeadDestructor: {
            title: 'Supprimer des prospects',
            description: 'Cette option permet la suppression des leads dans le système',
            label: 'Activer la suppression des leads',
          },
          crmLeadSeeAll: {
            title: 'Voir toutes les pistes',
            description: "Cette option permet l'affichage de toutes les pistes du système",
            label: "Activer l'affichage de toutes les pistes",
          },
          crmLeadSeeCoworkers: {
            title: 'Voir les pistes de collègues',
            description: "Cette option permet d'afficher les leads des collègues",
            label: 'Activer la visualisation des leads de vos collègues',
          },
          crmLeadUpdateCampaign: {
            title: 'Mettre à jour la campagne attribuée aux prospects',
            description: 'Cette option permet le changement de campagne à plusieurs leads',
            label: 'Activer la modification de la campagne à prospects',
          },
          patients: {
            accordion: {
              ovoclinic: 'Patients',
              ovobank: 'Donateurs',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Patients',
              ovobank: 'Donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la visualisation des patients dans le système',
              ovobank: "Cette option permet l'affichage des donateurs dans le système",
            },
            label: {
              ovoclinic: 'Activer la visualisation des patients',
              ovobank: "Activer l'affichage des donateurs",
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Créer des patients',
              ovobank: 'Créer des donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la création de patients dans le système',
              ovobank: 'Cette option permet la création de donateurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la création de patients',
              ovobank: 'Activer la création de donateurs',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Modifier les patients',
              ovobank: 'Modifier les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de modifier les patients dans le système',
              ovobank: 'Cette option permet de modifier les donateurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la modification des patients',
              ovobank: 'Activer la modification des donateurs',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Supprimer des patients',
              ovobank: 'Supprimer les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet la suppression de patients dans le système',
              ovobank: 'Cette option permet la suppression des donneurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la suppression des patients',
              ovobank: 'Activer la suppression des donneurs',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Voir tous les patients',
              ovobank: 'Voir tous les donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de visualiser tous les patients du système',
              ovobank: "Cette option permet d'afficher tous les donateurs dans le système",
            },
            label: {
              ovoclinic: 'Permettre la visualisation de tous les patients',
              ovobank: "Activer l'affichage de tous les donateurs",
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Voir les patients de collègues',
              ovobank: 'Voir les pairs donateurs',
            },
            description: {
              ovoclinic: 'Cette option permet de visualiser des patients pairs',
              ovobank: "Cette option permet l'affichage des pairs donneurs",
            },
            label: {
              ovoclinic: 'Permettre la visualisation des patients par des collègues',
              ovobank: 'Permettre la visualisation des pairs donateurs',
            },
          },
          contacts: {
            accordion: 'Contacts',
          },
          crmContact: {
            title: 'Contacts',
            description: "Cette option permet l'affichage des contacts dans le système",
            label: "Activer l'affichage des contacts",
          },
          crmContactCreator: {
            title: 'Créer des contacts',
            description: 'Cette option permet la création de contacts dans le système',
            label: 'Activer la création de contacts',
          },
          crmContactEditor: {
            title: 'Modifier les contacts',
            description: 'Cette option permet de modifier les contacts dans le système',
            label: 'Activer la modification des contacts',
          },
          crmContactDestructor: {
            title: 'Supprimer des contacts',
            description: 'Cette option permet la suppression des contacts dans le système',
            label: 'Activer la suppression des contacts',
          },
          activity: {
            accordion: 'Activités',
          },
          crmActivity: {
            title: 'Activités',
            description: 'Cette option permet la visualisation des activités dans le système',
            label: "Activer l'affichage des activités",
          },
          crmActivityCreator: {
            title: 'Créer des activités',
            description: "Cette option permet la création d'activités dans le système",
            label: "Activer la création d'activité",
          },
          crmActivityEditor: {
            title: 'Modifier les activités',
            description: 'Cette option permet de modifier les activités dans le système',
            label: 'Activer la modification des activités',
          },
          crmActivityDestructor: {
            title: 'Supprimer des activités',
            description: 'Cette option permet la suppression des activités dans le système',
            label: 'Activer la suppression des activités',
          },
          crmActivitySeeAll: {
            title: 'Voir toutes les activités',
            description: 'Cette option permet de visualiser toutes les activités du système',
            label: 'Activer la visualisation de toutes les activités',
          },
          results: {
            accordion: 'Résultats',
          },
          crmResult: {
            title: 'Résultats',
            description: "Cette option permet l'affichage des résultats dans le système",
            label: "Activer l'affichage des résultats",
          },
          crmResultCreator: {
            title: 'Créer des résultats',
            description: 'Cette option permet la création des résultats dans le système',
            label: 'Activer la création de résultats',
          },
          crmResultEditor: {
            title: 'Modifier les résultats',
            description: "Cette option permet d'éditer les résultats dans le système",
            label: 'Activer la modification des résultats',
          },
          crmResultDestructor: {
            title: 'Supprimer les résultats',
            description: 'Cette option permet la suppression des résultats dans le système',
            label: 'Activer la suppression des résultats',
          },
          crmResultCCEvent: {
            title: "Modifier les jalons du centre d'appels",
            description:
              'Cette option permet la modification des jalons du Call Center dans le système',
            label: "Activer la modification des jalons du centre d'appels",
          },
          crmResultATPCEvent: {
            title: 'Modifier les jalons commerciaux ATP',
            description:
              'Cette option permet de modifier les jalons ATP Comercial dans le système.',
            label: 'Activer la modification des jalons commerciaux ATP',
          },
          crmResultATPIEvent: {
            title: "Modifier les jalons de l'ATP International",
            description:
              'Cette option permet la modification des jalons ATP International dans le système',
            label: 'Activer la modification des jalons ATP International',
          },
          crmResultReactivity: {
            title: 'Modifier la réactivité',
            description: "Cette option permet d'éditer la réactivité dans le système",
            label: 'Activer la modification de la réactivité',
          },
          reasons: {
            accordion: 'Raisons',
          },
          crmReason: {
            title: 'Raisons',
            description: "Cette option permet l'affichage des motifs dans le système",
            label: "Activer l'affichage du motif",
          },
          crmReasonCreator: {
            title: 'Créer des motifs',
            description: 'Cette option permet de créer des motifs dans le système',
            label: 'Activer la création de motifs',
          },
          crmReasonEditor: {
            title: 'Modifier les motifs',
            description: 'Cette option permet de modifier les motifs dans le système',
            label: 'Activer la modification du motif',
          },
          crmReasonDestructor: {
            title: 'Supprimer les motifs',
            description: 'Cette option permet la suppression des motifs dans le système',
            label: 'Activer la suppression du motif',
          },
          campaigns: {
            accordion: 'Campagnes',
          },
          crmCampaign: {
            title: 'Campagnes',
            description: 'Cette option permet la visualisation des campagnes dans le système',
            label: "Activer l'affichage de la campagne",
          },
          crmCampaignCreator: {
            title: 'Créer des campagnes',
            description: 'Cette option permet la création de campagnes dans le système',
            label: 'Activer la création de campagnes',
          },
          crmCampaignEditor: {
            title: 'Modifier les campagnes',
            description: "Cette option permet l'édition des campagnes dans le système",
            label: 'Activer la modification de la campagne',
          },
          crmCampaignDestructor: {
            title: 'Supprimer des campagnes',
            description: 'Cette option permet la suppression des campagnes dans le système',
            label: 'Activer la suppression de la campagne',
          },
          funnels: {
            accordion: 'Entonnoirs',
          },
          crmFunnel: {
            title: 'Entonnoirs',
            description: 'Cette option permet la visualisation des entonnoirs dans le système',
            label: "Activer la visualisation de l'entonnoir",
          },
          crmFunnelCreator: {
            title: 'Créer des entonnoirs',
            description: "Cette option permet la création d'entonnoirs dans le système",
            label: "Activer la création d'entonnoir",
          },
          crmFunnelEditor: {
            title: 'Modifier les entonnoirs',
            description: 'Cette option permet de modifier les entonnoirs dans le système',
            label: "Activer la modification de l'entonnoir",
          },
          crmFunnelDestructor: {
            title: 'Supprimer les entonnoirs',
            description: 'Cette option permet la suppression des entonnoirs dans le système',
            label: "Activer la suppression de l'entonnoir",
          },
          channels: {
            accordion: 'Canaux',
          },
          crmChannel: {
            title: 'Canaux',
            description: 'Cette option permet la visualisation des chaînes sur le système',
            label: "Activer l'affichage des chaînes",
          },
          crmChannelCreator: {
            title: 'Créer des chaînes',
            description: 'Cette option permet la création de canaux dans le système',
            label: 'Activer la création de chaînes',
          },
          crmChannelEditor: {
            title: 'Modifier les chaînes',
            description: 'Cette option permet de modifier les canaux dans le système',
            label: 'Activer la modification des chaînes',
          },
          crmChannelDestructor: {
            title: 'Supprimer des chaînes',
            description: 'Cette option permet la suppression de chaînes dans le système',
            label: 'Activer la suppression des chaînes',
          },
          programs: {
            accordion: 'Traitements',
          },
          crmProgram: {
            title: 'Traitements',
            description:
              "Cette option permet l'affichage des traitements du catalogue dans le système",
            label: "Activer l'affichage des traitements du catalogue",
          },
          crmProgramCreator: {
            title: 'Créer des traitements',
            description: 'Cette option permet la création de traitements dans le système',
            label: 'Activer la création de traitements',
          },
          crmProgramEditor: {
            title: 'Modifier les traitements',
            description: "Cette option permet l'édition des traitements dans le système",
            label: 'Activer la modification du traitement',
          },
          crmProgramDestructor: {
            title: 'Supprimer les traitements',
            description: 'Cette option permet la suppression des traitements dans le système',
            label: 'Activer la suppression du traitement',
          },
          services: {
            accordion: 'Services',
          },
          crmService: {
            title: 'Services',
            description: 'Cette option permet la visualisation des services dans le système',
            label: "Activer l'affichage du service",
          },
          crmServiceCreator: {
            title: 'Créer des services',
            description: 'Cette option permet la création de services dans le système',
            label: 'Activer la création de services',
          },
          crmServiceEditor: {
            title: 'Modifier les services',
            description: 'Cette option permet de modifier les services dans le système',
            label: 'Activer la modification du service',
          },
          crmServiceDestructor: {
            title: 'Supprimer des services',
            description: 'Cette option permet la suppression de services sur le système',
            label: 'Activer la suppression du service',
          },
          techniques: {
            accordion: 'Techniques',
          },
          crmTechnique: {
            title: 'Techniques complémentaires',
            description:
              'Cette option permet de visualiser des techniques complémentaires dans le système',
            label: 'Permettre la visualisation de techniques complémentaires',
          },
          crmTechniqueCreator: {
            title: 'Créer des techniques complémentaires',
            description:
              'Cette option permet de créer des techniques complémentaires dans le système',
            label: 'Permettre la création de techniques complémentaires',
          },
          crmTechniqueEditor: {
            title: 'Modifier des techniques complémentaires',
            description:
              "Cette option permet l'édition de techniques complémentaires dans le système",
            label: 'Permettre l’édition de techniques complémentaires',
          },
          crmTechniqueDestructor: {
            title: 'Éliminer les techniques complémentaires',
            description:
              'Cette option permet de supprimer des techniques complémentaires dans le système',
            label: 'Activer la suppression des techniques complémentaires',
          },
          maintenances: {
            accordion: 'Entretien',
          },
          crmMaintenance: {
            title: 'Entretien',
            description: 'Cette option permet de visualiser la maintenance dans le système',
            label: 'Activer la visualisation de la maintenance',
          },
          crmMaintenanceCreator: {
            title: 'Créer une maintenance',
            description: 'Cette option permet de créer une maintenance dans le système',
            label: 'Activer la création de maintenance',
          },
          crmMaintenanceEditor: {
            title: 'Modifier la maintenance',
            description: "Cette option permet d'éditer la maintenance dans le système",
            label: 'Activer la modification de maintenance',
          },
          crmMaintenanceDestructor: {
            title: 'Éliminer la maintenance',
            description: "Cette option permet d'éliminer la maintenance dans le système",
            label: 'Activer la suppression pour maintenance',
          },
          treatments: {
            accordion: 'Traitements',
          },
          crmTreatment: {
            title: 'Traitements',
            description: 'Cette option permet la visualisation des traitements dans le système',
            label: 'Permettre la visualisation des traitements',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Créer des traitements pour le patient',
              ovobank: 'Créer des traitements pour donneurs',
            },
            description: {
              ovoclinic:
                'Cette option permet la création de traitements de patients dans le système',
              ovobank: 'Cette option permet la création de traitements donneurs dans le système',
            },
            label: {
              ovoclinic: 'Permettre la création de traitements pour le patient',
              ovobank: 'Permettre la création de traitements pour le donneur',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Modifier les traitements pour le patient',
              ovobank: 'Modifier les traitements des donneurs',
            },
            description: {
              ovoclinic:
                "Cette option permet l'édition des traitements des patients dans le système",
              ovobank: "Cette option permet l'édition des traitements des donneurs dans le système",
            },
            label: {
              ovoclinic: 'Activer la modification des traitements pour le patient',
              ovobank: 'Activer la modification du traitement pour le donneur',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Supprimer des traitements pour le patient',
              ovobank: 'Supprimer les traitements des donneurs',
            },
            description: {
              ovoclinic:
                'Cette option permet la suppression des traitements des patients dans le système',
              ovobank:
                'Cette option permet la suppression des traitements des donneurs dans le système',
            },
            label: {
              ovoclinic: 'Activer la suppression des traitements pour le patient',
              ovobank: 'Activer la suppression des traitements pour le donneur',
            },
          },
          phases: {
            accordion: 'Étapes',
          },
          crmPhase: {
            title: 'Étapes',
            description: 'Cette option permet de visualiser les étapes du système',
            label: 'Activer la visualisation de la scène',
          },
          crmPhaseCreator: {
            title: 'Créer des étapes',
            description: 'Cette option permet de créer des étapes dans le système',
            label: 'Activer la mise en scène',
          },
          crmPhaseEditor: {
            title: 'Modifier les étapes',
            description: "Cette option permet l'édition des étapes dans le système",
            label: "Activer l'édition de scène",
          },
          crmPhaseDestructor: {
            title: 'Supprimer des étapes',
            description: "Cette option permet d'éliminer des étapes dans le système",
            label: "Activer la suppression d'étape",
          },
          causes: {
            accordion: 'Raisons',
          },
          crmCause: {
            title: "Raisons de l'annulation",
            description: "Cette option permet d'afficher les motifs d'annulation dans le système",
            label: "Activer l'affichage des motifs d'annulation",
          },
          crmCauseCreator: {
            title: "Créer des motifs d'annulation",
            description: "Cette option permet de créer des motifs d'annulation dans le système",
            label: "Activer la création de motifs d'annulation",
          },
          crmCauseEditor: {
            title: "Modifier les motifs d'annulation",
            description: "Cette option permet de modifier les motifs d'annulation dans le système.",
            label: "Activer la modification des motifs d'annulation",
          },
          crmCauseDestructor: {
            title: "Éliminer les motifs d'annulation",
            description: "Cette option permet de supprimer les motifs d'annulation dans le système",
            label: "Activer la suppression des motifs d'annulation",
          },
          cycles: {
            accordion: 'Cycles de traitement',
          },
          crmCycle: {
            title: 'Cycles de traitement',
            description:
              'Cette option permet la visualisation des cycles de traitement dans le système',
            label: 'Permettre la visualisation des cycles de traitement',
          },
          crmCycleCreator: {
            title: 'Créer des cycles de traitement',
            description: 'Cette option permet la création de cycles de traitement dans le système',
            label: 'Permettre la création de cycles de traitement',
          },
          crmCycleEditor: {
            title: 'Modifier les cycles de traitement',
            description: "Cette option permet d'éditer les cycles de traitement dans le système",
            label: "Activer l'édition des cycles de traitement",
          },
          crmCycleDestructor: {
            title: 'Supprimer des cycles de traitement',
            description:
              'Cette option permet la suppression des cycles de traitement dans le système',
            label: 'Activer la suppression des cycles de traitement',
          },
          clinic: {
            accordion: 'OVOClinique',
            title: 'OVOClinique',
            description: "Cette option permet d'accéder au module OVOClinic",
            label: "Activer l'accès",
          },
          board: {
            accordion: 'Tableau OVO',
            title: 'Tableau OVO',
            description: "Cette option permet d'accéder au module OVOBoard",
            label: "Activer l'accès",
          },
          tory: {
            accordion: 'OVOtory',
            title: 'OVOtory',
            description: "Cette option permet d'accéder au module OVOTory",
            label: "Activer l'accès",
          },
          profile: {
            accordion: 'Profil OVO',
            title: 'Profil OVO',
            description: "Cette option permet d'accéder au module OVOProfile",
            label: "Activer l'accès",
          },
          status: {
            title: 'Rôle actif',
            description: 'Activer ou désactiver le rôle dans le système',
            label: 'Rôle actif',
          },
          submit: 'Mettre à jour le rôle',
        },
      },
      delete: {
        title: 'Supprimer le rôle',
        subtitle: 'Cette action est irréversible',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver ce rôle',
            label: 'Oui, je souhaite supprimer ce rôle',
          },
          submit: 'Supprimer le rôle',
        },
      },
      enable: {
        title: 'Activer le rôle',
        subtitle: "Cette action permet au rôle d'être actif dans le système",
        form: {
          status: {
            title: "Êtes-vous sûr de vouloir l'activer ?",
            description: "Cette action permettra au rôle d'être actif dans le système",
            label: 'Oui, je souhaite activer ce rôle',
          },
          submit: 'Activer le rôle',
        },
      },
      disable: {
        title: 'Désactiver le rôle',
        subtitle: 'Cette action désactive le rôle dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le désactiver ?',
            description: 'Cette action désactivera le rôle dans le système',
            label: 'Oui, je souhaite désactiver ce rôle',
          },
          submit: 'Désactiver le rôle',
        },
      },
    },
    clinics: {
      list: {
        title: 'Cliniques',
        description: 'Gestion de clinique',
        create: 'Clinique',
        one: 'Clinique',
        many: 'Cliniques',
        columns: {
          name: 'Nom',
          city: 'Ville',
          document: 'CIF',
        },
      },
      create: {
        title: 'Nouvelle clinique',
        subtitle: "Enregistrement d'une nouvelle clinique dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la clinique",
          },
          country: {
            title: 'Pays',
            description: 'Pays où se trouve la clinique',
          },
          state: {
            title: 'État',
            description: 'État ou province de la clinique',
          },
          city: {
            title: 'Ville',
            description: 'Ville où se trouve la clinique',
          },
          street: {
            title: 'Rue',
            description: 'Rue où se trouve la clinique',
          },
          zip: {
            title: 'Code postal',
            description: 'Code postal de la clinique',
          },
          timezone: {
            title: 'fuseau horaire',
            description: 'Fuseau horaire de la clinique',
          },
          center: {
            title: 'Emplacement',
            description: 'Emplacement de la clinique',
          },
          status: {
            title: 'Clinique active',
            description: {
              ovoclinic:
                "Activer ou désactiver la clinique dans le système pour l'utilisation des patients",
              ovobank:
                "Activer ou désactiver la clinique dans le système pour l'utilisation des donateurs",
            },
            label: 'Clinique active',
          },
          avatar: {
            title: 'Avatar',
            description: 'Image associée/Avatar de la clinique. ',
          },
          document: {
            title: 'Document',
            description: "Document d'identification CIF, NIF ou clinique",
          },
          title: {
            title: 'Qualification',
            description: 'Titre de la clinique',
          },
          description: {
            title: 'Description',
            description: 'Description de la clinique',
          },
          color: {
            title: 'Couleur',
            description: 'Couleur associée à la clinique dans le système.',
          },
          submit: 'Créer une clinique',
        },
      },
      update: {
        title: 'Mettre à jour la clinique',
        subtitle: 'Modification des données cliniques dans le système',
        tabs: {
          details: {
            title: 'Détails',
            description:
              "Les cliniques sont des regroupements de premier niveau du système et servent à regrouper l'activité des utilisateurs. ",
          },
          departments: {
            title: 'Départements',
            description:
              "L'objectif principal des départements est de répartir l'activité de la clinique pour une meilleure organisation et un meilleur contrôle. ",
            add: 'Ajouter un département',
          },
        },
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la clinique",
          },
          country: {
            title: 'Pays',
            description: 'Pays où se trouve la clinique',
          },
          state: {
            title: 'État',
            description: 'État ou province de la clinique',
          },
          city: {
            title: 'Ville',
            description: 'Ville où se trouve la clinique',
          },
          street: {
            title: 'Rue',
            description: 'Rue où se trouve la clinique',
          },
          zip: {
            title: 'Code postal',
            description: 'Code postal de la clinique',
          },
          timezone: {
            title: 'fuseau horaire',
            description: 'Fuseau horaire de la clinique',
          },
          center: {
            title: 'Emplacement',
            description: 'Emplacement de la clinique',
          },
          status: {
            title: {
              ovoclinic: 'Clinique active',
              ovobank: 'Clinique active',
            },
            description: {
              ovoclinic:
                "Activer ou désactiver la clinique dans le système pour l'utilisation des patients",
              ovobank:
                "Activer ou désactiver la clinique dans le système pour l'utilisation des donateurs",
            },
            label: {
              ovoclinic: 'Clinique active',
              ovobank: 'Clinique active',
            },
          },
          avatar: {
            title: 'Avatar',
            description: 'Image associée/Avatar de la clinique. ',
          },
          document: {
            title: 'Document',
            description: "Document d'identification CIF, NIF ou clinique",
          },
          title: {
            title: 'Qualification',
            description: 'Titre de la clinique',
          },
          description: {
            title: 'Description',
            description: 'Description de la clinique',
          },
          color: {
            title: 'Couleur',
            description: 'Couleur associée à la clinique dans le système',
          },
          submit: 'Mettre à jour la clinique',
        },
      },
      disable: {
        title: 'Désactiver la clinique',
        subtitle: 'Désactiver la clinique dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette clinique ?',
            description:
              "Désactiver une clinique signifie qu'elle ne peut pas être utilisée dans le système",
            label: 'Oui, je veux désactiver cette clinique',
          },
          submit: 'Désactiver la clinique',
        },
      },
      enable: {
        title: 'Activer la clinique',
        subtitle: 'Activer la clinique dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette clinique ?',
            description: "Activer une clinique signifie qu'elle peut être utilisée dans le système",
            label: 'Oui, je souhaite activer cette clinique',
          },
          submit: 'Activer la clinique',
        },
      },
      delete: {
        title: 'Désactiver la clinique',
        subtitle: 'Désactiver la clinique dans le système',
        button: 'Désactiver',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette clinique ?',
            description:
              "Désactiver une clinique signifie qu'elle ne peut pas être utilisée dans le système",
            label: 'Oui, je veux désactiver cette clinique',
          },
          submit: 'Désactiver la clinique',
        },
        passwordForm: {
          title: 'Entrez votre mot de passe',
          subtitle: 'Pour désactiver la clinique, entrez votre mot de passe',
          password: {
            title: 'Mot de passe',
            description: "Mot de passe de l'utilisateur qui effectue l'action",
          },
          submit: 'Confirmer',
        },
      },
    },
    licenses: {
      list: {
        one: 'Licence',
        many: 'Licences',
      },
    },
    movements: {
      list: {
        one: 'Mouvement',
        many: 'Mouvements',
      },
    },
    attempts: {
      list: {
        one: 'Essayé',
        many: 'Tentatives',
      },
    },
    contracts: {
      list: {
        one: 'Contracter',
        many: 'Contrats',
      },
    },
    timetables: {
      list: {
        title: 'Horaires',
        description: 'Gestion des plannings des utilisateurs',
        create: 'Calendrier',
        one: 'Calendrier',
        many: 'Horaires',
        columns: {
          name: 'Nom',
          day: 'Jour',
          range: 'Gamme',
        },
      },
      create: {
        title: 'Nouvel horaire',
        subtitle: "Enregistrement d'un nouvel horaire dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de l'horaire",
          },
          day: {
            title: 'Jour',
            description: "Jour de la semaine auquel l'horaire s'applique",
          },
          started: {
            title: 'Commencer',
            description: "Programmer l'heure de début",
          },
          ended: {
            title: 'Fin',
            description: 'Heure de fin programmée',
          },
          submit: 'Créer un planning',
        },
      },
      update: {
        title: 'Calendrier de mise à jour',
        subtitle: 'Modification des données de planification dans le système',
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de l'horaire",
          },
          submit: 'Enregistrer le planning',
        },
        tabs: {
          timetable: {
            title: 'Calendrier',
            description: 'Détails du calendrier',
          },
          timetableDay: {
            title: 'Jour',
            description: "Jours de la semaine auxquels l'horaire s'applique",
          },
        },
        timetableDays: {
          create: {
            title: 'Ajouter un jour',
            subtitle: 'Ajouter un jour au planning',
            form: {
              day: {
                title: 'Jour',
                description: "Jour de la semaine auquel l'horaire s'applique",
              },
              started: {
                title: 'Commencer',
                description: "Programmer l'heure de début",
              },
              ended: {
                title: 'Fin',
                description: 'Heure de fin programmée',
              },
              submit: 'Ajouter un jour',
            },
          },
          update: {
            title: 'Jour de mise à jour',
            subtitle: "Modification des données d'une journée dans le planning",
            form: {
              day: {
                title: 'Jour',
                description: "Jour de la semaine auquel l'horaire s'applique",
              },
              started: {
                title: 'Commencer',
                description: "Programmer l'heure de début",
              },
              ended: {
                title: 'Fin',
                description: 'Heure de fin programmée',
              },
              submit: 'Jour de mise à jour',
            },
          },
          delete: {
            title: 'Supprimer le jour',
            subtitle: 'Supprimer un jour du planning',
            form: {
              status: {
                title: 'Etes-vous sûr de vouloir supprimer ce jour ?',
                label: 'Oui, je veux supprimer ce jour',
              },
              submit: 'Supprimer le jour',
            },
          },
          add: 'Ajouter un jour',
        },
      },
      delete: {
        title: 'Supprimer le planning',
        subtitle: 'Désactiver la planification dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette programmation ?',
            description:
              "La désactivation d'un planning signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je souhaite désactiver ce planning',
          },
          submit: 'Désactiver le planning',
        },
      },
      disable: {
        title: 'Désactiver le planning',
        subtitle: 'Désactiver la planification dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette programmation ?',
            description:
              "La désactivation d'un planning signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je souhaite désactiver ce planning',
          },
          submit: 'Désactiver le planning',
        },
      },
      enable: {
        title: 'Activer le planning',
        subtitle: 'Activer la planification dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce planning ?',
            description:
              "L'activation d'un planning signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer ce planning',
          },
          submit: 'Activer le planning',
        },
      },
    },
    departments: {
      list: {
        title: 'Départements',
        description: 'Gestion du département',
        create: 'Département',
        one: 'Département',
        many: 'Départements',
        columns: {
          name: 'Nom',
          responsible: 'Responsable',
          language: 'Langue',
          country: 'Pays',
          clinic: 'Clinique',
          created: 'Création',
        },
      },
      create: {
        title: 'Nouveau département',
        subtitle: "Enregistrement d'un nouveau département dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du département",
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique à laquelle appartient le département',
          },
          status: {
            title: 'Département actif',
            description: 'Activer ou désactiver le département dans le système',
            label: 'Département actif',
          },
          languageId: {
            title: 'Langue',
            description: 'Langue du département',
          },
          responsibleId: {
            title: 'Responsable',
            description: 'Utilisateur responsable du département',
          },
          submit: 'Créer',
        },
      },
      enable: {
        title: 'Activer le département',
        subtitle: 'Activer le département dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce département ?',
            label: 'Oui, je souhaite activer ce département',
          },
          submit: 'Activer le département',
        },
      },
      disable: {
        title: 'Désactiver le département',
        subtitle: 'Désactiver le département dans le système',
        form: {
          status: {
            title: 'Etes-vous sûr de vouloir désactiver ce département ?',
            label: 'Oui, je souhaite désactiver ce département',
          },
          submit: 'Désactiver le département',
        },
      },
      update: {
        title: 'Département de mise à jour',
        subtitle: "Modifier les données d'un service dans le système",
        department: {
          tab: 'Département',
          description: 'Détails du département',
        },
        clinic: {
          tab: 'Clinique',
          description: 'Clinique à laquelle appartient le département',
        },
        responsible: {
          tab: 'Responsable',
          description: 'Chef de département',
        },
        language: {
          tab: 'Langue',
          description: 'Langue du département',
        },
        tabs: {
          department: {
            title: 'Département',
            description: 'Détails du département',
          },
          members: {
            title: 'Membres',
            description: 'Membres du département. ',
          },
        },
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du département",
          },
          languageId: {
            title: 'Langue',
            description: 'Langue du département',
          },
          responsibleId: {
            title: 'Responsable',
            description: 'Utilisateur responsable du département',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique à laquelle appartient le département',
          },
          status: {
            title: 'Département actif',
            description: 'Activer ou désactiver le département dans le système',
            label: 'Département actif',
          },
          submit: 'Département de mise à jour',
        },
        members: {
          one: 'Membre',
          many: 'Membres',
          add: 'Ajouter un membre',
          create: {
            title: 'Ajouter un membre',
            subtitle: 'Un membre est un utilisateur qui appartient à un département. ',
            form: {
              contractId: {
                title: 'Utilisateur',
              },
              submit: 'Ajouter un membre',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Supprimer un membre',
        subtitle: 'Supprimer un membre du département. ',
        form: {
          submit: 'Supprimer un membre',
        },
      },
    },
    countries: {
      list: {
        title: 'Pays',
        description: 'Gestion pays',
        create: 'Pays',
        one: 'Pays',
        many: 'Pays',
        columns: {
          name: 'Nom',
          code: 'Code',
          coin: 'Devise',
          updated: 'Mise à jour',
          created: 'Création',
        },
      },
      update: {
        title: 'Modifier le pays',
        subtitle: 'Modifier le pays dans le système',
        form: {
          countryNameEn: {
            title: 'Nom anglais',
            description: 'Nom anglais du pays',
          },
          countryNameEs: {
            title: 'Nom espagnol',
            description: 'Nom espagnol du pays',
          },
          flag: {
            title: 'Drapeau',
            description: 'Drapeau du pays représenté par une émoticône',
          },
          coin: {
            title: 'Devise',
            description: 'Monnaie utilisée dans le pays',
          },
          code: {
            title: 'code du pays',
            description: 'Code pays représenté sur 2 caractères (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Préfixe',
            description: 'Indicatif téléphonique du pays',
          },
          center: {
            title: 'Emplacement',
            description: 'Localisation du pays',
          },
          geometry: {
            title: 'Géométrie au format json',
            description: 'Géométrie du pays au format json',
          },
          latitude: {
            title: 'Coordonnée de latitude',
            description: 'Coordonnée de latitude du pays',
          },
          longitude: {
            title: 'Coordonnée de longueur',
            description: 'Coordonnée de longitude du pays',
          },
          description: {
            title: 'Description',
            description: 'Pays Description',
          },
          color: {
            title: 'Format couleur #ffffff, réseau, rgb(0,0,0)',
            description: 'Couleur du pays en hexadécimal, RVB ou nom au format anglais',
          },
          submit: 'Enregistrer le pays',
        },
      },
      delete: {
        title: 'Supprimer le pays',
        subtitle: 'Désactiver le pays dans le système',
        form: {
          submit: 'Supprimer le pays',
        },
      },
      disable: {
        title: 'Désactiver le pays',
        subtitle: 'Désactiver le pays dans le système',
        form: {
          submit: 'Désactiver le pays',
        },
      },
      enable: {
        title: 'Activer le pays',
        subtitle: 'Activer le pays dans le système',
        form: {
          submit: 'Activer le pays',
        },
      },
      create: {
        title: 'nouveau pays',
        subtitle: "Enregistrement d'un nouveau pays dans le système",
        form: {
          countryNameEn: {
            title: 'Nom anglais',
            description: 'Nom anglais du pays',
          },
          countryNameEs: {
            title: 'Nom espagnol',
            description: 'Nom espagnol du pays',
          },
          flag: {
            title: 'Drapeau',
            description: 'Drapeau du pays représenté par une émoticône',
          },
          coin: {
            title: 'Devise',
            description: 'Monnaie utilisée dans le pays',
          },
          code: {
            title: 'code du pays',
            description: 'Code pays représenté sur 2 caractères (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Préfixe',
            description: 'Indicatif téléphonique du pays',
          },
          center: {
            title: 'Emplacement',
            description: 'Localisation du pays',
          },
          geometry: {
            title: 'Géométrie au format json',
            description: 'Géométrie du pays au format json',
          },
          latitude: {
            title: 'Coordonnée de latitude',
            description: 'Coordonnée de latitude du pays',
          },
          longitude: {
            title: 'Coordonnée de longueur',
            description: 'Coordonnée de longitude du pays',
          },
          description: {
            title: 'Description',
            description: 'Pays Description',
          },
          color: {
            title: 'Format couleur #ffffff, réseau, rgb(0,0,0)',
            description: 'Couleur du pays en hexadécimal, RVB ou nom au format anglais',
          },
          submit: 'Créer un pays',
        },
      },
    },
    access: {
      create: {
        title: 'Nouvel accès',
        subtitle: "Enregistrement d'un nouvel accès dans le système",
        form: {
          ip: {
            title: 'Adresse IP',
            description: "Adresse IP de l'appareil accédant au système",
          },
          status: {
            title: 'Accès au système',
            description:
              "Activer ou désactiver l'accès au système pour cet utilisateur et cette adresse IP",
            label: "Autoriser l'accès au système",
          },
          submit: 'Créer un accès',
        },
      },
    },
    settings: {
      title: 'Configuration',
      subtitle: 'Configuration de la plateforme',
      form: {
        loginEnabled: {
          title: "Activer l'accès",
          description: "Si l'accès est activé, n'importe quel utilisateur peut accéder au système",
          label: "Autoriser l'accès",
        },
        attemptLimitEnabled: {
          title: 'Activer la limite de tentatives',
          description:
            "Si la limite de tentatives est activée, l'utilisateur sera verrouillé si plus de tentatives que le nombre autorisé pour l'utilisateur ne parviennent pas à se connecter. ",
          label: 'Autoriser la limite de tentatives',
        },
        movementLimitEnabled: {
          title: 'Activer la limite de mouvement',
          description:
            "Si la limite de mouvement est activée, l'utilisateur sera bloqué s'il fait plus de demandes que ce qui est autorisé à l'utilisateur. ",
          label: 'Autoriser la limite de mouvement',
        },
        submit: 'Enregistrer les paramètres',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Activités',
      contacts: 'Contacts',
      marketing: 'Commercialisation',
      sales: 'Ventes',
      directory: 'Annuaire',
      customers: 'Clients',
      commercial: 'Commercial',
      campaigns: 'Campagnes',
      funnels: 'Entonnoirs',
      channels: 'Canaux',
      leads: 'Conduit',
      transferLead: 'Transfert de leads',
      patients: {
        ovoclinic: 'Patients',
        ovobank: 'Donateurs',
      },
      programs: 'Programmes',
      maintenances: 'Entretien',
      tasks: 'Tâches',
      transferTask: 'Transfert de tâches',
      createTaskMassively: 'Créer des tâches en masse',
      actions: 'Calendrier',
      origins: 'Origines',
      techniques: 'Techniques',
      services: 'Services',
      activities: 'Activités',
      results: 'Résultats',
      reasons: 'Raisons',
      products: 'Produits',
      categories: 'Catégories',
      dashboard: 'Tableau de bord',
      catalog: 'Catalogue',
      phases: 'Étapes',
      causes: 'Causes',
      treatments: 'Traitements',
      cycles: 'Cycles',
    },
    faq: {
      title: 'FAQ',
      description: 'Questions fréquemment posées',
      list: {
        '1': {
          question: 'Comment puis-je créer un prospect ?',
          answer: 'Pour créer un prospect, procédez comme suit :',
        },
        '2': {
          question: 'Comment puis-je créer une campagne ?',
          answer: 'Pour créer une campagne, procédez comme suit :',
        },
        '3': {
          question: 'Comment puis-je créer une tâche ?',
          answer: 'Pour créer une tâche, procédez comme suit :',
        },
        '4': {
          question: 'Comment puis-je transférer un prospect ?',
          answer: 'Pour transférer un prospect, procédez comme suit :',
        },
      },
    },
    information: {
      list: {
        '0': {
          title: 'Flux de travail du département NATIONAL',
          sublists: {
            '0': {
              title: "Centre d'appels",
            },
            '1': {
              title: 'Soins aux patients Commercial',
            },
            '2': {
              title: 'Médecin de soins aux patients',
            },
          },
        },
        '1': {
          title: 'Flux de travail du département INTERNATIONAL',
        },
      },
    },
    dashboard: {
      title: 'Tableau de bord',
      description: 'Panneau de contrôle de votre activité',
      actions: {
        title: 'Tâches',
        description: {
          ovoclinic: 'Gestion des tâches qui vous sont assignées. ',
          ovobank: 'Gestion des tâches qui vous sont assignées. ',
        },
      },
      receipts: {
        title: 'Dernières recettes',
        description: 'Paiements effectués à vos leads',
        list: {
          columns: {
            contactName: 'Nom',
            payments: 'Paiements',
            updated: 'F. mise à jour',
          },
          many: 'Recettes',
          one: 'Reçu',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Conduit',
          ovobank: 'Donateurs',
        },
        description: {
          ovoclinic: 'Gestion de vos leads/patients',
          ovobank: 'Gestion de vos leads/donateurs',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campagnes',
          ovobank: 'Campagnes',
        },
        description: {
          ovoclinic: 'Gestion de vos campagnes',
          ovobank: 'Gestion de vos campagnes',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Transfert de leads',
        description: 'Transférer les campagnes des leads',
        create: 'Plomb',
        one: 'Plomb',
        many: 'Conduit',
        required: 'Vous devez sélectionner au moins un prospect',
        columns: {
          name: 'Nom',
          activity: 'Activité',
          responsible: 'Responsable',
          campaignName: 'Cloche',
          started: 'Date estimée',
          clinicName: 'Clinique',
          created: 'Création',
          resultId: 'Progrès',
          phones: 'Téléphone',
          emails: 'E-mail',
        },
      },
      confirm: {
        title: 'Transférer des prospects',
        subtitle: 'Réaffecter la campagne à plusieurs prospects',
        success: 'Prospects transférés avec succès',
        steps: {
          campaign: {
            form: 'Campagne sélectionnée',
          },
        },
        form: {
          campaignId: {
            title: 'Sélectionnez une campagne',
            description: 'Campagne à laquelle les leads seront réaffectés',
          },
          responsibleId: {
            title: 'Sélectionnez le responsable',
            description:
              "Personne responsable à qui les leads seront réaffectés lors d'un changement de campagne",
          },
          submit: 'Transfert',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Transfert de tâches',
          ovobank: 'Transfert de tâches',
        },
        description: {
          ovoclinic: "Transférer des tâches à d'autres utilisateurs. ",
          ovobank: "Transférer des tâches à d'autres utilisateurs. ",
        },
        create: 'Tâche',
        one: 'Tâche',
        many: 'Tâches',
        columns: {
          activity: 'Activité',
          phone: 'Téléphone',
          contact: 'Contact',
          subject: 'Affaire',
          resultId: 'Progrès',
          campaign: 'Cloche',
          clinic: 'Clinique',
          contactTime: 'Préférence temporelle',
          created: 'Création',
          user: 'Responsable',
          started: 'Date de début estimée',
          finished: 'Durée',
        },
      },
      confirm: {
        title: 'Tâches de transfert',
        subtitle: 'Réaffecter le responsable à plusieurs tâches',
        success: 'Tâches transférées avec succès',
        form: {
          responsibleId: {
            title: 'Sélectionnez le responsable',
            description: 'Personne à qui les tâches seront réaffectées',
          },
          submit: 'Transfert',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Créer des tâches en masse',
        description: "Cet écran vous permet de créer un type d'activité pour plusieurs leads",
        columns: {
          name: 'Nom',
          clinicName: 'Clinique',
          campaignName: 'Cloche',
          phones: 'Téléphone',
          emails: 'E-mail',
          created: 'Création',
          lastAction: 'Dernière tâche',
          subject: 'Affaire',
          lastActionDate: 'Date de la tâche en attente',
        },
        noItemSelected: "Aucun prospect n'est sélectionné",
        one: 'Plomb',
        many: 'Conduit',
      },
      selectActivity: {
        title: 'Sélectionnez une activité',
        subtitle: "Sélectionnez l'activité que vous souhaitez créer pour les leads sélectionnés",
        form: {
          activityId: {
            title: 'Activité',
            description: 'Activité que vous souhaitez créer pour les prospects sélectionnés',
            required: 'Vous devez sélectionner une activité',
          },
          subject: {
            title: 'Affaire',
            description:
              'Le sujet qui sera défini pour toutes les tâches qui seront créées pour les leads sélectionnés',
          },
          submit: 'Créer des tâches',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Tâches',
          ovobank: 'Tâches',
        },
        description: {
          ovoclinic: 'Gestion des tâches qui vous sont assignées. ',
          ovobank: 'Gestion des tâches qui vous sont assignées. ',
        },
        create: {
          ovoclinic: 'Tâche',
          ovobank: 'Tâche',
        },
        one: 'Tâche',
        many: 'Tâches',
        columns: {
          activity: 'Activité',
          contact: 'Contact',
          responsible: 'Responsable',
          subject: 'Affaire',
          resultId: 'Progrès',
          resultName: 'Résultat',
          campaign: 'Cloche',
          clinic: 'Clinique',
          contactTime: 'Préférence temporelle',
          created: 'Création',
          user: 'Responsable',
          started: 'Date de début estimée',
          finished: 'Durée',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'Nouvelle action',
              subtitle: "Enregistrement d'une nouvelle action dans le système. ",
              form: {
                clinicId: {
                  title: 'Clinique',
                  description: "Clinique vers laquelle l'action est dirigée",
                },
                submit: 'Suivant',
              },
            },
            lead: {
              title: 'Nouvelle action',
              subtitle: "Enregistrement d'une nouvelle action dans le système. ",
              form: {
                leadId: {
                  title: 'Plomb',
                  description: 'Diriger vers qui l’action est dirigée',
                },
                submit: 'Suivant',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'Toute la journée',
        previous: 'Ancien',
        next: 'Suivant',
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        agenda: 'Ordre du jour',
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
      },
    },
    channels: {
      list: {
        title: 'Canaux',
        description: 'Gestion des canaux de communication',
        create: 'Canal',
        one: 'Canal',
        many: 'Canaux',
        columns: {
          name: 'Nom',
          funnels: 'Entonnoirs',
        },
      },
      create: {
        title: 'Nouvelle chaîne',
        subtitle: "Enregistrement d'une nouvelle chaîne dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la chaîne",
          },
          status: {
            title: 'Canal actif',
            description: 'Activer ou désactiver le canal dans le système',
            label: 'Canal actif',
          },
          submit: 'Créer une chaîne',
        },
      },
      update: {
        title: 'Mettre à jour la chaîne',
        subtitle: "Modifier les données d'un canal dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la chaîne",
          },
          status: {
            title: 'Canal actif',
            description: 'Activer ou désactiver le canal dans le système',
            label: 'Canal actif',
          },
          submit: 'Mettre à jour la chaîne',
        },
      },
      disable: {
        title: 'Désactiver la chaîne',
        subtitle: 'Désactiver le canal dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette chaîne ?',
            description:
              "La désactivation d'un canal signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je veux désactiver cette chaîne',
          },
          submit: 'Désactiver la chaîne',
        },
      },
      enable: {
        title: 'Activer la chaîne',
        subtitle: 'Activer le canal dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette chaîne ?',
            description: "L'activation d'un canal signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer cette chaîne',
          },
          submit: 'Activer la chaîne',
        },
      },
      delete: {
        title: 'Supprimer la chaîne',
        subtitle: 'Cette action est irréversible',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver cette chaîne',
            label: 'Oui, je veux supprimer cette chaîne',
          },
          submit: 'Éliminer',
        },
      },
    },
    cycles: {
      list: {
        title: 'Cycles',
        description: 'Gestion des cycles de traitement',
        create: 'Faire du vélo',
        one: 'Faire du vélo',
        many: 'Cycles',
        columns: {
          name: 'Nom',
          complexity: 'Complexité',
          created: 'Date de création',
          code: 'Code',
        },
      },
      create: {
        title: 'Nouveau cycle',
        subtitle: "Enregistrement d'un nouveau cycle dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du cycle",
          },
          complexity: {
            title: 'Complexité',
            description: 'Complexité du cycle',
          },
          code: {
            title: 'Code',
            description: "Code d'identification du cycle",
          },
          description: {
            title: 'Description',
            description: 'Description des cycles',
          },
          submit: 'Créer un cycle',
        },
      },
      update: {
        title: 'Cycle de mise à jour',
        subtitle: "Modifier les données d'un cycle dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du cycle",
          },
          complexity: {
            title: 'Complexité',
            description: 'Complexité du cycle',
          },
          code: {
            title: 'Code',
            description: "Code d'identification du cycle",
          },
          description: {
            title: 'Description',
            description: 'Description des cycles',
          },
          submit: 'Cycle de mise à jour',
        },
      },
      enable: {
        title: 'Activer le cycle',
        subtitle: 'Activer le cycle dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce cycle ?',
            description: "Activer un cycle signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je veux activer ce cycle',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver le cycle',
        subtitle: 'Désactiver le cycle dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce cycle ?',
            description:
              "La désactivation d'un cycle signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je veux désactiver ce cycle',
          },
          submit: 'Désactiver',
        },
      },
    },
    funnels: {
      list: {
        title: 'Entonnoirs',
        description: 'Gestion des tunnels de conversion. ',
        create: 'Entonnoir',
        one: 'Entonnoir',
        many: 'Entonnoirs',
        params: {
          channelId: 'Entonnoirs filtrés par canal de communication',
        },
        columns: {
          name: 'Nom',
          channelName: 'Canal',
        },
      },
      create: {
        title: 'Nouvel entonnoir',
        subtitle: "Enregistrement d'un nouvel entonnoir dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Identifier le nom de l'entonnoir",
          },
          channelId: {
            title: 'Canal',
            description: "Canal de communication associé à l'entonnoir",
          },
          status: {
            title: 'Entonnoir actif',
            description: "Activer ou désactiver l'entonnoir dans le système",
            label: 'Entonnoir actif',
          },
          submit: 'Créer un entonnoir',
        },
      },
      update: {
        title: 'Entonnoir de mise à jour',
        subtitle: "Modifier les données d'un entonnoir dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Identifier le nom de l'entonnoir",
          },
          channelId: {
            title: 'Canal',
            description: "Canal de communication associé à l'entonnoir",
          },
          status: {
            title: 'Entonnoir actif',
            description: "Activer ou désactiver l'entonnoir dans le système",
            label: 'Entonnoir actif',
          },
          submit: 'Entonnoir de mise à jour',
        },
      },
      delete: {
        title: "Supprimer l'entonnoir",
        subtitle: 'Cette action est irréversible',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver cet entonnoir',
            label: 'Oui, je souhaite supprimer cet entonnoir',
          },
          submit: 'Éliminer',
        },
      },
      enable: {
        title: "Activer l'entonnoir",
        subtitle: "Activer l'entonnoir dans le système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cet entonnoir ?',
            description:
              "L'activation d'un entonnoir signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer cet entonnoir',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'entonnoir",
        subtitle: "Désactiver l'entonnoir dans le système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cet entonnoir ?',
            description:
              "La désactivation d'un entonnoir signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je souhaite désactiver cet entonnoir',
          },
          submit: 'Désactiver',
        },
      },
    },
    leads: {
      import: {
        title: 'Importation de leads',
        subtitle: 'Importer des leads dans le système',
        description: 'Chargement des leads dans le système via un fichier csv. ',
        list: {
          columns: {
            name: 'Nom',
            errors: "Nombre d'erreurs",
            created: "Date d'importation",
          },
          one: 'Importer',
          many: 'Importations',
        },
        delete: {
          title: "Supprimer l'enregistrement d'importation",
          subtitle: "Supprimer l'enregistrement d'importation dans le système",
          form: {
            submit: 'Éliminer',
          },
        },
      },
      list: {
        title: 'Conduit',
        description: 'Gestion des clients potentiels générés grâce aux campagnes',
        create: 'Plomb',
        one: 'Plomb',
        many: 'Conduit',
        columns: {
          name: 'Nom',
          activity: 'Activité',
          responsible: 'Responsable',
          campaignName: 'Cloche',
          started: 'Date estimée',
          clinicName: 'Clinique',
          created: 'Création',
          resultId: 'Progrès',
          phones: 'Téléphone',
          emails: 'E-mail',
        },
        csv: {
          select: 'IMPORTER .CSV',
          somethingwentwrong: 'Leads non chargés',
          wrongdata: 'le fichier est erroné ou les données ne correspondent pas au bon format',
          loaded: 'Leads chargés correctement',
          downloadCsv:
            "Certains leads n'ont pas été téléchargés, vous pouvez télécharger la liste des leads incorrects sur le bouton de téléchargement",
        },
      },
      create: {
        title: 'Nouvelle piste',
        subtitle: "Enregistrement d'un nouveau prospect dans le système",
        form: {
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler le prospect.',
          },
          clinicId: {
            title: 'Clinique',
            description: 'La clinique préférée du responsable. ',
          },
          contactId: {
            title: 'Contact',
            description: 'Contact associé au prospect. ',
          },
          campaignId: {
            title: 'Cloche',
            description: 'Campagne marketing actuelle du prospect.',
          },
          submit: 'Créer un prospect',
        },
      },
      update: {
        title: 'Mettre à jour le responsable',
        subtitle: 'Modification des données des prospects dans le système',
        disabledBanner: {
          title: 'Prospect inactif',
        },
        tabs: {
          lead: {
            title: 'Plomb',
            description:
              'Les détails du prospect visent à comprendre l’origine du prospect et son suivi. ',
          },
          actions: {
            title: 'Tâches',
            description:
              'Les tâches sont les actions entreprises pour convertir un prospect en donateur. ',
          },
          responsible: {
            title: 'Responsable',
            description:
              "Le responsable est l'utilisateur qui est en charge de réaliser les tâches de suivi du lead.",
          },
          contact: {
            title: 'Contact',
            description: "Un prospect provient d'un contact. ",
          },
          notes: {
            title: 'Observations',
            description:
              "Les observations sont des commentaires ajoutés aux prospects pour mémoriser des détails importants ou pour partager des informations avec d'autres vendeurs.",
          },
          specialist: {
            title: 'Spécialiste',
            description: 'Le spécialiste affecté au responsable',
          },
          communications: {
            title: 'Communications',
            description:
              'Intégration avec le système de messagerie pour surveiller les communications avec les leads.',
          },
        },
        leadPassport: {
          birthCountry: 'Pays de naissance',
          birthDate: 'Date de naissance',
          height: 'Hauteur',
          weight: 'Poids',
          contactTime: 'Préférence temporelle',
          created: 'Date de création',
          consent: {
            title: 'Consentement',
            type: {
              legal: 'Légal',
              marketing: 'Commercialisation',
            },
          },
        },
        form: {
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler le prospect.',
          },
          clinicId: {
            title: 'Clinique',
            description: 'La clinique préférée du responsable. ',
          },
          contactId: {
            title: 'Contact',
            description: 'Contact associé au prospect. ',
          },
          campaignId: {
            title: 'Cloche',
            description: 'La campagne marketing actuelle du prospect',
          },
          reasonId: {
            title: 'Raison',
            description: 'Raison',
          },
          submit: 'Mettre à jour le responsable',
        },
        lead: {
          tab: 'Plomb',
          description: 'Détails du prospect',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsable',
              description: 'Utilisateur responsable du prospect',
            },
            submit: 'Désigner un responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Spécialiste',
              description: 'Spécialiste affecté au responsable',
            },
            submit: 'Désigner un spécialiste',
          },
        },
        actions: {
          tab: 'Tâches',
          description: 'Tâches principales',
          add: 'Ajouter une tâche',
          create: {
            title: 'Nouvelle tâche',
            subtitle: "Enregistrement d'une nouvelle tâche dans le système",
            confirmationMessage: 'Tâche imprévue. \n\n\n',
            form: {
              activityId: {
                title: 'Activité',
                description: 'Activité à faire',
              },
              responsibleId: {
                title: 'Responsable',
                description: "Responsable de la réalisation de l'activité",
              },
              resultId: {
                title: 'Résultat',
                description: "Résultat de l'activité",
              },
              started: {
                title: 'Date estimée',
                description: "Date à laquelle l'activité devrait avoir lieu",
              },
              subject: {
                title: 'Affaire',
                description: 'Sujet de la tâche',
              },
              observation: {
                title: 'Note',
                description: "Un petit commentaire sur l'activité",
              },
              submit: 'Créer une tâche',
            },
            steps: {
              clinic: {
                form: 'Clinique sélectionnée',
              },
              lead: {
                form: 'Prospect sélectionné',
              },
            },
          },
          update: {
            title: 'Tâche de mise à jour',
            subtitle: "Modifier les données d'une tâche dans le système",
            form: {
              activityId: {
                title: 'Activité',
                description: 'Activité à faire',
              },
              responsibleId: {
                title: 'Responsable',
                description: "Responsable de la réalisation de l'activité",
              },
              resultId: {
                title: 'Résultat',
                description: "Résultat de l'activité",
              },
              started: {
                title: 'Date estimée',
                description: "Date à laquelle l'activité devrait avoir lieu",
              },
              subject: {
                title: 'Affaire',
                description: 'Sujet de la tâche',
              },
              observation: {
                title: 'Note',
                description: "Un petit commentaire sur l'activité",
              },
              submit: 'Tâche de mise à jour',
            },
            tabs: {
              action: {
                title: 'Tâche',
                description: 'Informations associées à la tâche',
              },
              actionEnding: {
                title: 'Réalisation',
                description: 'Informations associées à la réalisation de la tâche',
              },
              alerts: {
                title: 'Alertes',
                description: 'Alertes associées à la tâche. ',
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Résultat',
                },
                result: {
                  title: 'Résultat',
                  description: "Résultat de l'activité. ",
                },
                communicationChannel: {
                  title: 'Voie de communication',
                  description: "Voie de communication utilisée pour l'activité",
                },
                submit: 'Terminer la tâche',
                submitNextSuggestion: 'Finition',
              },
            },
            alert: {
              list: {
                title: 'Alertes',
                columns: {
                  expectedAlarmTime: "Date de l'alarme",
                },
                many: 'Alertes',
                one: 'Alerte',
              },
              add: 'Ajouter une alerte',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'Général',
              },
              notes: {
                title: 'Observations',
              },
              contactNotes: {
                title: 'Observations',
              },
            },
            fields: {
              newLead: 'Nouvelle piste',
              active: 'Responsable actif',
              inactive: 'Prospect inactif',
              canceled: 'Prospect annulé',
              converted: 'Plomb converti',
              clinic: 'Clinique',
              contactTime: 'Préférence temporelle',
              responsible: 'Responsable',
              funnel: 'Entonnoir',
              consent: 'Consentement',
              campaign: 'Cloche',
              originCampaign: "Campagne d'origine",
              created: 'Date de création',
              legal: 'Légal',
              marketing: 'Commercialisation',
              donationPreference: 'Ville',
              birthCountry: 'Pays de naissance',
              birthDate: 'Date de naissance',
              height: '(CM)',
              weight: '(kg)',
            },
          },
        },
      },
      delete: {
        title: 'Supprimer le prospect',
        subtitle: 'Cette action est irréversible',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver ce prospect',
            label: 'Oui, je souhaite supprimer ce prospect',
          },
          confirm: 'Cette action supprimera tout ce qui concerne le prospect, êtes-vous sûr ?',
          submit: 'Éliminer',
        },
      },
      disable: {
        title: 'Désactiver le prospect',
        subtitle: 'Désactiver le plomb dans le système',
        form: {
          reasonId: {
            title: 'Raison',
            description: 'Raison pour laquelle le prospect est désactivé',
          },
          submit: 'Désactiver',
        },
        confirmationMessage: 'Raison non jointe. ',
      },
      enable: {
        title: 'Activer le prospect',
        subtitle: 'Activer le lead dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce prospect ?',
            description: "Activer un prospect signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer ce prospect',
          },
          submit: 'Activer',
        },
      },
      addSpecialist: {
        title: 'Désigner un spécialiste',
        subtitle: 'Désigner un spécialiste pour diriger',
        form: {
          specialistId: {
            title: 'Spécialiste',
            description: 'Spécialiste affecté au responsable',
          },
          submit: 'Désigner un spécialiste',
        },
      },
    },
    reasons: {
      list: {
        title: 'Raisons',
        description:
          'Gestion des raisons pour lesquelles un client potentiel peut se désinscrire ou être considéré comme invalide',
        create: 'Raison',
        one: 'Raison',
        many: 'Raisons',
        columns: {
          name: 'Qualification',
        },
      },
      create: {
        title: 'Nouvelle raison',
        subtitle: "Enregistrement d'un nouveau motif dans le système",
        form: {
          name: {
            title: 'Qualification',
            description: "Titre d'identification de la raison",
          },
          description: {
            title: 'Description',
            description: "Description détaillée de l'annulation du prospect",
          },
          color: {
            title: 'Couleur',
            description: 'Couleur associée au motif',
          },
          notify: {
            title: 'Notifier',
            description: "S'il faut ou non notifier la raison dans le système",
            label: 'Notifier la raison',
          },
          icon: {
            title: 'Icône',
            description: 'Icône associée au motif',
            label: "Sélectionnez l'icône...",
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          canceled: {
            title: 'Faible',
            description:
              'En sélectionnant cette option, il est indiqué que ce motif attribue le statut de retrait définitif. ',
            label: 'Raison du retrait',
          },
          subject: {
            title: 'Affaire',
            description: 'Problème de raison',
          },
          message: {
            title: 'Message',
            description: 'Message de raison',
          },
          submit: 'Créer un motif',
        },
      },
      enable: {
        title: 'Activer le motif',
        subtitle: 'Activer la raison dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette raison ?',
            description: "Activer un motif signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je veux activer cette raison',
          },
          submit: 'Activer le motif',
        },
      },
      disable: {
        title: 'Désactiver la raison',
        subtitle: 'Désactiver la raison dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette raison ?',
            description:
              "Désactiver un motif signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je veux désactiver cette raison',
          },
          submit: 'Désactiver la raison',
        },
      },
      update: {
        title: 'Raison de la mise à jour',
        subtitle: "Modifier les données d'un motif dans le système",
        tabs: {
          reason: {
            title: 'Raison',
            description: 'Informations sur la raison',
          },
          notification: {
            title: 'Définir une notification',
            description:
              "Paramétrage de la notification à envoyer lorsqu'une annulation se produit pour cette raison particulière",
          },
          activity: {
            title: 'Activité suivante',
            description:
              'Si cette section de motif est configurée, une fois le Lead avec ce motif désactivé, une activité à réaliser sera suggérée. ',
          },
        },
        reason: {
          form: {
            name: {
              title: 'Qualification',
              description: "Titre d'identification de la raison",
            },
            description: {
              title: 'Description',
              description: "Description détaillée de l'annulation du prospect",
            },
            color: {
              title: 'Couleur',
              description: 'Couleur associée au motif',
            },
            notify: {
              title: 'Notifier',
              description: "S'il faut ou non notifier la raison dans le système",
              label: 'Notifier la raison',
            },
            icon: {
              title: 'Icône',
              description: 'Icône associée au motif',
              label: "Sélectionnez l'icône...",
              search: {
                placeholder: 'Icône de recherche...',
              },
            },
            canceled: {
              title: 'Faible',
              description:
                'En sélectionnant cette option, il est indiqué que ce motif attribue le statut de retrait définitif. ',
              label: 'Raison du retrait',
            },
            subject: {
              title: 'Affaire',
              description: 'Problème de raison',
            },
            message: {
              title: 'Message',
              description: 'Message de raison',
            },
            submit: 'Raison de la mise à jour',
          },
          notification: {
            form: {
              notify: {
                title: 'Activer les notifications',
                description: "Notifier ou non lorsqu'une annulation survient pour cette raison",
                label: 'Activer le système de notification',
              },
              subject: {
                title: 'Affaire',
                description: 'Objet de la notification',
              },
              message: {
                title: 'Message',
                description: 'Message à afficher dans la notification',
              },
              submit: 'Enregistrer les paramètres',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Activité suivante',
              description: "Activité suggérée à faire en cas d'annulation",
            },
            nextActivityDelay: {
              title: "Temps d'attente",
              description: "Temps d'attente pour réaliser l'activité proposée, en jours",
            },
            submit: 'Garder',
          },
        },
      },
    },
    patients: {
      list: {
        title: {
          ovoclinic: 'Patients',
          ovobank: 'Donateurs',
        },
        description: {
          ovoclinic: 'Gestion des patients dans le système',
          ovobank: 'Gestion des donateurs dans le système',
        },
        create: {
          ovoclinic: 'Patient',
          ovobank: 'Donneur',
        },
        one: {
          ovoclinic: 'Patient',
          ovobank: 'Donneur',
        },
        many: {
          ovoclinic: 'Patients',
          ovobank: 'Donateurs',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contact',
              ovobank: 'Contact',
            },
          },
          description: {
            ovoclinic: 'Description',
            ovobank: 'Description',
          },
          phones: {
            ovoclinic: 'Téléphone',
            ovobank: 'Téléphone',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'clinique cycliste',
              ovobank: 'clinique cycliste',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clinique',
              ovobank: 'Clinique',
            },
          },
          created: {
            ovoclinic: 'Création',
            ovobank: 'Création',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'Nouveau patient',
          ovobank: 'Nouveau donateur',
        },
        subtitle: {
          ovoclinic: "Enregistrement d'un nouveau patient dans le système",
          ovobank: "Enregistrement d'un nouveau donneur dans le système",
        },
        form: {
          description: {
            title: {
              ovobank: 'Description',
              ovoclinic: 'Description',
            },
            description: {
              ovoclinic: 'Une brève description pour détailler le patient.',
              ovobank: 'Une brève description pour détailler le donateur.',
            },
          },
          leadId: {
            title: 'Plomb',
            description: {
              ovoclinic: 'Plomb associé au patient',
              ovobank: 'Piste associée au donateur',
            },
          },
          clinicId: {
            title: 'Clinique',
            description: {
              ovoclinic: 'La clinique préférée du patient.',
              ovobank: 'La clinique préférée du donneur.',
            },
          },
          campaignId: {
            title: 'Cloche',
            description: {
              ovoclinic: 'La campagne de marketing à domicile. ',
              ovobank: 'La campagne de marketing à domicile du donateur. ',
            },
          },
          contactId: {
            title: 'Contact',
            description: {
              ovoclinic: 'Le contact grâce auquel vous êtes devenu patient.',
              ovobank: 'Le contact grâce auquel vous êtes devenu donateur.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Accord de garantie',
              ovobank: 'Accord de garantie',
            },
            description: {
              ovoclinic: 'Le patient accepte le contrat de garantie',
              ovobank: "Le donateur accepte l'accord de garantie",
            },
            label: {
              ovoclinic: "Oui, j'accepte le contrat de garantie",
              ovobank: "Oui, j'accepte le contrat de garantie",
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentement',
              ovobank: 'Consentement',
            },
            description: {
              ovoclinic: 'Le patient donne son consentement à être traité',
              ovobank: 'Le donneur donne son consentement à être traité',
            },
            label: {
              ovoclinic: 'Oui, je donne mon accord',
              ovobank: 'Oui, je donne mon accord',
            },
          },
          submit: {
            ovoclinic: 'Créer un patient',
            ovobank: 'Créer un donateur',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Traitement',
              },
              phase: {
                form: 'Scène',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Scène',
                description: 'Étape du traitement',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Programme',
                description: 'Nom du programme',
              },
              phaseId: {
                title: 'Scène',
                description: 'Étape du traitement',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Mettre à jour le patient',
          ovobank: 'Mettre à jour le donateur',
        },
        subtitle: {
          ovoclinic: 'Modification des données du patient dans le système',
          ovobank: "Modification des données d'un donneur dans le système",
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Patient',
              ovobank: 'Donneur',
            },
            description: {
              ovoclinic: 'Détails du patient',
              ovobank: 'Détails du donateur',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contact',
              ovobank: 'Contact',
            },
            description: {
              ovoclinic: 'Coordonnées des patients',
              ovobank: 'Coordonnées du donateur',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsable',
              ovobank: 'Responsable',
            },
            description: {
              ovoclinic: 'Patient responsable',
              ovobank: 'Responsable du donateur',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Spécialiste',
              ovobank: 'Spécialiste',
            },
            description: {
              ovoclinic: 'Spécialiste affecté au patient',
              ovobank: 'Spécialiste affecté au donneur',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Recettes',
              ovobank: 'Recettes',
            },
            description: 'Liste des reçus associés au client',
            list: {
              create: 'Reçu',
            },
            many: 'Recettes',
            one: 'Reçu',
            columns: {
              receipt: {
                name: 'Nom',
                clinic: 'Clinique',
                created: 'F. création',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Créer un paiement',
                  },
                  many: 'Paiements',
                  one: 'Payer',
                  columns: {
                    payment: {
                      price: 'Prix',
                      clinic: 'Clinique',
                      name: 'Nom',
                      created: 'F. création',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Embauche',
              ovobank: 'Embauche',
            },
            description: {
              ovoclinic: 'Embauches de patients',
              ovobank: 'Embauche de donateurs',
            },
            one: 'Embauche',
            many: 'Embauche',
            columns: {
              price: 'Prix',
              purchaseType: 'Gars',
              treatmentName: 'Nom',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Observations',
              ovobank: 'Observations',
            },
            description: {
              ovoclinic: 'Observations des patients',
              ovobank: 'Observations des donateurs',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documents',
              ovobank: 'Documents',
            },
            description: {
              ovoclinic: 'Documents téléchargés par le patient',
              ovobank: 'Documents téléchargés par le donateur',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Traitement',
              ovobank: 'Traitement',
            },
            description: {
              ovoclinic: 'Programme pour les patients',
              ovobank: 'Programme des donateurs',
            },
            one: 'Programme',
            many: 'Programmes',
            submit: 'Ajouter un programme',
            columns: {
              programName: 'Programme',
              estimated: 'Date estimée',
              finished: 'Date de fin',
            },
            document: {
              delete: {
                title: 'Supprimer le fichier',
                subtitle:
                  'Êtes-vous sûr de vouloir supprimer le fichier ? Il sera également supprimé du cloud ?',
                submit: 'Supprimer',
              },
              columns: {
                programName: 'Programme',
                estimated: 'Date estimée',
                finished: 'Date de fin',
                documentName: 'Document',
                created: 'Créé',
                size: 'Taille',
                vRepoUploaded: 'Synchronisé avec Vrepo',
                type: 'Gars',
              },
              select: 'Téléchargez un fichier pdf',
              unselected: 'Aucun fichier sélectionné',
              unvalid: 'Veuillez sélectionner un fichier pdf valide.',
              submit: 'Envoyer',
              many: 'Fichiers',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Tâches',
              ovobank: 'Tâches',
            },
            description: {
              ovoclinic: 'Gestion des tâches qui vous sont assignées. ',
              ovobank: 'Gestion des tâches qui vous sont assignées. ',
            },
            add: 'Ajouter une tâche',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'Général',
              },
              notes: {
                title: 'Observations',
              },
              contactNotes: {
                title: 'Observations',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'Nouveau patient',
                ovobank: 'Nouveau donateur',
              },
              active: {
                ovoclinic: 'Patient actif',
                ovobank: 'Donateur actif',
              },
              inactive: {
                ovoclinic: 'Patient inactif',
                ovobank: 'Donateur inactif',
              },
              clinic: 'Clinique',
              contactTime: 'Préférence temporelle',
              responsible: 'Responsable',
              funnel: 'Origine',
              consent: 'Approbation',
              consentCheck: 'Consentement',
              warrantyAgreement: 'Accord de garantie',
              campaign: 'Cloche',
              originCampaign: "Campagne d'origine",
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Patient',
            ovobank: 'Donneur',
          },
          description: {
            ovoclinic: 'Détails du patient',
            ovobank: 'Détails du donateur',
          },
        },
        contact: {
          tab: 'Contact',
          description: {
            ovoclinic: 'Coordonnées des patients',
            ovobank: 'Coordonnées du donateur',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsable',
                ovobank: 'Responsable',
              },
              description: {
                ovoclinic: 'Utilisateur patient responsable',
                ovobank: 'Utilisateur donateur responsable',
              },
            },
            submit: 'Désigner un responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Spécialiste',
              description: {
                ovoclinic: 'Spécialiste affecté au patient',
                ovobank: 'Spécialiste affecté au donneur',
              },
            },
            submit: 'Désigner un spécialiste',
          },
        },
        receipts: {
          create: {
            title: 'Créer un reçu',
            subtitle: {
              ovoclinic: 'Créer un reçu associé au patient',
              ovobank: 'Créer un reçu associé au donateur',
            },
            form: {
              clinicId: {
                title: 'Clinique',
              },
              submit: 'Créer un reçu',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Reçu',
                description: {
                  ovoclinic: 'Mettre à jour le reçu associé au patient',
                  ovobank: 'Mettre à jour le reçu associé au donateur',
                },
              },
              payments: {
                title: 'Paiements',
                description: 'Paiements de la facture associée au client',
                list: {
                  create: 'Ajouter un paiement',
                },
              },
            },
            title: 'Mettre à jour le reçu',
            subtitle: {
              ovoclinic: 'Mettre à jour le reçu associé au patient',
              ovobank: 'Mettre à jour le reçu associé au donateur',
            },
            form: {
              clinicId: {
                title: 'Clinique',
              },
              submit: 'Enregistrer le reçu',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Description',
              ovobank: 'Description',
            },
            description: {
              ovoclinic: 'Une brève description pour ajouter tout commentaire sur le patient.',
              ovobank: 'Une brève description pour ajouter tout commentaire sur le donateur.',
            },
          },
          clinicId: {
            title: 'Clinique',
            description: {
              ovoclinic: 'La clinique dans laquelle le patient souhaite être soigné.',
              ovobank: 'La clinique où le donneur souhaite être traité.',
            },
          },
          leadId: {
            title: 'Plomb',
            description: {
              ovoclinic: 'Plomb associé au patient',
              ovobank: 'Piste associée au donateur',
            },
          },
          contactId: {
            title: 'Contact',
            description: {
              ovoclinic: 'Les coordonnées par lesquelles le patient est arrivé à la clinique.',
              ovobank: 'Les coordonnées par lesquelles le donneur est venu à la clinique.',
            },
          },
          campaignId: {
            title: 'Cloche',
            description: {
              ovoclinic: 'La campagne de marketing à domicile. ',
              ovobank: 'La campagne de marketing à domicile du donateur. ',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Accord de garantie',
              ovobank: 'Accord de garantie',
            },
            description: {
              ovoclinic: 'Le patient accepte le contrat de garantie',
              ovobank: "Le donateur accepte l'accord de garantie",
            },
            label: {
              ovoclinic: "Oui, j'accepte le contrat de garantie",
              ovobank: "Oui, j'accepte le contrat de garantie",
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentement',
              ovobank: 'Consentement',
            },
            description: {
              ovoclinic: 'Le patient donne son consentement à être traité',
              ovobank: 'Le donneur donne son consentement à être traité',
            },
            label: {
              ovoclinic: 'Oui, je donne mon accord',
              ovobank: 'Oui, je donne mon accord',
            },
          },
          submit: {
            ovoclinic: 'Mettre à jour le patient',
            ovobank: 'Mettre à jour le donateur',
          },
        },
        payments: {
          create: {
            title: 'Créer un paiement',
            subtitle: {
              ovoclinic: 'Ajouter le paiement associé au reçu du patient',
              ovobank: 'Ajouter le paiement associé au reçu du donateur',
            },
            form: {
              price: {
                title: 'Prix',
              },
              submit: 'Économisez le prix',
            },
          },
          update: {
            title: 'Mettre à jour le paiement',
            subtitle: {
              ovoclinic: 'Mettre à jour le paiement associé au reçu du patient',
              ovobank: 'Mettre à jour le paiement associé au reçu du donateur',
            },
            form: {
              price: {
                title: 'Prix',
              },
              submit: 'Économisez le prix',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Traitement',
              },
              phase: {
                form: 'Phase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Traitement',
              },
              phase: {
                form: 'Phase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Désactiver le patient',
          ovobank: 'Désactiver le donateur',
        },
        subtitle: {
          ovoclinic: 'Désactiver le patient dans le système',
          ovobank: 'Désactiver le donateur dans le système',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Êtes-vous sûr de vouloir désactiver ce patient ?',
              ovobank: 'Êtes-vous sûr de vouloir désactiver ce donateur ?',
            },
            description: {
              ovoclinic:
                "Désactiver un patient signifie qu'il ne peut pas être utilisé dans le système",
              ovobank:
                "Désactiver un donneur signifie qu'il ne peut pas être utilisé dans le système",
            },
            label: {
              ovoclinic: 'Oui, je veux désactiver ce patient',
              ovobank: 'Oui, je veux désactiver ce donateur',
            },
          },
          submit: 'Désactiver',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Activer le patient',
          ovobank: 'Activer le donateur',
        },
        subtitle: {
          ovoclinic: 'Activer le patient dans le système',
          ovobank: 'Activer le donateur dans le système',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Êtes-vous sûr de vouloir activer ce patient ?',
              ovobank: 'Êtes-vous sûr de vouloir activer ce donateur ?',
            },
            description: {
              ovoclinic: "Activer un patient signifie qu'il peut être utilisé dans le système",
              ovobank: "Activer un donateur signifie qu'il peut être utilisé dans le système",
            },
            label: {
              ovoclinic: 'Oui, je souhaite activer ce patient',
              ovobank: 'Oui, je souhaite activer ce donateur',
            },
          },
          submit: 'Activer',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Supprimer un patient',
          ovobank: 'Supprimer le donateur',
        },
        subtitle: {
          ovoclinic: 'Cette action est irréversible',
          ovobank: 'Cette action est irréversible',
        },
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: {
              ovoclinic: 'Vous préférerez peut-être désactiver ce patient',
              ovobank: 'Vous préférerez peut-être désactiver ce donateur',
            },
            label: {
              ovoclinic: 'Oui, je veux supprimer ce patient',
              ovobank: 'Oui, je souhaite supprimer ce donateur',
            },
          },
          confirm: {
            ovoclinic: 'Cette action éliminera tout ce qui concerne le patient, en êtes-vous sûr ?',
            ovobank: 'Cette action supprimera tout ce qui concerne le donateur, en êtes-vous sûr ?',
          },
          submit: 'Éliminer',
        },
      },
      actions: {
        create: {
          title: 'Nouvelle tâche',
          subtitle: "Enregistrement d'une nouvelle tâche dans le système",
          form: {
            activityId: {
              title: 'Activité',
              description: 'Activité à faire',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Utilisateur responsable de la tâche',
            },
            started: {
              title: 'Date estimée',
              description: "Date à laquelle l'activité devrait avoir lieu",
            },
            subject: {
              title: 'Affaire',
              description: 'Sujet de la tâche',
            },
            observation: {
              title: 'Note',
              description: "Un petit commentaire sur l'activité",
            },
            submit: 'Créer une tâche',
          },
        },
        update: {
          title: 'Tâche de mise à jour',
          subtitle: "Modifier les données d'une tâche dans le système",
          actionEnding: {
            form: {
              submit: 'Terminer la tâche',
              submitNextSuggestion: 'Finaliser et planifier',
            },
          },
          form: {
            result: {
              false: {
                title: 'Résultat',
              },
              result: {
                title: 'Résultat',
                description: "Résultat de l'activité. ",
              },
            },
            communicationChannel: {
              title: 'Voie de communication',
              description: "Voie de communication utilisée pour l'activité",
            },
            activityId: {
              title: 'Activité',
              description: 'Activité à faire',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Utilisateur responsable de la tâche',
            },
            started: {
              title: 'Date estimée',
              description: "Date à laquelle l'activité devrait avoir lieu",
            },
            subject: {
              title: 'Affaire',
              description: 'Sujet de la tâche',
            },
            observation: {
              title: 'Note',
              description: "Un petit commentaire sur l'activité",
            },
            submit: 'Tâche de mise à jour',
          },
          tabs: {
            action: {
              title: 'Tâche',
              description: 'Informations associées à la tâche',
            },
            actionEnding: {
              title: 'Réalisation',
              description: 'Informations associées à la réalisation de la tâche',
            },
            alerts: {
              title: 'Alertes',
              description: 'Alertes associées à la tâche',
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: "Mettre à jour l'embauche",
        subtitle: {
          ovoclinic: "Modification des données d'un contrat patient",
          ovobank: "Modification des données d'un contrat de donateur",
        },
        form: {
          price: {
            title: 'Prix',
            description: 'Prix ​​du contrat',
          },
          submit: "Mettre à jour l'embauche",
        },
      },
      disable: {
        title: "Désactiver l'embauche",
        subtitle: {
          ovoclinic: 'Désactiver le recrutement de patients',
          ovobank: 'Désactiver le recrutement de donneurs',
        },
        form: {
          submit: 'Désactiver',
        },
      },
      enable: {
        title: "Activer l'embauche",
        subtitle: {
          ovoclinic: 'Permettre le recrutement de patients',
          ovobank: 'Activer les contrats avec les donateurs',
        },
        form: {
          submit: 'Activer',
        },
      },
    },
    treatments: {
      create: {
        title: 'Nouveau programme',
        subtitle: "Enregistrement d'un nouveau programme dans le système",
        form: {
          programId: {
            title: 'Programme',
            description: 'Programme à réaliser',
          },
          estimated: {
            title: 'Date estimée',
            description: 'Date à laquelle le programme devrait avoir lieu',
          },
          finished: {
            title: 'Date de fin',
            description: 'Date à laquelle le programme a été programmé',
          },
          phaseId: {
            title: 'Phase',
            description: 'Phase dans laquelle le programme est',
          },
          discount: {
            title: 'Pourcentage de remise',
            description: 'Rabais appliqué au programme',
          },
          submit: 'Créer un programme',
        },
      },
      update: {
        title: 'Programme de mise à jour',
        subtitle: 'Modification des données du programme dans le système',
        form: {
          programId: {
            title: 'Programme',
            description: 'Programme à réaliser',
          },
          phaseId: {
            title: 'Phase',
            description: 'Phase dans laquelle le programme est',
          },
          estimated: {
            title: 'Date estimée',
            description: 'Date à laquelle le programme devrait avoir lieu',
          },
          finished: {
            title: 'Date de fin',
            description: 'Date à laquelle le programme a été programmé',
          },
          discount: {
            title: 'Pourcentage de remise',
            description: 'Rabais appliqué au programme',
          },
          submit: 'Programme de mise à jour',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Programme',
              ovobank: 'Programme',
            },
            description: {
              ovoclinic: 'Informations sur le programme',
              ovobank: 'Informations sur le programme',
            },
          },
          program: {
            title: {
              ovoclinic: 'Traitement',
            },
            description: {
              ovoclinic: 'Informations sur le traitement',
              ovobank: 'Informations sur le traitement',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Techniques',
              ovobank: 'Techniques',
            },
            description: {
              ovoclinic:
                'Ce sont les techniques complémentaires qui ont été créées en relation avec chaque programme. ',
              ovobank:
                'Ce sont les techniques complémentaires qui ont été créées en relation avec chaque programme. ',
            },
            many: 'Techniques',
            one: 'Technique',
            add: 'Ajouter une technique',
          },
          maintenances: {
            title: {
              ovoclinic: 'Entretien',
              ovobank: 'Entretien',
            },
            description: {
              ovoclinic: 'Entretien du traitement. ',
              ovobank: 'Entretien du traitement. ',
            },
            many: 'Entretien',
            one: 'Entretien',
            add: 'Ajouter une maintenance',
          },
          interventions: {
            title: {
              ovoclinic: 'Traitements',
              ovobank: 'Traitements',
            },
            description: {
              ovoclinic: 'Cycles réalisés dans les traitements. ',
              ovobank: 'Cycles réalisés dans les traitements. ',
            },
            many: 'Traitements',
            one: 'Traitement',
            columns: {
              interventionClinicName: 'Clinique',
              observation: 'Observation',
              expectedDate: 'Date estimée',
              interventionDate: "Date d'intervention",
              cycleName: 'Faire du vélo',
            },
            add: 'Ajouter un traitement',
          },
        },
      },
      disable: {
        title: 'Désactiver le traitement',
        subtitle: 'Désactiver le traitement dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce traitement ?',
            description:
              "Désactiver un traitement signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je souhaite désactiver ce traitement',
          },
          submit: 'Désactiver le traitement',
        },
      },
      enable: {
        title: 'Activer le traitement',
        subtitle: 'Activer le traitement dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce traitement ?',
            description: "Activer un traitement signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer ce traitement',
          },
          submit: 'Activer le traitement',
        },
      },
      list: {
        title: {
          ovoclinic: 'Traitements',
          ovobank: 'Traitements',
        },
        description: {
          ovoclinic: 'Gestion des traitements effectués sur les patients',
          ovobank: 'Gestion des traitements effectués sur les donneurs',
        },
        create: 'Traitement',
        one: 'Traitement',
        many: {
          ovoclinic: 'Traitements',
          ovobank: 'Traitements',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Faire du vélo',
            ovobank: 'Faire du vélo',
          },
          expectedDate: {
            ovoclinic: 'Date estimée',
            ovobank: 'Date estimée',
          },
          clinicName: {
            ovoclinic: 'Clinique',
            ovobank: 'Clinique',
          },
          patientName: {
            ovoclinic: 'Patient',
            ovobank: 'Donneur',
          },
          programName: {
            ovoclinic: 'Programme',
            ovobank: 'Programme',
          },
          phaseName: {
            ovoclinic: 'Scène',
            ovobank: 'Scène',
          },
          estimated: {
            ovoclinic: 'Date estimée',
            ovobank: 'Date estimée',
          },
          finished: {
            ovoclinic: 'Date de fin',
            ovobank: 'Date de fin',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: "Activer l'intervention",
        subtitle: "Activer l'intervention du système",
        form: {
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'intervention",
        subtitle: "Désactiver l'intervention dans le système",
        form: {
          submit: 'Désactiver',
        },
      },
      create: {
        title: 'Nouveau traitement',
        subtitle: "Enregistrement d'un nouveau traitement",
        form: {
          interventionClinicId: {
            title: 'Clinique',
            description: 'Clinique dans laquelle le traitement a été effectué',
          },
          interventionDate: {
            title: "Date d'intervention",
            description: 'Date à laquelle le cycle a été réalisé',
          },
          observation: {
            title: 'Note',
            description: 'Observation sur le traitement',
          },
          cycleId: {
            title: 'Faire du vélo',
            description: 'Type de cycle à réaliser',
          },
          expectedDate: {
            title: 'Date estimée',
            description: 'Date estimée du cycle',
          },
          submit: 'Créer un traitement',
        },
      },
      update: {
        title: 'Mettre à jour le traitement',
        subtitle: 'Modification des données de traitement dans le système',
        form: {
          interventionClinicId: {
            title: 'Clinique',
            description: 'Clinique dans laquelle le traitement a été effectué',
          },
          interventionDate: {
            title: "Date d'intervention",
            description: 'Date à laquelle le cycle a été réalisé',
          },
          observation: {
            title: 'Note',
            description: 'Observation sur le traitement',
          },
          cycleId: {
            title: 'Faire du vélo',
            description: 'Type de cycle à réaliser',
          },
          expectedDate: {
            title: 'Date estimée',
            description: 'Date estimée du cycle',
          },
          submit: 'Mettre à jour le traitement',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Traitement',
              ovobank: 'Traitement',
            },
            description: {
              ovoclinic: 'Informations sur le traitement',
              ovobank: 'Informations sur le traitement',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Techniques',
              ovobank: 'Techniques',
            },
            description: {
              ovoclinic: "Techniques d'intervention. ",
              ovobank: "Techniques d'intervention. ",
            },
          },
        },
        techniques: {
          delete: {
            title: 'Technique claire',
            subtitle: "Supprimer la technique d'intervention",
            form: {
              submit: 'Technique claire',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'Nouvelle procédure',
        subtitle: "Enregistrement d'une nouvelle procédure d'intervention dans le système",
        form: {
          techniqueId: {
            title: 'Technique',
            description: 'Technique associée à la procédure',
          },
          name: {
            title: 'Nom',
            description: "Nom d'identification de la procédure",
          },
          description: {
            title: 'Description',
            description: 'Description détaillée de la procédure',
          },
          submit: 'Créer une procédure',
        },
      },
      update: {
        title: 'Procédure de mise à jour',
        subtitle: "Modifier les données d'une procédure dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la procédure",
          },
          description: {
            title: 'Description',
            description: 'Description détaillée de la procédure',
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de la procédure',
          },
          submit: 'Procédure de mise à jour',
        },
      },
      delete: {
        title: 'Supprimer la procédure',
        subtitle: 'Supprimer la procédure dans le système',
        form: {
          submit: 'Supprimer la procédure',
        },
      },
    },
    maintenances: {
      create: {
        title: 'Nouvel entretien',
        subtitle: "Enregistrement d'un nouveau maintien pour traitement dans le système",
        form: {
          serviceId: {
            title: 'Service',
            description: 'Service associé à la maintenance',
          },
          submit: 'Créer une maintenance',
        },
      },
      update: {
        title: 'Maintenance des mises à jour',
        subtitle: 'Modification des données de maintenance dans le système',
        form: {
          serviceId: {
            title: 'Service',
            description: 'Service associé à la maintenance',
          },
          amount: {
            title: 'Prix',
            description: "Prix ​​d'entretien",
          },
          paymentStatus: {
            title: 'Statut de paiement',
            description: 'Statut de paiement de la pension alimentaire',
          },
          estimatedPaymentDate: {
            title: 'Date de paiement estimée',
            description: 'Date estimée de paiement de la pension alimentaire',
          },
          submit: 'Maintenance des mises à jour',
        },
        tabs: {
          maintenance: {
            title: 'Entretien',
            description: "Informations d'entretien",
          },
          action: {
            title: 'Tâche',
            description: 'Informations associées à la tâche',
          },
        },
      },
      delete: {
        title: 'Supprimer la maintenance',
        subtitle: 'Supprimer la maintenance sur le système',
        form: {
          submit: 'Supprimer la maintenance',
        },
      },
      list: {
        title: {
          ovoclinic: 'Entretien',
          ovobank: 'Entretien',
        },
        description: {
          ovoclinic: 'Gestion des maintenances effectuées sur les patients',
          ovobank: 'Gestion de la maintenance effectuée sur les donneurs',
        },
        create: 'Entretien',
        one: {
          ovoclinic: 'Entretien',
          ovobank: 'Entretien',
        },
        many: {
          ovoclinic: 'Entretien',
          ovobank: 'Entretien',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clinique',
            ovobank: 'Clinique',
          },
          patientName: {
            ovoclinic: 'Patient',
            ovobank: 'Donneur',
          },
          serviceName: {
            ovoclinic: 'Service',
            ovobank: 'Service',
          },
          amount: {
            ovoclinic: 'Prix',
            ovobank: 'Prix',
          },
          paymentStatus: {
            ovoclinic: 'Statut de paiement',
            ovobank: 'Statut de paiement',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Date de paiement estimée',
            ovobank: 'Date de paiement estimée',
          },
          programName: {
            ovoclinic: 'Programme',
            ovobank: 'Programme',
          },
          estimated: {
            ovoclinic: 'Date estimée',
            ovobank: 'Date estimée',
          },
          finished: {
            ovoclinic: 'Date de fin',
            ovobank: 'Date de fin',
          },
        },
      },
      enable: {
        title: 'Activer la maintenance',
        subtitle: 'Activer la maintenance sur le système',
        form: {
          submit: 'Activer la maintenance',
        },
      },
      disable: {
        title: 'Désactiver la maintenance',
        subtitle: 'Désactiver la maintenance sur le système',
        form: {
          submit: 'Désactiver la maintenance',
        },
      },
    },
    actions: {
      enable: {
        title: "Activer l'action",
        subtitle: "Activer l'action dans le système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette action ?',
            description: "Activer une action signifie qu'elle peut être utilisée dans le système",
            label: 'Oui, je souhaite activer cette action',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'action",
        subtitle: "Désactiver l'action dans le système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette action ?',
            description:
              "Désactiver une action signifie qu'elle ne peut pas être utilisée dans le système",
            label: 'Oui, je souhaite désactiver cette action',
          },
          submit: 'Désactiver',
        },
      },
      list: {
        title: 'Calendrier',
        description: 'Calendrier des tâches liées aux leads',
      },
      delete: {
        title: 'Supprimer la tâche',
        subtitle: 'Cette action est irréversible',
        form: {
          submit: 'Éliminer',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Alertes',
            subtitle: 'Ajouter des alertes à la tâche',
            form: {
              alertTime: {
                title: "Heure d'alerte",
                description: "Entrez le nombre de minutes à l'avance pour recevoir l'avis de tâche",
              },
              submit: 'Ajouter une alerte',
            },
          },
          delete: {
            title: "Supprimer l'alerte",
            subtitle: "Supprimer l'alerte de tâche",
            form: {
              submit: "Supprimer l'alerte",
            },
          },
          update: {
            title: 'Alerte de mise à jour',
            subtitle: "Mettre à jour l'alerte de tâche",
            form: {
              alertTime: {
                title: "Heure d'alerte",
                description: "Entrez le nombre de minutes à l'avance pour recevoir l'avis de tâche",
              },
              submit: 'Alerte de mise à jour',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Nom',
          phones: 'Téléphone',
          contactTime: 'Frange',
          consents: 'Consentements',
          marketing: 'Commercialisation',
          legal: 'Légal',
          created: 'Date de création',
        },
        title: {
          ovoclinic: 'Contacts',
          ovobank: 'Contacts',
        },
        description: {
          ovoclinic: "Gestion des contacts existants dans l'application. ",
          ovobank: "Gestion des contacts existants dans l'application. ",
        },
        create: {
          ovoclinic: 'Contact',
          ovobank: 'Contact',
        },
        one: 'Contact',
        many: 'Contacts',
      },
      create: {
        title: {
          ovoclinic: 'Nouveau contact',
          ovobank: 'Nouveau contact',
        },
        subtitle: {
          ovoclinic: 'Personne, puis associez-la à un prospect ou à un patient',
          ovobank: 'Personne puis associez-la à un prospect ou un donateur',
        },
        form: {
          name: {
            title: 'Nom',
          },
          surname: {
            title: 'Noms de famille',
          },
          prefix: {
            title: 'Préfixe',
            description: 'Préfixe de contact',
          },
          phone: {
            title: 'Téléphone',
            description: 'Téléphone de contact',
          },
          email: {
            title: 'E-mail',
            description: 'E-mail de contact',
          },
          zip: {
            title: 'Code postal',
            description: 'Contacter le code postal',
          },
          marketing: {
            title: 'Commercialisation',
            label: 'Commercialisation',
            description: 'Le contact accepte de recevoir des informations marketing',
          },
          birthDate: {
            title: 'Date de naissance',
            description: 'Date de naissance du contact',
          },
          birthCountry: {
            title: 'Pays de naissance',
            description: 'Pays de naissance du contact',
          },
          weight: {
            title: 'Poids (kg)',
            description: 'Poids du contact en kilogrammes',
          },
          height: {
            title: 'Hauteur (cm)',
            description: 'Hauteur de contact en centimètres',
          },
          donationPreference: {
            title: 'Préférence de ville',
            description: 'Préférence de ville où faire le don',
          },
          legal: {
            title: 'Conditions juridiques',
            label: 'Acceptez les conditions légales',
            description: 'Le contact accepte les conditions légales',
          },
          contactTime: {
            title: 'Plage horaire de contact',
            description: 'Heure à laquelle le contact souhaite être contacté',
          },
          clinicId: {
            title: 'Clinique',
            description: 'OvoCRM vous permet de créer des contacts pour plusieurs cliniques. ',
          },
          campaignId: {
            title: 'Cloche',
            description: 'La campagne marketing d’origine des contacts. ',
          },
          center: {
            title: 'Emplacement',
            description: 'Lieu de contact',
          },
          photo: {
            title: 'Photo',
            description: 'Photo de contact',
          },
          submit: 'Créer un contact',
        },
      },
      update: {
        title: {
          ovoclinic: 'Mettre à jour le contact',
          ovobank: 'Mettre à jour le contact',
        },
        subtitle: {
          ovoclinic: 'Informations personnelles du responsable ou du patient',
          ovobank: 'Informations personnelles du prospect ou du donateur',
        },
        tabs: {
          account: {
            title: 'Données personnelles',
            description: 'Coordonnées détaillées',
          },
          actions: {
            title: 'Activités',
            description: 'Liste des activités créées sur le contact',
          },
          leads: {
            title: 'Campagnes',
            description: 'Campagnes dans lesquelles ce contact a participé. ',
          },
          contact: {
            title: 'Contact',
            description: 'Coordonnées détaillées',
          },
          receipts: {
            title: 'Recettes',
            description: {
              ovoclinic: 'Reçus créés sur le contact. ',
              ovobank: 'Reçus créés sur le contact. ',
            },
          },
          notes: {
            title: 'Observations',
            description: 'Informations ou commentaires sur le contact. ',
          },
          purchases: {
            title: 'Embauche',
            description: 'Embauche effectuée par le contact. ',
          },
        },
        form: {
          name: {
            title: 'Nom',
          },
          surname: {
            title: 'Noms de famille',
          },
          dni: {
            title: 'IDENTIFIANT',
          },
          marketing: {
            title: 'Commercialisation',
            label: 'Commercialisation',
            description: 'Le contact accepte de recevoir des informations marketing',
          },
          legal: {
            title: 'Conditions juridiques',
            label: 'Acceptez les conditions légales',
            description: 'Le contact accepte les conditions légales',
          },
          contactTime: {
            title: 'Plage horaire de contact',
            description: 'Heure à laquelle le contact souhaite être contacté',
          },
          center: {
            title: 'Emplacement',
            description: 'Lieu de contact',
          },
          prefix: {
            title: 'Préfixe',
            description: 'Préfixe de contact',
          },
          phone: {
            title: 'Téléphone',
            description: 'Téléphone de contact',
          },
          email: {
            title: 'E-mail',
            description: 'E-mail de contact',
          },
          birthDate: {
            title: 'Date de naissance',
            description: 'Date de naissance du contact',
          },
          birthCountry: {
            title: 'Pays de naissance',
            description: 'Pays de naissance du contact',
          },
          weight: {
            title: 'Poids (kg)',
            description: 'Poids du contact en kilogrammes',
          },
          height: {
            title: 'Hauteur (cm)',
            description: 'Hauteur de contact en centimètres',
          },
          donationPreference: {
            title: 'Préférence de ville',
            description: 'Préférence de ville où faire le don',
          },
          photo: {
            title: 'Photo',
            description: 'Photo de contact',
          },
          submit: 'Mettre à jour le contact',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Gars',
              treatmentName: 'Traitement',
              price: 'Prix',
            },
            one: 'Embauche',
            many: 'Embauche',
          },
        },
      },
      delete: {
        title: 'Supprimer le contact',
        subtitle: 'Cette action est irréversible',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver ce contact',
            label: 'Oui, je souhaite supprimer ce contact',
          },
          confirm: 'Cette action supprimera tout ce qui concerne le contact, en êtes-vous sûr ?',
          submit: 'Éliminer',
        },
      },
      enable: {
        title: 'Activer le contact',
        subtitle: 'Activer le contact dans le système',
        form: {
          status: {
            title: 'Etes-vous sûr de vouloir activer ce contact ?',
            description: "Activer un contact signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer ce contact',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver les contacts',
        subtitle: 'Désactiver le contact dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce contact ?',
            description:
              "Désactiver un contact signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je souhaite désactiver ce contact',
          },
          submit: 'Désactiver',
        },
      },
      notes: {
        source: {
          LEAD: 'Plomb',
          PATIENT: {
            ovoclinic: 'Patient',
            ovobank: 'Donneur',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campagnes',
        description: 'Gestion des campagnes marketing. ',
        userId: {
          title: 'En charge',
          description: 'Gestionnaire de campagne',
        },
        submit: 'Attribuer un gestionnaire',
        create: 'Cloche',
        one: 'Cloche',
        many: 'Campagnes',
        columns: {
          name: 'Nom',
          leads: 'Conduit',
          leadsStatus: 'Actif/Inactif',
          funnelName: 'Entonnoir',
          languageName: 'Langue',
          clinics: 'Cliniques',
          managers: 'Gestionnaires',
        },
      },
      create: {
        title: 'Nouvelle campagne',
        subtitle: "Enregistrement d'une nouvelle campagne dans le système",
        form: {
          image: {
            title: 'Couverture',
            description:
              "Nous vous encourageons à télécharger une image pour améliorer l'expérience commerciale",
          },
          name: {
            title: 'Qualification',
            description: 'Titre de la campagne marketing',
          },
          facebookId: {
            title: 'Identifiant Facebook',
            description: 'Identifiant Facebook de la campagne marketing',
          },
          code: {
            title: 'Code',
            description: 'Code de campagne marketing',
          },
          budget: {
            title: 'Budget',
            description: 'Budget investi par la clinique dans la campagne',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique qui finance la campagne',
          },
          firstActivityId: {
            title: 'Première activité',
            description: "Première activité attribuée lors de la création d'un prospect",
          },
          funnelId: {
            title: 'Entonnoir',
            description: 'Entonnoir de conversion de campagne marketing',
          },
          status: {
            title: 'Campagne active',
            description: 'Activer ou désactiver la campagne dans le système',
            label: 'Campagne active',
          },
          languageId: {
            title: 'Langue',
            description:
              'Sélectionnez une langue pour que tous les prospects de cette campagne soient créés dans cette langue',
          },
          startDate: {
            title: 'Date de début',
            description: 'Date de début de la campagne',
          },
          submit: 'Créer une campagne',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Canal',
                description: 'Canal de campagne marketing',
              },
            },
          },
        },
      },
      enable: {
        title: 'Activer la campagne',
        subtitle: 'Activer la campagne dans le système',
        form: {
          status: {
            title: "Êtes-vous sûr de vouloir l'activer ?",
            description: 'De cette façon, il sera disponible dans le système',
            label: 'Oui, je souhaite activer cette campagne',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver la campagne',
        subtitle: 'Désactiver la campagne dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le désactiver ?',
            description: 'De cette façon, il ne sera pas disponible dans le système',
            label: 'Oui, je souhaite désactiver cette campagne',
          },
          submit: 'Désactiver',
        },
      },
      update: {
        title: 'Campagne de mise à jour',
        subtitle: 'Les changements affecteront toutes les intégrations',
        tabs: {
          general: {
            title: 'Général',
            description: 'Détails de la campagne',
          },
          contact: {
            title: 'Contact',
            description: 'Grâce à ce formulaire, vous pouvez ajouter un contact à la campagne. ',
          },
          managers: {
            title: 'Gestionnaires',
            description: 'Gestionnaires de campagnes',
          },
          financing: {
            title: 'Financement',
            description: 'Détails du financement de la campagne',
          },
          webhook: {
            title: 'webhook',
            description:
              "Un webhook est un moyen d'intégrer votre application à d'autres applications. ",
            toast: 'Lien copié dans le presse-papier',
          },
          participants: {
            title: "Cliniques d'accueil",
            description: 'Ce sont ces cliniques auxquelles s’adresse la campagne marketing. ',
          },
        },
        participants: {
          list: {
            one: "Clinique d'accueil",
            many: "Cliniques d'accueil",
            columns: {
              clinicName: 'Clinique',
            },
          },
          clinicsAdded: 'Cliniques ajoutées',
          create: {
            title: "Ajouter une clinique d'accueil",
            subtitle: "Ajouter une clinique d'accueil à la campagne",
            form: {
              clinicId: {
                title: 'Clinique',
                description: 'Ajouter une clinique de réception à la campagne',
              },
              submit: "Ajouter une clinique d'accueil",
            },
          },
          delete: {
            title: "Supprimer la clinique d'accueil",
            subtitle: 'Cette action est irréversible',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                description: 'Vous préférerez peut-être désactiver ce participant',
                label: 'Oui, je souhaite supprimer cette clinique réceptrice',
              },
              submit: 'Éliminer',
            },
          },
          add: 'Ajouter',
          addAll: 'Ajouter tout',
          clinicIdCopied: 'ID de clinique copié dans le presse-papiers',
        },
        financing: {
          form: {
            budget: {
              title: 'Budget',
              description: 'Budget investi par la clinique dans la campagne',
            },
            financingClinicId: {
              title: 'Clinique de financement',
              description: 'Clinique qui a financé la campagne',
            },
            submit: 'Mettre à jour le financement',
          },
        },
        managers: {
          list: {
            one: 'en charge',
            many: 'gestionnaires',
            columns: {
              name: 'Nom',
            },
          },
          add: 'Ajouter un gestionnaire',
        },
        manager: {
          title: 'Attribuer un gestionnaire',
          subtitle: 'Attribuer un responsable à la campagne',
        },
        contact: {
          name: {
            title: 'Nom',
          },
          surname: {
            title: 'Noms de famille',
          },
          prefix: {
            title: 'Préfixe',
          },
          phone: {
            title: 'Téléphone',
          },
          email: {
            title: 'E-mail',
          },
          zip: {
            title: 'Code postal',
          },
          legal: {
            title: 'Conditions juridiques',
            label: 'Acceptez les conditions légales',
            description: 'Le contact accepte les conditions légales',
          },
          marketing: {
            title: 'Commercialisation',
            label: 'Commercialisation',
            description: 'Le contact accepte de recevoir des informations marketing',
          },
          contactTime: {
            title: 'Plage horaire de contact',
            description: 'Heure à laquelle le contact souhaite être contacté',
          },
          clinicId: {
            title: 'Clinique',
            description: 'La clinique où le contact souhaite être traité',
          },
          center: {
            title: 'Emplacement',
            description: 'Lieu de contact',
          },
          birthDate: {
            title: 'Date de naissance',
            description: 'Date de naissance du contact',
          },
          birthCountry: {
            title: 'Pays de naissance',
            description: 'Pays de naissance du contact',
          },
          weight: {
            title: 'Poids (kg)',
            description: 'Poids du contact en kilogrammes',
          },
          height: {
            title: 'Hauteur (cm)',
            description: 'Hauteur de contact en centimètres',
          },
          donationPreference: {
            title: 'Préférence de ville',
            description: 'Préférence de ville où faire le don',
          },
          photo: {
            title: 'Photo',
            description: 'Photo de contact',
          },
          submit: 'Ajouter un contact',
        },
        form: {
          name: {
            title: 'Qualification',
            description: 'Titre de la campagne marketing',
          },
          facebookId: {
            title: 'Identifiant Facebook',
            description: 'Identifiant Facebook de la campagne marketing',
          },
          code: {
            title: 'Code',
            description: "Code d'identification de la campagne marketing",
          },
          budget: {
            title: 'Budget',
            description: 'Budget investi par la clinique dans la campagne',
          },
          image: {
            title: 'Couverture',
            description:
              "Nous vous encourageons à télécharger une image pour améliorer l'expérience commerciale",
          },
          funnelId: {
            title: 'Entonnoir',
            description: 'Entonnoir de conversion de campagne marketing',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique qui finance la campagne',
          },
          startDate: {
            title: 'Date de début',
            description: 'Date de début de la campagne',
          },
          endDate: {
            title: 'Date de fin',
            description: 'Date de fin de campagne',
          },
          firstActivityId: {
            title: 'Première activité',
            description: "Première activité attribuée lors de la création d'un prospect",
          },
          status: {
            title: 'Campagne active',
            description: 'Activer ou désactiver la campagne dans le système',
            label: 'Campagne active',
          },
          languageId: {
            title: 'Langue',
            description:
              'Sélectionnez une langue pour que tous les prospects de cette campagne soient créés dans cette langue',
          },
          submit: 'Campagne de mise à jour',
        },
      },
      delete: {
        title: 'Supprimer la campagne',
        subtitle: 'Cette action est irréversible',
        manager: {
          title: 'Supprimer le gestionnaire',
          subtitle: 'Cette action est irréversible',
          form: {
            submit: 'Éliminer',
          },
        },
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le supprimer ?',
            description: 'Vous préférerez peut-être désactiver cette campagne',
            label: 'Oui, je souhaite supprimer cette campagne',
          },
          success: 'Campagne supprimée avec succès',
          error: 'Erreur lors de la suppression de la campagne',
          confirm: 'Cette action supprimera tout ce qui concerne la campagne, en êtes-vous sûr ?',
          submit: 'Éliminer',
        },
      },
    },
    activities: {
      list: {
        title: 'Activités',
        description:
          "Les types d'activités permettent aux vendeurs de suivre les tâches effectuées avec les prospects",
        create: 'Activité',
        one: 'activité',
        many: 'activités',
        columns: {
          name: 'Nom',
          description: 'Description',
          position: 'Position',
          icon: 'Icône',
          color: 'Couleur',
          time: 'Temps',
        },
      },
      update: {
        subtitle: "Modifier l'activité dans le système",
        title: "Modifier l'activité",
        form: {
          submit: 'Garder',
          name: {
            title: 'Qualification',
            description: "Titre d'identification de l'activité",
          },
          icon: {
            title: 'Icône',
            description: "Icône pour identifier l'activité. ",
            label: "Sélectionnez l'icône...",
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          position: {
            title: 'Position',
            description:
              "Position dans laquelle l'activité est affichée dans la liste des activités.",
          },
          description: {
            title: 'Description',
            description: "Un bref résumé pour donner plus de détails sur l'activité.",
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification de l'activité. ",
          },
          time: {
            title: 'Temps',
            description: 'Temps estimé nécessaire pour réaliser l’activité.',
          },
          primary: {
            title: 'Activité principale',
            description:
              'Les principales activités sont celles qui sont affichées sur le tableau de bord du vendeur pour permettre une interaction rapide avec les contacts.',
            label: 'Afficher sur le tableau de bord',
          },
        },
        tabs: {
          activity: {
            title: 'Activité',
            description: "Informations associées à l'activité",
          },
          results: {
            title: 'Résultats',
            description:
              "Les résultats décrivent ce qui s'est passé après la réalisation de l'activité. ",
          },
          scopes: {
            title: 'Portées',
            description:
              'Les périmètres décrivent quels départements peuvent effectuer quelle activité.',
          },
          communicationChannels: {
            title: 'Voies de communication',
            description: "Canaux de communication associés à l'activité",
          },
          integrations: {
            title: 'Intégrations',
            description:
              'Intégrations externes qui seront faites lors de la création de ces tâches',
          },
        },
        results: {
          one: 'résultat',
          many: 'résultats',
          add: 'Ajouter le résultat',
        },
        scopes: {
          one: 'Portée',
          many: 'Portées',
          add: 'Ajouter une portée',
        },
        communicationChannels: {
          add: 'Ajouter un chemin de communication',
          list: {
            one: 'Voie de communication',
            many: 'Voies de communication',
            columns: {
              channelName: 'Nom',
            },
          },
          create: {
            title: 'Ajouter un chemin de communication',
            subtitle: "Ajouter une voie de communication à l'activité",
            form: {
              title: 'Voie de communication',
              subtitle: "Voie de communication associée à l'activité",
              submit: 'Ajouter',
              id: {
                title: 'Voie de communication',
                description: "Voie de communication associée à l'activité",
              },
            },
          },
          delete: {
            title: 'Supprimer le chemin de communication',
            subtitle: "Supprimer le canal de communication de l'activité",
            form: {
              submit: 'Éliminer',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'Bot terrestre',
            description: 'Intégration avec LandBot. ',
            label: "Activer l'intégration de LandBot",
          },
          landbotTemplateId: {
            title: 'Modèle LandBot',
            description: 'Intégration avec LandBot. ',
          },
          landbotActivityId: {
            title: 'Activité du LandBot',
            description: 'Activité qui sera générée par le LandBot',
          },
          landbotResultId: {
            title: 'Résultat du LandBot',
            description: "Résultat que LandBot entrera si l'activité est terminée",
          },
          submit: 'Garder',
        },
      },
      enable: {
        title: "Activer l'activité",
        subtitle: "Activer l'activité du système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette activité ?',
            description: "Activer une activité signifie qu'elle peut être utilisée dans le système",
            label: 'Oui, je souhaite activer cette activité',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'activité",
        subtitle: "Désactiver l'activité du système",
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette activité ?',
            description:
              "Désactiver une activité signifie qu'elle ne peut pas être utilisée dans le système",
            label: 'Oui, je souhaite désactiver cette activité',
          },
          submit: 'Désactiver',
        },
      },
      create: {
        title: 'Créer une activité',
        subtitle: 'Créer une activité dans le système',
        form: {
          submit: 'Créer',
          name: {
            title: 'Qualification',
            description: "Titre d'identification de l'activité",
          },
          icon: {
            title: 'Icône',
            description: "Icône pour identifier l'activité. ",
            label: "Sélectionnez l'icône...",
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          position: {
            title: 'Position',
            description:
              "Position dans laquelle l'activité est affichée dans la liste des activités.",
          },
          description: {
            title: 'Description',
            description: "Un bref résumé pour donner plus de détails sur l'activité.",
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification de l'activité. ",
          },
          time: {
            title: 'Temps',
            description: 'Temps estimé nécessaire pour réaliser l’activité.',
          },
          primary: {
            title: 'Activité principale',
            description:
              'Les principales activités sont celles qui sont affichées sur le tableau de bord du vendeur pour permettre une interaction rapide avec les contacts.',
            label: 'Afficher sur le tableau de bord',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Portées',
        description: "Gestion des domaines d'activités dans le système",
        create: 'Portée',
        one: 'Portée',
        many: 'Portées',
        columns: {
          clinicName: 'Clinique',
          departmentName: 'Département',
        },
      },
      create: {
        title: 'Créer une portée',
        subtitle: 'Créer une portée dans le système',
        form: {
          submit: 'Créer',
          clinicId: {
            title: 'Clinique',
            description: 'Clinique à laquelle appartient la zone',
          },
          departmentId: {
            title: 'Département',
            description: 'Département auquel appartient le périmètre',
          },
          departmentIds: {
            title: 'Départements',
            description: "Départements auxquels le modèle s'appliquera",
          },
        },
      },
      delete: {
        title: 'Supprimer la portée',
        subtitle: 'Cette action est irréversible',
        form: {
          submit: 'Éliminer',
        },
      },
    },
    results: {
      list: {
        title: 'Résultats',
        description: "Gestion des résultats d'activité dans le système. ",
        create: 'Ajouter un résultat',
        one: 'résultat',
        many: 'résultats',
        columns: {
          name: 'Nom',
          description: 'Description',
          icon: 'Icône',
          color: 'Couleur',
          time: 'Temps estimé',
          position: 'Position',
          activityName: 'Activité',
        },
      },
      update: {
        subtitle: "Modifier le résultat de l'activité dans le système",
        title: 'Modifier le résultat',
        form: {
          submit: 'Mise à jour',
          name: {
            title: 'Nom',
            description: "Nom d'identification du résultat",
          },
          icon: {
            title: 'Icône',
            label: 'Icône',
            description: 'Icône pour identifier le résultat. ',
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          position: {
            title: 'Position',
            description:
              'Position dans laquelle le résultat est affiché dans la liste des résultats.',
          },
          description: {
            title: 'Description',
            description: 'Un bref résumé pour donner plus de détails sur le résultat.',
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification du résultat. ",
          },
          time: {
            title: 'Temps',
            description: 'Temps estimé nécessaire pour produire le résultat.',
          },
          activityId: {
            title: 'Activité',
            description: 'Activité à laquelle appartient le résultat.',
          },
          nextActivityId: {
            title: 'Activité suivante',
            description: 'Activité qui sera réalisée après avoir obtenu ce résultat.',
          },
          nextActivityDelay: {
            title: "Temps d'attente",
            description: "Temps d'attente pour effectuer l'activité suivante. ",
          },
          disableLead: {
            title: 'Désactiver le prospect',
            description: 'Désactivez le lead après avoir effectué ce résultat',
            label: 'Oui, désactivez le prospect',
          },
          contacted: {
            title: 'Responsable contacté',
            description: 'Le contact a été établi avec le responsable',
            label: 'Oui, le responsable a été contacté',
          },
          informed: {
            title: 'Responsable informé',
            description: 'Le prospect a reçu des informations sur le service',
            label: 'Oui, le responsable a été informé',
          },
          converted: {
            title: {
              ovoclinic: 'Un plomb transformé en patient',
              ovobank: 'Lead converti en donateur',
            },
            description: {
              ovoclinic: 'Le prospect a été transformé en patient. ',
              ovobank: 'Le prospect a été converti en donateur. ',
            },
            label: {
              ovoclinic: 'Oui, le prospect a été converti',
              ovobank: 'Oui, le prospect a été converti',
            },
          },
          appointed: {
            title: 'Lead cité pour une visite d’information commerciale',
            description: 'Un rendez-vous a été fixé avec le responsable',
            label: 'Oui, la piste a été citée',
          },
          attended: {
            title: "Visite d'information médicale effectuée",
            description:
              "Grâce à ces informations, il sera possible de compter le moment où le responsable s'est présenté au rendez-vous médical.",
            label: 'Oui, le responsable a eu un rendez-vous médical',
          },
          comercialAttended: {
            title: 'Budget respecté',
            description:
              'Avec ces informations, il sera possible de compter le moment où un budget est remis au responsable.',
            label: 'Oui, le prospect a reçu un devis',
          },
          medicalAppointed: {
            title: "Responsable prévu pour une visite d'information médicale",
            description: 'Un rendez-vous médical a été fixé avec le responsable',
            label: 'Oui, la piste a été citée',
          },
          medicalEvaluated: {
            title: 'Évaluation médicale',
            description: 'Le plomb a été évalué par un médecin',
            label: 'Oui, le prospect a été évalué',
          },
          templateId: {
            title: 'Nom',
            description: 'Nom du modèle',
          },
          landbotId: {
            title: 'Bot terrestre',
            description: "L'ID du bot à envoyer au contact avec le modèle",
          },
          landbotAppointed: {
            title: 'Rendez-vous commercial organisé par LandBot',
            description:
              'Si une activité est réalisée avec ce résultat cela signifie que le bot a pris rendez-vous',
            label: 'Oui, le bot a pris rendez-vous',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead cité pour une visite d’information commerciale',
            description: 'Un rendez-vous a été fixé avec le responsable',
            label: 'Oui, la piste a été citée',
          },
          comercialAttended: {
            title: "Visite d'information commerciale réalisée",
            description:
              "Grâce à ces informations, il sera possible de compter le moment où le lead s'est présenté au rendez-vous commercial.",
            label: 'Oui, le responsable a eu un rendez-vous commercial',
          },
          medicalAppointed: {
            title: "Responsable prévu pour une visite d'information médicale",
            description: 'Un rendez-vous médical a été fixé avec le responsable',
            label: 'Oui, la piste a été citée',
          },
          attended: {
            title: "Visite d'information médicale effectuée",
            description:
              "Grâce à ces informations, il sera possible de compter le moment où le responsable s'est présenté au rendez-vous médical.",
            label: 'Oui, le responsable a eu un rendez-vous médical',
          },
          converted: {
            title: 'Ajustement médical',
            description: 'Le prospect a été jugé apte à faire un don',
            label: 'Oui, la piste a été considérée comme appropriée',
          },
          submit: 'Mise à jour',
        },
        tabs: {
          result: {
            title: 'Résultat',
            description: "Gestion des résultats d'activité dans le système. ",
          },
          template: {
            title: 'Modèle',
            description: 'Modèle à utiliser pour Landbot.',
          },
          nextActivity: {
            title: 'Activité suivante',
            description:
              "L'activité suivante est l'activité qui sera réalisée après l'obtention de ce résultat.",
          },
          ccAchievement: {
            title: 'Jalons du centre d’appels',
            description: 'Étapes à respecter pour que le résultat soit considéré comme « atteint »',
          },
          atpcAchievement: {
            title: 'Jalons commerciaux de l’ATP',
            description: 'Étapes à respecter pour que le résultat soit considéré comme « atteint »',
          },
          atpiAchievement: {
            title: 'Jalons internationaux de l’ATP',
            description: 'Étapes à respecter pour que le résultat soit considéré comme « atteint »',
          },
          ovoBankAtpAchievement: {
            title: 'Jalons des donateurs',
            description: 'Étapes à respecter pour que le résultat soit considéré comme « atteint »',
          },
          integrationAchievement: {
            title: "Étapes d'intégration",
            description: 'Les jalons qui pourraient être franchis par une certaine intégration. ',
          },
          reactivity: {
            title: 'Réactivité',
            description: 'Réactivité du résultat. ',
          },
        },
      },
      enable: {
        title: 'Activer le résultat',
        subtitle: 'Activer le résultat dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce résultat ?',
            description: "Activer un résultat signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je veux activer ce résultat',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver le résultat',
        subtitle: 'Désactiver le résultat dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce résultat ?',
            description:
              "Désactiver un résultat signifie qu'il ne peut pas être utilisé dans le système",
            label: 'Oui, je veux désactiver ce résultat',
          },
          submit: 'Désactiver',
        },
      },
      create: {
        title: 'Créer un résultat',
        subtitle: "Créer le résultat de l'activité dans le système",
        form: {
          submit: 'Créer',
          name: {
            title: 'Nom',
            description: "Nom d'identification du résultat",
          },
          icon: {
            title: 'Icône',
            description: 'Icône pour identifier le résultat. ',
            label: 'Icône',
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          position: {
            title: 'Position',
            description:
              'Position dans laquelle le résultat est affiché dans la liste des résultats.',
          },
          description: {
            title: 'Description',
            description: 'Un bref résumé pour donner plus de détails sur le résultat.',
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification du résultat. ",
          },
          time: {
            title: 'Temps',
            description: 'Temps estimé nécessaire pour produire le résultat.',
          },
          activityId: {
            title: 'Activité',
            description: 'Activité à laquelle appartient le résultat.',
          },
          nextActivityId: {
            title: 'Activité suivante',
            description: 'Activité qui sera réalisée après avoir obtenu ce résultat.',
          },
          nextActivityDelay: {
            title: "Temps d'attente",
            description: "Temps d'attente pour effectuer l'activité suivante. ",
          },
          contacted: {
            title: 'Responsable contacté',
            description: 'Le contact a été établi avec le responsable',
            label: 'Oui, le responsable a été contacté',
          },
          informed: {
            title: 'Responsable informé',
            description: 'Le prospect a reçu des informations sur le service',
            label: 'Oui, le responsable a été informé',
          },
          converted: {
            title: 'Convertir le prospect',
            description: {
              ovoclinic: 'Le prospect a été transformé en patient. ',
              ovobank: 'Le prospect a été converti en donateur. ',
            },
            label: 'Oui, le prospect a été converti',
          },
          appointed: {
            title: 'Piste citée',
            description: 'Un rendez-vous a été fixé avec le responsable',
            label: 'Oui, la piste a été citée',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Services',
        description: 'Gestion des services dans le système. ',
        create: 'Service',
        one: 'service',
        many: 'services',
        columns: {
          name: 'Nom',
          price: 'Prix',
          nextActivityName: 'Activité suivante',
          nextActivityDelay: "Temps d'activité reporté",
        },
      },
      update: {
        subtitle: 'Modifier le service système',
        title: 'Modifier le service',
        form: {
          submit: 'Mise à jour',
          name: {
            title: 'Nom',
            description: "Nom d'identification du service",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​des prestations',
          },
          nextActivityId: {
            title: 'Activité suivante',
            description: 'Activité suivante',
          },
          nextActivityDelay: {
            title: "Temps d'activité reporté",
            description: "Heure à laquelle l'activité est reportée",
          },
        },
        tabs: {
          service: {
            title: 'Service',
            description: "Gestion des services d'activité dans le système. ",
          },
          nextActivity: {
            title: 'Activité suivante',
            description:
              "L'activité suivante est l'activité qui sera réalisée après la réalisation de ce service.",
          },
        },
      },
      enable: {
        title: 'Activer le service',
        subtitle: 'Activer le service sur le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce service ?',
            description:
              "L'activation d'un service signifie qu'il peut être utilisé sur le système",
            label: 'Oui, je souhaite activer ce service',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver le service',
        subtitle: 'Désactiver le service dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce service ?',
            description:
              "Désactiver un service signifie qu'il ne peut pas être utilisé sur le système",
            label: 'Oui, je souhaite désactiver ce service',
          },
          submit: 'Désactiver',
        },
      },
      create: {
        title: 'Créer un service',
        subtitle: "Créer un service d'activité dans le système",
        form: {
          submit: 'Créer',
          name: {
            title: 'Nom',
            description: "Nom d'identification du service",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​des prestations',
          },
          nextActivityId: {
            title: 'Activité suivante',
            description: 'Activité suivante',
          },
          nextActivityDelay: {
            title: "Temps d'activité reporté",
            description: "Heure à laquelle l'activité est reportée",
          },
        },
      },
    },
    products: {
      list: {
        title: 'Produits',
        description: 'Gestion des produits dans le système',
        create: 'Ajouter un produit',
        one: 'produit',
        many: 'produits',
        columns: {
          name: 'Nom',
          price: 'Prix',
          tax: 'Impôts',
          categoryName: 'Catégorie',
          clinicName: 'Clinique',
        },
      },
      update: {
        subtitle: 'Modifier le produit dans le système',
        title: 'Modifier le produit',
        form: {
          submit: 'Garder',
          name: {
            title: 'Nom',
            description: "Nom d'identification du produit",
          },
          icon: {
            title: 'Icône',
            description: "Icône d'identification du produit. ",
          },
          description: {
            title: 'Description',
            description: 'Une brève description du produit pour détailler ses fonctionnalités',
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification du produit. ",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente du produit',
          },
          tax: {
            title: 'impôt',
            description: 'Taxes appliquées au produit',
          },
          path: {
            title: 'Chemin',
            description: 'Chemin où se trouve le produit',
          },
          categoryId: {
            title: 'Catégorie',
            description: 'Catégorie à laquelle appartient le produit',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique à laquelle appartient cette catégorie',
          },
        },
      },
      enable: {
        title: 'Activer le produit',
        subtitle: 'Activer le produit dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer ce produit ?',
            description:
              "L'activation d'un produit signifie qu'il peut être utilisé dans le système",
            label: 'Oui, je souhaite activer ce produit',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver le produit',
        subtitle: 'Désactiver le produit dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver ce produit ?',
            description:
              "Désactiver un produit signifie qu'il ne peut pas être utilisé sur le système",
            label: 'Oui, je souhaite désactiver ce produit',
          },
          submit: 'Désactiver',
        },
      },
      create: {
        title: 'Créer un produit',
        subtitle: 'Créer un produit dans le système',
        steps: {
          category: {
            form: {
              selected: 'Catégorie sélectionnée',
              categoryId: {
                title: 'Catégorie',
                description: 'Catégorie à laquelle appartient le produit',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Clinique sélectionnée',
              clinicId: {
                title: 'Clinique',
                description: 'Clinique à laquelle appartient cette catégorie',
              },
            },
          },
        },
        form: {
          submit: 'Créer',
          name: {
            title: 'Nom',
            description: "Nom d'identification du produit",
          },
          icon: {
            title: 'Icône',
            description: "Icône d'identification du produit. ",
          },
          description: {
            title: 'Description',
            description: 'Une brève description du produit pour détailler ses fonctionnalités',
          },
          color: {
            title: 'Couleur',
            description: "Couleur d'identification du produit. ",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente du produit',
          },
          tax: {
            title: 'impôt',
            description: 'Taxes appliquées au produit',
          },
          path: {
            title: 'Chemin',
            description: 'Chemin où se trouve le produit',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Catégories',
        description: 'Regroupement de produits',
        create: 'Ajouter une catégorie',
        one: 'catégorie',
        many: 'catégories',
        columns: {
          name: 'Nom',
          clinicName: 'Clinique',
          created: 'Date de création',
        },
      },
      update: {
        subtitle: 'Modifier la catégorie dans le système',
        title: 'Modifier la catégorie',
        form: {
          submit: 'Garder',
          name: {
            title: 'Nom',
            description: "Nom d'identification de la catégorie",
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler la catégorie',
          },
        },
      },
      create: {
        title: 'Créer une catégorie',
        subtitle: 'Créer une catégorie dans le système',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clinique',
                description: 'Clinique à laquelle appartient cette catégorie',
              },
            },
          },
        },
        form: {
          submit: 'Créer',
          name: {
            title: 'Nom',
            description: "Nom d'identification de la catégorie",
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler la catégorie',
          },
        },
      },
      enable: {
        title: 'Activer la catégorie',
        subtitle: 'Activer la catégorie dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir activer cette catégorie ?',
            description:
              "Activer une catégorie signifie qu'elle peut être utilisée dans le système",
            label: 'Oui, je souhaite activer cette catégorie',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver la catégorie',
        subtitle: 'Désactiver la catégorie dans le système',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir désactiver cette catégorie ?',
            description:
              "Désactiver une catégorie signifie qu'elle ne peut pas être utilisée dans le système",
            label: 'Oui, je souhaite désactiver cette catégorie',
          },
          submit: 'Désactiver',
        },
      },
    },
    techniques: {
      list: {
        title: 'Techniques',
        description: 'Gestion des techniques de traitement dans le système',
        create: 'Technique',
        one: 'Technique',
        many: 'Techniques',
        columns: {
          name: 'Nom',
          clinic: 'Clinique',
          created: 'Date de création',
          price: 'Prix',
        },
      },
      create: {
        title: 'Créer une technique',
        subtitle: "Enregistrement d'une nouvelle technique dans le système",
        form: {
          id: {
            title: 'Nom',
            description: "Nom d'identification de la technique",
          },
          name: {
            title: 'Nom',
            description: "Nom d'identification de la technique",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente de la technique',
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler la technique',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique où la technique est pratiquée',
          },
          submit: 'Créer une technique',
        },
      },
      update: {
        title: 'Technique de mise à jour',
        subtitle: "Modification d'une technique dans le système",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification de la technique",
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente de la technique',
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler la technique',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique où la technique est pratiquée',
          },
          submit: 'Technique de mise à jour',
        },
      },
      enable: {
        title: 'Activer la technique',
        subtitle: 'Activer la technique dans le système',
        form: {
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver la technique',
        subtitle: 'Désactiver la technique dans le système',
        form: {
          submit: 'Désactiver',
        },
      },
      add: 'Ajouter',
    },
    programs: {
      list: {
        title: 'Programmes',
        description: 'Gestion des programmes de catalogue',
        create: 'Programme',
        one: 'Programme',
        many: 'Programmes',
        columns: {
          name: 'Nom',
          created: 'Date de création',
          price: 'Prix',
          techniques: 'Techniques',
        },
      },
      create: {
        title: 'Créer un programme pour le catalogue',
        subtitle: "Inscription d'un nouveau programme au catalogue",
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du programme",
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler le programme',
          },
          complexity: {
            title: 'Complexité',
            description: 'Niveau de complexité du programme',
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente du programme',
          },
          code: {
            title: 'Code',
            description: "Code d'identification du programme",
          },
          submit: 'Créer un programme',
        },
      },
      enable: {
        title: 'Activer le programme',
        subtitle: 'Activer le programme dans le catalogue',
        form: {
          status: {
            title: "Êtes-vous sûr de vouloir l'activer ?",
            description:
              "L'activation d'un programme signifie qu'il peut être utilisé sur le système",
            label: 'Oui, je veux activer ce programme',
          },
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver le programme',
        subtitle: 'Désactiver le programme dans le catalogue',
        form: {
          status: {
            title: 'Êtes-vous sûr de vouloir le désactiver ?',
            description:
              "Désactiver un programme signifie qu'il ne peut pas être utilisé sur le système",
            label: 'Oui, je veux désactiver ce programme',
          },
          submit: 'Désactiver',
        },
      },
      update: {
        title: 'Programme de mise à jour',
        subtitle: 'Modifier un programme dans le catalogue',
        form: {
          name: {
            title: 'Nom',
            description: "Nom d'identification du programme",
          },
          description: {
            title: 'Description',
            description: 'Une brève description pour détailler le programme',
          },
          complexity: {
            title: 'Complexité',
            description: 'Niveau de complexité du programme',
          },
          clinicId: {
            title: 'Clinique',
            description: 'Clinique dans laquelle le programme est réalisé',
          },
          price: {
            title: 'Prix',
            description: 'Prix ​​de vente du programme',
          },
          code: {
            title: 'Code',
            description: "Code d'identification du programme",
          },
          submit: 'Programme de mise à jour',
        },
        tabs: {
          program: {
            title: 'Programmes',
            description: 'Modifier un programme dans le catalogue',
          },
          techniques: {
            title: 'Techniques',
            description: 'Techniques associées au programme',
          },
          phases: {
            title: 'Étapes',
            description: 'Étapes associées au programme',
          },
          cycles: {
            title: 'Cycles',
            description: 'Cycles associés au programme',
          },
        },
        cycles: {
          list: {
            title: 'Cycles',
            description: 'Cycles associés au programme',
            columns: {
              cycleName: 'Nom',
              cycleCreated: 'Date de création',
            },
            one: 'Faire du vélo',
            many: 'Cycles',
          },
          create: {
            title: 'Ajouter un cycle',
            subtitle: 'Ajouter un cycle au programme',
            form: {
              cycleId: {
                title: 'Faire du vélo',
                description: 'Cycle associé au programme',
              },
              submit: 'Ajouter',
            },
          },
          delete: {
            title: 'Supprimer le cycle',
            subtitle: 'Cette action est irréversible',
            form: {
              submit: 'Éliminer',
            },
          },
        },
        phases: {
          create: {
            title: 'Ajouter une étape',
            subtitle: 'Ajouter une étape au programme',
            form: {
              phaseId: {
                title: 'Scène',
                description: 'Étape associée au programme',
              },
              submit: 'Ajouter',
            },
          },
          delete: {
            title: "Supprimer l'étape",
            subtitle: 'Cette action est irréversible',
            form: {
              status: {
                title: 'Êtes-vous sûr de vouloir le supprimer ?',
                description: 'Vous préférerez peut-être désactiver cette étape',
                label: 'Oui, je veux éliminer cette étape',
              },
              submit: 'Éliminer',
            },
          },
          list: {
            title: 'Étapes',
            description: 'Étapes associées au programme',
            columns: {
              phaseName: 'Nom',
              phaseCreated: 'Date de création',
            },
            one: 'Scène',
            many: 'Étapes',
          },
        },
        techniques: {
          create: {
            title: 'Ajouter une technique',
            subtitle: 'Ajouter une technique au programme',
            form: {
              id: {
                title: 'Technique',
                description: 'Technique associée au programme',
              },
              submit: 'Ajouter',
            },
          },
          delete: {
            title: 'Supprimer la technique',
            subtitle: 'Cette action est irréversible',
            form: {
              submit: 'Éliminer',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Étapes',
        description: "Gestion des étapes d'un traitement",
        create: 'Scène',
        one: 'Scène',
        many: 'Étapes',
        columns: {
          name: 'Nom',
          created: 'Date de création',
        },
      },
      create: {
        title: 'Créer une scène',
        subtitle: "Enregistrement d'une nouvelle étape dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de scène',
          },
          description: {
            title: 'Description',
            description: "Un bref résumé pour donner plus de détails sur l'étape.",
          },
          icon: {
            title: 'Icône',
            label: 'Icône',
            description: 'Icône pour identifier la scène. ',
            search: {
              placeholder: 'Icône de recherche...',
            },
          },
          color: {
            title: 'Couleur',
            description: 'Identifier la couleur de la scène. ',
          },
          submit: 'Créer une scène',
        },
      },
      update: {
        title: 'Étape de mise à jour',
        subtitle: "Modification d'une étape du système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de scène',
          },
          description: {
            title: 'Description',
            description: "Un bref résumé pour donner plus de détails sur l'étape.",
          },
          icon: {
            title: 'Icône',
            description: 'Icône pour identifier la scène. ',
            search: {
              placeholder: 'Icône de recherche...',
            },
            label: 'Icône',
          },
          color: {
            title: 'Couleur',
            description: 'Identifier la couleur de la scène. ',
          },
          submit: 'Garder',
        },
        tabs: {
          phase: {
            title: 'Scène',
            description: 'Informations sur la scène',
          },
          causes: {
            title: 'Causes',
            description: 'Causes pour lesquelles un traitement peut être annulé',
            one: 'Cause',
            many: 'Causes',
          },
        },
      },
      enable: {
        title: "Activer l'étape",
        subtitle: "Activer l'étape dans le système",
        form: {
          submit: 'Activer',
        },
      },
      disable: {
        title: "Désactiver l'étape",
        subtitle: "Désactiver l'étape dans le système",
        form: {
          submit: 'Désactiver',
        },
      },
    },
    causes: {
      list: {
        title: 'Causes',
        description: "Gestion des causes d'annulation d'un soin",
        create: 'Cause',
        one: 'Cause',
        many: 'Causes',
        columns: {
          name: 'Nom',
          created: 'Date de création',
        },
      },
      create: {
        title: 'Créer une cause',
        subtitle: "Enregistrement d'une nouvelle cause dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de la cause',
          },
          description: {
            title: 'Description',
            description: 'Un bref résumé pour donner plus de détails sur la cause.',
          },
          icon: {
            title: 'Icône',
            description: 'Icône pour identifier la cause. ',
            search: {
              placeholder: 'Icône de recherche...',
            },
            label: 'Icône',
          },
          color: {
            title: 'Couleur',
            description: 'Identifier la couleur de la cause. ',
          },
          submit: 'Créer une cause',
        },
      },
      update: {
        title: 'Cause de la mise à jour',
        subtitle: "Modification d'une cause dans le système",
        form: {
          name: {
            title: 'Nom',
            description: 'Nom de la cause',
          },
          description: {
            title: 'Description',
            description: 'Un bref résumé pour donner plus de détails sur la cause.',
          },
          icon: {
            title: 'Icône',
            description: 'Icône pour identifier la cause. ',
            search: {
              placeholder: 'Icône de recherche...',
            },
            label: 'Icône',
          },
          color: {
            title: 'Couleur',
            description: 'Identifier la couleur de la cause. ',
          },
          submit: 'Garder',
        },
      },
      enable: {
        title: 'Activer la cause',
        subtitle: 'Activer la cause dans le système',
        form: {
          submit: 'Activer',
        },
      },
      disable: {
        title: 'Désactiver la cause',
        subtitle: 'Désactiver la cause dans le système',
        form: {
          submit: 'Désactiver',
        },
      },
    },
  },
  board: {
    total: 'Total',
    months: {
      jan: 'Jan',
      feb: 'Fév',
      mar: 'Mer',
      apr: 'Avr',
      may: 'Peut',
      jun: 'juin',
      jul: 'Juillet',
      aug: 'Août',
      sep: 'Sep',
      oct: 'Octobre',
      nov: 'Nov',
      dec: 'Déc',
    },
    noData: {
      title: 'Aucune donnée disponible',
      description: "Vous pouvez essayer de sélectionner d'autres filtres",
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Leads par campagne et statut',
        description: 'Leads par campagne et statut',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
      leadPerClinicAndState: {
        title: 'Dirige par clinique et par état',
        description: 'Dirige par clinique et par état',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
    },
    direction: {
      title: 'Adresse des tableaux de bord',
      description: 'Vue exécutive des informations CRM en temps réel',
      cost: 'Coût du plomb',
      leadCostByClinicAndMonth: {
        title: 'Coût par prospect par clinique',
        description: 'Coût par prospect par clinique',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
      leadCostByFunnelAndMonth: {
        title: 'Répartition des leads par cliniques et départements',
        description: 'Répartition des leads par cliniques et départements',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
      leadCostByCampaignAndMonth: {
        title: 'Coût par prospect par campagne',
        description: 'Coût par prospect par campagne',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
    },
    marketing: {
      title: 'Tableaux de bord marketing',
      description: 'Informations marketing en temps réel sur le CRM',
      secondary: {
        title: 'Campagnes marketing',
        description:
          'Visualisation des informations sur les prospects concernant les campagnes marketing.',
      },
      convertionRateByCampaign: {
        title: 'Taux de conversion par campagne',
        description: 'Taux de conversion par campagne',
        totalLeads: 'Nombre total de prospects',
        totalConvertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
      },
      totalLeads: 'TOTAL DES PROSPECTS',
      leadsState: 'STATUT DE PISTE',
      leadActiveInactive: 'PROSPECTS ACTIFS/INACTIFS',
      leadsPerCampaign: {
        title: 'Leads par campagne',
        description: 'Leads par campagne',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
        filters: {
          campaign: 'Cloche',
          funnel: 'Entonnoir',
        },
      },
      leadCostDetail: {
        title: 'Détail',
        selectedClinic: 'Clinique sélectionnée',
        selectedCampaign: 'Campagne sélectionnée',
        budget: 'Budget',
      },
      costCampaign: {
        '0': 'Clinique sélectionnée : ',
        '1': 'Campagne sélectionnée : ',
        '2': 'Budget: ',
        '3': 'Nombre de prospects : ',
        '4': 'Pistes citées : ',
        '5': 'Coût total des leads : ',
        '6': 'Coût total indiqué : ',
      },
      leadsPerFunnel: {
        title: 'Leads par entonnoir',
        description: 'Leads par entonnoir',
        totalLeads: 'Nombre total de prospects',
        totalCost: 'Coût total',
        costPerLead: 'Coût par prospect',
      },
      filters: {
        year: 'Année',
        yearSubtitle: 'Filtrer par année sélectionnée',
        by: 'Filtrer par',
      },
      generalTable: {
        totalLeads: 'Nombre total de prospects',
        informed: "V.J'ai joué",
        total: 'Total cumulé',
        costPerLead: 'Coût par prospect',
        totalRow: 'Total',
      },
    },
    subordinates: {
      title: 'La performance de mon équipe',
      description: 'Visualiser les performances de mon équipe',
      performance: {
        title: 'Jalons atteints',
        description: 'Visualiser les jalons atteints par mon équipe',
      },
      performanceSubordinates: {
        title: 'PERFORMANCE DE MON ÉQUIPE',
      },
      performanceSubordinate: {
        title: 'LA PERFORMANCE DE MON PARTENAIRE',
      },
      filters: {
        clinics: {
          all: 'Tous',
        },
        subordinate: {
          title: 'Employés',
          subtitle: 'Filtrer par employé sélectionné',
        },
      },
    },
    intervention: {
      title: 'Prévisions de traitement',
      description: 'Affichage des traitements estimés',
      expectedInterventions: {
        monthsTable: 'PRÉVISIONS DE TRAITEMENT',
        yearsTable: 'PRÉVISION DES TRAITEMENTS ACCUMULÉS',
      },
      segmention: 'Répartition des traitements',
      lossGainSegmentation: 'Répartition des profits et pertes',
      one: 'Traitement',
      many: 'Traitements',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Pertes',
        total_first_attempt: 'Garanties (1ère tentative)',
        total: 'Total',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Pertes',
        total_first_attempt: 'Gains',
        total: 'Total',
      },
      filters: {
        complexity: {
          title: 'Complexité',
          subtitle: 'Filtrer par complexité sélectionnée',
          types: {
            ALL: 'Tous',
            HIGH: 'Haut',
            LOW: 'Faible',
          },
        },
      },
    },
    callcenter: {
      title: "Tableaux de bord du centre d'appels",
      description: "Vue du centre d'appels des informations CRM en temps réel",
    },
    leads: {
      title: 'Tableaux de bord mène',
      description: 'Visualisation des leads à partir des informations CRM en temps réel',
      totalLeads: {
        title: 'Nombre total de prospects',
        description: 'Résumé des pistes par clinique et service',
        filters: {
          clinic: 'Clinique',
          clinicSubtitle: 'Filtrer par clinique sélectionnée',
          campaign: 'Cloche',
          campaignSubtitle: 'Filtrer par campagne sélectionnée',
          optionA: 'Option A',
          optionASubtitle: '1ère option du comparatif',
          optionB: 'Option B',
          optionBSubtitle: '2ème option de la comparaison',
          funnel: 'Origine',
          funnelSubtitle: 'Filtrer par origine',
          all: 'Tous',
          department: 'Département',
          departmentSubtitle: 'Filtrer par département sélectionné',
          year: 'Année',
          yearSubtitle: 'Filtrer par année sélectionnée',
          month: 'Mois',
          monthSubtitle: 'Filtrer par mois sélectionné',
          allMonths: 'Tous',
        },
        comparativeGraphicTitle: 'COMPARAISON DES PISTES',
        comparativeAcumulatedGraphicTitle: 'COMPARAISON PLOMB/ACCUMULÉS',
        totalLeads: 'TOTAL DES PROSPECTS',
        summary: 'RÉSUMÉ',
        export: 'EXPORTER CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Entonnoir de vente',
      description: "Vue de l'entonnoir de vente des informations CRM en temps réel",
      totalSales: {
        graphicTitle: 'ENTONNOIR DE VENTES',
      },
    },
    sales: {
      title: 'Ventes de tableaux de bord',
      description: 'Vue commerciale des informations CRM en temps réel',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Traitements, techniques et services par clinique',
        description: 'Traitements, techniques et services par clinique',
        totalTreatments: 'Traitements totaux',
        totalTechniques: 'Techniques totales',
        totalServices: 'Prestations totales',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Traitements, techniques et services par département',
        description: 'Traitements, techniques et services par département',
        totalTreatments: 'Traitements totaux',
        totalTechniques: 'Techniques totales',
        totalServices: 'Prestations totales',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Récapitulatif des ventes',
        ovobank: 'Récapitulatif des ventes',
      },
      description: {
        ovoclinic: 'Synthèse des leads convertis en patients',
        ovobank: 'Synthèse des leads convertis en donateurs',
      },
      totalSales: {
        title: 'Ventes',
        description: 'Visualisation des ventes réalisées',
        filters: {
          clinic: 'Clinique',
          clinicSubtitle: 'Filtrer par clinique sélectionnée',
          optionA: 'Option A',
          optionASubtitle: '1ère option du comparatif',
          optionB: 'Option B',
          optionBSubtitle: '2ème option de la comparaison',
          department: 'Département',
          departmentSubtitle: 'Filtrer par département sélectionné',
          month: 'Mois',
          monthSubtitle: 'Filtrer par mois sélectionné',
        },
        comparativeGraphicTitle: 'COMPARAISON DES VENTES',
        export: 'EXPORTER CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'COMPARAISON DES VENTES / CUMULÉS',
      },
    },
    medical: {
      title: 'Tableaux de bord médicaux',
      description: 'Visualisation par le service médical des informations CRM en temps réel',
      leadConversionByFunnelAndMonth: {
        title: 'Taux de conversion des leads par origine et mois',
        description: 'Taux de conversion des leads par origine et mois',
        totalLeads: 'Nombre total de prospects',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Pourcentage de conversion de leads par origine et par mois',
        description: 'Pourcentage de conversion de leads par origine et par mois',
        totalLeads: 'Nombre total de prospects',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Pourcentage de conversion',
      },
      estimatedTreatmentByYear: {
        title: 'Traitements programmés',
        description: "Comparaison des soins programmés l'année sélectionnée et l'année précédente",
        estimated: 'Traitement programmé',
        finished: 'Traitement terminé',
        previousEstimated: 'Traitements programmés',
        previousFinished: 'Traitements terminés',
      },
      finishedTreatmentByYear: {
        title: 'Traitements terminés',
        description:
          "Comparaison des traitements réalisés au cours de l'année sélectionnée et de l'année précédente",
        estimated: 'Traitement programmé',
        finished: 'Traitement terminé',
        previousEstimated: 'Traitements programmés',
        previousFinished: 'Traitements terminés',
      },
      leadConversionBySpecialist: {
        title: 'Taux de conversion de leads par spécialiste',
        description: 'Taux de conversion de leads par spécialiste',
        totalLeads: 'Nombre total de prospects',
        appointedLeads: 'Pistes citées',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
        treatmentSold: 'Soins vendus',
        treatmentFinished: 'Traitements terminés',
        specialistData: 'Informations spécialisées',
      },
      leadConversionBySpecialistKPI: {
        title: 'KPI Taux de conversion des leads par spécialiste',
        conversionRate: 'Taux de conversion',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Taux de conversion des leads par spécialiste et par mois',
        description: 'Taux de conversion des leads par spécialiste et par mois',
        totalLeads: 'Nombre total de prospects',
        appointedLeads: 'Pistes citées',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
        treatmentSold: 'Soins vendus',
        treatmentFinished: 'Traitements terminés',
        specialistData: 'Informations spécialisées',
        leadsAttended: 'Pistes servies',
        leadsAttendedConverted: 'Leads servis convertis',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'KPI Taux de conversion des leads par spécialiste et par mois',
        description: 'KPI Taux de conversion des leads par spécialiste et par mois',
        conversionRate: 'Taux de conversion',
      },
      leadConversionByFunnel: {
        title: 'Taux de conversion des leads par origine',
        description: 'Taux de conversion des leads par origine',
        totalLeads: 'Nombre total de prospects',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
        appointedLeads: 'Pistes citées',
        treatmentSold: 'Soins vendus',
        treatmentFinished: 'Traitements terminés',
      },
      leadConversionByFunnelKPI: {
        title: 'KPI Taux de conversion des leads par origine',
        description: 'KPI Taux de conversion des leads par origine',
        totalLeads: 'Nombre total de prospects',
        convertedLeads: 'Prospects convertis',
        conversionRate: 'Taux de conversion',
        appointedLeads: 'Pistes citées',
        treatmentSold: 'Soins vendus',
        treatmentFinished: 'Traitements terminés',
        convertedLeadsRate: 'Taux de conversion des prospects',
        treatmentSoldRate: 'Taux de conversion des soins vendus',
      },
    },
    menu: {
      leads: 'Résumé du prospect',
      directory: 'Annuaire',
      direction: 'Adresse',
      expectedIntervention: 'Prévisions de traitement',
      general: 'Général',
      callcenter: "Centre d'appels",
      medical: 'Médecin',
      marketing: 'Commercialisation',
      sale: 'Ventes',
      saleFunnel: 'Entonnoir de vente',
      summarySales: 'Récapitulatif des ventes',
      subordinates: 'Performance des employés',
    },
    sections: {
      marketing: {
        actives: 'Actifs',
        inactives: 'Inactif',
        converted: 'Converti',
        appointed: 'Cité',
        informed: 'Informé',
        contacted: 'Contacté',
        leadPerCampaign: {
          title: 'Leads par campagne marketing',
        },
        leadPerFunnel: {
          title: 'Prospects par origine de la campagne',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Taux de conversion des patients principaux par entonnoir',
            ovobank: 'Taux de conversion des donateurs principaux par entonnoir',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Taux de conversion des patients principaux par campagne',
            ovobank: 'Taux de conversion des donateurs principaux par campagne',
          },
          patients: {
            ovoclinic: 'Patients',
            ovobank: 'Donateurs',
          },
          leads: 'Conduit',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Taux de conversion des patients principaux par origine',
            ovobank: 'Taux de conversion en donneurs principaux par origine',
          },
          patients: {
            ovoclinic: 'Patients',
            ovobank: 'Donateurs',
          },
          leads: 'Conduit',
        },
        leadPerClinicPerState: {
          total: 'Total',
          title: 'Leads par clinique et par état',
          actives: 'Actifs',
          inactives: 'Inactif',
          converted: 'Converti',
          appointed: 'Cité',
          appointedMedic: 'Cité (médecin)',
          contacted: 'Contacté',
          informed: 'Informé',
          loss: 'Faible',
          cancelled: 'Annulé',
        },
        leadPerCampaignPerState: {
          total: 'Total',
          title: 'Leads par campagne et par état',
          actives: 'Actifs',
          inactives: 'Inactif',
          converted: 'Converti',
          appointed: 'Cité',
          contacted: 'Contacté',
          informed: 'Informé',
          loss: 'Faible',
        },
        totalLeadsPerState: {
          total: 'Total',
          appointed: 'Cité',
          percentage: 'Pourcentage',
          cost: 'Coût',
          actives: 'Actifs',
          inactives: 'Inactif',
        },
        filter: {
          campaignId: {
            title: 'Filtrer par campagne',
          },
          languageId: {
            title: 'Filtrer par langue',
          },
          funnelId: {
            title: 'Filtrer par origine',
          },
          clinicId: {
            title: 'Filtrer par clinique',
          },
        },
        listLeadsByCampaign: {
          title: 'Répartition de la campagne',
          campaign: 'Campagne marketing',
          funnel: 'Origine',
          financingClinic: 'Clinique de financement',
          language: 'Département',
          budget: 'Budget de campagne',
          nLeads: 'Nombre de leads',
          costPerLead: 'Coût par prospect',
        },
        all: 'Tous',
      },
      callcenter: {
        listLeadsByReason: {
          title: "Prospects en raison d'une annulation",
          reason: 'Raison',
          nLeads: 'Nombre de leads',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Coût du plomb par clinique',
          monthFilter: 'Filtrer par mois',
        },
        costsLeadPerFunnel: {
          title: 'Coût du plomb par origine',
        },
        costsLeadPerCampaign: {
          title: 'Coût du prospect par campagne',
        },
        costsLeadPerLanguage: {
          title: 'Coût des leads par département',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Traitements, techniques et services par clinique',
        },
        salePerLanguage: {
          title: 'Traitements, techniques et services par département',
        },
      },
      saleFunnel: {
        title: 'Entonnoir commercial',
        description: "Visualisation de l'état général des leads pendant le processus de vente",
      },
    },
  },
  profile: {
    menu: {
      profile: 'Profil',
      account: 'mon compte',
      changePassword: 'Changer le mot de passe',
    },
    changePassword: {
      title: 'Changer le mot de passe',
      subtitle: 'Changez le mot de passe de votre compte',
      form: {
        password: {
          title: 'Mot de passe',
          description: "Nouveau mot de passe pour l'utilisateur",
        },
        repeatPassword: {
          title: 'Répéter le mot de passe',
          description: 'Répétez le mot de passe pour le confirmer',
        },
        submit: 'Changer le mot de passe',
      },
    },
    account: {
      title: 'mon compte',
      subtitle: 'Informations personnelles et de compte',
      form: {
        name: {
          title: 'Nom',
          description: 'Entrez votre nom',
        },
        surname: {
          title: 'Noms de famille',
          description: 'Entrez votre nom de famille',
        },
        username: {
          title: "Nom d'utilisateur",
          description: "Entrez votre nom d'utilisateur",
        },
        phone: {
          title: 'Téléphone',
          description: 'Entrez votre numéro de téléphone',
        },
        submit: 'Mettre à jour le compte',
      },
    },
  },
  common: {
    search: {
      placeholder: 'Rechercher...',
    },
    actions: {
      create: 'Créer',
      update: 'Mise à jour',
      delete: 'Éliminer',
      save: 'Garder',
      cancel: 'Annuler',
    },
    tasks: {
      disabled: 'Afficher inactif',
      noResult: 'Aucun résultat',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Supprimer la note',
          description: 'Êtes-vous sûr de vouloir supprimer cette note ?',
          cancel: 'Annuler',
          submit: 'Éliminer',
        },
      },
      noteType: {
        title: 'Voir les observations du',
        patient: {
          ovoclinic: 'Patient',
          ovobank: 'Donneur',
        },
        lead: {
          ovoclinic: 'Plomb',
          ovobank: 'Plomb',
        },
      },
      filter: {
        title: 'Filtrer par',
        tags: {
          medical: 'Médecin',
          nursing: 'Allaitement',
          followUp: 'Suivi',
        },
      },
    },
    time: {
      seconds: 'secondes',
      second: 'deuxième',
      minutes: 'minutes',
      minute: 'minute',
      hour: 'heure',
      hours: 'heures',
      days: 'jours',
      day: 'jour',
      weekDays: {
        '0': 'Lundi',
        '1': 'Mardi',
        '2': 'Mercredi',
        '3': 'Jeudi',
        '4': 'Vendredi',
        '5': 'Samedi',
        '6': 'Dimanche',
      },
      timeMessage: {
        expiresIn: 'Expire dans {{value}} {{unit}}',
        expired: 'Expiré',
        ago: 'Fait',
      },
    },
    status: {
      success: 'Correct',
      fail: 'Échoué',
    },
    notification: {
      title: 'Vous avez une nouvelle notification',
    },
    contactTime: {
      MORNING: 'Demain',
      AFTERNOON: 'En retard',
      NO_PREFERENCE: 'Aucune préférence',
    },
    complexity: {
      HIGH: 'Haut',
      LOW: 'Faible',
    },
    specialistType: {
      GYNECOLOGIST: 'Gynécologue',
      EMBRYOLOGIST: 'embryologiste',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: "Boucles d'oreilles",
      COMPLETED: 'Fait',
      ALL: 'Tous',
    },
    plannedStatus: {
      ALL: 'Tous',
      PLANNED: 'Prévu',
      UNPLANNED: 'Imprévu',
    },
    taskProgress: {
      PENDING: 'Boucle',
      COMPLETED: 'Fait',
    },
    leadEntryStatus: {
      NEW: 'Nouveau',
      DUPLICATED: 'Double',
      REENTRY: 'Rentrée',
    },
    paymentStatus: {
      PENDING: 'Boucle',
      PAID: 'Payé',
    },
    purchaseType: {
      MAINTENANCE: 'Entretien',
      TREATMENT: 'Programme',
      TECHNIQUE: 'Technique',
    },
    notifications: {
      list: {
        title: 'Notifications',
        description: 'Gestion des notifications dans le système',
        columns: {
          title: 'Qualification',
          type: 'Type de notification',
          created: 'Date de création',
        },
        one: 'Notification',
        many: 'Notifications',
      },
      types: {
        ACTION_ALERT: 'Alerte de tâche',
      },
      new: 'Vous avez une nouvelle notification',
    },
    information: {
      title: 'Ressources',
      description: 'Informations et ressources sur la plateforme',
    },
  },
  components: {
    fileUploader: {
      title: 'Télécharger le fichier',
      invalidType: 'Type de fichier non autorisé',
    },
  },
  login: {
    title: 'Se connecter',
    subtitle: 'Accès à la plateforme de gestion',
    form: {
      email: {
        title: 'E-mail',
      },
      signature: {
        title: 'Mot de passe',
      },
      submit: 'Se connecter',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: "Nom d'utilisateur ou mot de passe incorrect",
      WRONG_PERMISSIONS: "Vous n'avez pas les autorisations pour exécuter cette action",
      service: {
        group: {
          findByDomain: {
            error: 'FATAL : le groupe est introuvable',
          },
        },
      },
    },
  },
};
