export const FontLoaderConstants = {
  fonts: {
    // icons font
    'line-awesome': require('../../../assets/fonts/line-awesome/line-awesome.ttf'),

    // Lato-ThinItalic.ttf
    'Lato-Black': require('../../../assets/fonts/lato/Lato-Black.ttf'),
    'Lato-Bold': require('../../../assets/fonts/lato/Lato-Bold.ttf'),
    'Lato-BoldItalic': require('../../../assets/fonts/lato/Lato-BoldItalic.ttf'),
    'Lato-Italic': require('../../../assets/fonts/lato/Lato-Italic.ttf'),
    'Lato-Light': require('../../../assets/fonts/lato/Lato-Light.ttf'),
    'Lato-LightItalic': require('../../../assets/fonts/lato/Lato-LightItalic.ttf'),
    'Lato-Regular': require('../../../assets/fonts/lato/Lato-Regular.ttf'),
    'Lato-Thin': require('../../../assets/fonts/lato/Lato-Thin.ttf'),
    'Lato-ThinItalic': require('../../../assets/fonts/lato/Lato-ThinItalic.ttf'),


    'TsukimiRounded-Bold': require('../../../assets/fonts/tsukimi-rounded/TsukimiRounded-Bold.ttf'),
    'TsukimiRounded-Light': require('../../../assets/fonts/tsukimi-rounded/TsukimiRounded-Light.ttf'),
    'TsukimiRounded-Medium': require('../../../assets/fonts/tsukimi-rounded/TsukimiRounded-Medium.ttf'),
    'TsukimiRounded-Regular': require('../../../assets/fonts/tsukimi-rounded/TsukimiRounded-Regular.ttf'),
  },
}
