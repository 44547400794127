// Modules
import { StyleSheet } from 'react-native';

/**
 * Search Styles
 * @constant {StyleSheet} SearchStyles
 */
export const SearchStyles = StyleSheet.create({
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 40,
    width: 40,
    opacity: 0.5,
    zIndex: 1,
  },
  input: {
    height: 40,
    padding: 10,
    paddingLeft: 40,
    fontSize: 17,

    borderWidth: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 5,
  },
  badgesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  icon: {
    color: '#000',
    fontSize: 20,
    lineHeight: 40,
    textAlign: 'center',
  },
});
