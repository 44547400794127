import { Text, View } from 'react-native';
import { FieldsProperties } from './fields.properties';
import { TextInput } from './inputs/text/text.input';
import { FieldsImplementation } from './fields.implementation';
import { PairInput } from './inputs/pair/pair.input';
import { CheckboxInput } from './inputs/checkbox/checkbox.input';
import { FieldsStyles } from './fields.styles';
import { DateInput } from './inputs/date/date.input';
import { JsonInput } from './inputs/json/json.input';
import { SelectInput } from './inputs/select/select.input';
import { NumberInput } from './inputs/number/number.input';
import { AccordionInput } from './inputs/accordion/accordion.input';
import { ColorInput } from './inputs/color/color.input';
import { IconInput } from './inputs/icon/icon.input';
import { ImageInput } from './inputs/image/image.input';
import { MapInput } from './inputs/map/map.input';
import { FieldContainer } from './field.container';
import { FloatInput } from './inputs/float/float.input';
import { FileInput } from './inputs/file/file.input';
import { AvatarInput } from './inputs/avatar/avatar.input';

export function FieldsComponent<ITEM>(properties: FieldsImplementation<ITEM>) {
  const renderField = (field: FieldsProperties<ITEM>['fields'][0], index: number) => {
    if (field.type === 'json') {
      return (
        <JsonInput<ITEM>
          {...field}
          key={index}
          readonly={properties.readonly}
          errors={properties.errors}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }
    if (field.type === 'text') {
      return (
        <TextInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }
    if (field.type === 'image') {
      return (
        <ImageInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'pair') {
      return (
        <PairInput<ITEM>
          prefix={properties.prefix}
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          values={properties.values}
        />
      );
    }
    if (field.type === 'accordion') {
      return (
        <AccordionInput<ITEM>
          prefix={properties.prefix}
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          values={properties.values}
        />
      );
    }
    if (field.type === 'checkbox') {
      return (
        <CheckboxInput<ITEM>
          prefix={properties.prefix}
          {...field}
          errors={properties.errors}
          readonly={properties.readonly}
          key={index}
          onChange={(value) => {
            properties.onChange(value);
          }}
          values={properties.values}
        />
      );
    }
    if (field.type === 'date') {
      return (
        <DateInput<ITEM>
          prefix={properties.prefix}
          {...field}
          errors={properties.errors}
          readonly={properties.readonly}
          key={index}
          onChange={(value) => {
            properties.onChange(value);
          }}
          values={properties.values}
        />
      );
    }

    if (field.type === 'select') {
      return (
        <SelectInput<ITEM>
          {...field}
          errors={properties.errors}
          readonly={properties.readonly || field.readonlyField}
          key={index}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'number') {
      return (
        <NumberInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'float') {
      return (
        <FloatInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'color') {
      return (
        <ColorInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }
    if (field.type === 'icon') {
      return (
        <IconInput<ITEM>
          prefix={properties.prefix}
          {...field}
          key={index}
          onChange={(value) => {
            properties.onChange(value);
          }}
          readonly={properties.readonly}
          value={properties.values}
          errors={properties.errors}
        />
      );
    }

    if (field.type === 'map') {
      return (
        <MapInput<ITEM>
          {...field}
          errors={properties.errors}
          key={index}
          readonly={properties.readonly}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'avatar') {
      return (
        <AvatarInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          onChange={(value) => {
            properties.onChange(value);
          }}
          value={properties.values}
        />
      );
    }

    if (field.type === 'file') {
      return (
        <FileInput<ITEM>
          {...field}
          key={index}
          errors={properties.errors}
          onChange={properties.onChange}
          value={properties.values}
        />
      );
    }

    return <Text key={index}>Field type not supported</Text>;
  };

  return (
    <>
      {properties.fields.map((field, index) => {
        return (
          <FieldContainer
            key={index}
            style={[
              FieldsStyles.container,
              { flex: properties.flex },
              { zIndex: properties.fields.length - index },
            ]}
            required={properties.readonly ? false : 'required' in field ? field.required : false}
            type={field.type}
            name={'name' in field ? field.name : 'name'}
            multiWorkspace={'multiWorkspace' in field}
            description={'description' in field ? field.description : false}
            prefix={properties.prefix}
          >
            {renderField(field, index)}
          </FieldContainer>
        );
      })}
    </>
  );
}
