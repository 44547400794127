import { ButtonComponent } from '@components/button/button.component';
import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PatientProfileComponent } from '@components/patientProfile/patientProfile.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { ActivityCommunicationChannelCrmRepository } from '@human/crm/repositories/activityCommunicationChannel/activityCommunicationChannel.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { UpdateActionActionEndingStyles } from '@human/crm/sections/actions/screens/update/parts/update.action.actionEnding.styles';
import { ListActionAlertScreen } from '@human/crm/sections/alerts/screens/list/list.alert.screen';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ActivityCommunicationCrmType } from '@human/crm/types/activityCommunicationChannel.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { addDays } from '@utils/addDays/addDays.utility';
import { useEffect, useState } from 'react';
import { View } from 'react-native';

export function UpdatePatientActionScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientActionUpdate'>;
}) {
  const [action, setAction] = useState<Partial<ActionCrmType>>();
  const [commsChannel, setCommsChannel] = useState<ActivityCommunicationCrmType[]>([]);

  const { showSuccess } = useSuccessToast();

  useEffect(() => {
    new ActionCrmRepository()
      .pickByPatient({
        actionId: properties.route.params.actionId,
        clinicId: properties.route.params.clinicId,
        patientId: properties.route.params.patientId,
      })
      .then((action) => {
        new ActivityCommunicationChannelCrmRepository()
          .list({
            activityId: action.activityId ?? '',
            limit: 100,
            page: 0,
          })
          .then((commsChannel) => {
            setCommsChannel(commsChannel.items);
            if (commsChannel.items.length > 0 && !action.communicationChannelId) {
              setAction({
                ...action,
                communicationChannelId: commsChannel.items[0].channelId,
              });
              return;
            }

            setAction(action);
          });
      });
  }, []);

  const handleSubmit = () => {
    new ActionCrmRepository()
      .addPatientResult({
        actionId: properties.route.params.actionId,
        clinicId: properties.route.params.clinicId,
        patientId: properties.route.params.patientId,
        resultId: action?.resultId ?? '',
        communicationChannelId: action?.communicationChannelId,
      })
      .then((action) => {
        properties.navigation.goBack();
        if (action.nextActivityId)
          properties.navigation.navigate('patientActionNextResultUpdate', {
            actionId: action.id,
            clinicId: action.clinicId,
            patientId: action.patientId,
            level: properties.route.params.level,
            activityId: action.nextActivityId,
            started: action.nextActivityId
              ? addDays(new Date(), action.nextActivityDelay)
              : new Date(),
          });
      });
  };

  return (
    <>
      <PatientProfileComponent
        patientId={properties.route.params.patientId}
        clinicId={properties.route.params.clinicId}
        contactId={properties.route.params.contactId}
        leadId={action?.leadId || ''}
      />

      <ModalComponent
        level={properties.route.params.level}
        icon="resolving"
        prefix="crm.patients.actions.update"
      >
        <TabsComponent
          prefix="crm.patients.actions.update"
          tabs={[
            {
              name: 'action',
              icon: 'calendar-check',
              content: () => {
                return (
                  <FormComponent<ActionCrmType>
                    prefix="crm.patients.actions.update.form"
                    repository={{
                      get: () => {
                        return new ActionCrmRepository().pickByPatient({
                          actionId: properties.route.params.actionId,
                          patientId: properties.route.params.patientId,
                          clinicId: properties.route.params.clinicId,
                        });
                      },
                      send: (settings) => {
                        return new ActionCrmRepository()
                          .updatePatientAction({
                            item: {
                              ...settings.item,
                              patientId: properties.route.params.patientId,
                              clinicId: properties.route.params.clinicId,
                            },
                          })
                          .then(() => showSuccess());
                      },
                    }}
                    fields={[
                      {
                        type: 'text',
                        name: 'subject',
                        description: true,
                        validations: ['title'],
                      },
                      {
                        type: 'select',
                        name: 'activityId',
                        required: true,
                        description: true,
                        pick: (properties: { id: string }) => {
                          return new ActivityCrmRepository()
                            .pick({ activityId: properties.id })
                            .then((activity) => {
                              return `${activity.name}`;
                            });
                        },
                        repository: ({ search }) => {
                          return new ActivityCrmRepository()
                            .list({
                              direction: 'asc',
                              limit: 100,
                              order: 'id',
                              page: 0,
                              search,
                              active: true,
                            })
                            .then((activities) =>
                              activities.items.map((activity) => ({
                                label: activity.name,
                                value: activity.id,
                              }))
                            );
                        },
                      },
                      {
                        type: 'select',
                        name: 'responsibleId',
                        required: true,
                        description: true,
                        pick: (props: { id: string }) => {
                          return new CoworkerCrmRepository()
                            .pick({
                              clinicId: properties.route.params.clinicId,
                              coworkerId: props.id,
                            })
                            .then((coworker) => {
                              return `${coworker.name} ${coworker.surname}`;
                            });
                        },
                        repository: ({ search }) => {
                          return new CoworkerCrmRepository()
                            .list({
                              clinicId: properties.route.params.clinicId,
                              direction: 'asc',
                              limit: 100,
                              active: true,
                              order: 'email',
                              page: 0,
                              search,
                            })
                            .then((leads) =>
                              leads.items.map((user) => ({
                                label: `${user.name} ${user.surname}`,
                                value: user.id,
                              }))
                            );
                        },
                      },
                      {
                        type: 'date',
                        description: true,
                        required: true,
                        name: 'started',
                        format: 'YYYY-MM-DD HH:mm:ss',
                      },
                      {
                        type: 'text',
                        description: true,
                        lines: 3,
                        name: 'observation',
                        validations: ['observation'],
                      },
                    ]}
                  />
                );
              },
            },
            {
              name: 'actionEnding',
              icon: 'check-circle',
              content: () => (
                <>
                  <FieldContainer
                    prefix="crm.patients.actions.update.form.result"
                    type="select"
                    name={'result'}
                    description
                    required
                  >
                    <SelectInput<{ resultId: string }>
                      type={'select'}
                      name="resultId"
                      required
                      value={{
                        resultId: action?.resultId,
                      }}
                      description
                      onChange={(value, option) => {
                        if (!value.resultId) return;
                        setAction({
                          ...action,
                          resultId: value.resultId,
                        });
                      }}
                      pick={(props: { id: string }) => {
                        return new ResultCrmRepository()
                          .pick({
                            resultId: action?.resultId ?? '',
                            activityId: properties.route.params.activityId,
                          })
                          .then((result) => {
                            return result.name;
                          });
                      }}
                      repository={() => {
                        return new ResultCrmRepository()
                          .findByActivity({
                            activityId: properties.route.params.activityId,
                            direction: 'asc',
                            limit: 100,
                            page: 0,
                            active: true,
                          })
                          .then((results) =>
                            results.items.map((result) => ({
                              label: result.name,
                              value: result.id,
                            }))
                          );
                      }}
                    />
                  </FieldContainer>
                  {commsChannel.length > 1 && (
                    <FieldContainer
                      prefix="crm.leads.update.actions.update.actionEnding.form"
                      name={'communicationChannel'}
                      type="select"
                      description
                      required
                    >
                      <SelectInput<{ channel: string }>
                        type={'select'}
                        name="channel"
                        required
                        value={{
                          channel: action?.communicationChannelId ?? '',
                        }}
                        description
                        onChange={(value, option) => {
                          if (!value.channel) return;
                          setAction({
                            ...action,
                            communicationChannelId: value.channel,
                          });
                        }}
                        pick={async ({ id }) => {
                          return (
                            commsChannel.find((channel) => channel.channelId === id)?.channelName ??
                            ''
                          );
                        }}
                        repository={async (settings) => {
                          return commsChannel.map((channel) => ({
                            label: channel.channelName,
                            value: channel.channelId,
                          }));
                        }}
                      />
                    </FieldContainer>
                  )}
                  <View style={UpdateActionActionEndingStyles.submitContainer}>
                    <ButtonComponent
                      icon="plus"
                      prefix="crm.patients.actions.update.actionEnding.form.submitNextSuggestion"
                      onPress={() => handleSubmit()}
                    />
                  </View>
                </>
              ),
            },
            {
              icon: 'bell',
              name: 'alerts',
              content: () =>
                ListActionAlertScreen({
                  actionId: properties.route.params.actionId,
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  onAdd: () => {
                    properties.navigation.navigate('alertCreate', {
                      actionId: properties.route.params.actionId,
                      clinicId: properties.route.params.clinicId,
                      patientId: properties.route.params.patientId,
                      level: 2,
                    });
                  },
                  onDelete: ({ actionAlertId }) => {
                    properties.navigation.navigate('alertDelete', {
                      actionId: properties.route.params.actionId,
                      actionAlertId,
                      clinicId: properties.route.params.clinicId,
                      patientId: properties.route.params.patientId,
                      level: 2,
                    });
                  },
                  onUpdate: ({ actionAlertId }) => {
                    properties.navigation.navigate('alertUpdate', {
                      actionId: properties.route.params.actionId,
                      actionAlertId,
                      clinicId: properties.route.params.clinicId,
                      patientId: properties.route.params.patientId,
                      level: 2,
                    });
                  },
                }),
            },
          ]}
        />
      </ModalComponent>
    </>
  );
}
