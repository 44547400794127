import { NavigationProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { BulletinsRoutes } from '../../bulletins.routes';
import { BulletinAccessType } from '@human/access/types/bulletin.access.type';
import { useState } from 'react';
import { ActionComponent } from '../../../../../../../../../components/action/action.component';
import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { BulletinAccesRepository } from '@human/access/repositories/bulletin/bulletin.repository';

export function CreateBulletinsScreen(properties: { navigation: NavigationProp<BulletinsRoutes> }) {
  const [clinicId, setClinicId] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();

  const [actorId, setActorId] = useState<string>();
  const [actorName, setActorName] = useState<string>();

  return (
    <ModalComponent icon="newspaper" prefix="access.bulletins.create" flex={false}>
      {!clinicId ? (
        <FieldContainer
          prefix="access.bulletins.create.steps.clinic.form"
          type="select"
          required
          padding
          name="clinicId"
          description
        >
          <SelectInput<{ clinicId: string }>
            type={'select'}
            name="clinicId"
            required
            value={{
              clinicId: clinicId || undefined,
            }}
            description
            onChange={(value, option) => {
              if (!value.clinicId) return;
              setClinicId(value.clinicId);
              setClinicName(option.label);
            }}
            pick={(properties: { id: string }) => {
              return new ClinicAccessRepository().pick({ id: properties.id }).then((language) => {
                return `${language.name}`;
              });
            }}
            repository={({ search }) => {
              return new ClinicAccessRepository()
                .list({ limit: 100, page: 0, search, active: true })
                .then((clinics) => {
                  const auxClinics = clinics.items.map((clinic) => ({
                    label: clinic.name,
                    value: clinic.id,
                  }));

                  return auxClinics;
                });
            }}
          />
        </FieldContainer>
      ) : (
        <ActionComponent
          onPress={() => {
            setClinicId(undefined);
            setActorId(undefined);
          }}
          prefix="access.bulletins.create.steps.clinic.form.title"
          bubble={clinicName}
        />
      )}
      {!actorId && clinicId ? (
        <FieldContainer
          prefix="access.bulletins.create.steps.actor.form"
          type="select"
          required
          padding
          name="actorId"
          description
        >
          <SelectInput<{ actorId: string }>
            type={'select'}
            name="actorId"
            required
            value={{
              actorId: actorId || undefined,
            }}
            description
            onChange={(value, option) => {
              if (!value.actorId) return;
              setActorId(value.actorId);
              setActorName(option.label);
            }}
            pick={(properties: { id: string }) => {
              return new CoworkerCrmRepository()
                .pick({ clinicId, coworkerId: properties.id })
                .then((coworker) => {
                  return `${coworker.name} ${coworker.surname}`;
                });
            }}
            repository={({ search }) => {
              return new CoworkerCrmRepository()
                .list({ clinicId: clinicId, search })
                .then((coworkers) => {
                  const auxCoworkers = coworkers.items.map((coworker) => ({
                    label: `${coworker.name} ${coworker.surname}`,
                    value: coworker.id,
                  }));

                  return auxCoworkers;
                });
            }}
          />
        </FieldContainer>
      ) : (
        clinicId && (
          <ActionComponent
            onPress={() => setActorId(undefined)}
            prefix="access.bulletins.create.steps.actor.form.title"
            bubble={actorName}
          />
        )
      )}

      {clinicId && actorId && (
        <FormComponent<BulletinAccessType>
          padding
          prefix="access.bulletins.create.form"
          repository={{
            send: (props: { item: Partial<BulletinAccessType> }) => {
              return new BulletinAccesRepository()
                .create({
                  item: {
                    ...props.item,
                    clinicId: clinicId,
                    actorId: actorId,
                  },
                })
                .then(() => {
                  properties.navigation.goBack();
                });
            },
          }}
          fields={[
            {
              type: 'text',
              name: 'description',
              description: true,
              required: true,
              lines: 6,
              validations: ['description'],
            },
            {
              type: 'image',
              name: 'image',
              description: true,
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
