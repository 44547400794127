import { LeadsRoutes } from './leads.routes';
import { ListLeadsScreen } from './screens/list/list.leads.screen';
import { CreateLeadScreen } from './screens/create/create.lead.screen';
import { UpdateLeadScreen } from './screens/update/update.lead.screen';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { CreateActionScreen } from '../actions/screens/create/create.action.screen';
import { UpdateActionScreen } from '../actions/screens/update/update.action.screen';
import { DisableLeadsScreen } from './screens/disable/disable.leads.screen';
import { EnableLeadsScreen } from './screens/enable/enable.leads.screen';
import { DisableActionScreen } from '../actions/screens/disable/disable.action.screen';
import { EnableActionScreen } from '../actions/screens/enable/enable.action.screen';
import { UpdateCampaignScreen } from '../campaings/screens/update/update.campaign.screen';
import { DisableCampaignScreen } from '../campaings/screens/disable/disable.campaign.screen';
import { EnableCampaignScreen } from '../campaings/screens/enable/enable.campaign.sceen';
import { CreateManagerCampaignScreen } from '../campaings/screens/update/parts/create.manager.campaign.screen';
import { DeleteManagerCampaignPart } from '../campaings/screens/update/parts/delete.manager.campaign.update.part';
import { CreateParticipantCampaignScreen } from '../campaings/screens/update/parts/create.participant.campaign.screen';
import { DeleteParticipantCampaignPart } from '../campaings/screens/update/parts/delete.participant.campaign.screen';
import { UpdateActionNextResultPart } from '../actions/screens/update/parts/update.action.nextResult.part';
import { DeleteLeadScreen } from './screens/delete/delete.lead.screen';
import { CreateAlertScreen } from '../alerts/screens/create/create.alert.screen';
import { UpdateAlertScreen } from '../alerts/screens/update/update.alert.screen';
import { DeleteAlertScreen } from '../alerts/screens/delete/delete.alert.screen';
import { UpdateActionDisableLeadPart } from '../actions/screens/update/parts/update.action.disableLead.part';
import { UpdateActionCreateTreatmentScreen } from '../actions/screens/update/parts/update.action.createTreatment.part';
import { UpdateActionAddSpecialistScreen } from '../actions/screens/update/parts/update.action.addSpecialist.part';
import { UpdateActionTemplatePart } from '../actions/screens/update/parts/update.action.template.part';
import { ImportLeadsScreen } from './screens/import/import.leads.screen';
import { DeleteLeadImportScreen } from './screens/deleteImport/delete.lead.import.screen';

const Navigation = NavigationComponent<LeadsRoutes>();
export function LeadsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListLeadsScreen} />
      <Navigation.Screen name="create" component={CreateLeadScreen} />
      <Navigation.Screen name="leadUpdate" component={UpdateLeadScreen} />
      <Navigation.Screen name="disable" component={DisableLeadsScreen} />
      <Navigation.Screen name="enable" component={EnableLeadsScreen} />
      <Navigation.Screen name="delete" component={DeleteLeadScreen} />
      <Navigation.Screen name="import" component={ImportLeadsScreen} />
      <Navigation.Screen name="deleteImport" component={DeleteLeadImportScreen} />
      {/**Campaing routes */}
      <Navigation.Screen name="campaignUpdate" component={UpdateCampaignScreen} />
      <Navigation.Screen name="campaignDisable" component={DisableCampaignScreen} />
      <Navigation.Screen name="campaignEnable" component={EnableCampaignScreen} />
      <Navigation.Screen name="createManager" component={CreateManagerCampaignScreen} />
      <Navigation.Screen name="deleteManager" component={DeleteManagerCampaignPart} />
      <Navigation.Screen name="createParticipant" component={CreateParticipantCampaignScreen} />
      <Navigation.Screen name="deleteParticipant" component={DeleteParticipantCampaignPart} />

      {/**Action routes */}
      <Navigation.Screen name="actionCreate" component={CreateActionScreen} />
      <Navigation.Screen name="actionUpdate" component={UpdateActionScreen} />
      <Navigation.Screen name="actionDisable" component={DisableActionScreen} />
      <Navigation.Screen name="actionEnable" component={EnableActionScreen} />
      <Navigation.Screen name="actionNextResultUpdate" component={UpdateActionNextResultPart} />
      <Navigation.Screen name="actionDisableLead" component={UpdateActionDisableLeadPart} />
      <Navigation.Screen name="alertCreate" component={CreateAlertScreen} />
      <Navigation.Screen name="alertUpdate" component={UpdateAlertScreen} />
      <Navigation.Screen name="alertDelete" component={DeleteAlertScreen} />
      <Navigation.Screen
        name="updateActionAddSpecialist"
        component={UpdateActionAddSpecialistScreen}
      />
      <Navigation.Screen
        name="updateActionCreateTreatment"
        component={UpdateActionCreateTreatmentScreen}
      />
      <Navigation.Screen name="updateActionTemplate" component={UpdateActionTemplatePart} />
    </Navigation.Navigator>
  );
}
