// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const ClockStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'row',
    left: 0,
    bottom: 0,
    margin: 50,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  cornerContainer: {
    bottom: 'auto',
    left: 'auto',
    right: 0,
    top: 0,
  },
  hourText: {
    color: 'white',
    fontSize: 100,
    lineHeight: 100,
  },
  minuteText: {
    color: 'white',
    fontSize: 100,
    lineHeight: 100,
  },
  littleTimeText: {
    fontSize: 50,
    lineHeight: 50,
  },
  separatorText: {
    color: 'white',
    fontSize: 30,
  },
  littleText: {
    fontSize: 10,
  },
  locationText: {
    color: 'white',
    fontSize: 20,
    lineHeight: 20,
    position: 'absolute',
    textTransform: 'uppercase',
    bottom: -20,
    letterSpacing: 3,
    left: 0,
  },
});
