import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ServicesRoutes } from '../../service.routes';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';

export function DisableServiceScreen(properties: {
  navigation: NavigationProp<ServicesRoutes>;
  route: RouteProp<ServicesRoutes, 'serviceDisable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.services.disable">
      <FormComponent
        prefix="crm.services.disable.form"
        padding
        repository={{
          send: () => {
            return new ServiceCrmRepository()
              .disable({
                serviceId: properties.route.params.serviceId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
