// Modules
import { StyleSheet } from 'react-native';

/**
 * WidgetStyles Styles
 * @constant {StyleSheet} WidgetStyles
 */
export const WidgetStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    borderBottomWidth: 1,
    padding: 10,
    gap: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
  iconContainer: {
    height: 30,
    width: 30,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(237, 53, 145)',
    borderWidth: 4,
    borderColor: 'rgba(237, 53, 145, 0.3)',
    // outlineStyle: '4px solid rgba(237, 53, 145, 0.3)',
    borderRadius: 100,
  },
  icon: {
    color: '#fff',
    fontSize: 18,
    marginTop: -2,
  },
  titleText: {
    color: '#fff',
    fontSize: 16,
    marginLeft: 10,
  },
  counterContainer: {
    marginLeft: 'auto',
    flexDirection: 'row',
  },
  counterTextContainer: {
    height: 30,
    width: 30,
    borderRadius: 100,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  counterText: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 30,
  },
  angleDownContainer: {
    height: 30,
    width: 30,
    marginLeft: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  },
  angleDownIcon: {
    color: '#FFFFFF8D',
    fontSize: 15,
    marginTop: -2,
  },

  content: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    flex: 1,
    overflow: 'hidden',
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    borderBottomWidth: 1,
  },
});
