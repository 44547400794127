import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { PaymentCrmType } from '@human/crm/types/payment.crm.type';
import { PaymentCrmRepository } from '@human/crm/repositories/payment/payment.repository';
import { PatientsRoutes } from '../../../../../patient.routes';
import { IntroductionComponent } from '@components/introduction/introduction.component';
import { ButtonComponent } from '@components/button/button.component';

export function PaymentsUpdateReceiptPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'receiptUpdate'>;
}): TabType {
  return {
    name: 'payments',
    icon: 'money-bill',
    content: () => {
      return (
        <>
          <TableComponent<PaymentCrmType, []>
            key={'attempts'}
            prefix={'crm.patients.update.tabs.receipts.update.tabs.payments'}
            suffix={'list'}
            pagination={true}
            search={false}
            limit={10}
            repository={(settings) => {
              return new PaymentCrmRepository().list({
                receiptId: properties.route.params.receiptId,
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
                page: settings.page,
                limit: settings.limit,
                order: settings.order || 'created',
                direction: settings.direction,
              });
            }}
            columns={[
              {
                type: 'icon',
                icon: 'pen',
                onPress: (row) =>
                  properties.navigation.navigate('paymentUpdate', {
                    receiptId: properties.route.params.receiptId,
                    patientId: properties.route.params.patientId,
                    paymentId: row.id,
                    clinicId: properties.route.params.clinicId,
                    level: 3,
                  }),
              },
              {
                name: 'payment.price',
                type: 'number',
                width: 100,
                onRender: (row) => row.price,
              },
              {
                type: 'text',
                name: 'payment.clinic',
                width: 100,
                onRender: (row) => row.clinic.name,
              },
              {
                type: 'text',
                name: 'payment.name',
                width: 100,
                onRender: (row) => `${row.contact.name} ${row.contact.surname}`,
              },
              {
                type: 'text',
                name: 'payment.created',
                width: 100,
                onRender: (row) => `${row.created}`,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="crm.patients.update.tabs.receipts.update.tabs.payments.list.create"
            onPress={() =>
              properties.navigation.navigate('paymentCreate', {
                patientId: properties.route.params.patientId,
                receiptId: properties.route.params.receiptId,
                level: properties.route.params.level + 1,
                clinicId: properties.route.params.clinicId,
              })
            }
          />
        </>
      );
    },
  };
}
