import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class TemplateParamsAccessRepository extends AuthorizedRepository {
  async create(properties: {
    item: Partial<{ templateId: string; templateKeyId: string; order: number }>;
  }) {
    return this.fetch('templateParam/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async delete(properties: { templateId: string; templateKeyId: string }): Promise<void> {
    return this.fetch('templateParam/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async list(properties: {
    page?: number;
    limit?: number;
    search?: string;
    templateId: string;
  }): Promise<{
    items: {
      templateKeyId: string;
      templateKeyName: string;
      order: number;
    }[];
    total: number;
  }> {
    return this.fetch('templateParam/find', {
      params: properties,
      method: 'GET',
    }).then((data) => ({
      items: data.items.map(
        (template: { templateKeyId: string; templateKeyName: string; order: number }) => ({
          templateKeyId: template.templateKeyId,
          templateKeyName: template.templateKeyName,
          order: template.order,
        })
      ),
      total: data.total,
    }));
  }
}
