import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { LeadsRoutes } from '../../../leads.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ButtonComponent } from '@components/button/button.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';

export function ActionsUpdateLeadPart(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadUpdate'>;
}): TabType {
  const { t } = useTranslation();

  const columns: ColumnProperties<ActionCrmType, []>[] = [
    {
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'actionDisable' : 'actionEnable', {
          actionId: row.id,
          clinicId: properties.route.params.clinicId,
          leadId: properties.route.params.id,
        });
      },
    },
    {
      type: 'text',
      name: 'activity',
      width: 169,
      onRender: (row) => row.activityName,
    },
    {
      type: 'text',
      name: 'responsible',
      width: 169,
      onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
    },
    {
      type: 'taskStatus',
      name: 'resultId',
      width: 150,
      background: () => 'white',
      color: (row) => (row.resultId ? 'rgba(186, 224, 144, 0.40)' : '#FCEEE2'),
      textColor: (row) => (row.resultId ? 'rgb(40, 167, 69)' : '#f0ad4e'),
      onRender: (row) =>
        row.resultId ? t('common.taskProgress.COMPLETED') : t('common.taskProgress.PENDING'),
    },
    {
      type: 'text',
      name: 'resultName',
      width: 200,
      onRender: (row) => row.resultName ?? t('common.tasks.noResult'),
      color: (row) => (row.resultName ? 'black' : 'grey'),
    },
    {
      type: 'date',
      name: 'started',
      renderDate: (row) => row.started,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.tasks.editor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'pen',
      onPress: (row) => {
        properties.navigation.navigate('actionUpdate', {
          activityId: row.activityId,
          leadId: properties.route.params.id,
          contactId: properties.route.params.contactId,
          actionId: row.id,
          clinicId: properties.route.params.clinicId,
          level: 1,
        });
      },
    });
  }

  return {
    name: 'actions',
    icon: 'tasks',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<ActionCrmType, []>
            key={'actions'}
            prefix={'crm.tasks.list'}
            suffix={'list'}
            completed
            scrollable
            repository={(settings) =>
              new ActionCrmRepository().findByLead({
                completed: settings.completed,
                clinicId: properties.route.params.clinicId,
                leadId: properties.route.params.id,
                direction: settings.direction,
                search: settings.search,
                page: settings.page,
                limit: settings.limit,
              })
            }
            columns={columns}
          />
          {hasPermissionUtilty({
            name: 'crm.tasks.creator',
          }) && (
            <ButtonComponent
              icon="plus"
              prefix="crm.leads.update.actions.add"
              onPress={() => {
                properties.navigation.navigate('actionCreate', {
                  leadId: properties.route.params.id,
                  clinicId: properties.route.params.clinicId,
                  level: 1,
                });
              }}
            />
          )}
        </ScrollView>
      );
    },
  };
}
