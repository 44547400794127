import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';

import { View } from 'react-native';
import { PatientDetailStyles } from './patientDetail.styles';
import { PatientDetailProperties } from './patientDetail.properties';

export function PatientDetailScreen(properties: PatientDetailProperties) {
  return (
    <View style={PatientDetailStyles.container}>
      <View style={PatientDetailStyles.section}>
        <View style={PatientDetailStyles.row}>
          <IconComponent name="envelope" iconStyle={PatientDetailStyles.icon} />
          <TextComponent
            text={
              properties.patient.contactEmails.length > 0
                ? properties.patient.contactEmails[0].email
                : ''
            }
            style={PatientDetailStyles.text}
            bold
          />
        </View>
        <View style={PatientDetailStyles.row}>
          <IconComponent name="phone-volume" iconStyle={PatientDetailStyles.icon} />
          <TextComponent
            text={
              properties.patient.contactPhones.length > 0
                ? `(${properties.patient.contactPhones[0].prefix}) ${properties.patient.contactPhones[0].phone}`
                : ''
            }
            style={PatientDetailStyles.text}
            bold
          />
        </View>
      </View>
      <View style={PatientDetailStyles.mainSection}>
        <View style={PatientDetailStyles.gapSection}>
          <TextComponent
            text={'crm.patients.update.actions.patient.fields.clinic'}
            style={PatientDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={properties.patient.clinicName}
            style={PatientDetailStyles.text}
            bold
          />
        </View>
        <View style={PatientDetailStyles.gapSection}>
          <TextComponent
            text={'crm.patients.update.actions.patient.fields.contactTime'}
            style={PatientDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={`common.contactTime.${properties.patient.contactTime}`}
            style={PatientDetailStyles.text}
            translate
            bold
          />
        </View>
        <View style={PatientDetailStyles.gapSection}>
          <TextComponent
            text={'crm.patients.update.actions.patient.fields.campaign'}
            style={PatientDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={properties.patient.campaignName}
            style={PatientDetailStyles.text}
            bold
          />
        </View>
        <View style={PatientDetailStyles.gapSection}>
          <TextComponent
            text={'crm.patients.update.actions.patient.fields.originCampaign'}
            style={PatientDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={properties.patient.originCampaignName}
            style={PatientDetailStyles.text}
            bold
          />
        </View>
        <View style={PatientDetailStyles.gapSection}>
          <TextComponent
            text={'crm.patients.update.actions.patient.fields.consent'}
            style={PatientDetailStyles.label}
            bold
            translate
          />
          <View style={PatientDetailStyles.row}>
            {properties.patient.warrantyAgreement && (
              <View style={PatientDetailStyles.tag}>
                <TextComponent
                  text={'crm.patients.update.actions.patient.fields.warrantyAgreement'}
                  style={PatientDetailStyles.text}
                  translate
                />
              </View>
            )}
            {properties.patient.consentCheck && (
              <View style={PatientDetailStyles.tag}>
                <TextComponent
                  text={'crm.patients.update.actions.patient.fields.consentCheck'}
                  style={PatientDetailStyles.text}
                  translate
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
