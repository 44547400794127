import { StyleSheet } from 'react-native';

export const CampaignLeadCostDetailStyles = StyleSheet.create({
  container: {
    flex: 2,
    backgroundColor: '#1F242C',
    padding: 20,
    borderRadius: 6,
  },
  mobileContainer: {
    flex: 1,
    backgroundColor: '#1F242C',
    padding: 20,
    borderRadius: 6,
  },
  dataContainer: {
    marginVertical: 10,
  },
  title: {
    fontSize: 11,
    color: '#999999',
    fontWeight: '300',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    color: '#999999',
    fontWeight: '300',
  },
  clinic: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: '400',
  },
  campaign: {
    fontSize: 20,
    color: '#FFFFFF',
    fontWeight: '600',
  },
  budget: {
    fontSize: 20,
    color: '#FFFFFF',
    fontStyle: 'italic',
  },
});
