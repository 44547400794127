import { StyleSheet } from 'react-native';

export const ToggleStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10,
  },
  text: {
    color: '#888888',
  },
  toggleContainer: {
    borderRadius: 10,
    padding: 2,
    width: 35,
    height: 20,
    justifyContent: 'center',
    position: 'relative',
  },
  toggleContainerActive: {
    backgroundColor: 'rgb(255, 222, 238)',
  },
  toggleContainerInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: 10,
    position: 'absolute',
  },
  circleActive: {
    backgroundColor: 'rgba(237, 53, 145, 1)',
    right: 2,
  },
  circleInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.42)',
    left: 2,
  },
});
