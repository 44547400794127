import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TransferLeadRoutes } from '../../transferLead.routes';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { statusColorMap } from '@constants/entryStatus.constants';

export function ListTransferLeadsScreen(properties: {
  navigation: NavigationProp<TransferLeadRoutes>;
  route: RouteProp<TransferLeadRoutes, 'list'>;
}) {
  const [selectedItems, setSelectedItems] = useState<{ id: string; clinicId: string }[]>([]);

  const { t } = useTranslation();

  const columns: ColumnProperties<LeadCrmType, []>[] = [
    {
      type: 'selectable',
      selected: (row) => selectedItems.some((item) => item.id === row.id),
      onSelect: (item) => {
        setSelectedItems((items) =>
          items.some((selectedItem) => selectedItem.id === item.id)
            ? items.filter((selectedItem) => selectedItem.id !== item.id)
            : [...items, item]
        );
      },
    },
    {
      type: 'text',
      name: 'name',
      onRender: (row) => row.contactName,
      width: 200,
    },
    {
      type: 'taskStatus',
      onRender: (row) => t(`common.leadEntryStatus.${row.entryStatus}`),
      width: 150,
      color: (row) => statusColorMap[row.entryStatus].backgroundColor,
      background: (row) => statusColorMap[row.entryStatus].textColor,
      textColor: (row) => statusColorMap[row.entryStatus].textColor,
    },
    {
      type: 'clinic',
      name: 'clinicName',
      onRender: (row) => row.clinicName,
      width: 200,
    },
    {
      type: 'text',
      name: 'campaignName',
      width: 200,
      onRender: (row) => row.campaignName,
    },
    {
      type: 'text',
      name: 'phones',
      width: 200,
      // last phone added to the contact
      onRender: (row) =>
        row.phones && row.phones.length > 0
          ? `${row.phones[row.phones.length - 1].prefix} ${row.phones[row.phones.length - 1].phone}`
          : '',
    },
    {
      type: 'text',
      name: 'emails',
      width: 200,
      // last email added to the contact
      onRender: (row) =>
        row.emails && row.emails.length > 0 ? `${row.emails[row.emails.length - 1].email}` : '',
    },
    {
      type: 'date',
      filter: 'range',
      max: new Date(new Date().setDate(new Date().getDate() + 1)),
      // min: new Date(),
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  return (
    <SceneComponent icon="sync-alt" prefix="crm.transferLead.list">
      <TableComponent
        repository={(settings) =>
          new LeadCrmRepository().list({
            direction: settings.direction,
            order: settings.order || 'created',
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            dates: settings.dates,
            planned: settings.toggle,
          })
        }
        actions={[
          {
            icon: 'sync-alt',
            label: 'transfer.title',
            onPress: () => {
              if (selectedItems.length === 0) {
                alert(t('crm.transferLead.list.required'));
                return;
              }
              properties.navigation.navigate('confirmTransfer', {
                leadIds: selectedItems.map((item) => item.id),
                clinicIds: selectedItems.map((item) => item.clinicId),
                onSuccess: () => {
                  setSelectedItems([]);
                },
              });
            },
          },
        ]}
        scrollable
        avoidRefresh
        onSelectAllClick={(items) => {
          setSelectedItems(items);
        }}
        columns={columns}
        prefix={'crm.transferLead.list'}
        suffix={'list'}
      />
    </SceneComponent>
  );
}
