import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function DeleteLanguageScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'removeUserLanguage'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash"
      prefix="access.users.update.languages.delete"
    >
      <FormComponent
        prefix="access.users.update.languages.delete.form"
        padding
        repository={{
          send: () => {
            return new UserAccessRepository()
              .removeLanguage({
                languageId: properties.route.params.languageId,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
