import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { UserAccessType } from '@human/access/types/user.access.type';
import { ManagerCrmType } from '@human/crm/types/manager.crm.type';

export class ManagerRepository extends AuthorizedRepository {
  async list(properties: {
    campaignId: string;
  }): Promise<{ items: ManagerCrmType[]; total: number }> {
    return this.fetch('manager/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          userId: item.userId,
          campaignId: item.campaignId,
          userName: item.userName,
          userSurname: item.userSurname,
        })),
        total: data.total,
      };
    });
  }

  async listPossible(properties: {
    campaignId: string;
    search?: string;
  }): Promise<{ items: UserAccessType[]; total: number }> {
    return this.fetch('manager/findPossible', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          userId: item.userId,
          campaignId: item.campaignId,
          name: item.name,
          surname: item.surname,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { item: Partial<ManagerCrmType> }): Promise<ManagerCrmType> {
    return this.fetch('manager/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async delete(properties: { campaignId: string; userId: string }): Promise<void> {
    return this.fetch('manager/delete', {
      method: 'DELETE',
      params: properties,
    });
  }
}
