// Modules
import { StyleSheet } from 'react-native';

/**
 * Errors Styles
 * @constant {StyleSheet} ErrorsStyles
 */
export const ErrorStyles = StyleSheet.create({
  container: {
    borderRadius: 5,
    marginTop: 5,
    borderWidth: 1,
    backgroundColor: '#FFF4F8',
    borderColor: '#00000016',
  },
  errorContainer: {
    padding: 10,
    borderBottomColor: '#00000042',
    // borderStyle: 'dashed',
    zIndex: -1,
    paddingVertical: 6,
    flexDirection: 'row',
  },
  cornerTopError: {
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
  },
  middleTopError: {
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
  },
  cornerBottomError: {
    borderBottomEndRadius: 5,
    borderBottomStartRadius: 5,
    borderBottomWidth: 0,
  },
  middleBottomError: {
    borderBottomEndRadius: 0,
    borderBottomStartRadius: 0,
    borderBottomWidth: 1,
  },
  circle: {
    backgroundColor: 'rgb(237, 53, 145)',
    borderRadius: 100,
    height: 6,
    width: 16,
    position: 'absolute',
    left: -8,
    top: '50%',
    marginTop: -3,
  },
  errorText: {
    color: '#4C1D2F',
    paddingLeft: 10,
    fontSize: 12,
  },
});
