import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';

import { NavigationProp, RouteProp } from '@react-navigation/native';

export function DeleteProgramPhaseScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programPhaseDelete'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.programs.update.phases.delete">
      <FormComponent
        prefix="crm.programs.update.phases.delete.form"
        padding
        repository={{
          send: () => {
            return new ProgramPhaseCrmRepository()
              .delete({
                programId: properties.route.params.programId,
                phaseId: properties.route.params.phaseId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
