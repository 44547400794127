// Modules
import { StyleSheet } from 'react-native';

/**
 * RowStyles Styles
 * @constant {StyleSheet} RowStylesStyles
 */
export const RowStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 20,
  },
  mobileContainer: {
    flexDirection: 'column',
    gap: 20,
  },
});
