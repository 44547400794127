import { OrderTableType } from '@components/table/table.properties';
import { MultipleBadgeColumnImplementation } from './multipleBadge.column.properties';
import { MultipleBadgeColumnStyles } from './multipleBadge.column.styles';
import { View } from 'react-native';
import { TextComponent } from '@components/text/text.component';

export function MultipleBadgeColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: MultipleBadgeColumnImplementation<ITEM, ORDER>
) {
  const texts = properties.onRender?.(properties.item);
  const color = properties.color?.(properties.item);
  const background = properties.background?.(properties.item);

  const renderMoreItems = (left: number) => {
    return (
      <View style={[MultipleBadgeColumnStyles.itemsLeftContainer, { backgroundColor: background }]}>
        <TextComponent
          lines={1}
          bold
          text={`+${left}`}
          style={[
            MultipleBadgeColumnStyles.itemsLeftText,
            {
              color: color ?? MultipleBadgeColumnStyles.text.color,
            },
          ]}
        />
      </View>
    );
  };

  return (
    <View style={MultipleBadgeColumnStyles.container}>
      <View style={MultipleBadgeColumnStyles.bubblesContainer}>
        {texts?.map((text, index) => (
          <>
            {index < 2 && (
              <View style={[MultipleBadgeColumnStyles.bubble, { backgroundColor: background }]}>
                <TextComponent
                  lines={1}
                  bold
                  text={`${text}`}
                  style={[
                    MultipleBadgeColumnStyles.text,
                    {
                      color: color ?? MultipleBadgeColumnStyles.text.color,
                    },
                  ]}
                />
              </View>
            )}
          </>
        ))}
      </View>
      {texts && texts.length > 2 && renderMoreItems(texts.length - 2)}
    </View>
  );
}
