import { ValidationCheckboxInput } from "./checkbox.properties";

export function CheckboxInputValidations(properties: {
    validations: ValidationCheckboxInput[],
    required?: boolean,
    value?: boolean,

}) {
    const errors: string[] = [];
    if (properties.required && !properties.value) {
        errors.push('required')
    }

    properties.validations.forEach((validation) => {
        if (validation === 'true' && !properties.value) {
            errors.push('required')
        }
    })

    return errors
}