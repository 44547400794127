import { UnauthorizedRepository } from '@core/repository/unauthorized.repository.core';
import { PermissionType } from 'types/permission.type';

export class SessionRepository extends UnauthorizedRepository {
  async login(properties: {
    item: Partial<{
      email: string;
      signature: string;
    }>;
  }): Promise<{
    id: string;
    accessToken: string;
    expires: Date;
    permissions: PermissionType;
    sessionId: string;
    idioms: string[];
  }> {
    return this.fetch('session/login', {
      method: 'POST',
      body: properties.item,
    }).then((response) => {
      if (response.status >= 400) {
        throw new Error('Invalid credentials');
      }
      return response;
    });
  }
}
