import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { DocumentCrmType } from '@human/crm/types/document.crm.type';
import { DocumentCrmRepository } from '@human/crm/repositories/document/document.repository';
import { useState } from 'react';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { FileUploaderComponent } from '@components/fileUploader/fileUploader.component.web';

export function DocumentUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  const [reload, setReload] = useState<boolean>();
  const columns: ColumnProperties<DocumentCrmType, []>[] = [
    {
      type: 'text',
      name: 'documentName',
      onRender: (item) => item.documentName,
      width: 200,
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (item) => item.created,
    },
    {
      type: 'text',
      name: 'size',
      onRender: (item) => (item.size / 1000).toFixed(2) + 'Kb',
      width: 200,
    },
    {
      type: 'text',
      name: 'type',
      onRender: (item) => item.type,
      width: 200,
    },
    {
      type: 'element',
      width: 200,
      name: 'vRepoUploaded',
      renderIcon: (row) => (row.vRepoUploaded ? 'check-circle' : 'times-circle'),
      renderColor: (row) => (row.vRepoUploaded ? 'green' : 'red'),
    },
  ];

  columns.unshift({
    type: 'icon',
    icon: 'trash',
    renderColor: () => 'red',
    onPress: (row) => {
      properties.navigation.navigate('patientDocumentDelete', {
        patientId: properties.route.params.patientId,
        clinicId: properties.route.params.clinicId,
        documentId: row.id,
      });
    },
  });
  // }

  return {
    name: 'document',
    icon: 'book',
    content: () => (
      <>
        <FileUploaderComponent
          fileType="application/pdf"
          onSubmit={async (file) => {
            new DocumentCrmRepository()
              .create({
                pdfFile: file,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
              })
              .then(() => {
                showSuccess();
              });
          }}
        />
        <TableComponent<DocumentCrmType, []>
          reload={
            new Promise((resolve) => {
              if (reload) {
                setReload(false);
                resolve();
              }
            })
          }
          scrollable
          prefix="crm.patients.update.tabs.treatment.document"
          suffix="list"
          repository={(setting) =>
            new DocumentCrmRepository().listByPatient({
              order: 'created',
              direction: setting.direction,
              page: setting.page,
              limit: setting.limit,
              search: setting.search,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
            })
          }
          columns={columns}
        />
      </>
    ),
  };
}
