import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TransferTaskRoutes } from '../../transferTask.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { IconType } from '@components/icon/icon.types';
import { t } from 'i18next';
import { getTimeDiff } from '@utils/timeDiff/getTimeDiff.utility';
import { useEffect, useState } from 'react';

export function ListTransferTasksScreen(properties: {
  navigation: NavigationProp<TransferTaskRoutes>;
  route: RouteProp<TransferTaskRoutes, 'list'>;
}) {
  const [selectedItems, setSelectedItems] = useState<{ id: string; clinicId: string }[]>([]);
  const [clinicId, setClinicId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedItems.length === 1) {
      setClinicId(selectedItems[0].clinicId);
    }
  }, [selectedItems]);

  const columns: ColumnProperties<
    {
      id: string;
      status: boolean;
      contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
      subject: string;
      activityId: string;
      activityName: string;
      activityColor: string;
      activityIcon: string;
      contactSurname: string;
      phone: string;
      leadId: string;
      patientId?: string;
      clinicId: string;
      clinicName: string;
      contactId: string;
      contactName: string;
      responsibleName: string;
      responsibleSurname: string;
      resultId: string;
      resultName: string;
      started: Date;
      finished: Date;
      created: Date;
      selfie: string;
    },
    []
  >[] = [
    {
      type: 'selectable',
      selected: (row) => selectedItems.some((item) => item.id === row.id),
      onSelect: (item) => {
        setSelectedItems((items) =>
          items.some((selectedItem) => selectedItem.id === item.id)
            ? items.filter((selectedItem) => selectedItem.id !== item.id)
            : [...items, item]
        );
      },
    },
    {
      type: 'iconText',
      name: 'activity',
      color: (row) => row.activityColor,
      text: (row) => row.activityName,
      icon: (row) => row.activityIcon as IconType,
      width: 350,
    },
    {
      type: 'taskStatus',
      name: 'resultId',
      width: 150,
      background: () => 'white',
      color: (row) => (row.resultId ? 'rgba(186, 224, 144, 0.40)' : '#FCEEE2'),
      textColor: (row) => (row.resultId ? 'rgb(40, 167, 69)' : '#f0ad4e'),
      onRender: (row) =>
        row.resultId ? t('common.taskProgress.COMPLETED') : t('common.taskProgress.PENDING'),
    },
    {
      type: 'text',
      name: 'resultName',
      width: 200,
      onRender: (row) => row.resultName ?? t('common.tasks.noResult'),
      color: (row) => (row.resultName ? 'black' : 'grey'),
    },
    {
      type: 'text',
      name: 'contact',
      onRender: (row) => `${row.contactName} ${row.contactSurname}`,
      width: 200,
    },
    {
      type: 'text',
      name: 'phone',
      onRender: (row) => row.phone,
      width: 200,
    },
    {
      type: 'contactTime',
      name: 'contactTime',
      width: 150,
      onRender: (row) => row.contactTime,
    },
    {
      type: 'clinic',
      name: 'clinic',
      onRender: (row) => row.clinicName,
      width: 200,
    },
    {
      type: 'text',
      name: 'subject',
      onRender: (row) => row.subject ?? '',
      width: 250,
    },
    {
      type: 'user',
      name: 'user',
      onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
      onRenderSelfie: (row) => row.selfie,
      width: 200,
    },
    {
      type: 'date',
      name: 'started',
      renderDate: (row) => row.started,
    },
    {
      type: 'text',
      name: 'finished',
      width: 200,
      onRender: (row) => getTimeDiff({ started: row.started, finished: row.finished }),
    },
  ];

  return (
    <SceneComponent icon="sync-alt" prefix="crm.transferTask.list" multiWorkspace>
      <TableComponent<
        {
          id: string;
          status: boolean;
          contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
          subject: string;
          activityId: string;
          activityName: string;
          activityColor: string;
          activityIcon: string;
          contactSurname: string;
          phone: string;
          leadId: string;
          patientId?: string;
          clinicId: string;
          clinicName: string;
          contactId: string;
          contactName: string;
          responsibleName: string;
          responsibleSurname: string;
          resultId: string;
          resultName: string;
          started: Date;
          finished: Date;
          created: Date;
          selfie: string;
        },
        []
      >
        actions={[
          {
            icon: 'sync-alt',
            label: 'transfer.title',
            onPress: () => {
              properties.navigation.navigate('confirmTransfer', {
                clinicId: clinicId as string,
                actionsIds: selectedItems.map((item) => item.id),
                onSuccess: () => {
                  setSelectedItems([]);
                  setClinicId(undefined);
                },
              });
            },
          },
        ]}
        //ESTO ES UNA ÑAPA
        onSelectAllClick={(items) => {
          //Se selecciona la clinica de la tarea seleccionada
          //Hay que discutirlo con el equipo para buscar otra manera
          setClinicId(items.length > 0 ? items[0].clinicId : undefined);
          setSelectedItems(items);
        }}
        avoidRefresh
        completed
        dates
        prefix="crm.transferTask.list"
        scrollable
        suffix="list"
        repository={(settings) =>
          new ActionCrmRepository().list({
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            completed: settings.completed,
            ...(settings.dates !== undefined && {
              dates: { start: settings.dates.start, end: settings.dates.end },
            }),
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
