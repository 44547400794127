import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ItemByDepartmentYearsComparativeType } from '../../types/itemByDepartmentYearsComparative.type';

export class InterventionBoardRepository extends AuthorizedRepository {
  expectedInterventionsDepartmentsComparative = async (properties: {
    clinicId: string;
    year: number;
    complexity?: string;
  }): Promise<ItemByDepartmentYearsComparativeType[]> => {
    return this.fetch('board/intervention/expectedInterventionsYearDepartment', {
      method: 'GET',
      params: properties,
    });
  };

  expectedInterventionsByDepartmentAndMonth = async (properties: {
    clinicId: string;
    month: number;
    languageId: string;
    year: number;
  }): Promise<{
    total: number;
    total_loss: number;
    total_first_attempt: number;
  }> => {
    return this.fetch('board/intervention/expectedInterventionsByDepartmentAndMonth', {
      method: 'GET',
      params: properties,
    });
  };
}
