import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { TabType } from '@components/tabs/tabs.types';
import { CrmSections } from '@human/crm/crm.sections';
import { FormComponent } from '@components/form/form.component';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { UserActivityAssignmentAccessRepository } from '@human/access/repositories/userActivityAssignment/userActivityAssignment.repository';

export function ActivityUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
  showSuccess: () => void;
}): TabType {
  return {
    name: 'activity',
    icon: CrmSections.activities.icon,
    content: () => {
      return (
        <FormComponent<{
          activityId: string;
        }>
          prefix="access.users.update.activity.form"
          repository={{
            get: () => {
              return new UserActivityAssignmentAccessRepository().pick({
                userId: properties.route.params.userId,
              });
            },
            send: (data) => {
              return new UserActivityAssignmentAccessRepository()
                .update({
                  userId: properties.route.params.userId,
                  activityId: data.item.activityId,
                })
                .then(() => properties.showSuccess());
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'activityId',
              required: true,
              pick: ({ id }) => {
                return new ActivityCrmRepository()
                  .pick({
                    activityId: id,
                  })
                  .then((data) => data.name);
              },
              repository: async () =>
                new ActivityCrmRepository()
                  .list({
                    direction: 'asc',
                    limit: 100,
                    order: 'name',
                    page: 0,
                  })
                  .then((data) =>
                    data.items.map((activity) => ({
                      value: activity.id,
                      label: activity.name,
                    }))
                  ),
            },
          ]}
        />
      );
    },
  };
}
