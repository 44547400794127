import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TableComponent } from '@components/table/table.component';
import { ButtonComponent } from '@components/button/button.component';
import { TreatmentsRoutes } from '../../../treatment.routes';
import { ProcedureCrmRepository } from '@human/crm/repositories/procedure/procedure.repository';
import { ProcedureCrmType } from '@human/crm/types/procedure.crm.type';
import { ScrollView } from 'react-native';

export function UpdateTreatmentProceduresPart(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'updateTreatment'>;
}): TabType {
  return {
    name: 'procedures',
    icon: 'procedures',
    content: () => (
      <ScrollView>
        <TableComponent<ProcedureCrmType, []>
          key={'procedures'}
          prefix={'crm.treatments.update.tabs.procedures'}
          header={false}
          suffix="list"
          repository={(settings) =>
            new ProcedureCrmRepository().list({
              treatmentId: properties.route.params.treatmentId,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              direction: settings.direction,
              limit: settings.limit,
              page: settings.page,
              search: settings.search,
            })
          }
          columns={[
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('updateProcedure', {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  treatmentId: properties.route.params.treatmentId,
                  techniqueId: row.techniqueId,
                  level: 1,
                });
              },
            },
            {
              type: 'icon',
              icon: 'trash',
              onPress: (row) => {
                properties.navigation.navigate('deleteProcedure', {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  treatmentId: properties.route.params.treatmentId,
                  techniqueId: row.techniqueId,
                  level: 1,
                });
              },
            },
            {
              name: 'techniqueName',
              type: 'text',
              onRender: (row) => row.techniqueName,
              width: 200,
            },
            {
              name: 'price',
              type: 'price',
              width: 140,
              onRender: (row) => {
                return row.price;
              },
              onCurrency: (row) => {
                return '€';
              },
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.treatments.update.tabs.procedures.add"
          onPress={() => {
            properties.navigation.navigate('createProcedure', {
              level: 1,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              treatmentId: properties.route.params.treatmentId,
            });
          }}
        />
      </ScrollView>
    ),
  };
}
