import { StyleSheet } from 'react-native';

export const TaskStatusStyles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
    flex: 1,
  },
  wrapper: {
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingVertical: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  badge: {
    width: 6,
    height: 6,
    borderRadius: 6,
  },
});
