import { NavigationProp } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { Platform, Text, View } from 'react-native';
import { BoardHeaderComponent } from '../../components/header/board.header.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { SubordinateBoardRepository } from '../../repositories/subordinate/subordinate.repository';
import { SubordinatesStyles } from './subordinates.styles';
import { BarChartComponent } from '../funnels/components/barChart/barChart.component';
import { SaleFunnelPerDepartmentTable } from '../funnels/components/saleFunnelPerDepartmentTable/saleFunnelPerDepartmentTable.component';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { SubordinatePerformanceTable } from './components/subordinatePerformanceMonth/subordinatePerformanceMonth';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { ToggleComponent } from '@components/toggle/toggle.component';
import { LoaderComponent } from '@components/loader/loader.component';

export function SubordinatesScreen(properties: { navigation: NavigationProp<BoardRoutes> }) {
  const { t } = useTranslation();
  const [month, setMonth] = useState<number>(new Date().getMonth());

  const [clinics, setClinics] = useState<
    {
      id: string;
      label: string;
    }[]
  >();
  const [clinicId, setClinicId] = useState<string>();

  const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
  const [users, setUsers] = useState<{ label: string; value: string }[]>([]);

  //Filtro para mostrar tambien usuarios inactivos en la tabla
  const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false);

  const [data, setData] = useState<
    {
      total_leads: number;
      lead_contacted_count: number;
      lead_informed_count: number;
      lead_appointed_count: number;
      lead_medical_appointed_count: number;
      lead_medical_evaluated_count: number;
      lead_comercial_attended_count: number;
      lead_converted_count: number;
      lead_medic_attended_count: number;
      department_id: string;
      department_name: string;
    }[]
  >();

  const [loading, setLoading] = useState<boolean>(false);

  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  useEffect(() => {
    if (!month) return;
    setLoading(true);
    const fetchData = async () => {
      if (!clinicId) {
        new ClinicAccessRepository()
          .list({
            page: 0,
            direction: 'asc',
            limit: 100,
          })
          .then((data) => {
            setClinicId('all');
            setClinics([
              {
                id: 'all',
                label: t('board.subordinates.filters.clinics.all'),
              },
              ...data.items.map((clinic) => ({ id: clinic.id, label: clinic.name })),
            ]);
          });
        return;
      }

      const [performanceDate, users] = await Promise.all([
        new SubordinateBoardRepository().countSubordinatesPerformanceByMonth({
          month: month + 1,
          clinicId: clinicId === 'all' ? undefined : clinicId,
          showInactive: showInactiveUsers,
        }),
        new UserAccessRepository().listSubordinates({
          clinicId: clinicId === 'all' ? undefined : clinicId,
        }),
      ]);

      setData(
        performanceDate.map((item) => {
          return {
            department_id: item.id,
            department_name: `${item.name} ${item.surname}`,
            lead_contacted_count: item.lead_contacted_count,
            lead_appointed_count: item.lead_appointed_count,
            lead_informed_count: item.lead_informed_count,
            lead_medical_appointed_count: item.lead_medical_appointed_count,
            lead_medic_attended_count: item.lead_medic_attended_count,
            lead_comercial_attended_count: item.lead_comercial_attended_count,
            lead_medical_evaluated_count: item.lead_medical_evaluated_count,
            lead_converted_count: item.lead_converted_count,
            total_leads: 0,
          };
        })
      );

      setUsers(
        users.map((item) => {
          return {
            label: `${item.name} ${item.surname}`,
            value: item.id,
          };
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [clinicId, month, showInactiveUsers]);

  useEffect(() => {
    if (users.length > 0) setSelectedUser(users[0]?.value);
  }, [clinicId, users]);

  const handleExport = (type: 'yearly' | 'monthly' | 'byEvent') => {
    if (data && confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      handleExportTableCSV({
        headers: ['Empleado', ...Object.values(leadEvent)],
        data: data,
        type,
        fileName: 'coworker_performance.csv',
        eventFields: [
          'lead_contacted_count',
          'lead_appointed_count',
          'lead_informed_count',
          'lead_medical_appointed_count',
          'lead_medic_attended_count',
          'lead_comercial_attended_count',
          'lead_medical_evaluated_count',
          'lead_converted_count',
        ],
      });
    }
    return;
  };

  // Cuando seleccionas un empleado en el filtro de empleados
  // Esta variable guarda el desempeño del empleado seleccionado
  const auxSubordinatePerformance = data?.find((d) => d.department_id === selectedUser);

  return (
    <SceneComponent prefix={'board.subordinates'} icon={'users'} links={[]}>
      <View style={SubordinatesStyles.container}>
        <BoardHeaderComponent
          title="board.subordinates.performance.title"
          description="board.subordinates.performance.description"
        />
        <View style={SubordinatesStyles.filtersContainer}>
          <View style={[SubordinatesStyles.graphicContainer, { zIndex: 1, flex: 2 }]}>
            <View style={[SubordinatesStyles.filterContainer, { width: 250 }]}>
              <View style={SubordinatesStyles.filterTextContainer}>
                <Text style={SubordinatesStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.clinic')}
                </Text>
                <Text style={SubordinatesStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.clinicSubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={clinicId || ''}
                  name="clinic"
                  onChange={(value: any) => {
                    setClinicId(value.clinic);
                  }}
                  pick={async ({ id }) =>
                    new ClinicAccessRepository()
                      .pick({ id })
                      .then((data) => data.name ?? '')
                      .catch(() => t('board.subordinates.filters.clinics.all') ?? 'All')
                  }
                  hideSearch
                  repository={async () => {
                    if (!clinics) return [];
                    return clinics.map((clinic) => {
                      return {
                        label: clinic.label,
                        value: clinic.id,
                      };
                    });
                  }}
                />
              </View>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View style={SubordinatesStyles.filterContainer}>
                <View style={SubordinatesStyles.filterTextContainer}>
                  <Text style={SubordinatesStyles.filterTitle}>
                    {t('board.summarySales.totalSales.filters.month')}
                  </Text>
                  <Text style={SubordinatesStyles.filterSubtitle}>
                    {t('board.summarySales.totalSales.filters.monthSubtitle')}
                  </Text>
                </View>
                <View>
                  <FilterSelectInput
                    type="select"
                    value={month.toString()}
                    name="month"
                    onChange={(value: any) => {
                      setMonth(parseInt(value.month));
                    }}
                    options={Array.from({ length: 12 }, (_, i) => {
                      return {
                        label: t(`access.users.list.month.${i}`),
                        value: i.toString(),
                      };
                    })}
                    hideSearch
                  />
                </View>
              </View>
              <View>
                <ToggleComponent
                  text={'Visualizar inactivos'}
                  value={showInactiveUsers}
                  onChange={() => setShowInactiveUsers(!showInactiveUsers)}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={[SubordinatesStyles.minHeightContainer, { flex: 2 }]}>
            <View style={SubordinatesStyles.graphicContainerHeader}>
              <Text style={SubordinatesStyles.graphicTitle}>
                {`${t('board.subordinates.performanceSubordinates.title')} / ${t(`access.users.list.month.${month}`)}`}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={SubordinatesStyles.uploadBox}
                  onPress={() => handleExport('byEvent')}
                >
                  <View style={SubordinatesStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={SubordinatesStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={SubordinatesStyles.iconContainer}
                      iconStyle={SubordinatesStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {loading ? (
              <LoaderComponent loading />
            ) : (
              <SaleFunnelPerDepartmentTable data={data || []} total={false} />
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'column', paddingTop: 50 }}>
          <View style={{ marginBottom: 10, width: 250, zIndex: 1 }}>
            <View style={SubordinatesStyles.filterTextContainer}>
              <Text style={SubordinatesStyles.filterTitle}>
                {t('board.subordinates.filters.subordinate.title')}
              </Text>
              <Text style={SubordinatesStyles.filterSubtitle}>
                {t('board.subordinates.filters.subordinate.subtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedUser || ''}
                name="user"
                onChange={(value: any) => {
                  setSelectedUser(value.user);
                }}
                pick={async ({ id }) =>
                  new UserAccessRepository()
                    .pick({
                      userId: id,
                    })
                    .then((data) => {
                      return `${data.name} ${data.surname}`;
                    })
                }
                hideSearch
                repository={async ({ search }) => {
                  return Promise.resolve(users);
                }}
              />
            </View>
          </View>
          <View style={SubordinatesStyles.graphicContainer}>
            <View style={[SubordinatesStyles.minHeightContainer, { flex: 1 }]}>
              <View style={SubordinatesStyles.graphicContainerHeader}>
                <Text style={SubordinatesStyles.graphicTitle}>
                  {`${t('board.subordinates.performanceSubordinate.title')} / ${t(`access.users.list.month.${month}`)}`}
                </Text>
              </View>
              {auxSubordinatePerformance && !loading ? (
                <SubordinatePerformanceTable
                  data={Object.keys(auxSubordinatePerformance).map((key) => {
                    return {
                      eventName: leadEvent[key as keyof typeof leadEvent],
                      total: auxSubordinatePerformance[
                        key as keyof typeof auxSubordinatePerformance
                      ] as number,
                    };
                  })}
                />
              ) : (
                <LoaderComponent loading />
              )}
            </View>

            <View
              style={[SubordinatesStyles.minHeightContainer, { flex: 5, backgroundColor: 'white' }]}
            >
              <View style={SubordinatesStyles.graphicContainerHeader}>
                <Text style={SubordinatesStyles.graphicTitle}>
                  {`${t('board.subordinates.performanceSubordinate.title')} / ${t(`access.users.list.month.${month}`)}`}
                </Text>
              </View>
              {auxSubordinatePerformance && !loading ? (
                <BarChartComponent
                  data={auxSubordinatePerformance}
                  aspectRatio={5}
                  leadEvent={leadEvent}
                  total={false}
                />
              ) : (
                <LoaderComponent loading />
              )}
            </View>
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
