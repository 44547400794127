import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../../activity.routes';
import { TableComponent } from '@components/table/table.component';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { ButtonComponent } from '@components/button/button.component';
import { ActivityScopeCrmType } from '@human/crm/types/activityScope.crm.type';
import { ScrollView } from 'react-native';

export function UpdateActivityScopesPart(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'update'>;
}): TabType {
  return {
    name: 'scopes',
    icon: 'hospital',
    content: () => (
      <ScrollView>
        <TableComponent<ActivityScopeCrmType, []>
          prefix={'crm.scopes.list'}
          suffix={'scopes'}
          repository={(settings) =>
            new ActivityCrmRepository().listScopes({
              activityId: properties.route.params.activityId,
              search: settings.search,
            })
          }
          columns={[
            {
              type: 'icon',
              icon: 'trash',
              onPress: (row) => {
                properties.navigation.navigate('deleteScope', {
                  activityId: properties.route.params.activityId,
                  departmentId: row.departmentId,
                  clinicId: row.clinicId,
                  level: 1,
                });
              },
            },
            {
              type: 'clinic',
              name: 'clinicName',
              onRender: (row) => row.clinicName,
              width: 200,
            },
            {
              type: 'text',
              name: 'departmentName',
              onRender: (row) => row.departmentName,
              width: 200,
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.activities.update.scopes.add"
          onPress={() =>
            properties.navigation.navigate('createScope', {
              activityId: properties.route.params.activityId,
              level: 1,
            })
          }
        />
      </ScrollView>
    ),
  };
}
