import { TouchableOpacity, View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { useState } from 'react';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { FileUploaderStyles } from './fileUploader.styles';
import { LoaderComponent } from '@components/loader/loader.component';
import { StringTruncateUtility } from '@utils/string/string.truncate.utility';

export function FileUploaderComponent(properties: {
  onSubmit: (f: File) => Promise<void>;
  fileType: 'application/pdf' | 'text/csv' | 'image/*';
}) {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>();
  const { t } = useTranslation();

  return (
    <View style={FileUploaderStyles.container}>
      <TouchableOpacity style={FileUploaderStyles.uploadBox}>
        <label style={FileUploaderStyles.buttonContainer} className="custom-file-upload">
          <input
            id="file"
            type="file"
            accept={properties.fileType}
            onClick={(e) => {
              if ('value' in e.target) e.target.value = null;
            }}
            onChange={(event) => {
              const file = event.target.files?.[0];

              if (file && file.type === properties.fileType) {
                setFile(file);
              } else {
                alert(t('components.fileUploader.invalidType'));
              }
            }}
            className="fileInput"
          />
          <TextComponent
            style={FileUploaderStyles.buttonLabel}
            text={t('components.fileUploader.title')}
          />
          <IconComponent
            name="upload"
            containerstyle={FileUploaderStyles.iconContainer}
            iconStyle={FileUploaderStyles.icon}
          />
        </label>
      </TouchableOpacity>
      {file && !loading ? (
        <View style={FileUploaderStyles.fileContainer}>
          <TextComponent
            lines={1}
            style={FileUploaderStyles.text}
            text={StringTruncateUtility({
              string: file.name,
              length: 20,
            })}
          />
          <TouchableOpacity onPress={() => setFile(undefined)}>
            <IconComponent name="times" iconStyle={FileUploaderStyles.icon} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setLoading(true);
              properties.onSubmit(file).then(() => {
                setFile(undefined);
                setLoading(false);
              });
            }}
          >
            <IconComponent
              name="arrow-right"
              iconStyle={FileUploaderStyles.submitIcon}
              containerstyle={FileUploaderStyles.submitButton}
            />
          </TouchableOpacity>
        </View>
      ) : null}
      {loading && <LoaderComponent loading={loading} />}
    </View>
  );
}
