import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class OvoBankContactCrmRepository extends AuthorizedRepository {
  async pick({ id }: { id: string }): Promise<ContactCrmType> {
    return this.fetch(`contact-ovobank/pick?contactId=${id}`, {
      method: 'GET',
    }).then((data) => ({
      id: data.id,
      name: data.name,
      surname: data.surname,
      dni: data.dni,
      marketing: data.marketing,
      potentialDuplicate: data.potentialDuplicate,
      legal: data.legal,
      contactTime: data.contactTime,
      clinicId: data.clinicId,
      center: {
        latitude: data.latitude,
        longitude: data.longitude,
      },
      status: data.status,
      emails: data.emails,
      phones: data.phones,
      created: new Date(data.created),
      birthDate: data.birthDate ? new Date(data.birthDate) : undefined,
      birthCountry: data.birthCountry,
      weight: data.weight,
      height: data.height,
      donationPreference: data.donationPreference,
      photo: data.photo,
    }));
  }

  async createFromCampaign(properties: {
    item: Partial<{
      name: string;
      surname: string;
      email: string;
      prefix: string;
      phone: string;
      zip: string;
      clinicId: string;
      legal: boolean;
      marketing: boolean;
      campaignId: string;
      birthDate?: Date;
      birthCountry?: string;
      weight?: string;
      height?: string;
      donationPreference?: string;
      photo?: File;
    }>;
  }): Promise<void> {
    const formData = new FormData();
    formData.append('clinicId', properties.item.clinicId || '');
    formData.append('name', properties.item.name || '');
    formData.append('surname', properties.item.surname || '');
    formData.append('legal', properties.item.legal ? 'true' : 'false');
    formData.append('marketing', properties.item.marketing ? 'true' : 'false');

    formData.append('phone', properties.item.phone || '');
    formData.append('prefix', properties.item.prefix || '');

    formData.append('email', properties.item.email || '');

    formData.append('zip', properties.item.zip || '');
    formData.append('campaignId', properties.item.campaignId || '');
    formData.append('height', properties.item.height || '');
    formData.append('weight', properties.item.weight || '');
    formData.append('birthDate', properties.item.birthDate?.toString() || '');
    formData.append('birthCountry', properties.item.birthCountry || '');
    formData.append('donationPreference', properties.item.donationPreference || '');
    if (properties.item.photo) {
      formData.append('file', properties.item.photo);
    }

    return this.fetchMultiPart('contact-ovobank/create', {
      formData,
    });
  }

  async update({ item }: { item: Partial<ContactCrmType> }): Promise<void> {
    const formData = new FormData();

    formData.append('name', item.name || '');
    formData.append('surname', item.surname || '');
    formData.append('legal', item.legal ? 'true' : 'false');
    formData.append('marketing', item.marketing ? 'true' : 'false');

    if (item.center) {
      formData.append('longitude', item.center.longitude.toString());
      formData.append('latitude', item.center.latitude.toString());
    }

    if (item.phones && item.phones.length > 0) {
      formData.append('phone', item.phones[0].phone || '');
      formData.append('prefix', item.phones[0].prefix || '');
    }

    if (item.emails && item.emails.length > 0) {
      formData.append('email', item.emails[0].email || '');
    }

    formData.append('dni', item.dni || '');

    formData.append('height', item.height || '');
    formData.append('weight', item.weight || '');
    formData.append('birthDate', item.birthDate?.toString() || '');
    formData.append('birthCountry', item.birthCountry || '');
    formData.append('donationPreference', item.donationPreference || '');
    if (item.photo) {
      console.log({
        photo: item.photo,
      });
      formData.append('file', item.photo);
    }

    return this.fetchMultiPart(`contact-ovobank/update?id=${item.id}`, {
      formData,
    });

    // return this.fetch(`contact-ovobank/update?id=${item.id}`, {

    // });
  }
}
