import { FormComponent } from '@components/form/form.component';
import { TabType } from '@components/tabs/tabs.types';
import { UsersRoutes } from '../../../users.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function UpdateUserIntegrationsPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
  showSuccess: (label?: string) => void;
}): TabType {
  return {
    name: 'integrations',
    icon: 'network-wired',
    content: () => (
      <FormComponent<{
        isLandbot: boolean;
      }>
        prefix="access.users.update.integrations.form"
        repository={{
          send: (data) => {
            return new UserAccessRepository()
              .updateIntegrations({
                userId: properties.route.params.userId,
                isLandbot: data.item.isLandbot ?? false,
              })
              .then(() => {
                properties.showSuccess();
              });
          },
          get: async () => {
            return new UserAccessRepository().pickIntegrations({
              userId: properties.route.params.userId,
            });
          },
        }}
        fields={[
          {
            type: 'checkbox',
            name: 'isLandbot',
            required: true,
            description: true,
          },
        ]}
      />
    ),
  };
}
