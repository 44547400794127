import { TextComponent } from '@components/text/text.component';
import { Alert, Platform, TextInput, TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTimeHhmmUtility } from '@utils/hhmm/hhmm.utility';
import { TaggedNoteProperties } from './tagged.note.properties';
import { TaggedNoteStyles } from './tagged.note.styles';
import { TaggedNoteConstants } from './tagged.note.constants';
import { tags } from '../tagged.notes.constants';
import { HistoricalComponent } from '@components/notes/components/historical/historical.component';

export function TaggedNoteComponent(properties: TaggedNoteProperties) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [height, setHeight] = useState<number>();
  const [text, setText] = useState(properties.message.text);
  const [tag, setTag] = useState(properties.message.tag);
  const [historical, setHistorical] = useState<boolean>(false);

  const submitEdit = () => {
    // If the text has not changed, we should not submit the edit
    if (!text) {
      return;
    }

    properties
      .onEdit({
        noteId: properties.message.id,
        newText: text,
        newTag: tag,
      })
      .then(() => setIsEditing(false));
  };

  const handleEdit = () => {
    // If the user is already editing, we should cancel the edit
    if (isEditing) {
      setText(properties.message.text);
      setTag(properties.message.tag);
      setIsEditing(false);
      return;
    }
    // If the user is not editing, we should start the edit
    setIsEditing(true);
  };

  const askDelete = () => {
    if (Platform.OS === 'web') {
      confirm(
        t(TaggedNoteConstants.question.description) ?? TaggedNoteConstants.question.description
      ) && handleDelete();
      return;
    }

    Alert.alert(
      t(TaggedNoteConstants.question.title) ?? TaggedNoteConstants.question.title,
      t(TaggedNoteConstants.question.description) ?? TaggedNoteConstants.question.description,
      [
        {
          text: t(TaggedNoteConstants.question.cancel) ?? TaggedNoteConstants.question.cancel,
          style: 'cancel',
        },
        {
          text: t(TaggedNoteConstants.question.submit) ?? TaggedNoteConstants.question.submit,
          onPress: handleDelete,
          style: 'destructive',
        },
      ]
    );
  };

  const handleDelete = () => {
    properties.onDelete({
      noteId: properties.message.id,
    });
  };

  return (
    <View
      style={[
        TaggedNoteStyles.message,
        { zIndex: properties.index },
        properties.message.status ? null : TaggedNoteStyles.deleted,
      ]}
    >
      <View style={TaggedNoteStyles.messageHeader}>
        <View style={TaggedNoteStyles.header}>
          <View style={TaggedNoteStyles.authorHeader}>
            <TextComponent
              bold
              style={TaggedNoteStyles.author}
              text={`${properties.message.authorName} ${properties.message.authorSurname}`}
            />
            <TextComponent
              bold
              style={{ fontSize: 16, color: tags.find((t) => t.name === tag)?.color }}
              text={`#${tag}`}
            />
          </View>

          <TextComponent style={TaggedNoteStyles.position} text={properties.message.authorEmail} />
        </View>
        <View style={TaggedNoteStyles.actionsContainer}>
          {properties.message.logs ? (
            <>
              <TouchableOpacity
                onBlur={() => setHistorical(false)}
                style={TaggedNoteStyles.actionIcon}
                onPress={() => {
                  setHistorical(!historical);
                }}
              >
                <IconComponent name="history" iconStyle={TaggedNoteStyles.actionIconShape} />
              </TouchableOpacity>
              {historical && properties.historical ? (
                <HistoricalComponent message={properties.message} logs={properties.message.logs} />
              ) : null}
            </>
          ) : null}
          <TouchableOpacity style={TaggedNoteStyles.actionIcon} onPress={handleEdit}>
            <IconComponent name="pen" iconStyle={TaggedNoteStyles.actionIconShape} />
          </TouchableOpacity>
          <TouchableOpacity style={TaggedNoteStyles.actionIcon} onPress={askDelete}>
            <IconComponent name="trash" iconStyle={TaggedNoteStyles.actionIconShape} />
          </TouchableOpacity>
        </View>
      </View>
      {isEditing ? (
        <View>
          <TextInput
            style={[TaggedNoteStyles.editText, { height: height }]}
            multiline
            placeholder="Escribe un nota"
            value={text}
            onChangeText={setText}
            onContentSizeChange={(event) => {
              setHeight(event.nativeEvent.contentSize.height);
            }}
          />
          <TouchableOpacity onPress={submitEdit}>
            <IconComponent name="paper-plane" iconStyle={TaggedNoteStyles.send} />
          </TouchableOpacity>
        </View>
      ) : (
        <TextComponent style={TaggedNoteStyles.text} text={text} />
      )}

      <View style={TaggedNoteStyles.when}>
        <TextComponent
          style={TaggedNoteStyles.date}
          text={properties.message.updated.toLocaleDateString('es-ES', {
            weekday: 'short', // Día de la semana abreviado
            year: 'numeric', // Año con 4 dígitos
            month: 'short', // Mes abreviado
            day: 'numeric', // Día del mes
          })}
        />
        <TextComponent
          style={TaggedNoteStyles.hour}
          text={formatTimeHhmmUtility({ date: properties.message.updated })}
        />
      </View>
    </View>
  );
}
