import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { InterventionCrmType } from '@human/crm/types/intervention.crm.type';
import { dayjs } from '@utils/dayjs/dayjs.config';

export class InterventionCrmRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    treatmentId: string;
    clinicId: string;
    patientId: string;
  }): Promise<{ items: InterventionCrmType[]; total: number }> {
    return this.fetch('intervention/find', {
      params: properties,
      method: 'GET',
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          interventionId: item.id,
          interventionClinicId: item.interventionClinicId,
          interventionClinicName: item.interventionClinicName,
          interventionDate: new Date(item.interventionDate),
          status: item.status,
          cycleId: item.cycleId,
          cycleName: item.cycleName,
          expectedDate: new Date(item.expectedDate),
          observation: item.observation,
          created: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
    interventionId: string;
  }): Promise<InterventionCrmType> {
    return this.fetch('intervention/pick', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        interventionClinicId: data.interventionClinicId,
        interventionClinicName: data.interventionClinicName,
        interventionId: data.id,
        interventionDate: data.interventionDate ? new Date(data.interventionDate) : undefined,
        status: data.status,
        cycleId: data.cycleId,
        cycleName: data.cycleName,
        expectedDate: new Date(data.expectedDate),
        observation: data.observation,
        created: new Date(data.created),
      };
    });
  }

  async create(properties: {
    item: Partial<InterventionCrmType>;
    treatmentId: string;
    clinicId: string;
    patientId: string;
  }): Promise<void> {
    return this.fetch('intervention/create', {
      method: 'POST',
      params: {
        treatmentId: properties.treatmentId,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
      },
      body: {
        ...properties.item,
        expectedDate: properties.item.expectedDate
          ? dayjs(properties.item.expectedDate).tz('Europe/Madrid').toISOString()
          : undefined,
        interventionDate: properties.item.interventionDate
          ? dayjs(properties.item.interventionDate).tz('Europe/Madrid').toISOString()
          : undefined,
      },
    });
  }

  async update(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
    interventionId: string;
    item: Partial<InterventionCrmType>;
  }): Promise<void> {
    return this.fetch(`intervention/update`, {
      method: 'PUT',
      params: {
        treatmentId: properties.treatmentId,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        interventionId: properties.interventionId,
      },
      body: {
        ...properties.item,
        expectedDate: properties.item.expectedDate
          ? dayjs(properties.item.expectedDate).tz('Europe/Madrid').toISOString()
          : undefined,
        interventionDate: properties.item.interventionDate
          ? dayjs(properties.item.interventionDate).tz('Europe/Madrid').toISOString()
          : undefined,
      },
    });
  }

  async enable(properties: {
    interventionId: string;
    patientId: string;
    treatmentId: string;
    clinicId: string;
  }) {
    return this.fetch('intervention/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: {
    interventionId: string;
    patientId: string;
    treatmentId: string;
    clinicId: string;
  }) {
    return this.fetch('intervention/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
