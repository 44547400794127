export const timeZonesConstant = [
  { label: 'Baker Island, UTC-12:00', value: -12 },
  { label: 'Niue, UTC-11:00', value: -11 },
  { label: 'Honolulu, Hawaii, UTC-10:00', value: -10 },
  { label: 'Anchorage, Alaska, UTC-09:00', value: -9 },
  { label: 'Los Angeles, USA, UTC-08:00', value: -8 },
  { label: 'Denver, USA, UTC-07:00', value: -7 },
  { label: 'Chicago, USA, UTC-06:00', value: -6 },
  { label: 'Mexico City, Mexico, UTC-05:00', value: -5 },
  { label: 'New York, USA, UTC-04:00', value: -4 },
  { label: 'Buenos Aires, Argentina, UTC-03:00', value: -3 },
  { label: 'South Georgia and the South Sandwich Islands, UTC-02:00', value: -2 },
  { label: 'Cape Verde, UTC-01:00', value: -1 },
  { label: 'Reykjavik, Iceland, UTC+00:00', value: 0 },
  { label: 'London, UK, UTC+01:00', value: 1 },
  { label: 'Berlin, Germany, UTC+02:00', value: 2 },
  { label: 'Moscow, Russia, UTC+03:00', value: 3 },
  { label: 'Dubai, UAE, UTC+04:00', value: 4 },
  { label: 'Karachi, Pakistan, UTC+05:00', value: 5 },
  { label: 'Dhaka, Bangladesh, UTC+06:00', value: 6 },
  { label: 'Jakarta, Indonesia, UTC+07:00', value: 7 },
  { label: 'Beijing, China, UTC+08:00', value: 8 },
  { label: 'Tokyo, Japan, UTC+09:00', value: 9 },
  { label: 'Sydney, Australia, UTC+10:00', value: 10 },
  { label: 'Noumea, New Caledonia, UTC+11:00', value: 11 },
  { label: 'Auckland, New Zealand, UTC+12:00', value: 12 },
];
