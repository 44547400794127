// Modules
import { StyleSheet } from 'react-native';

/**
 * Historical Styles
 * @constant {StyleSheet} NoteStyles
 */
export const HistoricalStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 24,
    left: -140,
    width: 300,
    minHeight: 200,
    maxHeight: 400,
    paddingHorizontal: 10,
    borderRadius: 6,
    overflow: 'scroll',
    backgroundColor: 'rgb(255, 255, 255)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    paddingLeft: 20,
  },
  name: {
    fontWeight: 'bold',
  },
});
