import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class PatientCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof PatientCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{ items: PatientCrmType[]; total: number }> {
    return this.fetch('patient/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          description: item.description,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          lastCycleClinicId: item.lastCycleClinicId,
          lastCycleClinicName: item.lastCycleClinicName,
          contactId: item.contactId,
          contactName: item.contactName,
          phones: item.phones,
          campaignId: item.campaignId,
          campaignName: item.campaignName,
          status: item.status,
          warrantyAgreement: item.warrantyAgreement,
          consentCheck: item.consentCheck,
          created: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: { id: string; clinicId: string }): Promise<Partial<PatientCrmType>> {
    return this.fetch(`patient/pick`, {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      id: data.id,
      description: data.description,
      clinicId: data.clinicId,
      contactId: data.contactId,
      campaignId: data.campaignId,
      specialistId: data.specialistId,
      patientId: data.patientId,
      status: data.status,
      warrantyAgreement: data.warrantyAgreement,
      consentCheck: data.consentCheck,
      responsibleId: data.responsibleId,
    }));
  }

  async pickProfile(properties: { patientId: string; clinicId: string }): Promise<{
    id: string;
    status: boolean;
    contactName: string;
    contactSurname: string;
    contactEmails: { id: string; email: string; contactId: string }[];
    contactPhones: { id: string; phone: string; prefix: string; contactId: string }[];
    contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
    clinicName: string;
    campaignName: string;
    originCampaignName: string;
    warrantyAgreement: boolean;
    consentCheck: boolean;
  }> {
    return this.fetch(`patient/pickProfile`, {
      method: 'GET',
      params: properties,
    }).then((patient) => ({
      id: patient.id,
      status: patient.status,
      contactName: patient.contactName,
      contactSurname: patient.contactSurname,
      contactTime: patient.contactTime,
      contactEmails: patient.contactEmails,
      contactPhones: patient.contactPhones,
      clinicName: patient.clinicName,
      campaignName: patient.campaignName,
      originCampaignName: patient.originCampaignName,
      warrantyAgreement: patient.warrantyAgreement,
      consentCheck: patient.consentCheck,
    }));
  }

  async create({ item }: { item: Partial<PatientCrmType> }): Promise<void> {
    return this.fetch('patient/create', {
      method: 'POST',
      body: item,
    });
  }

  async update(properties: { item: Partial<PatientCrmType> }): Promise<void> {
    return this.fetch(`patient/update?patientId=${properties.item.id}`, {
      method: 'PUT',
      body: properties.item,
    });
  }

  async updateResponsible({
    item,
  }: {
    item: Partial<{ patientId: string; responsibleId: string; clinicId: string }>;
  }): Promise<void> {
    return this.fetch(`patient/update/responsible`, {
      method: 'PUT',
      body: item,
    }).then(() => {});
  }

  async enable(properties: { patientId: string; clinicId: string }): Promise<void> {
    return this.fetch(`patient/enable`, {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { patientId: string; clinicId: string }): Promise<void> {
    return this.fetch(`patient/disable`, {
      method: 'PUT',
      params: properties,
    });
  }

  async delete(properties: { patientId: string; clinicId: string }): Promise<void> {
    return this.fetch(`patient/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }

  async updateSpecialist(properties: {
    patientId: string;
    clinicId: string;
    specialistId: string;
  }) {
    return this.fetch('patient/updateSpecialist', {
      method: 'PUT',
      body: properties,
    });
  }
}
