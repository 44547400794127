import { useTranslation } from 'react-i18next';
import { ItemByDepartmentYearsComparativeType } from '../../../../types/itemByDepartmentYearsComparative.type';
import { View, ScrollView, Text } from 'react-native';
import { ExpectedInterventionsYearTableStyles } from './expectedInterventionsYear.styles';
import { InterventionsYearCountRow } from './components/interventionsYearCountRow';
import { useDeviceHook } from '@hooks/device/device.hook';
import { months } from '../../../../constants/months.constant';

export function ExpectedInterventionsYearTable(properties: {
  selectedYear: number;
  data: ItemByDepartmentYearsComparativeType[];
}) {
  const { t } = useTranslation();

  const { phone } = useDeviceHook();

  const departments = Array.from(new Set(properties.data.map((item) => item.department_name)));
  const formattedData = departments.map((department) => {
    const departmentData = properties.data
      .filter(
        (item) => item.department_name === department && item.year === properties.selectedYear
      )
      .sort((a, b) => {
        return a.month - b.month;
      });
    return {
      department_name: department,
      total_leads: departmentData.map((item) => item.total),
    };
  });

  const LeadCountTableColumn = (settings: {
    header: {
      month: string;
    };
    index: number;
  }) => {
    return (
      <View
        style={[
          phone && { width: 75, marginHorizontal: 10 },
          ExpectedInterventionsYearTableStyles.headerColumnContainer,
        ]}
      >
        <Text
          style={[
            phone
              ? [{ width: 75 }, ExpectedInterventionsYearTableStyles.headerText]
              : ExpectedInterventionsYearTableStyles.headerText,
          ]}
        >
          {t(`board.months.${settings.header.month}`)}
        </Text>
      </View>
    );
  };

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              gap: 2,
              marginLeft: 5,
            }}
          >
            <View
              style={[
                ExpectedInterventionsYearTableStyles.headerSpaceHeader,
                phone && { width: 75 },
              ]}
            />
            <View style={ExpectedInterventionsYearTableStyles.headerContainer}>
              {months.map((item, index) => (
                <LeadCountTableColumn key={item.month} header={item} index={index} />
              ))}
            </View>
          </View>

          {formattedData.map((item, index) => (
            <InterventionsYearCountRow
              key={item.department_name}
              item={item}
              firstAttemptRow={index === formattedData.length - 3}
              isLossRow={index === formattedData.length - 2}
              last={index === formattedData.length - 1}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}
