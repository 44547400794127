import { StyleSheet } from 'react-native';

export const AccordionStyles = StyleSheet.create({
  title: {
    overflow: 'hidden',
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    shadowColor: '#000',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    height: 40,
    maxHeight: 40,
    gap: 20,
  },
  paddingTitle: {
    paddingLeft: 40,
    borderRadius: 0,
  },
  titleText: {
    fontSize: 14,
    fontFamily: 'Lato-Bold',
  },
  titleIconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
  },
  titleIconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  titleAngleIconContainer: {
    height: 27,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 7,
    top: 7,
    zIndex: 1,
  },
  titleAngleIconStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 27,
  },
  description: {
    fontSize: 14,
    fontFamily: 'Lato-Regular',
    color: 'rgba(0,0,0,0.6)',
  },
  container: {
    borderRadius: 5,
    overflow: 'hidden',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.05)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  containerOpen: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
});
