import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { CommunicationChannelAccessType } from '@human/access/types/communicationChannel.access.type';

export class CommunicationChannelAccessRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    page: number;
    limit: number;
    active?: boolean;
  }): Promise<{ items: CommunicationChannelAccessType[]; total: number }> {
    return this.fetch('communicationChannel/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((communicationChannel: any) => ({
        id: communicationChannel.id,
        name: communicationChannel.name,
        status: communicationChannel.status,
        created: communicationChannel.created,
      })),
      total: data.total,
    }));
  }

  async pick(properties: { id: string }): Promise<CommunicationChannelAccessType> {
    return this.fetch('communicationChannel/pick', {
      method: 'GET',
      params: properties,
    });
  }

  async create(properties: { name: string }): Promise<CommunicationChannelAccessType> {
    return this.fetch('communicationChannel/create', {
      method: 'POST',
      body: properties,
    });
  }

  async update(properties: { id: string; name: string }): Promise<void> {
    return this.fetch('communicationChannel/update', {
      method: 'PUT',
      params: {
        id: properties.id,
      },
      body: {
        name: properties.name,
      },
    });
  }

  async enable(properties: { id: string }): Promise<void> {
    return this.fetch('communicationChannel/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { id: string }): Promise<void> {
    return this.fetch('communicationChannel/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
