import { Platform } from 'react-native';
import { IconType } from '../../../../../components/icon/icon.types';
import { BoardRoutes } from './board.routes';
export const BoardSections: {
  [key in keyof BoardRoutes]: {
    name: string;
    icon: IconType;
    keywords: string[];
    department: string;
    status?: boolean;
    permission?: string;
    supressBadge?: boolean;
    platforms?: Array<typeof Platform.OS>;
  };
} = {
  leads: {
    name: 'leads',
    icon: 'search',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    permission: 'board.leadsResume',
  },
  summarySales: {
    name: 'summarySales',
    icon: 'chart-bar',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    permission: 'board.saleResume',
  },
  saleFunnel: {
    name: 'saleFunnel',
    icon: 'funnel-dollar',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    permission: 'board.saleFunnel',
  },
  expectedIntervention: {
    name: 'expectedIntervention',
    icon: 'stethoscope',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    permission: 'board.expectedIntervention',
  },
  subordinates: {
    name: 'subordinates',
    icon: 'users',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    supressBadge: true,
    platforms: ['web'],
    permission: 'board.coworkerPerformance',
  },
  marketing: {
    name: 'marketing',
    icon: 'chart-area',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    supressBadge: true,
    permission: 'board.marketing',
  },
};
