import { NavigationProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessType } from '@human/access/types/country.access.type';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { useUserState } from '../../../../../../../../../states/user/user.state';
import { AccessSections } from '@human/access/access.sections';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function CreateCountryScreen(properties: { navigation: NavigationProp<CountriesRoutes> }) {
  const { user } = useUserState();

  return (
    <ModalComponent icon={AccessSections.countries.icon} prefix="access.countries.create">
      <FormComponent<CountryAccessType>
        padding
        prefix="access.countries.create.form"
        repository={{
          send: (settings: { item: Partial<CountryAccessType> }) => {
            return new CountryAccessRepository()
              .create({
                item: settings.item,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'countryNameEs',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            type: 'text',
            name: 'countryNameEn',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            type: 'text',
            name: 'flag',
            description: true,
            required: true,
            validations: ['flag'],
          },
          {
            type: 'text',
            name: 'code',
            description: true,
            required: true,
            validations: ['countryCode'],
          },
          {
            type: 'text',
            name: 'coin',
            description: true,
            required: true,
            validations: ['coin'],
          },
          {
            type: 'text',
            name: 'prefix',
            description: true,
            required: true,
            validations: ['prefix'],
          },
          {
            type: 'json',
            name: 'geometry',
            description: true,
          },
          {
            type: 'text',
            name: 'description',
            description: true,
            required: true,
            lines: 6,
            validations: ['description'],
          },
          {
            type: 'color',
            name: 'color',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
