import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ItemByDepartmentYearsComparativeType } from '../../screens/leads/components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { LeadActiveInactiveType } from '../../screens/leads/components/leadActiveInactiveComparative/leadActiveInactiveComparative.type';
import { LeadActiveInactiveChartType } from '../../screens/leads/components/leadActiveInactiveChart/leadActiveInactiveChart.type';

export class LeadDetailBoardRepository extends AuthorizedRepository {
  newLeadCountYearsComparative = async (properties: {
    clinicId: string;
    firstYear: number;
    secondYear: number;
    funnelId?: string;
  }): Promise<ItemByDepartmentYearsComparativeType[]> => {
    return this.fetch('board/leadDetail/totalLeadsComparingYears', {
      method: 'GET',
      params: properties,
    });
  };

  newLeadCountYear = async (properties: {
    clinicId: string;
    year: number;
    funnelId?: string;
    campaignId?: string;
  }): Promise<Omit<ItemByDepartmentYearsComparativeType, 'year'>[]> => {
    return this.fetch('board/leadDetail/totalLeadsDuringYear', {
      method: 'GET',
      params: properties,
    });
  };

  leadStateByClinicYearMonthFunnel = async (properties: {
    clinicId: string;
    year: number;
    month: number;
    funnelId?: string;
    campaignId?: string;
  }): Promise<
    {
      department_id: string;
      department_name: string;
      total_leads: number;
      lead_appointed_count: number;
      lead_percentage_appointed: number;
      lead_active_count: number;
      lead_inactive_count: number;
    }[]
  > => {
    return this.fetch('board/leadDetail/leadStateByClinicYearMonthFunnel', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return data.map((item: any) => {
        return {
          department_id: item.department_id,
          department_name: item.department_name,
          total_leads: item.total_leads,
          lead_appointed_count: item.lead_appointed_count,
          lead_percentage_appointed: `${item.lead_percentage_appointed}%`,
          lead_active_count: item.lead_active_count,
          lead_inactive_count: item.lead_inactive_count,
        };
      });
    });
  };

  leadsCostByCampaign = async (properties: {
    clinicId: string;
    campaignId: string;
  }): Promise<{
    campaign_name: string;
    campaign_budget: number;
    total_leads: number;
    lead_appointed_count: number;
    cost_total_lead: number;
    cost_total_appointed_lead: number;
  }> => {
    return this.fetch('board/leadDetail/leadsCostByCampaign', {
      method: 'GET',
      params: properties,
    });
  };

  leadActiveInactiveComparativeByYear = async (properties: {
    year: number;
    clinicId: string;
    languageId: string;
    funnelId?: string;
  }): Promise<LeadActiveInactiveType[]> => {
    return this.fetch('board/leadDetail/totalActiveInactiveByYear', {
      method: 'GET',
      params: properties,
    });
  };

  leadActiveInactiveComparativeByMonth = async (properties: {
    month?: number;
    clinicId: string;
    languageId: string;
    funnelId?: string;
    year: number;
  }): Promise<LeadActiveInactiveChartType[]> => {
    return this.fetch('board/leadDetail/totalActiveInactiveByMonth', {
      method: 'GET',
      params: properties,
    });
  };
}
