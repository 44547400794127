import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ProgramCycleCrmRepository } from '@human/crm/repositories/programCycle/programCycle.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';
import { NavigationProp } from '@react-navigation/native';
import { ScrollView } from 'react-native';

export function CyclesUpdateProgramPart(properties: {
  programId: string;
  navigation: NavigationProp<ProgramsRoutes>;
}) {
  return (
    <ScrollView>
      <TableComponent<
        {
          cycleId: string;
          cycleName: string;
          cycleCreated: Date;
        },
        []
      >
        key={'cycles'}
        prefix={'crm.programs.update.cycles.list'}
        repository={(settings) =>
          new ProgramCycleCrmRepository().list({
            programId: properties.programId,
            search: settings.search,
          })
        }
        columns={[
          {
            type: 'icon',
            icon: 'trash',
            onPress: (row) => {
              properties.navigation.navigate('programCycleDelete', {
                programId: properties.programId,
                cycleId: row.cycleId,
                level: 1,
              });
            },
          },
          {
            type: 'text',
            name: 'cycleName',
            width: 200,
            onRender: (row) => row.cycleName,
          },
          {
            type: 'date',
            name: 'cycleCreated',
            renderDate: (row) => row.cycleCreated,
          },
        ]}
        suffix={'list'}
      />
      <ButtonComponent
        icon="plus"
        prefix="crm.programs.update.cycles.create.title"
        onPress={() => {
          properties.navigation.navigate('programCycleCreate', {
            programId: properties.programId,
            level: 1,
          });
        }}
      />
    </ScrollView>
  );
}
