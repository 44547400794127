import { TouchableOpacity } from 'react-native';
import { IconComponent } from '../../../../../icon/icon.component';
import { IconColumnImplementation } from './icon.column.properties';
import { IconColumnStyles } from './icon.column.styles';

export function IconColumnComponent<T>(properties: IconColumnImplementation<T, []>) {
  const icon =
    'renderIcon' in properties
      ? properties.renderIcon?.(properties.item)
      : 'icon' in properties
        ? properties.icon
        : '';

  return (
    <TouchableOpacity
      disabled={properties.blockAction}
      onPress={() => properties.onPress?.(properties.item)}
      style={[
        IconColumnStyles.container,
        icon === 'trash' ? IconColumnStyles.containerIsTrash : IconColumnStyles.containerIsNotTrash,
      ]}
    >
      <IconComponent
        name={icon as any}
        containerstyle={[
          IconColumnStyles.iconContainer,
          { backgroundColor: properties.renderBackground?.(properties.item) ?? 'transparent' },
        ]}
        iconStyle={[
          IconColumnStyles.iconStyle,
          {
            color:
              icon === 'trash' || icon === 'lock'
                ? IconColumnStyles.iconIsTrash.color
                : properties.renderColor?.(properties.item) ??
                  IconColumnStyles.iconsIsNotTrash.color,
          },
        ]}
      />
    </TouchableOpacity>
  );
}
