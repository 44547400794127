import { ItemTableType } from '@components/table/table.component';
import { OrderTableType } from '@components/table/table.properties';
import { RiskIndicatorImplementation, RiskIndicatorVariant } from './riskIndicator.properties';
import { View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { RiskIndicatorStyles } from './riskIndicator.styles';

export function RiskIndicatorComponent<
  ITEM extends ItemTableType,
  ORDER extends OrderTableType<ITEM>,
>(properties: RiskIndicatorImplementation<ITEM, ORDER>) {
  const getVariant = (variant: RiskIndicatorVariant) => {
    switch (variant) {
      case 'danger':
        return RiskIndicatorStyles.textDanger;
      case 'success':
        return RiskIndicatorStyles.textSuccess;
      case 'warning':
        return RiskIndicatorStyles.textWarning;
      case 'normal':
        return RiskIndicatorStyles.textNormal;
    }
  };

  const icon = properties.icon ? properties.icon(properties.item) : null;
  const text = properties.onRender(properties.item);
  const variant = getVariant(properties.variant(properties.item));

  return (
    <View style={RiskIndicatorStyles.container}>
      <TextComponent lines={1} bold text={text} style={variant} />
      {icon && (
        <IconComponent
          name={icon}
          containerstyle={undefined}
          iconStyle={[variant, { fontSize: 20 }]}
        />
      )}
    </View>
  );
}
