import { View } from 'react-native';
import { PanelStyles } from './panel.styles';
import { PanelProperties } from './panel.properties';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Panel Component
 * @description Escena de la aplicación que contiene un header y un cuerpo scrolleables.
 *
 * @param {PanelProperties} properties
 * @returns {JSX.Element}
 */

export function PanelComponent(properties: PanelProperties) {
  const device = useDeviceHook();

  return (
    <View style={PanelStyles.container}>
      <View style={PanelStyles.header}>
        <IconComponent
          name={properties.icon}
          containerstyle={PanelStyles.icon}
          iconStyle={PanelStyles.iconShape}
        />

        <TextComponent
          multiWorkspace
          translate
          text={`${properties.prefix}.title`}
          style={PanelStyles.title}
        />
        {!device.tablet && !device.phone && (
          <TextComponent
            translate
            multiWorkspace
            text={`${properties.prefix}.description`}
            style={PanelStyles.description}
          />
        )}
      </View>
      <View style={PanelStyles.content}>{properties.children}</View>
    </View>
  );
}
