import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CommunicationChannelsRoutes } from '../../communicationChannels.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CommunicationChannelAccessRepository } from '@human/access/repositories/communicationChannel/communicationChannel.repository';

export function DisableCommunicationChannelScreen(properties: {
  navigation: NavigationProp<CommunicationChannelsRoutes>;
  route: RouteProp<CommunicationChannelsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.communicationChannels.disable">
      <FormComponent
        prefix="access.communicationChannels.disable.form"
        padding
        repository={{
          send: () => {
            return new CommunicationChannelAccessRepository()
              .disable({
                id: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
