import { useNavigation } from '@react-navigation/native';
import { View, TouchableOpacity } from 'react-native';
import { ModalStyles } from './modal.styles';
import { ModalProperties } from './modal.properties';
import { HeaderComponent } from './components/header/header.component';
import { useDeviceHook } from '@hooks/device/device.hook';
import { useTranslation } from 'react-i18next';

/**
 * ModalComponent renders a modal with accompanying title, subtitle, and content.
 * The component allows the user to view additional information or perform an action.
 *
 * @param {ModalProperties} properties - The properties object containing the icon, prefix, and children of the modal.
 *
 * @returns {JSX.Element} The rendered JSX element containing the modal, title, subtitle, and content.
 *
 * @example
 * <ModalComponent icon="activity" prefix="Activity" >
 *  <TextComponent text="Hello, world!" />
 * </ModalComponent>
 *
 */

export function ModalComponent(properties: ModalProperties) {
  const navigation = useNavigation();
  const { device, laptop, desktop } = useDeviceHook();
  const { t } = useTranslation();

  return (
    <>
      {properties.transparent === undefined && (
        <TouchableOpacity
          activeOpacity={1}
          style={ModalStyles.backButton}
          onPress={() => {
            if (
              !properties.confirmationMessage ||
              confirm(t(`${properties.prefix}.confirmationMessage`) || '')
            )
              navigation.goBack();
          }}
        />
      )}
      <View
        style={[
          ModalStyles.container,
          {
            width:
              device === 'laptop' ? '50%' : device !== 'desktop' ? '100%' : properties.width || 500,
          },
          {
            top:
              device === 'laptop'
                ? properties.level
                  ? 50 + 10 * properties.level
                  : 50
                : device === 'desktop'
                  ? properties.level
                    ? 100 + 20 * properties.level
                    : 100
                  : 0,
            right:
              device === 'laptop'
                ? properties.level
                  ? 50 + 10 * properties.level
                  : 50
                : device !== 'desktop'
                  ? 0
                  : properties.level
                    ? 100 + 20 * properties.level
                    : 100,
          },
        ]}
      >
        <HeaderComponent
          confirmationMessage={properties.confirmationMessage}
          prefix={properties.prefix}
          icon={properties.icon}
          multiWorkspace={properties.multiWorkspace}
        />
        {properties.children && (
          <View
            style={[
              ModalStyles.content,
              {
                ...(properties.flex !== false && ModalStyles.flexedContent),
              },
            ]}
          >
            {properties.children}
          </View>
        )}
      </View>
    </>
  );
}
