import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ProgramPhaseCrmRepository extends AuthorizedRepository {
  async list(properties: { search?: string; programId: string }): Promise<{
    items: {
      phaseId: string;
      phaseName: string;
      phaseDescription: string;
      phaseCreated: Date;
    }[];
    total: number;
  }> {
    return this.fetch('programPhase/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          phaseId: item.id,
          phaseName: item.name,
          phaseDescription: item.description,
          phaseCreated: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { programId: string; phaseId?: string }): Promise<void> {
    return this.fetch(`programPhase/create`, {
      method: 'POST',
      body: properties,
    });
  }

  async delete(properties: { programId: string; phaseId: string }): Promise<void> {
    return this.fetch(`programPhase/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
