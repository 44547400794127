import { ContactsRoutes } from './contacts.routes';
import { ListContactsScreen } from './screens/list/list.contacts.screen';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { UpdateContactScreen } from './screens/update/update.contact.screen';
import { UpdateLeadScreen } from '../leads/screens/update/update.lead.screen';
import { DisableContactscreen } from './screens/disable/disable.contacts.screen';
import { EnableContactscreen } from './screens/enable/enable.contacts.screen';
import { CreateContactScreen } from './screens/create/create.contact.screen';
import { DeleteContactScreen } from './screens/delete/delete.contact.screen';
import { UpdatePurchaseScreen } from '../purchase/screens/update/update.purchase.screen';
import { DisablePurchaseScreen } from '../purchase/screens/disable/disable.purchase.screen';
import { EnablePurchaseScreen } from '../purchase/screens/enable/enable.purchase.screen';

const Navigation = NavigationComponent<ContactsRoutes>();
export function ContactsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListContactsScreen} />
      <Navigation.Screen name="create" component={CreateContactScreen} />
      <Navigation.Screen name="update" component={UpdateContactScreen} />
      <Navigation.Screen name="contactDisable" component={DisableContactscreen} />
      <Navigation.Screen name="contactEnable" component={EnableContactscreen} />
      <Navigation.Screen name="contactDelete" component={DeleteContactScreen} />
      <Navigation.Screen name="leadUpdate" component={UpdateLeadScreen} />

      <Navigation.Screen name="updatePurchase" component={UpdatePurchaseScreen} />
      <Navigation.Screen name="disablePurchase" component={DisablePurchaseScreen} />
      <Navigation.Screen name="enablePurchase" component={EnablePurchaseScreen} />
    </Navigation.Navigator>
  );
}
