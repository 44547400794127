import { Image, View } from 'react-native';
import { ElementColumnImplementation } from './element.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { ElementColumnStyles } from './element.column.styles';
import { IconComponent } from '@components/icon/icon.component';

export function ElementColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: ElementColumnImplementation<ITEM, ORDER>
) {
  return (
    <View style={ElementColumnStyles.container}>
      {properties.renderIcon ? (
        <IconComponent
          name={properties.renderIcon?.(properties.item)}
          containerstyle={[
            ElementColumnStyles.iconContainer,

            { borderColor: properties.renderColor?.(properties.item) ?? 'black' },
          ]}
          iconStyle={[
            ElementColumnStyles.icon,
            {
              color: properties.renderColor?.(properties.item) ?? 'grey',
            },
          ]}
        />
      ) : null}
    </View>
  );
}
