import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { PermissionType } from 'types/permission.type';

export class UserRepository extends AuthorizedRepository {
  async retrieve(): Promise<{
    id: string;
    name: string;
    surname: string;
    username: string;
    email: string;
    selfie: string;
    companyCode: string;
    idioms: string[];
    permissions: PermissionType;
  }> {
    return this.fetch('session/user', {
      method: 'GET',
    });
  }

  async addSelfie(properties: { selfie: string }): Promise<void> {
    return this.fetch('user/selfie', {
      method: 'PUT',
      body: { selfie: properties.selfie },
    });
  }

  async changePassword(properties: { userId: string; password: string }): Promise<void> {
    return this.fetch('user/change-password', {
      method: 'PUT',
      body: properties,
    });
  }
}
