// Modules
import { StyleSheet } from 'react-native';

/**
 * NumberColumn Styles
 * @constant {StyleSheet} NumberColumnStyles
 */
export const NumberColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 14,
    alignContent: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: '#333',
  },
  icon: {
    fontSize: 16,
    color: '#aaa',
  },
  suffix: {
    color: '#aaa',
    marginLeft: 5,
  }
});
