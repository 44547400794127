import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccessSections } from '@human/access/access.sections';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { DepartmentAccessType } from '@human/access/types/department.access.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../departments.routes';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';

export function CreateDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentCreate'>;
}) {
  return (
    <ModalComponent
      width={600}
      icon={AccessSections.departments.icon}
      prefix="access.departments.create"
    >
      <FormComponent<DepartmentAccessType>
        prefix="access.departments.create.form"
        padding
        repository={{
          send: (data) => {
            return new DepartmentAccessRepository()
              .create({
                item: {
                  ...data.item,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['name'],
          },

          {
            type: 'select',
            name: 'languageId',
            description: true,
            required: true,
            pick: (properties: { id: string }) => {
              return new LanguageAccessRepository()
                .pick({ languageId: properties.id })
                .then((language) => {
                  return `${language.name}`;
                });
            },
            repository: ({ search }) =>
              new LanguageAccessRepository()
                .list({
                  order: 'name',
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((languages) =>
                  languages.items.map((language) => ({
                    value: language.id,
                    label: language.name,
                  }))
                ),
          },
          {
            type: 'select',
            name: 'responsibleId',
            description: true,
            required: true,
            pick: (props: { id: string }) => {
              return new CoworkerCrmRepository()
                .pick({
                  clinicId: properties.route.params.clinicId,
                  coworkerId: props.id,
                })
                .then((coworker) => {
                  return `${coworker.name} ${coworker.surname}`;
                });
            },
            repository: ({ search }) =>
              new CoworkerCrmRepository()
                .list({
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  order: 'name',
                  clinicId: properties.route.params.clinicId,
                  search,
                  active: true,
                })
                .then((users) =>
                  users.items.map((user) => ({
                    value: user.id,
                    label: `${user.name} ${user.surname}`,
                    description: user.email,
                    bubbles: user?.aggregated?.roles
                      ? user.aggregated?.roles?.map((role) => ({ value: role, label: role }))
                      : undefined,
                  }))
                ),
          },
        ]}
      />
    </ModalComponent>
  );
}
