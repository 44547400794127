// Modules
import { StyleSheet } from 'react-native';

/**
 * PanelStyles Styles
 * @constant {StyleSheet} PanelStylesStyles
 */
export const PanelStyles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 163.84,
  },
  containerContent: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    padding: 7,
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    gap: 10,
  },
  icon: {
    opacity: 0.5,
  },
  iconShape: {
    fontSize: 25,
    color: '#333',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333',
  },
  description: {
    color: '#888',
    fontSize: 12,
  },
  content: {
    padding: 10,
    backgroundColor: '#eee',
    flex: 1,
  },
});
