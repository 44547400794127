import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ContactCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof ContactCrmType;
    search?: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    active?: boolean;
  }): Promise<{ items: ContactCrmType[]; total: number }> {
    return this.fetch('contact/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          surname: item.surname,
          marketing: item.marketing,
          legal: item.legal,
          contactTime: item.contactTime,
          potentialDuplicate: item.potentialDuplicate,
          center: {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          phones: item.phones,
          status: item.status,
          created: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async pick({ id }: { id: string }): Promise<ContactCrmType> {
    return this.fetch(`contact/pick?contactId=${id}`, {
      method: 'GET',
    }).then((data) => ({
      id: data.id,
      name: data.name,
      surname: data.surname,
      dni: data.dni,
      marketing: data.marketing,
      potentialDuplicate: data.potentialDuplicate,
      legal: data.legal,
      contactTime: data.contactTime,
      clinicId: data.clinicId,
      center: {
        latitude: data.latitude,
        longitude: data.longitude,
      },
      status: data.status,
      emails: data.emails,
      phones: data.phones,
      created: new Date(data.created),
    }));
  }

  async create({ item }: { item: Partial<ContactCrmType> }): Promise<void> {
    return this.fetch('contact/create', {
      method: 'POST',
      body: {
        ...item,
        center: undefined,
        latitude: item.center?.latitude,
        longitude: item.center?.longitude,
      },
    });
  }

  async createFromCampaign({
    item,
  }: {
    item: Partial<ContactCrmType & { campaignId: string }>;
  }): Promise<void> {
    return this.fetch('contact/create', {
      method: 'POST',
      body: item,
    });
  }

  async update({ item }: { item: Partial<ContactCrmType> }): Promise<void> {
    return this.fetch(`contact/update?id=${item.id}`, {
      method: 'PUT',
      body: {
        ...item,
        center: undefined,
        latitude: item.center?.latitude,
        longitude: item.center?.longitude,
      },
    });
  }

  async delete(properties: { contactId: string }): Promise<void> {
    return this.fetch(`contact/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }

  async enable(properties: { contactId: string }): Promise<void> {
    return this.fetch('contact/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { contactId: string }): Promise<void> {
    return this.fetch('contact/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async sendTemplate(properties: {
    contactId: string;
    templateId: string;
    landbotId: string;
    responsibleId: string;
    clinicId: string;
  }): Promise<void> {
    return this.fetch('contact/template/send', {
      method: 'POST',
      body: properties,
    });
  }
}
