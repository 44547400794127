import { AccordionComponent } from '@components/accordion/accordion.component';
import { SceneComponent } from '@components/scene/scene.component';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export function FaqScreen() {
  const { t } = useTranslation();

  const questions: {
    question: string;
    answer: string;
  }[] = Object.values(t('crm.faq.list', { returnObjects: true }));

  return (
    <SceneComponent icon={'info-circle'} prefix="crm.faq">
      <></>
    </SceneComponent>
  );
}
