import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../contacts.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { NotesComponent } from '@components/notes/notes.component';
import { NoteCrmRepository } from '@human/crm/repositories/note/note.repository';
import { ContactUpdatePart } from './parts/contact.update.part';
import { LeadsUpdatePart } from './parts/leads.update.part';
import { ActionUpdatePart } from './parts/action.update.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { PurchasesUpdatePart } from './parts/purchases.update.part';

export function UpdateContactScreen(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <>
      {/* <ChatComponent /> */}
      <ModalComponent multiWorkspace width={820} icon="user" prefix="crm.contacts.update">
        <TabsComponent
          width={820}
          prefix="crm.contacts.update"
          tabs={[
            {
              name: 'contact',
              icon: 'user',
              content: (settings) => (
                <ContactUpdatePart
                  contactId={properties.route.params.contactId}
                  onSend={() => {
                    settings?.setIsEditing(false);
                    showSuccess();
                  }}
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                />
              ),
            },
            {
              name: 'leads',
              icon: 'bullseye',
              content: () => <LeadsUpdatePart {...properties} />,
            },
            {
              name: 'actions',
              icon: 'calendar-check',
              content: () => <ActionUpdatePart {...properties} />,
            },
            {
              name: 'notes',
              icon: 'sticky-note',
              content: () => {
                return (
                  <NotesComponent
                    repository={{
                      get: () => {
                        return new NoteCrmRepository().findByContact({
                          contactId: properties.route.params.contactId,
                        });
                      },
                      send: (text: string) => {
                        return new NoteCrmRepository().create({
                          item: {
                            text,
                            contactId: properties.route.params.contactId,
                          },
                        });
                      },
                      update: (settings: { text: string; noteId: string }) => {
                        return new NoteCrmRepository().update({
                          text: settings.text,
                          noteId: settings.noteId,
                          contactId: properties.route.params.contactId,
                        });
                      },
                      delete: (noteId: string) => {
                        return new NoteCrmRepository().delete({
                          noteId,
                          contactId: properties.route.params.contactId,
                        });
                      },
                    }}
                  />
                );
              },
            },
            PurchasesUpdatePart(properties),
          ]}
        />
      </ModalComponent>
    </>
  );
}
