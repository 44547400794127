import { IconComponent } from '@components/icon/icon.component';
import { IconType } from '@components/icon/icon.types';
import { TextComponent } from '@components/text/text.component';
import { TouchableOpacity, View } from 'react-native';
import { HeaderStyles } from './header.styles';
import { HeaderProperties } from './header.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Header Component
 * @description Cabecera de la escena con título, descripción e icono, además de
 * enlaces a otras secciones.
 *
 * @param {HeaderProperties} properties
 * @returns {JSX.Element}
 */

export function HeaderComponent(properties: HeaderProperties) {
  const { phone } = useDeviceHook();

  return (
    <View style={HeaderStyles.container}>
      <IconComponent
        name={properties.icon}
        containerstyle={[HeaderStyles.iconContainer, phone && HeaderStyles.iconContainerMobile]}
        iconStyle={[HeaderStyles.iconStyle, phone && HeaderStyles.iconStyleMobile]}
      />
      <View style={{ minWidth: '50%', flexShrink: 1 }}>
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          bold
          text={`${properties.prefix}.title${properties.suffix ? `.${properties.suffix}` : ''}`}
          style={[HeaderStyles.title, phone && HeaderStyles.titleMobile]}
        />
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          text={`${properties.prefix}.description${properties.suffix ? `.${properties.suffix}` : ''}`}
          style={[HeaderStyles.description, phone && HeaderStyles.descriptionMobile]}
        />
      </View>
      <View style={HeaderStyles.linksContainer}>
        {properties.links?.map((link, index) => (
          <>
            {!phone ? (
              <TouchableOpacity key={index} onPress={link.onPress} style={HeaderStyles.link}>
                <IconComponent
                  name={link.icon}
                  containerstyle={{
                    backgroundColor: 'rgb(237, 53, 145)',
                    borderRadius: 100,
                    height: 40,
                    width: 40,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',

                    marginRight: 10,
                  }}
                  iconStyle={HeaderStyles.linkIconStyle}
                />
                <TextComponent
                  multiWorkspace={properties.multiWorkspace}
                  translate
                  text={`${properties.prefix}.${link.name}`}
                  bold
                  style={HeaderStyles.linkText}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={link.onPress}>
                <IconComponent
                  name={link.icon}
                  containerstyle={{
                    backgroundColor: 'rgb(237, 53, 145)',
                    borderRadius: 100,
                    height: 30,
                    width: 30,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                  iconStyle={[HeaderStyles.linkIconStyle, HeaderStyles.linkIconStyleMobile]}
                />
              </TouchableOpacity>
            )}
          </>
        ))}
      </View>
    </View>
  );
}
