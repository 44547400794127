import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { BulletinAccessType } from '@human/access/types/bulletin.access.type';

export class BulletinAccesRepository extends AuthorizedRepository {
  async randomPick(): Promise<Partial<BulletinAccessType>> {
    return this.fetch(`bulletin/randomPick`, {
      method: 'GET',
    }).then((data) => ({
      id: data.id,
      description: data.description,
      image: data.image,
      actorName: data.actorName,
      actorSurname: data.actorSurname,
      authorName: data.authorName,
      clinicName: data.clinicName,
      authorSurname: data.authorSurname,
    }));
  }

  async pick(properties: {
    bulletinId: string;
    clinicId: string;
  }): Promise<Partial<BulletinAccessType>> {
    return this.fetch(`bulletin/pick`, {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      id: data.id,
      clinicId: data.clinicId,
      clinicName: data.clinicName,
      actorId: data.actorId,
      description: data.description,
      image: data.image,
      actorName: data.actorName,
      actorSurname: data.actorSurname,
      authorName: data.authorName,
      authorSurname: data.authorSurname,
      status: data.status,
      created: data.created,
    }));
  }

  async create(properties: { item: Partial<BulletinAccessType> }) {
    return this.fetch('bulletin/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: BulletinAccessType[]; total: number }> {
    return this.fetch(`bulletin/find`, {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((bulletin: BulletinAccessType) => ({
          id: bulletin.id,
          clinicId: bulletin.clinicId,
          clinicName: bulletin.clinicName,
          actorId: bulletin.actorId,
          description: bulletin.description,
          image: bulletin.image,
          actorName: bulletin.actorName,
          actorSurname: bulletin.actorSurname,
          authorName: bulletin.authorName,
          authorSurname: bulletin.authorSurname,
          status: bulletin.status,
          created: bulletin.created,
        })),
        total: data.total,
      };
    });
  }

  async delete(properties: Partial<{ bulletinId: string; clinicId: string; password: string }>) {
    return this.fetch(`bulletin/delete`, {
      method: 'DELETE',
      params: {
        clinicId: properties.clinicId ?? '',
        bulletinId: properties.bulletinId ?? '',
      },
      body: {
        password: properties.password,
      },
    });
  }

  async disable(properties: { bulletinId: string; clinicId: string }) {
    return this.fetch(`bulletin/disable`, {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { bulletinId: string; clinicId: string }) {
    return this.fetch(`bulletin/enable`, {
      method: 'PUT',
      params: properties,
    });
  }
}
