import { TouchableOpacity, View } from 'react-native';
import { ButtonProperties } from '../button.properties';
import { IconComponent } from '@components/icon/icon.component';
import { SecondaryButtonStyles } from './secondary.button.styles';
import { TextComponent } from '@components/text/text.component';
import { useDeviceHook } from '@hooks/device/device.hook';

export function SecondaryButtonComponent(properties: ButtonProperties) {
  const { phone } = useDeviceHook();

  return (
    <TouchableOpacity onPress={properties.onPress} style={SecondaryButtonStyles.button}>
      <View style={SecondaryButtonStyles.buttonContainer}>
        {!phone && (
          <TextComponent
            translate
            text={`${properties.prefix}`}
            bold
            style={SecondaryButtonStyles.buttonLabel}
          />
        )}
        <IconComponent
          name={properties.icon || 'arrow-down'}
          containerstyle={SecondaryButtonStyles.iconContainer}
          iconStyle={SecondaryButtonStyles.icon}
        />
      </View>
    </TouchableOpacity>
  );
}
