import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { FormComponent } from '@components/form/form.component';
import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { TabType } from '@components/tabs/tabs.types';

export function SpecialistUpdatePatient(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'specialist',
    icon: 'user',
    content: () => {
      return (
        <FormComponent<PatientCrmType>
          prefix={'crm.patients.update.specialist.form'}
          readonly={!hasPermissionUtilty({ name: 'crm.patients.editor' })}
          repository={{
            send: (data) => {
              return new PatientCrmRepository()
                .updateSpecialist({
                  specialistId: data.item.specialistId ?? '',
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                })
                .then(() => showSuccess());
            },
            get: async () => {
              const patient = await new PatientCrmRepository().pick({
                id: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
              });
              return {
                specialistId: patient.specialistId ?? '',
              };
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'specialistId',
              required: true,
              pick: (properties: { id: string }) => {
                return new SpecialistAccessRepository()
                  .pick({ id: properties.id })
                  .then((specialist) => `${specialist.name} ${specialist.surname}`);
              },
              repository: (settings) => {
                return new SpecialistAccessRepository()
                  .list({
                    search: settings.search,
                    page: 0,
                    limit: 100,
                    active: true,
                  })
                  .then((data) => {
                    return data.items.map((item) => ({
                      value: item.id,
                      label: `${item.name} ${item.surname}`,
                    }));
                  });
              },
            },
          ]}
        />
      );
    },
  };
}
