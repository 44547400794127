import { IconType } from '../../../components/icon/icon.types';
import { AuthorizedSpaces } from './horse.spaces';
export const AuthorizedSections: {
  [key in keyof AuthorizedSpaces]: {
    icon: IconType;
    keywords: string[];
    version: string;
    time: string;
  };
} = {
  desktop: {
    icon: 'desktop',
    keywords: ['desktop', 'home', 'dashboard', 'main'],
    version: '1.2',
    time: '2024-05-25',
  },
  crm: {
    icon: 'users',
    keywords: ['crm', 'clients', 'customers'],
    version: '1.3',
    time: '2024-04-21',
  },
  clinic: {
    icon: 'clinic-medical',
    keywords: ['clinic', 'hospital', 'medical'],
    version: '1.6',
    time: '2024-03-25',
  },
  access: {
    icon: 'address-book',
    keywords: ['directory', 'contacts', 'address'],
    version: '1.8',
    time: '2024-02-25',
  },
  board: {
    icon: 'tachometer-alt',
    keywords: ['dashboard', 'performance', 'analytics'],
    version: '1.4',
    time: '2024-01-25',
  },
  tory: {
    icon: 'flask',
    keywords: ['laboratory', 'lab', 'science'],
    version: '1.3',
    time: '2024-05-21',
  },
  profile: {
    icon: 'user',
    keywords: ['profile', 'account'],
    version: '1.7',
    time: '2024-06-21',
  },
};
