import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignsRoutes } from '../../campaigns.routes';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function DisableCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignDisable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.campaigns.disable">
      <FormComponent
        prefix="crm.campaigns.disable.form"
        padding
        repository={{
          send: () => {
            return new CampaignCrmRepository()
              .disable({
                campaignId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
