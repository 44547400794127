import { RouteProp } from '@react-navigation/native';
import { CrmRoutes } from '@human/crm/crm.routes';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { ActionsRoutes } from './actions.routes';
import { ListActionsScreen } from './screens/list/list.actions.screen';
import { CreateActionScreen } from './screens/create/create.action.screen';
import { UpdateActionScreen } from './screens/update/update.action.screen';
import { CreateFromCalendarScreen } from './screens/createFromCalendar/createFromCalendar.screen';
import { EnableActionScreen } from './screens/enable/enable.action.screen';
import { DisableActionScreen } from './screens/disable/disable.action.screen';
import { UpdateActionCreateTreatmentScreen } from './screens/update/parts/update.action.createTreatment.part';
import { UpdateActionAddSpecialistScreen } from './screens/update/parts/update.action.addSpecialist.part';
import { UpdateActionTemplatePart } from './screens/update/parts/update.action.template.part';
import { UpdatePatientScreen } from '../patients/screens/update/update.patient.screen';

const Navigation = NavigationComponent<ActionsRoutes>();

export function ActionsSection(properties: { route: RouteProp<CrmRoutes, 'actions'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListActionsScreen} />
      <Navigation.Screen name="actionCreate" component={CreateActionScreen} />
      <Navigation.Screen name="actionUpdate" component={UpdateActionScreen} />
      <Navigation.Screen name="actionDisable" component={DisableActionScreen} />
      <Navigation.Screen name="actionEnable" component={EnableActionScreen} />
      <Navigation.Screen name="actionCreateFromCalendar" component={CreateFromCalendarScreen} />
      <Navigation.Screen
        name="updateActionCreateTreatment"
        component={UpdateActionCreateTreatmentScreen}
      />
      <Navigation.Screen
        name="updateActionAddSpecialist"
        component={UpdateActionAddSpecialistScreen}
      />
      <Navigation.Screen name="updateActionTemplate" component={UpdateActionTemplatePart} />
      <Navigation.Screen name="patientUpdate" component={UpdatePatientScreen} />
    </Navigation.Navigator>
  );
}
