import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ChannelsRoutes } from '../../channels.routes';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateChannelScreen(properties: {
  navigation: NavigationProp<ChannelsRoutes>;
  route: RouteProp<ChannelsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="user" prefix="crm.channels.update">
      <FormComponent<any>
        padding
        prefix="crm.channels.update.form"
        readonly={!hasPermissionUtilty({ name: 'crm.channels.editor' })}
        repository={{
          send: (data) => {
            return new ChannelCrmRepository()
              .update({
                item: data.item,
              })
              .then(() => {
                showSuccess();
              });
          },
          get: () => {
            return new ChannelCrmRepository().pick({
              id: properties.route.params.id,
            });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            required: true,
            description: true,
            validations: ['name'],
          },
        ]}
      />
    </ModalComponent>
  );
}
