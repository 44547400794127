import { createStackNavigator } from '@react-navigation/stack';
import { AuthorizedBrands } from './authorized.brands';
import { HorseNavigator } from './horse/horse.screen';
import { HumanNavigator } from './human/authorized.screen';

const Drawer = createStackNavigator<AuthorizedBrands>();
export function AuthorizedScreen() {
  const groupId = '821ab519-3e38-433a-82f5-5519316b68f1';

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Drawer.Screen
        name="human"
        component={HumanNavigator}
        initialParams={{
          groupId: groupId,
        }}
      />
      <Drawer.Screen
        name="horse"
        component={HorseNavigator}
        initialParams={{
          groupId: groupId,
        }}
      />
    </Drawer.Navigator>
  );
}
