import { NavigationComponent } from '@components/navigation/navigation.component';
import { PhasesRoutes } from './phase.routes';
import { CreatePhaseScreen } from './screens/create/create.phase.screen';
import { UpdatePhaseScreen } from './screens/update/update.phase.screen';
import { DisablePhaseScreen } from './screens/disable/disable.phase.screen';
import { EnablePhaseScreen } from './screens/enable/enable.phase.screen';
import { ListPhasesScreen } from './screens/list/list.phase.screen';
import { CreateCauseScreen } from '../cause/screens/create/create.cause.screen';
import { UpdateCauseScreen } from '../cause/screens/update/update.cause.screen';

const Navigation = NavigationComponent<PhasesRoutes>();

export function PhasesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListPhasesScreen} />
      <Navigation.Screen name="create" component={CreatePhaseScreen} />
      <Navigation.Screen name="update" component={UpdatePhaseScreen} />
      <Navigation.Screen name="disable" component={DisablePhaseScreen} />
      <Navigation.Screen name="enable" component={EnablePhaseScreen} />
      <Navigation.Screen name="causeCreate" component={CreateCauseScreen} />
      <Navigation.Screen name="causeUpdate" component={UpdateCauseScreen} />
    </Navigation.Navigator>
  );
}
