import { FormComponent } from '@components/form/form.component';

import { NavigationProp } from '@react-navigation/native';
import { t } from 'i18next';
import { ProgramsRoutes } from '../../programs.routes';
import { ProgramCrmType } from '@human/crm/types/program.crm.type';
import { ProgramCrmRepository } from '@human/crm/repositories/program/program.repository';

export function FormProgramScreen(properties: { navigation: NavigationProp<ProgramsRoutes> }) {
  return (
    <FormComponent<Partial<ProgramCrmType>>
      prefix={'crm.programs.create.form'}
      padding
      repository={{
        send: (data) => {
          return new ProgramCrmRepository()
            .create({
              item: data.item,
            })
            .then(() => {
              properties.navigation.navigate('list');
            });
        },
      }}
      fields={[
        {
          type: 'text',
          name: 'name',
          required: true,
          validations: ['name'],
          description: true,
        },
        {
          type: 'text',
          name: 'description',
          required: true,
          validations: ['description'],
          description: true,
          lines: 6,
        },
        {
          type: 'select',
          name: 'complexity',
          options: [
            {
              value: 'HIGH',
              label: t('common.complexity.HIGH'),
            },
            {
              value: 'LOW',
              label: t('common.complexity.LOW'),
            },
          ],
          description: true,
        },
        {
          type: 'float',
          name: 'price',
          required: true,
          description: true,
          validations: ['price'],
        },
        {
          type: 'text',
          name: 'code',
          required: true,
          validations: ['code'],
          description: true,
        },
      ]}
    />
  );
}
