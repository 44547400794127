import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { ContactUpdateFormPart } from './form/contact.update.form.part';
import { UpdateOvoBankContactForm } from './form/ovobankContact.update.form.part';

export function ContactUpdatePart(properties: {
  contactId: string;
  onSend: () => void;
  onChange: (properties: { item: Partial<ContactCrmType>; isLocal: boolean }) => void;
}) {
  const form = Workspace.select({
    ovoclinic: ContactUpdateFormPart(properties),
    ovobank: UpdateOvoBankContactForm(properties),
  });

  return <>{form}</>;
}
