// Modules
import { StyleSheet } from 'react-native';

/**
 * Logotype Styles
 * @constant {StyleSheet} LogotypeStyles
 */
export const LogotypeStyles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 50,
  },
  littleContainer: {
    margin: 20,
    marginTop: 50,
  },
  desklogoContainer: {
    flexDirection: 'row',
  },
  logo: {
    color: 'rgb(237, 53, 145)',
    fontSize: 40,
    lineHeight: 40,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
  },
  desk: {
    color: 'white',
    fontSize: 40,
    lineHeight: 40,
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
  },
  byLogotypeContainer: {
    gap: 5,
    marginLeft: 3,
    flexDirection: 'row',
  },
  byText: {
    color: 'white',
    fontSize: 22,
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
  },
  logoTypeText: {
    color: 'white',
    fontSize: 22,
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    opacity: 0.8,
  },
});
