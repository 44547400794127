import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { LanguagesRoutes } from '../../languages.routes';
import { LanguageAccessType } from '@human/access/types/language.access.type';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListLanguagesScreen(properties: {
  navigation: NavigationProp<LanguagesRoutes>;
  route: RouteProp<LanguagesRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.languages.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }
  const { phone } = useDeviceHook();

  const columns: ColumnProperties<LanguageAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.languages.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          languageId: row.id,
          countryId: row.countryId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          languageId: row.id,
          countryId: row.countryId,
        });
      },
    },
    {
      name: 'code',
      type: 'code',
      width: 100,
      onRender: (row) => row.code,
    },
    {
      name: 'country',
      type: 'text',
      width: 200,
      onRender: (row) => row.countryNameEs,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          languageId: row.id,
          countryId: row.countryId,
        });
      },
    },
  ];

  if (hasPermissionUtilty({ name: 'access.languages.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          languageId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent
      icon={AccessSections.languages.icon}
      prefix={'access.languages.list'}
      links={links}
    >
      <TableComponent<LanguageAccessType, []>
        prefix={'access.languages.list'}
        suffix={'list'}
        search
        scrollable={phone ?? undefined}
        pagination={false}
        repository={(settings) => {
          return new LanguageAccessRepository().list({
            order: settings.order || 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
