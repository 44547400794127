import { ModalComponent } from '@components/modal/modal.component';
import { CauseRoutes } from '../../cause.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { CauseCrmType } from '@human/crm/types/cause.crm.type';
import { CauseCrmRepository } from '@human/crm/repositories/cause/cause.repository';

export function CreateCauseScreen(properties: {
  navigation: NavigationProp<CauseRoutes>;
  route: RouteProp<CauseRoutes, 'causeCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="resolving"
      prefix="crm.causes.create"
    >
      <FormComponent<CauseCrmType>
        prefix="crm.causes.create.form"
        padding
        repository={{
          send: (settings) => {
            return new CauseCrmRepository()
              .create({
                item: settings.item,
                phaseId: properties.route.params.phaseId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            validations: ['name'],
            description: true,
            required: true,
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            validations: ['description'],
            lines: 6,
            required: true,
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
            required: true,
          },
          {
            name: 'color',
            type: 'color',
            description: true,
            required: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
