import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ContactsRoutes } from '../../contacts.routes';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';

export function DisableContactscreen(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'contactDisable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.contacts.disable">
      <FormComponent
        prefix="crm.contacts.disable.form"
        padding
        repository={{
          send: () => {
            return new ContactCrmRepository()
              .disable({
                contactId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
