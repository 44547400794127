import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SpecialistsRoutes } from '../../../specialists.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { SpecialistContractAccessType } from '@human/access/types/specialistContract.access.type';
import { ButtonComponent } from '@components/button/button.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';

export function UpdateSpecialistContractsPart(properties: {
  navigation: NavigationProp<SpecialistsRoutes, 'update'>;
  route: RouteProp<SpecialistsRoutes, 'update'>;
}): TabType {
  return {
    name: 'contracts',
    icon: 'user',
    content: () => {
      return (
        <>
          <TableComponent<SpecialistContractAccessType, []>
            key={'contracts'}
            prefix={'access.specialists.update.contracts.list'}
            suffix={'list'}
            repository={(settings) => {
              return new SpecialistAccessRepository().listContracts({
                id: properties.route.params.specialistId,
                search: settings.search,
              });
            }}
            columns={[
              {
                type: 'icon',
                icon: 'trash',
                onPress: (row) => {
                  properties.navigation.navigate('contractDelete', {
                    specialistId: properties.route.params.specialistId,
                    clinicId: row.clinicId,
                    level: properties.route.params.level + 1,
                  });
                },
              },
              {
                type: 'text',
                width: 169,
                onRender: (row) => row.clinicName,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.specialists.update.contracts.add"
            onPress={() => {
              properties.navigation.navigate('contractCreate', {
                specialistId: properties.route.params.specialistId,
                level: properties.route.params.level + 1,
              });
            }}
          />
        </>
      );
    },
  };
}
