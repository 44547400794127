import { NavigationComponent } from '@components/navigation/navigation.component';
import { ResultsRoutes } from './result.routes';
import { ListResultsScreen } from './screens/list/list.result.screen';
import { CreateResultScreen } from './screens/create/create.result.screen';
import { UpdateResultScreen } from './screens/update/update.result.screen';
import { DisableResultScreen } from './screens/disable/disable.result.screen';
import { EnableResultScreen } from './screens/enable/enable.result.screen';

const Navigation = NavigationComponent<ResultsRoutes>();

export function ResultsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="resultList" component={ListResultsScreen} />
      <Navigation.Screen name="resultCreate" component={CreateResultScreen} />
      <Navigation.Screen name="resultUpdate" component={UpdateResultScreen} />
      <Navigation.Screen name="resultDisable" component={DisableResultScreen} />
      <Navigation.Screen name="resultEnable" component={EnableResultScreen} />
    </Navigation.Navigator>
  );
}
