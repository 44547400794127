// Modules
import { StyleSheet } from 'react-native';

/**
 * Launch Styles
 * @constant {StyleSheet} LaunchStyles
 */
export const LaunchStyles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  containerPadding: {
    flex: 0,
  },
  introduction: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
    width: '100%',
  },
  title: {
    color: 'white',
    fontSize: 30,
    marginBottom: 5,
  },
  subtitle: {
    color: 'white',
    fontSize: 20,
    marginTop: -5,
    opacity: 0.5,
    marginBottom: 15,
  },
  separator: {
    height: 5,
    marginLeft: -10,
    marginBottom: 15,
    width: 50,
    backgroundColor: 'rgb(237, 53, 145)',
  },
  description: {
    color: 'white',
    lineHeight: 22,
    fontSize: 14,
    maxWidth: 550,
    fontWeight: 'bold',
    marginBottom: 40,
  },
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    justifyContent: 'center',
    marginVertical: 10,
  },
  cardContainerVertical: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  cardContainerWrap: {
    flexWrap: 'wrap',
    height: 400,
  },
});
