import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActionsRoutes } from '../../actions.routes';

export function DisableActionScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionDisable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.actions.disable">
      <FormComponent
        prefix="crm.actions.disable.form"
        padding
        repository={{
          send: () => {
            return new ActionCrmRepository()
              .disable({
                actionId: properties.route.params.actionId,
                leadId: properties.route.params.leadId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
