import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ProfileType } from '../../types/profile.type';

export class AccountProfileRepository extends AuthorizedRepository {
  async pick(): Promise<ProfileType> {
    return this.fetch('profile/pick', {
      method: 'GET',
    }).then((data) => ({
      id: data.id,
      name: data.name,
      surname: data.surname,
      username: data.username,
      phone: data.phone,
      email: data.email,
    }));
  }

  async update(properties: { item: Partial<ProfileType> }): Promise<any> {
    return this.fetch('profile/update', {
      method: 'PUT',
      body: properties.item,
    }).then((data) => data);
  }
}
