export const colorValues = [
  '#F94144',
  '#591f0a',
  '#F8961E',
  '#F9844A',
  '#F9C74F',
  '#90BE6D',
  '#43AA8B',
  '#577590',
  '#277DA1',
  '#023047',
  '#D81159',
  '#8F2D56',
  '#73D2DE',
  '#218380',
  '#ed3591',
  '#a663cc',
];
