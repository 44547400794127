import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LanguagesRoutes } from '../../languages.routes';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { LanguageAccessType } from '@human/access/types/language.access.type';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
export function UpdateLanguageScreen(properties: {
  navigation: NavigationProp<LanguagesRoutes>;

  route: RouteProp<LanguagesRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="language" flex={false} prefix="access.languages.update">
      <FormComponent<LanguageAccessType>
        prefix="access.languages.update.form"
        padding
        readonly={!hasPermissionUtilty({ name: 'access.languages.editor' })}
        repository={{
          get: () => {
            return new LanguageAccessRepository().pick({
              languageId: properties.route.params.languageId,
            });
          },
          send: (data) => {
            return new LanguageAccessRepository()
              .update({
                languageId: properties.route.params.languageId,
                item: data.item,
              })
              .then(() => showSuccess());
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
          },
          {
            name: 'code',
            type: 'text',
            description: true,
            required: true,
            validations: ['countryCode'],
          },
          {
            name: 'countryCode',
            type: 'select',
            description: true,
            pick: () => {
              return new CountryAccessRepository()
                .pick({ countryId: properties.route.params.countryId })
                .then((country) => {
                  return `${country.countryNameEs}`;
                });
            },
            repository: ({ search }) => {
              return new CountryAccessRepository()
                .list({
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((response) => {
                  return response.items.map((item) => ({
                    label: `${item.flag} ${item.countryNameEs}`,
                    value: item.code,
                  }));
                });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
