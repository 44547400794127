import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../../campaigns.routes';
import { Text } from 'react-native';
import { apiUrl } from '@environment/apiUrl.utility';
import { CopyToClipboard } from '@components/copyToClipboard/copy.to.clipboard.component';
import { useTranslation } from 'react-i18next';
import { WebhookCampaignTabStyles } from './webhook.campaign.styles';

export function WebhookCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Text style={WebhookCampaignTabStyles.label}>Link</Text>
      <CopyToClipboard
        value={`${apiUrl}/webhook/lead?campaignId=${properties.route.params.id}`}
        toastText={t('crm.campaigns.update.tabs.webhook.toast') || 'copied'}
      />
    </>
  );
}
