import { TextComponent } from '@components/text/text.component';
import { useEffect, useState } from 'react';
import { AgoProperties } from './ago.properties';

/**
 * AgoComponent renders a text component that displays the time elapsed since a given date.
 *
 * @param {AgoProperties} properties - The properties object containing the date and style of the text component.
 *
 * @returns {JSX.Element} The rendered JSX element containing the text component.
 *
 * @example
 * <AgoComponent date={new Date()} />
 */

export function AgoComponent(properties: AgoProperties) {
  const [ago, setAgo] = useState<string>('');

  const formatAgo = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      setAgo(`${days} days ago`);
    } else if (hours > 0) {
      setAgo(`${hours} hours ago`);
    } else if (minutes > 0) {
      setAgo(`${minutes} minutes ago`);
    } else {
      setAgo(`${seconds} seconds ago`);
    }
  };

  useEffect(() => {
    formatAgo(properties.date);
    const interval = setInterval(() => {
      formatAgo(properties.date);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <TextComponent text={ago} style={properties.style} />;
}
