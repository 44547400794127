// Modules
import { View, Text } from 'react-native';

// Components
import { RawIconComponent } from './rawIconComponent';

// Properties
import { type IconProperties } from './icon.properties';

/**
 * Icon Component
 * @description Component to render an icon could be clickable or not. You can see list of icons here: https://icons8.com/line-awesome
 * @function IconComponent
 * @param {IconProperties} properties
 * @return {JSX.Element}
 */
export function IconComponent(properties: IconProperties): JSX.Element {
  return (
    <View style={properties.containerstyle}>
      <RawIconComponent name={properties.name} iconStyle={properties.iconStyle} />
    </View>
  );
}
