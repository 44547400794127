// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const DateInputStyles = StyleSheet.create({
  container: {},
  button: {
    margin: 20,
  },
  statusContainer: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  cancelStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },
  statusStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 27,
  },
  iconStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },
  calendar: {
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: 5,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  icon: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    dateAlign: 'center',
    lineHeight: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  inputReadonly: {
    backgroundColor: '#DDDDDD',
    color: '#777777',
    minHeight: 42,
    borderRadius: 5,
  },
  input: {
    borderRadius: 5,
    backgroundColor: 'white',
    height: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
    cursor: 'pointer',
    paddingLeft: 15,
  },
  inputIcon: {
    paddingLeft: 50,
  },
  inputNoIcon: {
    paddingLeft: 10,
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
});
