import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class NotificationCommonRepository extends AuthorizedRepository {
  async list() {
    return this.fetch('notification/find', {
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((notification: any) => ({
        id: notification.notificationId,
        content: notification.content,
        title: notification.title,
        type: notification.type,
        resourceId: notification.resourceId,
        created: new Date(notification.created),
      })),
      total: data.total,
    }));
  }

  async count() {
    return this.fetch('notification/count', {
      method: 'GET',
    });
  }

  async markAsRead(properties: { notificationId: string }) {
    return this.fetch('notification/markAsRead', {
      method: 'PUT',
      body: {
        notificationId: properties.notificationId,
      },
    });
  }
}
