export const ItLocale = {
  logotype: {
    horse: 'Ovocavallo',
    human: 'Ovoclinico',
    ovobank: 'Ovobank',
    ovoclinic: 'Ovoclinico',
  },
  calendar: {
    hour: 'Ora',
    minutes: ':',
    save: 'Mantenere',
    placeholder: 'Seleziona una data',
  },
  historical: {
    deleted: 'Eliminato',
    created: 'Creato',
    updated: 'Aggiornato',
  },
  chat: {
    placeholder: 'Scrivi un messaggio',
    title: 'Contatta chattare',
  },
  slicer: {
    confirm: 'Selezionare',
  },
  export: {
    title: 'Esportare',
    description: 'Esporta i dati della tabella in un file CSV',
  },
  import: {
    title: 'Questione',
  },
  transfer: {
    title: 'Trasferire',
  },
  createTaskMassively: {
    title: 'Crea compiti',
  },
  leadEvents: {
    informed: 'Commerciale V.I effettuato',
    contacted: 'Contattato',
    attended: 'Medical V.I eseguito',
    appointed: 'Spot V.I citato',
    medicalAppointed: 'Citato V.I Medical',
    medicalEvaluated: 'Valutazione medica',
    comercialAttended: 'Bilancio consegnato',
    converted: 'Convertito',
  },
  form: {
    tabChange: {
      title: 'Stai modificando un modulo. ',
    },
    update: {
      successToast: 'Modulo aggiornato con successo',
    },
    checkbox: {
      errors: {
        required: 'Devi accettare per continuare',
      },
    },
    selectable: {
      placeholder: {
        title: "Seleziona un'opzione",
        empty: 'Non ci sono risultati',
      },
    },
    date: {
      placeholder: 'Filtra per data',
    },
    json: {
      errors: {
        geojson: 'Formato GeoJSON non valido',
      },
    },
    text: {
      errors: {
        notString: 'Il valore deve essere una stringa di testo',
        ipIsRequired: "L'indirizzo IP è obbligatorio",
        ipMustBeAString: "L'indirizzo IP deve essere una stringa di testo",
        ipMustBeAValidIpAddress: "L'indirizzo IP deve essere valido",
        countryNameEn: 'Il nome è obbligatorio',
        countryNameEs: 'Il nome è obbligatorio',
        nameIsRequired: 'Il nome è obbligatorio',
        nameMustBeAString: 'Il nome deve essere una stringa di testo',
        nameMustNotContainNumbers: 'Il nome non deve contenere numeri',
        nameMustNotContainSpecialCharacters: 'Il nome non deve contenere caratteri speciali',
        nameMustBeAtMost50CharactersLong: 'Il nome deve contenere un massimo di 50 caratteri',
        dniIsRequired: "È richiesto un documento d'identità",
        dniMustBeAtLeast8CharactersLong: "L'ID deve contenere almeno 8 caratteri",
        dniMustBeAtMost20CharactersLong: "L'ID deve contenere un massimo di 20 caratteri",
        surnameIsRequired: 'Il cognome è obbligatorio',
        surnameMustBeAString: 'Il cognome deve essere una stringa di testo',
        surnameMustNotContainNumbers: 'Il cognome non deve contenere numeri',
        surnameMustNotContainSpecialCharacters: 'Il cognome non deve contenere caratteri speciali',
        surnameMustBeAtMost50CharactersLong: 'Il cognome deve avere un massimo di 50 caratteri',
        emailIsRequired: "L'e-mail è obbligatoria",
        emailMustBeAString: "L'e-mail deve essere una stringa di testo",
        emailMustBeAValidEmail: "L'e-mail deve essere valida",
        emailMustBeAtLeast3CharactersLong: "L'e-mail deve contenere almeno 3 caratteri",
        emailMustBeAtMost50CharactersLong: "L'e-mail deve contenere un massimo di 30 caratteri",
        emailMustContainAnAtSymbol: "L'e-mail deve contenere un simbolo @",
        emailMustNotContainWhitespace: "L'e-mail non deve contenere spazi bianchi",
        passwordIsRequired: 'È richiesta la password',
        passwordMustBeAString: 'La password deve essere una stringa di testo',
        passwordMustBeAtLeast8CharactersLong: 'La password deve contenere almeno 8 caratteri',
        passwordMustBeAtMost30CharactersLong: 'La password deve avere un massimo di 30 caratteri',
        passwordMustContainAnUppercaseLetter: 'La password deve contenere una lettera maiuscola',
        passwordMustContainALowercaseLetter: 'La password deve contenere una lettera minuscola',
        passwordMustContainANumber: 'La password deve contenere un numero',
        passwordMustContainASpecialCharacter: 'La password deve contenere un carattere speciale',
        passwordMustNotContainWhitespace: 'La password non deve contenere spazi vuoti',
        passwordsDoNotMatch: 'Le password non corrispondono',
        valueMustBeAnInteger: 'Il valore deve essere un numero intero',
        valueMustBeAValidNumber: 'Il valore deve essere un numero valido',
        valueMustBePositive: 'Il valore deve essere positivo',
        TEXT_TOO_SHORT: 'Il testo è troppo breve',
        WRONG_EMAIL: "Il formato dell'e-mail non è corretto",
        REQUIRED: 'Questo campo è obbligatorio',
        titleMustBeAString: 'Il titolo deve essere una stringa di testo',
        titleIsRequired: 'Il titolo è obbligatorio',
        titleMustBeAtLeast3CharactersLong: 'Il titolo deve contenere almeno 3 caratteri',
        titleMustBeAtMost100CharactersLong: 'Il titolo deve avere un massimo di 100 caratteri',
        codeMustBeAString: 'Il codice deve essere una stringa di testo',
        codeIsRequired: 'Il codice è obbligatorio',
        codeMustBeAtLeast3CharactersLong: 'Il codice deve contenere almeno 3 caratteri',
        codeMustBeAtLeast10CharactersLong: 'Il codice deve contenere almeno 10 caratteri',
        codeMustBeAtMost30CharactersLong: 'Il codice deve avere un massimo di 30 caratteri',
        prefixIsRequired: 'Il prefisso è obbligatorio',
        prefixInvalidLength: 'Il prefisso deve essere composto da 2 o 3 cifre',
        prefixMustContainOnlyNumbers: 'Il prefisso deve contenere solo numeri',
        prefixFirstCharacterMustBePlusSign: 'Il prefisso deve iniziare con il segno +',
        prefixMustBeAtLeast1CharactersLong: 'Il prefisso ha almeno 1 cifra',
        prefixMustBeAtMost3CharactersLong: 'Il prefisso ha un massimo di 3 cifre',
        countryCodeMustBeAString: 'Il codice paese deve essere una stringa di testo',
        countryCodeIsRequired: 'Il codice paese è obbligatorio',
        countryCodeMustBe2CharactersLong: 'Il codice paese deve contenere 2 caratteri',
        countryCodeMustBeUppercase: 'Il codice del paese deve essere in maiuscolo',
        phoneIsRequired: 'È richiesto il telefono',
        phoneMustBeAString: 'Il telefono deve essere una stringa di testo',
        phoneMustContainOnlyNumbers: 'Il telefono deve contenere solo numeri',
        phoneMustNotContainWhitespace: 'Il telefono non deve contenere spazi vuoti',
        phoneMustBeAtLeast9CharactersLong: 'Il numero di telefono deve contenere almeno 9 cifre',
        phoneMustBeAtMost12CharactersLong:
          'Il numero di telefono deve avere un massimo di 12 cifre',
        extensionIsRequired: "È necessaria l'estensione",
        extensionMustContainOnlyNumbers: "L'interno deve contenere solo numeri",
        extensionMustBeAtLeast4CharactersLong: "L'estensione deve contenere almeno 4 cifre",
        descriptionIsRequired: 'La descrizione è obbligatoria',
        descriptionMustBeAString: 'La descrizione deve essere una stringa di testo',
        descriptionMustBeAtMost500CharactersLong:
          'La descrizione deve avere un massimo di 500 caratteri',
        observationIsRequired: "È necessaria l'osservazione",
        observationMustBeAString: "L'osservazione deve essere una stringa di testo",
        observationMustBeAtLeast1CharactersLong: "L'osservazione deve avere almeno 1 carattere",
        observationMustBeAtMost150CharactersLong:
          'Il commento deve contenere un massimo di 150 caratteri',
        observationMustBeAtMost500CharactersLong:
          'Il commento deve contenere un massimo di 500 caratteri',
        usernameIsRequired: 'Il nome utente è obbligatorio',
        usernameMustBeAString: 'Il nome utente deve essere una stringa di testo',
        usernameMustBeAtLeast3CharactersLong: 'Il nome utente deve contenere almeno 3 caratteri',
        usernameMustBeAtMost30CharactersLong:
          'Il nome utente deve contenere un massimo di 30 caratteri',
        usernameMustNotContainSpecialCharacters:
          'Il nome utente non deve contenere caratteri speciali',
        usernameMustNotContainWhitespace: 'Il nome utente non deve contenere spazi bianchi',
        zipIsRequired: 'Il codice postale è obbligatorio',
        zipMustBeAString: 'Il codice postale deve essere una stringa di testo',
        zipMustBe5CharactersLong: 'Il codice postale deve contenere 5 caratteri',
        zipMustContainOnlyNumbers: 'Il codice postale deve contenere solo numeri',
        zipMustNotContainWhitespace: 'Il codice postale non deve contenere spazi vuoti',
        zipMustNotContainSpecialCharacters:
          'Il codice postale non deve contenere caratteri speciali',
        streetIsRequired: 'La strada è obbligatoria',
        streetMustBeAString: 'La strada deve essere una stringa di testo',
        streetMustBeAtLeast8CharactersLong: 'La strada deve contenere almeno 8 caratteri',
        cityIsRequired: 'La città è obbligatoria',
        cityMustBeAString: 'La città deve essere una stringa di testo',
        stateIsRequired: 'Lo Stato è necessario',
        stateMustBeAString: 'Lo stato deve essere una stringa di testo',
        coinIsRequired: 'La valuta è richiesta',
        coinMustBeAString: 'La valuta deve essere una stringa di testo',
        flagIsRequired: 'La bandiera è obbligatoria',
        flagMustBeAString: 'Il flag deve essere una stringa di testo',
        subjectIsRequired: 'La questione è necessaria',
        subjectMustBeAString: "L'oggetto deve essere una stringa di testo",
        subjectMustBeAtLeast3CharactersLong: "L'oggetto deve contenere almeno 3 caratteri",
        subjectMustBeAtMost30CharactersLong: "L'oggetto deve contenere un massimo di 30 caratteri",
        messageIsRequired: 'Il messaggio è obbligatorio',
        messageMustBeAString: 'Il messaggio deve essere una stringa di testo',
        messageMustBeAtLeast3CharactersLong: 'Il messaggio deve contenere almeno 3 caratteri',
        messageMustBeAtMost500CharactersLong:
          'Il messaggio deve contenere un massimo di 500 caratteri',
        hourIsRequired: 'Il tempo è necessario',
        hourMustBeValidFormat: "L'ora deve essere in un formato valido: HH:MM",
        documentIsRequired: 'Il documento è obbligatorio',
        documentMustBeAString: 'Il documento deve essere una stringa di testo',
        documentMustBeAtLeast8CharactersLong: 'Il documento deve contenere almeno 8 caratteri',
        codeMustBeAtLeast2CharactersLong: 'Il codice deve contenere almeno 2 caratteri',
        required: 'Questo campo è obbligatorio',
        name: {
          notString: 'Il nome deve essere una stringa di testo',
          tooShort: 'Il nome è troppo corto',
          tooLong: 'Il nome è troppo lungo',
          invalidCharacters: 'Il nome contiene caratteri non validi',
        },
        email: {
          notValid: "L'e-mail non è valida",
          notString: "L'e-mail deve essere una stringa di testo",
        },
        phone: {
          invalid: 'Il telefono non è valido',
          notString: 'Il telefono deve essere una stringa di testo',
        },
        surname: {
          notString: 'Il cognome deve essere una stringa di testo',
          tooShort: 'Il cognome è troppo corto',
          tooLong: 'Il cognome è troppo lungo',
          invalidCharacters: 'Il cognome contiene caratteri non validi',
        },
        boolean: {
          notBoolean: 'Il valore deve essere booleano',
          truthy: {
            notTruthy: 'Il valore deve essere vero',
          },
        },
        password: {
          notString: 'La password deve essere una stringa di testo',
          tooShort: 'La password è troppo corta',
          tooLong: 'La password è troppo lunga',
          invalidCharacters: 'La password contiene caratteri non validi',
          noUpperCase: 'La password deve contenere almeno una lettera maiuscola',
          noLowerCase: 'La password deve contenere almeno una lettera minuscola',
          noNumber: 'La password deve contenere almeno un numero',
          noSpecialCharacter: 'La password deve contenere almeno un carattere speciale',
        },
        number: {
          notNumber: 'Il valore deve essere un numero',
        },
        positive: {
          notPositive: 'Il valore deve essere positivo',
          notNumber: 'Il valore deve essere un numero',
        },
        price: {
          notNumber: 'Il valore deve essere un numero',
          notPositive: 'Il valore deve essere positivo',
          notString: 'Il valore deve essere una stringa di testo',
        },
        negative: {
          notNegative: 'Il valore deve essere negativo',
          notNumber: 'Il valore deve essere un numero',
        },
        uuid: {
          notUuid: 'Il valore deve essere un UUID',
          notString: 'Il valore deve essere una stringa di testo',
          empty: 'Il valore non può essere vuoto',
        },
        object: {
          notValid: 'Il valore deve essere un oggetto',
        },
        description: {
          notString: 'La descrizione deve essere una stringa di testo',
          tooShort: 'La descrizione è troppo breve',
          tooLong: 'La descrizione è troppo lunga',
        },
        observation: {
          notString: "L'osservazione deve essere una stringa di testo",
          tooShort: "L'osservazione è troppo breve",
          tooLong: "L'osservazione è troppo lunga",
        },
        date: {
          notValid: 'La data non è valida',
          notString: 'La data deve essere una stringa di testo',
        },
        color: {
          notString: 'Il colore deve essere una stringa di testo',
          notHex: 'Il colore deve essere un codice esadecimale',
        },
        code: {
          notString: 'Il codice deve essere una stringa di testo',
          tooShort: 'Il codice è troppo corto',
          tooLong: 'Il codice è troppo lungo',
        },
        image: {
          notString: "L'immagine deve essere una stringa di testo",
          notBase64: "L'immagine deve essere un codice base64",
        },
        phonePrefix: {
          notString: 'Il prefisso deve essere una stringa di testo',
          notValid: 'Il prefisso non è valido',
        },
        countryCode: {
          notString: 'Il codice paese deve essere una stringa di testo',
          notValid: 'Il codice paese non è valido',
        },
        zip: {
          notString: 'Il codice postale deve essere una stringa di testo',
          notLength: 'Il codice postale deve contenere 5 caratteri',
          notNumber: 'Il codice postale deve contenere solo numeri',
          hasSpace: 'Il codice postale non deve contenere spazi vuoti',
          hasLetter: 'Il codice postale non deve contenere lettere',
          hasSymbol: 'Il codice postale non deve contenere simboli',
        },
        title: {
          notString: 'Il titolo deve essere una stringa di testo',
          tooShort: 'Il titolo è troppo corto',
          tooLong: 'Il titolo è troppo lungo',
        },
        username: {
          notString: 'Il nome utente deve essere una stringa di testo',
          tooShort: 'Il nome utente è troppo corto',
          tooLong: 'Il nome utente è troppo lungo',
          invalidCharacters: 'Il nome utente contiene caratteri non validi',
        },
        ip: {
          notString: "L'indirizzo IP deve essere una stringa di testo",
          notValid: "L'indirizzo IP non è valido",
        },
        state: {
          notString: 'Lo stato deve essere una stringa di testo',
        },
        city: {
          notString: 'La città deve essere una stringa di testo',
        },
        street: {
          notString: 'La strada deve essere una stringa di testo',
        },
        coin: {
          notString: 'La valuta deve essere una stringa di testo',
        },
        flag: {
          notString: 'Il flag deve essere una stringa di testo',
        },
      },
    },
  },
  analytics: {
    title: 'Analitica',
    filter: 'Confronto tra la scorsa settimana e questa settimana',
    activities: {
      title: 'Compiti',
    },
    leads: {
      title: 'Conduce',
    },
    results: {
      title: 'Risultati',
    },
  },
  history: {
    title: 'Documentazione',
  },
  performance: {
    title: 'Prestazione',
  },
  sessions: {
    title: 'Sessioni attive',
  },
  security: {
    title: 'Sistema protetto',
  },
  launch: {
    title: 'Il futuro della riproduzione assistita',
    subtitle: 'Tracciabilità e sicurezza per la tua clinica',
    description: {
      ovoclinic:
        'Software protetto di proprietà di Ovoclinic per la gestione delle cliniche di riproduzione assistita. ',
      ovobank:
        'Software protetto di proprietà di Ovobank per la gestione delle banche degli ovociti. ',
    },
    crm: {
      description: 'Spazio alla gestione dei clienti e al miglioramento della relazione con loro. ',
    },
    clinic: {
      description:
        'Tutto ciò di cui hai bisogno per gestire la tua clinica, ospedale o centro medico. ',
    },
    access: {
      description: 'Controllo accessi e gestione utenti. ',
    },
    board: {
      description: 'Pannello di controllo e monitoraggio degli indicatori. ',
    },
    tory: {
      description: 'Risultati clinici e tracciabilità dei test di laboratorio. ',
    },
    profile: {
      description: "Gestione delle informazioni personali e dell'account. ",
    },
  },
  access: {
    menu: {
      users: 'Utenti',
      roles: 'Ruoli',
      specialists: 'Specialisti',
      clinics: 'Cliniche',
      planning: 'Pianificazione',
      timetables: 'Orari',
      bulletins: 'Notizia',
      departments: 'Dipartimenti',
      languages: 'Lingue',
      countries: 'Paesi',
      settings: 'Configurazione',
      directory: 'Direttorio',
      locations: 'Posizioni',
      globalization: 'Globalizzazione',
      communications: 'Comunicazioni',
      communicationChannels: 'Vie di comunicazione',
      ovopoints: 'Ovopunti',
      landbot: 'Landbot',
      templates: 'Modelli',
    },
    users: {
      list: {
        chart: {
          user: 'Utenti',
          movement: 'Movimenti',
        },
        month: {
          '0': 'Gennaio',
          '1': 'Febbraio',
          '2': 'Marzo',
          '3': 'aprile',
          '4': 'Maggio',
          '5': 'Giugno',
          '6': 'Luglio',
          '7': 'agosto',
          '8': 'settembre',
          '9': 'ottobre',
          '10': 'novembre',
          '11': 'Dicembre',
          '12': 'Totale',
        },
        year: {
          '0': '2020',
          '1': '2021',
          '2': '2022',
          '3': '2023',
          '4': '2024',
          '5': '2025',
          '6': '2026',
          '7': '2027',
          '8': '2028',
          '9': '2029',
          '10': '2030',
          '11': '2031',
        },
        columns: {
          name: 'Nome',
          email: 'E-mail',
          phone: 'Telefono',
          status: 'Stato',
          createdAt: 'Creato',
          updatedAt: 'Aggiornato',
          expiration: 'Scadenza',
          created: 'Creazione',
          lastMove: 'Ultima mossa',
        },
        title: 'Utenti',
        description: 'Gestione del personale e relativo accesso al sistema',
        create: 'Utente',
        one: 'Utente',
        many: 'Utenti',
        cloneConfirm: 'Sei sicuro di voler clonare questo utente?',
        cloneSuccess: 'Utente clonato correttamente',
      },
      create: {
        title: 'Nuovo utente',
        subtitle: 'Registrazione di un nuovo utente nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome utente',
          },
          surname: {
            title: 'Cognomi',
            description: "Cognome dell'utente",
          },
          password: {
            title: 'Password',
            description:
              'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola e un numero.',
          },
          username: {
            title: 'Nome utente',
            description: "Nome utente per l'accesso al sistema",
          },
          email: {
            title: 'E-mail',
            description: "Indirizzo email dove l'utente riceverà le notifiche di sistema",
          },
          phone: {
            title: 'Telefono',
            description: "Numero di contatto dell'utente",
          },
          extension: {
            title: 'Estensione',
            description: "Estensione dell'identificazione all'interno del dipartimento",
          },
          signature: {
            title: 'Password',
            description: 'Password utente',
          },
          expiration: {
            title: 'Data di scadenza',
            description: "Data di scadenza dell'account dell'utente",
          },
          color: {
            title: 'Colore',
            description: "Colore dell'utente nel sistema",
          },
          icon: {
            title: 'Icona',
            label: "Seleziona l'icona",
            description: "Icona di identificazione dell'utente nel sistema",
            search: {
              placeholder: 'Icona di ricerca',
            },
          },
          status: {
            title: 'Accesso al sistema',
            description: "Abilita o disabilita l'accesso al sistema per questo utente",
            label: "Consentire l'accesso al sistema",
          },
          movementLimit: {
            title: 'Limite di movimento',
            description: 'Numero di richieste consentite per questo utente',
          },
          attemptLimit: {
            title: 'Limite di tentativi',
            description: 'Numero di tentativi consentiti per accedere',
          },
          submit: 'Crea utente',
        },
      },
      delete: {
        title: 'Disabilita utente',
        subtitle: 'Questa azione è reversibile',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo utente?',
            description:
              'Disabilitare un utente significa che non sarà in grado di accedere al sistema. ',
            label: 'Sì, voglio disattivare questo utente',
          },
          submit: 'Disabilita',
        },
        passwordForm: {
          title: 'Inserisci la tua password',
          subtitle: "Per disabilitare l'utente, inserisci la tua password",
          password: {
            title: 'Password',
            description: "Password dell'utente che esegue l'azione",
          },
          submit: 'Confermare',
        },
      },
      enable: {
        title: 'Abilita utente',
        subtitle: "Questa azione consente all'utente di accedere al sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo utente?',
            description: 'Abilitare un utente significa che potrà accedere al sistema. ',
            label: 'Sì, voglio abilitare questo utente',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita utente',
        subtitle: "Questa azione impedisce all'utente di accedere al sistema",
        form: {
          status: {
            title: 'Sei sicuro di volerlo disabilitare?',
            description:
              'Disabilitare un utente significa che non sarà in grado di accedere al sistema. ',
            label: 'Sì, voglio disabilitare questo utente',
          },
          submit: 'Disabilita',
        },
      },
      update: {
        access: 'Accesso',
        account: {
          tab: 'Account',
          description: "Informazioni sull'account utente",
        },
        tabs: {
          account: {
            title: 'Account',
            description: "Dettagli dell'account personale dell'utente con accesso al sistema.",
          },
          licenses: {
            title: 'Licenze',
            description:
              "Una licenza determina le azioni che un utente può eseguire e l'accesso che ha al sistema. ",
          },
          contracts: {
            title: 'Contratti',
            description: "Un contratto è un accordo tra l'utente e la clinica. ",
          },
          movements: {
            title: 'Traffico',
            description: 'Il sistema monitora i movimenti degli utenti. ',
          },
          attempts: {
            title: 'Tentativi',
            description:
              'Per salvaguardare la sicurezza del sistema, i tentativi di accesso al sistema vengono monitorati.',
          },
          languages: {
            title: 'Lingue',
            description: "Lingue parlate dall'utente",
          },
          timetables: {
            title: 'Orari',
            description:
              "Gli orari sono gli intervalli di tempo in cui l'utente ha accesso al sistema.",
          },
          changePassword: {
            title: 'Password',
            description: 'Cambia password utente',
          },
          activity: {
            title: 'Attività',
            description:
              "Se viene definita un'attività, quando la persona responsabile del Lead viene assegnata a questo utente, verrà creata automaticamente un'attività con l'attività definita.",
          },
          integrations: {
            title: 'Integrazioni',
            description:
              'Configurazione a livello utente per poter realizzare le diverse integrazioni implementate nel CRM. ',
          },
        },
        schedules: {
          create: {
            title: 'Aggiungi pianificazione',
            subtitle: "Aggiungi pianificazione all'utente",
            form: {
              timetableId: {
                title: 'Seleziona un orario',
                description: "Modello temporale in cui l'utente ha accesso al sistema",
              },
              submit: 'Aggiungi pianificazione',
            },
          },
          delete: {
            title: 'Scollega pianificazione',
            subtitle: "Scollega la pianificazione dell'utente",
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                label: "Sì, desidero scollegare questa pianificazione all'utente",
              },
              submit: 'Scollega pianificazione',
            },
          },
        },
        languages: {
          add: 'Aggiungi lingua',
          create: {
            title: 'Aggiungi lingua',
            subtitle: "Aggiungi la lingua parlata dall'utente",
            form: {
              languageId: {
                title: 'Lingua',
                description: "Lingua parlata dall'utente",
              },
              submit: 'Aggiungere',
            },
          },
          delete: {
            title: 'Elimina lingua',
            subtitle: 'Elimina la lingua di questo utente',
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                description: 'Questa azione è irreversibile.',
                label: 'Sì, voglio rimuovere questa lingua',
              },
              submit: 'Confermare',
            },
          },
        },
        roles: 'Ruoli',
        title: 'Aggiorna utente',
        subtitle: 'Modifica dei dati di un utente nel sistema',
        licenses: {
          tab: 'Licenze',
          description:
            'Le licenze definiscono le azioni che gli utenti possono eseguire a livello di gruppo, ovvero sono permessi che non coinvolgono le cliniche.',
          add: 'Aggiungere',
          create: {
            title: 'Nuova licenza',
            subtitle: 'Registrazione di una nuova licenza nel sistema.',
            form: {
              roleId: {
                title: 'Ruolo',
                description: "Il ruolo è l'insieme di permessi che l'utente avrà nel sistema",
              },
              submit: 'Crea licenza',
            },
          },
          delete: {
            title: 'Elimina licenza',
            subtitle: 'Questa azione è irreversibile',
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                description: 'Potresti preferire disattivare questa licenza',
                label: 'Sì, desidero eliminare questa licenza',
              },
              submit: 'Elimina licenza',
            },
          },
        },
        permissions: {
          add: 'Aggiungi licenza',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Landbot',
              description: 'Sei un utente LandBot',
              label: 'Sì, sei un utente LandBot',
            },
            submit: 'Aggiorna le integrazioni',
          },
        },
        contracts: {
          tab: 'Contratti',
          description: "I contratti sono accordi tra l'utente e la clinica. ",
          add: 'Aggiungi contratto',
          addAll: 'Aggiungi tutti i contratti',
          create: {
            title: 'Nuovo contratto',
            subtitle: 'Registrazione di un nuovo contratto nel sistema.',
            form: {
              clinicId: {
                title: 'Clinica',
              },
              submit: 'Crea contratto',
            },
          },
          disable: {
            title: 'Disabilita contratto',
            subtitle: "Questa azione impedisce all'utente di accedere alla clinica",
            form: {
              status: {
                title: 'Sei sicuro di volerlo disabilitare?',
              },
              submit: 'Disabilita contratto',
            },
          },
          enable: {
            title: 'Abilita contratto',
            subtitle: "Questa azione consente all'utente di accedere alla clinica",
            form: {
              status: {
                title: 'Sei sicuro di volerlo abilitare?',
              },
              submit: 'Abilita contratto',
            },
          },
        },
        timetables: {
          add: 'Aggiungi pianificazione',
          create: {
            title: 'Nuovo programma',
            subtitle: "Registra la nuova pianificazione per l'utente",
            form: {
              day: {
                title: 'Giorno',
                description: "Giorno della settimana in cui l'utente ha accesso al sistema",
              },
              started: {
                title: 'Inizio',
                description: "Ora di inizio in cui l'utente ha accesso al sistema",
              },
              ended: {
                title: 'FINE',
                description: "Ora di fine in cui l'utente ha accesso al sistema",
              },
              submit: 'Crea pianificazione',
            },
          },
          delete: {
            title: 'Elimina pianificazione',
            subtitle: "Elimina la pianificazione dell'utente",
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                label: 'Sì, voglio eliminare questa pianificazione',
              },
              submit: 'Elimina pianificazione',
            },
          },
        },
        movements: {
          tab: 'Movimenti',
          description: "I movimenti sono le richieste che l'utente fa al sistema. ",
        },
        attempts: {
          tab: 'Tentativi',
          description: "I tentativi sono accessi al login dell'utente. ",
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome utente',
          },
          surname: {
            title: 'Cognomi',
            description: "Cognome dell'utente",
          },
          password: {
            title: 'Password',
            description:
              'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola e un numero.',
          },
          username: {
            title: 'Nome utente',
            description: "Nome utente per l'accesso al sistema",
          },
          email: {
            title: 'E-mail',
            description: "E-mail dell'utente per accedere al sistema",
          },
          phone: {
            title: 'Telefono',
            description: "Numero di contatto dell'utente",
          },
          extension: {
            title: 'Estensione',
            description: "Estensione dell'identificazione all'interno del dipartimento",
          },
          signature: {
            title: 'Password',
            description: 'Password utente',
          },
          expiration: {
            title: 'Data di scadenza',
            description: "Data di scadenza dell'account dell'utente",
          },
          color: {
            title: 'Colore',
            description: "Colore dell'utente nel sistema",
          },
          status: {
            title: 'Accesso al sistema',
            description: "Abilita o disabilita l'accesso al sistema per questo utente",
            label: "Consentire l'accesso al sistema",
          },
          movementLimit: {
            title: 'Limite di movimento',
            description: 'Numero di richieste consentite per questo utente durante 24 ore',
          },
          attemptLimit: {
            title: 'Limite di tentativi',
            description: 'Numero di tentativi consentiti di accesso durante 24 ore',
          },
          submit: 'Aggiorna utente',
        },
        activity: {
          form: {
            activityId: {
              title: 'Attività',
            },
            submit: 'Aggiorna attività',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Aggiorna ovopoint',
        subtitle: 'Modificare gli ovopoint di un utente nel sistema',
        form: {
          appointedPoints: {
            title: 'Piombo citato',
            description: 'Ovopoints per fissare un appuntamento',
          },
          attendedPoints: {
            title: 'Il protagonista si è presentato alla visita medica',
            description: 'Ovopoint per partecipare a una visita medica',
          },
          commercialAttendedPoints: {
            title: 'Il lead ha avuto un appuntamento di vendita',
            description: 'Ovopoint per partecipare a un appuntamento di lavoro',
          },
          informedPoints: {
            title: 'Leader informato',
            description: 'Ovopoints per informare un lead',
          },
          contactedPoints: {
            title: 'Responsabile contattato',
            description: 'Ovopoint per contattare un lead',
          },
          convertedPoints: {
            title: 'Converti piombo',
            description: {
              ovoclinic: 'Ovopoints per convertire un lead in un paziente',
              ovobank: 'Ovopoints per convertire un contatto in un donatore',
            },
          },
          submit: 'Aggiorna ovopoint',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Via di comunicazione',
        title: 'Vie di comunicazione',
        description: 'Gestione dei canali di comunicazione',
        one: 'Via di comunicazione',
        many: 'Vie di comunicazione',
        columns: {
          name: 'Nome',
          created: 'Data di creazione',
        },
      },
      create: {
        title: 'Nuova via di comunicazione',
        subtitle: 'Registrazione di un nuovo canale di comunicazione nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome del percorso di comunicazione',
          },
          submit: 'Creare un canale di comunicazione',
        },
      },
      update: {
        title: 'Aggiorna il percorso di comunicazione',
        subtitle: 'Modifica dei dati di un percorso di comunicazione nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome del percorso di comunicazione',
          },
          submit: 'Aggiorna il percorso di comunicazione',
        },
      },
      enable: {
        title: 'Abilita il percorso di comunicazione',
        subtitle: 'Abilita il percorso di comunicazione nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo canale di comunicazione?',
            label: 'Sì, voglio abilitare questo canale di comunicazione',
          },
          submit: 'Abilita il percorso di comunicazione',
        },
      },
      disable: {
        title: 'Disabilita il percorso di comunicazione',
        subtitle: 'Disabilitare il percorso di comunicazione nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo percorso di comunicazione?',
            label: 'Sì, voglio disabilitare questo canale di comunicazione',
          },
          submit: 'Disabilita il percorso di comunicazione',
        },
      },
    },
    specialists: {
      list: {
        create: 'Specialista',
        title: 'Specialista',
        description: 'Gestione specialistica',
        one: 'Specialista',
        many: 'Specialisti',
        columns: {
          name: 'Nome',
          surname: 'Cognomi',
          dni: 'ID',
          type: 'Tipo',
        },
      },
      create: {
        title: 'Nuovo specialista',
        subtitle: 'Registrazione di un nuovo specialista nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome dello specialista',
          },
          surname: {
            title: 'Cognomi',
            description: 'Cognome dello specialista',
          },
          dni: {
            title: 'ID',
            description: "Documento d'identità specialistico",
          },
          type: {
            title: 'Tipo',
            description: 'Tipo di specialista',
          },
          submit: 'Crea specialista',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Specialista',
            description: 'Dati personali dello specialista',
          },
          contracts: {
            title: 'Contratti',
            description: 'Contratti specialistici',
          },
        },
        contracts: {
          add: 'Aggiungi contratto',
          list: {
            one: 'Contrarre',
            many: 'Contratti',
          },
          create: {
            title: 'Nuovo contratto',
            subtitle: 'Registrazione di un nuovo contratto specialistico nel sistema',
            form: {
              clinicId: {
                title: 'Clinica',
                description: 'Clinica a cui appartiene il contratto',
              },
              submit: 'Crea contratto',
            },
          },
          delete: {
            title: 'Elimina contratto',
            subtitle: 'Elimina il contratto di questo specialista',
            form: {
              submit: 'Elimina contratto',
            },
          },
        },
        title: "Specialista dell'aggiornamento",
        subtitle: 'Modifica dei dati di uno specialista nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome dello specialista',
          },
          surname: {
            title: 'Cognomi',
            description: 'Cognome dello specialista',
          },
          dni: {
            title: 'ID',
            description: "Documento d'identità specialistico",
          },
          type: {
            title: 'Tipo',
            description: 'Tipo di specialista',
          },
          submit: "Specialista dell'aggiornamento",
        },
      },
      enable: {
        title: 'Abilita specialista',
        subtitle: 'Questa azione abilita lo specialista del sistema',
        form: {
          submit: 'Abilita specialista',
        },
      },
      disable: {
        title: 'Specialista della disabilità',
        subtitle: 'Questa azione disabilita lo specialista nel sistema',
        form: {
          submit: 'Specialista della disabilità',
        },
      },
    },
    templates: {
      list: {
        title: 'Modelli',
        description: 'Gestione dei template per Landbot',
        create: 'Modello',
        columns: {
          label: 'Nome',
        },
        one: 'Modello',
        many: 'Modelli',
      },
      create: {
        title: 'Nuovo modello',
        subtitle: 'Registrazione di un nuovo modello per Landbot',
        form: {
          label: {
            title: 'Nome',
            description: 'Nome del modello',
          },
          landbotId: {
            title: 'ID del Landbot',
            description: 'ID Landbot a cui è associato il modello',
          },
          submit: 'Crea modello',
        },
      },
      update: {
        title: 'Aggiorna modello',
        subtitle: 'Modifica dei dati del modello per Landbot',
        tabs: {
          template: {
            title: 'Modello',
            description: 'Dettagli del modello',
          },
          templateScope: {
            title: 'Ambito',
            description: 'Ambito del modello',
          },
          templateParam: {
            title: 'Parametri',
            description: 'Parametri del modello',
          },
        },
        form: {
          label: {
            title: 'Nome',
            description: 'Nome del modello',
          },
          landbotId: {
            title: 'ID del Landbot',
            description: 'ID Landbot a cui è associato il modello',
          },
          id: {
            title: 'Modello',
            description: 'Modello da utilizzare in Landbot per inviare il messaggio',
          },
          submit: 'Aggiorna modello',
        },
      },
      disable: {
        title: 'Disabilita modello',
        subtitle: 'Disabilita modello per Landbot',
        form: {
          submit: 'Disabilita modello',
        },
      },
      enable: {
        title: 'Abilita modello',
        subtitle: 'Abilita modello per Landbot',
        form: {
          submit: 'Abilita modello',
        },
      },
      delete: {
        title: 'Elimina modello',
        subtitle: 'Elimina modello Landbot',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            label: 'Sì, voglio eliminare questo modello',
          },
          submit: 'Elimina modello',
        },
      },
      send: {
        title: 'Invia modello',
        subtitle: 'Invia modello al contatto',
        form: {
          id: {
            title: 'Modello',
            description: 'Modello da utilizzare in Landbot per inviare il messaggio',
          },
          landbotId: {
            title: 'ID del Landbot',
            description: 'ID del bot Landbot a cui è associato il modello',
          },
          submit: 'Invia modello',
        },
      },
      templateScopes: {
        list: {
          title: 'Ambiti',
          description: 'Gestione degli ambiti del modello',
          create: 'Ambito',
          columns: {
            label: 'Nome',
          },
          one: 'Ambito',
          many: 'Ambiti',
        },
        create: {
          title: 'Nuovo ambito',
          subtitle: 'Registrazione di un nuovo ambito per i modelli',
          form: {
            label: {
              title: 'Nome',
              description: "Nome dell'ambito",
            },
            departmentId: {
              title: 'Dipartimento',
              description: 'Dipartimento a cui avrà ambito il modello',
            },
            submit: 'Crea ambito',
          },
        },
        update: {
          title: 'Aggiorna ambito',
          subtitle: "Modifica dei dati nell'ambito di un modello",
          form: {
            label: {
              title: 'Nome',
              description: "Nome dell'ambito",
            },
            submit: 'Aggiorna ambito',
          },
        },
        delete: {
          title: 'Elimina ambito',
          subtitle: "Rimuovi l'ambito da questo modello",
          form: {
            status: {
              title: 'Sei sicuro di volerlo eliminare?',
              label: 'Sì, desidero eliminare questo ambito',
            },
            submit: 'Elimina ambito',
          },
        },
        add: 'Aggiungi ambito',
      },
      templateParams: {
        list: {
          title: 'Parametri',
          description: 'Gestione dei parametri del modello',
          create: 'Parametro',
          columns: {
            label: 'Nome',
            order: 'Ordine',
            templateKeyName: 'Parametro',
          },
          one: 'Parametro',
          many: 'Parametri',
        },
        create: {
          title: 'Nuovo parametro',
          subtitle: 'Registrazione di un nuovo parametro per i modelli',
          form: {
            templateKeyId: {
              title: 'Parametro',
              description: 'Possibili parametri da allegare al modello',
            },
            order: {
              title: 'Ordine',
              description: 'Ordine in cui il parametro verrà visualizzato nel modello',
            },
            submit: 'Crea parametro',
          },
        },
        delete: {
          title: 'Elimina parametro',
          subtitle: 'Rimuovi il parametro da questo modello',
          form: {
            status: {
              title: 'Sei sicuro di volerlo eliminare?',
              label: 'Sì, voglio rimuovere questo parametro',
            },
            submit: 'Elimina parametro',
          },
        },
        add: 'Aggiungi parametro',
      },
    },
    languages: {
      list: {
        create: 'Lingua',
        title: 'Lingua',
        description: 'Gestione della lingua',
        one: 'Lingua',
        many: 'Lingue',
        columns: {
          name: 'Nome',
          country: 'Paese',
          code: 'Codice',
        },
      },
      create: {
        title: 'Nuova lingua',
        subtitle: 'Registrazione di una nuova lingua nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome della lingua',
          },
          status: {
            title: 'Stato',
            label: 'Stato',
          },
          countryCode: {
            title: 'Paese',
            description: 'Paese a cui appartiene la lingua',
          },
          code: {
            title: 'Codice',
            description: 'Codice della lingua',
          },
          submit: 'Crea la lingua',
        },
      },
      update: {
        title: 'Aggiorna la lingua',
        subtitle: 'Modifica dei dati della lingua nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome della lingua',
          },
          countryCode: {
            title: 'Paese',
            description: 'Paese a cui appartiene la lingua',
          },
          code: {
            title: 'Codice',
            description: 'Codice della lingua',
          },
          submit: 'Aggiorna la lingua',
        },
      },
      enable: {
        title: 'Abilita lingua',
        subtitle: 'Abilita la lingua nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa lingua?',
            label: 'Sì, voglio abilitare questa lingua',
          },
          submit: 'Abilita lingua',
        },
      },
      disable: {
        title: 'Disabilita lingua',
        subtitle: 'Disabilitare la lingua nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa lingua?',
            label: 'Sì, voglio disabilitare questa lingua',
          },
          submit: 'Disabilita lingua',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'Abbiamo buone notizie',
      },
      create: {
        title: 'Nuove notizie',
        subtitle: 'Registra nuove novità in clinica',
        steps: {
          clinic: {
            form: {
              title: 'Clinica',
              clinicId: {
                title: 'Clinica',
                description: 'Clinica a cui appartiene la notizia',
              },
            },
          },
          actor: {
            form: {
              title: 'Compagno',
              actorId: {
                title: 'Compagno',
                description: 'Partner di cui parla la notizia',
              },
            },
          },
        },
        form: {
          description: {
            title: 'Descrizione',
            description:
              "Per celebrare i risultati ottenuti all'interno della clinica, puoi condividere informazioni quali: storie di successo, riconoscimenti personali e gratitudine.",
          },
          image: {
            title: 'Immagine',
            description: 'Immagine di notizie',
          },
          submit: 'Crea notizie',
        },
      },
      update: {
        title: 'Dettagli della notizia',
        subtitle: 'Dettagli sulle notizie della clinica',
        form: {
          clinicId: {
            title: 'Clinica',
            description: 'Clinica a cui appartiene la notizia',
          },
          authorName: {
            title: 'Autore',
            description: 'Autore della notizia',
          },
          actorName: {
            title: 'Compagno',
            description: 'Partner di cui parla la notizia',
          },
          description: {
            title: 'Descrizione',
            description:
              "Per celebrare i risultati ottenuti all'interno della clinica, puoi condividere informazioni quali: storie di successo, riconoscimenti personali e gratitudine.",
          },
          image: {
            title: 'Immagine',
            description: 'Immagine di notizie',
          },
          submit: 'Aggiorna notizie',
        },
      },
      list: {
        title: 'Notizia',
        description: 'Novità sulla clinica',
        create: 'Notizia',
        one: 'Notizia',
        many: 'Notizia',
        columns: {
          clinicName: 'Clinica',
          actorName: 'Compagno',
          authorName: 'Autore',
          created: 'Creazione',
        },
      },
      delete: {
        title: 'Disabilita notizie',
        subtitle: 'Disabilita le notizie della clinica',
        button: 'Disabilita',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare queste notizie?',
            label: 'Sì, voglio disattivare queste notizie',
          },
          submit: 'Disabilita',
        },
        passwordForm: {
          title: 'Inserisci la tua password',
          subtitle: 'Per disattivare le novità inserisci la tua password',
          password: {
            title: 'Password',
            description: "Password dell'utente che esegue l'azione",
          },
          submit: 'Confermare',
        },
      },
      enable: {
        title: 'Abilita notizie',
        subtitle: 'Abilita le notizie sulla clinica',
        button: 'Abilitare',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare queste notizie?',
            label: 'Sì, voglio abilitare queste notizie',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita notizie',
        subtitle: 'Disabilita le notizie della clinica',
        button: 'Disabilita',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare queste notizie?',
            label: 'Sì, voglio disattivare queste notizie',
          },
          submit: 'Disabilita',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Ruolo',
          created: 'Creazione',
        },
        title: 'Ruoli',
        description: 'Gestione dei ruoli e dei relativi permessi',
        create: 'Ruolo',
        one: 'Ruolo',
        many: 'Ruoli',
      },
      create: {
        title: 'Nuovo ruolo',
        subtitle: 'Registrazione di un nuovo ruolo nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del ruolo',
          },
          general: {
            accordion: 'Autorizzazioni generali',
            title: 'Autorizzazioni generali',
            description: 'Autorizzazioni generali per il ruolo',
          },
          viewDisabledRecords: {
            title: 'Visualizza i registri disabilitati',
            description: 'Questa opzione abilita la visualizzazione dei log disabilitati',
            label: 'Abilita la visualizzazione dei log disabilitati',
          },
          viewDisabledLeadRecords: {
            title: 'Visualizza i lead disabilitati',
            description: 'Questa opzione abilita la visualizzazione delle derivazioni disabilitate',
            label: 'Abilita la visualizzazione delle derivazioni disabilitate',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Visualizza i pazienti disabili',
              ovobank: 'Visualizza i donatori disabili',
            },
            description: {
              ovoclinic: 'Questa opzione abilita la visualizzazione dei pazienti disabili',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori disabili',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione dei pazienti disabili',
              ovobank: 'Abilita la visualizzazione dei donatori disabili',
            },
          },
          access: {
            accordion: 'Accesso OVO',
            title: 'Autorizzazione al modulo OVOAccess',
            description: "Questa opzione abilita l'accesso al modulo OVOAccess",
            label: "Abilita l'accesso",
          },
          user: {
            accordion: 'Utenti',
          },
          accessUser: {
            title: 'Visualizza gli utenti',
            description: 'Questa opzione abilita la visualizzazione degli utenti nel sistema',
            label: 'Abilita visualizzazione utente',
          },
          accessUserCreator: {
            title: 'Creare utenti',
            description: 'Questa opzione abilita la creazione di utenti nel sistema',
            label: "Abilita la creazione dell'utente",
          },
          accessUserEditor: {
            title: 'Modifica utenti',
            description: 'Questa opzione consente la modifica degli utenti nel sistema',
            label: "Abilita la modifica dell'utente",
          },
          accessUserDestructor: {
            title: 'Elimina utenti',
            description: 'Questa opzione abilita la cancellazione degli utenti nel sistema',
            label: "Abilita l'eliminazione dell'utente",
          },
          accessUserChangePassword: {
            title: 'Cambiare la password',
            description:
              'Questa opzione consente di modificare la password degli utenti nel sistema',
            label: 'Abilita la modifica della password',
          },
          role: {
            accordion: 'Ruoli',
          },
          accessRole: {
            title: 'Visualizza ruoli',
            description: 'Questa opzione abilita la visualizzazione dei ruoli nel sistema',
            label: 'Abilita la visualizzazione del ruolo',
          },
          accessRoleCreator: {
            title: 'Crea ruoli',
            description: 'Questa opzione abilita la creazione di ruoli nel sistema',
            label: 'Abilita la creazione del ruolo',
          },
          accessRoleEditor: {
            title: 'Modifica ruoli',
            description: 'Questa opzione consente la modifica dei ruoli nel sistema',
            label: 'Abilita la modifica del ruolo',
          },
          accessRoleDestructor: {
            title: 'Elimina ruoli',
            description: 'Questa opzione abilita la cancellazione dei ruoli nel sistema',
            label: "Abilita l'eliminazione del ruolo",
          },
          timetable: {
            accordion: 'Orari',
          },
          accessTimetable: {
            title: 'Vedi gli orari',
            description: 'Questa opzione abilita la visualizzazione degli orari nel sistema',
            label: 'Abilita la visualizzazione della pianificazione',
          },
          accessTimetableCreator: {
            title: 'Crea pianificazioni',
            description: 'Questa opzione abilita la creazione di pianificazioni nel sistema',
            label: 'Abilita la creazione della pianificazione',
          },
          accessTimetableEditor: {
            title: 'Modifica orari',
            description: 'Questa opzione consente la modifica delle pianificazioni nel sistema',
            label: 'Abilita la modifica della pianificazione',
          },
          accessTimetableDestructor: {
            title: 'Elimina pianificazioni',
            description: 'Questa opzione abilita la cancellazione delle pianificazioni nel sistema',
            label: "Abilita l'eliminazione della pianificazione",
          },
          bulletin: {
            accordion: 'Notizia',
          },
          accessBulletin: {
            title: 'Vedi le notizie',
            description: 'Questa opzione abilita la visualizzazione delle notizie nel sistema',
            label: 'Abilita la visualizzazione delle notizie',
          },
          accessBulletinCreator: {
            title: 'Crea notizie',
            description: 'Questa opzione abilita la creazione di notizie nel sistema',
            label: 'Abilita la creazione di notizie',
          },
          accessBulletinEditor: {
            title: 'Modifica notizie',
            description: 'Questa opzione abilita la modifica delle notizie nel sistema',
            label: 'Abilita la modifica delle notizie',
          },
          accessBulletinDestructor: {
            title: 'Elimina notizie',
            description: 'Questa opzione abilita la cancellazione delle notizie nel sistema',
            label: "Abilita l'eliminazione delle notizie",
          },
          accessClinic: {
            title: 'Vedi cliniche',
            description: 'Questa opzione consente la visualizzazione delle cliniche nel sistema',
            label: 'Abilita la visualizzazione della clinica',
          },
          accessClinicCreator: {
            title: 'Creare cliniche',
            description: 'Questa opzione consente la creazione di cliniche nel sistema',
            label: 'Abilita la creazione della clinica',
          },
          accessClinicEditor: {
            title: 'Modifica cliniche',
            description: 'Questa opzione consente la modifica delle cliniche nel sistema',
            label: 'Abilita la modifica della clinica',
          },
          accessClinicDestructor: {
            title: 'Elimina cliniche',
            description: 'Questa opzione consente la cancellazione delle cliniche nel sistema',
            label: "Abilita l'eliminazione della clinica",
          },
          department: {
            accordion: 'Dipartimenti',
          },
          accessDepartment: {
            title: 'Vedi dipartimenti',
            description:
              'Questa opzione abilita la visualizzazione dei reparti presenti nel sistema',
            label: 'Abilita la visualizzazione del reparto',
          },
          accessDepartmentCreator: {
            title: 'Creare dipartimenti',
            description: 'Questa opzione consente la creazione di dipartimenti nel sistema',
            label: 'Abilita la creazione del dipartimento',
          },
          accessDepartmentEditor: {
            title: 'Modifica dipartimenti',
            description: 'Questa opzione consente la modifica dei dipartimenti nel sistema',
            label: 'Abilita la modifica del dipartimento',
          },
          accessDepartmentDestructor: {
            title: 'Elimina dipartimenti',
            description: 'Questa opzione abilita la cancellazione dei reparti nel sistema',
            label: "Abilita l'eliminazione dei dipartimenti",
          },
          language: {
            accordion: 'Lingue',
          },
          accessLanguage: {
            title: 'Vedi le lingue',
            description: 'Questa opzione abilita la visualizzazione delle lingue nel sistema',
            label: 'Abilita la visualizzazione della lingua',
          },
          accessLanguageCreator: {
            title: 'Creare lingue',
            description: 'Questa opzione abilita la creazione di lingue nel sistema',
            label: 'Abilita la creazione della lingua',
          },
          accessLanguageEditor: {
            title: 'Modifica lingue',
            description: 'Questa opzione consente la modifica delle lingue nel sistema',
            label: 'Abilita la modifica della lingua',
          },
          accessLanguageDestructor: {
            title: 'Elimina le lingue',
            description: 'Questa opzione consente la rimozione delle lingue dal sistema',
            label: 'Abilita la rimozione della lingua',
          },
          country: {
            accordion: 'Paesi',
          },
          accessCountry: {
            title: 'Vedi i paesi',
            description: 'Questa opzione abilita la visualizzazione dei paesi nel sistema',
            label: 'Abilita la visualizzazione del paese',
          },
          accessCountryCreator: {
            title: 'Creare paesi',
            description: 'Questa opzione consente la creazione di paesi nel sistema',
            label: 'Abilita la creazione del paese',
          },
          accessCountryEditor: {
            title: 'Modifica paesi',
            description: 'Questa opzione consente la modifica dei paesi nel sistema',
            label: 'Abilita la modifica del Paese',
          },
          accessCountryDestructor: {
            title: 'Elimina paesi',
            description: 'Questa opzione consente la cancellazione dei paesi nel sistema',
            label: "Abilita l'eliminazione del paese",
          },
          accessSettings: {
            title: 'Visualizza le impostazioni',
            description: 'Questa opzione abilita la visualizzazione delle impostazioni nel sistema',
            label: 'Abilita la visualizzazione della configurazione',
          },
          specialists: {
            accordion: 'Specialisti',
          },
          accessSpecialist: {
            title: 'Vedi gli specialisti',
            description: 'Questa opzione consente la visualizzazione degli specialisti nel sistema',
            label: 'Abilita la visualizzazione degli specialisti',
          },
          accessSpecialistCreator: {
            title: 'Creare specialisti',
            description: 'Questa opzione consente la creazione di specialisti nel sistema',
            label: 'Consentire la creazione di specialisti',
          },
          accessSpecialistEditor: {
            title: 'Modifica specialisti',
            description: 'Questa opzione consente la modifica degli specialisti nel sistema',
            label: 'Abilita la modifica specializzata',
          },
          accessSpecialistDestructor: {
            title: 'Eliminare gli specialisti',
            description: 'Questa opzione consente la rimozione degli specialisti dal sistema',
            label: 'Abilita la rimozione specializzata',
          },
          commsChannels: {
            accordion: 'Canali di comunicazione',
          },
          accessCommsChannel: {
            title: 'Vedi canali di comunicazione',
            description:
              'Questa opzione abilita la visualizzazione dei canali di comunicazione nel sistema',
            label: 'Abilita la visualizzazione dei canali di comunicazione',
          },
          accessCommsChannelCreator: {
            title: 'Creare canali di comunicazione',
            description:
              'Questa opzione consente la creazione di canali di comunicazione nel sistema',
            label: 'Abilita la creazione di canali di comunicazione',
          },
          accessCommsChannelEditor: {
            title: 'Modifica i canali di comunicazione',
            description:
              'Questa opzione consente la modifica dei canali di comunicazione nel sistema',
            label: 'Abilita la modifica dei canali di comunicazione',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminare i canali di comunicazione',
            description:
              "Questa opzione consente l'eliminazione dei canali di comunicazione nel sistema",
            label: "Abilita l'eliminazione dei canali di comunicazione",
          },
          ovopoints: {
            accordion: 'Ovopunti',
          },
          accessOvopoint: {
            title: 'Ovopunti',
            description: 'Questa opzione abilita la visualizzazione degli ovopoint nel sistema',
            label: 'Abilita la visualizzazione degli ovopunti',
          },
          accessOvopointEditor: {
            title: 'Modifica ovopoint',
            description: 'Questa opzione abilita la modifica degli ovopoint nel sistema',
            label: 'Abilita la modifica del punto ovo',
          },
          templates: {
            accordion: 'Modelli',
          },
          accessTemplate: {
            title: 'Vedi i modelli WhatsApp',
            description: 'Questa opzione abilita la visualizzazione dei modelli WhatsApp',
            label: 'Abilita la visualizzazione del modello',
          },
          accessTemplateCreator: {
            title: 'Crea modelli WhatsApp',
            description: 'Questa opzione consente la creazione di modelli WhatsApp',
            label: 'Abilita modello',
          },
          accessTemplateEditor: {
            title: 'Modifica modelli WhatsApp',
            description: 'Questa opzione consente la modifica dei modelli WhatsApp',
            label: 'Abilita la modifica del modello',
          },
          accessTemplateDestructor: {
            title: 'Elimina modelli WhatsApp',
            description: "Questa opzione consente l'eliminazione dei modelli WhatsApp",
            label: "Abilita l'eliminazione del modello",
          },
          crm: {
            accordion: 'OVOCRM',
            title: "Autorizzazione dell'applicazione",
            description: "Questa opzione abilita l'accesso al modulo OVOCrm",
            label: "Abilita l'accesso",
          },
          tasks: {
            accordion: 'Compiti',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Questa opzione abilita la visualizzazione del calendario sul sistema',
            label: 'Abilita la visualizzazione del calendario',
          },
          crmTask: {
            title: 'Compiti',
            description: 'Questa opzione abilita la visualizzazione delle attività nel sistema',
            label: 'Abilita la visualizzazione delle attività',
          },
          crmTaskCreator: {
            title: 'Crea compiti',
            description: 'Questa opzione consente la creazione di attività nel sistema',
            label: 'Abilita la creazione di attività',
          },
          crmTaskEditor: {
            title: 'Modifica attività',
            description: 'Questa opzione consente la modifica delle attività nel sistema',
            label: 'Abilita la modifica delle attività',
          },
          crmTaskDestructor: {
            title: 'Elimina attività',
            description: "Questa opzione consente l'eliminazione delle attività nel sistema",
            label: "Abilita l'eliminazione delle attività",
          },
          crmTaskSeeAll: {
            title: 'Visualizza tutte le attività',
            description:
              'Questa opzione abilita la visualizzazione di tutte le attività nel sistema',
            label: 'Abilita la visualizzazione di tutte le attività',
          },
          crmTaskSeeCoworkers: {
            title: 'Visualizza le attività dei colleghi',
            description:
              'Questa opzione abilita la visualizzazione delle attività dei colleghi nel sistema',
            label: 'Abilita la visualizzazione delle attività peer',
          },
          crmTaskUpdateResponsible: {
            title: "Aggiorna la persona assegnata a un'attività",
            description:
              "Questa opzione consente di modificare la persona responsabile di un'attività",
            label: 'Abilita la modifica del task manager',
          },
          leads: {
            accordion: 'Conduce',
          },
          crmLead: {
            title: 'Conduce',
            description: 'Questa opzione abilita la visualizzazione dei lead nel sistema',
            label: 'Abilita la visualizzazione dei lead',
          },
          crmLeadCreator: {
            title: 'Crea lead',
            description: 'Questa opzione consente la creazione di lead nel sistema',
            label: 'Abilita la creazione di lead',
          },
          crmLeadEditor: {
            title: 'Modifica lead',
            description: 'Questa opzione consente la modifica dei lead nel sistema',
            label: 'Abilita la modifica dei lead',
          },
          crmLeadDestructor: {
            title: 'Elimina lead',
            description: 'Questa opzione consente la cancellazione delle iniziative nel sistema',
            label: "Abilita l'eliminazione dei lead",
          },
          crmLeadSeeAll: {
            title: 'Visualizza tutti i lead',
            description:
              'Questa opzione abilita la visualizzazione di tutte le derivazioni nel sistema',
            label: 'Abilita la visualizzazione di tutti i lead',
          },
          crmLeadSeeCoworkers: {
            title: 'Visualizza i lead dei colleghi',
            description: 'Questa opzione abilita la visualizzazione dei lead dei colleghi',
            label: 'Abilita la visualizzazione dei lead dei colleghi',
          },
          crmLeadUpdateCampaign: {
            title: 'Aggiorna la campagna assegnata ai lead',
            description: 'Questa opzione consente la modifica di più campagne di lead',
            label: 'Abilita la modifica della campagna principale',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pazienti',
              ovobank: 'Donatori',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pazienti',
              ovobank: 'Donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la visualizzazione dei pazienti nel sistema',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione del paziente',
              ovobank: 'Abilita la visualizzazione del donatore',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Creare pazienti',
              ovobank: 'Creare donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la creazione di pazienti nel sistema',
              ovobank: 'Questa opzione abilita la creazione di donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la creazione del paziente',
              ovobank: 'Abilita la creazione del donatore',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Modifica pazienti',
              ovobank: 'Modifica donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la modifica dei pazienti nel sistema',
              ovobank: 'Questa opzione consente la modifica dei donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la modifica del paziente',
              ovobank: 'Abilita la modifica del donatore',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Elimina pazienti',
              ovobank: 'Elimina donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la cancellazione dei pazienti nel sistema',
              ovobank: 'Questa opzione consente la rimozione dei donatori dal sistema',
            },
            label: {
              ovoclinic: "Abilita l'eliminazione del paziente",
              ovobank: 'Abilita la rimozione del donatore',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Vedi tutti i pazienti',
              ovobank: 'Vedi tutti i donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la visualizzazione di tutti i pazienti nel sistema',
              ovobank: 'Questa opzione abilita la visualizzazione di tutti i donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione di tutti i pazienti',
              ovobank: 'Abilita la visualizzazione di tutti i donatori',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Vedi i pazienti dei colleghi',
              ovobank: 'Visualizza i donatori peer',
            },
            description: {
              ovoclinic: 'Questa opzione consente la visualizzazione dei pazienti pari',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori peer',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione dei pazienti da parte dei colleghi',
              ovobank: 'Abilita la visualizzazione dei donatori peer',
            },
          },
          contacts: {
            accordion: 'Contatti',
          },
          crmContact: {
            title: 'Contatti',
            description: 'Questa opzione abilita la visualizzazione dei contatti nel sistema',
            label: 'Abilita la visualizzazione dei contatti',
          },
          crmContactCreator: {
            title: 'Crea contatti',
            description: 'Questa opzione abilita la creazione di contatti nel sistema',
            label: 'Abilita la creazione dei contatti',
          },
          crmContactEditor: {
            title: 'Modifica contatti',
            description: 'Questa opzione consente la modifica dei contatti nel sistema',
            label: 'Abilita la modifica dei contatti',
          },
          crmContactDestructor: {
            title: 'Elimina contatti',
            description: 'Questa opzione consente la cancellazione dei contatti nel sistema',
            label: "Abilita l'eliminazione dei contatti",
          },
          activity: {
            accordion: 'Attività',
          },
          crmActivity: {
            title: 'Attività',
            description: 'Questa opzione abilita la visualizzazione delle attività nel sistema',
            label: 'Abilita la visualizzazione delle attività',
          },
          crmActivityCreator: {
            title: 'Creare attività',
            description: 'Questa opzione abilita la creazione di attività nel sistema',
            label: 'Abilita la creazione di attività',
          },
          crmActivityEditor: {
            title: 'Modifica attività',
            description: 'Questa opzione consente la modifica delle attività nel sistema',
            label: 'Abilita la modifica delle attività',
          },
          crmActivityDestructor: {
            title: 'Elimina attività',
            description: 'Questa opzione abilita la cancellazione delle attività nel sistema',
            label: "Abilita l'eliminazione delle attività",
          },
          crmActivitySeeAll: {
            title: 'Vedi tutte le attività',
            description:
              'Questa opzione abilita la visualizzazione di tutte le attività nel sistema',
            label: 'Abilita la visualizzazione di tutte le attività',
          },
          results: {
            accordion: 'Risultati',
          },
          crmResult: {
            title: 'Risultati',
            description: 'Questa opzione abilita la visualizzazione dei risultati nel sistema',
            label: 'Abilita la visualizzazione dei risultati',
          },
          crmResultCreator: {
            title: 'Crea risultati',
            description: 'Questa opzione abilita la creazione dei risultati nel sistema',
            label: 'Abilita la creazione dei risultati',
          },
          crmResultEditor: {
            title: 'Modifica risultati',
            description: 'Questa opzione consente la modifica dei risultati nel sistema',
            label: 'Abilita la modifica dei risultati',
          },
          crmResultDestructor: {
            title: 'Elimina i risultati',
            description: 'Questa opzione consente la cancellazione dei risultati nel sistema',
            label: "Abilita l'eliminazione dei risultati",
          },
          crmResultCCEvent: {
            title: 'Modifica le tappe fondamentali del Call Center',
            description:
              'Questa opzione consente la modifica delle tappe fondamentali del Call Center nel sistema',
            label: 'Abilita la modifica dei traguardi del Call Center',
          },
          crmResultATPCEvent: {
            title: 'Modifica i traguardi commerciali ATP',
            description:
              'Questa opzione consente la modifica delle tappe fondamentali di ATP Commerciale nel sistema',
            label: 'Abilita la modifica dei traguardi commerciali ATP',
          },
          crmResultATPIEvent: {
            title: "Modifica i traguardi internazionali dell'ATP",
            description:
              "Questa opzione consente la modifica delle tappe fondamentali dell'ATP International nel sistema",
            label: 'Abilita la modifica dei traguardi internazionali ATP',
          },
          crmResultReactivity: {
            title: 'Modifica reattività',
            description: 'Questa opzione abilita la modifica della reattività nel sistema',
            label: 'Abilita la modifica della reattività',
          },
          reasons: {
            accordion: 'Ragioni',
          },
          crmReason: {
            title: 'Ragioni',
            description: 'Questa opzione abilita la visualizzazione delle causali nel sistema',
            label: 'Abilita la visualizzazione del motivo',
          },
          crmReasonCreator: {
            title: 'Crea motivi',
            description: 'Questa opzione abilita la creazione di causali nel sistema',
            label: 'Abilita la creazione del motivo',
          },
          crmReasonEditor: {
            title: 'Modifica motivi',
            description: 'Questa opzione abilita la modifica delle causali nel sistema',
            label: 'Abilita la modifica del motivo',
          },
          crmReasonDestructor: {
            title: 'Elimina motivi',
            description: 'Questa opzione consente la cancellazione delle causali nel sistema',
            label: "Abilita l'eliminazione del motivo",
          },
          campaigns: {
            accordion: 'Campagne',
          },
          crmCampaign: {
            title: 'Campagne',
            description: 'Questa opzione abilita la visualizzazione delle campagne nel sistema',
            label: 'Abilita la visualizzazione della campagna',
          },
          crmCampaignCreator: {
            title: 'Crea campagne',
            description: 'Questa opzione abilita la creazione di campagne nel sistema',
            label: 'Abilita la creazione della campagna',
          },
          crmCampaignEditor: {
            title: 'Modifica campagne',
            description: 'Questa opzione consente la modifica delle campagne nel sistema',
            label: 'Abilita la modifica della campagna',
          },
          crmCampaignDestructor: {
            title: 'Elimina campagne',
            description: 'Questa opzione consente la cancellazione delle campagne nel sistema',
            label: "Abilita l'eliminazione della campagna",
          },
          funnels: {
            accordion: 'Imbuti',
          },
          crmFunnel: {
            title: 'Imbuti',
            description: 'Questa opzione abilita la visualizzazione degli imbuti nel sistema',
            label: 'Abilita la visualizzazione della canalizzazione',
          },
          crmFunnelCreator: {
            title: 'Crea canalizzazioni',
            description: 'Questa opzione consente la creazione di canalizzazioni nel sistema',
            label: 'Abilita la creazione della canalizzazione',
          },
          crmFunnelEditor: {
            title: 'Modifica canalizzazioni',
            description: 'Questa opzione consente la modifica delle canalizzazioni nel sistema',
            label: 'Abilita la modifica della canalizzazione',
          },
          crmFunnelDestructor: {
            title: 'Elimina canalizzazioni',
            description: 'Questa opzione consente la rimozione degli imbuti nel sistema',
            label: 'Abilita la rimozione della canalizzazione',
          },
          channels: {
            accordion: 'Canali',
          },
          crmChannel: {
            title: 'Canali',
            description: 'Questa opzione abilita la visualizzazione dei canali sul sistema',
            label: 'Abilita la visualizzazione del canale',
          },
          crmChannelCreator: {
            title: 'Crea canali',
            description: 'Questa opzione abilita la creazione di canali nel sistema',
            label: 'Abilita la creazione del canale',
          },
          crmChannelEditor: {
            title: 'Modifica canali',
            description: 'Questa opzione consente la modifica dei canali nel sistema',
            label: 'Abilita la modifica del canale',
          },
          crmChannelDestructor: {
            title: 'Elimina canali',
            description: 'Questa opzione abilita la cancellazione dei canali nel sistema',
            label: "Abilita l'eliminazione del canale",
          },
          programs: {
            accordion: 'Trattamenti',
          },
          crmProgram: {
            title: 'Trattamenti',
            description:
              'Questa opzione abilita la visualizzazione dei trattamenti del catalogo nel sistema',
            label: 'Abilita la visualizzazione dei trattamenti del catalogo',
          },
          crmProgramCreator: {
            title: 'Creare trattamenti',
            description: 'Questa opzione abilita la creazione di trattamenti nel sistema',
            label: 'Abilita la creazione del trattamento',
          },
          crmProgramEditor: {
            title: 'Modifica trattamenti',
            description: 'Questa opzione consente la modifica dei trattamenti nel sistema',
            label: 'Abilita la modifica del trattamento',
          },
          crmProgramDestructor: {
            title: 'Elimina trattamenti',
            description: 'Questa opzione abilita la cancellazione dei trattamenti nel sistema',
            label: 'Abilita la rimozione del trattamento',
          },
          services: {
            accordion: 'Servizi',
          },
          crmService: {
            title: 'Servizi',
            description: 'Questa opzione abilita la visualizzazione dei servizi nel sistema',
            label: 'Abilita la visualizzazione del servizio',
          },
          crmServiceCreator: {
            title: 'Creare servizi',
            description: 'Questa opzione abilita la creazione di servizi nel sistema',
            label: 'Abilita la creazione del servizio',
          },
          crmServiceEditor: {
            title: 'Modifica servizi',
            description: 'Questa opzione consente la modifica dei servizi nel sistema',
            label: 'Abilita la modifica del servizio',
          },
          crmServiceDestructor: {
            title: 'Elimina servizi',
            description: 'Questa opzione consente la rimozione dei servizi sul sistema',
            label: 'Abilita la rimozione del servizio',
          },
          techniques: {
            accordion: 'Tecniche',
          },
          crmTechnique: {
            title: 'Tecniche complementari',
            description:
              'Questa opzione consente la visualizzazione di tecniche complementari nel sistema',
            label: 'Abilita la visualizzazione di tecniche complementari',
          },
          crmTechniqueCreator: {
            title: 'Creare tecniche complementari',
            description:
              'Questa opzione consente la creazione di tecniche complementari nel sistema',
            label: 'Consentire la creazione di tecniche complementari',
          },
          crmTechniqueEditor: {
            title: 'Modifica tecniche complementari',
            description:
              'Questa opzione consente la modifica di tecniche complementari nel sistema',
            label: 'Abilita la modifica di tecniche complementari',
          },
          crmTechniqueDestructor: {
            title: 'Eliminare le tecniche complementari',
            description:
              'Questa opzione consente la rimozione delle tecniche complementari nel sistema',
            label: 'Abilita la rimozione delle tecniche aggiuntive',
          },
          maintenances: {
            accordion: 'Manutenzione',
          },
          crmMaintenance: {
            title: 'Manutenzione',
            description: 'Questa opzione abilita la visualizzazione della manutenzione nel sistema',
            label: 'Abilita la visualizzazione della manutenzione',
          },
          crmMaintenanceCreator: {
            title: 'Creare manutenzione',
            description: 'Questa opzione abilita la creazione della manutenzione nel sistema',
            label: 'Abilita la creazione della manutenzione',
          },
          crmMaintenanceEditor: {
            title: 'Modifica manutenzione',
            description: 'Questa opzione consente la modifica della manutenzione nel sistema',
            label: 'Abilita la modifica di manutenzione',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminare la manutenzione',
            description: 'Questa opzione consente di eliminare la manutenzione del sistema',
            label: 'Abilita la rimozione della manutenzione',
          },
          treatments: {
            accordion: 'Trattamenti',
          },
          crmTreatment: {
            title: 'Trattamenti',
            description: 'Questa opzione consente la visualizzazione dei trattamenti nel sistema',
            label: 'Abilita la visualizzazione dei trattamenti',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Creare trattamenti per il paziente',
              ovobank: 'Creare trattamenti per i donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la creazione di trattamenti paziente nel sistema',
              ovobank:
                'Questa opzione consente la creazione di trattamenti per i donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la creazione di trattamenti per il paziente',
              ovobank: 'Abilita la creazione di trattamenti per il donatore',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Modifica i trattamenti per il paziente',
              ovobank: 'Modifica i trattamenti dei donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la modifica dei trattamenti dei pazienti nel sistema',
              ovobank:
                'Questa opzione consente la modifica dei trattamenti del donatore nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la modifica dei trattamenti per il paziente',
              ovobank: 'Abilita la modifica del trattamento per il donatore',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Elimina i trattamenti per il paziente',
              ovobank: 'Eliminare i trattamenti dei donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la cancellazione dei trattamenti dei pazienti nel sistema',
              ovobank:
                'Questa opzione consente la cancellazione dei trattamenti dei donatori nel sistema',
            },
            label: {
              ovoclinic: "Abilita l'eliminazione dei trattamenti per il paziente",
              ovobank: "Abilita l'eliminazione dei trattamenti per il donatore",
            },
          },
          phases: {
            accordion: 'Fasi',
          },
          crmPhase: {
            title: 'Fasi',
            description: 'Questa opzione abilita la visualizzazione delle fasi del sistema',
            label: 'Abilita la visualizzazione del palco',
          },
          crmPhaseCreator: {
            title: 'Crea fasi',
            description: 'Questa opzione abilita la creazione di fasi nel sistema',
            label: 'Abilita la messa in scena',
          },
          crmPhaseEditor: {
            title: 'Modifica fasi',
            description: 'Questa opzione abilita la modifica delle fasi del sistema',
            label: 'Abilita la modifica dello stage',
          },
          crmPhaseDestructor: {
            title: 'Elimina fasi',
            description: "Questa opzione consente l'eliminazione delle fasi nel sistema",
            label: "Abilita l'eliminazione delle fasi",
          },
          causes: {
            accordion: 'Ragioni',
          },
          crmCause: {
            title: 'Motivi di cancellazione',
            description:
              "Questa opzione abilita la visualizzazione nel sistema dei motivi dell'annullamento",
            label: 'Abilita la visualizzazione dei motivi di cancellazione',
          },
          crmCauseCreator: {
            title: 'Crea motivi di cancellazione',
            description:
              'Questa opzione abilita la creazione di causali di annullamento nel sistema',
            label: 'Abilita la creazione di motivi di annullamento',
          },
          crmCauseEditor: {
            title: "Modifica i motivi dell'annullamento",
            description:
              'Questa opzione consente la modifica dei motivi di cancellazione nel sistema',
            label: 'Abilita la modifica dei motivi di annullamento',
          },
          crmCauseDestructor: {
            title: 'Eliminare i motivi di cancellazione',
            description:
              'Questa opzione consente la rimozione dei motivi di cancellazione nel sistema',
            label: 'Abilita la rimozione dei motivi di annullamento',
          },
          cycles: {
            accordion: 'Cicli di trattamento',
          },
          crmCycle: {
            title: 'Cicli di trattamento',
            description:
              'Questa opzione consente la visualizzazione dei cicli di trattamento nel sistema',
            label: 'Abilita la visualizzazione dei cicli di trattamento',
          },
          crmCycleCreator: {
            title: 'Creare cicli di trattamento',
            description: 'Questa opzione permette di creare cicli di trattamento nel sistema',
            label: 'Abilita la creazione di cicli di trattamento',
          },
          crmCycleEditor: {
            title: 'Modifica i cicli di trattamento',
            description: 'Questa opzione consente di modificare i cicli di trattamento nel sistema',
            label: 'Abilita la modifica dei cicli di trattamento',
          },
          crmCycleDestructor: {
            title: 'Eliminare i cicli di trattamento',
            description:
              'Questa opzione permette di cancellare i cicli di trattamento presenti nel sistema',
            label: 'Abilita la cancellazione dei cicli di trattamento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: "Questa opzione abilita l'accesso al modulo OVOClinic",
            label: "Abilita l'accesso",
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: "Questa opzione abilita l'accesso al modulo OVOBoard",
            label: "Abilita l'accesso",
          },
          tory: {
            accordion: 'OVOToria',
            title: 'OVOToria',
            description: "Questa opzione abilita l'accesso al modulo OVOTory",
            label: "Abilita l'accesso",
          },
          profile: {
            accordion: 'Profilo OVO',
            title: 'Profilo OVO',
            description: "Questa opzione abilita l'accesso al modulo OVOProfile",
            label: "Abilita l'accesso",
          },
          status: {
            title: 'Ruolo attivo',
            description: 'Abilitare o disabilitare il ruolo nel sistema',
            label: 'Ruolo attivo',
          },
          submit: 'Crea ruolo',
        },
      },
      update: {
        title: 'Aggiorna ruolo',
        subtitle: 'Modificare i dati di un ruolo nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del ruolo',
          },
          general: {
            accordion: 'Generale',
            title: 'Autorizzazioni generali',
            description: 'Autorizzazioni generali per il ruolo',
          },
          viewDisabledRecords: {
            title: 'Visualizza i registri disabilitati',
            description: 'Questa opzione abilita la visualizzazione dei log disabilitati',
            label: 'Abilita la visualizzazione dei log disabilitati',
          },
          viewDisabledLeadRecords: {
            title: 'Visualizza i lead disabilitati',
            description: 'Questa opzione abilita la visualizzazione delle derivazioni disabilitate',
            label: 'Abilita la visualizzazione delle derivazioni disabilitate',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Visualizza i pazienti disabili',
              ovobank: 'Visualizza i donatori disabili',
            },
            description: {
              ovoclinic: 'Questa opzione abilita la visualizzazione dei pazienti disabili',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori disabili',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione dei pazienti disabili',
              ovobank: 'Abilita la visualizzazione dei donatori disabili',
            },
          },
          access: {
            accordion: 'Accesso OVO',
            title: 'Autorizzazione al modulo OVOAccess',
            description: "Questa opzione abilita l'accesso al modulo OVOAccess",
            label: "Abilita l'accesso",
          },
          user: {
            accordion: 'Utenti',
          },
          accessUser: {
            title: 'Visualizza gli utenti',
            description: 'Questa opzione abilita la visualizzazione degli utenti nel sistema',
            label: 'Abilita visualizzazione utente',
          },
          accessUserCreator: {
            title: 'Creare utenti',
            description: 'Questa opzione abilita la creazione di utenti nel sistema',
            label: "Abilita la creazione dell'utente",
          },
          accessUserEditor: {
            title: 'Modifica utenti',
            description: 'Questa opzione consente la modifica degli utenti nel sistema',
            label: "Abilita la modifica dell'utente",
          },
          accessUserDestructor: {
            title: 'Elimina utenti',
            description: 'Questa opzione abilita la cancellazione degli utenti nel sistema',
            label: "Abilita l'eliminazione dell'utente",
          },
          accessUserChangePassword: {
            title: 'Cambiare la password',
            description:
              'Questa opzione consente di modificare la password degli utenti nel sistema',
            label: 'Abilita la modifica della password',
          },
          role: {
            accordion: 'Ruoli',
          },
          accessRole: {
            title: 'Visualizza ruoli',
            description: 'Questa opzione abilita la visualizzazione dei ruoli nel sistema',
            label: 'Abilita la visualizzazione del ruolo',
          },
          accessRoleCreator: {
            title: 'Crea ruoli',
            description: 'Questa opzione abilita la creazione di ruoli nel sistema',
            label: 'Abilita la creazione del ruolo',
          },
          accessRoleEditor: {
            title: 'Modifica ruoli',
            description: 'Questa opzione consente la modifica dei ruoli nel sistema',
            label: 'Abilita la modifica del ruolo',
          },
          accessRoleDestructor: {
            title: 'Elimina ruoli',
            description: 'Questa opzione abilita la cancellazione dei ruoli nel sistema',
            label: "Abilita l'eliminazione del ruolo",
          },
          timetable: {
            accordion: 'Orari',
          },
          accessTimetable: {
            title: 'Vedi gli orari',
            description: 'Questa opzione abilita la visualizzazione degli orari nel sistema',
            label: 'Abilita la visualizzazione della pianificazione',
          },
          accessTimetableCreator: {
            title: 'Crea pianificazioni',
            description: 'Questa opzione abilita la creazione di pianificazioni nel sistema',
            label: 'Abilita la creazione della pianificazione',
          },
          accessTimetableEditor: {
            title: 'Modifica orari',
            description: 'Questa opzione consente la modifica delle pianificazioni nel sistema',
            label: 'Abilita la modifica della pianificazione',
          },
          accessTimetableDestructor: {
            title: 'Elimina pianificazioni',
            description: 'Questa opzione abilita la cancellazione delle pianificazioni nel sistema',
            label: "Abilita l'eliminazione della pianificazione",
          },
          bulletin: {
            accordion: 'Notizia',
          },
          accessBulletin: {
            title: 'Vedi le notizie',
            description: 'Questa opzione abilita la visualizzazione delle notizie nel sistema',
            label: 'Abilita la visualizzazione delle notizie',
          },
          accessBulletinCreator: {
            title: 'Crea notizie',
            description: 'Questa opzione abilita la creazione di notizie nel sistema',
            label: 'Abilita la creazione di notizie',
          },
          accessBulletinEditor: {
            title: 'Modifica notizie',
            description: 'Questa opzione abilita la modifica delle notizie nel sistema',
            label: 'Abilita la modifica delle notizie',
          },
          accessBulletinDestructor: {
            title: 'Elimina notizie',
            description: 'Questa opzione abilita la cancellazione delle notizie nel sistema',
            label: "Abilita l'eliminazione delle notizie",
          },
          accessClinic: {
            title: 'Vedi cliniche',
            description: 'Questa opzione consente la visualizzazione delle cliniche nel sistema',
            label: 'Abilita la visualizzazione della clinica',
          },
          accessClinicCreator: {
            title: 'Creare cliniche',
            description: 'Questa opzione consente la creazione di cliniche nel sistema',
            label: 'Abilita la creazione della clinica',
          },
          accessClinicEditor: {
            title: 'Modifica cliniche',
            description: 'Questa opzione consente la modifica delle cliniche nel sistema',
            label: 'Abilita la modifica della clinica',
          },
          accessClinicDestructor: {
            title: 'Elimina cliniche',
            description: 'Questa opzione consente la cancellazione delle cliniche nel sistema',
            label: "Abilita l'eliminazione della clinica",
          },
          department: {
            accordion: 'Dipartimenti',
          },
          accessDepartment: {
            title: 'Vedi dipartimenti',
            description:
              'Questa opzione abilita la visualizzazione dei reparti presenti nel sistema',
            label: 'Abilita la visualizzazione del reparto',
          },
          accessDepartmentCreator: {
            title: 'Creare dipartimenti',
            description: 'Questa opzione consente la creazione di dipartimenti nel sistema',
            label: 'Abilita la creazione del dipartimento',
          },
          accessDepartmentEditor: {
            title: 'Modifica dipartimenti',
            description: 'Questa opzione consente la modifica dei dipartimenti nel sistema',
            label: 'Abilita la modifica del dipartimento',
          },
          accessDepartmentDestructor: {
            title: 'Elimina dipartimenti',
            description: 'Questa opzione abilita la cancellazione dei reparti nel sistema',
            label: "Abilita l'eliminazione dei dipartimenti",
          },
          language: {
            accordion: 'Lingue',
          },
          accessLanguage: {
            title: 'Vedi le lingue',
            description: 'Questa opzione abilita la visualizzazione delle lingue nel sistema',
            label: 'Abilita la visualizzazione della lingua',
          },
          accessLanguageCreator: {
            title: 'Creare lingue',
            description: 'Questa opzione abilita la creazione di lingue nel sistema',
            label: 'Abilita la creazione della lingua',
          },
          accessLanguageEditor: {
            title: 'Modifica lingue',
            description: 'Questa opzione consente la modifica delle lingue nel sistema',
            label: 'Abilita la modifica della lingua',
          },
          accessLanguageDestructor: {
            title: 'Elimina le lingue',
            description: 'Questa opzione consente la rimozione delle lingue dal sistema',
            label: 'Abilita la rimozione della lingua',
          },
          country: {
            accordion: 'Paesi',
          },
          accessCountry: {
            title: 'Vedi i paesi',
            description: 'Questa opzione abilita la visualizzazione dei paesi nel sistema',
            label: 'Abilita la visualizzazione del paese',
          },
          accessCountryCreator: {
            title: 'Creare paesi',
            description: 'Questa opzione consente la creazione di paesi nel sistema',
            label: 'Abilita la creazione del paese',
          },
          accessCountryEditor: {
            title: 'Modifica paesi',
            description: 'Questa opzione consente la modifica dei paesi nel sistema',
            label: 'Abilita la modifica del Paese',
          },
          accessCountryDestructor: {
            title: 'Elimina paesi',
            description: 'Questa opzione consente la cancellazione dei paesi nel sistema',
            label: "Abilita l'eliminazione del paese",
          },
          accessSettings: {
            title: 'Visualizza le impostazioni',
            description: 'Questa opzione abilita la visualizzazione delle impostazioni nel sistema',
            label: 'Abilita la visualizzazione della configurazione',
          },
          specialists: {
            accordion: 'Specialisti',
          },
          accessSpecialist: {
            title: 'Vedi gli specialisti',
            description: 'Questa opzione consente la visualizzazione degli specialisti nel sistema',
            label: 'Abilita la visualizzazione degli specialisti',
          },
          accessSpecialistCreator: {
            title: 'Creare specialisti',
            description: 'Questa opzione consente la creazione di specialisti nel sistema',
            label: 'Consentire la creazione di specialisti',
          },
          accessSpecialistEditor: {
            title: 'Modifica specialisti',
            description: 'Questa opzione consente la modifica degli specialisti nel sistema',
            label: 'Abilita la modifica specializzata',
          },
          accessSpecialistDestructor: {
            title: 'Eliminare gli specialisti',
            description: 'Questa opzione consente la rimozione degli specialisti dal sistema',
            label: 'Abilita la rimozione specializzata',
          },
          commsChannels: {
            accordion: 'Canali di comunicazione',
          },
          accessCommsChannel: {
            title: 'Vedi canali di comunicazione',
            description:
              'Questa opzione abilita la visualizzazione dei canali di comunicazione nel sistema',
            label: 'Abilita la visualizzazione dei canali di comunicazione',
          },
          accessCommsChannelCreator: {
            title: 'Creare canali di comunicazione',
            description:
              'Questa opzione consente la creazione di canali di comunicazione nel sistema',
            label: 'Abilita la creazione di canali di comunicazione',
          },
          accessCommsChannelEditor: {
            title: 'Modifica i canali di comunicazione',
            description:
              'Questa opzione consente la modifica dei canali di comunicazione nel sistema',
            label: 'Abilita la modifica dei canali di comunicazione',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminare i canali di comunicazione',
            description:
              "Questa opzione consente l'eliminazione dei canali di comunicazione nel sistema",
            label: "Abilita l'eliminazione dei canali di comunicazione",
          },
          ovopoints: {
            accordion: 'Ovopunti',
          },
          accessOvopoint: {
            title: 'Ovopunti',
            description: 'Questa opzione abilita la visualizzazione degli ovopoint nel sistema',
            label: 'Abilita la visualizzazione degli ovopunti',
          },
          accessOvopointEditor: {
            title: 'Modifica ovopoint',
            description: 'Questa opzione abilita la modifica degli ovopoint nel sistema',
            label: 'Abilita la modifica del punto ovo',
          },
          templates: {
            accordion: 'Modelli',
          },
          accessTemplate: {
            title: 'Vedi i modelli WhatsApp',
            description: 'Questa opzione abilita la visualizzazione dei modelli WhatsApp',
            label: 'Abilita la visualizzazione del modello',
          },
          accessTemplateCreator: {
            title: 'Crea modelli WhatsApp',
            description: 'Questa opzione consente la creazione di modelli WhatsApp',
            label: 'Abilita modello',
          },
          accessTemplateEditor: {
            title: 'Modifica modelli WhatsApp',
            description: 'Questa opzione consente la modifica dei modelli WhatsApp',
            label: 'Abilita la modifica del modello',
          },
          accessTemplateDestructor: {
            title: 'Elimina modelli WhatsApp',
            description: "Questa opzione consente l'eliminazione dei modelli WhatsApp",
            label: "Abilita l'eliminazione del modello",
          },
          crm: {
            accordion: 'OVOCRM',
            title: "Autorizzazione dell'applicazione",
            description: "Questa opzione abilita l'accesso al modulo OVOCrm",
            label: "Abilita l'accesso",
          },
          tasks: {
            accordion: 'Compiti',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Questa opzione abilita la visualizzazione del calendario sul sistema',
            label: 'Abilita la visualizzazione del calendario',
          },
          crmTask: {
            title: 'Compiti',
            description: 'Questa opzione abilita la visualizzazione delle attività nel sistema',
            label: 'Abilita la visualizzazione delle attività',
          },
          crmTaskCreator: {
            title: 'Crea compiti',
            description: 'Questa opzione consente la creazione di attività nel sistema',
            label: 'Abilita la creazione di attività',
          },
          crmTaskEditor: {
            title: 'Modifica attività',
            description: 'Questa opzione consente la modifica delle attività nel sistema',
            label: 'Abilita la modifica delle attività',
          },
          crmTaskDestructor: {
            title: 'Elimina attività',
            description: "Questa opzione consente l'eliminazione delle attività nel sistema",
            label: "Abilita l'eliminazione delle attività",
          },
          crmTaskSeeAll: {
            title: 'Visualizza tutte le attività',
            description:
              'Questa opzione abilita la visualizzazione di tutte le attività nel sistema',
            label: 'Abilita la visualizzazione di tutte le attività',
          },
          crmTaskSeeCoworkers: {
            title: 'Visualizza le attività dei colleghi',
            description:
              'Questa opzione abilita la visualizzazione delle attività dei colleghi nel sistema',
            label: 'Abilita la visualizzazione delle attività peer',
          },
          crmTaskUpdateResponsible: {
            title: "Aggiorna la persona assegnata a un'attività",
            description:
              "Questa opzione consente di modificare la persona responsabile di un'attività",
            label: 'Abilita la modifica del task manager',
          },
          leads: {
            accordion: 'Conduce',
          },
          crmLead: {
            title: 'Conduce',
            description: 'Questa opzione abilita la visualizzazione dei lead nel sistema',
            label: 'Abilita la visualizzazione dei lead',
          },
          crmLeadCreator: {
            title: 'Crea lead',
            description: 'Questa opzione consente la creazione di lead nel sistema',
            label: 'Abilita la creazione di lead',
          },
          crmLeadEditor: {
            title: 'Modifica lead',
            description: 'Questa opzione consente la modifica dei lead nel sistema',
            label: 'Abilita la modifica dei lead',
          },
          crmLeadDestructor: {
            title: 'Elimina lead',
            description: 'Questa opzione consente la cancellazione delle iniziative nel sistema',
            label: "Abilita l'eliminazione dei lead",
          },
          crmLeadSeeAll: {
            title: 'Visualizza tutti i lead',
            description:
              'Questa opzione abilita la visualizzazione di tutte le derivazioni nel sistema',
            label: 'Abilita la visualizzazione di tutti i lead',
          },
          crmLeadSeeCoworkers: {
            title: 'Visualizza i lead dei colleghi',
            description: 'Questa opzione abilita la visualizzazione dei lead dei colleghi',
            label: 'Abilita la visualizzazione dei lead dei colleghi',
          },
          crmLeadUpdateCampaign: {
            title: 'Aggiorna la campagna assegnata ai lead',
            description: 'Questa opzione consente la modifica di più campagne di lead',
            label: 'Abilita la modifica della campagna principale',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pazienti',
              ovobank: 'Donatori',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pazienti',
              ovobank: 'Donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la visualizzazione dei pazienti nel sistema',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione del paziente',
              ovobank: 'Abilita la visualizzazione del donatore',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Creare pazienti',
              ovobank: 'Creare donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la creazione di pazienti nel sistema',
              ovobank: 'Questa opzione abilita la creazione di donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la creazione del paziente',
              ovobank: 'Abilita la creazione del donatore',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Modifica pazienti',
              ovobank: 'Modifica donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la modifica dei pazienti nel sistema',
              ovobank: 'Questa opzione consente la modifica dei donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la modifica del paziente',
              ovobank: 'Abilita la modifica del donatore',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Elimina pazienti',
              ovobank: 'Elimina donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la cancellazione dei pazienti nel sistema',
              ovobank: 'Questa opzione consente la rimozione dei donatori dal sistema',
            },
            label: {
              ovoclinic: "Abilita l'eliminazione del paziente",
              ovobank: 'Abilita la rimozione del donatore',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Vedi tutti i pazienti',
              ovobank: 'Vedi tutti i donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la visualizzazione di tutti i pazienti nel sistema',
              ovobank: 'Questa opzione abilita la visualizzazione di tutti i donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione di tutti i pazienti',
              ovobank: 'Abilita la visualizzazione di tutti i donatori',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Vedi i pazienti dei colleghi',
              ovobank: 'Visualizza i donatori peer',
            },
            description: {
              ovoclinic: 'Questa opzione consente la visualizzazione dei pazienti pari',
              ovobank: 'Questa opzione abilita la visualizzazione dei donatori peer',
            },
            label: {
              ovoclinic: 'Abilita la visualizzazione dei pazienti da parte dei colleghi',
              ovobank: 'Abilita la visualizzazione dei donatori peer',
            },
          },
          contacts: {
            accordion: 'Contatti',
          },
          crmContact: {
            title: 'Contatti',
            description: 'Questa opzione abilita la visualizzazione dei contatti nel sistema',
            label: 'Abilita la visualizzazione dei contatti',
          },
          crmContactCreator: {
            title: 'Crea contatti',
            description: 'Questa opzione abilita la creazione di contatti nel sistema',
            label: 'Abilita la creazione dei contatti',
          },
          crmContactEditor: {
            title: 'Modifica contatti',
            description: 'Questa opzione consente la modifica dei contatti nel sistema',
            label: 'Abilita la modifica dei contatti',
          },
          crmContactDestructor: {
            title: 'Elimina contatti',
            description: 'Questa opzione consente la cancellazione dei contatti nel sistema',
            label: "Abilita l'eliminazione dei contatti",
          },
          activity: {
            accordion: 'Attività',
          },
          crmActivity: {
            title: 'Attività',
            description: 'Questa opzione abilita la visualizzazione delle attività nel sistema',
            label: 'Abilita la visualizzazione delle attività',
          },
          crmActivityCreator: {
            title: 'Creare attività',
            description: 'Questa opzione abilita la creazione di attività nel sistema',
            label: 'Abilita la creazione di attività',
          },
          crmActivityEditor: {
            title: 'Modifica attività',
            description: 'Questa opzione consente la modifica delle attività nel sistema',
            label: 'Abilita la modifica delle attività',
          },
          crmActivityDestructor: {
            title: 'Elimina attività',
            description: 'Questa opzione abilita la cancellazione delle attività nel sistema',
            label: "Abilita l'eliminazione delle attività",
          },
          crmActivitySeeAll: {
            title: 'Vedi tutte le attività',
            description:
              'Questa opzione abilita la visualizzazione di tutte le attività nel sistema',
            label: 'Abilita la visualizzazione di tutte le attività',
          },
          results: {
            accordion: 'Risultati',
          },
          crmResult: {
            title: 'Risultati',
            description: 'Questa opzione abilita la visualizzazione dei risultati nel sistema',
            label: 'Abilita la visualizzazione dei risultati',
          },
          crmResultCreator: {
            title: 'Crea risultati',
            description: 'Questa opzione abilita la creazione dei risultati nel sistema',
            label: 'Abilita la creazione dei risultati',
          },
          crmResultEditor: {
            title: 'Modifica risultati',
            description: 'Questa opzione consente la modifica dei risultati nel sistema',
            label: 'Abilita la modifica dei risultati',
          },
          crmResultDestructor: {
            title: 'Elimina i risultati',
            description: 'Questa opzione consente la cancellazione dei risultati nel sistema',
            label: "Abilita l'eliminazione dei risultati",
          },
          crmResultCCEvent: {
            title: 'Modifica le tappe fondamentali del Call Center',
            description:
              'Questa opzione consente la modifica delle tappe fondamentali del Call Center nel sistema',
            label: 'Abilita la modifica dei traguardi del Call Center',
          },
          crmResultATPCEvent: {
            title: 'Modifica i traguardi commerciali ATP',
            description:
              'Questa opzione consente la modifica delle tappe fondamentali di ATP Commerciale nel sistema',
            label: 'Abilita la modifica dei traguardi commerciali ATP',
          },
          crmResultATPIEvent: {
            title: "Modifica i traguardi internazionali dell'ATP",
            description:
              "Questa opzione consente la modifica delle tappe fondamentali dell'ATP International nel sistema",
            label: 'Abilita la modifica dei traguardi internazionali ATP',
          },
          crmResultReactivity: {
            title: 'Modifica reattività',
            description: 'Questa opzione abilita la modifica della reattività nel sistema',
            label: 'Abilita la modifica della reattività',
          },
          reasons: {
            accordion: 'Ragioni',
          },
          crmReason: {
            title: 'Ragioni',
            description: 'Questa opzione abilita la visualizzazione delle causali nel sistema',
            label: 'Abilita la visualizzazione del motivo',
          },
          crmReasonCreator: {
            title: 'Crea motivi',
            description: 'Questa opzione abilita la creazione di causali nel sistema',
            label: 'Abilita la creazione del motivo',
          },
          crmReasonEditor: {
            title: 'Modifica motivi',
            description: 'Questa opzione abilita la modifica delle causali nel sistema',
            label: 'Abilita la modifica del motivo',
          },
          crmReasonDestructor: {
            title: 'Elimina motivi',
            description: 'Questa opzione consente la cancellazione delle causali nel sistema',
            label: "Abilita l'eliminazione del motivo",
          },
          campaigns: {
            accordion: 'Campagne',
          },
          crmCampaign: {
            title: 'Campagne',
            description: 'Questa opzione abilita la visualizzazione delle campagne nel sistema',
            label: 'Abilita la visualizzazione della campagna',
          },
          crmCampaignCreator: {
            title: 'Crea campagne',
            description: 'Questa opzione abilita la creazione di campagne nel sistema',
            label: 'Abilita la creazione della campagna',
          },
          crmCampaignEditor: {
            title: 'Modifica campagne',
            description: 'Questa opzione consente la modifica delle campagne nel sistema',
            label: 'Abilita la modifica della campagna',
          },
          crmCampaignDestructor: {
            title: 'Elimina campagne',
            description: 'Questa opzione consente la cancellazione delle campagne nel sistema',
            label: "Abilita l'eliminazione della campagna",
          },
          funnels: {
            accordion: 'Imbuti',
          },
          crmFunnel: {
            title: 'Imbuti',
            description: 'Questa opzione abilita la visualizzazione degli imbuti nel sistema',
            label: 'Abilita la visualizzazione della canalizzazione',
          },
          crmFunnelCreator: {
            title: 'Crea canalizzazioni',
            description: 'Questa opzione consente la creazione di canalizzazioni nel sistema',
            label: 'Abilita la creazione della canalizzazione',
          },
          crmFunnelEditor: {
            title: 'Modifica canalizzazioni',
            description: 'Questa opzione consente la modifica delle canalizzazioni nel sistema',
            label: 'Abilita la modifica della canalizzazione',
          },
          crmFunnelDestructor: {
            title: 'Elimina canalizzazioni',
            description: 'Questa opzione consente la rimozione degli imbuti nel sistema',
            label: 'Abilita la rimozione della canalizzazione',
          },
          channels: {
            accordion: 'Canali',
          },
          crmChannel: {
            title: 'Canali',
            description: 'Questa opzione abilita la visualizzazione dei canali sul sistema',
            label: 'Abilita la visualizzazione del canale',
          },
          crmChannelCreator: {
            title: 'Crea canali',
            description: 'Questa opzione abilita la creazione di canali nel sistema',
            label: 'Abilita la creazione del canale',
          },
          crmChannelEditor: {
            title: 'Modifica canali',
            description: 'Questa opzione consente la modifica dei canali nel sistema',
            label: 'Abilita la modifica del canale',
          },
          crmChannelDestructor: {
            title: 'Elimina canali',
            description: 'Questa opzione abilita la cancellazione dei canali nel sistema',
            label: "Abilita l'eliminazione del canale",
          },
          programs: {
            accordion: 'Trattamenti',
          },
          crmProgram: {
            title: 'Trattamenti',
            description:
              'Questa opzione abilita la visualizzazione dei trattamenti del catalogo nel sistema',
            label: 'Abilita la visualizzazione dei trattamenti del catalogo',
          },
          crmProgramCreator: {
            title: 'Creare trattamenti',
            description: 'Questa opzione abilita la creazione di trattamenti nel sistema',
            label: 'Abilita la creazione del trattamento',
          },
          crmProgramEditor: {
            title: 'Modifica trattamenti',
            description: 'Questa opzione consente la modifica dei trattamenti nel sistema',
            label: 'Abilita la modifica del trattamento',
          },
          crmProgramDestructor: {
            title: 'Elimina trattamenti',
            description: 'Questa opzione abilita la cancellazione dei trattamenti nel sistema',
            label: 'Abilita la rimozione del trattamento',
          },
          services: {
            accordion: 'Servizi',
          },
          crmService: {
            title: 'Servizi',
            description: 'Questa opzione abilita la visualizzazione dei servizi nel sistema',
            label: 'Abilita la visualizzazione del servizio',
          },
          crmServiceCreator: {
            title: 'Creare servizi',
            description: 'Questa opzione abilita la creazione di servizi nel sistema',
            label: 'Abilita la creazione del servizio',
          },
          crmServiceEditor: {
            title: 'Modifica servizi',
            description: 'Questa opzione consente la modifica dei servizi nel sistema',
            label: 'Abilita la modifica del servizio',
          },
          crmServiceDestructor: {
            title: 'Elimina servizi',
            description: 'Questa opzione consente la rimozione dei servizi sul sistema',
            label: 'Abilita la rimozione del servizio',
          },
          techniques: {
            accordion: 'Tecniche',
          },
          crmTechnique: {
            title: 'Tecniche complementari',
            description:
              'Questa opzione consente la visualizzazione di tecniche complementari nel sistema',
            label: 'Abilita la visualizzazione di tecniche complementari',
          },
          crmTechniqueCreator: {
            title: 'Creare tecniche complementari',
            description:
              'Questa opzione consente la creazione di tecniche complementari nel sistema',
            label: 'Consentire la creazione di tecniche complementari',
          },
          crmTechniqueEditor: {
            title: 'Modifica tecniche complementari',
            description:
              'Questa opzione consente la modifica di tecniche complementari nel sistema',
            label: 'Abilita la modifica di tecniche complementari',
          },
          crmTechniqueDestructor: {
            title: 'Eliminare le tecniche complementari',
            description:
              'Questa opzione consente la rimozione delle tecniche complementari nel sistema',
            label: 'Abilita la rimozione delle tecniche aggiuntive',
          },
          maintenances: {
            accordion: 'Manutenzione',
          },
          crmMaintenance: {
            title: 'Manutenzione',
            description: 'Questa opzione abilita la visualizzazione della manutenzione nel sistema',
            label: 'Abilita la visualizzazione della manutenzione',
          },
          crmMaintenanceCreator: {
            title: 'Creare manutenzione',
            description: 'Questa opzione abilita la creazione della manutenzione nel sistema',
            label: 'Abilita la creazione della manutenzione',
          },
          crmMaintenanceEditor: {
            title: 'Modifica manutenzione',
            description: 'Questa opzione consente la modifica della manutenzione nel sistema',
            label: 'Abilita la modifica di manutenzione',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminare la manutenzione',
            description: 'Questa opzione consente di eliminare la manutenzione del sistema',
            label: 'Abilita la rimozione della manutenzione',
          },
          treatments: {
            accordion: 'Trattamenti',
          },
          crmTreatment: {
            title: 'Trattamenti',
            description: 'Questa opzione consente la visualizzazione dei trattamenti nel sistema',
            label: 'Abilita la visualizzazione dei trattamenti',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Creare trattamenti per il paziente',
              ovobank: 'Creare trattamenti per i donatori',
            },
            description: {
              ovoclinic: 'Questa opzione consente la creazione di trattamenti paziente nel sistema',
              ovobank:
                'Questa opzione consente la creazione di trattamenti per i donatori nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la creazione di trattamenti per il paziente',
              ovobank: 'Abilita la creazione di trattamenti per il donatore',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Modifica i trattamenti per il paziente',
              ovobank: 'Modifica i trattamenti dei donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la modifica dei trattamenti dei pazienti nel sistema',
              ovobank:
                'Questa opzione consente la modifica dei trattamenti del donatore nel sistema',
            },
            label: {
              ovoclinic: 'Abilita la modifica dei trattamenti per il paziente',
              ovobank: 'Abilita la modifica del trattamento per il donatore',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Elimina i trattamenti per il paziente',
              ovobank: 'Eliminare i trattamenti dei donatori',
            },
            description: {
              ovoclinic:
                'Questa opzione consente la cancellazione dei trattamenti dei pazienti nel sistema',
              ovobank:
                'Questa opzione consente la cancellazione dei trattamenti dei donatori nel sistema',
            },
            label: {
              ovoclinic: "Abilita l'eliminazione dei trattamenti per il paziente",
              ovobank: "Abilita l'eliminazione dei trattamenti per il donatore",
            },
          },
          phases: {
            accordion: 'Fasi',
          },
          crmPhase: {
            title: 'Fasi',
            description: 'Questa opzione abilita la visualizzazione delle fasi del sistema',
            label: 'Abilita la visualizzazione del palco',
          },
          crmPhaseCreator: {
            title: 'Crea fasi',
            description: 'Questa opzione abilita la creazione di fasi nel sistema',
            label: 'Abilita la messa in scena',
          },
          crmPhaseEditor: {
            title: 'Modifica fasi',
            description: 'Questa opzione abilita la modifica delle fasi del sistema',
            label: 'Abilita la modifica dello stage',
          },
          crmPhaseDestructor: {
            title: 'Elimina fasi',
            description: "Questa opzione consente l'eliminazione delle fasi nel sistema",
            label: "Abilita l'eliminazione delle fasi",
          },
          causes: {
            accordion: 'Ragioni',
          },
          crmCause: {
            title: 'Motivi di cancellazione',
            description:
              "Questa opzione abilita la visualizzazione nel sistema dei motivi dell'annullamento",
            label: 'Abilita la visualizzazione dei motivi di cancellazione',
          },
          crmCauseCreator: {
            title: 'Crea motivi di cancellazione',
            description:
              'Questa opzione abilita la creazione di causali di annullamento nel sistema',
            label: 'Abilita la creazione di motivi di annullamento',
          },
          crmCauseEditor: {
            title: "Modifica i motivi dell'annullamento",
            description:
              'Questa opzione consente la modifica dei motivi di cancellazione nel sistema',
            label: 'Abilita la modifica dei motivi di annullamento',
          },
          crmCauseDestructor: {
            title: 'Eliminare i motivi di cancellazione',
            description:
              'Questa opzione consente la rimozione dei motivi di cancellazione nel sistema',
            label: 'Abilita la rimozione dei motivi di annullamento',
          },
          cycles: {
            accordion: 'Cicli di trattamento',
          },
          crmCycle: {
            title: 'Cicli di trattamento',
            description:
              'Questa opzione consente la visualizzazione dei cicli di trattamento nel sistema',
            label: 'Abilita la visualizzazione dei cicli di trattamento',
          },
          crmCycleCreator: {
            title: 'Creare cicli di trattamento',
            description: 'Questa opzione permette di creare cicli di trattamento nel sistema',
            label: 'Abilita la creazione di cicli di trattamento',
          },
          crmCycleEditor: {
            title: 'Modifica i cicli di trattamento',
            description: 'Questa opzione consente di modificare i cicli di trattamento nel sistema',
            label: 'Abilita la modifica dei cicli di trattamento',
          },
          crmCycleDestructor: {
            title: 'Eliminare i cicli di trattamento',
            description:
              'Questa opzione permette di cancellare i cicli di trattamento presenti nel sistema',
            label: 'Abilita la cancellazione dei cicli di trattamento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: "Questa opzione abilita l'accesso al modulo OVOClinic",
            label: "Abilita l'accesso",
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: "Questa opzione abilita l'accesso al modulo OVOBoard",
            label: "Abilita l'accesso",
          },
          tory: {
            accordion: 'OVOToria',
            title: 'OVOToria',
            description: "Questa opzione abilita l'accesso al modulo OVOTory",
            label: "Abilita l'accesso",
          },
          profile: {
            accordion: 'Profilo OVO',
            title: 'Profilo OVO',
            description: "Questa opzione abilita l'accesso al modulo OVOProfile",
            label: "Abilita l'accesso",
          },
          status: {
            title: 'Ruolo attivo',
            description: 'Abilitare o disabilitare il ruolo nel sistema',
            label: 'Ruolo attivo',
          },
          submit: 'Aggiorna ruolo',
        },
      },
      delete: {
        title: 'Elimina ruolo',
        subtitle: 'Questa azione è irreversibile',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disabilitare questo ruolo',
            label: 'Sì, voglio eliminare questo ruolo',
          },
          submit: 'Elimina ruolo',
        },
      },
      enable: {
        title: 'Abilita ruolo',
        subtitle: 'Questa azione consente al ruolo di essere attivo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di volerlo abilitare?',
            description: 'Questa azione consentirà al ruolo di essere attivo nel sistema',
            label: 'Sì, voglio abilitare questo ruolo',
          },
          submit: 'Abilita ruolo',
        },
      },
      disable: {
        title: 'Disabilita ruolo',
        subtitle: 'Questa azione disabilita il ruolo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di volerlo disabilitare?',
            description: 'Questa azione disabiliterà il ruolo nel sistema',
            label: 'Sì, voglio disabilitare questo ruolo',
          },
          submit: 'Disabilita ruolo',
        },
      },
    },
    clinics: {
      list: {
        title: 'Cliniche',
        description: 'Gestione della clinica',
        create: 'Clinica',
        one: 'Clinica',
        many: 'Cliniche',
        columns: {
          name: 'Nome',
          city: 'Città',
          document: 'CIF',
        },
      },
      create: {
        title: 'Nuova clinica',
        subtitle: 'Registrazione di una nuova clinica nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della clinica',
          },
          country: {
            title: 'Paese',
            description: 'Paese in cui si trova la clinica',
          },
          state: {
            title: 'Stato',
            description: 'Stato o provincia della clinica',
          },
          city: {
            title: 'Città',
            description: 'Città in cui si trova la clinica',
          },
          street: {
            title: 'Strada',
            description: 'Via dove si trova la clinica',
          },
          zip: {
            title: 'Cap',
            description: 'Codice postale della clinica',
          },
          timezone: {
            title: 'fuso orario',
            description: 'Fuso orario della clinica',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione della clinica',
          },
          status: {
            title: 'Clinica attiva',
            description: {
              ovoclinic:
                "Abilitare o disabilitare la clinica nel sistema per l'uso da parte del paziente",
              ovobank:
                "Abilita o disabilita la clinica nel sistema per l'uso da parte dei donatori",
            },
            label: 'Clinica attiva',
          },
          avatar: {
            title: 'Avatar',
            description: 'Immagine associata/Avatar della clinica. ',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF o documento identificativo della clinica',
          },
          title: {
            title: 'Qualificazione',
            description: 'Titolo della clinica',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione della clinica',
          },
          color: {
            title: 'Colore',
            description: 'Colore associato alla clinica nel sistema.',
          },
          submit: 'Crea clinica',
        },
      },
      update: {
        title: 'Aggiorna clinica',
        subtitle: 'Modifica dei dati clinici nel sistema',
        tabs: {
          details: {
            title: 'Dettagli',
            description:
              "Le cliniche sono raggruppamenti di primo livello del sistema e servono a raggruppare l'attività degli utenti. ",
          },
          departments: {
            title: 'Dipartimenti',
            description:
              "L'obiettivo principale dei reparti è quello di suddividere l'attività della clinica per una migliore organizzazione e controllo. ",
            add: 'Aggiungi dipartimento',
          },
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della clinica',
          },
          country: {
            title: 'Paese',
            description: 'Paese in cui si trova la clinica',
          },
          state: {
            title: 'Stato',
            description: 'Stato o provincia della clinica',
          },
          city: {
            title: 'Città',
            description: 'Città in cui si trova la clinica',
          },
          street: {
            title: 'Strada',
            description: 'Via dove si trova la clinica',
          },
          zip: {
            title: 'Cap',
            description: 'Codice postale della clinica',
          },
          timezone: {
            title: 'fuso orario',
            description: 'Fuso orario della clinica',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione della clinica',
          },
          status: {
            title: {
              ovoclinic: 'Clinica attiva',
              ovobank: 'Clinica attiva',
            },
            description: {
              ovoclinic:
                "Abilitare o disabilitare la clinica nel sistema per l'uso da parte del paziente",
              ovobank:
                "Abilita o disabilita la clinica nel sistema per l'uso da parte dei donatori",
            },
            label: {
              ovoclinic: 'Clinica attiva',
              ovobank: 'Clinica attiva',
            },
          },
          avatar: {
            title: 'Avatar',
            description: 'Immagine associata/Avatar della clinica. ',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF o documento identificativo della clinica',
          },
          title: {
            title: 'Qualificazione',
            description: 'Titolo della clinica',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione della clinica',
          },
          color: {
            title: 'Colore',
            description: 'Colore associato alla clinica nel sistema',
          },
          submit: 'Aggiorna clinica',
        },
      },
      disable: {
        title: 'Clinica per disabili',
        subtitle: 'Disabilitare la clinica nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa clinica?',
            description:
              'Disabilitare una clinica significa che non può essere utilizzata nel sistema',
            label: 'Sì, voglio disattivare questa clinica',
          },
          submit: 'Clinica per disabili',
        },
      },
      enable: {
        title: 'Abilita clinica',
        subtitle: 'Abilita la clinica nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa clinica?',
            description: 'Abilitare una clinica significa che può essere utilizzata nel sistema',
            label: 'Sì, voglio abilitare questa clinica',
          },
          submit: 'Abilita clinica',
        },
      },
      delete: {
        title: 'Clinica per disabili',
        subtitle: 'Disabilitare la clinica nel sistema',
        button: 'Disabilita',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa clinica?',
            description:
              'Disabilitare una clinica significa che non può essere utilizzata nel sistema',
            label: 'Sì, voglio disattivare questa clinica',
          },
          submit: 'Clinica per disabili',
        },
        passwordForm: {
          title: 'Inserisci la tua password',
          subtitle: 'Per disabilitare la clinica, inserisci la tua password',
          password: {
            title: 'Password',
            description: "Password dell'utente che esegue l'azione",
          },
          submit: 'Confermare',
        },
      },
    },
    licenses: {
      list: {
        one: 'Licenza',
        many: 'Licenze',
      },
    },
    movements: {
      list: {
        one: 'Movimento',
        many: 'Movimenti',
      },
    },
    attempts: {
      list: {
        one: 'Provato',
        many: 'Tentativi',
      },
    },
    contracts: {
      list: {
        one: 'Contrarre',
        many: 'Contratti',
      },
    },
    timetables: {
      list: {
        title: 'Orari',
        description: 'Gestione degli orari degli utenti',
        create: 'Programma',
        one: 'Programma',
        many: 'Orari',
        columns: {
          name: 'Nome',
          day: 'Giorno',
          range: 'Allineare',
        },
      },
      create: {
        title: 'Nuovo programma',
        subtitle: 'Registrazione di un nuovo programma nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della pianificazione',
          },
          day: {
            title: 'Giorno',
            description: 'Giorno della settimana in cui si applica il programma',
          },
          started: {
            title: 'Inizio',
            description: 'Orario di inizio pianificazione',
          },
          ended: {
            title: 'FINE',
            description: 'Ora di fine della pianificazione',
          },
          submit: 'Crea pianificazione',
        },
      },
      update: {
        title: 'Aggiorna il programma',
        subtitle: 'Modifica dei dati di pianificazione nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della pianificazione',
          },
          submit: 'Salva programma',
        },
        tabs: {
          timetable: {
            title: 'Programma',
            description: 'Dettagli del programma',
          },
          timetableDay: {
            title: 'Giorno',
            description: 'Giorni della settimana in cui si applica la pianificazione',
          },
        },
        timetableDays: {
          create: {
            title: 'Aggiungi giorno',
            subtitle: 'Aggiungi un giorno alla pianificazione',
            form: {
              day: {
                title: 'Giorno',
                description: 'Giorno della settimana in cui si applica il programma',
              },
              started: {
                title: 'Inizio',
                description: 'Orario di inizio pianificazione',
              },
              ended: {
                title: 'FINE',
                description: 'Ora di fine della pianificazione',
              },
              submit: 'Aggiungi giorno',
            },
          },
          update: {
            title: "Giorno dell'aggiornamento",
            subtitle: 'Modificare i dati di un giorno nel palinsesto',
            form: {
              day: {
                title: 'Giorno',
                description: 'Giorno della settimana in cui si applica il programma',
              },
              started: {
                title: 'Inizio',
                description: 'Orario di inizio pianificazione',
              },
              ended: {
                title: 'FINE',
                description: 'Ora di fine della pianificazione',
              },
              submit: "Giorno dell'aggiornamento",
            },
          },
          delete: {
            title: 'Elimina giorno',
            subtitle: 'Elimina il giorno dalla pianificazione',
            form: {
              status: {
                title: 'Sei sicuro di voler eliminare questo giorno?',
                label: 'Sì, voglio eliminare questo giorno',
              },
              submit: 'Elimina giorno',
            },
          },
          add: 'Aggiungi giorno',
        },
      },
      delete: {
        title: 'Elimina pianificazione',
        subtitle: 'Disabilitare la pianificazione nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questa pianificazione?',
            description:
              'Disabilitare una pianificazione significa che non può essere utilizzata nel sistema',
            label: 'Sì, desidero disattivare questa pianificazione',
          },
          submit: 'Disabilita pianificazione',
        },
      },
      disable: {
        title: 'Disabilita pianificazione',
        subtitle: 'Disabilitare la pianificazione nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questa pianificazione?',
            description:
              'Disabilitare una pianificazione significa che non può essere utilizzata nel sistema',
            label: 'Sì, desidero disattivare questa pianificazione',
          },
          submit: 'Disabilita pianificazione',
        },
      },
      enable: {
        title: 'Abilita pianificazione',
        subtitle: 'Abilita la pianificazione nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa pianificazione?',
            description:
              'Abilitare una pianificazione significa che può essere utilizzata nel sistema',
            label: 'Sì, voglio abilitare questa pianificazione',
          },
          submit: 'Abilita pianificazione',
        },
      },
    },
    departments: {
      list: {
        title: 'Dipartimenti',
        description: 'Gestione del dipartimento',
        create: 'Dipartimento',
        one: 'Dipartimento',
        many: 'Dipartimenti',
        columns: {
          name: 'Nome',
          responsible: 'Responsabile',
          language: 'Lingua',
          country: 'Paese',
          clinic: 'Clinica',
          created: 'Creazione',
        },
      },
      create: {
        title: 'Nuovo dipartimento',
        subtitle: 'Registrazione di un nuovo dipartimento nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del dipartimento',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica a cui appartiene il reparto',
          },
          status: {
            title: 'Dipartimento attivo',
            description: 'Abilitare o disabilitare il reparto nel sistema',
            label: 'Dipartimento attivo',
          },
          languageId: {
            title: 'Lingua',
            description: 'Lingua del dipartimento',
          },
          responsibleId: {
            title: 'Responsabile',
            description: 'Utente responsabile del dipartimento',
          },
          submit: 'Creare',
        },
      },
      enable: {
        title: 'Abilita dipartimento',
        subtitle: 'Abilita il reparto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo dipartimento?',
            label: 'Sì, voglio abilitare questo dipartimento',
          },
          submit: 'Abilita dipartimento',
        },
      },
      disable: {
        title: 'Dipartimento disabili',
        subtitle: 'Disabilitare il reparto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo dipartimento?',
            label: 'Sì, voglio disabilitare questo dipartimento',
          },
          submit: 'Dipartimento disabili',
        },
      },
      update: {
        title: 'Dipartimento di aggiornamento',
        subtitle: 'Modificare i dati di un reparto nel sistema',
        department: {
          tab: 'Dipartimento',
          description: 'Dettagli del dipartimento',
        },
        clinic: {
          tab: 'Clinica',
          description: 'Clinica a cui appartiene il reparto',
        },
        responsible: {
          tab: 'Responsabile',
          description: 'Responsabile del dipartimento',
        },
        language: {
          tab: 'Lingua',
          description: 'Lingua del dipartimento',
        },
        tabs: {
          department: {
            title: 'Dipartimento',
            description: 'Dettagli del dipartimento',
          },
          members: {
            title: 'Membri',
            description: 'Membri del Dipartimento. ',
          },
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del dipartimento',
          },
          languageId: {
            title: 'Lingua',
            description: 'Lingua del dipartimento',
          },
          responsibleId: {
            title: 'Responsabile',
            description: 'Utente responsabile del dipartimento',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica a cui appartiene il reparto',
          },
          status: {
            title: 'Dipartimento attivo',
            description: 'Abilitare o disabilitare il reparto nel sistema',
            label: 'Dipartimento attivo',
          },
          submit: 'Dipartimento di aggiornamento',
        },
        members: {
          one: 'Membro',
          many: 'Membri',
          add: 'Aggiungi membro',
          create: {
            title: 'Aggiungi membro',
            subtitle: 'Un membro è un utente che appartiene a un dipartimento. ',
            form: {
              contractId: {
                title: 'Utente',
              },
              submit: 'Aggiungi membro',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Elimina membro',
        subtitle: 'Elimina membro dal dipartimento. ',
        form: {
          submit: 'Elimina membro',
        },
      },
    },
    countries: {
      list: {
        title: 'Paesi',
        description: 'Gestione del paese',
        create: 'Paese',
        one: 'Paese',
        many: 'Paesi',
        columns: {
          name: 'Nome',
          code: 'Codice',
          coin: 'Valuta',
          updated: 'Aggiornamento',
          created: 'Creazione',
        },
      },
      update: {
        title: 'Modifica paese',
        subtitle: 'Modifica il paese nel sistema',
        form: {
          countryNameEn: {
            title: 'Nome inglese',
            description: 'Nome inglese del paese',
          },
          countryNameEs: {
            title: 'Nome spagnolo',
            description: 'Nome spagnolo del paese',
          },
          flag: {
            title: 'Bandiera',
            description: "Bandiera del paese rappresentata con un'emoticon",
          },
          coin: {
            title: 'Valuta',
            description: 'Valuta utilizzata nel paese',
          },
          code: {
            title: 'codice paese',
            description: 'Codice paese rappresentato in 2 caratteri (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefisso',
            description: 'Prefisso telefonico del paese',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione del paese',
          },
          geometry: {
            title: 'Geometria in formato json',
            description: 'Geometria del paese in formato json',
          },
          latitude: {
            title: 'Coordinata latitudine',
            description: 'Coordinata latitudine del paese',
          },
          longitude: {
            title: 'Coordinata della lunghezza',
            description: 'Coordinata longitudinale del paese',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione del paese',
          },
          color: {
            title: 'Formato colore #ffffff, rete, rgb(0,0,0)',
            description: 'Colore del paese in formato esadecimale, RGB o nome in formato inglese',
          },
          submit: 'Salva Paese',
        },
      },
      delete: {
        title: 'Elimina paese',
        subtitle: 'Disabilita il paese nel sistema',
        form: {
          submit: 'Elimina paese',
        },
      },
      disable: {
        title: 'Disabilita paese',
        subtitle: 'Disabilita il paese nel sistema',
        form: {
          submit: 'Disabilita paese',
        },
      },
      enable: {
        title: 'Abilita paese',
        subtitle: 'Abilita il paese nel sistema',
        form: {
          submit: 'Abilita paese',
        },
      },
      create: {
        title: 'nuovo paese',
        subtitle: 'Registrazione di un nuovo paese nel sistema',
        form: {
          countryNameEn: {
            title: 'Nome inglese',
            description: 'Nome inglese del paese',
          },
          countryNameEs: {
            title: 'Nome spagnolo',
            description: 'Nome spagnolo del paese',
          },
          flag: {
            title: 'Bandiera',
            description: "Bandiera del paese rappresentata con un'emoticon",
          },
          coin: {
            title: 'Valuta',
            description: 'Valuta utilizzata nel paese',
          },
          code: {
            title: 'codice paese',
            description: 'Codice paese rappresentato in 2 caratteri (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefisso',
            description: 'Prefisso telefonico del paese',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione del paese',
          },
          geometry: {
            title: 'Geometria in formato json',
            description: 'Geometria del paese in formato json',
          },
          latitude: {
            title: 'Coordinata latitudine',
            description: 'Coordinata latitudine del paese',
          },
          longitude: {
            title: 'Coordinata della lunghezza',
            description: 'Coordinata longitudinale del paese',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione del paese',
          },
          color: {
            title: 'Formato colore #ffffff, rete, rgb(0,0,0)',
            description: 'Colore del paese in formato esadecimale, RGB o nome in formato inglese',
          },
          submit: 'Crea Paese',
        },
      },
    },
    access: {
      create: {
        title: 'Nuovo accesso',
        subtitle: 'Registrazione di un nuovo accesso nel sistema',
        form: {
          ip: {
            title: 'Indirizzo IP',
            description: 'Indirizzo IP del dispositivo che accede al sistema',
          },
          status: {
            title: 'Accesso al sistema',
            description: "Abilita o disabilita l'accesso al sistema per questo utente e questo IP",
            label: "Consentire l'accesso al sistema",
          },
          submit: 'Crea accesso',
        },
      },
    },
    settings: {
      title: 'Configurazione',
      subtitle: 'Configurazione della piattaforma',
      form: {
        loginEnabled: {
          title: "Abilita l'accesso",
          description: "Se l'accesso è abilitato, qualsiasi utente può accedere al sistema",
          label: "Consenti l'accesso",
        },
        attemptLimitEnabled: {
          title: 'Abilita limite tentativi',
          description:
            "Se il limite di tentativi è abilitato, l'utente verrà bloccato se non riesce ad accedere più del numero di tentativi consentiti per l'utente. ",
          label: 'Consenti limite tentativi',
        },
        movementLimitEnabled: {
          title: 'Abilita limite di movimento',
          description:
            "Se il limite di movimento è abilitato, l'utente verrà bloccato se effettua più richieste di quelle consentite. ",
          label: 'Consenti limite di movimento',
        },
        submit: 'Salva impostazioni',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Attività',
      contacts: 'Contatti',
      marketing: 'Marketing',
      sales: 'Saldi',
      directory: 'Direttorio',
      customers: 'Clienti',
      commercial: 'Commerciale',
      campaigns: 'Campagne',
      funnels: 'Imbuti',
      channels: 'Canali',
      leads: 'Conduce',
      transferLead: 'Trasferimento di piombo',
      patients: {
        ovoclinic: 'Pazienti',
        ovobank: 'Donatori',
      },
      programs: 'Programmi',
      maintenances: 'Manutenzione',
      tasks: 'Compiti',
      transferTask: 'Trasferimento di attività',
      createTaskMassively: 'Crea attività in blocco',
      actions: 'Calendario',
      origins: 'Origini',
      techniques: 'Tecniche',
      services: 'Servizi',
      activities: 'Attività',
      results: 'Risultati',
      reasons: 'Ragioni',
      products: 'Prodotti',
      categories: 'Categorie',
      dashboard: 'Pannello di controllo',
      catalog: 'Catalogare',
      phases: 'Fasi',
      causes: 'Cause',
      treatments: 'Trattamenti',
      cycles: 'Cicli',
    },
    faq: {
      title: 'Domande frequenti',
      description: 'Domande frequenti',
      list: {
        '1': {
          question: 'Come posso creare un lead?',
          answer: 'Per creare un lead, procedi nel seguente modo:',
        },
        '2': {
          question: 'Come posso creare una campagna?',
          answer: 'Per creare una campagna, procedi nel seguente modo:',
        },
        '3': {
          question: "Come posso creare un'attività?",
          answer: "Per creare un'attività, attenersi alla seguente procedura:",
        },
        '4': {
          question: 'Come posso trasferire un lead?',
          answer: 'Per trasferire un lead, procedi nel seguente modo:',
        },
      },
    },
    information: {
      list: {
        '0': {
          title: 'Flusso di lavoro del dipartimento NAZIONALE',
          sublists: {
            '0': {
              title: 'Call Center',
            },
            '1': {
              title: 'Cura del paziente Commerciale',
            },
            '2': {
              title: 'Dottore per la cura del paziente',
            },
          },
        },
        '1': {
          title: 'Flusso di lavoro del dipartimento INTERNAZIONALE',
        },
      },
    },
    dashboard: {
      title: 'Pannello di controllo',
      description: 'Pannello di controllo della tua attività',
      actions: {
        title: 'Compiti',
        description: {
          ovoclinic: 'Gestione delle attività a cui sei assegnato. ',
          ovobank: 'Gestione delle attività a cui sei assegnato. ',
        },
      },
      receipts: {
        title: 'Ultimi incassi',
        description: 'Pagamenti effettuati ai tuoi contatti',
        list: {
          columns: {
            contactName: 'Nome',
            payments: 'Pagamenti',
            updated: 'aggiornamento F',
          },
          many: 'Ricevute',
          one: 'Ricevuta',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Conduce',
          ovobank: 'Donatori',
        },
        description: {
          ovoclinic: 'Gestione dei tuoi lead/pazienti',
          ovobank: 'Gestione dei tuoi lead/donatori',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campagne',
          ovobank: 'Campagne',
        },
        description: {
          ovoclinic: 'Gestione delle tue campagne',
          ovobank: 'Gestione delle tue campagne',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Trasferimento di piombo',
        description: 'Trasferisci campagne dai lead',
        create: 'Guida',
        one: 'Guida',
        many: 'Conduce',
        required: 'È necessario selezionare almeno un lead',
        columns: {
          name: 'Nome',
          activity: 'Attività',
          responsible: 'Responsabile',
          campaignName: 'Campana',
          started: 'Data stimata',
          clinicName: 'Clinica',
          created: 'Creazione',
          resultId: 'Progressi',
          phones: 'Telefono',
          emails: 'E-mail',
        },
      },
      confirm: {
        title: 'Trasferisci lead',
        subtitle: 'Riassegna la campagna a più lead',
        success: 'Lead trasferiti correttamente',
        steps: {
          campaign: {
            form: 'Campagna selezionata',
          },
        },
        form: {
          campaignId: {
            title: 'Seleziona campagna',
            description: 'Campagna a cui verranno riassegnati i lead',
          },
          responsibleId: {
            title: 'Seleziona responsabile',
            description:
              'Persona responsabile a cui verranno riassegnati i lead quando si cambiano le campagne',
          },
          submit: 'Trasferire',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Trasferimento di attività',
          ovobank: 'Trasferimento di attività',
        },
        description: {
          ovoclinic: 'Trasferisci attività ad altri utenti. ',
          ovobank: 'Trasferisci attività ad altri utenti. ',
        },
        create: 'Compito',
        one: 'Compito',
        many: 'Compiti',
        columns: {
          activity: 'Attività',
          phone: 'Telefono',
          contact: 'Contatto',
          subject: 'Affare',
          resultId: 'Progressi',
          campaign: 'Campana',
          clinic: 'Clinica',
          contactTime: 'Preferenza temporale',
          created: 'Creazione',
          user: 'Responsabile',
          started: 'Data di inizio stimata',
          finished: 'Durata',
        },
      },
      confirm: {
        title: 'Trasferisci compiti',
        subtitle: 'Riassegnare la responsabilità a più attività',
        success: 'Attività trasferite correttamente',
        form: {
          responsibleId: {
            title: 'Seleziona responsabile',
            description: 'Persona a cui verranno riassegnate le attività',
          },
          submit: 'Trasferire',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Crea attività in blocco',
        description: 'Questa schermata consente di creare un tipo di attività per più lead',
        columns: {
          name: 'Nome',
          clinicName: 'Clinica',
          campaignName: 'Campana',
          phones: 'Telefono',
          emails: 'E-mail',
          created: 'Creazione',
          lastAction: 'Ultimo compito',
          subject: 'Affare',
          lastActionDate: "Data dell'attività in sospeso",
        },
        noItemSelected: 'Non ci sono lead selezionati',
        one: 'Guida',
        many: 'Conduce',
      },
      selectActivity: {
        title: "Seleziona l'attività",
        subtitle: "Seleziona l'attività che desideri creare per i lead selezionati",
        form: {
          activityId: {
            title: 'Attività',
            description: 'Attività che desideri creare per i lead selezionati',
            required: "È necessario selezionare un'attività",
          },
          subject: {
            title: 'Affare',
            description:
              "L'oggetto che verrà impostato per tutte le attività che verranno create per i lead selezionati",
          },
          submit: 'Crea compiti',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Compiti',
          ovobank: 'Compiti',
        },
        description: {
          ovoclinic: 'Gestione delle attività a cui sei assegnato. ',
          ovobank: 'Gestione delle attività a cui sei assegnato. ',
        },
        create: {
          ovoclinic: 'Compito',
          ovobank: 'Compito',
        },
        one: 'Compito',
        many: 'Compiti',
        columns: {
          activity: 'Attività',
          contact: 'Contatto',
          responsible: 'Responsabile',
          subject: 'Affare',
          resultId: 'Progressi',
          resultName: 'Risultato',
          campaign: 'Campana',
          clinic: 'Clinica',
          contactTime: 'Preferenza temporale',
          created: 'Creazione',
          user: 'Responsabile',
          started: 'Data di inizio stimata',
          finished: 'Durata',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'Nuova azione',
              subtitle: 'Registrazione di una nuova azione nel sistema. ',
              form: {
                clinicId: {
                  title: 'Clinica',
                  description: "Clinica alla quale è diretta l'azione",
                },
                submit: 'Seguente',
              },
            },
            lead: {
              title: 'Nuova azione',
              subtitle: 'Registrazione di una nuova azione nel sistema. ',
              form: {
                leadId: {
                  title: 'Guida',
                  description: "Lead a cui è diretta l'azione",
                },
                submit: 'Seguente',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'Tutto il giorno',
        previous: 'Ex',
        next: 'Seguente',
        today: 'Oggi',
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
        agenda: 'ordine del giorno',
        date: 'Data',
        time: 'Ora',
        event: 'Evento',
      },
    },
    channels: {
      list: {
        title: 'Canali',
        description: 'Gestione dei canali di comunicazione',
        create: 'Canale',
        one: 'Canale',
        many: 'Canali',
        columns: {
          name: 'Nome',
          funnels: 'Imbuti',
        },
      },
      create: {
        title: 'Nuovo canale',
        subtitle: 'Registrazione di un nuovo canale nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del canale',
          },
          status: {
            title: 'Canale attivo',
            description: 'Abilitare o disabilitare il canale nel sistema',
            label: 'Canale attivo',
          },
          submit: 'Crea canale',
        },
      },
      update: {
        title: 'Aggiorna canale',
        subtitle: 'Modificare i dati di un canale nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del canale',
          },
          status: {
            title: 'Canale attivo',
            description: 'Abilitare o disabilitare il canale nel sistema',
            label: 'Canale attivo',
          },
          submit: 'Aggiorna canale',
        },
      },
      disable: {
        title: 'Disabilita canale',
        subtitle: 'Disabilitare il canale nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo canale?',
            description:
              'Disabilitare un canale significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disattivare questo canale',
          },
          submit: 'Disabilita canale',
        },
      },
      enable: {
        title: 'Abilita canale',
        subtitle: 'Abilita il canale nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo canale?',
            description: 'Abilitare un canale significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo canale',
          },
          submit: 'Abilita canale',
        },
      },
      delete: {
        title: 'Elimina canale',
        subtitle: 'Questa azione è irreversibile',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disabilitare questo canale',
            label: 'Sì, voglio eliminare questo canale',
          },
          submit: 'Eliminare',
        },
      },
    },
    cycles: {
      list: {
        title: 'Cicli',
        description: 'Gestione dei cicli di trattamento',
        create: 'Ciclo',
        one: 'Ciclo',
        many: 'Cicli',
        columns: {
          name: 'Nome',
          complexity: 'Complessità',
          created: 'Data di creazione',
          code: 'Codice',
        },
      },
      create: {
        title: 'Nuovo ciclo',
        subtitle: 'Registrazione di un nuovo ciclo nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del ciclo',
          },
          complexity: {
            title: 'Complessità',
            description: 'Complessità del ciclo',
          },
          code: {
            title: 'Codice',
            description: 'Codice identificativo del ciclo',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione del ciclo',
          },
          submit: 'Crea ciclo',
        },
      },
      update: {
        title: 'Ciclo di aggiornamento',
        subtitle: 'Modificare i dati di un ciclo nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del ciclo',
          },
          complexity: {
            title: 'Complessità',
            description: 'Complessità del ciclo',
          },
          code: {
            title: 'Codice',
            description: 'Codice identificativo del ciclo',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione del ciclo',
          },
          submit: 'Ciclo di aggiornamento',
        },
      },
      enable: {
        title: 'Abilita ciclo',
        subtitle: 'Abilita il ciclo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo ciclo?',
            description: 'Abilitare un ciclo significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo ciclo',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita ciclo',
        subtitle: 'Disabilitare il ciclo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo ciclo?',
            description:
              'Disabilitare un ciclo significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disabilitare questo ciclo',
          },
          submit: 'Disabilita',
        },
      },
    },
    funnels: {
      list: {
        title: 'Imbuti',
        description: 'Gestione dei funnel di conversione. ',
        create: 'Imbuto',
        one: 'Imbuto',
        many: 'Imbuti',
        params: {
          channelId: 'Canalizzazioni filtrate per canale di comunicazione',
        },
        columns: {
          name: 'Nome',
          channelName: 'Canale',
        },
      },
      create: {
        title: 'Nuovo imbuto',
        subtitle: 'Registrazione di un nuovo funnel nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: "Nome identificativo dell'imbuto",
          },
          channelId: {
            title: 'Canale',
            description: 'Canale di comunicazione associato al funnel',
          },
          status: {
            title: 'Imbuto attivo',
            description: "Abilita o disabilita l'imbuto nel sistema",
            label: 'Imbuto attivo',
          },
          submit: 'Crea imbuto',
        },
      },
      update: {
        title: 'Aggiorna canalizzazione',
        subtitle: 'Modifica dei dati di un imbuto nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: "Nome identificativo dell'imbuto",
          },
          channelId: {
            title: 'Canale',
            description: 'Canale di comunicazione associato al funnel',
          },
          status: {
            title: 'Imbuto attivo',
            description: "Abilita o disabilita l'imbuto nel sistema",
            label: 'Imbuto attivo',
          },
          submit: 'Aggiorna canalizzazione',
        },
      },
      delete: {
        title: 'Elimina imbuto',
        subtitle: 'Questa azione è irreversibile',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disabilitare questa canalizzazione',
            label: 'Sì, voglio eliminare questa canalizzazione',
          },
          submit: 'Eliminare',
        },
      },
      enable: {
        title: 'Abilita imbuto',
        subtitle: "Abilita l'imbuto nel sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa canalizzazione?',
            description: 'Abilitare un funnel significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questa canalizzazione',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita imbuto',
        subtitle: "Disabilitare l'imbuto nel sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa canalizzazione?',
            description:
              'Disabilitare un funnel significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disabilitare questa canalizzazione',
          },
          submit: 'Disabilita',
        },
      },
    },
    leads: {
      import: {
        title: 'Importazione di piombo',
        subtitle: 'Importazione di lead nel sistema',
        description: 'Caricamento dei lead nel sistema tramite un file CSV. ',
        list: {
          columns: {
            name: 'Nome',
            errors: 'Numero di errori',
            created: 'Data di importazione',
          },
          one: 'Importare',
          many: 'Importazioni',
        },
        delete: {
          title: 'Elimina il record di importazione',
          subtitle: 'Elimina il record di importazione nel sistema',
          form: {
            submit: 'Eliminare',
          },
        },
      },
      list: {
        title: 'Conduce',
        description: 'Gestione dei potenziali clienti generati attraverso le campagne',
        create: 'Guida',
        one: 'Guida',
        many: 'Conduce',
        columns: {
          name: 'Nome',
          activity: 'Attività',
          responsible: 'Responsabile',
          campaignName: 'Campana',
          started: 'Data stimata',
          clinicName: 'Clinica',
          created: 'Creazione',
          resultId: 'Progressi',
          phones: 'Telefono',
          emails: 'E-mail',
        },
        csv: {
          select: 'IMPORTA .CSV',
          somethingwentwrong: 'Lead non caricati',
          wrongdata: 'il file è sbagliato oppure i dati non corrispondono al formato corretto',
          loaded: 'I lead sono stati caricati correttamente',
          downloadCsv:
            "Alcuni lead non sono stati caricati, puoi scaricare l'elenco dei lead errati sul pulsante di download",
        },
      },
      create: {
        title: 'Nuovo vantaggio',
        subtitle: 'Registrazione di un nuovo lead nel sistema',
        form: {
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare il lead.',
          },
          clinicId: {
            title: 'Clinica',
            description: 'La clinica preferita del protagonista. ',
          },
          contactId: {
            title: 'Contatto',
            description: 'Contatto associato al lead. ',
          },
          campaignId: {
            title: 'Campana',
            description: "L'attuale campagna di marketing del lead.",
          },
          submit: 'Crea lead',
        },
      },
      update: {
        title: 'Aggiorna lead',
        subtitle: 'Modifica dei dati lead nel sistema',
        disabledBanner: {
          title: 'Piombo inattivo',
        },
        tabs: {
          lead: {
            title: 'Guida',
            description:
              "I dettagli del lead hanno lo scopo di comprendere l'origine del lead e il suo follow-up. ",
          },
          actions: {
            title: 'Compiti',
            description:
              'Le attività sono le azioni intraprese per convertire un lead in un donatore. ',
          },
          responsible: {
            title: 'Responsabile',
            description:
              "Il responsabile è l'utente incaricato di svolgere le attività di follow-up principali.",
          },
          contact: {
            title: 'Contatto',
            description: 'Un lead ha origine da un contatto. ',
          },
          notes: {
            title: 'Osservazioni',
            description:
              'Le osservazioni sono commenti che vengono aggiunti ai lead per ricordare dettagli importanti o per condividere informazioni con altri venditori.',
          },
          specialist: {
            title: 'Specialista',
            description: 'Lo specialista assegnato al lead',
          },
          communications: {
            title: 'Comunicazioni',
            description:
              'Integrazione con il sistema di messaggistica per monitorare le comunicazioni con i lead.',
          },
        },
        leadPassport: {
          birthCountry: 'Paese di nascita',
          birthDate: 'Data di nascita',
          height: 'Altezza',
          weight: 'Peso',
          contactTime: 'Preferenza temporale',
          created: 'Data di creazione',
          consent: {
            title: 'Consenso',
            type: {
              legal: 'Legale',
              marketing: 'Marketing',
            },
          },
        },
        form: {
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare il lead.',
          },
          clinicId: {
            title: 'Clinica',
            description: 'La clinica preferita del protagonista. ',
          },
          contactId: {
            title: 'Contatto',
            description: 'Contatto associato al lead. ',
          },
          campaignId: {
            title: 'Campana',
            description: "L'attuale campagna di marketing del lead",
          },
          reasonId: {
            title: 'Motivo',
            description: 'Motivo',
          },
          submit: 'Aggiorna lead',
        },
        lead: {
          tab: 'Guida',
          description: 'Dettagli del piombo',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsabile',
              description: 'Utente responsabile del lead',
            },
            submit: 'Assegnare il responsabile',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Specialista',
              description: 'Specialista assegnato al comando',
            },
            submit: 'Assegnare specialista',
          },
        },
        actions: {
          tab: 'Compiti',
          description: 'Condurre compiti',
          add: 'Aggiungi attività',
          create: {
            title: 'Nuovo compito',
            subtitle: 'Registrazione di una nuova attività nel sistema',
            confirmationMessage: 'Compito non pianificato. \n\n\n',
            form: {
              activityId: {
                title: 'Attività',
                description: 'Attività da fare',
              },
              responsibleId: {
                title: 'Responsabile',
                description: "Responsabile dello svolgimento dell'attività",
              },
              resultId: {
                title: 'Risultato',
                description: "Risultato dell'attività",
              },
              started: {
                title: 'Data stimata',
                description: "Data in cui si prevede che si svolgerà l'attività",
              },
              subject: {
                title: 'Affare',
                description: 'Oggetto del compito',
              },
              observation: {
                title: 'Nota',
                description: "Un piccolo commento sull'attività",
              },
              submit: 'Crea attività',
            },
            steps: {
              clinic: {
                form: 'Clinica selezionata',
              },
              lead: {
                form: 'Lead selezionato',
              },
            },
          },
          update: {
            title: 'Aggiorna attività',
            subtitle: "Modifica dei dati di un'attività nel sistema",
            form: {
              activityId: {
                title: 'Attività',
                description: 'Attività da fare',
              },
              responsibleId: {
                title: 'Responsabile',
                description: "Responsabile dello svolgimento dell'attività",
              },
              resultId: {
                title: 'Risultato',
                description: "Risultato dell'attività",
              },
              started: {
                title: 'Data stimata',
                description: "Data in cui si prevede che si svolgerà l'attività",
              },
              subject: {
                title: 'Affare',
                description: 'Oggetto del compito',
              },
              observation: {
                title: 'Nota',
                description: "Un piccolo commento sull'attività",
              },
              submit: 'Aggiorna attività',
            },
            tabs: {
              action: {
                title: 'Compito',
                description: "Informazioni associate all'attività",
              },
              actionEnding: {
                title: 'Realizzazione',
                description: "Informazioni associate al completamento dell'attività",
              },
              alerts: {
                title: 'Avvisi',
                description: "Avvisi associati all'attività. ",
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Risultato',
                },
                result: {
                  title: 'Risultato',
                  description: "Risultato dell'attività. ",
                },
                communicationChannel: {
                  title: 'Via di comunicazione',
                  description: "Via di comunicazione utilizzata per l'attività",
                },
                submit: 'Termina il compito',
                submitNextSuggestion: 'Fine',
              },
            },
            alert: {
              list: {
                title: 'Avvisi',
                columns: {
                  expectedAlarmTime: "Data dell'allarme",
                },
                many: 'Avvisi',
                one: 'Avviso',
              },
              add: 'Aggiungi avviso',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'Generale',
              },
              notes: {
                title: 'Osservazioni',
              },
              contactNotes: {
                title: 'Osservazioni',
              },
            },
            fields: {
              newLead: 'Nuovo vantaggio',
              active: 'Piombo attivo',
              inactive: 'Piombo inattivo',
              canceled: 'Richiesta annullata',
              converted: 'Piombo convertito',
              clinic: 'Clinica',
              contactTime: 'Preferenza temporale',
              responsible: 'Responsabile',
              funnel: 'Imbuto',
              consent: 'Consenso',
              campaign: 'Campana',
              originCampaign: "Campagna sull'origine",
              created: 'Data di creazione',
              legal: 'Legale',
              marketing: 'Marketing',
              donationPreference: 'Città',
              birthCountry: 'Paese di nascita',
              birthDate: 'Data di nascita',
              height: '(CM)',
              weight: '(KG)',
            },
          },
        },
      },
      delete: {
        title: 'Elimina lead',
        subtitle: 'Questa azione è irreversibile',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disattivare questo lead',
            label: 'Sì, voglio eliminare questo lead',
          },
          confirm: 'Questa azione eliminerà tutto ciò che riguarda il lead, sei sicuro?',
          submit: 'Eliminare',
        },
      },
      disable: {
        title: 'Disabilita vantaggio',
        subtitle: 'Disabilitare il comando nel sistema',
        form: {
          reasonId: {
            title: 'Motivo',
            description: 'Motivo per cui il lead è disabilitato',
          },
          submit: 'Disabilita',
        },
        confirmationMessage: 'Motivo non allegato. ',
      },
      enable: {
        title: 'Abilita lead',
        subtitle: 'Abilita il lead nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo lead?',
            description: 'Abilitare un lead significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo lead',
          },
          submit: 'Abilitare',
        },
      },
      addSpecialist: {
        title: 'Assegnare specialista',
        subtitle: 'Assegnare lo specialista alla guida',
        form: {
          specialistId: {
            title: 'Specialista',
            description: 'Specialista assegnato al comando',
          },
          submit: 'Assegnare specialista',
        },
      },
    },
    reasons: {
      list: {
        title: 'Ragioni',
        description:
          "Gestione dei motivi per cui un potenziale cliente può annullare l'iscrizione o essere considerato non valido",
        create: 'Motivo',
        one: 'Motivo',
        many: 'Ragioni',
        columns: {
          name: 'Qualificazione',
        },
      },
      create: {
        title: 'Nuova ragione',
        subtitle: 'Registrazione di una nuova causale nel sistema',
        form: {
          name: {
            title: 'Qualificazione',
            description: 'Titolo identificativo della causale',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione dettagliata della cancellazione del lead',
          },
          color: {
            title: 'Colore',
            description: 'Colore associato al motivo',
          },
          notify: {
            title: 'Avvisare',
            description: 'Se notificare o meno il motivo al sistema',
            label: 'Comunicare motivo',
          },
          icon: {
            title: 'Icona',
            description: 'Icona associata al motivo',
            label: "Seleziona l'icona...",
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          canceled: {
            title: 'Basso',
            description:
              'Selezionando questa opzione si indica che a tale motivo si attribuisce lo stato di recesso definitivo. ',
            label: 'Motivo del ritiro',
          },
          subject: {
            title: 'Affare',
            description: 'Questione di ragioni',
          },
          message: {
            title: 'Messaggio',
            description: 'Messaggio motivo',
          },
          submit: 'Crea motivo',
        },
      },
      enable: {
        title: 'Abilita motivo',
        subtitle: 'Abilita il motivo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo motivo?',
            description: 'Abilitare un motivo significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo motivo',
          },
          submit: 'Abilita motivo',
        },
      },
      disable: {
        title: 'Disabilita motivo',
        subtitle: 'Disabilita motivo nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questo motivo?',
            description:
              'Disabilitare un motivo significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disattivare questo motivo',
          },
          submit: 'Disabilita motivo',
        },
      },
      update: {
        title: 'Aggiorna motivo',
        subtitle: 'Modificare i dati di una causale nel sistema',
        tabs: {
          reason: {
            title: 'Motivo',
            description: 'Informazioni sul motivo',
          },
          notification: {
            title: 'Imposta la notifica',
            description:
              'Impostazione della notifica da inviare quando si verifica una cancellazione per questo particolare motivo',
          },
          activity: {
            title: 'Prossima attività',
            description:
              "Se è configurata questa sezione del motivo, una volta disabilitato il Lead con questo motivo, verrà suggerita un'attività da svolgere. ",
          },
        },
        reason: {
          form: {
            name: {
              title: 'Qualificazione',
              description: 'Titolo identificativo della causale',
            },
            description: {
              title: 'Descrizione',
              description: 'Descrizione dettagliata della cancellazione del lead',
            },
            color: {
              title: 'Colore',
              description: 'Colore associato al motivo',
            },
            notify: {
              title: 'Avvisare',
              description: 'Se notificare o meno il motivo al sistema',
              label: 'Comunicare motivo',
            },
            icon: {
              title: 'Icona',
              description: 'Icona associata al motivo',
              label: "Seleziona l'icona...",
              search: {
                placeholder: 'Icona Cerca...',
              },
            },
            canceled: {
              title: 'Basso',
              description:
                'Selezionando questa opzione si indica che a tale motivo si attribuisce lo stato di recesso definitivo. ',
              label: 'Motivo del ritiro',
            },
            subject: {
              title: 'Affare',
              description: 'Questione di ragioni',
            },
            message: {
              title: 'Messaggio',
              description: 'Messaggio motivo',
            },
            submit: 'Aggiorna motivo',
          },
          notification: {
            form: {
              notify: {
                title: 'Attiva la notifica',
                description:
                  'Avvisare o meno quando si verifica una cancellazione per questo motivo',
                label: 'Attiva il sistema di notifiche',
              },
              subject: {
                title: 'Affare',
                description: 'Oggetto della notifica',
              },
              message: {
                title: 'Messaggio',
                description: 'Messaggio da visualizzare nella notifica',
              },
              submit: 'Salva impostazioni',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Prossima attività',
              description: 'Attività consigliata da eseguire in caso di annullamento',
            },
            nextActivityDelay: {
              title: 'Tempo di attesa',
              description: "Tempo di attesa per svolgere l'attività suggerita, in giorni",
            },
            submit: 'Mantenere',
          },
        },
      },
    },
    patients: {
      list: {
        title: {
          ovoclinic: 'Pazienti',
          ovobank: 'Donatori',
        },
        description: {
          ovoclinic: 'Gestione dei pazienti nel sistema',
          ovobank: 'Gestione dei donatori nel sistema',
        },
        create: {
          ovoclinic: 'Paziente',
          ovobank: 'Donatore',
        },
        one: {
          ovoclinic: 'Paziente',
          ovobank: 'Donatore',
        },
        many: {
          ovoclinic: 'Pazienti',
          ovobank: 'Donatori',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contatto',
              ovobank: 'Contatto',
            },
          },
          description: {
            ovoclinic: 'Descrizione',
            ovobank: 'Descrizione',
          },
          phones: {
            ovoclinic: 'Telefono',
            ovobank: 'Telefono',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'clinica del ciclo',
              ovobank: 'clinica del ciclo',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clinica',
              ovobank: 'Clinica',
            },
          },
          created: {
            ovoclinic: 'Creazione',
            ovobank: 'Creazione',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'Nuovo paziente',
          ovobank: 'Nuovo donatore',
        },
        subtitle: {
          ovoclinic: 'Registrazione di un nuovo paziente nel sistema',
          ovobank: 'Registrazione di un nuovo donatore nel sistema',
        },
        form: {
          description: {
            title: {
              ovobank: 'Descrizione',
              ovoclinic: 'Descrizione',
            },
            description: {
              ovoclinic: 'Una breve descrizione per dettagliare il paziente.',
              ovobank: 'Una breve descrizione per dettagliare il donatore.',
            },
          },
          leadId: {
            title: 'Guida',
            description: {
              ovoclinic: 'Derivazione associata al paziente',
              ovobank: 'Lead associato al donatore',
            },
          },
          clinicId: {
            title: 'Clinica',
            description: {
              ovoclinic: 'La clinica preferita dal paziente.',
              ovobank: 'La clinica preferita dal donatore.',
            },
          },
          campaignId: {
            title: 'Campana',
            description: {
              ovoclinic: 'La campagna di marketing a domicilio del paziente. ',
              ovobank: 'La campagna di marketing domestico del donatore. ',
            },
          },
          contactId: {
            title: 'Contatto',
            description: {
              ovoclinic: 'Il contatto attraverso il quale sei diventato paziente.',
              ovobank: 'Il contatto attraverso il quale sei diventato donatore.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Contratto di garanzia',
              ovobank: 'Contratto di garanzia',
            },
            description: {
              ovoclinic: 'Il paziente accetta il contratto di garanzia',
              ovobank: 'Il donatore accetta il contratto di garanzia',
            },
            label: {
              ovoclinic: 'Sì, accetto il contratto di garanzia',
              ovobank: 'Sì, accetto il contratto di garanzia',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consenso',
              ovobank: 'Consenso',
            },
            description: {
              ovoclinic: 'Il paziente dà il consenso al trattamento',
              ovobank: 'Il donatore dà il consenso al trattamento',
            },
            label: {
              ovoclinic: 'Sì, do il mio consenso',
              ovobank: 'Sì, do il mio consenso',
            },
          },
          submit: {
            ovoclinic: 'Crea paziente',
            ovobank: 'Crea donatore',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Trattamento',
              },
              phase: {
                form: 'Palcoscenico',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Palcoscenico',
                description: 'Fase del trattamento',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Programma',
                description: 'Nome del programma',
              },
              phaseId: {
                title: 'Palcoscenico',
                description: 'Fase del trattamento',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Aggiorna paziente',
          ovobank: 'Aggiorna donatore',
        },
        subtitle: {
          ovoclinic: 'Modifica dei dati del paziente nel sistema',
          ovobank: 'Modifica dei dati del donatore nel sistema',
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Paziente',
              ovobank: 'Donatore',
            },
            description: {
              ovoclinic: 'Dettagli del paziente',
              ovobank: 'Dettagli del donatore',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contatto',
              ovobank: 'Contatto',
            },
            description: {
              ovoclinic: 'Dettagli di contatto del paziente',
              ovobank: 'Dettagli di contatto del donatore',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsabile',
              ovobank: 'Responsabile',
            },
            description: {
              ovoclinic: 'Responsabile del paziente',
              ovobank: 'Responsabile per il donatore',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Specialista',
              ovobank: 'Specialista',
            },
            description: {
              ovoclinic: 'Specialista assegnato al paziente',
              ovobank: 'Specialista assegnato al donatore',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Ricevute',
              ovobank: 'Ricevute',
            },
            description: 'Elenco delle ricevute associate al cliente',
            list: {
              create: 'Ricevuta',
            },
            many: 'Ricevute',
            one: 'Ricevuta',
            columns: {
              receipt: {
                name: 'Nome',
                clinic: 'Clinica',
                created: 'F. creazione',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Crea pagamento',
                  },
                  many: 'Pagamenti',
                  one: 'Paga',
                  columns: {
                    payment: {
                      price: 'Prezzo',
                      clinic: 'Clinica',
                      name: 'Nome',
                      created: 'F. creazione',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Assumere',
              ovobank: 'Assumere',
            },
            description: {
              ovoclinic: 'Assunzioni di pazienti',
              ovobank: 'Assunzione di donatori',
            },
            one: 'Assumere',
            many: 'Assumere',
            columns: {
              price: 'Prezzo',
              purchaseType: 'Tipo',
              treatmentName: 'Nome',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Osservazioni',
              ovobank: 'Osservazioni',
            },
            description: {
              ovoclinic: 'Osservazioni dei pazienti',
              ovobank: 'Osservazioni dei donatori',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documenti',
              ovobank: 'Documenti',
            },
            description: {
              ovoclinic: 'Documenti caricati dal paziente',
              ovobank: 'Documenti caricati dal donatore',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Trattamento',
              ovobank: 'Trattamento',
            },
            description: {
              ovoclinic: 'Programma paziente',
              ovobank: 'Programma Donatori',
            },
            one: 'Programma',
            many: 'Programmi',
            submit: 'Aggiungi programma',
            columns: {
              programName: 'Programma',
              estimated: 'Data stimata',
              finished: 'Data di fine',
            },
            document: {
              delete: {
                title: 'Elimina file',
                subtitle: 'Sei sicuro di voler eliminare il file Verrà eliminato anche dal cloud',
                submit: 'Eliminare',
              },
              columns: {
                programName: 'Programma',
                estimated: 'Data stimata',
                finished: 'Data di fine',
                documentName: 'Documento',
                created: 'Creato',
                size: 'Misurare',
                vRepoUploaded: 'Sincronizzato con Vrepo',
                type: 'Tipo',
              },
              select: 'Carica un file pdf',
              unselected: 'Nessun file selezionato',
              unvalid: 'Seleziona un file pdf valido.',
              submit: 'Inviare',
              many: 'File',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Compiti',
              ovobank: 'Compiti',
            },
            description: {
              ovoclinic: 'Gestione delle attività a cui sei assegnato. ',
              ovobank: 'Gestione delle attività a cui sei assegnato. ',
            },
            add: 'Aggiungi attività',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'Generale',
              },
              notes: {
                title: 'Osservazioni',
              },
              contactNotes: {
                title: 'Osservazioni',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'Nuovo paziente',
                ovobank: 'Nuovo donatore',
              },
              active: {
                ovoclinic: 'Paziente attivo',
                ovobank: 'Donatore attivo',
              },
              inactive: {
                ovoclinic: 'Paziente inattivo',
                ovobank: 'Donatore inattivo',
              },
              clinic: 'Clinica',
              contactTime: 'Preferenza temporale',
              responsible: 'Responsabile',
              funnel: 'Origine',
              consent: 'Approvazione',
              consentCheck: 'Consenso',
              warrantyAgreement: 'Contratto di garanzia',
              campaign: 'Campana',
              originCampaign: "Campagna sull'origine",
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Paziente',
            ovobank: 'Donatore',
          },
          description: {
            ovoclinic: 'Dettagli del paziente',
            ovobank: 'Dettagli del donatore',
          },
        },
        contact: {
          tab: 'Contatto',
          description: {
            ovoclinic: 'Dettagli di contatto del paziente',
            ovobank: 'Dettagli di contatto del donatore',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsabile',
                ovobank: 'Responsabile',
              },
              description: {
                ovoclinic: 'Utente paziente responsabile',
                ovobank: 'Utente donatore responsabile',
              },
            },
            submit: 'Assegnare il responsabile',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Specialista',
              description: {
                ovoclinic: 'Specialista assegnato al paziente',
                ovobank: 'Specialista assegnato al donatore',
              },
            },
            submit: 'Assegnare specialista',
          },
        },
        receipts: {
          create: {
            title: 'Crea ricevuta',
            subtitle: {
              ovoclinic: 'Crea ricevuta associata al paziente',
              ovobank: 'Crea ricevuta associata al donatore',
            },
            form: {
              clinicId: {
                title: 'Clinica',
              },
              submit: 'Crea ricevuta',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Ricevuta',
                description: {
                  ovoclinic: 'Aggiorna la ricevuta associata al paziente',
                  ovobank: 'Aggiorna la ricevuta associata al donatore',
                },
              },
              payments: {
                title: 'Pagamenti',
                description: 'Pagamenti della fattura associata al cliente',
                list: {
                  create: 'Aggiungi pagamento',
                },
              },
            },
            title: 'Aggiorna ricevuta',
            subtitle: {
              ovoclinic: 'Aggiorna la ricevuta associata al paziente',
              ovobank: 'Aggiorna la ricevuta associata al donatore',
            },
            form: {
              clinicId: {
                title: 'Clinica',
              },
              submit: 'Salva ricevuta',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Descrizione',
              ovobank: 'Descrizione',
            },
            description: {
              ovoclinic: 'Una breve descrizione per aggiungere eventuali commenti sul paziente.',
              ovobank: 'Una breve descrizione per aggiungere eventuali commenti sul donatore.',
            },
          },
          clinicId: {
            title: 'Clinica',
            description: {
              ovoclinic: 'La clinica in cui il paziente desidera essere curato.',
              ovobank: 'La clinica dove il donatore desidera essere curato.',
            },
          },
          leadId: {
            title: 'Guida',
            description: {
              ovoclinic: 'Derivazione associata al paziente',
              ovobank: 'Lead associato al donatore',
            },
          },
          contactId: {
            title: 'Contatto',
            description: {
              ovoclinic:
                'Le informazioni di contatto attraverso le quali il paziente è arrivato in clinica.',
              ovobank: 'I dettagli di contatto con cui il donatore è arrivato alla clinica.',
            },
          },
          campaignId: {
            title: 'Campana',
            description: {
              ovoclinic: 'La campagna di marketing a domicilio del paziente. ',
              ovobank: 'La campagna di marketing domestico del donatore. ',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Contratto di garanzia',
              ovobank: 'Contratto di garanzia',
            },
            description: {
              ovoclinic: 'Il paziente accetta il contratto di garanzia',
              ovobank: 'Il donatore accetta il contratto di garanzia',
            },
            label: {
              ovoclinic: 'Sì, accetto il contratto di garanzia',
              ovobank: 'Sì, accetto il contratto di garanzia',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consenso',
              ovobank: 'Consenso',
            },
            description: {
              ovoclinic: 'Il paziente dà il consenso al trattamento',
              ovobank: 'Il donatore dà il consenso al trattamento',
            },
            label: {
              ovoclinic: 'Sì, do il mio consenso',
              ovobank: 'Sì, do il mio consenso',
            },
          },
          submit: {
            ovoclinic: 'Aggiorna paziente',
            ovobank: 'Aggiorna donatore',
          },
        },
        payments: {
          create: {
            title: 'Crea pagamento',
            subtitle: {
              ovoclinic: 'Aggiungi il pagamento associato alla ricevuta del paziente',
              ovobank: 'Aggiungi il pagamento associato alla ricevuta del donatore',
            },
            form: {
              price: {
                title: 'Prezzo',
              },
              submit: 'Risparmia sul prezzo',
            },
          },
          update: {
            title: 'Aggiorna pagamento',
            subtitle: {
              ovoclinic: 'Aggiorna il pagamento associato alla ricevuta del paziente',
              ovobank: 'Aggiorna il pagamento associato alla ricevuta del donatore',
            },
            form: {
              price: {
                title: 'Prezzo',
              },
              submit: 'Risparmia sul prezzo',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Trattamento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Trattamento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Disabilitare il paziente',
          ovobank: 'Disabilita donatore',
        },
        subtitle: {
          ovoclinic: 'Disabilitare il paziente nel sistema',
          ovobank: 'Disabilitare il donatore nel sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Sei sicuro di voler disabilitare questo paziente?',
              ovobank: 'Sei sicuro di voler disabilitare questo donatore?',
            },
            description: {
              ovoclinic:
                'Disabilitare un paziente significa che non può essere utilizzato nel sistema',
              ovobank:
                'Disabilitare un donatore significa che non può essere utilizzato nel sistema',
            },
            label: {
              ovoclinic: 'Sì, voglio disabilitare questo paziente',
              ovobank: 'Sì, voglio disabilitare questo donatore',
            },
          },
          submit: 'Disabilita',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Abilita paziente',
          ovobank: 'Abilita donatore',
        },
        subtitle: {
          ovoclinic: 'Abilita il paziente nel sistema',
          ovobank: 'Abilita il donatore nel sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Sei sicuro di voler abilitare questo paziente?',
              ovobank: 'Sei sicuro di voler abilitare questo donatore?',
            },
            description: {
              ovoclinic: 'Abilitare un paziente significa che può essere utilizzato nel sistema',
              ovobank: 'Abilitare un donatore significa che può essere utilizzato nel sistema',
            },
            label: {
              ovoclinic: 'Sì, voglio abilitare questo paziente',
              ovobank: 'Sì, voglio abilitare questo donatore',
            },
          },
          submit: 'Abilitare',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Elimina paziente',
          ovobank: 'Elimina donatore',
        },
        subtitle: {
          ovoclinic: 'Questa azione è irreversibile',
          ovobank: 'Questa azione è irreversibile',
        },
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: {
              ovoclinic: 'Potresti preferire disattivare questo paziente',
              ovobank: 'Potresti preferire disattivare questo donatore',
            },
            label: {
              ovoclinic: 'Sì, voglio eliminare questo paziente',
              ovobank: 'Sì, voglio eliminare questo donatore',
            },
          },
          confirm: {
            ovoclinic: 'Questa azione eliminerà tutto ciò che riguarda il paziente, sei sicuro?',
            ovobank: 'Questa azione cancellerà tutto ciò che riguarda il donatore, sei sicuro?',
          },
          submit: 'Eliminare',
        },
      },
      actions: {
        create: {
          title: 'Nuovo compito',
          subtitle: 'Registrazione di una nuova attività nel sistema',
          form: {
            activityId: {
              title: 'Attività',
              description: 'Attività da fare',
            },
            responsibleId: {
              title: 'Responsabile',
              description: "Utente responsabile dell'attività",
            },
            started: {
              title: 'Data stimata',
              description: "Data in cui si prevede che si svolgerà l'attività",
            },
            subject: {
              title: 'Affare',
              description: 'Oggetto del compito',
            },
            observation: {
              title: 'Nota',
              description: "Un piccolo commento sull'attività",
            },
            submit: 'Crea attività',
          },
        },
        update: {
          title: 'Aggiorna attività',
          subtitle: "Modifica dei dati di un'attività nel sistema",
          actionEnding: {
            form: {
              submit: 'Termina il compito',
              submitNextSuggestion: 'Finalizzare e pianificare',
            },
          },
          form: {
            result: {
              false: {
                title: 'Risultato',
              },
              result: {
                title: 'Risultato',
                description: "Risultato dell'attività. ",
              },
            },
            communicationChannel: {
              title: 'Via di comunicazione',
              description: "Via di comunicazione utilizzata per l'attività",
            },
            activityId: {
              title: 'Attività',
              description: 'Attività da fare',
            },
            responsibleId: {
              title: 'Responsabile',
              description: "Utente responsabile dell'attività",
            },
            started: {
              title: 'Data stimata',
              description: "Data in cui si prevede che si svolgerà l'attività",
            },
            subject: {
              title: 'Affare',
              description: 'Oggetto del compito',
            },
            observation: {
              title: 'Nota',
              description: "Un piccolo commento sull'attività",
            },
            submit: 'Aggiorna attività',
          },
          tabs: {
            action: {
              title: 'Compito',
              description: "Informazioni associate all'attività",
            },
            actionEnding: {
              title: 'Realizzazione',
              description: "Informazioni associate al completamento dell'attività",
            },
            alerts: {
              title: 'Avvisi',
              description: "Avvisi associati all'attività",
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: 'Aggiornamento assunzioni',
        subtitle: {
          ovoclinic: 'Modifica dei dati di un contratto paziente',
          ovobank: 'Modifica dei dati di un contratto di donatore',
        },
        form: {
          price: {
            title: 'Prezzo',
            description: 'Prezzo contrattuale',
          },
          submit: 'Aggiornamento assunzioni',
        },
      },
      disable: {
        title: 'Disabilitare le assunzioni',
        subtitle: {
          ovoclinic: 'Disabilitare il reclutamento dei pazienti',
          ovobank: 'Disabilitare il reclutamento dei donatori',
        },
        form: {
          submit: 'Disabilita',
        },
      },
      enable: {
        title: "Abilita l'assunzione",
        subtitle: {
          ovoclinic: 'Abilita il reclutamento dei pazienti',
          ovobank: 'Abilita la contrattazione dei donatori',
        },
        form: {
          submit: 'Abilitare',
        },
      },
    },
    treatments: {
      create: {
        title: 'Nuovo programma',
        subtitle: 'Registrazione di un nuovo programma nel sistema',
        form: {
          programId: {
            title: 'Programma',
            description: 'Programma da realizzare',
          },
          estimated: {
            title: 'Data stimata',
            description: 'Data in cui è previsto lo svolgimento del programma',
          },
          finished: {
            title: 'Data di fine',
            description: 'Data in cui è stato programmato il programma',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase in cui si trova il programma',
          },
          discount: {
            title: 'Percentuale di sconto',
            description: 'Sconto applicato al programma',
          },
          submit: 'Crea programma',
        },
      },
      update: {
        title: 'Aggiorna il programma',
        subtitle: 'Modifica dei dati del programma nel sistema',
        form: {
          programId: {
            title: 'Programma',
            description: 'Programma da realizzare',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase in cui si trova il programma',
          },
          estimated: {
            title: 'Data stimata',
            description: 'Data in cui è previsto lo svolgimento del programma',
          },
          finished: {
            title: 'Data di fine',
            description: 'Data in cui è stato programmato il programma',
          },
          discount: {
            title: 'Percentuale di sconto',
            description: 'Sconto applicato al programma',
          },
          submit: 'Aggiorna il programma',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Programma',
              ovobank: 'Programma',
            },
            description: {
              ovoclinic: 'Informazioni sul programma',
              ovobank: 'Informazioni sul programma',
            },
          },
          program: {
            title: {
              ovoclinic: 'Trattamento',
            },
            description: {
              ovoclinic: 'Informazioni sul trattamento',
              ovobank: 'Informazioni sul trattamento',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Tecniche',
              ovobank: 'Tecniche',
            },
            description: {
              ovoclinic:
                'Sono le tecniche complementari che sono state create in relazione a ciascun programma. ',
              ovobank:
                'Sono le tecniche complementari che sono state create in relazione a ciascun programma. ',
            },
            many: 'Tecniche',
            one: 'Tecnica',
            add: 'Aggiungi tecnica',
          },
          maintenances: {
            title: {
              ovoclinic: 'Manutenzione',
              ovobank: 'Manutenzione',
            },
            description: {
              ovoclinic: 'Mantenimento del trattamento. ',
              ovobank: 'Mantenimento del trattamento. ',
            },
            many: 'Manutenzione',
            one: 'Manutenzione',
            add: 'Aggiungi manutenzione',
          },
          interventions: {
            title: {
              ovoclinic: 'Trattamenti',
              ovobank: 'Trattamenti',
            },
            description: {
              ovoclinic: 'Cicli effettuati nei trattamenti. ',
              ovobank: 'Cicli effettuati nei trattamenti. ',
            },
            many: 'Trattamenti',
            one: 'Trattamento',
            columns: {
              interventionClinicName: 'Clinica',
              observation: 'Osservazione',
              expectedDate: 'Data stimata',
              interventionDate: "Data dell'intervento",
              cycleName: 'Ciclo',
            },
            add: 'Aggiungi il trattamento',
          },
        },
      },
      disable: {
        title: 'Disabilita il trattamento',
        subtitle: 'Disabilitare il trattamento nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questo trattamento?',
            description:
              'Disabilitare un trattamento significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disattivare questo trattamento',
          },
          submit: 'Disabilita il trattamento',
        },
      },
      enable: {
        title: 'Abilita il trattamento',
        subtitle: 'Abilitare il trattamento nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo trattamento?',
            description: 'Abilitare un trattamento significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo trattamento',
          },
          submit: 'Abilita il trattamento',
        },
      },
      list: {
        title: {
          ovoclinic: 'Trattamenti',
          ovobank: 'Trattamenti',
        },
        description: {
          ovoclinic: 'Gestione dei trattamenti eseguiti sui pazienti',
          ovobank: 'Gestione dei trattamenti eseguiti sui donatori',
        },
        create: 'Trattamento',
        one: 'Trattamento',
        many: {
          ovoclinic: 'Trattamenti',
          ovobank: 'Trattamenti',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Ciclo',
            ovobank: 'Ciclo',
          },
          expectedDate: {
            ovoclinic: 'Data stimata',
            ovobank: 'Data stimata',
          },
          clinicName: {
            ovoclinic: 'Clinica',
            ovobank: 'Clinica',
          },
          patientName: {
            ovoclinic: 'Paziente',
            ovobank: 'Donatore',
          },
          programName: {
            ovoclinic: 'Programma',
            ovobank: 'Programma',
          },
          phaseName: {
            ovoclinic: 'Palcoscenico',
            ovobank: 'Palcoscenico',
          },
          estimated: {
            ovoclinic: 'Data stimata',
            ovobank: 'Data stimata',
          },
          finished: {
            ovoclinic: 'Data di fine',
            ovobank: 'Data di fine',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: "Abilita l'intervento",
        subtitle: "Abilita l'intervento del sistema",
        form: {
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita intervento',
        subtitle: "Disabilitare l'intervento nel sistema",
        form: {
          submit: 'Disabilita',
        },
      },
      create: {
        title: 'Nuovo trattamento',
        subtitle: 'Registrazione di un nuovo trattamento',
        form: {
          interventionClinicId: {
            title: 'Clinica',
            description: 'Clinica in cui è stato eseguito il trattamento',
          },
          interventionDate: {
            title: "Data dell'intervento",
            description: 'Data in cui è stato effettuato il ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Osservazione sul trattamento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo di ciclo da eseguire',
          },
          expectedDate: {
            title: 'Data stimata',
            description: 'Data prevista del ciclo',
          },
          submit: 'Crea un trattamento',
        },
      },
      update: {
        title: 'Aggiornare il trattamento',
        subtitle: 'Modifica dei dati del trattamento nel sistema',
        form: {
          interventionClinicId: {
            title: 'Clinica',
            description: 'Clinica in cui è stato eseguito il trattamento',
          },
          interventionDate: {
            title: "Data dell'intervento",
            description: 'Data in cui è stato effettuato il ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Osservazione sul trattamento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo di ciclo da eseguire',
          },
          expectedDate: {
            title: 'Data stimata',
            description: 'Data prevista del ciclo',
          },
          submit: 'Aggiornare il trattamento',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Trattamento',
              ovobank: 'Trattamento',
            },
            description: {
              ovoclinic: 'Informazioni sul trattamento',
              ovobank: 'Informazioni sul trattamento',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Tecniche',
              ovobank: 'Tecniche',
            },
            description: {
              ovoclinic: 'Tecniche di intervento. ',
              ovobank: 'Tecniche di intervento. ',
            },
          },
        },
        techniques: {
          delete: {
            title: 'Tecnica chiara',
            subtitle: 'Eliminare la tecnica di intervento',
            form: {
              submit: 'Tecnica chiara',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'Nuova procedura',
        subtitle: 'Registrazione di una nuova procedura di intervento nel sistema',
        form: {
          techniqueId: {
            title: 'Tecnica',
            description: 'Tecnica associata alla procedura',
          },
          name: {
            title: 'Nome',
            description: 'Nome identificativo della procedura',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione dettagliata della procedura',
          },
          submit: 'Crea procedura',
        },
      },
      update: {
        title: 'Procedura di aggiornamento',
        subtitle: 'Modificare i dati di una procedura nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della procedura',
          },
          description: {
            title: 'Descrizione',
            description: 'Descrizione dettagliata della procedura',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo della procedura',
          },
          submit: 'Procedura di aggiornamento',
        },
      },
      delete: {
        title: 'Procedura di eliminazione',
        subtitle: 'Eliminare la procedura nel sistema',
        form: {
          submit: 'Procedura di eliminazione',
        },
      },
    },
    maintenances: {
      create: {
        title: 'Nuova manutenzione',
        subtitle: 'Registrazione di una nuova manutenzione per il trattamento nel sistema',
        form: {
          serviceId: {
            title: 'Servizio',
            description: 'Servizio associato alla manutenzione',
          },
          submit: 'Creare manutenzione',
        },
      },
      update: {
        title: "Manutenzione dell'aggiornamento",
        subtitle: 'Modifica dei dati di manutenzione nel sistema',
        form: {
          serviceId: {
            title: 'Servizio',
            description: 'Servizio associato alla manutenzione',
          },
          amount: {
            title: 'Prezzo',
            description: 'Prezzo di mantenimento',
          },
          paymentStatus: {
            title: 'Stato del pagamento',
            description: 'Stato del pagamento della manutenzione',
          },
          estimatedPaymentDate: {
            title: 'Data di pagamento stimata',
            description: 'Data stimata del pagamento della manutenzione',
          },
          submit: "Manutenzione dell'aggiornamento",
        },
        tabs: {
          maintenance: {
            title: 'Manutenzione',
            description: 'Informazioni sulla manutenzione',
          },
          action: {
            title: 'Compito',
            description: "Informazioni associate all'attività",
          },
        },
      },
      delete: {
        title: 'Elimina manutenzione',
        subtitle: 'Eliminare la manutenzione sul sistema',
        form: {
          submit: 'Elimina manutenzione',
        },
      },
      list: {
        title: {
          ovoclinic: 'Manutenzione',
          ovobank: 'Manutenzione',
        },
        description: {
          ovoclinic: 'Gestione delle manutenzioni eseguite sui pazienti',
          ovobank: 'Gestione delle manutenzioni effettuate sui donatori',
        },
        create: 'Manutenzione',
        one: {
          ovoclinic: 'Manutenzione',
          ovobank: 'Manutenzione',
        },
        many: {
          ovoclinic: 'Manutenzione',
          ovobank: 'Manutenzione',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clinica',
            ovobank: 'Clinica',
          },
          patientName: {
            ovoclinic: 'Paziente',
            ovobank: 'Donatore',
          },
          serviceName: {
            ovoclinic: 'Servizio',
            ovobank: 'Servizio',
          },
          amount: {
            ovoclinic: 'Prezzo',
            ovobank: 'Prezzo',
          },
          paymentStatus: {
            ovoclinic: 'Stato del pagamento',
            ovobank: 'Stato del pagamento',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Data di pagamento stimata',
            ovobank: 'Data di pagamento stimata',
          },
          programName: {
            ovoclinic: 'Programma',
            ovobank: 'Programma',
          },
          estimated: {
            ovoclinic: 'Data stimata',
            ovobank: 'Data stimata',
          },
          finished: {
            ovoclinic: 'Data di fine',
            ovobank: 'Data di fine',
          },
        },
      },
      enable: {
        title: 'Abilita manutenzione',
        subtitle: 'Abilitare la manutenzione sul sistema',
        form: {
          submit: 'Abilita manutenzione',
        },
      },
      disable: {
        title: 'Disabilita manutenzione',
        subtitle: 'Disabilitare la manutenzione sul sistema',
        form: {
          submit: 'Disabilita manutenzione',
        },
      },
    },
    actions: {
      enable: {
        title: 'Abilita azione',
        subtitle: "Abilita l'azione nel sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa azione?',
            description: "Abilitare un'azione significa che può essere utilizzata nel sistema",
            label: 'Sì, voglio abilitare questa azione',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita azione',
        subtitle: "Disabilitare l'azione nel sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa azione?',
            description:
              "Disabilitare un'azione significa che non può essere utilizzata nel sistema",
            label: 'Sì, voglio disabilitare questa azione',
          },
          submit: 'Disabilita',
        },
      },
      list: {
        title: 'Calendario',
        description: 'Calendario delle attività relativo ai lead',
      },
      delete: {
        title: 'Elimina attività',
        subtitle: 'Questa azione è irreversibile',
        form: {
          submit: 'Eliminare',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Avvisi',
            subtitle: "Aggiungi avvisi all'attività",
            form: {
              alertTime: {
                title: 'Orario di allerta',
                description:
                  "Immettere il numero di minuti in anticipo per ricevere l'avviso di attività",
              },
              submit: 'Aggiungi avviso',
            },
          },
          delete: {
            title: 'Elimina avviso',
            subtitle: 'Elimina avviso attività',
            form: {
              submit: 'Elimina avviso',
            },
          },
          update: {
            title: 'Aggiorna avviso',
            subtitle: 'Aggiorna avviso attività',
            form: {
              alertTime: {
                title: 'Orario di allerta',
                description:
                  "Immettere il numero di minuti in anticipo per ricevere l'avviso di attività",
              },
              submit: 'Aggiorna avviso',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Nome',
          phones: 'Telefono',
          contactTime: 'Frangia',
          consents: 'Consensi',
          marketing: 'Marketing',
          legal: 'Legale',
          created: 'Data di creazione',
        },
        title: {
          ovoclinic: 'Contatti',
          ovobank: 'Contatti',
        },
        description: {
          ovoclinic: "Gestione dei contatti esistenti nell'applicazione. ",
          ovobank: "Gestione dei contatti esistenti nell'applicazione. ",
        },
        create: {
          ovoclinic: 'Contatto',
          ovobank: 'Contatto',
        },
        one: 'Contatto',
        many: 'Contatti',
      },
      create: {
        title: {
          ovoclinic: 'Nuovo contatto',
          ovobank: 'Nuovo contatto',
        },
        subtitle: {
          ovoclinic: 'Persona e quindi associarlo a un lead o a un paziente',
          ovobank: 'Persona e quindi associarla a un lead o a un donatore',
        },
        form: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Cognomi',
          },
          prefix: {
            title: 'Prefisso',
            description: 'Prefisso di contatto',
          },
          phone: {
            title: 'Telefono',
            description: 'Telefono di contatto',
          },
          email: {
            title: 'E-mail',
            description: 'Contatto e-mail',
          },
          zip: {
            title: 'Cap',
            description: 'Contatta il codice postale',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'Il contatto accetta di ricevere informazioni di marketing',
          },
          birthDate: {
            title: 'Data di nascita',
            description: 'Data di nascita del contatto',
          },
          birthCountry: {
            title: 'Paese di nascita',
            description: 'Paese di nascita del contatto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contatto in chilogrammi',
          },
          height: {
            title: 'Altezza (cm)',
            description: 'Altezza di contatto in centimetri',
          },
          donationPreference: {
            title: 'Preferenza della città',
            description: 'Preferenza città dove effettuare la donazione',
          },
          legal: {
            title: 'Condizioni legali',
            label: 'Accetta le condizioni legali',
            description: 'Il contatto accetta le condizioni legali',
          },
          contactTime: {
            title: 'Fascia oraria di contatto',
            description: 'Orario in cui il contatto desidera essere contattato',
          },
          clinicId: {
            title: 'Clinica',
            description: 'OvoCRM ti consente di creare contatti per più cliniche. ',
          },
          campaignId: {
            title: 'Campana',
            description: 'La campagna di contact origin marketing. ',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione del contatto',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contatto',
          },
          submit: 'Crea contatto',
        },
      },
      update: {
        title: {
          ovoclinic: 'Aggiorna contatto',
          ovobank: 'Aggiorna contatto',
        },
        subtitle: {
          ovoclinic: 'Informazioni personali del lead o del paziente',
          ovobank: 'Informazioni personali del lead o del donatore',
        },
        tabs: {
          account: {
            title: 'Dati personali',
            description: 'Informazioni di contatto dettagliate',
          },
          actions: {
            title: 'Attività',
            description: 'Elenco delle attività create sul contatto',
          },
          leads: {
            title: 'Campagne',
            description: 'Campagne attraverso le quali è entrato questo contatto. ',
          },
          contact: {
            title: 'Contatto',
            description: 'Informazioni di contatto dettagliate',
          },
          receipts: {
            title: 'Ricevute',
            description: {
              ovoclinic: 'Ricevute create sul contatto. ',
              ovobank: 'Ricevute create sul contatto. ',
            },
          },
          notes: {
            title: 'Osservazioni',
            description: 'Informazioni o commenti sul contatto. ',
          },
          purchases: {
            title: 'Assumere',
            description: 'Affitto effettuato dal contatto. ',
          },
        },
        form: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Cognomi',
          },
          dni: {
            title: 'ID',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'Il contatto accetta di ricevere informazioni di marketing',
          },
          legal: {
            title: 'Condizioni legali',
            label: 'Accetta le condizioni legali',
            description: 'Il contatto accetta le condizioni legali',
          },
          contactTime: {
            title: 'Fascia oraria di contatto',
            description: 'Orario in cui il contatto desidera essere contattato',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione del contatto',
          },
          prefix: {
            title: 'Prefisso',
            description: 'Prefisso di contatto',
          },
          phone: {
            title: 'Telefono',
            description: 'Telefono di contatto',
          },
          email: {
            title: 'E-mail',
            description: 'Contatto e-mail',
          },
          birthDate: {
            title: 'Data di nascita',
            description: 'Data di nascita del contatto',
          },
          birthCountry: {
            title: 'Paese di nascita',
            description: 'Paese di nascita del contatto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contatto in chilogrammi',
          },
          height: {
            title: 'Altezza (cm)',
            description: 'Altezza di contatto in centimetri',
          },
          donationPreference: {
            title: 'Preferenza della città',
            description: 'Preferenza città dove effettuare la donazione',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contatto',
          },
          submit: 'Aggiorna contatto',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Tipo',
              treatmentName: 'Trattamento',
              price: 'Prezzo',
            },
            one: 'Assumere',
            many: 'Assumere',
          },
        },
      },
      delete: {
        title: 'Elimina contatto',
        subtitle: 'Questa azione è irreversibile',
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disattivare questo contatto',
            label: 'Sì, voglio eliminare questo contatto',
          },
          confirm: 'Questa azione eliminerà tutto ciò che riguarda il contatto, sei sicuro?',
          submit: 'Eliminare',
        },
      },
      enable: {
        title: 'Abilita contatto',
        subtitle: 'Abilita il contatto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo contatto?',
            description: 'Abilitare un contatto significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo contatto',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita contatto',
        subtitle: 'Disabilitare il contatto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo contatto?',
            description:
              'Disabilitare un contatto significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disattivare questo contatto',
          },
          submit: 'Disabilita',
        },
      },
      notes: {
        source: {
          LEAD: 'Guida',
          PATIENT: {
            ovoclinic: 'Paziente',
            ovobank: 'Donatore',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campagne',
        description: 'Gestione delle campagne di marketing. ',
        userId: {
          title: 'In carica',
          description: 'Responsabile della campagna',
        },
        submit: 'Assegnare il direttore',
        create: 'Campana',
        one: 'Campana',
        many: 'Campagne',
        columns: {
          name: 'Nome',
          leads: 'Conduce',
          leadsStatus: 'Attivo/Inattivo',
          funnelName: 'Imbuto',
          languageName: 'Lingua',
          clinics: 'Cliniche',
          managers: 'Manager',
        },
      },
      create: {
        title: 'Nuova campagna',
        subtitle: 'Registrazione di una nuova campagna nel sistema',
        form: {
          image: {
            title: 'Copertina',
            description:
              "Ti invitiamo a caricare un'immagine per migliorare l'esperienza commerciale",
          },
          name: {
            title: 'Qualificazione',
            description: 'Titolo della campagna di marketing',
          },
          facebookId: {
            title: 'ID Facebook',
            description: 'Campagna di marketing ID Facebook',
          },
          code: {
            title: 'Codice',
            description: 'Codice della campagna di marketing',
          },
          budget: {
            title: 'Bilancio',
            description: 'Budget investito dalla clinica nella campagna',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica che finanzia la campagna',
          },
          firstActivityId: {
            title: 'Prima attività',
            description: 'Prima attività assegnata durante la creazione di un lead',
          },
          funnelId: {
            title: 'Imbuto',
            description: 'Funnel di conversione delle campagne di marketing',
          },
          status: {
            title: 'Campagna attiva',
            description: 'Abilita o disabilita la campagna nel sistema',
            label: 'Campagna attiva',
          },
          languageId: {
            title: 'Lingua',
            description:
              'Seleziona una lingua in modo che tutti i lead di questa campagna vengano creati in quella lingua',
          },
          startDate: {
            title: 'Data di inizio',
            description: 'Data di inizio della campagna',
          },
          submit: 'Crea campagna',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Canale',
                description: 'Canale della campagna di marketing',
              },
            },
          },
        },
      },
      enable: {
        title: 'Abilita campagna',
        subtitle: 'Abilita la campagna nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di volerlo abilitare?',
            description: 'In questo modo sarà disponibile nel sistema',
            label: 'Sì, voglio abilitare questa campagna',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disattiva campagna',
        subtitle: 'Disabilita la campagna nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di volerlo disabilitare?',
            description: 'In questo modo non sarà disponibile nel sistema',
            label: 'Sì, voglio disattivare questa campagna',
          },
          submit: 'Disabilita',
        },
      },
      update: {
        title: 'Aggiorna campagna',
        subtitle: 'Le modifiche influenzeranno tutte le integrazioni',
        tabs: {
          general: {
            title: 'Generale',
            description: 'Dettagli della campagna',
          },
          contact: {
            title: 'Contatto',
            description: 'Utilizzando questo modulo puoi aggiungere un contatto alla campagna. ',
          },
          managers: {
            title: 'Manager',
            description: 'Gestori della campagna',
          },
          financing: {
            title: 'Finanziamento',
            description: 'Dettagli sul finanziamento della campagna',
          },
          webhook: {
            title: 'webhook',
            description:
              'Un webhook è un modo per integrare la tua applicazione con altre applicazioni. ',
            toast: 'Link copiato negli appunti',
          },
          participants: {
            title: 'Cliniche riceventi',
            description: 'Queste sono le cliniche a cui è rivolta la campagna di marketing. ',
          },
        },
        participants: {
          list: {
            one: 'Clinica ricevente',
            many: 'Cliniche riceventi',
            columns: {
              clinicName: 'Clinica',
            },
          },
          clinicsAdded: 'Aggiunte cliniche',
          create: {
            title: 'Aggiungi clinica ricevente',
            subtitle: 'Aggiungi una clinica ricevente alla campagna',
            form: {
              clinicId: {
                title: 'Clinica',
                description: 'Aggiungi la clinica ricevente alla campagna',
              },
              submit: 'Aggiungi clinica ricevente',
            },
          },
          delete: {
            title: 'Elimina la clinica ricevente',
            subtitle: 'Questa azione è irreversibile',
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                description: 'Potresti preferire disattivare questo partecipante',
                label: 'Sì, voglio eliminare questa clinica ricevente',
              },
              submit: 'Eliminare',
            },
          },
          add: 'Aggiungere',
          addAll: 'Aggiungi tutto',
          clinicIdCopied: 'ID clinica copiato negli appunti',
        },
        financing: {
          form: {
            budget: {
              title: 'Bilancio',
              description: 'Budget investito dalla clinica nella campagna',
            },
            financingClinicId: {
              title: 'Clinica di finanziamento',
              description: 'Clinica che ha finanziato la campagna',
            },
            submit: 'Aggiornare il finanziamento',
          },
        },
        managers: {
          list: {
            one: 'in carica',
            many: 'gestori',
            columns: {
              name: 'Nome',
            },
          },
          add: 'Aggiungi gestore',
        },
        manager: {
          title: 'Assegnare il direttore',
          subtitle: 'Assegnare un manager alla campagna',
        },
        contact: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Cognomi',
          },
          prefix: {
            title: 'Prefisso',
          },
          phone: {
            title: 'Telefono',
          },
          email: {
            title: 'E-mail',
          },
          zip: {
            title: 'Cap',
          },
          legal: {
            title: 'Condizioni legali',
            label: 'Accetta le condizioni legali',
            description: 'Il contatto accetta le condizioni legali',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'Il contatto accetta di ricevere informazioni di marketing',
          },
          contactTime: {
            title: 'Fascia oraria di contatto',
            description: 'Orario in cui il contatto desidera essere contattato',
          },
          clinicId: {
            title: 'Clinica',
            description: 'La clinica in cui il contatto desidera essere curato',
          },
          center: {
            title: 'Posizione',
            description: 'Posizione del contatto',
          },
          birthDate: {
            title: 'Data di nascita',
            description: 'Data di nascita del contatto',
          },
          birthCountry: {
            title: 'Paese di nascita',
            description: 'Paese di nascita del contatto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contatto in chilogrammi',
          },
          height: {
            title: 'Altezza (cm)',
            description: 'Altezza di contatto in centimetri',
          },
          donationPreference: {
            title: 'Preferenza della città',
            description: 'Preferenza città dove effettuare la donazione',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contatto',
          },
          submit: 'Aggiungi contatto',
        },
        form: {
          name: {
            title: 'Qualificazione',
            description: 'Titolo della campagna di marketing',
          },
          facebookId: {
            title: 'ID Facebook',
            description: 'Campagna di marketing ID Facebook',
          },
          code: {
            title: 'Codice',
            description: 'Codice identificativo della campagna di marketing',
          },
          budget: {
            title: 'Bilancio',
            description: 'Budget investito dalla clinica nella campagna',
          },
          image: {
            title: 'Copertina',
            description:
              "Ti invitiamo a caricare un'immagine per migliorare l'esperienza commerciale",
          },
          funnelId: {
            title: 'Imbuto',
            description: 'Funnel di conversione delle campagne di marketing',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica che finanzia la campagna',
          },
          startDate: {
            title: 'Data di inizio',
            description: 'Data di inizio della campagna',
          },
          endDate: {
            title: 'Data di fine',
            description: 'Data di fine della campagna',
          },
          firstActivityId: {
            title: 'Prima attività',
            description: 'Prima attività assegnata durante la creazione di un lead',
          },
          status: {
            title: 'Campagna attiva',
            description: 'Abilita o disabilita la campagna nel sistema',
            label: 'Campagna attiva',
          },
          languageId: {
            title: 'Lingua',
            description:
              'Seleziona una lingua in modo che tutti i lead di questa campagna vengano creati in quella lingua',
          },
          submit: 'Aggiorna campagna',
        },
      },
      delete: {
        title: 'Elimina campagna',
        subtitle: 'Questa azione è irreversibile',
        manager: {
          title: 'Elimina gestore',
          subtitle: 'Questa azione è irreversibile',
          form: {
            submit: 'Eliminare',
          },
        },
        form: {
          status: {
            title: 'Sei sicuro di volerlo eliminare?',
            description: 'Potresti preferire disattivare questa campagna',
            label: 'Sì, voglio eliminare questa campagna',
          },
          success: 'Campagna eliminata correttamente',
          error: "Errore durante l'eliminazione della campagna",
          confirm: 'Questa azione eliminerà tutto ciò che riguarda la campagna, sei sicuro?',
          submit: 'Eliminare',
        },
      },
    },
    activities: {
      list: {
        title: 'Attività',
        description:
          'I tipi di attività consentono ai venditori di tenere traccia delle attività eseguite con i lead',
        create: 'Attività',
        one: 'attività',
        many: 'attività',
        columns: {
          name: 'Nome',
          description: 'Descrizione',
          position: 'Posizione',
          icon: 'Icona',
          color: 'Colore',
          time: 'Tempo',
        },
      },
      update: {
        subtitle: "Modificare l'attività nel sistema",
        title: 'Modifica attività',
        form: {
          submit: 'Mantenere',
          name: {
            title: 'Qualificazione',
            description: "Titolo identificativo dell'attività",
          },
          icon: {
            title: 'Icona',
            description: "Icona per identificare l'attività. ",
            label: "Seleziona l'icona...",
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          position: {
            title: 'Posizione',
            description:
              "Posizione in cui l'attività viene visualizzata nell'elenco delle attività.",
          },
          description: {
            title: 'Descrizione',
            description: "Un breve riassunto per fornire maggiori dettagli sull'attività.",
          },
          color: {
            title: 'Colore',
            description: "Colore identificativo dell'attività. ",
          },
          time: {
            title: 'Tempo',
            description: "Tempo stimato necessario per svolgere l'attività.",
          },
          primary: {
            title: 'Attività principale',
            description:
              'Le attività principali sono quelle che vengono mostrate sulla dashboard del venditore per consentire una rapida interazione con i contatti.',
            label: 'Mostra sulla dashboard',
          },
        },
        tabs: {
          activity: {
            title: 'Attività',
            description: "Informazioni associate all'attività",
          },
          results: {
            title: 'Risultati',
            description: 'I risultati descrivono cosa è successo dopo aver svolto l’attività. ',
          },
          scopes: {
            title: 'Ambiti',
            description:
              'Gli ambiti descrivono quali dipartimenti possono eseguire ciascuna attività.',
          },
          communicationChannels: {
            title: 'Vie di comunicazione',
            description: "Canali di comunicazione associati all'attività",
          },
          integrations: {
            title: 'Integrazioni',
            description:
              'Integrazioni esterne che verranno eseguite durante la creazione di queste attività',
          },
        },
        results: {
          one: 'risultato',
          many: 'risultati',
          add: 'Aggiungi risultato',
        },
        scopes: {
          one: 'Ambito',
          many: 'Ambiti',
          add: 'Aggiungi ambito',
        },
        communicationChannels: {
          add: 'Aggiungi percorso di comunicazione',
          list: {
            one: 'Via di comunicazione',
            many: 'Vie di comunicazione',
            columns: {
              channelName: 'Nome',
            },
          },
          create: {
            title: 'Aggiungi percorso di comunicazione',
            subtitle: "Aggiungi il percorso di comunicazione all'attività",
            form: {
              title: 'Via di comunicazione',
              subtitle: "Via di comunicazione associata all'attività",
              submit: 'Aggiungere',
              id: {
                title: 'Via di comunicazione',
                description: "Via di comunicazione associata all'attività",
              },
            },
          },
          delete: {
            title: 'Elimina il percorso di comunicazione',
            subtitle: "Eliminare il canale di comunicazione dell'attività",
            form: {
              submit: 'Eliminare',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'LandBot',
            description: 'Integrazione con LandBot. ',
            label: "Attiva l'integrazione LandBot",
          },
          landbotTemplateId: {
            title: 'Modello LandBot',
            description: 'Integrazione con LandBot. ',
          },
          landbotActivityId: {
            title: 'Attività del LandBot',
            description: 'Attività che verrà generata dal LandBot',
          },
          landbotResultId: {
            title: 'Risultato del LandBot',
            description: "Risultato che LandBot inserirà se l'attività sarà completata",
          },
          submit: 'Mantenere',
        },
      },
      enable: {
        title: 'Abilita attività',
        subtitle: "Abilita l'attività del sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa attività?',
            description: "Abilitare un'attività significa che può essere utilizzata nel sistema",
            label: 'Sì, voglio abilitare questa attività',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disattiva attività',
        subtitle: "Disabilita l'attività del sistema",
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa attività?',
            description:
              "Disabilitare un'attività significa che non può essere utilizzata nel sistema",
            label: 'Sì, voglio disattivare questa attività',
          },
          submit: 'Disabilita',
        },
      },
      create: {
        title: 'Crea attività',
        subtitle: 'Creare attività nel sistema',
        form: {
          submit: 'Creare',
          name: {
            title: 'Qualificazione',
            description: "Titolo identificativo dell'attività",
          },
          icon: {
            title: 'Icona',
            description: "Icona per identificare l'attività. ",
            label: "Seleziona l'icona...",
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          position: {
            title: 'Posizione',
            description:
              "Posizione in cui l'attività viene visualizzata nell'elenco delle attività.",
          },
          description: {
            title: 'Descrizione',
            description: "Un breve riassunto per fornire maggiori dettagli sull'attività.",
          },
          color: {
            title: 'Colore',
            description: "Colore identificativo dell'attività. ",
          },
          time: {
            title: 'Tempo',
            description: "Tempo stimato necessario per svolgere l'attività.",
          },
          primary: {
            title: 'Attività principale',
            description:
              'Le attività principali sono quelle che vengono mostrate sulla dashboard del venditore per consentire una rapida interazione con i contatti.',
            label: 'Mostra sulla dashboard',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Ambiti',
        description: 'Gestione delle aree di attività del sistema',
        create: 'Ambito',
        one: 'Ambito',
        many: 'Ambiti',
        columns: {
          clinicName: 'Clinica',
          departmentName: 'Dipartimento',
        },
      },
      create: {
        title: 'Crea ambito',
        subtitle: "Creare l'ambito nel sistema",
        form: {
          submit: 'Creare',
          clinicId: {
            title: 'Clinica',
            description: 'Clinica di appartenenza della zona',
          },
          departmentId: {
            title: 'Dipartimento',
            description: "Dipartimento a cui appartiene l'ambito",
          },
          departmentIds: {
            title: 'Dipartimenti',
            description: 'Dipartimenti a cui avrà ambito il modello',
          },
        },
      },
      delete: {
        title: 'Elimina ambito',
        subtitle: 'Questa azione è irreversibile',
        form: {
          submit: 'Eliminare',
        },
      },
    },
    results: {
      list: {
        title: 'Risultati',
        description: 'Gestione dei risultati delle attività nel sistema. ',
        create: 'Aggiungi risultato',
        one: 'risultato',
        many: 'risultati',
        columns: {
          name: 'Nome',
          description: 'Descrizione',
          icon: 'Icona',
          color: 'Colore',
          time: 'Tempo stimato',
          position: 'Posizione',
          activityName: 'Attività',
        },
      },
      update: {
        subtitle: "Modificare il risultato dell'attività nel sistema",
        title: 'Modifica risultato',
        form: {
          submit: 'Aggiornamento',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del risultato',
          },
          icon: {
            title: 'Icona',
            label: 'Icona',
            description: 'Icona per identificare il risultato. ',
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          position: {
            title: 'Posizione',
            description:
              "Posizione in cui viene visualizzato il risultato nell'elenco dei risultati.",
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per fornire maggiori dettagli sul risultato.',
          },
          color: {
            title: 'Colore',
            description: 'Colore identificativo del risultato. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo stimato necessario per produrre il risultato.',
          },
          activityId: {
            title: 'Attività',
            description: 'Attività a cui appartiene il risultato.',
          },
          nextActivityId: {
            title: 'Prossima attività',
            description: 'Attività che verrà eseguita dopo aver ottenuto questo risultato.',
          },
          nextActivityDelay: {
            title: 'Tempo di attesa',
            description: "Tempo di attesa per eseguire l'attività successiva. ",
          },
          disableLead: {
            title: 'Disabilita vantaggio',
            description: "Disabilitare l'elettrocatetere dopo aver eseguito questo risultato",
            label: 'Sì, disabilita il comando',
          },
          contacted: {
            title: 'Responsabile contattato',
            description: 'È stato stabilito il contatto con il responsabile',
            label: 'Sì, il responsabile è stato contattato',
          },
          informed: {
            title: 'Leader informato',
            description: 'Il lead ha ricevuto informazioni sul servizio',
            label: 'Sì, il protagonista è stato informato',
          },
          converted: {
            title: {
              ovoclinic: 'Il piombo si è trasformato in paziente',
              ovobank: 'Piombo convertito in donatore',
            },
            description: {
              ovoclinic: 'Il lead è stato convertito in un paziente. ',
              ovobank: 'Il lead è stato convertito in un donatore. ',
            },
            label: {
              ovoclinic: 'Sì, il lead è stato convertito',
              ovobank: 'Sì, il lead è stato convertito',
            },
          },
          appointed: {
            title: 'Lead citato per visita informativa commerciale',
            description: 'È stato fissato un appuntamento con il responsabile',
            label: 'Sì, la pista è stata citata',
          },
          attended: {
            title: 'Effettuata visita medica informativa',
            description:
              'Con queste informazioni sarà possibile contare quando il lead si è presentato alla visita medica.',
            label: 'Sì, il protagonista ha avuto una visita medica',
          },
          comercialAttended: {
            title: 'Bilancio consegnato',
            description:
              'Con queste informazioni sarà possibile contare quando un budget viene consegnato al lead.',
            label: 'Sì, il lead ha ricevuto un preventivo',
          },
          medicalAppointed: {
            title: 'Guida programmata per visita informativa medica',
            description: 'È stato fissato un appuntamento medico con il protagonista',
            label: 'Sì, la pista è stata citata',
          },
          medicalEvaluated: {
            title: 'Valutazione medica',
            description: 'Il piombo è stato valutato da un medico',
            label: 'Sì, il vantaggio è stato valutato',
          },
          templateId: {
            title: 'Nome',
            description: 'Nome del modello',
          },
          landbotId: {
            title: 'LandBot',
            description: "L'ID del bot da inviare al contatto insieme al modello",
          },
          landbotAppointed: {
            title: 'Appuntamento commerciale organizzato da LandBot',
            description:
              "Se viene svolta un'attività con questo risultato significa che il bot ha fissato un appuntamento",
            label: 'Sì, il bot ha fissato un appuntamento',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead citato per visita informativa commerciale',
            description: 'È stato fissato un appuntamento con il responsabile',
            label: 'Sì, la pista è stata citata',
          },
          comercialAttended: {
            title: 'Visita informativa commerciale effettuata',
            description:
              "Con queste informazioni sarà possibile contare quando il lead ha presenziato all'appuntamento commerciale.",
            label: 'Sì, il lead ha avuto un appuntamento di vendita',
          },
          medicalAppointed: {
            title: 'Guida programmata per visita informativa medica',
            description: 'È stato fissato un appuntamento medico con il protagonista',
            label: 'Sì, la pista è stata citata',
          },
          attended: {
            title: 'Effettuata visita medica informativa',
            description:
              'Con queste informazioni sarà possibile contare quando il lead si è presentato alla visita medica.',
            label: 'Sì, il protagonista ha avuto una visita medica',
          },
          converted: {
            title: 'Vestibilità medica',
            description: 'Il piombo è stato considerato idoneo alla donazione',
            label: 'Sì, il lead è stato ritenuto idoneo',
          },
          submit: 'Aggiornamento',
        },
        tabs: {
          result: {
            title: 'Risultato',
            description: 'Gestione dei risultati delle attività nel sistema. ',
          },
          template: {
            title: 'Modello',
            description: 'Modello da utilizzare per Landbot.',
          },
          nextActivity: {
            title: 'Prossima attività',
            description:
              "L'attività successiva è l'attività che verrà eseguita dopo il completamento di questo risultato.",
          },
          ccAchievement: {
            title: 'Pietre miliari del Call Center',
            description:
              'Traguardi che devono essere raggiunti affinché il risultato possa essere considerato “raggiunto”',
          },
          atpcAchievement: {
            title: "Traguardi commerciali dell'ATP",
            description:
              'Traguardi che devono essere raggiunti affinché il risultato possa essere considerato “raggiunto”',
          },
          atpiAchievement: {
            title: "Traguardi internazionali dell'ATP",
            description:
              'Traguardi che devono essere raggiunti affinché il risultato possa essere considerato “raggiunto”',
          },
          ovoBankAtpAchievement: {
            title: 'Traguardi dei donatori',
            description:
              'Traguardi che devono essere raggiunti affinché il risultato possa essere considerato “raggiunto”',
          },
          integrationAchievement: {
            title: "Pietre miliari dell'integrazione",
            description: 'I traguardi che potrebbero essere raggiunti con una certa integrazione. ',
          },
          reactivity: {
            title: 'Reattività',
            description: 'Reattività del risultato. ',
          },
        },
      },
      enable: {
        title: 'Abilita risultato',
        subtitle: 'Abilita il risultato nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo risultato?',
            description: 'Abilitare un risultato significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo risultato',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disattiva risultato',
        subtitle: 'Disabilita il risultato nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questo risultato?',
            description:
              'Disabilitare un risultato significa che non può essere utilizzato nel sistema',
            label: 'Sì, voglio disabilitare questo risultato',
          },
          submit: 'Disabilita',
        },
      },
      create: {
        title: 'Crea risultato',
        subtitle: "Creare il risultato dell'attività nel sistema",
        form: {
          submit: 'Creare',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del risultato',
          },
          icon: {
            title: 'Icona',
            description: 'Icona per identificare il risultato. ',
            label: 'Icona',
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          position: {
            title: 'Posizione',
            description:
              "Posizione in cui viene visualizzato il risultato nell'elenco dei risultati.",
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per fornire maggiori dettagli sul risultato.',
          },
          color: {
            title: 'Colore',
            description: 'Colore identificativo del risultato. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo stimato necessario per produrre il risultato.',
          },
          activityId: {
            title: 'Attività',
            description: 'Attività a cui appartiene il risultato.',
          },
          nextActivityId: {
            title: 'Prossima attività',
            description: 'Attività che verrà eseguita dopo aver ottenuto questo risultato.',
          },
          nextActivityDelay: {
            title: 'Tempo di attesa',
            description: "Tempo di attesa per eseguire l'attività successiva. ",
          },
          contacted: {
            title: 'Responsabile contattato',
            description: 'È stato stabilito il contatto con il responsabile',
            label: 'Sì, il responsabile è stato contattato',
          },
          informed: {
            title: 'Leader informato',
            description: 'Il lead ha ricevuto informazioni sul servizio',
            label: 'Sì, il protagonista è stato informato',
          },
          converted: {
            title: 'Converti piombo',
            description: {
              ovoclinic: 'Il lead è stato convertito in un paziente. ',
              ovobank: 'Il lead è stato convertito in un donatore. ',
            },
            label: 'Sì, il lead è stato convertito',
          },
          appointed: {
            title: 'Piombo citato',
            description: 'È stato fissato un appuntamento con il responsabile',
            label: 'Sì, la pista è stata citata',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Servizi',
        description: 'Gestione dei servizi nel sistema. ',
        create: 'Servizio',
        one: 'servizio',
        many: 'servizi',
        columns: {
          name: 'Nome',
          price: 'Prezzo',
          nextActivityName: 'Prossima attività',
          nextActivityDelay: 'Tempo di attività posticipato',
        },
      },
      update: {
        subtitle: 'Modifica servizio di sistema',
        title: 'Modifica servizio',
        form: {
          submit: 'Aggiornamento',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del servizio',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo del servizio',
          },
          nextActivityId: {
            title: 'Prossima attività',
            description: 'Prossima attività',
          },
          nextActivityDelay: {
            title: 'Tempo di attività posticipato',
            description: "Orario in cui l'attività viene posticipata",
          },
        },
        tabs: {
          service: {
            title: 'Servizio',
            description: 'Gestione dei servizi di attività nel sistema. ',
          },
          nextActivity: {
            title: 'Prossima attività',
            description:
              "L'attività seguente è l'attività che verrà svolta dopo il completamento di questo servizio.",
          },
        },
      },
      enable: {
        title: 'Abilita il servizio',
        subtitle: 'Abilita il servizio sul sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo servizio?',
            description: 'Abilitare un servizio significa che può essere utilizzato sul sistema',
            label: 'Sì, voglio abilitare questo servizio',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita servizio',
        subtitle: 'Disabilitare il servizio nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questo servizio?',
            description:
              'Disabilitare un servizio significa che non può essere utilizzato sul sistema',
            label: 'Sì, voglio disattivare questo servizio',
          },
          submit: 'Disabilita',
        },
      },
      create: {
        title: 'Crea servizio',
        subtitle: 'Creare un servizio di attività nel sistema',
        form: {
          submit: 'Creare',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del servizio',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo del servizio',
          },
          nextActivityId: {
            title: 'Prossima attività',
            description: 'Prossima attività',
          },
          nextActivityDelay: {
            title: 'Tempo di attività posticipato',
            description: "Orario in cui l'attività viene posticipata",
          },
        },
      },
    },
    products: {
      list: {
        title: 'Prodotti',
        description: 'Gestione del prodotto nel sistema',
        create: 'Aggiungi prodotto',
        one: 'prodotto',
        many: 'prodotti',
        columns: {
          name: 'Nome',
          price: 'Prezzo',
          tax: 'Tasse',
          categoryName: 'Categoria',
          clinicName: 'Clinica',
        },
      },
      update: {
        subtitle: 'Modificare il prodotto nel sistema',
        title: 'Modifica prodotto',
        form: {
          submit: 'Mantenere',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del prodotto',
          },
          icon: {
            title: 'Icona',
            description: 'Icona di identificazione del prodotto. ',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione del prodotto per dettagliarne le caratteristiche',
          },
          color: {
            title: 'Colore',
            description: 'Colore identificativo del prodotto. ',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita del prodotto',
          },
          tax: {
            title: 'tassare',
            description: 'Tasse applicate al prodotto',
          },
          path: {
            title: 'Sentiero',
            description: 'Percorso in cui si trova il prodotto',
          },
          categoryId: {
            title: 'Categoria',
            description: 'Categoria a cui appartiene il prodotto',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica a cui appartiene questa categoria',
          },
        },
      },
      enable: {
        title: 'Abilita prodotto',
        subtitle: 'Abilita il prodotto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questo prodotto?',
            description: 'Abilitare un prodotto significa che può essere utilizzato nel sistema',
            label: 'Sì, voglio abilitare questo prodotto',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita prodotto',
        subtitle: 'Disabilitare il prodotto nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disattivare questo prodotto?',
            description:
              'Disabilitare un prodotto significa che non può essere utilizzato sul sistema',
            label: 'Sì, desidero disattivare questo prodotto',
          },
          submit: 'Disabilita',
        },
      },
      create: {
        title: 'Crea prodotto',
        subtitle: 'Creare il prodotto nel sistema',
        steps: {
          category: {
            form: {
              selected: 'Categoria selezionata',
              categoryId: {
                title: 'Categoria',
                description: 'Categoria a cui appartiene il prodotto',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Clinica selezionata',
              clinicId: {
                title: 'Clinica',
                description: 'Clinica a cui appartiene questa categoria',
              },
            },
          },
        },
        form: {
          submit: 'Creare',
          name: {
            title: 'Nome',
            description: 'Nome identificativo del prodotto',
          },
          icon: {
            title: 'Icona',
            description: 'Icona di identificazione del prodotto. ',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione del prodotto per dettagliarne le caratteristiche',
          },
          color: {
            title: 'Colore',
            description: 'Colore identificativo del prodotto. ',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita del prodotto',
          },
          tax: {
            title: 'tassare',
            description: 'Tasse applicate al prodotto',
          },
          path: {
            title: 'Sentiero',
            description: 'Percorso in cui si trova il prodotto',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Categorie',
        description: 'Raggruppamento di prodotti',
        create: 'Aggiungi categoria',
        one: 'categoria',
        many: 'categorie',
        columns: {
          name: 'Nome',
          clinicName: 'Clinica',
          created: 'Data di creazione',
        },
      },
      update: {
        subtitle: 'Modifica la categoria nel sistema',
        title: 'Modifica categoria',
        form: {
          submit: 'Mantenere',
          name: {
            title: 'Nome',
            description: 'Nome identificativo della categoria',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare la categoria',
          },
        },
      },
      create: {
        title: 'Crea categoria',
        subtitle: 'Crea categoria nel sistema',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clinica',
                description: 'Clinica a cui appartiene questa categoria',
              },
            },
          },
        },
        form: {
          submit: 'Creare',
          name: {
            title: 'Nome',
            description: 'Nome identificativo della categoria',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare la categoria',
          },
        },
      },
      enable: {
        title: 'Abilita categoria',
        subtitle: 'Abilita la categoria nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler abilitare questa categoria?',
            description: 'Abilitare una categoria significa che può essere utilizzata nel sistema',
            label: 'Sì, voglio abilitare questa categoria',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita categoria',
        subtitle: 'Disabilita la categoria nel sistema',
        form: {
          status: {
            title: 'Sei sicuro di voler disabilitare questa categoria?',
            description:
              'Disabilitare una categoria significa che non può essere utilizzata nel sistema',
            label: 'Sì, voglio disabilitare questa categoria',
          },
          submit: 'Disabilita',
        },
      },
    },
    techniques: {
      list: {
        title: 'Tecniche',
        description: 'Gestione delle tecniche di trattamento nel sistema',
        create: 'Tecnica',
        one: 'Tecnica',
        many: 'Tecniche',
        columns: {
          name: 'Nome',
          clinic: 'Clinica',
          created: 'Data di creazione',
          price: 'Prezzo',
        },
      },
      create: {
        title: 'Crea tecnica',
        subtitle: 'Registrazione di una nuova tecnica nel sistema',
        form: {
          id: {
            title: 'Nome',
            description: 'Nome identificativo della tecnica',
          },
          name: {
            title: 'Nome',
            description: 'Nome identificativo della tecnica',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita della tecnica',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare la tecnica',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica dove viene eseguita la tecnica',
          },
          submit: 'Crea tecnica',
        },
      },
      update: {
        title: 'Aggiornare la tecnica',
        subtitle: 'Modifica di una tecnica nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo della tecnica',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita della tecnica',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare la tecnica',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica dove viene eseguita la tecnica',
          },
          submit: 'Aggiornare la tecnica',
        },
      },
      enable: {
        title: 'Abilita la tecnica',
        subtitle: 'Abilita la tecnica nel sistema',
        form: {
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilitare la tecnica',
        subtitle: 'Disabilitare la tecnica nel sistema',
        form: {
          submit: 'Disabilita',
        },
      },
      add: 'Aggiungere',
    },
    programs: {
      list: {
        title: 'Programmi',
        description: 'Gestione dei programmi a catalogo',
        create: 'Programma',
        one: 'Programma',
        many: 'Programmi',
        columns: {
          name: 'Nome',
          created: 'Data di creazione',
          price: 'Prezzo',
          techniques: 'Tecniche',
        },
      },
      create: {
        title: 'Creare un programma per il catalogo',
        subtitle: 'Registrazione di un nuovo programma nel catalogo',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del programma',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare il programma',
          },
          complexity: {
            title: 'Complessità',
            description: 'Livello di complessità del programma',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita del programma',
          },
          code: {
            title: 'Codice',
            description: 'Codice identificativo del programma',
          },
          submit: 'Crea programma',
        },
      },
      enable: {
        title: 'Abilita programma',
        subtitle: 'Abilita il programma nel catalogo',
        form: {
          status: {
            title: 'Sei sicuro di volerlo abilitare?',
            description: 'Abilitare un programma significa che può essere utilizzato sul sistema',
            label: 'Sì, voglio abilitare questo programma',
          },
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita programma',
        subtitle: 'Disabilita il programma nel catalogo',
        form: {
          status: {
            title: 'Sei sicuro di volerlo disabilitare?',
            description:
              'Disabilitare un programma significa che non può essere utilizzato sul sistema',
            label: 'Sì, voglio disabilitare questo programma',
          },
          submit: 'Disabilita',
        },
      },
      update: {
        title: 'Aggiorna il programma',
        subtitle: 'Modificare un programma nel catalogo',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome identificativo del programma',
          },
          description: {
            title: 'Descrizione',
            description: 'Una breve descrizione per dettagliare il programma',
          },
          complexity: {
            title: 'Complessità',
            description: 'Livello di complessità del programma',
          },
          clinicId: {
            title: 'Clinica',
            description: 'Clinica in cui viene svolto il programma',
          },
          price: {
            title: 'Prezzo',
            description: 'Prezzo di vendita del programma',
          },
          code: {
            title: 'Codice',
            description: 'Codice identificativo del programma',
          },
          submit: 'Aggiorna il programma',
        },
        tabs: {
          program: {
            title: 'Programmi',
            description: 'Modificare un programma nel catalogo',
          },
          techniques: {
            title: 'Tecniche',
            description: 'Tecniche associate al programma',
          },
          phases: {
            title: 'Fasi',
            description: 'Fasi associate al programma',
          },
          cycles: {
            title: 'Cicli',
            description: 'Cicli associati al programma',
          },
        },
        cycles: {
          list: {
            title: 'Cicli',
            description: 'Cicli associati al programma',
            columns: {
              cycleName: 'Nome',
              cycleCreated: 'Data di creazione',
            },
            one: 'Ciclo',
            many: 'Cicli',
          },
          create: {
            title: 'Aggiungi ciclo',
            subtitle: 'Aggiungi ciclo al programma',
            form: {
              cycleId: {
                title: 'Ciclo',
                description: 'Ciclo associato al programma',
              },
              submit: 'Aggiungere',
            },
          },
          delete: {
            title: 'Elimina ciclo',
            subtitle: 'Questa azione è irreversibile',
            form: {
              submit: 'Eliminare',
            },
          },
        },
        phases: {
          create: {
            title: 'Aggiungi fase',
            subtitle: 'Aggiungi fase al programma',
            form: {
              phaseId: {
                title: 'Palcoscenico',
                description: 'Tappa associata al programma',
              },
              submit: 'Aggiungere',
            },
          },
          delete: {
            title: 'Elimina fase',
            subtitle: 'Questa azione è irreversibile',
            form: {
              status: {
                title: 'Sei sicuro di volerlo eliminare?',
                description: 'Potresti preferire disabilitare questa fase',
                label: 'Sì, voglio eliminare questa fase',
              },
              submit: 'Eliminare',
            },
          },
          list: {
            title: 'Fasi',
            description: 'Fasi associate al programma',
            columns: {
              phaseName: 'Nome',
              phaseCreated: 'Data di creazione',
            },
            one: 'Palcoscenico',
            many: 'Fasi',
          },
        },
        techniques: {
          create: {
            title: 'Aggiungi tecnica',
            subtitle: 'Aggiungi tecnica al programma',
            form: {
              id: {
                title: 'Tecnica',
                description: 'Tecnica associata al programma',
              },
              submit: 'Aggiungere',
            },
          },
          delete: {
            title: 'Elimina la tecnica',
            subtitle: 'Questa azione è irreversibile',
            form: {
              submit: 'Eliminare',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Fasi',
        description: 'Gestione delle fasi di un trattamento',
        create: 'Palcoscenico',
        one: 'Palcoscenico',
        many: 'Fasi',
        columns: {
          name: 'Nome',
          created: 'Data di creazione',
        },
      },
      create: {
        title: 'Crea palcoscenico',
        subtitle: 'Registrazione di una nuova fase nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: "Nome d'arte",
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per dare maggiori dettagli sulla tappa.',
          },
          icon: {
            title: 'Icona',
            label: 'Icona',
            description: 'Icona per identificare il palco. ',
            search: {
              placeholder: 'Icona Cerca...',
            },
          },
          color: {
            title: 'Colore',
            description: 'Identificare il colore del palco. ',
          },
          submit: 'Crea palcoscenico',
        },
      },
      update: {
        title: 'Fase di aggiornamento',
        subtitle: 'Modifica di una fase del sistema',
        form: {
          name: {
            title: 'Nome',
            description: "Nome d'arte",
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per dare maggiori dettagli sulla tappa.',
          },
          icon: {
            title: 'Icona',
            description: 'Icona per identificare il palco. ',
            search: {
              placeholder: 'Icona Cerca...',
            },
            label: 'Icona',
          },
          color: {
            title: 'Colore',
            description: 'Identificare il colore del palco. ',
          },
          submit: 'Mantenere',
        },
        tabs: {
          phase: {
            title: 'Palcoscenico',
            description: 'Informazioni sul palco',
          },
          causes: {
            title: 'Cause',
            description: 'Cause per le quali un trattamento può essere annullato',
            one: 'Causa',
            many: 'Cause',
          },
        },
      },
      enable: {
        title: 'Abilita fase',
        subtitle: 'Abilita la fase nel sistema',
        form: {
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disattiva fase',
        subtitle: 'Disabilitare la fase nel sistema',
        form: {
          submit: 'Disabilita',
        },
      },
    },
    causes: {
      list: {
        title: 'Cause',
        description: 'Gestione delle cause di annullamento di un trattamento',
        create: 'Causa',
        one: 'Causa',
        many: 'Cause',
        columns: {
          name: 'Nome',
          created: 'Data di creazione',
        },
      },
      create: {
        title: 'Crea causa',
        subtitle: 'Registrazione di una nuova causa nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome della causa',
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per fornire maggiori dettagli sulla causa.',
          },
          icon: {
            title: 'Icona',
            description: 'Icona per identificare la causa. ',
            search: {
              placeholder: 'Icona Cerca...',
            },
            label: 'Icona',
          },
          color: {
            title: 'Colore',
            description: 'Identificazione del colore della causa. ',
          },
          submit: 'Crea causa',
        },
      },
      update: {
        title: 'Aggiorna causa',
        subtitle: 'Modifica di una causa nel sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome della causa',
          },
          description: {
            title: 'Descrizione',
            description: 'Un breve riassunto per fornire maggiori dettagli sulla causa.',
          },
          icon: {
            title: 'Icona',
            description: 'Icona per identificare la causa. ',
            search: {
              placeholder: 'Icona Cerca...',
            },
            label: 'Icona',
          },
          color: {
            title: 'Colore',
            description: 'Identificazione del colore della causa. ',
          },
          submit: 'Mantenere',
        },
      },
      enable: {
        title: 'Abilita causa',
        subtitle: 'Abilita la causa nel sistema',
        form: {
          submit: 'Abilitare',
        },
      },
      disable: {
        title: 'Disabilita causa',
        subtitle: 'Disabilitare la causa nel sistema',
        form: {
          submit: 'Disabilita',
        },
      },
    },
  },
  board: {
    total: 'Totale',
    months: {
      jan: 'Gen',
      feb: 'Febbraio',
      mar: 'Mare',
      apr: 'aprile',
      may: 'Maggio',
      jun: 'giugno',
      jul: 'Lug',
      aug: 'Agosto',
      sep: 'Settembre',
      oct: 'ottobre',
      nov: 'novembre',
      dec: 'dicembre',
    },
    noData: {
      title: 'Nessun dato disponibile',
      description: 'Puoi provare a selezionare altri filtri',
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Lead per campagna e stato',
        description: 'Lead per campagna e stato',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
      leadPerClinicAndState: {
        title: 'Conduce per clinica e stato',
        description: 'Conduce per clinica e stato',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
    },
    direction: {
      title: 'Indirizzo dei dashboard',
      description: 'Visualizzazione esecutiva delle informazioni CRM in tempo reale',
      cost: 'Costo del piombo',
      leadCostByClinicAndMonth: {
        title: 'Costo per lead per clinica',
        description: 'Costo per lead per clinica',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
      leadCostByFunnelAndMonth: {
        title: 'Distribuzione dei lead per cliniche e dipartimenti',
        description: 'Distribuzione dei lead per cliniche e dipartimenti',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
      leadCostByCampaignAndMonth: {
        title: 'Costo per lead per campagna',
        description: 'Costo per lead per campagna',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
    },
    marketing: {
      title: 'Cruscotti di marketing',
      description: 'Approfondimenti di marketing delle informazioni CRM in tempo reale',
      secondary: {
        title: 'Campagne di marketing',
        description: 'Visualizzazione di informazioni lead relative a campagne di marketing.',
      },
      convertionRateByCampaign: {
        title: 'Tasso di conversione per campagna',
        description: 'Tasso di conversione per campagna',
        totalLeads: 'Lead totali',
        totalConvertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
      },
      totalLeads: 'LEAD TOTALI',
      leadsState: 'STATO LEADER',
      leadActiveInactive: 'DERIVAZIONI ATTIVE/INATTIVE',
      leadsPerCampaign: {
        title: 'Lead per campagna',
        description: 'Lead per campagna',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
        filters: {
          campaign: 'Campana',
          funnel: 'Imbuto',
        },
      },
      leadCostDetail: {
        title: 'Dettaglio',
        selectedClinic: 'Clinica selezionata',
        selectedCampaign: 'Campagna selezionata',
        budget: 'Bilancio',
      },
      costCampaign: {
        '0': 'Clinica selezionata: ',
        '1': 'Campagna selezionata: ',
        '2': 'Bilancio: ',
        '3': 'Numero di lead: ',
        '4': 'Lead citati: ',
        '5': 'Costo totale dei lead: ',
        '6': 'Costo totale indicato: ',
      },
      leadsPerFunnel: {
        title: 'Lead per imbuto',
        description: 'Lead per imbuto',
        totalLeads: 'Lead totali',
        totalCost: 'Costo totale',
        costPerLead: 'Costo per lead',
      },
      filters: {
        year: 'Anno',
        yearSubtitle: 'Filtra per anno selezionato',
        by: 'Filtra per',
      },
      generalTable: {
        totalLeads: 'Lead totali',
        informed: 'V.Mi sono esibito',
        total: 'Totale cumulativo',
        costPerLead: 'Costo per lead',
        totalRow: 'Totale',
      },
    },
    subordinates: {
      title: 'La prestazione della mia squadra',
      description: 'Visualizzazione delle prestazioni della mia squadra',
      performance: {
        title: 'Traguardi raggiunti',
        description: 'Visualizzazione dei traguardi raggiunti dal mio team',
      },
      performanceSubordinates: {
        title: 'PRESTAZIONE DELLA MIA SQUADRA',
      },
      performanceSubordinate: {
        title: 'LA PRESTAZIONE DEL MIO PARTNER',
      },
      filters: {
        clinics: {
          all: 'Tutto',
        },
        subordinate: {
          title: 'Dipendenti',
          subtitle: 'Filtra per dipendente selezionato',
        },
      },
    },
    intervention: {
      title: 'Previsioni del trattamento',
      description: 'Visualizzazione dei trattamenti stimati',
      expectedInterventions: {
        monthsTable: 'PREVISIONE DEL TRATTAMENTO',
        yearsTable: 'PREVISIONE DEI TRATTAMENTI ACCUMULATI',
      },
      segmention: 'Suddivisione dei trattamenti',
      lossGainSegmentation: 'Ripartizione dei profitti e delle perdite',
      one: 'Trattamento',
      many: 'Trattamenti',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Perdite',
        total_first_attempt: 'Garanzie (1° tentativo)',
        total: 'Totale',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Perdite',
        total_first_attempt: 'Guadagni',
        total: 'Totale',
      },
      filters: {
        complexity: {
          title: 'Complessità',
          subtitle: 'Filtra per complessità selezionata',
          types: {
            ALL: 'Tutto',
            HIGH: 'Alto',
            LOW: 'Basso',
          },
        },
      },
    },
    callcenter: {
      title: 'Cruscotti del call center',
      description: 'Visualizzazione da parte del call center delle informazioni CRM in tempo reale',
    },
    leads: {
      title: 'Lead di dashboard',
      description: 'Visualizzazione dei lead dalle informazioni del CRM in tempo reale',
      totalLeads: {
        title: 'Lead totali',
        description: 'Riepilogo dei contatti per clinica e dipartimento',
        filters: {
          clinic: 'Clinica',
          clinicSubtitle: 'Filtra per clinica selezionata',
          campaign: 'Campana',
          campaignSubtitle: 'Filtra per campagna selezionata',
          optionA: 'Opzione A',
          optionASubtitle: '1a opzione del confronto',
          optionB: 'Opzione B',
          optionBSubtitle: '2a opzione del confronto',
          funnel: 'Origine',
          funnelSubtitle: 'Filtra per origine',
          all: 'Tutto',
          department: 'Dipartimento',
          departmentSubtitle: 'Filtra per dipartimento selezionato',
          year: 'Anno',
          yearSubtitle: 'Filtra per anno selezionato',
          month: 'Mese',
          monthSubtitle: 'Filtra per mese selezionato',
          allMonths: 'Tutto',
        },
        comparativeGraphicTitle: 'CONFRONTO LEADER',
        comparativeAcumulatedGraphicTitle: 'CONFRONTO LEAD/ACCUMULATE',
        totalLeads: 'LEAD TOTALI',
        summary: 'RIEPILOGO',
        export: 'ESPORTA CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Imbuto di vendita',
      description: "Visualizzazione dell'imbuto di vendita delle informazioni CRM in tempo reale",
      totalSales: {
        graphicTitle: 'IMBUTO DI VENDITA',
      },
    },
    sales: {
      title: 'Vendite sul cruscotto',
      description: 'Visualizzazione delle vendite delle informazioni CRM in tempo reale',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Trattamenti, tecniche e servizi per clinica',
        description: 'Trattamenti, tecniche e servizi per clinica',
        totalTreatments: 'Trattamenti totali',
        totalTechniques: 'Tecniche totali',
        totalServices: 'Servizi totali',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Trattamenti, tecniche e servizi per reparto',
        description: 'Trattamenti, tecniche e servizi per reparto',
        totalTreatments: 'Trattamenti totali',
        totalTechniques: 'Tecniche totali',
        totalServices: 'Servizi totali',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Riepilogo delle vendite',
        ovobank: 'Riepilogo delle vendite',
      },
      description: {
        ovoclinic: 'Riepilogo dei lead convertiti in pazienti',
        ovobank: 'Riepilogo dei lead convertiti in donatori',
      },
      totalSales: {
        title: 'Saldi',
        description: 'Visualizzazione delle vendite effettuate',
        filters: {
          clinic: 'Clinica',
          clinicSubtitle: 'Filtra per clinica selezionata',
          optionA: 'Opzione A',
          optionASubtitle: '1a opzione del confronto',
          optionB: 'Opzione B',
          optionBSubtitle: '2a opzione del confronto',
          department: 'Dipartimento',
          departmentSubtitle: 'Filtra per dipartimento selezionato',
          month: 'Mese',
          monthSubtitle: 'Filtra per mese selezionato',
        },
        comparativeGraphicTitle: 'CONFRONTO DELLE VENDITE',
        export: 'ESPORTA CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'CONFRONTO VENDITE/ACCUMULATE',
      },
    },
    medical: {
      title: 'Cruscotti medici',
      description: 'Visualizzazione del reparto medico delle informazioni CRM in tempo reale',
      leadConversionByFunnelAndMonth: {
        title: 'Tasso di conversione dei lead per origine e mese',
        description: 'Tasso di conversione dei lead per origine e mese',
        totalLeads: 'Lead totali',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Percentuale di conversione dei lead per origine e mese',
        description: 'Percentuale di conversione dei lead per origine e mese',
        totalLeads: 'Lead totali',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Percentuale di conversione',
      },
      estimatedTreatmentByYear: {
        title: 'Trattamenti programmati',
        description:
          "Confronto dei trattamenti previsti nell'anno selezionato e nell'anno precedente",
        estimated: 'Trattamento programmato',
        finished: 'Trattamento completato',
        previousEstimated: 'Trattamenti programmati',
        previousFinished: 'Trattamenti completati',
      },
      finishedTreatmentByYear: {
        title: 'Trattamenti completati',
        description:
          "Confronto dei trattamenti effettuati nell'anno selezionato e nell'anno precedente",
        estimated: 'Trattamento programmato',
        finished: 'Trattamento completato',
        previousEstimated: 'Trattamenti programmati',
        previousFinished: 'Trattamenti completati',
      },
      leadConversionBySpecialist: {
        title: 'Tasso di conversione dei lead per specialista',
        description: 'Tasso di conversione dei lead per specialista',
        totalLeads: 'Lead totali',
        appointedLeads: 'Lead citati',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
        treatmentSold: 'Trattamenti venduti',
        treatmentFinished: 'Trattamenti completati',
        specialistData: 'Informazioni specialistiche',
      },
      leadConversionBySpecialistKPI: {
        title: 'KPI Tasso di conversione lead per specialista',
        conversionRate: 'Tasso di conversione',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Tasso di conversione dei lead per specialista e mese',
        description: 'Tasso di conversione dei lead per specialista e mese',
        totalLeads: 'Lead totali',
        appointedLeads: 'Lead citati',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
        treatmentSold: 'Trattamenti venduti',
        treatmentFinished: 'Trattamenti completati',
        specialistData: 'Informazioni specialistiche',
        leadsAttended: 'Lead serviti',
        leadsAttendedConverted: 'Lead serviti convertiti',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'KPI Tasso di conversione lead per specialista e mese',
        description: 'KPI Tasso di conversione lead per specialista e mese',
        conversionRate: 'Tasso di conversione',
      },
      leadConversionByFunnel: {
        title: 'Tasso di conversione dei lead per origine',
        description: 'Tasso di conversione dei lead per origine',
        totalLeads: 'Lead totali',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
        appointedLeads: 'Lead citati',
        treatmentSold: 'Trattamenti venduti',
        treatmentFinished: 'Trattamenti completati',
      },
      leadConversionByFunnelKPI: {
        title: 'KPI Tasso di conversione dei lead per origine',
        description: 'KPI Tasso di conversione dei lead per origine',
        totalLeads: 'Lead totali',
        convertedLeads: 'Lead convertiti',
        conversionRate: 'Tasso di conversione',
        appointedLeads: 'Lead citati',
        treatmentSold: 'Trattamenti venduti',
        treatmentFinished: 'Trattamenti completati',
        convertedLeadsRate: 'Tasso di conversione dei lead',
        treatmentSoldRate: 'Tasso di conversione dei trattamenti venduti',
      },
    },
    menu: {
      leads: 'Riepilogo dei lead',
      directory: 'Direttorio',
      direction: 'Indirizzo',
      expectedIntervention: 'Previsioni del trattamento',
      general: 'Generale',
      callcenter: 'Call center',
      medical: 'Medico',
      marketing: 'Marketing',
      sale: 'Saldi',
      saleFunnel: 'Imbuto di vendita',
      summarySales: 'Riepilogo delle vendite',
      subordinates: 'Prestazioni dei dipendenti',
    },
    sections: {
      marketing: {
        actives: 'Attività',
        inactives: 'Inattivo',
        converted: 'Convertito',
        appointed: 'Citato',
        informed: 'Informato',
        contacted: 'Contattato',
        leadPerCampaign: {
          title: 'Lead per campagna di marketing',
        },
        leadPerFunnel: {
          title: 'Lead per origine della campagna',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Tasso di conversione del paziente principale per canalizzazione',
            ovobank: 'Tasso di conversione dei donatori principali per canalizzazione',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Tasso di conversione del paziente principale per campagna',
            ovobank: 'Tasso di conversione dei donatori principali per campagna',
          },
          patients: {
            ovoclinic: 'Pazienti',
            ovobank: 'Donatori',
          },
          leads: 'Conduce',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Tasso di conversione del paziente principale per origine',
            ovobank: 'Tasso di conversione dei donatori principali per origine',
          },
          patients: {
            ovoclinic: 'Pazienti',
            ovobank: 'Donatori',
          },
          leads: 'Conduce',
        },
        leadPerClinicPerState: {
          total: 'Totale',
          title: 'Lead per clinica per stato',
          actives: 'Attività',
          inactives: 'Inattivo',
          converted: 'Convertito',
          appointed: 'Citato',
          appointedMedic: 'Citato (medico)',
          contacted: 'Contattato',
          informed: 'Informato',
          loss: 'Basso',
          cancelled: 'Annullato',
        },
        leadPerCampaignPerState: {
          total: 'Totale',
          title: 'Lead per campagna per stato',
          actives: 'Attività',
          inactives: 'Inattivo',
          converted: 'Convertito',
          appointed: 'Citato',
          contacted: 'Contattato',
          informed: 'Informato',
          loss: 'Basso',
        },
        totalLeadsPerState: {
          total: 'Totale',
          appointed: 'Citato',
          percentage: 'Percentuale',
          cost: 'Costo',
          actives: 'Attività',
          inactives: 'Inattivo',
        },
        filter: {
          campaignId: {
            title: 'Filtra per campagna',
          },
          languageId: {
            title: 'Filtra per lingua',
          },
          funnelId: {
            title: 'Filtra per origine',
          },
          clinicId: {
            title: 'Filtra per clinica',
          },
        },
        listLeadsByCampaign: {
          title: 'Ripartizione della campagna',
          campaign: 'Campagna di marketing',
          funnel: 'Origine',
          financingClinic: 'Clinica di finanziamento',
          language: 'Dipartimento',
          budget: 'Budget della campagna',
          nLeads: 'N. di lead',
          costPerLead: 'Costo per lead',
        },
        all: 'Tutto',
      },
      callcenter: {
        listLeadsByReason: {
          title: 'Lead dovuti alla cancellazione',
          reason: 'Motivo',
          nLeads: 'N. di lead',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Costo principale per clinica',
          monthFilter: 'Filtra per mese',
        },
        costsLeadPerFunnel: {
          title: 'Costo del lead per origine',
        },
        costsLeadPerCampaign: {
          title: 'Costo lead per campagna',
        },
        costsLeadPerLanguage: {
          title: 'Costo principale per dipartimento',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Trattamenti, tecniche e servizi per clinica',
        },
        salePerLanguage: {
          title: 'Trattamenti, tecniche e servizi per reparto',
        },
      },
      saleFunnel: {
        title: 'Imbuto aziendale',
        description: 'Visualizzazione dello stato generale dei lead durante il processo di vendita',
      },
    },
  },
  profile: {
    menu: {
      profile: 'Profilo',
      account: 'il mio conto',
      changePassword: 'Cambiare la password',
    },
    changePassword: {
      title: 'Cambiare la password',
      subtitle: 'Cambia la password del tuo account',
      form: {
        password: {
          title: 'Password',
          description: "Nuova password per l'utente",
        },
        repeatPassword: {
          title: 'Ripeti la password',
          description: 'Ripetere la password per confermarla',
        },
        submit: 'Cambiare la password',
      },
    },
    account: {
      title: 'il mio conto',
      subtitle: "Informazioni personali e sull'account",
      form: {
        name: {
          title: 'Nome',
          description: 'Inserisci il tuo nome',
        },
        surname: {
          title: 'Cognomi',
          description: 'Inserisci il tuo cognome',
        },
        username: {
          title: 'Nome utente',
          description: 'Inserisci il tuo nome utente',
        },
        phone: {
          title: 'Telefono',
          description: 'Inserisci il tuo numero di telefono',
        },
        submit: 'Aggiorna conto',
      },
    },
  },
  common: {
    search: {
      placeholder: 'Cercare...',
    },
    actions: {
      create: 'Creare',
      update: 'Aggiornamento',
      delete: 'Eliminare',
      save: 'Mantenere',
      cancel: 'Cancellare',
    },
    tasks: {
      disabled: 'Mostra inattivo',
      noResult: 'Nessun risultato',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Elimina nota',
          description: 'Sei sicuro di voler eliminare questa nota?',
          cancel: 'Cancellare',
          submit: 'Eliminare',
        },
      },
      noteType: {
        title: 'Vedi le osservazioni del',
        patient: {
          ovoclinic: 'Paziente',
          ovobank: 'Donatore',
        },
        lead: {
          ovoclinic: 'Guida',
          ovobank: 'Guida',
        },
      },
      filter: {
        title: 'Filtra per',
        tags: {
          medical: 'Medico',
          nursing: 'Assistenza infermieristica',
          followUp: 'Seguito',
        },
      },
    },
    time: {
      seconds: 'secondi',
      second: 'secondo',
      minutes: 'minuti',
      minute: 'minuto',
      hour: 'ora',
      hours: 'ore',
      days: 'giorni',
      day: 'giorno',
      weekDays: {
        '0': 'Lunedi',
        '1': 'Martedì',
        '2': 'Mercoledì',
        '3': 'Giovedì',
        '4': 'Venerdì',
        '5': 'Sabato',
        '6': 'Domenica',
      },
      timeMessage: {
        expiresIn: 'Scade tra {{value}} {{unit}}',
        expired: 'Scaduto',
        ago: 'Fa',
      },
    },
    status: {
      success: 'Corretto',
      fail: 'Fallito',
    },
    notification: {
      title: 'Hai una nuova notifica',
    },
    contactTime: {
      MORNING: 'Domani',
      AFTERNOON: 'Tardi',
      NO_PREFERENCE: 'Nessuna preferenza',
    },
    complexity: {
      HIGH: 'Alto',
      LOW: 'Basso',
    },
    specialistType: {
      GYNECOLOGIST: 'Ginecologo',
      EMBRYOLOGIST: 'embriologo',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: 'Orecchini',
      COMPLETED: 'Fatto',
      ALL: 'Tutto',
    },
    plannedStatus: {
      ALL: 'Tutto',
      PLANNED: 'Pianificato',
      UNPLANNED: 'Non pianificato',
    },
    taskProgress: {
      PENDING: 'Orecchino',
      COMPLETED: 'Fatto',
    },
    leadEntryStatus: {
      NEW: 'Nuovo',
      DUPLICATED: 'Duplicato',
      REENTRY: 'Rientro',
    },
    paymentStatus: {
      PENDING: 'Orecchino',
      PAID: 'Pagato',
    },
    purchaseType: {
      MAINTENANCE: 'Manutenzione',
      TREATMENT: 'Programma',
      TECHNIQUE: 'Tecnica',
    },
    notifications: {
      list: {
        title: 'Notifiche',
        description: 'Gestione delle notifiche nel sistema',
        columns: {
          title: 'Qualificazione',
          type: 'Tipo di notifica',
          created: 'Data di creazione',
        },
        one: 'Notifica',
        many: 'Notifiche',
      },
      types: {
        ACTION_ALERT: 'Avviso attività',
      },
      new: 'Hai una nuova notifica',
    },
    information: {
      title: 'Risorse',
      description: 'Informazioni e risorse sulla piattaforma',
    },
  },
  components: {
    fileUploader: {
      title: 'Carica file',
      invalidType: 'Tipo di file non consentito',
    },
  },
  login: {
    title: 'Login',
    subtitle: 'Accesso alla piattaforma di gestione',
    form: {
      email: {
        title: 'E-mail',
      },
      signature: {
        title: 'Password',
      },
      submit: 'Login',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: 'Nome utente o password errati',
      WRONG_PERMISSIONS: 'Non disponi delle autorizzazioni per eseguire questa azione',
      service: {
        group: {
          findByDomain: {
            error: 'FATAL: Impossibile trovare il gruppo',
          },
        },
      },
    },
  },
};
