import { View, Text, useWindowDimensions } from 'react-native';
import { LeadsScreenStyles } from '../../../leads.screen.styles';
import { LeadActiveInactiveComparativeWeb } from '../../../components/leadActiveInactiveComparative/leadActiveInactiveComparative.web';
import { LeadActiveInactiveProperties } from './leadActiveInactive.properties';
import { useTranslation } from 'react-i18next';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { LeadActiveInactiveChartWeb } from '../../../components/leadActiveInactiveChart/leadActiveInactiveChart.web';
import { useEffect, useState } from 'react';
import { LoaderComponent } from '@components/loader/loader.component';

export function LeadActiveInactiveChart(properties: LeadActiveInactiveProperties) {
  const { t } = useTranslation();

  const [year, setYear] = useState<number>(properties.optionB);
  const [month, setMonth] = useState<number>(0);

  const [yearsSelection, setYearsSelection] = useState<{ label: string; value: string }[]>([
    { label: properties.optionA.toString(), value: properties.optionA.toString() },
    { label: properties.optionB.toString(), value: properties.optionB.toString() },
  ]);

  useEffect(() => {
    setYearsSelection([
      { label: properties.optionA.toString(), value: properties.optionA.toString() },
      { label: properties.optionB.toString(), value: properties.optionB.toString() },
    ]);
  }, [properties.optionA, properties.optionB]);

  return (
    <>
      <View style={LeadsScreenStyles.graphicsContainer}>
        <View style={{ flex: 2 }}>
          <View style={{ width: 250, marginVertical: 10, zIndex: 5 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.year')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.yearSubtitle')}
              </Text>
            </View>
            {properties.clinicId && (
              <View>
                <FilterSelectInput
                  type="select"
                  value={year.toString() ?? ''}
                  name="year"
                  onChange={(value: any) => {
                    setYear(parseInt(value.year));
                  }}
                  options={yearsSelection}
                  hideSearch
                />
              </View>
            )}
          </View>
          <View
            style={{
              gap: 30,
              backgroundColor: 'white',
              padding: 15,
              borderRadius: 5,
              flex: 1,
              minHeight: 500,
            }}
          >
            <View>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeGraphicTitle')}
              </Text>
            </View>

            {properties.clinicId && properties.languageId && !properties.loading ? (
              <LeadActiveInactiveComparativeWeb
                year={year}
                departmentId={properties.languageId ?? ''}
                clinicId={properties.clinicId}
                funnelId={properties.funnelId === 'all' ? undefined : properties.funnelId}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <View style={{ width: 250, marginVertical: 10, zIndex: 5 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.month')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.monthSubtitle')}
              </Text>
            </View>
            {properties.clinicId && (
              <View>
                <FilterSelectInput
                  type="select"
                  value={month.toString()}
                  name="month"
                  onChange={(value: any) => {
                    setMonth(parseInt(value.month));
                  }}
                  options={Array.from({ length: 13 }, (_, i) => {
                    return {
                      label:
                        i === 0
                          ? t('board.leads.totalLeads.filters.allMonths')
                          : t(`access.users.list.month.${i - 1}`),
                      value: i.toString(),
                    };
                  })}
                  hideSearch
                />
              </View>
            )}
          </View>
          <View
            style={{
              gap: 30,
              backgroundColor: 'white',
              padding: 15,
              borderRadius: 5,
              flex: 1,
              minHeight: 500,
            }}
          >
            <View>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
              </Text>
            </View>
            {properties.clinicId && properties.languageId && !properties.loading ? (
              <LeadActiveInactiveChartWeb
                year={year}
                month={month}
                departmentId={properties.languageId ?? ''}
                clinicId={properties.clinicId}
                funnelId={properties.funnelId === 'all' ? undefined : properties.funnelId}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
      </View>
    </>
  );
}
