// Modules
import { StyleSheet } from 'react-native';

/**
 * Section Styles
 * @constant {StyleSheet} SectionStyles
 */
export const SectionStyles = StyleSheet.create({
  menuTitle: {
    fontSize: 14,
    padding: 15,
    paddingTop: 10,
    color: '#666',
    paddingBottom: 10,
  },
  menuItem: {
    height: 41,
    borderBottomWidth: 1,
    flexDirection: 'row',
    padding: 16,
    paddingVertical: 5,
    gap: 10,
    alignItems: 'center',
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  menuItemActive: {
    backgroundColor: 'rgb(237, 53, 145)',
  },
  menuItemInactive: {
    backgroundColor: '#00000013',
  },
  menuItemIcon: {
    fontSize: 24,
  },
  menuItemIconMobile: {
    fontSize: 20,
  },
  menuItemIconActive: {
    color: '#fff',
    opacity: 1,
  },
  menuItemIconInactive: {
    color: '#111',
    opacity: 0.3,
  },
  menuItemText: {
    fontSize: 16,
    marginRight: 'auto',
  },
  menuItemTextMobile: {
    fontSize: 14,
    marginRight: 'auto',
  },
  menuItemTextActive: {
    color: '#fff',
  },
  menuItemTextInactive: {
    color: '#111',
  },
  notificationBadge: {
    height: 30,
    width: 30,
    borderRadius: 100,
    textAlign: 'center',
    lineHeight: 20,
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationBadgeMobile: {
    textAlign: 'center',
    borderRadius: 50,
    padding: 4,
    overflow: 'hidden',
  },
  notificationBadgeActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    color: '#fff',
  },
  notificationBadgeInactive: {
    backgroundColor: '#0000000A',
    color: '#111',
  },
  newsContainer: {
    marginTop: 'auto',
  },
  status: {
    marginLeft: 'auto',
    color: 'rgb(255, 0, 128)',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: 5,
    fontSize: 8,
    paddingHorizontal: 7,
    letterSpacing: 1,
    borderRadius: 25,
  },
});
