import { View, TextInput as TextInputNative } from 'react-native';
import { FloatInputImplementation } from './float.implementation';
import { useState } from 'react';
import { TextInputValidations } from './float.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { FloatInputStyles } from './float.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function FloatInput<ITEM>(properties: FloatInputImplementation<ITEM>) {
  const value = properties.value[properties.name] as string;
  const [focus, setFocus] = useState(false);

  const onChange = (text: string) => {
    if (properties.readonly) return;
    if (text.trim() === '') {
      text = '0.00';
    }
    const isNan = [...text].some((char) => {
      if (char === '.') return false;
      return isNaN(parseInt(char));
    });

    if (isNan) return;
    const key = properties.name;
    // @ts-ignore

    const newValue: Partial<ITEM> = {
      [key]: text,
    };
    properties.onChange(newValue);
  };

  const errors =
    properties.validations &&
    TextInputValidations({
      validations: properties.validations,
      value: parseFloat(value),
      required: properties.required,
    });

  return (
    <>
      <View style={FloatInputStyles.container}>
        <TextInputNative
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          focusable={!properties.readonly}
          editable={!properties.readonly}
          placeholder="0.00"
          value={value}
          style={[
            FloatInputStyles.input,
            !properties.readonly && focus
              ? FloatInputStyles.inputFocus
              : FloatInputStyles.inputUnfocus,
            properties.readonly ? FloatInputStyles.readonly : null,
          ]}
          onChangeText={onChange}
        />
      </View>
      {errors && <ErrorsComponent prefix={'form.text.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
