import { StyleSheet } from 'react-native';

export const SelectableColumnStyles = StyleSheet.create({
  line: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#C3C3C3',
    width: 6,
  },
  container: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
  },
  checkbox: {
    width: 15,
    height: 15,
    borderColor: '#C3C3C3',
    borderRadius: 4,
    borderWidth: 1,
  },
  checked: {
    borderColor: '#CCCCCC',
    backgroundColor: '#E3E3E3',
  },
  checkedIcon: {
    fontSize: 12,
    fontWeight: '700',
    color: '#585858',
  },
  checkedIconContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
