import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Dimensions, Text, View } from 'react-native';
import { ItemByDepartmentYearsComparativeType } from '../newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';

const { height } = Dimensions.get('window');

export function NewLeadsByDepartmentChart(properties: {
  data: ItemByDepartmentYearsComparativeType[];
  firstYear: number;
  secondYear: number;
  departmentName: string;
  selectedDepartment: string;
}) {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  }>({ labels: [], datasets: [] });

  useEffect(() => {
    if (
      properties.data.length === 0 ||
      !properties.firstYear ||
      !properties.secondYear ||
      !properties.departmentName
    )
      return;

    const months = Array.from({ length: 12 }, (_, i) => t(`access.users.list.month.${i}`)); // Etiquetas de los meses

    const firstYearData = new Array(12).fill(0); // Datos del primer año
    const secondYearData = new Array(12).fill(0); // Datos del segundo año

    const filteredData = properties.data.filter(
      (item) => item.department_name === properties.departmentName
    );

    // Llenamos los arrays con los leads de acuerdo al mes y año
    filteredData.forEach((item) => {
      const monthIndex = item.month - 1; // Meses empiezan en 0 (enero = 0, febrero = 1, etc.)
      if (item.year === properties.firstYear) {
        firstYearData[monthIndex] += item.total; // Sumamos los leads del primer año
      } else if (item.year === properties.secondYear) {
        secondYearData[monthIndex] += item.total; // Sumamos los leads del segundo año
      }
    });

    const datasets = [
      {
        label: properties.firstYear.toString(),
        data: firstYearData,
        backgroundColor: 'rgba(211, 211, 211, 0.8)',
        borderColor: 'rgba(211, 211, 211, 1)',
        borderWidth: 1,
      },
      {
        label: properties.secondYear.toString(),
        data: secondYearData,
        backgroundColor: '#283038',
        borderColor: '#283038',
        borderWidth: 1,
      },
    ];

    setChartData({ labels: months, datasets });
  }, [properties.data, properties.selectedDepartment]);

  if (!chartData || !chartData.labels.length) {
    return (
      <View>
        <Text>{t('No data available')}</Text>
      </View>
    );
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 20,
        height: height * 0.4,
      }}
    >
      <Bar
        data={chartData}
        options={{
          aspectRatio: 3, // Relación de aspecto
          plugins: {
            legend: {
              display: true,
              position: 'bottom', // Leyenda en la parte inferior
              labels: {
                color: '#283038', // Color de la leyenda
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true, // Comienza desde 0
              title: {
                display: true,
                text: 'Total de Leads', // Etiqueta del eje Y
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 2, // Ancho del borde
              borderColor: 'rgba(0, 0, 0, 0.6)', // Color del borde
              borderRadius: 6, // Radio del borde
            },
          },
        }}
      />
    </View>
  );
}
