export const PtLocale = {
  logotype: {
    horse: 'Ovocavalo',
    human: 'Ovoclínica',
    ovobank: 'Ovobanco',
    ovoclinic: 'Ovoclínica',
  },
  calendar: {
    hour: 'Hora',
    minutes: ':',
    save: 'Manter',
    placeholder: 'Selecione uma data',
  },
  historical: {
    deleted: 'Excluído',
    created: 'Criado',
    updated: 'Atualizado',
  },
  chat: {
    placeholder: 'Escreva uma mensagem',
    title: 'Bate-papo de contato',
  },
  slicer: {
    confirm: 'Selecione',
  },
  export: {
    title: 'Exportar',
    description: 'Exportar dados da tabela para um arquivo CSV',
  },
  import: {
    title: 'Matéria',
  },
  transfer: {
    title: 'Transferir',
  },
  createTaskMassively: {
    title: 'Criar tarefas',
  },
  leadEvents: {
    informed: 'Comercial V.I realizado',
    contacted: 'Contatado',
    attended: 'Médico V.I realizado',
    appointed: 'Comercial citado de V.I',
    medicalAppointed: 'Citado por V.I Medical',
    medicalEvaluated: 'Avaliação médica',
    comercialAttended: 'Orçamento entregue',
    converted: 'Convertido',
  },
  form: {
    tabChange: {
      title: 'Você está editando um formulário. ',
    },
    update: {
      successToast: 'Formulário atualizado com sucesso',
    },
    checkbox: {
      errors: {
        required: 'Você deve aceitar para continuar',
      },
    },
    selectable: {
      placeholder: {
        title: 'Selecione uma opção',
        empty: 'Não há resultados',
      },
    },
    date: {
      placeholder: 'Filtrar por data',
    },
    json: {
      errors: {
        geojson: 'Formato GeoJSON inválido',
      },
    },
    text: {
      errors: {
        notString: 'O valor deve ser uma string de texto',
        ipIsRequired: 'O endereço IP é obrigatório',
        ipMustBeAString: 'O endereço IP deve ser uma string de texto',
        ipMustBeAValidIpAddress: 'O endereço IP deve ser válido',
        countryNameEn: 'O nome é obrigatório',
        countryNameEs: 'O nome é obrigatório',
        nameIsRequired: 'O nome é obrigatório',
        nameMustBeAString: 'O nome deve ser uma string de texto',
        nameMustNotContainNumbers: 'O nome não deve conter números',
        nameMustNotContainSpecialCharacters: 'O nome não deve conter caracteres especiais',
        nameMustBeAtMost50CharactersLong: 'O nome deve ter no máximo 50 caracteres',
        dniIsRequired: 'O documento de identificação é obrigatório',
        dniMustBeAtLeast8CharactersLong: 'O ID deve ter pelo menos 8 caracteres',
        dniMustBeAtMost20CharactersLong: 'O ID deve ter no máximo 20 caracteres',
        surnameIsRequired: 'O sobrenome é obrigatório',
        surnameMustBeAString: 'O sobrenome deve ser uma string de texto',
        surnameMustNotContainNumbers: 'O sobrenome não deve conter números',
        surnameMustNotContainSpecialCharacters: 'O sobrenome não deve conter caracteres especiais',
        surnameMustBeAtMost50CharactersLong: 'O sobrenome deve ter no máximo 50 caracteres',
        emailIsRequired: 'O e-mail é obrigatório',
        emailMustBeAString: 'O e-mail deve ser uma string de texto',
        emailMustBeAValidEmail: 'O e-mail deve ser válido',
        emailMustBeAtLeast3CharactersLong: 'O e-mail deve ter pelo menos 3 caracteres',
        emailMustBeAtMost50CharactersLong: 'O e-mail deve ter no máximo 30 caracteres',
        emailMustContainAnAtSymbol: 'O e-mail deve conter um símbolo @',
        emailMustNotContainWhitespace: 'O e-mail não deve conter espaços em branco',
        passwordIsRequired: 'A senha é obrigatória',
        passwordMustBeAString: 'A senha deve ser uma string de texto',
        passwordMustBeAtLeast8CharactersLong: 'A senha deve ter pelo menos 8 caracteres',
        passwordMustBeAtMost30CharactersLong: 'A senha deve ter no máximo 30 caracteres',
        passwordMustContainAnUppercaseLetter: 'A senha deve conter letra maiúscula',
        passwordMustContainALowercaseLetter: 'A senha deve conter uma letra minúscula',
        passwordMustContainANumber: 'A senha deve conter um número',
        passwordMustContainASpecialCharacter: 'A senha deve conter um caractere especial',
        passwordMustNotContainWhitespace: 'A senha não deve conter espaços em branco',
        passwordsDoNotMatch: 'As senhas não coincidem',
        valueMustBeAnInteger: 'O valor deve ser um número inteiro',
        valueMustBeAValidNumber: 'O valor deve ser um número válido',
        valueMustBePositive: 'O valor deve ser positivo',
        TEXT_TOO_SHORT: 'O texto é muito curto',
        WRONG_EMAIL: 'O formato do e-mail está incorreto',
        REQUIRED: 'Este campo é obrigatório',
        titleMustBeAString: 'O título deve ser uma string de texto',
        titleIsRequired: 'O título é obrigatório',
        titleMustBeAtLeast3CharactersLong: 'O título deve ter pelo menos 3 caracteres',
        titleMustBeAtMost100CharactersLong: 'O título deve ter no máximo 100 caracteres',
        codeMustBeAString: 'O código deve ser uma string de texto',
        codeIsRequired: 'O código é obrigatório',
        codeMustBeAtLeast3CharactersLong: 'O código deve ter pelo menos 3 caracteres',
        codeMustBeAtLeast10CharactersLong: 'O código deve ter pelo menos 10 caracteres',
        codeMustBeAtMost30CharactersLong: 'O código deve ter no máximo 30 caracteres',
        prefixIsRequired: 'O prefixo é obrigatório',
        prefixInvalidLength: 'O prefixo deve ter 2 ou 3 dígitos',
        prefixMustContainOnlyNumbers: 'O prefixo deve conter apenas números',
        prefixFirstCharacterMustBePlusSign: 'O prefixo deve começar com o sinal +',
        prefixMustBeAtLeast1CharactersLong: 'O prefixo tem pelo menos 1 dígito',
        prefixMustBeAtMost3CharactersLong: 'O prefixo tem no máximo 3 dígitos',
        countryCodeMustBeAString: 'O código do país deve ser uma string de texto',
        countryCodeIsRequired: 'O código do país é obrigatório',
        countryCodeMustBe2CharactersLong: 'O código do país deve ter 2 caracteres',
        countryCodeMustBeUppercase: 'O código do país deve estar em letras maiúsculas',
        phoneIsRequired: 'Telefone é obrigatório',
        phoneMustBeAString: 'O telefone deve ser uma string de texto',
        phoneMustContainOnlyNumbers: 'O telefone deve conter apenas números',
        phoneMustNotContainWhitespace: 'O telefone não deve conter espaços em branco',
        phoneMustBeAtLeast9CharactersLong: 'O número de telefone deve ter pelo menos 9 dígitos',
        phoneMustBeAtMost12CharactersLong: 'O número de telefone deve ter no máximo 12 dígitos',
        extensionIsRequired: 'A extensão é obrigatória',
        extensionMustContainOnlyNumbers: 'A extensão deve conter apenas números',
        extensionMustBeAtLeast4CharactersLong: 'O ramal deve ter pelo menos 4 dígitos',
        descriptionIsRequired: 'A descrição é obrigatória',
        descriptionMustBeAString: 'A descrição deve ser uma string de texto',
        descriptionMustBeAtMost500CharactersLong: 'A descrição deve ter no máximo 500 caracteres',
        observationIsRequired: 'Observação é necessária',
        observationMustBeAString: 'A observação deve ser uma string de texto',
        observationMustBeAtLeast1CharactersLong: 'A observação deve ter pelo menos 1 caractere',
        observationMustBeAtMost150CharactersLong: 'O comentário deve ter no máximo 150 caracteres',
        observationMustBeAtMost500CharactersLong: 'O comentário deve ter no máximo 500 caracteres',
        usernameIsRequired: 'O nome de usuário é obrigatório',
        usernameMustBeAString: 'O nome de usuário deve ser uma string de texto',
        usernameMustBeAtLeast3CharactersLong: 'O nome de usuário deve ter pelo menos 3 caracteres',
        usernameMustBeAtMost30CharactersLong: 'O nome de usuário deve ter no máximo 30 caracteres',
        usernameMustNotContainSpecialCharacters:
          'O nome de usuário não deve conter caracteres especiais',
        usernameMustNotContainWhitespace: 'O nome de usuário não deve conter espaços em branco',
        zipIsRequired: 'O CEP é obrigatório',
        zipMustBeAString: 'O CEP deve ser uma string de texto',
        zipMustBe5CharactersLong: 'O CEP deve ter 5 caracteres',
        zipMustContainOnlyNumbers: 'O CEP deve conter apenas números',
        zipMustNotContainWhitespace: 'O CEP não deve conter espaços em branco',
        zipMustNotContainSpecialCharacters: 'O CEP não deve conter caracteres especiais',
        streetIsRequired: 'A rua é necessária',
        streetMustBeAString: 'A rua deve ser uma string de texto',
        streetMustBeAtLeast8CharactersLong: 'A rua deve ter pelo menos 8 caracteres',
        cityIsRequired: 'A cidade é necessária',
        cityMustBeAString: 'A cidade deve ser uma string de texto',
        stateIsRequired: 'O estado é necessário',
        stateMustBeAString: 'O status deve ser uma string de texto',
        coinIsRequired: 'A moeda é obrigatória',
        coinMustBeAString: 'A moeda deve ser uma string de texto',
        flagIsRequired: 'A bandeira é obrigatória',
        flagMustBeAString: 'A bandeira deve ser uma string de texto',
        subjectIsRequired: 'O assunto é necessário',
        subjectMustBeAString: 'O assunto deve ser uma string de texto',
        subjectMustBeAtLeast3CharactersLong: 'O assunto deve ter pelo menos 3 caracteres',
        subjectMustBeAtMost30CharactersLong: 'O assunto deve ter no máximo 30 caracteres',
        messageIsRequired: 'A mensagem é obrigatória',
        messageMustBeAString: 'A mensagem deve ser uma string de texto',
        messageMustBeAtLeast3CharactersLong: 'A mensagem deve ter pelo menos 3 caracteres',
        messageMustBeAtMost500CharactersLong: 'A mensagem deve ter no máximo 500 caracteres',
        hourIsRequired: 'O tempo é necessário',
        hourMustBeValidFormat: 'A hora deve estar em um formato válido: HH:MM',
        documentIsRequired: 'O documento é obrigatório',
        documentMustBeAString: 'O documento deve ser uma string de texto',
        documentMustBeAtLeast8CharactersLong: 'O documento deve ter pelo menos 8 caracteres',
        codeMustBeAtLeast2CharactersLong: 'O código deve ter pelo menos 2 caracteres',
        required: 'Este campo é obrigatório',
        name: {
          notString: 'O nome deve ser uma string de texto',
          tooShort: 'O nome é muito curto',
          tooLong: 'O nome é muito longo',
          invalidCharacters: 'O nome contém caracteres inválidos',
        },
        email: {
          notValid: 'O e-mail não é válido',
          notString: 'O e-mail deve ser uma string de texto',
        },
        phone: {
          invalid: 'O telefone é inválido',
          notString: 'O telefone deve ser uma string de texto',
        },
        surname: {
          notString: 'O sobrenome deve ser uma string de texto',
          tooShort: 'O sobrenome é muito curto',
          tooLong: 'O sobrenome é muito longo',
          invalidCharacters: 'O sobrenome contém caracteres inválidos',
        },
        boolean: {
          notBoolean: 'O valor deve ser um booleano',
          truthy: {
            notTruthy: 'O valor deve ser verdadeiro',
          },
        },
        password: {
          notString: 'A senha deve ser uma string de texto',
          tooShort: 'A senha é muito curta',
          tooLong: 'A senha é muito longa',
          invalidCharacters: 'A senha contém caracteres inválidos',
          noUpperCase: 'A senha deve conter pelo menos uma letra maiúscula',
          noLowerCase: 'A senha deve conter pelo menos uma letra minúscula',
          noNumber: 'A senha deve conter pelo menos um número',
          noSpecialCharacter: 'A senha deve conter pelo menos um caractere especial',
        },
        number: {
          notNumber: 'O valor deve ser um número',
        },
        positive: {
          notPositive: 'O valor deve ser positivo',
          notNumber: 'O valor deve ser um número',
        },
        price: {
          notNumber: 'O valor deve ser um número',
          notPositive: 'O valor deve ser positivo',
          notString: 'O valor deve ser uma string de texto',
        },
        negative: {
          notNegative: 'O valor deve ser negativo',
          notNumber: 'O valor deve ser um número',
        },
        uuid: {
          notUuid: 'O valor deve ser um UUID',
          notString: 'O valor deve ser uma string de texto',
          empty: 'O valor não pode ficar vazio',
        },
        object: {
          notValid: 'O valor deve ser um objeto',
        },
        description: {
          notString: 'A descrição deve ser uma string de texto',
          tooShort: 'A descrição é muito curta',
          tooLong: 'A descrição é muito longa',
        },
        observation: {
          notString: 'A observação deve ser uma string de texto',
          tooShort: 'A observação é muito curta',
          tooLong: 'A observação é muito longa',
        },
        date: {
          notValid: 'A data não é válida',
          notString: 'A data deve ser uma string de texto',
        },
        color: {
          notString: 'A cor deve ser uma string de texto',
          notHex: 'A cor deve ser um código hexadecimal',
        },
        code: {
          notString: 'O código deve ser uma string de texto',
          tooShort: 'O código é muito curto',
          tooLong: 'O código é muito longo',
        },
        image: {
          notString: 'A imagem deve ser uma string de texto',
          notBase64: 'A imagem deve ser um código base64',
        },
        phonePrefix: {
          notString: 'O prefixo deve ser uma string de texto',
          notValid: 'O prefixo não é válido',
        },
        countryCode: {
          notString: 'O código do país deve ser uma string de texto',
          notValid: 'O código do país é inválido',
        },
        zip: {
          notString: 'O CEP deve ser uma string de texto',
          notLength: 'O CEP deve ter 5 caracteres',
          notNumber: 'O CEP deve conter apenas números',
          hasSpace: 'O CEP não deve conter espaços em branco',
          hasLetter: 'O CEP não deve conter letras',
          hasSymbol: 'O CEP não deve conter símbolos',
        },
        title: {
          notString: 'O título deve ser uma string de texto',
          tooShort: 'O título é muito curto',
          tooLong: 'O título é muito longo',
        },
        username: {
          notString: 'O nome de usuário deve ser uma string de texto',
          tooShort: 'O nome de usuário é muito curto',
          tooLong: 'O nome de usuário é muito longo',
          invalidCharacters: 'O nome de usuário contém caracteres inválidos',
        },
        ip: {
          notString: 'O endereço IP deve ser uma string de texto',
          notValid: 'O endereço IP é inválido',
        },
        state: {
          notString: 'O status deve ser uma string de texto',
        },
        city: {
          notString: 'A cidade deve ser uma string de texto',
        },
        street: {
          notString: 'A rua deve ser uma string de texto',
        },
        coin: {
          notString: 'A moeda deve ser uma string de texto',
        },
        flag: {
          notString: 'A bandeira deve ser uma string de texto',
        },
      },
    },
  },
  analytics: {
    title: 'Análise',
    filter: 'Comparação entre a semana passada e esta semana',
    activities: {
      title: 'Tarefas',
    },
    leads: {
      title: 'Pistas',
    },
    results: {
      title: 'Resultados',
    },
  },
  history: {
    title: 'Registro',
  },
  performance: {
    title: 'Desempenho',
  },
  sessions: {
    title: 'Sessões ativas',
  },
  security: {
    title: 'Sistema Seguro',
  },
  launch: {
    title: 'O futuro da reprodução assistida',
    subtitle: 'Rastreabilidade e segurança para sua clínica',
    description: {
      ovoclinic:
        'Software seguro de propriedade da Ovoclinic para gestão de clínicas de reprodução assistida. ',
      ovobank: 'Software seguro de propriedade do Ovobank para gerenciamento de bancos de ovos. ',
    },
    crm: {
      description: 'Espaço para gestão de clientes e melhoria do relacionamento com eles. ',
    },
    clinic: {
      description:
        'Tudo o que você precisa para gerenciar sua clínica, hospital ou centro médico. ',
    },
    access: {
      description: 'Controle de acesso e gerenciamento de usuários. ',
    },
    board: {
      description: 'Quadro de controle e monitoramento de indicadores. ',
    },
    tory: {
      description: 'Resultados clínicos e rastreabilidade de exames laboratoriais. ',
    },
    profile: {
      description: 'Gerenciamento de informações pessoais e de conta. ',
    },
  },
  access: {
    menu: {
      users: 'Usuários',
      roles: 'Funções',
      specialists: 'Especialistas',
      clinics: 'Clínicas',
      planning: 'Planejamento',
      timetables: 'Horários',
      bulletins: 'Notícias',
      departments: 'Departamentos',
      languages: 'Idiomas',
      countries: 'Países',
      settings: 'Configuração',
      directory: 'Diretório',
      locations: 'Locais',
      globalization: 'Globalização',
      communications: 'Comunicações',
      communicationChannels: 'Rotas de comunicação',
      ovopoints: 'Ovopontos',
      landbot: 'Robô terrestre',
      templates: 'Modelos',
    },
    users: {
      list: {
        chart: {
          user: 'Usuários',
          movement: 'Movimentos',
        },
        month: {
          '0': 'Janeiro',
          '1': 'Fevereiro',
          '2': 'Marchar',
          '3': 'abril',
          '4': 'Poderia',
          '5': 'Junho',
          '6': 'Julho',
          '7': 'Agosto',
          '8': 'Setembro',
          '9': 'outubro',
          '10': 'novembro',
          '11': 'dezembro',
          '12': 'Total',
        },
        year: {
          '0': '2020',
          '1': '2021',
          '2': '2022',
          '3': '2023',
          '4': '2024',
          '5': '2025',
          '6': '2026',
          '7': '2027',
          '8': '2028',
          '9': '2029',
          '10': '2030',
          '11': '2031',
        },
        columns: {
          name: 'Nome',
          email: 'E-mail',
          phone: 'Telefone',
          status: 'Estado',
          createdAt: 'Criado',
          updatedAt: 'Atualizado',
          expiration: 'Expiração',
          created: 'Criação',
          lastMove: 'Última jogada',
        },
        title: 'Usuários',
        description: 'Gestão de pessoal e seu acesso ao sistema',
        create: 'Usuário',
        one: 'Usuário',
        many: 'Usuários',
        cloneConfirm: 'Tem certeza de que deseja clonar este usuário?',
        cloneSuccess: 'Usuário clonado com sucesso',
      },
      create: {
        title: 'Novo usuário',
        subtitle: 'Cadastro de um novo usuário no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de usuário',
          },
          surname: {
            title: 'Sobrenomes',
            description: 'Sobrenome do usuário',
          },
          password: {
            title: 'Senha',
            description:
              'A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número.',
          },
          username: {
            title: 'Nome de usuário',
            description: 'Nome de usuário para acesso ao sistema',
          },
          email: {
            title: 'E-mail',
            description: 'Endereço de e-mail onde o usuário receberá notificações do sistema',
          },
          phone: {
            title: 'Telefone',
            description: 'Número de contato do usuário',
          },
          extension: {
            title: 'Extensão',
            description: 'Extensão de identificação dentro do departamento',
          },
          signature: {
            title: 'Senha',
            description: 'Senha do usuário',
          },
          expiration: {
            title: 'Data de validade',
            description: 'Data em que a conta do usuário irá expirar',
          },
          color: {
            title: 'Cor',
            description: 'Cor do usuário no sistema',
          },
          icon: {
            title: 'Ícone',
            label: 'Selecione o ícone',
            description: 'Ícone de identificação do usuário no sistema',
            search: {
              placeholder: 'Ícone de pesquisa',
            },
          },
          status: {
            title: 'Acesso ao sistema',
            description: 'Habilitar ou desabilitar o acesso ao sistema para este usuário',
            label: 'Permitir acesso ao sistema',
          },
          movementLimit: {
            title: 'Limite de movimento',
            description: 'Número de solicitações permitidas para este usuário',
          },
          attemptLimit: {
            title: 'Limite de tentativas',
            description: 'Número de tentativas permitidas de login',
          },
          submit: 'Criar usuário',
        },
      },
      delete: {
        title: 'Desativar usuário',
        subtitle: 'Esta ação é reversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este usuário?',
            description: 'Desabilitar um usuário significa que ele não poderá acessar o sistema. ',
            label: 'Sim, quero desativar este usuário',
          },
          submit: 'Desativar',
        },
        passwordForm: {
          title: 'Digite sua senha',
          subtitle: 'Para desabilitar o usuário, digite sua senha',
          password: {
            title: 'Senha',
            description: 'Senha do usuário que realiza a ação',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar usuário',
        subtitle: 'Esta ação permite que o usuário acesse o sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este usuário?',
            description: 'Habilitar um usuário significa que ele poderá acessar o sistema. ',
            label: 'Sim, quero ativar este usuário',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar usuário',
        subtitle: 'Esta ação impede que o usuário acesse o sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativá-lo?',
            description: 'Desabilitar um usuário significa que ele não poderá acessar o sistema. ',
            label: 'Sim, quero desabilitar este usuário',
          },
          submit: 'Desativar',
        },
      },
      update: {
        access: 'Acesso',
        account: {
          tab: 'Conta',
          description: 'Informações da conta do usuário',
        },
        tabs: {
          account: {
            title: 'Conta',
            description: 'Dados da conta pessoal do usuário com acesso ao sistema.',
          },
          licenses: {
            title: 'Licenças',
            description:
              'Uma licença determina as ações que um usuário pode realizar e o acesso que ele tem no sistema. ',
          },
          contracts: {
            title: 'Contratos',
            description: 'Um contrato é um acordo entre o usuário e a clínica. ',
          },
          movements: {
            title: 'Tráfego',
            description: 'O sistema monitora os movimentos dos usuários. ',
          },
          attempts: {
            title: 'Tentativas',
            description:
              'Para salvaguardar a segurança do sistema, as tentativas de acesso ao sistema são monitoradas.',
          },
          languages: {
            title: 'Idiomas',
            description: 'Idiomas falados pelo usuário',
          },
          timetables: {
            title: 'Horários',
            description:
              'Agendamentos são os intervalos de tempo em que o usuário tem acesso ao sistema.',
          },
          changePassword: {
            title: 'Senha',
            description: 'Alterar senha do usuário',
          },
          activity: {
            title: 'Atividade',
            description:
              'Caso seja definida uma atividade, quando o responsável pelo Lead for atribuído a este usuário, será criada automaticamente uma tarefa com a atividade que foi definida.',
          },
          integrations: {
            title: 'Integrações',
            description:
              'Configuração ao nível do usuário para poder realizar as diferentes integrações implementadas no CRM. ',
          },
        },
        schedules: {
          create: {
            title: 'Adicionar programação',
            subtitle: 'Adicionar agendamento ao usuário',
            form: {
              timetableId: {
                title: 'Selecione um horário',
                description: 'Modelo de horário em que o usuário tem acesso ao sistema',
              },
              submit: 'Adicionar programação',
            },
          },
          delete: {
            title: 'Desvincular programação',
            subtitle: 'Desvincular agendamento do usuário',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                label: 'Sim, quero desvincular esta programação do usuário',
              },
              submit: 'Desvincular programação',
            },
          },
        },
        languages: {
          add: 'Adicionar idioma',
          create: {
            title: 'Adicionar idioma',
            subtitle: 'Adicionar idioma falado pelo usuário',
            form: {
              languageId: {
                title: 'Linguagem',
                description: 'Idioma falado pelo usuário',
              },
              submit: 'Adicionar',
            },
          },
          delete: {
            title: 'Excluir idioma',
            subtitle: 'Excluir o idioma deste usuário',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                description: 'Esta ação é irreversível.',
                label: 'Sim, quero remover este idioma',
              },
              submit: 'Confirmar',
            },
          },
        },
        roles: 'Funções',
        title: 'Atualizar usuário',
        subtitle: 'Modificação dos dados de um usuário no sistema',
        licenses: {
          tab: 'Licenças',
          description:
            'As licenças definem as ações que os usuários podem realizar em nível de grupo, ou seja, são permissões que não envolvem as clínicas.',
          add: 'Adicionar',
          create: {
            title: 'Nova licença',
            subtitle: 'Cadastro de uma nova licença no sistema.',
            form: {
              roleId: {
                title: 'Papel',
                description: 'A função é o conjunto de permissões que o usuário terá no sistema',
              },
              submit: 'Criar licença',
            },
          },
          delete: {
            title: 'Excluir licença',
            subtitle: 'Esta ação é irreversível',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                description: 'Você pode preferir desativar esta licença',
                label: 'Sim, quero excluir esta licença',
              },
              submit: 'Excluir licença',
            },
          },
        },
        permissions: {
          add: 'Adicionar licença',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Robô terrestre',
              description: 'Você é um usuário LandBot',
              label: 'Sim, você é um usuário LandBot',
            },
            submit: 'Atualizar integrações',
          },
        },
        contracts: {
          tab: 'Contratos',
          description: 'Contratos são acordos entre o usuário e a clínica. ',
          add: 'Adicionar contrato',
          addAll: 'Adicione todos os contratos',
          create: {
            title: 'Novo contrato',
            subtitle: 'Cadastro de novo contrato no sistema.',
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Criar contrato',
            },
          },
          disable: {
            title: 'Desativar contrato',
            subtitle: 'Esta ação impede que o usuário acesse a clínica',
            form: {
              status: {
                title: 'Tem certeza de que deseja desativá-lo?',
              },
              submit: 'Desativar contrato',
            },
          },
          enable: {
            title: 'Ativar contrato',
            subtitle: 'Esta ação permite ao usuário acessar a clínica',
            form: {
              status: {
                title: 'Tem certeza de que deseja ativá-lo?',
              },
              submit: 'Ativar contrato',
            },
          },
        },
        timetables: {
          add: 'Adicionar programação',
          create: {
            title: 'Nova programação',
            subtitle: 'Cadastrar novo agendamento para o usuário',
            form: {
              day: {
                title: 'Dia',
                description: 'Dia da semana em que o usuário tem acesso ao sistema',
              },
              started: {
                title: 'Começar',
                description: 'Hora de início em que o usuário tem acesso ao sistema',
              },
              ended: {
                title: 'Fim',
                description: 'Hora de término em que o usuário tem acesso ao sistema',
              },
              submit: 'Criar cronograma',
            },
          },
          delete: {
            title: 'Excluir programação',
            subtitle: 'Excluir agendamento do usuário',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                label: 'Sim, quero excluir esta programação',
              },
              submit: 'Excluir programação',
            },
          },
        },
        movements: {
          tab: 'Movimentos',
          description: 'Os movimentos são as solicitações que o usuário faz ao sistema. ',
        },
        attempts: {
          tab: 'Tentativas',
          description: 'As tentativas são acessos ao login do usuário. ',
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de usuário',
          },
          surname: {
            title: 'Sobrenomes',
            description: 'Sobrenome do usuário',
          },
          password: {
            title: 'Senha',
            description:
              'A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número.',
          },
          username: {
            title: 'Nome de usuário',
            description: 'Nome de usuário para acesso ao sistema',
          },
          email: {
            title: 'E-mail',
            description: 'E-mail do usuário para efetuar login no sistema',
          },
          phone: {
            title: 'Telefone',
            description: 'Número de contato do usuário',
          },
          extension: {
            title: 'Extensão',
            description: 'Extensão de identificação dentro do departamento',
          },
          signature: {
            title: 'Senha',
            description: 'Senha do usuário',
          },
          expiration: {
            title: 'Data de validade',
            description: 'Data em que a conta do usuário irá expirar',
          },
          color: {
            title: 'Cor',
            description: 'Cor do usuário no sistema',
          },
          status: {
            title: 'Acesso ao sistema',
            description: 'Habilitar ou desabilitar o acesso ao sistema para este usuário',
            label: 'Permitir acesso ao sistema',
          },
          movementLimit: {
            title: 'Limite de movimento',
            description: 'Número de solicitações permitidas para este usuário durante 24 horas',
          },
          attemptLimit: {
            title: 'Limite de tentativas',
            description: 'Número de tentativas permitidas de login durante 24 horas',
          },
          submit: 'Atualizar usuário',
        },
        activity: {
          form: {
            activityId: {
              title: 'Atividade',
            },
            submit: 'Atualizar atividade',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Atualizar ovopontos',
        subtitle: 'Modificando os ovopoints de um usuário no sistema',
        form: {
          appointedPoints: {
            title: 'Lead citado',
            description: 'Ovopoints para agendamento de consulta',
          },
          attendedPoints: {
            title: 'O líder compareceu à consulta médica',
            description: 'Ovopontos para comparecimento a consulta médica',
          },
          commercialAttendedPoints: {
            title: 'O lead teve um encontro de vendas',
            description: 'Ovopoints para comparecer a um encontro de negócios',
          },
          informedPoints: {
            title: 'Lead informado',
            description: 'Ovopoints para informar um lead',
          },
          contactedPoints: {
            title: 'Lead contatado',
            description: 'Ovopoints para entrar em contato com um lead',
          },
          convertedPoints: {
            title: 'Converter lead',
            description: {
              ovoclinic: 'Ovopoints para converter um lead em um paciente',
              ovobank: 'Ovopoints para converter um lead em um doador',
            },
          },
          submit: 'Atualizar ovopontos',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Rota de comunicação',
        title: 'Rotas de comunicação',
        description: 'Gestão de canais de comunicação',
        one: 'Rota de comunicação',
        many: 'Rotas de comunicação',
        columns: {
          name: 'Nome',
          created: 'Data de criação',
        },
      },
      create: {
        title: 'Nova rota de comunicação',
        subtitle: 'Cadastro de novo canal de comunicação no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do caminho de comunicação',
          },
          submit: 'Criar canal de comunicação',
        },
      },
      update: {
        title: 'Atualizar caminho de comunicação',
        subtitle: 'Modificação dos dados de uma via de comunicação no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do caminho de comunicação',
          },
          submit: 'Atualizar caminho de comunicação',
        },
      },
      enable: {
        title: 'Habilitar caminho de comunicação',
        subtitle: 'Habilitar caminho de comunicação no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este canal de comunicação?',
            label: 'Sim, quero ativar este canal de comunicação',
          },
          submit: 'Habilitar caminho de comunicação',
        },
      },
      disable: {
        title: 'Desabilitar caminho de comunicação',
        subtitle: 'Desabilitar caminho de comunicação no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este caminho de comunicação?',
            label: 'Sim, quero desativar este canal de comunicação',
          },
          submit: 'Desabilitar caminho de comunicação',
        },
      },
    },
    specialists: {
      list: {
        create: 'Especialista',
        title: 'Especialista',
        description: 'Gestão especializada',
        one: 'Especialista',
        many: 'Especialistas',
        columns: {
          name: 'Nome',
          surname: 'Sobrenomes',
          dni: 'EU IA',
          type: 'Cara',
        },
      },
      create: {
        title: 'Novo especialista',
        subtitle: 'Cadastro de novo especialista no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do especialista',
          },
          surname: {
            title: 'Sobrenomes',
            description: 'Sobrenome do especialista',
          },
          dni: {
            title: 'EU IA',
            description: 'Documento de identidade especializado',
          },
          type: {
            title: 'Cara',
            description: 'Tipo de especialista',
          },
          submit: 'Criar especialista',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Especialista',
            description: 'Dados pessoais do especialista',
          },
          contracts: {
            title: 'Contratos',
            description: 'Contratos especializados',
          },
        },
        contracts: {
          add: 'Adicionar contrato',
          list: {
            one: 'Contrato',
            many: 'Contratos',
          },
          create: {
            title: 'Novo contrato',
            subtitle: 'Cadastro de novo contrato especializado no sistema',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a que pertence o contrato',
              },
              submit: 'Criar contrato',
            },
          },
          delete: {
            title: 'Excluir contrato',
            subtitle: 'Excluir contrato deste especialista',
            form: {
              submit: 'Excluir contrato',
            },
          },
        },
        title: 'Especialista em atualização',
        subtitle: 'Modificação dos dados de um especialista no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do especialista',
          },
          surname: {
            title: 'Sobrenomes',
            description: 'Sobrenome do especialista',
          },
          dni: {
            title: 'EU IA',
            description: 'Documento de identidade especializado',
          },
          type: {
            title: 'Cara',
            description: 'Tipo de especialista',
          },
          submit: 'Especialista em atualização',
        },
      },
      enable: {
        title: 'Habilitar especialista',
        subtitle: 'Esta ação permite ao especialista do sistema',
        form: {
          submit: 'Habilitar especialista',
        },
      },
      disable: {
        title: 'Desativar especialista',
        subtitle: 'Esta ação desabilita o especialista do sistema',
        form: {
          submit: 'Desativar especialista',
        },
      },
    },
    templates: {
      list: {
        title: 'Modelos',
        description: 'Gerenciamento de modelos para Landbot',
        create: 'Modelo',
        columns: {
          label: 'Nome',
        },
        one: 'Modelo',
        many: 'Modelos',
      },
      create: {
        title: 'Novo modelo',
        subtitle: 'Registrando um novo modelo para Landbot',
        form: {
          label: {
            title: 'Nome',
            description: 'Nome do modelo',
          },
          landbotId: {
            title: 'ID do Landbot',
            description: 'ID do Landbot ao qual o modelo está associado',
          },
          submit: 'Criar modelo',
        },
      },
      update: {
        title: 'Atualizar modelo',
        subtitle: 'Modificando dados de modelo para Landbot',
        tabs: {
          template: {
            title: 'Modelo',
            description: 'Detalhes do modelo',
          },
          templateScope: {
            title: 'Escopo',
            description: 'Escopo do modelo',
          },
          templateParam: {
            title: 'Parâmetros',
            description: 'Parâmetros do modelo',
          },
        },
        form: {
          label: {
            title: 'Nome',
            description: 'Nome do modelo',
          },
          landbotId: {
            title: 'ID do Landbot',
            description: 'ID do Landbot ao qual o modelo está associado',
          },
          id: {
            title: 'Modelo',
            description: 'Modelo para usar no Landbot para enviar a mensagem',
          },
          submit: 'Atualizar modelo',
        },
      },
      disable: {
        title: 'Desativar modelo',
        subtitle: 'Desativar modelo para Landbot',
        form: {
          submit: 'Desativar modelo',
        },
      },
      enable: {
        title: 'Habilitar modelo',
        subtitle: 'Habilitar modelo para Landbot',
        form: {
          submit: 'Habilitar modelo',
        },
      },
      delete: {
        title: 'Excluir modelo',
        subtitle: 'Excluir modelo Landbot',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            label: 'Sim, quero excluir este modelo',
          },
          submit: 'Excluir modelo',
        },
      },
      send: {
        title: 'Enviar modelo',
        subtitle: 'Enviar modelo para contato',
        form: {
          id: {
            title: 'Modelo',
            description: 'Modelo para usar no Landbot para enviar a mensagem',
          },
          landbotId: {
            title: 'ID do Landbot',
            description: 'ID do bot Landbot ao qual o modelo está associado',
          },
          submit: 'Enviar modelo',
        },
      },
      templateScopes: {
        list: {
          title: 'Escopos',
          description: 'Gerenciamento de escopos de modelos',
          create: 'Escopo',
          columns: {
            label: 'Nome',
          },
          one: 'Escopo',
          many: 'Escopos',
        },
        create: {
          title: 'Novo escopo',
          subtitle: 'Registrando um novo escopo para modelos',
          form: {
            label: {
              title: 'Nome',
              description: 'Nome do escopo',
            },
            departmentId: {
              title: 'Departamento',
              description: 'Departamento ao qual o modelo terá escopo',
            },
            submit: 'Criar escopo',
          },
        },
        update: {
          title: 'Atualizar escopo',
          subtitle: 'Modificando dados em um escopo de modelo',
          form: {
            label: {
              title: 'Nome',
              description: 'Nome do escopo',
            },
            submit: 'Atualizar escopo',
          },
        },
        delete: {
          title: 'Excluir escopo',
          subtitle: 'Remover escopo deste modelo',
          form: {
            status: {
              title: 'Tem certeza de que deseja excluí-lo?',
              label: 'Sim, quero excluir este escopo',
            },
            submit: 'Excluir escopo',
          },
        },
        add: 'Adicionar escopo',
      },
      templateParams: {
        list: {
          title: 'Parâmetros',
          description: 'Gerenciamento de parâmetros de modelo',
          create: 'Parâmetro',
          columns: {
            label: 'Nome',
            order: 'Ordem',
            templateKeyName: 'Parâmetro',
          },
          one: 'Parâmetro',
          many: 'Parâmetros',
        },
        create: {
          title: 'Novo parâmetro',
          subtitle: 'Registrando um novo parâmetro para modelos',
          form: {
            templateKeyId: {
              title: 'Parâmetro',
              description: 'Possíveis parâmetros para anexar ao modelo',
            },
            order: {
              title: 'Ordem',
              description: 'Ordem em que o parâmetro será exibido no modelo',
            },
            submit: 'Criar parâmetro',
          },
        },
        delete: {
          title: 'Excluir parâmetro',
          subtitle: 'Remover parâmetro deste modelo',
          form: {
            status: {
              title: 'Tem certeza de que deseja excluí-lo?',
              label: 'Sim, quero remover este parâmetro',
            },
            submit: 'Excluir parâmetro',
          },
        },
        add: 'Adicionar parâmetro',
      },
    },
    languages: {
      list: {
        create: 'Linguagem',
        title: 'Linguagem',
        description: 'Gerenciamento de idiomas',
        one: 'Linguagem',
        many: 'Idiomas',
        columns: {
          name: 'Nome',
          country: 'País',
          code: 'Código',
        },
      },
      create: {
        title: 'Novo idioma',
        subtitle: 'Cadastrando um novo idioma no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do idioma',
          },
          status: {
            title: 'Estado',
            label: 'Estado',
          },
          countryCode: {
            title: 'País',
            description: 'País ao qual o idioma pertence',
          },
          code: {
            title: 'Código',
            description: 'Código do idioma',
          },
          submit: 'Criar idioma',
        },
      },
      update: {
        title: 'Atualizar idioma',
        subtitle: 'Modificando dados de idioma no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome do idioma',
          },
          countryCode: {
            title: 'País',
            description: 'País ao qual o idioma pertence',
          },
          code: {
            title: 'Código',
            description: 'Código do idioma',
          },
          submit: 'Atualizar idioma',
        },
      },
      enable: {
        title: 'Ativar idioma',
        subtitle: 'Habilitar idioma no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este idioma?',
            label: 'Sim, quero ativar este idioma',
          },
          submit: 'Ativar idioma',
        },
      },
      disable: {
        title: 'Desativar idioma',
        subtitle: 'Desativar idioma no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este idioma?',
            label: 'Sim, quero desativar este idioma',
          },
          submit: 'Desativar idioma',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'Temos boas notícias',
      },
      create: {
        title: 'Novas notícias',
        subtitle: 'Cadastre novas novidades na clínica',
        steps: {
          clinic: {
            form: {
              title: 'Clínica',
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a que pertence a notícia',
              },
            },
          },
          actor: {
            form: {
              title: 'Companheiro',
              actorId: {
                title: 'Companheiro',
                description: 'Parceiro a notícia é sobre',
              },
            },
          },
        },
        form: {
          description: {
            title: 'Descrição',
            description:
              'Para comemorar conquistas dentro da clínica, você pode compartilhar informações como: cases de sucesso, reconhecimento pessoal e gratidão.',
          },
          image: {
            title: 'Imagem',
            description: 'Imagem de notícias',
          },
          submit: 'Criar notícias',
        },
      },
      update: {
        title: 'Detalhes das notícias',
        subtitle: 'Detalhes de notícias da clínica',
        form: {
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a que pertence a notícia',
          },
          authorName: {
            title: 'Autor',
            description: 'Autor da notícia',
          },
          actorName: {
            title: 'Companheiro',
            description: 'Parceiro a notícia é sobre',
          },
          description: {
            title: 'Descrição',
            description:
              'Para comemorar conquistas dentro da clínica, você pode compartilhar informações como: cases de sucesso, reconhecimento pessoal e gratidão.',
          },
          image: {
            title: 'Imagem',
            description: 'Imagem de notícias',
          },
          submit: 'Atualizar notícias',
        },
      },
      list: {
        title: 'Notícias',
        description: 'Notícias da clínica',
        create: 'Notícias',
        one: 'Notícias',
        many: 'Notícias',
        columns: {
          clinicName: 'Clínica',
          actorName: 'Companheiro',
          authorName: 'Autor',
          created: 'Criação',
        },
      },
      delete: {
        title: 'Desativar notícias',
        subtitle: 'Desativar notícias da clínica',
        button: 'Desativar',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta notícia?',
            label: 'Sim, quero desativar esta notícia',
          },
          submit: 'Desativar',
        },
        passwordForm: {
          title: 'Digite sua senha',
          subtitle: 'Para desativar as notícias, digite sua senha',
          password: {
            title: 'Senha',
            description: 'Senha do usuário que realiza a ação',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar notícias',
        subtitle: 'Ativar notícias clínicas',
        button: 'Habilitar',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta notícia?',
            label: 'Sim, quero ativar esta notícia',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar notícias',
        subtitle: 'Desativar notícias da clínica',
        button: 'Desativar',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta notícia?',
            label: 'Sim, quero desativar esta notícia',
          },
          submit: 'Desativar',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Papel',
          created: 'Criação',
        },
        title: 'Funções',
        description: 'Gerenciamento de funções e suas permissões',
        create: 'Papel',
        one: 'Papel',
        many: 'Funções',
      },
      create: {
        title: 'Nova função',
        subtitle: 'Cadastrando uma nova função no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação da função',
          },
          general: {
            accordion: 'Permissões gerais',
            title: 'Permissões gerais',
            description: 'Permissões gerais para a função',
          },
          viewDisabledRecords: {
            title: 'Ver registros desativados',
            description: 'Esta opção permite a exibição de logs desabilitados',
            label: 'Habilitar exibição de logs desabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads desativados',
            description: 'Esta opção permite a exibição de leads desabilitados',
            label: 'Habilitar exibição de leads desativados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes com deficiência',
              ovobank: 'Ver doadores deficientes',
            },
            description: {
              ovoclinic: 'Esta opção permite a exibição de pacientes com deficiência',
              ovobank: 'Esta opção permite a exibição de doadores desabilitados',
            },
            label: {
              ovoclinic: 'Habilitar exibição de pacientes com deficiência',
              ovobank: 'Habilitar exibição de doadores desativados',
            },
          },
          access: {
            accordion: 'Acesso OVO',
            title: 'Permissão para o módulo OVOAccess',
            description: 'Esta opção permite o acesso ao módulo OVOAccess',
            label: 'Habilitar acesso',
          },
          user: {
            accordion: 'Usuários',
          },
          accessUser: {
            title: 'Ver usuários',
            description: 'Esta opção permite a exibição de usuários no sistema',
            label: 'Habilitar exibição do usuário',
          },
          accessUserCreator: {
            title: 'Criar usuários',
            description: 'Esta opção permite a criação de usuários no sistema',
            label: 'Habilitar criação de usuário',
          },
          accessUserEditor: {
            title: 'Editar usuários',
            description: 'Esta opção permite a edição de usuários no sistema',
            label: 'Habilitar edição do usuário',
          },
          accessUserDestructor: {
            title: 'Excluir usuários',
            description: 'Esta opção permite a exclusão de usuários do sistema',
            label: 'Habilitar exclusão de usuário',
          },
          accessUserChangePassword: {
            title: 'Alterar a senha',
            description: 'Esta opção permite alterar a senha dos usuários do sistema',
            label: 'Habilitar alteração de senha',
          },
          role: {
            accordion: 'Funções',
          },
          accessRole: {
            title: 'Ver funções',
            description: 'Esta opção permite a exibição de funções no sistema',
            label: 'Habilitar exibição de função',
          },
          accessRoleCreator: {
            title: 'Criar funções',
            description: 'Esta opção permite a criação de funções no sistema',
            label: 'Habilitar criação de função',
          },
          accessRoleEditor: {
            title: 'Editar funções',
            description: 'Esta opção permite a edição de funções no sistema',
            label: 'Habilitar edição de função',
          },
          accessRoleDestructor: {
            title: 'Excluir funções',
            description: 'Esta opção permite a exclusão de funções no sistema',
            label: 'Habilitar exclusão de função',
          },
          timetable: {
            accordion: 'Horários',
          },
          accessTimetable: {
            title: 'Ver horários',
            description: 'Esta opção possibilita a exibição de horários no sistema',
            label: 'Ativar exibição de agendamento',
          },
          accessTimetableCreator: {
            title: 'Crie programações',
            description: 'Esta opção possibilita a criação de agendamentos no sistema',
            label: 'Habilitar criação de agendamento',
          },
          accessTimetableEditor: {
            title: 'Editar programações',
            description: 'Esta opção possibilita a edição de horários no sistema',
            label: 'Habilitar edição de agendamento',
          },
          accessTimetableDestructor: {
            title: 'Excluir programações',
            description: 'Esta opção possibilita a exclusão de agendamentos no sistema',
            label: 'Ativar exclusão agendada',
          },
          bulletin: {
            accordion: 'Notícias',
          },
          accessBulletin: {
            title: 'Ver notícias',
            description: 'Esta opção possibilita a exibição de notícias no sistema',
            label: 'Ativar exibição de notícias',
          },
          accessBulletinCreator: {
            title: 'Criar notícias',
            description: 'Esta opção possibilita a criação de notícias no sistema',
            label: 'Habilitar criação de notícias',
          },
          accessBulletinEditor: {
            title: 'Editar notícias',
            description: 'Esta opção permite a edição de notícias no sistema',
            label: 'Habilitar edição de notícias',
          },
          accessBulletinDestructor: {
            title: 'Excluir notícias',
            description: 'Esta opção possibilita a exclusão de notícias do sistema',
            label: 'Habilitar exclusão de notícias',
          },
          accessClinic: {
            title: 'Veja clínicas',
            description: 'Esta opção possibilita a visualização das clínicas no sistema',
            label: 'Ativar visualização clínica',
          },
          accessClinicCreator: {
            title: 'Crie clínicas',
            description: 'Esta opção possibilita a criação de clínicas no sistema',
            label: 'Habilitar criação de clínica',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opção permite a edição de clínicas no sistema',
            label: 'Ativar edição clínica',
          },
          accessClinicDestructor: {
            title: 'Excluir clínicas',
            description: 'Esta opção possibilita a exclusão de clínicas do sistema',
            label: 'Ativar exclusão de clínica',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opção permite a visualização dos departamentos no sistema',
            label: 'Habilitar exibição de departamento',
          },
          accessDepartmentCreator: {
            title: 'Criar departamentos',
            description: 'Esta opção permite a criação de departamentos no sistema',
            label: 'Habilitar criação de departamento',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opção permite a edição de departamentos no sistema',
            label: 'Habilitar edição de departamento',
          },
          accessDepartmentDestructor: {
            title: 'Excluir departamentos',
            description: 'Esta opção permite a exclusão de departamentos do sistema',
            label: 'Habilitar exclusão de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opção possibilita a exibição de idiomas no sistema',
            label: 'Ativar exibição de idioma',
          },
          accessLanguageCreator: {
            title: 'Criar idiomas',
            description: 'Esta opção possibilita a criação de idiomas no sistema',
            label: 'Habilitar criação de idioma',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opção permite a edição de idiomas no sistema',
            label: 'Ativar edição de idioma',
          },
          accessLanguageDestructor: {
            title: 'Excluir idiomas',
            description: 'Esta opção permite a remoção de idiomas do sistema',
            label: 'Ativar remoção de idioma',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opção permite a exibição de países no sistema',
            label: 'Ativar exibição de país',
          },
          accessCountryCreator: {
            title: 'Criar países',
            description: 'Esta opção permite a criação de países no sistema',
            label: 'Habilitar criação de país',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opção permite a edição de países no sistema',
            label: 'Ativar edição de país',
          },
          accessCountryDestructor: {
            title: 'Excluir países',
            description: 'Esta opção permite a exclusão de países do sistema',
            label: 'Ativar exclusão de país',
          },
          accessSettings: {
            title: 'Ver configurações',
            description: 'Esta opção permite a exibição de configurações no sistema',
            label: 'Habilitar exibição de configuração',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Consulte especialistas',
            description: 'Esta opção permite a exibição de especialistas no sistema',
            label: 'Habilitar visualização de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crie especialistas',
            description: 'Esta opção possibilita a criação de especialistas no sistema',
            label: 'Permitir a criação de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opção possibilita a edição de especialistas no sistema',
            label: 'Habilitar edição especializada',
          },
          accessSpecialistDestructor: {
            title: 'Elimine especialistas',
            description: 'Esta opção permite a remoção de especialistas do sistema',
            label: 'Habilitar remoção especializada',
          },
          commsChannels: {
            accordion: 'Canais de comunicação',
          },
          accessCommsChannel: {
            title: 'Veja canais de comunicação',
            description:
              'Esta opção possibilita a visualização dos canais de comunicação do sistema',
            label: 'Habilite a visualização dos canais de comunicação',
          },
          accessCommsChannelCreator: {
            title: 'Crie canais de comunicação',
            description: 'Esta opção possibilita a criação de canais de comunicação no sistema',
            label: 'Permitir a criação de canais de comunicação',
          },
          accessCommsChannelEditor: {
            title: 'Editar canais de comunicação',
            description: 'Esta opção possibilita a edição dos canais de comunicação do sistema',
            label: 'Habilitar edição de canais de comunicação',
          },
          accessCommsChannelDestructor: {
            title: 'Elimine canais de comunicação',
            description: 'Esta opção permite a eliminação de canais de comunicação no sistema',
            label: 'Habilitar exclusão de canais de comunicação',
          },
          ovopoints: {
            accordion: 'Ovopontos',
          },
          accessOvopoint: {
            title: 'Ovopontos',
            description: 'Esta opção habilita a exibição de ovopontos no sistema',
            label: 'Habilitar exibição de ovopontos',
          },
          accessOvopointEditor: {
            title: 'Editar ovopontos',
            description: 'Esta opção possibilita a edição de ovopontos no sistema',
            label: 'Habilitar edição ovopoint',
          },
          templates: {
            accordion: 'Modelos',
          },
          accessTemplate: {
            title: 'Veja modelos do WhatsApp',
            description: 'Esta opção permite a exibição de modelos do WhatsApp',
            label: 'Habilitar exibição de modelo',
          },
          accessTemplateCreator: {
            title: 'Crie modelos do WhatsApp',
            description: 'Esta opção permite a criação de modelos de WhatsApp',
            label: 'Ativar modelos',
          },
          accessTemplateEditor: {
            title: 'Editar modelos do WhatsApp',
            description: 'Esta opção permite a edição de modelos do WhatsApp',
            label: 'Habilitar edição de modelo',
          },
          accessTemplateDestructor: {
            title: 'Excluir modelos do WhatsApp',
            description: 'Esta opção permite a exclusão de modelos do WhatsApp',
            label: 'Habilitar exclusão de modelo',
          },
          crm: {
            accordion: 'OVOCRM',
            title: 'Permissão do aplicativo',
            description: 'Esta opção permite o acesso ao módulo OVOCrm',
            label: 'Habilitar acesso',
          },
          tasks: {
            accordion: 'Tarefas',
          },
          crmCalendar: {
            title: 'Calendário',
            description: 'Esta opção permite a exibição do calendário no sistema',
            label: 'Ativar exibição de calendário',
          },
          crmTask: {
            title: 'Tarefas',
            description: 'Esta opção permite a exibição de tarefas no sistema',
            label: 'Habilitar exibição de tarefas',
          },
          crmTaskCreator: {
            title: 'Criar tarefas',
            description: 'Esta opção permite a criação de tarefas no sistema',
            label: 'Habilitar criação de tarefas',
          },
          crmTaskEditor: {
            title: 'Editar tarefas',
            description: 'Esta opção permite a edição de tarefas no sistema',
            label: 'Habilitar edição de tarefas',
          },
          crmTaskDestructor: {
            title: 'Excluir tarefas',
            description: 'Esta opção permite a exclusão de tarefas no sistema',
            label: 'Habilitar exclusão de tarefa',
          },
          crmTaskSeeAll: {
            title: 'Ver todas as tarefas',
            description: 'Esta opção permite a exibição de todas as tarefas do sistema',
            label: 'Habilite a exibição de todas as tarefas',
          },
          crmTaskSeeCoworkers: {
            title: 'Veja as tarefas dos colegas',
            description: 'Esta opção permite a exibição das tarefas dos colegas no sistema',
            label: 'Habilitar a visualização de tarefas de pares',
          },
          crmTaskUpdateResponsible: {
            title: 'Atualizar a pessoa atribuída a uma tarefa',
            description: 'Esta opção permite alterar o responsável por uma tarefa',
            label: 'Habilite a edição do gerenciador de tarefas',
          },
          leads: {
            accordion: 'Pistas',
          },
          crmLead: {
            title: 'Pistas',
            description: 'Esta opção possibilita a visualização de leads no sistema',
            label: 'Habilite a visualização de leads',
          },
          crmLeadCreator: {
            title: 'Criar leads',
            description: 'Esta opção permite a criação de leads no sistema',
            label: 'Habilitar criação de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opção permite a edição de leads no sistema',
            label: 'Ativar edição de leads',
          },
          crmLeadDestructor: {
            title: 'Excluir leads',
            description: 'Esta opção permite a exclusão de leads no sistema',
            label: 'Ativar exclusão de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos os leads',
            description: 'Esta opção permite a exibição de todos os leads do sistema',
            label: 'Habilitar exibição de todos os leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Veja leads de colegas',
            description: 'Esta opção permite a exibição de leads de colegas',
            label: 'Habilite a visualização dos leads dos colegas',
          },
          crmLeadUpdateCampaign: {
            title: 'Atualizar campanha atribuída a leads',
            description: 'Esta opção permite a alteração de campanha de múltiplos leads',
            label: 'Habilitar edição de campanha principal',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Doadores',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Doadores',
            },
            description: {
              ovoclinic: 'Esta opção possibilita a visualização dos pacientes no sistema',
              ovobank: 'Esta opção possibilita a visualização dos doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualização do paciente',
              ovobank: 'Habilitar exibição de doadores',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Criar pacientes',
              ovobank: 'Criar doadores',
            },
            description: {
              ovoclinic: 'Esta opção possibilita a criação de pacientes no sistema',
              ovobank: 'Esta opção possibilita a criação de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar criação de pacientes',
              ovobank: 'Habilitar criação de doadores',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a edição de pacientes no sistema',
              ovobank: 'Esta opção permite a edição de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar edição de pacientes',
              ovobank: 'Habilitar edição de doadores',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Excluir pacientes',
              ovobank: 'Excluir doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a exclusão de pacientes do sistema',
              ovobank: 'Esta opção possibilita a retirada de doadores do sistema',
            },
            label: {
              ovoclinic: 'Habilitar exclusão de paciente',
              ovobank: 'Habilitar remoção de doadores',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos os pacientes',
              ovobank: 'Ver todos os doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a visualização de todos os pacientes do sistema',
              ovobank: 'Esta opção permite a visualização de todos os doadores do sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualização de todos os pacientes',
              ovobank: 'Habilitar exibição de todos os doadores',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Veja pacientes de colegas',
              ovobank: 'Ver doadores pares',
            },
            description: {
              ovoclinic: 'Esta opção permite a visualização de pacientes pares',
              ovobank: 'Esta opção permite a exibição de doadores pares',
            },
            label: {
              ovoclinic: 'Habilite a visualização de pacientes de colegas',
              ovobank: 'Habilitar visualização de doadores pares',
            },
          },
          contacts: {
            accordion: 'Contatos',
          },
          crmContact: {
            title: 'Contatos',
            description: 'Esta opção permite a exibição de contatos no sistema',
            label: 'Ativar exibição de contato',
          },
          crmContactCreator: {
            title: 'Criar contatos',
            description: 'Esta opção possibilita a criação de contatos no sistema',
            label: 'Habilitar criação de contato',
          },
          crmContactEditor: {
            title: 'Editar contatos',
            description: 'Esta opção permite a edição de contatos no sistema',
            label: 'Ativar edição de contatos',
          },
          crmContactDestructor: {
            title: 'Excluir contatos',
            description: 'Esta opção permite a exclusão de contatos do sistema',
            label: 'Habilitar exclusão de contato',
          },
          activity: {
            accordion: 'Atividades',
          },
          crmActivity: {
            title: 'Atividades',
            description: 'Esta opção possibilita a visualização das atividades do sistema',
            label: 'Ativar visualização de atividades',
          },
          crmActivityCreator: {
            title: 'Criar atividades',
            description: 'Esta opção possibilita a criação de atividades no sistema',
            label: 'Habilitar criação de atividades',
          },
          crmActivityEditor: {
            title: 'Editar atividades',
            description: 'Esta opção permite a edição de atividades no sistema',
            label: 'Habilitar edição de atividades',
          },
          crmActivityDestructor: {
            title: 'Excluir atividades',
            description: 'Esta opção permite a exclusão de atividades no sistema',
            label: 'Habilitar exclusão de atividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas as atividades',
            description: 'Esta opção permite a visualização de todas as atividades do sistema',
            label: 'Habilite a visualização de todas as atividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opção permite a exibição dos resultados no sistema',
            label: 'Ativar exibição de resultados',
          },
          crmResultCreator: {
            title: 'Criar resultados',
            description: 'Esta opção permite a criação dos resultados no sistema',
            label: 'Habilitar criação de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opção permite a edição dos resultados no sistema',
            label: 'Habilitar edição de resultados',
          },
          crmResultDestructor: {
            title: 'Excluir resultados',
            description: 'Esta opção permite a exclusão de resultados no sistema',
            label: 'Habilitar exclusão de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar marcos do Call Center',
            description: 'Esta opção permite a edição dos marcos do Call Center no sistema',
            label: 'Habilitar edição de marcos do Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar marcos comerciais da ATP',
            description: 'Esta opção possibilita a edição dos marcos do ATP Comercial no sistema',
            label: 'Habilitar edição de marco comercial ATP',
          },
          crmResultATPIEvent: {
            title: 'Editar marcos da ATP International',
            description: 'Esta opção permite a edição dos marcos da ATP Internacional no sistema',
            label: 'Habilitar edição de marcos da ATP International',
          },
          crmResultReactivity: {
            title: 'Editar reatividade',
            description: 'Esta opção permite a edição da reatividade no sistema',
            label: 'Habilitar edição de reatividade',
          },
          reasons: {
            accordion: 'Razões',
          },
          crmReason: {
            title: 'Razões',
            description: 'Esta opção permite a exibição dos motivos no sistema',
            label: 'Ativar exibição do motivo',
          },
          crmReasonCreator: {
            title: 'Crie motivos',
            description: 'Esta opção possibilita a criação de motivos no sistema',
            label: 'Habilitar criação de motivo',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opção permite a edição de motivos no sistema',
            label: 'Ativar edição do motivo',
          },
          crmReasonDestructor: {
            title: 'Excluir motivos',
            description: 'Esta opção permite a exclusão de motivos no sistema',
            label: 'Ativar motivo de exclusão',
          },
          campaigns: {
            accordion: 'Campanhas',
          },
          crmCampaign: {
            title: 'Campanhas',
            description: 'Esta opção possibilita a visualização de campanhas no sistema',
            label: 'Ativar visualização de campanha',
          },
          crmCampaignCreator: {
            title: 'Crie campanhas',
            description: 'Esta opção possibilita a criação de campanhas no sistema',
            label: 'Habilitar criação de campanha',
          },
          crmCampaignEditor: {
            title: 'Editar campanhas',
            description: 'Esta opção possibilita a edição de campanhas no sistema',
            label: 'Ativar edição de campanha',
          },
          crmCampaignDestructor: {
            title: 'Excluir campanhas',
            description: 'Esta opção permite a exclusão de campanhas no sistema',
            label: 'Ativar exclusão de campanha',
          },
          funnels: {
            accordion: 'Funis',
          },
          crmFunnel: {
            title: 'Funis',
            description: 'Esta opção possibilita a visualização de funis no sistema',
            label: 'Habilite a visualização do funil',
          },
          crmFunnelCreator: {
            title: 'Crie funis',
            description: 'Esta opção possibilita a criação de funis no sistema',
            label: 'Habilitar criação de funil',
          },
          crmFunnelEditor: {
            title: 'Editar funis',
            description: 'Esta opção permite a edição de funis no sistema',
            label: 'Ativar edição de funil',
          },
          crmFunnelDestructor: {
            title: 'Excluir funis',
            description: 'Esta opção permite a remoção de funis no sistema',
            label: 'Ativar remoção de funil',
          },
          channels: {
            accordion: 'Canais',
          },
          crmChannel: {
            title: 'Canais',
            description: 'Esta opção permite a visualização de canais no sistema',
            label: 'Habilitar exibição do canal',
          },
          crmChannelCreator: {
            title: 'Criar canais',
            description: 'Esta opção possibilita a criação de canais no sistema',
            label: 'Habilitar criação de canal',
          },
          crmChannelEditor: {
            title: 'Editar canais',
            description: 'Esta opção permite a edição de canais no sistema',
            label: 'Habilitar edição de canal',
          },
          crmChannelDestructor: {
            title: 'Excluir canais',
            description: 'Esta opção permite a exclusão de canais do sistema',
            label: 'Habilitar exclusão de canal',
          },
          programs: {
            accordion: 'Tratamentos',
          },
          crmProgram: {
            title: 'Tratamentos',
            description: 'Esta opção permite a visualização dos tratamentos do catálogo no sistema',
            label: 'Habilitar exibição de tratamentos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crie tratamentos',
            description: 'Esta opção possibilita a criação de tratamentos no sistema',
            label: 'Habilitar criação de tratamento',
          },
          crmProgramEditor: {
            title: 'Editar tratamentos',
            description: 'Esta opção permite a edição de tratamentos no sistema',
            label: 'Habilitar edição de tratamento',
          },
          crmProgramDestructor: {
            title: 'Excluir tratamentos',
            description: 'Esta opção permite a exclusão de tratamentos no sistema',
            label: 'Ativar remoção de tratamento',
          },
          services: {
            accordion: 'Serviços',
          },
          crmService: {
            title: 'Serviços',
            description: 'Esta opção possibilita a visualização de serviços no sistema',
            label: 'Habilitar exibição de serviço',
          },
          crmServiceCreator: {
            title: 'Criar serviços',
            description: 'Esta opção permite a criação de serviços no sistema',
            label: 'Habilitar criação de serviço',
          },
          crmServiceEditor: {
            title: 'Editar serviços',
            description: 'Esta opção permite a edição de serviços no sistema',
            label: 'Habilitar edição de serviço',
          },
          crmServiceDestructor: {
            title: 'Excluir serviços',
            description: 'Esta opção permite a remoção de serviços do sistema',
            label: 'Habilitar remoção de serviço',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementares',
            description:
              'Esta opção possibilita a visualização de técnicas complementares no sistema',
            label: 'Permitir a visualização de técnicas complementares',
          },
          crmTechniqueCreator: {
            title: 'Crie técnicas complementares',
            description: 'Esta opção permite a criação de técnicas complementares no sistema',
            label: 'Possibilitar a criação de técnicas complementares',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementares',
            description: 'Esta opção possibilita a edição de técnicas complementares no sistema',
            label: 'Habilite a edição de técnicas complementares',
          },
          crmTechniqueDestructor: {
            title: 'Elimine técnicas complementares',
            description: 'Esta opção permite a remoção de técnicas complementares do sistema',
            label: 'Habilitar a remoção de técnicas complementares',
          },
          maintenances: {
            accordion: 'Manutenção',
          },
          crmMaintenance: {
            title: 'Manutenção',
            description: 'Esta opção possibilita a visualização das manutenções no sistema',
            label: 'Habilitar visualização de manutenção',
          },
          crmMaintenanceCreator: {
            title: 'Criar manutenção',
            description: 'Esta opção possibilita a criação de manutenções no sistema',
            label: 'Habilitar criação de manutenção',
          },
          crmMaintenanceEditor: {
            title: 'Editar manutenção',
            description: 'Esta opção permite a edição da manutenção no sistema',
            label: 'Habilitar edição de manutenção',
          },
          crmMaintenanceDestructor: {
            title: 'Elimine a manutenção',
            description: 'Esta opção permite eliminar manutenções no sistema',
            label: 'Ativar remoção de manutenção',
          },
          treatments: {
            accordion: 'Tratamentos',
          },
          crmTreatment: {
            title: 'Tratamentos',
            description: 'Esta opção possibilita a visualização dos tratamentos no sistema',
            label: 'Habilite a visualização de tratamentos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crie tratamentos para o paciente',
              ovobank: 'Crie tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a criação de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a criação de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Permitir a criação de tratamentos para o paciente',
              ovobank: 'Permitir a criação de tratamentos para doadores',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamentos para o paciente',
              ovobank: 'Editar tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a edição de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a edição de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar a edição de tratamentos para o paciente',
              ovobank: 'Habilitar edição de tratamento para doador',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Excluir tratamentos para o paciente',
              ovobank: 'Excluir tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a exclusão de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a exclusão de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar exclusão de tratamentos para o paciente',
              ovobank: 'Habilitar exclusão de tratamentos para doadores',
            },
          },
          phases: {
            accordion: 'Estágios',
          },
          crmPhase: {
            title: 'Estágios',
            description: 'Esta opção possibilita a visualização das etapas do sistema',
            label: 'Habilitar visualização de palco',
          },
          crmPhaseCreator: {
            title: 'Criar etapas',
            description: 'Esta opção possibilita a criação de etapas no sistema',
            label: 'Habilitar preparação',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opção possibilita a edição das etapas no sistema',
            label: 'Habilitar edição de palco',
          },
          crmPhaseDestructor: {
            title: 'Excluir etapas',
            description: 'Esta opção permite eliminar etapas do sistema',
            label: 'Habilitar exclusão de estágio',
          },
          causes: {
            accordion: 'Razões',
          },
          crmCause: {
            title: 'Razões para cancelamento',
            description: 'Esta opção permite a exibição dos motivos de cancelamento no sistema',
            label: 'Habilitar exibição dos motivos do cancelamento',
          },
          crmCauseCreator: {
            title: 'Crie motivos de cancelamento',
            description: 'Esta opção possibilita a criação de motivos de cancelamento no sistema',
            label: 'Habilitar a criação de motivos de cancelamento',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelamento',
            description: 'Esta opção permite a edição dos motivos de cancelamento no sistema',
            label: 'Habilitar edição de motivos de cancelamento',
          },
          crmCauseDestructor: {
            title: 'Elimine motivos de cancelamento',
            description: 'Esta opção permite a remoção dos motivos de cancelamento do sistema',
            label: 'Habilitar a remoção dos motivos de cancelamento',
          },
          cycles: {
            accordion: 'Ciclos de tratamento',
          },
          crmCycle: {
            title: 'Ciclos de tratamento',
            description: 'Esta opção permite a visualização dos ciclos de tratamento no sistema',
            label: 'Habilite a visualização dos ciclos de tratamento',
          },
          crmCycleCreator: {
            title: 'Crie ciclos de tratamento',
            description: 'Esta opção permite a criação de ciclos de tratamento no sistema',
            label: 'Permitir a criação de ciclos de tratamento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamento',
            description: 'Esta opção permite a edição de ciclos de tratamento no sistema',
            label: 'Habilitar edição de ciclos de tratamento',
          },
          crmCycleDestructor: {
            title: 'Excluir ciclos de tratamento',
            description: 'Esta opção permite a exclusão de ciclos de tratamento no sistema',
            label: 'Habilitar exclusão de ciclos de tratamento',
          },
          clinic: {
            accordion: 'Clínica OVO',
            title: 'Clínica OVO',
            description: 'Esta opção permite o acesso ao módulo OVOClinic',
            label: 'Habilitar acesso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opção permite o acesso ao módulo OVOBoard',
            label: 'Habilitar acesso',
          },
          tory: {
            accordion: 'OVOTÓRIA',
            title: 'OVOTÓRIA',
            description: 'Esta opção permite o acesso ao módulo OVOTory',
            label: 'Habilitar acesso',
          },
          profile: {
            accordion: 'Perfil OVO',
            title: 'Perfil OVO',
            description: 'Esta opção permite o acesso ao módulo OVOProfile',
            label: 'Habilitar acesso',
          },
          status: {
            title: 'Função ativa',
            description: 'Habilite ou desabilite a função no sistema',
            label: 'Função ativa',
          },
          submit: 'Criar função',
        },
      },
      update: {
        title: 'Atualizar função',
        subtitle: 'Modificando os dados de uma função no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação da função',
          },
          general: {
            accordion: 'Em geral',
            title: 'Permissões gerais',
            description: 'Permissões gerais para a função',
          },
          viewDisabledRecords: {
            title: 'Ver registros desativados',
            description: 'Esta opção permite a exibição de logs desabilitados',
            label: 'Habilitar exibição de logs desabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads desativados',
            description: 'Esta opção permite a exibição de leads desabilitados',
            label: 'Habilitar exibição de leads desativados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes com deficiência',
              ovobank: 'Ver doadores deficientes',
            },
            description: {
              ovoclinic: 'Esta opção permite a exibição de pacientes com deficiência',
              ovobank: 'Esta opção permite a exibição de doadores desabilitados',
            },
            label: {
              ovoclinic: 'Habilitar exibição de pacientes com deficiência',
              ovobank: 'Habilitar exibição de doadores desativados',
            },
          },
          access: {
            accordion: 'Acesso OVO',
            title: 'Permissão para o módulo OVOAccess',
            description: 'Esta opção permite o acesso ao módulo OVOAccess',
            label: 'Habilitar acesso',
          },
          user: {
            accordion: 'Usuários',
          },
          accessUser: {
            title: 'Ver usuários',
            description: 'Esta opção permite a exibição de usuários no sistema',
            label: 'Habilitar exibição do usuário',
          },
          accessUserCreator: {
            title: 'Criar usuários',
            description: 'Esta opção permite a criação de usuários no sistema',
            label: 'Habilitar criação de usuário',
          },
          accessUserEditor: {
            title: 'Editar usuários',
            description: 'Esta opção permite a edição de usuários no sistema',
            label: 'Habilitar edição do usuário',
          },
          accessUserDestructor: {
            title: 'Excluir usuários',
            description: 'Esta opção permite a exclusão de usuários do sistema',
            label: 'Habilitar exclusão de usuário',
          },
          accessUserChangePassword: {
            title: 'Alterar a senha',
            description: 'Esta opção permite alterar a senha dos usuários do sistema',
            label: 'Habilitar alteração de senha',
          },
          role: {
            accordion: 'Funções',
          },
          accessRole: {
            title: 'Ver funções',
            description: 'Esta opção permite a exibição de funções no sistema',
            label: 'Habilitar exibição de função',
          },
          accessRoleCreator: {
            title: 'Criar funções',
            description: 'Esta opção permite a criação de funções no sistema',
            label: 'Habilitar criação de função',
          },
          accessRoleEditor: {
            title: 'Editar funções',
            description: 'Esta opção permite a edição de funções no sistema',
            label: 'Habilitar edição de função',
          },
          accessRoleDestructor: {
            title: 'Excluir funções',
            description: 'Esta opção permite a exclusão de funções no sistema',
            label: 'Habilitar exclusão de função',
          },
          timetable: {
            accordion: 'Horários',
          },
          accessTimetable: {
            title: 'Ver horários',
            description: 'Esta opção possibilita a exibição de horários no sistema',
            label: 'Ativar exibição de agendamento',
          },
          accessTimetableCreator: {
            title: 'Crie programações',
            description: 'Esta opção possibilita a criação de agendamentos no sistema',
            label: 'Habilitar criação de agendamento',
          },
          accessTimetableEditor: {
            title: 'Editar programações',
            description: 'Esta opção possibilita a edição de horários no sistema',
            label: 'Habilitar edição de agendamento',
          },
          accessTimetableDestructor: {
            title: 'Excluir programações',
            description: 'Esta opção possibilita a exclusão de agendamentos no sistema',
            label: 'Ativar exclusão agendada',
          },
          bulletin: {
            accordion: 'Notícias',
          },
          accessBulletin: {
            title: 'Ver notícias',
            description: 'Esta opção possibilita a exibição de notícias no sistema',
            label: 'Ativar exibição de notícias',
          },
          accessBulletinCreator: {
            title: 'Criar notícias',
            description: 'Esta opção possibilita a criação de notícias no sistema',
            label: 'Habilitar criação de notícias',
          },
          accessBulletinEditor: {
            title: 'Editar notícias',
            description: 'Esta opção permite a edição de notícias no sistema',
            label: 'Habilitar edição de notícias',
          },
          accessBulletinDestructor: {
            title: 'Excluir notícias',
            description: 'Esta opção possibilita a exclusão de notícias do sistema',
            label: 'Habilitar exclusão de notícias',
          },
          accessClinic: {
            title: 'Veja clínicas',
            description: 'Esta opção possibilita a visualização das clínicas no sistema',
            label: 'Ativar visualização clínica',
          },
          accessClinicCreator: {
            title: 'Crie clínicas',
            description: 'Esta opção possibilita a criação de clínicas no sistema',
            label: 'Habilitar criação de clínica',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opção permite a edição de clínicas no sistema',
            label: 'Ativar edição clínica',
          },
          accessClinicDestructor: {
            title: 'Excluir clínicas',
            description: 'Esta opção possibilita a exclusão de clínicas do sistema',
            label: 'Ativar exclusão de clínica',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opção permite a visualização dos departamentos no sistema',
            label: 'Habilitar exibição de departamento',
          },
          accessDepartmentCreator: {
            title: 'Criar departamentos',
            description: 'Esta opção permite a criação de departamentos no sistema',
            label: 'Habilitar criação de departamento',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opção permite a edição de departamentos no sistema',
            label: 'Habilitar edição de departamento',
          },
          accessDepartmentDestructor: {
            title: 'Excluir departamentos',
            description: 'Esta opção permite a exclusão de departamentos do sistema',
            label: 'Habilitar exclusão de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opção possibilita a exibição de idiomas no sistema',
            label: 'Ativar exibição de idioma',
          },
          accessLanguageCreator: {
            title: 'Criar idiomas',
            description: 'Esta opção possibilita a criação de idiomas no sistema',
            label: 'Habilitar criação de idioma',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opção permite a edição de idiomas no sistema',
            label: 'Ativar edição de idioma',
          },
          accessLanguageDestructor: {
            title: 'Excluir idiomas',
            description: 'Esta opção permite a remoção de idiomas do sistema',
            label: 'Ativar remoção de idioma',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opção permite a exibição de países no sistema',
            label: 'Ativar exibição de país',
          },
          accessCountryCreator: {
            title: 'Criar países',
            description: 'Esta opção permite a criação de países no sistema',
            label: 'Habilitar criação de país',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opção permite a edição de países no sistema',
            label: 'Ativar edição de país',
          },
          accessCountryDestructor: {
            title: 'Excluir países',
            description: 'Esta opção permite a exclusão de países do sistema',
            label: 'Ativar exclusão de país',
          },
          accessSettings: {
            title: 'Ver configurações',
            description: 'Esta opção permite a exibição de configurações no sistema',
            label: 'Habilitar exibição de configuração',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Consulte especialistas',
            description: 'Esta opção permite a exibição de especialistas no sistema',
            label: 'Habilitar visualização de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crie especialistas',
            description: 'Esta opção possibilita a criação de especialistas no sistema',
            label: 'Permitir a criação de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opção possibilita a edição de especialistas no sistema',
            label: 'Habilitar edição especializada',
          },
          accessSpecialistDestructor: {
            title: 'Elimine especialistas',
            description: 'Esta opção permite a remoção de especialistas do sistema',
            label: 'Habilitar remoção especializada',
          },
          commsChannels: {
            accordion: 'Canais de comunicação',
          },
          accessCommsChannel: {
            title: 'Veja canais de comunicação',
            description:
              'Esta opção possibilita a visualização dos canais de comunicação do sistema',
            label: 'Habilite a visualização dos canais de comunicação',
          },
          accessCommsChannelCreator: {
            title: 'Crie canais de comunicação',
            description: 'Esta opção possibilita a criação de canais de comunicação no sistema',
            label: 'Permitir a criação de canais de comunicação',
          },
          accessCommsChannelEditor: {
            title: 'Editar canais de comunicação',
            description: 'Esta opção possibilita a edição dos canais de comunicação do sistema',
            label: 'Habilitar edição de canais de comunicação',
          },
          accessCommsChannelDestructor: {
            title: 'Elimine canais de comunicação',
            description: 'Esta opção permite a eliminação de canais de comunicação no sistema',
            label: 'Habilitar exclusão de canais de comunicação',
          },
          ovopoints: {
            accordion: 'Ovopontos',
          },
          accessOvopoint: {
            title: 'Ovopontos',
            description: 'Esta opção habilita a exibição de ovopontos no sistema',
            label: 'Habilitar exibição de ovopontos',
          },
          accessOvopointEditor: {
            title: 'Editar ovopontos',
            description: 'Esta opção possibilita a edição de ovopontos no sistema',
            label: 'Habilitar edição ovopoint',
          },
          templates: {
            accordion: 'Modelos',
          },
          accessTemplate: {
            title: 'Veja modelos do WhatsApp',
            description: 'Esta opção permite a exibição de modelos do WhatsApp',
            label: 'Habilitar exibição de modelo',
          },
          accessTemplateCreator: {
            title: 'Crie modelos do WhatsApp',
            description: 'Esta opção permite a criação de modelos de WhatsApp',
            label: 'Ativar modelos',
          },
          accessTemplateEditor: {
            title: 'Editar modelos do WhatsApp',
            description: 'Esta opção permite a edição de modelos do WhatsApp',
            label: 'Habilitar edição de modelo',
          },
          accessTemplateDestructor: {
            title: 'Excluir modelos do WhatsApp',
            description: 'Esta opção permite a exclusão de modelos do WhatsApp',
            label: 'Habilitar exclusão de modelo',
          },
          crm: {
            accordion: 'OVOCRM',
            title: 'Permissão do aplicativo',
            description: 'Esta opção permite o acesso ao módulo OVOCrm',
            label: 'Habilitar acesso',
          },
          tasks: {
            accordion: 'Tarefas',
          },
          crmCalendar: {
            title: 'Calendário',
            description: 'Esta opção permite a exibição do calendário no sistema',
            label: 'Ativar exibição de calendário',
          },
          crmTask: {
            title: 'Tarefas',
            description: 'Esta opção permite a exibição de tarefas no sistema',
            label: 'Habilitar exibição de tarefas',
          },
          crmTaskCreator: {
            title: 'Criar tarefas',
            description: 'Esta opção permite a criação de tarefas no sistema',
            label: 'Habilitar criação de tarefas',
          },
          crmTaskEditor: {
            title: 'Editar tarefas',
            description: 'Esta opção permite a edição de tarefas no sistema',
            label: 'Habilitar edição de tarefas',
          },
          crmTaskDestructor: {
            title: 'Excluir tarefas',
            description: 'Esta opção permite a exclusão de tarefas no sistema',
            label: 'Habilitar exclusão de tarefa',
          },
          crmTaskSeeAll: {
            title: 'Ver todas as tarefas',
            description: 'Esta opção permite a exibição de todas as tarefas do sistema',
            label: 'Habilite a exibição de todas as tarefas',
          },
          crmTaskSeeCoworkers: {
            title: 'Veja as tarefas dos colegas',
            description: 'Esta opção permite a exibição das tarefas dos colegas no sistema',
            label: 'Habilitar a visualização de tarefas de pares',
          },
          crmTaskUpdateResponsible: {
            title: 'Atualizar a pessoa atribuída a uma tarefa',
            description: 'Esta opção permite alterar o responsável por uma tarefa',
            label: 'Habilite a edição do gerenciador de tarefas',
          },
          leads: {
            accordion: 'Pistas',
          },
          crmLead: {
            title: 'Pistas',
            description: 'Esta opção possibilita a visualização de leads no sistema',
            label: 'Habilite a visualização de leads',
          },
          crmLeadCreator: {
            title: 'Criar leads',
            description: 'Esta opção permite a criação de leads no sistema',
            label: 'Habilitar criação de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opção permite a edição de leads no sistema',
            label: 'Ativar edição de leads',
          },
          crmLeadDestructor: {
            title: 'Excluir leads',
            description: 'Esta opção permite a exclusão de leads no sistema',
            label: 'Ativar exclusão de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos os leads',
            description: 'Esta opção permite a exibição de todos os leads do sistema',
            label: 'Habilitar exibição de todos os leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Veja leads de colegas',
            description: 'Esta opção permite a exibição de leads de colegas',
            label: 'Habilite a visualização dos leads dos colegas',
          },
          crmLeadUpdateCampaign: {
            title: 'Atualizar campanha atribuída a leads',
            description: 'Esta opção permite a alteração de campanha de múltiplos leads',
            label: 'Habilitar edição de campanha principal',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Doadores',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Doadores',
            },
            description: {
              ovoclinic: 'Esta opção possibilita a visualização dos pacientes no sistema',
              ovobank: 'Esta opção possibilita a visualização dos doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualização do paciente',
              ovobank: 'Habilitar exibição de doadores',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Criar pacientes',
              ovobank: 'Criar doadores',
            },
            description: {
              ovoclinic: 'Esta opção possibilita a criação de pacientes no sistema',
              ovobank: 'Esta opção possibilita a criação de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar criação de pacientes',
              ovobank: 'Habilitar criação de doadores',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a edição de pacientes no sistema',
              ovobank: 'Esta opção permite a edição de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar edição de pacientes',
              ovobank: 'Habilitar edição de doadores',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Excluir pacientes',
              ovobank: 'Excluir doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a exclusão de pacientes do sistema',
              ovobank: 'Esta opção possibilita a retirada de doadores do sistema',
            },
            label: {
              ovoclinic: 'Habilitar exclusão de paciente',
              ovobank: 'Habilitar remoção de doadores',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos os pacientes',
              ovobank: 'Ver todos os doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a visualização de todos os pacientes do sistema',
              ovobank: 'Esta opção permite a visualização de todos os doadores do sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualização de todos os pacientes',
              ovobank: 'Habilitar exibição de todos os doadores',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Veja pacientes de colegas',
              ovobank: 'Ver doadores pares',
            },
            description: {
              ovoclinic: 'Esta opção permite a visualização de pacientes pares',
              ovobank: 'Esta opção permite a exibição de doadores pares',
            },
            label: {
              ovoclinic: 'Habilite a visualização de pacientes de colegas',
              ovobank: 'Habilitar visualização de doadores pares',
            },
          },
          contacts: {
            accordion: 'Contatos',
          },
          crmContact: {
            title: 'Contatos',
            description: 'Esta opção permite a exibição de contatos no sistema',
            label: 'Ativar exibição de contato',
          },
          crmContactCreator: {
            title: 'Criar contatos',
            description: 'Esta opção possibilita a criação de contatos no sistema',
            label: 'Habilitar criação de contato',
          },
          crmContactEditor: {
            title: 'Editar contatos',
            description: 'Esta opção permite a edição de contatos no sistema',
            label: 'Ativar edição de contatos',
          },
          crmContactDestructor: {
            title: 'Excluir contatos',
            description: 'Esta opção permite a exclusão de contatos do sistema',
            label: 'Habilitar exclusão de contato',
          },
          activity: {
            accordion: 'Atividades',
          },
          crmActivity: {
            title: 'Atividades',
            description: 'Esta opção possibilita a visualização das atividades do sistema',
            label: 'Ativar visualização de atividades',
          },
          crmActivityCreator: {
            title: 'Criar atividades',
            description: 'Esta opção possibilita a criação de atividades no sistema',
            label: 'Habilitar criação de atividades',
          },
          crmActivityEditor: {
            title: 'Editar atividades',
            description: 'Esta opção permite a edição de atividades no sistema',
            label: 'Habilitar edição de atividades',
          },
          crmActivityDestructor: {
            title: 'Excluir atividades',
            description: 'Esta opção permite a exclusão de atividades no sistema',
            label: 'Habilitar exclusão de atividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas as atividades',
            description: 'Esta opção permite a visualização de todas as atividades do sistema',
            label: 'Habilite a visualização de todas as atividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opção permite a exibição dos resultados no sistema',
            label: 'Ativar exibição de resultados',
          },
          crmResultCreator: {
            title: 'Criar resultados',
            description: 'Esta opção permite a criação dos resultados no sistema',
            label: 'Habilitar criação de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opção permite a edição dos resultados no sistema',
            label: 'Habilitar edição de resultados',
          },
          crmResultDestructor: {
            title: 'Excluir resultados',
            description: 'Esta opção permite a exclusão de resultados no sistema',
            label: 'Habilitar exclusão de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar marcos do Call Center',
            description: 'Esta opção permite a edição dos marcos do Call Center no sistema',
            label: 'Habilitar edição de marcos do Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar marcos comerciais da ATP',
            description: 'Esta opção possibilita a edição dos marcos do ATP Comercial no sistema',
            label: 'Habilitar edição de marco comercial ATP',
          },
          crmResultATPIEvent: {
            title: 'Editar marcos da ATP International',
            description: 'Esta opção permite a edição dos marcos da ATP Internacional no sistema',
            label: 'Habilitar edição de marcos da ATP International',
          },
          crmResultReactivity: {
            title: 'Editar reatividade',
            description: 'Esta opção permite a edição da reatividade no sistema',
            label: 'Habilitar edição de reatividade',
          },
          reasons: {
            accordion: 'Razões',
          },
          crmReason: {
            title: 'Razões',
            description: 'Esta opção permite a exibição dos motivos no sistema',
            label: 'Ativar exibição do motivo',
          },
          crmReasonCreator: {
            title: 'Crie motivos',
            description: 'Esta opção possibilita a criação de motivos no sistema',
            label: 'Habilitar criação de motivo',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opção permite a edição de motivos no sistema',
            label: 'Ativar edição do motivo',
          },
          crmReasonDestructor: {
            title: 'Excluir motivos',
            description: 'Esta opção permite a exclusão de motivos no sistema',
            label: 'Ativar motivo de exclusão',
          },
          campaigns: {
            accordion: 'Campanhas',
          },
          crmCampaign: {
            title: 'Campanhas',
            description: 'Esta opção possibilita a visualização de campanhas no sistema',
            label: 'Ativar visualização de campanha',
          },
          crmCampaignCreator: {
            title: 'Crie campanhas',
            description: 'Esta opção possibilita a criação de campanhas no sistema',
            label: 'Habilitar criação de campanha',
          },
          crmCampaignEditor: {
            title: 'Editar campanhas',
            description: 'Esta opção possibilita a edição de campanhas no sistema',
            label: 'Ativar edição de campanha',
          },
          crmCampaignDestructor: {
            title: 'Excluir campanhas',
            description: 'Esta opção permite a exclusão de campanhas no sistema',
            label: 'Ativar exclusão de campanha',
          },
          funnels: {
            accordion: 'Funis',
          },
          crmFunnel: {
            title: 'Funis',
            description: 'Esta opção possibilita a visualização de funis no sistema',
            label: 'Habilite a visualização do funil',
          },
          crmFunnelCreator: {
            title: 'Crie funis',
            description: 'Esta opção possibilita a criação de funis no sistema',
            label: 'Habilitar criação de funil',
          },
          crmFunnelEditor: {
            title: 'Editar funis',
            description: 'Esta opção permite a edição de funis no sistema',
            label: 'Ativar edição de funil',
          },
          crmFunnelDestructor: {
            title: 'Excluir funis',
            description: 'Esta opção permite a remoção de funis no sistema',
            label: 'Ativar remoção de funil',
          },
          channels: {
            accordion: 'Canais',
          },
          crmChannel: {
            title: 'Canais',
            description: 'Esta opção permite a visualização de canais no sistema',
            label: 'Habilitar exibição do canal',
          },
          crmChannelCreator: {
            title: 'Criar canais',
            description: 'Esta opção possibilita a criação de canais no sistema',
            label: 'Habilitar criação de canal',
          },
          crmChannelEditor: {
            title: 'Editar canais',
            description: 'Esta opção permite a edição de canais no sistema',
            label: 'Habilitar edição de canal',
          },
          crmChannelDestructor: {
            title: 'Excluir canais',
            description: 'Esta opção permite a exclusão de canais do sistema',
            label: 'Habilitar exclusão de canal',
          },
          programs: {
            accordion: 'Tratamentos',
          },
          crmProgram: {
            title: 'Tratamentos',
            description: 'Esta opção permite a visualização dos tratamentos do catálogo no sistema',
            label: 'Habilitar exibição de tratamentos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crie tratamentos',
            description: 'Esta opção possibilita a criação de tratamentos no sistema',
            label: 'Habilitar criação de tratamento',
          },
          crmProgramEditor: {
            title: 'Editar tratamentos',
            description: 'Esta opção permite a edição de tratamentos no sistema',
            label: 'Habilitar edição de tratamento',
          },
          crmProgramDestructor: {
            title: 'Excluir tratamentos',
            description: 'Esta opção permite a exclusão de tratamentos no sistema',
            label: 'Ativar remoção de tratamento',
          },
          services: {
            accordion: 'Serviços',
          },
          crmService: {
            title: 'Serviços',
            description: 'Esta opção possibilita a visualização de serviços no sistema',
            label: 'Habilitar exibição de serviço',
          },
          crmServiceCreator: {
            title: 'Criar serviços',
            description: 'Esta opção permite a criação de serviços no sistema',
            label: 'Habilitar criação de serviço',
          },
          crmServiceEditor: {
            title: 'Editar serviços',
            description: 'Esta opção permite a edição de serviços no sistema',
            label: 'Habilitar edição de serviço',
          },
          crmServiceDestructor: {
            title: 'Excluir serviços',
            description: 'Esta opção permite a remoção de serviços do sistema',
            label: 'Habilitar remoção de serviço',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementares',
            description:
              'Esta opção possibilita a visualização de técnicas complementares no sistema',
            label: 'Permitir a visualização de técnicas complementares',
          },
          crmTechniqueCreator: {
            title: 'Crie técnicas complementares',
            description: 'Esta opção permite a criação de técnicas complementares no sistema',
            label: 'Possibilitar a criação de técnicas complementares',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementares',
            description: 'Esta opção possibilita a edição de técnicas complementares no sistema',
            label: 'Habilite a edição de técnicas complementares',
          },
          crmTechniqueDestructor: {
            title: 'Elimine técnicas complementares',
            description: 'Esta opção permite a remoção de técnicas complementares do sistema',
            label: 'Habilitar a remoção de técnicas complementares',
          },
          maintenances: {
            accordion: 'Manutenção',
          },
          crmMaintenance: {
            title: 'Manutenção',
            description: 'Esta opção possibilita a visualização das manutenções no sistema',
            label: 'Habilitar visualização de manutenção',
          },
          crmMaintenanceCreator: {
            title: 'Criar manutenção',
            description: 'Esta opção possibilita a criação de manutenções no sistema',
            label: 'Habilitar criação de manutenção',
          },
          crmMaintenanceEditor: {
            title: 'Editar manutenção',
            description: 'Esta opção permite a edição da manutenção no sistema',
            label: 'Habilitar edição de manutenção',
          },
          crmMaintenanceDestructor: {
            title: 'Elimine a manutenção',
            description: 'Esta opção permite eliminar manutenções no sistema',
            label: 'Ativar remoção de manutenção',
          },
          treatments: {
            accordion: 'Tratamentos',
          },
          crmTreatment: {
            title: 'Tratamentos',
            description: 'Esta opção possibilita a visualização dos tratamentos no sistema',
            label: 'Habilite a visualização de tratamentos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crie tratamentos para o paciente',
              ovobank: 'Crie tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a criação de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a criação de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Permitir a criação de tratamentos para o paciente',
              ovobank: 'Permitir a criação de tratamentos para doadores',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamentos para o paciente',
              ovobank: 'Editar tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a edição de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a edição de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar a edição de tratamentos para o paciente',
              ovobank: 'Habilitar edição de tratamento para doador',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Excluir tratamentos para o paciente',
              ovobank: 'Excluir tratamentos de doadores',
            },
            description: {
              ovoclinic: 'Esta opção permite a exclusão de tratamentos de pacientes no sistema',
              ovobank: 'Esta opção permite a exclusão de tratamentos de doadores no sistema',
            },
            label: {
              ovoclinic: 'Habilitar exclusão de tratamentos para o paciente',
              ovobank: 'Habilitar exclusão de tratamentos para doadores',
            },
          },
          phases: {
            accordion: 'Estágios',
          },
          crmPhase: {
            title: 'Estágios',
            description: 'Esta opção possibilita a visualização das etapas do sistema',
            label: 'Habilitar visualização de palco',
          },
          crmPhaseCreator: {
            title: 'Criar etapas',
            description: 'Esta opção possibilita a criação de etapas no sistema',
            label: 'Habilitar preparação',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opção possibilita a edição das etapas no sistema',
            label: 'Habilitar edição de palco',
          },
          crmPhaseDestructor: {
            title: 'Excluir etapas',
            description: 'Esta opção permite eliminar etapas do sistema',
            label: 'Habilitar exclusão de estágio',
          },
          causes: {
            accordion: 'Razões',
          },
          crmCause: {
            title: 'Razões para cancelamento',
            description: 'Esta opção permite a exibição dos motivos de cancelamento no sistema',
            label: 'Habilitar exibição dos motivos do cancelamento',
          },
          crmCauseCreator: {
            title: 'Crie motivos de cancelamento',
            description: 'Esta opção possibilita a criação de motivos de cancelamento no sistema',
            label: 'Habilitar a criação de motivos de cancelamento',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelamento',
            description: 'Esta opção permite a edição dos motivos de cancelamento no sistema',
            label: 'Habilitar edição de motivos de cancelamento',
          },
          crmCauseDestructor: {
            title: 'Elimine motivos de cancelamento',
            description: 'Esta opção permite a remoção dos motivos de cancelamento do sistema',
            label: 'Habilitar a remoção dos motivos de cancelamento',
          },
          cycles: {
            accordion: 'Ciclos de tratamento',
          },
          crmCycle: {
            title: 'Ciclos de tratamento',
            description: 'Esta opção permite a visualização dos ciclos de tratamento no sistema',
            label: 'Habilite a visualização dos ciclos de tratamento',
          },
          crmCycleCreator: {
            title: 'Crie ciclos de tratamento',
            description: 'Esta opção permite a criação de ciclos de tratamento no sistema',
            label: 'Permitir a criação de ciclos de tratamento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamento',
            description: 'Esta opção permite a edição de ciclos de tratamento no sistema',
            label: 'Habilitar edição de ciclos de tratamento',
          },
          crmCycleDestructor: {
            title: 'Excluir ciclos de tratamento',
            description: 'Esta opção permite a exclusão de ciclos de tratamento no sistema',
            label: 'Habilitar exclusão de ciclos de tratamento',
          },
          clinic: {
            accordion: 'Clínica OVO',
            title: 'Clínica OVO',
            description: 'Esta opção permite o acesso ao módulo OVOClinic',
            label: 'Habilitar acesso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opção permite o acesso ao módulo OVOBoard',
            label: 'Habilitar acesso',
          },
          tory: {
            accordion: 'OVOTÓRIA',
            title: 'OVOTÓRIA',
            description: 'Esta opção permite o acesso ao módulo OVOTory',
            label: 'Habilitar acesso',
          },
          profile: {
            accordion: 'Perfil OVO',
            title: 'Perfil OVO',
            description: 'Esta opção permite o acesso ao módulo OVOProfile',
            label: 'Habilitar acesso',
          },
          status: {
            title: 'Função ativa',
            description: 'Habilite ou desabilite a função no sistema',
            label: 'Função ativa',
          },
          submit: 'Atualizar função',
        },
      },
      delete: {
        title: 'Excluir função',
        subtitle: 'Esta ação é irreversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desabilitar esta função',
            label: 'Sim, quero excluir esta função',
          },
          submit: 'Excluir função',
        },
      },
      enable: {
        title: 'Habilitar função',
        subtitle: 'Esta ação permite que a função esteja ativa no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativá-lo?',
            description: 'Esta ação permitirá que a função esteja ativa no sistema',
            label: 'Sim, quero ativar esta função',
          },
          submit: 'Habilitar função',
        },
      },
      disable: {
        title: 'Desativar função',
        subtitle: 'Esta ação desativa a função no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativá-lo?',
            description: 'Esta ação desabilitará a função no sistema',
            label: 'Sim, quero desativar esta função',
          },
          submit: 'Desativar função',
        },
      },
    },
    clinics: {
      list: {
        title: 'Clínicas',
        description: 'Gestão clínica',
        create: 'Clínica',
        one: 'Clínica',
        many: 'Clínicas',
        columns: {
          name: 'Nome',
          city: 'Cidade',
          document: 'CIF',
        },
      },
      create: {
        title: 'Nova clínica',
        subtitle: 'Cadastro de uma nova clínica no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação da clínica',
          },
          country: {
            title: 'País',
            description: 'País onde a clínica está localizada',
          },
          state: {
            title: 'Estado',
            description: 'Estado ou província da clínica',
          },
          city: {
            title: 'Cidade',
            description: 'Cidade onde a clínica está localizada',
          },
          street: {
            title: 'Rua',
            description: 'Rua onde fica a clínica',
          },
          zip: {
            title: 'CEP',
            description: 'CEP da clínica',
          },
          timezone: {
            title: 'fuso horário',
            description: 'Fuso horário da clínica',
          },
          center: {
            title: 'Localização',
            description: 'Localização da Clínica',
          },
          status: {
            title: 'Clínica ativa',
            description: {
              ovoclinic: 'Habilitar ou desabilitar a clínica no sistema para uso do paciente',
              ovobank: 'Habilitar ou desabilitar a clínica no sistema para uso do doador',
            },
            label: 'Clínica ativa',
          },
          avatar: {
            title: 'avatar',
            description: 'Imagem/Avatar associado da clínica. ',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF ou documento de identificação clínica',
          },
          title: {
            title: 'Qualificação',
            description: 'Título da clínica',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição da clínica',
          },
          color: {
            title: 'Cor',
            description: 'Cor associada à clínica no sistema.',
          },
          submit: 'Criar clínica',
        },
      },
      update: {
        title: 'Atualizar clínica',
        subtitle: 'Modificação de dados clínicos no sistema',
        tabs: {
          details: {
            title: 'Detalhes',
            description:
              'As clínicas são agrupamentos de primeiro nível do sistema e servem para agrupar a atividade do usuário. ',
          },
          departments: {
            title: 'Departamentos',
            description:
              'O principal objetivo dos departamentos é dividir a atividade da clínica para melhor organização e controle. ',
            add: 'Adicionar departamento',
          },
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação da clínica',
          },
          country: {
            title: 'País',
            description: 'País onde a clínica está localizada',
          },
          state: {
            title: 'Estado',
            description: 'Estado ou província da clínica',
          },
          city: {
            title: 'Cidade',
            description: 'Cidade onde a clínica está localizada',
          },
          street: {
            title: 'Rua',
            description: 'Rua onde fica a clínica',
          },
          zip: {
            title: 'CEP',
            description: 'CEP da clínica',
          },
          timezone: {
            title: 'fuso horário',
            description: 'Fuso horário da clínica',
          },
          center: {
            title: 'Localização',
            description: 'Localização da Clínica',
          },
          status: {
            title: {
              ovoclinic: 'Clínica ativa',
              ovobank: 'Clínica ativa',
            },
            description: {
              ovoclinic: 'Habilitar ou desabilitar a clínica no sistema para uso do paciente',
              ovobank: 'Habilitar ou desabilitar a clínica no sistema para uso do doador',
            },
            label: {
              ovoclinic: 'Clínica ativa',
              ovobank: 'Clínica ativa',
            },
          },
          avatar: {
            title: 'avatar',
            description: 'Imagem/Avatar associado da clínica. ',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF ou documento de identificação clínica',
          },
          title: {
            title: 'Qualificação',
            description: 'Título da clínica',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição da clínica',
          },
          color: {
            title: 'Cor',
            description: 'Cor associada à clínica no sistema',
          },
          submit: 'Atualizar clínica',
        },
      },
      disable: {
        title: 'Desativar clínica',
        subtitle: 'Desativar clínica no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta clínica?',
            description: 'Desativar uma clínica significa que ela não pode ser usada no sistema',
            label: 'Sim, quero desativar esta clínica',
          },
          submit: 'Desativar clínica',
        },
      },
      enable: {
        title: 'Ativar clínica',
        subtitle: 'Habilitar clínica no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta clínica?',
            description: 'Habilitar uma clínica significa que ela pode ser usada no sistema',
            label: 'Sim, quero ativar esta clínica',
          },
          submit: 'Ativar clínica',
        },
      },
      delete: {
        title: 'Desativar clínica',
        subtitle: 'Desativar clínica no sistema',
        button: 'Desativar',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta clínica?',
            description: 'Desativar uma clínica significa que ela não pode ser usada no sistema',
            label: 'Sim, quero desativar esta clínica',
          },
          submit: 'Desativar clínica',
        },
        passwordForm: {
          title: 'Digite sua senha',
          subtitle: 'Para desativar a clínica, digite sua senha',
          password: {
            title: 'Senha',
            description: 'Senha do usuário que realiza a ação',
          },
          submit: 'Confirmar',
        },
      },
    },
    licenses: {
      list: {
        one: 'Licença',
        many: 'Licenças',
      },
    },
    movements: {
      list: {
        one: 'Movimento',
        many: 'Movimentos',
      },
    },
    attempts: {
      list: {
        one: 'Testado',
        many: 'Tentativas',
      },
    },
    contracts: {
      list: {
        one: 'Contrato',
        many: 'Contratos',
      },
    },
    timetables: {
      list: {
        title: 'Horários',
        description: 'Gerenciamento de agendas de usuários',
        create: 'Agendar',
        one: 'Agendar',
        many: 'Horários',
        columns: {
          name: 'Nome',
          day: 'Dia',
          range: 'Faixa',
        },
      },
      create: {
        title: 'Nova programação',
        subtitle: 'Cadastro de novo agendamento no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do agendamento',
          },
          day: {
            title: 'Dia',
            description: 'Dia da semana em que a programação se aplica',
          },
          started: {
            title: 'Começar',
            description: 'Agendar horário de início',
          },
          ended: {
            title: 'Fim',
            description: 'Agendar horário de término',
          },
          submit: 'Criar cronograma',
        },
      },
      update: {
        title: 'Cronograma de atualização',
        subtitle: 'Modificando dados de agendamento no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do agendamento',
          },
          submit: 'Salvar programação',
        },
        tabs: {
          timetable: {
            title: 'Agendar',
            description: 'Detalhes da programação',
          },
          timetableDay: {
            title: 'Dia',
            description: 'Dias da semana em que a programação se aplica',
          },
        },
        timetableDays: {
          create: {
            title: 'Adicionar dia',
            subtitle: 'Adicionar dia à programação',
            form: {
              day: {
                title: 'Dia',
                description: 'Dia da semana em que a programação se aplica',
              },
              started: {
                title: 'Começar',
                description: 'Agendar horário de início',
              },
              ended: {
                title: 'Fim',
                description: 'Agendar horário de término',
              },
              submit: 'Adicionar dia',
            },
          },
          update: {
            title: 'Dia de atualização',
            subtitle: 'Modificando os dados de um dia na programação',
            form: {
              day: {
                title: 'Dia',
                description: 'Dia da semana em que a programação se aplica',
              },
              started: {
                title: 'Começar',
                description: 'Agendar horário de início',
              },
              ended: {
                title: 'Fim',
                description: 'Agendar horário de término',
              },
              submit: 'Dia de atualização',
            },
          },
          delete: {
            title: 'Excluir dia',
            subtitle: 'Excluir dia da programação',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluir este dia?',
                label: 'Sim, quero excluir este dia',
              },
              submit: 'Excluir dia',
            },
          },
          add: 'Adicionar dia',
        },
      },
      delete: {
        title: 'Excluir programação',
        subtitle: 'Desativar agendamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta programação?',
            description:
              'Desabilitar um agendamento significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar esta programação',
          },
          submit: 'Desativar agendamento',
        },
      },
      disable: {
        title: 'Desativar agendamento',
        subtitle: 'Desativar agendamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta programação?',
            description:
              'Desabilitar um agendamento significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar esta programação',
          },
          submit: 'Desativar agendamento',
        },
      },
      enable: {
        title: 'Ativar agendamento',
        subtitle: 'Habilitar agendamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta programação?',
            description: 'Habilitar um agendamento significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar esta programação',
          },
          submit: 'Ativar agendamento',
        },
      },
    },
    departments: {
      list: {
        title: 'Departamentos',
        description: 'Gestão de departamento',
        create: 'Departamento',
        one: 'Departamento',
        many: 'Departamentos',
        columns: {
          name: 'Nome',
          responsible: 'Responsável',
          language: 'Linguagem',
          country: 'País',
          clinic: 'Clínica',
          created: 'Criação',
        },
      },
      create: {
        title: 'Novo departamento',
        subtitle: 'Cadastro de novo departamento no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do departamento',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a que pertence o departamento',
          },
          status: {
            title: 'Departamento ativo',
            description: 'Habilitar ou desabilitar o departamento no sistema',
            label: 'Departamento ativo',
          },
          languageId: {
            title: 'Linguagem',
            description: 'Idioma do departamento',
          },
          responsibleId: {
            title: 'Responsável',
            description: 'Usuário responsável pelo departamento',
          },
          submit: 'Criar',
        },
      },
      enable: {
        title: 'Ativar departamento',
        subtitle: 'Habilitar departamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este departamento?',
            label: 'Sim, quero ativar este departamento',
          },
          submit: 'Ativar departamento',
        },
      },
      disable: {
        title: 'Desativar departamento',
        subtitle: 'Desativar departamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este departamento?',
            label: 'Sim, quero desativar este departamento',
          },
          submit: 'Desativar departamento',
        },
      },
      update: {
        title: 'Departamento de atualização',
        subtitle: 'Modificando os dados de um departamento no sistema',
        department: {
          tab: 'Departamento',
          description: 'Detalhes do departamento',
        },
        clinic: {
          tab: 'Clínica',
          description: 'Clínica a que pertence o departamento',
        },
        responsible: {
          tab: 'Responsável',
          description: 'Gerente de Departamento',
        },
        language: {
          tab: 'Linguagem',
          description: 'Idioma do departamento',
        },
        tabs: {
          department: {
            title: 'Departamento',
            description: 'Detalhes do departamento',
          },
          members: {
            title: 'Membros',
            description: 'Membros do departamento. ',
          },
        },
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do departamento',
          },
          languageId: {
            title: 'Linguagem',
            description: 'Idioma do departamento',
          },
          responsibleId: {
            title: 'Responsável',
            description: 'Usuário responsável pelo departamento',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a que pertence o departamento',
          },
          status: {
            title: 'Departamento ativo',
            description: 'Habilitar ou desabilitar o departamento no sistema',
            label: 'Departamento ativo',
          },
          submit: 'Departamento de atualização',
        },
        members: {
          one: 'Membro',
          many: 'Membros',
          add: 'Adicionar membro',
          create: {
            title: 'Adicionar membro',
            subtitle: 'Um membro é um usuário que pertence a um departamento. ',
            form: {
              contractId: {
                title: 'Usuário',
              },
              submit: 'Adicionar membro',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Excluir membro',
        subtitle: 'Excluir membro do departamento. ',
        form: {
          submit: 'Excluir membro',
        },
      },
    },
    countries: {
      list: {
        title: 'Países',
        description: 'Gestão do país',
        create: 'País',
        one: 'País',
        many: 'Países',
        columns: {
          name: 'Nome',
          code: 'Código',
          coin: 'Moeda',
          updated: 'Atualizar',
          created: 'Criação',
        },
      },
      update: {
        title: 'Editar país',
        subtitle: 'Editar país no sistema',
        form: {
          countryNameEn: {
            title: 'Nome inglês',
            description: 'Nome inglês do país',
          },
          countryNameEs: {
            title: 'Nome espanhol',
            description: 'Nome espanhol do país',
          },
          flag: {
            title: 'Bandeira',
            description: 'Bandeira do país representada por um emoticon',
          },
          coin: {
            title: 'Moeda',
            description: 'Moeda usada no país',
          },
          code: {
            title: 'código do país',
            description: 'Código do país representado em 2 caracteres (ISO 3166-1 alfa-2)',
          },
          prefix: {
            title: 'Prefixo',
            description: 'Código telefônico do país',
          },
          center: {
            title: 'Localização',
            description: 'Localização do país',
          },
          geometry: {
            title: 'Geometria em formato json',
            description: 'Geometria do país em formato json',
          },
          latitude: {
            title: 'Coordenada de latitude',
            description: 'Coordenada de latitude do país',
          },
          longitude: {
            title: 'Coordenada de comprimento',
            description: 'Coordenada de longitude do país',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição do país',
          },
          color: {
            title: 'Formato de cor #ffffff, rede, rgb(0,0,0)',
            description: 'Cor do país em hexadecimal, RGB ou nome em formato inglês',
          },
          submit: 'Salvar país',
        },
      },
      delete: {
        title: 'Excluir país',
        subtitle: 'Desativar país no sistema',
        form: {
          submit: 'Excluir país',
        },
      },
      disable: {
        title: 'Desativar país',
        subtitle: 'Desativar país no sistema',
        form: {
          submit: 'Desativar país',
        },
      },
      enable: {
        title: 'Ativar país',
        subtitle: 'Habilitar país no sistema',
        form: {
          submit: 'Ativar país',
        },
      },
      create: {
        title: 'novo país',
        subtitle: 'Cadastro de um novo país no sistema',
        form: {
          countryNameEn: {
            title: 'Nome inglês',
            description: 'Nome inglês do país',
          },
          countryNameEs: {
            title: 'Nome espanhol',
            description: 'Nome espanhol do país',
          },
          flag: {
            title: 'Bandeira',
            description: 'Bandeira do país representada por um emoticon',
          },
          coin: {
            title: 'Moeda',
            description: 'Moeda usada no país',
          },
          code: {
            title: 'código do país',
            description: 'Código do país representado em 2 caracteres (ISO 3166-1 alfa-2)',
          },
          prefix: {
            title: 'Prefixo',
            description: 'Código telefônico do país',
          },
          center: {
            title: 'Localização',
            description: 'Localização do país',
          },
          geometry: {
            title: 'Geometria em formato json',
            description: 'Geometria do país em formato json',
          },
          latitude: {
            title: 'Coordenada de latitude',
            description: 'Coordenada de latitude do país',
          },
          longitude: {
            title: 'Coordenada de comprimento',
            description: 'Coordenada de longitude do país',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição do país',
          },
          color: {
            title: 'Formato de cor #ffffff, rede, rgb(0,0,0)',
            description: 'Cor do país em hexadecimal, RGB ou nome em formato inglês',
          },
          submit: 'Criar país',
        },
      },
    },
    access: {
      create: {
        title: 'Novo acesso',
        subtitle: 'Cadastro de um novo acesso no sistema',
        form: {
          ip: {
            title: 'Endereço IP',
            description: 'Endereço IP do dispositivo que acessa o sistema',
          },
          status: {
            title: 'Acesso ao sistema',
            description: 'Habilite ou desabilite o acesso ao sistema para este usuário e este ip',
            label: 'Permitir acesso ao sistema',
          },
          submit: 'Criar acesso',
        },
      },
    },
    settings: {
      title: 'Configuração',
      subtitle: 'Configuração da plataforma',
      form: {
        loginEnabled: {
          title: 'Habilitar acesso',
          description: 'Se o acesso estiver habilitado, qualquer usuário poderá acessar o sistema',
          label: 'Permitir acesso',
        },
        attemptLimitEnabled: {
          title: 'Ativar limite de tentativas',
          description:
            'Se o limite de tentativas estiver ativado, o usuário será bloqueado se mais do que o número de tentativas permitidas para o usuário falhar no login. ',
          label: 'Permitir limite de tentativas',
        },
        movementLimitEnabled: {
          title: 'Ativar limite de movimento',
          description:
            'Se o limite de movimento estiver habilitado, o usuário será bloqueado se fizer mais solicitações do que o permitido. ',
          label: 'Permitir limite de movimento',
        },
        submit: 'Salvar configurações',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Atividades',
      contacts: 'Contatos',
      marketing: 'Marketing',
      sales: 'Vendas',
      directory: 'Diretório',
      customers: 'Clientes',
      commercial: 'Comercial',
      campaigns: 'Campanhas',
      funnels: 'Funis',
      channels: 'Canais',
      leads: 'Pistas',
      transferLead: 'Transferência de leads',
      patients: {
        ovoclinic: 'Pacientes',
        ovobank: 'Doadores',
      },
      programs: 'Programas',
      maintenances: 'Manutenção',
      tasks: 'Tarefas',
      transferTask: 'Transferência de tarefas',
      createTaskMassively: 'Crie tarefas em massa',
      actions: 'Calendário',
      origins: 'Origens',
      techniques: 'Técnicas',
      services: 'Serviços',
      activities: 'Atividades',
      results: 'Resultados',
      reasons: 'Razões',
      products: 'Produtos',
      categories: 'Categorias',
      dashboard: 'Painel',
      catalog: 'Catálogo',
      phases: 'Estágios',
      causes: 'Causas',
      treatments: 'Tratamentos',
      cycles: 'Ciclos',
    },
    faq: {
      title: 'Perguntas frequentes',
      description: 'Perguntas frequentes',
      list: {
        '1': {
          question: 'Como posso criar um lead?',
          answer: 'Para criar um lead, siga estas etapas:',
        },
        '2': {
          question: 'Como posso criar uma campanha?',
          answer: 'Para criar uma campanha, siga estas etapas:',
        },
        '3': {
          question: 'Como posso criar uma tarefa?',
          answer: 'Para criar uma tarefa, siga estas etapas:',
        },
        '4': {
          question: 'Como posso transferir um lead?',
          answer: 'Para transferir um lead, siga estas etapas:',
        },
      },
    },
    information: {
      list: {
        '0': {
          title: 'Fluxo de trabalho do departamento NACIONAL',
          sublists: {
            '0': {
              title: 'Central de atendimento',
            },
            '1': {
              title: 'Atendimento ao paciente Comercial',
            },
            '2': {
              title: 'Atendimento ao paciente Médico',
            },
          },
        },
        '1': {
          title: 'Fluxo de trabalho do departamento INTERNACIONAL',
        },
      },
    },
    dashboard: {
      title: 'Painel',
      description: 'Painel de controle da sua atividade',
      actions: {
        title: 'Tarefas',
        description: {
          ovoclinic: 'Gestão das tarefas às quais está atribuído. ',
          ovobank: 'Gestão das tarefas às quais está atribuído. ',
        },
      },
      receipts: {
        title: 'Últimos recibos',
        description: 'Pagamentos feitos aos seus leads',
        list: {
          columns: {
            contactName: 'Nome',
            payments: 'Pagamentos',
            updated: 'F. atualização',
          },
          many: 'Recibos',
          one: 'Recibo',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Pistas',
          ovobank: 'Doadores',
        },
        description: {
          ovoclinic: 'Gerenciamento de seus leads/pacientes',
          ovobank: 'Gerenciamento de seus leads/doadores',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campanhas',
          ovobank: 'Campanhas',
        },
        description: {
          ovoclinic: 'Gestão das suas campanhas',
          ovobank: 'Gestão das suas campanhas',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Transferência de leads',
        description: 'Transferir campanhas de leads',
        create: 'Liderar',
        one: 'Liderar',
        many: 'Pistas',
        required: 'Você deve selecionar pelo menos um lead',
        columns: {
          name: 'Nome',
          activity: 'Atividade',
          responsible: 'Responsável',
          campaignName: 'Sino',
          started: 'Data estimada',
          clinicName: 'Clínica',
          created: 'Criação',
          resultId: 'Progresso',
          phones: 'Telefone',
          emails: 'E-mail',
        },
      },
      confirm: {
        title: 'Transferir leads',
        subtitle: 'Reatribuir campanha a vários leads',
        success: 'Leads transferidos com sucesso',
        steps: {
          campaign: {
            form: 'Campanha selecionada',
          },
        },
        form: {
          campaignId: {
            title: 'Selecione a campanha',
            description: 'Campanha para a qual os leads serão reatribuídos',
          },
          responsibleId: {
            title: 'Selecione o responsável',
            description:
              'Pessoa responsável a quem os leads serão reatribuídos ao mudar de campanha',
          },
          submit: 'Transferir',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Transferência de tarefas',
          ovobank: 'Transferência de tarefas',
        },
        description: {
          ovoclinic: 'Transferir tarefas para outros usuários. ',
          ovobank: 'Transferir tarefas para outros usuários. ',
        },
        create: 'Tarefa',
        one: 'Tarefa',
        many: 'Tarefas',
        columns: {
          activity: 'Atividade',
          phone: 'Telefone',
          contact: 'Contato',
          subject: 'Assunto',
          resultId: 'Progresso',
          campaign: 'Sino',
          clinic: 'Clínica',
          contactTime: 'Preferência de horário',
          created: 'Criação',
          user: 'Responsável',
          started: 'Data estimada de início',
          finished: 'Duração',
        },
      },
      confirm: {
        title: 'Transferir tarefas',
        subtitle: 'Reatribuir responsáveis ​​para múltiplas tarefas',
        success: 'Tarefas transferidas com sucesso',
        form: {
          responsibleId: {
            title: 'Selecione o responsável',
            description: 'Pessoa a quem as tarefas serão reatribuídas',
          },
          submit: 'Transferir',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Crie tarefas em massa',
        description: 'Esta tela permite criar um tipo de atividade para vários leads',
        columns: {
          name: 'Nome',
          clinicName: 'Clínica',
          campaignName: 'Sino',
          phones: 'Telefone',
          emails: 'E-mail',
          created: 'Criação',
          lastAction: 'Última tarefa',
          subject: 'Assunto',
          lastActionDate: 'Data da tarefa pendente',
        },
        noItemSelected: 'Não há leads selecionados',
        one: 'Liderar',
        many: 'Pistas',
      },
      selectActivity: {
        title: 'Selecione a atividade',
        subtitle: 'Selecione a atividade que deseja criar para os leads selecionados',
        form: {
          activityId: {
            title: 'Atividade',
            description: 'Atividade que você deseja criar para os leads selecionados',
            required: 'Você deve selecionar uma atividade',
          },
          subject: {
            title: 'Assunto',
            description:
              'O assunto que será definido para todas as tarefas que serão criadas para os leads selecionados',
          },
          submit: 'Criar tarefas',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Tarefas',
          ovobank: 'Tarefas',
        },
        description: {
          ovoclinic: 'Gestão das tarefas às quais está atribuído. ',
          ovobank: 'Gestão das tarefas às quais está atribuído. ',
        },
        create: {
          ovoclinic: 'Tarefa',
          ovobank: 'Tarefa',
        },
        one: 'Tarefa',
        many: 'Tarefas',
        columns: {
          activity: 'Atividade',
          contact: 'Contato',
          responsible: 'Responsável',
          subject: 'Assunto',
          resultId: 'Progresso',
          resultName: 'Resultado',
          campaign: 'Sino',
          clinic: 'Clínica',
          contactTime: 'Preferência de horário',
          created: 'Criação',
          user: 'Responsável',
          started: 'Data estimada de início',
          finished: 'Duração',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'Nova ação',
              subtitle: 'Cadastro de uma nova ação no sistema. ',
              form: {
                clinicId: {
                  title: 'Clínica',
                  description: 'Clínica para a qual a ação é direcionada',
                },
                submit: 'Seguindo',
              },
            },
            lead: {
              title: 'Nova ação',
              subtitle: 'Cadastro de uma nova ação no sistema. ',
              form: {
                leadId: {
                  title: 'Liderar',
                  description: 'Levar a quem a ação é direcionada',
                },
                submit: 'Seguindo',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'O dia todo',
        previous: 'Antigo',
        next: 'Seguindo',
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Hora',
        event: 'Evento',
      },
    },
    channels: {
      list: {
        title: 'Canais',
        description: 'Gestão de canais de comunicação',
        create: 'Canal',
        one: 'Canal',
        many: 'Canais',
        columns: {
          name: 'Nome',
          funnels: 'Funis',
        },
      },
      create: {
        title: 'Novo canal',
        subtitle: 'Cadastro de um novo canal no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do canal',
          },
          status: {
            title: 'Canal ativo',
            description: 'Habilite ou desabilite o canal no sistema',
            label: 'Canal ativo',
          },
          submit: 'Criar canal',
        },
      },
      update: {
        title: 'Atualizar canal',
        subtitle: 'Modificando os dados de um canal no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do canal',
          },
          status: {
            title: 'Canal ativo',
            description: 'Habilite ou desabilite o canal no sistema',
            label: 'Canal ativo',
          },
          submit: 'Atualizar canal',
        },
      },
      disable: {
        title: 'Desativar canal',
        subtitle: 'Desativar canal no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este canal?',
            description: 'Desabilitar um canal significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este canal',
          },
          submit: 'Desativar canal',
        },
      },
      enable: {
        title: 'Ativar canal',
        subtitle: 'Habilitar canal no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este canal?',
            description: 'Habilitar um canal significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este canal',
          },
          submit: 'Ativar canal',
        },
      },
      delete: {
        title: 'Excluir canal',
        subtitle: 'Esta ação é irreversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desativar este canal',
            label: 'Sim, quero excluir este canal',
          },
          submit: 'Eliminar',
        },
      },
    },
    cycles: {
      list: {
        title: 'Ciclos',
        description: 'Gestão de ciclos de tratamento',
        create: 'Ciclo',
        one: 'Ciclo',
        many: 'Ciclos',
        columns: {
          name: 'Nome',
          complexity: 'Complexidade',
          created: 'Data de criação',
          code: 'Código',
        },
      },
      create: {
        title: 'Novo ciclo',
        subtitle: 'Cadastro de novo ciclo no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do ciclo',
          },
          complexity: {
            title: 'Complexidade',
            description: 'Complexidade do ciclo',
          },
          code: {
            title: 'Código',
            description: 'Código de identificação do ciclo',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição do ciclo',
          },
          submit: 'Criar ciclo',
        },
      },
      update: {
        title: 'Ciclo de atualização',
        subtitle: 'Modificando os dados de um ciclo no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do ciclo',
          },
          complexity: {
            title: 'Complexidade',
            description: 'Complexidade do ciclo',
          },
          code: {
            title: 'Código',
            description: 'Código de identificação do ciclo',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição do ciclo',
          },
          submit: 'Ciclo de atualização',
        },
      },
      enable: {
        title: 'Habilitar ciclo',
        subtitle: 'Habilitar ciclo no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este ciclo?',
            description: 'Habilitar um ciclo significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este ciclo',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar ciclo',
        subtitle: 'Desativar ciclo no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este ciclo?',
            description: 'Desabilitar um ciclo significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este ciclo',
          },
          submit: 'Desativar',
        },
      },
    },
    funnels: {
      list: {
        title: 'Funis',
        description: 'Gestão de funis de conversão. ',
        create: 'Funil',
        one: 'Funil',
        many: 'Funis',
        params: {
          channelId: 'Funis filtrados por canal de comunicação',
        },
        columns: {
          name: 'Nome',
          channelName: 'Canal',
        },
      },
      create: {
        title: 'Novo funil',
        subtitle: 'Cadastro de novo funil no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Identificando o nome do funil',
          },
          channelId: {
            title: 'Canal',
            description: 'Canal de comunicação associado ao funil',
          },
          status: {
            title: 'Funil ativo',
            description: 'Habilite ou desabilite o funil no sistema',
            label: 'Funil ativo',
          },
          submit: 'Criar funil',
        },
      },
      update: {
        title: 'Funil de atualização',
        subtitle: 'Modificando os dados de um funil no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Identificando o nome do funil',
          },
          channelId: {
            title: 'Canal',
            description: 'Canal de comunicação associado ao funil',
          },
          status: {
            title: 'Funil ativo',
            description: 'Habilite ou desabilite o funil no sistema',
            label: 'Funil ativo',
          },
          submit: 'Funil de atualização',
        },
      },
      delete: {
        title: 'Excluir funil',
        subtitle: 'Esta ação é irreversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desativar este funil',
            label: 'Sim, quero excluir este funil',
          },
          submit: 'Eliminar',
        },
      },
      enable: {
        title: 'Ativar funil',
        subtitle: 'Habilitar funil no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este funil?',
            description: 'Habilitar um funil significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este funil',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar funil',
        subtitle: 'Desativar funil no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este funil?',
            description: 'Desativar um funil significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este funil',
          },
          submit: 'Desativar',
        },
      },
    },
    leads: {
      import: {
        title: 'Importação de leads',
        subtitle: 'Importando leads para o sistema',
        description: 'Carregando leads no sistema por meio de um arquivo csv. ',
        list: {
          columns: {
            name: 'Nome',
            errors: 'Número de erros',
            created: 'Data de importação',
          },
          one: 'Importar',
          many: 'Importações',
        },
        delete: {
          title: 'Excluir registro de importação',
          subtitle: 'Excluir registro de importação no sistema',
          form: {
            submit: 'Eliminar',
          },
        },
      },
      list: {
        title: 'Pistas',
        description: 'Gestão de potenciais clientes gerados através de campanhas',
        create: 'Liderar',
        one: 'Liderar',
        many: 'Pistas',
        columns: {
          name: 'Nome',
          activity: 'Atividade',
          responsible: 'Responsável',
          campaignName: 'Sino',
          started: 'Data estimada',
          clinicName: 'Clínica',
          created: 'Criação',
          resultId: 'Progresso',
          phones: 'Telefone',
          emails: 'E-mail',
        },
        csv: {
          select: 'IMPORTAR .CSV',
          somethingwentwrong: 'Leads não carregados',
          wrongdata: 'o arquivo está errado ou os dados não correspondem ao formato correto',
          loaded: 'Leads carregados corretamente',
          downloadCsv:
            'Alguns leads não foram carregados, você pode baixar a lista de leads ruins no botão de download',
        },
      },
      create: {
        title: 'Novo lead',
        subtitle: 'Cadastro de um novo lead no sistema',
        form: {
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar o lead.',
          },
          clinicId: {
            title: 'Clínica',
            description: 'A clínica preferida do lead. ',
          },
          contactId: {
            title: 'Contato',
            description: 'Contato associado ao lead. ',
          },
          campaignId: {
            title: 'Sino',
            description: 'A campanha de marketing atual do lead.',
          },
          submit: 'Criar lead',
        },
      },
      update: {
        title: 'Atualizar lead',
        subtitle: 'Modificando dados de leads no sistema',
        disabledBanner: {
          title: 'Lead inativo',
        },
        tabs: {
          lead: {
            title: 'Liderar',
            description:
              'Os detalhes do lead têm como objetivo entender a origem do lead e seu acompanhamento. ',
          },
          actions: {
            title: 'Tarefas',
            description: 'Tarefas são as ações realizadas para converter um lead em um doador. ',
          },
          responsible: {
            title: 'Responsável',
            description:
              'O responsável é o usuário responsável pela execução das tarefas de acompanhamento do lead.',
          },
          contact: {
            title: 'Contato',
            description: 'Um lead se origina de um contato. ',
          },
          notes: {
            title: 'Observações',
            description:
              'Observações são comentários adicionados aos leads para lembrar detalhes importantes ou para compartilhar informações com outros vendedores.',
          },
          specialist: {
            title: 'Especialista',
            description: 'O especialista designado para o líder',
          },
          communications: {
            title: 'Comunicações',
            description:
              'Integração com o sistema de mensagens para monitorar comunicações com leads.',
          },
        },
        leadPassport: {
          birthCountry: 'País de nascimento',
          birthDate: 'Data de nascimento',
          height: 'Altura',
          weight: 'Peso',
          contactTime: 'Preferência de horário',
          created: 'Data de criação',
          consent: {
            title: 'Consentimento',
            type: {
              legal: 'Jurídico',
              marketing: 'Marketing',
            },
          },
        },
        form: {
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar o lead.',
          },
          clinicId: {
            title: 'Clínica',
            description: 'A clínica preferida do lead. ',
          },
          contactId: {
            title: 'Contato',
            description: 'Contato associado ao lead. ',
          },
          campaignId: {
            title: 'Sino',
            description: 'A campanha de marketing atual do lead',
          },
          reasonId: {
            title: 'Razão',
            description: 'Razão',
          },
          submit: 'Atualizar lead',
        },
        lead: {
          tab: 'Liderar',
          description: 'Detalhes do lead',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsável',
              description: 'Usuário responsável pelo lead',
            },
            submit: 'Atribuir responsável',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description: 'Especialista designado para o lead',
            },
            submit: 'Atribuir especialista',
          },
        },
        actions: {
          tab: 'Tarefas',
          description: 'Liderar tarefas',
          add: 'Adicionar tarefa',
          create: {
            title: 'Nova tarefa',
            subtitle: 'Cadastro de uma nova tarefa no sistema',
            confirmationMessage: 'Tarefa não agendada. \n\n\n',
            form: {
              activityId: {
                title: 'Atividade',
                description: 'Atividade para fazer',
              },
              responsibleId: {
                title: 'Responsável',
                description: 'Responsável pela execução da atividade',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado da atividade',
              },
              started: {
                title: 'Data estimada',
                description: 'Data em que se espera que a atividade ocorra',
              },
              subject: {
                title: 'Assunto',
                description: 'Assunto da tarefa',
              },
              observation: {
                title: 'Observação',
                description: 'Um pequeno comentário sobre a atividade',
              },
              submit: 'Criar tarefa',
            },
            steps: {
              clinic: {
                form: 'Clínica selecionada',
              },
              lead: {
                form: 'Lead selecionado',
              },
            },
          },
          update: {
            title: 'Atualizar tarefa',
            subtitle: 'Modificando os dados de uma tarefa no sistema',
            form: {
              activityId: {
                title: 'Atividade',
                description: 'Atividade para fazer',
              },
              responsibleId: {
                title: 'Responsável',
                description: 'Responsável pela execução da atividade',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado da atividade',
              },
              started: {
                title: 'Data estimada',
                description: 'Data em que se espera que a atividade ocorra',
              },
              subject: {
                title: 'Assunto',
                description: 'Assunto da tarefa',
              },
              observation: {
                title: 'Observação',
                description: 'Um pequeno comentário sobre a atividade',
              },
              submit: 'Atualizar tarefa',
            },
            tabs: {
              action: {
                title: 'Tarefa',
                description: 'Informações associadas à tarefa',
              },
              actionEnding: {
                title: 'Realização',
                description: 'Informações associadas à conclusão da tarefa',
              },
              alerts: {
                title: 'Alertas',
                description: 'Alertas associados à tarefa. ',
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Resultado',
                },
                result: {
                  title: 'Resultado',
                  description: 'Resultado da atividade. ',
                },
                communicationChannel: {
                  title: 'Rota de comunicação',
                  description: 'Via de comunicação utilizada para a atividade',
                },
                submit: 'Concluir tarefa',
                submitNextSuggestion: 'Terminar',
              },
            },
            alert: {
              list: {
                title: 'Alertas',
                columns: {
                  expectedAlarmTime: 'Data do alarme',
                },
                many: 'Alertas',
                one: 'Alerta',
              },
              add: 'Adicionar alerta',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'Em geral',
              },
              notes: {
                title: 'Observações',
              },
              contactNotes: {
                title: 'Observações',
              },
            },
            fields: {
              newLead: 'Novo lead',
              active: 'Lead ativo',
              inactive: 'Lead inativo',
              canceled: 'Lead cancelado',
              converted: 'Lead convertido',
              clinic: 'Clínica',
              contactTime: 'Preferência de horário',
              responsible: 'Responsável',
              funnel: 'Funil',
              consent: 'Consentimento',
              campaign: 'Sino',
              originCampaign: 'Campanha de origem',
              created: 'Data de criação',
              legal: 'Jurídico',
              marketing: 'Marketing',
              donationPreference: 'Cidade',
              birthCountry: 'País de nascimento',
              birthDate: 'Data de nascimento',
              height: '(CM)',
              weight: '(KG)',
            },
          },
        },
      },
      delete: {
        title: 'Excluir lead',
        subtitle: 'Esta ação é irreversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desativar este lead',
            label: 'Sim, quero excluir este lead',
          },
          confirm: 'Esta ação irá deletar tudo relacionado ao lead, tem certeza?',
          submit: 'Eliminar',
        },
      },
      disable: {
        title: 'Desativar lead',
        subtitle: 'Desativar lead no sistema',
        form: {
          reasonId: {
            title: 'Razão',
            description: 'Motivo pelo qual o lead está desativado',
          },
          submit: 'Desativar',
        },
        confirmationMessage: 'Motivo não anexado. ',
      },
      enable: {
        title: 'Ativar lead',
        subtitle: 'Habilitar lead no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este lead?',
            description: 'Habilitar um lead significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este lead',
          },
          submit: 'Habilitar',
        },
      },
      addSpecialist: {
        title: 'Atribuir especialista',
        subtitle: 'Designar especialista para liderar',
        form: {
          specialistId: {
            title: 'Especialista',
            description: 'Especialista designado para o lead',
          },
          submit: 'Atribuir especialista',
        },
      },
    },
    reasons: {
      list: {
        title: 'Razões',
        description:
          'Gestão dos motivos pelos quais um potencial cliente pode cancelar a assinatura ou ser considerado inválido',
        create: 'Razão',
        one: 'Razão',
        many: 'Razões',
        columns: {
          name: 'Qualificação',
        },
      },
      create: {
        title: 'Novo motivo',
        subtitle: 'Cadastro de novo motivo no sistema',
        form: {
          name: {
            title: 'Qualificação',
            description: 'Título de identificação do motivo',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição detalhada do cancelamento de lead',
          },
          color: {
            title: 'Cor',
            description: 'Cor associada ao motivo',
          },
          notify: {
            title: 'Notificar',
            description: 'Notificar ou não o motivo no sistema',
            label: 'Notificar motivo',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone associado ao motivo',
            label: 'Selecione o ícone...',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          canceled: {
            title: 'Baixo',
            description:
              'Ao selecionar esta opção, indica-se que este motivo atribui o estatuto de levantamento permanente. ',
            label: 'Motivo da retirada',
          },
          subject: {
            title: 'Assunto',
            description: 'Problema de motivo',
          },
          message: {
            title: 'Mensagem',
            description: 'Mensagem de motivo',
          },
          submit: 'Criar motivo',
        },
      },
      enable: {
        title: 'Ativar motivo',
        subtitle: 'Habilite o motivo no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esse motivo?',
            description: 'Habilitar um motivo significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar esse motivo',
          },
          submit: 'Ativar motivo',
        },
      },
      disable: {
        title: 'Desativar motivo',
        subtitle: 'Desativar motivo no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esse motivo?',
            description: 'Desativar um motivo significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar esse motivo',
          },
          submit: 'Desativar motivo',
        },
      },
      update: {
        title: 'Motivo da atualização',
        subtitle: 'Modificando os dados de um motivo no sistema',
        tabs: {
          reason: {
            title: 'Razão',
            description: 'Informação do motivo',
          },
          notification: {
            title: 'Definir notificação',
            description:
              'Definir a notificação a ser enviada quando ocorrer um cancelamento por este motivo específico',
          },
          activity: {
            title: 'Próxima atividade',
            description:
              'Caso esta seção de motivo esteja configurada, uma vez desabilitado o Lead com este motivo, será sugerida uma atividade a ser realizada. ',
          },
        },
        reason: {
          form: {
            name: {
              title: 'Qualificação',
              description: 'Título de identificação do motivo',
            },
            description: {
              title: 'Descrição',
              description: 'Descrição detalhada do cancelamento de lead',
            },
            color: {
              title: 'Cor',
              description: 'Cor associada ao motivo',
            },
            notify: {
              title: 'Notificar',
              description: 'Notificar ou não o motivo no sistema',
              label: 'Notificar motivo',
            },
            icon: {
              title: 'Ícone',
              description: 'Ícone associado ao motivo',
              label: 'Selecione o ícone...',
              search: {
                placeholder: 'Ícone de pesquisa...',
              },
            },
            canceled: {
              title: 'Baixo',
              description:
                'Ao selecionar esta opção, indica-se que este motivo atribui o estatuto de levantamento permanente. ',
              label: 'Motivo da retirada',
            },
            subject: {
              title: 'Assunto',
              description: 'Problema de motivo',
            },
            message: {
              title: 'Mensagem',
              description: 'Mensagem de motivo',
            },
            submit: 'Motivo da atualização',
          },
          notification: {
            form: {
              notify: {
                title: 'Ativar notificação',
                description: 'Notificar ou não quando ocorrer um cancelamento por este motivo',
                label: 'Ativar sistema de notificação',
              },
              subject: {
                title: 'Assunto',
                description: 'Assunto da notificação',
              },
              message: {
                title: 'Mensagem',
                description: 'Mensagem a ser exibida na notificação',
              },
              submit: 'Salvar configurações',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Próxima atividade',
              description: 'Atividade sugerida para fazer quando ocorre um cancelamento',
            },
            nextActivityDelay: {
              title: 'Tempo de espera',
              description: 'Tempo de espera para realizar a atividade sugerida, em dias',
            },
            submit: 'Manter',
          },
        },
      },
    },
    patients: {
      list: {
        title: {
          ovoclinic: 'Pacientes',
          ovobank: 'Doadores',
        },
        description: {
          ovoclinic: 'Gerenciamento de pacientes no sistema',
          ovobank: 'Gestão de doadores no sistema',
        },
        create: {
          ovoclinic: 'Paciente',
          ovobank: 'Doador',
        },
        one: {
          ovoclinic: 'Paciente',
          ovobank: 'Doador',
        },
        many: {
          ovoclinic: 'Pacientes',
          ovobank: 'Doadores',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contato',
              ovobank: 'Contato',
            },
          },
          description: {
            ovoclinic: 'Descrição',
            ovobank: 'Descrição',
          },
          phones: {
            ovoclinic: 'Telefone',
            ovobank: 'Telefone',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'clínica de ciclo',
              ovobank: 'clínica de ciclo',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clínica',
              ovobank: 'Clínica',
            },
          },
          created: {
            ovoclinic: 'Criação',
            ovobank: 'Criação',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'Novo paciente',
          ovobank: 'Novo doador',
        },
        subtitle: {
          ovoclinic: 'Cadastro de novo paciente no sistema',
          ovobank: 'Cadastro de novo doador no sistema',
        },
        form: {
          description: {
            title: {
              ovobank: 'Descrição',
              ovoclinic: 'Descrição',
            },
            description: {
              ovoclinic: 'Uma breve descrição para detalhar o paciente.',
              ovobank: 'Uma breve descrição para detalhar o doador.',
            },
          },
          leadId: {
            title: 'Liderar',
            description: {
              ovoclinic: 'Lead associado ao paciente',
              ovobank: 'Lead associado ao doador',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'A clínica preferida do paciente.',
              ovobank: 'A clínica preferida do doador.',
            },
          },
          campaignId: {
            title: 'Sino',
            description: {
              ovoclinic: 'A campanha de marketing doméstico do paciente. ',
              ovobank: 'A campanha de marketing doméstica do doador. ',
            },
          },
          contactId: {
            title: 'Contato',
            description: {
              ovoclinic: 'O contato através do qual você se tornou paciente.',
              ovobank: 'O contato através do qual você se tornou um doador.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Contrato de garantia',
              ovobank: 'Contrato de garantia',
            },
            description: {
              ovoclinic: 'O paciente aceita o acordo de garantia',
              ovobank: 'O doador aceita o acordo de garantia',
            },
            label: {
              ovoclinic: 'Sim, aceito o contrato de garantia',
              ovobank: 'Sim, aceito o contrato de garantia',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimento',
              ovobank: 'Consentimento',
            },
            description: {
              ovoclinic: 'O paciente dá consentimento para ser tratado',
              ovobank: 'O doador dá consentimento para ser tratado',
            },
            label: {
              ovoclinic: 'Sim, dou meu consentimento',
              ovobank: 'Sim, dou meu consentimento',
            },
          },
          submit: {
            ovoclinic: 'Criar paciente',
            ovobank: 'Criar doador',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamento',
              },
              phase: {
                form: 'Estágio',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Estágio',
                description: 'Estágio de tratamento',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Programa',
                description: 'Nome do programa',
              },
              phaseId: {
                title: 'Estágio',
                description: 'Estágio de tratamento',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Atualizar paciente',
          ovobank: 'Atualizar doador',
        },
        subtitle: {
          ovoclinic: 'Modificando dados do paciente no sistema',
          ovobank: 'Modificação dos dados de um doador no sistema',
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Paciente',
              ovobank: 'Doador',
            },
            description: {
              ovoclinic: 'Detalhes do paciente',
              ovobank: 'Detalhes do doador',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contato',
              ovobank: 'Contato',
            },
            description: {
              ovoclinic: 'Detalhes de contato do paciente',
              ovobank: 'Detalhes de contato do doador',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsável',
              ovobank: 'Responsável',
            },
            description: {
              ovoclinic: 'Paciente Responsável',
              ovobank: 'Responsável pelo doador',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Especialista',
              ovobank: 'Especialista',
            },
            description: {
              ovoclinic: 'Especialista atribuído ao paciente',
              ovobank: 'Especialista atribuído ao doador',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Recibos',
              ovobank: 'Recibos',
            },
            description: 'Lista de recebimentos associados ao cliente',
            list: {
              create: 'Recibo',
            },
            many: 'Recibos',
            one: 'Recibo',
            columns: {
              receipt: {
                name: 'Nome',
                clinic: 'Clínica',
                created: 'F. criação',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Criar pagamento',
                  },
                  many: 'Pagamentos',
                  one: 'Pagar',
                  columns: {
                    payment: {
                      price: 'Preço',
                      clinic: 'Clínica',
                      name: 'Nome',
                      created: 'F. criação',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Contratando',
              ovobank: 'Contratando',
            },
            description: {
              ovoclinic: 'Contratações de pacientes',
              ovobank: 'Contratação de doadores',
            },
            one: 'Contratando',
            many: 'Contratando',
            columns: {
              price: 'Preço',
              purchaseType: 'Cara',
              treatmentName: 'Nome',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Observações',
              ovobank: 'Observações',
            },
            description: {
              ovoclinic: 'Observações do paciente',
              ovobank: 'Observações dos doadores',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documentos',
              ovobank: 'Documentos',
            },
            description: {
              ovoclinic: 'Documentos carregados pelo paciente',
              ovobank: 'Documentos enviados pelo doador',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Tratamento',
              ovobank: 'Tratamento',
            },
            description: {
              ovoclinic: 'Programa do paciente',
              ovobank: 'Programa de Doadores',
            },
            one: 'Programa',
            many: 'Programas',
            submit: 'Adicionar programa',
            columns: {
              programName: 'Programa',
              estimated: 'Data estimada',
              finished: 'Data de término',
            },
            document: {
              delete: {
                title: 'Excluir arquivo',
                subtitle:
                  'Tem certeza de que deseja excluir o arquivo. Ele também será excluído da nuvem?',
                submit: 'Excluir',
              },
              columns: {
                programName: 'Programa',
                estimated: 'Data estimada',
                finished: 'Data de término',
                documentName: 'Documento',
                created: 'Criado',
                size: 'Tamanho',
                vRepoUploaded: 'Sincronizado com Vrepo',
                type: 'Cara',
              },
              select: 'Carregar um arquivo pdf',
              unselected: 'Nenhum arquivo selecionado',
              unvalid: 'Selecione um arquivo PDF válido.',
              submit: 'Enviar',
              many: 'Arquivos',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Tarefas',
              ovobank: 'Tarefas',
            },
            description: {
              ovoclinic: 'Gestão das tarefas às quais está atribuído. ',
              ovobank: 'Gestão das tarefas às quais está atribuído. ',
            },
            add: 'Adicionar tarefa',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'Em geral',
              },
              notes: {
                title: 'Observações',
              },
              contactNotes: {
                title: 'Observações',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'Novo paciente',
                ovobank: 'Novo doador',
              },
              active: {
                ovoclinic: 'Paciente ativo',
                ovobank: 'Doador ativo',
              },
              inactive: {
                ovoclinic: 'Paciente inativo',
                ovobank: 'Doador inativo',
              },
              clinic: 'Clínica',
              contactTime: 'Preferência de horário',
              responsible: 'Responsável',
              funnel: 'Origem',
              consent: 'Aprovação',
              consentCheck: 'Consentimento',
              warrantyAgreement: 'Acordo de garantia',
              campaign: 'Sino',
              originCampaign: 'Campanha de origem',
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Paciente',
            ovobank: 'Doador',
          },
          description: {
            ovoclinic: 'Detalhes do paciente',
            ovobank: 'Detalhes do doador',
          },
        },
        contact: {
          tab: 'Contato',
          description: {
            ovoclinic: 'Detalhes de contato do paciente',
            ovobank: 'Detalhes de contato do doador',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsável',
                ovobank: 'Responsável',
              },
              description: {
                ovoclinic: 'Usuário paciente responsável',
                ovobank: 'Usuário doador responsável',
              },
            },
            submit: 'Atribuir responsável',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description: {
                ovoclinic: 'Especialista atribuído ao paciente',
                ovobank: 'Especialista atribuído ao doador',
              },
            },
            submit: 'Atribuir especialista',
          },
        },
        receipts: {
          create: {
            title: 'Criar recibo',
            subtitle: {
              ovoclinic: 'Criar recibo associado ao paciente',
              ovobank: 'Criar recibo associado ao doador',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Criar recibo',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Recibo',
                description: {
                  ovoclinic: 'Atualizar recibo associado ao paciente',
                  ovobank: 'Atualizar recibo associado ao doador',
                },
              },
              payments: {
                title: 'Pagamentos',
                description: 'Pagamentos da fatura associada ao cliente',
                list: {
                  create: 'Adicionar pagamento',
                },
              },
            },
            title: 'Atualizar recibo',
            subtitle: {
              ovoclinic: 'Atualizar recibo associado ao paciente',
              ovobank: 'Atualizar recibo associado ao doador',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Salvar recibo',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Descrição',
              ovobank: 'Descrição',
            },
            description: {
              ovoclinic: 'Uma breve descrição para adicionar comentários sobre o paciente.',
              ovobank: 'Uma breve descrição para adicionar comentários sobre o doador.',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'A clínica na qual o paciente deseja ser tratado.',
              ovobank: 'A clínica onde o doador deseja ser tratado.',
            },
          },
          leadId: {
            title: 'Liderar',
            description: {
              ovoclinic: 'Lead associado ao paciente',
              ovobank: 'Lead associado ao doador',
            },
          },
          contactId: {
            title: 'Contato',
            description: {
              ovoclinic: 'As informações de contato através das quais o paciente chegou à clínica.',
              ovobank: 'Os dados de contato pelos quais o doador chegou à clínica.',
            },
          },
          campaignId: {
            title: 'Sino',
            description: {
              ovoclinic: 'A campanha de marketing doméstico do paciente. ',
              ovobank: 'A campanha de marketing doméstica do doador. ',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Contrato de garantia',
              ovobank: 'Contrato de garantia',
            },
            description: {
              ovoclinic: 'O paciente aceita o acordo de garantia',
              ovobank: 'O doador aceita o acordo de garantia',
            },
            label: {
              ovoclinic: 'Sim, aceito o contrato de garantia',
              ovobank: 'Sim, aceito o contrato de garantia',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimento',
              ovobank: 'Consentimento',
            },
            description: {
              ovoclinic: 'O paciente dá consentimento para ser tratado',
              ovobank: 'O doador dá consentimento para ser tratado',
            },
            label: {
              ovoclinic: 'Sim, dou meu consentimento',
              ovobank: 'Sim, dou meu consentimento',
            },
          },
          submit: {
            ovoclinic: 'Atualizar paciente',
            ovobank: 'Atualizar doador',
          },
        },
        payments: {
          create: {
            title: 'Criar pagamento',
            subtitle: {
              ovoclinic: 'Adicione o pagamento associado ao recibo do paciente',
              ovobank: 'Adicione o pagamento associado ao recibo do doador',
            },
            form: {
              price: {
                title: 'Preço',
              },
              submit: 'Salvar preço',
            },
          },
          update: {
            title: 'Atualizar pagamento',
            subtitle: {
              ovoclinic: 'Atualizar pagamento associado ao recibo do paciente',
              ovobank: 'Atualizar pagamento associado ao recibo do doador',
            },
            form: {
              price: {
                title: 'Preço',
              },
              submit: 'Salvar preço',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Tratamento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Desativar paciente',
          ovobank: 'Desativar doador',
        },
        subtitle: {
          ovoclinic: 'Desativar paciente no sistema',
          ovobank: 'Desativar doador no sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Tem certeza de que deseja desabilitar este paciente?',
              ovobank: 'Tem certeza de que deseja desativar este doador?',
            },
            description: {
              ovoclinic:
                'Desabilitar um paciente significa que ele não poderá ser usado no sistema',
              ovobank: 'Desabilitar um doador significa que ele não poderá ser usado no sistema',
            },
            label: {
              ovoclinic: 'Sim, quero desabilitar este paciente',
              ovobank: 'Sim, quero desativar este doador',
            },
          },
          submit: 'Desativar',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Habilitar paciente',
          ovobank: 'Habilitar doador',
        },
        subtitle: {
          ovoclinic: 'Habilitar paciente no sistema',
          ovobank: 'Habilitar doador no sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Tem certeza de que deseja ativar este paciente?',
              ovobank: 'Tem certeza de que deseja ativar este doador?',
            },
            description: {
              ovoclinic: 'Habilitar um paciente significa que ele pode ser usado no sistema',
              ovobank: 'Habilitar um doador significa que ele pode ser usado no sistema',
            },
            label: {
              ovoclinic: 'Sim, quero habilitar este paciente',
              ovobank: 'Sim, quero permitir que este doador',
            },
          },
          submit: 'Habilitar',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Excluir paciente',
          ovobank: 'Excluir doador',
        },
        subtitle: {
          ovoclinic: 'Esta ação é irreversível',
          ovobank: 'Esta ação é irreversível',
        },
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: {
              ovoclinic: 'Você pode preferir desativar este paciente',
              ovobank: 'Você pode preferir desativar este doador',
            },
            label: {
              ovoclinic: 'Sim, quero excluir este paciente',
              ovobank: 'Sim, quero excluir este doador',
            },
          },
          confirm: {
            ovoclinic: 'Essa ação vai eliminar tudo relacionado ao paciente, tem certeza?',
            ovobank: 'Esta ação irá deletar tudo relacionado ao doador, tem certeza?',
          },
          submit: 'Eliminar',
        },
      },
      actions: {
        create: {
          title: 'Nova tarefa',
          subtitle: 'Cadastro de uma nova tarefa no sistema',
          form: {
            activityId: {
              title: 'Atividade',
              description: 'Atividade para fazer',
            },
            responsibleId: {
              title: 'Responsável',
              description: 'Usuário responsável pela tarefa',
            },
            started: {
              title: 'Data estimada',
              description: 'Data em que se espera que a atividade ocorra',
            },
            subject: {
              title: 'Assunto',
              description: 'Assunto da tarefa',
            },
            observation: {
              title: 'Observação',
              description: 'Um pequeno comentário sobre a atividade',
            },
            submit: 'Criar tarefa',
          },
        },
        update: {
          title: 'Atualizar tarefa',
          subtitle: 'Modificando os dados de uma tarefa no sistema',
          actionEnding: {
            form: {
              submit: 'Concluir tarefa',
              submitNextSuggestion: 'Finalizar e planejar',
            },
          },
          form: {
            result: {
              false: {
                title: 'Resultado',
              },
              result: {
                title: 'Resultado',
                description: 'Resultado da atividade. ',
              },
            },
            communicationChannel: {
              title: 'Rota de comunicação',
              description: 'Via de comunicação utilizada para a atividade',
            },
            activityId: {
              title: 'Atividade',
              description: 'Atividade para fazer',
            },
            responsibleId: {
              title: 'Responsável',
              description: 'Usuário responsável pela tarefa',
            },
            started: {
              title: 'Data estimada',
              description: 'Data em que se espera que a atividade ocorra',
            },
            subject: {
              title: 'Assunto',
              description: 'Assunto da tarefa',
            },
            observation: {
              title: 'Observação',
              description: 'Um pequeno comentário sobre a atividade',
            },
            submit: 'Atualizar tarefa',
          },
          tabs: {
            action: {
              title: 'Tarefa',
              description: 'Informações associadas à tarefa',
            },
            actionEnding: {
              title: 'Realização',
              description: 'Informações associadas à conclusão da tarefa',
            },
            alerts: {
              title: 'Alertas',
              description: 'Alertas associados à tarefa',
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: 'Atualizar contratação',
        subtitle: {
          ovoclinic: 'Modificação dos dados de um contrato de paciente',
          ovobank: 'Modificação dos dados de um contrato de doador',
        },
        form: {
          price: {
            title: 'Preço',
            description: 'Preço do contrato',
          },
          submit: 'Atualizar contratação',
        },
      },
      disable: {
        title: 'Desativar contratação',
        subtitle: {
          ovoclinic: 'Desabilitar recrutamento de pacientes',
          ovobank: 'Desabilitar recrutamento de doadores',
        },
        form: {
          submit: 'Desativar',
        },
      },
      enable: {
        title: 'Habilitar contratação',
        subtitle: {
          ovoclinic: 'Habilite o recrutamento de pacientes',
          ovobank: 'Permitir a contratação de doadores',
        },
        form: {
          submit: 'Habilitar',
        },
      },
    },
    treatments: {
      create: {
        title: 'Novo programa',
        subtitle: 'Cadastro de um novo programa no sistema',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          estimated: {
            title: 'Data estimada',
            description: 'Data prevista para a realização do programa',
          },
          finished: {
            title: 'Data de término',
            description: 'Data em que o programa foi agendado',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase em que o programa está',
          },
          discount: {
            title: 'Porcentagem de desconto',
            description: 'Desconto aplicado ao programa',
          },
          submit: 'Criar programa',
        },
      },
      update: {
        title: 'Programa de atualização',
        subtitle: 'Modificando dados do programa no sistema',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase em que o programa está',
          },
          estimated: {
            title: 'Data estimada',
            description: 'Data prevista para a realização do programa',
          },
          finished: {
            title: 'Data de término',
            description: 'Data em que o programa foi agendado',
          },
          discount: {
            title: 'Porcentagem de desconto',
            description: 'Desconto aplicado ao programa',
          },
          submit: 'Programa de atualização',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Programa',
              ovobank: 'Programa',
            },
            description: {
              ovoclinic: 'Informações do programa',
              ovobank: 'Informações do programa',
            },
          },
          program: {
            title: {
              ovoclinic: 'Tratamento',
            },
            description: {
              ovoclinic: 'Informações sobre tratamento',
              ovobank: 'Informações sobre tratamento',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic:
                'São as técnicas complementares que foram criadas em relação a cada programa. ',
              ovobank:
                'São as técnicas complementares que foram criadas em relação a cada programa. ',
            },
            many: 'Técnicas',
            one: 'Técnica',
            add: 'Adicionar técnica',
          },
          maintenances: {
            title: {
              ovoclinic: 'Manutenção',
              ovobank: 'Manutenção',
            },
            description: {
              ovoclinic: 'Manutenção do tratamento. ',
              ovobank: 'Manutenção do tratamento. ',
            },
            many: 'Manutenção',
            one: 'Manutenção',
            add: 'Adicionar manutenção',
          },
          interventions: {
            title: {
              ovoclinic: 'Tratamentos',
              ovobank: 'Tratamentos',
            },
            description: {
              ovoclinic: 'Ciclos realizados nos tratamentos. ',
              ovobank: 'Ciclos realizados nos tratamentos. ',
            },
            many: 'Tratamentos',
            one: 'Tratamento',
            columns: {
              interventionClinicName: 'Clínica',
              observation: 'Observação',
              expectedDate: 'Data estimada',
              interventionDate: 'Data da intervenção',
              cycleName: 'Ciclo',
            },
            add: 'Adicionar tratamento',
          },
        },
      },
      disable: {
        title: 'Desativar tratamento',
        subtitle: 'Desabilitar tratamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este tratamento?',
            description:
              'Desabilitar um tratamento significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este tratamento',
          },
          submit: 'Desativar tratamento',
        },
      },
      enable: {
        title: 'Ativar tratamento',
        subtitle: 'Habilitar tratamento no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este tratamento?',
            description: 'Habilitar um tratamento significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este tratamento',
          },
          submit: 'Ativar tratamento',
        },
      },
      list: {
        title: {
          ovoclinic: 'Tratamentos',
          ovobank: 'Tratamentos',
        },
        description: {
          ovoclinic: 'Gerenciamento de tratamentos realizados em pacientes',
          ovobank: 'Gestão de tratamentos realizados em doadores',
        },
        create: 'Tratamento',
        one: 'Tratamento',
        many: {
          ovoclinic: 'Tratamentos',
          ovobank: 'Tratamentos',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Ciclo',
            ovobank: 'Ciclo',
          },
          expectedDate: {
            ovoclinic: 'Data estimada',
            ovobank: 'Data estimada',
          },
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Doador',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          phaseName: {
            ovoclinic: 'Estágio',
            ovobank: 'Estágio',
          },
          estimated: {
            ovoclinic: 'Data estimada',
            ovobank: 'Data estimada',
          },
          finished: {
            ovoclinic: 'Data de término',
            ovobank: 'Data de término',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: 'Habilitar intervenção',
        subtitle: 'Habilitar intervenção no sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar intervenção',
        subtitle: 'Desabilitar intervenção no sistema',
        form: {
          submit: 'Desativar',
        },
      },
      create: {
        title: 'Novo tratamento',
        subtitle: 'Registro de um novo tratamento',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica onde o tratamento foi realizado',
          },
          interventionDate: {
            title: 'Data da intervenção',
            description: 'Data em que o ciclo foi realizado',
          },
          observation: {
            title: 'Observação',
            description: 'Observação sobre o tratamento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo de ciclo a realizar',
          },
          expectedDate: {
            title: 'Data estimada',
            description: 'Data estimada do ciclo',
          },
          submit: 'Criar tratamento',
        },
      },
      update: {
        title: 'Atualizar tratamento',
        subtitle: 'Modificação de dados de tratamento no sistema',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica onde o tratamento foi realizado',
          },
          interventionDate: {
            title: 'Data da intervenção',
            description: 'Data em que o ciclo foi realizado',
          },
          observation: {
            title: 'Observação',
            description: 'Observação sobre o tratamento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo de ciclo a realizar',
          },
          expectedDate: {
            title: 'Data estimada',
            description: 'Data estimada do ciclo',
          },
          submit: 'Atualizar tratamento',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Tratamento',
              ovobank: 'Tratamento',
            },
            description: {
              ovoclinic: 'Informações sobre tratamento',
              ovobank: 'Informações sobre tratamento',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic: 'Técnicas de intervenção. ',
              ovobank: 'Técnicas de intervenção. ',
            },
          },
        },
        techniques: {
          delete: {
            title: 'Técnica clara',
            subtitle: 'Excluir técnica de intervenção',
            form: {
              submit: 'Técnica clara',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'Novo procedimento',
        subtitle: 'Cadastro de novo procedimento de intervenção no sistema',
        form: {
          techniqueId: {
            title: 'Técnica',
            description: 'Técnica associada ao procedimento',
          },
          name: {
            title: 'Nome',
            description: 'Nome de identificação do procedimento',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição detalhada do procedimento',
          },
          submit: 'Criar procedimento',
        },
      },
      update: {
        title: 'Procedimento de atualização',
        subtitle: 'Modificando os dados de um procedimento no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do procedimento',
          },
          description: {
            title: 'Descrição',
            description: 'Descrição detalhada do procedimento',
          },
          price: {
            title: 'Preço',
            description: 'Preço do procedimento',
          },
          submit: 'Procedimento de atualização',
        },
      },
      delete: {
        title: 'Excluir procedimento',
        subtitle: 'Excluir procedimento no sistema',
        form: {
          submit: 'Excluir procedimento',
        },
      },
    },
    maintenances: {
      create: {
        title: 'Nova manutenção',
        subtitle: 'Cadastro de nova manutenção para tratamento no sistema',
        form: {
          serviceId: {
            title: 'Serviço',
            description: 'Serviço associado à manutenção',
          },
          submit: 'Criar manutenção',
        },
      },
      update: {
        title: 'Manutenção de atualização',
        subtitle: 'Modificação de dados de manutenção no sistema',
        form: {
          serviceId: {
            title: 'Serviço',
            description: 'Serviço associado à manutenção',
          },
          amount: {
            title: 'Preço',
            description: 'Preço de manutenção',
          },
          paymentStatus: {
            title: 'Status do pagamento',
            description: 'Status do pagamento de manutenção',
          },
          estimatedPaymentDate: {
            title: 'Data estimada de pagamento',
            description: 'Data estimada de pagamento da manutenção',
          },
          submit: 'Manutenção de atualização',
        },
        tabs: {
          maintenance: {
            title: 'Manutenção',
            description: 'Informações de manutenção',
          },
          action: {
            title: 'Tarefa',
            description: 'Informações associadas à tarefa',
          },
        },
      },
      delete: {
        title: 'Excluir manutenção',
        subtitle: 'Excluir manutenção no sistema',
        form: {
          submit: 'Excluir manutenção',
        },
      },
      list: {
        title: {
          ovoclinic: 'Manutenção',
          ovobank: 'Manutenção',
        },
        description: {
          ovoclinic: 'Gestão da manutenção realizada em pacientes',
          ovobank: 'Gestão da manutenção realizada nos doadores',
        },
        create: 'Manutenção',
        one: {
          ovoclinic: 'Manutenção',
          ovobank: 'Manutenção',
        },
        many: {
          ovoclinic: 'Manutenção',
          ovobank: 'Manutenção',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Doador',
          },
          serviceName: {
            ovoclinic: 'Serviço',
            ovobank: 'Serviço',
          },
          amount: {
            ovoclinic: 'Preço',
            ovobank: 'Preço',
          },
          paymentStatus: {
            ovoclinic: 'Status do pagamento',
            ovobank: 'Status do pagamento',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Data estimada de pagamento',
            ovobank: 'Data estimada de pagamento',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          estimated: {
            ovoclinic: 'Data estimada',
            ovobank: 'Data estimada',
          },
          finished: {
            ovoclinic: 'Data de término',
            ovobank: 'Data de término',
          },
        },
      },
      enable: {
        title: 'Habilitar manutenção',
        subtitle: 'Habilite a manutenção no sistema',
        form: {
          submit: 'Habilitar manutenção',
        },
      },
      disable: {
        title: 'Desativar manutenção',
        subtitle: 'Desative a manutenção no sistema',
        form: {
          submit: 'Desativar manutenção',
        },
      },
    },
    actions: {
      enable: {
        title: 'Habilitar ação',
        subtitle: 'Habilitar ação no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta ação?',
            description: 'Habilitar uma ação significa que ela pode ser usada no sistema',
            label: 'Sim, quero ativar esta ação',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar ação',
        subtitle: 'Desabilitar ação no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta ação?',
            description: 'Desabilitar uma ação significa que ela não pode ser usada no sistema',
            label: 'Sim, quero desativar esta ação',
          },
          submit: 'Desativar',
        },
      },
      list: {
        title: 'Calendário',
        description: 'Calendário de tarefas relacionadas a leads',
      },
      delete: {
        title: 'Excluir tarefa',
        subtitle: 'Esta ação é irreversível',
        form: {
          submit: 'Eliminar',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Alertas',
            subtitle: 'Adicione alertas à tarefa',
            form: {
              alertTime: {
                title: 'Hora do alerta',
                description:
                  'Insira o número de minutos de antecedência para receber o aviso da tarefa',
              },
              submit: 'Adicionar alerta',
            },
          },
          delete: {
            title: 'Excluir alerta',
            subtitle: 'Excluir alerta de tarefa',
            form: {
              submit: 'Excluir alerta',
            },
          },
          update: {
            title: 'Alerta de atualização',
            subtitle: 'Atualizar alerta de tarefa',
            form: {
              alertTime: {
                title: 'Hora do alerta',
                description:
                  'Insira o número de minutos de antecedência para receber o aviso da tarefa',
              },
              submit: 'Alerta de atualização',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Nome',
          phones: 'Telefone',
          contactTime: 'Franja',
          consents: 'Consentimentos',
          marketing: 'Marketing',
          legal: 'Jurídico',
          created: 'Data de criação',
        },
        title: {
          ovoclinic: 'Contatos',
          ovobank: 'Contatos',
        },
        description: {
          ovoclinic: 'Gestão dos contactos existentes na aplicação. ',
          ovobank: 'Gestão dos contactos existentes na aplicação. ',
        },
        create: {
          ovoclinic: 'Contato',
          ovobank: 'Contato',
        },
        one: 'Contato',
        many: 'Contatos',
      },
      create: {
        title: {
          ovoclinic: 'Novo contato',
          ovobank: 'Novo contato',
        },
        subtitle: {
          ovoclinic: 'Pessoa e depois associá-la a um lead ou paciente',
          ovobank: 'Pessoa e depois associá-la a um lead ou doador',
        },
        form: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Sobrenomes',
          },
          prefix: {
            title: 'Prefixo',
            description: 'Prefixo de contato',
          },
          phone: {
            title: 'Telefone',
            description: 'Telefone de contato',
          },
          email: {
            title: 'E-mail',
            description: 'E-mail de contato',
          },
          zip: {
            title: 'CEP',
            description: 'CEP do contato',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'O contato concorda em receber informações de marketing',
          },
          birthDate: {
            title: 'Data de nascimento',
            description: 'Data de nascimento do contato',
          },
          birthCountry: {
            title: 'País de nascimento',
            description: 'País de nascimento do contato',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso de contato em quilogramas',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura de contato em centímetros',
          },
          donationPreference: {
            title: 'Preferência de cidade',
            description: 'Preferência de cidade onde fazer a doação',
          },
          legal: {
            title: 'Condições Legais',
            label: 'Aceite as condições legais',
            description: 'O contacto aceita as condições legais',
          },
          contactTime: {
            title: 'Horário de contato',
            description: 'Hora em que o contato deseja ser contatado',
          },
          clinicId: {
            title: 'Clínica',
            description: 'OvoCRM permite criar contatos para múltiplas clínicas. ',
          },
          campaignId: {
            title: 'Sino',
            description: 'A campanha de marketing de origem do contato. ',
          },
          center: {
            title: 'Localização',
            description: 'Local de contato',
          },
          photo: {
            title: 'Foto',
            description: 'Foto de contato',
          },
          submit: 'Criar contato',
        },
      },
      update: {
        title: {
          ovoclinic: 'Atualizar contato',
          ovobank: 'Atualizar contato',
        },
        subtitle: {
          ovoclinic: 'Informações pessoais do líder ou paciente',
          ovobank: 'Informações pessoais do líder ou doador',
        },
        tabs: {
          account: {
            title: 'Dados Pessoais',
            description: 'Informações detalhadas de contato',
          },
          actions: {
            title: 'Atividades',
            description: 'Lista de atividades criadas no contato',
          },
          leads: {
            title: 'Campanhas',
            description: 'Campanhas pelas quais este contato entrou. ',
          },
          contact: {
            title: 'Contato',
            description: 'Informações detalhadas de contato',
          },
          receipts: {
            title: 'Recibos',
            description: {
              ovoclinic: 'Recibos criados no contato. ',
              ovobank: 'Recibos criados no contato. ',
            },
          },
          notes: {
            title: 'Observações',
            description: 'Informações ou comentários sobre o contato. ',
          },
          purchases: {
            title: 'Contratando',
            description: 'Contratação feita pelo contato. ',
          },
        },
        form: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Sobrenomes',
          },
          dni: {
            title: 'EU IA',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'O contato concorda em receber informações de marketing',
          },
          legal: {
            title: 'Condições Legais',
            label: 'Aceite as condições legais',
            description: 'O contacto aceita as condições legais',
          },
          contactTime: {
            title: 'Horário de contato',
            description: 'Hora em que o contato deseja ser contatado',
          },
          center: {
            title: 'Localização',
            description: 'Local de contato',
          },
          prefix: {
            title: 'Prefixo',
            description: 'Prefixo de contato',
          },
          phone: {
            title: 'Telefone',
            description: 'Telefone de contato',
          },
          email: {
            title: 'E-mail',
            description: 'E-mail de contato',
          },
          birthDate: {
            title: 'Data de nascimento',
            description: 'Data de nascimento do contato',
          },
          birthCountry: {
            title: 'País de nascimento',
            description: 'País de nascimento do contato',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso de contato em quilogramas',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura de contato em centímetros',
          },
          donationPreference: {
            title: 'Preferência de cidade',
            description: 'Preferência de cidade onde fazer a doação',
          },
          photo: {
            title: 'Foto',
            description: 'Foto de contato',
          },
          submit: 'Atualizar contato',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Cara',
              treatmentName: 'Tratamento',
              price: 'Preço',
            },
            one: 'Contratando',
            many: 'Contratando',
          },
        },
      },
      delete: {
        title: 'Excluir contato',
        subtitle: 'Esta ação é irreversível',
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desativar este contato',
            label: 'Sim, quero excluir este contato',
          },
          confirm: 'Esta ação irá deletar tudo relacionado ao contato, tem certeza?',
          submit: 'Eliminar',
        },
      },
      enable: {
        title: 'Ativar contato',
        subtitle: 'Habilitar contato no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este contato?',
            description: 'Habilitar um contato significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este contato',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar contato',
        subtitle: 'Desativar contato no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este contato?',
            description: 'Desabilitar um contato significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este contato',
          },
          submit: 'Desativar',
        },
      },
      notes: {
        source: {
          LEAD: 'Liderar',
          PATIENT: {
            ovoclinic: 'Paciente',
            ovobank: 'Doador',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campanhas',
        description: 'Gestão de campanhas de marketing. ',
        userId: {
          title: 'Responsável',
          description: 'Gerente de campanha',
        },
        submit: 'Atribuir gerente',
        create: 'Sino',
        one: 'Sino',
        many: 'Campanhas',
        columns: {
          name: 'Nome',
          leads: 'Pistas',
          leadsStatus: 'Ativo/Inativo',
          funnelName: 'Funil',
          languageName: 'Linguagem',
          clinics: 'Clínicas',
          managers: 'Gerentes',
        },
      },
      create: {
        title: 'Nova campanha',
        subtitle: 'Cadastro de uma nova campanha no sistema',
        form: {
          image: {
            title: 'Cobrir',
            description:
              'Nós encorajamos você a fazer upload de uma imagem para melhorar a experiência comercial',
          },
          name: {
            title: 'Qualificação',
            description: 'Título da campanha de marketing',
          },
          facebookId: {
            title: 'ID do Facebook',
            description: 'ID do Facebook da campanha de marketing',
          },
          code: {
            title: 'Código',
            description: 'Código da campanha de marketing',
          },
          budget: {
            title: 'Orçamento',
            description: 'Orçamento investido pela clínica na campanha',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica que financia a campanha',
          },
          firstActivityId: {
            title: 'Primeira atividade',
            description: 'Primeira atividade atribuída ao criar um lead',
          },
          funnelId: {
            title: 'Funil',
            description: 'Funil de conversão de campanha de marketing',
          },
          status: {
            title: 'Campanha ativa',
            description: 'Habilite ou desabilite a campanha no sistema',
            label: 'Campanha ativa',
          },
          languageId: {
            title: 'Linguagem',
            description:
              'Selecione um idioma para que todos os leads desta campanha sejam criados nesse idioma',
          },
          startDate: {
            title: 'Data de início',
            description: 'Data de início da campanha',
          },
          submit: 'Criar campanha',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Canal',
                description: 'Canal de campanha de marketing',
              },
            },
          },
        },
      },
      enable: {
        title: 'Ativar campanha',
        subtitle: 'Habilitar campanha no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativá-lo?',
            description: 'Desta forma estará disponível no sistema',
            label: 'Sim, quero ativar esta campanha',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar campanha',
        subtitle: 'Desativar campanha no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativá-lo?',
            description: 'Desta forma não estará disponível no sistema',
            label: 'Sim, quero desativar esta campanha',
          },
          submit: 'Desativar',
        },
      },
      update: {
        title: 'Atualizar campanha',
        subtitle: 'As mudanças afetarão todas as integrações',
        tabs: {
          general: {
            title: 'Em geral',
            description: 'Detalhes da campanha',
          },
          contact: {
            title: 'Contato',
            description: 'Através deste formulário você pode adicionar um contato à campanha. ',
          },
          managers: {
            title: 'Gerentes',
            description: 'Gerentes de campanha',
          },
          financing: {
            title: 'Financiamento',
            description: 'Detalhes de financiamento da campanha',
          },
          webhook: {
            title: 'webhook',
            description: 'Um webhook é uma forma de integrar seu aplicativo a outros aplicativos. ',
            toast: 'Link copiado para a área de transferência',
          },
          participants: {
            title: 'Recebendo clínicas',
            description: 'Estas são as clínicas às quais se destina a campanha de marketing. ',
          },
        },
        participants: {
          list: {
            one: 'Clínica de recepção',
            many: 'Recebendo clínicas',
            columns: {
              clinicName: 'Clínica',
            },
          },
          clinicsAdded: 'Clínicas adicionadas',
          create: {
            title: 'Adicionar clínica receptora',
            subtitle: 'Adicione uma clínica receptora à campanha',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Adicionar clínica receptora à campanha',
              },
              submit: 'Adicionar clínica receptora',
            },
          },
          delete: {
            title: 'Excluir clínica receptora',
            subtitle: 'Esta ação é irreversível',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                description: 'Você pode preferir desativar este participante',
                label: 'Sim, quero excluir esta clínica receptora',
              },
              submit: 'Eliminar',
            },
          },
          add: 'Adicionar',
          addAll: 'Adicionar tudo',
          clinicIdCopied: 'ID da clínica copiada para a área de transferência',
        },
        financing: {
          form: {
            budget: {
              title: 'Orçamento',
              description: 'Orçamento investido pela clínica na campanha',
            },
            financingClinicId: {
              title: 'Clínica de financiamento',
              description: 'Clínica que financiou a campanha',
            },
            submit: 'Atualizar financiamento',
          },
        },
        managers: {
          list: {
            one: 'responsável',
            many: 'gerentes',
            columns: {
              name: 'Nome',
            },
          },
          add: 'Adicionar gerente',
        },
        manager: {
          title: 'Atribuir gerente',
          subtitle: 'Atribuir um gerente à campanha',
        },
        contact: {
          name: {
            title: 'Nome',
          },
          surname: {
            title: 'Sobrenomes',
          },
          prefix: {
            title: 'Prefixo',
          },
          phone: {
            title: 'Telefone',
          },
          email: {
            title: 'E-mail',
          },
          zip: {
            title: 'CEP',
          },
          legal: {
            title: 'Condições Legais',
            label: 'Aceite as condições legais',
            description: 'O contacto aceita as condições legais',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'O contato concorda em receber informações de marketing',
          },
          contactTime: {
            title: 'Horário de contato',
            description: 'Hora em que o contato deseja ser contatado',
          },
          clinicId: {
            title: 'Clínica',
            description: 'A clínica onde o contato deseja ser tratado',
          },
          center: {
            title: 'Localização',
            description: 'Local de contato',
          },
          birthDate: {
            title: 'Data de nascimento',
            description: 'Data de nascimento do contato',
          },
          birthCountry: {
            title: 'País de nascimento',
            description: 'País de nascimento do contato',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso de contato em quilogramas',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura de contato em centímetros',
          },
          donationPreference: {
            title: 'Preferência de cidade',
            description: 'Preferência de cidade onde fazer a doação',
          },
          photo: {
            title: 'Foto',
            description: 'Foto de contato',
          },
          submit: 'Adicionar contato',
        },
        form: {
          name: {
            title: 'Qualificação',
            description: 'Título da campanha de marketing',
          },
          facebookId: {
            title: 'ID do Facebook',
            description: 'ID do Facebook da campanha de marketing',
          },
          code: {
            title: 'Código',
            description: 'Código de identificação da campanha de marketing',
          },
          budget: {
            title: 'Orçamento',
            description: 'Orçamento investido pela clínica na campanha',
          },
          image: {
            title: 'Cobrir',
            description:
              'Nós encorajamos você a fazer upload de uma imagem para melhorar a experiência comercial',
          },
          funnelId: {
            title: 'Funil',
            description: 'Funil de conversão de campanha de marketing',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica que financia a campanha',
          },
          startDate: {
            title: 'Data de início',
            description: 'Data de início da campanha',
          },
          endDate: {
            title: 'Data de término',
            description: 'Data de término da campanha',
          },
          firstActivityId: {
            title: 'Primeira atividade',
            description: 'Primeira atividade atribuída ao criar um lead',
          },
          status: {
            title: 'Campanha ativa',
            description: 'Habilite ou desabilite a campanha no sistema',
            label: 'Campanha ativa',
          },
          languageId: {
            title: 'Linguagem',
            description:
              'Selecione um idioma para que todos os leads desta campanha sejam criados nesse idioma',
          },
          submit: 'Atualizar campanha',
        },
      },
      delete: {
        title: 'Excluir campanha',
        subtitle: 'Esta ação é irreversível',
        manager: {
          title: 'Excluir gerente',
          subtitle: 'Esta ação é irreversível',
          form: {
            submit: 'Eliminar',
          },
        },
        form: {
          status: {
            title: 'Tem certeza de que deseja excluí-lo?',
            description: 'Você pode preferir desativar esta campanha',
            label: 'Sim, quero excluir esta campanha',
          },
          success: 'Campanha excluída com sucesso',
          error: 'Erro ao excluir campanha',
          confirm: 'Esta ação excluirá tudo relacionado à campanha, tem certeza?',
          submit: 'Eliminar',
        },
      },
    },
    activities: {
      list: {
        title: 'Atividades',
        description:
          'Os tipos de atividades permitem que os vendedores acompanhem as tarefas realizadas com leads',
        create: 'Atividade',
        one: 'atividade',
        many: 'atividades',
        columns: {
          name: 'Nome',
          description: 'Descrição',
          position: 'Posição',
          icon: 'Ícone',
          color: 'Cor',
          time: 'Tempo',
        },
      },
      update: {
        subtitle: 'Modificar atividade no sistema',
        title: 'Modificar atividade',
        form: {
          submit: 'Manter',
          name: {
            title: 'Qualificação',
            description: 'Título de identificação da atividade',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar a atividade. ',
            label: 'Selecione o ícone...',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          position: {
            title: 'Posição',
            description: 'Posição em que a atividade é exibida na lista de atividades.',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre a atividade.',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação da atividade. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo estimado que leva para realizar a atividade.',
          },
          primary: {
            title: 'Atividade principal',
            description:
              'As principais atividades são aquelas que aparecem no dashboard do vendedor para permitir uma rápida interação com os contatos.',
            label: 'Mostrar no painel',
          },
        },
        tabs: {
          activity: {
            title: 'Atividade',
            description: 'Informações associadas à atividade',
          },
          results: {
            title: 'Resultados',
            description: 'Os resultados descrevem o que aconteceu após a realização da atividade. ',
          },
          scopes: {
            title: 'Escopos',
            description:
              'Os escopos descrevem quais departamentos podem realizar quais atividades.',
          },
          communicationChannels: {
            title: 'Rotas de comunicação',
            description: 'Canais de comunicação associados à atividade',
          },
          integrations: {
            title: 'Integrações',
            description: 'Integrações externas que serão feitas ao criar essas tarefas',
          },
        },
        results: {
          one: 'resultado',
          many: 'resultados',
          add: 'Adicionar resultado',
        },
        scopes: {
          one: 'Escopo',
          many: 'Escopos',
          add: 'Adicionar escopo',
        },
        communicationChannels: {
          add: 'Adicionar caminho de comunicação',
          list: {
            one: 'Rota de comunicação',
            many: 'Rotas de comunicação',
            columns: {
              channelName: 'Nome',
            },
          },
          create: {
            title: 'Adicionar caminho de comunicação',
            subtitle: 'Adicionar rota de comunicação à atividade',
            form: {
              title: 'Rota de comunicação',
              subtitle: 'Rota de comunicação associada à atividade',
              submit: 'Adicionar',
              id: {
                title: 'Rota de comunicação',
                description: 'Rota de comunicação associada à atividade',
              },
            },
          },
          delete: {
            title: 'Excluir caminho de comunicação',
            subtitle: 'Eliminar canal de comunicação da atividade',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'LandBot',
            description: 'Integração com LandBot. ',
            label: 'Ative a integração LandBot',
          },
          landbotTemplateId: {
            title: 'Modelo LandBot',
            description: 'Integração com LandBot. ',
          },
          landbotActivityId: {
            title: 'Atividade do LandBot',
            description: 'Atividade que será gerada pelo LandBot',
          },
          landbotResultId: {
            title: 'Resultado do LandBot',
            description: 'Resultado que o LandBot irá inserir se a atividade for concluída',
          },
          submit: 'Manter',
        },
      },
      enable: {
        title: 'Habilitar atividade',
        subtitle: 'Habilitar atividade do sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta atividade?',
            description: 'Habilitar uma atividade significa que ela pode ser usada no sistema',
            label: 'Sim, quero ativar esta atividade',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar atividade',
        subtitle: 'Desabilitar atividade do sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta atividade?',
            description:
              'Desabilitar uma atividade significa que ela não pode ser usada no sistema',
            label: 'Sim, quero desativar esta atividade',
          },
          submit: 'Desativar',
        },
      },
      create: {
        title: 'Criar atividade',
        subtitle: 'Criar atividade no sistema',
        form: {
          submit: 'Criar',
          name: {
            title: 'Qualificação',
            description: 'Título de identificação da atividade',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar a atividade. ',
            label: 'Selecione o ícone...',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          position: {
            title: 'Posição',
            description: 'Posição em que a atividade é exibida na lista de atividades.',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre a atividade.',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação da atividade. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo estimado que leva para realizar a atividade.',
          },
          primary: {
            title: 'Atividade principal',
            description:
              'As principais atividades são aquelas que aparecem no dashboard do vendedor para permitir uma rápida interação com os contatos.',
            label: 'Mostrar no painel',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Escopos',
        description: 'Gestão das áreas de atividades do sistema',
        create: 'Escopo',
        one: 'Escopo',
        many: 'Escopos',
        columns: {
          clinicName: 'Clínica',
          departmentName: 'Departamento',
        },
      },
      create: {
        title: 'Criar escopo',
        subtitle: 'Crie escopo no sistema',
        form: {
          submit: 'Criar',
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a que pertence a área',
          },
          departmentId: {
            title: 'Departamento',
            description: 'Departamento ao qual pertence o escopo',
          },
          departmentIds: {
            title: 'Departamentos',
            description: 'Departamentos aos quais o modelo terá escopo',
          },
        },
      },
      delete: {
        title: 'Excluir escopo',
        subtitle: 'Esta ação é irreversível',
        form: {
          submit: 'Eliminar',
        },
      },
    },
    results: {
      list: {
        title: 'Resultados',
        description: 'Gestão dos resultados das atividades no sistema. ',
        create: 'Adicionar resultado',
        one: 'resultado',
        many: 'resultados',
        columns: {
          name: 'Nome',
          description: 'Descrição',
          icon: 'Ícone',
          color: 'Cor',
          time: 'Tempo estimado',
          position: 'Posição',
          activityName: 'Atividade',
        },
      },
      update: {
        subtitle: 'Modificar o resultado da atividade no sistema',
        title: 'Modificar resultado',
        form: {
          submit: 'Atualizar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do resultado',
          },
          icon: {
            title: 'Ícone',
            label: 'Ícone',
            description: 'Ícone para identificar o resultado. ',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          position: {
            title: 'Posição',
            description: 'Posição em que o resultado é exibido na lista de resultados.',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre o resultado.',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação do resultado. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo estimado que leva para produzir o resultado.',
          },
          activityId: {
            title: 'Atividade',
            description: 'Atividade à qual o resultado pertence.',
          },
          nextActivityId: {
            title: 'Próxima atividade',
            description: 'Atividade que será realizada após a realização deste resultado.',
          },
          nextActivityDelay: {
            title: 'Tempo de espera',
            description: 'Tempo de espera para realizar a próxima atividade. ',
          },
          disableLead: {
            title: 'Desativar lead',
            description: 'Desative o lead após realizar este resultado',
            label: 'Sim, desabilite o lead',
          },
          contacted: {
            title: 'Lead contatado',
            description: 'O contato foi estabelecido com o líder',
            label: 'Sim, o lead foi contatado',
          },
          informed: {
            title: 'Lead informado',
            description: 'O lead recebeu informações sobre o serviço',
            label: 'Sim, o lead foi informado',
          },
          converted: {
            title: {
              ovoclinic: 'Lead transformado em paciente',
              ovobank: 'Lead convertido em doador',
            },
            description: {
              ovoclinic: 'O lead foi convertido em paciente. ',
              ovobank: 'O lead foi convertido em doador. ',
            },
            label: {
              ovoclinic: 'Sim, o lead foi convertido',
              ovobank: 'Sim, o lead foi convertido',
            },
          },
          appointed: {
            title: 'Lead citado para visita de informações comerciais',
            description: 'Uma consulta foi agendada com o líder',
            label: 'Sim, o lead foi citado',
          },
          attended: {
            title: 'Visita de informação médica realizada',
            description:
              'Com essas informações será possível contabilizar quando o lead compareceu à consulta médica.',
            label: 'Sim, o lead teve consulta médica',
          },
          comercialAttended: {
            title: 'Orçamento entregue',
            description:
              'Com essas informações será possível contabilizar quando um orçamento será entregue ao lead.',
            label: 'Sim, o lead recebeu uma cotação',
          },
          medicalAppointed: {
            title: 'Lead agendado para visita de informações médicas',
            description: 'Uma consulta médica foi agendada com o líder',
            label: 'Sim, o lead foi citado',
          },
          medicalEvaluated: {
            title: 'Avaliação médica',
            description: 'O chumbo foi avaliado por um médico',
            label: 'Sim, o lead foi avaliado',
          },
          templateId: {
            title: 'Nome',
            description: 'Nome do modelo',
          },
          landbotId: {
            title: 'LandBot',
            description: 'O ID do bot a ser enviado ao contato junto com o modelo',
          },
          landbotAppointed: {
            title: 'Consulta comercial organizada pela LandBot',
            description:
              'Se for realizada uma atividade com este resultado significa que o bot marcou um encontro',
            label: 'Sim, o bot marcou uma consulta',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead citado para visita de informações comerciais',
            description: 'Uma consulta foi agendada com o líder',
            label: 'Sim, o lead foi citado',
          },
          comercialAttended: {
            title: 'Visita de informação comercial realizada',
            description:
              'Com essas informações será possível contabilizar quando o lead compareceu ao encontro comercial.',
            label: 'Sim, o lead teve um compromisso de vendas',
          },
          medicalAppointed: {
            title: 'Lead agendado para visita de informações médicas',
            description: 'Uma consulta médica foi agendada com o líder',
            label: 'Sim, o lead foi citado',
          },
          attended: {
            title: 'Visita de informação médica realizada',
            description:
              'Com essas informações será possível contabilizar quando o lead compareceu à consulta médica.',
            label: 'Sim, o lead teve consulta médica',
          },
          converted: {
            title: 'Ajuste médico',
            description: 'O lead foi considerado adequado para doar',
            label: 'Sim, o lead foi considerado adequado',
          },
          submit: 'Atualizar',
        },
        tabs: {
          result: {
            title: 'Resultado',
            description: 'Gestão dos resultados das atividades no sistema. ',
          },
          template: {
            title: 'Modelo',
            description: 'Modelo a ser usado para Landbot.',
          },
          nextActivity: {
            title: 'Próxima atividade',
            description:
              'A próxima atividade é a atividade que será realizada após a conclusão deste resultado.',
          },
          ccAchievement: {
            title: 'Marcos do call center',
            description:
              'Marcos que devem ser cumpridos para que o resultado seja considerado ‘alcançado’',
          },
          atpcAchievement: {
            title: 'Marcos ATP Comerciais',
            description:
              'Marcos que devem ser cumpridos para que o resultado seja considerado ‘alcançado’',
          },
          atpiAchievement: {
            title: 'Marcos Internacionais da ATP',
            description:
              'Marcos que devem ser cumpridos para que o resultado seja considerado ‘alcançado’',
          },
          ovoBankAtpAchievement: {
            title: 'Marcos dos Doadores',
            description:
              'Marcos que devem ser cumpridos para que o resultado seja considerado ‘alcançado’',
          },
          integrationAchievement: {
            title: 'Marcos de integração',
            description: 'Os marcos que poderiam ser alcançados por alguma integração. ',
          },
          reactivity: {
            title: 'Reatividade',
            description: 'Reatividade do resultado. ',
          },
        },
      },
      enable: {
        title: 'Habilitar resultado',
        subtitle: 'Habilitar resultado no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este resultado?',
            description: 'Habilitar um resultado significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este resultado',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar resultado',
        subtitle: 'Desativar resultado no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este resultado?',
            description: 'Desabilitar um resultado significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este resultado',
          },
          submit: 'Desativar',
        },
      },
      create: {
        title: 'Criar resultado',
        subtitle: 'Criar resultado de atividade no sistema',
        form: {
          submit: 'Criar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do resultado',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar o resultado. ',
            label: 'Ícone',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          position: {
            title: 'Posição',
            description: 'Posição em que o resultado é exibido na lista de resultados.',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre o resultado.',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação do resultado. ',
          },
          time: {
            title: 'Tempo',
            description: 'Tempo estimado que leva para produzir o resultado.',
          },
          activityId: {
            title: 'Atividade',
            description: 'Atividade à qual o resultado pertence.',
          },
          nextActivityId: {
            title: 'Próxima atividade',
            description: 'Atividade que será realizada após a realização deste resultado.',
          },
          nextActivityDelay: {
            title: 'Tempo de espera',
            description: 'Tempo de espera para realizar a próxima atividade. ',
          },
          contacted: {
            title: 'Lead contatado',
            description: 'O contato foi estabelecido com o líder',
            label: 'Sim, o lead foi contatado',
          },
          informed: {
            title: 'Lead informado',
            description: 'O lead recebeu informações sobre o serviço',
            label: 'Sim, o lead foi informado',
          },
          converted: {
            title: 'Converter lead',
            description: {
              ovoclinic: 'O lead foi convertido em paciente. ',
              ovobank: 'O lead foi convertido em doador. ',
            },
            label: 'Sim, o lead foi convertido',
          },
          appointed: {
            title: 'Lead citado',
            description: 'Uma consulta foi agendada com o líder',
            label: 'Sim, o lead foi citado',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Serviços',
        description: 'Gestão de serviços no sistema. ',
        create: 'Serviço',
        one: 'serviço',
        many: 'serviços',
        columns: {
          name: 'Nome',
          price: 'Preço',
          nextActivityName: 'Próxima atividade',
          nextActivityDelay: 'Tempo de atividade adiado',
        },
      },
      update: {
        subtitle: 'Modificar serviço do sistema',
        title: 'Modificar serviço',
        form: {
          submit: 'Atualizar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do serviço',
          },
          price: {
            title: 'Preço',
            description: 'Preço do serviço',
          },
          nextActivityId: {
            title: 'Próxima atividade',
            description: 'Próxima atividade',
          },
          nextActivityDelay: {
            title: 'Tempo de atividade adiado',
            description: 'Hora em que a atividade é adiada',
          },
        },
        tabs: {
          service: {
            title: 'Serviço',
            description: 'Gestão de serviços de atividades no sistema. ',
          },
          nextActivity: {
            title: 'Próxima atividade',
            description:
              'A atividade seguinte é a atividade que será realizada após a conclusão deste serviço.',
          },
        },
      },
      enable: {
        title: 'Habilitar serviço',
        subtitle: 'Habilitar serviço no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este serviço?',
            description: 'Habilitar um serviço significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este serviço',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar serviço',
        subtitle: 'Desabilitar serviço no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este serviço?',
            description: 'Desabilitar um serviço significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este serviço',
          },
          submit: 'Desativar',
        },
      },
      create: {
        title: 'Criar serviço',
        subtitle: 'Criar serviço de atividade no sistema',
        form: {
          submit: 'Criar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do serviço',
          },
          price: {
            title: 'Preço',
            description: 'Preço do serviço',
          },
          nextActivityId: {
            title: 'Próxima atividade',
            description: 'Próxima atividade',
          },
          nextActivityDelay: {
            title: 'Tempo de atividade adiado',
            description: 'Hora em que a atividade é adiada',
          },
        },
      },
    },
    products: {
      list: {
        title: 'Produtos',
        description: 'Gerenciamento de produtos no sistema',
        create: 'Adicionar produto',
        one: 'produto',
        many: 'produtos',
        columns: {
          name: 'Nome',
          price: 'Preço',
          tax: 'Impostos',
          categoryName: 'Categoria',
          clinicName: 'Clínica',
        },
      },
      update: {
        subtitle: 'Modificar produto no sistema',
        title: 'Modificar produto',
        form: {
          submit: 'Manter',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do produto',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone de identificação do produto. ',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição do produto para detalhar suas características',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação do produto. ',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda do produto',
          },
          tax: {
            title: 'imposto',
            description: 'Impostos aplicados ao produto',
          },
          path: {
            title: 'Caminho',
            description: 'Caminho onde o produto está localizado',
          },
          categoryId: {
            title: 'Categoria',
            description: 'Categoria à qual o produto pertence',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica à qual esta categoria pertence',
          },
        },
      },
      enable: {
        title: 'Habilitar produto',
        subtitle: 'Habilitar produto no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar este produto?',
            description: 'Habilitar um produto significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este produto',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar produto',
        subtitle: 'Desativar produto no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar este produto?',
            description: 'Desativar um produto significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este produto',
          },
          submit: 'Desativar',
        },
      },
      create: {
        title: 'Criar produto',
        subtitle: 'Criar produto no sistema',
        steps: {
          category: {
            form: {
              selected: 'Categoria selecionada',
              categoryId: {
                title: 'Categoria',
                description: 'Categoria à qual o produto pertence',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Clínica selecionada',
              clinicId: {
                title: 'Clínica',
                description: 'Clínica à qual esta categoria pertence',
              },
            },
          },
        },
        form: {
          submit: 'Criar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação do produto',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone de identificação do produto. ',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição do produto para detalhar suas características',
          },
          color: {
            title: 'Cor',
            description: 'Cor de identificação do produto. ',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda do produto',
          },
          tax: {
            title: 'imposto',
            description: 'Impostos aplicados ao produto',
          },
          path: {
            title: 'Caminho',
            description: 'Caminho onde o produto está localizado',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Categorias',
        description: 'Agrupamento de produtos',
        create: 'Adicionar categoria',
        one: 'categoria',
        many: 'categorias',
        columns: {
          name: 'Nome',
          clinicName: 'Clínica',
          created: 'Data de criação',
        },
      },
      update: {
        subtitle: 'Modificar categoria no sistema',
        title: 'Modificar categoria',
        form: {
          submit: 'Manter',
          name: {
            title: 'Nome',
            description: 'Nome de identificação da categoria',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar a categoria',
          },
        },
      },
      create: {
        title: 'Criar categoria',
        subtitle: 'Criar categoria no sistema',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Clínica à qual esta categoria pertence',
              },
            },
          },
        },
        form: {
          submit: 'Criar',
          name: {
            title: 'Nome',
            description: 'Nome de identificação da categoria',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar a categoria',
          },
        },
      },
      enable: {
        title: 'Habilitar categoria',
        subtitle: 'Habilitar categoria no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativar esta categoria?',
            description: 'Habilitar uma categoria significa que ela pode ser usada no sistema',
            label: 'Sim, quero ativar esta categoria',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar categoria',
        subtitle: 'Desativar categoria no sistema',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativar esta categoria?',
            description: 'Desativar uma categoria significa que ela não pode ser usada no sistema',
            label: 'Sim, quero desativar esta categoria',
          },
          submit: 'Desativar',
        },
      },
    },
    techniques: {
      list: {
        title: 'Técnicas',
        description: 'Gestão de técnicas de tratamento no sistema',
        create: 'Técnica',
        one: 'Técnica',
        many: 'Técnicas',
        columns: {
          name: 'Nome',
          clinic: 'Clínica',
          created: 'Data de criação',
          price: 'Preço',
        },
      },
      create: {
        title: 'Criar técnica',
        subtitle: 'Cadastro de uma nova técnica no sistema',
        form: {
          id: {
            title: 'Nome',
            description: 'Nome de identificação da técnica',
          },
          name: {
            title: 'Nome',
            description: 'Nome de identificação da técnica',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda da técnica',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar a técnica',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica onde a técnica é realizada',
          },
          submit: 'Criar técnica',
        },
      },
      update: {
        title: 'Técnica de atualização',
        subtitle: 'Modificação de uma técnica no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação da técnica',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda da técnica',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar a técnica',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica onde a técnica é realizada',
          },
          submit: 'Técnica de atualização',
        },
      },
      enable: {
        title: 'Habilitar técnica',
        subtitle: 'Habilitar técnica no sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar técnica',
        subtitle: 'Desativar técnica no sistema',
        form: {
          submit: 'Desativar',
        },
      },
      add: 'Adicionar',
    },
    programs: {
      list: {
        title: 'Programas',
        description: 'Gerenciamento de programas de catálogo',
        create: 'Programa',
        one: 'Programa',
        many: 'Programas',
        columns: {
          name: 'Nome',
          created: 'Data de criação',
          price: 'Preço',
          techniques: 'Técnicas',
        },
      },
      create: {
        title: 'Criar programa para o catálogo',
        subtitle: 'Cadastro de um novo programa no catálogo',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do programa',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar o programa',
          },
          complexity: {
            title: 'Complexidade',
            description: 'Nível de complexidade do programa',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda do programa',
          },
          code: {
            title: 'Código',
            description: 'Código de identificação do programa',
          },
          submit: 'Criar programa',
        },
      },
      enable: {
        title: 'Habilitar programa',
        subtitle: 'Habilitar programa no catálogo',
        form: {
          status: {
            title: 'Tem certeza de que deseja ativá-lo?',
            description: 'Habilitar um programa significa que ele pode ser usado no sistema',
            label: 'Sim, quero ativar este programa',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar programa',
        subtitle: 'Desabilitar programa no catálogo',
        form: {
          status: {
            title: 'Tem certeza de que deseja desativá-lo?',
            description: 'Desabilitar um programa significa que ele não pode ser usado no sistema',
            label: 'Sim, quero desativar este programa',
          },
          submit: 'Desativar',
        },
      },
      update: {
        title: 'Programa de atualização',
        subtitle: 'Modificando um programa no catálogo',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome de identificação do programa',
          },
          description: {
            title: 'Descrição',
            description: 'Uma breve descrição para detalhar o programa',
          },
          complexity: {
            title: 'Complexidade',
            description: 'Nível de complexidade do programa',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica onde o programa é realizado',
          },
          price: {
            title: 'Preço',
            description: 'Preço de venda do programa',
          },
          code: {
            title: 'Código',
            description: 'Código de identificação do programa',
          },
          submit: 'Programa de atualização',
        },
        tabs: {
          program: {
            title: 'Programas',
            description: 'Modificando um programa no catálogo',
          },
          techniques: {
            title: 'Técnicas',
            description: 'Técnicas associadas ao programa',
          },
          phases: {
            title: 'Estágios',
            description: 'Etapas associadas ao programa',
          },
          cycles: {
            title: 'Ciclos',
            description: 'Ciclos associados ao programa',
          },
        },
        cycles: {
          list: {
            title: 'Ciclos',
            description: 'Ciclos associados ao programa',
            columns: {
              cycleName: 'Nome',
              cycleCreated: 'Data de criação',
            },
            one: 'Ciclo',
            many: 'Ciclos',
          },
          create: {
            title: 'Adicionar ciclo',
            subtitle: 'Adicionar ciclo ao programa',
            form: {
              cycleId: {
                title: 'Ciclo',
                description: 'Ciclo associado ao programa',
              },
              submit: 'Adicionar',
            },
          },
          delete: {
            title: 'Excluir ciclo',
            subtitle: 'Esta ação é irreversível',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        phases: {
          create: {
            title: 'Adicionar estágio',
            subtitle: 'Adicionar estágio ao programa',
            form: {
              phaseId: {
                title: 'Estágio',
                description: 'Etapa associada ao programa',
              },
              submit: 'Adicionar',
            },
          },
          delete: {
            title: 'Excluir estágio',
            subtitle: 'Esta ação é irreversível',
            form: {
              status: {
                title: 'Tem certeza de que deseja excluí-lo?',
                description: 'Você pode preferir desativar este estágio',
                label: 'Sim, quero eliminar esta fase',
              },
              submit: 'Eliminar',
            },
          },
          list: {
            title: 'Estágios',
            description: 'Etapas associadas ao programa',
            columns: {
              phaseName: 'Nome',
              phaseCreated: 'Data de criação',
            },
            one: 'Estágio',
            many: 'Estágios',
          },
        },
        techniques: {
          create: {
            title: 'Adicionar técnica',
            subtitle: 'Adicionar técnica ao programa',
            form: {
              id: {
                title: 'Técnica',
                description: 'Técnica associada ao programa',
              },
              submit: 'Adicionar',
            },
          },
          delete: {
            title: 'Excluir técnica',
            subtitle: 'Esta ação é irreversível',
            form: {
              submit: 'Eliminar',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Estágios',
        description: 'Gestão das etapas de um tratamento',
        create: 'Estágio',
        one: 'Estágio',
        many: 'Estágios',
        columns: {
          name: 'Nome',
          created: 'Data de criação',
        },
      },
      create: {
        title: 'Criar estágio',
        subtitle: 'Cadastro de uma nova etapa no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome artístico',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre o palco.',
          },
          icon: {
            title: 'Ícone',
            label: 'Ícone',
            description: 'Ícone para identificar o estágio. ',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
          },
          color: {
            title: 'Cor',
            description: 'Identificando a cor do palco. ',
          },
          submit: 'Criar estágio',
        },
      },
      update: {
        title: 'Estágio de atualização',
        subtitle: 'Modificação de uma etapa do sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome artístico',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para dar mais detalhes sobre o palco.',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar o estágio. ',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
            label: 'Ícone',
          },
          color: {
            title: 'Cor',
            description: 'Identificando a cor do palco. ',
          },
          submit: 'Manter',
        },
        tabs: {
          phase: {
            title: 'Estágio',
            description: 'Informações do palco',
          },
          causes: {
            title: 'Causas',
            description: 'Causas pelas quais um tratamento pode ser cancelado',
            one: 'Causa',
            many: 'Causas',
          },
        },
      },
      enable: {
        title: 'Habilitar estágio',
        subtitle: 'Habilitar estágio no sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar estágio',
        subtitle: 'Desativar estágio no sistema',
        form: {
          submit: 'Desativar',
        },
      },
    },
    causes: {
      list: {
        title: 'Causas',
        description: 'Gestão das causas de cancelamento de um tratamento',
        create: 'Causa',
        one: 'Causa',
        many: 'Causas',
        columns: {
          name: 'Nome',
          created: 'Data de criação',
        },
      },
      create: {
        title: 'Criar causa',
        subtitle: 'Cadastro de uma nova causa no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome da causa',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para fornecer mais detalhes sobre a causa.',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar a causa. ',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
            label: 'Ícone',
          },
          color: {
            title: 'Cor',
            description: 'Identificação da cor da causa. ',
          },
          submit: 'Criar causa',
        },
      },
      update: {
        title: 'Causa da atualização',
        subtitle: 'Modificação de uma causa no sistema',
        form: {
          name: {
            title: 'Nome',
            description: 'Nome da causa',
          },
          description: {
            title: 'Descrição',
            description: 'Um breve resumo para fornecer mais detalhes sobre a causa.',
          },
          icon: {
            title: 'Ícone',
            description: 'Ícone para identificar a causa. ',
            search: {
              placeholder: 'Ícone de pesquisa...',
            },
            label: 'Ícone',
          },
          color: {
            title: 'Cor',
            description: 'Identificação da cor da causa. ',
          },
          submit: 'Manter',
        },
      },
      enable: {
        title: 'Habilitar causa',
        subtitle: 'Habilitar causa no sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Desativar causa',
        subtitle: 'Desativar causa no sistema',
        form: {
          submit: 'Desativar',
        },
      },
    },
  },
  board: {
    total: 'Total',
    months: {
      jan: 'janeiro',
      feb: 'fevereiro',
      mar: 'Mar',
      apr: 'abril',
      may: 'Poderia',
      jun: 'junho',
      jul: 'julho',
      aug: 'Agosto',
      sep: 'Setembro',
      oct: 'Outubro',
      nov: 'Novembro',
      dec: 'dezembro',
    },
    noData: {
      title: 'Não há dados disponíveis',
      description: 'Você pode tentar selecionar outros filtros',
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Leads por campanha e status',
        description: 'Leads por campanha e status',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
      leadPerClinicAndState: {
        title: 'Líderes por clínica e estado',
        description: 'Líderes por clínica e estado',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
    },
    direction: {
      title: 'Endereço dos painéis',
      description: 'Visão executiva das informações do CRM em tempo real',
      cost: 'Custo de lead',
      leadCostByClinicAndMonth: {
        title: 'Custo por lead por clínica',
        description: 'Custo por lead por clínica',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
      leadCostByFunnelAndMonth: {
        title: 'Distribuição de leads por clínicas e departamentos',
        description: 'Distribuição de leads por clínicas e departamentos',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
      leadCostByCampaignAndMonth: {
        title: 'Custo por lead por campanha',
        description: 'Custo por lead por campanha',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
    },
    marketing: {
      title: 'Painéis de marketing',
      description: 'Insights de marketing de informações de CRM em tempo real',
      secondary: {
        title: 'Campanhas de marketing',
        description: 'Visualização de informações de leads sobre campanhas de marketing.',
      },
      convertionRateByCampaign: {
        title: 'Taxa de conversão por campanha',
        description: 'Taxa de conversão por campanha',
        totalLeads: 'Total de leads',
        totalConvertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
      },
      totalLeads: 'TOTAL DE LÍDERES',
      leadsState: 'ESTADO DO LÍDER',
      leadActiveInactive: 'LEADS ATIVOS/INATIVOS',
      leadsPerCampaign: {
        title: 'Leads por campanha',
        description: 'Leads por campanha',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
        filters: {
          campaign: 'Sino',
          funnel: 'Funil',
        },
      },
      leadCostDetail: {
        title: 'Detalhe',
        selectedClinic: 'Clínica selecionada',
        selectedCampaign: 'Campanha selecionada',
        budget: 'Orçamento',
      },
      costCampaign: {
        '0': 'Clínica selecionada: ',
        '1': 'Campanha selecionada: ',
        '2': 'Orçamento: ',
        '3': 'Número de leads: ',
        '4': 'Leads citados: ',
        '5': 'Custo total de leads: ',
        '6': 'Custo total cotado: ',
      },
      leadsPerFunnel: {
        title: 'Leads por funil',
        description: 'Leads por funil',
        totalLeads: 'Total de leads',
        totalCost: 'Custo total',
        costPerLead: 'Custo por lead',
      },
      filters: {
        year: 'Ano',
        yearSubtitle: 'Filtrar por ano selecionado',
        by: 'Filtrar por',
      },
      generalTable: {
        totalLeads: 'Total de leads',
        informed: 'V. eu atuei',
        total: 'Total acumulado',
        costPerLead: 'Custo por lead',
        totalRow: 'Total',
      },
    },
    subordinates: {
      title: 'Desempenho da minha equipe',
      description: 'Visualizando o desempenho da minha equipe',
      performance: {
        title: 'Marcos alcançados',
        description: 'Visualizando marcos alcançados pela minha equipe',
      },
      performanceSubordinates: {
        title: 'DESEMPENHO DA MINHA EQUIPE',
      },
      performanceSubordinate: {
        title: 'DESEMPENHO DO MEU PARCEIRO',
      },
      filters: {
        clinics: {
          all: 'Todos',
        },
        subordinate: {
          title: 'Funcionários',
          subtitle: 'Filtrar por funcionário selecionado',
        },
      },
    },
    intervention: {
      title: 'Previsão de tratamento',
      description: 'Visualizando tratamentos estimados',
      expectedInterventions: {
        monthsTable: 'PREVISÃO DE TRATAMENTO',
        yearsTable: 'PREVISÃO DE TRATAMENTOS ACUMULADOS',
      },
      segmention: 'Divisão de tratamentos',
      lossGainSegmentation: 'Composição de lucros e perdas',
      one: 'Tratamento',
      many: 'Tratamentos',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Perdas',
        total_first_attempt: 'Garantias (1ª tentativa)',
        total: 'Total',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Perdas',
        total_first_attempt: 'Ganhos',
        total: 'Total',
      },
      filters: {
        complexity: {
          title: 'Complexidade',
          subtitle: 'Filtrar por complexidade selecionada',
          types: {
            ALL: 'Todos',
            HIGH: 'Alto',
            LOW: 'Baixo',
          },
        },
      },
    },
    callcenter: {
      title: 'Painéis de call center',
      description: 'Visualização do call center das informações do CRM em tempo real',
    },
    leads: {
      title: 'Leads de painéis',
      description: 'Visualização de leads a partir de informações de CRM em tempo real',
      totalLeads: {
        title: 'Total de leads',
        description: 'Resumo de leads por clínica e departamento',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtrar por clínica selecionada',
          campaign: 'Sino',
          campaignSubtitle: 'Filtrar por campanha selecionada',
          optionA: 'Opção A',
          optionASubtitle: '1ª opção da comparação',
          optionB: 'Opção B',
          optionBSubtitle: '2ª opção da comparação',
          funnel: 'Origem',
          funnelSubtitle: 'Filtrar por origem',
          all: 'Todos',
          department: 'Departamento',
          departmentSubtitle: 'Filtrar por departamento selecionado',
          year: 'Ano',
          yearSubtitle: 'Filtrar por ano selecionado',
          month: 'Mês',
          monthSubtitle: 'Filtrar por mês selecionado',
          allMonths: 'Todos',
        },
        comparativeGraphicTitle: 'COMPARAÇÃO DE LÍDERES',
        comparativeAcumulatedGraphicTitle: 'COMPARAÇÃO DE LEADS/ACUMULADOS',
        totalLeads: 'TOTAL DE LÍDERES',
        summary: 'RESUMO',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Funil de vendas',
      description: 'Visualização do funil de vendas das informações do CRM em tempo real',
      totalSales: {
        graphicTitle: 'FUNIL DE VENDAS',
      },
    },
    sales: {
      title: 'Vendas do painel',
      description: 'Visualização de vendas das informações do CRM em tempo real',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Tratamentos, técnicas e serviços por clínica',
        description: 'Tratamentos, técnicas e serviços por clínica',
        totalTreatments: 'Tratamentos totais',
        totalTechniques: 'Técnicas totais',
        totalServices: 'Serviços totais',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Tratamentos, técnicas e serviços por departamento',
        description: 'Tratamentos, técnicas e serviços por departamento',
        totalTreatments: 'Tratamentos totais',
        totalTechniques: 'Técnicas totais',
        totalServices: 'Serviços totais',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Resumo de vendas',
        ovobank: 'Resumo de vendas',
      },
      description: {
        ovoclinic: 'Resumo dos leads convertidos em pacientes',
        ovobank: 'Resumo de leads convertidos em doadores',
      },
      totalSales: {
        title: 'Vendas',
        description: 'Visualizando vendas realizadas',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtrar por clínica selecionada',
          optionA: 'Opção A',
          optionASubtitle: '1ª opção da comparação',
          optionB: 'Opção B',
          optionBSubtitle: '2ª opção da comparação',
          department: 'Departamento',
          departmentSubtitle: 'Filtrar por departamento selecionado',
          month: 'Mês',
          monthSubtitle: 'Filtrar por mês selecionado',
        },
        comparativeGraphicTitle: 'COMPARAÇÃO DE VENDAS',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'COMPARAÇÃO DE VENDAS/ ACUMULADA',
      },
    },
    medical: {
      title: 'Painéis médicos',
      description: 'Visualização do departamento médico das informações do CRM em tempo real',
      leadConversionByFunnelAndMonth: {
        title: 'Taxa de conversão de leads por origem e mês',
        description: 'Taxa de conversão de leads por origem e mês',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Porcentagem de conversão de leads por origem e mês',
        description: 'Porcentagem de conversão de leads por origem e mês',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Porcentagem de conversão',
      },
      estimatedTreatmentByYear: {
        title: 'Tratamentos agendados',
        description: 'Comparação dos tratamentos agendados no ano selecionado e no ano anterior',
        estimated: 'Tratamento agendado',
        finished: 'Tratamento concluído',
        previousEstimated: 'Tratamentos agendados',
        previousFinished: 'Tratamentos concluídos',
      },
      finishedTreatmentByYear: {
        title: 'Tratamentos concluídos',
        description: 'Comparação dos tratamentos concluídos no ano selecionado e no ano anterior',
        estimated: 'Tratamento agendado',
        finished: 'Tratamento concluído',
        previousEstimated: 'Tratamentos agendados',
        previousFinished: 'Tratamentos concluídos',
      },
      leadConversionBySpecialist: {
        title: 'Taxa de conversão de leads por especialista',
        description: 'Taxa de conversão de leads por especialista',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
        treatmentSold: 'Tratamentos vendidos',
        treatmentFinished: 'Tratamentos concluídos',
        specialistData: 'Informações especializadas',
      },
      leadConversionBySpecialistKPI: {
        title: 'Taxa de conversão de leads de KPI por especialista',
        conversionRate: 'Taxa de conversão',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Taxa de conversão de leads por especialista e mês',
        description: 'Taxa de conversão de leads por especialista e mês',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
        treatmentSold: 'Tratamentos vendidos',
        treatmentFinished: 'Tratamentos concluídos',
        specialistData: 'Informações especializadas',
        leadsAttended: 'Leads atendidos',
        leadsAttendedConverted: 'Leads atendidos convertidos',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'Taxa de conversão de leads de KPI por especialista e mês',
        description: 'Taxa de conversão de leads de KPI por especialista e mês',
        conversionRate: 'Taxa de conversão',
      },
      leadConversionByFunnel: {
        title: 'Taxa de conversão de leads por origem',
        description: 'Taxa de conversão de leads por origem',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamentos vendidos',
        treatmentFinished: 'Tratamentos concluídos',
      },
      leadConversionByFunnelKPI: {
        title: 'Taxa de conversão de leads de KPI por origem',
        description: 'Taxa de conversão de leads de KPI por origem',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Taxa de conversão',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamentos vendidos',
        treatmentFinished: 'Tratamentos concluídos',
        convertedLeadsRate: 'Taxa de conversão de leads',
        treatmentSoldRate: 'Taxa de conversão dos tratamentos vendidos',
      },
    },
    menu: {
      leads: 'Resumo do lead',
      directory: 'Diretório',
      direction: 'Endereço',
      expectedIntervention: 'Previsão de tratamento',
      general: 'Em geral',
      callcenter: 'Central de atendimento',
      medical: 'Doutor',
      marketing: 'Marketing',
      sale: 'Vendas',
      saleFunnel: 'Funil de vendas',
      summarySales: 'Resumo de vendas',
      subordinates: 'Desempenho dos funcionários',
    },
    sections: {
      marketing: {
        actives: 'Ativos',
        inactives: 'Inativo',
        converted: 'Convertido',
        appointed: 'Citado',
        informed: 'Informado',
        contacted: 'Contatado',
        leadPerCampaign: {
          title: 'Leads por campanha de marketing',
        },
        leadPerFunnel: {
          title: 'Leads por origem da campanha',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Taxa de conversão paciente-líder por funil',
            ovobank: 'Taxa de conversão de doadores líderes por funil',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Taxa de conversão paciente-líder por campanha',
            ovobank: 'Taxa de conversão de doadores líderes por campanha',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Doadores',
          },
          leads: 'Pistas',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Taxa de conversão paciente-líder por origem',
            ovobank: 'Taxa de conversão de doadores líderes por origem',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Doadores',
          },
          leads: 'Pistas',
        },
        leadPerClinicPerState: {
          total: 'Total',
          title: 'Leads por clínica por estado',
          actives: 'Ativos',
          inactives: 'Inativo',
          converted: 'Convertido',
          appointed: 'Citado',
          appointedMedic: 'Citado (médico)',
          contacted: 'Contatado',
          informed: 'Informado',
          loss: 'Baixo',
          cancelled: 'Cancelado',
        },
        leadPerCampaignPerState: {
          total: 'Total',
          title: 'Leads por campanha por estado',
          actives: 'Ativos',
          inactives: 'Inativo',
          converted: 'Convertido',
          appointed: 'Citado',
          contacted: 'Contatado',
          informed: 'Informado',
          loss: 'Baixo',
        },
        totalLeadsPerState: {
          total: 'Total',
          appointed: 'Citado',
          percentage: 'Percentagem',
          cost: 'Custo',
          actives: 'Ativos',
          inactives: 'Inativo',
        },
        filter: {
          campaignId: {
            title: 'Filtrar por campanha',
          },
          languageId: {
            title: 'Filtrar por idioma',
          },
          funnelId: {
            title: 'Filtrar por origem',
          },
          clinicId: {
            title: 'Filtrar por clínica',
          },
        },
        listLeadsByCampaign: {
          title: 'Detalhamento da campanha',
          campaign: 'Campanha de marketing',
          funnel: 'Origem',
          financingClinic: 'Clínica de financiamento',
          language: 'Departamento',
          budget: 'Orçamento da campanha',
          nLeads: 'Nº de leads',
          costPerLead: 'Custo por lead',
        },
        all: 'Todos',
      },
      callcenter: {
        listLeadsByReason: {
          title: 'Leads devido ao cancelamento',
          reason: 'Razão',
          nLeads: 'Nº de leads',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Custo de lead por clínica',
          monthFilter: 'Filtrar por mês',
        },
        costsLeadPerFunnel: {
          title: 'Custo de lead por origem',
        },
        costsLeadPerCampaign: {
          title: 'Custo de lead por campanha',
        },
        costsLeadPerLanguage: {
          title: 'Custo de lead por departamento',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Tratamentos, técnicas e serviços por clínica',
        },
        salePerLanguage: {
          title: 'Tratamentos, técnicas e serviços por departamento',
        },
      },
      saleFunnel: {
        title: 'Funil de negócios',
        description: 'Visualização do estado geral dos leads durante o processo de vendas',
      },
    },
  },
  profile: {
    menu: {
      profile: 'Perfil',
      account: 'minha conta',
      changePassword: 'Alterar a senha',
    },
    changePassword: {
      title: 'Alterar a senha',
      subtitle: 'Alterar a senha da sua conta',
      form: {
        password: {
          title: 'Senha',
          description: 'Nova senha do usuário',
        },
        repeatPassword: {
          title: 'Repetir senha',
          description: 'Repita a senha para confirmá-la',
        },
        submit: 'Alterar a senha',
      },
    },
    account: {
      title: 'minha conta',
      subtitle: 'Informações pessoais e de conta',
      form: {
        name: {
          title: 'Nome',
          description: 'Digite seu nome',
        },
        surname: {
          title: 'Sobrenomes',
          description: 'Digite seu sobrenome',
        },
        username: {
          title: 'Nome de usuário',
          description: 'Digite seu nome de usuário',
        },
        phone: {
          title: 'Telefone',
          description: 'Digite seu número de telefone',
        },
        submit: 'Atualizar conta',
      },
    },
  },
  common: {
    search: {
      placeholder: 'Procurar...',
    },
    actions: {
      create: 'Criar',
      update: 'Atualizar',
      delete: 'Eliminar',
      save: 'Manter',
      cancel: 'Cancelar',
    },
    tasks: {
      disabled: 'Mostrar inativo',
      noResult: 'Nenhum resultado',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Excluir nota',
          description: 'Tem certeza de que deseja excluir esta nota?',
          cancel: 'Cancelar',
          submit: 'Eliminar',
        },
      },
      noteType: {
        title: 'Veja observações do',
        patient: {
          ovoclinic: 'Paciente',
          ovobank: 'Doador',
        },
        lead: {
          ovoclinic: 'Liderar',
          ovobank: 'Liderar',
        },
      },
      filter: {
        title: 'Filtrar por',
        tags: {
          medical: 'Doutor',
          nursing: 'Enfermagem',
          followUp: 'Seguir',
        },
      },
    },
    time: {
      seconds: 'segundos',
      second: 'segundo',
      minutes: 'minutos',
      minute: 'minuto',
      hour: 'hora',
      hours: 'horas',
      days: 'dias',
      day: 'dia',
      weekDays: {
        '0': 'Segunda-feira',
        '1': 'Terça-feira',
        '2': 'Quarta-feira',
        '3': 'Quinta-feira',
        '4': 'Sexta-feira',
        '5': 'Sábado',
        '6': 'Domingo',
      },
      timeMessage: {
        expiresIn: 'Expira em {{value}} {{unit}}',
        expired: 'Expirado',
        ago: 'Faz',
      },
    },
    status: {
      success: 'Correto',
      fail: 'Fracassado',
    },
    notification: {
      title: 'Você tem uma nova notificação',
    },
    contactTime: {
      MORNING: 'Amanhã',
      AFTERNOON: 'Tarde',
      NO_PREFERENCE: 'Sem preferência',
    },
    complexity: {
      HIGH: 'Alto',
      LOW: 'Baixo',
    },
    specialistType: {
      GYNECOLOGIST: 'Ginecologista',
      EMBRYOLOGIST: 'embriologista',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: 'Brincos',
      COMPLETED: 'Feito',
      ALL: 'Todos',
    },
    plannedStatus: {
      ALL: 'Todos',
      PLANNED: 'Planejado',
      UNPLANNED: 'Não planejado',
    },
    taskProgress: {
      PENDING: 'Brinco',
      COMPLETED: 'Feito',
    },
    leadEntryStatus: {
      NEW: 'Novo',
      DUPLICATED: 'Duplicado',
      REENTRY: 'Reentrada',
    },
    paymentStatus: {
      PENDING: 'Brinco',
      PAID: 'Pago',
    },
    purchaseType: {
      MAINTENANCE: 'Manutenção',
      TREATMENT: 'Programa',
      TECHNIQUE: 'Técnica',
    },
    notifications: {
      list: {
        title: 'Notificações',
        description: 'Gerenciamento de notificações no sistema',
        columns: {
          title: 'Qualificação',
          type: 'Tipo de notificação',
          created: 'Data de criação',
        },
        one: 'Notificação',
        many: 'Notificações',
      },
      types: {
        ACTION_ALERT: 'Alerta de tarefa',
      },
      new: 'Você tem uma nova notificação',
    },
    information: {
      title: 'Recursos',
      description: 'Informações e recursos da plataforma',
    },
  },
  components: {
    fileUploader: {
      title: 'Carregar arquivo',
      invalidType: 'Tipo de arquivo não permitido',
    },
  },
  login: {
    title: 'Conecte-se',
    subtitle: 'Acesso à plataforma de gerenciamento',
    form: {
      email: {
        title: 'E-mail',
      },
      signature: {
        title: 'Senha',
      },
      submit: 'Conecte-se',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: 'Nome de usuário ou senha incorretos',
      WRONG_PERMISSIONS: 'Você não tem permissão para executar esta ação',
      service: {
        group: {
          findByDomain: {
            error: 'FATAL: O grupo não foi encontrado',
          },
        },
      },
    },
  },
};
