import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CommunicationChannelsRoutes } from '../../communicationChannels.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CommunicationChannelAccessType } from '@human/access/types/communicationChannel.access.type';
import { CommunicationChannelAccessRepository } from '@human/access/repositories/communicationChannel/communicationChannel.repository';

export function CreateCommunicationChannelsScreen(properties: {
  navigation: NavigationProp<CommunicationChannelsRoutes>;
  route: RouteProp<CommunicationChannelsRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="envelope" prefix="access.communicationChannels.create">
      <FormComponent<CommunicationChannelAccessType>
        padding
        prefix={'access.communicationChannels.create.form'}
        repository={{
          send: (settings: { item: Partial<CommunicationChannelAccessType> }) => {
            return new CommunicationChannelAccessRepository()
              .create({
                name: settings.item.name ?? '',
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['name'],
          },
        ]}
      />
    </ModalComponent>
  );
}
