import { useNotificationState } from '@states/notification/notification.state';
import { useToaster } from './../../context/toaster/toaster.context';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useNotificationChecker() {
  const { count } = useNotificationState();
  const { t } = useTranslation();
  const { updateToaster } = useToaster();

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        updateToaster({
          content: t('common.notifications.new'),
          // TODO change title and content depending on notification content
          title: 'Nueva notificación',
          icon: 'user', // 'exclamation-circle'
          color: 'black',
        });
      }, 1000);
    }
  }, []);
}
