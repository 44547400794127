import { CoworkerCrmType } from '@human/crm/types/coworker.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class CoworkerCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order?: keyof CoworkerCrmType;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    clinicId: string;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: CoworkerCrmType[];
    total: number;
  }> {
    return this.fetch('coworker/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        surname: item.surname,
        email: item.email,
        aggregated: {
          roles: item.aggregated.roles,
        },
      })),
    }));
  }
  async pick(properties: {
    clinicId: string;
    coworkerId: string;
  }): Promise<Partial<CoworkerCrmType>> {
    return this.fetch('coworker/pick', {
      method: 'GET',
      params: properties,
    }).then((coworker) => {
      return {
        id: coworker.id,
        name: coworker.name,
        surname: coworker.surname,
      };
    });
  }
}
