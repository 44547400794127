import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionsRoutes } from '../../../actions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';

export function UpdateActionAddSpecialistScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'updateActionAddSpecialist'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="user-plus"
      prefix="crm.leads.addSpecialist"
      flex={false}
    >
      <FormComponent<{ specialistId: string }>
        padding
        prefix={'crm.leads.addSpecialist.form'}
        repository={{
          send: (settings) => {
            return new LeadCrmRepository()
              .updateSpecialist({
                leadId: properties.route.params.leadId,
                clinicId: properties.route.params.clinicId,
                specialistId: settings.item.specialistId ?? '',
              })
              .then(() => {
                properties.navigation.goBack();
                if (
                  properties.route.params.nextActivityId &&
                  properties.route.params.nextActivityDelay &&
                  properties.route.params.actionId &&
                  properties.route.params.started
                ) {
                  properties.navigation.navigate('actionNextResultUpdate', {
                    actionId: properties.route.params.actionId,
                    clinicId: properties.route.params.clinicId,
                    leadId: properties.route.params.leadId,
                    level: properties.route.params.level,
                    activityId: properties.route.params.nextActivityId,
                    started: properties.route.params.started,
                  });
                }
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'specialistId',
            description: true,
            repository: (props: { search?: string }) => {
              return new SpecialistAccessRepository()
                .list({
                  search: props.search,
                  page: 0,
                  limit: 100,
                  active: true,
                })
                .then((specialists) => {
                  return specialists.items.map((specialist) => ({
                    value: specialist.id,
                    label: `${specialist.name} ${specialist.surname}`,
                  }));
                });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
