// Modules
import { StyleSheet } from 'react-native';

/**
 * ContactTimeColumn Styles
 * @constant {StyleSheet} ContactTimeColumnStyles
 */
export const ContactTimeColumnStyles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
    flex: 1,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  text: {
    fontSize: 14,
  },
});
