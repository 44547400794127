import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import { Platform, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { SaleBoardRepository } from '../../repositories/sale/sale.repository';
import { LoaderComponent } from '@components/loader/loader.component';
import { SceneComponent } from '@components/scene/scene.component';
import { BoardHeaderComponent } from '../../components/header/board.header.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { IconComponent } from '@components/icon/icon.component';
import { NewLeadsByDepartmentYearsComparative } from '../leads/components/newLeadsByDepartmentYearsComparative/newLeadsByDepartmentYearsComparative';
import { TwoYearColumnLeadsComparative } from '../leads/components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative';
import { ItemByDepartmentYearsComparativeType } from '../leads/components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { LinearChartSalesByDepartment } from './components/linearChartSalesByDepartment/linearChartSalesByDepartment.component';
import { TextComponent } from '@components/text/text.component';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { BoardStyles } from '../common/board.styles';

export default function SummarySalesScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'summarySales'>;
}) {
  const { t } = useTranslation();
  const [selectedClinic, setSelectedClinic] = useState<string>();
  const [clinics, setClinics] = useState<{ label: string; value: string }[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<{ label: string; value: string }[]>([]);

  const [firstYear, setFirstYear] = useState<number>(new Date().getFullYear() - 1);
  const [secondYear, setSecondYear] = useState<number>(new Date().getFullYear());

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    new ClinicAccessRepository().list({}).then((clinics) => {
      setClinics(
        clinics.items.map((clinic) => {
          return {
            label: clinic.name,
            value: clinic.id,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    setSelectedClinic(clinics[0]?.value);
  }, [clinics]);

  useEffect(() => {
    if (!selectedClinic) return;
    setLoading(true);
    new SaleBoardRepository()
      .newSaleCountYearsComparative({
        clinicId: selectedClinic,
        firstYear,
        secondYear,
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [selectedClinic, firstYear, secondYear]);

  useEffect(() => {
    if (!selectedClinic) return;

    new LanguageAccessRepository().list({ limit: 100 }).then((languages) => {
      setLanguages(
        languages.items.map((language) => {
          return {
            label: language.name,
            value: language.id,
          };
        })
      );
    });
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedLanguage(languages[0]?.value);
  }, [languages]);

  const handleExport = (type: 'yearly' | 'monthly') => {
    if (confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      const headers =
        type === 'monthly'
          ? Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`))
          : [firstYear.toString(), secondYear.toString()];

      handleExportTableCSV({
        headers: ['Departamento', ...headers],
        data,
        type,
        fileName: type === 'monthly' ? 'monthlySales.csv' : 'yearlySales.csv',
      });
    }
    return;
  };

  if (!selectedClinic) return <LoaderComponent loading />;

  return (
    <SceneComponent icon={'search'} prefix={'board.summarySales'} links={[]} multiWorkspace>
      <View style={BoardStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.summarySales.totalSales.title"
          description="board.summarySales.totalSales.description"
        />
        <View style={BoardStyles.fiterContainer}>
          <View style={{ width: 250 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.clinic')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.clinicSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedClinic ?? ''}
                name="clinic"
                onChange={(value: any) => {
                  setSelectedClinic(value.clinic);
                }}
                pick={async ({ id }) =>
                  new ClinicAccessRepository()
                    .pick({
                      id: selectedClinic ?? '',
                    })
                    .then((clinic) => {
                      return clinic.name ?? '';
                    })
                }
                repository={async () => {
                  return Promise.resolve(clinics);
                }}
                hideSearch
              />
            </View>
          </View>
          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.optionA')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.optionASubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + firstYear}
                name="fistYear"
                onChange={(value: any) => {
                  setFirstYear(+value.fistYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 8;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>

          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.optionB')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.optionBSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + secondYear}
                name="secondYear"
                onChange={(value: any) => {
                  setSecondYear(+value.secondYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 7;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>
        </View>
        <View style={BoardStyles.graphicsContainer}>
          <View style={[BoardStyles.graphicContainer, { flex: 7, minHeight: 300 }]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport('monthly')}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {selectedClinic && !loading ? (
              <NewLeadsByDepartmentYearsComparative
                data={data}
                firstYear={firstYear}
                secondYear={secondYear}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
          <View style={[BoardStyles.graphicContainer, { minHeight: 300 }]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeAcumulatedGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport('yearly')}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.csv')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {selectedClinic && !loading ? (
              <TwoYearColumnLeadsComparative
                data={data}
                firstYear={firstYear}
                secondYear={secondYear}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>

        <View style={[BoardStyles.fiterContainer, { marginTop: 50 }]}>
          <View style={{ width: 250 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.department')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.departmentSubtitle')}
              </Text>
            </View>
            {selectedClinic && (
              <View>
                <FilterSelectInput
                  type="select"
                  value={selectedLanguage ?? ''}
                  name="department"
                  onChange={(value: any) => {
                    setSelectedLanguage(value.department);
                  }}
                  pick={async ({ id }) =>
                    new LanguageAccessRepository()
                      .pick({
                        languageId: selectedLanguage ?? '',
                      })
                      .then((language) => {
                        return language.name ?? '';
                      })
                  }
                  repository={async () => {
                    return Promise.resolve(languages);
                  }}
                  hideSearch
                />
              </View>
            )}
          </View>
        </View>
        <View style={BoardStyles.graphicsContainer}>
          <View
            style={{
              gap: 30,
              backgroundColor: 'white',
              padding: 15,
              borderRadius: 5,
              flex: 1,
              minHeight: 400,
            }}
          >
            <View>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeGraphicTitle')}
              </Text>
            </View>

            {selectedClinic && selectedLanguage && !loading ? (
              <LinearChartSalesByDepartment
                selectedDepartment={selectedLanguage}
                departmentName={
                  languages.find((language) => language.value === selectedLanguage)?.label!
                }
                firstYear={firstYear}
                secondYear={secondYear}
                data={data}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
