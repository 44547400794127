import { AttemptAccessType } from '@human/access/types/attempt.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class AttemptAccessRepository extends AuthorizedRepository {
  async list(properties: {
    userId: string;
    page: number;
    limit: number;
  }): Promise<{ items: AttemptAccessType[]; total: number }> {
    return this.fetch('attempt/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((attempt: any) => ({
        id: attempt.id,
        created: new Date(attempt.created),
        status: attempt.status,
      })),
    }));
  }
}
