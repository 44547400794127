import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { SpecialistAccessType } from '@human/access/types/specialist.access.type';
import { SpecialistContractAccessType } from '@human/access/types/specialistContract.access.type';

export class SpecialistAccessRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    page: number;
    limit: number;
    active?: boolean;
  }): Promise<{ items: SpecialistAccessType[]; total: number }> {
    return this.fetch('specialist/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((specialist: any) => ({
        id: specialist.id,
        name: specialist.name,
        surname: specialist.surname,
        type: specialist.type,
        dni: specialist.dni,
        status: specialist.status,
      })),
    }));
  }

  async create(properties: { item: Partial<SpecialistAccessType> }): Promise<void> {
    return this.fetch('specialist/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async update(properties: {
    specialistId: string;
    item: Partial<SpecialistAccessType>;
  }): Promise<void> {
    return this.fetch('specialist/update', {
      method: 'PUT',
      body: {
        specialistId: properties.specialistId,
        ...properties.item,
      },
    });
  }

  async disable(properties: { id: string }) {
    return this.fetch('specialist/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { id: string }) {
    return this.fetch('specialist/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async pick(properties: { id: string }): Promise<SpecialistAccessType> {
    return this.fetch('specialist/pick', {
      method: 'GET',
      params: {
        specialistId: properties.id,
      },
    }).then((data) => ({
      id: data.id,
      name: data.name,
      surname: data.surname,
      type: data.type,
      dni: data.dni,
      status: data.status,
    }));
  }

  //SPECIALIST CONTRACTS

  async listContracts(properties: {
    id: string;
    search?: string;
  }): Promise<{ items: SpecialistContractAccessType[]; total: number }> {
    return this.fetch('specialist/find/contracts', {
      method: 'GET',
      params: {
        specialistId: properties.id,
        search: properties.search || '',
      },
    }).then((data) => ({
      total: data.total,
      items: data.items.map((contract: any) => ({
        clinicId: contract.clinicId,
        clinicName: contract.clinicName,
      })),
    }));
  }

  async createContract(properties: { specialistId: string; clinicId: string }): Promise<void> {
    return this.fetch('specialist/create/contract', {
      method: 'POST',
      body: properties,
    });
  }

  async deleteContract(properties: { specialistId: string; clinicId: string }): Promise<void> {
    return this.fetch('specialist/delete/contract', {
      method: 'DELETE',
      params: properties,
    });
  }
}
