// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const TextInputStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  containerReadonly: {
    borderRadius: 5,
  },
  iconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  iconContainerReadonly: {
    height: 27,
    color: '#777777',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  iconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  iconStyleReadonly: {
    color: '#777777',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  input: {
    borderRadius: 5,

    backgroundColor: 'white',
    minHeight: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
  },
  inputReadonly: {
    backgroundColor: '#DDDDDD',
    color: '#777777',
    minHeight: 42,
    borderRadius: 5,
  },
  inputSingleline: {
    alignContent: 'center',
  },
  inputMultiline: {
    paddingTop: 10,
  },
  inputIcon: {
    paddingLeft: 50,
  },
  inputNoIcon: {
    paddingLeft: 10,
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
});
