import { NavigationProp, RouteProp } from '@react-navigation/native';
import { AlertsRoutes } from '../../alerts.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActionAlertCrmRepository } from '@human/crm/repositories/action/alert/action.alert.repository';

export function CreateAlertScreen(properties: {
  navigation: NavigationProp<AlertsRoutes>;
  route: RouteProp<AlertsRoutes, 'alertCreate'>;
}) {
  return (
    <ModalComponent
      icon="bell"
      prefix="crm.actions.update.alerts.create"
      level={properties.route.params.level}
    >
      <FormComponent<{ actionAlertId: string; alertTime: number; expectedAlarmTime: Date }>
        prefix="crm.actions.update.alerts.create.form"
        padding
        repository={{
          send: (data) => {
            return new ActionAlertCrmRepository()
              .create({
                actionId: properties.route.params.actionId,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                leadId: properties.route.params.leadId,
                alertTime: data.item.alertTime ?? 0,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'number',
            name: 'alertTime',
            description: true,
            required: true,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
