import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { MaintenancesRoutes } from '../../../maintenance.routes';
import { FormComponent } from '@components/form/form.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function ActionUpdateMaintenancePart(properties: {
  navigation: NavigationProp<MaintenancesRoutes>;
  route: RouteProp<MaintenancesRoutes, 'updateMaintenance'>;
  actionId?: string;
  onActionUpdate: () => void;
}): TabType {
  return {
    name: 'action',
    icon: 'tasks',
    content: () => (
      <FormComponent<ActionCrmType>
        prefix="crm.patients.actions.update.form"
        repository={{
          get: () => {
            if (!properties.actionId) return Promise.resolve({});

            return new ActionCrmRepository().pickByPatient({
              actionId: properties.actionId,
              patientId: properties.route.params.patientId,
              clinicId: properties.route.params.clinicId,
            });
          },
          send: (settings) => {
            return new ActionCrmRepository()
              .updatePatientAction({
                item: {
                  ...settings.item,
                  patientId: properties.route.params.patientId,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => {
                properties.onActionUpdate();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'subject',
            description: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'activityId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return `${activity.name}`;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository()
                .list({
                  direction: 'asc',
                  limit: 100,
                  order: 'id',
                  page: 0,
                  search,
                  active: true,
                })
                .then((activities) =>
                  activities.items.map((activity) => ({
                    label: activity.name,
                    value: activity.id,
                  }))
                );
            },
          },
          {
            type: 'select',
            name: 'responsibleId',
            required: true,
            description: true,
            pick: (props: { id: string }) => {
              return new CoworkerCrmRepository()
                .pick({
                  clinicId: properties.route.params.clinicId,
                  coworkerId: props.id,
                })
                .then((coworker) => {
                  return `${coworker.name} ${coworker.surname}`;
                });
            },
            repository: ({ search }) => {
              return new CoworkerCrmRepository()
                .list({
                  clinicId: properties.route.params.clinicId,
                  direction: 'asc',
                  limit: 100,
                  order: 'email',
                  page: 0,
                  search,
                  active: true,
                })
                .then((leads) =>
                  leads.items.map((user) => ({
                    label: `${user.name} ${user.surname}`,
                    value: user.id,
                  }))
                );
            },
          },
          {
            type: 'date',
            description: true,
            required: true,
            name: 'started',
            format: 'YYYY-MM-DD HH:mm:ss',
          },
          {
            type: 'text',
            description: true,
            lines: 9,
            name: 'observation',
            validations: ['observation'],
          },
        ]}
      />
    ),
  };
}
