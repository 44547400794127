// Modules
import { StyleSheet } from 'react-native';

/**
 * Bar Column Styles
 * @constant {StyleSheet} NumberColumnStyles
 */
export const BarColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 7,
    alignContent: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: '#333',
  },
  icon: {
    fontSize: 16,
    color: '#aaa',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingRight: 10,
  },
  barContainer: {
    flex: 1,
    marginHorizontal: 7,
    height: 8,
    borderRadius: 8,
    backgroundColor: '#ddd',
  },
  bar: {
    height: 8,
    borderRadius: 8,
    backgroundColor: 'rgb(237, 53, 145)',
  },
  total: {},
});
