import { NavigationComponent } from '@components/navigation/navigation.component';
import { TransferLeadRoutes } from './transferLead.routes';
import { ListTransferLeadsScreen } from './screens/list/list.transfer.lead.screen';
import { ConfirmTransferLeadScreen } from './screens/confirmTransfer/confirmTransfer.lead.screen';

const Navigation = NavigationComponent<TransferLeadRoutes>();

export function TransferLeadSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListTransferLeadsScreen} />
      <Navigation.Screen name="confirmTransfer" component={ConfirmTransferLeadScreen} />
    </Navigation.Navigator>
  );
}
