import { LeadEntryStatusType } from '@human/crm/types/lead.crm.type';

export interface StatusColors {
  backgroundColor: string;
  textColor: string;
}

export const statusColorMap: Record<LeadEntryStatusType, StatusColors> = {
  NEW: {
    backgroundColor: '#FBE7F1',
    textColor: '#DC5195',
  },
  DUPLICATED: {
    backgroundColor: '#DBE5EF',
    textColor: '#4F5861',
  },
  REENTRY: {
    backgroundColor: '#FFEAC7',
    textColor: '#FFA500',
  },
};
