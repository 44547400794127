import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { View, TouchableOpacity } from 'react-native';
import { WidgetStyles } from './widget.styles';
import { WidgetProperties } from './widget.properties';
import { useState } from 'react';

/**
 * Widget Component
 * @description Sección del componente Performance que contiene un icono, un título y un contador.
 *
 * @param {WidgetProperties} properties
 * @returns {JSX.Element}
 */

export function WidgetComponent(properties: WidgetProperties) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <View
      style={{
        flex: properties.flex,
      }}
    >
      <View style={WidgetStyles.container}>
        <View style={WidgetStyles.iconContainer}>
          <IconComponent name={properties.icon} iconStyle={WidgetStyles.icon} />
        </View>
        <TextComponent bold translate text={properties.title} style={WidgetStyles.titleText} />
        <View style={WidgetStyles.counterContainer}>
          {properties.counter && (
            <View style={WidgetStyles.counterTextContainer}>
              <TextComponent bold text={`${properties.counter}`} style={WidgetStyles.counterText} />
            </View>
          )}
          <TouchableOpacity
            onPress={() => setIsOpen(!isOpen)}
            style={WidgetStyles.angleDownContainer}
          >
            <IconComponent
              name={isOpen ? 'angle-up' : 'angle-down'}
              iconStyle={WidgetStyles.angleDownIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
      {isOpen && <View style={WidgetStyles.content}>{properties.children}</View>}
    </View>
  );
}
