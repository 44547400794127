import { StyleSheet } from 'react-native';

/**
 * Tagged Notes Styles
 * @constant {StyleSheet} TaggedNotesStyles
 */
export const TaggedNotesStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    backgroundColor: 'rgba(60, 76, 88, 0.32)',
    borderRadius: 10,
    fontSize: 14,
    height: 200,
  },
  inputText: {
    margin: 10,
    height: 100,
  },
  darkInput: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  lightInput: {
    color: 'rgb(17, 27, 34)',
  },
  tagsContainer: {
    flexGrow: 1,
    padding: 16,
    marginVertical: 10,
  },
  tagsContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'flex-start',
  },
  sendContainer: {
    position: 'absolute',
    bottom: 25,
    right: 30,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  send: {
    color: 'rgb(255, 255, 255)',
    fontSize: 25,
  },
});
