import { RouteProp } from '@react-navigation/native';
import { CrmRoutes } from '@human/crm/crm.routes';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { ListTasksScreen } from './screens/list/list.tasks.screen';
import { TasksRoutes } from './tasks.routes';
import { UpdateActionScreen } from '../actions/screens/update/update.action.screen';
import { EnableActionScreen } from '../actions/screens/enable/enable.action.screen';
import { UpdateActionNextResultPart } from '../actions/screens/update/parts/update.action.nextResult.part';
import { DisableActionScreen } from '../actions/screens/disable/disable.action.screen';
import { CreateFromCalendarScreen } from '../actions/screens/createFromCalendar/createFromCalendar.screen';
import { CreateAlertScreen } from '../alerts/screens/create/create.alert.screen';
import { UpdateAlertScreen } from '../alerts/screens/update/update.alert.screen';
import { DeleteAlertScreen } from '../alerts/screens/delete/delete.alert.screen';
import { UpdateActionDisableLeadPart } from '../actions/screens/update/parts/update.action.disableLead.part';
import { UpdateActionCreateTreatmentScreen } from '../actions/screens/update/parts/update.action.createTreatment.part';
import { UpdateActionAddSpecialistScreen } from '../actions/screens/update/parts/update.action.addSpecialist.part';
import { UpdateActionTemplatePart } from '../actions/screens/update/parts/update.action.template.part';

const Navigation = NavigationComponent<TasksRoutes>();

export function TasksSection(properties: { route: RouteProp<CrmRoutes, 'tasks'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListTasksScreen} />
      <Navigation.Screen name="actionUpdate" component={UpdateActionScreen} />
      <Navigation.Screen name="actionNextResultUpdate" component={UpdateActionNextResultPart} />
      <Navigation.Screen name="actionDisable" component={DisableActionScreen} />
      <Navigation.Screen name="actionDisableLead" component={UpdateActionDisableLeadPart} />
      <Navigation.Screen name="actionEnable" component={EnableActionScreen} />
      <Navigation.Screen name="actionCreateFromCalendar" component={CreateFromCalendarScreen} />
      <Navigation.Screen name="alertCreate" component={CreateAlertScreen} />
      <Navigation.Screen name="alertUpdate" component={UpdateAlertScreen} />
      <Navigation.Screen name="alertDelete" component={DeleteAlertScreen} />
      <Navigation.Screen
        name="updateActionCreateTreatment"
        component={UpdateActionCreateTreatmentScreen}
      />
      <Navigation.Screen
        name="updateActionAddSpecialist"
        component={UpdateActionAddSpecialistScreen}
      />
      <Navigation.Screen name="updateActionTemplate" component={UpdateActionTemplatePart} />
    </Navigation.Navigator>
  );
}
