import { ImageUploaderComponent } from '@components/imageUploader/imageUploader.componen.web';
import { FileInputImplementation } from './file.implementation';

export function FileInput<ITEM>(properties: FileInputImplementation<ITEM>) {
  return (
    <ImageUploaderComponent
      fileType={properties.fileType}
      onSubmit={async (file) => {
        const key = properties.name;

        properties.onChange({
          ...properties.value,
          [key]: file,
        });
      }}
      file={properties.value[properties.name] as string}
    />
  );
}
