import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TemplatesRoutes } from '../../templates.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TemplateAccessType } from '@human/access/types/template.access.type';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function CreateTemplatesScreen(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="file-alt" prefix="access.templates.create">
      <FormComponent<TemplateAccessType>
        prefix={'access.templates.create.form'}
        padding
        repository={{
          send: (data) => {
            return new TemplateAccessRepository()
              .create({
                item: data.item,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'label',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            type: 'text',
            name: 'landbotId',
            description: true,
            required: true,
            validations: ['code'],
          },
        ]}
      />
    </ModalComponent>
  );
}
