import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { LanguageAccessType } from '@human/access/types/language.access.type';

export class LanguageAccessRepository extends AuthorizedRepository {
  async list(properties: {
    order?: keyof LanguageAccessType;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: LanguageAccessType[];
    total: number;
  }> {
    return this.fetch('language/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((language: any) => ({
        id: language.id,
        name: language.name,
        code: language.code,
        status: language.status,
        countryNameEs: language.countryNameEs,
        countryId: language.countryId,
      })),
    }));
  }

  async create(properties: {
    item: Partial<LanguageAccessType & { signature: string }>;
  }): Promise<Partial<LanguageAccessType>> {
    return this.fetch('language/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { languageId: string }): Promise<LanguageAccessType> {
    return this.fetch('language/pick', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: { languageId: string; item: Partial<LanguageAccessType> }) {
    return this.fetch(`language/update`, {
      method: 'PUT',
      body: {
        languageId: properties.languageId,
        ...properties.item,
      },
    }).then(() => true);
  }
  enable(properties: { languageId: string }): Promise<void> {
    return this.fetch('language/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  disable(properties: { languageId: string }): Promise<void> {
    return this.fetch('language/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
