import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { UsersRoutes } from '../../../users.routes';
import { useTranslation } from 'react-i18next';
import { ScheduleAccessRepository } from '@human/access/repositories/schedule/schedule.repository';
import { ScheduleAccessType } from '@human/access/types/schedule.access.type';
import { ScrollView } from 'react-native';

export function ScheduleUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;

  route: RouteProp<UsersRoutes, 'update'>;
}) {
  const { t } = useTranslation();
  const part: TabType = {
    name: 'timetables',
    icon: 'calendar-week',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<ScheduleAccessType, []>
            prefix={'access.timetables.list'}
            suffix={'list'}
            header={false}
            search={false}
            repository={() =>
              new ScheduleAccessRepository().list({
                userId: properties.route.params.userId,
              })
            }
            columns={[
              {
                type: 'icon',
                icon: 'trash',
                onPress: (row) => {
                  properties.navigation.navigate('scheduleDelete', {
                    level: 1,
                    scheduleId: row.id,
                    userId: properties.route.params.userId,
                  });
                },
              },
              {
                type: 'text',
                name: 'name',
                width: 160,
                onRender: (row) => row.timetableName,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.users.update.timetables.add"
            onPress={() => {
              properties.navigation.navigate('scheduleCreate', {
                level: 1,
                userId: properties.route.params.userId,
              });
            }}
          />
        </ScrollView>
      );
    },
  };

  return part;
}
