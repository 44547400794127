import { ScrollView, View } from 'react-native';
import { HeaderComponent } from '@components/header/header.component';
import { SceneStyles } from './scene.styles';
import { SceneProperties } from './scene.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Scene Component
 * @description Escena de la aplicación que contiene un header y un cuerpo scrolleables.
 *
 * @param {SceneProperties} properties
 * @returns {JSX.Element}
 */

export function SceneComponent(properties: SceneProperties) {
  const { phone } = useDeviceHook();

  if (properties.scrollable === false) {
    return (
      <View style={phone ? SceneStyles.containerMobile : SceneStyles.container}>
        {properties.prefix && properties.header !== false && (
          <HeaderComponent
            icon={properties.icon}
            prefix={properties.prefix}
            suffix={properties.suffix}
            links={properties.links}
            multiWorkspace={properties.multiWorkspace}
          />
        )}
        {properties.children}
      </View>
    );
  }
  return (
    <ScrollView
      style={phone ? SceneStyles.containerMobile : SceneStyles.container}
      showsVerticalScrollIndicator={!phone}
    >
      {properties.prefix && properties.header !== false && (
        <HeaderComponent
          icon={properties.icon}
          prefix={properties.prefix}
          suffix={properties.suffix}
          links={properties.links}
          multiWorkspace={properties.multiWorkspace}
        />
      )}
      {properties.children}
    </ScrollView>
  );
}
