import { ExpireUtility } from '@utils/date/expire/expire.utility';
import { decode } from 'base-64';

function base64UrlDecode(str: string) {
  // Reemplaza los caracteres de la URL con los caracteres base64 adecuados
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  // Añade relleno '=' si es necesario
  while (str.length % 4) {
    str += '=';
  }
  // Decodifica el string base64
  const decodedString = decode(str);
  return decodedString;
}

function parseJwt(token: string) {
  const payload = token.split('.')[1];
  const decodedPayload = base64UrlDecode(payload);
  return JSON.parse(decodedPayload);
}

export function JwtExpireUtility(token: string) {
  const decodedToken = parseJwt(token);

  if (!decodedToken.exp) {
    throw new Error('Invalid token.');
  }

  const expirationDate = new Date(decodedToken.exp * 1000);

  return { date: expirationDate };
}
