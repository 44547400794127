// Modules
import { CSSProperties } from 'react';

/**
 * SplashWebStyles Styles
 * @constant {StyleSheet} SplashWebStylesStyles
 */
export const SplashWebStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'black',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
};
