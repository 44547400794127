import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { FormComponent } from '@components/form/form.component';
import { UserCrmRepository } from '@human/crm/repositories/user/user.repository';
import { ManagerRepository } from '@human/crm/repositories/manager/manager.repository';
import { ManagerCrmType } from '@human/crm/types/manager.crm.type';
import { ModalComponent } from '@components/modal/modal.component';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function CreateManagerCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'createManager'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="user-edit"
      prefix="crm.campaigns.update.manager"
    >
      <FormComponent<ManagerCrmType>
        padding
        prefix={'crm.campaigns.list'}
        repository={{
          send: (data) => {
            return new ManagerRepository()
              .create({
                item: {
                  ...data.item,
                  campaignId: properties.route.params.campaignId,
                },
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'userId',
            required: true,
            description: true,
            pick: (props: { id: string }) => {
              return new UserAccessRepository()
                .pick({
                  userId: props.id,
                })
                .then((user) => {
                  return `${user.name} ${user.surname}`;
                });
            },
            repository: ({ search }) => {
              return new ManagerRepository()
                .listPossible({
                  campaignId: properties.route.params.campaignId,
                  search,
                })
                .then((data) => {
                  return data.items.map((item) => ({
                    value: item.id,
                    label: `${item.name} ${item.surname}`,
                  }));
                });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
