import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { TableComponent } from '@components/table/table.component';
import { ScrollView } from 'react-native';
import { LeadImportAttemptCrmType } from '@human/crm/types/leadImportAttempt.crm.type';
import { LeadImportAttemptCrmRepository } from '@human/crm/repositories/leadImportAttempt/leadImportAttempt.repository';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { useErrorToast } from '@hooks/errorToast/errorToast.hook';
import { isObject } from '@validator/index';
import { t } from 'i18next';
import { exportStringCsvUtility } from '@utils/export/export.csv.utility';
import { TextComponent } from '@components/text/text.component';
import { TabsStyles } from '@components/tabs/tabs.style';
import { FileUploaderComponent } from '@components/fileUploader/fileUploader.component.web';

export function ImportLeadsScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'import'>;
}) {
  const { showSuccess } = useSuccessToast();
  const { showError } = useErrorToast();

  return (
    <ModalComponent width={660} icon="user-ninja" prefix="crm.leads.import">
      <ScrollView style={{ padding: 20 }}>
        <TextComponent
          translate
          text={'crm.leads.import.description'}
          style={[TabsStyles.description, { marginBottom: 20 }]}
        />
        <FileUploaderComponent
          fileType="text/csv"
          onSubmit={async (file) => {
            return new LeadCrmRepository()
              .importCsv({
                csvFile: file,
              })
              .then((res) => {
                if (typeof res === 'number') {
                  showSuccess(`${res} ${t('crm.leads.list.csv.loaded')}`);
                }
                if (isObject(res)) {
                  showError(
                    `${res.success} ${t('crm.leads.list.csv.loaded')}, ${t('crm.leads.list.csv.downloadCsv')}`
                  );
                }
              })
              .catch(() => {
                showError(t('crm.leads.list.csv.wrongdata') ?? undefined);
                return;
              });
          }}
        />
        <TableComponent<LeadImportAttemptCrmType, []>
          prefix={'crm.leads.import.list'}
          suffix={'list'}
          scrollable
          search={false}
          repository={(settings) => {
            return new LeadImportAttemptCrmRepository().list({
              page: settings.page,
              limit: settings.limit,
            });
          }}
          columns={[
            {
              type: 'icon',
              icon: 'trash',
              renderColor: () => 'red',
              onPress: (row) => {
                properties.navigation.navigate('deleteImport', {
                  id: row.id,
                  level: 1,
                });
              },
            },
            {
              type: 'text',
              name: 'name',
              width: 175,
              onRender: (row) => row.fileName,
            },
            {
              type: 'text',
              name: 'errors',
              width: 100,
              onRender: (row) => row.errors.toString(),
            },
            {
              type: 'date',
              name: 'created',
              renderDate: (row) => row.created,
            },
            {
              type: 'button',
              width: 150,
              icon: 'download',
              text: (row) => 'DESCARGAR .CSV',
              onPress: (row) =>
                row.errors > 0
                  ? () => {
                      new LeadImportAttemptCrmRepository().pick({ id: row.id }).then((attempt) => {
                        exportStringCsvUtility({
                          data: attempt.errorContent!,
                          name: attempt.fileName,
                        });
                      });
                    }
                  : null,
            },
          ]}
        />
      </ScrollView>
    </ModalComponent>
  );
}
