import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ScheduleAccessRepository } from '@human/access/repositories/schedule/schedule.repository';

export function DeleteScheduleScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'scheduleDelete'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash"
      prefix="access.users.update.schedules.delete"
    >
      <FormComponent
        prefix="access.users.update.schedules.delete.form"
        padding
        repository={{
          send: () => {
            return new ScheduleAccessRepository()
              .delete({
                scheduleId: properties.route.params.scheduleId,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
