import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ServicesRoutes } from '../../service.routes';
import { ServiceCrmType } from '@human/crm/types/service.crm.type';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
export function CreateServiceScreen(properties: {
  navigation: NavigationProp<ServicesRoutes>;
  route: RouteProp<ServicesRoutes, 'serviceCreate'>;
}) {
  return (
    <ModalComponent icon="resolving" prefix="crm.services.create">
      <FormComponent<ServiceCrmType>
        prefix="crm.services.create.form"
        padding
        repository={{
          send: (settings) => {
            return new ServiceCrmRepository()
              .create({
                item: {
                  ...settings.item,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            description: true,
            type: 'text',
            validations: ['name'],
            required: true,
          },
          {
            name: 'price',
            description: true,
            type: 'float',
            validations: ['price'],
          },
          {
            name: 'nextActivityId',
            type: 'select',
            description: true,
            required: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return activity.name;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository()
                .list({
                  order: 'name',
                  direction: 'asc',
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((activities) =>
                  activities.items.map((activity) => ({
                    value: activity.id,
                    label: activity.name,
                  }))
                );
            },
          },
          {
            name: 'nextActivityDelay',
            description: true,
            type: 'number',
            required: false,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
