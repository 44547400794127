import { Text, View } from 'react-native';
import { MapInputImplementation } from './map.implementation';
import Map, { MapRef, Marker, Projection } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef } from 'react';
import { MapInputStyles } from './map.styles';
import { IconComponent } from '@components/icon/icon.component';

export function MapInput<ITEM>(properties: MapInputImplementation<ITEM>) {
  const mapRef = useRef<MapRef>();
  const marker = properties.value[properties.name] as {
    latitude: number;
    longitude: number;
  };

  const handleClick = ({ lngLat }: mapboxgl.MapLayerMouseEvent) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: {
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      },
    };

    !properties.readonly && properties.onChange(newValue);
  };

  useEffect(() => {
    if (!mapRef) return;
    if (marker?.latitude) {
      mapRef.current?.flyTo({
        center: [marker.longitude, marker.latitude],
        essential: true,
        animate: false,
      });
    }
  }, [marker]);

  return (
    <Map
      dragPan={!properties.readonly}
      scrollZoom={!properties.readonly}
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1Ijoic2FsYmF0b3JlIiwiYSI6ImNseGppd3ZkNDFxdmQycHFwZTZtaGQ5emwifQ.3QZrmpKvZNja_3yjPyYjZg"
      projection={'globe'}
      initialViewState={{
        longitude: marker?.longitude || -3.439,
        latitude: marker?.latitude || 40.4165,
        zoom: 5,
      }}
      style={{ height: 300, backgroundColor: 'rgb(36, 9, 42)', borderRadius: 15 }}
      mapStyle="mapbox://styles/salbatore/clxl8734900fn01pc3ezp5n7h"
      onClick={handleClick}
    >
      {marker?.latitude && (
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <View style={MapInputStyles.marker}>
            <IconComponent
              iconStyle={MapInputStyles.markerIconShape}
              name={properties.marker?.icon || 'map-marker-alt'}
              containerstyle={[
                MapInputStyles.markerIcon,
                { backgroundColor: properties.marker?.color || 'rgba(0, 0, 0, 0.15)' },
              ]}
            />
          </View>
        </Marker>
      )}
    </Map>
  );
}
