import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { ProgramCycleCrmRepository } from '@human/crm/repositories/programCycle/programCycle.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';

export function CreateProgramCycleScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programCycleCreate'>;
}) {
  return (
    <ModalComponent icon="plus" prefix="crm.programs.update.cycles.create">
      <FormComponent<{ cycleId: string }>
        padding
        prefix={'crm.programs.update.cycles.create.form'}
        repository={{
          send: ({ item }) => {
            return new ProgramCycleCrmRepository()
              .create({
                programId: properties.route.params.programId,
                cycleId: item.cycleId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            type: 'select',
            description: true,
            name: 'cycleId',
            required: true,
            pick: (props: { id: string }) => {
              return new CycleCrmRepository().pick({ cycleId: props.id }).then((cycle) => {
                return `${cycle.name}`;
              });
            },
            repository: ({ search }) => {
              return new CycleCrmRepository()
                .list({ page: 0, limit: 100, search })
                .then((response) =>
                  response.items.map((item) => ({ label: item.name, value: item.id }))
                );
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
