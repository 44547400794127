// Modules
import { StyleSheet } from 'react-native';

/**
 * SceneStyles Styles
 * @constant {StyleSheet} SceneStylesStyles
 */
export const SceneStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    gap: 20,
    backgroundColor: '#E4E4E4',
  },
  containerMobile: {
    flex: 1,
    padding: 10,
    gap: 10,
    backgroundColor: '#E4E4E4',
  },
});
