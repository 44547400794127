import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TimetableDayAccessType } from '@human/access/types/timetableDay.access.type';

export class TimetableDayAccessRepository extends AuthorizedRepository {
  async create(properties: { item: Partial<TimetableDayAccessType> }) {
    return this.fetch('timetableDay/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async list(properties: { timetableId: string; page?: number; limit?: number }): Promise<{
    items: TimetableDayAccessType[];
    total: number;
  }> {
    return this.fetch('timetableDay/findbyTimetable', {
      params: properties,
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((timetable: TimetableDayAccessType) => timetable),
      total: data.total,
    }));
  }

  async pick(properties: { timetableId: string; timetableDayId: string }) {
    return this.fetch('timetableDay/pick', {
      params: properties,
      method: 'GET',
    }).then((timetable) => timetable);
  }

  async delete(properties: { timetableId: string; timetableDayId: string }) {
    return this.fetch('timetableDay/delete', {
      method: 'DELETE',
      params: properties,
    }).then(() => true);
  }

  async update(properties: { item: Partial<TimetableDayAccessType> }) {
    return this.fetch('timetableDay/update', {
      method: 'PUT',
      body: properties.item,
    });
  }
}
