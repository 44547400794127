import { NavigationProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function CreateLeadScreen(properties: { navigation: NavigationProp<LeadsRoutes> }) {
  return (
    <ModalComponent icon="user-ninja" prefix="crm.leads.create">
      <FormComponent<{
        description: string;
        clinicId: string;
        contactId: string;
        campaignId: string;
      }>
        prefix="crm.leads.create.form"
        padding
        repository={{
          send: (data) => {
            return new LeadCrmRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'description',
            required: false,
            validations: ['description'],
            lines: 6,
          },
          {
            type: 'select',
            name: 'clinicId',
            description: true,
            required: true,
            pick: (properties: { id: string }) => {
              return new ClinicAccessRepository().pick({ id: properties.id }).then((clinic) => {
                return `${clinic.name}`;
              });
            },
            repository: ({ search }) => {
              return new ClinicAccessRepository()
                .list({
                  page: 0,
                  search,
                  limit: 100,
                  active: true,
                })
                .then((clinics) =>
                  clinics.items.map((clinic) => ({
                    value: clinic.id,
                    label: clinic.name,
                  }))
                );
            },
          },
          {
            type: 'select',
            name: 'contactId',
            description: true,
            pick: (properties: { id: string }) => {
              return new ContactCrmRepository().pick({ id: properties.id }).then((contact) => {
                return `${contact.name} ${contact.surname}`;
              });
            },
            repository: ({ search }) => {
              return new ContactCrmRepository()
                .list({
                  search,
                  direction: 'desc',
                  page: 0,
                  limit: 100,
                  active: true,
                  order: 'name',
                })
                .then((contacts) =>
                  contacts.items.map((contact) => ({
                    value: contact.id,
                    label: `${contact.name} ${contact.surname}`,
                  }))
                );
            },
          },
          {
            type: 'select',
            name: 'campaignId',
            description: true,
            pick: (properties: { id: string }) => {
              return new CampaignCrmRepository().pick({ id: properties.id }).then((campaign) => {
                return `${campaign.name}`;
              });
            },
            repository: ({ search }) => {
              return new CampaignCrmRepository()
                .list({
                  search,
                  direction: 'desc',
                  page: 0,
                  limit: 100,
                  active: true,
                })
                .then((campaigns) =>
                  campaigns.items.map((campaign) => ({
                    value: campaign.id,
                    label: campaign.name,
                  }))
                );
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
