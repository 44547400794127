import { View } from 'react-native';
import { Bar } from 'react-chartjs-2';
import { BarChartProperties } from './barChart.properties';

export function BarChartComponent(properties: BarChartProperties) {
  const auxLeadEvent = Object.keys(properties.leadEvent).filter((value) => {
    const excludeInSpanish = ['MEDICALEVALUATED'];
    const excludeInInternational = ['MEDICALAPPOINTED', 'ATTENDED'];
    const isSpanish = properties.selectedDepartmentName === 'Español';

    if (isSpanish) {
      return !excludeInSpanish.includes(value);
    } else {
      return !excludeInInternational.includes(value);
    }
  });

  const headers = properties.total
    ? ['Total', ...auxLeadEvent.map((value) => properties.leadEvent[value])]
    : [...auxLeadEvent.map((value) => properties.leadEvent[value])];

  //Asi nos aseguramos que cada dato va con su leadEvent correspondiente
  //Si va por posicion, se puede desordenar y mas cuando los leads events cambian en funcion de los departamentos
  const datasets = properties.total
    ? [
        properties.data.total_leads,
        ...auxLeadEvent.map((key) => {
          switch (key) {
            case 'CONTACTED':
              return properties.data.lead_contacted_count;
            case 'APPOINTED':
              return properties.data.lead_appointed_count;
            case 'INFORMED':
              return properties.data.lead_informed_count;
            case 'MEDICALAPPOINTED':
              return properties.data.lead_medical_appointed_count;
            case 'ATTENDED':
              return properties.data.lead_medic_attended_count;
            case 'COMERCIALATTENDED':
              return properties.data.lead_comercial_attended_count;
            case 'MEDICALEVALUATED':
              return properties.data.lead_medical_evaluated_count;
            case 'CONVERTED':
              return properties.data.lead_converted_count;
            default:
              return 0;
          }
        }),
      ]
    : auxLeadEvent.map((key) => {
        switch (key) {
          case 'CONTACTED':
            return properties.data.lead_contacted_count;
          case 'APPOINTED':
            return properties.data.lead_appointed_count;
          case 'INFORMED':
            return properties.data.lead_informed_count;
          case 'MEDICALAPPOINTED':
            return properties.data.lead_medical_appointed_count;
          case 'ATTENDED':
            return properties.data.lead_medic_attended_count;
          case 'COMERCIALATTENDED':
            return properties.data.lead_comercial_attended_count;
          case 'MEDICALEVALUATED':
            return properties.data.lead_medical_evaluated_count;
          case 'CONVERTED':
            return properties.data.lead_converted_count;
          default:
            return 0;
        }
      });

  const data = {
    labels: headers,
    datasets: [
      {
        data: datasets,
        backgroundColor: '#283038',
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };

  return (
    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 4 }}>
      <Bar
        data={data}
        options={{
          responsive: true,
          aspectRatio: properties.aspectRatio ? properties.aspectRatio : 3,

          scales: {
            y: {
              beginAtZero: true, // Comienza desde 0
            },
          },
          elements: {
            bar: {
              borderRadius: 6, // Radio del borde
            },
          },
          plugins: {
            legend: {
              display: false, // Oculta la leyenda si no quieres mostrarla
            },
          },
        }}
      />
    </View>
  );
}
