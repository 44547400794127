import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReasonsRoutes } from '../../reason.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ReasonCrmType } from '@human/crm/types/reason.crm.type';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';
import { CrmSections } from '@human/crm/crm.sections';

export function CreateReasonScreen(properties: {
  navigation: NavigationProp<ReasonsRoutes, 'create'>;
  route: RouteProp<ReasonsRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon={CrmSections.reasons.icon} prefix="crm.reasons.create">
      <FormComponent<ReasonCrmType>
        prefix="crm.reasons.create.form"
        padding
        repository={{
          send: (data) => {
            return new ReasonCrmRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['title'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            required: true,
            validations: ['description'],
            lines: 6,
          },
          {
            name: 'color',
            type: 'color',
            required: true,
            description: true,
          },
          {
            name: 'icon',
            type: 'icon',
            required: true,
            description: true,
          },
          {
            name: 'canceled',
            type: 'checkbox',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
