import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PhaseCrmType } from '@human/crm/types/phase.crm.type';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { PhasesRoutes } from '../../phase.routes';
export function CreatePhaseScreen(properties: {
  navigation: NavigationProp<PhasesRoutes>;
  route: RouteProp<PhasesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="tasks" prefix="crm.phases.create">
      <FormComponent<PhaseCrmType>
        prefix="crm.phases.create.form"
        padding
        repository={{
          send: (settings) => {
            return new PhaseCrmRepository()
              .create({
                item: settings.item,
              })
              .then(() => {
                properties.navigation.navigate('list');
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['title'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            required: true,
            validations: ['description'],
            lines: 6,
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
            required: true,
          },
          {
            name: 'color',
            type: 'color',
          },
        ]}
      />
    </ModalComponent>
  );
}
