import { MovementAccessType } from '@human/access/types/movement.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class MovementAccessRepository extends AuthorizedRepository {
  async list(properties: {
    userId: string;
    page: number;
    limit: number;
  }): Promise<{ items: MovementAccessType[]; total: number }> {
    return this.fetch('movement/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((movement: any) => ({
        id: movement.id,
        created: new Date(movement.created),
        path: movement.path,
        method: movement.method,
        ip: movement.ip,
        sessionId: movement.sessionId,
      })),
    }));
  }
}
