import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ActivityCommunicationCrmType } from '@human/crm/types/activityCommunicationChannel.type';

export class ActivityCommunicationChannelCrmRepository extends AuthorizedRepository {
  async list(properties: {
    page: number;
    limit: number;
    search?: string;
    activityId: string;
  }): Promise<{ items: ActivityCommunicationCrmType[]; total: number }> {
    return this.fetch('activityCommunicationChannel/find', {
      method: 'GET',
      params: {
        activityId: properties.activityId,
      },
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          channelId: item.channelId,
          channelName: item.channelName,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { activityId: string; communicationChannelId: string }): Promise<void> {
    return this.fetch('activityCommunicationChannel/create', {
      method: 'POST',
      body: {
        activityId: properties.activityId,
        communicationChannelId: properties.communicationChannelId,
      },
    });
  }

  async delete(properties: { activityId: string; communicationChannelId: string }): Promise<void> {
    return this.fetch('activityCommunicationChannel/delete', {
      method: 'DELETE',
      params: {
        activityId: properties.activityId,
        communicationChannelId: properties.communicationChannelId,
      },
    });
  }
}
