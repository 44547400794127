import { View } from 'react-native';
import { TextComponent } from '../../../../../text/text.component';
import { CodeColumnImplementation } from './code.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { CodeColumnStyles } from './code.column.styles';
import { IconComponent } from '@components/icon/icon.component';

export function CodeColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: CodeColumnImplementation<ITEM, ORDER>
) {
  const text = properties.onRender?.(properties.item) ?? properties.name;
  const color = properties.color?.(properties.item);
  const background = properties.background?.(properties.item);
  return (
    <View style={[
      CodeColumnStyles.container,
      {
        backgroundColor: background
      },
    ]}>
      <View style={[
        CodeColumnStyles.bubble,

      ]}>
        <IconComponent name="tag" containerstyle={CodeColumnStyles.icon} iconStyle={CodeColumnStyles.iconShape} />
        <TextComponent lines={1} bold text={`${text}`} style={[CodeColumnStyles.text, {
          color: color ?? CodeColumnStyles.text.color,
        }]}

        />
      </View>
    </View>
  );
}
