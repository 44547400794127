import { isObject } from '../isObject/is.object.validator';

export function isInObject(
  key: string,
  value: unknown
): value is {
  [key: string]: unknown;
} {
  // validate if is object

  if (isObject(value)) {
    // validate if key is in object
    if (key in value) {
      return true;
    }
    // return value
  }
  return false;
}
