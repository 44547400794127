import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { TextProperties } from './text.properties';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';

/**
 * TextComponent
 * @description TextComponent renders a text with the specified properties.
 * The component allows the user to display text with different styles.
 *
 * @param {TextProperties} properties - The properties object containing the text, font, and style of the text.
 *
 * @returns {JSX.Element} The rendered JSX element containing the text component.
 *
 * @example
 * <TextComponent text="Hello World" font="logotype" bold />
 *
 */

export function TextComponent(properties: TextProperties) {
  const { t } = useTranslation();
  const fontFamily = properties.font === 'logotype' ? 'TsukimiRounded' : 'Lato';

  const getText = () => {
    let auxText = properties.text;

    if (
      properties.multiWorkspace
      // && t(`${auxText}.${getWorkspaceUtility()}`) === `${auxText}.${getWorkspaceUtility()}`
    ) {
      auxText += `.${getWorkspaceUtility()}`;
    }
    if (properties.translate) auxText = t(auxText);

    return auxText;
  };

  return (
    <Text
      ellipsizeMode={properties.ellipsizeMode}
      numberOfLines={properties.lines}
      selectable
      style={[
        {
          fontFamily: properties.bold
            ? `${fontFamily}-Bold`
            : properties.light
              ? `${fontFamily}-Light`
              : properties.thin
                ? `${fontFamily}-Thin`
                : properties.strong
                  ? `${fontFamily}-Black`
                  : `${fontFamily}-Regular`,
          fontWeight: properties.light ? '100' : undefined,
        },
        properties.style,
      ]}
    >
      {getText()}
    </Text>
  );
}

export function TranslateTextComponent(properties: { text: string }) {
  const { t } = useTranslation();

  return t(properties.text);
}
