import { NavigationComponent } from '@components/navigation/navigation.component';
import { ReasonsRoutes } from './reason.routes';
import { ListReasonScreen } from './screen/list/list.reason.screen';
import { CreateReasonScreen } from './screen/create/create.reason.screen';
import { UpdateReasonScreen } from './screen/update/update.reason.screen';
import { DisableReasonScreen } from './screen/disable/disable.reason.screen';
import { EnableReasonScreen } from './screen/enable/enable.reason.screen';

const Navigation = NavigationComponent<ReasonsRoutes>();

export function ReasonsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListReasonScreen} />
      <Navigation.Screen name="create" component={CreateReasonScreen} />
      <Navigation.Screen name="update" component={UpdateReasonScreen} />
      <Navigation.Screen name="disable" component={DisableReasonScreen} />
      <Navigation.Screen name="enable" component={EnableReasonScreen} />
    </Navigation.Navigator>
  );
}
