import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../users.routes';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function PasswordDeleteUserScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'password'>;
}) {
  return (
    <ModalComponent
      icon="trash"
      prefix="access.users.delete.passwordForm"
      level={properties.route.params.level}
    >
      <FormComponent<{ password: string }>
        prefix="access.users.delete.passwordForm"
        padding
        repository={{
          get: async () => {
            return {
              password: __DEV__ ? process.env.EXPO_PUBLIC_DEV_PASSWORD : '',
            };
          },
          send: (data) =>
            new UserAccessRepository()
              .delete({
                id: properties.route.params.userId,
                password: data.item.password,
              })
              .then(() => {
                properties.navigation.navigate('list');
              }),
        }}
        fields={[
          {
            name: 'password',
            icon: 'lock',
            type: 'text',
            secure: true,
            validations: ['password'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
