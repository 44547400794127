import { isInObject } from '../inObject/in.object.validator';
import { isNumber } from '../isNumber/is.number.validator';

export function isArrayUnknown(value: unknown): value is unknown[] {
  if (!isInObject('length', value))
    return false;
  if (!isNumber(value.length))
    return false;
  return true;
}
