import { ScrollView, Text, View, Image } from 'react-native';
import { TextComponent } from '../text/text.component';
import { CardComponent } from './components/card/card.component';
import { LaunchStyles } from './launch.styles';
import { LaunchProperties } from './launch.properties';
import { useDeviceHook } from '@hooks/device/device.hook';
import { LogotypeComponent } from '@components/logotype/logotype.component';
import { ClockComponent } from '@components/clock/clock.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useNavigation } from '@react-navigation/native';
import { useUserState } from '@states/user/user.state';
import { useEffect, useState } from 'react';

/**
 * Launch Component
 * @description Componente de presentación de la aplicación, muestra la marca,
 * el reloj y las secciones de la aplicación. Se puede alternar entre los diferentes
 * productos de la marca como OVOCLINIC y OVOHORSE.
 *
 * @param {LaunchProperties} properties
 * @returns {JSX.Element}
 */

export function LaunchComponent(properties: LaunchProperties) {
  const { phone, tablet, laptop } = useDeviceHook();
  const { permissions } = useUserState();
  const navigation = useNavigation<any>();
  const [cards, setCards] = useState<
    {
      key: string;
      permission: boolean;
    }[]
  >([]);

  useEffect(() => {
    const permissionsBySection = Object.entries(properties.sections).map(([key, value]) => ({
      key,
      permission: hasPermissionUtilty({
        name: value.permission ?? '',
      }),
    }));
    if (permissionsBySection.filter((p) => p.permission && p.key !== 'desktop').length === 1) {
      navigation.navigate(
        permissionsBySection.find((p) => p.permission && p.key !== 'desktop')?.key
      );
    }
    setCards(permissionsBySection);
  }, [permissions]);

  const getLogo = () => {
    switch (process.env.EXPO_PUBLIC_WORKSPACE) {
      case 'ovoclinic':
        return require('../../../assets/images/logo.png');
      case 'ovobank':
        return require('../../../assets/images/ovobank.png');
      default:
        return require('../../../assets/images/logo.png');
    }
  };

  return (
    <ScrollView
      scrollEnabled={phone || tablet}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[
        LaunchStyles.container,
        (phone || tablet) && LaunchStyles.containerPadding,
      ]}
      style={LaunchStyles.scrollView}
    >
      <View style={{ marginTop: laptop ? 100 : 150 }} />
      <LogotypeComponent type="human" />
      {!phone && (
        <View
          style={{
            flex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            marginHorizontal: 50,
            marginVertical: 35,
          }}
        >
          <Image
            source={require('../../../assets/images/logo.png')}
            resizeMode="contain"
            style={{ width: 300, height: 100 }}
          />
        </View>
      )}
      {!phone && <ClockComponent />}
      <View
        style={{
          marginTop: 30,
        }}
      >
        {phone || tablet ? (
          <View style={LaunchStyles.introduction}>
            <TextComponent translate bold text="launch.title" style={LaunchStyles.title} />
            <TextComponent translate text="launch.subtitle" style={LaunchStyles.subtitle} />
            <View style={LaunchStyles.separator} />
            <TextComponent
              translate
              light
              text="launch.description"
              style={LaunchStyles.description}
              multiWorkspace
            />
          </View>
        ) : (
          <View style={LaunchStyles.introduction}>
            <TextComponent translate bold text="launch.title" style={LaunchStyles.title} />
            <TextComponent translate text="launch.subtitle" style={LaunchStyles.subtitle} />
            <View style={LaunchStyles.separator} />
            <TextComponent
              translate
              light
              text="launch.description"
              style={LaunchStyles.description}
              multiWorkspace
            />
          </View>
        )}

        <View
          style={[
            phone
              ? LaunchStyles.cardContainerVertical
              : tablet
                ? LaunchStyles.cardContainerWrap
                : LaunchStyles.cardContainer,
          ]}
        >
          {Object.entries(properties.sections).map(([key, value], index) => {
            if (key === 'desktop') return null;
            if (!cards.find((section) => section.key === key)?.permission) return null;

            return (
              <CardComponent
                key={key}
                time={value.time}
                version={value.version}
                index={index}
                title={key}
                description="Description"
                keywords={value.keywords}
                icon={value.icon}
              />
            );
          })}
        </View>
      </View>

      {/* deuda técnica para incorporar animaciones, crear componente para mobile y desktop */}
    </ScrollView>
  );
}
