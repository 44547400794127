import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { TabType } from '@components/tabs/tabs.types';
import { CrmSections } from '@human/crm/crm.sections';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ContactUpdatePart } from '@human/crm/sections/contacts/screens/update/parts/contact.update.part';

export function ContactUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'contact',
    icon: CrmSections.contacts.icon,
    content: (settings) => {
      return (
        <ContactUpdatePart
          contactId={properties.route.params.contactId}
          onSend={() => {
            settings?.setIsEditing(false);
            showSuccess();
          }}
          onChange={(data) => {
            if (!data.isLocal) return;
            settings?.setIsEditing(true);
          }}
        />
      );
    },
  };
}
