import { FormComponent } from '@components/form/form.component';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { OvoBankContactCrmRepository } from '@human/crm/repositories/ovobankContact/ovobankContact.repository';
import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useTranslation } from 'react-i18next';

export function UpdateOvoBankContactForm(properties: {
  contactId: string;
  onSend: () => void;
  onChange: (properties: { item: Partial<ContactCrmType>; isLocal: boolean }) => void;
}) {
  const { t } = useTranslation();

  return (
    //De momento solo vamos a editar el primero contacto y email de las relaciones, mas adelante se arregalara para que se puedan editar todos los telefonos y emails
    <FormComponent<ContactCrmType & { phone: string; prefix: string; email: string }>
      prefix="crm.contacts.update.form"
      onChange={properties.onChange}
      readonly={!hasPermissionUtilty({ name: 'crm.contacts.editor' })}
      repository={{
        send: (data) => {
          return new OvoBankContactCrmRepository()
            .update({
              item: data.item,
            })
            .then(() => {
              properties.onSend();
            });
        },
        get: () => {
          return new OvoBankContactCrmRepository()
            .pick({
              id: properties.contactId,
            })
            .then((data) => {
              return {
                ...data,
                phone: data.phones[0]?.phone || '',
                email: data.emails[0]?.email || '',
                prefix: data.phones[0]?.prefix || '',
              };
            });
        },
      }}
      fields={[
        {
          type: 'avatar',
          name: 'photo',
          description: true,
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'name',
              required: true,
              validations: ['name'],
            },
            {
              type: 'text',
              name: 'surname',
              required: true,
              validations: ['surname'],
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'prefix',
              required: true,
              validations: ['prefix'],
            },
            {
              type: 'text',
              name: 'phone',
              required: true,
              validations: ['phone'],
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'email',
              required: true,
              validations: ['email'],
            },
            {
              type: 'text',
              name: 'dni',
              validations: ['dni'],
              required: false,
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'date',
              name: 'birthDate',
              description: true,
              required: true,
              format: 'YYYY-MM-DD',
            },
            {
              type: 'text',
              name: 'birthCountry',
              description: true,
              required: true,
              validations: ['name'],
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'number',
              name: 'height',
              description: true,
              required: true,
              validations: ['positive'],
            },
            {
              type: 'number',
              name: 'weight',
              description: true,
              required: true,
              validations: ['positive'],
            },
          ],
        },
        {
          type: 'text',
          name: 'donationPreference',
          description: true,
          required: true,
          validations: ['name'],
        },
        {
          type: 'checkbox',
          name: 'legal',
          description: true,
          required: true,
        },
        {
          type: 'checkbox',
          name: 'marketing',
          description: true,
        },
        {
          type: 'map',
          name: 'center',
          description: true,
          required: true,
        },
      ]}
    />
  );
}
