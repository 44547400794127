import { IconType } from '../../../../../components/icon/icon.types';
import { AccessRoutes } from './access.routes';
export const AccessSections: {
  [key in keyof AccessRoutes]: {
    name: string;
    icon: IconType;
    keywords: string[];
    department: string;
    status?: boolean;
    permission?: string;
  };
} = {
  users: {
    name: 'access.users',
    icon: 'users',
    keywords: ['users', 'people', 'staff'],
    department: 'directory',
    status: true,
    permission: 'access.users',
  },
  roles: {
    name: 'roles',
    icon: 'user-tag',
    keywords: ['roles', 'permissions', 'access'],
    department: 'directory',
    status: true,
    permission: 'access.roles',
  },
  specialists: {
    name: 'specialists',
    icon: 'user-md',
    keywords: ['specialists', 'doctors', 'professionals'],
    department: 'directory',
    status: true,
    permission: 'access.specialists',
  },
  templates: {
    name: 'templates',
    icon: 'file-alt',
    keywords: ['templates', 'forms', 'documents'],
    department: 'landbot',
    status: true,
    permission: 'access.templates',
  },
  timetables: {
    name: 'timetables',
    icon: 'clock',
    keywords: ['timetables', 'schedules', 'appointments'],
    department: 'planning',
    status: true,
    permission: 'access.timetables',
  },
  bulletins: {
    name: 'bulletins',
    icon: 'newspaper',
    keywords: ['bulletins', 'news', 'articles'],
    department: 'communications',
    status: true,
    permission: 'access.bulletins',
  },

  communicationChannels: {
    name: 'communicationChannels',
    icon: 'comments',
    keywords: ['communicationChannels'],
    department: 'communications',
    status: true,
    permission: 'access.commsChannels',
  },

  clinics: {
    name: 'clinics',
    icon: 'clinic-medical',
    keywords: ['clinics', 'hospitals', 'centers'],
    department: 'locations',
    status: true,
    permission: 'access.clinics',
  },
  departments: {
    name: 'departments',
    icon: 'hospital',
    keywords: ['departments', 'offices', 'locations'],
    department: 'locations',
    status: true,
    permission: 'access.departments',
  },
  languages: {
    name: 'languages',
    icon: 'language',
    keywords: ['languages', 'translations', 'idioms'],
    department: 'globalization',
    status: true,
    permission: 'access.languages',
  },
  countries: {
    name: 'countries',
    icon: 'globe',
    keywords: ['countries', 'nations', 'states'],
    department: 'globalization',
    status: true,
    permission: 'access.countries',
  },
  settings: {
    name: 'settings',
    icon: 'cog',
    keywords: ['configuration', 'settings', 'options'],
    department: 'settings',
    status: true,
    permission: 'access.settings',
  },
  ovopoints: {
    name: 'ovopoints',
    icon: 'coins',
    keywords: ['ovopoints', 'points', 'rewards'],
    department: 'ovopoints',
    status: true,
    permission: 'access.ovopoints',
  },
};
