import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { ClinicsRoutes } from '../../clinics.routes';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';

export function EnableClinicScreen(properties: {
  navigation: NavigationProp<ClinicsRoutes>;
  route: RouteProp<ClinicsRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.clinics.enable">
      <FormComponent
        prefix="access.clinics.enable.form"
        padding
        repository={{
          send: () => {
            return new ClinicAccessRepository()
              .enable({
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
