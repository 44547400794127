import { StyleSheet } from 'react-native';

export const ProfileStyles = StyleSheet.create({
  rowContainer: {
    padding: 30,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 22,
    borderBottomColor: 'rgba(255, 255, 255, 0.2)',
    borderBottomWidth: 1,
  },
  textContainer: { overflow: 'hidden', width: '70%' },
  imageTextContainer: {
    height: 70,
    borderRadius: 10,
    width: 70,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: 1,
  },
  image: {
    height: 70,
    borderRadius: 10,
    width: 70,
  },
  iconContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  icon: {
    fontSize: 70,
    fontWeight: 'bold',
    color: 'rgba(237, 53, 145, 0.5)',
  },
  ovoText: {
    backgroundColor: 'rgb(237, 53, 145)',
    fontSize: 12,
    position: 'absolute',
    bottom: -10,
    padding: 3,
    paddingHorizontal: 6,
    right: -10,
    borderRadius: 100,
    color: '#fff',
  },
  nameText: {
    color: 'rgb(255, 255, 255)',
    fontSize: 20,
  },
  emailText: {
    color: 'rgba(237, 53, 145, 0.6)',
    fontSize: 13,
  },
});
