import { CalendarProperties } from './calendar.properties';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CalendarStyles } from './calendar.styles.web';
import { useIsFocused } from '@react-navigation/native';
import { EventType } from './calendar.types';
import { useTranslation } from 'react-i18next';
moment.locale('es');

const localizer = momentLocalizer(moment);

export function CalendarComponent(properties: CalendarProperties) {
  const [events, setEvents] = useState<EventType[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const focused = useIsFocused();
  const { i18n, t } = useTranslation();
  const messages = {
    allDay: t('crm.calendar.messages.allDay'),
    previous: t('crm.calendar.messages.previous'),
    next: t('crm.calendar.messages.next'),
    today: t('crm.calendar.messages.today'),
    month: t('crm.calendar.messages.month'),
    week: t('crm.calendar.messages.week'),
    day: t('crm.calendar.messages.day'),
    agenda: t('crm.calendar.messages.agenda'),
    date: t('crm.calendar.messages.date'),
    time: t('crm.calendar.messages.time'),
    event: t('crm.calendar.messages.event'),
  };

  useEffect(() => {
    if (!focused) return;
    properties.repository().then((events) => {
      setEvents(events);
    });
  }, [properties.repository, focused]);

  return (
    <div style={CalendarStyles}>
      <Calendar
        onNavigate={properties.onNavigate}
        localizer={localizer}
        culture={i18n.language}
        messages={messages}
        events={events}
        views={['month', 'week', 'day']}
        step={30}
        timeslots={1}
        defaultDate={currentDate}
        selectable
        startAccessor="start"
        endAccessor="end"
        dayLayoutAlgorithm="no-overlap"
        style={{ overflowY: 'auto' }}
        onSelectSlot={(slotInfo) => {
          properties.onSlotPress({
            end: slotInfo.end,
            start: slotInfo.start,
          });
        }}
        onSelectEvent={(event) => {
          properties.onEventPress(event);
        }}
        components={{
          // custom event component month view
          month: {
            event: (props) => {
              return (
                <div
                  className="rbc-month-event-view"
                  style={{
                    backgroundColor: !props.event.leadStatus ? 'gray' : props.event.color,
                  }}
                >
                  <div className="rbc-event-content">
                    <div>
                      <div className="rbc-event-title">{props.event.title}</div>
                      <div className="rbc-event-clinic">{props.event.clinicName}</div>
                    </div>
                    <div className="rbc-event-subtitle">{props.event.subtitle}</div>
                  </div>
                </div>
              );
            },
          },
          day: {
            event: (props) => {
              return (
                <div className="rbc-day-view">
                  <div className="rbc-event-content">
                    <div>{props.event.subtitle}</div>
                    <div>&ndash;</div>
                    <div>{props.event.clinicName}</div>
                  </div>
                </div>
              );
            },
          },
        }}
      />
    </div>
  );
}
