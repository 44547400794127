import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import { Platform, Text, View, useWindowDimensions } from 'react-native';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SceneComponent } from '@components/scene/scene.component';
import { BoardHeaderComponent } from '../../components/header/board.header.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { MarketingBoardRepository } from '../../repositories/marketing/marketing.repository';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { LeadDetailBoardRepository } from '../../repositories/leadDetail/leadDetail.repository';
import { TotalLeadsByFunnelTable } from './components/totalLeadsByFunnelTable/totalLeadsByFunnel.table';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { TotalLeadsStateDepartmentsTable } from './components/totalLeadsStateDepartmentsTable/totalLeadsStateDepartmentsTable.component';
import { CampaignLeadCostDetail } from './components/campaignLeadCostDetail/campaignLeadCostDetail.component';
import { CostPerLeadComponent } from './components/costPerLead/costPerLead.component';
import { AppointedLeadsChartComponent } from './components/appointedLeadsChart/appointedLeadsChart.component';
import { BoardStyles } from '../common/board.styles';
import { LoaderComponent } from '@components/loader/loader.component';
import { GeneralTableComponent } from './components/general/general.table.component';
import { TotalClinicLeadsSuccessData } from './components/general/totalClinicLeadsSuccessData';

export default function MarketingScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'marketing'>;
}) {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  // ***** filters for totalLead
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [selectedClinic, setSelectedClinic] = useState<{ id: string; name: string }>();
  const [clinics, setClinics] = useState<{ label: string; value: string }[]>([]);

  const [selectedFunnel, setSelectedFunnel] = useState<{ id: string; name: string }>({
    id: 'all',
    name: t('board.leads.totalLeads.filters.all'),
  });
  const [funnels, setFunnels] = useState<{ label: string; value: string }[]>([]);
  const [funnelSearch, setFunnelSearch] = useState<string>();

  const [selectedCampaign, setSelectedCampaign] = useState<{ id: string; name: string }>({
    id: 'all',
    name: t('board.leads.totalLeads.filters.all'),
  });
  const [campaigns, setCampaigns] = useState<{ label: string; value: string }[]>([]);
  const [campaignSearch, setCampaignSearch] = useState<string>();
  const [month, setMonth] = useState<number>(new Date().getMonth());

  const [totalLeads, setTotalLeads] =
    useState<{ department_name: string; month: number; total: number }[]>();
  const [loadingTotalLeads, setLoadingTotalLeads] = useState<boolean>(false);

  const [totalLeadsState, setTotalLeadsState] = useState<
    {
      department_id: string;
      department_name: string;
      total_leads: number;
      lead_appointed_count: number;
      lead_percentage_appointed: number;
      lead_active_count: number;
      lead_inactive_count: number;
    }[]
  >([]);
  const [loadingTotalLeadsState, setLoadingTotalLeadsState] = useState<boolean>(false);

  const [totalClinicLeadsSuccess, setTotalClinicLeadsSuccess] =
    useState<TotalClinicLeadsSuccessData>();
  const [loadingClinicLeadsSuccess, setLoadingClinicLeadsSuccess] = useState<boolean>(false);

  const [totalLeadsCost, setTotalLeadsCost] = useState<{
    clinicName: string;
    campaign_name: string;
    campaign_budget: number;
    total_leads: number;
    lead_appointed_count: number;
    cost_total_lead: number;
    cost_total_appointed_lead: number;
  }>();
  const [loadingTotalLeadsCost, setLoadingTotalLeadsCost] = useState<boolean>(false);

  /** Funciones para cragar el estado */

  const newLeadCountYear = async () => {
    if (!selectedClinic || !selectedFunnel.id || !selectedCampaign.id || !year) return;

    setLoadingTotalLeads(true);
    new LeadDetailBoardRepository()
      .newLeadCountYear({
        clinicId: selectedClinic.id,
        year: year,
        funnelId: selectedFunnel.id === 'all' ? undefined : selectedFunnel.id,
        campaignId: selectedCampaign.id === 'all' ? undefined : selectedCampaign?.id,
      })
      .then((data) => {
        setTotalLeads(data);
        setLoadingTotalLeads(false);
      });
  };

  const leadStateByClinicYearMonthFunnel = async () => {
    if (!selectedClinic || !selectedFunnel.id || !selectedCampaign || !year || !month) return;

    setLoadingTotalLeadsState(true);
    new LeadDetailBoardRepository()
      .leadStateByClinicYearMonthFunnel({
        clinicId: selectedClinic.id,
        year: year,
        month: month + 1,
        funnelId: selectedFunnel.id === 'all' ? undefined : selectedFunnel.id,
        campaignId: selectedCampaign.id === 'all' ? undefined : selectedCampaign?.id,
      })
      .then((data) => {
        setTotalLeadsState(data);
        setLoadingTotalLeadsState(false);
      });
  };

  const leadsCostByCampaign = async () => {
    if (!selectedClinic || !selectedCampaign.id) return;

    setLoadingTotalLeadsCost(true);
    new LeadDetailBoardRepository()
      .leadsCostByCampaign({
        clinicId: selectedClinic.id,
        campaignId: selectedCampaign.id === 'all' ? '' : selectedCampaign.id,
      })
      .then((data) => {
        setTotalLeadsCost({
          clinicName: clinics.find((clinic) => clinic.value === selectedClinic.id)?.label ?? '',
          ...data,
        });
      })
      .finally(() => setLoadingTotalLeadsCost(false));
  };

  const listTotalClinicLeadsSuccess = async () => {
    if (!selectedFunnel.id || !selectedCampaign.id || !year) return;

    setLoadingClinicLeadsSuccess(true);
    new MarketingBoardRepository()
      .listTotalClinicLeadsSuccess({
        year: year,
        funnelId: selectedFunnel.id === 'all' ? undefined : selectedFunnel.id,
        campaignId: selectedCampaign?.id === 'all' ? undefined : selectedCampaign?.id,
      })
      .then((data) => {
        setTotalClinicLeadsSuccess(data);
        setLoadingClinicLeadsSuccess(false);
      });
  };

  const fetchCampaigns = async (funnelId?: string) => {
    if (!funnelId || funnelId === 'all') {
      new CampaignCrmRepository()
        .list({
          direction: 'asc',
          page: 0,
          limit: 100,
          search: campaignSearch || undefined,
        })
        .then((campaigns) => {
          setCampaigns([
            {
              label: t('board.leads.totalLeads.filters.all'),
              value: 'all',
            },
            ...campaigns.items.map((campaign) => {
              return {
                label: campaign.name,
                value: campaign.id,
              };
            }),
          ]);
        });
    } else {
      new CampaignCrmRepository()
        .listByFunnel({
          funnelId: funnelId,
          search: campaignSearch || undefined,
        })
        .then((campaigns) => {
          setCampaigns([
            {
              label: t('board.leads.totalLeads.filters.all'),
              value: 'all',
            },
            ...campaigns.items.map((campaign) => {
              return {
                label: campaign.name,
                value: campaign.id,
              };
            }),
          ]);
        });
    }
  };

  const fetchFunnels = async () => {
    new FunnelCrmRepository()
      .list({
        direction: 'asc',
        page: 0,
        limit: 100,
        search: funnelSearch || undefined,
      })
      .then((funnels) => {
        setFunnels([
          {
            label: t('board.leads.totalLeads.filters.all'),
            value: 'all',
          },
          ...funnels.items.map((funnel) => {
            return {
              label: funnel.name,
              value: funnel.id,
            };
          }),
        ]);
      });
  };
  /** */

  //INITIAL LOAD
  //Cargar y setear los valores iniciales correspondientes
  useEffect(() => {
    //settear la clinica
    new ClinicAccessRepository().list({}).then((clinics) => {
      setClinics(
        clinics.items.map((clinic) => {
          return {
            label: clinic.name,
            value: clinic.id,
          };
        })
      );
      setSelectedClinic({
        id: clinics.items[0].id,
        name: clinics.items[0].name,
      });
    });

    //settear el embudo
    fetchFunnels();
    //settear la campaña
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (campaignSearch) fetchCampaigns(selectedFunnel.id);
  }, [campaignSearch]);

  useEffect(() => {
    if (funnelSearch) fetchFunnels();
  }, [funnelSearch]);

  //Cargar los datos dependiendo de los filtros seleccionados
  useEffect(() => {
    leadsCostByCampaign();
    leadStateByClinicYearMonthFunnel();
    newLeadCountYear();
  }, [selectedClinic]);

  useEffect(() => {
    listTotalClinicLeadsSuccess();
    leadStateByClinicYearMonthFunnel();
    newLeadCountYear();
  }, [year]);

  useEffect(() => {
    fetchCampaigns(selectedFunnel.id);
    listTotalClinicLeadsSuccess();
    leadStateByClinicYearMonthFunnel();
    newLeadCountYear();
  }, [selectedFunnel]);

  useEffect(() => {
    listTotalClinicLeadsSuccess();
    leadsCostByCampaign();
    leadStateByClinicYearMonthFunnel();
    newLeadCountYear();
  }, [selectedCampaign]);

  useEffect(() => {
    leadStateByClinicYearMonthFunnel();
  }, [month]);

  const handleExport = (properties: { type: 'monthly' | 'byEvent' }) => {
    if (
      !totalLeadsState ||
      !totalLeads ||
      !confirm(t('export.description') ?? 'Exportar datos a CSV')
    )
      return;

    if (properties.type === 'byEvent') {
      const headers = Object.values({
        department_name: t('access.menu.departments'),
        total: t('board.sections.marketing.totalLeadsPerState.total'),
        appointed: t('board.sections.marketing.totalLeadsPerState.appointed'),
        percentage: t('board.sections.marketing.totalLeadsPerState.percentage'),
        actives: t('board.sections.marketing.totalLeadsPerState.actives'),
        inactives: t('board.sections.marketing.totalLeadsPerState.inactives'),
      });
      handleExportTableCSV({
        headers: headers,
        data: totalLeadsState.map((item) => ({
          department_name: item.department_name,
          total_leads: item.total_leads,
          lead_appointed_count: item.lead_appointed_count,
          lead_percentage_appointed: item.lead_percentage_appointed,
          lead_active_count: item.lead_active_count,
          lead_inactive_count: item.lead_inactive_count,
        })),

        type: properties.type,
        fileName: `summary-leads.csv`,
        eventFields: [
          'total_leads',
          'lead_appointed_count',
          'lead_percentage_appointed',
          'lead_active_count',
          'lead_inactive_count',
        ],
      });
    } else {
      const headers = Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`));

      handleExportTableCSV({
        headers: ['Department', ...headers],
        data: totalLeads.map((item) => ({
          department_name: item.department_name,
          year: year,
          month: item.month,
          total: item.total,
        })),
        type: properties.type,
        fileName: `total-leads-${year}.csv`,
      });
    }
  };

  const graphicTitle = (prefix: string) =>
    `${t(`board.marketing.${prefix}`)}/${year}/${t(`access.users.list.month.${month}`).toUpperCase()}/${t(`board.marketing.leadsPerCampaign.filters.${selectedCampaign.id === 'all' ? 'funnel' : 'campaign'}`).toUpperCase()}`;

  const totalData = totalLeadsState.find((data) => data.department_name === 'Total');

  return (
    <SceneComponent icon={'search'} prefix={'board.marketing'} links={[]}>
      <View style={BoardStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.marketing.secondary.title"
          description="board.marketing.secondary.description"
        />
        <View style={BoardStyles.fiterContainer}>
          <View style={{ width: 250 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.clinic')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.clinicSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedClinic?.id ?? ''}
                name="clinic"
                onChange={(value: any) => {
                  setSelectedClinic({
                    id: value.clinic,
                    name: clinics.find((clinic) => clinic.value === value.clinic)?.label ?? '',
                  });
                }}
                hideSearch
                pick={async ({ id }) =>
                  new ClinicAccessRepository()
                    .pick({
                      id: selectedClinic?.id ?? '',
                    })
                    .then((clinic) => {
                      return clinic.name ?? '';
                    })
                }
                repository={async () => {
                  return Promise.resolve(clinics);
                }}
              />
            </View>
          </View>
          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>{t('board.marketing.filters.year')}</Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.marketing.filters.yearSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + year}
                name="year"
                onChange={(value: any) => {
                  setYear(+value.year);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 7;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>

          <View style={{ width: 300 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.funnel')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.funnelSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedFunnel.id ?? ''}
                name="selectedFunnel"
                onChange={(value: any) => {
                  setSelectedFunnel({
                    id: value.selectedFunnel,
                    name:
                      funnels.find((funnel) => funnel.value === value.selectedFunnel)?.label ?? '',
                  });
                }}
                repository={async ({ search }) => {
                  setFunnelSearch(search);
                  return Promise.resolve(funnels);
                }}
              />
            </View>
          </View>

          <View style={{ width: 450 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.campaign')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.campaignSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedCampaign?.id ?? ''}
                name="selectedCampaign"
                onChange={(value: any) => {
                  setSelectedCampaign({
                    id: value.selectedCampaign,
                    name:
                      campaigns.find((campaign) => campaign.value === value.selectedCampaign)
                        ?.label ?? '',
                  });
                }}
                repository={async ({ search }) => {
                  setCampaignSearch(search);
                  return Promise.resolve(campaigns);
                }}
              />
            </View>
          </View>
        </View>
        <View style={BoardStyles.graphicsContainer}>
          <View style={[BoardStyles.graphicContainer, { flex: 7 }]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>{t('board.leads.totalLeads.totalLeads')}</Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport({ type: 'monthly' })}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            <View style={{ minHeight: height * 0.2 }}>
              {selectedClinic && year && !loadingTotalLeads ? (
                <TotalLeadsByFunnelTable year={year} data={totalLeads ?? []} />
              ) : (
                <LoaderComponent loading />
              )}
            </View>
          </View>
        </View>
        <View style={[BoardStyles.fiterContainer, { marginTop: 30 }]}>
          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.month')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.monthSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={month.toString()}
                name="month"
                onChange={(value: any) => {
                  setMonth(parseInt(value.month));
                }}
                options={Array.from({ length: 12 }, (_, i) => {
                  return {
                    label: t(`access.users.list.month.${i}`),
                    value: i.toString(),
                  };
                })}
                hideSearch
              />
            </View>
          </View>
        </View>
        <View style={BoardStyles.graphicsContainer}>
          <View style={[BoardStyles.graphicContainer, { flex: 2, gap: 0 }]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>{t('board.leads.totalLeads.summary')}</Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport({ type: 'byEvent' })}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            <View style={{ minHeight: height * 0.2 }}>
              {loadingTotalLeadsState ? (
                <LoaderComponent loading />
              ) : (
                <TotalLeadsStateDepartmentsTable data={totalLeadsState ?? []} />
              )}
            </View>
          </View>

          <View style={{ flex: 2, flexDirection: 'row', gap: 10, minHeight: 500 }}>
            {loadingTotalLeadsState ? (
              <LoaderComponent loading />
            ) : (
              totalData && <AppointedLeadsChartComponent data={totalData} />
            )}
            {loadingTotalLeadsCost ? (
              <LoaderComponent loading />
            ) : (
              <View style={{ flex: 1, flexDirection: 'column', gap: 10 }}>
                <CampaignLeadCostDetail
                  clinic={selectedClinic?.name || ''}
                  campaign={selectedCampaign?.name || ''}
                  budget={totalLeadsCost?.campaign_budget.toString() || '0.0'}
                />
                <CostPerLeadComponent cost={totalLeadsCost?.cost_total_lead || 0.0} />
              </View>
            )}
          </View>
        </View>

        <View style={[BoardStyles.graphicsContainer, { marginVertical: 20 }]}>
          <View style={[BoardStyles.graphicContainer, { flex: 4, gap: 15 }]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>{graphicTitle('leadsState')}</Text>
            </View>
            {!loadingClinicLeadsSuccess && totalClinicLeadsSuccess ? (
              <GeneralTableComponent data={totalClinicLeadsSuccess} />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
