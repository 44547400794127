import { NavigationComponent } from "@components/navigation/navigation.component";
import { LoginScreen } from "./login/login.screen";
import { UnauthorizedRoutes } from "./unauthorized.routes";


const Navigation = NavigationComponent<UnauthorizedRoutes>();

export function UnauthorizedSpace() {
    return (
        <Navigation.Navigator>
            <Navigation.Screen name="login" component={LoginScreen} />
        </Navigation.Navigator>
    );
}
