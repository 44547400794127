import { View, TextInput as TextInputNative } from 'react-native';
import { TextInputImplementation } from './text.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useEffect, useState } from 'react';
import { TextInputValidations } from './text.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { TextInputStyles } from './text.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { parseCodeErrors } from './text.utilities';
import { useDeviceHook } from '@hooks/device/device.hook';

export function TextInput<ITEM>(properties: TextInputImplementation<ITEM>) {
  const value = properties.value[properties.name] as string;
  const [focus, setFocus] = useState(false);
  const [backendErrors, setBackendErrors] = useState<any>();
  const onChange = (text: string) => {
    const auxText = text === '' ? undefined : text;
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: auxText,
    };
    properties.onChange(newValue);
  };

  const { phone } = useDeviceHook();

  const errors =
    properties.validations &&
    TextInputValidations({
      validations: properties.validations,
      value: value,
      required: properties.required,
    });

  useEffect(() => {
    if (properties.errors?.errors && errors)
      setBackendErrors(parseCodeErrors(properties.errors.errors, errors));
  }, [properties.errors]);

  return (
    <>
      <View
        style={properties.readonly ? TextInputStyles.containerReadonly : TextInputStyles.container}
      >
        {properties.icon && (
          <IconComponent
            name={properties.icon}
            containerstyle={
              properties.readonly
                ? TextInputStyles.iconContainerReadonly
                : TextInputStyles.iconContainer
            }
            iconStyle={
              properties.readonly ? TextInputStyles.iconStyleReadonly : TextInputStyles.iconStyle
            }
          />
        )}
        <TextInputNative
          numberOfLines={properties.lines}
          multiline={properties.lines ? true : false}
          editable={!properties.readonly}
          focusable={!properties.readonly}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          autoComplete="new-password"
          secureTextEntry={properties.secure}
          value={value || ''}
          style={[
            properties.readonly ? TextInputStyles.inputReadonly : TextInputStyles.input,
            properties.icon ? TextInputStyles.inputIcon : TextInputStyles.inputNoIcon,
            focus && !properties.readonly
              ? TextInputStyles.inputFocus
              : TextInputStyles.inputUnfocus,
            properties.lines
              ? TextInputStyles.inputMultiline
              : !phone
                ? TextInputStyles.inputSingleline
                : {},
          ]}
          onChangeText={(text: string) => {
            if (properties.readonly) return;
            onChange(text);
          }}
          autoCapitalize={properties.capitalize}
        />
      </View>
      {errors && <ErrorsComponent prefix={'form.text.errors'} errors={errors} />}
      {backendErrors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={backendErrors}
        />
      )}
    </>
  );
}
