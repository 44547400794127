import { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { FieldsComponent } from './components/fields/fields.component';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { FormStyles } from './form.styles';
import { FormProperties } from './form.properties';
import { ErrorFormType } from './form.types';
import { LoaderComponent } from '@components/loader/loader.component';
import { ErrorsComponent } from './components/fields/inputs/errors/errors.component';
import { isObject, isString } from '@validator/index';

/**
 * FormComponent renders a form with accompanying fields.
 * The component is interactive, allowing the user to input data.
 *
 * @param {FormProperties} properties - The properties object containing the prefix, fields, and onSuccess function of the form.
 *
 * @returns {JSX.Element} The rendered JSX element containing the form and fields.
 *
 * @example
 * <FormComponent
 *   prefix="login"
 *   fields={[
 *     { name: 'email', type: 'email' },
 *     { name: 'password', type: 'password' },
 *   ]}
 *   onSuccess={() => console.log('Form submitted')}
 * >
 *   <TextComponent text="Login" bold style={FormStyles.title} />
 * </FormComponent>
 *
 */

export function FormComponent<ITEM>(properties: FormProperties<ITEM>) {
  const [data, setData] = useState<Partial<ITEM>>({});
  const [autoValidation, setAutoValidation] = useState<boolean>(true);
  const [generalError, setGeneralError] = useState<string>();
  const [errors, setErrors] = useState<ErrorFormType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    if (properties.repository?.get) {
      setLoading(true);
      properties.repository.get({ item: data }).then((newData) => {
        if (newData) {
          setData(newData);
          properties.onChange?.({ item: newData, isLocal: false });
        }
        setLoading(false);
      });
    }
  }, []);

  const onSubmit = async () => {
    if (submitLoading) return;

    if (autoValidation) {
      setGeneralError(undefined);
      try {
        setSubmitLoading(true);
        await properties.repository?.send?.({ item: data });
      } catch (error) {
        if (error instanceof Error) {
          if ('message' in error) {
            const errors = JSON.parse(error.message);
            if (isString(errors) && errors.includes('No user permissions')) {
              setGeneralError('WRONG_PERMISSIONS');
            }
            if (errors) {
              setErrors(errors);
            }
          }
        } else {
          const msg = isObject(error) && 'message' in error ? error.message + '' : error + '';
          setGeneralError(msg.replace(/"/g, ''));
        }
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const renderSubmit = () => {
    if (properties.readonly) return null;
    return (
      <TouchableOpacity
        onPress={() => {
          if (properties.onNext) {
            properties.onNext();
            return;
          }
          onSubmit();
        }}
        style={FormStyles.submitButton}
      >
        {submitLoading ? (
          <LoaderComponent loading={submitLoading} style={{ backgroundColor: 'white' }} />
        ) : (
          <>
            <TextComponent
              translate
              text={`${properties.prefix}.submit`}
              bold
              style={FormStyles.submitText}
              multiWorkspace={properties.multiworkspace}
            />
            <IconComponent name={'arrow-right'} iconStyle={FormStyles.submitIcon} />
          </>
        )}
      </TouchableOpacity>
    );
  };

  if (loading) {
    return <LoaderComponent loading={loading} />;
  }

  return (
    <ScrollView
      style={FormStyles.container}
      contentContainerStyle={properties.padding && FormStyles.widhtPadding}
    >
      <View>
        {properties.children}
        <FieldsComponent<ITEM>
          errors={errors}
          prefix={properties.prefix}
          fields={properties.fields}
          readonly={properties.readonly}
          values={data}
          onChange={(newData) => {
            if (properties.readonly) return;
            setData({ ...data, ...newData });
            properties.onChange?.({ item: { ...data, ...newData }, isLocal: true });
          }}
        />
        {generalError && <ErrorsComponent prefix={`server.errors`} errors={[generalError]} />}
        {properties.submit !== false && renderSubmit()}
      </View>
    </ScrollView>
  );
}
