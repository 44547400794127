import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CommunicationChannelsRoutes } from '../../communicationChannels.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CommunicationChannelAccessRepository } from '@human/access/repositories/communicationChannel/communicationChannel.repository';
import { CommunicationChannelAccessType } from '@human/access/types/communicationChannel.access.type';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateCommunicationChannelsScreen(properties: {
  navigation: NavigationProp<CommunicationChannelsRoutes>;
  route: RouteProp<CommunicationChannelsRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon="envelope" prefix="access.communicationChannels.update">
      <FormComponent<CommunicationChannelAccessType>
        padding
        readonly={!hasPermissionUtilty({ name: 'access.commsChannels.editor' })}
        prefix={'access.communicationChannels.update.form'}
        repository={{
          send: (settings: { item: Partial<CommunicationChannelAccessType> }) => {
            return new CommunicationChannelAccessRepository()
              .update({
                id: properties.route.params.id,
                name: settings.item.name ?? '',
              })
              .then(properties.navigation.goBack);
          },
          get: () => {
            return new CommunicationChannelAccessRepository().pick({
              id: properties.route.params.id,
            });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['name'],
          },
        ]}
      />
    </ModalComponent>
  );
}
