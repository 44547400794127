import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ScheduleAccessType } from '@human/access/types/schedule.access.type';

export class ScheduleAccessRepository extends AuthorizedRepository {
  async list(properties: { userId: string; search?: string }): Promise<{
    items: ScheduleAccessType[];
    total: number;
  }> {
    return this.fetch('schedule/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((schedule: ScheduleAccessType) => ({
        id: schedule.id,
        timetableId: schedule.timetableId,
        timetableName: schedule.timetableName,
        timetableDay: schedule.timetableDay,
        timetableStarted: schedule.timetableStarted,
        timetableEnded: schedule.timetableEnded,
      })),
      total: data.total,
    }));
  }

  async create(properties: Partial<{ userId: string; timetableId: string }>) {
    return this.fetch('schedule/create', {
      method: 'POST',
      body: properties,
    });
  }

  async delete(properties: { scheduleId: string; userId: string }) {
    return this.fetch('schedule/delete', {
      method: 'DELETE',
      params: properties,
    }).then(() => true);
  }
}
