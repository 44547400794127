import { SessionType } from '../type/session.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class SessionRepository extends AuthorizedRepository {
  async find(): Promise<{
    items: SessionType[];
    total: number;
  }> {
    return this.fetch('session/find', {
      method: 'GET',
    }).then((data) => {
      return {
        items: data.items.map((item: any) => {
          return {
            id: item.id,
            expires: new Date(item.expires),
            created: new Date(item.created),
          };
        }),
        total: data.total,
      };
    });
  }
  async delete(properties: { sessionId: string }): Promise<void> {
    return this.fetch('session/delete', {
      method: 'PUT',
      params: properties,
    });
  }
}
