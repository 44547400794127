import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PurchaseCrmRepository } from '@human/crm/repositories/purchase/purchase.repository';
import { PurchaseCrmType } from '@human/crm/types/purchase.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PurchasesRoutes } from '../../purchases.routes';

export function EnablePurchaseScreen(properties: {
  navigation: NavigationProp<PurchasesRoutes>;
  route: RouteProp<PurchasesRoutes, 'enablePurchase'>;
}) {
  return (
    <ModalComponent icon="money-bill" flex={false} prefix="crm.purchases.enable">
      <FormComponent<PurchaseCrmType>
        key={'purchase'}
        padding
        prefix="crm.purchases.enable.form"
        repository={{
          send: (data) => {
            return new PurchaseCrmRepository()
              .enable({
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                purchaseId: properties.route.params.purchaseId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
