import { View } from 'react-native';
import { TextComponent } from '../../../../../text/text.component';
import { ClinicColumnImplementation } from './clinic.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { ClinicColumnStyles } from './clinic.column.styles';
import { IconComponent } from '@components/icon/icon.component';

export function ClinicColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: ClinicColumnImplementation<ITEM, ORDER>
) {
  const text = properties.onRender?.(properties.item) ?? properties.name;
  const color = properties.color?.(properties.item);
  const background = properties.background?.(properties.item);
  return (
    <View
      style={[
        ClinicColumnStyles.container,
        {
          backgroundColor: background,
        },
      ]}
    >
      <View style={[ClinicColumnStyles.bubble]}>
        <TextComponent lines={1} bold text={`OVO`} style={[ClinicColumnStyles.icon]} />
        <TextComponent
          lines={1}
          bold
          text={`${text}`}
          style={[
            ClinicColumnStyles.text,
            {
              color: color ?? ClinicColumnStyles.text.color,
            },
          ]}
        />
      </View>
    </View>
  );
}
