export const EsLocale = {
  logotype: {
    horse: 'Ovohorse',
    human: 'Ovoclinic',
    ovobank: 'Ovobank',
    ovoclinic: 'Ovoclinic',
  },
  calendar: {
    hour: 'Hora',
    minutes: ':',
    save: 'Guardar',
    placeholder: 'Selecciona una fecha',
  },
  historical: {
    deleted: 'Eliminado',
    created: 'Created',
    updated: 'Actualizado',
  },
  chat: {
    placeholder: 'Escribe un mensaje',
    title: 'Chat sobre el contacto',
  },
  slicer: {
    confirm: 'Seleccionar',
  },
  export: {
    title: 'Exportar',
    description: 'Exportar los datos de la tabla a un archivo CSV',
  },
  import: {
    title: 'Importar',
  },
  transfer: {
    title: 'Transferir',
  },
  createTaskMassively: {
    title: 'Crear tareas',
  },
  leadEvents: {
    informed: 'V.I comercial realizada',
    contacted: 'Contactados',
    attended: 'V.I Médica realizada',
    appointed: 'Citada V.I comercial',
    medicalAppointed: 'Citada V.I Médica',
    medicalEvaluated: 'Evaluacion médica',
    comercialAttended: 'Presupuesto entregado',
    converted: 'Convertidos',
  },
  form: {
    tabChange: {
      title: 'Estas editando un formulario. ¿Estás seguro de que deseas cambiar de pestaña?',
    },
    update: {
      successToast: 'Formulario actualizado correctamente',
    },
    checkbox: {
      errors: {
        required: 'Debes aceptar para continuar',
      },
    },
    selectable: {
      placeholder: {
        title: 'Selecciona una opción',
        empty: 'No hay resultados',
      },
    },
    date: {
      placeholder: 'Filtrar por fecha',
    },
    json: {
      errors: {
        geojson: 'GeoJSON formato no válido',
      },
    },
    text: {
      errors: {
        notString: 'El valor debe ser una cadena de texto',
        ipIsRequired: 'La dirección IP es requerida',
        ipMustBeAString: 'La dirección IP debe ser una cadena de texto',
        ipMustBeAValidIpAddress: 'La dirección IP debe ser válida',
        countryNameEn: 'El nombre es requerido',
        countryNameEs: 'El nombre es requerido',
        nameIsRequired: 'El nombre es requerido',
        nameMustBeAString: 'El nombre debe ser una cadena de texto',
        nameMustNotContainNumbers: 'El nombre no debe contener números',
        nameMustNotContainSpecialCharacters: 'El nombre no debe contener caracteres especiales',
        nameMustBeAtMost50CharactersLong: 'El nombre debe tener como máximo 50 caracteres',
        dniIsRequired: 'El dni es requerido',
        dniMustBeAtLeast8CharactersLong: 'El dni debe tener al menos 8 caracteres',
        dniMustBeAtMost20CharactersLong: 'El dni debe tener como máximo 20 caracteres',
        surnameIsRequired: 'El apellido es requerido',
        surnameMustBeAString: 'El apellido debe ser una cadena de texto',
        surnameMustNotContainNumbers: 'El apellido no debe contener números',
        surnameMustNotContainSpecialCharacters:
          'El apellido no debe contener caracteres especiales',
        surnameMustBeAtMost50CharactersLong: 'El apellido debe tener como máximo 50 caracteres',
        emailIsRequired: 'El correo electrónico es requerido',
        emailMustBeAString: 'El correo electrónico debe ser una cadena de texto',
        emailMustBeAValidEmail: 'El correo electrónico debe ser válido',
        emailMustBeAtLeast3CharactersLong: 'El correo electrónico debe tener al menos 3 caracteres',
        emailMustBeAtMost50CharactersLong:
          'El correo electrónico debe tener como máximo 30 caracteres',
        emailMustContainAnAtSymbol: 'El correo electrónico debe contener un símbolo @',
        emailMustNotContainWhitespace: 'El correo electrónico no debe contener espacios en blanco',
        passwordIsRequired: 'La contraseña es requerida',
        passwordMustBeAString: 'La contraseña debe ser una cadena de texto',
        passwordMustBeAtLeast8CharactersLong: 'La contraseña debe tener al menos 8 caracteres',
        passwordMustBeAtMost30CharactersLong: 'La contraseña debe tener como máximo 30 caracteres',
        passwordMustContainAnUppercaseLetter: 'La contraseña debe contener una letra mayúscula',
        passwordMustContainALowercaseLetter: 'La contraseña debe contener una letra minúscula',
        passwordMustContainANumber: 'La contraseña debe contener un número',
        passwordMustContainASpecialCharacter: 'La contraseña debe contener un carácter especial',
        passwordMustNotContainWhitespace: 'La contraseña no debe contener espacios en blanco',
        passwordsDoNotMatch: 'Las contraseñas no coinciden',
        valueMustBeAnInteger: 'El valor debe ser un número entero',
        valueMustBeAValidNumber: 'El valor debe ser un número válido',
        valueMustBePositive: 'El valor debe ser positivo',
        TEXT_TOO_SHORT: 'El texto es demasiado corto',
        WRONG_EMAIL: 'El formato del correo electrónico es incorrecto',
        REQUIRED: 'Este campo es obligatorio',
        titleMustBeAString: 'El título debe ser una cadena de texto',
        titleIsRequired: 'El título es requerido',
        titleMustBeAtLeast3CharactersLong: 'El título debe tener al menos 3 caracteres',
        titleMustBeAtMost100CharactersLong: 'El título debe tener como máximo 100 caracteres',
        codeMustBeAString: 'El código debe ser una cadena de texto',
        codeIsRequired: 'El código es requerido',
        codeMustBeAtLeast3CharactersLong: 'El código debe tener al menos 3 caracteres',
        codeMustBeAtLeast10CharactersLong: 'El código debe tener al menos 10 caracteres',
        codeMustBeAtMost30CharactersLong: 'El código debe tener como máximo 30 caracteres',
        prefixIsRequired: 'El prefijo es requerido',
        prefixInvalidLength: 'El prefijo debe tener 2 o 3 digitos',
        prefixMustContainOnlyNumbers: 'El prefijo debe contener solo números',
        prefixFirstCharacterMustBePlusSign: 'El prefijo debe comenzar con el signo +',
        prefixMustBeAtLeast1CharactersLong: 'El prefijo de tener minimo 1 dígito',
        prefixMustBeAtMost3CharactersLong: 'El prefijo de tener maximo 3 dígitos',
        countryCodeMustBeAString: 'El código de país debe ser una cadena de texto',
        countryCodeIsRequired: 'El código de país es requerido',
        countryCodeMustBe2CharactersLong: 'El código de país debe tener 2 caracteres',
        countryCodeMustBeUppercase: 'El código de país debe ser en mayúsculas',
        phoneIsRequired: 'El teléfono es requerido',
        phoneMustBeAString: 'El teléfono debe ser una cadena de texto',
        phoneMustContainOnlyNumbers: 'El teléfono debe contener solo números',
        phoneMustNotContainWhitespace: 'El teléfono no debe contener espacios en blanco',
        phoneMustBeAtLeast9CharactersLong: 'El teléfono debe tener al menos 9 digitos',
        phoneMustBeAtMost12CharactersLong: 'El teléfono debe tener como máximo 12 digitos',
        extensionIsRequired: 'La extensión es requerida',
        extensionMustContainOnlyNumbers: 'La extensión debe contener solo números',
        extensionMustBeAtLeast4CharactersLong: 'La extensión debe tener al menos 4 digitos',
        descriptionIsRequired: 'La descripción es requerida',
        descriptionMustBeAString: 'La descripción debe ser una cadena de texto',
        descriptionMustBeAtMost500CharactersLong:
          'La descripción debe tener como máximo 500 caracteres',
        observationIsRequired: 'La observación es requerida',
        observationMustBeAString: 'La observación debe ser una cadena de texto',
        observationMustBeAtLeast1CharactersLong: 'La observación debe tener al menos 1 caracter',
        observationMustBeAtMost150CharactersLong:
          'La observación debe tener como máximo 150 caracteres',
        observationMustBeAtMost500CharactersLong:
          'La observación debe tener como máximo 500 caracteres',
        usernameIsRequired: 'El nombre de usuario es requerido',
        usernameMustBeAString: 'El nombre de usuario debe ser una cadena de texto',
        usernameMustBeAtLeast3CharactersLong:
          'El nombre de usuario debe tener al menos 3 caracteres',
        usernameMustBeAtMost30CharactersLong:
          'El nombre de usuario debe tener como máximo 30 caracteres',
        usernameMustNotContainSpecialCharacters:
          'El nombre de usuario no debe contener caracteres especiales',
        usernameMustNotContainWhitespace:
          'El nombre de usuario no debe contener espacios en blanco',
        zipIsRequired: 'El código postal es requerido',
        zipMustBeAString: 'El código postal debe ser una cadena de texto',
        zipMustBe5CharactersLong: 'El código postal debe tener 5 caracteres',
        zipMustContainOnlyNumbers: 'El código postal debe contener solo números',
        zipMustNotContainWhitespace: 'El código postal no debe contener espacios en blanco',
        zipMustNotContainSpecialCharacters:
          'El código postal no debe contener caracteres especiales',
        streetIsRequired: 'La calle es requerida',
        streetMustBeAString: 'La calle debe ser una cadena de texto',
        streetMustBeAtLeast8CharactersLong: 'La calle debe tener al menos 8 caracteres',
        cityIsRequired: 'La ciudad es requerida',
        cityMustBeAString: 'La ciudad debe ser una cadena de texto',
        stateIsRequired: 'El estado es requerido',
        stateMustBeAString: 'El estado debe ser una cadena de texto',
        coinIsRequired: 'La moneda es requerida',
        coinMustBeAString: 'La moneda debe ser una cadena de texto',
        flagIsRequired: 'La bandera es requerida',
        flagMustBeAString: 'La bandera debe ser una cadena de texto',
        subjectIsRequired: 'El asunto es requerido',
        subjectMustBeAString: 'El asunto debe ser una cadena de texto',
        subjectMustBeAtLeast3CharactersLong: 'El asunto debe tener al menos 3 caracteres',
        subjectMustBeAtMost30CharactersLong: 'El asunto debe tener como máximo 30 caracteres',
        messageIsRequired: 'El mensaje es requerido',
        messageMustBeAString: 'El mensaje debe ser una cadena de texto',
        messageMustBeAtLeast3CharactersLong: 'El mensaje debe tener al menos 3 caracteres',
        messageMustBeAtMost500CharactersLong: 'El mensaje debe tener como máximo 500 caracteres',
        hourIsRequired: 'La hora es requerida',
        hourMustBeValidFormat: 'La hora debe tener un formato válido: HH:MM',
        documentIsRequired: 'El documento es requerido',
        documentMustBeAString: 'El documento debe ser una cadena de texto',
        documentMustBeAtLeast8CharactersLong: 'El documento debe tener al menos 8 caracteres',
        codeMustBeAtLeast2CharactersLong: 'El código debe tener al menos 2 caracteres',
        required: 'Este campo es obligatorio',
        name: {
          notString: 'El nombre debe ser una cadena de texto',
          tooShort: 'El nombre es demasiado corto',
          tooLong: 'El nombre es demasiado largo',
          invalidCharacters: 'El nombre contiene caracteres no válidos',
        },
        email: {
          notValid: 'El correo electrónico no es válido',
          notString: 'El correo electrónico debe ser una cadena de texto',
        },
        phone: {
          invalid: 'El teléfono no es válido',
          notString: 'El teléfono debe ser una cadena de texto',
        },
        surname: {
          notString: 'El apellido debe ser una cadena de texto',
          tooShort: 'El apellido es demasiado corto',
          tooLong: 'El apellido es demasiado largo',
          invalidCharacters: 'El apellido contiene caracteres no válidos',
        },
        boolean: {
          notBoolean: 'El valor debe ser un booleano',
          truthy: {
            notTruthy: 'El valor debe ser verdadero',
          },
        },
        password: {
          notString: 'La contraseña debe ser una cadena de texto',
          tooShort: 'La contraseña es demasiado corta',
          tooLong: 'La contraseña es demasiado larga',
          invalidCharacters: 'La contraseña contiene caracteres no válidos',
          noUpperCase: 'La contraseña debe contener al menos una letra mayúscula',
          noLowerCase: 'La contraseña debe contener al menos una letra minúscula',
          noNumber: 'La contraseña debe contener al menos un número',
          noSpecialCharacter: 'La contraseña debe contener al menos un carácter especial',
        },
        number: {
          notNumber: 'El valor debe ser un número',
        },
        positive: {
          notPositive: 'El valor debe ser positivo',
          notNumber: 'El valor debe ser un número',
        },
        price: {
          notNumber: 'El valor debe ser un número',
          notPositive: 'El valor debe ser positivo',
          notString: 'El valor debe ser una cadena de texto',
        },
        negative: {
          notNegative: 'El valor debe ser negativo',
          notNumber: 'El valor debe ser un número',
        },
        uuid: {
          notUuid: 'El valor debe ser un UUID',
          notString: 'El valor debe ser una cadena de texto',
          empty: 'El valor no puede estar vacío',
        },
        object: {
          notValid: 'El valor debe ser un objeto',
        },
        description: {
          notString: 'La descripción debe ser una cadena de texto',
          tooShort: 'La descripción es demasiado corta',
          tooLong: 'La descripción es demasiado larga',
        },
        observation: {
          notString: 'La observación debe ser una cadena de texto',
          tooShort: 'La observación es demasiado corta',
          tooLong: 'La observación es demasiado larga',
        },
        date: {
          notValid: 'La fecha no es válida',
          notString: 'La fecha debe ser una cadena de texto',
        },
        color: {
          notString: 'El color debe ser una cadena de texto',
          notHex: 'El color debe ser un código hexadecimal',
        },
        code: {
          notString: 'El código debe ser una cadena de texto',
          tooShort: 'El código es demasiado corto',
          tooLong: 'El código es demasiado largo',
        },
        image: {
          notString: 'La imagen debe ser una cadena de texto',
          notBase64: 'La imagen debe ser un código base64',
        },
        phonePrefix: {
          notString: 'El prefijo debe ser una cadena de texto',
          notValid: 'El prefijo no es válido',
        },
        countryCode: {
          notString: 'El código de país debe ser una cadena de texto',
          notValid: 'El código de país no es válido',
        },
        zip: {
          notString: 'El código postal debe ser una cadena de texto',
          notLength: 'El código postal debe tener 5 caracteres',
          notNumber: 'El código postal debe contener solo números',
          hasSpace: 'El código postal no debe contener espacios en blanco',
          hasLetter: 'El código postal no debe contener letras',
          hasSymbol: 'El código postal no debe contener símbolos',
        },
        title: {
          notString: 'El título debe ser una cadena de texto',
          tooShort: 'El título es demasiado corto',
          tooLong: 'El título es demasiado largo',
        },
        username: {
          notString: 'El nombre de usuario debe ser una cadena de texto',
          tooShort: 'El nombre de usuario es demasiado corto',
          tooLong: 'El nombre de usuario es demasiado largo',
          invalidCharacters: 'El nombre de usuario contiene caracteres no válidos',
        },
        ip: {
          notString: 'La dirección IP debe ser una cadena de texto',
          notValid: 'La dirección IP no es válida',
        },
        state: {
          notString: 'El estado debe ser una cadena de texto',
        },
        city: {
          notString: 'La ciudad debe ser una cadena de texto',
        },
        street: {
          notString: 'La calle debe ser una cadena de texto',
        },
        coin: {
          notString: 'La moneda debe ser una cadena de texto',
        },
        flag: {
          notString: 'La bandera debe ser una cadena de texto',
        },
      },
    },
  },
  analytics: {
    title: 'Analíticas',
    filter: 'Comparativa entre la semana pasada y esta semana',
    activities: {
      title: 'Tareas',
    },
    leads: {
      title: 'Leads',
    },
    results: {
      title: 'Resultados',
    },
  },
  history: {
    title: 'Historial',
  },
  performance: {
    title: 'Rendimiento',
  },
  sessions: {
    title: 'Sesiones Activas',
  },
  security: {
    title: 'Sistema Securizado',
  },
  launch: {
    title: 'El futuro de la reproducción asistida',
    subtitle: 'Trazabilidad y seguridad para tu clínica',
    description: {
      ovoclinic:
        'Software securizado y propiedad de Ovoclinic para la gestión de clínicas de reproducción asistida. OVO DESK es una plataforma de acceso restringido y todo acceso fraudulento será notificado a las autorizades de delitos informáticos de la Unión Europea.',
      ovobank:
        'Software securizado y propiedad de Ovobank para la gestión de bancos de óvulos. OVO DESK es una plataforma de acceso restringido y todo acceso fraudulento será notificado a las autorizades de delitos informáticos de la Unión Europea.',
    },
    crm: {
      description:
        'Espacio para la gestión de clientes y la mejora de la relación con ellos. Trazabilidad y seguimiento de las interacciones con los leads.',
    },
    clinic: {
      description:
        'Todo lo que necesitas para gestionar tu clínica, hospital o centro médico. Historias clínicas, turnos, facturación y mucho más.',
    },
    access: {
      description:
        'Control de acceso y gestión de usuarios. Permisos y roles para la administración de la información. Seguridad y confiabilidad.',
    },
    board: {
      description:
        'Tablero de control y monitoreo de indicadores. Visualización de datos y análisis de la información para la toma de decisiones.',
    },
    tory: {
      description:
        'Resultados clínicos y trazabilidad de las pruebas de laboratorio. Anonimización de datos y tokenización de la información.',
    },
    profile: {
      description:
        'Gestión de la información personal y de la cuenta. Configuración de la cuenta y preferencias de usuario.',
    },
  },
  access: {
    menu: {
      users: 'Usuarios',
      roles: 'Roles',
      specialists: 'Especialistas',
      clinics: 'Clínicas',
      planning: 'Planificación',
      timetables: 'Horarios',
      bulletins: 'Noticias',
      departments: 'Departamentos',
      languages: 'Idiomas',
      countries: 'Países',
      settings: 'Configuración',
      directory: 'Directorio',
      locations: 'Ubicaciones',
      globalization: 'Globalización',
      communications: 'Comunicaciones',
      communicationChannels: 'Vias de comunicación',
      ovopoints: 'Ovopoints',
      landbot: 'Landbot',
      templates: 'Plantillas',
    },
    users: {
      list: {
        chart: {
          user: 'Usuarios',
          movement: 'Movimientos',
        },
        month: {
          0: 'Enero',
          1: 'Febrero',
          2: 'Marzo',
          3: 'Abril',
          4: 'Mayo',
          5: 'Junio',
          6: 'Julio',
          7: 'Agosto',
          8: 'Septiembre',
          9: 'Octubre',
          10: 'Noviembre',
          11: 'Diciembre',
          12: 'Total',
        },
        year: {
          0: '2020',
          1: '2021',
          2: '2022',
          3: '2023',
          4: '2024',
          5: '2025',
          6: '2026',
          7: '2027',
          8: '2028',
          9: '2029',
          10: '2030',
          11: '2031',
        },
        columns: {
          name: 'Nombre',
          email: 'Correo electrónico',
          phone: 'Teléfono',
          status: 'Estado',
          createdAt: 'Creado',
          updatedAt: 'Actualizado',
          expiration: 'Caducidad',
          created: 'Creación',
          lastMove: 'Último movimiento',
        },
        title: 'Usuarios',
        description: 'Gestión del personal y su acceso al sistema',
        create: 'Usuario',
        one: 'Usuario',
        many: 'Usuarios',
        cloneConfirm: '¿Estás seguro de que deseas clonar este usuario?',
        cloneSuccess: 'Usuario clonado correctamente',
      },
      create: {
        title: 'Nuevo usuario',
        subtitle: 'Registro de un nuevo usuario en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del usuario',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del usuario',
          },
          password: {
            title: 'Contraseña',
            description:
              'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.',
          },
          username: {
            title: 'Username',
            description: 'Nombre de usuario para el acceso al sistema',
          },
          email: {
            title: 'Correo electrónico',
            description:
              'Dirección de correo electrónico donde el usuario recibirá las notificaciones del sistema',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto del usuario',
          },
          extension: {
            title: 'Extensión',
            description: 'Extensión identificativa dentro del departamento',
          },
          signature: {
            title: 'Contraseña',
            description: 'Contraseña del usuario',
          },
          expiration: {
            title: 'Fecha de expiración',
            description: 'Fecha en la que la cuenta del usuario expirará',
          },
          color: {
            title: 'Color',
            description: 'Color del usuario en el sistema',
          },
          icon: {
            title: 'Icono',
            label: 'Seleccionar icono',
            description: 'Icono identificativo del usuario en el sistema',
            search: {
              placeholder: 'Buscar icono',
            },
          },
          status: {
            title: 'Acceso al sistema',
            description: 'Habilitar o deshabilitar el acceso al sistema para este usuario',
            label: 'Permitir acceso al sistema',
          },
          movementLimit: {
            title: 'Límite de movimientos',
            description: 'Número de peticiones permitidas para este usuario',
          },
          attemptLimit: {
            title: 'Límite de intentos',
            description: 'Número de intentos permitidos para iniciar sesion',
          },
          submit: 'Crear usuario',
        },
      },
      delete: {
        title: 'Deshabilitar usuario',
        subtitle: 'Esta acción es reversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este usuario?',
            description:
              'Deshabilitar un usuario significa que no podrá acceder al sistema. Estará visible como deshabilitado en el tablón de usuarios.',
            label: 'Sí, deseo descativar este usuario',
          },
          submit: 'Deshabilitar',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar el usuario, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar usuario',
        subtitle: 'Esta acción permite que el usuario acceda al sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este usuario?',
            description:
              'Habilitar un usuario significa que podrá acceder al sistema. Estará visible como activo en el tablón de usuarios.',
            label: 'Sí, deseo habilitar este usuario',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar usuario',
        subtitle: 'Esta acción hace que el usuario no pueda acceder al sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description:
              'Deshabilitar un usuario significa que no podrá acceder al sistema. Estará visible como deshabilitado en el tablón de usuarios.',
            label: 'Sí, deseo deshabilitar este usuario',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        access: 'Acceso',
        account: {
          tab: 'Cuenta',
          description: 'Información de la cuenta del usuario',
        },
        tabs: {
          account: {
            title: 'Cuenta',
            description: 'Detalles personales de la cuenta del usuario con acceso al sistema.',
          },
          licenses: {
            title: 'Licencias',
            description:
              'Una licencia determina las acciones que un usuario puede realizar y los accesos que tiene en el sistema. Los roles específicos dentro de cada licencia definen estas capacidades. Los usuarios pueden tener una o varias licencias según sus necesidades y responsabilidades.',
          },
          contracts: {
            title: 'Contratos',
            description:
              'Un contrato es un acuerdo entre el usuario y la clínica. Los contratos permiten que el usuario tenga acceso a una o a múltiples clínicas.',
          },
          movements: {
            title: 'Tráfico',
            description:
              'El sistema monitoriza los movimientos de los usuarios. Cada movimiento es una petición que realiza el usuario al sistema.',
          },
          attempts: {
            title: 'Intentos',
            description:
              'Para salvaguardar la seguridad del sistema, se monitorizan los intentos de acceso al sistema.',
          },
          languages: {
            title: 'Idiomas',
            description: 'Idiomas que habla el usuario',
          },
          timetables: {
            title: 'Horarios',
            description:
              'Los horarios son los intervalos de tiempo en los que el usuario tiene acceso al sistema.',
          },
          changePassword: {
            title: 'Contraseña',
            description: 'Cambiar la contraseña del usuario',
          },
          activity: {
            title: 'Actividad',
            description:
              'Si se define una actividad, cuando el responsable del Lead es asignado a este usuario, se creará una tarea automáticamente con la actividad que se haya definido.',
          },
          integrations: {
            title: 'Integraciones',
            description:
              'Configuración a nivel de usuario para poder llevar a cabo las diferentes integraciones implementadas en el CRM. Por ejemplo, LandBot.',
          },
        },
        schedules: {
          create: {
            title: 'Añadir horario',
            subtitle: 'Añadir horario al usuario',
            form: {
              timetableId: {
                title: 'Selecciona un horario',
                description: 'Plantilla de horario en el que el usuario tiene acceso al sistema',
              },
              submit: 'Añadir horario',
            },
          },
          delete: {
            title: 'Desvincular horario',
            subtitle: 'Desvincular horario del usuario',
            form: {
              status: {
                title: '¿Estás seguro de que deseas borrarlo?',
                label: 'Sí, deseo desvincular este horario al usuario',
              },
              submit: 'Desvincular horario',
            },
          },
        },
        languages: {
          add: 'Añadir idioma',
          create: {
            title: 'Añadir idioma',
            subtitle: 'Agregar idioma hablado por el usuario',
            form: {
              languageId: {
                title: 'Idioma',
                description: 'Idioma que habla el usuario',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar idioma',
            subtitle: 'Eliminar idioma de este usuario',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description: 'Esta acción es irreversible.',
                label: 'Sí, deseo eliminar este idioma',
              },
              submit: 'Confirmar',
            },
          },
        },
        roles: 'Roles',
        title: 'Actualizar usuario',
        subtitle: 'Modificación de los datos de un usuario en el sistema',
        licenses: {
          tab: 'Licencias',
          description:
            'Las licencias definen las acciones que pueden realizar los usuarios a nivel de grupo, es decir, son permisos que no implican a las clínicas.',
          add: 'Añadir',
          create: {
            title: 'Nueva licencia',
            subtitle: 'Registro de una nueva licencia en el sistema.',
            form: {
              roleId: {
                title: 'Rol',
                description:
                  'El rol es el conjunto de permisos que tendrá el usuario en el sistema',
              },
              submit: 'Crear licencia',
            },
          },
          delete: {
            title: 'Eliminar licencia',
            subtitle: 'Esta acción es irreversible',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description: 'Tal vez prefieras desactivar esta licencia',
                label: 'Sí, deseo eliminar esta licencia',
              },
              submit: 'Eliminar licencia',
            },
          },
        },
        permissions: {
          add: 'Añadir licencia',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Landbot',
              description: 'Es un usuario de LandBot',
              label: 'Sí, es un usuario de LandBot',
            },
            submit: 'Actualizar integraciones',
          },
        },
        contracts: {
          tab: 'Contratos',
          description:
            'Los contratos son acuerdos entre el usuario y la clínica. Un contrato declara que el usuario es empleado de la clínica. Cada contrato tiene unos permisos específicos.',
          add: 'Añadir contrato',
          addAll: 'Añadir todos los contratos',
          create: {
            title: 'Nuevo contrato',
            subtitle: 'Registro de un nuevo contrato en el sistema.',
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Crear contrato',
            },
          },
          disable: {
            title: 'Deshabilitar contrato',
            subtitle: 'Esta acción hace que el usuario no pueda acceder a la clínica',
            form: {
              status: {
                title: '¿Estás seguro de que deseas deshabilitarlo?',
              },
              submit: 'Deshabilitar contrato',
            },
          },
          enable: {
            title: 'Habilitar contrato',
            subtitle: 'Esta acción permite que el usuario acceda a la clínica',
            form: {
              status: {
                title: '¿Estás seguro de que deseas habilitarlo?',
              },
              submit: 'Habilitar contrato',
            },
          },
        },
        timetables: {
          add: 'Añadir horario',
          create: {
            title: 'Nuevo horario',
            subtitle: 'Registrar nuevo horario para el usuario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que el usuario tiene acceso al sistema',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio en la que el usuario tiene acceso al sistema',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin en la que el usuario tiene acceso al sistema',
              },
              submit: 'Crear horario',
            },
          },
          delete: {
            title: 'Eliminar horario',
            subtitle: 'Eliminar horario del usuario',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                label: 'Sí, deseo eliminar este horario',
              },
              submit: 'Eliminar horario',
            },
          },
        },
        movements: {
          tab: 'Movimientos',
          description:
            'Los movimientos son las peticiones que realiza el usuario al sistema. Por ejemplo, cuando el usuario accede a la pantalla de usuarios, se registra un movimiento.',
        },
        attempts: {
          tab: 'Intentos',
          description:
            'Los intentos son los accesos al inicio de sesión de los usuarios. Tanto los intentos satisfactorios como los incorrectos.',
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del usuario',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del usuario',
          },
          password: {
            title: 'Contraseña',
            description:
              'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.',
          },
          username: {
            title: 'Username',
            description: 'Nombre de usuario para el acceso al sistema',
          },
          email: {
            title: 'Correo electrónico',
            description: 'Email del usuario para poder iniciar sesión en el sistema',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto del usuario',
          },
          extension: {
            title: 'Extensión',
            description: 'Extensión identificativa dentro del departamento',
          },
          signature: {
            title: 'Contraseña',
            description: 'Contraseña del usuario',
          },
          expiration: {
            title: 'Fecha de expiración',
            description: 'Fecha en la que la cuenta del usuario expirará',
          },
          color: {
            title: 'Color',
            description: 'Color del usuario en el sistema',
          },
          status: {
            title: 'Acceso al sistema',
            description: 'Habilitar o deshabilitar el acceso al sistema para este usuario',
            label: 'Permitir acceso al sistema',
          },
          movementLimit: {
            title: 'Límite de movimientos',
            description: 'Número de peticiones permitidas para este usuario durante 24 horas',
          },
          attemptLimit: {
            title: 'Límite de intentos',
            description: 'Número de intentos permitidos para iniciar sesion durante 24 horas',
          },
          submit: 'Actualizar usuario',
        },
        activity: {
          form: {
            activityId: {
              title: 'Actividad',
            },
            submit: 'Actualizar actividad',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Actualizar ovopoints',
        subtitle: 'Modificación de los ovopoints de un usuario en el sistema',
        form: {
          appointedPoints: {
            title: 'Lead citado',
            description: 'Ovopoints por agendar cita',
          },
          attendedPoints: {
            title: 'El lead ha asistido a la cita médica',
            description: 'Ovopoints por asistir a una cita médica',
          },
          commercialAttendedPoints: {
            title: 'El lead ha tenido una cita comercial',
            description: 'Ovopoints por asistir a una cita comercial',
          },
          informedPoints: {
            title: 'Lead informado',
            description: 'Ovopoints por informar a un lead',
          },
          contactedPoints: {
            title: 'Lead contactado',
            description: 'Ovopoints por contactar a un lead',
          },
          convertedPoints: {
            title: 'Convertir lead',
            description: {
              ovoclinic: 'Ovopoints por convertir un lead a paciente',
              ovobank: 'Ovopoints por convertir un lead a donante',
            },
          },
          submit: 'Actualizar ovopoints',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Vía de comunicación',
        title: 'Vías de comunicación',
        description: 'Gestión de las vías de comunicación',
        one: 'Vía de comunicación',
        many: 'Vías de comunicación',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
      },
      create: {
        title: 'Nueva vía de comunicación',
        subtitle: 'Registro de una nueva vía de comunicación en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la vía de comunicación',
          },
          submit: 'Crear vía de comunicación',
        },
      },
      update: {
        title: 'Actualizar vía de comunicación',
        subtitle: 'Modificación de los datos de una vía de comunicación en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la vía de comunicación',
          },
          submit: 'Actualizar vía de comunicación',
        },
      },
      enable: {
        title: 'Habilitar vía de comunicación',
        subtitle: 'Habilitar vía de comunicación en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta vía de comunicación?',
            label: 'Sí, deseo habilitar esta vía de comunicación',
          },
          submit: 'Habilitar vía de comunicación',
        },
      },
      disable: {
        title: 'Deshabilitar vía de comunicación',
        subtitle: 'Deshabilitar vía de comunicación en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta vía de comunicación?',
            label: 'Sí, deseo deshabilitar esta vía de comunicación',
          },
          submit: 'Deshabilitar vía de comunicación',
        },
      },
    },
    specialists: {
      list: {
        create: 'Especialista',
        title: 'Especialista',
        description: 'Gestión de los especialistas',
        one: 'Especialista',
        many: 'Especialistas',
        columns: {
          name: 'Nombre',
          surname: 'Apellidos',
          dni: 'DNI',
          type: 'Tipo',
        },
      },
      create: {
        title: 'Nuevo especialista',
        subtitle: 'Registro de un nuevo especialista en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del especialista',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del especialista',
          },
          dni: {
            title: 'DNI',
            description: 'Documento de identidad del especialista',
          },
          type: {
            title: 'Tipo',
            description: 'Tipo de especialista',
          },
          submit: 'Crear especialista',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Especialista',
            description: 'Detalles personales del especialista',
          },
          contracts: {
            title: 'Contratos',
            description: 'Contratos del especialista',
          },
        },
        contracts: {
          add: 'Añadir contrato',
          list: {
            one: 'Contrato',
            many: 'Contratos',
          },
          create: {
            title: 'Nuevo contrato',
            subtitle: 'Registro de un nuevo contrato de especialista en el sistema',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece el contrato',
              },
              submit: 'Crear contrato',
            },
          },
          delete: {
            title: 'Eliminar contrato',
            subtitle: 'Eliminar contrato de este especialista',
            form: {
              submit: 'Eliminar contrato',
            },
          },
        },
        title: 'Actualizar especialista',
        subtitle: 'Modificación de los datos de un especialista en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del especialista',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del especialista',
          },
          dni: {
            title: 'DNI',
            description: 'Documento de identidad del especialista',
          },
          type: {
            title: 'Tipo',
            description: 'Tipo de especialista',
          },
          submit: 'Actualizar especialista',
        },
      },
      enable: {
        title: 'Habilitar especialista',
        subtitle: 'Esta acción habilita el especialista en el sistema',
        form: {
          submit: 'Habilitar especialista',
        },
      },
      disable: {
        title: 'Deshabilitar especialista',
        subtitle: 'Esta acción deshabilita el especialista en el sistema',
        form: {
          submit: 'Deshabilitar especialista',
        },
      },
    },
    templates: {
      list: {
        title: 'Plantillas',
        description: 'Gestión de las plantillas para Landbot',
        create: 'Plantilla',
        columns: {
          label: 'Nombre',
        },
        one: 'Plantilla',
        many: 'Plantillas',
      },
      create: {
        title: 'Nueva plantilla',
        subtitle: 'Registro de una nueva plantilla para Landbot',
        form: {
          label: {
            title: 'Nombre',
            description: 'Nombre de la plantilla',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'ID de Landbot al que se asocia la plantilla',
          },
          submit: 'Crear plantilla',
        },
      },
      update: {
        title: 'Actualizar plantilla',
        subtitle: 'Modificación de los datos de una plantilla para Landbot',
        tabs: {
          template: {
            title: 'Plantilla',
            description: 'Detalles de la plantilla',
          },
          templateScope: {
            title: 'Ámbito',
            description: 'Ámbito de la plantilla',
          },
          templateParam: {
            title: 'Parámetros',
            description: 'Parámetros de la plantilla',
          },
        },
        form: {
          label: {
            title: 'Nombre',
            description: 'Nombre de la plantilla',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'ID de Landbot al que se asocia la plantilla',
          },
          id: {
            title: 'Plantilla',
            description: 'Plantilla a usar en Landbot para mandar el mensaje',
          },
          submit: 'Actualizar plantilla',
        },
      },
      disable: {
        title: 'Deshabilitar plantilla',
        subtitle: 'Deshabilitar plantilla para Landbot',
        form: {
          submit: 'Deshabilitar plantilla',
        },
      },
      enable: {
        title: 'Habilitar plantilla',
        subtitle: 'Habilitar plantilla para Landbot',
        form: {
          submit: 'Habilitar plantilla',
        },
      },
      delete: {
        title: 'Eliminar plantilla',
        subtitle: 'Eliminar plantilla de Landbot',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            label: 'Sí, deseo eliminar esta plantilla',
          },
          submit: 'Eliminar plantilla',
        },
      },
      send: {
        title: 'Enviar plantilla',
        subtitle: 'Enviar plantilla al contacto',
        form: {
          id: {
            title: 'Plantilla',
            description: 'Plantilla a usar en Landbot para mandar el mensaje',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'ID del bot de Landbot al que se asocia la plantilla',
          },
          submit: 'Enviar plantilla',
        },
      },
      templateScopes: {
        list: {
          title: 'Ámbitos',
          description: 'Gestión de los ámbitos de las plantillas',
          create: 'Ámbito',
          columns: {
            label: 'Nombre',
          },
          one: 'Ámbito',
          many: 'Ámbitos',
        },
        create: {
          title: 'Nuevo ámbito',
          subtitle: 'Registro de un nuevo ámbito para las plantillas',
          form: {
            label: {
              title: 'Nombre',
              description: 'Nombre del ámbito',
            },
            departmentId: {
              title: 'Departamento',
              description: 'Departamento al que va a tener ámbito la plantilla',
            },
            submit: 'Crear ámbito',
          },
        },
        update: {
          title: 'Actualizar ámbito',
          subtitle: 'Modificación de los datos de un ámbito de plantilla',
          form: {
            label: {
              title: 'Nombre',
              description: 'Nombre del ámbito',
            },
            submit: 'Actualizar ámbito',
          },
        },
        delete: {
          title: 'Eliminar ámbito',
          subtitle: 'Eliminar ámbito de esta plantilla',
          form: {
            status: {
              title: '¿Estás seguro de que deseas eliminarlo?',
              label: 'Sí, deseo eliminar este ámbito',
            },
            submit: 'Eliminar ámbito',
          },
        },
        add: 'Añadir ámbito',
      },
      templateParams: {
        list: {
          title: 'Parámetros',
          description: 'Gestión de los parámetros de las plantillas',
          create: 'Parámetro',
          columns: {
            label: 'Nombre',
            order: 'Orden',
            templateKeyName: 'Parámetro',
          },
          one: 'Parámetro',
          many: 'Parámetros',
        },
        create: {
          title: 'Nuevo parámetro',
          subtitle: 'Registro de un nuevo parámetro para las plantillas',
          form: {
            templateKeyId: {
              title: 'Parámetro',
              description: 'Posibles parámetros a adjuntar a la plantilla',
            },
            order: {
              title: 'Orden',
              description: 'Orden en el que se mostrará el parámetro en la plantilla',
            },
            submit: 'Crear parámetro',
          },
        },
        delete: {
          title: 'Eliminar parámetro',
          subtitle: 'Eliminar parámetro de esta plantilla',
          form: {
            status: {
              title: '¿Estás seguro de que deseas eliminarlo?',
              label: 'Sí, deseo eliminar este parámetro',
            },
            submit: 'Eliminar parámetro',
          },
        },
        add: 'Añadir parámetro',
      },
    },
    languages: {
      list: {
        create: 'Idioma',
        title: 'Idioma',
        description: 'Gestión de los idiomas',
        one: 'Idioma',
        many: 'Idiomas',
        columns: {
          name: 'Nombre',
          country: 'País',
          code: 'Código',
        },
      },
      create: {
        title: 'Nuevo idioma',
        subtitle: 'Registro de un nuevo idioma en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del idioma',
          },
          status: {
            title: 'Estado',
            label: 'Estado',
          },
          countryCode: {
            title: 'País',
            description: 'País al que pertenece el idioma',
          },
          code: {
            title: 'Código',
            description: 'Código del idioma',
          },
          submit: 'Crear idioma',
        },
      },
      update: {
        title: 'Actualizar idioma',
        subtitle: 'Modificación de los datos de un idioma en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del idioma',
          },
          countryCode: {
            title: 'País',
            description: 'País al que pertenece el idioma',
          },
          code: {
            title: 'Código',
            description: 'Código del idioma',
          },
          submit: 'Actualizar idioma',
        },
      },
      enable: {
        title: 'Habilitar idioma',
        subtitle: 'Habilitar idioma en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este idioma?',
            label: 'Sí, deseo habilitar este idioma',
          },
          submit: 'Habilitar idioma',
        },
      },
      disable: {
        title: 'Deshabilitar idioma',
        subtitle: 'Deshabilitar idioma en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este idioma?',
            label: 'Sí, deseo deshabilitar este idioma',
          },
          submit: 'Deshabilitar idioma',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'Tenemos buenas noticias',
      },
      create: {
        title: 'Nueva noticia',
        subtitle: 'Registrar una nueva noticia en la clínica',
        steps: {
          clinic: {
            form: {
              title: 'Clínica',
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece la noticia',
              },
            },
          },
          actor: {
            form: {
              title: 'Compañero',
              actorId: {
                title: 'Compañero',
                description: 'Compañero del que trata la noticia',
              },
            },
          },
        },
        form: {
          description: {
            title: 'Descripción',
            description:
              'Para celebrar logros dentro de la clínica, se puede compartir información como: historias de éxito, reconocimientos personales y agradecimientos.',
          },
          image: {
            title: 'Imagen',
            description: 'Imagen de la noticia',
          },
          submit: 'Crear noticia',
        },
      },
      update: {
        title: 'Detalles de la noticia',
        subtitle: 'Detalles de la noticia de la clínica',
        form: {
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece la noticia',
          },
          authorName: {
            title: 'Autor',
            description: 'Autor de la noticia',
          },
          actorName: {
            title: 'Compañero',
            description: 'Compañero del que trata la noticia',
          },
          description: {
            title: 'Descripción',
            description:
              'Para celebrar logros dentro de la clínica, se puede compartir información como: historias de éxito, reconocimientos personales y agradecimientos.',
          },
          image: {
            title: 'Imagen',
            description: 'Imagen de la noticia',
          },
          submit: 'Actualizar noticia',
        },
      },
      list: {
        title: 'Noticias',
        description: 'Noticia de la clínica',
        create: 'Noticia',
        one: 'Noticia',
        many: 'Noticias',
        columns: {
          clinicName: 'Clínica',
          actorName: 'Compañero',
          authorName: 'Autor',
          created: 'Creación',
        },
      },
      delete: {
        title: 'Deshabilitar noticia',
        subtitle: 'Deshabilitar noticia de la clínica',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta noticia?',
            label: 'Sí, deseo deshabilitar esta noticia',
          },
          submit: 'Deshabilitar',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar la noticia, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar noticia',
        subtitle: 'Habilitar noticia de la clínica',
        button: 'Habilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta noticia?',
            label: 'Sí, deseo habilitar esta noticia',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar noticia',
        subtitle: 'Deshabilitar noticia de la clínica',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta noticia?',
            label: 'Sí, deseo deshabilitar esta noticia',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Rol',
          created: 'Creación',
        },
        title: 'Roles',
        description: 'Gestión de los roles y sus permisos',
        create: 'Rol',
        one: 'Rol',
        many: 'Roles',
      },
      create: {
        title: 'Nuevo rol',
        subtitle: 'Registro de un nuevo rol en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del rol',
          },
          general: {
            accordion: 'Permisos generales',
            title: 'Permisos generales',
            description: 'Permisos generales para el rol',
          },
          viewDisabledRecords: {
            title: 'Ver registros deshabilitados',
            description: 'Esta opción habilita la visualización de los registros deshabilitados',
            label: 'Habilitar visualización de registros deshabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads deshabilitados',
            description: 'Esta opción habilita la visualización de los leads deshabilitados',
            label: 'Habilitar visualización de leads deshabilitados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes deshabilitados',
              ovobank: 'Ver donantes deshabilitados',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes deshabilitados',
              ovobank: 'Esta opción habilita la visualización de los donantes deshabilitados',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes deshabilitados',
              ovobank: 'Habilitar visualización de donantes deshabilitados',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permiso al módulo OVOAccess',
            description: 'Esta opción habilita el acceso al módulo OVOAccess',
            label: 'Habilitar acceso',
          },
          user: {
            accordion: 'Usuarios',
          },
          accessUser: {
            title: 'Ver usuarios',
            description: 'Esta opción habilita la visualización de los usuarios en el sistema',
            label: 'Habilitar visualización de usuarios',
          },
          accessUserCreator: {
            title: 'Crear usuarios',
            description: 'Esta opción habilita la creación de los usuarios en el sistema',
            label: 'Habilitar creación de usuarios',
          },
          accessUserEditor: {
            title: 'Editar usuarios',
            description: 'Esta opción habilita la edición de los usuarios en el sistema',
            label: 'Habilitar edición de usuarios',
          },
          accessUserDestructor: {
            title: 'Eliminar usuarios',
            description: 'Esta opción habilita la eliminación de los usuarios en el sistema',
            label: 'Habilitar eliminación de usuarios',
          },
          accessUserChangePassword: {
            title: 'Cambiar contraseña',
            description:
              'Esta opción habilita el cambio de contraseña de los usuarios en el sistema',
            label: 'Habilitar cambio de contraseña',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'Ver roles',
            description: 'Esta opción habilita la visualización de los roles en el sistema',
            label: 'Habilitar visualización de roles',
          },
          accessRoleCreator: {
            title: 'Crear roles',
            description: 'Esta opción habilita la creación de los roles en el sistema',
            label: 'Habilitar creación de roles',
          },
          accessRoleEditor: {
            title: 'Editar roles',
            description: 'Esta opción habilita la edición de los roles en el sistema',
            label: 'Habilitar edición de roles',
          },
          accessRoleDestructor: {
            title: 'Eliminar roles',
            description: 'Esta opción habilita la eliminación de los roles en el sistema',
            label: 'Habilitar eliminación de roles',
          },
          timetable: {
            accordion: 'Horarios',
          },
          accessTimetable: {
            title: 'Ver horarios',
            description: 'Esta opción habilita la visualización de los horarios en el sistema',
            label: 'Habilitar visualización de horarios',
          },
          accessTimetableCreator: {
            title: 'Crear horarios',
            description: 'Esta opción habilita la creación de los horarios en el sistema',
            label: 'Habilitar creación de horarios',
          },
          accessTimetableEditor: {
            title: 'Editar horarios',
            description: 'Esta opción habilita la edición de los horarios en el sistema',
            label: 'Habilitar edición de horarios',
          },
          accessTimetableDestructor: {
            title: 'Eliminar horarios',
            description: 'Esta opción habilita la eliminación de los horarios en el sistema',
            label: 'Habilitar eliminación de horarios',
          },
          bulletin: {
            accordion: 'Noticias',
          },
          accessBulletin: {
            title: 'Ver noticias',
            description: 'Esta opción habilita la visualización de las noticias en el sistema',
            label: 'Habilitar visualización de noticias',
          },
          accessBulletinCreator: {
            title: 'Crear noticias',
            description: 'Esta opción habilita la creación de las noticias en el sistema',
            label: 'Habilitar creación de noticias',
          },
          accessBulletinEditor: {
            title: 'Editar noticias',
            description: 'Esta opción habilita la edición de las noticias en el sistema',
            label: 'Habilitar edición de noticias',
          },
          accessBulletinDestructor: {
            title: 'Eliminar noticias',
            description: 'Esta opción habilita la eliminación de las noticias en el sistema',
            label: 'Habilitar eliminación de noticias',
          },
          accessClinic: {
            title: 'Ver clínicas',
            description: 'Esta opción habilita la visualización de las clínicas en el sistema',
            label: 'Habilitar visualización de clínicas',
          },
          accessClinicCreator: {
            title: 'Crear clínicas',
            description: 'Esta opción habilita la creación de las clínicas en el sistema',
            label: 'Habilitar creación de clínicas',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opción habilita la edición de las clínicas en el sistema',
            label: 'Habilitar edición de clínicas',
          },
          accessClinicDestructor: {
            title: 'Eliminar clínicas',
            description: 'Esta opción habilita la eliminación de las clínicas en el sistema',
            label: 'Habilitar eliminación de clínicas',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opción habilita la visualización de los departamentos en el sistema',
            label: 'Habilitar visualización de departamentos',
          },
          accessDepartmentCreator: {
            title: 'Crear departamentos',
            description: 'Esta opción habilita la creación de los departamentos en el sistema',
            label: 'Habilitar creación de departamentos',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opción habilita la edición de los departamentos en el sistema',
            label: 'Habilitar edición de departamentos',
          },
          accessDepartmentDestructor: {
            title: 'Eliminar departamentos',
            description: 'Esta opción habilita la eliminación de los departamentos en el sistema',
            label: 'Habilitar eliminación de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opción habilita la visualización de los idiomas en el sistema',
            label: 'Habilitar visualización de idiomas',
          },
          accessLanguageCreator: {
            title: 'Crear idiomas',
            description: 'Esta opción habilita la creación de los idiomas en el sistema',
            label: 'Habilitar creación de idiomas',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opción habilita la edición de los idiomas en el sistema',
            label: 'Habilitar edición de idiomas',
          },
          accessLanguageDestructor: {
            title: 'Eliminar idiomas',
            description: 'Esta opción habilita la eliminación de los idiomas en el sistema',
            label: 'Habilitar eliminación de idiomas',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opción habilita la visualización de los países en el sistema',
            label: 'Habilitar visualización de países',
          },
          accessCountryCreator: {
            title: 'Crear países',
            description: 'Esta opción habilita la creación de los países en el sistema',
            label: 'Habilitar creación de países',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opción habilita la edición de los países en el sistema',
            label: 'Habilitar edición de países',
          },
          accessCountryDestructor: {
            title: 'Eliminar países',
            description: 'Esta opción habilita la eliminación de los países en el sistema',
            label: 'Habilitar eliminación de países',
          },
          accessSettings: {
            title: 'Ver configuración',
            description: 'Esta opción habilita la visualización de la configuración en el sistema',
            label: 'Habilitar visualización de configuración',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Ver especialistas',
            description: 'Esta opción habilita la visualización de los especialistas en el sistema',
            label: 'Habilitar visualización de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crear especialistas',
            description: 'Esta opción habilita la creación de los especialistas en el sistema',
            label: 'Habilitar creación de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opción habilita la edición de los especialistas en el sistema',
            label: 'Habilitar edición de especialistas',
          },
          accessSpecialistDestructor: {
            title: 'Eliminar especialistas',
            description: 'Esta opción habilita la eliminación de los especialistas en el sistema',
            label: 'Habilitar eliminación de especialistas',
          },
          commsChannels: {
            accordion: 'Canales de comunicación',
          },
          accessCommsChannel: {
            title: 'Ver canales de comunicación',
            description:
              'Esta opción habilita la visualización de los canales de comunicación en el sistema',
            label: 'Habilitar visualización de canales de comunicación',
          },
          accessCommsChannelCreator: {
            title: 'Crear canales de comunicación',
            description:
              'Esta opción habilita la creación de los canales de comunicación en el sistema',
            label: 'Habilitar creación de canales de comunicación',
          },
          accessCommsChannelEditor: {
            title: 'Editar canales de comunicación',
            description:
              'Esta opción habilita la edición de los canales de comunicación en el sistema',
            label: 'Habilitar edición de canales de comunicación',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminar canales de comunicación',
            description:
              'Esta opción habilita la eliminación de los canales de comunicación en el sistema',
            label: 'Habilitar eliminación de canales de comunicación',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'Esta opción habilita la visualización de los ovopoints en el sistema',
            label: 'Habilitar visualización de ovopoints',
          },
          accessOvopointEditor: {
            title: 'Editar ovopoints',
            description: 'Esta opción habilita la edición de los ovopoints en el sistema',
            label: 'Habilitar edición de ovopoints',
          },
          templates: {
            accordion: 'Plantillas',
          },
          accessTemplate: {
            title: 'Ver plantillas de WhatsApp',
            description: 'Esta opción habilita la visualización de las plantillas de WhatsApp',
            label: 'Habilitar visualización de plantillas',
          },
          accessTemplateCreator: {
            title: 'Crear plantillas de WhatsApp',
            description: 'Esta opción habilita la creación de las plantillas de WhatsApp',
            label: 'Habilitar creación de plantillas',
          },
          accessTemplateEditor: {
            title: 'Editar plantillas de WhatsApp',
            description: 'Esta opción habilita la edición de las plantillas de WhatsApp',
            label: 'Habilitar edición de plantillas',
          },
          accessTemplateDestructor: {
            title: 'Eliminar plantillas de WhatsApp',
            description: 'Esta opción habilita la eliminación de las plantillas de WhatsApp',
            label: 'Habilitar eliminación de plantillas',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Permiso a la aplicación',
            description: 'Esta opción habilita el acceso al módulo OVOCrm',
            label: 'Habilitar acceso',
          },
          tasks: {
            accordion: 'Tareas',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Esta opción habilita la visualización del calendario en el sistema',
            label: 'Habilitar visualización de calendario',
          },
          crmTask: {
            title: 'Tareas',
            description: 'Esta opción habilita la visualización de las tareas en el sistema',
            label: 'Habilitar visualización de tareas',
          },
          crmTaskCreator: {
            title: 'Crear tareas',
            description: 'Esta opción habilita la creación de las tareas en el sistema',
            label: 'Habilitar creación de tareas',
          },
          crmTaskEditor: {
            title: 'Editar tareas',
            description: 'Esta opción habilita la edición de las tareas en el sistema',
            label: 'Habilitar edición de tareas',
          },
          crmTaskDestructor: {
            title: 'Eliminar tareas',
            description: 'Esta opción habilita la eliminación de las tareas en el sistema',
            label: 'Habilitar eliminación de tareas',
          },
          crmTaskSeeAll: {
            title: 'Ver todas las tareas',
            description: 'Esta opción habilita la visualización de todas las tareas en el sistema',
            label: 'Habilitar visualización de todas las tareas',
          },
          crmTaskSeeCoworkers: {
            title: 'Ver tareas de compañeros',
            description:
              'Esta opción habilita la visualización de las tareas de los compañeros en el sistema',
            label: 'Habilitar visualización de tareas de compañeros',
          },
          crmTaskUpdateResponsible: {
            title: 'Actualizar el responsable asignado a una tarea',
            description: 'Esta opción habilita el cambio de responsable de una tarea',
            label: 'Habilitar edicion de responsable de tarea',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'Esta opción habilita la visualización de los leads en el sistema',
            label: 'Habilitar visualización de leads',
          },
          crmLeadCreator: {
            title: 'Crear leads',
            description: 'Esta opción habilita la creación de los leads en el sistema',
            label: 'Habilitar creación de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opción habilita la edición de los leads en el sistema',
            label: 'Habilitar edición de leads',
          },
          crmLeadDestructor: {
            title: 'Eliminar leads',
            description: 'Esta opción habilita la eliminación de los leads en el sistema',
            label: 'Habilitar eliminación de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos los leads',
            description: 'Esta opción habilita la visualización de todos los leads en el sistema',
            label: 'Habilitar visualización de todos los leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Ver leads de compañeros',
            description: 'Esta opción habilita la visualización de los leads de compañeros',
            label: 'Habilitar visualización de leads de compañeros',
          },
          crmLeadUpdateCampaign: {
            title: 'Actualizar campaña asignada a leads',
            description: 'Esta opción habilita el cambio de campaña de multiples leads',
            label: 'Habilitar edicion de campaña de leads',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes',
              ovobank: 'Habilitar visualización de donantes',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Crear pacientes',
              ovobank: 'Crear donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la creación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la creación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de pacientes',
              ovobank: 'Habilitar creación de donantes',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la edición de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la edición de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de pacientes',
              ovobank: 'Habilitar edición de donantes',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Eliminar pacientes',
              ovobank: 'Eliminar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la eliminación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la eliminación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de pacientes',
              ovobank: 'Habilitar eliminación de donantes',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos los pacientes',
              ovobank: 'Ver todos los donantes',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la visualización de todos los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de todos los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de todos los pacientes',
              ovobank: 'Habilitar visualización de todos los donantes',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Ver pacientes de compañeros',
              ovobank: 'Ver donantes de compañeros',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes de compañeros',
              ovobank: 'Esta opción habilita la visualización de los donantes de compañeros',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes de compañeros',
              ovobank: 'Habilitar visualización de donantes de compañeros',
            },
          },
          contacts: {
            accordion: 'Contactos',
          },
          crmContact: {
            title: 'Contactos',
            description: 'Esta opción habilita la visualización de los contactos en el sistema',
            label: 'Habilitar visualización de contactos',
          },
          crmContactCreator: {
            title: 'Crear contactos',
            description: 'Esta opción habilita la creación de los contactos en el sistema',
            label: 'Habilitar creación de contactos',
          },
          crmContactEditor: {
            title: 'Editar contactos',
            description: 'Esta opción habilita la edición de los contactos en el sistema',
            label: 'Habilitar edición de contactos',
          },
          crmContactDestructor: {
            title: 'Eliminar contactos',
            description: 'Esta opción habilita la eliminación de los contactos en el sistema',
            label: 'Habilitar eliminación de contactos',
          },
          activity: {
            accordion: 'Actividades',
          },
          crmActivity: {
            title: 'Actividades',
            description: 'Esta opción habilita la visualización de las actividades en el sistema',
            label: 'Habilitar visualización de actividades',
          },
          crmActivityCreator: {
            title: 'Crear actividades',
            description: 'Esta opción habilita la creación de las actividades en el sistema',
            label: 'Habilitar creación de actividades',
          },
          crmActivityEditor: {
            title: 'Editar actividades',
            description: 'Esta opción habilita la edición de las actividades en el sistema',
            label: 'Habilitar edición de actividades',
          },
          crmActivityDestructor: {
            title: 'Eliminar actividades',
            description: 'Esta opción habilita la eliminación de las actividades en el sistema',
            label: 'Habilitar eliminación de actividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas las actividades',
            description:
              'Esta opción habilita la visualización de todas las actividades en el sistema',
            label: 'Habilitar visualización de todas las actividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opción habilita la visualización de los resultados en el sistema',
            label: 'Habilitar visualización de resultados',
          },
          crmResultCreator: {
            title: 'Crear resultados',
            description: 'Esta opción habilita la creación de los resultados en el sistema',
            label: 'Habilitar creación de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opción habilita la edición de los resultados en el sistema',
            label: 'Habilitar edición de resultados',
          },
          crmResultDestructor: {
            title: 'Eliminar resultados',
            description: 'Esta opción habilita la eliminación de los resultados en el sistema',
            label: 'Habilitar eliminación de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar hitos de Call Center',
            description:
              'Esta opción habilita la edición de los hitos de Call Center en el sistema',
            label: 'Habilitar edición de hitos de Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar hitos de ATP Comercial',
            description:
              'Esta opción habilita la edición de los hitos de ATP Comercial en el sistema',
            label: 'Habilitar edición de hitos de ATP Comercial',
          },
          crmResultATPIEvent: {
            title: 'Editar hitos de ATP Internacional',
            description:
              'Esta opción habilita la edición de los hitos de ATP Internacional en el sistema',
            label: 'Habilitar edición de hitos de ATP Internacional',
          },
          crmResultReactivity: {
            title: 'Editar reactividad',
            description: 'Esta opción habilita la edición de la reactividad en el sistema',
            label: 'Habilitar edición de reactividad',
          },
          reasons: {
            accordion: 'Motivos',
          },
          crmReason: {
            title: 'Motivos',
            description: 'Esta opción habilita la visualización de los motivos en el sistema',
            label: 'Habilitar visualización de motivos',
          },
          crmReasonCreator: {
            title: 'Crear motivos',
            description: 'Esta opción habilita la creación de los motivos en el sistema',
            label: 'Habilitar creación de motivos',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opción habilita la edición de los motivos en el sistema',
            label: 'Habilitar edición de motivos',
          },
          crmReasonDestructor: {
            title: 'Eliminar motivos',
            description: 'Esta opción habilita la eliminación de los motivos en el sistema',
            label: 'Habilitar eliminación de motivos',
          },
          campaigns: {
            accordion: 'Campañas',
          },
          crmCampaign: {
            title: 'Campañas',
            description: 'Esta opción habilita la visualización de las campañas en el sistema',
            label: 'Habilitar visualización de campañas',
          },
          crmCampaignCreator: {
            title: 'Crear campañas',
            description: 'Esta opción habilita la creación de las campañas en el sistema',
            label: 'Habilitar creación de campañas',
          },
          crmCampaignEditor: {
            title: 'Editar campañas',
            description: 'Esta opción habilita la edición de las campañas en el sistema',
            label: 'Habilitar edición de campañas',
          },
          crmCampaignDestructor: {
            title: 'Eliminar campañas',
            description: 'Esta opción habilita la eliminación de las campañas en el sistema',
            label: 'Habilitar eliminación de campañas',
          },
          funnels: {
            accordion: 'Embudos',
          },
          crmFunnel: {
            title: 'Embudos',
            description: 'Esta opción habilita la visualización de los embudos en el sistema',
            label: 'Habilitar visualización de embudos',
          },
          crmFunnelCreator: {
            title: 'Crear embudos',
            description: 'Esta opción habilita la creación de los embudos en el sistema',
            label: 'Habilitar creación de embudos',
          },
          crmFunnelEditor: {
            title: 'Editar embudos',
            description: 'Esta opción habilita la edición de los embudos en el sistema',
            label: 'Habilitar edición de embudos',
          },
          crmFunnelDestructor: {
            title: 'Eliminar embudos',
            description: 'Esta opción habilita la eliminación de los embudos en el sistema',
            label: 'Habilitar eliminación de embudos',
          },
          channels: {
            accordion: 'Canales',
          },
          crmChannel: {
            title: 'Canales',
            description: 'Esta opción habilita la visualización de los canales en el sistema',
            label: 'Habilitar visualización de canales',
          },
          crmChannelCreator: {
            title: 'Crear canales',
            description: 'Esta opción habilita la creación de los canales en el sistema',
            label: 'Habilitar creación de canales',
          },
          crmChannelEditor: {
            title: 'Editar canales',
            description: 'Esta opción habilita la edición de los canales en el sistema',
            label: 'Habilitar edición de canales',
          },
          crmChannelDestructor: {
            title: 'Eliminar canales',
            description: 'Esta opción habilita la eliminación de los canales en el sistema',
            label: 'Habilitar eliminación de canales',
          },
          programs: {
            accordion: 'Tratamientos',
          },
          crmProgram: {
            title: 'Tratamientos',
            description:
              'Esta opción habilita la visualización de los tratamientos del catálogo en el sistema',
            label: 'Habilitar visualización de tratamientos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crear tratamientos',
            description: 'Esta opción habilita la creación de los tratamientos en el sistema',
            label: 'Habilitar creación de tratamientos',
          },
          crmProgramEditor: {
            title: 'Editar tratamientos',
            description: 'Esta opción habilita la edición de los tratamientos en el sistema',
            label: 'Habilitar edición de tratamientos',
          },
          crmProgramDestructor: {
            title: 'Eliminar tratamientos',
            description: 'Esta opción habilita la eliminación de los tratamientos en el sistema',
            label: 'Habilitar eliminación de tratamientos',
          },
          services: {
            accordion: 'Servicios',
          },
          crmService: {
            title: 'Servicios',
            description: 'Esta opción habilita la visualización de los servicios en el sistema',
            label: 'Habilitar visualización de servicios',
          },
          crmServiceCreator: {
            title: 'Crear servicios',
            description: 'Esta opción habilita la creación de los servicios en el sistema',
            label: 'Habilitar creación de servicios',
          },
          crmServiceEditor: {
            title: 'Editar servicios',
            description: 'Esta opción habilita la edición de los servicios en el sistema',
            label: 'Habilitar edición de servicios',
          },
          crmServiceDestructor: {
            title: 'Eliminar servicios',
            description: 'Esta opción habilita la eliminación de los servicios en el sistema',
            label: 'Habilitar eliminación de servicios',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementarias',
            description:
              'Esta opción habilita la visualización de las técnicas complementarias en el sistema',
            label: 'Habilitar visualización de técnicas complementarias',
          },
          crmTechniqueCreator: {
            title: 'Crear técnicas complementarias',
            description:
              'Esta opción habilita la creación de las técnicas complementarias en el sistema',
            label: 'Habilitar creación de técnicas complementarias',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementarias',
            description:
              'Esta opción habilita la edición de las técnicas complementarias en el sistema',
            label: 'Habilitar edición de técnicas complementarias',
          },
          crmTechniqueDestructor: {
            title: 'Eliminar técnicas complementarias',
            description:
              'Esta opción habilita la eliminación de las técnicas complementarias en el sistema',
            label: 'Habilitar eliminación de técnicas complementarias',
          },
          maintenances: {
            accordion: 'Mantenimientos',
          },
          crmMaintenance: {
            title: 'Mantenimientos',
            description:
              'Esta opción habilita la visualización de los mantenimientos en el sistema',
            label: 'Habilitar visualización de mantenimientos',
          },
          crmMaintenanceCreator: {
            title: 'Crear mantenimientos',
            description: 'Esta opción habilita la creación de los mantenimientos en el sistema',
            label: 'Habilitar creación de mantenimientos',
          },
          crmMaintenanceEditor: {
            title: 'Editar mantenimientos',
            description: 'Esta opción habilita la edición de los mantenimientos en el sistema',
            label: 'Habilitar edición de mantenimientos',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminar mantenimientos',
            description: 'Esta opción habilita la eliminación de los mantenimientos en el sistema',
            label: 'Habilitar eliminación de mantenimientos',
          },
          treatments: {
            accordion: 'Tratamientos',
          },
          crmTreatment: {
            title: 'Tratamientos',
            description: 'Esta opción habilita la visualización de los tratamientos en el sistema',
            label: 'Habilitar visualización de tratamientos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crear tratamientos para paciente',
              ovobank: 'Crear tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la creación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la creación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de tratamientos para paciente',
              ovobank: 'Habilitar creación de tratamientos para donante',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamientos para paciente',
              ovobank: 'Editar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la edición de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la edición de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de tratamientos para paciente',
              ovobank: 'Habilitar edición de tratamientos para donante',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Eliminar tratamientos para paciente',
              ovobank: 'Eliminar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la eliminación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la eliminación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de tratamientos para paciente',
              ovobank: 'Habilitar eliminación de tratamientos para donante',
            },
          },
          phases: {
            accordion: 'Etapas',
          },
          crmPhase: {
            title: 'Etapas',
            description: 'Esta opción habilita la visualización de las etapas en el sistema',
            label: 'Habilitar visualización de etapas',
          },
          crmPhaseCreator: {
            title: 'Crear etapas',
            description: 'Esta opción habilita la creación de las etapas en el sistema',
            label: 'Habilitar creación de etapas',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opción habilita la edición de las etapas en el sistema',
            label: 'Habilitar edición de etapas',
          },
          crmPhaseDestructor: {
            title: 'Eliminar etapas',
            description: 'Esta opción habilita la eliminación de las etapas en el sistema',
            label: 'Habilitar eliminación de etapas',
          },
          causes: {
            accordion: 'Motivos',
          },
          crmCause: {
            title: 'Motivos de cancelación',
            description:
              'Esta opción habilita la visualización de los motivos de cancelación en el sistema',
            label: 'Habilitar visualización de motivos de cancelación',
          },
          crmCauseCreator: {
            title: 'Crear motivos de cancelación',
            description:
              'Esta opción habilita la creación de los motivos de cancelación en el sistema',
            label: 'Habilitar creación de motivos de cancelación',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelación',
            description:
              'Esta opción habilita la edición de los motivos de cancelación en el sistema',
            label: 'Habilitar edición de motivos de cancelación',
          },
          crmCauseDestructor: {
            title: 'Eliminar motivos de cancelación',
            description:
              'Esta opción habilita la eliminación de los motivos de cancelación en el sistema',
            label: 'Habilitar eliminación de motivos de cancelación',
          },
          cycles: {
            accordion: 'Ciclos de tratamiento',
          },
          crmCycle: {
            title: 'Ciclos de tratamiento',
            description:
              'Esta opción habilita la visualización de los ciclos de tratamiento en el sistema',
            label: 'Habilitar visualización de ciclos de tratamiento',
          },
          crmCycleCreator: {
            title: 'Crear ciclos de tratamiento',
            description:
              'Esta opción habilita la creación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar creación de ciclos de tratamiento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamiento',
            description:
              'Esta opción habilita la edición de los ciclos de tratamiento en el sistema',
            label: 'Habilitar edición de ciclos de tratamiento',
          },
          crmCycleDestructor: {
            title: 'Eliminar ciclos de tratamiento',
            description:
              'Esta opción habilita la eliminación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar eliminación de ciclos de tratamiento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'Esta opción habilita el acceso al módulo OVOClinic',
            label: 'Habilitar acceso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opción habilita el acceso al módulo OVOBoard',
            label: 'Habilitar acceso',
          },
          boardLeadsResume: {
            title: 'Resumen de leads',
            label: 'Habilitar resumen de leads',
          },
          boardSaleResume: {
            title: 'Resumen de ventas',
            label: 'Habilitar resumen de ventas',
          },
          boardSaleFunnel: {
            title: 'Embudo de ventas',
            label: 'Habilitar embudo de ventas',
          },
          boardExpectedIntervention: {
            title: 'Previsión de tratamientos',
            label: 'Habilitar previsión de tratamientos',
          },
          boardCoworkerPerformance: {
            title: 'Rendimiento de empleados',
            label: 'Habilitar rendimiento de empleados',
          },
          boardMarketing: {
            title: 'Marketing',
            label: 'Habilitar marketing',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'Esta opción habilita el acceso al módulo OVOTory',
            label: 'Habilitar acceso',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'Esta opción habilita el acceso al módulo OVOProfile',
            label: 'Habilitar acceso',
          },
          status: {
            title: 'Rol activo',
            description: 'Habilitar o deshabilitar el rol en el sistema',
            label: 'Rol activo',
          },
          submit: 'Crear rol',
        },
      },
      update: {
        title: 'Actualizar rol',
        subtitle: 'Modificación de los datos de un rol en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del rol',
          },
          general: {
            accordion: 'General',
            title: 'Permisos generales',
            description: 'Permisos generales para el rol',
          },
          viewDisabledRecords: {
            title: 'Ver registros deshabilitados',
            description: 'Esta opción habilita la visualización de los registros deshabilitados',
            label: 'Habilitar visualización de registros deshabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads deshabilitados',
            description: 'Esta opción habilita la visualización de los leads deshabilitados',
            label: 'Habilitar visualización de leads deshabilitados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes deshabilitados',
              ovobank: 'Ver donantes deshabilitados',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes deshabilitados',
              ovobank: 'Esta opción habilita la visualización de los donantes deshabilitados',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes deshabilitados',
              ovobank: 'Habilitar visualización de donantes deshabilitados',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permiso al módulo OVOAccess',
            description: 'Esta opción habilita el acceso al módulo OVOAccess',
            label: 'Habilitar acceso',
          },
          user: {
            accordion: 'Usuarios',
          },
          accessUser: {
            title: 'Ver usuarios',
            description: 'Esta opción habilita la visualización de los usuarios en el sistema',
            label: 'Habilitar visualización de usuarios',
          },
          accessUserCreator: {
            title: 'Crear usuarios',
            description: 'Esta opción habilita la creación de los usuarios en el sistema',
            label: 'Habilitar creación de usuarios',
          },
          accessUserEditor: {
            title: 'Editar usuarios',
            description: 'Esta opción habilita la edición de los usuarios en el sistema',
            label: 'Habilitar edición de usuarios',
          },
          accessUserDestructor: {
            title: 'Eliminar usuarios',
            description: 'Esta opción habilita la eliminación de los usuarios en el sistema',
            label: 'Habilitar eliminación de usuarios',
          },
          accessUserChangePassword: {
            title: 'Cambiar contraseña',
            description:
              'Esta opción habilita el cambio de contraseña de los usuarios en el sistema',
            label: 'Habilitar cambio de contraseña',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'Ver roles',
            description: 'Esta opción habilita la visualización de los roles en el sistema',
            label: 'Habilitar visualización de roles',
          },
          accessRoleCreator: {
            title: 'Crear roles',
            description: 'Esta opción habilita la creación de los roles en el sistema',
            label: 'Habilitar creación de roles',
          },
          accessRoleEditor: {
            title: 'Editar roles',
            description: 'Esta opción habilita la edición de los roles en el sistema',
            label: 'Habilitar edición de roles',
          },
          accessRoleDestructor: {
            title: 'Eliminar roles',
            description: 'Esta opción habilita la eliminación de los roles en el sistema',
            label: 'Habilitar eliminación de roles',
          },
          timetable: {
            accordion: 'Horarios',
          },
          accessTimetable: {
            title: 'Ver horarios',
            description: 'Esta opción habilita la visualización de los horarios en el sistema',
            label: 'Habilitar visualización de horarios',
          },
          accessTimetableCreator: {
            title: 'Crear horarios',
            description: 'Esta opción habilita la creación de los horarios en el sistema',
            label: 'Habilitar creación de horarios',
          },
          accessTimetableEditor: {
            title: 'Editar horarios',
            description: 'Esta opción habilita la edición de los horarios en el sistema',
            label: 'Habilitar edición de horarios',
          },
          accessTimetableDestructor: {
            title: 'Eliminar horarios',
            description: 'Esta opción habilita la eliminación de los horarios en el sistema',
            label: 'Habilitar eliminación de horarios',
          },
          bulletin: {
            accordion: 'Noticias',
          },
          accessBulletin: {
            title: 'Ver noticias',
            description: 'Esta opción habilita la visualización de las noticias en el sistema',
            label: 'Habilitar visualización de noticias',
          },
          accessBulletinCreator: {
            title: 'Crear noticias',
            description: 'Esta opción habilita la creación de las noticias en el sistema',
            label: 'Habilitar creación de noticias',
          },
          accessBulletinEditor: {
            title: 'Editar noticias',
            description: 'Esta opción habilita la edición de las noticias en el sistema',
            label: 'Habilitar edición de noticias',
          },
          accessBulletinDestructor: {
            title: 'Eliminar noticias',
            description: 'Esta opción habilita la eliminación de las noticias en el sistema',
            label: 'Habilitar eliminación de noticias',
          },
          accessClinic: {
            title: 'Ver clínicas',
            description: 'Esta opción habilita la visualización de las clínicas en el sistema',
            label: 'Habilitar visualización de clínicas',
          },
          accessClinicCreator: {
            title: 'Crear clínicas',
            description: 'Esta opción habilita la creación de las clínicas en el sistema',
            label: 'Habilitar creación de clínicas',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opción habilita la edición de las clínicas en el sistema',
            label: 'Habilitar edición de clínicas',
          },
          accessClinicDestructor: {
            title: 'Eliminar clínicas',
            description: 'Esta opción habilita la eliminación de las clínicas en el sistema',
            label: 'Habilitar eliminación de clínicas',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opción habilita la visualización de los departamentos en el sistema',
            label: 'Habilitar visualización de departamentos',
          },
          accessDepartmentCreator: {
            title: 'Crear departamentos',
            description: 'Esta opción habilita la creación de los departamentos en el sistema',
            label: 'Habilitar creación de departamentos',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opción habilita la edición de los departamentos en el sistema',
            label: 'Habilitar edición de departamentos',
          },
          accessDepartmentDestructor: {
            title: 'Eliminar departamentos',
            description: 'Esta opción habilita la eliminación de los departamentos en el sistema',
            label: 'Habilitar eliminación de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opción habilita la visualización de los idiomas en el sistema',
            label: 'Habilitar visualización de idiomas',
          },
          accessLanguageCreator: {
            title: 'Crear idiomas',
            description: 'Esta opción habilita la creación de los idiomas en el sistema',
            label: 'Habilitar creación de idiomas',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opción habilita la edición de los idiomas en el sistema',
            label: 'Habilitar edición de idiomas',
          },
          accessLanguageDestructor: {
            title: 'Eliminar idiomas',
            description: 'Esta opción habilita la eliminación de los idiomas en el sistema',
            label: 'Habilitar eliminación de idiomas',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opción habilita la visualización de los países en el sistema',
            label: 'Habilitar visualización de países',
          },
          accessCountryCreator: {
            title: 'Crear países',
            description: 'Esta opción habilita la creación de los países en el sistema',
            label: 'Habilitar creación de países',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opción habilita la edición de los países en el sistema',
            label: 'Habilitar edición de países',
          },
          accessCountryDestructor: {
            title: 'Eliminar países',
            description: 'Esta opción habilita la eliminación de los países en el sistema',
            label: 'Habilitar eliminación de países',
          },
          accessSettings: {
            title: 'Ver configuración',
            description: 'Esta opción habilita la visualización de la configuración en el sistema',
            label: 'Habilitar visualización de configuración',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Ver especialistas',
            description: 'Esta opción habilita la visualización de los especialistas en el sistema',
            label: 'Habilitar visualización de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crear especialistas',
            description: 'Esta opción habilita la creación de los especialistas en el sistema',
            label: 'Habilitar creación de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opción habilita la edición de los especialistas en el sistema',
            label: 'Habilitar edición de especialistas',
          },
          accessSpecialistDestructor: {
            title: 'Eliminar especialistas',
            description: 'Esta opción habilita la eliminación de los especialistas en el sistema',
            label: 'Habilitar eliminación de especialistas',
          },
          commsChannels: {
            accordion: 'Canales de comunicación',
          },
          accessCommsChannel: {
            title: 'Ver canales de comunicación',
            description:
              'Esta opción habilita la visualización de los canales de comunicación en el sistema',
            label: 'Habilitar visualización de canales de comunicación',
          },
          accessCommsChannelCreator: {
            title: 'Crear canales de comunicación',
            description:
              'Esta opción habilita la creación de los canales de comunicación en el sistema',
            label: 'Habilitar creación de canales de comunicación',
          },
          accessCommsChannelEditor: {
            title: 'Editar canales de comunicación',
            description:
              'Esta opción habilita la edición de los canales de comunicación en el sistema',
            label: 'Habilitar edición de canales de comunicación',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminar canales de comunicación',
            description:
              'Esta opción habilita la eliminación de los canales de comunicación en el sistema',
            label: 'Habilitar eliminación de canales de comunicación',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'Esta opción habilita la visualización de los ovopoints en el sistema',
            label: 'Habilitar visualización de ovopoints',
          },
          accessOvopointEditor: {
            title: 'Editar ovopoints',
            description: 'Esta opción habilita la edición de los ovopoints en el sistema',
            label: 'Habilitar edición de ovopoints',
          },
          templates: {
            accordion: 'Plantillas',
          },
          accessTemplate: {
            title: 'Ver plantillas de WhatsApp',
            description: 'Esta opción habilita la visualización de las plantillas de WhatsApp',
            label: 'Habilitar visualización de plantillas',
          },
          accessTemplateCreator: {
            title: 'Crear plantillas de WhatsApp',
            description: 'Esta opción habilita la creación de las plantillas de WhatsApp',
            label: 'Habilitar creación de plantillas',
          },
          accessTemplateEditor: {
            title: 'Editar plantillas de WhatsApp',
            description: 'Esta opción habilita la edición de las plantillas de WhatsApp',
            label: 'Habilitar edición de plantillas',
          },
          accessTemplateDestructor: {
            title: 'Eliminar plantillas de WhatsApp',
            description: 'Esta opción habilita la eliminación de las plantillas de WhatsApp',
            label: 'Habilitar eliminación de plantillas',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Permiso a la aplicación',
            description: 'Esta opción habilita el acceso al módulo OVOCrm',
            label: 'Habilitar acceso',
          },
          tasks: {
            accordion: 'Tareas',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Esta opción habilita la visualización del calendario en el sistema',
            label: 'Habilitar visualización de calendario',
          },
          crmTask: {
            title: 'Tareas',
            description: 'Esta opción habilita la visualización de las tareas en el sistema',
            label: 'Habilitar visualización de tareas',
          },
          crmTaskCreator: {
            title: 'Crear tareas',
            description: 'Esta opción habilita la creación de las tareas en el sistema',
            label: 'Habilitar creación de tareas',
          },
          crmTaskEditor: {
            title: 'Editar tareas',
            description: 'Esta opción habilita la edición de las tareas en el sistema',
            label: 'Habilitar edición de tareas',
          },
          crmTaskDestructor: {
            title: 'Eliminar tareas',
            description: 'Esta opción habilita la eliminación de las tareas en el sistema',
            label: 'Habilitar eliminación de tareas',
          },
          crmTaskSeeAll: {
            title: 'Ver todas las tareas',
            description: 'Esta opción habilita la visualización de todas las tareas en el sistema',
            label: 'Habilitar visualización de todas las tareas',
          },
          crmTaskSeeCoworkers: {
            title: 'Ver tareas de compañeros',
            description:
              'Esta opción habilita la visualización de las tareas de los compañeros en el sistema',
            label: 'Habilitar visualización de tareas de compañeros',
          },
          crmTaskUpdateResponsible: {
            title: 'Actualizar el responsable asignado a una tarea',
            description: 'Esta opción habilita el cambio de responsable de una tarea',
            label: 'Habilitar edicion de responsable de tarea',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'Esta opción habilita la visualización de los leads en el sistema',
            label: 'Habilitar visualización de leads',
          },
          crmLeadCreator: {
            title: 'Crear leads',
            description: 'Esta opción habilita la creación de los leads en el sistema',
            label: 'Habilitar creación de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opción habilita la edición de los leads en el sistema',
            label: 'Habilitar edición de leads',
          },
          crmLeadDestructor: {
            title: 'Eliminar leads',
            description: 'Esta opción habilita la eliminación de los leads en el sistema',
            label: 'Habilitar eliminación de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos los leads',
            description: 'Esta opción habilita la visualización de todos los leads en el sistema',
            label: 'Habilitar visualización de todos los leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Ver leads de compañeros',
            description: 'Esta opción habilita la visualización de los leads de compañeros',
            label: 'Habilitar visualización de leads de compañeros',
          },
          crmLeadUpdateCampaign: {
            title: 'Actualizar campaña asignada a leads',
            description: 'Esta opción habilita el cambio de campaña de multiples leads',
            label: 'Habilitar edicion de campaña de leads',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes',
              ovobank: 'Habilitar visualización de donantes',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Crear pacientes',
              ovobank: 'Crear donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la creación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la creación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de pacientes',
              ovobank: 'Habilitar creación de donantes',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la edición de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la edición de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de pacientes',
              ovobank: 'Habilitar edición de donantes',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Eliminar pacientes',
              ovobank: 'Eliminar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la eliminación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la eliminación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de pacientes',
              ovobank: 'Habilitar eliminación de donantes',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos los pacientes',
              ovobank: 'Ver todos los donantes',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la visualización de todos los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de todos los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de todos los pacientes',
              ovobank: 'Habilitar visualización de todos los donantes',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Ver pacientes de compañeros',
              ovobank: 'Ver donantes de compañeros',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes de compañeros',
              ovobank: 'Esta opción habilita la visualización de los donantes de compañeros',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes de compañeros',
              ovobank: 'Habilitar visualización de donantes de compañeros',
            },
          },
          contacts: {
            accordion: 'Contactos',
          },
          crmContact: {
            title: 'Contactos',
            description: 'Esta opción habilita la visualización de los contactos en el sistema',
            label: 'Habilitar visualización de contactos',
          },
          crmContactCreator: {
            title: 'Crear contactos',
            description: 'Esta opción habilita la creación de los contactos en el sistema',
            label: 'Habilitar creación de contactos',
          },
          crmContactEditor: {
            title: 'Editar contactos',
            description: 'Esta opción habilita la edición de los contactos en el sistema',
            label: 'Habilitar edición de contactos',
          },
          crmContactDestructor: {
            title: 'Eliminar contactos',
            description: 'Esta opción habilita la eliminación de los contactos en el sistema',
            label: 'Habilitar eliminación de contactos',
          },
          activity: {
            accordion: 'Actividades',
          },
          crmActivity: {
            title: 'Actividades',
            description: 'Esta opción habilita la visualización de las actividades en el sistema',
            label: 'Habilitar visualización de actividades',
          },
          crmActivityCreator: {
            title: 'Crear actividades',
            description: 'Esta opción habilita la creación de las actividades en el sistema',
            label: 'Habilitar creación de actividades',
          },
          crmActivityEditor: {
            title: 'Editar actividades',
            description: 'Esta opción habilita la edición de las actividades en el sistema',
            label: 'Habilitar edición de actividades',
          },
          crmActivityDestructor: {
            title: 'Eliminar actividades',
            description: 'Esta opción habilita la eliminación de las actividades en el sistema',
            label: 'Habilitar eliminación de actividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas las actividades',
            description:
              'Esta opción habilita la visualización de todas las actividades en el sistema',
            label: 'Habilitar visualización de todas las actividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opción habilita la visualización de los resultados en el sistema',
            label: 'Habilitar visualización de resultados',
          },
          crmResultCreator: {
            title: 'Crear resultados',
            description: 'Esta opción habilita la creación de los resultados en el sistema',
            label: 'Habilitar creación de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opción habilita la edición de los resultados en el sistema',
            label: 'Habilitar edición de resultados',
          },
          crmResultDestructor: {
            title: 'Eliminar resultados',
            description: 'Esta opción habilita la eliminación de los resultados en el sistema',
            label: 'Habilitar eliminación de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar hitos de Call Center',
            description:
              'Esta opción habilita la edición de los hitos de Call Center en el sistema',
            label: 'Habilitar edición de hitos de Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar hitos de ATP Comercial',
            description:
              'Esta opción habilita la edición de los hitos de ATP Comercial en el sistema',
            label: 'Habilitar edición de hitos de ATP Comercial',
          },
          crmResultATPIEvent: {
            title: 'Editar hitos de ATP Internacional',
            description:
              'Esta opción habilita la edición de los hitos de ATP Internacional en el sistema',
            label: 'Habilitar edición de hitos de ATP Internacional',
          },
          crmResultReactivity: {
            title: 'Editar reactividad',
            description: 'Esta opción habilita la edición de la reactividad en el sistema',
            label: 'Habilitar edición de reactividad',
          },
          reasons: {
            accordion: 'Motivos',
          },
          crmReason: {
            title: 'Motivos',
            description: 'Esta opción habilita la visualización de los motivos en el sistema',
            label: 'Habilitar visualización de motivos',
          },
          crmReasonCreator: {
            title: 'Crear motivos',
            description: 'Esta opción habilita la creación de los motivos en el sistema',
            label: 'Habilitar creación de motivos',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opción habilita la edición de los motivos en el sistema',
            label: 'Habilitar edición de motivos',
          },
          crmReasonDestructor: {
            title: 'Eliminar motivos',
            description: 'Esta opción habilita la eliminación de los motivos en el sistema',
            label: 'Habilitar eliminación de motivos',
          },
          campaigns: {
            accordion: 'Campañas',
          },
          crmCampaign: {
            title: 'Campañas',
            description: 'Esta opción habilita la visualización de las campañas en el sistema',
            label: 'Habilitar visualización de campañas',
          },
          crmCampaignCreator: {
            title: 'Crear campañas',
            description: 'Esta opción habilita la creación de las campañas en el sistema',
            label: 'Habilitar creación de campañas',
          },
          crmCampaignEditor: {
            title: 'Editar campañas',
            description: 'Esta opción habilita la edición de las campañas en el sistema',
            label: 'Habilitar edición de campañas',
          },
          crmCampaignDestructor: {
            title: 'Eliminar campañas',
            description: 'Esta opción habilita la eliminación de las campañas en el sistema',
            label: 'Habilitar eliminación de campañas',
          },
          funnels: {
            accordion: 'Embudos',
          },
          crmFunnel: {
            title: 'Embudos',
            description: 'Esta opción habilita la visualización de los embudos en el sistema',
            label: 'Habilitar visualización de embudos',
          },
          crmFunnelCreator: {
            title: 'Crear embudos',
            description: 'Esta opción habilita la creación de los embudos en el sistema',
            label: 'Habilitar creación de embudos',
          },
          crmFunnelEditor: {
            title: 'Editar embudos',
            description: 'Esta opción habilita la edición de los embudos en el sistema',
            label: 'Habilitar edición de embudos',
          },
          crmFunnelDestructor: {
            title: 'Eliminar embudos',
            description: 'Esta opción habilita la eliminación de los embudos en el sistema',
            label: 'Habilitar eliminación de embudos',
          },
          channels: {
            accordion: 'Canales',
          },
          crmChannel: {
            title: 'Canales',
            description: 'Esta opción habilita la visualización de los canales en el sistema',
            label: 'Habilitar visualización de canales',
          },
          crmChannelCreator: {
            title: 'Crear canales',
            description: 'Esta opción habilita la creación de los canales en el sistema',
            label: 'Habilitar creación de canales',
          },
          crmChannelEditor: {
            title: 'Editar canales',
            description: 'Esta opción habilita la edición de los canales en el sistema',
            label: 'Habilitar edición de canales',
          },
          crmChannelDestructor: {
            title: 'Eliminar canales',
            description: 'Esta opción habilita la eliminación de los canales en el sistema',
            label: 'Habilitar eliminación de canales',
          },
          programs: {
            accordion: 'Tratamientos',
          },
          crmProgram: {
            title: 'Tratamientos',
            description:
              'Esta opción habilita la visualización de los tratamientos del catálogo en el sistema',
            label: 'Habilitar visualización de tratamientos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crear tratamientos',
            description: 'Esta opción habilita la creación de los tratamientos en el sistema',
            label: 'Habilitar creación de tratamientos',
          },
          crmProgramEditor: {
            title: 'Editar tratamientos',
            description: 'Esta opción habilita la edición de los tratamientos en el sistema',
            label: 'Habilitar edición de tratamientos',
          },
          crmProgramDestructor: {
            title: 'Eliminar tratamientos',
            description: 'Esta opción habilita la eliminación de los tratamientos en el sistema',
            label: 'Habilitar eliminación de tratamientos',
          },
          services: {
            accordion: 'Servicios',
          },
          crmService: {
            title: 'Servicios',
            description: 'Esta opción habilita la visualización de los servicios en el sistema',
            label: 'Habilitar visualización de servicios',
          },
          crmServiceCreator: {
            title: 'Crear servicios',
            description: 'Esta opción habilita la creación de los servicios en el sistema',
            label: 'Habilitar creación de servicios',
          },
          crmServiceEditor: {
            title: 'Editar servicios',
            description: 'Esta opción habilita la edición de los servicios en el sistema',
            label: 'Habilitar edición de servicios',
          },
          crmServiceDestructor: {
            title: 'Eliminar servicios',
            description: 'Esta opción habilita la eliminación de los servicios en el sistema',
            label: 'Habilitar eliminación de servicios',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementarias',
            description:
              'Esta opción habilita la visualización de las técnicas complementarias en el sistema',
            label: 'Habilitar visualización de técnicas complementarias',
          },
          crmTechniqueCreator: {
            title: 'Crear técnicas complementarias',
            description:
              'Esta opción habilita la creación de las técnicas complementarias en el sistema',
            label: 'Habilitar creación de técnicas complementarias',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementarias',
            description:
              'Esta opción habilita la edición de las técnicas complementarias en el sistema',
            label: 'Habilitar edición de técnicas complementarias',
          },
          crmTechniqueDestructor: {
            title: 'Eliminar técnicas complementarias',
            description:
              'Esta opción habilita la eliminación de las técnicas complementarias en el sistema',
            label: 'Habilitar eliminación de técnicas complementarias',
          },
          maintenances: {
            accordion: 'Mantenimientos',
          },
          crmMaintenance: {
            title: 'Mantenimientos',
            description:
              'Esta opción habilita la visualización de los mantenimientos en el sistema',
            label: 'Habilitar visualización de mantenimientos',
          },
          crmMaintenanceCreator: {
            title: 'Crear mantenimientos',
            description: 'Esta opción habilita la creación de los mantenimientos en el sistema',
            label: 'Habilitar creación de mantenimientos',
          },
          crmMaintenanceEditor: {
            title: 'Editar mantenimientos',
            description: 'Esta opción habilita la edición de los mantenimientos en el sistema',
            label: 'Habilitar edición de mantenimientos',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminar mantenimientos',
            description: 'Esta opción habilita la eliminación de los mantenimientos en el sistema',
            label: 'Habilitar eliminación de mantenimientos',
          },
          treatments: {
            accordion: 'Tratamientos',
          },
          crmTreatment: {
            title: 'Tratamientos',
            description: 'Esta opción habilita la visualización de los tratamientos en el sistema',
            label: 'Habilitar visualización de tratamientos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crear tratamientos para paciente',
              ovobank: 'Crear tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la creación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la creación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de tratamientos para paciente',
              ovobank: 'Habilitar creación de tratamientos para donante',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamientos para paciente',
              ovobank: 'Editar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la edición de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la edición de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de tratamientos para paciente',
              ovobank: 'Habilitar edición de tratamientos para donante',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Eliminar tratamientos para paciente',
              ovobank: 'Eliminar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la eliminación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la eliminación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de tratamientos para paciente',
              ovobank: 'Habilitar eliminación de tratamientos para donante',
            },
          },
          phases: {
            accordion: 'Etapas',
          },
          crmPhase: {
            title: 'Etapas',
            description: 'Esta opción habilita la visualización de las etapas en el sistema',
            label: 'Habilitar visualización de etapas',
          },
          crmPhaseCreator: {
            title: 'Crear etapas',
            description: 'Esta opción habilita la creación de las etapas en el sistema',
            label: 'Habilitar creación de etapas',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opción habilita la edición de las etapas en el sistema',
            label: 'Habilitar edición de etapas',
          },
          crmPhaseDestructor: {
            title: 'Eliminar etapas',
            description: 'Esta opción habilita la eliminación de las etapas en el sistema',
            label: 'Habilitar eliminación de etapas',
          },
          causes: {
            accordion: 'Motivos',
          },
          crmCause: {
            title: 'Motivos de cancelación',
            description:
              'Esta opción habilita la visualización de los motivos de cancelación en el sistema',
            label: 'Habilitar visualización de motivos de cancelación',
          },
          crmCauseCreator: {
            title: 'Crear motivos de cancelación',
            description:
              'Esta opción habilita la creación de los motivos de cancelación en el sistema',
            label: 'Habilitar creación de motivos de cancelación',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelación',
            description:
              'Esta opción habilita la edición de los motivos de cancelación en el sistema',
            label: 'Habilitar edición de motivos de cancelación',
          },
          crmCauseDestructor: {
            title: 'Eliminar motivos de cancelación',
            description:
              'Esta opción habilita la eliminación de los motivos de cancelación en el sistema',
            label: 'Habilitar eliminación de motivos de cancelación',
          },
          cycles: {
            accordion: 'Ciclos de tratamiento',
          },
          crmCycle: {
            title: 'Ciclos de tratamiento',
            description:
              'Esta opción habilita la visualización de los ciclos de tratamiento en el sistema',
            label: 'Habilitar visualización de ciclos de tratamiento',
          },
          crmCycleCreator: {
            title: 'Crear ciclos de tratamiento',
            description:
              'Esta opción habilita la creación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar creación de ciclos de tratamiento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamiento',
            description:
              'Esta opción habilita la edición de los ciclos de tratamiento en el sistema',
            label: 'Habilitar edición de ciclos de tratamiento',
          },
          crmCycleDestructor: {
            title: 'Eliminar ciclos de tratamiento',
            description:
              'Esta opción habilita la eliminación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar eliminación de ciclos de tratamiento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'Esta opción habilita el acceso al módulo OVOClinic',
            label: 'Habilitar acceso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opción habilita el acceso al módulo OVOBoard',
            label: 'Habilitar acceso',
          },
          boardLeadsResume: {
            title: 'Resumen de leads',
            label: 'Habilitar resumen de leads',
          },
          boardSaleResume: {
            title: 'Resumen de ventas',
            label: 'Habilitar resumen de ventas',
          },
          boardSaleFunnel: {
            title: 'Embudo de ventas',
            label: 'Habilitar embudo de ventas',
          },
          boardExpectedIntervention: {
            title: 'Previsión de tratamientos',
            label: 'Habilitar previsión de tratamientos',
          },
          boardCoworkerPerformance: {
            title: 'Rendimiento de empleados',
            label: 'Habilitar rendimiento de empleados',
          },
          boardMarketing: {
            title: 'Marketing',
            label: 'Habilitar marketing',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'Esta opción habilita el acceso al módulo OVOTory',
            label: 'Habilitar acceso',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'Esta opción habilita el acceso al módulo OVOProfile',
            label: 'Habilitar acceso',
          },
          status: {
            title: 'Rol activo',
            description: 'Habilitar o deshabilitar el rol en el sistema',
            label: 'Rol activo',
          },
          submit: 'Actualizar rol',
        },
      },
      delete: {
        title: 'Eliminar rol',
        subtitle: 'Esta acción es irreversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este rol',
            label: 'Sí, deseo eliminar este rol',
          },
          submit: 'Eliminar rol',
        },
      },
      enable: {
        title: 'Habilitar rol',
        subtitle: 'Esta acción permite que el rol esté activo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarlo?',
            description: 'Esta acción permitirá que el rol esté activo en el sistema',
            label: 'Sí, deseo habilitar este rol',
          },
          submit: 'Habilitar rol',
        },
      },
      disable: {
        title: 'Deshabilitar rol',
        subtitle: 'Esta acción deshabilita el rol en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description: 'Esta acción deshabilitará el rol en el sistema',
            label: 'Sí, deseo deshabilitar este rol',
          },
          submit: 'Deshabilitar rol',
        },
      },
    },
    clinics: {
      list: {
        title: 'Clínicas',
        description: 'Gestión de las clínicas',
        create: 'Clínica',
        one: 'Clínica',
        many: 'Clínicas',
        columns: {
          name: 'Nombre',
          city: 'Ciudad',
          document: 'CIF',
        },
      },
      create: {
        title: 'Nueva clínica',
        subtitle: 'Registro de una nueva clínica en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la clínica',
          },
          country: {
            title: 'País',
            description: 'País donde está ubicada clínica',
          },
          state: {
            title: 'Estado',
            description: 'Estado o provincia de la clínica',
          },
          city: {
            title: 'Ciudad',
            description: 'Ciudad donde está ubicada la clínica',
          },
          street: {
            title: 'Calle',
            description: 'Calle donde está ubicada la clínica',
          },
          zip: {
            title: 'Código postal',
            description: 'Código postal de la clínica',
          },
          timezone: {
            title: 'Zona horaria',
            description: 'Zona horaria de la clínica',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación de la clínica',
          },
          status: {
            title: 'Clínica activa',
            description: {
              ovoclinic:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los pacientes',
              ovobank:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los donantes',
            },
            label: 'Clínica activa',
          },
          avatar: {
            title: 'Avatar',
            description:
              'Imagen / Avatar asociada de la clínica. Puede ayudar a identificar la clínica',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF o documento de identificación de la clínica',
          },
          title: {
            title: 'Título',
            description: 'Título de la clínica',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción de la clínica',
          },
          color: {
            title: 'Color',
            description: 'Color asociado a la clínica en el sistema.',
          },
          submit: 'Crear clínica',
        },
      },
      update: {
        title: 'Actualizar clínica',
        subtitle: 'Modificación de los datos de una clínica en el sistema',
        tabs: {
          details: {
            title: 'Detalles',
            description:
              'Las clínicas son agrupaciones de primer nivel del sistema y sirven para agrupar la actividad de los usuarios. Cada clínica tiene una serie de departamentos y usuarios asociados.',
          },
          departments: {
            title: 'Departamentos',
            description:
              'El objetivo principal de los departamentos es dividir la actividad de la clínica para una mejor organización y control. Cada departamento tiene un responsable y un idioma asociado.',
            add: 'Añadir departamento',
          },
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la clínica',
          },
          country: {
            title: 'País',
            description: 'País donde está ubicada clínica',
          },
          state: {
            title: 'Estado',
            description: 'Estado o provincia de la clínica',
          },
          city: {
            title: 'Ciudad',
            description: 'Ciudad donde está ubicada la clínica',
          },
          street: {
            title: 'Calle',
            description: 'Calle donde está ubicada la clínica',
          },
          zip: {
            title: 'Código postal',
            description: 'Código postal de la clínica',
          },
          timezone: {
            title: 'Zona horaria',
            description: 'Zona horaria de la clínica',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación de la clínica',
          },
          status: {
            title: {
              ovoclinic: 'Clínica activa',
              ovobank: 'Clínica activa',
            },
            description: {
              ovoclinic:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los pacientes',
              ovobank:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los donantes',
            },
            label: {
              ovoclinic: 'Clínica activa',
              ovobank: 'Clínica activa',
            },
          },
          avatar: {
            title: 'Avatar',
            description:
              'Imagen / Avatar asociada de la clínica. Puede ayudar a identificar la clínica',
          },
          document: {
            title: 'Documento',
            description: 'CIF, NIF o documento de identificación de la clínica',
          },
          title: {
            title: 'Título',
            description: 'Título de la clínica',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción de la clínica',
          },
          color: {
            title: 'Color',
            description: 'Color asociado a la clínica en el sistema',
          },
          submit: 'Actualizar clínica',
        },
      },
      disable: {
        title: 'Deshabilitar clínica',
        subtitle: 'Deshabilitar clínica en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta clinica?',
            description:
              'Deshabilitar una clínica significa que no podrá ser utilizada en el sistema',
            label: 'Sí, deseo deshabilitar esta clínica',
          },
          submit: 'Deshabilitar clínica',
        },
      },
      enable: {
        title: 'Habilitar clínica',
        subtitle: 'Habilitar clínica en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta clinica?',
            description: 'Habilitar una clínica significa que podrá ser utilizada en el sistema',
            label: 'Sí, deseo habilitar esta clínica',
          },
          submit: 'Habilitar clínica',
        },
      },
      delete: {
        title: 'Deshabilitar clínica',
        subtitle: 'Deshabilitar clínica en el sistema',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta clinica?',
            description:
              'Deshabilitar una clínica significa que no podrá ser utilizada en el sistema',
            label: 'Sí, deseo deshabilitar esta clínica',
          },
          submit: 'Deshabilitar clínica',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar la clínica, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
    },
    licenses: {
      list: {
        one: 'Licencia',
        many: 'Licencias',
      },
    },
    movements: {
      list: {
        one: 'Movimiento',
        many: 'Movimientos',
      },
    },
    attempts: {
      list: {
        one: 'Intento',
        many: 'Intentos',
      },
    },
    contracts: {
      list: {
        one: 'Contrato',
        many: 'Contratos',
      },
    },
    timetables: {
      list: {
        title: 'Horarios',
        description: 'Gestión de los horarios de los usuarios',
        create: 'Horario',
        one: 'Horario',
        many: 'Horarios',
        columns: {
          name: 'Nombre',
          day: 'Día',
          range: 'Rango',
        },
      },
      create: {
        title: 'Nuevo horario',
        subtitle: 'Registro de un nuevo horario en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del horario',
          },
          day: {
            title: 'Día',
            description: 'Día de la semana en el que se aplica el horario',
          },
          started: {
            title: 'Inicio',
            description: 'Hora de inicio del horario',
          },
          ended: {
            title: 'Fin',
            description: 'Hora de fin del horario',
          },
          submit: 'Crear horario',
        },
      },
      update: {
        title: 'Actualizar horario',
        subtitle: 'Modificación de los datos de un horario en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del horario',
          },
          submit: 'Guardar horario',
        },
        tabs: {
          timetable: {
            title: 'Horario',
            description: 'Detalles del horario',
          },
          timetableDay: {
            title: 'Día',
            description: 'Días de la semana en el que se aplica el horario',
          },
        },
        timetableDays: {
          create: {
            title: 'Añadir día',
            subtitle: 'Añadir día al horario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que se aplica el horario',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio del horario',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin del horario',
              },
              submit: 'Añadir día',
            },
          },
          update: {
            title: 'Actualizar día',
            subtitle: 'Modificación de los datos de un día en el horario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que se aplica el horario',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio del horario',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin del horario',
              },
              submit: 'Actualizar día',
            },
          },
          delete: {
            title: 'Eliminar día',
            subtitle: 'Eliminar día del horario',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminar este día?',
                label: 'Sí, deseo eliminar este día',
              },
              submit: 'Eliminar día',
            },
          },
          add: 'Añadir día',
        },
      },
      delete: {
        title: 'Eliminar horario',
        subtitle: 'Deshabilitar horario en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este horario?',
            description:
              'Deshabilitar un horario significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este horario',
          },
          submit: 'Deshabilitar horario',
        },
      },
      disable: {
        title: 'Deshabilitar horario',
        subtitle: 'Deshabilitar horario en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este horario?',
            description:
              'Deshabilitar un horario significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este horario',
          },
          submit: 'Deshabilitar horario',
        },
      },
      enable: {
        title: 'Habilitar horario',
        subtitle: 'Habilitar horario en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este horario?',
            description: 'Habilitar un horario significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este horario',
          },
          submit: 'Habilitar horario',
        },
      },
    },
    departments: {
      list: {
        title: 'Departamentos',
        description: 'Gestión de los departamentos',
        create: 'Departamento',
        one: 'Departamento',
        many: 'Departamentos',
        columns: {
          name: 'Nombre',
          responsible: 'Responsable',
          language: 'Idioma',
          country: 'País',
          clinic: 'Clínica',
          created: 'Creación',
        },
      },
      create: {
        title: 'Nuevo departamento',
        subtitle: 'Registro de un nuevo departamento en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del departamento',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el departamento',
          },
          status: {
            title: 'Departamento activo',
            description: 'Habilitar o deshabilitar el departamento en el sistema',
            label: 'Departamento activo',
          },
          languageId: {
            title: 'Idioma',
            description: 'Idioma del departamento',
          },
          responsibleId: {
            title: 'Responsable',
            description: 'Usuario responsable del departamento',
          },
          submit: 'Crear',
        },
      },
      enable: {
        title: 'Habilitar departamento',
        subtitle: 'Habilitar departamento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este departamento?',
            label: 'Sí, deseo habilitar este departamento',
          },
          submit: 'Habilitar departamento',
        },
      },
      disable: {
        title: 'Deshabilitar departamento',
        subtitle: 'Deshabilitar departamento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este departamento?',
            label: 'Sí, deseo deshabilitar este departamento',
          },
          submit: 'Deshabilitar departamento',
        },
      },
      update: {
        title: 'Actualizar departamento',
        subtitle: 'Modificación de los datos de un departamento en el sistema',
        department: {
          tab: 'Departamento',
          description: 'Detalles del departamento',
        },
        clinic: {
          tab: 'Clínica',
          description: 'Clínica a la que pertenece el departamento',
        },
        responsible: {
          tab: 'Responsable',
          description: 'Responsable del departamento',
        },
        language: {
          tab: 'Idioma',
          description: 'Idioma del departamento',
        },
        tabs: {
          department: {
            title: 'Departamento',
            description: 'Detalles del departamento',
          },
          members: {
            title: 'Miembros',
            description:
              'Miembros del departamento. Un miembro es un usuario que pertenece a un departamento. Mediante esta información el sistema reconoce el alcance del trabajador a nivel de idioma.',
          },
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del departamento',
          },
          languageId: {
            title: 'Idioma',
            description: 'Idioma del departamento',
          },
          responsibleId: {
            title: 'Responsable',
            description: 'Usuario responsable del departamento',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el departamento',
          },
          status: {
            title: 'Departamento activo',
            description: 'Habilitar o deshabilitar el departamento en el sistema',
            label: 'Departamento activo',
          },
          submit: 'Actualizar departamento',
        },
        members: {
          one: 'Miembro',
          many: 'Miembros',
          add: 'Añadir miembro',
          create: {
            title: 'Añadir miembro',
            subtitle:
              'Un miembro es un usuario que pertenece a un departamento. Mediante esta información el sistema reconoce el alcance del trabajador a nivel de idioma.',
            form: {
              contractId: {
                title: 'Usuario',
              },
              submit: 'Añadir miembro',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Eliminar miembro',
        subtitle: 'Eliminar miembro del departamento. Esta acción es irreversible',
        form: {
          submit: 'Eliminar miembro',
        },
      },
    },
    countries: {
      list: {
        title: 'Países',
        description: 'Gestión de los países',
        create: 'País',
        one: 'País',
        many: 'Países',
        columns: {
          name: 'Nombre',
          code: 'Código',
          coin: 'Moneda',
          updated: 'Actualización',
          created: 'Creación',
        },
      },
      update: {
        title: 'Editar país',
        subtitle: 'Editar país en el sistema',
        form: {
          countryNameEn: {
            title: 'Nombre en inglés',
            description: 'Nombre en inglés del país',
          },
          countryNameEs: {
            title: 'Nombre en español',
            description: 'Nombre en español del país',
          },
          flag: {
            title: 'Bandera',
            description: 'Bandera del país representada con un emoticono',
          },
          coin: {
            title: 'Moneda',
            description: 'Moneda utilizada en el país',
          },
          code: {
            title: 'Código de país',
            description: 'Código de país representado en 2 carácteres (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Prefijo telefónico del país',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del país',
          },
          geometry: {
            title: 'Geometría en formato json',
            description: 'Geometría del país en formato json',
          },
          latitude: {
            title: 'Coordenada latitud',
            description: 'Coordenada latitud del país',
          },
          longitude: {
            title: 'Coordenada longitud',
            description: 'Coordenada longitud del país',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción del país',
          },
          color: {
            title: 'Color formato #ffffff, red, rgb(0,0,0)',
            description: 'Color del país en formato hexadecimal, rgb o nombre en inglés',
          },
          submit: 'Guardar País',
        },
      },
      delete: {
        title: 'Eliminar país',
        subtitle: 'Deshabilitar país en el sistema',
        form: {
          submit: 'Borrar país',
        },
      },
      disable: {
        title: 'Deshabilitar país',
        subtitle: 'Deshabilitar país en el sistema',
        form: {
          submit: 'Deshabilitar país',
        },
      },
      enable: {
        title: 'Habilitar país',
        subtitle: 'Habilitar país en el sistema',
        form: {
          submit: 'Habilitar país',
        },
      },
      create: {
        title: 'Nuevo país',
        subtitle: 'Registro de un nuevo país en el sistema',
        form: {
          countryNameEn: {
            title: 'Nombre en inglés',
            description: 'Nombre en inglés del país',
          },
          countryNameEs: {
            title: 'Nombre en español',
            description: 'Nombre en español del país',
          },
          flag: {
            title: 'Bandera',
            description: 'Bandera del país representada con un emoticono',
          },
          coin: {
            title: 'Moneda',
            description: 'Moneda utilizada en el país',
          },
          code: {
            title: 'Código de país',
            description: 'Código de país representado en 2 carácteres (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Prefijo telefónico del país',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del país',
          },
          geometry: {
            title: 'Geometría en formato json',
            description: 'Geometría del país en formato json',
          },
          latitude: {
            title: 'Coordenada latitud',
            description: 'Coordenada latitud del país',
          },
          longitude: {
            title: 'Coordenada longitud',
            description: 'Coordenada longitud del país',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción del país',
          },
          color: {
            title: 'Color formato #ffffff, red, rgb(0,0,0)',
            description: 'Color del país en formato hexadecimal, rgb o nombre en inglés',
          },
          submit: 'Crear País',
        },
      },
    },
    access: {
      create: {
        title: 'Nuevo acceso',
        subtitle: 'Registro de un nuevo acceso en el sistema',
        form: {
          ip: {
            title: 'Dirección IP',
            description: 'Dirección IP del dispositivo que accede al sistema',
          },
          status: {
            title: 'Acceso al sistema',
            description:
              'Habilitar o deshabilitar el acceso al sistema para este usuario y esta ip',
            label: 'Permitir acceso al sistema',
          },
          submit: 'Crear acceso',
        },
      },
    },
    settings: {
      title: 'Configuración',
      subtitle: 'Configuración de la plataforma',
      form: {
        loginEnabled: {
          title: 'Habilitar acceso',
          description: 'Si se habilita el acceso, cualquier usuario podrá acceder al sistema',
          label: 'Permitir acceso',
        },
        attemptLimitEnabled: {
          title: 'Habilitar límite de intentos',
          description:
            'Si se habilita el límite de intentos, el usuario será bloqueado si falla el acceso más de del número de intentos permitidos para el usuario. Se puede configurar el número de intentos permitidos en la configuración de usuario.',
          label: 'Permitir límite de intentos',
        },
        movementLimitEnabled: {
          title: 'Habilitar límite de movimientos',
          description:
            'Si se habilita el límite de movimientos, el usuario será bloqueado si realiza más peticiones de los permitidos para el usuario. Se puede configurar el número de peticiones permitidos en la configuración de usuario.',
          label: 'Permitir límite de movimientos',
        },
        submit: 'Guardar configuración',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Actividades',
      contacts: 'Contactos',
      marketing: 'Marketing',
      sales: 'Ventas',
      directory: 'Directorio',
      customers: 'Clientes',
      commercial: 'Comercial',
      campaigns: 'Campañas',
      funnels: 'Embudos',
      channels: 'Canales',
      leads: 'Leads',
      transferLead: 'Transferencia de leads',
      patients: {
        ovoclinic: 'Pacientes',
        ovobank: 'Donantes',
      },
      programs: 'Programas',
      maintenances: 'Mantenimientos',
      tasks: 'Tareas',
      transferTask: 'Transferencia de tareas',
      createTaskMassively: 'Crear tareas masivamente',
      actions: 'Calendario',
      origins: 'Orígenes',
      techniques: 'Técnicas',
      services: 'Servicios',
      activities: 'Actividades',
      results: 'Resultados',
      reasons: 'Motivos',
      products: 'Productos',
      categories: 'Categorías',
      dashboard: 'Dashboard',
      catalog: 'Catálogo',
      phases: 'Etapas',
      causes: 'Causas',
      treatments: 'Tratamientos',
      cycles: 'Ciclos',
    },
    faq: {
      title: 'FAQ',
      description: 'Preguntas frecuentes',

      list: {
        1: {
          question: '¿Cómo puedo crear un lead?',
          answer: 'Para crear un lead, sigue los siguientes pasos:',
        },
        2: {
          question: '¿Cómo puedo crear una campaña?',
          answer: 'Para crear una campaña, sigue los siguientes pasos:',
        },
        3: {
          question: '¿Cómo puedo crear una tarea?',
          answer: 'Para crear una tarea, sigue los siguientes pasos:',
        },
        4: {
          question: '¿Cómo puedo transferir un lead?',
          answer: 'Para transferir un lead, sigue los siguientes pasos:',
        },
      },
    },
    information: {
      list: {
        0: {
          title: 'Flujo de trabajo del departamento NACIONAL',
          sublists: {
            0: {
              title: 'Call Center',
            },
            1: {
              title: 'Atención al paciente Comercial',
            },
            2: {
              title: 'Atención al paciente Médico',
            },
          },
        },
        1: {
          title: 'Flujo de trabajo del departamento INTERNACIONAL',
        },
      },
    },
    dashboard: {
      title: 'Dashboard',
      description: 'Panel de control de tu actividad',
      actions: {
        title: 'Tareas',
        description: {
          ovoclinic:
            'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/pacientes',
          ovobank:
            'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/donantes',
        },
      },
      receipts: {
        title: 'Últimos recibos',
        description: 'Pagos realizados a tus leads',
        list: {
          columns: {
            contactName: 'Nombre',
            payments: 'Pagos',
            updated: 'F. actualización',
          },
          many: 'Recibos',
          one: 'Recibo',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Leads',
          ovobank: 'Donantes',
        },
        description: {
          ovoclinic: 'Gestión de tus leads/pacientes',
          ovobank: 'Gestión de tus leads/donantes',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campañas',
          ovobank: 'Campañas',
        },
        description: {
          ovoclinic: 'Gestión de tus campañas',
          ovobank: 'Gestión de tus campañas',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Transferencia de leads',
        description: 'Transferir campañas de los leads',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        required: 'Debes seleccionar al menos un lead',
        columns: {
          name: 'Nombre',
          activity: 'Actividad',
          responsible: 'Responsable',
          campaignName: 'Campaña',
          started: 'Fecha estimada',
          clinicName: 'Clínica',
          created: 'Creación',
          resultId: 'Progreso',
          phones: 'Teléfono',
          emails: 'Email',
        },
      },
      confirm: {
        title: 'Transferir leads',
        subtitle: 'Reasignar campaña a multiples leads',
        success: 'Leads transferidos correctamente',
        steps: {
          campaign: {
            form: 'Campaña seleccionada',
          },
        },
        form: {
          campaignId: {
            title: 'Seleccionar camapaña',
            description: 'Campaña a la que se van a reasignar las leads',
          },
          responsibleId: {
            title: 'Seleccionar responsable',
            description: 'Responsable a la que van a reasignar las leads al cambiar de campaña',
          },
          submit: 'Transferir',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Transferencia de tareas',
          ovobank: 'Transferencia de tareas',
        },
        description: {
          ovoclinic:
            'Transferir tareas a otros usuarios. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/pacientes',
          ovobank:
            'Transferir tareas a otros usuarios. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/donantes',
        },
        create: 'Tarea',
        one: 'Tarea',
        many: 'Tareas',
        columns: {
          activity: 'Actividad',
          phone: 'Teléfono',
          contact: 'Contacto',
          subject: 'Asunto',
          resultId: 'Progreso',
          campaign: 'Campaña',
          clinic: 'Clínica',
          contactTime: 'Preferencia horaria',
          created: 'Creación',
          user: 'Responsable',
          started: 'Fecha de inicio estimada',
          finished: 'Duración',
        },
      },
      confirm: {
        title: 'Transferir tareas',
        subtitle: 'Reasignar responsable a multiples tareas',
        success: 'Tareas transferidas correctamente',
        form: {
          responsibleId: {
            title: 'Seleccionar responsable',
            description: 'Persona a la que van a reasignar las tareas',
          },
          submit: 'Transferir',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Crear tareas masivamente',
        description: 'Esta pantalla permite crear un tipo de actividad para múltiples leads',
        columns: {
          name: 'Nombre',
          clinicName: 'Clínica',
          campaignName: 'Campaña',
          phones: 'Teléfono',
          emails: 'Email',
          created: 'Creación',
          lastAction: 'Última tarea',
          subject: 'Asunto',
          lastActionDate: 'Fecha tarea pendiente',
        },
        noItemSelected: 'No hay ningún lead seleccionado',
        one: 'Lead',
        many: 'Leads',
      },
      selectActivity: {
        title: 'Seleccionar actividad',
        subtitle: 'Selecciona la actividad que deseas crear para los leads seleccionados',
        form: {
          activityId: {
            title: 'Actividad',
            description: 'Actividad que deseas crear para los leads seleccionados',
            required: 'Debes seleccionar una actividad',
          },
          subject: {
            title: 'Asunto',
            description:
              'El asunto que se le pondrá a todas las tareas que se crearán para los leads seleccionados',
          },
          submit: 'Crear tareas',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Tareas',
          ovobank: 'Tareas',
        },
        description: {
          ovoclinic:
            'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/pacientes',
          ovobank:
            'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/donantes',
        },
        create: {
          ovoclinic: 'Tarea',
          ovobank: 'Tarea',
        },
        one: 'Tarea',
        many: 'Tareas',
        columns: {
          activity: 'Actividad',
          contact: 'Contacto',
          responsible: 'Responsable',
          subject: 'Asunto',
          resultId: 'Progreso',
          resultName: 'Resultado',
          campaign: 'Campaña',
          clinic: 'Clínica',
          contactTime: 'Preferencia horaria',
          created: 'Creación',
          user: 'Responsable',
          started: 'Fecha de inicio estimada',
          finished: 'Duración',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'Nueva acción',
              subtitle:
                'Registro de una nueva acción en el sistema. Elige la clínica a la que va dirigida la tarea',
              form: {
                clinicId: {
                  title: 'Clínica',
                  description: 'Clínica a la que va dirigida la acción',
                },
                submit: 'Siguiente',
              },
            },
            lead: {
              title: 'Nueva acción',
              subtitle:
                'Registro de una nueva acción en el sistema. Elige el lead al que va dirigida la tarea',
              form: {
                leadId: {
                  title: 'Lead',
                  description: 'Lead al que va dirigida la acción',
                },
                submit: 'Siguiente',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'Todo el día',
        previous: 'Anterior',
        next: 'Siguiente',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        agenda: 'Agenda',
        date: 'Fecha',
        time: 'Hora',
        event: 'Evento',
      },
    },
    channels: {
      list: {
        title: 'Canales',
        description: 'Gestión de los canales de comunicación',
        create: 'Canal',
        one: 'Canal',
        many: 'Canales',
        columns: {
          name: 'Nombre',
          funnels: 'Embudos',
        },
      },
      create: {
        title: 'Nuevo canal',
        subtitle: 'Registro de un nuevo canal en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del canal',
          },
          status: {
            title: 'Canal activo',
            description: 'Habilitar o deshabilitar el canal en el sistema',
            label: 'Canal activo',
          },
          submit: 'Crear canal',
        },
      },
      update: {
        title: 'Actualizar canal',
        subtitle: 'Modificación de los datos de un canal en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del canal',
          },
          status: {
            title: 'Canal activo',
            description: 'Habilitar o deshabilitar el canal en el sistema',
            label: 'Canal activo',
          },
          submit: 'Actualizar canal',
        },
      },
      disable: {
        title: 'Deshabilitar canal',
        subtitle: 'Deshabilitar canal en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este canal?',
            description: 'Deshabilitar un canal significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este canal',
          },
          submit: 'Deshabilitar canal',
        },
      },
      enable: {
        title: 'Habilitar canal',
        subtitle: 'Habilitar canal en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este canal?',
            description: 'Habilitar un canal significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este canal',
          },
          submit: 'Habilitar canal',
        },
      },
      delete: {
        title: 'Eliminar canal',
        subtitle: 'Esta acción es irreversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este canal',
            label: 'Sí, deseo eliminar este canal',
          },
          submit: 'Eliminar',
        },
      },
    },
    cycles: {
      list: {
        title: 'Ciclos',
        description: 'Gestión de los ciclos de tratamiento',
        create: 'Ciclo',
        one: 'Ciclo',
        many: 'Ciclos',
        columns: {
          name: 'Nombre',
          complexity: 'Complejidad',
          created: 'Fecha de creación',
          code: 'Código',
        },
      },
      create: {
        title: 'Nuevo ciclo',
        subtitle: 'Registro de un nuevo ciclo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del ciclo',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Complejidad del ciclo',
          },
          code: {
            title: 'Código',
            description: 'Código identificativo del ciclo',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción del ciclo',
          },
          submit: 'Crear ciclo',
        },
      },
      update: {
        title: 'Actualizar ciclo',
        subtitle: 'Modificación de los datos de un ciclo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del ciclo',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Complejidad del ciclo',
          },
          code: {
            title: 'Código',
            description: 'Código identificativo del ciclo',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción del ciclo',
          },
          submit: 'Actualizar ciclo',
        },
      },
      enable: {
        title: 'Habilitar ciclo',
        subtitle: 'Habilitar ciclo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este ciclo?',
            description: 'Habilitar un ciclo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este ciclo',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar ciclo',
        subtitle: 'Deshabilitar ciclo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este ciclo?',
            description: 'Deshabilitar un ciclo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este ciclo',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    funnels: {
      list: {
        title: 'Embudos',
        description:
          'Gestión de los embudos de conversión. Un embudo es una procedencia por el que puede venir un cliente potencial.',
        create: 'Embudo',
        one: 'Embudo',
        many: 'Embudos',
        params: {
          channelId: 'Embudos filtrados por canal de comunicación',
        },
        columns: {
          name: 'Nombre',
          channelName: 'Canal',
        },
      },
      create: {
        title: 'Nuevo embudo',
        subtitle: 'Registro de un nuevo embudo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del embudo',
          },
          channelId: {
            title: 'Canal',
            description: 'Canal de comunicación asociado al embudo',
          },
          status: {
            title: 'Embudo activo',
            description: 'Habilitar o deshabilitar el embudo en el sistema',
            label: 'Embudo activo',
          },
          submit: 'Crear embudo',
        },
      },
      update: {
        title: 'Actualizar embudo',
        subtitle: 'Modificación de los datos de un embudo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del embudo',
          },
          channelId: {
            title: 'Canal',
            description: 'Canal de comunicación asociado al embudo',
          },
          status: {
            title: 'Embudo activo',
            description: 'Habilitar o deshabilitar el embudo en el sistema',
            label: 'Embudo activo',
          },
          submit: 'Actualizar embudo',
        },
      },
      delete: {
        title: 'Eliminar embudo',
        subtitle: 'Esta acción es irreversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este embudo',
            label: 'Sí, deseo eliminar este embudo',
          },
          submit: 'Eliminar',
        },
      },
      enable: {
        title: 'Habilitar embudo',
        subtitle: 'Habilitar embudo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este embudo?',
            description: 'Habilitar un embudo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este embudo',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar embudo',
        subtitle: 'Deshabilitar embudo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este embudo?',
            description:
              'Deshabilitar un embudo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este embudo',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    leads: {
      import: {
        title: 'Importación de leads',
        subtitle: 'Importación de leads en el sistema',
        description:
          'Carga de leads en el sistema a través de un fichero csv. Una vez subido, podrás revisar el registro y descargar un archivo .csv con los errores detectados, si existen.',
        list: {
          columns: {
            name: 'Nombre',
            errors: 'Nº de errores',
            created: 'Fecha de importación',
          },
          one: 'Importación',
          many: 'Importaciones',
        },
        delete: {
          title: 'Eliminar registro de importación',
          subtitle: 'Eliminar registro de importación en el sistema',
          form: {
            submit: 'Eliminar',
          },
        },
      },
      list: {
        title: 'Leads',
        description: 'Gestión de clientes potenciales generados a través de campañas',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        columns: {
          name: 'Nombre',
          activity: 'Actividad',
          responsible: 'Responsable',
          campaignName: 'Campaña',
          started: 'Fecha estimada',
          clinicName: 'Clínica',
          created: 'Creación',
          resultId: 'Progreso',
          phones: 'Teléfono',
          emails: 'Email',
        },
        csv: {
          select: 'IMPORTAR .CSV',
          somethingwentwrong: 'Leads no cargados',
          wrongdata: 'el fichero es erróneo, o los datos no corresponden con el formato correcto',
          loaded: 'Leads cargados correctamente',
          downloadCsv:
            'Algunos leads no se cargaron, puedes descargar la lista de leads erróneos en el botón de descarga',
        },
      },
      create: {
        title: 'Nuevo lead',
        subtitle: 'Registro de un nuevo lead en el sistema',
        form: {
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar el lead.',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'La clínica de preferencia del lead. Esta preferencía hace que el seguimiento se haga por los empleados de la clínica seleccionada.',
          },
          contactId: {
            title: 'Contacto',
            description:
              'Contacto asociado al lead. Un contacto puede tener varios leads. Esto es debido a que un contacto puede ser un lead en varias campañas de marketing.',
          },
          campaignId: {
            title: 'Campaña',
            description: 'La campaña de marketing actual del lead.',
          },
          submit: 'Crear lead',
        },
      },
      update: {
        title: 'Actualizar lead',
        subtitle: 'Modificación de los datos de un lead en el sistema',
        disabledBanner: {
          title: 'Lead inactivo',
        },
        tabs: {
          lead: {
            title: 'Lead',
            description:
              'Los detalles del lead están orientados a entender el origen del lead y su seguimiento. Cabe destacar que un lead puede ser un paciente en el futuro y siempre está asociado a un contacto.',
          },
          actions: {
            title: 'Tareas',
            description:
              'Las tareas son las acciones que se realizan para convertir un lead en un donante. Cada tarea tiene un responsable y un resultado.',
          },
          responsible: {
            title: 'Responsable',
            description:
              'El responsable es el usuario que se encarga de realizar las tareas de seguimiento del lead.',
          },
          contact: {
            title: 'Contacto',
            description:
              'Un lead se origina a partir de un contacto. Un contacto puede tener varios leads. Esto es debido a que un contacto puede ser un lead en varias campañas de marketing. Un lead, por lo tanto, es un contacto asociado a una campaña y a una clínica.',
          },
          notes: {
            title: 'Observaciones',
            description:
              'Las observaciones son comentarios que se añaden a los leads para recordar detalles importantes o para compartir información con otros comerciales.',
          },
          specialist: {
            title: 'Especialista',
            description: 'El especialista asignado al lead',
          },
          communications: {
            title: 'Comunicaciones',
            description:
              'Integración con el sistema de mensajería para monitorizar las comunicaciones con los leads.',
          },
        },
        leadPassport: {
          birthCountry: 'País de nacimiento',
          birthDate: 'Fecha de nacimiento',
          height: 'Altura',
          weight: 'Peso',
          contactTime: 'Preferencia horaria',
          created: 'Fecha de creación',
          consent: {
            title: 'Consentimiento',
            type: {
              legal: 'Legal',
              marketing: 'Marketing',
            },
          },
        },
        form: {
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar el lead.',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'La clínica de preferencia del lead. Esta preferencía hace que el seguimiento se haga por los empleados de la clínica seleccionada.',
          },
          contactId: {
            title: 'Contacto',
            description:
              'Contacto asociado al lead. Un contacto puede tener varios leads. Esto es debido a que un contacto puede ser un lead en varias campañas de marketing.',
          },
          campaignId: {
            title: 'Campaña',
            description: 'La campaña de marketing actual del lead',
          },
          reasonId: {
            title: 'Motivo',
            description: 'Motivo',
          },
          submit: 'Actualizar lead',
        },
        lead: {
          tab: 'Lead',
          description: 'Detalles del lead',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsable',
              description: 'Usuario responsable del lead',
            },
            submit: 'Asignar responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description: 'Especialista asignado al lead',
            },
            submit: 'Asignar especialista',
          },
        },
        actions: {
          tab: 'Tareas',
          description: 'Tareas del lead',
          add: 'Añadir tarea',
          create: {
            title: 'Nueva tarea',
            subtitle: 'Registro de una nueva tarea en el sistema',
            confirmationMessage:
              'Tarea no programada. \n\nEl lead quedará sin una tarea asignada en la planificación actual. Salir sin programar la tarea puede afectar el seguimiento del lead.\n\n¿Estás seguro de que deseas salir sin planificar esta tarea?\n',
            form: {
              activityId: {
                title: 'Actividad',
                description: 'Actividad a realizar',
              },
              responsibleId: {
                title: 'Responsable',
                description: 'Encargado de realizar la actividad',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado de la actividad',
              },
              started: {
                title: 'Fecha estimada',
                description: 'Fecha en la que se espera que se realice la actividad',
              },
              subject: {
                title: 'Asunto',
                description: 'Asunto de la tarea',
              },
              observation: {
                title: 'Nota',
                description: 'Un pequeño comentario sobre la actividad',
              },
              submit: 'Crear tarea',
            },
            steps: {
              clinic: {
                form: 'Clínica seleccionada',
              },
              lead: {
                form: 'Lead seleccionado',
              },
            },
          },
          update: {
            title: 'Actualizar tarea',
            subtitle: 'Modificación de los datos de una tarea en el sistema',
            form: {
              activityId: {
                title: 'Actividad',
                description: 'Actividad a realizar',
              },
              responsibleId: {
                title: 'Responsable',
                description: 'Encargado de realizar la actividad',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado de la actividad',
              },
              started: {
                title: 'Fecha estimada',
                description: 'Fecha en la que se espera que se realice la actividad',
              },
              subject: {
                title: 'Asunto',
                description: 'Asunto de la tarea',
              },
              observation: {
                title: 'Nota',
                description: 'Un pequeño comentario sobre la actividad',
              },
              submit: 'Actualizar tarea',
            },
            tabs: {
              action: {
                title: 'Tarea',
                description: 'Información asociada a la tarea',
              },
              actionEnding: {
                title: 'Realización',
                description: 'Información asociada a la realización de la tarea',
              },
              alerts: {
                title: 'Alertas',
                description:
                  'Alertas asociadas a la tarea. Las alertas son recordatorios de la tarea. Generarán una notificación en el sistema para recordar la tarea.',
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Resultado',
                },
                result: {
                  title: 'Resultado',
                  description:
                    'Resultado de la actividad. Si se inserta un resultado, la tarea se considerará finalizada y no se podrá modificar más. Si no se inserta un resultado, la tarea se considerará pendiente y se podrá modificar más adelante.',
                },
                communicationChannel: {
                  title: 'Vía de comunicación',
                  description: 'Vía de comunicación utilizada para la actividad',
                },
                submit: 'Finalizar tarea',
                submitNextSuggestion: 'Finalizar',
              },
            },
            alert: {
              list: {
                title: 'Alertas',
                columns: {
                  expectedAlarmTime: 'Fecha de alarma',
                },
                many: 'Alertas',
                one: 'Alerta',
              },
              add: 'Añadir alerta',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observaciones',
              },
              contactNotes: {
                title: 'Observaciones',
              },
            },
            fields: {
              newLead: 'Nuevo lead',
              active: 'Lead activo',
              inactive: 'Lead inactivo',
              canceled: 'Lead cancelado',
              converted: 'Lead convertido',
              clinic: 'Clínica',
              contactTime: 'Preferencia horaria',
              responsible: 'Responsable',
              funnel: 'Embudo',
              consent: 'Consentimiento',
              campaign: 'Campaña',
              originCampaign: 'Campaña de origen',
              created: 'Fecha de creación',
              legal: 'Legal',
              marketing: 'Marketing',
              donationPreference: 'Ciudad',
              birthCountry: 'País de nacimiento',
              birthDate: 'Fecha de nacimiento',
              height: '(CM)',
              weight: '(KG)',
            },
          },
        },
      },
      delete: {
        title: 'Eliminar lead',
        subtitle: 'Esta acción es irreversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este lead',
            label: 'Sí, deseo eliminar este lead',
          },
          confirm: 'Esta acción eliminará todo lo relacionado con el lead, estás seguro?',
          submit: 'Eliminar',
        },
      },
      disable: {
        title: 'Deshabilitar lead',
        subtitle: 'Deshabilitar lead en el sistema',
        form: {
          reasonId: {
            title: 'Motivo',
            description: 'Motivo por el que se deshabilita el lead',
          },
          submit: 'Deshabilitar',
        },
        confirmationMessage:
          'Motivo sin adjuntar. ¿Estás seguro de que deseas deshabilitar este lead sin adjuntar un motivo?',
      },
      enable: {
        title: 'Habilitar lead',
        subtitle: 'Habilitar lead en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este lead?',
            description: 'Habilitar un lead significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este lead',
          },
          submit: 'Habilitar',
        },
      },
      addSpecialist: {
        title: 'Asignar especialista',
        subtitle: 'Asignar especialista al lead',
        form: {
          specialistId: {
            title: 'Especialista',
            description: 'Especialista asignado al lead',
          },
          submit: 'Asignar especialista',
        },
      },
    },
    reasons: {
      list: {
        title: 'Motivos',
        description:
          'Gestión de motivos por el que un cliente potencial puede darse de baja o se le considera no válido',
        create: 'Motivo',
        one: 'Motivo',
        many: 'Motivos',
        columns: {
          name: 'Titulo',
        },
      },
      create: {
        title: 'Nuevo motivo',
        subtitle: 'Registro de un nuevo motivo en el sistema',
        form: {
          name: {
            title: 'Título',
            description: 'Título identificativo del motivo',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción detallada de la cancelación del lead',
          },
          color: {
            title: 'Color',
            description: 'Color asociado al motivo',
          },
          notify: {
            title: 'Notificar',
            description: 'Notificar o no el motivo en el sistema',
            label: 'Notificar motivo',
          },
          icon: {
            title: 'Icono',
            description: 'Icono asociado al motivo',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          canceled: {
            title: 'Baja',
            description:
              'Seleccionando esta opción, se indica que este motivo atribuye el estado de baja definitiva. Este campo sirve para el posterior análisis de la efectividad',
            label: 'Motivo de baja',
          },
          subject: {
            title: 'Asunto',
            description: 'Asunto del motivo',
          },
          message: {
            title: 'Mensaje',
            description: 'Mensaje del motivo',
          },
          submit: 'Crear motivo',
        },
      },
      enable: {
        title: 'Habilitar motivo',
        subtitle: 'Habilitar motivo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este motivo?',
            description: 'Habilitar un motivo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este motivo',
          },
          submit: 'Habilitar motivo',
        },
      },
      disable: {
        title: 'Deshabilitar motivo',
        subtitle: 'Deshabilitar motivo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este motivo?',
            description:
              'Deshabilitar un motivo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este motivo',
          },
          submit: 'Deshabilitar motivo',
        },
      },
      update: {
        title: 'Actualizar motivo',
        subtitle: 'Modificación de los datos de un motivo en el sistema',
        tabs: {
          reason: {
            title: 'Motivo',
            description: 'Información del motivo',
          },
          notification: {
            title: 'Configurar notificación',
            description:
              'Configuración de la notificación que se va a enviar cuando se produce un cancelamiento con este motivo en particular',
          },
          activity: {
            title: 'Siguiente actividad',
            description:
              'Si se configura esta sección del motivo, una vez se deshabilite el Lead con este motivo, se sugerirá una actividad a realizar. Además, el Lead será reactivado automáticamente una vez llegue el día de la actividad.',
          },
        },
        reason: {
          form: {
            name: {
              title: 'Título',
              description: 'Título identificativo del motivo',
            },
            description: {
              title: 'Descripción',
              description: 'Descripción detallada de la cancelación del lead',
            },
            color: {
              title: 'Color',
              description: 'Color asociado al motivo',
            },
            notify: {
              title: 'Notificar',
              description: 'Notificar o no el motivo en el sistema',
              label: 'Notificar motivo',
            },
            icon: {
              title: 'Icono',
              description: 'Icono asociado al motivo',
              label: 'Seleccionar icono...',
              search: {
                placeholder: 'Buscar icono...',
              },
            },
            canceled: {
              title: 'Baja',
              description:
                'Seleccionando esta opción, se indica que este motivo atribuye el estado de baja definitiva. Este campo sirve para el posterior análisis de la efectividad',
              label: 'Motivo de baja',
            },
            subject: {
              title: 'Asunto',
              description: 'Asunto del motivo',
            },
            message: {
              title: 'Mensaje',
              description: 'Mensaje del motivo',
            },
            submit: 'Actualizar motivo',
          },
          notification: {
            form: {
              notify: {
                title: 'Activar notificación',
                description: 'Notificar o no cuando se produce un cancelamiento con este motivo',
                label: 'Activar sistema de notificación',
              },
              subject: {
                title: 'Asunto',
                description: 'Asunto de la notificación',
              },
              message: {
                title: 'Mensaje',
                description: 'Mensaje que se mostrará en la notificación',
              },
              submit: 'Guardar configuración',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Siguiente actividad',
              description: 'Actividad sugerida a realizar cuando se produce un cancelamiento',
            },
            nextActivityDelay: {
              title: 'Tiempo de espera',
              description: 'Tiempo de espera para realizar la actividad sugerida, en días',
            },
            submit: 'Guardar',
          },
        },
      },
    },
    patients: {
      list: {
        title: {
          ovoclinic: 'Pacientes',
          ovobank: 'Donantes',
        },
        description: {
          ovoclinic: 'Gestión de los pacientes en el sistema',
          ovobank: 'Gestión de los donantes en el sistema',
        },
        create: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        one: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        many: {
          ovoclinic: 'Pacientes',
          ovobank: 'Donantes',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contacto',
              ovobank: 'Contacto',
            },
          },
          description: {
            ovoclinic: 'Descripción',
            ovobank: 'Descripción',
          },
          phones: {
            ovoclinic: 'Teléfono',
            ovobank: 'Teléfono',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'Clínica del ciclo',
              ovobank: 'Clínica del ciclo',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clínica',
              ovobank: 'Clínica',
            },
          },
          created: {
            ovoclinic: 'Creación',
            ovobank: 'Creación',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'Nuevo paciente',
          ovobank: 'Nuevo donante',
        },
        subtitle: {
          ovoclinic: 'Registro de un nuevo paciente en el sistema',
          ovobank: 'Registro de un nuevo donante en el sistema',
        },
        form: {
          description: {
            title: {
              ovobank: 'Descripción',
              ovoclinic: 'Descripción',
            },
            description: {
              ovoclinic: 'Una breve descripción para detallar el paciente.',
              ovobank: 'Una breve descripción para detallar el donante.',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead asociado al paciente',
              ovobank: 'Lead asociado al donante',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'La clínica de preferencia del paciente.',
              ovobank: 'La clínica de preferencia del donante.',
            },
          },
          campaignId: {
            title: 'Campaña',
            description: {
              ovoclinic:
                'La campaña de marketing de origen del paciente. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
              ovobank:
                'La campaña de marketing de origen del donante. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
            },
          },
          contactId: {
            title: 'Contacto',
            description: {
              ovoclinic: 'El contacto por el cual se ha llegado a convertir en un paciente.',
              ovobank: 'El contacto por el cual se ha llegado a convertir en un donante.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Acuerdo de garantía',
              ovobank: 'Acuerdo de garantía',
            },
            description: {
              ovoclinic: 'El paciente acepta el acuerdo de garantía',
              ovobank: 'El donante acepta el acuerdo de garantía',
            },
            label: {
              ovoclinic: 'Sí, acepto el acuerdo de garantía',
              ovobank: 'Sí, acepto el acuerdo de garantía',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimiento',
              ovobank: 'Consentimiento',
            },
            description: {
              ovoclinic: 'El paciente da su consentimiento para ser tratado',
              ovobank: 'El donante da su consentimiento para ser tratado',
            },
            label: {
              ovoclinic: 'Sí, doy mi consentimiento',
              ovobank: 'Sí, doy mi consentimiento',
            },
          },
          submit: {
            ovoclinic: 'Crear paciente',
            ovobank: 'Crear donante',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Etapa',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Etapa',
                description: 'Etapa en la que se encuentra el tratamiento',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Programa',
                description: 'Nombre del programa',
              },
              phaseId: {
                title: 'Etapa',
                description: 'Etapa en la que se encuentra el tratamiento',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Actualizar paciente',
          ovobank: 'Actualizar donante',
        },
        subtitle: {
          ovoclinic: 'Modificación de los datos de un paciente en el sistema',
          ovobank: 'Modificación de los datos de un donante en el sistema',
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Paciente',
              ovobank: 'Donante',
            },
            description: {
              ovoclinic: 'Detalles del paciente',
              ovobank: 'Detalles del donante',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contacto',
              ovobank: 'Contacto',
            },
            description: {
              ovoclinic: 'Detalles del contacto del paciente',
              ovobank: 'Detalles del contacto del donante',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsable',
              ovobank: 'Responsable',
            },
            description: {
              ovoclinic: 'Responsable del paciente',
              ovobank: 'Responsable del donante',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Especialista',
              ovobank: 'Especialista',
            },
            description: {
              ovoclinic: 'Especialista asignado al paciente',
              ovobank: 'Especialista asignado al donante',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Recibos',
              ovobank: 'Recibos',
            },
            description: 'Lista de recibos asociados al cliente',
            list: {
              create: 'Recibo',
            },
            many: 'Recibos',
            one: 'Recibo',
            columns: {
              receipt: {
                name: 'Nombre',
                clinic: 'Clínica',
                created: 'F. creación',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Crear pago',
                  },
                  many: 'Pagos',
                  one: 'Pago',
                  columns: {
                    payment: {
                      price: 'Precio',
                      clinic: 'Clínica',
                      name: 'Nombre',
                      created: 'F. creación',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Contrataciones',
              ovobank: 'Contrataciones',
            },
            description: {
              ovoclinic: 'Contrataciones del paciente',
              ovobank: 'Contrataciones del donante',
            },
            one: 'Contratación',
            many: 'Contrataciones',
            columns: {
              price: 'Precio',
              purchaseType: 'Tipo',
              treatmentName: 'Nombre',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Observaciones',
              ovobank: 'Observaciones',
            },
            description: {
              ovoclinic: 'Observaciones del paciente',
              ovobank: 'Observaciones del donante',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documentos',
              ovobank: 'Documentos',
            },
            description: {
              ovoclinic: 'Documentos subidos del paciente',
              ovobank: 'Documentos subidos del donante',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Tratamiento',
              ovobank: 'Tratamiento',
            },
            description: {
              ovoclinic: 'Programa del paciente',
              ovobank: 'Programa del donante',
            },
            one: 'Programa',
            many: 'Programas',
            submit: 'Añadir programa',
            columns: {
              programName: 'Programa',
              estimated: 'Fecha estimada',
              finished: 'Fecha finalización',
            },
            document: {
              delete: {
                title: 'Borrar fichero',
                subtitle:
                  '¿Estás seguro de que deseas borrar el fichero?, se borrará también del cloud',
                submit: 'Borrar',
              },
              columns: {
                programName: 'Programa',
                estimated: 'Fecha estimada',
                finished: 'Fecha finalización',
                documentName: 'Documento',
                created: 'Creado',
                size: 'Tamaño',
                vRepoUploaded: 'Sincronizado con Vrepo',
                type: 'Tipo',
              },
              select: 'Subir un fichero pdf',
              unselected: 'Ningún fichero seleccionado',
              unvalid: 'Por favor, selecciona un fichero pdf válido.',
              submit: 'Enviar',
              many: 'Ficheros',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Tareas',
              ovobank: 'Tareas',
            },
            description: {
              ovoclinic:
                'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/pacientes',
              ovobank:
                'Gestión de las tareas a las que estás asignado. Las tareas son aquellas acciones que se hacen para llevar el seguimiento de leads/donantes',
            },
            add: 'Añadir tarea',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observaciones',
              },
              contactNotes: {
                title: 'Observaciones',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'Nuevo paciente',
                ovobank: 'Nuevo donante',
              },
              active: {
                ovoclinic: 'Paciente activo',
                ovobank: 'Donante activo',
              },
              inactive: {
                ovoclinic: 'Paciente inactivo',
                ovobank: 'Donante inactivo',
              },
              clinic: 'Clínica',
              contactTime: 'Preferencia horaria',
              responsible: 'Responsable',
              funnel: 'Origen',
              consent: 'Aprobación',
              consentCheck: 'Consentimiento',
              warrantyAgreement: 'Acuerdo garantía',
              campaign: 'Campaña',
              originCampaign: 'Campaña de origen',
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          description: {
            ovoclinic: 'Detalles del paciente',
            ovobank: 'Detalles del donante',
          },
        },
        contact: {
          tab: 'Contacto',
          description: {
            ovoclinic: 'Detalles del contacto del paciente',
            ovobank: 'Detalles del contacto del donante',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsable',
                ovobank: 'Responsable',
              },
              description: {
                ovoclinic: 'Usuario responsable del paciente',
                ovobank: 'Usuario responsable del donante',
              },
            },
            submit: 'Asignar responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description: {
                ovoclinic: 'Especialista asignado al paciente',
                ovobank: 'Especialista asignado al donante',
              },
            },
            submit: 'Asignar especialista',
          },
        },
        receipts: {
          create: {
            title: 'Crear recibo',
            subtitle: {
              ovoclinic: 'Crear recibo asociado al paciente',
              ovobank: 'Crear recibo asociado al donante',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Crear recibo',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Recibo',
                description: {
                  ovoclinic: 'Actualizar recibo asociado al paciente',
                  ovobank: 'Actualizar recibo asociado al donante',
                },
              },
              payments: {
                title: 'Pagos',
                description: 'Pagos del recibo asociado al cliente',
                list: {
                  create: 'Añadir pago',
                },
              },
            },
            title: 'Actualizar recibo',
            subtitle: {
              ovoclinic: 'Actualizar recibo asociado al paciente',
              ovobank: 'Actualizar recibo asociado al donante',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Guardar recibo',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Descripción',
              ovobank: 'Descripción',
            },
            description: {
              ovoclinic: 'Una breve descripción para añadir algun comentario sobre el paciente.',
              ovobank: 'Una breve descripción para añadir algun comentario sobre el donante.',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'La clínica en la que el paciente desea ser atendido.',
              ovobank: 'La clínica en la que el donante desea ser atendido.',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead asociado al paciente',
              ovobank: 'Lead asociado al donante',
            },
          },
          contactId: {
            title: 'Contacto',
            description: {
              ovoclinic:
                'Los datos de contacto por los cuales el paciente ha llegado a la clínica.',
              ovobank: 'Los datos de contacto por los cuales el donante ha llegado a la clínica.',
            },
          },
          campaignId: {
            title: 'Campaña',
            description: {
              ovoclinic:
                'La campaña de marketing de origen del paciente. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
              ovobank:
                'La campaña de marketing de origen del donante. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Acuerdo de garantía',
              ovobank: 'Acuerdo de garantía',
            },
            description: {
              ovoclinic: 'El paciente acepta el acuerdo de garantía',
              ovobank: 'El donante acepta el acuerdo de garantía',
            },
            label: {
              ovoclinic: 'Sí, acepto el acuerdo de garantía',
              ovobank: 'Sí, acepto el acuerdo de garantía',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimiento',
              ovobank: 'Consentimiento',
            },
            description: {
              ovoclinic: 'El paciente da su consentimiento para ser tratado',
              ovobank: 'El donante da su consentimiento para ser tratado',
            },
            label: {
              ovoclinic: 'Sí, doy mi consentimiento',
              ovobank: 'Sí, doy mi consentimiento',
            },
          },
          submit: {
            ovoclinic: 'Actualizar paciente',
            ovobank: 'Actualizar donante',
          },
        },
        payments: {
          create: {
            title: 'Crear pago',
            subtitle: {
              ovoclinic: 'Añadir pago asociado al recibo del paciente',
              ovobank: 'Añadir pago asociado al recibo del donante',
            },
            form: {
              price: {
                title: 'Precio',
              },
              submit: 'Guardar precio',
            },
          },
          update: {
            title: 'Actualizar pago',
            subtitle: {
              ovoclinic: 'Actualizar pago asociado al recibo del paciente',
              ovobank: 'Actualizar pago asociado al recibo del donante',
            },
            form: {
              price: {
                title: 'Precio',
              },
              submit: 'Guardar precio',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Deshabilitar paciente',
          ovobank: 'Deshabilitar donante',
        },
        subtitle: {
          ovoclinic: 'Deshabilitar paciente en el sistema',
          ovobank: 'Deshabilitar donante en el sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: '¿Estás seguro de que deseas deshabilitar este paciente?',
              ovobank: '¿Estás seguro de que deseas deshabilitar este donante?',
            },
            description: {
              ovoclinic:
                'Deshabilitar un paciente significa que no podrá ser utilizado en el sistema',
              ovobank: 'Deshabilitar un donante significa que no podrá ser utilizado en el sistema',
            },
            label: {
              ovoclinic: 'Sí, deseo deshabilitar este paciente',
              ovobank: 'Sí, deseo deshabilitar este donante',
            },
          },
          submit: 'Deshabilitar',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Habilitar paciente',
          ovobank: 'Habilitar donante',
        },
        subtitle: {
          ovoclinic: 'Habilitar paciente en el sistema',
          ovobank: 'Habilitar donante en el sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: '¿Estás seguro de que deseas habilitar este paciente?',
              ovobank: '¿Estás seguro de que deseas habilitar este donante?',
            },
            description: {
              ovoclinic: 'Habilitar un paciente significa que podrá ser utilizado en el sistema',
              ovobank: 'Habilitar un donante significa que podrá ser utilizado en el sistema',
            },
            label: {
              ovoclinic: 'Sí, deseo habilitar este paciente',
              ovobank: 'Sí, deseo habilitar este donante',
            },
          },
          submit: 'Habilitar',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Eliminar paciente',
          ovobank: 'Eliminar donante',
        },
        subtitle: {
          ovoclinic: 'Esta acción es irreversible',
          ovobank: 'Esta acción es irreversible',
        },
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: {
              ovoclinic: 'Tal vez prefieras desactivar este paciente',
              ovobank: 'Tal vez prefieras desactivar este donante',
            },
            label: {
              ovoclinic: 'Sí, deseo eliminar este paciente',
              ovobank: 'Sí, deseo eliminar este donante',
            },
          },
          confirm: {
            ovoclinic: 'Esta acción eliminará todo lo relacionado con el paciente, estás seguro?',
            ovobank: 'Esta acción eliminará todo lo relacionado con el donante, estás seguro?',
          },
          submit: 'Eliminar',
        },
      },
      actions: {
        create: {
          title: 'Nueva tarea',
          subtitle: 'Registro de una nueva tarea en el sistema',
          form: {
            activityId: {
              title: 'Actividad',
              description: 'Actividad a realizar',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Usuario responsable de la tarea',
            },
            started: {
              title: 'Fecha estimada',
              description: 'Fecha en la que se espera que se realice la actividad',
            },
            subject: {
              title: 'Asunto',
              description: 'Asunto de la tarea',
            },
            observation: {
              title: 'Nota',
              description: 'Un pequeño comentario sobre la actividad',
            },
            submit: 'Crear tarea',
          },
        },
        update: {
          title: 'Actualizar tarea',
          subtitle: 'Modificación de los datos de una tarea en el sistema',
          actionEnding: {
            form: {
              submit: 'Finalizar tarea',
              submitNextSuggestion: 'Finalizar y planificar',
            },
          },
          form: {
            result: {
              false: {
                title: 'Resultado',
              },
              result: {
                title: 'Resultado',
                description:
                  'Resultado de la actividad. Si se inserta un resultado, la tarea se considerará finalizada y no se podrá modificar más. Si no se inserta un resultado, la tarea se considerará pendiente y se podrá modificar más adelante.',
              },
            },
            communicationChannel: {
              title: 'Vía de comunicación',
              description: 'Vía de comunicación utilizada para la actividad',
            },
            activityId: {
              title: 'Actividad',
              description: 'Actividad a realizar',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Usuario responsable de la tarea',
            },
            started: {
              title: 'Fecha estimada',
              description: 'Fecha en la que se espera que se realice la actividad',
            },
            subject: {
              title: 'Asunto',
              description: 'Asunto de la tarea',
            },
            observation: {
              title: 'Nota',
              description: 'Un pequeño comentario sobre la actividad',
            },
            submit: 'Actualizar tarea',
          },
          tabs: {
            action: {
              title: 'Tarea',
              description: 'Información asociada a la tarea',
            },
            actionEnding: {
              title: 'Realización',
              description: 'Información asociada a la realización de la tarea',
            },
            alerts: {
              title: 'Alertas',
              description: 'Alertas asociadas a la tarea',
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: 'Actualizar contratación',
        subtitle: {
          ovoclinic: 'Modificación de los datos de una contratación de un paciente',
          ovobank: 'Modificación de los datos de una contratación de un donante',
        },
        form: {
          price: {
            title: 'Precio',
            description: 'Precio de la contratación',
          },
          submit: 'Actualizar contratación',
        },
      },
      disable: {
        title: 'Deshabilitar contratación',
        subtitle: {
          ovoclinic: 'Deshabilitar contratación del paciente',
          ovobank: 'Deshabilitar contratación del donante',
        },
        form: {
          submit: 'Deshabilitar',
        },
      },
      enable: {
        title: 'Habilitar contratación',
        subtitle: {
          ovoclinic: 'Habilitar contratación del paciente',
          ovobank: 'Habilitar contratación del donante',
        },
        form: {
          submit: 'Habilitar',
        },
      },
    },
    treatments: {
      create: {
        title: 'Nuevo programa',
        subtitle: 'Registro de un nuevo programa en el sistema',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          estimated: {
            title: 'Fecha estimada',
            description: 'Fecha en la que se espera que se realice el programa',
          },
          finished: {
            title: 'Fecha finalización',
            description: 'Fecha en la que se ha programado el programa',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase en la que se encuentra el programa',
          },
          discount: {
            title: 'Porcentaje de descuento',
            description: 'Descuento aplicado al programa',
          },
          submit: 'Crear programa',
        },
      },
      update: {
        title: 'Actualizar programa',
        subtitle: 'Modificación de los datos de un programa en el sistema',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          phaseId: {
            title: 'Fase',
            description: 'Fase en la que se encuentra el programa',
          },
          estimated: {
            title: 'Fecha estimada',
            description: 'Fecha en la que se espera que se realice el programa',
          },
          finished: {
            title: 'Fecha finalización',
            description: 'Fecha en la que se ha programado el programa',
          },
          discount: {
            title: 'Porcentaje de descuento',
            description: 'Descuento aplicado al programa',
          },
          submit: 'Actualizar programa',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Programa',
              ovobank: 'Programa',
            },
            description: {
              ovoclinic: 'Información del programa',
              ovobank: 'Información del programa',
            },
          },
          program: {
            title: {
              ovoclinic: 'Tratamiento',
            },
            description: {
              ovoclinic: 'Información del tratamiento',
              ovobank: 'Información del tratamiento',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic:
                'Son las técnicas complementarias que se han creado en relación con cada programa. A través de esta pantalla, se pueden gestionar las técnicas específicas que se han aplicado al paciente.',
              ovobank:
                'Son las técnicas complementarias que se han creado en relación con cada programa. A través de esta pantalla, se pueden gestionar las técnicas específicas que se han aplicado al donante.',
            },
            many: 'Tecnicas',
            one: 'Técnica',
            add: 'Añadir técnica',
          },
          maintenances: {
            title: {
              ovoclinic: 'Mantenimientos',
              ovobank: 'Mantenimientos',
            },
            description: {
              ovoclinic:
                'Mantenimientos del tratamiento. Un mantenimiento es un servicio aplicado a un tratamiento. Este mantenimiento generará una actividad para que el departamento de mantenimiento pueda dar el seguimiento de pagos al paciente',
              ovobank:
                'Mantenimientos del tratamiento. Un mantenimiento es un servicio aplicado a un tratamiento. Este mantenimiento generará una actividad para que el departamento de mantenimiento pueda dar el seguimiento de pagos al donante',
            },
            many: 'Mantenimientos',
            one: 'Mantenimiento',
            add: 'Añadir mantenimiento',
          },
          interventions: {
            title: {
              ovoclinic: 'Tratamientos',
              ovobank: 'Tratamientos',
            },
            description: {
              ovoclinic:
                'Ciclos realizados en los tratamientos. Mediante esta pantalla se pueden gestionar tratamientos específicos que se le han aplicado al paciente.',
              ovobank:
                'Ciclos realizados en los tratamientos. Mediante esta pantalla se pueden gestionar tratamientos específicos que se le han aplicado al donante.',
            },
            many: 'Tratamientos',
            one: 'Tratamiento',
            columns: {
              interventionClinicName: 'Clínica',
              observation: 'Observación',
              expectedDate: 'Fecha estimada',
              interventionDate: 'Fecha intervención',
              cycleName: 'Ciclo',
            },
            add: 'Añadir tratamiento',
          },
        },
      },
      disable: {
        title: 'Deshabilitar tratamiento',
        subtitle: 'Deshabilitar tratamiento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este tratamiento?',
            description:
              'Deshabilitar un tratamiento significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este tratamiento',
          },
          submit: 'Deshabilitar tratamiento',
        },
      },
      enable: {
        title: 'Habilitar tratamiento',
        subtitle: 'Habilitar tratamiento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este tratamiento?',
            description: 'Habilitar un tratamiento significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este tratamiento',
          },
          submit: 'Habilitar tratamiento',
        },
      },
      list: {
        title: {
          ovoclinic: 'Tratamientos',
          ovobank: 'Tratamientos',
        },
        description: {
          ovoclinic: 'Gestión de los tratamientos realizadas a los pacientes',
          ovobank: 'Gestión de los tratamientos realizadas a los donantes',
        },
        create: 'Tratamiento',
        one: 'Tratamiento',
        many: {
          ovoclinic: 'Tratamientos',
          ovobank: 'Tratamientos',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Ciclo',
            ovobank: 'Ciclo',
          },

          expectedDate: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          phaseName: {
            ovoclinic: 'Etapa',
            ovobank: 'Etapa',
          },
          estimated: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          finished: {
            ovoclinic: 'Fecha finalización',
            ovobank: 'Fecha finalización',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: 'Habilitar intervención',
        subtitle: 'Habilitar intervención en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar intervención',
        subtitle: 'Deshabilitar intervención en el sistema',
        form: {
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Nueva tratamiento',
        subtitle: 'Registro de un nuevo tratamiento',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se ha realizado el tratamiento',
          },
          interventionDate: {
            title: 'Fecha de intervención',
            description: 'Fecha en la que se ha realizado el ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Observación sobre el tratamiento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo de ciclo a realizar',
          },
          expectedDate: {
            title: 'Fecha estimada',
            description: 'Fecha estimada del ciclo',
          },
          submit: 'Crear tratamiento',
        },
      },
      update: {
        title: 'Actualizar tratamiento',
        subtitle: 'Modificación de los datos de un tratamiento en el sistema',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se ha realizado el tratamiento',
          },
          interventionDate: {
            title: 'Fecha de intervención',
            description: 'Fecha en la que se ha realizado el ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Observación sobre el tratamiento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo de ciclo a realizar',
          },
          expectedDate: {
            title: 'Fecha estimada',
            description: 'Fecha estimada del ciclo',
          },
          submit: 'Actualizar tratamiento',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Tratamiento',
              ovobank: 'Tratamiento',
            },
            description: {
              ovoclinic: 'Información del tratamiento',
              ovobank: 'Información del tratamiento',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic:
                'Técnicas de la intervención. Las técnicas son procesos creados en la creación de la intervención. Mediante esta pantalla se pueden gestionar técnicas específicas que se le han aplicado al paciente.',
              ovobank:
                'Técnicas de la intervención. Las técnicas son procesos creados en la creación de la intervención. Mediante esta pantalla se pueden gestionar técnicas específicas que se le han aplicado al donante.',
            },
          },
        },
        techniques: {
          delete: {
            title: 'Borrar técnica',
            subtitle: 'Borrar técnica del la intervención',
            form: {
              submit: 'Borrar técnica',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'Nuevo procedimiento',
        subtitle: 'Registro de un nuevo procedimiento para la intervención en el sistema',
        form: {
          techniqueId: {
            title: 'Técnica',
            description: 'Técnica asociada al procedimiento',
          },
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del procedimiento',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción detallada del procedimiento',
          },
          submit: 'Crear procedimiento',
        },
      },
      update: {
        title: 'Actualizar procedimiento',
        subtitle: 'Modificación de los datos de un procedimiento en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del procedimiento',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción detallada del procedimiento',
          },
          price: {
            title: 'Precio',
            description: 'Precio del procedimiento',
          },
          submit: 'Actualizar procedimiento',
        },
      },
      delete: {
        title: 'Borrar procedimiento',
        subtitle: 'Borrar procedimiento en el sistema',
        form: {
          submit: 'Borrar procedimiento',
        },
      },
    },
    maintenances: {
      create: {
        title: 'Nuevo mantenimiento',
        subtitle: 'Registro de un nuevo mantenimiento para el tratamiento en el sistema',
        form: {
          serviceId: {
            title: 'Servicio',
            description: 'Servicio asociado al mantenimiento',
          },
          submit: 'Crear mantenimiento',
        },
      },
      update: {
        title: 'Actualizar mantenimiento',
        subtitle: 'Modificación de los datos de un mantenimiento en el sistema',
        form: {
          serviceId: {
            title: 'Servicio',
            description: 'Servicio asociado al mantenimiento',
          },
          amount: {
            title: 'Precio',
            description: 'Precio del mantenimiento',
          },
          paymentStatus: {
            title: 'Estado de pago',
            description: 'Estado del pago del mantenimiento',
          },
          estimatedPaymentDate: {
            title: 'Fecha estimada de pago',
            description: 'Fecha estimada de pago del mantenimiento',
          },
          submit: 'Actualizar mantenimiento',
        },
        tabs: {
          maintenance: {
            title: 'Mantenimiento',
            description: 'Información del mantenimiento',
          },
          action: {
            title: 'Tarea',
            description: 'Información asociada a la tarea',
          },
        },
      },
      delete: {
        title: 'Borrar mantenimiento',
        subtitle: 'Borrar mantenimiento en el sistema',
        form: {
          submit: 'Borrar mantenimiento',
        },
      },
      list: {
        title: {
          ovoclinic: 'Mantenimientos',
          ovobank: 'Mantenimientos',
        },
        description: {
          ovoclinic: 'Gestión de los mantenimientos realizadas a los pacientes',
          ovobank: 'Gestión de los mantenimientos realizadas a los donantes',
        },
        create: 'Mantenimiento',
        one: {
          ovoclinic: 'Mantenimiento',
          ovobank: 'Mantenimiento',
        },
        many: {
          ovoclinic: 'Mantenimientos',
          ovobank: 'Mantenimientos',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          serviceName: {
            ovoclinic: 'Servicio',
            ovobank: 'Servicio',
          },
          amount: {
            ovoclinic: 'Precio',
            ovobank: 'Precio',
          },
          paymentStatus: {
            ovoclinic: 'Estado de pago',
            ovobank: 'Estado de pago',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Fecha estimada de pago',
            ovobank: 'Fecha estimada de pago',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          estimated: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          finished: {
            ovoclinic: 'Fecha finalización',
            ovobank: 'Fecha finalización',
          },
        },
      },
      enable: {
        title: 'Habilitar mantenimiento',
        subtitle: 'Habilitar mantenimiento en el sistema',
        form: {
          submit: 'Habilitar mantenimiento',
        },
      },
      disable: {
        title: 'Deshabilitar mantenimiento',
        subtitle: 'Deshabilitar mantenimiento en el sistema',
        form: {
          submit: 'Deshabilitar mantenimiento',
        },
      },
    },
    actions: {
      enable: {
        title: 'Habilitar acción',
        subtitle: 'Habilitar acción en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este acción?',
            description: 'Habilitar un acción significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este acción',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar acción',
        subtitle: 'Deshabilitar acción en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este acción?',
            description:
              'Deshabilitar un acción significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este acción',
          },
          submit: 'Deshabilitar',
        },
      },
      list: {
        title: 'Calendario',
        description: 'Calendario de las tareas relacionado a los leads',
      },
      delete: {
        title: 'Eliminar tarea',
        subtitle: 'Esta acción es irreversible',
        form: {
          submit: 'Eliminar',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Alertas',
            subtitle: 'Añaadir alertas a la tarea',
            form: {
              alertTime: {
                title: 'Tiempo de alerta',
                description:
                  'Ingresa el número de minutos de antelación para recibir el aviso de la tarea',
              },
              submit: 'Añadir alerta',
            },
          },
          delete: {
            title: 'Eliminar alerta',
            subtitle: 'Eliminar alerta de la tarea',
            form: {
              submit: 'Eliminar alerta',
            },
          },
          update: {
            title: 'Actualizar alerta',
            subtitle: 'Actualizar alerta de la tarea',
            form: {
              alertTime: {
                title: 'Tiempo de alerta',
                description:
                  'Ingresa el número de minutos de antelación para recibir el aviso de la tarea',
              },
              submit: 'Actualizar alerta',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Nombre',
          phones: 'Teléfono',
          contactTime: 'Franja',
          consents: 'Consentimientos',
          marketing: 'Marketing',
          legal: 'Legal',
          created: 'Fecha de creación',
        },
        title: {
          ovoclinic: 'Contactos',
          ovobank: 'Contactos',
        },
        description: {
          ovoclinic:
            'Gestión de los contactos existentes en la aplicación. Un contacto es una ficha de una persona que puede ser un lead o un paciente.',
          ovobank:
            'Gestión de los contactos existentes en la aplicación. Un contacto es una ficha de una persona que puede ser un lead o un donante.',
        },
        create: {
          ovoclinic: 'Contacto',
          ovobank: 'Contacto',
        },
        one: 'Contacto',
        many: 'Contactos',
      },
      create: {
        title: {
          ovoclinic: 'Nuevo contacto',
          ovobank: 'Nuevo contacto',
        },
        subtitle: {
          ovoclinic: 'Persona para luego asociarla a un lead o paciente',
          ovobank: 'Persona para luego asociarla a un lead o donante',
        },
        form: {
          name: {
            title: 'Nombre',
          },
          surname: {
            title: 'Apellidos',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Prefijo del contacto',
          },
          phone: {
            title: 'Teléfono',
            description: 'Teléfono del contacto',
          },
          email: {
            title: 'Email',
            description: 'Email del contacto',
          },
          zip: {
            title: 'Código postal',
            description: 'Código postal del contacto',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'El contacto acepta recibir información de marketing',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Preferencia ciudad',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Acepta las condiciones legales',
            description: 'El contacto acepta las condiciones legales',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'Horario en el que el contacto desea ser contactado',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'OvoCRM permite crear contactos para múltiples clínicas. Para mantener una trazabilidad del origen del contacto, requerimos que al crear un nuevo contacto se defina la clinica en la que se encuentra.',
          },
          campaignId: {
            title: 'Campaña',
            description:
              'La campaña de marketing de origen del contacto. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del contacto',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Crear contacto',
        },
      },
      update: {
        title: {
          ovoclinic: 'Actualizar contacto',
          ovobank: 'Actualizar contacto',
        },
        subtitle: {
          ovoclinic: 'Información personal del lead o paciente',
          ovobank: 'Información personal del lead o donante',
        },
        tabs: {
          account: {
            title: 'Datos Personales',
            description: 'Información detallada del contacto',
          },
          actions: {
            title: 'Actividades',
            description: 'Lista de actividades creadas sobre el contacto',
          },
          leads: {
            title: 'Campañas',
            description:
              'Campañas por las que ha entrado este contacto. Útil para ver la campaña actual del contacto',
          },
          contact: {
            title: 'Contacto',
            description: 'Información detallada del contacto',
          },
          receipts: {
            title: 'Recibos',
            description: {
              ovoclinic:
                'Recibos creadas sobre el contacto. Recopila los pagos realizados por el contacto como paciente',
              ovobank:
                'Recibos creadas sobre el contacto. Recopila los pagos realizados por el contacto como donante',
            },
          },
          notes: {
            title: 'Observaciones',
            description:
              'Información o comentarios sobre el contacto. Como por ejemplo, información que todos los compañeros de trabajo deberían de saber sobre el contacto',
          },
          purchases: {
            title: 'Contrataciones',
            description:
              'Contrataciones realizadas por el contacto. Recopila los tratamientos que ha adquirido el contacto',
          },
        },
        form: {
          name: {
            title: 'Nombre',
          },
          surname: {
            title: 'Apellidos',
          },
          dni: {
            title: 'Dni',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'El contacto acepta recibir información de marketing',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Acepta las condiciones legales',
            description: 'El contacto acepta las condiciones legales',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'Horario en el que el contacto desea ser contactado',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del contacto',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Prefijo del contacto',
          },
          phone: {
            title: 'Teléfono',
            description: 'Teléfono del contacto',
          },
          email: {
            title: 'Email',
            description: 'Email del contacto',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Preferencia ciudad',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Actualizar contacto',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Tipo',
              treatmentName: 'Tratamiento',
              price: 'Precio',
            },
            one: 'Contratación',
            many: 'Contrataciones',
          },
        },
      },
      delete: {
        title: 'Eliminar contacto',
        subtitle: 'Esta acción es irreversible',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este contacto',
            label: 'Sí, deseo eliminar este contacto',
          },
          confirm: 'Esta acción eliminará todo lo relacionado con el contacto, estás seguro?',
          submit: 'Eliminar',
        },
      },
      enable: {
        title: 'Habilitar contacto',
        subtitle: 'Habilitar contacto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este contacto?',
            description: 'Habilitar un contacto significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este contacto',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar contacto',
        subtitle: 'Deshabilitar contacto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este contacto?',
            description:
              'Deshabilitar un contacto significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este contacto',
          },
          submit: 'Deshabilitar',
        },
      },
      notes: {
        source: {
          LEAD: 'Lead',
          PATIENT: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campañas',
        description:
          'Gestión de las campañas de marketing. Una campaña de marketing va a ser la fuente o el origen de los clientes potenciales.',
        userId: {
          title: 'Encargado',
          description: 'Encargado de la campaña',
        },
        submit: 'Asignar encargado',
        create: 'Campaña',
        one: 'Campaña',
        many: 'Campañas',
        columns: {
          name: 'Nombre',
          leads: 'Leads',
          leadsStatus: 'Activos/Inactivos',
          funnelName: 'Embudo',
          languageName: 'Idioma',
          clinics: 'Clínicas',
          managers: 'Encargados',
        },
      },
      create: {
        title: 'Nueva campaña',
        subtitle: 'Registro de una nueva campaña en el sistema',
        form: {
          image: {
            title: 'Carátula',
            description: 'Te animamos a subir una imagen para mejorar la experiencia del comercial',
          },
          name: {
            title: 'Título',
            description: 'Titulo de la campaña de marketing',
          },
          facebookId: {
            title: 'Facebook ID',
            description: 'ID de Facebook de la campaña de marketing',
          },
          code: {
            title: 'Código',
            description: 'Código de la campaña de marketing',
          },
          budget: {
            title: 'Presupuesto',
            description: 'Presupuesto invertido por la clínica en la campaña',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica que financia la campaña',
          },
          firstActivityId: {
            title: 'Primera actividad',
            description: 'Primera actividad asignada al crear un lead',
          },
          funnelId: {
            title: 'Embudo',
            description: 'Embudo de conversión de la campaña de marketing',
          },
          status: {
            title: 'Campaña activa',
            description: 'Habilitar o deshabilitar la campaña en el sistema',
            label: 'Campaña activa',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Selecciona un idioma para que todos los leads de esta campaña se creen en ese idioma',
          },
          startDate: {
            title: 'Fecha de inicio',
            description: 'Fecha de inicio de la campaña',
          },
          submit: 'Crear campaña',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Canal',
                description: 'Canal de la campaña de marketing',
              },
            },
          },
        },
      },
      enable: {
        title: 'Habilitar campaña',
        subtitle: 'Habilitar campaña en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarla?',
            description: 'De esta manera estará disponible en el sistema',
            label: 'Sí, deseo habilitar esta campaña',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar campaña',
        subtitle: 'Deshabilitar campaña en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarla?',
            description: 'De esta manera no estará disponible en el sistema',
            label: 'Sí, deseo deshabilitar esta campaña',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        title: 'Actualizar campaña',
        subtitle: 'Los cambios afectarán a todas las integraciones',
        tabs: {
          general: {
            title: 'General',
            description: 'Detalles de la campaña',
          },
          contact: {
            title: 'Contacto',
            description:
              'Mediante este formulario puedes añadir un contacto a la campaña. Este formulario es muy útil para validar el comportamiento de la campaña de la misma forma que lo haría un usuario final.',
          },
          managers: {
            title: 'Encargados',
            description: 'Encargados de la campaña',
          },
          financing: {
            title: 'Financiación',
            description: 'Detalles de la financiación de la campaña',
          },

          webhook: {
            title: 'Webhook',
            description:
              'Un webhook es una forma de integrar tu aplicación con otras aplicaciones. OvoCRM te permite recibir notificaciones de eventos de la aplicación en tiempo real.',
            toast: 'Link copiado al portapapeles',
          },
          participants: {
            title: 'Clínicas receptoras',
            description:
              'Estas son las clínicas a las que está dirigida la campaña de marketing. Los leads generados a partir de la campaña serán distribuidos entre estas clínicas para su seguimiento y gestión.',
          },
        },
        participants: {
          list: {
            one: 'Clínica receptora',
            many: 'Clínicas receptoras',
            columns: {
              clinicName: 'Clínica',
            },
          },
          clinicsAdded: 'Clínicas añadidas',
          create: {
            title: 'Añadir clínica receptora',
            subtitle: 'Añadir una clínica receptora a la campaña',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Añadir clínica receptora a la campaña',
              },
              submit: 'Añadir clínica receptora',
            },
          },
          delete: {
            title: 'Eliminar clínica receptora',
            subtitle: 'Esta acción es irreversible',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description: 'Tal vez prefieras desactivar este participante',
                label: 'Sí, deseo eliminar esta clínica receptora',
              },
              submit: 'Eliminar',
            },
          },
          add: 'Añadir',
          addAll: 'Añadir todas',
          clinicIdCopied: 'ID de la clínica copiado al portapapeles',
        },
        financing: {
          form: {
            budget: {
              title: 'Presupuesto',
              description: 'Presupuesto invertido por la clínica en la campaña',
            },
            financingClinicId: {
              title: 'Clínica financiadora',
              description: 'Clínica que ha financiado la campaña',
            },
            submit: 'Actualizar financiación',
          },
        },
        managers: {
          list: {
            one: 'encargado',
            many: 'encargados',
            columns: {
              name: 'Nombre',
            },
          },
          add: 'Añadir encargado',
        },
        manager: {
          title: 'Asignar encargado',
          subtitle: 'Asignar un encargado a la campaña',
        },
        contact: {
          name: {
            title: 'Nombre',
          },
          surname: {
            title: 'Apellidos',
          },
          prefix: {
            title: 'Prefijo',
          },
          phone: {
            title: 'Teléfono',
          },
          email: {
            title: 'Email',
          },
          zip: {
            title: 'Código postal',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Acepta las condiciones legales',
            description: 'El contacto acepta las condiciones legales',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'El contacto acepta recibir información de marketing',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'Horario en el que el contacto desea ser contactado',
          },
          clinicId: {
            title: 'Clínica',
            description: 'La clínica en la que el contacto desea ser atendido',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del contacto',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Preferencia ciudad',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Añadir contacto',
        },
        form: {
          name: {
            title: 'Título',
            description: 'Título de la campaña de marketing',
          },
          facebookId: {
            title: 'Facebook ID',
            description: 'ID de Facebook de la campaña de marketing',
          },
          code: {
            title: 'Código',
            description: 'Código identificativo de la campaña de marketing',
          },
          budget: {
            title: 'Presupuesto',
            description: 'Presupuesto invertido por la clínica en la campaña',
          },
          image: {
            title: 'Carátula',
            description: 'Te animamos a subir una imagen para mejorar la experiencia del comercial',
          },
          funnelId: {
            title: 'Embudo',
            description: 'Embudo de conversión de la campaña de marketing',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica que financia la campaña',
          },
          startDate: {
            title: 'Fecha de inicio',
            description: 'Fecha de inicio de la campaña',
          },
          endDate: {
            title: 'Fecha de fin',
            description: 'Fecha de fin de la campaña',
          },
          firstActivityId: {
            title: 'Primera actividad',
            description: 'Primera actividad asignada al crear un lead',
          },
          status: {
            title: 'Campaña activa',
            description: 'Habilitar o deshabilitar la campaña en el sistema',
            label: 'Campaña activa',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Selecciona un idioma para que todos los leads de esta campaña se creen en ese idioma',
          },
          submit: 'Actualizar campaña',
        },
      },
      delete: {
        title: 'Eliminar campaña',
        subtitle: 'Esta acción es irreversible',
        manager: {
          title: 'Eliminar encargado',
          subtitle: 'Esta acción es irreversible',
          form: {
            submit: 'Eliminar',
          },
        },
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar esta campaña',
            label: 'Sí, deseo eliminar esta campaña',
          },
          success: 'Campaña eliminada correctamente',
          error: 'Error al eliminar la campaña',
          confirm: 'Esta acción eliminará todo lo relacionado con la campaña, estás seguro?',
          submit: 'Eliminar',
        },
      },
    },
    activities: {
      list: {
        title: 'Actividades',
        description:
          'Los tipos de actividades permiten a los comerciales realizar un seguimiento de las tareas que se realizan con los leads',
        create: 'Actividad',
        one: 'actividad',
        many: 'actividades',
        columns: {
          name: 'Nombre',
          description: 'Descripción',
          position: 'Posición',
          icon: 'Icono',
          color: 'Color',
          time: 'Tiempo',
        },
      },
      update: {
        subtitle: 'Modificar actividad en el sistema',
        title: 'Modificar actividad',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Título',
            description: 'Título identificativo de la actividad',
          },
          icon: {
            title: 'Icon',
            description:
              'Icono para identificar la actividad. Un icono puede ayudar a asociar una actividad',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description: 'Posición en la que se muestra la actividad en la lista de actividades.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la actividad.',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo de la actividad. Puede ayudar a identificar la actividad.',
          },
          time: {
            title: 'Tiempo',
            description: 'Tiempo estimado que se tarda en realizar la actividad.',
          },
          primary: {
            title: 'Actividad principal',
            description:
              'Las actividades principales son aquellas que son mostradas en el dashboard del comercial para permitir interactuar rápidamente con los contactos',
            label: 'Mostrar en el dashboard',
          },
        },
        tabs: {
          activity: {
            title: 'Actividad',
            description: 'Información asociada a la actividad',
          },
          results: {
            title: 'Resultados',
            description:
              'Los resultados describen lo ocurrido tras realizar la actividad. Tras la finalización de una actividad, podemos especificar en el resultado qué actividad se realizará a continuación.',
          },
          scopes: {
            title: 'Ámbitos',
            description: 'Los ambitos describen que departamentos pueden realizar que actividad',
          },
          communicationChannels: {
            title: 'Vías de comunicación',
            description: 'Vías de comunicación asociadas a la actividad',
          },
          integrations: {
            title: 'Integraciones',
            description: 'Integraciones externas que se realizarán al crear estas tareas',
          },
        },
        results: {
          one: 'resultado',
          many: 'resultados',
          add: 'Añadir resultado',
        },
        scopes: {
          one: 'Ámbito',
          many: 'Ámbitos',
          add: 'Añadir ámbito',
        },
        communicationChannels: {
          add: 'Añadir vía de comunicación',
          list: {
            one: 'Vía de comunicación',
            many: 'Vías de comunicación',
            columns: {
              channelName: 'Nombre',
            },
          },
          create: {
            title: 'Añadir vía de comunicación',
            subtitle: 'Añadir vía de comunicación a la actividad',
            form: {
              title: 'Vía de comunicación',
              subtitle: 'Vía de comunicación asociada a la actividad',
              submit: 'Añadir',
              id: {
                title: 'Vía de comunicación',
                description: 'Vía de comunicación asociada a la actividad',
              },
            },
          },
          delete: {
            title: 'Eliminar vía de comunicación',
            subtitle: 'Eliminar vía de comunicación de la actividad',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'LandBot',
            description:
              'Integración con LandBot. Si se introduce un id de landbot, cuando se cree una tarea de esta actividad sobre un Lead, se creará una conversación en LandBot',
            label: 'Activar integración LandBot',
          },
          landbotTemplateId: {
            title: 'LandBot Template',
            description:
              'Integración con LandBot. Id del template que se enviara al cliente antes que el bot',
          },
          landbotActivityId: {
            title: 'Actividad LandBot',
            description: 'Actividad que generará el LandBot',
          },
          landbotResultId: {
            title: 'Resultado LandBot',
            description: 'Resultado que introducirá LandBot en caso de completar la actividad',
          },
          submit: 'Guardar',
        },
      },
      enable: {
        title: 'Habilitar actividad',
        subtitle: 'Habilitar actividad en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta actividad?',
            description: 'Habilitar una actividad significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar esta actividad',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar actividad',
        subtitle: 'Deshabilitar actividad en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta actividad?',
            description:
              'Deshabilitar una actividad significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar esta actividad',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear actividad',
        subtitle: 'Crear actividad en el sistema',
        form: {
          submit: 'Crear',
          name: {
            title: 'Título',
            description: 'Título identificativo de la actividad',
          },
          icon: {
            title: 'Icon',
            description:
              'Icono para identificar la actividad. Un icono puede ayudar a asociar una actividad',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description: 'Posición en la que se muestra la actividad en la lista de actividades.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la actividad.',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo de la actividad. Puede ayudar a identificar la actividad.',
          },
          time: {
            title: 'Tiempo',
            description: 'Tiempo estimado que se tarda en realizar la actividad.',
          },
          primary: {
            title: 'Actividad principal',
            description:
              'Las actividades principales son aquellas que son mostradas en el dashboard del comercial para permitir interactuar rápidamente con los contactos',
            label: 'Mostrar en el dashboard',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Ámbitos',
        description: 'Gestión de los ambitos de las actividades en el sistema',
        create: 'Ámbito',
        one: 'Ámbito',
        many: 'Ámbitos',
        columns: {
          clinicName: 'Clínica',
          departmentName: 'Departamento',
        },
      },
      create: {
        title: 'Crear ámbito',
        subtitle: 'Crear ámbito en el sistema',
        form: {
          submit: 'Crear',
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el ámbito',
          },
          departmentId: {
            title: 'Departamento',
            description: 'Departamento al que pertenece el ámbito',
          },
          departmentIds: {
            title: 'Departamentos',
            description: 'Departamentos al que va a tener ámbito la plantilla',
          },
        },
      },
      delete: {
        title: 'Eliminar ámbito',
        subtitle: 'Esta acción es irreversible',
        form: {
          submit: 'Eliminar',
        },
      },
    },
    results: {
      list: {
        title: 'Resultados',
        description:
          'Gestión de los resultados de actividad en el sistema. Un resultado es la información que se introduce cuando se finaliza una actividad.',
        create: 'Añadir Resultado',
        one: 'resultado',
        many: 'resultados',
        columns: {
          name: 'Nombre',
          description: 'Descripción',
          icon: 'Icono',
          color: 'Color',
          time: 'Tiempo estimado',
          position: 'Posición',
          activityName: 'Actividad',
        },
      },
      update: {
        subtitle: 'Modificar resultado de actividad en el sistema',
        title: 'Modificar resultado',
        form: {
          submit: 'Actualizar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del resultado',
          },
          icon: {
            title: 'Icono',
            label: 'Icono',
            description:
              'Icono para identificar el resultado. Un icono puede ayudar a asociar un resultado',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description: 'Posición en la que se muestra el resultado en la lista de resultados.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre el resultado.',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del resultado. Puede ayudar a identificar el resultado.',
          },
          time: {
            title: 'Tiempo',
            description: 'Tiempo estimado que se tarda en realizar el resultado.',
          },
          activityId: {
            title: 'Actividad',
            description: 'Actividad a la que pertenece el resultado.',
          },
          nextActivityId: {
            title: 'Siguiente actividad',
            description: 'Actividad que se realizará después de realizar este resultado.',
          },
          nextActivityDelay: {
            title: 'Tiempo de espera',
            description: 'Tiempo de espera para realizar la siguiente actividad. (Días)',
          },
          disableLead: {
            title: 'Deshabilitar lead',
            description: 'Deshabilitar el lead tras realizar este resultado',
            label: 'Sí, deshabilitar el lead',
          },
          contacted: {
            title: 'Lead contactado',
            description: 'Se ha establecido contacto con el lead',
            label: 'Sí, el lead ha sido contactado',
          },
          informed: {
            title: 'Lead informado',
            description: 'El lead ha recibido información sobre el servicio',
            label: 'Sí, el lead ha sido informado',
          },
          converted: {
            title: {
              ovoclinic: 'Lead convertido en paciente',
              ovobank: 'Lead convertido en donante',
            },

            description: {
              ovoclinic:
                'El lead ha sido convertido en paciente. Esto implica que el lead va a ser transferido a ATP medico',
              ovobank:
                'El lead ha sido convertido en donante. Esto implica que el lead va a ser transferido a ATP medico',
            },
            label: {
              ovoclinic: 'Sí, el lead ha sido convertido',
              ovobank: 'Sí, el lead ha sido convertido',
            },
          },
          appointed: {
            title: 'Lead citado para visita informativa comercial',
            description: 'Se ha agendado una cita con el lead',
            label: 'Sí, el lead ha sido citado',
          },
          attended: {
            title: 'Visita informativa médica realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita médica',
            label: 'Sí, el lead ha tenido una cita médica',
          },
          comercialAttended: {
            title: 'Presupuesto entregado',
            description:
              'Con esta información se podrá contabilizar cuando se entrega un presupuesto al lead',
            label: 'Sí, el lead ha recibido un presupuesto',
          },
          medicalAppointed: {
            title: 'Lead citado para visita informativa médica',
            description: 'Se ha agendado una cita médica con el lead',
            label: 'Sí, el lead ha sido citado',
          },
          medicalEvaluated: {
            title: 'Evaluación médica',
            description: 'El lead ha sido evaluado por un médico',
            label: 'Sí, el lead ha sido evaluado',
          },
          templateId: {
            title: 'Nombre',
            description: 'Nombre de la plantilla',
          },
          landbotId: {
            title: 'LandBot',
            description: 'El ID del bot que se va a enviar al contacto junto a la plantilla',
          },
          landbotAppointed: {
            title: 'Cita comercial concertada por LandBot',
            description:
              'Si se realiza una actividad con este resultado significa que el bot ha concertado una cita',
            label: 'Sí, el bot ha concertado una cita',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead citado para visita informativa comercial',
            description: 'Se ha agendado una cita con el lead',
            label: 'Sí, el lead ha sido citado',
          },
          comercialAttended: {
            title: 'Visita informativa comercial realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita comercial',
            label: 'Sí, el lead ha tenido una cita comercial',
          },
          medicalAppointed: {
            title: 'Lead citado para visita informativa médica',
            description: 'Se ha agendado una cita médica con el lead',
            label: 'Sí, el lead ha sido citado',
          },
          attended: {
            title: 'Visita informativa médica realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita médica',
            label: 'Sí, el lead ha tenido una cita médica',
          },
          converted: {
            title: 'Ápto médico',
            description: 'El lead ha sido considerado apto para donar',
            label: 'Sí, el lead ha sido considerado apto',
          },
          submit: 'Actualizar',
        },
        tabs: {
          result: {
            title: 'Resultado',
            description:
              'Gestión de los resultados de actividad en el sistema. Un resultado es la información que se introduce cuando se finaliza una actividad.',
          },
          template: {
            title: 'Plantilla',
            description: 'Plantilla a usar para Landbot.',
          },
          nextActivity: {
            title: 'Siguiente actividad',
            description:
              'La siguiente actividad es la actividad que se realizará tras la finalización de este resultado.',
          },
          ccAchievement: {
            title: 'Hitos de Call Center',
            description:
              "Hitos que se deben cumplir para que el resultado sea considerado 'logrado'",
          },
          atpcAchievement: {
            title: 'Hitos de ATP Comercial',
            description:
              "Hitos que se deben cumplir para que el resultado sea considerado 'logrado'",
          },
          atpiAchievement: {
            title: 'Hitos de ATP Internacional',
            description:
              "Hitos que se deben cumplir para que el resultado sea considerado 'logrado'",
          },
          ovoBankAtpAchievement: {
            title: 'Hitos de Donantes',
            description:
              "Hitos que se deben cumplir para que el resultado sea considerado 'logrado'",
          },
          integrationAchievement: {
            title: 'Hitos de Integración',
            description:
              'Los hitos que se podrían cumplir por alguna integración. Ejemplo: LandBot',
          },
          reactivity: {
            title: 'Reactividad',
            description:
              'Reactividad del resultado. Reacciones que se van a realizar tras el resultado',
          },
        },
      },
      enable: {
        title: 'Habilitar resultado',
        subtitle: 'Habilitar resultado en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este resultado?',
            description: 'Habilitar un resultado significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este resultado',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar resultado',
        subtitle: 'Deshabilitar resultado en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este resultado?',
            description:
              'Deshabilitar un resultado significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este resultado',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear resultado',
        subtitle: 'Crear resultado de actividad en el sistema',
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del resultado',
          },
          icon: {
            title: 'Icon',
            description:
              'Icono para identificar el resultado. Un icono puede ayudar a asociar un resultado',
            label: 'Icono',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description: 'Posición en la que se muestra el resultado en la lista de resultados.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre el resultado.',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del resultado. Puede ayudar a identificar el resultado.',
          },
          time: {
            title: 'Tiempo',
            description: 'Tiempo estimado que se tarda en realizar el resultado.',
          },
          activityId: {
            title: 'Actividad',
            description: 'Actividad a la que pertenece el resultado.',
          },
          nextActivityId: {
            title: 'Siguiente actividad',
            description: 'Actividad que se realizará después de realizar este resultado.',
          },
          nextActivityDelay: {
            title: 'Tiempo de espera',
            description: 'Tiempo de espera para realizar la siguiente actividad. (Días)',
          },
          contacted: {
            title: 'Lead contactado',
            description: 'Se ha establecido contacto con el lead',
            label: 'Sí, el lead ha sido contactado',
          },
          informed: {
            title: 'Lead informado',
            description: 'El lead ha recibido información sobre el servicio',
            label: 'Sí, el lead ha sido informado',
          },
          converted: {
            title: 'Convertir lead',
            description: {
              ovoclinic:
                'El lead ha sido convertido en paciente. Esto implica que el lead va a ser transferido a ATP medico',
              ovobank:
                'El lead ha sido convertido en donante. Esto implica que el lead va a ser transferido a ATP medico',
            },
            label: 'Sí, el lead ha sido convertido',
          },
          appointed: {
            title: 'Lead citado',
            description: 'Se ha agendado una cita con el lead',
            label: 'Sí, el lead ha sido citado',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Servicios',
        description:
          'Gestión de los servicios en el sistema. Un servicio es el procedimiento de mantenimiento que puede tener lugar después de un tratamiento',
        create: 'Servicio',
        one: 'servicio',
        many: 'servicios',
        columns: {
          name: 'Nombre',
          price: 'Precio',
          nextActivityName: 'Próxima actividad',
          nextActivityDelay: 'Tiempo pospuesto de actividad',
        },
      },
      update: {
        subtitle: 'Modificar servicio del sistema',
        title: 'Modificar servicio',
        form: {
          submit: 'Actualizar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del servicio',
          },
          price: {
            title: 'Precio',
            description: 'Precio del servicio',
          },
          nextActivityId: {
            title: 'Próxima actividad',
            description: 'Próxima actividad',
          },
          nextActivityDelay: {
            title: 'Tiempo pospuesto de actividad',
            description: 'Tiempo que se pospone la actividad',
          },
        },
        tabs: {
          service: {
            title: 'Servicio',
            description:
              'Gestión de los servicios de actividad en el sistema. Un servicio es la información que se introduce cuando se finaliza una actividad.',
          },
          nextActivity: {
            title: 'Siguiente actividad',
            description:
              'La siguiente actividad es la actividad que se realizará tras la finalización de este servicio.',
          },
        },
      },
      enable: {
        title: 'Habilitar servicio',
        subtitle: 'Habilitar servicio en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este servicio?',
            description: 'Habilitar un servicio significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este servicio',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar servicio',
        subtitle: 'Deshabilitar servicio en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este servicio?',
            description:
              'Deshabilitar un servicio significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este servicio',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear servicio',
        subtitle: 'Crear servicio de actividad en el sistema',
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del servicio',
          },
          price: {
            title: 'Precio',
            description: 'Precio del servicio',
          },
          nextActivityId: {
            title: 'Próxima actividad',
            description: 'Próxima actividad',
          },
          nextActivityDelay: {
            title: 'Tiempo pospuesto de actividad',
            description: 'Tiempo que se pospone la actividad',
          },
        },
      },
    },
    products: {
      list: {
        title: 'Productos',
        description: 'Gestión de los productos en el sistema',
        create: 'Añadir Producto',
        one: 'producto',
        many: 'productos',
        columns: {
          name: 'Nombre',
          price: 'Precio',
          tax: 'Impuestos',
          categoryName: 'Categoría',
          clinicName: 'Clínica',
        },
      },
      update: {
        subtitle: 'Modificar producto en el sistema',
        title: 'Modificar producto',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del producto',
          },
          icon: {
            title: 'Icon',
            description:
              'Icono identificativo del producto. Un icono puede ayudar a relacionar un producto con mayor facilidad',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción del producto para detallar sus características',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del producto. Un color puede ayudar a relacionar un producto con mayor facilidad',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del producto',
          },
          tax: {
            title: 'Tax',
            description: 'Impuestos aplicados al producto',
          },
          path: {
            title: 'Path',
            description: 'Path donde se encuentra el producto',
          },
          categoryId: {
            title: 'Categoria',
            description: 'Categoria a la que pertenece el producto',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece esta categoría',
          },
        },
      },
      enable: {
        title: 'Habilitar producto',
        subtitle: 'Habilitar producto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este producto?',
            description: 'Habilitar un producto significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este producto',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar producto',
        subtitle: 'Deshabilitar producto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este producto?',
            description:
              'Deshabilitar un producto significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este producto',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear producto',
        subtitle: 'Crear producto en el sistema',
        steps: {
          category: {
            form: {
              selected: 'Categoría seleccionada',
              categoryId: {
                title: 'Categoria',
                description: 'Categoria a la que pertenece el producto',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Clínica seleccionada',
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece esta categoría',
              },
            },
          },
        },
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del producto',
          },
          icon: {
            title: 'Icon',
            description:
              'Icono identificativo del producto. Un icono puede ayudar a relacionar un producto con mayor facilidad',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción del producto para detallar sus características',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del producto. Un color puede ayudar a relacionar un producto con mayor facilidad',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del producto',
          },
          tax: {
            title: 'Tax',
            description: 'Impuestos aplicados al producto',
          },
          path: {
            title: 'Path',
            description: 'Path donde se encuentra el producto',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Categorías',
        description: 'Agrupación de productos',
        create: 'Añadir Categoría',
        one: 'categoría',
        many: 'categorías',
        columns: {
          name: 'Nombre',
          clinicName: 'Clínica',
          created: 'Fecha de creación',
        },
      },
      update: {
        subtitle: 'Modificar categoría en el sistema',
        title: 'Modificar categoría',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la categoría',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la categoría',
          },
        },
      },
      create: {
        title: 'Crear categoría',
        subtitle: 'Crear categoría en el sistema',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece esta categoría',
              },
            },
          },
        },
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la categoría',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la categoría',
          },
        },
      },
      enable: {
        title: 'Habilitar categoría',
        subtitle: 'Habilitar categoría en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta categoría?',
            description: 'Habilitar una categoría significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar esta categoría',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar categoría',
        subtitle: 'Deshabilitar categoría en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta categoría?',
            description:
              'Deshabilitar una categoría significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar esta categoría',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    techniques: {
      list: {
        title: 'Técnicas',
        description: 'Gestión de tecnicas de tratamientos en el sistema',
        create: 'Técnica',
        one: 'Técnica',
        many: 'Técnicas',
        columns: {
          name: 'Nombre',
          clinic: 'Clínica',
          created: 'Fecha de creación',
          price: 'Precio',
        },
      },
      create: {
        title: 'Crear técnica',
        subtitle: 'Registro de una nueva técnica en el sistema',
        form: {
          id: {
            title: 'Nombre',
            description: 'Nombre identificativo de la técnica',
          },
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la técnica',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta de la técnica',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la técnica',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se realiza la técnica',
          },
          submit: 'Crear técnica',
        },
      },
      update: {
        title: 'Actualizar técnica',
        subtitle: 'Modificación de una técnica en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la técnica',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta de la técnica',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la técnica',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se realiza la técnica',
          },
          submit: 'Actualizar técnica',
        },
      },
      enable: {
        title: 'Habilitar técnica',
        subtitle: 'Habilitar técnica en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar técnica',
        subtitle: 'Deshabilitar técnica en el sistema',
        form: {
          submit: 'Deshabilitar',
        },
      },
      add: 'Añadir',
    },
    programs: {
      list: {
        title: 'Programas',
        description: 'Gestión de los programas del catálogo',
        create: 'Programa',
        one: 'Programa',
        many: 'Programas',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
          price: 'Precio',
          techniques: 'Técnicas',
        },
      },
      create: {
        title: 'Crear programa para el catálogo',
        subtitle: 'Registro de un nuevo programa en el catálogo',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del programa',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar el programa',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del programa',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del programa',
          },
          code: {
            title: 'Código',
            description: 'Código identificativo del programa',
          },
          submit: 'Crear programa',
        },
      },
      enable: {
        title: 'Habilitar programa',
        subtitle: 'Habilitar programa en el catálogo',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarlo?',
            description: 'Habilitar un programa significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este programa',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar programa',
        subtitle: 'Deshabilitar programa en el catálogo',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description:
              'Deshabilitar un programa significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este programa',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        title: 'Actualizar programa',
        subtitle: 'Modificación de un programa en el catálogo',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del programa',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar el programa',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del programa',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se realiza el programa',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del programa',
          },
          code: {
            title: 'Código',
            description: 'Código identificativo del programa',
          },
          submit: 'Actualizar programa',
        },
        tabs: {
          program: {
            title: 'Programas',
            description: 'Modificación de un programa en el catálogo',
          },
          techniques: {
            title: 'Técnicas',
            description: 'Técnicas asociadas al programa',
          },
          phases: {
            title: 'Etapas',
            description: 'Etapas asociadas al programa',
          },
          cycles: {
            title: 'Ciclos',
            description: 'Ciclos asociados al programa',
          },
        },
        cycles: {
          list: {
            title: 'Ciclos',
            description: 'Ciclos asociados al programa',
            columns: {
              cycleName: 'Nombre',
              cycleCreated: 'Fecha de creación',
            },
            one: 'Ciclo',
            many: 'Ciclos',
          },
          create: {
            title: 'Añadir ciclo',
            subtitle: 'Añadir ciclo al programa',
            form: {
              cycleId: {
                title: 'Ciclo',
                description: 'Ciclo asociado al programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar ciclo',
            subtitle: 'Esta acción es irreversible',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        phases: {
          create: {
            title: 'Añadir etapa',
            subtitle: 'Añadir etapa al programa',
            form: {
              phaseId: {
                title: 'Etapa',
                description: 'Etapa asociada al programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar etapa',
            subtitle: 'Esta acción es irreversible',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description: 'Tal vez prefieras desactivar esta etapa',
                label: 'Sí, deseo eliminar esta etapa',
              },
              submit: 'Eliminar',
            },
          },
          list: {
            title: 'Etapas',
            description: 'Etapas asociadas al programa',
            columns: {
              phaseName: 'Nombre',
              phaseCreated: 'Fecha de creación',
            },
            one: 'Etapa',
            many: 'Etapas',
          },
        },
        techniques: {
          create: {
            title: 'Añadir técnica',
            subtitle: 'Añadir técnica al programa',
            form: {
              id: {
                title: 'Técnica',
                description: 'Técnica asociada al programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar técnica',
            subtitle: 'Esta acción es irreversible',
            form: {
              submit: 'Eliminar',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Etapas',
        description: 'Gestión de las etapas de un tratamiento',
        create: 'Etapa',
        one: 'Etapa',
        many: 'Etapas',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
      },
      create: {
        title: 'Crear etapa',
        subtitle: 'Registro de una nueva etapa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la etapa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la etapa.',
          },
          icon: {
            title: 'Icono',
            label: 'Icono',
            description:
              'Icono para identificar la etapa. Un icono puede ayudar a asociar una etapa',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la etapa. Puede ayudar a identificar la etapa.',
          },
          submit: 'Crear etapa',
        },
      },
      update: {
        title: 'Actualizar etapa',
        subtitle: 'Modificación de una etapa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la etapa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la etapa.',
          },
          icon: {
            title: 'Icono',
            description:
              'Icono para identificar la etapa. Un icono puede ayudar a asociar una etapa',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la etapa. Puede ayudar a identificar la etapa.',
          },
          submit: 'Guardar',
        },
        tabs: {
          phase: {
            title: 'Etapa',
            description: 'Información de la etapa',
          },
          causes: {
            title: 'Causas',
            description: 'Causas por las que se puede cancelar un tratamiento',
            one: 'Causa',
            many: 'Causas',
          },
        },
      },
      enable: {
        title: 'Habilitar etapa',
        subtitle: 'Habilitar etapa en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar etapa',
        subtitle: 'Deshabilitar etapa en el sistema',
        form: {
          submit: 'Deshabilitar',
        },
      },
    },
    causes: {
      list: {
        title: 'Causas',
        description: 'Gestión de las causas de cancelación de un tratamiento',
        create: 'Causa',
        one: 'Causa',
        many: 'Causas',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
      },
      create: {
        title: 'Crear causa',
        subtitle: 'Registro de una nueva causa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la causa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la causa.',
          },
          icon: {
            title: 'Icono',
            description:
              'Icono para identificar la causa. Un icono puede ayudar a asociar una causa',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la causa. Puede ayudar a identificar la causa.',
          },
          submit: 'Crear causa',
        },
      },
      update: {
        title: 'Actualizar causa',
        subtitle: 'Modificación de una causa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la causa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la causa.',
          },
          icon: {
            title: 'Icono',
            description:
              'Icono para identificar la causa. Un icono puede ayudar a asociar una causa',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la causa. Puede ayudar a identificar la causa.',
          },
          submit: 'Guardar',
        },
      },
      enable: {
        title: 'Habilitar causa',
        subtitle: 'Habilitar causa en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar causa',
        subtitle: 'Deshabilitar causa en el sistema',
        form: {
          submit: 'Deshabilitar',
        },
      },
    },
  },
  board: {
    total: 'Total',
    months: {
      jan: 'Ene',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Abr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dic',
    },
    noData: {
      title: 'No hay datos disponibles',
      description: 'Puedes probar seleccionando otros filtros',
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Leads por campaña y estado',
        description: 'Leads por campaña y estado',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadPerClinicAndState: {
        title: 'Leads por clínica y estado',
        description: 'Leads por clínica y estado',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
    },
    direction: {
      title: 'Dashboards dirección',
      description: 'Visión ejecutiva de la información del crm en tiempo real',
      cost: 'Coste de lead',
      leadCostByClinicAndMonth: {
        title: 'Coste por lead por clínica',
        description: 'Coste por lead por clínica',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadCostByFunnelAndMonth: {
        title: 'Distribución de leads por clinicas y departamento',
        description: 'Distribución de leads por clinicas y departamento',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadCostByCampaignAndMonth: {
        title: 'Coste por lead por campaña',
        description: 'Coste por lead por campaña',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
    },
    marketing: {
      title: 'Dashboards marketing',
      description: 'Visión de marketing de la información del crm en tiempo real',
      secondary: {
        title: 'Campañas de marketing',
        description:
          'Visualización de la información de los leads respecto a las campañas de marketing.',
      },
      convertionRateByCampaign: {
        title: 'Tasa de conversión por campaña',
        description: 'Tasa de conversión por campaña',
        totalLeads: 'Total de leads',
        totalConvertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
      },
      totalLeads: 'TOTAL DE LEADS',
      leadsState: 'ESTADO DE LEADS',
      leadActiveInactive: 'LEADS ACTIVOS/INACTIVOS',
      leadsPerCampaign: {
        title: 'Leads por campaña',
        description: 'Leads por campaña',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
        filters: {
          campaign: 'Campaña',
          funnel: 'Embudo',
        },
      },
      leadCostDetail: {
        title: 'Detalle',
        selectedClinic: 'Clínica seleccionada',
        selectedCampaign: 'Campaña seleccionada',
        budget: 'Presupuesto',
      },
      costCampaign: {
        0: 'Clinica seleccionada: ',
        1: 'Campaña seleccionada: ',
        2: 'Presupuesto: ',
        3: 'Número de leads: ',
        4: 'Leads citados: ',
        5: 'Coste total de leads: ',
        6: 'Coste total citados: ',
      },
      leadsPerFunnel: {
        title: 'Leads por embudo',
        description: 'Leads por embudo',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      filters: {
        year: 'Año',
        yearSubtitle: 'Filtra por año seleccionado',
        by: 'Filtrar por',
      },
      generalTable: {
        totalLeads: 'Total leads',
        informed: 'V.I realizada',
        total: 'Total acumulado',
        costPerLead: 'Coste por lead',
        totalRow: 'Total',
      },
    },
    subordinates: {
      title: 'Rendimento de mi equipo',
      description: 'Visualización del rendimiento de mi equipo',
      performance: {
        title: 'Hitos realizados',
        description: 'Visualización de los hitos realizados por mi equipo',
      },
      performanceSubordinates: {
        title: 'RENDIMIENTO DE MI EQUIPO',
      },
      performanceSubordinate: {
        title: 'RENDIMIENTO DE MI COMPAÑERO',
      },
      filters: {
        clinics: {
          all: 'Todas',
        },
        subordinate: {
          title: 'Empleados',
          subtitle: 'Filtra por empleado seleccionado',
        },
      },
    },
    intervention: {
      title: 'Previsión de tratamientos',
      description: 'Visualización de los tratamientos estimados',
      expectedInterventions: {
        monthsTable: 'PREVISIÓN DE TRATAMIENTOS',
        yearsTable: 'PREVISIÓN DE TRATAMIENTOS ACUMULADOS',
      },
      segmention: 'Desglose tratamientos',
      lossGainSegmentation: 'Desglose pérdidas y ganancias',
      one: 'Tratamiento',
      many: 'Tratamientos',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Pérdidas',
        total_first_attempt: 'Garantias (1er intento)',
        total: 'Total',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Pérdidas',
        total_first_attempt: 'Ganancias',
        total: 'Total',
      },
      filters: {
        complexity: {
          title: 'Complejidad',
          subtitle: 'Filtra por complejidad seleccionada',
          types: {
            ALL: 'Todas',
            HIGH: 'Alta',
            LOW: 'Baja',
          },
        },
      },
    },
    callcenter: {
      title: 'Dashboards call center',
      description: 'Visión de call center de la información del crm en tiempo real',
    },
    leads: {
      title: 'Dashboards leads',
      description: 'Visión de leads de la información del crm en tiempo real',
      totalLeads: {
        title: 'Total de leads',
        description: 'Resumen de los leads por clínica y departamento',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtra por clínica seleccionada',
          campaign: 'Campaña',
          campaignSubtitle: 'Filtra por campaña seleccionada',
          optionA: 'Opción A',
          optionASubtitle: '1ª opción de la comparativa',
          optionB: 'Opción B',
          optionBSubtitle: '2ª opción de la comparativa',
          funnel: 'Procedencia',
          funnelSubtitle: 'Filtra por procedencia',
          all: 'Todas',
          department: 'Departamento',
          departmentSubtitle: 'Filtra por departamento seleccionado',
          year: 'Año',
          yearSubtitle: 'Filtra por año seleccionado',
          month: 'Mes',
          monthSubtitle: 'Filtra por mes seleccionado',
          allMonths: 'Todos',
        },
        comparativeGraphicTitle: 'COMPARATIVA DE LEADS',
        comparativeAcumulatedGraphicTitle: 'COMPARATIVA DE LEADS/ ACUMULADOS',
        totalLeads: 'LEAD TOTALES',
        summary: 'RESUMEN',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Embudo de ventas',
      description: 'Visión de embudo de ventas de la información del crm en tiempo real',
      totalSales: {
        graphicTitle: 'EMBUDO DE VENTAS',
      },
    },
    sales: {
      title: 'Dashboards sales',
      description: 'Visión de ventas de la información del crm en tiempo real',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Tratamientos, técnicas y servicios por clínica',
        description: 'Tratamientos, técnicas y servicios por clínica',
        totalTreatments: 'Total de tratamientos',
        totalTechniques: 'Total de técnicas',
        totalServices: 'Total de servicios',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Tratamientos, técnicas y servicios por departamento',
        description: 'Tratamientos, técnicas y servicios por departamento',
        totalTreatments: 'Total de tratamientos',
        totalTechniques: 'Total de técnicas',
        totalServices: 'Total de servicios',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Resumen de ventas',
        ovobank: 'Resumen de ventas',
      },
      description: {
        ovoclinic: 'Resumen de los leads convertidos en pacientes',
        ovobank: 'Resumen de los leads convertidos en donantes',
      },
      totalSales: {
        title: 'Ventas',
        description: 'Visualización de las ventas realizadas',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtra por clínica seleccionada',
          optionA: 'Opción A',
          optionASubtitle: '1ª opción de la comparativa',
          optionB: 'Opción B',
          optionBSubtitle: '2ª opción de la comparativa',
          department: 'Departamento',
          departmentSubtitle: 'Filtra por departamento seleccionado',
          month: 'Mes',
          monthSubtitle: 'Filtra por mes seleccionado',
        },
        comparativeGraphicTitle: 'COMPARATIVA DE VENTAS',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'COMPARATIVA DE VENTAS/ ACUMULADOS',
      },
    },
    medical: {
      title: 'Dashboards médico',
      description: 'Visión de departamento médico de la información del crm en tiempo real',
      leadConversionByFunnelAndMonth: {
        title: 'Tasa de conversión de leads por procedencia y mes',
        description: 'Tasa de conversión de leads por procedencia y mes',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Porcentaje de conversión de leads por procedencia y mes',
        description: 'Porcentaje de conversión de leads por procedencia y mes',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Porcentaje de conversión',
      },
      estimatedTreatmentByYear: {
        title: 'Tratamientos programados',
        description:
          'Comparación de los tratamientos programados en el año seleccionado y el anterior',
        estimated: 'Tratamiento programados',
        finished: 'Tratamiento finalizados',
        previousEstimated: 'Tratamientos pogramados',
        previousFinished: 'Tratamientos finalizados',
      },
      finishedTreatmentByYear: {
        title: 'Tratamientos finalizados',
        description:
          'Comparación de los tratamientos finalizados en el año seleccionado y el anterior',
        estimated: 'Tratamiento programados',
        finished: 'Tratamiento finalizados',
        previousEstimated: 'Tratamientos pogramados',
        previousFinished: 'Tratamientos finalizados',
      },
      leadConversionBySpecialist: {
        title: 'Tasa de conversión de leads por especialista',
        description: 'Tasa de conversión de leads por especialista',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        specialistData: 'Datos del especialista',
      },
      leadConversionBySpecialistKPI: {
        title: 'KPI Tasa de conversión de leads por especialista',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Tasa de conversión de leads por especialista y mes',
        description: 'Tasa de conversión de leads por especialista y mes',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        specialistData: 'Datos del especialista',
        leadsAttended: 'Leads atendidos',
        leadsAttendedConverted: 'Leads atendidos convertidos',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'KPI Tasa de conversión de leads por especialista y mes',
        description: 'KPI Tasa de conversión de leads por especialista y mes',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionByFunnel: {
        title: 'Tasa de conversión de leads por procedencia',
        description: 'Tasa de conversión de leads por procedencia',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
      },
      leadConversionByFunnelKPI: {
        title: 'KPI Tasa de conversión de leads por procedencia',
        description: 'KPI Tasa de conversión de leads por procedencia',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        convertedLeadsRate: 'Tasa de conversión de leads',
        treatmentSoldRate: 'Tasa de conversión de tratamientos vendidos',
      },
    },
    menu: {
      leads: 'Resumen Leads',
      directory: 'Directorio',
      direction: 'Dirección',
      expectedIntervention: 'Previsión de tratamientos',
      general: 'General',
      callcenter: 'Call center',
      medical: 'Médico',
      marketing: 'Marketing',
      sale: 'Ventas',
      saleFunnel: 'Embudo de ventas',
      summarySales: 'Resumen de ventas',
      subordinates: 'Rendimiento de empleados',
    },
    sections: {
      marketing: {
        actives: 'Activos',
        inactives: 'Inactivos',
        converted: 'Convertidos',
        appointed: 'Citados',
        informed: 'Informados',
        contacted: 'Contactado',
        leadPerCampaign: {
          title: 'Leads por campaña de marketing',
        },
        leadPerFunnel: {
          title: 'Leads por procedencia de las campañas',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por embudo',
            ovobank: 'Tasa de conversión lead-donante por embudo',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por campaña',
            ovobank: 'Tasa de conversión lead-donante por campaña',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Donantes',
          },
          leads: 'Leads',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por procedencia',
            ovobank: 'Tasa de conversión lead-donante por procedencia',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Donantes',
          },
          leads: 'Leads',
        },
        leadPerClinicPerState: {
          total: 'Total',
          title: 'Leads por clínica por estado',
          actives: 'Activos',
          inactives: 'Inactivos',
          converted: 'Convertidos',
          appointed: 'Citados',
          appointedMedic: 'Citados (médico)',
          contacted: 'Contactado',
          informed: 'Informado',
          loss: 'Baja',
          cancelled: 'Canceladas',
        },
        leadPerCampaignPerState: {
          total: 'Total',
          title: 'Leads por campaña por estado',
          actives: 'Activos',
          inactives: 'Inactivos',
          converted: 'Convertidos',
          appointed: 'Citados',
          contacted: 'Contactado',
          informed: 'Informado',
          loss: 'Baja',
        },
        totalLeadsPerState: {
          total: 'Total',
          appointed: 'Citados',
          percentage: 'Porcentaje',
          cost: 'Coste',
          actives: 'Activos',
          inactives: 'Inactivos',
        },
        filter: {
          campaignId: {
            title: 'Filtrar por campaña',
          },
          languageId: {
            title: 'Filtrar por idioma',
          },
          funnelId: {
            title: 'Filtrar por procedencia',
          },
          clinicId: {
            title: 'Filtrar por clínica',
          },
        },
        listLeadsByCampaign: {
          title: 'Desglose de campañas',
          campaign: 'Campaña de marketing',
          funnel: 'Procedencia',
          financingClinic: 'Clínica financiadora',
          language: 'Departamento',
          budget: 'Presupuesto campaña',
          nLeads: 'Nº de leads',
          costPerLead: 'Coste por lead',
        },
        all: 'Todo',
      },
      callcenter: {
        listLeadsByReason: {
          title: 'Leads por motivo de cancelación',
          reason: 'Razón',
          nLeads: 'Nº de leads',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Coste de lead por clínica',
          monthFilter: 'Filtrar por mes',
        },
        costsLeadPerFunnel: {
          title: 'Coste de lead por procedencia',
        },
        costsLeadPerCampaign: {
          title: 'Coste de lead por campaña',
        },
        costsLeadPerLanguage: {
          title: 'Coste de lead por departamento',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Tratamientos, técnicas y servicios por clínica',
        },
        salePerLanguage: {
          title: 'Tratamientos, técnicas y servicios por departamento',
        },
      },
      saleFunnel: {
        title: 'Embudo comercial',
        description: 'Visualizacion del estado general de los leads durante el proceso de venta',
      },
    },
  },
  profile: {
    menu: {
      profile: 'Perfil',
      account: 'Mi cuenta',
      changePassword: 'Cambiar contraseña',
    },
    changePassword: {
      title: 'Cambiar contraseña',
      subtitle: 'Cambia la contraseña de tu cuenta',
      form: {
        password: {
          title: 'Contraseña',
          description: 'Nueva contraseña para el usuario',
        },
        repeatPassword: {
          title: 'Repetir contraseña',
          description: 'Repite la contraseña para confirmarla',
        },
        submit: 'Cambiar contraseña',
      },
    },
    account: {
      title: 'Mi cuenta',
      subtitle: 'Información personal y de la cuenta',
      form: {
        name: {
          title: 'Nombre',
          description: 'Introduce tu nombre',
        },
        surname: {
          title: 'Apellidos',
          description: 'Introduce tus apellidos',
        },
        username: {
          title: 'Nombre de usuario',
          description: 'Introduce tu nombre de usuario',
        },
        phone: {
          title: 'Teléfono',
          description: 'Introduce tu número de teléfono',
        },
        submit: 'Actualizar cuenta',
      },
    },
  },
  common: {
    search: {
      placeholder: 'Buscar...',
    },
    actions: {
      create: 'Crear',
      update: 'Actualizar',
      delete: 'Eliminar',
      save: 'Guardar',
      cancel: 'Cancelar',
    },
    tasks: {
      disabled: 'Mostrar inactivos',
      noResult: 'Sin resultado',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Eliminar nota',
          description: '¿Estás seguro de que deseas eliminar esta nota?',
          cancel: 'Cancelar',
          submit: 'Eliminar',
        },
      },
      noteType: {
        title: 'Ver observaciones del',
        patient: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        lead: {
          ovoclinic: 'Lead',
          ovobank: 'Lead',
        },
      },
      filter: {
        title: 'Filtrar por',
        tags: {
          medical: 'Médico',
          nursing: 'Enfermeria',
          followUp: 'Seguimiento',
        },
      },
    },
    time: {
      seconds: 'segundos',
      second: 'segundo',
      minutes: 'minutos',
      minute: 'minuto',
      hour: 'hora',
      hours: 'horas',
      days: 'días',
      day: 'día',
      weekDays: {
        0: 'Lunes',
        1: 'Martes',
        2: 'Miércoles',
        3: 'Jueves',
        4: 'Viernes',
        5: 'Sábado',
        6: 'Domingo',
      },
      timeMessage: {
        expiresIn: 'Expira en {{value}} {{unit}}',
        expired: 'Expirado',
        ago: 'Hace',
      },
    },
    status: {
      success: 'Correcto',
      fail: 'Fallido',
    },
    notification: {
      title: 'Tienes una nueva notificación',
    },

    contactTime: {
      MORNING: 'Mañana',
      AFTERNOON: 'Tarde',
      NO_PREFERENCE: 'Sin preferencia',
    },
    complexity: {
      HIGH: 'Alta',
      LOW: 'Baja',
    },
    specialistType: {
      GYNECOLOGIST: 'Ginecólogo',
      EMBRYOLOGIST: 'Embriólogo',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: 'Pendientes',
      COMPLETED: 'Realizadas',
      ALL: 'Todas',
    },
    plannedStatus: {
      ALL: 'Todas',
      PLANNED: 'Planificadas',
      UNPLANNED: 'Sin planificar',
    },
    taskProgress: {
      PENDING: 'Pendiente',
      COMPLETED: 'Realizada',
    },
    leadEntryStatus: {
      NEW: 'Nuevo',
      DUPLICATED: 'Duplicado',
      REENTRY: 'Reingreso',
    },
    paymentStatus: {
      PENDING: 'Pendiente',
      PAID: 'Pagado',
    },
    purchaseType: {
      MAINTENANCE: 'Mantenimiento',
      TREATMENT: 'Programa',
      TECHNIQUE: 'Técnica',
    },
    notifications: {
      list: {
        title: 'Notificaciones',
        description: 'Gestión de las notificaciones en el sistema',
        columns: {
          title: 'Título',
          type: 'Tipo de notificación',
          created: 'Fecha de creación',
        },
        one: 'Notificación',
        many: 'Notificaciones',
      },
      types: {
        ACTION_ALERT: 'Alerta de tarea',
      },
      new: 'Tienes una nueva notificación',
    },
    information: {
      title: 'Recursos',
      description: 'Información y recursos de la plataforma',
    },
  },
  components: {
    fileUploader: {
      title: 'Subir archivo',
      invalidType: 'Tipo de archivo no permitido',
    },
  },
  login: {
    title: 'Iniciar sesión',
    subtitle: 'Acceso a la plataforma de gestión',
    form: {
      email: {
        title: 'Correo electrónico',
      },
      signature: {
        title: 'Contraseña',
      },
      submit: 'Iniciar sesión',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: 'Usuario o contraseña incorrectos',
      WRONG_PERMISSIONS: 'No tienes permisos para ejecutar esta acción',
      service: {
        group: {
          findByDomain: {
            error: 'FATAL: No se ha podido encontrar el grupo',
          },
        },
      },
    },
  },
};
