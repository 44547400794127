import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ProgramTechniqueCrmRepository } from '@human/crm/repositories/programTechnique/programTechnique.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';

export function DeleteProgramTechniqueScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programTechniqueDelete'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.programs.update.techniques.delete">
      <FormComponent
        prefix="crm.programs.update.techniques.delete.form"
        padding
        repository={{
          send: () => {
            return new ProgramTechniqueCrmRepository()
              .delete({
                programId: properties.route.params.programId,
                techniqueId: properties.route.params.techniqueId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
