import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LicenseAccessRepository } from '@human/access/repositories/license/license.repository';
export function DeleteLicenseScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'licenseDelete'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash"
      prefix="access.users.update.licenses.delete"
    >
      <FormComponent
        prefix="access.users.update.licenses.delete.form"
        padding
        repository={{
          send: () => {
            return new LicenseAccessRepository()
              .delete({
                licenseId: properties.route.params.id,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
          },
        ]}
      />
    </ModalComponent>
  );
}
