import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { ActionsUpdateLeadPart } from './parts/actions.update.lead.part';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { ResponsibleUpdateLeadPart } from './parts/responsible.update.lead.part';
import { ContactUpdatePart } from '@human/crm/sections/contacts/screens/update/parts/contact.update.part';
import { PassportComponent } from '@components/passport/passport.component';
import { useState } from 'react';
import { NotesComponent } from '@components/notes/notes.component';
import { LeadNoteCrmRepository } from '@human/crm/repositories/leadNote/leadNote.repository';
import { LeadDisabledComponent } from '@components/leadDisabled/lead.disabled.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { useDeviceHook } from '@hooks/device/device.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { SpecialistUpdateLeadPart } from './parts/specialist.update.lead';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvobankPassportComponent } from '@components/passport/ovobank.passport.component';

export function UpdateLeadScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadUpdate'>;
}) {
  const [lead, setLead] = useState<Partial<LeadCrmType>>();
  const { showSuccess } = useSuccessToast();

  const passport = Workspace.select({
    ovoclinic: lead ? <PassportComponent {...properties} contact={lead.contact} /> : <></>,
    ovobank: lead ? <OvobankPassportComponent {...properties} contact={lead.contact} /> : <></>,
  });

  const [campaign, setCampaign] = useState<
    Partial<{
      id: string;
      languageId: string;
      image: string;
      code: string;
      title: string;
    }>
  >();

  const { phone } = useDeviceHook();

  const leadPromise = () =>
    new LeadCrmRepository()
      .pick({
        id: properties.route.params.id,
        clinicId: properties.route.params.clinicId,
      })
      .then((lead) => {
        setLead(lead);
        return lead;
      });

  const tabs = (
    <TabsComponent
      width={820}
      prefix="crm.leads.update"
      tabs={[
        {
          name: 'lead',
          icon: 'user',
          content: (settings) => {
            return (
              <>
                {lead && !lead.status && (
                  <LeadDisabledComponent
                    reason={lead.reasonName ?? ''}
                    statusUpdated={lead.statusUpdated ?? new Date()}
                  />
                )}
                {lead?.contact && !phone && passport}
                <FormComponent<LeadCrmType>
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  prefix="crm.leads.update.form"
                  readonly={!hasPermissionUtilty({ name: 'crm.leads.editor' })}
                  repository={{
                    send: (data) => {
                      return new LeadCrmRepository()
                        .update({
                          item: {
                            ...data.item,
                            leadId: properties.route.params.id,
                            oldClinicId: properties.route.params.clinicId,
                          },
                        })
                        .then(() => {
                          properties.navigation.setParams({
                            ...properties.route.params,
                            clinicId: data.item.clinicId,
                          });
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: () => {
                      return leadPromise();
                    },
                  }}
                  fields={[
                    {
                      type: 'select',
                      name: 'contactId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new ContactCrmRepository()
                          .pick({ id: properties.id })
                          .then((contact) => {
                            return `${contact.name} ${contact.surname}`;
                          });
                      },
                      repository: ({ search }) => {
                        return new ContactCrmRepository()
                          .list({
                            direction: 'asc',
                            page: 0,
                            limit: 100,
                            search,
                            active: true,
                            order: 'name',
                          })
                          .then((contacts) =>
                            contacts.items.map((contact) => ({
                              value: contact.id,
                              label: `${contact.name} ${contact.surname}`,
                            }))
                          );
                      },
                    },
                    {
                      type: 'select',
                      name: 'clinicId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new ClinicAccessRepository()
                          .pick({ id: properties.id })
                          .then((clinic) => {
                            return `${clinic.name}`;
                          });
                      },
                      repository: ({ search }) => {
                        return new ContractAccessRepository().list({ search }).then((contracts) =>
                          contracts.items.map((contract) => ({
                            value: contract.clinic.clinicId,
                            label: contract.clinic.name,
                          }))
                        );
                      },
                    },
                    {
                      type: 'text',
                      name: 'description',
                      required: false,
                      validations: ['description'],
                      description: true,
                      lines: 6,
                    },
                    {
                      type: 'select',
                      name: 'campaignId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new CampaignCrmRepository()
                          .pick({ id: properties.id })
                          .then((campaign) => {
                            setCampaign({
                              id: campaign.id,
                              languageId: campaign.languageId,
                              code: campaign.code,
                              image: campaign.image,
                              title: campaign.name,
                            });
                            return `${campaign.name}`;
                          });
                      },
                      repository: ({ search }) => {
                        return new CampaignCrmRepository()
                          .list({
                            direction: 'asc',
                            page: 0,
                            limit: 100,
                            active: true,
                            search,
                          })
                          .then((campaigns) =>
                            campaigns.items.map((campaign) => ({
                              value: campaign.id,
                              label: campaign.name,
                            }))
                          );
                      },
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          name: 'contact',
          icon: 'address-card',
          content: (settings) => (
            <ContactUpdatePart
              contactId={properties.route.params.contactId}
              onSend={() => {
                settings?.setIsEditing(false);
                showSuccess();
              }}
              onChange={(data) => {
                if (!data.isLocal) return;
                settings?.setIsEditing(true);
              }}
            />
          ),
        },
        ResponsibleUpdateLeadPart(properties),
        ActionsUpdateLeadPart(properties),
        SpecialistUpdateLeadPart(properties),
        {
          name: 'notes',
          icon: 'sticky-note',
          content: () => (
            <NotesComponent
              repository={{
                get: () =>
                  new LeadNoteCrmRepository().findByLead({
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
                send: (text: string) =>
                  new LeadNoteCrmRepository().create({
                    item: {
                      text,
                      leadId: properties.route.params.id,
                      clinicId: properties.route.params.clinicId,
                    },
                  }),
                update: (settings: { text: string; noteId: string }) =>
                  new LeadNoteCrmRepository().update({
                    text: settings.text,
                    leadNoteId: settings.noteId,
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
                delete: (noteId: string) =>
                  new LeadNoteCrmRepository().delete({
                    leadNoteId: noteId,
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
              }}
            />
          ),
        },
      ]}
    />
  );

  return (
    <ModalComponent width={820} icon="user-ninja" prefix="crm.leads.update">
      {tabs}
    </ModalComponent>
  );
}
