// Modules
import { StyleSheet } from 'react-native';

/**
 * StatusColumn Styles
 * @constant {StyleSheet} StatusColumnStyles
 */
export const StatusColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    width: 42,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  containerStatus: {
    backgroundColor: '#35ED632E',
  },
  containerNoStatus: {
    backgroundColor: '#ED35352E',
  },
  iconContainer: {
    height: 40,
    borderRadius: 50,
    width: 40,
  },
  icon: {
    fontSize: 19,
    textAlign: 'center',
    lineHeight: 40,
  },
  iconStatus: {
    color: '#1F953D',
  },
  iconNoStatus: {
    color: '#ed3535',
  },
});
