import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { useState } from 'react';
import { DockComponent } from '../dock/dock.component';
import { useNavigation, useRoute } from '@react-navigation/native';
import { IconType } from '../icon/icon.types';
import { NewsComponent } from './components/news/news.component';
import { MenuStyles } from './menu.styles';
import { MenuProperties } from './menu.properties';
import { SecurityComponent } from './components/security/security.component';
import { FooterComponent } from './components/footer/footer.component';
import { SectionComponent } from './components/section/section.component';
import { HeaderComponent } from './components/header/header.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Menu Component
 * @description Menú de navegación para los diferentes departamentos de la empresa.
 * A su vez presenta un resumen de noticias.
 *
 * @param {MenuProperties} properties
 * @returns {JSX.Element}
 */

export function MenuComponent(properties: MenuProperties) {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const path = properties.state.routeNames[properties.state.index];
  const { phone, tablet, laptop, desktop } = useDeviceHook();
  let auxSection: {
    [key: string]: {
      icon: IconType;
      department?: string;
      keywords: string[];
      permission?: string;
      platforms?: Array<typeof Platform.OS>;
      multiWorkspace?: boolean;
    };
  } = {};
  Object.keys(properties.sections).forEach((key) => {
    const section = properties.sections[key];
    if (section.platforms && !section.platforms.includes(Platform.OS)) {
      return;
    }

    if (
      hasPermissionUtilty({
        name: section.permission || '',
      })
    )
      auxSection[key] = section;
  });

  // group by department
  const groupByDepartment = () => {
    const departments: {
      [key: string]: {
        [key: string]: {
          icon: IconType;
          department?: string;
          keywords: string[];
          permission?: string;
          platforms?: Array<typeof Platform.OS>;
          multiWorkspace?: boolean;
        };
      };
    } = {};
    Object.entries(auxSection).forEach(([key, value]) => {
      if (!value.department) {
        return;
      }
      if (!departments[value.department]) {
        departments[value.department] = {};
      }
      departments[value.department][key] = value;
    });
    return departments;
  };

  return (
    <View style={[MenuStyles.container, phone && MenuStyles.mobileContainer]}>
      <DockComponent />
      <View style={MenuStyles.mainContent}>
        <SafeAreaView />
        <HeaderComponent type={properties.type} brand={properties.brand} />
        <ScrollView style={MenuStyles.scrollView}>
          {Object.entries(groupByDepartment()).map(([area, value], index) => {
            return (
              <SectionComponent
                key={index}
                path={path}
                type={properties.type}
                value={value}
                area={area}
              />
            );
          })}
          <View style={MenuStyles.newsContainer}></View>
          {!phone && <NewsComponent />}
        </ScrollView>
        <FooterComponent />
        <SecurityComponent />
      </View>
    </View>
  );
}
