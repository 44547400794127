// Modules
import { StyleSheet } from 'react-native';

/**
 * TextColumn Styles
 * @constant {StyleSheet} TextColumnStyles
 */
export const TextColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 7,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: '#333',
    paddingHorizontal: 7,
  },
  disabledText: {
    color: '#9E9E9E',
  },
  tooltip: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'black',
    opacity: 0.8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 6,
  },
  tooltipText: { color: 'white', fontSize: 14 },
});
