import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TimetableRoutes } from '../../../timetables.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TimetableDayAccessRepository } from '@human/access/repositories/timetableDay/timetableDay.repository';

export function DeleteTimetableDayScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'timetableDayDelete'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash"
      prefix="access.timetables.update.timetableDays.delete"
    >
      <FormComponent
        prefix="access.timetables.update.timetableDays.delete.form"
        padding
        repository={{
          send: () => {
            return new TimetableDayAccessRepository()
              .delete({
                timetableDayId: properties.route.params.timetableDayId,
                timetableId: properties.route.params.timetableId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
