import { StyleSheet } from 'react-native';

export const ComparativeYearsLeadCountRows = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    padding: 5,
    paddingVertical: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
    backgroundColor: '#FFF',
  },
  rowContainerMobile: {
    flexDirection: 'row',
    paddingVertical: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
    backgroundColor: '#FFF',
  },
  rowContainerLast: {
    backgroundColor: '#EDEDED',
  },
  rowContainerLoss: {
    backgroundColor: '#FEE9E8',
  },
  rowContainerFirstAttempt: {
    backgroundColor: '#FAFAFA',
  },
  subRowLossText: {
    color: '#FF3B3A',
  },

  rowSpaceColumnContainer: {
    flex: 3,
    justifyContent: 'center',
  },
  rowClinicNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 20,
  },
  rowSpaceText: {
    fontWeight: 'bold',
    marginHorizontal: 10,
  },
  rowTotalColumnContainer: {
    flex: 1,
  },
  rowTotalText: {
    backgroundColor: '#111b22',
    borderRadius: 4,
    color: 'white',
    width: 75,
    padding: 2,
    textAlign: 'center',
  },
  rowColumnContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  rowText: {
    color: '#111b22',
    fontWeight: '700',
  },
  rowFutureText: {
    fontWeight: 'normal',
    color: '#aaa',
  },
  subRowSpaceColumnContainer: {
    flex: 3,
    justifyContent: 'center',
    marginVertical: 10,
    marginLeft: 10,
  },
  subRowSpaceColumnContainerMobile: {
    justifyContent: 'center',
    marginRight: 12,
  },
  subRowSpaceText: {
    fontSize: 12,
    color: 'black',
  },
  subRowTotalColumnContainer: {
    flex: 1,
  },
  subRowTotalText: {
    fontSize: 12,
    width: 75,
    padding: 2,
    textAlign: 'center',
    backgroundColor: '#ddd',
    borderRadius: 4,
    color: '#111b22b3',
  },
  subRowColumnContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subRowText: {
    fontSize: 12,
    borderLeftColor: 'black',

    color: 'black',
  },
  subRowFutureText: {
    fontWeight: 'normal',
    color: '#aaa',
  },
  subRowDivider: {
    flex: 1,
  },
});
