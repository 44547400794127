import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class TechniqueCrmRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    page?: number;
    limit?: number;
    active?: true;
  }): Promise<{
    items: TechniqueCrmType[];
    total: number;
  }> {
    return this.fetch('technique/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        created: new Date(item.created),
        name: item.name,
        price: item.price,
        status: item.status,
      })),
    }));
  }

  async create(properties: {
    item: Partial<TechniqueCrmType>;
  }): Promise<Partial<TechniqueCrmType>> {
    return this.fetch('technique/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { id: string }): Promise<TechniqueCrmType> {
    return this.fetch('technique/pick', {
      method: 'GET',
      params: properties,
    }).then((item) => ({
      ...item,
      price: item.price.toString(),
    }));
  }

  update(properties: { item: Partial<TechniqueCrmType> }) {
    return this.fetch(`technique/update?id=${properties.item.id}`, {
      method: 'PUT',
      body: properties.item,
    });
  }

  enable(properties: { techniqueId: string }) {
    return this.fetch(`technique/enable`, {
      method: 'PUT',
      params: properties,
    });
  }

  disable(properties: { techniqueId: string }) {
    return this.fetch(`technique/disable`, {
      method: 'PUT',
      params: properties,
    });
  }
}
