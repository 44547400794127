/**
 *
 * @param value string true or false
 * @returns returns true if the string is true or false
 * when sending a filter in form of queryparam the server will receive all
 * the filters in a string format, so we need to validate if the string is a booolean
 */
export function isStringedBoolean(value: unknown): value is string {
  if (typeof value !== 'string') return false;

  return value === 'true' || value === 'false';
}
