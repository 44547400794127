import { IconComponent } from '@components/icon/icon.component';
import { filterBadgeStyles } from './filterBadge.styles';
import { Text, TouchableOpacity, View } from 'react-native';
import { FilterBadgeProperties } from './filterBadge.properties';

export function FilterBadgeComponent(properties: FilterBadgeProperties) {
  return (
    <TouchableOpacity
      style={filterBadgeStyles.container}
      onPress={() => properties.onRemove(properties.value)}
    >
      <Text style={filterBadgeStyles.text}>{properties.value}</Text>
      <IconComponent
        name="times"
        iconStyle={filterBadgeStyles.icon}
        containerstyle={filterBadgeStyles.iconContainer}
      />
    </TouchableOpacity>
  );
}
