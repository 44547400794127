// Modules
import { StyleSheet } from 'react-native';

/**
 * Map input Styles
 * @constant {StyleSheet} MapInputStyles
 */
export const MapInputStyles = StyleSheet.create({
  container: {
    height: 300,
    backgroundColor: 'rgb(36, 9, 42)',
    borderRadius: 15,
  },
  marker: {
    height: 45,
    width: 45,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: 30,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markerIconShape: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
  },
  markerIcon: {
    height: 35,
    width: 35,
    zIndex: 100,
    borderRadius: 25,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  map: {
    height: 120,
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    overflow: 'hidden',
  },
});
