import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TemplateAccessType } from '@human/access/types/template.access.type';

export class TemplateAccessRepository extends AuthorizedRepository {
  async create(properties: { item: Partial<TemplateAccessType> }) {
    return this.fetch('template/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async list(properties: {
    direction?: 'ASC' | 'DESC';
    page?: number;
    limit?: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: TemplateAccessType[];
    total: number;
  }> {
    return this.fetch('template/find', {
      params: properties,
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((template: TemplateAccessType) => ({
        id: template.id,
        label: template.label,
        landBotId: template.landbotId,
        status: template.status,
      })),
      total: data.total,
    }));
  }

  async pick(properties: { templateId: string }): Promise<TemplateAccessType> {
    return this.fetch('template/pick', {
      params: properties,
      method: 'GET',
    }).then((template: TemplateAccessType) => ({
      id: template.id,
      label: template.label,
      landbotId: template.landbotId,
      status: template.status,
    }));
  }

  async delete(properties: { templateId: string }): Promise<void> {
    return this.fetch('template/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async disable(properties: { templateId: string }): Promise<void> {
    return this.fetch('template/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { templateId: string }): Promise<void> {
    return this.fetch('template/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async update(properties: { item: Partial<TemplateAccessType> }) {
    return this.fetch('template/update', {
      method: 'PUT',
      body: properties.item,
    });
  }
}
