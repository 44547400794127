import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Dimensions, View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { LeadDetailBoardOvobankRepository } from '../../../../repositories/leadDetail/leadDetail.ovobank.repository';
import { LeadEligibleComparativeProperties } from './leadEligibleComparative.properties';
import { LeadEligibleComparativeType } from './leadEligibleComparative.type';

const { height } = Dimensions.get('window');

export function LeadEligibleComparativeChartComponent(
  properties: LeadEligibleComparativeProperties
) {
  const [data, setData] = useState<LeadEligibleComparativeType[]>([]);
  const { t } = useTranslation();

  const months = Array.from({ length: 12 }, (_, i) => t(`access.users.list.month.${i}`));

  useEffect(() => {
    new LeadDetailBoardOvobankRepository()
      .leadsEligibleByYear({
        clinicId: properties.clinicId,
        year: properties.year,
        funnelId: properties.funnelId,
      })
      .then((response) => {
        setData(response);
      });
  }, [properties]);

  const datasets = [
    {
      label: 'Aptos',
      data: data?.map((item) => item.lead_eligible_count) ?? [],
      backgroundColor: '#D33E3D',
      borderColor: '#D33E3D',
      borderWidth: 1,
    },
    {
      label: 'No aptos',
      data: data?.map((item) => item.lead_no_eligible_count) ?? [],
      backgroundColor: '#273038',
      borderColor: '#273038',
      borderWidth: 1,
    },
  ];

  if (!datasets) {
    return (
      <View>
        <Text>{t('No data available')}</Text>
      </View>
    );
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 20,
        height: height * 0.4,
      }}
    >
      <Bar
        data={{ labels: months, datasets }}
        options={{
          aspectRatio: 3, // Relación de aspecto
          plugins: {
            legend: {
              display: true,
              position: 'bottom', // Leyenda en la parte inferior
              labels: {
                color: '#283038', // Color de la leyenda
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true, // Comienza desde 0
              title: {
                display: true,
                text: 'Total de Leads', // Etiqueta del eje Y
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 2, // Ancho del borde
              borderColor: 'rgba(0, 0, 0, 0.6)', // Color del borde
              borderRadius: 6, // Radio del borde
            },
          },
        }}
      />
    </View>
  );
}
