import { View, Text } from 'react-native';
import { BarChartProperties } from '../barChart/barChart.properties';
import { useTranslation } from 'react-i18next';
import { AnalyticsStyles } from './analytics.styles';

export function AnalyticsComponent(properties: {
  month: string;
  clinicName: string;
  departmentName: string;
  chartProps: BarChartProperties;
}) {
  const { t } = useTranslation();

  const AnalyticsRow = (settings: {
    leftData: number;
    leftText: string;
    rightData: number;
    rightText: string;
  }) => {
    const calculation =
      settings.leftData === 0
        ? settings.rightData > 0
          ? (100).toFixed(2)
          : (0).toFixed(2)
        : ((settings.rightData * 100) / settings.leftData).toFixed(2);

    return (
      <View style={AnalyticsStyles.row}>
        <View style={AnalyticsStyles.rowItem}>
          <Text style={{ fontWeight: 'bold' }}>{settings.leftData}</Text>
          <Text>{settings.leftText}</Text>
        </View>
        <View style={AnalyticsStyles.rowItem}>
          <Text style={{ fontWeight: 'bold' }}>{settings.rightData}</Text>
          <Text>{settings.rightText}</Text>
        </View>
        <View style={AnalyticsStyles.calculation}>
          <Text>{calculation}%</Text>
        </View>
      </View>
    );
  };

  const conversion = (
    (properties.chartProps.data.lead_converted_count * 100) /
    properties.chartProps.data.total_leads
  ).toFixed(2);

  return (
    <View style={AnalyticsStyles.container}>
      <View style={AnalyticsStyles.analyticsWrapper}>
        <View>
          <Text style={AnalyticsStyles.title}>
            {t('board.saleFunnel.totalSales.graphicTitle')} /{properties.clinicName} /
            {properties.departmentName} /{properties.month}
          </Text>
        </View>
        {AnalyticsRow({
          leftData: properties.chartProps.data.total_leads,
          leftText: 'Total leads',
          rightData: properties.chartProps.data.lead_contacted_count,
          rightText: properties.chartProps.leadEvent.CONTACTED,
        })}
        {AnalyticsRow({
          leftData: properties.chartProps.data.lead_contacted_count,
          leftText: properties.chartProps.leadEvent.CONTACTED,
          rightData: properties.chartProps.data.lead_appointed_count,
          rightText: properties.chartProps.leadEvent.APPOINTED,
        })}
        {AnalyticsRow({
          leftData: properties.chartProps.data.lead_appointed_count,
          leftText: properties.chartProps.leadEvent.APPOINTED,
          rightData: properties.chartProps.data.lead_informed_count,
          rightText: properties.chartProps.leadEvent.INFORMED,
        })}
        {AnalyticsRow({
          leftData: properties.chartProps.data.lead_informed_count,
          leftText: properties.chartProps.leadEvent.INFORMED,
          rightData:
            properties.departmentName === 'Español'
              ? properties.chartProps.data.lead_medical_appointed_count
              : properties.chartProps.data.lead_comercial_attended_count,
          rightText:
            properties.departmentName === 'Español'
              ? properties.chartProps.leadEvent.MEDICALAPPOINTED
              : properties.chartProps.leadEvent.COMERCIALATTENDED,
        })}
        {AnalyticsRow({
          leftData:
            properties.departmentName === 'Español'
              ? properties.chartProps.data.lead_medical_appointed_count
              : properties.chartProps.data.lead_comercial_attended_count,
          leftText:
            properties.departmentName === 'Español'
              ? properties.chartProps.leadEvent.MEDICALAPPOINTED
              : properties.chartProps.leadEvent.COMERCIALATTENDED,
          rightData:
            properties.departmentName === 'Español'
              ? properties.chartProps.data.lead_medic_attended_count
              : properties.chartProps.data.lead_medical_evaluated_count,
          rightText:
            properties.departmentName === 'Español'
              ? properties.chartProps.leadEvent.ATTENDED
              : properties.chartProps.leadEvent.MEDICALEVALUATED,
        })}
        {AnalyticsRow({
          leftData:
            properties.departmentName === 'Español'
              ? properties.chartProps.data.lead_medic_attended_count
              : properties.chartProps.data.lead_medical_evaluated_count,
          leftText:
            properties.departmentName === 'Español'
              ? properties.chartProps.leadEvent.ATTENDED
              : properties.chartProps.leadEvent.MEDICALEVALUATED,
          rightData:
            properties.departmentName === 'Español'
              ? properties.chartProps.data.lead_comercial_attended_count
              : properties.chartProps.data.lead_converted_count,
          rightText:
            properties.departmentName === 'Español'
              ? properties.chartProps.leadEvent.COMERCIALATTENDED
              : properties.chartProps.leadEvent.CONVERTED,
        })}
        {AnalyticsRow({
          leftData: properties.chartProps.data.lead_comercial_attended_count,
          leftText: properties.chartProps.leadEvent.COMERCIALATTENDED,
          rightData: properties.chartProps.data.lead_converted_count,
          rightText: properties.chartProps.leadEvent.CONVERTED,
        })}
      </View>
      <View style={AnalyticsStyles.dataContainer}>
        <View>
          <Text style={AnalyticsStyles.dataText}>{properties.chartProps.data.total_leads}</Text>
          <Text>Total leads</Text>
        </View>
        <View>
          <Text style={AnalyticsStyles.dataText}>
            {properties.chartProps.data.lead_converted_count}
          </Text>
          <Text>{properties.chartProps.leadEvent.CONVERTED}</Text>
        </View>
        <View>
          <Text style={[AnalyticsStyles.dataText, AnalyticsStyles.pinkText]}>
            {isNaN(+conversion) ? 0 : conversion}%
          </Text>
          <Text style={AnalyticsStyles.pinkText}>Conversion</Text>
        </View>
      </View>
    </View>
  );
}
