import { create } from 'zustand';

type NotificationState = {
  count: number;
  setCount: (count: number) => void;
  readNotification: () => void;
};

export const useNotificationState = create<NotificationState>()((set) => ({
  count: 0,
  setCount: (count) => set({ count }),
  readNotification: () => set((state) => ({ count: state.count - 1 })),
}));
