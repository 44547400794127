import { View, TextInput as TextInputNative, TouchableOpacity } from 'react-native';
import { AccordionInputImplementation } from './accordion.implementation';
import { FieldsComponent } from '../../fields.component';
import { AccordionInputStyles } from './accordion.styles';
import { useState } from 'react';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function AccordionInput<ITEM>(properties: AccordionInputImplementation<ITEM>) {
  const [status, setStatus] = useState(false);
  return (
    <View style={[AccordionInputStyles.container, status && AccordionInputStyles.containerOpen]}>
      <TouchableOpacity onPress={() => setStatus(!status)} style={AccordionInputStyles.title}>
        <IconComponent
          name={properties.icon}
          containerstyle={AccordionInputStyles.titleIconContainer}
          iconStyle={AccordionInputStyles.titleIconStyle}
        />
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          text={`${properties.prefix}.${properties.name}.accordion`}
          style={AccordionInputStyles.titleText}
        />
        <IconComponent
          name={status ? 'angle-up' : 'angle-down'}
          containerstyle={AccordionInputStyles.titleAngleIconContainer}
          iconStyle={AccordionInputStyles.titleAngleIconStyle}
        />
      </TouchableOpacity>
      {status && (
        <View style={AccordionInputStyles.content}>
          <FieldsComponent<ITEM>
            prefix={properties.prefix}
            fields={properties.fields}
            errors={properties.errors}
            values={properties.values}
            onChange={properties.onChange}
            readonly={properties.readonly}
          />
        </View>
      )}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </View>
  );
}
