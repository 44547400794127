import { SceneComponent } from '@components/scene/scene.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { BoardRoutes } from '../../../board.routes';
import { BoardHeaderComponent } from '../../../components/header/board.header.component';
import { LeadDetailBoardRepository } from '../../../repositories/leadDetail/leadDetail.repository';
import { ItemByDepartmentYearsComparativeType } from '../components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { useTranslation } from 'react-i18next';
import { BoardFilterComponent } from './components/filter/filter.component';
import { LeadComparativeTable } from './components/leadComparativeTable/leadComparativeTable';
import { LeadComparativeChart } from './components/leadComparativeChart/leadComparativeChart';
import { LeadActiveInactiveChart } from './components/leadActiveInactive/leadActiveInactiveChart';
import { BoardStyles } from '../../common/board.styles';

export function OvoclinicLeadsScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'leads'>;
}) {
  const { t } = useTranslation();
  const [selectedClinic, setSelectedClinic] = useState<string>();
  const [clinics, setClinics] = useState<{ label: string; value: string }[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<{ label: string; value: string }[]>([]);

  const [firstYear, setFirstYear] = useState<number>(new Date().getFullYear() - 1);
  const [secondYear, setSecondYear] = useState<number>(new Date().getFullYear());

  const [selectedFunnel, setSelectedFunnel] = useState<string>();
  const [funnels, setFunnels] = useState<{ label: string; value: string }[]>([]);

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);
  //Indicador para saber cuando esta cargando por una peticion a network
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    new FunnelCrmRepository()
      .list({
        direction: 'asc',
        page: 0,
        limit: 100,
      })
      .then((funnels) => {
        setFunnels([
          {
            label: t('board.leads.totalLeads.filters.all'),
            value: 'all',
          },
          ...funnels.items.map((funnel) => {
            return {
              label: funnel.name,
              value: funnel.id,
            };
          }),
        ]);
      });
  }, []);

  useEffect(() => {
    setSelectedFunnel(funnels[0]?.value);
  }, [funnels]);

  useEffect(() => {
    new ClinicAccessRepository().list({}).then((clinics) => {
      setClinics(
        clinics.items.map((clinic) => {
          return {
            label: clinic.name,
            value: clinic.id,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    setSelectedClinic(clinics[0]?.value);
  }, [clinics]);

  useEffect(() => {
    if (!selectedClinic || !selectedFunnel) return;
    setLoading(true);
    new LeadDetailBoardRepository()
      .newLeadCountYearsComparative({
        clinicId: selectedClinic,
        firstYear,
        secondYear,
        funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
      })
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, [selectedClinic, firstYear, secondYear, selectedFunnel]);

  useEffect(() => {
    if (!selectedClinic) return;

    new LanguageAccessRepository().list({ limit: 100 }).then((languages) => {
      setLanguages(
        languages.items.map((language) => {
          return {
            label: language.name,
            value: language.id,
          };
        })
      );
    });
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedLanguage(languages[0]?.value);
  }, [languages]);

  return (
    <SceneComponent icon={'search'} prefix={'board.leads'} links={[]}>
      <View style={BoardStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.leads.totalLeads.title"
          description="board.leads.totalLeads.description"
        />
        <View style={BoardStyles.fiterContainer}>
          {/** SELECTO DE CLINICA */}
          <BoardFilterComponent
            width={250}
            title={t('board.leads.totalLeads.filters.clinic')}
            subtitle={t('board.leads.totalLeads.filters.clinicSubtitle') || ''}
            value={selectedClinic ?? ''}
            name="clinic"
            onChange={(value: any) => {
              setSelectedClinic(value.clinic);
            }}
            pick={async ({ id }) =>
              new ClinicAccessRepository()
                .pick({
                  id: selectedClinic ?? '',
                })
                .then((clinic) => {
                  return clinic.name ?? '';
                })
            }
            repository={async () => {
              return Promise.resolve(clinics);
            }}
          />
          {/** SELECTOR DE PRIMER AÑO/OPTION A  */}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.optionA')}
            subtitle={t('board.leads.totalLeads.filters.optionASubtitle') || ''}
            value={'' + firstYear}
            name="fistYear"
            onChange={(value: any) => {
              setFirstYear(+value.fistYear);
            }}
            options={Array.from({ length: 8 }, (_, i) => {
              i = i - 8;
              return {
                label: `${new Date().getFullYear() + i}`,
                value: `${new Date().getFullYear() + i}`,
              };
            })}
          />
          {/**SELECTOR SEGUNDO AÑO/OPCION B*/}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.optionB')}
            subtitle={t('board.leads.totalLeads.filters.optionBSubtitle') || ''}
            value={'' + secondYear}
            name="secondYear"
            onChange={(value: any) => {
              setSecondYear(+value.secondYear);
            }}
            options={Array.from({ length: 8 }, (_, i) => {
              i = i - 7;
              return {
                label: `${new Date().getFullYear() + i}`,
                value: `${new Date().getFullYear() + i}`,
              };
            })}
          />
          {/**SELECTOR DE PROCEDENCIA */}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.funnel')}
            subtitle={t('board.leads.totalLeads.filters.funnelSubtitle') || ''}
            value={selectedFunnel ?? ''}
            name={'selectedFunnel'}
            onChange={(value: any) => {
              setSelectedFunnel(value.selectedFunnel);
            }}
            pick={async ({ id }) =>
              new FunnelCrmRepository()
                .pick({
                  id,
                })
                .then((funnel) => {
                  return funnel.name ?? '';
                })
                .catch(() => t('board.leads.totalLeads.filters.all') ?? 'All')
            }
            repository={async () => {
              return Promise.resolve(funnels);
            }}
          />
        </View>

        {/** TABLA COMPARATIVA DE LEADS  */}
        <LeadComparativeTable
          clinicId={selectedClinic}
          funnelId={selectedFunnel}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
        />

        {/**DEPARTMENT */}

        <View style={[BoardStyles.fiterContainer, { marginTop: 50 }]}>
          <BoardFilterComponent
            width={250}
            title={t('board.leads.totalLeads.filters.department')}
            subtitle={t('board.leads.totalLeads.filters.departmentSubtitle') || ''}
            value={selectedLanguage ?? ''}
            name="department"
            onChange={(value: any) => {
              setSelectedLanguage(value.department);
            }}
            pick={async ({ id }) =>
              new LanguageAccessRepository()
                .pick({
                  languageId: selectedLanguage ?? '',
                })
                .then((language) => {
                  return language.name ?? '';
                })
            }
            repository={async () => {
              return Promise.resolve(languages);
            }}
          />
        </View>
        {/** GRAFICOS DE LEADS FILTRADOS POR DEPARTAMENTOS */}
        <LeadComparativeChart
          clinicId={selectedClinic}
          funnelId={selectedFunnel}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
          languages={languages}
          languagueId={selectedLanguage}
        />

        {/**Active/Inactive */}

        <LeadActiveInactiveChart
          clinicId={selectedClinic}
          funnelId={selectedFunnel}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
          languageId={selectedLanguage}
        />
      </View>
    </SceneComponent>
  );
}
