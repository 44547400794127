import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { UsersRoutes } from '../../users.routes';
import { UserAccessType } from '@human/access/types/user.access.type';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function CreateUserScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="user" prefix="access.users.create" width={700}>
      <FormComponent<UserAccessType & { signature: string }>
        prefix="access.users.create.form"
        padding
        repository={{
          send: (settings) => {
            return new UserAccessRepository()
              .create({
                item: settings.item,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            type: 'pair',
            fields: [
              {
                name: 'name',
                type: 'text',
                description: true,
                required: true,
                validations: ['name'],
              },
              {
                name: 'surname',
                type: 'text',
                required: true,
                description: true,
                validations: ['surname'],
              },
            ],
          },
          {
            name: 'username',
            required: true,
            icon: 'id-card',
            type: 'text',
            validations: ['username'],
            description: true,
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
          },
          {
            type: 'pair',
            fields: [
              {
                name: 'extension',
                icon: 'phone',
                required: true,
                type: 'text',
                description: true,
                validations: ['extension'],
              },
              {
                name: 'phone',
                icon: 'phone',
                type: 'text',
                validations: ['phone'],
                description: true,
              },
            ],
          },
          {
            name: 'email',
            icon: 'envelope',
            required: true,
            type: 'text',
            validations: ['email'],
            description: true,
          },

          {
            name: 'signature',
            icon: 'key',
            secure: true,
            type: 'text',
            required: true,
            validations: ['password'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
