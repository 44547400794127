import { NavigationComponent } from '@components/navigation/navigation.component';
import { OvopointsRoutes } from './ovopoints.routes';
import { UpdateOvopointsScreen } from './screens/update.ovopoints.screen';

const Navigation = NavigationComponent<OvopointsRoutes>();

export function OvopointsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="update" component={UpdateOvopointsScreen} />
    </Navigation.Navigator>
  );
}
