import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../../result.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { TabType } from '@components/tabs/tabs.types';
import { FormComponent } from '@components/form/form.component';
import { ResultOvoBankATPEventCrmRepository } from '@human/crm/repositories/resultOvoBankATPEvent/resultOvoBankATPEvent.repository';

export function UpdateResultOvoBankAtpAchievement(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();

  return {
    name: 'ovoBankAtpAchievement',
    icon: 'medal',
    content: (settings) => (
      <FormComponent<{
        converted: boolean;
        attended: boolean;
        appointed: boolean;
        comercialAttended: boolean;
        medicalAppointed: boolean;
      }>
        prefix="crm.results.update.formOvoBank"
        repository={{
          get: () => {
            return new ResultOvoBankATPEventCrmRepository().pick({
              resultId: properties.route.params.resultId,
              activityId: properties.route.params.activityId,
            });
          },
          send: (data) => {
            return new ResultOvoBankATPEventCrmRepository()
              .update({
                id: properties.route.params.resultId,
                item: { ...data.item, activityId: properties.route.params.activityId },
              })
              .then(() => {
                settings?.setIsEditing(false);
                showSuccess();
              });
          },
        }}
        fields={[
          {
            type: 'checkbox',
            name: 'appointed',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'comercialAttended',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'medicalAppointed',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'attended',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'converted',
            description: true,
          },
        ]}
      />
    ),
  };
}
