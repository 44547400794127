import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { FieldContainer } from '@components/form/components/fields/field.container';

/**
 * Create Action From Calendar Clinic Step
 * @description Este formmulario sirve para seleccionar una clínica para permitir
 * continuar con el proceso de creación de una tarea, su objetivo es desacoplar la necesidad
 * de tener una clinica seleccionada y simplificar el proceso de creación de una tarea.
 * @returns JSX.Element
 */
export function SelectClinicStep(properties: {
  clinicId?: string;
  onSuccess: (settings: { clinicId: string; clinicName: string }) => void;
}) {
  return (
    <FieldContainer
      prefix="crm.calendar.actions.create.steps.clinic.form"
      type="select"
      required
      padding
      name="clinicId"
      description
    >
      <SelectInput<{ clinicId: string }>
        type={'select'}
        name="clinicId"
        required
        value={{
          clinicId: properties.clinicId || undefined,
        }}
        description
        onChange={(value, option) => {
          if (!value.clinicId || !option) return;
          properties.onSuccess({ clinicId: value.clinicId, clinicName: option.label });
        }}
        pick={(props: { id: string }) => {
          return new ClinicAccessRepository().pick({ id: props.id }).then((clinic) => {
            return `${clinic.name}`;
          });
        }}
        repository={(properties: { search?: string }) => {
          return new ClinicAccessRepository()
            .list({ limit: 100, page: 0, search: properties.search, active: true })
            .then((clinics) => {
              return clinics.items.map((clinic) => ({
                label: clinic.name,
                value: clinic.id,
              }));
            });
        }}
      />
    </FieldContainer>
  );
}
