import { TabType } from '@components/tabs/tabs.types';
import { CrmSections } from '@human/crm/crm.sections';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../../activity.routes';
import { TableComponent } from '@components/table/table.component';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { ButtonComponent } from '@components/button/button.component';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ScrollView } from 'react-native';

export function UpdateActivityResultsPart(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'update'>;
}): TabType {
  return {
    name: 'results',
    icon: CrmSections.results.icon,
    content: () => (
      <ScrollView>
        <TableComponent<ResultCrmType, []>
          key={'results'}
          prefix={'crm.activities.update.results'}
          header={false}
          repository={(settings) =>
            new ResultCrmRepository().findByActivity({
              activityId: properties.route.params.activityId,
              direction: 'asc',
              page: settings.page,
              limit: settings.limit,
              search: settings.search,
            })
          }
          suffix="list"
          columns={[
            {
              type: 'icon',
              renderIcon: (row) => row.icon as IconType,
              renderColor: (row) => '#fff',
              renderBackground: (row) => row.color,
            },
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('resultUpdate', {
                  activityId: properties.route.params.activityId,
                  resultId: row.id,
                  level: 1,
                });
              },
            },
            {
              name: 'name',
              type: 'text',
              onRender: (row) => row.name,
              width: 200,
            },
          ]}
        />
        {hasPermissionUtilty({ name: 'crm.results.creator' }) && (
          <ButtonComponent
            icon="plus"
            prefix="crm.activities.update.results.add"
            onPress={() => {
              properties.navigation.navigate('resultCreate', {
                activityId: properties.route.params.activityId,
                level: 1,
              });
            }}
          />
        )}
      </ScrollView>
    ),
  };
}
