// Modules
import { StyleSheet } from 'react-native';

/**
 * HeatStyles Styles
 * @constant {StyleSheet} HeatStyles
 */
export const HeatStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FFFFFF0E',
    padding: 18,
  },
  dotted: {
    height: 1,
    borderTopWidth: 1,
    // borderStyle: 'dotted',
    marginVertical: 5,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  textContainer: {
    height: 24,
    width: 24,
    margin: 1,
    borderRadius: 3,
  },
  text: {
    fontSize: 9,
    color: '#fff',
    opacity: 0.5,
    textAlign: 'center',
    lineHeight: 24,
  },
});
