import { ServiceCrmType } from '@human/crm/types/service.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ServiceCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof ServiceCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: ServiceCrmType[];
    total: number;
  }> {
    return this.fetch('service/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        price: item.price,
        nextActivity: item.nextActivity,
        nextActivityDelay: item.nextActivityDelay,
        created: item.created,

        status: item.status,
      })),
    }));
  }

  create(properties: { item: Partial<ServiceCrmType> }): Promise<Partial<ServiceCrmType>> {
    return this.fetch('service/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { serviceId: string }): Promise<ServiceCrmType> {
    return this.fetch('service/pick', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      ...data,
      price: data.price.toString(),
    }));
  }

  update(properties: { id: string; item: Partial<ServiceCrmType> }) {
    return this.fetch(`service/update?id=${properties.item.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }

  disable(properties: { serviceId: string }): Promise<boolean> {
    return this.fetch('service/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  enable(properties: { serviceId: string }): Promise<boolean> {
    return this.fetch('service/enable', {
      method: 'PUT',
      params: properties,
    });
  }
}
