import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { AccessRoutes } from '@human/access/access.routes';
import { SettingAccessRepository } from '@human/access/repositories/setting/setting.repository';
import { SettingAccessType } from '@human/access/types/setting.access.type';
import { NavigationProp } from '@react-navigation/native';

export function SettingsScreen(properties: { navigation: NavigationProp<AccessRoutes> }) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="cog" width={660} flex={false} prefix="access.settings">
      <FormComponent<SettingAccessType>
        padding
        prefix="access.settings.form"
        repository={{
          get: () => {
            return new SettingAccessRepository().pick();
          },
          send: (data) => {
            return new SettingAccessRepository()
              .update({
                item: data.item,
              })
              .then(() => showSuccess());
          },
        }}
        fields={[
          {
            type: 'checkbox',
            name: 'loginEnabled',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'attemptLimitEnabled',
            description: true,
          },
          {
            type: 'checkbox',
            name: 'movementLimitEnabled',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
