// Modules
import { StyleSheet } from 'react-native';

/**
 * Header Styles
 * @constant {StyleSheet} HeaderStyles
 */
export const HeaderStyles = StyleSheet.create({
  container: {
    borderRadius: 5,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 663.84,
    padding: 20,
    marginBottom: 20,
    alignItems: 'center',
    gap: 20,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  iconContainer: {
    backgroundColor: 'rgb(237, 53, 145)',
    height: 70,
    minHeight: 70,
    minWidth: 70,
    width: 70,
    borderRadius: 40,
    outlineWidth: 50,
    outlineStyle: 'solid',
    outlineColor: 'rgba(237, 53, 145, 0.19)',
  },
  iconContainerMobile: {
    height: 50,
    minHeight: 50,
    minWidth: 50,
    width: 50,
  },
  iconStyle: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: 70,
    fontSize: 40,
  },
  iconStyleMobile: {
    fontSize: 25,
    lineHeight: 50,
  },
  title: {
    fontSize: 25,
    color: '#000',
  },
  titleMobile: {
    fontSize: 18,
  },
  description: {
    fontSize: 15,
    color: '#555',
  },
  descriptionMobile: {
    fontSize: 10,
  },
  linksContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  link: {
    backgroundColor: 'rgb(255, 255, 255)',

    paddingRight: 4,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 53.84,
    borderRadius: 100,
    flexDirection: 'row',
  },
  linkIconContainer: {
    backgroundColor: 'rgb(237, 53, 145)',
    borderRadius: 100,
    height: 40,
    width: 40,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  linkIconStyle: {
    color: 'rgb(255, 255, 255)',
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 38,
  },
  linkIconStyleMobile: {
    fontSize: 15,
    lineHeight: 28,
    fontWeight: 'bold',
  },
  linkText: {
    paddingRight: 15,
    textTransform: 'uppercase',
    color: 'rgb(237, 53, 145)',
    fontSize: 15,
  },
});
