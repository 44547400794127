import { NavigationComponent } from '@components/navigation/navigation.component';
import { LanguagesRoutes } from './languages.routes';
import { AccessRoutes } from '@human/access/access.routes';
import { RouteProp } from '@react-navigation/native';
import { ListLanguagesScreen } from './screens/list/list.languages.screen';
import { CreateLanguageScreen } from './screens/create/create.language.screen';
import { UpdateLanguageScreen } from './screens/update/update.language.screen';
import { EnableLanguageScreen } from './screens/enable/enable.language.screen';
import { DisableLanguageScreen } from './screens/disable/disable.language.screen';

const Navigation = NavigationComponent<LanguagesRoutes>();

export function LanguagesSection(properties: { route: RouteProp<AccessRoutes, 'languages'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListLanguagesScreen} />
      <Navigation.Screen name="create" component={CreateLanguageScreen} />
      <Navigation.Screen name="update" component={UpdateLanguageScreen} />
      <Navigation.Screen name="disable" component={DisableLanguageScreen} />
      <Navigation.Screen name="enable" component={EnableLanguageScreen} />
    </Navigation.Navigator>
  );
}
