import { AccessSections } from '@human/access/access.sections';
import { DepartmentAccessType } from '../../../../types/department.access.type';
import { TableComponent } from '@components/table/table.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../departments.routes';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { SceneComponent } from '@components/scene/scene.component';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'list'>;
}) {
  const { desktop } = useDeviceHook();

  const columns: ColumnProperties<DepartmentAccessType, ['name']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.departments.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('departmentUpdate', {
          id: row.id,
          level: 0,
          clinicId: row.clinicId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => row.name,
    },
    {
      name: 'clinic',
      type: 'clinic',
      width: 200,
      onRender: (row) => row.clinicName,
    },
    {
      name: 'responsible',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
    },
    {
      name: 'language',
      type: 'text',
      width: 200,
      onRender: (row) => row.languageName,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.departments.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'departmentDisable' : 'departmentEnable', {
          id: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  return (
    <SceneComponent icon={AccessSections.departments.icon} prefix={'access.departments.list'}>
      <TableComponent<DepartmentAccessType, ['name']>
        prefix="access.departments.list"
        suffix="list"
        search
        scrollable={!desktop ?? undefined}
        repository={(properties) => {
          return new DepartmentAccessRepository().list({
            direction: properties.direction,
            page: properties.page,
            limit: properties.limit,
            search: properties.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
