import { useTranslation } from 'react-i18next';
import { useToast } from 'react-native-toast-notifications';

export function useErrorToast() {
  const toast = useToast();
  const { t } = useTranslation();

  const showError = (label?: string) => {
    const auxLang = label !== undefined ? label : 'form.update.errorToast';
    toast.show(t(auxLang) || 'Error', {
      placement: 'top',
      type: 'error',
      duration: 6000,
    });
  };

  return { showError };
}
