import { ReasonCrmType } from '@human/crm/types/reason.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ReasonCrmRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    search?: string;
    limit: number;
    page: number;
    active?: boolean;
  }): Promise<{ items: ReasonCrmType[]; total: number }> {
    return this.fetch('reason/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          color: item.color,
          icon: item.icon,
          canceled: item.canceled,
          subject: item.subject,
          message: item.message,
          notify: item.notify,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { item: Partial<ReasonCrmType> }): Promise<Partial<ReasonCrmType>> {
    return this.fetch('reason/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async pick(properties: { id: string }): Promise<Partial<ReasonCrmType>> {
    return this.fetch(`reason/pick`, {
      method: 'GET',
      params: {
        id: properties.id,
      },
    });
  }

  async update({ item }: { item: Partial<ReasonCrmType> }): Promise<void> {
    return this.fetch(`reason/update`, {
      method: 'PUT',
      params: {
        id: item.id || '',
      },
      body: item,
    });
  }

  async updateNotification(properties: {
    id: string;
    notify: boolean;
    subject?: string;
    message?: string;
  }): Promise<void> {
    return this.fetch(`reason/notification/update`, {
      method: 'PUT',
      body: {
        reasonId: properties.id,
        notify: properties.notify,
        subject: properties.subject,
        message: properties.message,
      },
    });
  }

  async updateNextActivity(properties: {
    reasonId: string;
    nextActivityId?: string;
    nextActivityDelay?: number;
  }): Promise<void> {
    return this.fetch(`reason/nextActivity/update`, {
      method: 'PUT',
      body: {
        reasonId: properties.reasonId,
        nextActivityId: properties.nextActivityId,
        nextActivityDelay: properties.nextActivityDelay,
      },
    });
  }

  pickNextActivity(properties: { reasonId: string }): Promise<
    Partial<{
      nextActivityId: string;
      nextActivityDelay: number;
    }>
  > {
    return this.fetch(`reason/nextActivity/pick`, {
      method: 'GET',
      params: properties,
    }).then((data: any) => ({
      nextActivityId: data.nextActivityId,
      nextActivityDelay: data.nextActivityDelay,
    }));
  }

  async delete(properties: { id: string }): Promise<void> {
    return this.fetch(`reason/delete`, {
      method: 'PUT',
      params: {
        id: properties.id,
      },
    });
  }

  async enable(properties: { reasonId: string }): Promise<void> {
    return this.fetch(`reason/enable`, {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { reasonId: string }): Promise<void> {
    return this.fetch(`reason/disable`, {
      method: 'PUT',
      params: properties,
    });
  }
}
