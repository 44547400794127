import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CyclesRoutes } from '../../cycles.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CycleCrmType } from '@human/crm/types/cycle.crm.type';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { t } from 'i18next';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateCycleScreen(properties: {
  navigation: NavigationProp<CyclesRoutes>;
  route: RouteProp<CyclesRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon="sync-alt" prefix={'crm.cycles.update'}>
      <FormComponent<CycleCrmType>
        prefix={'crm.cycles.update.form'}
        padding
        readonly={!hasPermissionUtilty({ name: 'crm.cycles.editor' })}
        repository={{
          send: (data) => {
            return new CycleCrmRepository()
              .update({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
          get: () => {
            return new CycleCrmRepository()
              .pick({
                cycleId: properties.route.params.cycleId,
              })
              .then((response) => response);
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'complexity',
            description: true,
            required: true,
            options: [
              {
                value: 'HIGH',
                label: t('common.complexity.HIGH'),
              },
              {
                value: 'LOW',
                label: t('common.complexity.LOW'),
              },
            ],
          },
          {
            type: 'text',
            name: 'code',
            description: true,
            required: true,
            validations: ['code'],
          },
          {
            type: 'text',
            name: 'description',
            description: true,
            lines: 6,
            validations: ['description'],
          },
        ]}
      />
    </ModalComponent>
  );
}
