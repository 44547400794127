import { View, Text, TouchableOpacity } from 'react-native';
import { ToggleProperties } from './toggle.properties';
import { ToggleStyles } from './toggle.styles';

export function ToggleComponent(properties: ToggleProperties) {
  return (
    <View style={ToggleStyles.container}>
      <Text style={ToggleStyles.text}>{properties.text}</Text>
      <TouchableOpacity
        style={[
          ToggleStyles.toggleContainer,
          properties.value
            ? ToggleStyles.toggleContainerActive
            : ToggleStyles.toggleContainerInactive,
        ]}
        onPress={properties.onChange}
      >
        <View
          style={[
            ToggleStyles.circle,
            properties.value ? ToggleStyles.circleActive : ToggleStyles.circleInactive,
          ]}
        />
      </TouchableOpacity>
    </View>
  );
}
