import { ChannelsRoutes } from './channels.routes';
import { ListChannelScreen } from './screens/list/list.channel.screen';
import { CreateChannelScreen } from './screens/create/create.channel.screen';
import { UpdateChannelScreen } from './screens/update/update.channel.screen';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { DisableChannelScreen } from './screens/disable/disable.channel.screen';
import { EnableChannelScreen } from './screens/enable/enable.channel.screen';

const Navigation = NavigationComponent<ChannelsRoutes>();
export function ChannelsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListChannelScreen} />
      <Navigation.Screen name="create" component={CreateChannelScreen} />
      <Navigation.Screen name="disable" component={DisableChannelScreen} />
      <Navigation.Screen name="enable" component={EnableChannelScreen} />
      <Navigation.Screen name="update" component={UpdateChannelScreen} />
    </Navigation.Navigator>
  );
}
