import { ActionsRoutes } from '../../../actions.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { FormComponent } from '@components/form/form.component';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { useUserState } from '@states/user/user.state';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';

export function UpdateActionNextResultPart(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionNextResultUpdate'>;
}) {
  const { user } = useUserState();

  return (
    <ModalComponent
      confirmationMessage
      level={properties.route.params.level}
      flex={false}
      icon={CrmSections.tasks.icon}
      prefix="crm.leads.update.actions.create"
    >
      <FormComponent<Partial<ActionCrmType>>
        padding
        prefix="crm.leads.update.actions.create.form"
        repository={{
          send: (settings) => {
            if (properties.route.params.patientId) {
              return new ActionCrmRepository()
                .createPatientAction({
                  item: {
                    ...settings.item,
                    leadId: properties.route.params.leadId,
                    clinicId: properties.route.params.clinicId,
                    patientId: properties.route.params.patientId,
                  },
                })
                .then(() => {
                  properties.navigation.goBack();
                });
            }
            return new ActionCrmRepository()
              .create({
                item: {
                  ...settings.item,
                  leadId: properties.route.params.leadId,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
          get: async () => {
            const contract = await new ContractAccessRepository().findByClinic({
              userId: user.id,
              clinicId: properties.route.params.clinicId,
            });
            return {
              started: properties.route.params.started,
              activityId: properties.route.params.activityId,
              responsibleId: contract.id,
            };
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'subject',
            description: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'activityId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return `${activity.name}`;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository()
                .list({
                  direction: 'asc',
                  limit: 100,
                  order: 'id',
                  page: 0,
                  search,
                  active: true,
                })
                .then((activities) =>
                  activities.items.map((activity) => ({
                    label: activity.name,
                    value: activity.id,
                  }))
                );
            },
          },
          {
            type: 'select',
            name: 'responsibleId',
            required: true,
            description: true,
            pick: (props: { id: string }) => {
              return new ContractAccessRepository()
                .pick({ userId: user.id, contractId: props.id })
                .then((contract) =>
                  new CoworkerCrmRepository()
                    .pick({
                      coworkerId: contract.id,
                      clinicId: properties.route.params.clinicId,
                    })
                    .then((coworker) => {
                      return `${coworker.name} ${coworker.surname}`;
                    })
                );
            },
            repository: ({ search }) => {
              return new CoworkerCrmRepository()
                .list({
                  clinicId: properties.route.params.clinicId,
                  direction: 'asc',
                  limit: 100,
                  order: 'email',
                  page: 0,
                  search,
                  active: true,
                })
                .then((leads) =>
                  leads.items.map((user) => ({
                    label: `${user.name} ${user.surname}`,
                    value: user.id,
                  }))
                );
            },
          },
          {
            type: 'date',
            name: 'started',
            required: true,
            description: true,
            format: 'YYYY-MM-DD HH:mm:ss',
          },
        ]}
      />
    </ModalComponent>
  );
}
