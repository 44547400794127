import { ItemTableType } from '@components/table/table.component';
import { TextComponent } from '../../../text/text.component';
import { OrderTableType } from '../../table.properties';
import { ColumnImplementation, ColumnProperties } from './column.properties';
import { BarColumnComponent } from './components/bar/bar.column.component';
import { ClinicColumnComponent } from './components/clinic/clinic.column.component';
import { CodeColumnComponent } from './components/code/code.column.component';
import { ColorColumnComponent } from './components/color/color.column.component';
import { DateColumnComponent } from './components/date/date.column.component';
import { IconColumnComponent } from './components/icon/icon.column.component';
import { NumberColumnComponent } from './components/number/number.column.component';
import { PriceColumnComponent } from './components/price/price.column.component';
import { StatusColumnComponent } from './components/status/status.column.component';
import { TextColumnComponent } from './components/text/text.column.component';
import { UserColumnComponent } from './components/user/user.column.component';
import { MultipleBadgeColumnComponent } from './components/mutipleBadge/multipleBadge.column.component';
import { ContactTimeColumnComponent } from './components/contactTime/contactTime.column.component';
import { TaskStatusColumnComponent } from './components/taskStatus/taskStatus.column.component';
import { SelectableColumnComponent } from './components/selectable/selectable.column.component';
import { IconTextColumnComponent } from './components/iconText/iconText.column.component';
import { ElementColumnComponent } from './components/element/element.column.component';
import { RiskIndicatorComponent } from './components/riskIndicator/riskIndicator.component';
import { ButtonColumnComponent } from './components/button/button.column.component';

/**
 * Column Component
 * @description Componente que renderiza una columna de la tabla.
 * @param {ColumnImplementation<ITEM, ORDER>} properties
 * @returns {JSX.Element}
 */

export function ColumnComponent<ITEM extends ItemTableType, ORDER extends OrderTableType<ITEM>>(
  properties: ColumnImplementation<ITEM, ORDER>
) {
  if (properties.component?.type === 'icon') {
    return <IconColumnComponent<ITEM> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'color') {
    return <ColorColumnComponent<ITEM> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'text') {
    return <TextColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'status') {
    return <StatusColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'date') {
    return <DateColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'number') {
    return <NumberColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'code') {
    return <CodeColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'multipleBadge') {
    return (
      <MultipleBadgeColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />
    );
  }

  if (properties.component?.type === 'contactTime') {
    return (
      <ContactTimeColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />
    );
  }

  if (properties.component?.type === 'bar') {
    return (
      <BarColumnComponent<ITEM, ORDER>
        {...properties.component}
        item={properties.item}
        items={properties.items}
      />
    );
  }
  if (properties.component?.type === 'clinic') {
    return <ClinicColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'price') {
    return <PriceColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'user') {
    return <UserColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component.type === 'taskStatus') {
    return (
      <TaskStatusColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />
    );
  }
  if (properties.component.type === 'selectable') {
    return (
      <SelectableColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />
    );
  }
  if (properties.component.type === 'iconText') {
    return (
      <IconTextColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />
    );
  }
  if (properties.component.type === 'element') {
    return <ElementColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }

  if (properties.component.type === 'riskIndicator') {
    return <RiskIndicatorComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }
  if (properties.component?.type === 'button') {
    return <ButtonColumnComponent<ITEM, ORDER> {...properties.component} item={properties.item} />;
  }

  return (
    <TextComponent
      text={`Not implemented yet`}
      style={{
        color: 'red',
      }}
    />
  );
}
