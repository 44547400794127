import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ResultsRoutes } from '../../result.routes';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';

export function DisableResultScreen(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultDisable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.results.disable">
      <FormComponent
        prefix="crm.results.disable.form"
        padding
        repository={{
          send: () => {
            return new ResultCrmRepository()
              .disable({
                resultId: properties.route.params.resultId,
                activityId: properties.route.params.activityId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
