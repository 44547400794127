import { ReceiptCrmType } from '@human/crm/types/receipt.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ReceiptDashboardCrmType } from '@human/crm/types/receipt.dashboard.crm.type';

export class ReceiptCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof ReceiptCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    patientId: string;
    clinicId: string;
  }): Promise<{
    items: ReceiptCrmType[];
    total: number;
  }> {
    return this.fetch('receipt/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        total: data.total,
        items: data.items,
      };
    });
  }

  async listLast(): Promise<{
    items: ReceiptDashboardCrmType[];
    total: number;
  }> {
    return this.fetch('receipt/find-last', {
      method: 'GET',
    }).then((data) => {
      return {
        total: data.total,
        items: data.items,
      };
    });
  }

  async create(properties: { item: Partial<ReceiptCrmType> }): Promise<Partial<ReceiptCrmType>> {
    return this.fetch('receipt/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { id: string; patientId: string; clinicId: string }): Promise<ReceiptCrmType> {
    return this.fetch('receipt/pick', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: {
    id: string;
    patientId: string;
    clinicId: string;
    item: Partial<ReceiptCrmType>;
  }) {
    return this.fetch(`receipt/update`, {
      method: 'PUT',
      params: {
        id: properties.id,
        patientId: properties.patientId,
        clinicId: properties.clinicId,
      },
      body: properties.item,
    }).then(() => true);
  }
}
