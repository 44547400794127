import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ActionAlertCrmRepository extends AuthorizedRepository {
  async list(properties: {
    clinicId: string;
    leadId?: string;
    patientId?: string;
    actionId: string;
  }): Promise<{
    items: { actionAlertId: string; alertTime: number; expectedAlarmTime: Date }[];
    total: number;
  }> {
    return this.fetch('action/alert/find', {
      method: 'GET',
      params: properties,
    }).then((alerts) => ({
      items: alerts.items.map(
        (item: { actionAlertId: string; alertTime: number; expectedAlarmTime: Date }) => ({
          actionAlertId: item.actionAlertId,
          alertTime: item.alertTime,
          expectedAlarmTime: new Date(item.expectedAlarmTime),
        })
      ),
      total: alerts.total,
    }));
  }

  async pick(properties: {
    leadId?: string;
    clinicId: string;
    patientId?: string;
    actionId: string;
    actionAlertId: string;
  }): Promise<{
    actionAlertId: string;
    alertTime: number;
    expectedAlarmTime: Date;
  }> {
    return this.fetch('action/alert/pick', {
      method: 'GET',
      params: properties,
    }).then((alert) => ({
      actionAlertId: alert.actionAlertId,
      alertTime: alert.alertTime,
      expectedAlarmTime: new Date(alert.expectedAlarmTime),
    }));
  }

  async create(properties: {
    clinicId: string;
    leadId?: string;
    patientId?: string;
    actionId: string;
    alertTime: number;
  }): Promise<void> {
    return this.fetch('action/alert/create', {
      method: 'POST',
      body: properties,
    });
  }

  async update(properties: {
    clinicId: string;
    leadId?: string;
    patientId?: string;
    actionId: string;
    actionAlertId: string;
    alertTime: number;
  }): Promise<void> {
    return this.fetch('action/alert/update', {
      method: 'PUT',
      body: properties,
    });
  }

  async delete(properties: {
    clinicId: string;
    leadId?: string;
    patientId?: string;
    actionId: string;
    actionAlertId: string;
  }): Promise<void> {
    return this.fetch('action/alert/delete', {
      method: 'DELETE',
      body: properties,
    });
  }
}
