import { createStackNavigator } from '@react-navigation/stack';

// Definimos las opciones predeterminadas para el modal
const modalOptions = {
  presentation: 'transparentModal',
};

// Ahora, en el componente NavigatorComponent, podemos utilizar ModalWrapper como lo deseamos
export function NavigationComponent<ROUTES extends { [key: string]: any }>() {
  const Stack = createStackNavigator<ROUTES>();
  Stack.Navigator.defaultProps = {
    screenOptions: {
      headerShown: false,
      presentation: 'transparentModal',
    },
  };

  return {
    Navigator: Stack.Navigator,
    Screen: Stack.Screen,
    Modal: Stack.Screen,
  };
}
