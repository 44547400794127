// Modules
import { StyleSheet } from 'react-native';

/**
 * AnalyticsStyles Styles
 * @constant {StyleSheet} AnalyticsStyles
 */
export const AnalyticsStyles = StyleSheet.create({
  container: {
    gap: 10,
    padding: 15,
    paddingBottom: 10,
    flexDirection: 'row',
  },
  infoFilterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    paddingTop: 0,
    gap: 7,
  },
  infoIcon: {
    color: 'rgb(186, 30, 137)',
    fontSize: 15,
    lineHeight: 14,
  },
  filterText: {
    color: 'rgb(255, 255, 255)',
    fontSize: 12,
    opacity: 0.5,
  },
  chartContainer: {
    backgroundColor: `rgb(36, 36, 36)`,
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
    shadowColor: 'rgb(165, 30, 186)',
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 0.5,
    shadowRadius: 210,
    elevation: 5,
  },
  chartHeader: {
    backgroundColor: '#111',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
  },
  chartHeaderTitleText: {
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
    fontSize: 8,
    textTransform: 'uppercase',
    paddingVertical: 10,
  },
  chartHeaderPercentageText: {
    marginLeft: 'auto',
    textAlign: 'center',
    fontSize: 8,
    textTransform: 'uppercase',
    paddingVertical: 10,
  },
  chartHeaderPercentageTextPositive: {
    color: 'rgb(0, 255, 0)',
  },
  chartHeaderPercentageTextNegative: {
    color: 'rgb(255, 0, 25)',
  },
  chartHeaderIconContainer: {
    borderWidth: 2,
    borderColor: '#FFFFFF16',
    marginLeft: 5,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    height: 20,
    width: 20,
    borderRadius: 10,
  },
  chartHeaderIconContainerPositive: {
    backgroundColor: 'rgba(0, 255, 85, 0.2)',
  },
  chartHeaderIconContainerNegative: {
    backgroundColor: 'rgba(169, 55, 66, 0.27)',
  },
  chartHeaderIcon: {
    textAlign: 'center',
    fontSize: 12,
  },
  chartHeaderIconPositive: {
    color: 'rgb(0, 255, 0)',
  },
  chartHeaderIconNegative: {
    color: 'rgb(255, 0, 25)',
  },
  chartWeekContainer: {
    flexDirection: 'row',
    padding: 15,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  chartWeekText: {
    color: 'rgb(255, 255, 255)',
  },
  chartWeekIcon: {
    color: 'rgb(255, 255, 255)',
    fontSize: 15,
    opacity: 0.5,
    lineHeight: 14,
  },
  barsContainer: {
    gap: 5,
    padding: 0,
    paddingBottom: 10,
  },
  barThisWeekContainer: {
    backgroundColor: 'rgba(237, 53, 145, 0.42)',
    height: 4,
  },
  barThisWeek: {
    height: 8,
    width: 8,
    borderRadius: 100,
    backgroundColor: 'rgb(237, 53, 145)',
    position: 'absolute',
    top: -2,
    right: -2,
  },
  barLastWeekContainer: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    height: 4,
  },
  barLastWeek: {
    height: 8,
    width: 8,
    borderRadius: 100,
    backgroundColor: 'rgb(142, 142, 142)',
    position: 'absolute',
    top: -2,
    right: -2,
  },
});
