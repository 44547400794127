import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ProgramCycleCrmRepository extends AuthorizedRepository {
  async list(properties: { search?: string; programId: string }): Promise<{
    items: {
      cycleId: string;
      cycleName: string;
      cycleCreated: Date;
    }[];
    total: number;
  }> {
    return this.fetch('programCycle/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          cycleId: item.id,
          cycleName: item.name,
          cycleCreated: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { programId: string; cycleId?: string }): Promise<void> {
    return this.fetch(`programCycle/create`, {
      method: 'POST',
      body: properties,
    });
  }

  async delete(properties: { programId: string; cycleId: string }): Promise<void> {
    return this.fetch(`programCycle/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
