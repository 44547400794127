/**
 * is UUID
 * @description Checks if the value is a UUID
 * @param value
 * @returns boolean
 */
export function isUUID(value: unknown): value is string {
  if (typeof value !== 'string') return false;

  return true;
}
