import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ResultIntegrationEventCrmRepository extends AuthorizedRepository {
  pick(properties: { resultId: string; activityId: string }): Promise<{
    landbotAppointed: boolean;
  }> {
    return this.fetch('result/pickIntegrations', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: {
    item: Partial<{
      activityId: string;
      resultId: string;
      landbotAppointed: boolean;
    }>;
  }) {
    return this.fetch(`result/updateIntegrations`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }
}
