import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ActionAlertCrmRepository } from '@human/crm/repositories/action/alert/action.alert.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { AlertsRoutes } from '../../alerts.routes';

export function UpdateAlertScreen(properties: {
  navigation: NavigationProp<AlertsRoutes>;
  route: RouteProp<AlertsRoutes, 'alertUpdate'>;
}) {
  return (
    <ModalComponent
      icon="bell"
      prefix="crm.actions.update.alerts.update"
      level={properties.route.params.level}
    >
      <FormComponent<{
        actionAlertId: string;
        alertTime: number;
        expectedAlarmTime: Date;
      }>
        prefix="crm.actions.update.alerts.update.form"
        padding
        repository={{
          send: (data) => {
            return new ActionAlertCrmRepository()
              .update({
                actionId: properties.route.params.actionId,
                actionAlertId: properties.route.params.actionAlertId,
                alertTime: data.item.alertTime ?? 0,
                clinicId: properties.route.params.clinicId,
                leadId: properties.route.params.leadId,
                patientId: properties.route.params.patientId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
          get: () => {
            return new ActionAlertCrmRepository()
              .pick({
                actionId: properties.route.params.actionId,
                actionAlertId: properties.route.params.actionAlertId,
                clinicId: properties.route.params.clinicId,
                leadId: properties.route.params.leadId,
                patientId: properties.route.params.patientId,
              })
              .then((item) => {
                return item;
              });
          },
        }}
        fields={[
          {
            type: 'number',
            name: 'alertTime',
            description: true,
            required: true,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
