import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { SessionStyles } from './session.styles';
import { AgoComponent } from '@components/ago/ago.component';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useEffect, useState } from 'react';
import { SessionType } from './type/session.type';
import { SessionRepository } from './repository/session.repository';
import { useUserState } from '@states/user/user.state';
import { WidgetComponent } from '../widget/widget.component';

export function SessionComponent() {
  const [sessions, setSessions] = useState<SessionType[]>();
  const [count, setCount] = useState<number>();

  const { user } = useUserState();

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = () => {
    new SessionRepository().find().then((res) => {
      setSessions(res.items);
      setCount(res.total);
    });
  };

  return (
    <WidgetComponent title={'sessions.title'} counter={count} icon="shield-alt">
      <ScrollView
        style={SessionStyles.scroll}
        contentContainerStyle={SessionStyles.scrollContainer}
      >
        {sessions &&
          sessions.map((item, index) => {
            return (
              <View key={index} style={SessionStyles.sessionContainer}>
                <TouchableOpacity
                  onPress={() => {
                    if (user.sessionId === item.id) return;

                    new SessionRepository()
                      .delete({ sessionId: item.id })
                      .then(() => getSessions());
                  }}
                >
                  <IconComponent
                    name={user.sessionId === item.id ? 'check-circle' : 'trash'}
                    containerstyle={SessionStyles.sessionIconContainer}
                    iconStyle={[
                      SessionStyles.sessionIcon,
                      user.sessionId === item.id
                        ? SessionStyles.sessionIconSuccess
                        : SessionStyles.sessionIconDanger,
                    ]}
                  />
                </TouchableOpacity>
                <View style={SessionStyles.labelDescriptionContainer}>
                  <TextComponent
                    bold
                    translate
                    text={'access.users.create.form.expiration.title'}
                    style={SessionStyles.label}
                  />
                  <TextComponent
                    text={item.expires.toLocaleString()}
                    style={SessionStyles.description}
                  />
                </View>
                <AgoComponent date={item.created} style={SessionStyles.ago} />
              </View>
            );
          })}
      </ScrollView>
    </WidgetComponent>
  );
}
