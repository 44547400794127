import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TransferTaskRoutes } from '../../transferTask.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { useTranslation } from 'react-i18next';

export function ConfirmTransferTaskScreen(props: {
  navigation: NavigationProp<TransferTaskRoutes>;
  route: RouteProp<TransferTaskRoutes, 'confirmTransfer'>;
}) {
  const { showSuccess } = useSuccessToast();
  const { t } = useTranslation();

  return (
    <ModalComponent icon="sync-alt" prefix="crm.transferTask.confirm">
      <FormComponent<{ responsibleId: string }>
        prefix="crm.transferTask.confirm.form"
        padding
        repository={{
          send: (data) => {
            return new ActionCrmRepository()
              .transferActions({
                clinicId: props.route.params.clinicId,
                actionIds: props.route.params.actionsIds,
                responsibleId: data.item.responsibleId ?? '',
              })
              .then(() => {
                showSuccess(t('crm.transferTask.confirm.success') || '');
                props.route.params.onSuccess();
                props.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'responsibleId',
            description: true,
            required: true,
            options: [],
            repository: (settings) =>
              new CoworkerCrmRepository()
                .list({
                  clinicId: props.route.params.clinicId,
                  page: 0,
                  search: settings.search,
                  limit: 100,
                  active: true,
                })
                .then((data) => {
                  return data.items.map((item) => ({
                    value: item.id,
                    label: `${item.name} ${item.surname}`,
                  }));
                }),
          },
        ]}
      />
    </ModalComponent>
  );
}
