// Modules
import { StyleSheet } from 'react-native';

/**
 * Footer Styles
 * @constant {StyleSheet} FootersStyles
 */
export const FootersStyles = StyleSheet.create({
  footer: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -12,
    },
    shadowOpacity: 0.4,
    shadowRadius: 163.84,
    backgroundColor: '#00000013',
    borderTopColor: 'rgba(0, 0, 0, 0.1)',
    borderTopWidth: 1,
  },
  profileContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    height: 50,
    borderRadius: 5,
    overflow: 'hidden',
    width: 50,
    opacity: 1,
    zIndex: 1,
  },
  textContainer: { width: '60%', overflow: 'hidden' },
  profileImage: {
    height: 50,
    borderRadius: 10,
    width: 50,
  },
  userIconContainer: {
    backgroundColor: 'rgb(237, 53, 145)',
    borderRadius: 5,
  },
  userIcon: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#fff',
  },
  profileBorder: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    borderColor: 'rgba(237, 53, 145, 0.5)',
    borderWidth: 4,
  },
  profileName: {
    color: 'rgb(26, 26, 26)',
    fontSize: 18,
    lineHeight: 18,
  },
  profileEmail: {
    color: 'rgb(126, 126, 126)',
    fontSize: 13,
  },
  sessionExpiration: {
    color: 'rgb(126, 126, 126)',
    fontSize: 13,
    marginTop: 5,
  },
  mobileSizeText: {
    fontSize: 12,
  },
  mobileSizeIconContainer: {
    height: 30,
    width: 30,
  },
  mobileSizeIcon: {
    fontSize: 20,
  },
});
