import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { MaintenanceCrmType } from '@human/crm/types/maintenance.crm.type';
import { dayjs } from '@utils/dayjs/dayjs.config';

export class MaintenanceCrmRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: MaintenanceCrmType[]; total: number }> {
    return this.fetch('maintenance/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: MaintenanceCrmType) => ({
          maintenanceId: item.maintenanceId,
          treatmentId: item.treatmentId,
          serviceId: item.serviceId,
          serviceName: item.serviceName,
          status: item.status,
          amount: item.amount,
          paymentStatus: item.paymentStatus,
          patientId: item.patientId,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          contactName: item.contactName,
          contactSurname: item.contactSurname,
          estimatedPaymentDate: new Date(item.estimatedPaymentDate),
        })),
        total: data.total,
      };
    });
  }

  async listByPatient(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<{ items: MaintenanceCrmType[]; total: number }> {
    return this.fetch('maintenance/findByTreatment', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: MaintenanceCrmType) => ({
          maintenanceId: item.maintenanceId,
          serviceId: item.serviceId,
          serviceName: item.serviceName,
          status: item.status,
          amount: item.amount,
          paymentStatus: item.paymentStatus,
          estimatedPaymentDate: new Date(item.estimatedPaymentDate),
        })),
        total: data.total,
      };
    });
  }

  async create(properties: {
    serviceId: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<void> {
    return this.fetch('maintenance/create', {
      method: 'POST',
      body: {
        serviceId: properties.serviceId,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
      },
    });
  }

  async pick(properties: {
    maintenanceId: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<Partial<MaintenanceCrmType>> {
    return this.fetch('maintenance/pick', {
      method: 'GET',
      params: properties,
    }).then((item) => ({
      ...item,
      amount: item.amount.toString(),
    }));
  }

  async update(properties: {
    item: Partial<MaintenanceCrmType>;
    clinicId: string;
    patientId: string;
    treatmentId: string;
    maintenanceId: string;
  }): Promise<void> {
    return this.fetch('maintenance/update', {
      method: 'PUT',
      body: {
        ...properties.item,
        estimatedPaymentDate: properties.item.estimatedPaymentDate
          ? dayjs(properties.item.estimatedPaymentDate).tz('Europe/Madrid').toISOString()
          : undefined,
        amount: properties.item.amount?.toString(),
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
        maintenanceId: properties.maintenanceId,
      },
    });
  }

  async enable(properties: {
    maintenanceId: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<void> {
    return this.fetch('maintenance/enable', {
      method: 'PUT',
      body: properties,
    });
  }

  async disable(properties: {
    maintenanceId: string;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<void> {
    return this.fetch('maintenance/disable', {
      method: 'PUT',
      body: properties,
    });
  }
}
