import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { CreateActionProperties } from './create.action.properties';
import { useState } from 'react';

import { FormActionScreen } from '../create/form.action.screen';
import { ActionComponent } from '@components/action/action.component';
import { SelectClinicStep } from '@steps/clinic/selectClinic.step';
import { SelectLeadStep } from '@steps/lead/selectLead.step';

export function CreateFromCalendarScreen(properties: CreateActionProperties) {
  /**
   * Clinic Selected
   * @description Para simplificar los formularios almacenamos el estado de la clínica
   * para facilitar la carga de los selectores dependientes de la clínica.
   */
  const [clinicId, setClinicId] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();

  /**
   * Lead Selected
   * @description Para simplificar los formularios almacenamos el estado del lead
   * para facilitar la carga de los selectores dependientes del lead.
   */
  const [leadId, setLeadId] = useState<string>();
  const [leadName, setLeadName] = useState<string>();

  return (
    <ModalComponent
      level={1}
      flex={false}
      icon={CrmSections.tasks.icon}
      prefix="crm.leads.update.actions.create"
    >
      {!clinicId ? (
        <SelectClinicStep
          clinicId={clinicId}
          onSuccess={({ clinicId, clinicName }) => {
            setClinicId(clinicId);
            setClinicName(clinicName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setClinicId(undefined);
            setLeadId(undefined);
          }}
          prefix="crm.leads.update.actions.create.steps.clinic.form"
          bubble={clinicName}
        />
      )}
      {!leadId && clinicId ? (
        <SelectLeadStep
          clinicId={clinicId}
          onSuccess={({ leadId, leadName }) => {
            setLeadId(leadId);
            setLeadName(leadName);
          }}
        />
      ) : clinicId ? (
        <ActionComponent
          onPress={() => setLeadId(undefined)}
          prefix="crm.leads.update.actions.create.steps.lead.form"
          bubble={leadName}
        />
      ) : null}
      {clinicId && leadId && (
        <FormActionScreen
          {...properties}
          started={properties.route.params.started}
          clinicId={clinicId}
          leadId={leadId}
        />
      )}
    </ModalComponent>
  );
}
