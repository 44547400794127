import { View, Text } from 'react-native';
import { CostPerLeadStyles } from './costPerLead.styles';
import { useTranslation } from 'react-i18next';
import { CostPerLeadProperties } from './costPerLead.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

export function CostPerLeadComponent(properties: CostPerLeadProperties) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();
  return (
    <View style={[CostPerLeadStyles.container, phone && { justifyContent: 'center' }]}>
      <Text style={CostPerLeadStyles.title}>
        {t('board.marketing.leadsPerFunnel.costPerLead').toLocaleUpperCase()}
      </Text>
      <Text style={CostPerLeadStyles.subtitle}>
        {t('board.marketing.leadsPerFunnel.costPerLead')}
      </Text>
      <Text style={CostPerLeadStyles.data}>{properties.cost}€</Text>
    </View>
  );
}
