import { TouchableOpacity, View } from 'react-native';
import { TextComponent } from '../text/text.component';
import { useNavigation } from '@react-navigation/native';
import { LogotypeStyles } from './logotype.styles';
import { LogotypeProperties } from './logotype.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Logotype Component
 * @description Logotipo dinámico dependiente de la marca comercial en la que se
 * encuentra el usuario. A su vez presenta la corporación autora de la marca.
 *
 * @param {LogotypeProperties} properties
 * @returns {JSX.Element}
 */

export function LogotypeComponent(properties: LogotypeProperties) {
  const { device } = useDeviceHook();
  return (
    <TouchableOpacity
      style={[
        LogotypeStyles.container,
        (device === 'phone' || device === 'tablet') && LogotypeStyles.littleContainer,
      ]}
    >
      <View style={LogotypeStyles.desklogoContainer}>
        <TextComponent text="OVO" light style={LogotypeStyles.logo} />
        <TextComponent text="DESK" bold style={LogotypeStyles.desk} />
      </View>
      <View style={LogotypeStyles.byLogotypeContainer}>
        <TextComponent text="by" light style={LogotypeStyles.byText} />
        <TextComponent
          translate
          text={`logotype.${process.env.EXPO_PUBLIC_WORKSPACE || properties.type}`}
          bold
          style={LogotypeStyles.logoTypeText}
        />
      </View>
    </TouchableOpacity>
  );
}
