import { StyleSheet } from 'react-native';

export const CostPerLeadStyles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 20,
    gap: 5,
    flex: 1,
    borderRadius: 6,
  },
  title: {
    fontSize: 11,
    color: '#999999',
    fontWeight: '300',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: '500',
  },
  data: {
    fontSize: 36,
    fontWeight: '700',
  },
});
