import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
import { TechniqueRoutes } from '../../technique.routes';
import { useDeviceHook } from '@hooks/device/device.hook';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListTechniqueScreen(properties: {
  navigation: NavigationProp<TechniqueRoutes>;
  route: RouteProp<TechniqueRoutes, 'list'>;
}) {
  const { desktop } = useDeviceHook();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.techniques.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('techniqueCreate'),
    });
  }

  const columns: ColumnProperties<TechniqueCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.techniques.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          techniqueId: row.id,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          techniqueId: row.id,
        });
      },
    },
    {
      name: 'price',
      type: 'price',
      width: 140,
      onRender: (row) => {
        return row.price;
      },
      onCurrency: (row) => {
        return '€';
      },
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.techniques.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          techniqueId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.techniques.icon} prefix={'crm.techniques.list'} links={links}>
      <TableComponent<TechniqueCrmType, []>
        prefix={'crm.techniques.list'}
        suffix={'list'}
        scrollable={!desktop}
        repository={(settings) =>
          new TechniqueCrmRepository().list({
            search: settings.search,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
