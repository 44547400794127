// Modules
import { StyleSheet } from 'react-native';

/**
 * Checkbox Styles
 * @constant {StyleSheet} CheckboxStyles
 */
export const CheckboxStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    alignItems: 'center',
    flexDirection: 'row',
    height: 42,
    backgroundColor: '#fff',
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  iconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  iconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  label: {
    padding: 5,
    fontSize: 13,
  },
  inputReadonly: {
    opacity: 0.4,
  },
  labelIcon: {
    marginLeft: 40,
  },
  labelNoIcon: {
    marginLeft: 10,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    flex: 1,
  },
  checkBoxIconContainer: {
    height: 40,
    width: 40,
  },
  checkBoxIconStyle: {
    fontSize: 24,
    textAlign: 'center',
    lineHeight: 40,
  },
  checkBoxIconActive: {
    color: 'rgba(237, 53, 145, 1)',
  },
  checkBoxIconInactive: {
    color: 'rgba(0, 0, 0, 0.42)',
  },
  checkBoxCheckContainer: {
    height: 30,
    width: 60,
    marginLeft: 'auto',
    borderRadius: 3,
  },
  checkBoxCheckActive: {
    backgroundColor: 'rgba(237, 53, 145, 0.2)',
  },
  checkBoxCheckInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  checkBoxCheckIconContainer: {
    margin: 5,
    borderRadius: 3,

    height: 20,
    width: 20,
  },
  checkBoxCheckIconActive: {
    backgroundColor: 'rgba(237, 53, 145, 1)',
    marginLeft: 35,
  },
  checkBoxCheckIconInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.42)',
    marginLeft: 5,
  },
  checkIconContainer: {
    height: 20,
    width: 20,
  },
  checkIconStyle: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
  },
});
