import { View, Platform, TextInput, Image, ImageBackground, TouchableOpacity } from 'react-native';
import { ImageInputImplementation } from './image.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useEffect, useState } from 'react';
import { ImageInputValidations } from './image.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { ImageInputStyles } from './image.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function ImageInput<ITEM>(properties: ImageInputImplementation<ITEM>) {
  const value = properties.value[properties.name] as string;
  const [focus, setFocus] = useState(false);
  const [items, setItems] = useState<string[]>([]);
  const apiKey = 'H9i9gBtdsuAQVb3PoHd2KK9QjFe77D-Lk0pG0ncCN3k';
  const [search, setSearch] = useState('');
  const onChange = (image: string | undefined) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: image,
    };
    properties.onChange(newValue);
  };

  const errors =
    properties.validations &&
    ImageInputValidations({
      validations: properties.validations,
      value: value,
      required: properties.required,
    });

  const loadImages = async () => {
    const path = 'https://api.unsplash.com/';
    const endpoint = 'search/photos';
    const query = `?query=${search}&client_id=${apiKey}&per_page=20`;
    const response = await fetch(`${path}${endpoint}${query}`);
    const data = await response.json();
    const images = data.results.map((image: any) => image.urls.small);
    setItems(images);
  };

  useEffect(() => {
    if (search) {
      loadImages();
    }
  }, [search]);

  return (
    <>
      {value && (
        <View
          style={[
            ImageInputStyles.imageSelected,
            properties.readonly ? ImageInputStyles.readonly : null,
          ]}
        >
          <ImageBackground source={{ uri: value }} style={ImageInputStyles.image}>
            {!properties.readonly ? (
              <TouchableOpacity
                style={ImageInputStyles.imageSelectedButton}
                onPress={() => {
                  onChange(undefined);
                }}
              >
                <IconComponent name="times" iconStyle={ImageInputStyles.imageSelectedIcon} />
              </TouchableOpacity>
            ) : null}
          </ImageBackground>
        </View>
      )}
      {!properties.readonly ? (
        <View style={ImageInputStyles.container}>
          {properties.icon && (
            <IconComponent
              name={properties.icon}
              containerstyle={ImageInputStyles.iconContainer}
              iconStyle={ImageInputStyles.iconStyle}
            />
          )}
          <TextInput
            numberOfLines={Platform.OS === 'android' ? properties.lines : undefined}
            // minHeight={(Platform.OS === 'ios' && properties.lines) ? (20 * properties.lines) : undefined}
            multiline={(properties.lines && properties.lines > 1) || false}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            focusable={!properties.readonly}
            editable={!properties.readonly}
            autoComplete="new-password"
            value={search || ''}
            style={[
              ImageInputStyles.input,

              properties.icon ? ImageInputStyles.inputIcon : ImageInputStyles.inputNoIcon,
              focus ? ImageInputStyles.inputFocus : ImageInputStyles.inputUnfocus,
            ]}
            onChangeText={(text: string) => {
              if (properties.readonly) return;
              setSearch(text);
            }}
            autoCapitalize={properties.capitalize}
          />
        </View>
      ) : null}

      {items.length > 0 && (
        <View style={[ImageInputStyles.grid]}>
          {items.map((item, index) => (
            <TouchableOpacity
              key={index}
              style={[ImageInputStyles.imageContainer]}
              onPress={() => {
                // load picture and convert to base64
                fetch(item)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                      onChange(reader.result as string);
                      setItems([]);
                    };
                  });
              }}
            >
              <ImageBackground source={{ uri: item }} style={[ImageInputStyles.image]} />
            </TouchableOpacity>
          ))}
        </View>
      )}
      {errors && <ErrorsComponent prefix={'form.image.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.image.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
