import { View } from 'react-native';
import { RowProperties } from './row.properties';
import { RowStyles } from './row.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Row Component
 * @description Componente para agrupar elementos en una fila
 *
 * @param {RowProperties} properties
 * @returns {JSX.Element}
 */

export function RowComponent(properties: RowProperties) {
  const { phone, tablet } = useDeviceHook();

  return (
    <View style={phone || tablet ? RowStyles.mobileContainer : RowStyles.container}>
      {properties.children}
    </View>
  );
}
