import { PaymentCrmType } from '@human/crm/types/payment.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class PaymentCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof PaymentCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    patientId: string;
    clinicId: string;
    receiptId: string;
  }): Promise<{
    items: PaymentCrmType[];
    total: number;
  }> {
    return this.fetch(`payment/find`, {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        total: data.total,
        items: data.items,
      };
    });
  }

  async create(properties: { item: Partial<PaymentCrmType> }): Promise<Partial<PaymentCrmType>> {
    return this.fetch('payment/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: {
    id: string;
    patientId: string;
    clinicId: string;
    receiptId: string;
  }): Promise<PaymentCrmType> {
    return this.fetch('payment/pick', {
      method: 'GET',
      params: properties,
    }).then((item) => ({
      ...item,
      price: item.price.toString(),
    }));
  }

  update(properties: {
    id: string;
    patientId: string;
    clinicId: string;
    receiptId: string;
    item: Partial<PaymentCrmType>;
  }) {
    return this.fetch(`payment/update`, {
      method: 'PUT',
      params: {
        id: properties.id,
        patientId: properties.patientId,
        receiptId: properties.receiptId,
        clinicId: properties.clinicId,
      },
      body: properties.item,
    });
  }
}
