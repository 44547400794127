// Modules
import { StyleSheet } from 'react-native';

/**
 * Notes Styles
 * @constant {StyleSheet} NoteStyles
 */
export const NoteStyles = StyleSheet.create({
  messageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 30,
    zIndex: 1,
  },
  actionsContainer: {
    flexDirection: 'row',
    gap: 5,
  },
  actionIcon: {
    height: 20,
    width: 20,
  },
  actionIconShape: {
    fontSize: 20,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 25,
    zIndex: 2,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
  },
  iconShape: {
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgb(237, 53, 145)',
  },
  deleted: {
    backgroundColor: 'rgba(60, 76, 88, 0.7)',
  },
  message: {
    marginBottom: 15,
    marginTop: 0,
    width: '90%',
    backgroundColor: 'rgb(60, 76, 88)',
    padding: 20,
    borderRadius: 10,
    color: '#fff',
    alignSelf: 'flex-start',
    gap: 10,
  },
  text: {
    color: '#fff',
    marginVertical: 8,
    lineHeight: 20,
  },
  editText: {
    backgroundColor: 'rgba(60, 76, 88, 0.32)',
    color: '#fff',
    borderWidth: 2,
    borderColor: 'rgba(255, 255, 255, 0.6)',
    maxWidth: 350,
    padding: 15,
    borderRadius: 10,
    // height: 150,
  },
  position: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 8,
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: 5,
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingHorizontal: 10,
    borderRadius: 35,
    borderTopLeftRadius: 0,
    alignSelf: 'flex-start',
  },
  date: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'right',
  },

  avatar: {},
  header: {
    gap: 5,
  },
  author: {
    fontSize: 16,
    color: 'rgb(255, 101, 178)',
  },
  when: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  send: {
    fontSize: 20,
    color: 'rgba(255, 255, 255, 0.6)',
    alignSelf: 'flex-end',
    marginTop: 10,
  },
  source: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'right',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 35,
  },
});
