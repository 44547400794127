export const getPercentage = (settings: { lastWeek: number; thisWeek: number }) => {
  //Si ambos son 0 muestra NaN
  if (settings.lastWeek === 0 && settings.thisWeek === 0) return 0;
  //Si lastweek es 0 muestra infinity
  if (settings.lastWeek === 0) return 100;

  if (settings.lastWeek > settings.thisWeek) {
    // return -100 * settings.thisWeek / settings.lastWeek
    const percentage = 100 - (settings.thisWeek * 100) / settings.lastWeek;
    return Math.round(percentage * 100) / 100;
  }
  return Math.round((settings.thisWeek * 100) / settings.lastWeek);
};

export const calculatePercentage = (total: number, vi: number): string => {
  if (!total || !vi || total <= 0 || vi < 0) return '0';
  return ((vi / total) * 100).toFixed(2);
};
