import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TemplateScopesRoutes } from '../../templateScopes.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TemplateScopeAccessRepository } from '@human/access/repositories/template/templateScope/templateScope.repository';

export function DeleteTemplateScopesScreen(properties: {
  navigation: NavigationProp<TemplateScopesRoutes>;
  route: RouteProp<TemplateScopesRoutes, 'deleteTemplateScope'>;
}) {
  return (
    <ModalComponent
      icon="trash"
      prefix="access.templates.templateScopes.delete"
      level={properties.route.params.level}
    >
      <FormComponent
        prefix="access.templates.templateScopes.delete.form"
        padding
        repository={{
          send: (data) => {
            return new TemplateScopeAccessRepository()
              .delete({
                departmentId: properties.route.params.departmentId,
                templateId: properties.route.params.templateId,
                clinicId: properties.route.params.clinicId,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
