import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TreatmentsRoutes } from '../../../treatment.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { InterventionCrmType } from '@human/crm/types/intervention.crm.type';
import { InterventionCrmRepository } from '@human/crm/repositories/intervention/intervention.repository';
import { ButtonComponent } from '@components/button/button.component';
import { ScrollView } from 'react-native';

export function UpdateTreatmentInterventionsPart(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'updateTreatment'>;
}): TabType {
  return {
    name: 'interventions',
    icon: 'heartbeat',
    content: () => (
      <ScrollView>
        <TableComponent<InterventionCrmType, []>
          key={'interventions'}
          prefix={'crm.treatments.update.tabs.interventions'}
          scrollable
          suffix="list"
          repository={(settings) =>
            new InterventionCrmRepository().list({
              treatmentId: properties.route.params.treatmentId,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              search: settings.search,
            })
          }
          columns={[
            {
              type: 'icon',
              renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
              renderColor: (row) => (row.status ? 'green' : 'red'),
              onPress: (row) => {
                properties.navigation.navigate(
                  row.status ? 'disableIntervention' : 'enableIntervention',
                  {
                    interventionId: row.interventionId,
                    clinicId: properties.route.params.clinicId,
                    treatmentId: properties.route.params.treatmentId,
                    patientId: properties.route.params.patientId,
                    level: properties.route.params.level + 1,
                  }
                );
              },
            },
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('updateIntervention', {
                  treatmentId: properties.route.params.treatmentId,
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  interventionId: row.interventionId,
                  programId: properties.route.params.programId ?? '',
                  level: 1,
                });
              },
            },
            {
              type: 'clinic',
              name: 'interventionClinicName',
              onRender: (row) => row.interventionClinicName,
              width: 200,
            },
            {
              type: 'text',
              name: 'cycleName',
              onRender: (row) => row.cycleName,
              width: 200,
            },
            {
              type: 'date',
              name: 'expectedDate',
              renderDate: (row) => row.expectedDate,
            },
            {
              name: 'observation',
              type: 'text',
              onRender: (row) => row.observation,
              width: 200,
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.treatments.update.tabs.interventions.add"
          onPress={() => {
            properties.navigation.navigate('createIntervention', {
              programId: properties.route.params.programId ?? '',
              level: properties.route.params.level + 1,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              treatmentId: properties.route.params.treatmentId,
            });
          }}
        />
      </ScrollView>
    ),
  };
}
