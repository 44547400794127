import { PatientNoteCrmType } from '@human/crm/types/note.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class PatientNoteCrmRepository extends AuthorizedRepository {
  async findByPatient(properties: {
    patientId: string;
    clinicId: string;
    tag?: string;
  }): Promise<PatientNoteCrmType[]> {
    return this.fetch(`patientNote/find`, {
      method: 'GET',
      params: properties,
    }).then((notes) => {
      return notes.map((note: any) => ({
        ...note,
        updated: new Date(note.updated),
        logs: note.patientNoteLogs,
      }));
    });
  }

  async create({
    item,
  }: {
    item: { text: string; tag: string; patientId: string; clinicId: string };
  }): Promise<void> {
    return this.fetch('patientNote/create', {
      method: 'POST',
      body: item,
    });
  }

  async update(properties: {
    text: string;
    patientNoteId: string;
    patientId: string;
    clinicId: string;
    tag: string;
  }): Promise<void> {
    return this.fetch(`patientNote/update`, {
      method: 'PUT',
      body: properties,
    });
  }

  async delete(properties: {
    patientNoteId: string;
    patientId: string;
    clinicId: string;
  }): Promise<void> {
    return this.fetch(`patientNote/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
