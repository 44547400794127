import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class CampaignFinancingRepository extends AuthorizedRepository {
  pick = async (data: { campaignId: string }) => {
    return this.fetch(`campaign/financing/pick`, {
      method: 'GET',
      params: {
        campaignId: data.campaignId,
      },
    }).then((data) => {
      return {
        budget: data.budget.toString(),
        financingClinicId: data.financingClinicId,
        financingClinicName: data.financingClinicName,
      };
    });
  };

  update = async (data: { campaignId: string; budget?: number; financingClinicId?: string }) => {
    return this.fetch(`campaign/financing/update`, {
      method: 'PUT',
      body: {
        campaignId: data.campaignId,
        budget: data.budget,
        financingClinicId: data.financingClinicId,
      },
    });
  };
}
