import { View } from 'react-native';

export function ScreenComponent(properties: { children: React.ReactNode }) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {properties.children}
    </View>
  );
}
