import { NavigationProp, RouteProp } from '@react-navigation/native';
import { InterventionsRoutes } from '../../interventions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { InterventionCrmType } from '../../../../types/intervention.crm.type';
import { InterventionCrmRepository } from '@human/crm/repositories/intervention/intervention.repository';
import { FormComponent } from '@components/form/form.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { UpdateInterventionTechniquesPart } from './parts/update.intervention.techniques.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { ProgramCycleCrmRepository } from '@human/crm/repositories/programCycle/programCycle.repository';

export function UpdateInterventionScreen(properties: {
  navigation: NavigationProp<InterventionsRoutes>;
  route: RouteProp<InterventionsRoutes, 'updateIntervention'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="heartbeat"
      prefix="crm.interventions.update"
    >
      <TabsComponent
        prefix="crm.interventions.update"
        multiworkspace
        tabs={[
          {
            name: 'intervention',
            icon: 'heartbeat',
            content: (settings) => (
              <FormComponent<InterventionCrmType>
                prefix="crm.interventions.update.form"
                repository={{
                  get: () => {
                    return new InterventionCrmRepository().pick({
                      interventionId: properties.route.params.interventionId,
                      clinicId: properties.route.params.clinicId,
                      patientId: properties.route.params.patientId,
                      treatmentId: properties.route.params.treatmentId,
                    });
                  },
                  send: (settings) => {
                    return new InterventionCrmRepository()
                      .update({
                        item: settings.item,
                        interventionId: properties.route.params.interventionId,
                        clinicId: properties.route.params.clinicId,
                        patientId: properties.route.params.patientId,
                        treatmentId: properties.route.params.treatmentId,
                      })
                      .then(() => {
                        showSuccess();
                      });
                  },
                }}
                fields={[
                  {
                    type: 'select',
                    name: 'interventionClinicId',
                    required: true,
                    description: true,
                    pick: (settings: { id: string }) => {
                      return new ClinicAccessRepository()
                        .pick({ id: settings.id })
                        .then((clinic) => {
                          return `${clinic.name}`;
                        });
                    },
                    repository: ({ search }) => {
                      return new ClinicAccessRepository()
                        .list({
                          page: 0,
                          search,
                          limit: 100,
                          active: true,
                        })
                        .then((clinics) =>
                          clinics.items.map((clinic) => ({
                            value: clinic.id,
                            label: clinic.name,
                          }))
                        );
                    },
                  },
                  {
                    type: 'select',
                    name: 'cycleId',
                    required: true,
                    description: true,
                    pick: (settings: { id: string }) => {
                      return new CycleCrmRepository()
                        .pick({ cycleId: settings.id })
                        .then((cycle) => {
                          return `${cycle.name}`;
                        });
                    },
                    repository: ({ search }) => {
                      return new ProgramCycleCrmRepository()
                        .list({ search, programId: properties.route.params.programId })
                        .then((response) =>
                          response.items.map((item) => ({
                            label: item.cycleName,
                            value: item.cycleId,
                          }))
                        );
                    },
                  },
                  {
                    type: 'date',
                    name: 'expectedDate',
                    required: true,
                    description: true,
                    format: 'YYYY-MM-DD HH:mm:ss',
                  },
                  {
                    type: 'date',
                    name: 'interventionDate',
                    description: true,
                    format: 'YYYY-MM-DD HH:mm:ss',
                  },
                  {
                    type: 'text',
                    name: 'observation',
                    required: true,
                    description: true,
                    validations: ['observation'],
                  },
                ]}
              />
            ),
          },
          UpdateInterventionTechniquesPart(properties),
        ]}
      />
    </ModalComponent>
  );
}
