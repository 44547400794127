import { NavigationComponent } from '@components/navigation/navigation.component';
import { CyclesRoutes } from './cycles.routes';
import { ListCyclesScreen } from './screens/list/list.cycles.screen';
import { CreateCycleScreen } from './screens/create/create.cycle.screen';
import { UpdateCycleScreen } from './screens/update/update.cycle.screen';
import { EnableCycleScreen } from './screens/enable/enable.cycle.screen';
import { DisableCycleScreen } from './screens/disable/disable.cycle.screen';

const Navigation = NavigationComponent<CyclesRoutes>();
export function CyclesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListCyclesScreen} />
      <Navigation.Screen name="create" component={CreateCycleScreen} />
      <Navigation.Screen name="update" component={UpdateCycleScreen} />
      <Navigation.Screen name="enable" component={EnableCycleScreen} />
      <Navigation.Screen name="disable" component={DisableCycleScreen} />
    </Navigation.Navigator>
  );
}
