import { View, Text, ScrollView } from 'react-native';
import { GeneralTableStyles } from './general.table.styles';
import { GeneralTableRow } from './components/generalTableRow/general.table.row';
import { useTranslation } from 'react-i18next';
import { TotalDataComponent } from './components/totalData/totalData.component';
import { TotalRowComponent } from './components/totalRow/totalRow.component';
import { GeneralTableProperties } from './general.properties';

export function GeneralTableComponent(properties: GeneralTableProperties) {
  const { t } = useTranslation();

  const months = Array.from({ length: 12 }, (_, i) => t('access.users.list.month.' + i));

  const GeneralTableColumn = (properties: { month: string }) => {
    return (
      <View style={GeneralTableStyles.columnContainer}>
        <Text>{properties.month}</Text>
        <View style={GeneralTableStyles.columnSubtitleContainer}>
          <Text style={GeneralTableStyles.columnSubtitle}>
            {t('board.marketing.generalTable.totalLeads')}
          </Text>
          <Text style={GeneralTableStyles.columnSubtitle}>
            {t('board.marketing.generalTable.informed')}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View style={GeneralTableStyles.container}>
      <View style={GeneralTableStyles.clinicNameColumn}>
        <View style={GeneralTableStyles.headerPlaceholder} />
        {properties.data.clinics.map((clinic) => (
          <View key={clinic.clinic_name} style={GeneralTableStyles.clinicNameRow}>
            <Text>{clinic.clinic_name}</Text>
          </View>
        ))}
        <View
          key={'totalname'}
          style={[GeneralTableStyles.clinicNameRow, GeneralTableStyles.total]}
        >
          <Text>{t('board.marketing.generalTable.totalRow')}</Text>
        </View>
      </View>

      <ScrollView horizontal>
        <View>
          <View style={GeneralTableStyles.monthHeaderRow}>
            {months.map((item) => (
              <GeneralTableColumn key={item} month={item} />
            ))}
          </View>

          {properties.data.clinics.map((clinic) => (
            <View key={clinic.clinic_name} style={GeneralTableStyles.dataRow}>
              <GeneralTableRow clinicData={clinic} />
            </View>
          ))}
          <TotalRowComponent data={properties.data} />
        </View>
      </ScrollView>
      <TotalDataComponent clinics={properties.data.clinics} />
    </View>
  );
}
