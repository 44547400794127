import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { Table2DBoardType } from '../../types/table2d.board.type';
import { PieBoardType } from '../../types/pie.board.type';
import { leadperdepartmentandmonth } from '../../screens/funnels/sale.funnel.screen.web';
import { TotalClinicLeadsSuccessData } from '../../screens/marketing_v2/components/general/totalClinicLeadsSuccessData';

export class MarketingBoardRepository extends AuthorizedRepository {
  async countLeadByStateByClinic(properties: {
    languageId?: string;
    funnelId?: string;
    campaignId?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<Table2DBoardType> {
    return this.fetch('board/marketing/countLeadByStateByClinic', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadByStateByCampaign(properties: {
    languageId?: string;
    funnelId?: string;
    clinicId?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<Table2DBoardType> {
    return this.fetch('board/marketing/countLeadByStateByCampaign', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadByCampaign(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<PieBoardType> {
    return this.fetch('board/marketing/countLeadByCampaign', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadByFunnel(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<PieBoardType> {
    return this.fetch('board/marketing/countLeadByFunnel', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadPatientByCampaign(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<Table2DBoardType> {
    return this.fetch('board/marketing/countLeadPatientByCampaign', {
      method: 'GET',
      params: properties,
    });
  }

  async countLeadPatientByFunnel(properties: {
    order?: string;
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<Table2DBoardType> {
    return this.fetch('board/marketing/countLeadPatientByFunnel', {
      method: 'GET',
      params: properties,
    });
  }

  async listLeadsByCampaign(properties: {
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{
    x: string[];
    data: {
      [key: string]: string;
    }[];
  }> {
    return this.fetch('board/marketing/listLeadsByCampaign', {
      method: 'GET',
      params: properties,
    });
  }

  //SQL QUERIES
  listLeadsPerCampaign = async (properties: {
    year: number;
    month?: number;
  }): Promise<
    {
      id: string;
      name: string;
      count: number;
    }[]
  > => {
    return this.fetch('board/marketing/leadPerCampaign', {
      method: 'GET',
      params: properties,
    });
  };

  listLeadsPerFunnel = async (properties: {
    year: number;
    month?: number;
  }): Promise<
    {
      id: string;
      name: string;
      count: number;
    }[]
  > => {
    return this.fetch('board/marketing/leadPerFunnel', {
      method: 'GET',
      params: properties,
    });
  };

  listLeadsPerFunnelAndState = async (properties: { year: number; month?: number }) => {
    return this.fetch('board/common/leadPerFunnelAndState', {
      method: 'GET',
      params: properties,
    });
  };

  listConvertionRateByCampaign = async (properties: { month?: number }) => {
    return this.fetch('board/marketing/convertionRateByCampaign', {
      method: 'GET',
      params: properties,
    });
  };

  listConvertionRateByFunnel = async (properties: { month?: number }) => {
    return this.fetch('board/marketing/convertionRateByFunnel', {
      method: 'GET',
      params: properties,
    });
  };

  listLeadPerClinicAndState = async (properties: { month?: number }) => {
    return this.fetch('board/common/leadPerClinicAndState', {
      method: 'GET',
      params: properties,
    });
  };

  leadStateByClinicIdAndMonth = async (properties: {
    month: number;
    clinicId: string;
  }): Promise<leadperdepartmentandmonth[]> => {
    return this.fetch('board/common/leadStateByClinicIdAndMonth', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return data.map((item: leadperdepartmentandmonth) => ({
        department_id: item.department_id ?? 0,
        department_name: item.department_name ?? 0,
        language_id: item.language_id ?? 0,
        lead_converted_count: item.lead_converted_count ?? 0,
        lead_medic_attended_count: item.lead_medic_attended_count ?? 0,
        lead_comercial_attended_count: item.lead_comercial_attended_count ?? 0,
        lead_appointed_count: item.lead_appointed_count ?? 0,
        lead_informed_count: item.lead_informed_count ?? 0,
        lead_contacted_count: item.lead_contacted_count ?? 0,
        lead_medical_appointed_count: item.lead_medical_appointed_count ?? 0,
        lead_medical_evaluated_count: item.lead_medical_evaluated_count ?? 0,
        total_leads: item.total_leads ?? 0,
      }));
    });
  };

  //Devuelve un array, corregirlo mas tarde
  leadsStateAndMonthByClinicDepartment = async (properties: {
    month: number;
    clinicId: string;
    languageId: string;
  }) => {
    return this.fetch('board/common/leadsStateAndMonthByClinicDepartment', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      if (data.length === 0) return undefined;
      return {
        department_id: data[0].department_id ?? 0,
        department_name: data[0].department_name ?? 0,
        lead_active_count: data[0].lead_active_count ?? 0,
        lead_inactive_count: data[0].lead_inactive_count ?? 0,
        lead_converted_count: data[0].lead_converted_count ?? 0,
        lead_medic_attended_count: data[0].lead_medic_attended_count ?? 0,
        lead_comercial_attended_count: data[0].lead_comercial_attended_count ?? 0,
        lead_canceled_count: data[0].lead_canceled_count ?? 0,
        lead_informed_count: data[0].lead_informed_count ?? 0,
        lead_contacted_count: data[0].lead_contacted_count ?? 0,
        lead_medical_appointed_count: data[0].lead_medical_appointed_count ?? 0,
        lead_medical_evaluated_count: data[0].lead_medical_evaluated_count ?? 0,
        lead_appointed_count: data[0].lead_appointed_count ?? 0,
        total_leads: data[0].total_leads ?? 0,
      };
    });
  };

  leadStateAndMonthByClinicAndEvent = async (properties: {
    month: number;
    clinicId: string;
    event: string;
  }): Promise<[{ department_id: string; department_name: string; lead_count: number }]> => {
    return this.fetch('board/common/leadStateAndMonthByClinicAndEvent', {
      method: 'GET',
      params: properties,
    });
  };

  listLeadPerCampaignAndState = async (properties: { year: number; month?: number }) => {
    return this.fetch('board/common/leadPerCampaignAndState', {
      method: 'GET',
      params: properties,
    });
  };

  listTotalClinicLeadsSuccess = async (properties: {
    year: number;
    funnelId?: string;
    campaignId?: string;
  }): Promise<TotalClinicLeadsSuccessData> => {
    return this.fetch('board/marketing/totalClinicLeadsSuccess', {
      method: 'GET',
      params: properties,
    });
  };
}
