// Modules
import { StyleSheet } from 'react-native';

/**
 * Export Button Styles
 * @constant {StyleSheet} ButtonStyles
 */
export const SecondaryButtonStyles = StyleSheet.create({
  button: {
    backgroundColor: 'rgb(17, 27, 34)',
    borderRadius: 4,
  },
  buttonContainer: {
    height: 40,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  buttonLabel: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 15,
    marginLeft: 15,
  },
  iconContainer: {
    height: 40,
    width: 40,
    backgroundColor: '#00000029',
    borderLeftColor: '#00000017',
    borderLeftWidth: 1,
  },
  icon: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 40,
    color: '#fff',
  },
});
