import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { RolesRoutes } from '../../roles.routes';
import { RoleAccessType } from '../../../../types/role.access.type';
import { RoleAccessRepository } from '@human/access/repositories/role/role.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListRolesScreen(properties: {
  navigation: NavigationProp<RolesRoutes>;
  route: RouteProp<RolesRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.roles.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () =>
        properties.navigation.navigate('create', {
          groupId: properties.route.params.groupId,
        }),
    });
  }
  const { phone } = useDeviceHook();

  const columns: ColumnProperties<RoleAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.roles.editor' }) ? 'pen' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          roleId: row.id,
          groupId: properties.route.params.groupId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          roleId: row.id,
          groupId: properties.route.params.groupId,
        });
      },
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.roles.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          roleId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={AccessSections.roles.icon} prefix={'access.roles.list'} links={links}>
      <TableComponent<RoleAccessType, []>
        prefix={'access.roles.list'}
        suffix={'list'}
        scrollable={phone ?? undefined}
        repository={(settings) =>
          new RoleAccessRepository().list({
            groupId: properties.route.params.groupId,
            search: settings.search,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
