import { ClinicAccessType } from '@human/access/types/clinic.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ClinicAccessRepository extends AuthorizedRepository {
  async list(properties: {
    direction?: 'asc' | 'desc';
    page?: number;
    limit?: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: ClinicAccessType[];
    total: number;
  }> {
    return this.fetch('clinic/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((user: any) => ({
        id: user.id,
        name: user.name,
        country: user.country,
        state: user.state,
        city: user.city,
        street: user.street,
        zip: user.zip,
        center: {
          latitude: user.latitude,
          longitude: user.longitude,
        },
        status: user.status,
        avatar: user.avatar,
        document: user.document,
        title: user.title,
        description: user.description,
        created: new Date(user.created),
        color: user.color,
      })),
      total: data.total,
    }));
  }

  async pick(properties: { id: string }): Promise<Partial<ClinicAccessType>> {
    return this.fetch(`clinic/pick`, {
      method: 'GET',
      params: {
        clinicId: properties.id,
      },
    }).then((data) => ({
      id: data.id,
      name: data.name,
      country: data.country,
      state: data.state,
      city: data.city,
      street: data.street,
      zip: data.zip,
      center: {
        latitude: data.latitude,
        longitude: data.longitude,
      },
      status: data.status,
      avatar: data.avatar,
      document: data.document,
      title: data.title,
      timezone: data.timezone,
      description: data.description,
      created: data.created,
      updated: data.updated,
      deleted: data.deleted,
      color: data.color,
    }));
  }

  async create(properties: { item: Partial<ClinicAccessType> }) {
    return this.fetch('clinic/create', {
      method: 'POST',
      body: {
        ...properties.item,
        center: undefined,
        longitude: properties.item.center?.longitude,
        latitude: properties.item.center?.latitude,
      },
    });
  }

  async update(properties: { item: Partial<ClinicAccessType> }) {
    return this.fetch(`clinic/update?clinicId=${properties.item.id}`, {
      method: 'PUT',
      body: {
        ...properties.item,
        center: undefined,
        longitude: properties.item.center?.longitude,
        latitude: properties.item.center?.latitude,
      },
    });
  }

  async delete(properties: Partial<{ clinicId: string; password: string }>) {
    return this.fetch(`clinic/delete`, {
      params: {
        clinicId: properties.clinicId ?? '',
      },
      body: {
        password: properties.password,
      },
      method: 'DELETE',
    });
  }

  async disable(properties: { clinicId: string }) {
    return this.fetch(`clinic/disable`, {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { clinicId: string }) {
    return this.fetch(`clinic/enable`, {
      method: 'PUT',
      params: properties,
    });
  }
}
