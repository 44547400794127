import { View } from 'react-native';
import { LaunchComponent } from '../../../../../components/launch/launch.component';
import { SplashComponent } from '../../../../../components/splash/splash.component';
import { AuthorizedSections } from '../../horse.sections';

export function DesktopSpace() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#333',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <SplashComponent type="horse" />
      <LaunchComponent sections={AuthorizedSections} />
    </View>
  );
}
