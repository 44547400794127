import { useTranslation } from 'react-i18next';
import { View, Text, useWindowDimensions } from 'react-native';
import { TotalLeadsByFunnelTableStyles } from './totalLeadsByFunnel.styles';
import { LeadsByFunnelYearCountRow } from './components/leadsByFunnelYearCountRow';
import { months } from '../../../../constants/months.constant';
import { useDeviceHook } from '@hooks/device/device.hook';

export function TotalLeadsByFunnelTable(properties: {
  data: { department_name: string; month: number; total: number }[];
  year: number;
}) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();
  const { height } = useWindowDimensions();
  const departments = Array.from(new Set(properties.data.map((item) => item.department_name)));
  const formattedData = departments.map((department) => {
    const departmentData = properties.data
      .filter((item) => item.department_name === department)
      .sort((a, b) => {
        return a.month - b.month;
      });
    return {
      department_name: department,
      total_leads: departmentData.map((item) => item.total),
    };
  });

  const LeadCountTableColumn = (settings: {
    header: {
      month: string;
    };
    index: number;
  }) => {
    return (
      <View
        style={
          phone
            ? { width: 100, marginHorizontal: 10 }
            : TotalLeadsByFunnelTableStyles.headerColumnContainer
        }
      >
        <Text style={TotalLeadsByFunnelTableStyles.headerText}>
          {t(`board.months.${settings.header.month}`)}
        </Text>
      </View>
    );
  };

  return (
    <>
      <View style={{ flexDirection: 'column', flex: 1, minHeight: height * 0.25 }}>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={phone ? { width: 100 } : TotalLeadsByFunnelTableStyles.headerSpaceHeader}
            />
            <View style={TotalLeadsByFunnelTableStyles.headerContainer}>
              {months.map((item, index) => (
                <LeadCountTableColumn key={item.month} header={item} index={index} />
              ))}
            </View>
          </View>

          {formattedData.map((item, index) => (
            <LeadsByFunnelYearCountRow
              key={item.department_name}
              item={item}
              last={index === formattedData.length - 1}
              year={properties.year}
            />
          ))}
        </View>
      </View>
    </>
  );
}
