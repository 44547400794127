import { View, TextInput as TextInputNative } from 'react-native';
import { PairInputImplementation } from './pair.implementation';
import { FieldsComponent } from '../../fields.component';
import { PairInputStyles } from './pair.styles';

export function PairInput<ITEM>(properties: PairInputImplementation<ITEM>) {
  return (
    <View style={PairInputStyles.container}>
      <FieldsComponent<ITEM>
        flex={1}
        errors={properties.errors}
        prefix={properties.prefix}
        fields={properties.fields}
        values={properties.values}
        onChange={properties.onChange}
        readonly={properties.readonly}
      />
    </View>
  );
}
