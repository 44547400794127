import { FormComponent } from '@components/form/form.component';
import { TabType } from '@components/tabs/tabs.types';
import { CrmSections } from '@human/crm/crm.sections';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReasonsRoutes } from '../../../reason.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateReasonNextActivityPart(properties: {
  navigation: NavigationProp<ReasonsRoutes>;
  route: RouteProp<ReasonsRoutes, 'update'>;
  showSuccess: (label?: string) => void;
}): TabType {
  return {
    name: 'activity',
    icon: CrmSections.activities.icon,
    content: (settings) => (
      <FormComponent<{
        nextActivityId: string;
        nextActivityDelay: number;
      }>
        repository={{
          send: (data) =>
            new ReasonCrmRepository()
              .updateNextActivity({
                reasonId: properties.route.params.id,
                nextActivityId: data.item.nextActivityId,
                nextActivityDelay: data.item.nextActivityDelay,
              })
              .then(() => properties.showSuccess()),
          get: async () =>
            new ReasonCrmRepository().pickNextActivity({ reasonId: properties.route.params.id }),
        }}
        fields={[
          {
            type: 'select',
            name: 'nextActivityId',
            description: true,
            pick: (props: { id: string }) => {
              return new ActivityCrmRepository().pick({ activityId: props.id }).then((category) => {
                return `${category.name}`;
              });
            },
            repository: (selectSettings) =>
              new ActivityCrmRepository()
                .list({
                  direction: 'asc',
                  limit: 100,
                  page: 0,
                  search: selectSettings.search,
                  order: 'name',
                  active: true,
                })
                .then((response) =>
                  response.items.map((item) => ({ label: item.name, value: item.id }))
                ),
          },
          {
            type: 'number',
            description: true,
            name: 'nextActivityDelay',
          },
        ]}
        prefix="crm.reasons.update.nextActivity.form"
      />
    ),
  };
}
