import { NavigationProp, RouteProp } from '@react-navigation/native';
import { InterventionsRoutes } from '../../../interventions.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { ButtonComponent } from '@components/button/button.component';
import { InterventionTechniqueCrmRepository } from '@human/crm/repositories/interventionTechnique/interventionTechnique.repository';
import { ScrollView } from 'react-native';

export function UpdateInterventionTechniquesPart(properties: {
  navigation: NavigationProp<InterventionsRoutes>;
  route: RouteProp<InterventionsRoutes, 'updateIntervention'>;
}): TabType {
  return {
    name: 'techniques',
    icon: 'stethoscope',
    content: () => (
      <ScrollView>
        <TableComponent<TechniqueCrmType, []>
          prefix={'crm.techniques.list'}
          suffix={'list'}
          repository={(settings) =>
            new InterventionTechniqueCrmRepository().list({
              treatmentId: properties.route.params.treatmentId,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              interventionId: properties.route.params.interventionId,
              search: settings.search,
            })
          }
          columns={[
            {
              type: 'icon',
              icon: 'trash',
              onPress: (row) => {
                properties.navigation.navigate('deleteInterventionTechnique', {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  treatmentId: properties.route.params.treatmentId,
                  interventionId: properties.route.params.interventionId,
                  techniqueId: row.id,
                  level: 2,
                });
              },
            },
            {
              name: 'name',
              type: 'text',
              width: 200,
              onRender: (row) => `${row.name}`,
            },
            {
              name: 'price',
              type: 'price',
              width: 140,
              onRender: (row) => {
                return row.price;
              },
              onCurrency: (row) => {
                return '€';
              },
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.techniques.add"
          onPress={() => {
            properties.navigation.navigate('createInterventionTechnique', {
              treatmentId: properties.route.params.treatmentId,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              interventionId: properties.route.params.interventionId,
              programId: properties.route.params.programId || '',
              level: 1,
            });
          }}
        />
      </ScrollView>
    ),
  };
}
