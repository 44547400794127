import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TotalDataStyles } from './totalData.styles';
import { TotalDataProperties } from './totalData.properties';
import { ClinicData } from '../../totalClinicLeadsSuccessData';
import { calculatePercentage } from '@utils/percentage/getPercentage.utility';

export function TotalDataComponent(properties: TotalDataProperties) {
  const { t } = useTranslation();

  const calculateLeads = (clinics: ClinicData) => {
    return (
      clinics.total_leads_january +
      clinics.total_leads_february +
      clinics.total_leads_march +
      clinics.total_leads_april +
      clinics.total_leads_may +
      clinics.total_leads_june +
      clinics.total_leads_july +
      clinics.total_leads_august +
      clinics.total_leads_september +
      clinics.total_leads_october +
      clinics.total_leads_november +
      clinics.total_leads_december
    );
  };

  const calculateInformed = (clinics: ClinicData) => {
    return (
      clinics.total_vi_january +
      clinics.total_vi_february +
      clinics.total_vi_march +
      clinics.total_vi_april +
      clinics.total_vi_may +
      clinics.total_vi_june +
      clinics.total_vi_july +
      clinics.total_vi_august +
      clinics.total_vi_september +
      clinics.total_vi_october +
      clinics.total_vi_november +
      clinics.total_vi_december
    );
  };

  const calculateCost = (clinics: ClinicData) => {
    const sumCost =
      clinics.total_campaign_cost_january +
      clinics.total_campaign_cost_february +
      clinics.total_campaign_cost_march +
      clinics.total_campaign_cost_april +
      clinics.total_campaign_cost_may +
      clinics.total_campaign_cost_june +
      clinics.total_campaign_cost_july +
      clinics.total_campaign_cost_august +
      clinics.total_campaign_cost_september +
      clinics.total_campaign_cost_october +
      clinics.total_campaign_cost_november +
      clinics.total_campaign_cost_december;
    return (parseFloat(sumCost.toString()) / 100).toFixed(2);
  };

  const calculateTotalInformed = (clinics: ClinicData[]): number => {
    return clinics.reduce((acc: number, clinic: ClinicData) => {
      return (
        acc +
        clinic.total_vi_january +
        clinic.total_vi_february +
        clinic.total_vi_march +
        clinic.total_vi_april +
        clinic.total_vi_may +
        clinic.total_vi_june +
        clinic.total_vi_july +
        clinic.total_vi_august +
        clinic.total_vi_september +
        clinic.total_vi_october +
        clinic.total_vi_november +
        clinic.total_vi_december
      );
    }, 0);
  };

  const calculateTotalLeads = (clinics: ClinicData[]): number => {
    return clinics.reduce((acc: number, clinic: ClinicData) => {
      return (
        acc +
        clinic.total_leads_january +
        clinic.total_leads_february +
        clinic.total_leads_march +
        clinic.total_leads_april +
        clinic.total_leads_may +
        clinic.total_leads_june +
        clinic.total_leads_july +
        clinic.total_leads_august +
        clinic.total_leads_september +
        clinic.total_leads_october +
        clinic.total_leads_november +
        clinic.total_leads_december
      );
    }, 0);
  };

  const calculateTotalCost = (clinics: ClinicData[]): string => {
    const totalSum = clinics.reduce((acc: number, clinic: ClinicData) => {
      return (
        acc +
        clinic.total_campaign_cost_january +
        clinic.total_campaign_cost_february +
        clinic.total_campaign_cost_march +
        clinic.total_campaign_cost_april +
        clinic.total_campaign_cost_may +
        clinic.total_campaign_cost_june +
        clinic.total_campaign_cost_july +
        clinic.total_campaign_cost_august +
        clinic.total_campaign_cost_september +
        clinic.total_campaign_cost_october +
        clinic.total_campaign_cost_november +
        clinic.total_campaign_cost_december
      );
    }, 0);
    return (parseFloat(totalSum.toString()) / 100).toFixed(2);
  };

  const TotalRow = () => {
    const totalLeadsCount = calculateTotalLeads(properties.clinics);
    const totalInformedCount = calculateTotalInformed(properties.clinics);
    const totalPercentage = calculatePercentage(totalLeadsCount, totalInformedCount);
    const totalCost = calculateTotalCost(properties.clinics);

    return (
      <View style={[TotalDataStyles.rowContainer, TotalDataStyles.total]}>
        <Text style={TotalDataStyles.tableDataText}>{totalLeadsCount}</Text>
        <Text
          style={TotalDataStyles.tableDataText}
        >{`${totalInformedCount} (${totalPercentage}%)`}</Text>
        <Text style={TotalDataStyles.tableDataText}>{`${totalCost}€`}</Text>
      </View>
    );
  };

  return (
    <View style={TotalDataStyles.totalContainer}>
      <View style={TotalDataStyles.totalHeaderContainer}>
        <Text>{t('board.marketing.generalTable.total')}</Text>
        <View style={TotalDataStyles.totalHeaderSubtitleContainer}>
          <Text style={TotalDataStyles.columnSubtitle}>
            {t('board.marketing.generalTable.totalLeads')}
          </Text>
          <Text style={TotalDataStyles.columnSubtitle}>
            {t('board.marketing.generalTable.informed')}
          </Text>
          <Text style={TotalDataStyles.columnSubtitle}>
            {t('board.marketing.generalTable.costPerLead')}
          </Text>
        </View>
      </View>
      <View>
        <>
          {properties.clinics.map((clinic) => {
            const leadsCount = calculateLeads(clinic);
            const informedCount = calculateInformed(clinic);
            const percentage = calculatePercentage(leadsCount, informedCount);
            const cost = calculateCost(clinic);
            return (
              <View style={TotalDataStyles.rowContainer}>
                <Text style={TotalDataStyles.tableDataText}>{leadsCount}</Text>
                <Text
                  style={TotalDataStyles.tableDataText}
                >{`${informedCount} (${percentage}%)`}</Text>
                <Text style={TotalDataStyles.tableDataText}>{`${cost}€`}</Text>
              </View>
            );
          })}
          <TotalRow />
        </>
      </View>
    </View>
  );
}
