// Modules
import { StyleSheet } from 'react-native';

/**
 * Search Styles
 * @constant {StyleSheet} SearchStyles
 */
export const PeriodStyles = StyleSheet.create({
  calendarContainer: {
    marginTop: 2,
    position: 'relative',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  pickerContainer: {
    position: 'relative',
    top: 7,
    zIndex: 5,
  },
  calendarContentRow: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  calendarDates: {
    display: 'flex',
    flexDirection: 'row',
  },
  textDates: {
    height: 17,
  },
  calendarContentColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dateContainer: {
    borderRadius: 4,
    marginRight: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  calendarIcon: {
    height: 40,
    fontSize: 24,
    width: 40,
    opacity: 0.5,
    zIndex: 1,
  },
  expandIcon: {
    color: '#000',
    fontSize: 14,
    lineHeight: 40,
    textAlign: 'center',
  },
  icon: {
    color: '#000',
    fontSize: 24,
    lineHeight: 40,
    textAlign: 'center',
  },
  closeContainer: {
    position: 'absolute',
    right: 0,
  },
  iconClose: {
    color: 'grey',
    fontSize: 16,
    margin: 4,
  },
});
