import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { LanguageAccessType } from '@human/access/types/language.access.type';
import { ButtonComponent } from '@components/button/button.component';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { ScrollView } from 'react-native';

export function LanguagesUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
}): TabType {
  const part: TabType = {
    name: 'languages',
    icon: 'language',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<LanguageAccessType, []>
            prefix={'access.languages.list'}
            suffix={'list'}
            header={false}
            repository={(settings) =>
              new UserAccessRepository().findLanguages({
                userId: properties.route.params.userId,
                search: settings.search,
              })
            }
            columns={[
              {
                type: 'icon',
                icon: 'trash',
                onPress: (row) => {
                  properties.navigation.navigate('removeUserLanguage', {
                    level: 1,
                    languageId: row.id,
                    userId: properties.route.params.userId,
                  });
                },
              },
              {
                type: 'text',
                width: 169,
                onRender: (row) => row.name,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.users.update.languages.add"
            onPress={() => {
              properties.navigation.navigate('createUserLanguage', {
                level: 1,
                userId: properties.route.params.userId,
              });
            }}
          />
        </ScrollView>
      );
    },
  };

  return part;
}
