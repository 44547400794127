import { IconComponent } from '@components/icon/icon.component';
import { IconColumnComponent } from '@components/table/components/columns/components/icon/icon.column.component';
import { TextComponent } from '@components/text/text.component';
import { ActivityIndicator, View } from 'react-native';
import { AnalyticsStyles } from './analytics.styles';
import { useEffect, useState } from 'react';
import { AnalyticType } from './type/analytic.type';
import { AnalyticRepository } from './repository/analytic.repository';
import { AnalyticChartComponent } from './analytic.chart.component';

/**
 * Analytics Component
 * @description Componente de la sección de Analytics que contiene gráficos de barras
 * comparativos de actividades, leads y clientes entre semanas.
 *
 * @returns {JSX.Element}
 */

export function AnalyticsComponent() {
  const [analytics, setAnalytics] = useState<AnalyticType>();

  useEffect(() => {
    new AnalyticRepository().find().then((data) => {
      setAnalytics(data);
    });
  }, []);

  if (!analytics) return null;

  return (
    <>
      <View style={AnalyticsStyles.container}>
        <AnalyticChartComponent
          prefix={'activities'}
          lastWeek={analytics.lastWeekActivities}
          thisWeek={analytics.thisWeekActivities}
        />
        <AnalyticChartComponent
          prefix={'leads'}
          lastWeek={analytics.lastWeekLeads}
          thisWeek={analytics.thisWeekLeads}
        />
        <AnalyticChartComponent
          prefix={'results'}
          lastWeek={analytics.lastWeekResults}
          thisWeek={analytics.thisWeekResults}
        />
      </View>
      <View style={AnalyticsStyles.infoFilterContainer}>
        <IconComponent name="info-circle" iconStyle={AnalyticsStyles.infoIcon} />
        <TextComponent translate text="analytics.filter" light style={AnalyticsStyles.filterText} />
      </View>
    </>
  );
}
