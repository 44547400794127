import { Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useEffect, useState } from 'react';
import { SelectInputStyles } from './select.styles';
import { SelectInputImplementation } from './select.implementation';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '@components/loader/loader.component';
import { ScrollView } from 'react-native-gesture-handler';
import { FilterBadgeComponent } from '@components/table/components/columns/components/search/components/filterBadge/filterBadge.component';

export function SelectInput<ITEM>(properties: SelectInputImplementation<ITEM>) {
  const value = properties.value[properties.name] as string;
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState(properties.options);
  const [label, setLabel] = useState<string>();
  const { t, i18n } = useTranslation();
  const [multipleValue, setMultipleValue] = useState<{ value: string; label: string }[]>([]);
  const onChange = (
    value: string | number | undefined,
    option: {
      value: string | number;
      label: string;
    }
  ) => {
    const key = properties.name;
    if (properties.multiple && !value) return;
    // @ts-ignore
    const newValue: Partial<ITEM> = properties.multiple
      ? {
          [key]: multipleValue.map((mVal) => mVal.value).concat(value + ''),
        }
      : {
          [key]: value,
        };
    if (properties.multiple) {
      if (!multipleValue.find((mV) => mV.value === value)) {
        setMultipleValue(multipleValue.concat({ value: value + '', label: option.label }));
      } else {
        setMultipleValue(multipleValue.filter((mVal) => mVal.value !== value));
      }
    } else {
      properties.onChange(newValue, {
        label: option.label,
        value: option.value,
      });
    }
  };

  useEffect(() => {
    if (properties.multiple) {
      const key = properties.name;
      // @ts-ignore
      const newValue: Partial<ITEM> = {
        [key]: multipleValue.map((mVal) => mVal.value),
      };
      properties.onChange(newValue);
    }
  }, [multipleValue]);

  const loadItems = () => {
    setLoading(true);
    properties
      .repository?.({ search: search })
      .then((newOptions) => {
        setOptions(newOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //useEffect para hacer la primera llamada
  useEffect(() => {
    if (properties.repository) {
      loadItems();
    }
  }, []);

  //useEffect to debounce API search
  useEffect(() => {
    if (search === undefined) return;
    const timeout = setTimeout(() => {
      loadItems();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    const option = options?.find((option) => option.value === value);
    if (value && !option && properties.pick) {
      properties
        .pick({ id: value })
        .then((lab) => {
          setLabel(lab);
        })
        .catch((err) => {
          setLabel(t('form.selectable.placeholder.title') ?? '');
        });
    } else {
      setLabel(option?.label ? option.label : t('form.selectable.placeholder.title') ?? '');
    }
  }, [value, i18n.language]);

  const renderNullableIcon = () => {
    if (!properties.nullable) {
      return null;
    }

    if (!value) {
      return null;
    }

    return (
      <TouchableOpacity
        disabled={properties.readonly}
        onPress={() => {
          if (!properties.multiple)
            onChange(undefined, {
              label: '',
              value: '',
            });
        }}
        style={SelectInputStyles.cancelStatusContainer}
      >
        <IconComponent name="times" iconStyle={[SelectInputStyles.statusStyle]} />
      </TouchableOpacity>
    );
  };

  const renderSearch = () => {
    if (properties.readonly) return null;

    return (
      <View style={SelectInputStyles.backgroundWhite}>
        <View style={SelectInputStyles.optionItem}>
          <IconComponent name="search" iconStyle={[SelectInputStyles.searchIconStyle]} />
          <TextInput
            placeholder={properties.placeholder ?? (t('common.search.placeholder') || 'Search')}
            value={search}
            placeholderTextColor={'#808080'}
            style={SelectInputStyles.searchInput}
            onChangeText={setSearch}
          />
        </View>
      </View>
    );
  };

  const renderCaretIcon = () => {
    if (properties.nullable && value) {
      return null;
    }

    if (properties.readonly) return null;

    return (
      <IconComponent
        name={focus ? 'angle-up' : 'angle-down'}
        containerstyle={SelectInputStyles.iconStatusContainer}
        iconStyle={[
          SelectInputStyles.statusStyle,
          properties.disabled || properties.readonly ? SelectInputStyles.inputDisabled : null,
        ]}
      />
    );
  };

  return (
    <>
      <TouchableOpacity
        disabled={properties.disabled || properties.readonly}
        onPress={() => {
          if (properties.readonly) return;
          setFocus(!focus);
        }}
        style={[SelectInputStyles.statusContainer]}
      >
        {renderCaretIcon()}
        <TextInput
          value={label}
          editable={false}
          style={[
            SelectInputStyles.input,
            properties.readonly ? SelectInputStyles.inputReadonly : null,

            focus ? SelectInputStyles.inputFocus : SelectInputStyles.inputUnfocus,
            properties.disabled ? SelectInputStyles.inputDisabled : null,
          ]}
        />

        {renderNullableIcon()}
      </TouchableOpacity>
      {focus && !properties.disabled && properties.repository && renderSearch()}
      {loading && <LoaderComponent loading={loading} />}
      {focus && !properties.disabled && !properties.readonly && (
        <ScrollView scrollEnabled style={SelectInputStyles.options}>
          {!options || options.length === 0 ? (
            <View style={SelectInputStyles.emptyOption}>
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('form.selectable.placeholder.empty')}
              </Text>
            </View>
          ) : (
            options?.map((option, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  if (!properties.multiple) setFocus(false);
                  onChange(option.value, {
                    label: option.label,
                    value: option.value,
                  });
                }}
                style={[
                  SelectInputStyles.optionItem,
                  index === options.length - 1 ? SelectInputStyles.lastOption : null,
                ]}
              >
                <View>
                  {/* <Text
                    style={{
                      fontWeight: '500',
                    }}
                  >
                    {option.label}
                  </Text> */}
                  <TextComponent
                    multiWorkspace={properties.multiWorkspace}
                    text={option.label}
                    style={{ fontWeight: '500' }}
                  />

                  {option.description && (
                    <TextComponent
                      multiWorkspace={properties.multiWorkspace}
                      light
                      style={{ color: 'rgb(51, 51, 51)', fontSize: 12 }}
                      text={option.description}
                    />
                  )}
                </View>
                {option.bubbles && (
                  <View style={SelectInputStyles.bubbles}>
                    {option.bubbles.map((bubble, index) => (
                      <View key={index} style={SelectInputStyles.bubble}>
                        <Text
                          style={{ fontWeight: '600', color: 'rgb(255, 255, 255)', fontSize: 12 }}
                        >
                          {bubble.label}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
              </TouchableOpacity>
            ))
          )}
        </ScrollView>
      )}
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 5,
        }}
      >
        {multipleValue.map((mValue) => {
          return (
            <FilterBadgeComponent
              key={mValue.value}
              value={mValue.label}
              onRemove={(value) => {
                setMultipleValue(multipleValue.filter((mV) => mV.label !== value));
              }}
            />
          );
        })}
      </View>
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
