import { Bar } from 'react-chartjs-2';
import { View } from 'react-native';
import { AppointedLeadsChartComponentProperties } from './appointedLeadsChart.properties';
import { useTranslation } from 'react-i18next';

export function AppointedLeadsChartComponent(properties: AppointedLeadsChartComponentProperties) {
  const { t } = useTranslation();

  const total = properties.data.total_leads;
  const appointed = properties.data.lead_appointed_count;

  const data = {
    labels: [t('board.leads.totalLeads.title'), t('leadEvents.appointed')],
    datasets: [
      {
        data: [total, appointed],
        backgroundColor: '#283038',
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 4,
        alignItems: 'stretch',
      }}
    >
      <Bar
        data={data}
        options={{
          responsive: true,
          aspectRatio: 1,
          scales: {
            y: {
              beginAtZero: true, // Comienza desde 0
            },
          },
          elements: {
            bar: {
              borderRadius: 6, // Radio del borde
            },
          },
          plugins: {
            legend: {
              display: false, // Oculta la leyenda si no quieres mostrarla
            },
          },
        }}
      />
    </View>
  );
}
