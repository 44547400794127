import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../../leads.routes';
import { FormComponent } from '@components/form/form.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function SpecialistUpdateLeadPart(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'specialist',
    icon: 'user',
    content: () => {
      return (
        <FormComponent<LeadCrmType>
          prefix="crm.leads.update.specialist.form"
          readonly={!hasPermissionUtilty({ name: 'crm.leads.editor' })}
          repository={{
            send: (data) => {
              return new LeadCrmRepository()
                .updateSpecialist({
                  specialistId: data.item.specialistId ?? '',
                  clinicId: properties.route.params.clinicId,
                  leadId: properties.route.params.id,
                })
                .then(() => showSuccess());
            },
            get: async () => {
              const lead = await new LeadCrmRepository().pick({
                id: properties.route.params.id,
                clinicId: properties.route.params.clinicId,
              });
              return {
                specialistId: lead.specialistId ?? '',
              };
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'specialistId',
              required: true,
              description: true,
              pick: (properties: { id: string }) => {
                return new SpecialistAccessRepository()
                  .pick({ id: properties.id })
                  .then((specialist) => `${specialist.name} ${specialist.surname}`);
              },
              repository: (settings) => {
                return new SpecialistAccessRepository()
                  .list({
                    search: settings.search,
                    page: 0,
                    limit: 1000,
                  })
                  .then((data) => {
                    return data.items.map((item) => ({
                      value: item.id,
                      label: `${item.name} ${item.surname}`,
                    }));
                  });
              },
            },
          ]}
        />
      );
    },
  };
}
