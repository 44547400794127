import { View } from 'react-native';
import { LaunchComponent } from '../../../../../components/launch/launch.component';
import { SplashComponent } from '../../../../../components/splash/splash.component';
import { AuthorizedSections } from '../../authorized.sections';

export function DesktopSpace() {
  return (
    <View
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <SplashComponent type="human" />
      <LaunchComponent sections={AuthorizedSections} />
    </View>
  );
}
