// Modules
import { loadAsync } from 'expo-font';

// Constants
import { FontLoaderConstants } from './font.constants';

/**
 * Font Loader Utility
 * @description Loads all fonts used in the app
 * @returns {Promise<void>}
 */
export async function FontUtility(): Promise<void> {
  await loadAsync(FontLoaderConstants.fonts);
}
