import { NavigationComponent } from '@components/navigation/navigation.component';
import { PatientsRoutes } from './patient.routes';
import { ListPatientsScreen } from './screens/list/list.patient.screen';
import { RouteProp } from '@react-navigation/native';
import { CrmRoutes } from '@human/crm/crm.routes';
import { CreatePatientScreen } from './screens/create/create.patient.screen';
import { UpdateReceiptScreen } from './screens/receipts/screens/update/update.receipt.screen';
import { CreatePaymentScreen } from './screens/receipts/screens/payment/create/create.payment.screen';
import { UpdatePaymentScreen } from './screens/receipts/screens/payment/update/update.payment.screen';
import { CreateReceiptScreen } from './screens/receipts/screens/create/create.receipt.screen';
import { UpdatePatientScreen } from './screens/update/update.patient.screen';
import { DisablePatientsScreen } from './screens/disable/disable.patients.screen';
import { EnablePatientsScreen } from './screens/enable/enable.patients.screen';
import { CreateTreatmentScreen } from '../treatment/screens/create/create.treatment.screen';
import { UpdateTreatmentScreen } from '../treatment/screens/update/update.treatment.screen';
import { EnableTreatmentScreen } from '../treatment/screens/enable/enable.treatment.screen';
import { DisableTreatmentScreen } from '../treatment/screens/disable/disable.treatment.screen';
import { CreateProcedureScreen } from '../procedure/screens/create/create.procedure.screen';

import { DeleteProceduresScreen } from '../procedure/screens/delete/delete.procedures.screen';
import { CreatePatientActionScreen } from './screens/update/parts/actions/create.action.patient';
import { UpdatePatientActionScreen } from './screens/update/parts/actions/update.action.patient';
import { UpdateActionNextResultPatientPart } from './screens/update/parts/actions/update.action.nextResult.patient';
import { CreateInterventionScreen } from '../interventions/screens/create/create.intervention.screen';
import { UpdateInterventionScreen } from '../interventions/screens/update/update.intervention.screen';
import { CreateInterventionTechniqueScreen } from '../interventions/screens/update/parts/create.interventionTechnique.screen';
import { DeleteInterventionTechniqueScreen } from '../interventions/screens/update/parts/delete.interventionTechnique.screen';
import { CreateMaintenanceScreen } from '../maintenance/screens/create/create.maintenance.screen';
import { UpdateMaintenanceScreen } from '../maintenance/screens/update/update.maintenance.screen';
import { PurchasesUpdatePatientPart } from './screens/update/parts/purchases.update.patient.part';
import { UpdatePurchaseScreen } from '../purchase/screens/update/update.purchase.screen';
import { EnablePurchaseScreen } from '../purchase/screens/enable/enable.purchase.screen';
import { DisablePurchaseScreen } from '../purchase/screens/disable/disable.purchase.screen';
import { DeletePatientScreen } from './screens/delete/delete.patient.screen';
import { CreateAlertScreen } from '../alerts/screens/create/create.alert.screen';
import { UpdateAlertScreen } from '../alerts/screens/update/update.alert.screen';
import { DeleteAlertScreen } from '../alerts/screens/delete/delete.alert.screen';
import { UpdateProcedureScreen } from '../procedure/screens/update/update.procedure.screen';
import { DocumentDeletePatientPart } from './screens/update/parts/document.delete.patient.part';
import { EnableInterventionScreen } from '../interventions/screens/enable/enable.intervention.screen';
import { DisableInterventionScreen } from '../interventions/screens/disable/disable.intervention.screen';

const Navigation = NavigationComponent<PatientsRoutes>();

export function PatientsSection(properties: { route: RouteProp<CrmRoutes, 'patients'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="patientList" component={ListPatientsScreen} />
      <Navigation.Screen name="patientCreate" component={CreatePatientScreen} />
      <Navigation.Screen name="patientUpdate" component={UpdatePatientScreen} />
      <Navigation.Screen name="patientDisable" component={DisablePatientsScreen} />
      <Navigation.Screen name="patientEnable" component={EnablePatientsScreen} />
      <Navigation.Screen name="patientDelete" component={DeletePatientScreen} />

      <Navigation.Screen name="receiptCreate" component={CreateReceiptScreen} />
      <Navigation.Screen name="receiptUpdate" component={UpdateReceiptScreen} />

      <Navigation.Screen name="paymentCreate" component={CreatePaymentScreen} />
      <Navigation.Screen name="paymentUpdate" component={UpdatePaymentScreen} />

      <Navigation.Screen name="createTreatment" component={CreateTreatmentScreen} />
      <Navigation.Screen name="updateTreatment" component={UpdateTreatmentScreen} />
      <Navigation.Screen name="enableTreatment" component={EnableTreatmentScreen} />
      <Navigation.Screen name="disableTreatment" component={DisableTreatmentScreen} />

      <Navigation.Screen name="createProcedure" component={CreateProcedureScreen} />
      <Navigation.Screen name="deleteProcedure" component={DeleteProceduresScreen} />
      <Navigation.Screen name="updateProcedure" component={UpdateProcedureScreen} />
      <Navigation.Screen name="patientActionCreate" component={CreatePatientActionScreen} />
      <Navigation.Screen name="patientActionUpdate" component={UpdatePatientActionScreen} />
      <Navigation.Screen
        name="patientActionNextResultUpdate"
        component={UpdateActionNextResultPatientPart}
      />

      <Navigation.Screen name="createIntervention" component={CreateInterventionScreen} />
      <Navigation.Screen name="updateIntervention" component={UpdateInterventionScreen} />
      <Navigation.Screen name="enableIntervention" component={EnableInterventionScreen} />
      <Navigation.Screen name="disableIntervention" component={DisableInterventionScreen} />
      <Navigation.Screen
        name="createInterventionTechnique"
        component={CreateInterventionTechniqueScreen}
      />
      <Navigation.Screen
        name="deleteInterventionTechnique"
        component={DeleteInterventionTechniqueScreen}
      />
      <Navigation.Screen name="createMaintenance" component={CreateMaintenanceScreen} />
      <Navigation.Screen name="updateMaintenance" component={UpdateMaintenanceScreen} />

      <Navigation.Screen name="updatePurchase" component={UpdatePurchaseScreen} />
      <Navigation.Screen name="enablePurchase" component={EnablePurchaseScreen} />
      <Navigation.Screen name="disablePurchase" component={DisablePurchaseScreen} />

      <Navigation.Screen name="alertCreate" component={CreateAlertScreen} />
      <Navigation.Screen name="alertUpdate" component={UpdateAlertScreen} />
      <Navigation.Screen name="alertDelete" component={DeleteAlertScreen} />

      <Navigation.Screen name="patientDocumentDelete" component={DocumentDeletePatientPart} />
    </Navigation.Navigator>
  );
}
