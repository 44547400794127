// Modules
import { StyleSheet } from 'react-native';

/**
 * Header Styles
 * @constant {StyleSheet} HeaderStyles
 */
export const HeaderStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerBackground: {
    backgroundColor: 'hwb(330.3 40.78% 19.61% / 0.46)',
    justifyContent: 'flex-start',
  },
  header: {
    padding: 20,
    flexDirection: 'row',
    paddingBottom: 0,
  },
  headerOvoText: {
    fontSize: 25,
    color: '#000',
    opacity: 0.6,
  },
  headerTypeText: {
    fontSize: 25,
    color: 'rgb(237, 53, 145)',
  },
  headerSubtitle: {
    gap: 5,
    marginLeft: 20,
    marginBottom: 20,
    opacity: 0.5,
    flexDirection: 'row',
  },
  headerByText: {
    color: '#000',
    fontSize: 13,
    opacity: 1,
  },
  headerLogotypeText: {
    color: '#000',
    fontSize: 13,
    opacity: 0.8,
  },
  searchIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 50,
    width: 50,
    opacity: 0.5,
    zIndex: 1,
  },
  searchIcon: {
    color: '#000',
    fontSize: 20,
    lineHeight: 50,
    textAlign: 'center',
  },
  searchInput: {
    height: 50,
    padding: 10,
    paddingLeft: 50,
    fontSize: 17,
    borderWidth: 0,
    borderTopWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.21)',
    borderBottomWidth: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
  },
  versionText: {
    color: '#000',
    fontSize: 10,
    opacity: 0.8,
  },
});
