import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CyclesRoutes } from '../../cycles.routes';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { CycleCrmType } from '@human/crm/types/cycle.crm.type';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { IconType } from '@components/icon/icon.types';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { t } from 'i18next';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListCyclesScreen(properties: {
  navigation: NavigationProp<CyclesRoutes>;
  route: RouteProp<CyclesRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];

  if (hasPermissionUtilty({ name: 'crm.cycles.creator' })) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<CycleCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.cycles.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          cycleId: row.id,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => row.name,
    },
    {
      name: 'code',
      type: 'text',
      width: 200,
      onRender: (row) => row.code,
    },
    {
      name: 'complexity',
      type: 'text',
      width: 200,
      onRender: (row) => t(`common.complexity.${row.complexity}`),
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.cycles.destructor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon="sync-alt" prefix={'crm.cycles.list'} links={links}>
      <TableComponent<CycleCrmType, []>
        prefix={'crm.cycles.list'}
        suffix={'list'}
        columns={columns}
        repository={(settings) =>
          new CycleCrmRepository().list({
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          })
        }
      />
    </SceneComponent>
  );
}
