import { StyleSheet } from 'react-native';

export const AvatarStyles = StyleSheet.create({
  smallContainer: {
    height: 50,
    borderRadius: 10,
    width: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  bigContainer: {
    height: 70,
    borderRadius: 10,
    width: 70,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 10,
  },
  iconContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'rgba(237, 53, 145, 0.5)',
  },
  ovoText: {
    backgroundColor: 'rgb(237, 53, 145)',
    fontSize: 12,
    position: 'absolute',
    bottom: -10,
    padding: 3,
    paddingHorizontal: 6,
    right: -10,
    zIndex: 10,
    lineHeight: 13,
    paddingBottom: 4,
    borderRadius: 100,
    color: '#fff',
  },
  hoverContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    zIndex: 5,
  },
  hoverIcon: {
    fontSize: 30,
    color: '#fff',
  },
});
