import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../../result.routes';
import { FormComponent } from '@components/form/form.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ResultIntegrationEventCrmRepository } from '@human/crm/repositories/resultIntegrationEvent/resultIntegrationEvent.repository';

export function UpdateResultIntegrationAchievement(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'integrationAchievement',
    icon: 'medal',
    content: (settings) => (
      <FormComponent<{
        landbotAppointed: boolean;
      }>
        prefix="crm.results.update.form"
        repository={{
          get: () => {
            return new ResultIntegrationEventCrmRepository().pick({
              resultId: properties.route.params.resultId,
              activityId: properties.route.params.activityId,
            });
          },
          send: (data) => {
            return new ResultIntegrationEventCrmRepository()
              .update({
                item: {
                  ...data.item,
                  activityId: properties.route.params.activityId,
                  resultId: properties.route.params.resultId,
                },
              })
              .then(() => {
                settings?.setIsEditing(false);
                showSuccess();
              });
          },
        }}
        fields={[
          {
            type: 'checkbox',
            name: 'landbotAppointed',
            description: true,
          },
        ]}
      />
    ),
  };
}
