import { NoteCrmType } from '@human/crm/types/note.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class NoteCrmRepository extends AuthorizedRepository {
  async findByContact(properties: { contactId: string; search?: string }): Promise<NoteCrmType[]> {
    return this.fetch(`note/find`, {
      method: 'GET',
      params: properties,
    }).then((notes) => {
      return notes.map((note: NoteCrmType) => ({
        ...note,
        updated: new Date(note.updated),
      }));
    });
  }

  async create({ item }: { item: { text: string; contactId: string } }): Promise<void> {
    return this.fetch('note/create', {
      method: 'POST',
      body: item,
    });
  }

  async update(properties: { text: string; noteId: string; contactId: string }): Promise<void> {
    return this.fetch(`note/update`, {
      method: 'PUT',
      body: properties,
    });
  }

  async delete(properties: { noteId: string; contactId: string }): Promise<void> {
    return this.fetch(`note/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
