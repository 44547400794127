import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { TreatmentsRoutes } from '../../treatment.routes';
import { useState } from 'react';

import { ActionComponent } from '@components/action/action.component';
import { FormCreateTreatmentScreen } from './parts/form.create.treatment.screen';
import { SelectProgramStep } from '@steps/program/selectProgram.step';

export function CreateTreatmentScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'createTreatment'>;
}) {
  const [programId, setProgramId] = useState<string>();
  const [programName, setProgramName] = useState<string>();

  const [phaseId, setPhaseId] = useState<string>();
  const [phaseName, setPhaseName] = useState<string>();

  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="tasks"
      prefix="crm.treatments.create"
      flex={false}
    >
      {!programId ? (
        <SelectProgramStep
          programId={programId}
          onSuccess={({ programId, programName }) => {
            setProgramId(programId);
            setProgramName(programName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setProgramId(undefined);
            setPhaseId(undefined);
          }}
          prefix="crm.patients.create.treatment.create.steps.program.form"
          bubble={programName}
        />
      )}
      {programId && <FormCreateTreatmentScreen {...properties} programId={programId} />}
    </ModalComponent>
  );
}
