import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { OvopointAccessType } from '@human/access/types/ovopoint.access.type';
import { UserOvopointAccessType } from '@human/access/types/user.ovopoint.access.type';

export class OvopointAccessRepository extends AuthorizedRepository {
  async update(properties: { item: Partial<OvopointAccessType> }): Promise<void> {
    return this.fetch('ovopoint/update', {
      method: 'PUT',
      body: properties.item,
    });
  }

  async pick(): Promise<OvopointAccessType> {
    return this.fetch('ovopoint/pick', {
      method: 'GET',
    });
  }

  async find(): Promise<UserOvopointAccessType[]> {
    return this.fetch('ovopoint/find', {
      method: 'GET',
    }).then((data) => {
      return data.map((item: any) => {
        return {
          date: new Date(item.date),
          total: item.total,
        };
      });
    });
  }
}
