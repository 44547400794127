import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class MarketingOvobankRepository extends AuthorizedRepository {
  leadStateAndMonth = async (properties: { month: number; clinicId?: string }) => {
    return this.fetch('board/common/leadStateByAndMonthOvobank', {
      method: 'GET',
      params: properties,
    });
  };

  eligibleNoEligibleCount = async (properties: { month: number; clinicId?: string }) => {
    return this.fetch('board/common/leadEligibleNoEligibleCount', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      if (!data) return;
      return {
        lead_eligible_count: data[0].lead_eligible_count,
        lead_no_eligible_count: data[0].lead_no_eligible_count,
        lead_medic_attended_count: data[0].lead_medic_attended_count,
      };
    });
  };
}
