import { View } from 'react-native';
import { TextComponent } from '../../../../../text/text.component';
import { DateColumnImplementation } from './date.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { AgoComponent } from '@components/ago/ago.component';
import { DateColumnStyles } from './date.column.styles';
import { isPast } from '@utils/date/isPast/isPast.utility';
import { formatTimeHhmmUtility } from '@utils/hhmm/hhmm.utility';
import { ItemTableType } from '@components/table/table.component';

export function DateColumnComponent<ITEM extends ItemTableType, ORDER extends OrderTableType<ITEM>>(
  properties: DateColumnImplementation<ITEM, ORDER>
) {
  const date = properties.renderDate?.(properties.item);
  const formatYYYYMMDD = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  return date ? (
    <View style={DateColumnStyles.container}>
      <TextComponent
        lines={1}
        bold
        text={formatYYYYMMDD(date)}
        style={[DateColumnStyles.textformatYYYYMMDD]}
      />
      <TextComponent
        lines={1}
        bold
        text={
          date
            ? formatTimeHhmmUtility({
                date,
              })
            : ''
        }
        style={[
          DateColumnStyles.textformatHHMM,
          properties.item.status
            ? DateColumnStyles.textformatHHMMEnabled
            : DateColumnStyles.textformatHHMMDisabled,
        ]}
      />

      {isPast(date) && (
        <AgoComponent
          date={date}
          style={[
            DateColumnStyles.agoComponent,
            properties.item.status
              ? DateColumnStyles.agoComponentEnabled
              : DateColumnStyles.agoComponentDisabled,
          ]}
        />
      )}
    </View>
  ) : null;
}
