
export function isPhone(value: unknown): value is string {

  if (typeof value !== 'string') return false;
  if (value.includes('+')) {
    if (isNaN(parseInt(value.trim().substring(1)))) return false;
  } else {
    if (isNaN(parseInt(value.trim()))) return false;
  }
  return true
}

