import { ScrollView, Text, TextInput, View } from 'react-native';
import { useEffect, useState } from 'react';
import { FilterSelectInputStyles } from './filter.select.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '@components/loader/loader.component';
import { FilterSelectInputImplementation } from './filter.select.implementation';
import { TouchableOpacity } from 'react-native-gesture-handler';

export function FilterSelectInput(properties: FilterSelectInputImplementation) {
  const value = properties.value as string;
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState(properties.options);
  const [label, setLabel] = useState<string>();
  const { t, i18n } = useTranslation();

  const onChange = (
    value: string | number | undefined,
    option: {
      value: string | number;
      label: string;
    }
  ) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: value,
    };
    properties.onChange(newValue, {
      label: option.label,
      value: option.value,
    });
  };

  const loadItems = () => {
    setLoading(true);
    properties
      .repository?.({ search: search })
      .then((newOptions) => {
        setOptions(newOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //useEffect para hacer la primera llamada
  useEffect(() => {
    if (properties.repository) {
      loadItems();
    }
  }, [properties.repository]);

  //useEffect para cuando cambien las opciones se actualicen
  useEffect(() => {
    setOptions(properties.options);
  }, [properties.options]);

  //useEffect to debounce API search
  useEffect(() => {
    if (search === undefined) return;
    const timeout = setTimeout(() => {
      loadItems();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    const option = options?.find((option) => option.value === value);
    if (value && !option && properties.pick) {
      properties
        .pick({ id: value })
        .then((lab) => {
          setLabel(lab);
        })
        .catch(() => {
          setLabel(
            properties.title ? properties.title : (t('form.selectable.placeholder.title') ?? '')
          );
        });
    } else {
      setLabel(
        option?.label
          ? option.label
          : ((properties.title ? properties.title : t('form.selectable.placeholder.title')) ?? '')
      );
    }
  }, [value, i18n.language, options]);

  const renderSearch = () => {
    if (properties.hideSearch) return null;

    return (
      <View style={FilterSelectInputStyles.searchContainer}>
        <View style={FilterSelectInputStyles.optionItem}>
          <IconComponent name="search" iconStyle={[FilterSelectInputStyles.statusStyle]} />
          <TextInput
            placeholder={properties.placeholder ?? (t('common.search.placeholder') || 'Search')}
            value={search}
            style={FilterSelectInputStyles.searchInput}
            onChangeText={setSearch}
          />
        </View>
      </View>
    );
  };

  const renderCaretIcon = () => {
    return (
      <IconComponent
        name={focus ? 'angle-up' : 'angle-down'}
        containerstyle={FilterSelectInputStyles.iconStatusContainer}
        iconStyle={[
          FilterSelectInputStyles.statusStyle,
          properties.optionStyle ? { color: '#808080' } : null,
        ]}
      />
    );
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          setFocus(!focus);
        }}
        style={[FilterSelectInputStyles.statusContainer]}
      >
        {renderCaretIcon()}
        <TextInput
          value={label}
          editable={false}
          style={[
            FilterSelectInputStyles.input,
            focus && FilterSelectInputStyles.inputFocus,
            properties.inputStyle,
            properties.optionStyle ? FilterSelectInputStyles.inputBoard : null,
          ]}
        />
      </TouchableOpacity>
      {focus && properties.repository && renderSearch()}
      {loading && <LoaderComponent loading={loading} />}
      <View>
        {focus && (
          <ScrollView
            style={[
              FilterSelectInputStyles.options,
              !properties.hideSearch && { top: 45 },
              properties.optionStyle ? FilterSelectInputStyles.optionsContainerBoard : null,
              options && options?.length > 8 && { maxHeight: 300 },
            ]}
            showsVerticalScrollIndicator={options && options?.length > 8}
          >
            {!options || options.length === 0 ? (
              <View style={FilterSelectInputStyles.emptyOption}>
                <Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {t('form.selectable.placeholder.empty')}
                </Text>
              </View>
            ) : (
              options?.map((option, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    setFocus(false);
                    onChange(option.value, {
                      label: option.label,
                      value: option.value,
                    });
                  }}
                  style={[
                    FilterSelectInputStyles.optionItem,
                    index === options.length - 1 ? FilterSelectInputStyles.lastOption : null,
                    properties.optionStyle
                      ? {
                          ...FilterSelectInputStyles.optionsBoard,
                          borderBottomWidth: index === options.length - 1 ? 0 : 1,
                        }
                      : null,
                  ]}
                >
                  <View>
                    <Text style={{ color: 'black', fontSize: 14, fontFamily: 'Lato-Regular' }}>
                      {option.label}
                    </Text>
                  </View>
                  {option.bubbles && (
                    <View style={FilterSelectInputStyles.bubbles}>
                      {option.bubbles.map((bubble, index) => (
                        <View key={index} style={FilterSelectInputStyles.bubble}>
                          <Text
                            style={{ fontWeight: '600', color: 'rgb(255, 255, 255)', fontSize: 12 }}
                          >
                            {bubble.label}
                          </Text>
                        </View>
                      ))}
                    </View>
                  )}
                </TouchableOpacity>
              ))
            )}
          </ScrollView>
        )}
      </View>
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
