import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { ProgramCrmRepository } from '@human/crm/repositories/program/program.repository';

/**
 * Create Treatment for patient
 * @description Este formulario sirve para escoger el tratamiento
 * en una intervención del paciente
 * @returns JSX.Element
 */
export function SelectProgramStep(properties: {
  programId?: string;
  onSuccess: (settings: { programId: string; programName: string }) => void;
}) {
  return (
    <FieldContainer
      prefix="crm.patients.create.steps.program.form"
      type="select"
      required
      name="programId"
      description
      padding
    >
      <SelectInput<{ programId: string }>
        type={'select'}
        name="programId"
        required
        value={{
          programId: properties.programId || undefined,
        }}
        description
        onChange={(value, option) => {
          if (!value.programId) return;
          properties.onSuccess({ programId: value.programId, programName: option.label });
        }}
        repository={(props: { search?: string }) => {
          return new ProgramCrmRepository()
            .list({
              search: props.search,
              page: 0,
              limit: 100,
            })
            .then((programs) => {
              const auxPrograms = programs.items.map((program) => ({
                label: program.name,
                value: program.id,
              }));
              return auxPrograms;
            });
        }}
      />
    </FieldContainer>
  );
}
