import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CommunicationChannelsRoutes } from '../../communicationChannels.routes';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { IconType } from '@components/icon/icon.types';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { CommunicationChannelAccessType } from '@human/access/types/communicationChannel.access.type';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { CommunicationChannelAccessRepository } from '@human/access/repositories/communicationChannel/communicationChannel.repository';

export function ListCommunicationChannelsScreen(properties: {
  navigation: NavigationProp<CommunicationChannelsRoutes>;
  route: RouteProp<CommunicationChannelsRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];

  if (hasPermissionUtilty({ name: 'access.commsChannels.creator' })) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<CommunicationChannelAccessType, ['name']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.commsChannels.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', { id: row.id });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => row.name,
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => new Date(row.created),
    },
  ];

  if (hasPermissionUtilty({ name: 'access.commsChannels.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={'envelope'} prefix={'access.communicationChannels.list'} links={links}>
      <TableComponent<CommunicationChannelAccessType, ['name']>
        prefix={'access.communicationChannels.list'}
        suffix={'list'}
        columns={columns}
        repository={(properties) => {
          return new CommunicationChannelAccessRepository().list({
            search: properties.search,
            page: properties.page,
            limit: properties.limit,
          });
        }}
      />
    </SceneComponent>
  );
}
