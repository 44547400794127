import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';

import { NavigationProp, RouteProp } from '@react-navigation/native';

export function CreateProgramPhaseScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programPhaseCreate'>;
}) {
  return (
    <ModalComponent icon="plus" prefix="crm.programs.update.phases.create">
      <FormComponent<{ phaseId: string }>
        padding
        prefix="crm.programs.update.phases.create.form"
        repository={{
          send: ({ item }) => {
            return new ProgramPhaseCrmRepository()
              .create({
                programId: properties.route.params.programId,
                phaseId: item.phaseId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'phaseId',
            description: true,
            required: true,
            pick: (props: { id: string }) => {
              return new PhaseCrmRepository().pick({ phaseId: props.id }).then((phase) => {
                return `${phase.name}`;
              });
            },
            repository: ({ search }) => {
              return new PhaseCrmRepository()
                .list({ direction: 'asc', page: 0, limit: 100, search, active: true })
                .then((response) =>
                  response.items.map((item) => ({ label: item.name, value: item.id }))
                );
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
