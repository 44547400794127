import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CyclesRoutes } from '../../cycles.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';

export function EnableCycleScreen(properties: {
  navigation: NavigationProp<CyclesRoutes>;
  route: RouteProp<CyclesRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.cycles.enable">
      <FormComponent
        prefix="crm.cycles.enable.form"
        padding
        repository={{
          send: () => {
            return new CycleCrmRepository()
              .enable({
                cycleId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
