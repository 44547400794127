import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { RoleAccessType } from '@human/access/types/role.access.type';
import { RoleAccessRepository } from '@human/access/repositories/role/role.repository';
import { RolesRoutes } from '../../roles.routes';

export function CreateRoleScreen(properties: {
  navigation: NavigationProp<RolesRoutes>;
  route: RouteProp<RolesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="tag" prefix="access.roles.create">
      <FormComponent<RoleAccessType>
        prefix="access.roles.create.form"
        padding
        repository={{
          send: (settings) => {
            return new RoleAccessRepository()
              .create({
                item: settings.item,
              })
              .then(() => {
                properties.navigation.navigate('list', {
                  groupId: properties.route.params.groupId,
                });
              });
          },
          get: async () => ({
            // Permisos Generales
            // ------------------------------------------------
            viewDisabledRecords: false, // Permite ver los registros que han sido deshabilitados
            viewDisabledLeadRecords: false, // Permite ver los registros de leads que han sido deshabilitados
            viewDisabledPatientRecords: false, // Permite ver los registros de pacientes que han sido deshabilitados

            // PERMISOS DE ovoACCESS
            // ------------------------------------------------
            access: false,
            accessUser: false,
            accessUserCreator: false,
            accessUserEditor: false,
            accessUserDestructor: false,
            accessUserChangePassword: false,
            accessRole: false,
            accessRoleCreator: false,
            accessRoleEditor: false,
            accessRoleDestructor: false,
            accessTimetable: false,
            accessTimetableCreator: false,
            accessTimetableEditor: false,
            accessTimetableDestructor: false,
            accessBulletin: false,
            accessBulletinCreator: false,
            accessBulletinEditor: false,
            accessBulletinDestructor: false,
            accessClinic: false,
            accessClinicCreator: false,
            accessClinicEditor: false,
            accessClinicDestructor: false,
            accessDepartment: false,
            accessDepartmentCreator: false,
            accessDepartmentEditor: false,
            accessDepartmentDestructor: false,
            accessLanguage: false,
            accessLanguageCreator: false,
            accessLanguageEditor: false,
            accessLanguageDestructor: false,
            accessCountry: false,
            accessCountryCreator: false,
            accessCountryEditor: false,
            accessCountryDestructor: false,
            accessSettings: false,
            accessSpecialist: false,
            accessSpecialistCreator: false,
            accessSpecialistEditor: false,
            accessSpecialistDestructor: false,
            accessCommsChannel: false,
            accessCommsChannelCreator: false,
            accessCommsChannelEditor: false,
            accessCommsChannelDestructor: false,
            accessOvopoint: false,
            accessOvopointEditor: false,
            accessTemplate: false,
            accessTemplateCreator: false,
            accessTemplateEditor: false,
            accessTemplateDestructor: false,

            // PERMISOS DE CRM
            // ------------------------------------------------
            crm: false,
            crmCalendar: false,
            crmTask: false,
            crmTaskCreator: false,
            crmTaskEditor: false,
            crmTaskDestructor: false,
            crmTaskSeeAll: false,
            crmTaskSeeCoworkers: false,
            crmTaskUpdateResponsible: false,
            crmLead: false,
            crmLeadCreator: false,
            crmLeadEditor: false,
            crmLeadDestructor: false,
            crmLeadSeeAll: false,
            crmLeadSeeCoworkers: false,
            crmLeadUpdateCampaign: false,
            crmPatient: false,
            crmPatientCreator: false,
            crmPatientEditor: false,
            crmPatientDestructor: false,
            crmPatientSeeAll: false,
            crmPatientSeeCoworkers: false,
            crmContact: false,
            crmContactCreator: false,
            crmContactEditor: false,
            crmContactDestructor: false,
            crmActivity: false,
            crmActivityCreator: false,
            crmActivityEditor: false,
            crmActivityDestructor: false,
            crmActivitySeeAll: false,
            crmResult: false,
            crmResultCreator: false,
            crmResultEditor: false,
            crmResultDestructor: false,
            crmResultCCEvent: false,
            crmResultATPCEvent: false,
            crmResultATPIEvent: false,
            crmResultReactivity: false,
            crmReason: false,
            crmReasonCreator: false,
            crmReasonEditor: false,
            crmReasonDestructor: false,
            crmCampaign: false,
            crmCampaignCreator: false,
            crmCampaignEditor: false,
            crmCampaignDestructor: false,
            crmFunnel: false,
            crmFunnelCreator: false,
            crmFunnelEditor: false,
            crmFunnelDestructor: false,
            crmChannel: false,
            crmChannelCreator: false,
            crmChannelEditor: false,
            crmChannelDestructor: false,
            crmProgram: false,
            crmProgramCreator: false,
            crmProgramEditor: false,
            crmProgramDestructor: false,
            crmService: false,
            crmServiceCreator: false,
            crmServiceEditor: false,
            crmServiceDestructor: false,
            crmTechnique: false,
            crmTechniqueCreator: false,
            crmTechniqueEditor: false,
            crmTechniqueDestructor: false,
            crmMaintenance: false,
            crmMaintenanceCreator: false,
            crmMaintenanceEditor: false,
            crmMaintenanceDestructor: false,
            crmTreatment: false,
            crmTreatmentCreator: false,
            crmTreatmentEditor: false,
            crmTreatmentDestructor: false,
            crmPhase: false,
            crmPhaseCreator: false,
            crmPhaseEditor: false,
            crmPhaseDestructor: false,
            crmCause: false,
            crmCauseCreator: false,
            crmCauseEditor: false,
            crmCauseDestructor: false,
            crmCycle: false,
            crmCycleCreator: false,
            crmCycleEditor: false,
            crmCycleDestructor: false,

            // PERMISOS DE ovoCLINIC
            // ------------------------------------------------
            clinic: false,

            // PERMISOS DE ovoBOARD
            // ------------------------------------------------
            board: false,
            boardLeadsResume: false,
            boardSaleResume: false,
            boardSaleFunnel: false,
            boardExpectedIntervention: false,
            boardCoworkerPerformance: false,
            boardMarketing: false,

            // PERMISOS DE ovoTORY
            // ------------------------------------------------
            tory: false,

            // PERMISOS DE ovoPROFILE
            // ------------------------------------------------
            profile: false,
          }),
        }}
        fields={[
          {
            name: 'name',
            required: true,
            description: true,
            icon: 'id-card',
            type: 'text',
            validations: ['name'],
          },
          {
            type: 'accordion',
            name: 'general',
            icon: 'user-shield',

            fields: [
              { name: 'viewDisabledRecords', type: 'checkbox' },
              { name: 'viewDisabledLeadRecords', type: 'checkbox' },
              { name: 'viewDisabledPatientRecords', type: 'checkbox', multiWorkspace: true },
            ],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'access',
            fields: [
              { name: 'access', type: 'checkbox' },
              {
                type: 'accordion',
                icon: 'user-alt',
                name: 'user',
                fields: [
                  { name: 'accessUser', type: 'checkbox' },
                  { name: 'accessUserCreator', type: 'checkbox' },
                  { name: 'accessUserEditor', type: 'checkbox' },
                  { name: 'accessUserDestructor', type: 'checkbox' },
                  { name: 'accessUserChangePassword', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'user-secret',
                name: 'role',
                fields: [
                  { name: 'accessRole', type: 'checkbox' },
                  { name: 'accessRoleCreator', type: 'checkbox' },
                  { name: 'accessRoleEditor', type: 'checkbox' },
                  { name: 'accessRoleDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'calendar-times',
                name: 'timetable',
                fields: [
                  { name: 'accessTimetable', type: 'checkbox' },
                  { name: 'accessTimetableCreator', type: 'checkbox' },
                  { name: 'accessTimetableEditor', type: 'checkbox' },
                  { name: 'accessTimetableDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'newspaper',
                name: 'bulletin',
                fields: [
                  { name: 'accessBulletin', type: 'checkbox' },
                  { name: 'accessBulletinCreator', type: 'checkbox' },
                  { name: 'accessBulletinEditor', type: 'checkbox' },
                  { name: 'accessBulletinDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'clinic-medical',
                name: 'clinic',
                fields: [
                  { name: 'accessClinic', type: 'checkbox' },
                  { name: 'accessClinicCreator', type: 'checkbox' },
                  { name: 'accessClinicEditor', type: 'checkbox' },
                  { name: 'accessClinicDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'hospital',
                name: 'department',
                fields: [
                  { name: 'accessDepartment', type: 'checkbox' },
                  { name: 'accessDepartmentCreator', type: 'checkbox' },
                  { name: 'accessDepartmentEditor', type: 'checkbox' },
                  { name: 'accessDepartmentDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'language',
                name: 'language',
                fields: [
                  { name: 'accessLanguage', type: 'checkbox' },
                  { name: 'accessLanguageCreator', type: 'checkbox' },
                  { name: 'accessLanguageEditor', type: 'checkbox' },
                  { name: 'accessLanguageDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'globe',
                name: 'country',
                fields: [
                  { name: 'accessCountry', type: 'checkbox' },
                  { name: 'accessCountryCreator', type: 'checkbox' },
                  { name: 'accessCountryEditor', type: 'checkbox' },
                  { name: 'accessCountryDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'user',
                name: 'specialists',
                fields: [
                  { name: 'accessSpecialist', type: 'checkbox' },
                  { name: 'accessSpecialistCreator', type: 'checkbox' },
                  { name: 'accessSpecialistEditor', type: 'checkbox' },
                  { name: 'accessSpecialistDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'envelope',
                name: 'commsChannels',
                fields: [
                  { name: 'accessCommsChannel', type: 'checkbox' },
                  { name: 'accessCommsChannelCreator', type: 'checkbox' },
                  { name: 'accessCommsChannelEditor', type: 'checkbox' },
                  { name: 'accessCommsChannelDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'coins',
                name: 'ovopoints',
                fields: [
                  { name: 'accessOvopoint', type: 'checkbox' },
                  { name: 'accessOvopointEditor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'whatsapp',
                name: 'templates',
                fields: [
                  { name: 'accessTemplate', type: 'checkbox' },
                  { name: 'accessTemplateCreator', type: 'checkbox' },
                  { name: 'accessTemplateEditor', type: 'checkbox' },
                  { name: 'accessTemplateDestructor', type: 'checkbox' },
                ],
              },
            ],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'crm',
            fields: [
              { name: 'crm', type: 'checkbox' },
              {
                type: 'accordion',
                icon: 'stream',
                name: 'tasks',
                fields: [
                  { name: 'crmCalendar', type: 'checkbox' },
                  { name: 'crmTask', type: 'checkbox' },
                  { name: 'crmTaskCreator', type: 'checkbox' },
                  { name: 'crmTaskEditor', type: 'checkbox' },
                  { name: 'crmTaskDestructor', type: 'checkbox' },
                  { name: 'crmTaskSeeAll', type: 'checkbox' },
                  { name: 'crmTaskSeeCoworkers', type: 'checkbox' },
                  { name: 'crmTaskUpdateResponsible', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'user-ninja',
                name: 'leads',
                fields: [
                  { name: 'crmLead', type: 'checkbox' },
                  { name: 'crmLeadCreator', type: 'checkbox' },
                  { name: 'crmLeadEditor', type: 'checkbox' },
                  { name: 'crmLeadDestructor', type: 'checkbox' },
                  { name: 'crmLeadSeeAll', type: 'checkbox' },
                  { name: 'crmLeadSeeCoworkers', type: 'checkbox' },
                  { name: 'crmLeadUpdateCampaign', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'patients',
                icon: 'user-plus',
                multiWorkspace: true,
                fields: [
                  { name: 'crmPatient', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmPatientCreator', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmPatientEditor', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmPatientDestructor', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmPatientSeeAll', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmPatientSeeCoworkers', type: 'checkbox', multiWorkspace: true },
                ],
              },
              {
                type: 'accordion',
                icon: 'user',
                name: 'contacts',
                fields: [
                  { name: 'crmContact', type: 'checkbox' },
                  { name: 'crmContactCreator', type: 'checkbox' },
                  { name: 'crmContactEditor', type: 'checkbox' },
                  { name: 'crmContactDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'tasks',
                name: 'activity',
                fields: [
                  { name: 'crmActivity', type: 'checkbox' },
                  { name: 'crmActivityCreator', type: 'checkbox' },
                  { name: 'crmActivityEditor', type: 'checkbox' },
                  { name: 'crmActivityDestructor', type: 'checkbox' },
                  { name: 'crmActivitySeeAll', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                icon: 'resolving',
                name: 'results',
                fields: [
                  { name: 'crmResult', type: 'checkbox' },
                  { name: 'crmResultCreator', type: 'checkbox' },
                  { name: 'crmResultEditor', type: 'checkbox' },
                  { name: 'crmResultDestructor', type: 'checkbox' },
                  { name: 'crmResultCCEvent', type: 'checkbox' },
                  { name: 'crmResultATPCEvent', type: 'checkbox' },
                  { name: 'crmResultATPIEvent', type: 'checkbox' },
                  { name: 'crmResultReactivity', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'reasons',
                icon: 'handshake',
                fields: [
                  { name: 'crmReason', type: 'checkbox' },
                  { name: 'crmReasonCreator', type: 'checkbox' },
                  { name: 'crmReasonEditor', type: 'checkbox' },
                  { name: 'crmReasonDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'campaigns',
                icon: 'th-large',
                fields: [
                  { name: 'crmCampaign', type: 'checkbox' },
                  { name: 'crmCampaignCreator', type: 'checkbox' },
                  { name: 'crmCampaignEditor', type: 'checkbox' },
                  { name: 'crmCampaignDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'funnels',
                icon: 'user',
                fields: [
                  { name: 'crmFunnel', type: 'checkbox' },
                  { name: 'crmFunnelCreator', type: 'checkbox' },
                  { name: 'crmFunnelEditor', type: 'checkbox' },
                  { name: 'crmFunnelDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'channels',
                icon: 'user',
                fields: [
                  { name: 'crmChannel', type: 'checkbox' },
                  { name: 'crmChannelCreator', type: 'checkbox' },
                  { name: 'crmChannelEditor', type: 'checkbox' },
                  { name: 'crmChannelDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'programs',
                icon: 'syringe',
                fields: [
                  { name: 'crmProgram', type: 'checkbox' },
                  { name: 'crmProgramCreator', type: 'checkbox' },
                  { name: 'crmProgramEditor', type: 'checkbox' },
                  { name: 'crmProgramDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'services',
                icon: 'resolving',
                fields: [
                  { name: 'crmService', type: 'checkbox' },
                  { name: 'crmServiceCreator', type: 'checkbox' },
                  { name: 'crmServiceEditor', type: 'checkbox' },
                  { name: 'crmServiceDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'techniques',
                icon: 'tasks',
                fields: [
                  { name: 'crmTechnique', type: 'checkbox' },
                  { name: 'crmTechniqueCreator', type: 'checkbox' },
                  { name: 'crmTechniqueEditor', type: 'checkbox' },
                  { name: 'crmTechniqueDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'maintenances',
                icon: 'tasks',
                fields: [
                  { name: 'crmMaintenance', type: 'checkbox' },
                  { name: 'crmMaintenanceCreator', type: 'checkbox' },
                  { name: 'crmMaintenanceEditor', type: 'checkbox' },
                  { name: 'crmMaintenanceDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'treatments',
                icon: 'tasks',
                fields: [
                  { name: 'crmTreatment', type: 'checkbox' },
                  { name: 'crmTreatmentCreator', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmTreatmentEditor', type: 'checkbox', multiWorkspace: true },
                  { name: 'crmTreatmentDestructor', type: 'checkbox', multiWorkspace: true },
                ],
              },
              {
                type: 'accordion',
                name: 'phases',
                icon: 'tasks',
                fields: [
                  { name: 'crmPhase', type: 'checkbox' },
                  { name: 'crmPhaseCreator', type: 'checkbox' },
                  { name: 'crmPhaseEditor', type: 'checkbox' },
                  { name: 'crmPhaseDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'causes',
                icon: 'resolving',
                fields: [
                  { name: 'crmCause', type: 'checkbox' },
                  { name: 'crmCauseCreator', type: 'checkbox' },
                  { name: 'crmCauseEditor', type: 'checkbox' },
                  { name: 'crmCauseDestructor', type: 'checkbox' },
                ],
              },
              {
                type: 'accordion',
                name: 'cycles',
                icon: 'sync-alt',
                fields: [
                  { name: 'crmCycle', type: 'checkbox' },
                  { name: 'crmCycleCreator', type: 'checkbox' },
                  { name: 'crmCycleEditor', type: 'checkbox' },
                  { name: 'crmCycleDestructor', type: 'checkbox' },
                ],
              },
            ],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'clinic',
            fields: [{ name: 'clinic', type: 'checkbox' }],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'board',
            fields: [
              { name: 'board', type: 'checkbox' },
              { name: 'boardLeadsResume', type: 'checkbox' },
              { name: 'boardSaleResume', type: 'checkbox' },
              { name: 'boardSaleFunnel', type: 'checkbox' },
              { name: 'boardExpectedIntervention', type: 'checkbox' },
              { name: 'boardCoworkerPerformance', type: 'checkbox' },
              { name: 'boardMarketing', type: 'checkbox' },
            ],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'tory',
            fields: [{ name: 'tory', type: 'checkbox' }],
          },
          {
            type: 'accordion',
            icon: 'user-shield',
            name: 'profile',
            fields: [{ name: 'profile', type: 'checkbox' }],
          },
        ]}
      />
    </ModalComponent>
  );
}
