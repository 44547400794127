import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class UserActivityAssignmentAccessRepository extends AuthorizedRepository {
  async pick(properties: { userId: string }) {
    return this.fetch('user/pick/activityAssignment', {
      method: 'GET',
      params: properties,
    });
  }

  async update(properties: { userId: string; activityId: string | undefined }) {
    return this.fetch('user/update/activityAssignment', {
      method: 'PUT',
      body: properties,
    });
  }
}
