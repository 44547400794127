import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-native';

export function BoardHeaderComponent(properties: { title: string; description: string }) {
  const { t } = useTranslation();
  return (
    <View style={{ marginBottom: 10 }}>
      <Text style={{ fontSize: 18, marginBottom: 2, fontWeight: '600' }}>
        {t(properties.title)}
      </Text>
      <Text style={{ fontSize: 14, marginBottom: 20, color: 'grey' }}>
        {t(properties.description)}
      </Text>
    </View>
  );
}
