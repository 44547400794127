import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicsRoutes } from '../../clinics.routes';
import { TabsComponent } from '@components/tabs/tabs.component';
import { DetailUpdateClinicPart } from './parts/detail/detail.part';
import { DepartmentsPart } from './parts/departments/departments.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateClinicScreen(properties: {
  navigation: NavigationProp<ClinicsRoutes>;
  route: RouteProp<ClinicsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <ModalComponent width={700} icon="clinic-medical" prefix="access.clinics.update">
      <TabsComponent
        width={700}
        prefix="access.clinics.update"
        tabs={[
          {
            icon: 'clinic-medical',
            name: 'details',
            content: (settings) => (
              <DetailUpdateClinicPart
                clinicId={properties.route.params.clinicId}
                goBack={() => {
                  settings?.setIsEditing(false);
                  showSuccess();
                }}
                onChange={(data) => {
                  if (!data.isLocal) return;
                  settings?.setIsEditing(true);
                }}
              />
            ),
          },
          {
            icon: 'hospital',
            name: 'departments',
            content: () => (
              <DepartmentsPart
                clinicId={properties.route.params.clinicId}
                navigation={properties.navigation}
              />
            ),
          },
        ]}
      />
    </ModalComponent>
  );
}
