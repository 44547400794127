import { createStackNavigator } from "@react-navigation/stack";
import { View } from "react-native";
import { PerformanceComponent } from "../../../components/performance/performance.component";
import { DesktopSpace } from "./spaces/desktop/desktop.space";
import { AuthorizedSpaces } from "./horse.spaces";
const Drawer = createStackNavigator<AuthorizedSpaces>();
export function HorseNavigator() {
    return <View style={{
        flex: 1,
        flexDirection: 'row'
    }}>
        <Drawer.Navigator
            screenOptions={{

                headerShown: false,

            }}
        >
            <Drawer.Screen name="desktop" component={DesktopSpace} />
        </Drawer.Navigator>
    </View>

}