import { ItemTableType } from '@components/table/table.component';

export function exportCsvUtility<ITEM extends ItemTableType>(properties: {
  items: ITEM[];
  name: string;
}) {
  if (properties.items.length === 0) return;
  const headers = Object.keys(properties.items[0]);

  // Convertir cada objeto a una fila CSV
  const rows = properties.items.map((item) =>
    headers.map((header) => JSON.stringify(item[header] || '')).join(',')
  );

  const data = [headers.join(','), ...rows].join('\n');
  const blob = new Blob([data], { type: 'text/csv' });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${properties.name}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function exportStringCsvUtility(properties: { data: string; name: string }) {
  // Crear un Blob con los datos CSV
  const blob = new Blob([properties.data], { type: 'text/csv;charset=utf-8;' });

  // Crear un enlace temporal para la descarga
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  // Asignar el enlace al blob generado
  link.href = url;
  link.setAttribute('download', properties.name);

  // Agregar el enlace al DOM, hacer clic, y luego eliminarlo
  document.body.appendChild(link);
  link.click();

  // Limpiar el enlace temporal
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
