import { View } from 'react-native';
import { ColorColumnStyle } from './color.column.styles';
import { ColorColumnImplementation } from './color.column.properties';

export function ColorColumnComponent<T>(properties: ColorColumnImplementation<T>) {
  return (
    <View style={ColorColumnStyle.container}>
      <View
        style={[
          ColorColumnStyle.background,
          { backgroundColor: properties.renderColor?.(properties.item) ?? '#808080' },
        ]}
      />
    </View>
  );
}
