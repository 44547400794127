import { RouteProp } from '@react-navigation/native';
import { TemplatesRoutes } from './templates.routes';
import { AccessRoutes } from '@human/access/access.routes';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { ListTemplatesScreen } from './screens/list/list.templates.screen';
import { CreateTemplatesScreen } from './screens/create/create.templates.screen';
import { UpdateTemplateScreen } from './screens/update/update.templates.screen';
import { DeleteTemplateScreen } from './screens/delete/delete.template.screen';
import { DisableTemplateScreen } from './screens/disable/disable.template.screen';
import { EnableTemplateScreen } from './screens/enable/enable.template.screen';
import { CreateTemplateScopeScreen } from './templateScopes/screens/create/create.templateScopes.screen';
import { DeleteTemplateScopesScreen } from './templateScopes/screens/delete/delete.templateScopes.screen';
import { CreateTemplateParamsScreen } from './templateParams/screen/create/create.templateParams.screen';
import { DeleteTemplateParamsScreen } from './templateParams/screen/delete/delete.templateParams.screen';

const Navigation = NavigationComponent<TemplatesRoutes>();

export function TemplatesSections(properties: { route: RouteProp<AccessRoutes, 'templates'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListTemplatesScreen} />
      <Navigation.Screen name="create" component={CreateTemplatesScreen} />
      <Navigation.Screen name="update" component={UpdateTemplateScreen} />
      <Navigation.Screen name="delete" component={DeleteTemplateScreen} />
      <Navigation.Screen name="disable" component={DisableTemplateScreen} />
      <Navigation.Screen name="enable" component={EnableTemplateScreen} />
      <Navigation.Screen name="createTemplateScope" component={CreateTemplateScopeScreen} />
      <Navigation.Screen name="deleteTemplateScope" component={DeleteTemplateScopesScreen} />
      <Navigation.Screen name="createTemplateParam" component={CreateTemplateParamsScreen} />
      <Navigation.Screen name="deleteTemplateParam" component={DeleteTemplateParamsScreen} />
    </Navigation.Navigator>
  );
}
