import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { SpecialistsRoutes } from '../../specialists.routes';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';

export function DisableSpecialistScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes>;
  route: RouteProp<SpecialistsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.specialists.disable">
      <FormComponent
        prefix="access.specialists.disable.form"
        padding
        repository={{
          send: () => {
            return new SpecialistAccessRepository()
              .disable({
                id: properties.route.params.specialistId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
