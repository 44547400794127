import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';

export function CreatePatientActionScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientActionCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="resolving"
      prefix="crm.patients.actions.create"
    >
      <FormComponent<ActionCrmType>
        prefix="crm.patients.actions.create.form"
        padding
        repository={{
          send: (settings) => {
            return new ActionCrmRepository()
              .createPatientAction({
                item: {
                  ...settings.item,
                  patientId: properties.route.params.patientId,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'subject',
            description: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'activityId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return `${activity.name}`;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository()
                .list({
                  direction: 'asc',
                  limit: 100,
                  order: 'id',
                  page: 0,
                  search,
                  active: true,
                })
                .then((activities) =>
                  activities.items.map((activity) => ({
                    label: activity.name,
                    value: activity.id,
                  }))
                );
            },
          },
          {
            type: 'select',
            name: 'responsibleId',
            required: true,
            description: true,
            pick: (props: { id: string }) => {
              return new CoworkerCrmRepository()
                .pick({
                  clinicId: properties.route.params.clinicId,
                  coworkerId: props.id,
                })
                .then((coworker) => {
                  return `${coworker.name} ${coworker.surname}`;
                });
            },
            repository: ({ search }) => {
              return new CoworkerCrmRepository()
                .list({
                  clinicId: properties.route.params.clinicId,
                  direction: 'asc',
                  limit: 100,
                  order: 'email',
                  page: 0,
                  search,
                  active: true,
                })
                .then((leads) =>
                  leads.items.map((user) => ({
                    label: `${user.name} ${user.surname}`,
                    value: user.id,
                  }))
                );
            },
          },
          {
            type: 'date',
            description: true,
            required: true,
            name: 'started',
            format: 'YYYY-MM-DD HH:mm:ss',
          },
        ]}
      />
    </ModalComponent>
  );
}
