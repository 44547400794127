// Modules
import { StyleSheet } from 'react-native';

/**
 * ElementColumn Styles
 * @constant {StyleSheet} ElementColumnStyles
 */
export const ElementColumnStyles = StyleSheet.create({
  container: {
    margin: 'auto',
    paddingHorizontal: 7,
    alignContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
  },
  icon: {
    fontSize: 20,
  },
  iconContainer: {
    margin: 'auto',
    borderWidth: 2,
    borderRadius: 20,
  },
});
