import { View, Text, TouchableOpacity } from 'react-native';
import { CopyToClipboardStyles } from './copy.to.clipboard.styles';
import { IconComponent } from '@components/icon/icon.component';
import { CopyToClipboardProperties } from './copy.to.clipboard.properties';
import { useToast } from 'react-native-toast-notifications';
import * as Clipboard from 'expo-clipboard';

export function CopyToClipboard(properties: CopyToClipboardProperties) {
  const toast = useToast();

  const handlePress = async () => {
    Clipboard.setStringAsync(properties.value).then(() => {
      toast.show(properties.toastText, {
        placement: 'top',
        type: 'success',
        duration: 2000,
      });
    });
  };

  return (
    <View style={CopyToClipboardStyles.container}>
      <Text style={CopyToClipboardStyles.text} numberOfLines={1} ellipsizeMode="tail">
        {properties.value}
      </Text>
      <TouchableOpacity onPress={handlePress}>
        <IconComponent
          name="link"
          containerstyle={CopyToClipboardStyles.iconContainer}
          iconStyle={CopyToClipboardStyles.iconStyle}
        />
      </TouchableOpacity>
    </View>
  );
}
