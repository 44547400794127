import axios from 'axios';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { DocumentCrmType } from '@human/crm/types/document.crm.type';
import { apiUrl } from '@environment/apiUrl.utility';
import { useUserState } from '@states/user/user.state';

export class DocumentCrmRepository extends AuthorizedRepository {
  async listByPatient(properties: {
    page?: number;
    limit?: number;
    search?: string;
    clinicId: string;
    patientId: string;
    order?: keyof DocumentCrmType;
    direction?: 'asc' | 'desc';
  }): Promise<{ items: DocumentCrmType[]; total: number }> {
    return this.fetch('patientDocument/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          documentName: item.documentName,
          created: new Date(item.created),
          vRepoUploaded: item.vRepoUploaded,
          size: item.size,
          type: item.type,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: { clinicId: string; patientId: string; pdfFile: File }): Promise<void> {
    const formData = new FormData();

    formData.append('patientId', properties.patientId);
    formData.append('clinicId', properties.clinicId);
    formData.append('file', properties.pdfFile);

    return this.fetchMultiPart('patientDocument/create', {
      formData,
    });
  }

  async delete(properties: {
    clinicId: string;
    patientId: string;
    documentId: string;
  }): Promise<void> {
    return this.fetch('patientDocument/delete', {
      method: 'DELETE',
      params: properties,
    });
  }
}
