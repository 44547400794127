// Modules
import { StyleSheet } from 'react-native';

/**
 * Icon Styles
 * @constant {StyleSheet} IconStyles
 */
export const IconInputStyles = StyleSheet.create({
  iconInputContainer: {
    borderRadius: 5,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    paddingLeft: 7,
    paddingVertical: 5,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  text: {
    paddingVertical: 8,
    paddingLeft: 7,
  },
  iconShape: {
    color: 'rgb(237, 53, 145)',
  },
  searchIcon: {
    color: 'rgb(70, 70, 70)',
    position: 'absolute',
    left: 15,
    top: 11,
    zIndex: 1,
    fontSize: 18,
  },
  readonly: {
    elevation: 0,
    shadowOpacity: 0,
    shadowRadius: 0,
    color: '#777777',
    backgroundColor: 'rgb(221, 221, 221)',
  },
  options: {
    backgroundColor: '#ddd',
    borderRadius: 5,
    marginTop: 10,
    borderColor: 'rgba(237, 53, 145, 1)',
    borderWidth: 2,
  },
  optionsScroll: {
    backgroundColor: '#ddd',
    borderRadius: 5,
    overflow: 'hidden',
    height: 300,
  },
  optionsContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 8,
    gap: 9,
  },
  search: {
    alignContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    height: 42,
    paddingLeft: 50,
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    borderWidth: 0,
    fontFamily: 'Lato-Regular',
  },
  option: {
    padding: 10,
    height: 63,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    aspectRatio: 1,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 23.84,
    elevation: 5,
  },
  lastOption: {
    borderBottomWidth: 0,
  },
  iconStatusContainer: {
    position: 'absolute',
    top: 0,
    height: 40,
    width: 40,
    right: 0,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStatus: {
    color: '#aaa',
    fontSize: 15,
  },
});
