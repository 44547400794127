import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';

export function DisableCountryScreen(properties: {
  navigation: NavigationProp<CountriesRoutes>;
  route: RouteProp<CountriesRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.countries.disable">
      <FormComponent
        prefix="access.countries.disable.form"
        padding
        repository={{
          send: () => {
            return new CountryAccessRepository()
              .disable({
                countryId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
