import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { UsersRoutes } from '../../users.routes';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function DisableUserScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.users.disable">
      <FormComponent
        prefix="access.users.disable.form"
        padding
        repository={{
          send: () => {
            return new UserAccessRepository()
              .disable({
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
