import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';

import { View } from 'react-native';
import { LeadDetailStyles } from './leadDetail.styles';
import { LeadDetailProperties } from './leadDetail.properties';
import dayjs from 'dayjs';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';

export function LeadDetailScreen(properties: LeadDetailProperties) {
  return (
    <View style={LeadDetailStyles.container}>
      <View style={LeadDetailStyles.section}>
        <View style={LeadDetailStyles.row}>
          <IconComponent name="envelope" iconStyle={LeadDetailStyles.icon} />
          <TextComponent
            text={
              properties.lead.contactEmails.length > 0 ? properties.lead.contactEmails[0].email : ''
            }
            style={LeadDetailStyles.text}
            bold
          />
        </View>
        <View style={LeadDetailStyles.row}>
          <IconComponent name="phone-volume" iconStyle={LeadDetailStyles.icon} />
          <TextComponent
            text={
              properties.lead.contactPhones.length > 0
                ? `(${properties.lead.contactPhones[0].prefix}) ${properties.lead.contactPhones[0].phone}`
                : ''
            }
            style={LeadDetailStyles.text}
            bold
          />
        </View>
        {getWorkspaceUtility() === 'ovobank' && (
          <>
            <View style={LeadDetailStyles.row}>
              <TextComponent
                text={'crm.leads.update.actions.lead.fields.birthDate'}
                style={LeadDetailStyles.text}
                bold
                translate
              />
              <TextComponent
                text={
                  properties.lead.birthDate
                    ? dayjs(properties.lead.birthDate).format('DD/MM/YYYY')
                    : ''
                }
                style={LeadDetailStyles.text}
                bold
              />
            </View>
            <View style={LeadDetailStyles.row}>
              <TextComponent
                text="crm.leads.update.actions.lead.fields.height"
                style={LeadDetailStyles.text}
                bold
                translate
              />
              <TextComponent
                text={properties.lead.height ? properties.lead.height : ''}
                style={LeadDetailStyles.text}
                bold
              />
            </View>
            <View style={[LeadDetailStyles.row, { marginBottom: 30 }]}>
              <TextComponent
                text="crm.leads.update.actions.lead.fields.weight"
                style={LeadDetailStyles.text}
                bold
                translate
              />
              <TextComponent
                text={properties.lead.weight ? properties.lead.weight : ''}
                style={LeadDetailStyles.text}
                bold
              />
            </View>
          </>
        )}
      </View>
      <View style={LeadDetailStyles.mainSection}>
        {getWorkspaceUtility() === 'ovoclinic' ? (
          <>
            <View style={LeadDetailStyles.gapSection}>
              <TextComponent
                text={'crm.leads.update.actions.lead.fields.clinic'}
                style={LeadDetailStyles.label}
                bold
                translate
              />
              <TextComponent text={properties.lead.clinicName} style={LeadDetailStyles.text} bold />
            </View>
            <View style={LeadDetailStyles.gapSection}>
              <TextComponent
                text={'crm.leads.update.actions.lead.fields.contactTime'}
                style={LeadDetailStyles.label}
                bold
                translate
              />
              <TextComponent
                text={`common.contactTime.${properties.lead.contactTime}`}
                style={LeadDetailStyles.text}
                translate
                bold
              />
            </View>
          </>
        ) : (
          <>
            <View style={LeadDetailStyles.gapSection}>
              <TextComponent
                text={'crm.leads.update.actions.lead.fields.donationPreference'}
                style={LeadDetailStyles.label}
                bold
                translate
              />
              <TextComponent
                text={properties.lead.donationPreference ?? ''}
                style={LeadDetailStyles.text}
                bold
              />
            </View>
            <View style={LeadDetailStyles.gapSection}>
              <TextComponent
                text={'crm.leads.update.actions.lead.fields.birthCountry'}
                style={LeadDetailStyles.label}
                bold
                translate
              />
              <TextComponent
                text={properties.lead.birthCountry ?? ''}
                style={LeadDetailStyles.text}
                translate
                bold
              />
            </View>
          </>
        )}

        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.responsible'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <View>
            <TextComponent
              text={`${properties.lead.responsibleName || '-'} ${properties.lead.responsibleSurname || ''}`}
              style={LeadDetailStyles.text}
              bold
            />
            <View style={LeadDetailStyles.spacer} />
          </View>
        </View>
        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.funnel'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <TextComponent text={properties.lead.funnelName} style={LeadDetailStyles.text} bold />
        </View>
        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.consent'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <View style={LeadDetailStyles.row}>
            {properties.lead.contactLegal && (
              <View style={LeadDetailStyles.tag}>
                <TextComponent
                  text={'crm.leads.update.actions.lead.fields.legal'}
                  style={LeadDetailStyles.text}
                  translate
                />
              </View>
            )}
            {properties.lead.contactMarketing && (
              <View style={LeadDetailStyles.tag}>
                <TextComponent
                  text={'crm.leads.update.actions.lead.fields.marketing'}
                  style={LeadDetailStyles.text}
                  translate
                />
              </View>
            )}
          </View>
        </View>
        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.campaign'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <TextComponent text={properties.lead.campaignName} style={LeadDetailStyles.text} bold />
        </View>
        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.originCampaign'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={properties.lead.originCampaignName}
            style={LeadDetailStyles.text}
            bold
          />
        </View>
        <View style={LeadDetailStyles.gapSection}>
          <TextComponent
            text={'crm.leads.update.actions.lead.fields.created'}
            style={LeadDetailStyles.label}
            bold
            translate
          />
          <TextComponent
            text={dayjs(properties.lead.created).format('DD/MM/YYYY HH:mm')}
            style={LeadDetailStyles.text}
            bold
          />
        </View>
      </View>
    </View>
  );
}
