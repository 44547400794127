// Modules
import { StyleSheet } from 'react-native';

/**
 * Dock Styles
 * @constant {StyleSheet} DockStyles
 */
export const DockStyles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(17, 27, 34)',
    width: 75,
    flex: 1,
    maxWidth: 75,
    padding: 15,
    alignItems: 'center',
    paddingHorizontal: 0,
    borderRightColor: 'rgba(255, 255, 255, 0.1)',
    borderRightWidth: 1,
    gap: 9,
  },
  line: {
    width: 75,
    marginBottom: 10,
    flex: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    borderBottomWidth: 1,
  },
  lineTop: {
    width: 75,
    marginTop: 10,
    marginBottom: 10,
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    borderBottomWidth: 1,
  },
});
