import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';
import { HeaderStyles } from './header.styles';
import { HeaderProperties } from './header.properties';
import { useTranslation } from 'react-i18next';
import { useDeviceHook } from '@hooks/device/device.hook';

export function HeaderComponent(properties: HeaderProperties) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();

  return (
    <View style={HeaderStyles.headerContainer}>
      <View style={HeaderStyles.headerBackground}>
        <View style={HeaderStyles.header}>
          <TextComponent
            text="OVO"
            light
            style={[HeaderStyles.headerOvoText, phone ? { fontSize: 16 } : {}]}
          />
          <TextComponent
            text={properties.type.toUpperCase()}
            bold
            style={[HeaderStyles.headerTypeText, phone ? { fontSize: 16 } : {}]}
          />
        </View>
        <View style={HeaderStyles.headerSubtitle}>
          <TextComponent text="by" light style={HeaderStyles.headerByText} />
          <TextComponent
            translate
            text={`logotype.${process.env.EXPO_PUBLIC_WORKSPACE || properties.brand}`}
            bold
            style={HeaderStyles.headerLogotypeText}
          />
        </View>
      </View>
      <TextComponent
        text={`${process.env.VERSION || 'v1.0.0'}`}
        bold
        style={HeaderStyles.versionText}
      />
    </View>
  );
}
