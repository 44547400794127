import { SlicerStyles, SlicerStylesWeb } from './slicer.styles.web';
import DateTimePicker, { DateType } from 'react-native-ui-datepicker';
import { useState } from 'react';
import { isObject } from '@validator/index';
import { DateConstants } from '@components/form/components/fields/inputs/date/date.constants';
import { View, TouchableOpacity } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { useDeviceHook } from '@hooks/device/device.hook';

export function SlicerComponent(properties: {
  onClose: () => void;
  onDateChange?: (properties: { start: Date | undefined; end: Date | undefined }) => void;
  max?: Date;
  min?: Date;
}) {
  const { phone } = useDeviceHook();

  const [startDate, setStartDate] = useState<DateType>();
  const [endDate, setEndDate] = useState<DateType>();

  const pressableClose = (onPress: () => void) => {
    return (
      <TouchableOpacity onPress={onPress} style={[SlicerStyles.button, SlicerStyles.cancelButton]}>
        <IconComponent iconStyle={SlicerStyles.cancelButtonIcon} name="times" />
      </TouchableOpacity>
    );
  };

  const pressableArrow = (onPress: () => void) => {
    return (
      <TouchableOpacity onPress={onPress} style={[SlicerStyles.button, SlicerStyles.applyButton]}>
        <IconComponent iconStyle={SlicerStyles.appluButtonIcon} name="arrow-right" />
      </TouchableOpacity>
    );
  };

  return (
    <div style={!phone ? SlicerStylesWeb : { ...SlicerStylesWeb, width: 250, right: 6 }}>
      {/**Para que los estilos del calendario funcionen bien hay que pasarle un color hexadecimal y no un rgb */}
      <DateTimePicker
        mode="range"
        startDate={startDate}
        endDate={endDate}
        firstDayOfWeek={1}
        timePicker={true}
        locale={DateConstants.locale}
        headerButtonColor="#ed3591"
        selectedItemColor="#ed3591"
        onChange={(params: { startDate: DateType; endDate: DateType }) => {
          setStartDate(params.startDate);
          setEndDate(params.endDate);
        }}
      />
      <View style={SlicerStyles.iconsContainer}>
        {pressableClose(() => {
          properties.onClose();
          setStartDate(undefined);
          setEndDate(undefined);
          if (properties.onDateChange) {
            properties.onDateChange({ start: undefined, end: undefined });
          }
        })}
        {pressableArrow(() => {
          if (
            properties.onDateChange &&
            startDate &&
            isObject(startDate) &&
            'toDate' in startDate &&
            endDate &&
            isObject(endDate) &&
            'toDate' in endDate
          ) {
            properties.onDateChange({
              start: startDate.toDate(),
              end: endDate.toDate(),
            });
          }
        })}
      </View>
    </div>
  );
}
