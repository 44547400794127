import { Text, View } from 'react-native';
import { DepartmentRowStyles } from '../../../../funnels/components/saleFunnelPerDepartmentTable/components/departmentRow/departmentRow.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

export const TotalLeadsStateDepartmentsTableRow = (properties: {
  row: {
    department_id: string;
    department_name: string;
    total_leads: number;
    lead_appointed_count: number;
    lead_percentage_appointed: number;
    lead_active_count: number;
    lead_inactive_count: number;
  };
  last: boolean;
  iparent: number;
}) => {
  const { phone } = useDeviceHook();

  return (
    <View
      style={[
        DepartmentRowStyles.rowContainer,
        properties.last
          ? DepartmentRowStyles.rowContainerEven
          : DepartmentRowStyles.rowContainerOdd,
      ]}
    >
      <View
        style={[
          phone
            ? { width: 100 }
            : {
                flexDirection: 'row',
                flex: 2,
                padding: 10,
              },
        ]}
      >
        <Text style={DepartmentRowStyles.rowSpaceText}>{properties.row.department_name}</Text>
      </View>

      {Object.values(properties.row).map((value, index) => {
        if (index === 0 || index === 1) return null;
        return (
          <View
            style={[
              phone
                ? {
                    width: 75,

                    alignItems: 'center',
                  }
                : DepartmentRowStyles.rowColumnContainer,
            ]}
          >
            <Text style={[DepartmentRowStyles.rowText]}>{value}</Text>
          </View>
        );
      })}
    </View>
  );
};
