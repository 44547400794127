// Modules
import { StyleSheet } from 'react-native';

/**
 * SpaceStyles Styles
 * @constant {StyleSheet} SceneStylesStyles
 */
export const SpaceStyles = StyleSheet.create({
  container: {
    height: 20,
  },
});
