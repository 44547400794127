import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class PasswordProfileRepository extends AuthorizedRepository {
  async changePassword(properties: { password: string }): Promise<any> {
    return this.fetch('profile/change-password', {
      method: 'PUT',
      body: properties,
    });
  }
}
