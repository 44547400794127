import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface BulletintState {
  bulletin: {
    enabled: boolean;
  };

  disableBulletin: () => void;
  enableBulletin: () => void;
}

export const useBulletinState = create<BulletintState>()(
  devtools(
    persist(
      (set) => ({
        disableBulletin: () => set({ bulletin: { enabled: false } }),
        enableBulletin: () => set({ bulletin: { enabled: true } }),
        bulletin: { enabled: true },
      }),
      { name: 'bulletinStore', storage: createJSONStorage(() => AsyncStorage) }
    )
  )
);
