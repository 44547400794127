import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { PurchaseCrmType } from '@human/crm/types/purchase.crm.type';

export class PurchaseCrmRepository extends AuthorizedRepository {
  async list(properties: {
    clinicId: string;
    patientId: string;
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: PurchaseCrmType[]; total: number }> {
    return this.fetch('purchase/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((item: PurchaseCrmType) => ({
        id: item.id,
        price: item.price,
        clinicId: item.clinicId,
        clinicName: item.clinicName,
        treatmentId: item.treatmentId,
        treatmentName: item.treatmentName,
        maintenanceId: item.maintenanceId,
        maintenanceName: item.maintenanceName,
        techniqueId: item.techniqueId,
        techniqueName: item.techniqueName,
        purchaseType: item.purchaseType,
        status: item.status,
      })),
      total: data.total,
    }));
  }

  async listByContact(properties: {
    contactId: string;
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: PurchaseCrmType[]; total: number }> {
    return this.fetch('purchase/findByContact', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: PurchaseCrmType) => ({
          id: item.id,
          price: item.price,
          patientId: item.patientId,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          treatmentId: item.treatmentId,
          treatmentName: item.treatmentName,
          maintenanceId: item.maintenanceId,
          maintenanceName: item.maintenanceName,
          techniqueId: item.techniqueId,
          techniqueName: item.techniqueName,
          purchaseType: item.purchaseType,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: {
    clinicId: string;
    patientId: string;
    purchaseId: string;
  }): Promise<PurchaseCrmType> {
    return this.fetch('purchase/pick', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      id: data.id,
      price: data.price,
      clinicId: data.clinicId,
      clinicName: data.clinicName,
      treatmentId: data.treatmentId,
      treatmentName: data.treatmentName,
      maintenanceId: data.maintenanceId,
      maintenanceName: data.maintenanceName,
      techniqueId: data.techniqueId,
      techniqueName: data.techniqueName,
      purchaseType: data.purchaseType,
      status: data.status,
    }));
  }

  async enable(properties: {
    clinicId: string;
    patientId: string;
    purchaseId: string;
  }): Promise<void> {
    return this.fetch('purchase/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: {
    clinicId: string;
    patientId: string;
    purchaseId: string;
  }): Promise<void> {
    return this.fetch('purchase/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async update(properties: {
    clinicId: string;
    patientId: string;
    purchaseId: string;
    price?: number;
  }): Promise<void> {
    return this.fetch('purchase/update', {
      method: 'PUT',
      body: properties,
    });
  }
}
