import { UnauthorizedRepository } from '@core/repository/unauthorized.repository.core';

export class ValidateSessionRepository extends UnauthorizedRepository {
  async validate(properties: { accessToken: string }): Promise<boolean> {
    return this.fetch('session/validate', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${properties.accessToken}`,
      },
    }).then((response) => {
      if (response.status >= 400) {
        throw new Error('Invalid credentials');
      }
      return response;
    });
  }
}
