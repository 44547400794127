import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TemplatesRoutes } from '../../templates.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { TemplateAccessType } from '@human/access/types/template.access.type';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { TemplateScopeUpdatePart } from './parts/templateScope.update.template.part';
import { TemplateParamUpdatePart } from './parts/templateParams.update.template.part';

export function UpdateTemplateScreen(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="file-alt" prefix="access.templates.update">
      <TabsComponent
        width={700}
        prefix="access.templates.update"
        tabs={[
          {
            name: 'template',
            icon: 'file-alt',
            content: (settings) => {
              return (
                <FormComponent<TemplateAccessType>
                  readonly={!hasPermissionUtilty({ name: 'access.templates.editor' })}
                  prefix={'access.templates.update.form'}
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  repository={{
                    get: () => {
                      return new TemplateAccessRepository().pick({
                        templateId: properties.route.params.templateId,
                      });
                    },
                    send: (data) => {
                      return new TemplateAccessRepository()
                        .update({
                          item: {
                            ...data.item,
                            id: properties.route.params.templateId,
                          },
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                  }}
                  fields={[
                    {
                      type: 'text',
                      name: 'label',
                      description: true,
                      required: true,
                      validations: ['name'],
                    },
                    {
                      type: 'text',
                      name: 'landbotId',
                      description: true,
                      required: true,
                      validations: ['code'],
                    },
                  ]}
                />
              );
            },
          },
          TemplateScopeUpdatePart(properties),
          TemplateParamUpdatePart(properties),
        ]}
      />
    </ModalComponent>
  );
}
