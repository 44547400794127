import { NavigationProp } from '@react-navigation/native';
import { ProgramsRoutes } from '../../programs.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { FormProgramScreen } from './form.program.screen';

export function CreateProgramForm(properties: { navigation: NavigationProp<ProgramsRoutes> }) {
  return (
    <ModalComponent icon={CrmSections.programs.icon} prefix={'crm.programs.create'}>
      <FormProgramScreen {...properties} />
    </ModalComponent>
  );
}
