import { TextComponent } from '@components/text/text.component';
import { Image, View } from 'react-native';
import { ProfileStyles } from './profile.styles';
import { ProfileProperties } from './profile.properties';
import { useEffect, useState } from 'react';
import { UserRepository } from '../../../../screens/authorized/human/spaces/access/repositories/session/user.repository';
import { IconComponent } from '@components/icon/icon.component';
import { AvatarComponent } from './components/avatar/avatar.component';

export function ProfileComponent(properties: ProfileProperties) {
  const [user, setUser] = useState<{
    id: string;
    name: string;
    surname: string;
    username: string;
    email: string;
    selfie: string;
    companyCode: string;
  }>();

  const retrieveUser = async () => {
    new UserRepository().retrieve().then((res) => {
      setUser({
        ...res,
        companyCode: res.companyCode ? res.companyCode : 'OVO',
      });
    });
  };
  useEffect(() => {
    retrieveUser();
  }, []);

  return user ? (
    <View style={ProfileStyles.rowContainer}>
      <AvatarComponent selfie={user.selfie} companyCode={user.companyCode} />
      <View style={ProfileStyles.textContainer}>
        <TextComponent
          lines={1}
          text={`${user.name} ${user.surname}`}
          bold
          style={ProfileStyles.nameText}
        />
        <TextComponent lines={1} text={user.email} style={ProfileStyles.emailText} />
      </View>
    </View>
  ) : null;
}
