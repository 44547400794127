import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp } from '@react-navigation/native';
import { ChannelsRoutes } from '../../channels.routes';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';

export function CreateChannelScreen(properties: { navigation: NavigationProp<ChannelsRoutes> }) {
  return (
    <ModalComponent icon="user" prefix="crm.channels.create">
      <FormComponent<any>
        prefix="crm.channels.create.form"
        padding
        repository={{
          send: (data) => {
            return new ChannelCrmRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            required: true,
            description: true,
            validations: ['name'],
          },
        ]}
      />
    </ModalComponent>
  );
}
