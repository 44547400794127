import { StyleSheet } from 'react-native';

export const SaleFunnelPerDepartmentTableStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerSpaceHeader: {
    flex: 3,
    marginLeft: 10,
  },
  headerColumnContainer: {
    flex: 1,
  },
  headerText: {
    color: '#999',
    fontWeight: '500',
  },
  headerSpacer: {
    height: 1,
    backgroundColor: '#ccc',
    marginTop: 5,
  },
  scrollContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
});
