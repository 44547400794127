import { useUserState } from '@states/user/user.state';

export function hasPermissionUtilty(properties: { name: string }) {
  if (properties.name === '') return true;

  const permissions = useUserState.getState().permissions;

  const keys = properties.name.split('.');
  let i = 1;
  let permission = permissions[keys[0] as keyof typeof permissions];
  if (!permission) return false;
  while (i < keys.length) {
    if (!permission) return false;
    if (permission[keys[i] as keyof typeof permission]) {
      permission = permission[keys[i] as keyof typeof permission];
    } else {
      return false;
    }
    i++;
  }

  return true;
}
