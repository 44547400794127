import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ResultsRoutes } from '../../result.routes';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
export function CreateResultScreen(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="resolving"
      prefix="crm.results.create"
    >
      <FormComponent<ResultCrmType>
        prefix="crm.results.create.form"
        padding
        repository={{
          send: (settings) => {
            return new ResultCrmRepository()
              .create({
                item: {
                  ...settings.item,
                  activityId: properties.route.params.activityId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            validations: ['title'],
            description: true,
            required: true,
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
            required: true,
          },
          {
            name: 'position',
            type: 'number',
            description: true,
            required: true,
            validations: ['positive'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            validations: ['description'],
            required: false,

            lines: 6,
          },
          {
            name: 'color',
            type: 'color',
            required: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
