import { NavigationProp, RouteProp } from '@react-navigation/native';
import { AlertsRoutes } from '../../alerts.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActionAlertCrmRepository } from '@human/crm/repositories/action/alert/action.alert.repository';

export function DeleteAlertScreen(properties: {
  navigation: NavigationProp<AlertsRoutes>;
  route: RouteProp<AlertsRoutes, 'alertDelete'>;
}) {
  return (
    <ModalComponent
      icon="trash"
      prefix="crm.actions.update.alerts.delete"
      level={properties.route.params.level}
    >
      <FormComponent<{ actionAlertId: string; alertTime: number; expectedAlarmTime: Date }>
        padding
        prefix="crm.actions.update.alerts.delete.form"
        repository={{
          send: async (data) => {
            return new ActionAlertCrmRepository()
              .delete({
                actionAlertId: properties.route.params.actionAlertId,
                actionId: properties.route.params.actionId,
                clinicId: properties.route.params.clinicId,
                leadId: properties.route.params.leadId,
                patientId: properties.route.params.patientId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
