import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TransferLeadRoutes } from '../../transferLead.routes';
import { useTranslation } from 'react-i18next';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ManagerRepository } from '@human/crm/repositories/manager/manager.repository';
import { useState } from 'react';
import { SelectCampaignStep } from './selectCampaign.step';
import { ActionComponent } from '@components/action/action.component';

export function ConfirmTransferLeadScreen(props: {
  navigation: NavigationProp<TransferLeadRoutes, 'confirmTransfer'>;
  route: RouteProp<TransferLeadRoutes, 'confirmTransfer'>;
}) {
  const { t } = useTranslation();

  const { showSuccess } = useSuccessToast();

  const [campaignId, setCampaignId] = useState<string>();
  const [campaignName, setCampaignName] = useState<string>();

  return (
    <ModalComponent icon="sync-alt" prefix="crm.transferLead.confirm">
      {!campaignId ? (
        <SelectCampaignStep
          campaignId={campaignId}
          onSuccess={({ campaignId, campaignName }) => {
            setCampaignId(campaignId);
            setCampaignName(campaignName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setCampaignId(undefined);
            setCampaignName(undefined);
          }}
          prefix="crm.transferLead.confirm.steps.campaign.form"
          bubble={campaignName}
        />
      )}
      {campaignId && (
        <FormComponent<{ campaignId: string; responsibleId: string }>
          padding
          prefix="crm.transferLead.confirm.form"
          repository={{
            send: (data) => {
              return new LeadCrmRepository()
                .transferLead({
                  leadIds: props.route.params.leadIds,
                  clinicIds: props.route.params.clinicIds,
                  campaignId,
                  responsibleId: data.item.responsibleId ?? '',
                })
                .then(() => {
                  showSuccess(t('crm.transferLead.confirm.success') || '');
                  props.route.params.onSuccess();
                  props.navigation.goBack();
                });
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'responsibleId',
              description: true,
              required: true,
              repository: () =>
                new ManagerRepository()
                  .list({
                    campaignId,
                  })
                  .then((data) => {
                    return data.items.map((item) => ({
                      value: item.userId,
                      label: `${item.userName} ${item.userSurname}`,
                    }));
                  }),
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
