import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { UserAccessType } from '@human/access/types/user.access.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../users.routes';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { ContractsUpdateUserPart } from './parts/contracts.update.user.part';
import { LicensesUpdateUserPart } from './parts/licenses.update.user.part';
import { MovementsUpdateUserPart } from './parts/movements.update.user.part';
import { AttemptsUpdateUserPart } from './parts/attempts.update.user.part';
import { LanguagesUpdateUserPart } from './parts/languages.update.user.part';
import { ScheduleUpdateUserPart } from './parts/schedule.update.user.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ChangePasswordUserPart } from './parts/change.password.user.part';
import { TabType } from '@components/tabs/tabs.types';
import { ActivityUpdateUserPart } from './parts/activity.update.user.part';
import { UpdateUserIntegrationsPart } from './parts/integrations.user.part';

export function UpdateUserScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  const tabs: TabType[] = [
    {
      name: 'account',
      icon: 'user',
      content: (settings) => {
        return (
          <FormComponent<UserAccessType>
            onChange={(data) => {
              if (!data.isLocal) return;
              settings?.setIsEditing(true);
            }}
            readonly={!hasPermissionUtilty({ name: 'access.users.editor' })}
            prefix="access.users.update.form"
            repository={{
              get: () => {
                return new UserAccessRepository().pick({
                  userId: properties.route.params.userId,
                });
              },
              send: (data) => {
                return new UserAccessRepository()
                  .update({
                    item: data.item,
                  })
                  .then(() => {
                    settings?.setIsEditing(false);
                    showSuccess();
                  });
              },
            }}
            fields={[
              {
                type: 'pair',
                fields: [
                  {
                    name: 'name',
                    required: true,
                    type: 'text',
                    description: true,
                    validations: ['name'],
                  },
                  {
                    name: 'surname',
                    required: true,
                    type: 'text',
                    description: true,
                    validations: ['surname'],
                  },
                ],
              },
              {
                name: 'username',
                required: true,
                description: true,
                icon: 'id-card',
                type: 'text',
                validations: ['username'],
              },
              {
                type: 'pair',
                fields: [
                  {
                    name: 'extension',
                    required: true,
                    icon: 'phone',
                    type: 'text',
                    description: true,
                    validations: ['extension'],
                  },
                  {
                    name: 'phone',
                    icon: 'phone',
                    type: 'text',
                    validations: ['phone'],
                    description: true,
                  },
                ],
              },
              {
                name: 'email',
                required: true,
                description: true,
                icon: 'envelope',
                type: 'text',
                validations: ['email'],
              },
              {
                name: 'movementLimit',
                description: true,
                type: 'number',
                validations: ['positive'],
              },
              {
                name: 'attemptLimit',
                validations: ['positive'],
                description: true,
                type: 'number',
              },
              {
                name: 'status',
                icon: 'lock',
                type: 'checkbox',
                description: true,
              },
            ]}
          />
        );
      },
    },
    ActivityUpdateUserPart({
      ...properties,
      showSuccess,
    }),
    LicensesUpdateUserPart(properties),
    ScheduleUpdateUserPart(properties),
    ContractsUpdateUserPart(properties),
    MovementsUpdateUserPart(properties),
    AttemptsUpdateUserPart(properties),
    LanguagesUpdateUserPart(properties),
    UpdateUserIntegrationsPart({
      ...properties,
      showSuccess,
    }),
  ];

  if (hasPermissionUtilty({ name: 'access.users.changePassword' })) {
    tabs.push(ChangePasswordUserPart({ ...properties, showSuccess }));
  }

  return (
    <ModalComponent icon="user" width={700} flex={false} prefix="access.users.update">
      <TabsComponent width={700} prefix="access.users.update" tabs={tabs} />
    </ModalComponent>
  );
}
