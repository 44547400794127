import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

export function useDeviceHook() {
  const [device, setDevice] = useState<'phone' | 'tablet' | 'laptop' | 'desktop'>('desktop');
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 500) setDevice('phone');
    else if (width < 800) setDevice('tablet');
    else if (width <= 1440) setDevice('laptop');
    else setDevice('desktop');
  }, [width]);

  return {
    device,
    phone: device === 'phone',
    tablet: device === 'tablet',
    laptop: device === 'laptop',
    desktop: device === 'desktop',
  };
}
