import {
  ImageBackground,
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { CoversStyles } from './covers.styles';
import { TextComponent } from '@components/text/text.component';
import { useEffect, useRef, useState } from 'react';
import { ItemCoversType } from './covers.types';
import { useIsFocused } from '@react-navigation/native';
import { LoaderComponent } from '@components/loader/loader.component';
import { COVERS_LENGTH } from './covers.constants';
import { stringToColorUtility } from '@utils/color/stringToColor.utility';

export function CoversComponent(properties: {
  infiniteScroll?: boolean;
  search?: string;
  repository: (settings: {
    search?: string;
    page: number;
    limit: number;
  }) => Promise<{ items: ItemCoversType[]; total: number }>;
}) {
  const scrollViewRef = useRef<ScrollView>(null);
  const isFocus = useIsFocused();
  const [items, setItems] = useState<ItemCoversType[][]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(0);
  const loadData = () => {
    if (!loading) {
      setLoading(true);
      properties
        .repository({ search: properties.search, limit: COVERS_LENGTH, page })
        .then((covers) => {
          let newAr = items;
          newAr[page] = covers.items;
          setItems([...newAr]);
          setTotal(covers.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    loadData();
  }, [isFocus, page]);

  useEffect(() => {
    setItems([]);
    if (page === 0) {
      loadData();
    } else {
      setPage(0);
    }
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  }, [properties.search]);

  const isInTheEnd = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) => {
    return layoutMeasurement.width + contentOffset.x === contentSize.width;
  };

  return (
    <ScrollView
      ref={scrollViewRef}
      style={CoversStyles.container}
      horizontal
      contentContainerStyle={CoversStyles.content}
      onScroll={({ nativeEvent }) => {
        if (
          properties.infiniteScroll &&
          !loading &&
          isInTheEnd(nativeEvent) &&
          page < total / COVERS_LENGTH - 1
        ) {
          setPage(page + 1);
        }
      }}
    >
      {items.map((coverAr, j) =>
        coverAr.map((item, index) => {
          const color = stringToColorUtility(item.title);
          return (
            <TouchableOpacity
              key={j + '-' + index}
              style={CoversStyles.cover}
              onPress={item.onPress}
            >
              <ImageBackground source={{ uri: item.image }} style={CoversStyles.image}>
                <div
                  style={{
                    backgroundColor: color,
                    position: 'absolute',
                    zIndex: 1,
                    opacity: 0.4,
                    height: 350,
                    width: 260,
                    borderRadius: 10,
                  }}
                ></div>
                <View style={CoversStyles.subtitle}>
                  <TextComponent style={CoversStyles.subtitlePrefix} text={'#'} />
                  <TextComponent style={CoversStyles.subtitleText} text={item.subtitle} />
                </View>
                <View style={CoversStyles.count}>
                  <TextComponent style={CoversStyles.countPrefix} text={'Leads'} />
                  <TextComponent style={CoversStyles.countText} text={`${item.count}`} />
                </View>

                <TextComponent style={CoversStyles.title} text={`${item.title}`} />
                <View style={CoversStyles.detail}>
                  <TextComponent style={CoversStyles.detailText} text={`${item.detail}`} />
                </View>
              </ImageBackground>
            </TouchableOpacity>
          );
        })
      )}
      <LoaderComponent
        loading={
          !!properties.infiniteScroll &&
          items.length > 0 &&
          items[0].length > 0 &&
          (loading || page < total / COVERS_LENGTH - 1)
        }
      />
    </ScrollView>
  );
}
