import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessType } from '@human/access/types/country.access.type';

import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { useUserState } from '../../../../../../../../../states/user/user.state';
import { AccessSections } from '@human/access/access.sections';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateCountryScreen(properties: {
  navigation: NavigationProp<CountriesRoutes>;
  route: RouteProp<CountriesRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <ModalComponent icon={AccessSections.countries.icon} prefix="access.countries.update">
      <FormComponent<CountryAccessType>
        padding
        readonly={!hasPermissionUtilty({ name: 'access.countries.editor' })}
        prefix="access.countries.update.form"
        repository={{
          send: (settings: { item: Partial<CountryAccessType> }) => {
            return new CountryAccessRepository()
              .update({
                item: settings.item,
              })
              .then(() => showSuccess());
          },
          get: () => {
            return new CountryAccessRepository().pick({
              countryId: properties.route.params.id,
            });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'countryNameEs',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            type: 'text',
            name: 'countryNameEn',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            type: 'text',
            name: 'flag',
            description: true,
            required: true,
            validations: ['flag'],
          },
          {
            type: 'text',
            name: 'code',
            description: true,
            required: true,
            validations: ['countryCode'],
          },
          {
            type: 'text',
            name: 'coin',
            description: true,
            required: true,
            validations: ['coin'],
          },
          {
            type: 'text',
            name: 'prefix',
            description: true,
            required: true,
            validations: ['prefix'],
          },
          {
            type: 'json',
            name: 'geometry',
            description: true,
          },
          {
            type: 'text',
            name: 'description',
            description: true,
            required: true,
            lines: 6,
            validations: ['description'],
          },
          {
            type: 'color',
            name: 'color',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
