import { View, Text } from 'react-native';
import { CampaignLeadCostDetailStyles } from './campaignLeadCostDetail.styles';
import { CampaignLeadCostDetailProperties } from './campaignLeadCostDetail.properties';
import { useTranslation } from 'react-i18next';
import { useDeviceHook } from '@hooks/device/device.hook';

export function CampaignLeadCostDetail(properties: CampaignLeadCostDetailProperties) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();

  return (
    <View
      style={
        phone
          ? CampaignLeadCostDetailStyles.mobileContainer
          : CampaignLeadCostDetailStyles.container
      }
    >
      {!phone && (
        <>
          <Text style={CampaignLeadCostDetailStyles.title}>
            {t('board.marketing.leadCostDetail.title').toLocaleUpperCase()}
          </Text>
          <View style={CampaignLeadCostDetailStyles.dataContainer}>
            <Text style={CampaignLeadCostDetailStyles.subtitle}>
              {t('board.marketing.leadCostDetail.selectedClinic')}
            </Text>
            <Text style={CampaignLeadCostDetailStyles.clinic}>{properties.clinic}</Text>
          </View>
        </>
      )}
      <View style={CampaignLeadCostDetailStyles.dataContainer}>
        <Text style={CampaignLeadCostDetailStyles.subtitle}>
          {t('board.marketing.leadCostDetail.selectedCampaign')}
        </Text>
        <Text style={CampaignLeadCostDetailStyles.campaign}>{properties.campaign}</Text>
      </View>
      <View style={CampaignLeadCostDetailStyles.dataContainer}>
        <Text style={CampaignLeadCostDetailStyles.subtitle}>
          {t('board.marketing.leadCostDetail.budget')}
        </Text>
        <Text style={CampaignLeadCostDetailStyles.budget}>{properties.budget}€</Text>
      </View>
    </View>
  );
}
