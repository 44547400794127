import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { MaintenanceCrmType } from '@human/crm/types/maintenance.crm.type';
import { MaintenanceCrmRepository } from '@human/crm/repositories/maintenance/maintenance.repository';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
import { MaintenancesRoutes } from '../../maintenance.routes';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';
export function CreateMaintenanceScreen(properties: {
  navigation: NavigationProp<MaintenancesRoutes>;
  route: RouteProp<MaintenancesRoutes, 'createMaintenance'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="tasks"
      prefix="crm.maintenances.create"
    >
      <FormComponent<MaintenanceCrmType>
        prefix="crm.maintenances.create.form"
        padding
        repository={{
          send: (settings) => {
            return new MaintenanceCrmRepository()
              .create({
                serviceId: settings.item.serviceId ?? '',
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'serviceId',
            description: true,
            required: true,
            repository: (settings) =>
              new ServiceCrmRepository()
                .list({
                  search: settings.search,
                  direction: 'asc',
                  limit: 100,
                  order: 'name',
                  page: 0,
                  active: true,
                })
                .then((data) => {
                  return data.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }));
                }),
          },
        ]}
      />
    </ModalComponent>
  );
}
