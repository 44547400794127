import { Platform } from 'react-native';
import { IconType } from '../../../../../components/icon/icon.types';
import { CrmOvoBankRoutes, CrmRoutes } from './crm.routes';

export const CrmSections: {
  [key in keyof CrmRoutes]: {
    name: string;
    icon: IconType;
    keywords: string[];
    department: string;
    status?: boolean;
    permission?: string;
    platforms?: Array<typeof Platform.OS>;
    multiWorkspace?: boolean;
  };
} = {
  // ACTIVITY
  dashboard: {
    department: 'activity',
    name: 'dashboard',
    icon: 'tachometer-alt',
    keywords: ['dashboard'],
    platforms: ['web'],
  },
  actions: {
    department: 'activity',
    name: 'actions',
    status: true,
    icon: 'calendar-check',
    keywords: ['tasks', 'to-do', 'agenda'],
    permission: 'crm.calendar',
    platforms: ['web'],
  },
  tasks: {
    department: 'activity',

    name: 'tasks',
    icon: 'stream',
    keywords: ['tasks', 'to-do', 'agenda'],
    permission: 'crm.tasks',
  },

  transferTask: {
    department: 'activity',
    name: 'transferTask',
    icon: 'sync-alt',
    keywords: ['transferTask', 'to-do', 'agenda'],
    permission: 'crm.tasks.updateResponsible',
  },
  createTaskMassively: {
    department: 'activity',
    name: 'createTaskMassively',
    icon: 'tasks',
    keywords: ['transferTask', 'to-do', 'agenda'],
    permission: '',
  },
  // DIRECTORY
  leads: {
    status: true,
    department: 'directory',
    name: 'leads',
    icon: 'user-ninja',
    keywords: ['leads', 'contacts', 'clients'],
    permission: 'crm.leads',
  },
  transferLead: {
    status: true,
    department: 'directory',
    name: 'transferLead',
    icon: 'sync-alt',
    keywords: ['transferLead', 'leads', 'campaign'],
    permission: 'crm.leads.updateCampaign',
  },
  patients: {
    department: 'directory',

    name: 'patients',
    icon: 'user-shield',
    keywords: ['patients', 'medical', 'health'],
    permission: 'crm.patients',
    multiWorkspace: true,
  },

  activities: {
    department: 'commercial',

    name: 'activities',
    icon: 'stopwatch',
    status: true,
    keywords: ['activities', 'events', 'actions'],
    permission: 'crm.activities',
  },
  results: {
    department: 'commercial',
    name: 'results',
    icon: 'flask',
    status: true,

    keywords: ['results', 'events', 'actions'],
    permission: 'crm.results',
  },
  campaigns: {
    department: 'marketing',
    name: 'campaigns',
    icon: 'th-large',
    keywords: ['campaigns', 'marketing', 'ads'],
    status: true,
    permission: 'crm.campaigns',
  },
  reasons: {
    department: 'commercial',

    name: 'reasons',
    icon: 'handshake',
    keywords: ['reasons', 'causes', 'motives'],
    permission: 'crm.reasons',
  },
  programs: {
    department: 'catalog',
    name: 'programs',
    icon: 'syringe',
    keywords: ['programs', 'medications', 'drugs'],
    permission: 'crm.programs',
  },

  services: {
    department: 'catalog',

    name: 'packs',
    icon: 'notes-medical',
    keywords: ['services', 'products', 'items'],
    permission: 'crm.services',
  },
  techniques: {
    department: 'catalog',

    name: 'techniques',
    icon: 'stethoscope',
    keywords: ['techniques', 'methods', 'procedures'],
    permission: 'crm.techniques',
  },
  maintenances: {
    department: 'services',
    name: 'maintenances',
    icon: 'history',
    keywords: ['maintenances', 'settings', 'configurations'],
    permission: 'crm.maintenances',
  },
  funnels: {
    department: 'marketing',
    name: 'funnels',
    icon: 'funnel-dollar',
    keywords: ['funnels', 'services', 'conversions'],
    status: true,
    permission: 'crm.funnels',
  },
  channels: {
    department: 'marketing',
    name: 'channels',
    icon: 'link',
    keywords: ['channels', 'links', 'urls'],
    status: true,
    permission: 'crm.channels',
  },
  contacts: {
    name: 'Contacts',
    icon: 'user-tag',
    status: true,
    keywords: [],
    department: 'directory',
    permission: 'crm.contacts',
  },
  treatments: {
    department: 'services',
    name: 'treatmanents',
    icon: 'stethoscope',
    keywords: [],
    permission: 'crm.treatments',
  },
  phases: {
    name: 'Phases',
    icon: 'phabricator',
    status: true,
    keywords: [],
    department: 'services',
    permission: 'crm.phases',
  },
  causes: {
    name: 'Causes',
    icon: 'ban',
    status: true,
    keywords: [],
    department: 'services',
    permission: 'crm.causes',
  },
  alerts: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  notifications: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  information: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  faq: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  cycles: {
    name: 'Cycles',
    icon: 'sync-alt',
    keywords: [],
    status: true,
    department: 'catalog',
    permission: 'crm.cycles',
  },
};

export const CrmOvoBankSections: {
  [key in keyof CrmOvoBankRoutes]: {
    name: string;
    icon: IconType;
    keywords: string[];
    department: string;
    status?: boolean;
    permission?: string;
    multiWorkspace?: boolean;
  };
} = {
  // ACTIVITY
  dashboard: {
    department: 'activity',
    name: 'dashboard',
    icon: 'tachometer-alt',
    keywords: ['dashboard'],
  },
  actions: {
    department: 'activity',
    name: 'actions',
    status: true,
    icon: 'calendar-check',
    keywords: ['tasks', 'to-do', 'agenda'],
    permission: 'crm.calendar',
  },
  tasks: {
    department: 'activity',

    name: 'tasks',
    icon: 'stream',
    keywords: ['tasks', 'to-do', 'agenda'],
    permission: 'crm.tasks',
  },

  transferTask: {
    department: 'activity',
    name: 'transferTask',
    icon: 'sync-alt',
    keywords: ['transferTask', 'to-do', 'agenda'],
    permission: 'crm.tasks.updateResponsible',
  },
  createTaskMassively: {
    department: 'activity',
    name: 'createTaskMassively',
    icon: 'tasks',
    keywords: ['transferTask', 'to-do', 'agenda'],
    permission: '',
  },
  // DIRECTORY
  leads: {
    status: true,
    department: 'directory',
    name: 'leads',
    icon: 'user-ninja',
    keywords: ['leads', 'contacts', 'clients'],
    permission: 'crm.leads',
  },
  patients: {
    department: 'directory',

    name: 'patients',
    icon: 'user-shield',
    keywords: ['patients', 'medical', 'health'],
    permission: 'crm.patients',
    multiWorkspace: true,
  },

  activities: {
    department: 'commercial',

    name: 'activities',
    icon: 'stopwatch',
    status: true,
    keywords: ['activities', 'events', 'actions'],
    permission: 'crm.activities',
  },
  results: {
    department: 'commercial',
    name: 'results',
    icon: 'flask',
    status: true,

    keywords: ['results', 'events', 'actions'],
    permission: 'crm.results',
  },
  campaigns: {
    department: 'marketing',
    name: 'campaigns',
    icon: 'th-large',
    keywords: ['campaigns', 'marketing', 'ads'],
    status: true,
    permission: 'crm.campaigns',
  },
  reasons: {
    department: 'commercial',

    name: 'reasons',
    icon: 'handshake',
    keywords: ['reasons', 'causes', 'motives'],
    permission: 'crm.reasons',
  },

  funnels: {
    department: 'marketing',
    name: 'funnels',
    icon: 'funnel-dollar',
    keywords: ['funnels', 'services', 'conversions'],
    status: true,
    permission: 'crm.funnels',
  },
  channels: {
    department: 'marketing',
    name: 'channels',
    icon: 'link',
    keywords: ['channels', 'links', 'urls'],
    status: true,
    permission: 'crm.channels',
  },
  contacts: {
    name: 'Contacts',
    icon: 'user-tag',
    status: true,
    keywords: [],
    department: 'directory',
    permission: 'crm.contacts',
  },

  alerts: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  notifications: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  information: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
  faq: {
    name: '',
    icon: 'list',
    keywords: [],
    department: '',
    status: undefined,
    permission: undefined,
  },
};
