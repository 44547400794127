import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import 'react-native-gesture-handler';
import { MainApplication } from './src/application.main';
import './src/i18n';
import { FontUtility } from './src/utils/font/font.utility';
import { NavigationConstants } from './src/constants/navigation.constants';
import { ToastProvider } from 'react-native-toast-notifications';
import { IconComponent } from '@components/icon/icon.component';
import { ToolProvider } from './src/context/tool/tool.context';
import { ToasterProvider } from './src/context/toaster/toaster.context';

export default function App() {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  // This useEffect initializes the market with the test data and loads the source.
  useEffect(() => {
    FontUtility().then(() => setIsFontLoaded(true));
  }, []);

  if (!isFontLoaded) {
    return null;
  }
  return (
    <View
      style={{
        overflow: 'hidden',
        borderWidth: 1,
        flex: 1,
      }}
    >
      {/* <DeviceIndicator /> */}
      <NavigationContainer
        documentTitle={{
          formatter: (options, route) =>
            `${NavigationConstants.title} ${options?.title ?? route?.name}`,
        }}
        linking={
          {
            enabled: true,
            prefixes: ['*'],
            config: {
              screens: {
                authorized: {
                  screens: {
                    human: {
                      screens: {
                        access: {
                          screens: {
                            departments: {
                              path: 'departments',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            users: {
                              path: 'users',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',

                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                              //delete: 'delete',
                              // licenseCreate: 'licenseCreate',
                              // licenseDelete: 'licenseDelete',
                              // contractCreate: 'contractCreate',
                              // contractDelete: 'contractDelete',
                            },
                            roles: {
                              path: 'roles',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            timetables: {
                              path: 'timetables',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            bulletins: {
                              path: 'bulletins',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            clinics: {
                              path: 'clinics',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            languages: {
                              path: 'languages',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            countries: {
                              path: 'countries',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            settings: {
                              path: 'settings',
                            },
                          },
                        },
                        crm: {
                          path: 'crm',
                          screens: {
                            dashboard: {
                              path: 'dashboard',
                              initialRouteName: 'dashboard',
                              screens: {
                                dashboard: '',
                                leadUpdate: 'leads/update',
                                actionCreate: 'actions/create',
                                actionUpdate: 'actions/update',
                              },
                            },
                            contacts: {
                              path: 'contacts',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            actions: {
                              path: 'actions',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                              },
                            },
                            tasks: {
                              path: 'tasks',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                actionUpdate: {
                                  path: 'update',
                                },
                              },
                            },
                            leads: {
                              path: 'leads',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                leadUpdate: {
                                  path: 'update',
                                },
                                delete: 'delete',
                                actionsCreate: 'actionCreate',
                                actionsUpdate: {
                                  path: 'actionUpdate',
                                },
                                actionsDelete: {
                                  path: 'actionDelete',
                                },
                              },
                            },
                            campaigns: {
                              path: 'campaigns',
                              initialRouteName: 'campaignList',
                              screens: {
                                campaignList: 'list',
                                campaignCreate: 'create',
                                campaignUpdate: {
                                  path: 'update',
                                },
                                campaignDelete: 'delete',
                                createManager: {
                                  path: 'createManager',
                                },
                                deleteManager: {
                                  path: 'deleteManager',
                                },
                              },
                            },
                            channels: {
                              path: 'channels',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            funnels: {
                              path: 'funnels',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },

                            activities: {
                              path: 'activities',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            programs: {
                              path: 'programs',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            results: {
                              path: 'results',
                              initialRouteName: 'list',
                              screens: {
                                resultList: 'list',
                                resultCreate: 'create',
                                resultUpdate: {
                                  path: 'update',
                                },
                                resultDelete: 'delete',
                              },
                            },
                            categories: {
                              path: 'categories',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            products: {
                              path: 'products',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            patients: {
                              path: 'patients',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                            techniques: {
                              path: 'techniques',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },

                            reasons: {
                              path: 'reasons',
                              initialRouteName: 'list',
                              screens: {
                                list: 'list',
                                create: 'create',
                                update: {
                                  path: 'update',
                                },
                                delete: 'delete',
                              },
                            },
                          },
                        },
                        profile: {
                          path: 'profile',
                          initialRouteName: 'account',
                          screens: {
                            account: 'account',
                            changePassword: 'changePassword',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          } as any
        }
      >
        <ToastProvider
          successIcon={<IconComponent name="check-circle" iconStyle={{ color: 'white' }} />}
        >
          <ToolProvider>
            <ToasterProvider>
              <MainApplication />
            </ToasterProvider>
          </ToolProvider>
        </ToastProvider>
      </NavigationContainer>
    </View>
  );
}
