import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { CauseCrmType } from '@human/crm/types/cause.crm.type';

export class CauseCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof CauseCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: CauseCrmType[]; total: number }> {
    return this.fetch('cause/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: CauseCrmType) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          status: item.status,
          icon: item.icon,
          color: item.color,
          created: new Date(item.created),
          phaseId: item.phaseId,
        })),
        total: data.total,
      };
    });
  }

  async listByPhase(properties: {
    phaseId: string;
    limit: number;
    page: number;
    search?: string;
  }): Promise<{ items: CauseCrmType[]; total: number }> {
    return this.fetch('cause/findByPhase', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((item: CauseCrmType) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        status: item.status,
        icon: item.icon,
        color: item.color,
        created: new Date(item.created),
        phaseId: item.phaseId,
      })),
      total: data.total,
    }));
  }

  async pick(properties: { phaseId: string; causeId: string }): Promise<CauseCrmType> {
    return this.fetch(`cause/pick`, {
      method: 'GET',
      params: {
        causeId: properties.causeId,
        phaseId: properties.phaseId,
      },
    });
  }

  async create(properties: { phaseId: string; item: Partial<CauseCrmType> }): Promise<void> {
    return this.fetch('cause/create', {
      method: 'POST',
      params: {
        phaseId: properties.phaseId,
      },
      body: properties.item,
    });
  }

  async update(properties: {
    causeId: string;
    phaseId: string;
    item: Partial<CauseCrmType>;
  }): Promise<void> {
    return this.fetch('cause/update', {
      method: 'PUT',
      body: {
        ...properties.item,
        causeId: properties.causeId,
        phaseId: properties.phaseId,
      },
    });
  }

  enable(properties: { causeId: string; phaseId: string }): Promise<void> {
    return this.fetch('cause/enable', {
      method: 'PUT',
      body: {
        causeId: properties.causeId,
        phaseId: properties.phaseId,
      },
    });
  }

  disable(properties: { causeId: string; phaseId: string }): Promise<void> {
    return this.fetch('cause/disable', {
      method: 'PUT',
      body: {
        causeId: properties.causeId,
        phaseId: properties.phaseId,
      },
    });
  }
}
