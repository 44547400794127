/**
 * Dictionary of icons
 * where the key is the name and value is the unicode
 * you can see list of icons here: https://icons8.com/line-awesome
 * @constant {Object} Icons
 */
export const IconsConstant = {
  'mail-bulk': 'e900',
  'globe-europe': 'e901',
  cube: 'e902',
  heart: 'ec2c',
  'redo-alt': 'e904',
  'hat-cowboy': 'e905',
  'code-branch': 'e906',
  itunes: 'e907',
  'basketball-ball': 'e908',
  hospital: 'ec3d',
  'heart-broken': 'e90a',
  'wave-square': 'e90b',
  'lira-sign': 'e90c',
  'space-shuttle': 'e90d',
  'h-square': 'e90e',
  'angle-double-right': 'e90f',
  cubes: 'e910',
  flickr: 'e911',
  spinner: 'e912',
  firefox: 'e913',
  'money-check': 'e914',
  'align-right': 'e915',
  staylinked: 'e916',
  clipboard: 'ed32',
  'caret-square-up': 'e993',
  'pencil-alt': 'e919',
  surprise: 'e9a6',
  map: 'edc3',
  divide: 'e91c',
  transgender: 'e91d',
  'internet-explorer': 'e91e',
  plug: 'e91f',
  resolving: 'e920',
  behance: 'e921',
  moon: 'ece3',
  tree: 'e923',
  gem: 'eb06',
  'medium-m': 'e925',
  ups: 'e926',
  store: 'e927',
  'get-pocket': 'e928',
  dashcube: 'e929',
  'twitter-square': 'e92a',
  icicles: 'e92b',
  briefcase: 'e92c',
  'hand-holding-heart': 'e92d',
  running: 'e92e',
  'cc-mastercard': 'e92f',
  flushed: 'ee0c',
  'grin-alt': 'ed58',
  'traffic-light': 'e932',
  'file-pdf': 'eb7d',
  'share-alt': 'e934',
  node: 'e935',
  deploydog: 'e936',
  'battery-three-quarters': 'e937',
  hotel: 'e938',
  typo3: 'e939',
  'id-card-alt': 'e93a',
  folder: 'eea8',
  megaport: 'e93c',
  'suitcase-rolling': 'e93d',
  asterisk: 'e93e',
  'sign-in-alt': 'e93f',
  angry: 'ed8e',
  'pinterest-square': 'e941',
  'smoking-ban': 'e942',
  'wine-glass-alt': 'e943',
  building: 'e998',
  'shipping-fast': 'e945',
  searchengin: 'e946',
  'apple-alt': 'e947',
  gifts: 'e948',
  'digital-tachograph': 'e949',
  'user-md': 'e94a',
  autoprefixer: 'e94b',
  weebly: 'e94c',
  palfed: 'e94d',
  'teeth-open': 'e94e',
  'quote-left': 'e94f',
  'google-plus-g': 'e950',
  'odnoklassniki-square': 'e951',
  'telegram-plane': 'e952',
  waze: 'e953',
  vnv: 'e954',
  nimblr: 'e955',
  napster: 'e956',
  passport: 'e957',
  apple: 'e958',
  'universal-access': 'e959',
  maxcdn: 'e95a',
  'node-js': 'e95b',
  'candy-cane': 'e95c',
  'window-restore': 'eef1',
  'snapchat-square': 'e95e',
  forumbee: 'e95f',
  'arrows-alt': 'e960',
  'feather-alt': 'e961',
  crutch: 'e962',
  'dice-five': 'e963',
  info: 'e964',
  'sticky-note': 'ec70',
  chrome: 'e966',
  hdd: 'eaad',
  list: 'e968',
  'chevron-circle-down': 'e969',
  bookmark: 'e997',
  cloudsmith: 'e96b',
  'euro-sign': 'e96c',
  'pied-piper-alt': 'e96d',
  globe: 'e96e',
  'poo-storm': 'e96f',
  meteor: 'e970',
  cheese: 'e971',
  'user-injured': 'e972',
  'door-closed': 'e973',
  amilia: 'e974',
  'file-excel': 'eed3',
  'ellipsis-h': 'e976',
  'address-card': 'eac0',
  'grin-stars': 'eb13',
  'hand-rock': 'edac',
  'luggage-cart': 'e97a',
  'cash-register': 'e97b',
  'car-side': 'e97c',
  sitemap: 'e97d',
  pushed: 'e97e',
  'facebook-messenger': 'e97f',
  blackberry: 'e980',
  'wordpress-simple': 'e981',
  viadeo: 'e982',
  user: 'ee6e',
  grunt: 'e984',
  republican: 'e985',
  cloudversify: 'e986',
  registered: 'ea27',
  korvue: 'e988',
  'mars-stroke-h': 'e989',
  kiss: 'ebf6',
  'arrow-alt-circle-down': 'ea1b',
  screwdriver: 'e98c',
  'cloud-moon': 'e98d',
  wordpress: 'e98e',
  'hand-pointer': 'ec3f',
  cpanel: 'e990',
  'user-secret': 'e991',
  ebay: 'e992',
  gofore: 'e994',
  skype: 'e995',
  'spell-check': 'e996',
  'money-bill-wave-alt': 'e999',
  'steam-square': 'e99a',
  gitkraken: 'e99b',
  ember: 'e99c',
  'file-export': 'e99d',
  'glide-g': 'e99e',
  'phone-slash': 'e99f',
  'laugh-squint': 'eeda',
  'first-order': 'e9a1',
  'i-cursor': 'e9a2',
  hooli: 'e9a3',
  'user-friends': 'e9a4',
  'comment-alt': 'ed63',
  futbol: 'eb88',
  'creative-commons-sampling': 'e9a8',
  'folder-minus': 'e9a9',
  'pied-piper-pp': 'e9aa',
  quran: 'e9ab',
  'location-arrow': 'e9ac',
  tablets: 'e9ad',
  helicopter: 'e9ae',
  'truck-moving': 'e9af',
  'toilet-paper': 'e9b0',
  'volume-off': 'e9b1',
  gopuram: 'e9b2',
  'wikipedia-w': 'e9b3',
  'product-hunt': 'e9b4',
  boxes: 'e9b5',
  'file-csv': 'e9b6',
  'gas-pump': 'e9b7',
  soundcloud: 'e9b8',
  zhihu: 'e9b9',
  slack: 'e9ba',
  'file-code': 'eb1d',
  firstdraft: 'e9bc',
  'ellipsis-v': 'e9bd',
  'calendar-times': 'eebd',
  mixcloud: 'e9bf',
  'file-download': 'e9c0',
  'truck-pickup': 'e9c1',
  'hand-spock': 'eb2d',
  torah: 'e9c3',
  'network-wired': 'e9c4',
  'less-than': 'e9c5',
  'times-circle': 'ed04',
  cut: 'e9c7',
  'battery-empty': 'e9c8',
  'facebook-square': 'e9c9',
  child: 'e9ca',
  'chevron-circle-left': 'e9cb',
  vuejs: 'e9cc',
  'greater-than-equal': 'e9cd',
  'remove-format': 'e9ce',
  bomb: 'e9cf',
  'arrow-alt-circle-left': 'ee9d',
  sistrix: 'e9d1',
  ns8: 'e9d2',
  'map-pin': 'e9d3',
  'user-ninja': 'e9d4',
  'cloud-upload-alt': 'e9d5',
  'temperature-high': 'e9d6',
  fax: 'e9d7',
  'envelope-square': 'e9d8',
  renren: 'e9d9',
  igloo: 'e9da',
  ghost: 'e9db',
  css3: 'e9dc',
  'border-none': 'e9dd',
  buffer: 'e9de',
  gitter: 'e9df',
  'mars-stroke-v': 'e9e0',
  peace: 'e9e1',
  fingerprint: 'e9e2',
  calendar: 'eccb',
  'hand-holding-usd': 'e9e4',
  opera: 'e9e5',
  comment: 'ed49',
  'hacker-news-square': 'e9e7',
  discord: 'e9e8',
  'life-ring': 'ed2e',
  swift: 'e9ea',
  handshake: 'ee7c',
  'bell-slash': 'ea2d',
  patreon: 'e9ed',
  'layer-group': 'e9ee',
  'globe-asia': 'e9ef',
  envelope: 'ecf6',
  'umbrella-beach': 'e9f1',
  broom: 'e9f2',
  'comments-dollar': 'e9f3',
  less: 'e9f4',
  twitter: 'e9f5',
  'star-of-david': 'e9f6',
  'chart-line': 'e9f7',
  'optin-monster': 'e9f8',
  'quote-right': 'e9f9',
  'id-card': 'ed99',
  'dice-three': 'e9fb',
  'creative-commons-by': 'e9fc',
  'pepper-hot': 'e9fd',
  'chalkboard-teacher': 'e9fe',
  haykal: 'e9ff',
  react: 'ea00',
  'search-location': 'ea01',
  tools: 'ea02',
  chess: 'ea03',
  menorah: 'ea04',
  uikit: 'ea05',
  'calendar-minus': 'edb4',
  vaadin: 'ea07',
  highlighter: 'ea08',
  qq: 'ea09',
  'old-republic': 'ea0a',
  mandalorian: 'ea0b',
  dungeon: 'ea0c',
  'cloud-meatball': 'ea0d',
  stream: 'ea0e',
  linkedin: 'ea0f',
  ad: 'ea10',
  contao: 'ea11',
  bong: 'ea12',
  'fast-forward': 'ea13',
  grin: 'edb1',
  khanda: 'ea15',
  'google-plus': 'ea16',
  tooth: 'ea17',
  cookie: 'ea18',
  stop: 'ea19',
  'cart-plus': 'ea1a',
  snowman: 'ea1c',
  home: 'ea1d',
  'shuttle-van': 'ea1e',
  themeco: 'ea1f',
  hornbill: 'ea20',
  trademark: 'ea21',
  dhl: 'ea22',
  adversal: 'ea23',
  video: 'ea24',
  poll: 'ea25',
  dumpster: 'ea26',
  trello: 'ea28',
  save: 'ebb6',
  xbox: 'ea2a',
  bell: 'ec9b',
  'x-ray': 'ea2c',
  'cc-amazon-pay': 'ea2e',
  'comment-dollar': 'ea2f',
  dribbble: 'ea30',
  codepen: 'ea31',
  monument: 'ea32',
  evernote: 'ea33',
  'level-down-alt': 'ea34',
  'user-tie': 'ea35',
  sync: 'ea36',
  avianex: 'ea37',
  'briefcase-medical': 'ea38',
  road: 'ea39',
  'dice-four': 'ea3a',
  'mobile-alt': 'ea3b',
  'file-invoice': 'ea3c',
  'truck-monster': 'ea3d',
  'mouse-pointer': 'ea3e',
  'play-circle': 'eeb8',
  'hand-point-right': 'ee1d',
  'shoe-prints': 'ea41',
  quidditch: 'ea42',
  'file-archive': 'ec21',
  'js-square': 'ea44',
  'user-lock': 'ea45',
  linode: 'ea46',
  'blender-phone': 'ea47',
  tty: 'ea48',
  'window-minimize': 'ea61',
  walking: 'ea4a',
  eye: 'ebae',
  'balance-scale': 'ea4c',
  copy: 'ebed',
  wpforms: 'ea4e',
  microchip: 'ea4f',
  angellist: 'ea50',
  asymmetrik: 'ea51',
  'phone-square': 'ea52',
  microphone: 'ea53',
  'file-upload': 'ea54',
  frown: 'ec64',
  'steam-symbol': 'ea56',
  vials: 'ea57',
  'chevron-circle-up': 'ea58',
  'sad-cry': 'ed78',
  buysellads: 'ea5a',
  teeth: 'ea5b',
  'cloud-rain': 'ea5c',
  cloudscale: 'ea5d',
  'balance-scale-right': 'ea5e',
  'venus-mars': 'ea5f',
  'pen-nib': 'ea60',
  alipay: 'ea62',
  reddit: 'ea63',
  'pause-circle': 'eeea',
  clock: 'eb46',
  'laptop-medical': 'ea66',
  'sad-tear': 'ed71',
  'cotton-bureau': 'ea68',
  'house-damage': 'ea69',
  diaspora: 'ea6a',
  keycdn: 'ea6b',
  'exchange-alt': 'ea6c',
  'hand-paper': 'eba3',
  'list-alt': 'eeb7',
  gulp: 'ea6f',
  'itunes-note': 'ea70',
  'creative-commons-remix': 'ea71',
  sketch: 'ea72',
  biking: 'ea73',
  uber: 'ea74',
  tired: 'edc7',
  share: 'ea76',
  'window-close': 'ead1',
  'trash-alt': 'ea9d',
  'goodreads-g': 'ea79',
  'caret-square-right': 'eabb',
  'hat-wizard': 'ea7b',
  'sticker-mule': 'ea7c',
  venus: 'ea7d',
  grav: 'ea7e',
  yahoo: 'ea7f',
  trash: 'ea80',
  'share-alt-square': 'ea81',
  heartbeat: 'ea82',
  'map-marked': 'ea83',
  jsfiddle: 'ea84',
  'user-circle': 'ebf2',
  'linkedin-in': 'ea86',
  'glass-martini': 'ea87',
  'hand-peace': 'ec83',
  'list-ul': 'ea89',
  nutritionix: 'ea8a',
  'dollar-sign': 'ea8b',
  'credit-card': 'eaaa',
  percent: 'ea8d',
  'search-minus': 'ea8e',
  'torii-gate': 'ea8f',
  'file-video': 'ed6a',
  stumbleupon: 'ea91',
  codiepie: 'ea92',
  'balance-scale-left': 'ea93',
  'hockey-puck': 'ea94',
  times: 'ea95',
  cat: 'ea96',
  'file-medical-alt': 'ea97',
  gamepad: 'ea98',
  slash: 'ea99',
  'dice-one': 'ea9a',
  'app-store': 'ea9b',
  poo: 'ea9c',
  compass: 'eab1',
  hiking: 'ea9f',
  bolt: 'eaa0',
  'tachometer-alt': 'eaa1',
  stamp: 'eaa2',
  vial: 'eaa3',
  'gg-circle': 'eaa4',
  'won-sign': 'eaa5',
  'accessible-icon': 'eaa6',
  paperclip: 'eaa7',
  youtube: 'eaa8',
  'star-of-life': 'eaa9',
  'smile-beam': 'eacc',
  elementor: 'eaac',
  'creative-commons-zero': 'eaae',
  'angle-double-up': 'eaaf',
  btc: 'eab0',
  rev: 'eab2',
  lemon: 'ee1b',
  'align-center': 'eab4',
  'user-edit': 'eab5',
  windows: 'eab6',
  'cc-diners-club': 'eab7',
  twitch: 'eab8',
  plus: 'eab9',
  blind: 'eaba',
  'ruler-combined': 'eabc',
  hips: 'eabd',
  glasses: 'eabe',
  spa: 'eabf',
  award: 'eac1',
  'car-battery': 'eac2',
  'caret-square-down': 'eba0',
  'circle-notch': 'eac4',
  'facebook-f': 'eac5',
  'fantasy-flight-games': 'eac6',
  socks: 'eac7',
  'envelope-open-text': 'eac8',
  redhat: 'eac9',
  edit: 'ecd2',
  medapps: 'eacb',
  'fire-alt': 'eacd',
  'trash-restore': 'eace',
  'dice-d6': 'eacf',
  'truck-loading': 'ead0',
  'sort-numeric-up-alt': 'ead2',
  portrait: 'ead3',
  xing: 'ead4',
  baby: 'ead5',
  'css3-alt': 'ead6',
  hands: 'ead7',
  italic: 'ead8',
  box: 'ead9',
  sun: 'ee42',
  infinity: 'eadb',
  toilet: 'eadc',
  adn: 'eadd',
  glide: 'eade',
  star: 'eb23',
  studiovinari: 'eae0',
  jenkins: 'eae1',
  'hands-helping': 'eae2',
  fedora: 'eae3',
  play: 'eae4',
  'parachute-box': 'eae5',
  'meh-rolling-eyes': 'ec44',
  'closed-captioning': 'ebdf',
  'long-arrow-alt-right': 'eae8',
  'address-book': 'ecc9',
  'cloud-sun-rain': 'eaea',
  hourglass: 'ee9f',
  'level-up-alt': 'eaec',
  'stack-exchange': 'eaed',
  'thermometer-three-quarters': 'eaee',
  'street-view': 'eaef',
  scribd: 'eaf0',
  'file-import': 'eaf1',
  'angle-double-left': 'eaf2',
  redo: 'eaf3',
  'window-maximize': 'ec8e',
  backward: 'eaf5',
  medal: 'eaf6',
  'sort-numeric-down-alt': 'eaf7',
  microscope: 'eaf8',
  heading: 'eaf9',
  'fighter-jet': 'eafa',
  'microphone-alt-slash': 'eafb',
  'chart-bar': 'eebb',
  'font-awesome-flag': 'eafd',
  artstation: 'eafe',
  'spray-can': 'eaff',
  'apple-pay': 'eb00',
  'sort-alpha-up-alt': 'eb01',
  phabricator: 'eb02',
  'low-vision': 'eb03',
  buromobelexperte: 'eb04',
  wrench: 'eb05',
  'envelope-open': 'ec55',
  mars: 'eb08',
  'think-peaks': 'eb09',
  'jedi-order': 'eb0a',
  swatchbook: 'eb0b',
  'book-dead': 'eb0c',
  'external-link-alt': 'eb0d',
  stripe: 'eb0e',
  'stumbleupon-circle': 'eb0f',
  php: 'eb10',
  'user-nurse': 'eb11',
  'first-aid': 'eb12',
  bity: 'eb14',
  aviato: 'eb15',
  atlassian: 'eb16',
  ethernet: 'eb17',
  'caret-left': 'eb18',
  affiliatetheme: 'eb19',
  question: 'eb1a',
  'battle-net': 'eb1b',
  line: 'eb1c',
  'box-open': 'eb1e',
  dove: 'eb1f',
  chromecast: 'eb20',
  google: 'eb21',
  whatsapp: 'eb22',
  'arrows-alt-v': 'eb24',
  crosshairs: 'eb25',
  yandex: 'eb26',
  osi: 'eb27',
  rainbow: 'eb28',
  mdb: 'eb29',
  magic: 'eb2a',
  'star-half': 'ec6f',
  page4: 'eb2c',
  'stack-overflow': 'eb2e',
  'ruble-sign': 'eb2f',
  'creative-commons-pd-alt': 'eb30',
  couch: 'eb31',
  thumbtack: 'eb32',
  'grin-beam-sweat': 'edc9',
  'chess-pawn': 'eb34',
  'grin-tongue': 'eea0',
  'creative-commons-sa': 'eb36',
  pinterest: 'eb37',
  'grin-wink': 'edd0',
  signature: 'eb39',
  vine: 'eb3a',
  'pen-square': 'eb3b',
  'file-powerpoint': 'ec7c',
  restroom: 'eb3d',
  'free-code-camp': 'eb3e',
  'user-check': 'eb3f',
  quinscape: 'eb40',
  amazon: 'eb41',
  'frown-open': 'ed8c',
  'charging-station': 'eb43',
  'sign-language': 'eb44',
  'grin-squint-tears': 'eeba',
  'flag-checkered': 'eb47',
  'dice-d20': 'eb48',
  'undo-alt': 'eb49',
  tram: 'eb4a',
  'grin-beam': 'ebf5',
  'hot-tub': 'eb4c',
  'broadcast-tower': 'eb4d',
  'amazon-pay': 'eb4e',
  facebook: 'eb4f',
  snowplow: 'eb50',
  playstation: 'eb51',
  'globe-africa': 'eb52',
  compress: 'eb53',
  draft2digital: 'eb54',
  'volleyball-ball': 'eb55',
  rocketchat: 'eb56',
  'fist-raised': 'eb57',
  palette: 'eb58',
  'arrow-alt-circle-up': 'eeb5',
  poop: 'eb5a',
  om: 'eb5b',
  university: 'eb5c',
  procedures: 'eb5d',
  'blogger-b': 'eb5e',
  wpbeginner: 'eb5f',
  suse: 'eb60',
  'angle-double-down': 'eb61',
  fire: 'eb62',
  paypal: 'eb63',
  'plane-arrival': 'eb64',
  cocktail: 'eb65',
  'pied-piper': 'eb66',
  'chess-rook': 'eb67',
  opencart: 'eb68',
  ubuntu: 'eb69',
  'paper-plane': 'ec1f',
  'fast-backward': 'eb6b',
  'wine-bottle': 'eb6c',
  'door-open': 'eb6d',
  'first-order-alt': 'eb6e',
  wpexplorer: 'eb6f',
  bicycle: 'eb70',
  'caret-down': 'eb71',
  'calendar-plus': 'ed19',
  'eye-slash': 'eb77',
  'thumbs-down': 'ecfc',
  filter: 'eb75',
  square: 'ecef',
  pen: 'eb78',
  'audio-description': 'eb79',
  'thermometer-empty': 'eb7a',
  binoculars: 'eb7b',
  teamspeak: 'eb7c',
  'thumbs-up': 'eba9',
  jedi: 'eb7f',
  'cloud-showers-heavy': 'eb80',
  file: 'eda7',
  'phone-square-alt': 'eb82',
  'american-sign-language-interpreting': 'eb83',
  'bluetooth-b': 'eb84',
  'hand-lizard': 'ece7',
  key: 'eb86',
  centos: 'eb87',
  earlybirds: 'eb89',
  ambulance: 'eb8a',
  mercury: 'eb8b',
  'xing-square': 'eb8c',
  'arrows-alt-h': 'eb8d',
  'digital-ocean': 'eb8e',
  'cloud-sun': 'eb8f',
  ban: 'eb90',
  'user-graduate': 'eb91',
  signal: 'eb92',
  'file-alt': 'ed9e',
  images: 'ebb1',
  laravel: 'eb95',
  'github-square': 'eb96',
  dochub: 'eb97',
  'cc-visa': 'eb98',
  car: 'eb99',
  yarn: 'eb9a',
  'creative-commons-share': 'eb9b',
  'github-alt': 'eb9c',
  'hire-a-helper': 'eb9d',
  dog: 'eb9e',
  monero: 'eb9f',
  'font-awesome-alt': 'eba1',
  'birthday-cake': 'eba2',
  pastafarianism: 'eba4',
  quora: 'eba5',
  ravelry: 'eba6',
  tablet: 'eba7',
  'laugh-beam': 'ed7c',
  'calendar-alt': 'ecbe',
  'globe-americas': 'ebab',
  'grin-tears': 'ed77',
  rockrms: 'ebad',
  cuttlefish: 'ebaf',
  'angle-up': 'ebb0',
  'smile-wink': 'ee5e',
  'hospital-symbol': 'ebb3',
  replyd: 'ebb4',
  smile: 'ebf9',
  motorcycle: 'ebb7',
  pills: 'ebb8',
  'paint-roller': 'ebb9',
  deaf: 'ebba',
  genderless: 'ebbb',
  html5: 'ebbc',
  'check-circle': 'ed37',
  themeisle: 'ebbe',
  lightbulb: 'ec01',
  satellite: 'ebc0',
  'sort-down': 'ebc1',
  'hand-point-down': 'ee7d',
  image: 'ecbb',
  'creative-commons-nc-jp': 'ebc4',
  readme: 'ebc5',
  'buy-n-large': 'ebc6',
  search: 'ebc7',
  'calendar-check': 'ed09',
  'file-word': 'ee0a',
  'vote-yea': 'ebca',
  'font-awesome': 'ebcb',
  'star-and-crescent': 'ebcc',
  'shopping-cart': 'ebcd',
  snapchat: 'ebce',
  'plane-departure': 'ebcf',
  guitar: 'ebd0',
  bible: 'ebd1',
  'sort-amount-up-alt': 'ebd2',
  slideshare: 'ebd3',
  kickstarter: 'ebd4',
  modx: 'ebd5',
  hryvnia: 'ebd6',
  'file-medical': 'ebd7',
  periscope: 'ebd8',
  'arrow-alt-circle-right': 'ec32',
  'poll-h': 'ebda',
  archive: 'ebdb',
  'lastfm-square': 'ebdc',
  chair: 'ebdd',
  'cart-arrow-down': 'ebde',
  wpressr: 'ebe0',
  'fire-extinguisher': 'ebe1',
  'sort-numeric-down': 'ebe2',
  'info-circle': 'ebe3',
  lyft: 'ebe4',
  'kiss-beam': 'ebfb',
  'radiation-alt': 'ebe6',
  researchgate: 'ebe7',
  hamburger: 'ebe8',
  'theater-masks': 'ebe9',
  'cloud-moon-rain': 'ebea',
  users: 'ebeb',
  minus: 'ebec',
  'cc-jcb': 'ebee',
  'align-left': 'ebef',
  'tint-slash': 'ebf0',
  'tablet-alt': 'ebf1',
  'kickstarter-k': 'ebf3',
  podcast: 'ebf4',
  'money-check-alt': 'ebf7',
  'assistive-listening-systems': 'ebf8',
  'caret-square-left': 'ed1c',
  'phoenix-framework': 'ebfc',
  shopware: 'ebfd',
  rss: 'ebfe',
  stroopwafel: 'ebff',
  'google-wallet': 'ec00',
  hammer: 'ec02',
  flipboard: 'ec03',
  'book-open': 'ec04',
  qrcode: 'ec05',
  barcode: 'ec06',
  'wolf-pack-battalion': 'ec07',
  intercom: 'ec08',
  mobile: 'ec09',
  ship: 'ec0a',
  spotify: 'ec0b',
  undo: 'ec0c',
  jira: 'ec0d',
  sellsy: 'ec0e',
  expand: 'ec0f',
  gift: 'ec10',
  'video-slash': 'ec11',
  crow: 'ec12',
  'book-medical': 'ec13',
  'dolly-flatbed': 'ec14',
  bed: 'ec15',
  'file-prescription': 'ec16',
  ussunnah: 'ec17',
  backspace: 'ec18',
  algolia: 'ec19',
  parking: 'ec1a',
  link: 'ec1b',
  sith: 'ec1c',
  beer: 'ec1d',
  'canadian-maple-leaf': 'ec1e',
  'grip-lines-vertical': 'ec20',
  eject: 'ec22',
  'the-red-yeti': 'ec23',
  'map-marker': 'ec24',
  columns: 'ec25',
  mitten: 'ec26',
  plane: 'ec27',
  'exclamation-triangle': 'ec28',
  atlas: 'ec29',
  'file-invoice-dollar': 'ec2a',
  'air-freshener': 'ec2b',
  'volume-mute': 'ec2d',
  headset: 'ec2e',
  kaaba: 'ec2f',
  expeditedssl: 'ec30',
  bacon: 'ec31',
  certificate: 'ec33',
  'caret-right': 'ec34',
  wallet: 'ec35',
  'clipboard-check': 'ec36',
  'laptop-code': 'ec37',
  skating: 'ec38',
  houzz: 'ec39',
  snowflake: 'ec7b',
  bluetooth: 'ec3b',
  diagnoses: 'ec3c',
  toolbox: 'ec3e',
  magento: 'ec40',
  'object-ungroup': 'ecaa',
  tv: 'ec42',
  icons: 'ec43',
  braille: 'ec45',
  gratipay: 'ec46',
  'shield-alt': 'ec47',
  medkit: 'ec48',
  'solar-panel': 'ec49',
  'share-square': 'ed28',
  'swimming-pool': 'ec4b',
  'border-style': 'ec4c',
  'arrow-circle-left': 'ec4d',
  'check-square': 'eee8',
  'battery-quarter': 'ec4f',
  frog: 'ec50',
  female: 'ec51',
  mix: 'ec52',
  'square-full': 'ec53',
  'money-bill': 'ec54',
  invision: 'ec56',
  'creative-commons-sampling-plus': 'ec57',
  'ruler-vertical': 'ec58',
  'search-dollar': 'ec59',
  'hand-point-up': 'ecca',
  drum: 'ec5b',
  'chevron-circle-right': 'ec5c',
  'grin-tongue-squint': 'ed4c',
  'map-marker-alt': 'ec5e',
  meh: 'ed1f',
  'dribbble-square': 'ec60',
  laptop: 'ec61',
  goodreads: 'ec62',
  'meh-blank': 'eda5',
  syringe: 'ec65',
  flask: 'ec66',
  'pen-alt': 'ec67',
  history: 'ec68',
  tasks: 'ec69',
  'eye-dropper': 'ec6a',
  industry: 'ec6b',
  bullhorn: 'ec6c',
  'calendar-day': 'ec6d',
  subway: 'ec6e',
  paragraph: 'ec71',
  'angle-left': 'ec72',
  'book-reader': 'ec73',
  skiing: 'ec74',
  skull: 'ec75',
  markdown: 'ec76',
  'lock-open': 'ec77',
  'dot-circle': 'ee92',
  language: 'ec79',
  'wizards-of-the-coast': 'ec7a',
  newspaper: 'ed1e',
  male: 'ec7e',
  'chess-board': 'ec7f',
  'th-large': 'ec80',
  meetup: 'ec81',
  terminal: 'ec82',
  salesforce: 'ec84',
  medrt: 'ec85',
  'calendar-week': 'ec86',
  circle: 'ee43',
  erlang: 'ec88',
  'pizza-slice': 'ec89',
  'unlock-alt': 'ec8a',
  centercode: 'ec8b',
  prescription: 'ec8c',
  flag: 'ed7e',
  'cc-discover': 'ec8f',
  'mars-stroke': 'ec90',
  'crop-alt': 'ec91',
  telegram: 'ec92',
  'pound-sign': 'ec93',
  'sort-amount-down-alt': 'ec94',
  random: 'ec95',
  tshirt: 'ec96',
  'microphone-slash': 'ec97',
  'vimeo-square': 'ec98',
  'microphone-alt': 'ec99',
  hackerrank: 'ec9a',
  'venus-double': 'ec9c',
  'bread-slice': 'ec9d',
  receipt: 'ec9e',
  strikethrough: 'ec9f',
  memory: 'eca0',
  'file-image': 'eea6',
  snowboarding: 'eca2',
  donate: 'eca3',
  'band-aid': 'eca4',
  dizzy: 'edc6',
  'phone-volume': 'eca6',
  'dumpster-fire': 'eca7',
  delicious: 'eca8',
  whmcs: 'eca9',
  pallet: 'ecab',
  'yin-yang': 'ecac',
  bitcoin: 'ecad',
  'ruler-horizontal': 'ecae',
  voicemail: 'ecaf',
  'acquisitions-incorporated': 'ecb0',
  film: 'ecb1',
  directions: 'ecb2',
  carrot: 'ecb3',
  'minus-square': 'ed02',
  'user-tag': 'ecb5',
  digg: 'ecb6',
  cog: 'ecb7',
  'pinterest-p': 'ecb8',
  'chevron-up': 'ecb9',
  taxi: 'ecba',
  'grip-lines': 'ecbc',
  'creative-commons-pd': 'ecbd',
  'hand-middle-finger': 'ecbf',
  'money-bill-alt': 'ecd4',
  neos: 'ecc1',
  umbrella: 'ecc2',
  hippo: 'ecc3',
  'minus-circle': 'ecc4',
  'vector-square': 'ecc5',
  'mars-double': 'ecc6',
  spider: 'ecc7',
  mailchimp: 'ecc8',
  'angle-down': 'eccc',
  schlix: 'eccd',
  'mug-hot': 'ecce',
  'file-signature': 'eccf',
  'notes-medical': 'ecd0',
  'sort-amount-down': 'ecd1',
  paw: 'ecd3',
  'creative-commons-nc-eu': 'ecd5',
  'user-plus': 'ecd6',
  tags: 'ecd7',
  'people-carry': 'ecd8',
  'hand-scissors': 'edce',
  weight: 'ecda',
  'map-marked-alt': 'ecdb',
  blender: 'ecdc',
  'itch-io': 'ecdd',
  'paint-brush': 'ecde',
  chalkboard: 'ecdf',
  blogger: 'ece0',
  git: 'ece1',
  docker: 'ece2',
  swimmer: 'ece4',
  'prescription-bottle': 'ece5',
  truck: 'ece6',
  'text-width': 'ece8',
  bone: 'ece9',
  'cc-apple-pay': 'ecea',
  'user-alt-slash': 'eceb',
  mosque: 'ecec',
  'hand-point-left': 'ee1c',
  'piggy-bank': 'ecee',
  python: 'ecf0',
  radiation: 'ecf1',
  utensils: 'ecf2',
  landmark: 'ecf3',
  'arrow-up': 'ecf4',
  'grin-tongue-wink': 'ee3d',
  phone: 'ecf7',
  'step-forward': 'ecf8',
  clone: 'eefb',
  'grip-horizontal': 'ecfa',
  'prescription-bottle-alt': 'ecfb',
  bold: 'ecfd',
  'fort-awesome': 'ecfe',
  yoast: 'ecff',
  'thermometer-half': 'ed00',
  audible: 'ed01',
  'pied-piper-hat': 'ed03',
  'file-contract': 'ed05',
  'baseball-ball': 'ed06',
  'toggle-on': 'ed07',
  safari: 'ed08',
  'concierge-bell': 'ed0a',
  stethoscope: 'ed0b',
  'compact-disc': 'ed0c',
  'hourglass-half': 'ed0d',
  'sign-out-alt': 'ed0e',
  adjust: 'ed0f',
  fly: 'ed10',
  'long-arrow-alt-up': 'ed11',
  unlock: 'ed12',
  'place-of-worship': 'ed13',
  yammer: 'ed14',
  edge: 'ed15',
  'bus-alt': 'ed16',
  'dice-six': 'ed17',
  'stop-circle': 'eddb',
  cross: 'ed1a',
  'r-project': 'ed1b',
  'fort-awesome-alt': 'ed1d',
  mendeley: 'ed20',
  dharmachakra: 'ed21',
  'expand-arrows-alt': 'ed22',
  'vr-cardboard': 'ed23',
  trophy: 'ed24',
  'praying-hands': 'ed25',
  'thermometer-quarter': 'ed26',
  copyright: 'ee38',
  'power-off': 'ed29',
  'external-link-square-alt': 'ed2a',
  'clipboard-list': 'ed2b',
  'y-combinator': 'ed2c',
  coffee: 'ed2d',
  instagram: 'ed2f',
  'sort-alpha-down-alt': 'ed30',
  'hourglass-end': 'ed31',
  exclamation: 'ed33',
  'football-ball': 'ed34',
  ruler: 'ed35',
  brush: 'ed36',
  'utensil-spoon': 'ed38',
  'store-alt': 'ed39',
  'hacker-news': 'ed3a',
  skyatlas: 'ed3b',
  desktop: 'ed3c',
  'kiwi-bird': 'ed3d',
  marker: 'ed3e',
  database: 'ed3f',
  'hat-cowboy-side': 'ed40',
  download: 'ed41',
  'arrow-circle-down': 'ed42',
  bug: 'ed43',
  th: 'ed44',
  'slack-hash': 'ed45',
  'folder-plus': 'ed46',
  symfony: 'ed47',
  allergies: 'ed48',
  'comment-slash': 'ed4a',
  'thermometer-full': 'ed4b',
  print: 'ed4d',
  'chart-area': 'ed4e',
  hotjar: 'ed4f',
  'oil-can': 'ed50',
  atom: 'ed51',
  discourse: 'ed52',
  vimeo: 'ed53',
  hotdog: 'ed54',
  'rupee-sign': 'ed55',
  'question-circle': 'edb8',
  vk: 'ed57',
  'behance-square': 'ed59',
  'chess-knight': 'ed5a',
  'git-alt': 'ed5b',
  'fill-drip': 'ed5c',
  apper: 'ed5d',
  untappd: 'ed5e',
  keyboard: 'ed87',
  'drum-steelpan': 'ed60',
  splotch: 'ed61',
  angular: 'ed62',
  mastodon: 'ed64',
  'folder-open': 'edd4',
  'tumblr-square': 'ed66',
  recycle: 'ed67',
  camera: 'ed68',
  font: 'ed69',
  'raspberry-pi': 'ed6b',
  'not-equal': 'ed6c',
  'user-clock': 'ed6d',
  'hand-holding': 'ed6e',
  table: 'ed6f',
  check: 'ed70',
  stopwatch: 'ed72',
  laugh: 'ee58',
  underline: 'ed74',
  'galactic-republic': 'ed75',
  'creative-commons-nc': 'ed76',
  hamsa: 'ed79',
  'trash-restore-alt': 'ed7a',
  'id-badge': 'ee66',
  at: 'ed7d',
  'th-list': 'ed7f',
  'record-vinyl': 'ed80',
  anchor: 'ed81',
  pause: 'ed82',
  'shopping-bag': 'ed83',
  'headphones-alt': 'ed84',
  eraser: 'ed85',
  'long-arrow-alt-left': 'ed86',
  'chart-pie': 'ed88',
  wind: 'ed89',
  tag: 'ed8a',
  vihara: 'ed8b',
  npm: 'ed8d',
  'reddit-square': 'ed8f',
  'user-cog': 'ed90',
  'kiss-wink-heart': 'ee69',
  servicestack: 'ed92',
  'satellite-dish': 'ed93',
  'chess-bishop': 'ed94',
  'youtube-square': 'ed95',
  tractor: 'ed96',
  bars: 'ed97',
  smoking: 'ed98',
  'journal-whills': 'ed9a',
  'reply-all': 'ed9b',
  confluence: 'ed9c',
  music: 'ed9d',
  'arrow-right': 'ed9f',
  sms: 'eda0',
  hubspot: 'eda1',
  'star-half-alt': 'eda2',
  magnet: 'eda3',
  bath: 'eda4',
  'golf-ball': 'eda6',
  school: 'eda8',
  mouse: 'eda9',
  'plus-square': 'edbf',
  kaggle: 'edab',
  'car-alt': 'edad',
  'yen-sign': 'edae',
  'chevron-right': 'edaf',
  'd-and-d': 'edb0',
  'weight-hanging': 'edb2',
  'check-double': 'edb3',
  uniregistry: 'edb5',
  'google-play': 'edb6',
  joomla: 'edb7',
  cogs: 'edb9',
  umbraco: 'edba',
  'yandex-international': 'edbb',
  keybase: 'edbc',
  joint: 'edbd',
  'stripe-s': 'edbe',
  comments: 'eedf',
  'less-than-equal': 'edc1',
  'vimeo-v': 'edc2',
  'project-diagram': 'edc4',
  'dice-two': 'edc5',
  'user-alt': 'edc8',
  'critical-role': 'edca',
  subscript: 'edcb',
  'drafting-compass': 'edcc',
  'mortar-pestle': 'edcd',
  'arrow-down': 'edcf',
  democrat: 'edd1',
  'file-audio': 'eea5',
  'map-signs': 'edd3',
  deskpro: 'edd5',
  'text-height': 'edd6',
  'speaker-deck': 'edd7',
  fish: 'edd8',
  'step-backward': 'edd9',
  dice: 'edda',
  px: 'eddc',
  bullseye: 'eddd',
  gripfire: 'edde',
  'app-store-ios': 'eddf',
  dolly: 'ede0',
  'bezier-curve': 'ede1',
  bimobject: 'ede2',
  etsy: 'ede3',
  'sd-card': 'ede4',
  'object-group': 'eeb2',
  capsules: 'ede6',
  warehouse: 'ede7',
  'search-plus': 'ede8',
  'square-root-alt': 'ede9',
  'sort-amount-up': 'edea',
  'caret-up': 'edeb',
  'chevron-left': 'edec',
  hanukiah: 'eded',
  'google-drive': 'edee',
  simplybuilt: 'edef',
  retweet: 'edf0',
  'sync-alt': 'edf1',
  empire: 'edf2',
  cloud: 'edf3',
  route: 'edf4',
  grimace: 'eef8',
  usb: 'edf6',
  fedex: 'edf7',
  scroll: 'edf8',
  campground: 'edf9',
  rebel: 'edfa',
  'chess-queen': 'edfb',
  freebsd: 'edfc',
  viacoin: 'edfd',
  github: 'edfe',
  'glass-cheers': 'edff',
  'creative-commons': 'ee00',
  pagelines: 'ee01',
  lock: 'ee02',
  bitbucket: 'ee03',
  synagogue: 'ee04',
  'volume-down': 'ee05',
  leanpub: 'ee06',
  mizuni: 'ee07',
  'ticket-alt': 'ee08',
  'grin-squint': 'eee7',
  'clinic-medical': 'ee0b',
  superscript: 'ee0d',
  'user-astronaut': 'ee0e',
  wix: 'ee0f',
  ello: 'ee10',
  fill: 'ee11',
  viber: 'ee12',
  'trade-federation': 'ee13',
  'compress-arrows-alt': 'ee14',
  'tencent-weibo': 'ee15',
  orcid: 'ee16',
  java: 'ee17',
  outdent: 'ee18',
  'user-minus': 'ee19',
  sellcast: 'ee1a',
  otter: 'ee1e',
  'laugh-wink': 'ef00',
  superpowers: 'ee20',
  'cc-paypal': 'ee21',
  sass: 'ee22',
  crown: 'ee23',
  'align-justify': 'ee24',
  brain: 'ee25',
  aws: 'ee26',
  church: 'ee27',
  weibo: 'ee28',
  joget: 'ee29',
  horse: 'ee2a',
  thermometer: 'ee2b',
  gg: 'ee2c',
  'sort-up': 'ee2d',
  'list-ol': 'ee2e',
  sourcetree: 'ee2f',
  gitlab: 'ee30',
  openid: 'ee31',
  reacteurope: 'ee32',
  book: 'ee33',
  'galactic-senate': 'ee34',
  'sort-alpha-down': 'ee35',
  feather: 'ee36',
  indent: 'ee37',
  'hard-hat': 'ee39',
  'baby-carriage': 'ee3a',
  js: 'ee3b',
  'cc-amex': 'ee3c',
  percentage: 'ee3e',
  dna: 'ee3f',
  'fonticons-fi': 'ee40',
  fan: 'ee41',
  'cookie-bite': 'ee44',
  odnoklassniki: 'ee45',
  'black-tie': 'ee46',
  shirtsinbulk: 'ee47',
  'users-cog': 'ee48',
  pray: 'ee49',
  wheelchair: 'ee4a',
  'photo-video': 'ee4b',
  ethereum: 'ee4c',
  'git-square': 'ee4d',
  adobe: 'ee4e',
  'person-booth': 'ee4f',
  'arrow-left': 'ee50',
  android: 'ee51',
  city: 'ee52',
  speakap: 'ee53',
  dev: 'ee54',
  'horse-head': 'ee55',
  'cc-stripe': 'ee56',
  'border-all': 'ee57',
  'pencil-ruler': 'ee59',
  ribbon: 'ee5a',
  sleigh: 'ee5b',
  equals: 'ee5c',
  tenge: 'ee5d',
  drupal: 'ee5f',
  'user-slash': 'ee60',
  tint: 'ee61',
  foursquare: 'ee62',
  linux: 'ee63',
  fulcrum: 'ee64',
  'user-times': 'ee65',
  envira: 'ee67',
  usps: 'ee68',
  'sort-alpha-up': 'ee6a',
  airbnb: 'ee6b',
  burn: 'ee6c',
  'car-crash': 'ee6d',
  deviantart: 'ee6f',
  'rss-square': 'ee70',
  mask: 'ee71',
  yelp: 'ee72',
  'chevron-down': 'ee73',
  gavel: 'ee74',
  code: 'ee75',
  robot: 'ee76',
  tape: 'ee77',
  'phoenix-squadron': 'ee78',
  lastfm: 'ee79',
  accusoft: 'ee7a',
  sort: 'ee7b',
  'glass-martini-alt': 'ee7e',
  'whatsapp-square': 'ee7f',
  'user-shield': 'ee80',
  'arrow-circle-right': 'ee81',
  coins: 'ee82',
  'phone-alt': 'ee83',
  'holly-berry': 'ee84',
  'greater-than': 'ee85',
  'sort-numeric-up': 'ee86',
  'google-plus-square': 'ee87',
  hashtag: 'ee88',
  mountain: 'ee89',
  squarespace: 'ee8a',
  upload: 'ee8b',
  ring: 'ee8c',
  'glass-whiskey': 'ee8d',
  water: 'ee8e',
  'money-bill-wave': 'ee8f',
  'angle-right': 'ee90',
  'chess-king': 'ee91',
  dyalog: 'ee93',
  unlink: 'ee94',
  'grip-vertical': 'ee95',
  crop: 'ee96',
  'grin-hearts': 'eebc',
  'creative-commons-nd': 'ee98',
  dumbbell: 'ee99',
  'toggle-off': 'ee9a',
  'table-tennis': 'ee9b',
  microsoft: 'ee9c',
  'battery-half': 'ee9e',
  'shekel-sign': 'eea1',
  'temperature-low': 'eea2',
  'cloud-download-alt': 'eea3',
  leaf: 'eea4',
  'hospital-alt': 'eea7',
  smog: 'eea9',
  dropbox: 'eeaa',
  strava: 'eeab',
  'drumstick-bite': 'eeac',
  inbox: 'eead',
  dragon: 'eeae',
  stackpath: 'eeaf',
  'red-river': 'eeb0',
  bandcamp: 'eeb1',
  'comment-dots': 'ef06',
  'penny-arcade': 'eeb4',
  tripadvisor: 'eeb6',
  sign: 'eeb9',
  'graduation-cap': 'eebe',
  rocket: 'eebf',
  cannabis: 'eec0',
  suitcase: 'eec1',
  paste: 'eec2',
  ankh: 'eec3',
  neuter: 'eec4',
  'sliders-h': 'eec5',
  tumblr: 'eec6',
  biohazard: 'eec7',
  'd-and-d-beyond': 'eec8',
  'camera-retro': 'eec9',
  'plus-circle': 'eeca',
  calculator: 'eecb',
  server: 'eecc',
  headphones: 'eecd',
  'long-arrow-alt-down': 'eece',
  'funnel-dollar': 'eecf',
  train: 'eed0',
  'bowling-ball': 'eed1',
  wifi: 'eed2',
  bus: 'eed4',
  'hourglass-start': 'eed5',
  reply: 'eed6',
  blog: 'eed7',
  egg: 'eed8',
  'sim-card': 'eed9',
  steam: 'eedb',
  imdb: 'eedc',
  seedling: 'eedd',
  angrycreative: 'eede',
  'flag-usa': 'eee0',
  'skull-crossbones': 'eee1',
  archway: 'eee2',
  pager: 'eee3',
  'reddit-alien': 'eee4',
  'arrow-circle-up': 'eee5',
  figma: 'eee6',
  'battery-full': 'eee9',
  'puzzle-piece': 'eeeb',
  fonticons: 'eeec',
  'business-time': 'eeed',
  forward: 'eeee',
  'wine-glass': 'eeef',
  'snapchat-ghost': 'eef0',
  'draw-polygon': 'eef2',
  ioxhost: 'eef3',
  shapes: 'eef4',
  shower: 'eef5',
  'exclamation-circle': 'eef6',
  'ice-cream': 'eef7',
  'transgender-alt': 'eef9',
  'skiing-nordic': 'eefa',
  medium: 'eefc',
  'volume-up': 'eefd',
  'pen-fancy': 'eefe',
  connectdevelop: 'eeff',
  weixin: 'ef01',
  'shopping-basket': 'ef02',
  bootstrap: 'ef03',
  'comment-medical': 'ef04',
  supple: 'ef05',
  'viadeo-square': 'ef07',
} as const;

export const KeysIconsConstant = Object.keys(IconsConstant);
