import { Image, TouchableOpacity, View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { useState } from 'react';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '@components/loader/loader.component';
import { ImageUploaderStyles } from './imageUploader.styles';

export function ImageUploaderComponent(properties: {
  onSubmit: (f: File | undefined) => Promise<void>;
  fileType: 'application/pdf' | 'text/csv' | 'image/*';
  file: string | undefined;
}) {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>();
  const { t } = useTranslation();

  return (
    <View style={ImageUploaderStyles.container}>
      <TouchableOpacity style={ImageUploaderStyles.uploadBox}>
        <label style={ImageUploaderStyles.buttonContainer} className="custom-file-upload">
          <input
            id="file"
            type="file"
            accept={properties.fileType}
            onClick={(e) => {
              if ('value' in e.target) e.target.value = null;
            }}
            onChange={(event) => {
              setLoading(true);
              const file = event.target.files?.[0];

              if (
                (file && file.type === properties.fileType) ||
                (file && properties.fileType === 'image/*' && file.type.includes('image'))
              ) {
                setFile(file);
                properties.onSubmit(file);
              } else {
                alert(t('components.fileUploader.invalidType'));
              }
              setLoading(false);
            }}
            className="fileInput"
          />
          <TextComponent
            style={ImageUploaderStyles.buttonLabel}
            text={t('components.fileUploader.title')}
          />
          <IconComponent
            name="upload"
            containerstyle={ImageUploaderStyles.iconContainer}
            iconStyle={ImageUploaderStyles.icon}
          />
        </label>
      </TouchableOpacity>
      {(properties.file || file) && !loading && (
        <View>
          <TouchableOpacity
            onPress={() => {
              properties.onSubmit(undefined);
              setFile(undefined);
            }}
            style={ImageUploaderStyles.iconDeleteContainer}
          >
            <IconComponent
              name="times"
              iconStyle={[ImageUploaderStyles.icon, ImageUploaderStyles.iconDelete]}
            />
          </TouchableOpacity>
          <Image
            style={ImageUploaderStyles.image}
            source={{ uri: file ? URL.createObjectURL(file) : properties.file }}
          />
        </View>
      )}
      {loading && <LoaderComponent loading={loading} />}
    </View>
  );
}
