import { useEffect, useState } from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { NotesComponent } from '@components/notes/notes.component';
import { LeadNoteCrmRepository } from '@human/crm/repositories/leadNote/leadNote.repository';
import { LeadDetailScreen } from './components/leadDetail.screen';
import { TextComponent } from '@components/text/text.component';
import { LeadProfileStyles } from './leadProfile.styles';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { LoaderComponent } from '@components/loader/loader.component';
import { useDeviceHook } from '@hooks/device/device.hook';
import { NavigationProp } from '@react-navigation/native';
import { hasPermissionUtilty } from '../../utils/permission/has.permission.utility';
import { ActionsRoutes } from '@human/crm/sections/actions/actions.routes';

export function LeadProfileComponent(properties: {
  leadId: string;
  clinicId: string;
  contactId: string;
  navigation: NavigationProp<ActionsRoutes>;
}) {
  const { device, phone, tablet } = useDeviceHook();
  const [collapse, setCollapse] = useState<boolean>(phone || tablet);
  const [lead, setLead] = useState<{
    id: string;
    patientId?: string;
    contactName: string;
    contactSurname: string;
    contactEmails: {
      id: string;
      email: string;
      contactId: string;
    }[];
    contactPhones: {
      id: string;
      phone: string;
      prefix: string;
      contactId: string;
    }[];
    contactLegal: boolean;
    contactMarketing: boolean;
    contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
    clinicName: string;
    funnelName: string;
    responsibleName: string;
    responsibleSurname: string;
    status: boolean;
    canceled: boolean;
    campaignName: string;
    originCampaignName: string;
    created: Date;
    birthDate?: Date;
    birthCountry?: string;
    height?: string;
    weight?: string;
    donationPreference?: string;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const loadData = () => {
    setLoading(true);
    new LeadCrmRepository()
      .pickProfile({ leadId: properties.leadId, clinicId: properties.clinicId })
      .then((lead) => {
        setLead(lead);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setCollapse(phone || tablet);
  }, [device]);

  const hasPermission = hasPermissionUtilty({ name: 'crm.patients' });

  const leadStatusText = () => {
    if (lead?.canceled) {
      return 'crm.leads.update.actions.lead.fields.canceled';
    }
    if (!lead?.status) {
      return 'crm.leads.update.actions.lead.fields.inactive';
    }
    if (lead?.patientId) {
      return 'crm.leads.update.actions.lead.fields.converted';
    }
    return 'crm.leads.update.actions.lead.fields.active';
  };

  const leadStatusColor = () => {
    if (lead?.canceled) {
      return LeadProfileStyles.canceledStatusIndicator;
    }
    if (!lead?.status) {
      return LeadProfileStyles.disableStatusIndicator;
    }
    if (lead?.patientId) {
      return LeadProfileStyles.convertedStatusIndicator;
    }
    return LeadProfileStyles.enableStatusIndicator;
  };

  const handlePatienUpdateRedirect = () => {
    if (lead?.patientId) {
      properties.navigation.navigate('patientUpdate', {
        patientId: lead.patientId,
        contactId: properties.contactId,
        clinicId: properties.clinicId,
        level: 1,
      });
    }
  };

  if (loading || !lead) {
    return <LoaderComponent loading={loading} />;
  }

  return (
    <View style={[LeadProfileStyles.container, collapse ? LeadProfileStyles.collapse : null]}>
      <View style={LeadProfileStyles.headerContainer}>
        <View style={LeadProfileStyles.header}>
          <View style={LeadProfileStyles.headerContent}>
            <View style={LeadProfileStyles.profilePicture}>
              <TextComponent
                text={`${lead?.contactName[0].toUpperCase()} ${lead?.contactSurname ? lead?.contactSurname[0].toUpperCase() : ''}`}
                style={LeadProfileStyles.profilePictureText}
                bold
              />
            </View>
            <View style={LeadProfileStyles.headerText}>
              <View style={LeadProfileStyles.headerNameContainer}>
                <TextComponent
                  text={`${lead?.contactName} ${lead?.contactSurname}`}
                  style={LeadProfileStyles.headerName}
                  bold
                  lines={1}
                />
                {hasPermission && lead.patientId && (
                  <TouchableOpacity onPress={handlePatienUpdateRedirect}>
                    <IconComponent
                      name="external-link-alt"
                      iconStyle={LeadProfileStyles.externalLinkIcon}
                    />
                  </TouchableOpacity>
                )}
              </View>
              <View style={LeadProfileStyles.status}>
                <View style={[LeadProfileStyles.statusIndicator, leadStatusColor()]} />
                <TextComponent
                  text={leadStatusText()}
                  translate
                  style={LeadProfileStyles.statusText}
                  bold
                />
              </View>
            </View>
          </View>
          <Pressable
            style={LeadProfileStyles.closeIcon}
            onPress={() => {
              setCollapse(!collapse);
            }}
          >
            <IconComponent
              name={collapse ? 'angle-up' : 'angle-down'}
              iconStyle={LeadProfileStyles.closeShape}
              containerstyle={LeadProfileStyles.close}
            />
          </Pressable>
        </View>
      </View>

      {!collapse && (
        <TabsComponent
          darkMode
          prefix="crm.leads.update.actions.lead"
          disabledDescription
          tabs={[
            {
              icon: 'user',
              name: 'details',
              content: () => <LeadDetailScreen lead={lead} />,
            },
            {
              name: 'notes',
              icon: 'sticky-note',
              content: () => (
                <NotesComponent
                  darkMode
                  source="LEAD"
                  repository={{
                    get: () =>
                      new LeadNoteCrmRepository().findByLead({
                        leadId: properties.leadId,
                        clinicId: properties.clinicId,
                      }),
                    send: (text: string) =>
                      new LeadNoteCrmRepository().create({
                        item: {
                          text,
                          leadId: properties.leadId,
                          clinicId: properties.clinicId,
                        },
                      }),
                    update: (settings: { text: string; noteId: string }) =>
                      new LeadNoteCrmRepository().update({
                        text: settings.text,
                        leadNoteId: settings.noteId,
                        leadId: properties.leadId,
                        clinicId: properties.clinicId,
                      }),
                    delete: (noteId: string) =>
                      new LeadNoteCrmRepository().delete({
                        leadNoteId: noteId,
                        leadId: properties.leadId,
                        clinicId: properties.clinicId,
                      }),
                  }}
                />
              ),
            },
          ]}
        />
      )}
    </View>
  );
}
