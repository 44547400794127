import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ProgramsRoutes } from '../../programs.routes';
import { ProgramCrmRepository } from '@human/crm/repositories/program/program.repository';

export function DisableProgramScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.programs.disable">
      <FormComponent
        prefix="crm.programs.disable.form"
        padding
        repository={{
          send: () => {
            return new ProgramCrmRepository()
              .disable({
                programId: properties.route.params.id,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
