import { NoteCrmType } from '@human/crm/types/note.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class LeadNoteCrmRepository extends AuthorizedRepository {
  async findByLead(properties: {
    leadId: string;
    clinicId: string;
    search?: string;
  }): Promise<NoteCrmType[]> {
    return this.fetch(`leadNote/find`, {
      method: 'GET',
      params: properties,
    }).then((notes) => {
      return notes.map((note: any) => ({
        ...note,
        updated: new Date(note.updated),
        logs: note.leadNoteLogs,
      }));
    });
  }

  async create({
    item,
  }: {
    item: { text: string; leadId: string; clinicId: string };
  }): Promise<void> {
    return this.fetch('leadNote/create', {
      method: 'POST',
      body: item,
    });
  }

  async update(properties: {
    text: string;
    leadNoteId: string;
    leadId: string;
    clinicId: string;
  }): Promise<void> {
    return this.fetch(`leadNote/update`, {
      method: 'PUT',
      body: properties,
    });
  }

  async delete(properties: {
    leadNoteId: string;
    leadId: string;
    clinicId: string;
  }): Promise<void> {
    return this.fetch(`leadNote/delete`, {
      method: 'DELETE',
      params: properties,
    });
  }
}
