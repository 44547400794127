import { TextComponent } from '@components/text/text.component';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { View } from 'react-native';

interface ToolState {
  updateTool: (props?: { content: string; left: number; top: number }) => void;
  tool?: {
    content: string;
    left: number;
    top: number;
  };
}

const ToolContext = createContext<ToolState | undefined>(undefined);

export const ToolProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tool, setTool] = useState<{
    content: string;

    left: number;
    top: number;
  }>();

  const updateTool = (props?: { content: string; left: number; top: number }) => {
    setTool(props);
  };

  return (
    <ToolContext.Provider value={{ updateTool, tool }}>
      {tool?.content ? (
        <View
          style={{
            position: 'absolute',
            zIndex: 1,
            backgroundColor: 'black',
            opacity: 0.8,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 6,
            left: tool.left,
            top: tool.top,
          }}
        >
          <TextComponent text={tool.content} style={{ color: 'white', fontSize: 14 }} />
        </View>
      ) : null}
      {children}
    </ToolContext.Provider>
  );
};

export const useTool = (): ToolState => {
  const context = useContext(ToolContext);
  if (!context) {
    throw new Error('useTool must be used within an ToolProvider');
  }
  return context;
};
