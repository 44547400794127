import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CyclesRoutes } from '../../cycles.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CycleCrmType } from '@human/crm/types/cycle.crm.type';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { t } from 'i18next';

export function CreateCycleScreen(properties: {
  navigation: NavigationProp<CyclesRoutes>;
  route: RouteProp<CyclesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="sync-alt" prefix="crm.cycles.create">
      <FormComponent<CycleCrmType>
        prefix={'crm.cycles.create.form'}
        padding
        repository={{
          send: (data) => {
            return new CycleCrmRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            required: true,
            description: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'complexity',
            description: true,
            required: true,
            options: [
              {
                value: 'HIGH',
                label: t('common.complexity.HIGH'),
              },
              {
                value: 'LOW',
                label: t('common.complexity.LOW'),
              },
            ],
          },
          {
            type: 'text',
            name: 'code',
            required: true,
            description: true,
            validations: ['code'],
          },
          {
            type: 'text',
            name: 'description',
            description: true,
            lines: 6,
            validations: ['description'],
          },
        ]}
      />
    </ModalComponent>
  );
}
