import { OrderTableType } from '@components/table/table.properties';

import { View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { hexToRgba } from '@utils/color/hexToRgba.utility';
import { IconTextColumnImplementation } from './iconText.column.properties';
import { IconTextColumnStyles } from './iconText.column.styles';

export function IconTextColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: IconTextColumnImplementation<ITEM, ORDER>
) {
  const text = properties.text(properties.item);
  const color = properties.color(properties.item);
  const icon = properties.icon(properties.item);

  return (
    <View style={IconTextColumnStyles.container}>
      <IconComponent
        name={icon}
        iconStyle={[IconTextColumnStyles.iconStyle, { color: hexToRgba(color, 0.7) }]}
        containerstyle={[
          IconTextColumnStyles.iconContainer,
          { backgroundColor: hexToRgba(color, 0.2) },
        ]}
      />
      <TextComponent lines={1} bold text={text} style={{}} />
    </View>
  );
}
