import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CommunicationChannelAccessType } from '@human/access/types/communicationChannel.access.type';
import { ActivityCommunicationChannelCrmRepository } from '@human/crm/repositories/activityCommunicationChannel/activityCommunicationChannel.repository';
import { CommunicationChannelAccessRepository } from '@human/access/repositories/communicationChannel/communicationChannel.repository';

export function CreateCommunicationChannelScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'createCommunicationChannel'>;
}) {
  return (
    <ModalComponent
      icon="envelope"
      level={properties.route.params.level}
      prefix="crm.activities.update.communicationChannels.create.form"
    >
      <FormComponent<CommunicationChannelAccessType>
        prefix={'crm.activities.update.communicationChannels.create.form'}
        padding
        repository={{
          send: (settings) => {
            return new ActivityCommunicationChannelCrmRepository()
              .create({
                activityId: properties.route.params.activityId,
                communicationChannelId: settings.item.id ?? '',
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            name: 'id',
            type: 'select',
            required: true,
            repository: ({ search }) => {
              return new CommunicationChannelAccessRepository()
                .list({
                  page: 0,
                  limit: 100,
                  search,
                  active: true,
                })
                .then((response) => {
                  return response.items.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }));
                });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
