import { SecuritysStyles } from './security.styles';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';

/**
 * Security Component
 * @description Componente de seguridad que indica que el sistema se encuentra securizado.
 * Se muestra al final del componente MenuComponent.
 * @returns {JSX.Element}
 */

export const SecurityComponent = () => {
  return (
    <View style={SecuritysStyles.securityContainer}>
      <IconComponent
        name="lock"
        containerstyle={SecuritysStyles.securityIconContainer}
        iconStyle={SecuritysStyles.securityIcon}
      />
      <TextComponent translate text={'security.title'} style={SecuritysStyles.securityText} />
    </View>
  );
};
