import { View, Text, ScrollView, useWindowDimensions } from 'react-native';
import { DepartmentRowComponent } from './components/departmentRow/departmentRow.component';
import { SaleFunnelPerDepartmentTableStyles } from './saleFunnelPerDepartmentTable.styles';
import { leadperdepartmentandmonth } from '../../sale.funnel.screen.web';
import { useTranslation } from 'react-i18next';
import { useDeviceHook } from '@hooks/device/device.hook';

export function SaleFunnelPerDepartmentTable(properties: {
  data: leadperdepartmentandmonth[];
  total: boolean;
}) {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const { phone } = useDeviceHook();

  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  const SaleFunnelPerDepartmentTableColumn = (properties: { text: string }) => {
    return (
      <View
        style={[
          { flex: 2, justifyContent: 'space-around' },
          phone && { marginHorizontal: 10, width: 75 },
        ]}
      >
        <Text
          style={{
            color: '#999',
            fontWeight: '300',
            fontSize: 12,
            textAlign: 'center',
          }}
        >
          {properties.text}
        </Text>
      </View>
    );
  };

  if (properties.data === undefined) return <View />;

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 4 }}>
      <View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 4 }}>
        <View style={SaleFunnelPerDepartmentTableStyles.headerContainer}>
          <View
            style={[
              SaleFunnelPerDepartmentTableStyles.headerSpaceHeader,

              phone && { width: width * 0.22 },
            ]}
          />
          {['Total', ...Object.values(leadEvent)].map((value) => (
            <SaleFunnelPerDepartmentTableColumn text={value} />
          ))}
        </View>
        <View style={SaleFunnelPerDepartmentTableStyles.headerSpacer} />
        <ScrollView
          style={[
            SaleFunnelPerDepartmentTableStyles.scrollContainer,
            {
              maxHeight: height * 0.3,
            },
          ]}
        >
          {properties.data.map((value, iparent) => (
            <DepartmentRowComponent
              last={iparent + 1 === properties.data.length}
              iparent={iparent}
              row={value}
              total={properties.total}
            />
          ))}
        </ScrollView>
      </View>
    </View>
  );
}
