import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function SelectCampaignStep(properties: {
  campaignId?: string;
  onSuccess: (settings: { campaignId: string; campaignName: string }) => void;
}) {
  return (
    <FieldContainer
      prefix="crm.transferLead.confirm.form"
      type="select"
      required
      padding
      name="campaignId"
      description
    >
      <SelectInput<{ campaignId: string }>
        type={'select'}
        name={'campaignId'}
        description={true}
        required={true}
        repository={(settings) =>
          new CampaignCrmRepository()
            .list({
              direction: 'asc',
              page: 0,
              search: settings.search,
              limit: 1000,
              active: true,
            })
            .then((data) => {
              return data.items.map((item) => ({
                value: item.id,
                label: item.name,
              }));
            })
        }
        onChange={(value, option) => {
          if (!value.campaignId || !option) return;
          properties.onSuccess({ campaignId: value.campaignId, campaignName: option.label });
        }}
        value={{
          campaignId: properties.campaignId || undefined,
        }}
      />
    </FieldContainer>
  );
}
