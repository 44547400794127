import { Text, View, useWindowDimensions } from 'react-native';
import { Line } from 'react-chartjs-2';
import { LinearChartSalesByDepartmentProperties } from './linearChartSalesByDepartment.properties';
import { ItemByDepartmentYearsComparativeType } from '../../../../types/itemByDepartmentYearsComparative.type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@components/text/text.component';
import { TwoYearColumnLeadsComparativeStyles } from '../../../leads/components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative.styles';
import { BoardNoDataComponent } from '../../../../components/noData/boardNoData.component';

export function LinearChartSalesByDepartment(properties: {
  data: ItemByDepartmentYearsComparativeType[];
  firstYear: number;
  secondYear: number;
  departmentName: string;
  selectedDepartment: string;
}) {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: any;
      borderColor: string;
      borderWidth: number;
    }[];
  }>({ labels: [], datasets: [] });

  const createGradient = (canvas: HTMLCanvasElement, firstColor: string, secondColor: string) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, firstColor); // Color inicial
    gradient.addColorStop(1, secondColor); // Color final
    return gradient;
  };

  useEffect(() => {
    if (
      properties.data.length === 0 ||
      !properties.firstYear ||
      !properties.secondYear ||
      !properties.departmentName
    )
      return;

    const months = Array.from({ length: 12 }, (_, i) => t(`access.users.list.month.${i}`)); // Etiquetas de los meses

    const firstYearData = new Array(12).fill(0); // Datos del primer año
    const secondYearData = new Array(12).fill(0); // Datos del segundo año

    const filteredData = properties.data.filter(
      (item) => item.department_name === properties.departmentName
    );

    // Llenamos los arrays con los leads de acuerdo al mes y año
    filteredData.forEach((item) => {
      const monthIndex = item.month - 1; // Meses empiezan en 0 (enero = 0, febrero = 1, etc.)
      if (item.year === properties.firstYear) {
        firstYearData[monthIndex] += item.total; // Sumamos los leads del primer año
      } else if (item.year === properties.secondYear) {
        secondYearData[monthIndex] += item.total; // Sumamos los leads del segundo año
      }
    });

    const datasets = [
      {
        label: properties.firstYear.toString(),
        data: firstYearData,
        backgroundColor: (ctx: any) =>
          createGradient(ctx.chart.canvas, '#283038', 'rgba(54, 54, 54, 0.1)'),
        borderWidth: 2,
        fill: true,
        borderColor: '#283038',
        pointBackgroundColor: '#283038',
      },
      {
        label: properties.secondYear.toString(),
        data: secondYearData,
        fill: true,
        backgroundColor: (ctx: any) =>
          createGradient(ctx.chart.canvas, 'rgba(253, 219, 235, 2)', 'rgba(253, 219, 235, 0.1)'),
        borderColor: '#FEDBEB', // Rosa
        pointBackgroundColor: '#FEDBEB',
        borderWidth: 2,
      },
    ];

    setChartData({ labels: months, datasets });
  }, [properties.data, properties.selectedDepartment]);

  if (!chartData || !chartData.labels.length) {
    return <BoardNoDataComponent />;
  }

  return (
    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 4, height: height * 0.3 }}>
      <Line
        data={chartData}
        options={{
          responsive: true,
          aspectRatio: 5,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          elements: {
            line: {
              borderWidth: 4, // Grosor de la línea
            },
            point: {
              radius: 6, // Tamaño de los puntos
              hoverRadius: 8, // Tamaño de los puntos al hacer hover
            },
          },
        }}
      />
    </View>
  );
}
