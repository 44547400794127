import { StyleSheet } from 'react-native';

/**
 * Header Styles
 * @constant {StyleSheet} HeaderStyles
 */
export const HeaderStyles = StyleSheet.create({
  headerContainer: {
    borderBottomColor: 'rgba(0, 0, 0, 0.18)',
    borderBottomWidth: 1,
    backgroundColor: 'rgb(255, 255, 255)',
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 263.84,
  },
  headerIconContainer: {
    height: 50,
    alignContent: 'center',
    justifyContent: 'center',
    width: 50,
    borderRadius: 40,
    backgroundColor: 'rgb(237, 53, 145)',
  },
  headerIconStyle: {
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
    fontSize: 24,
  },
  haeaderTextsContainer: {
    flex: 1,
  },
  title: {
    color: 'rgb(64, 17, 40)',
    fontSize: 19,
  },
  subtitle: {
    color: 'dimgray',
    opacity: 0.5,
    fontSize: 15,
  },
  timesContainer: {
    height: 40,
    marginLeft: 'auto',
    marginRight: 10,
    alignContent: 'center',
    justifyContent: 'center',
    width: 40,
    borderRadius: 40,
    backgroundColor: 'rgb(241, 241, 241)',
  },
  timeIcon: {
    color: 'rgb(141, 141, 141)',
    fontSize: 24,
    textAlign: 'center',
  },
});
