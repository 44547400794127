// Constants
import { Text } from 'react-native';

// Constants
import { IconsConstant as IconNames } from './icon.constants';

// Styles
import { IconStyles } from './icon.styles';

// Properties
import { type RawIconProperties } from './icon.properties';

/**
 * Raw Icon Component
 * @description Use icon name to find the unicode and render the correct icon as text
 * @function RawIconComponent
 * @param {RawIconProperties} properties
 * @return {JSX.Element}
 */
export function RawIconComponent(properties: RawIconProperties): JSX.Element {
  // Get icon unicode from IconNames dictionary.
  const unicode = IconNames[properties.name] as any;
  const icon = String.fromCharCode(parseInt(unicode, 16));

  return <Text style={[IconStyles.rawIcon, properties.iconStyle]}>{icon}</Text>;
}
