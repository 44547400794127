import { CSSProperties } from 'react';
import './styles.css';

export default function LoaderComponentWeb(properties: {
  loading: boolean;
  style?: CSSProperties;
}) {
  if (!properties.loading) return null;

  return (
    <span className="loading">
      {Array.from({ length: 3 }).map((_, index) => (
        <span key={index} className="loading-dots" style={properties.style}></span>
      ))}
    </span>
  );
}
