import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TimetableRoutes } from '../../../timetables.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { TimetableDayAccessType } from '../../../../../types/timetableDay.access.type';
import { TimetableDayAccessRepository } from '@human/access/repositories/timetableDay/timetableDay.repository';
import { useTranslation } from 'react-i18next';
import { FormComponent } from '@components/form/form.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateTimetableDayScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'timetableDayUpdate'>;
}) {
  const { t } = useTranslation();
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="access.timetables.update.timetableDays.update"
    >
      <FormComponent<TimetableDayAccessType>
        prefix="access.timetables.update.timetableDays.update.form"
        padding
        readonly={!hasPermissionUtilty({ name: 'access.timetables.editor' })}
        repository={{
          send: (settings) => {
            return new TimetableDayAccessRepository()
              .update({
                item: {
                  ...settings.item,
                  timetableId: properties.route.params.timetableId,
                  id: properties.route.params.timetableDayId,
                },
              })
              .then(() => {
                showSuccess();
              });
          },
          get: () => {
            return new TimetableDayAccessRepository().pick({
              timetableDayId: properties.route.params.timetableDayId,
              timetableId: properties.route.params.timetableId,
            });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'day',
            required: true,
            description: true,
            options: Array.from({ length: 7 }, (_, i) => ({
              label: t(`common.time.weekDays.${i}`),
              value: i,
            })),
          },
          {
            type: 'text',
            name: 'started',
            required: true,
            description: true,
            validations: ['hour'],
          },
          {
            type: 'text',
            name: 'ended',
            required: true,
            description: true,
            validations: ['hour'],
          },
        ]}
      />
    </ModalComponent>
  );
}
