// Modules
import { CSSProperties } from 'react';
import { StyleSheet } from 'react-native';

/**
 * PerformanceStyles Styles
 * @constant {StyleSheet} PerformanceStyles
 */
export const PerformanceStyles = StyleSheet.create({
  performanceContainer: {
    backgroundColor: 'rgb(17, 27, 34)',
    position: 'relative',
    height: '100%',
    borderLeftWidth: 1,
    borderLeftColor: 'rgba(255, 255, 255, 0.06)',
  },
  statusButton: {
    height: 35,
    width: 35,
    borderRadius: 100,
    backgroundColor: 'rgb(237, 53, 145)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 50,
    left: -19,
    zIndex: 12,
  },
  statusButtonIcon: {
    color: 'rgb(255, 255, 255)',
    fontSize: 20,
  },
  container: {
    width: 400,
    overflow: 'hidden',
    position: 'absolute',
    maxHeight: '100%',
    flex: 1,
  },
});

export const PerformanceWebStyle = (status: boolean): CSSProperties => ({
  backgroundColor: 'rgb(17, 27, 34)',
  width: status ? 400 : 50,
  position: 'relative',
  height: '100%',
  borderLeftWidth: 1,
  borderLeftColor: 'rgba(255, 255, 255, 0.06)',
});
