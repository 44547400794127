// Modules
import { StyleSheet } from 'react-native';

/**
 * JsonInput Styles
 * @constant {StyleSheet} ClockStyles
 */
export const JsonInputStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    // height: 42,
    // maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  readonly: {
    opacity: 0.4,
  },
  iconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,

    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  iconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  input: {
    borderRadius: 5,
    backgroundColor: 'white',
    // height: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
  },
  inputIcon: {
    paddingLeft: 50,
  },
  inputNoIcon: {
    paddingLeft: 10,
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
});
