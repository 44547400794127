// Modules
import { CSSProperties } from 'react';
import { StyleSheet } from 'react-native';

/**
 * CardWeb Styles
 * @constant {StyleSheet} CardWebStyles
 */
export const CardStyles = StyleSheet.create({
  iconContainer: {
    height: 70,
    width: 70,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 7,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
  },
  iconContainerLaptop: {
    height: 50,
    width: 50,
  },
  icon: {
    color: 'rgb(237, 53, 145)',
    fontSize: 40,
  },
  iconLaptop: {
    fontSize: 30,
  },
  headerContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainerLaptop: {
    marginBottom: 10,
  },
  titleOVO: {
    color: 'rgb(237, 53, 145)',
    fontSize: 20,
    marginLeft: 20,
    fontWeight: 'bold',
  },
  titleOVOlaptop: {
    fontSize: 16,
    marginLeft: 16,
  },
  titleBold: {
    color: 'white',
    fontSize: 20,
    textTransform: 'uppercase',
  },
  titleBoldLaptop: {
    fontSize: 16,
  },
  agoContainer: {
    marginLeft: 10,
  },
  agoText: {
    color: '#fff',
    fontSize: 8,
    lineHeight: 8,
    flexWrap: 'nowrap',
    textTransform: 'uppercase',
  },
  agoArrow: {
    height: 10,
    zIndex: -1,
    width: 10,
    transform: 'rotate(45deg)',
    backgroundColor: '#000',
    position: 'absolute',
    bottom: -3,
  },
  version: {
    color: 'white',
    fontSize: 14,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 5,
    padding: 3,
    paddingHorizontal: 7,
    textTransform: 'uppercase',
  },
  description: {
    color: 'white',
    marginLeft: 20,
    marginRight: 20,
    opacity: 0.9,
    lineHeight: 22,
  },
  descriptionSmall: {
    fontSize: 14,
    lineHeight: 14,
  },
  arrowIconContainer: {
    marginLeft: 20,
    height: 60,
    width: 60,
    marginTop: 'auto',
    alignSelf: 'flex-end',
    marginRight: 30,
    marginBottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 30,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIconContainerSmall: {
    height: 40,
    width: 40,
  },
  arrowIcon: {
    color: 'white',
    fontSize: 30,
    textAlign: 'center',
  },
  arrowIconSmall: {
    margin: 6,
    fontSize: 20,
  },
  keywordsContainer: {
    flexDirection: 'row',
    margin: 20,
    marginTop: 'auto',
    gap: 5,
  },
  keywordBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 25,
    padding: 10,
    paddingVertical: 5,
  },
  keywordText: {
    color: 'white',
    fontSize: 9,
    textTransform: 'uppercase',
  },
  keywordLittleText: {
    fontSize: 7,
  },
});

export const CardWebStyle = (index: number): CSSProperties => ({
  height: 400,
  width: 250,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: `hue-rotate(${-25 * index}deg) blur(10px)`,
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)',
  flexDirection: 'column',
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))`,
  display: 'flex',
  cursor: 'pointer',
});

export const CardWebTabletStyle: CSSProperties = {
  height: 340,
  width: 220,
};

export const CardWebLaptopStyle: CSSProperties = {
  width: 180,
  height: 300,
};

export const CardAgoBoxStyle: CSSProperties = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: '#000',
  borderRadius: 3,
  padding: 5,
  paddingLeft: 7,
  paddingRight: 7,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.7,
  display: 'flex',
  flexDirection: 'column',
  top: -25,
};
