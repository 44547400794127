import { useTranslation } from 'react-i18next';
import { useToast } from 'react-native-toast-notifications';

export function useSuccessToast() {
  const toast = useToast();
  const { t } = useTranslation();

  const showSuccess = (label?: string) => {
    const auxLang = label !== undefined ? label : 'form.update.successToast';
    toast.show(t(auxLang) || 'Success', {
      placement: 'top',
      type: 'success',
      duration: 2000,
    });
  };

  return { showSuccess };
}
