// Modules
import { StyleSheet } from 'react-native';

/**
 * Color Styles
 * @constant {StyleSheet} ColorStyles
 */
export const ColorInputStyles = StyleSheet.create({
  colorContainer: {
    width: 30,
    height: 30,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  options: {
    flex: 1,
    margin: 'auto',
    backgroundColor: 'white',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 20,
    borderRadius: 5,
    gap: 5,
  },
  readonly: {
    opacity: 0.4,
  },
  optionItem: {
    width: 47,
    aspectRatio: 1,
    padding: 10,
    borderRadius: 5,
    transform: [{ scale: 0.9 }],
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionItemActive: {
    borderWidth: 4,
    borderColor: 'rgb(237, 53, 145)',
  },
  lastOption: {
    borderBottomWidth: 0,
  },
  icon: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    alignContent: 'center',
    justifyContent: 'center',
  },
  iconBox: {
    width: 30,
    height: 30,
    borderRadius: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
