import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TechniqueRoutes } from '../../technique.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { FormComponent } from '@components/form/form.component';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateTechniqueScreen(properties: {
  navigation: NavigationProp<TechniqueRoutes>;
  route: RouteProp<TechniqueRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent width={620} icon={CrmSections.techniques.icon} prefix="crm.techniques.update">
      <FormComponent<Partial<TechniqueCrmType>>
        padding
        readonly={!hasPermissionUtilty({ name: 'crm.techniques.editor' })}
        prefix="crm.techniques.update.form"
        repository={{
          send: (data) => {
            return new TechniqueCrmRepository()
              .update({
                item: data.item,
              })
              .then(() => {
                showSuccess();
              });
          },
          get: () => {
            return new TechniqueCrmRepository().pick({
              id: properties.route.params.techniqueId,
            });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            required: true,
            validations: ['name'],
            description: true,
          },
          {
            type: 'text',
            name: 'description',
            required: true,
            validations: ['description'],
            description: true,
            lines: 6,
          },
          {
            name: 'price',
            type: 'float',
            required: true,
            validations: ['price'],
          },
        ]}
      />
    </ModalComponent>
  );
}
