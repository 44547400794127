import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TemplateScopesRoutes } from '../../templateScopes.routes';
import { FormComponent } from '@components/form/form.component';
import { TemplateScopeAccessRepository } from '@human/access/repositories/template/templateScope/templateScope.repository';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { ModalComponent } from '@components/modal/modal.component';
import { useState } from 'react';
import { SelectClinicStep } from '@steps/clinic/selectClinic.step';
import { ActionComponent } from '@components/action/action.component';

export function CreateTemplateScopeScreen(properties: {
  navigation: NavigationProp<TemplateScopesRoutes>;
  route: RouteProp<TemplateScopesRoutes, 'createTemplateScope'>;
}) {
  const [clinicId, setClinicId] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();

  return (
    <ModalComponent
      icon="file-alt"
      prefix="access.templates.templateScopes.create"
      level={properties.route.params.level}
    >
      {!clinicId ? (
        <SelectClinicStep
          clinicId={clinicId}
          onSuccess={({ clinicId, clinicName }) => {
            setClinicId(clinicId);
            setClinicName(clinicName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setClinicId(undefined);
          }}
          prefix="crm.leads.update.actions.create.steps.clinic.form"
          bubble={clinicName}
        />
      )}
      {clinicId && (
        <FormComponent<{ departmentId: string }>
          prefix={'access.templates.templateScopes.create.form'}
          padding
          repository={{
            send: (data) => {
              return new TemplateScopeAccessRepository()
                .create({
                  item: {
                    departmentId: data.item.departmentId,
                    templateId: properties.route.params.templateId,
                    clinicId,
                  },
                })
                .then(properties.navigation.goBack);
            },
          }}
          fields={[
            {
              name: 'departmentId',
              type: 'select',
              description: true,
              required: true,
              repository: ({ search }) => {
                return new DepartmentAccessRepository()
                  .listByClinic({
                    clinicId,
                    direction: 'asc',
                    page: 0,
                    limit: 100,
                    search,
                    active: true,
                  })
                  .then((response) => {
                    return response.items.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }));
                  });
              },
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
