import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { RolesRoutes } from '../../roles.routes';
import { RoleAccessRepository } from '@human/access/repositories/role/role.repository';

export function EnableRoleScreen(properties: {
  navigation: NavigationProp<RolesRoutes>;
  route: RouteProp<RolesRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.roles.enable">
      <FormComponent
        prefix="access.roles.enable.form"
        padding
        repository={{
          send: () => {
            return new RoleAccessRepository()
              .enable({
                roleId: properties.route.params.roleId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
