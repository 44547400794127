import { View, Text, useWindowDimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SaleFunnelPerDepartmentTableStyles } from '../../../funnels/components/saleFunnelPerDepartmentTable/saleFunnelPerDepartmentTable.styles';
import { TotalLeadsStateDepartmentsTableRow } from './row/totalLeadsStateDepartmentsTable.row';
import { useDeviceHook } from '@hooks/device/device.hook';

export function TotalLeadsStateDepartmentsTable(properties: {
  data: {
    department_id: string;
    department_name: string;
    total_leads: number;
    lead_appointed_count: number;
    lead_percentage_appointed: number;
    lead_active_count: number;
    lead_inactive_count: number;
  }[];
}) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();
  const { height } = useWindowDimensions();

  const leadEvent: { [key: string]: string } = {
    total: t('board.sections.marketing.totalLeadsPerState.total'),
    appointed: t('board.sections.marketing.totalLeadsPerState.appointed'),
    percentage: t('board.sections.marketing.totalLeadsPerState.percentage'),
    actives: t('board.sections.marketing.totalLeadsPerState.actives'),
    inactives: t('board.sections.marketing.totalLeadsPerState.inactives'),
  };

  const SaleFunnelPerDepartmentTableColumn = (properties: { text: string }) => {
    return (
      <View
        style={phone ? { width: 75, alignItems: 'center' } : { flex: 1, justifyContent: 'center' }}
      >
        <Text style={{ color: '#999', fontWeight: '300', fontSize: 12 }}>{properties.text}</Text>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: 'white',
        padding: 15,
        paddingBottom: 0,
        borderRadius: 4,
        minHeight: height * 0.3,
      }}
    >
      <View style={{ flexDirection: 'column', backgroundColor: 'white', borderRadius: 4 }}>
        <View style={[SaleFunnelPerDepartmentTableStyles.headerContainer]}>
          <View style={phone ? { width: 100 } : { flex: 2 }} />
          {Object.values(leadEvent).map((value) => (
            <SaleFunnelPerDepartmentTableColumn text={value} />
          ))}
        </View>
        <View style={SaleFunnelPerDepartmentTableStyles.headerSpacer} />
        {properties.data.map((value, iparent) => (
          <TotalLeadsStateDepartmentsTableRow
            last={iparent + 1 === properties.data.length}
            iparent={iparent}
            row={value}
          />
        ))}
      </View>
    </View>
  );
}
