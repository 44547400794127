import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ParticipantCrmType } from '@human/crm/types/participant.crm.type';

export class ParticipantRepository extends AuthorizedRepository {
  async list(properties: {
    campaignId: string;
  }): Promise<{ items: ParticipantCrmType[]; total: number }> {
    return this.fetch('participant/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          clinicId: item.clinicId,
          clinicName: item.clinicName,
        })),
        total: data.total,
      };
    });
  }
  async create(
    properties: Partial<{
      campaignId: string;
      clinicId: string;
    }>
  ): Promise<ParticipantCrmType> {
    return this.fetch('participant/create', {
      method: 'POST',
      body: {
        campaignId: properties.campaignId,
        clinicId: properties.clinicId,
      },
    });
  }
  async createAll(properties: { campaignId: string }): Promise<void> {
    return this.fetch('participant/create/all', {
      method: 'POST',
      body: {
        campaignId: properties.campaignId,
      },
    });
  }

  async delete(properties: { campaignId: string; clinicId: string }): Promise<void> {
    return this.fetch('participant/delete', {
      method: 'DELETE',
      params: properties,
    });
  }
}
