import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { AccordionStyles } from './accordion.styles';
import { AccordionProperties } from './accordion.properties';

export function AccordionComponent(properties: AccordionProperties) {
  const [status, setStatus] = useState(false);

  return (
    <View
      style={[AccordionStyles.container, properties.isChild && { borderRadius: 0, borderWidth: 0 }]}
    >
      <TouchableOpacity
        onPress={() => setStatus(!status)}
        style={[AccordionStyles.title, properties.isChild && AccordionStyles.paddingTitle]}
      >
        {properties.icon && (
          <IconComponent
            name={properties.icon}
            containerstyle={AccordionStyles.titleIconContainer}
            iconStyle={AccordionStyles.titleIconStyle}
          />
        )}

        <TextComponent translate text={properties.title} style={AccordionStyles.titleText} />
        <IconComponent
          name={status ? 'angle-up' : 'angle-down'}
          containerstyle={AccordionStyles.titleAngleIconContainer}
          iconStyle={AccordionStyles.titleAngleIconStyle}
        />
      </TouchableOpacity>
      {status && (
        <View style={{ flexDirection: 'column', gap: 20 }}>
          {properties.description && (
            <TextComponent
              translate
              text={properties.description}
              style={[AccordionStyles.description, properties.icon && { paddingLeft: 48 }]}
            />
          )}
          {properties.children}
        </View>
      )}
    </View>
  );
}
