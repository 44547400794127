import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TemplatesRoutes } from '../../templates.routes';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function EnableTemplateScreen(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.templates.enable">
      <FormComponent
        prefix="access.templates.enable.form"
        padding
        repository={{
          send: () => {
            return new TemplateAccessRepository()
              .enable({
                templateId: properties.route.params.templateId,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
