// Modules
import { StyleSheet } from 'react-native';

/**
 * Modal Styles
 * @constant {StyleSheet} ModalStyles
 */
export const ModalStyles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'grayscale(1)',
  },
  container: {
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    position: 'absolute',
    overflow: 'hidden',
    bottom: 0,
    backgroundColor: 'white',
  },
  headerContainer: {
    borderBottomColor: 'rgba(0, 0, 0, 0.18)',
    borderBottomWidth: 1,
    backgroundColor: 'rgb(255, 255, 255)',
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 263.84,
  },
  headerIcon: {
    color: 'rgb(255, 255, 255)',
    fontSize: 24,
    height: 50,
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // outlineStyle: '50px solid rgba(237, 53, 145, 0.18)',
    width: 50,
    borderRadius: 40,
    backgroundColor: 'rgb(237, 53, 145)',
  },
  haeaderTextsContainer: {
    flex: 1,
  },
  title: {
    color: 'rgb(64, 17, 40)',
    fontSize: 19,
  },
  subtitle: {
    color: 'dimgray',
    opacity: 0.5,
    fontSize: 15,
  },
  timesContainer: {
    height: 40,
    marginLeft: 'auto',
    marginRight: 10,
    alignContent: 'center',
    justifyContent: 'center',
    width: 40,
    borderRadius: 40,
    backgroundColor: 'rgb(241, 241, 241)',
  },
  timeIcon: {
    color: 'rgb(141, 141, 141)',
    fontSize: 24,
    textAlign: 'center',
  },
  content: {
    flex: 1,
    backgroundColor: '#eee',
  },
  flexedContent: {
    gap: 20,
    padding: 0,
  },
});
