import { StyleSheet } from 'react-native';

const width = 250;

export const TotalDataStyles = StyleSheet.create({
  totalContainer: {
    marginLeft: 20,
  },
  totalHeaderContainer: {
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 15,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    height: 100,
    width,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  columnSubtitle: {
    textAlign: 'center',
    color: '#999',
    fontWeight: '300',
    fontSize: 12,
  },
  totalHeaderSubtitleContainer: {
    flexDirection: 'row',
    gap: 10,
  },
  rowContainer: {
    flexDirection: 'row',
    padding: 10,
    width,
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  tableDataText: {
    fontWeight: '300',
    fontSize: 12,
  },
  total: {
    backgroundColor: '#EDEDED',
  },
});
