// Modules
import { StyleSheet } from 'react-native';

/**
 * Security Styles
 * @constant {StyleSheet} SecuritysStyles
 */
export const SecuritysStyles = StyleSheet.create({
  securityContainer: {
    flexDirection: 'row',
    backgroundColor: 'rgba(7, 12, 16, 0.63)',
    alignItems: 'center',
  },
  securityIconContainer: {
    height: 50,
    borderRadius: 25,
    width: 50,
    zIndex: 1,
  },
  securityIcon: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 50,
    textAlign: 'center',
  },
  securityText: {
    color: 'rgb(255, 255, 255)',
    fontSize: 15,
    lineHeight: 18,
  },
});
