import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function TreatmentUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const columns: ColumnProperties<TreatmentCrmType, []>[] = [
    {
      type: 'icon',
      icon: 'pen',
      onPress: (row) => {
        properties.navigation.navigate('updateTreatment', {
          level: 1,
          clinicId: properties.route.params.clinicId,
          treatmentId: row.id,
          patientId: properties.route.params.patientId,
          programId: row.programId,
        });
      },
    },
    {
      type: 'text',
      name: 'programName',
      onRender: (item) => item.programName,
      width: 200,
    },
    {
      type: 'date',
      name: 'finished',
      renderDate: (item) => item.finished,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.treatments.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disableTreatment' : 'enableTreatment', {
          clinicId: properties.route.params.clinicId,
          patientId: properties.route.params.patientId,
          treatmentId: row.id,
          level: 1,
        });
      },
    });
  }

  return {
    name: 'treatment',
    icon: 'stethoscope',
    content: () => (
      <>
        <TableComponent<TreatmentCrmType, []>
          scrollable
          prefix="crm.patients.update.tabs.treatment"
          suffix="list"
          repository={(setting) =>
            new TreatmentCrmRepository().listByPatient({
              order: 'created',
              direction: setting.direction,
              page: setting.page,
              limit: setting.limit,
              search: setting.search,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
            })
          }
          columns={columns}
        />
        {hasPermissionUtilty({
          name: 'crm.treatments.creator',
        }) && (
          <ButtonComponent
            prefix="crm.patients.update.tabs.treatment.submit"
            onPress={() => {
              properties.navigation.navigate('createTreatment', {
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                level: 1,
              });
            }}
          />
        )}
      </>
    ),
  };
}
