import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { DepartmentAccessType } from '@human/access/types/department.access.type';

export class DepartmentAccessRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
  }): Promise<{
    items: DepartmentAccessType[];
    total: number;
  }> {
    return this.fetch(`department/find`, {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((department: any) => ({
          id: department.id,
          name: department.name,
          responsibleId: department.responsibleId,
          responsibleName: department.responsibleName,
          responsibleSurname: department.responsibleSurname,
          languageId: department.languageId,
          languageName: department.languageName,
          languageCountryId: department.languageCountryId,
          languageCountryName: department.languageCountryName,
          clinicId: department.clinicId,
          clinicName: department.clinicName,
          status: department.status,
        })),
        total: data.total,
      };
    });
  }

  async listByClinic(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    clinicId: string;
    search?: string;
    active?: boolean;
  }): Promise<{ items: DepartmentAccessType[]; total: number }> {
    return this.fetch(`department/find/clinic`, {
      method: 'GET',
      params: properties,
    }).then((departments) => {
      return {
        items: departments.items.map((department: any) => ({
          id: department.id,
          name: department.name,
          responsibleId: department.responsibleId,
          responsibleName: department.responsibleName,
          responsibleSurname: department.responsibleSurname,
          languageId: department.languageId,
          languageName: department.languageName,
          languageCountryId: department.languageCountryId,
          languageCountryName: department.languageCountryName,
          clinicId: department.clinicId,
          clinicName: department.clinicName,
          status: department.status,
        })),
        total: departments.total,
      };
    });
  }

  async pick(properties: { id: string; clinicId: string }): Promise<Partial<DepartmentAccessType>> {
    return this.fetch(`department/pick`, {
      method: 'GET',
      params: {
        id: properties.id,
        clinicId: properties.clinicId,
      },
    });
  }

  async create(properties: { item: Partial<DepartmentAccessType> }): Promise<{ id: string }> {
    return this.fetch('department/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async update(properties: { item: Partial<DepartmentAccessType> }) {
    return this.fetch(`department/update?id=${properties.item.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }

  async delete(properties: { id: string }): Promise<void> {
    return this.fetch(`department/remove/${properties.id}`, {
      method: 'DELETE',
    }).then(() => {});
  }

  async disable(properties: { departmentId: string; clinicId: string }): Promise<void> {
    return this.fetch('department/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { departmentId: string; clinicId: string }): Promise<void> {
    return this.fetch('department/enable', {
      method: 'PUT',
      params: properties,
    });
  }
}
