import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { NavigationProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../../contacts.routes';
import { useTranslation } from 'react-i18next';

export function ContactForm(properties: { navigation: NavigationProp<ContactsRoutes> }) {
  const { t } = useTranslation();
  return (
    <FormComponent<{
      name: string;
      surname: string;
      email: string;
      prefix: string;
      phone: string;
      zip: string;
      clinicId: string;
      legal: boolean;
      marketing: boolean;
      contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
      campaignId: string;
    }>
      prefix="crm.contacts.create.form"
      padding
      repository={{
        send: (data) => {
          return new ContactCrmRepository()
            .createFromCampaign({
              item: data.item,
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
        get: async () => ({
          legal: false,
          marketing: false,
        }),
      }}
      fields={[
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'name',
              required: true,
              validations: ['name'],
            },
            {
              type: 'text',
              name: 'surname',
              required: true,
              validations: ['surname'],
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'prefix',
              validations: ['prefix'],
              required: true,
            },
            {
              type: 'text',
              name: 'phone',
              validations: ['phone'],
              required: true,
            },
          ],
        },
        {
          type: 'text',
          name: 'email',
          validations: ['email'],
          required: true,
        },
        {
          type: 'text',
          name: 'zip',
          validations: ['zip'],
        },
        {
          type: 'checkbox',
          name: 'marketing',
          description: true,
        },
        {
          type: 'checkbox',
          description: true,
          name: 'legal',
        },
        {
          type: 'select',
          name: 'contactTime',
          description: true,
          options: [
            {
              value: 'MORNING',
              label: t('common.contactTime.MORNING'),
            },
            {
              value: 'AFTERNOON',
              label: t('common.contactTime.AFTERNOON'),
            },
            {
              value: 'NO_PREFERENCE',
              label: t('common.contactTime.NO_PREFERENCE'),
            },
          ],
        },
        {
          type: 'select',
          description: true,
          name: 'clinicId',
          pick: (properties: { id: string }) => {
            return new ClinicAccessRepository().pick({ id: properties.id }).then((clinic) => {
              return `${clinic.name}`;
            });
          },
          repository: ({ search }) => {
            return new ClinicAccessRepository()
              .list({ search, limit: 100, active: true })
              .then((clinics) =>
                clinics.items.map((clinic) => ({
                  value: clinic.id,
                  label: clinic.name,
                }))
              );
          },
        },
        {
          type: 'select',
          description: true,
          name: 'campaignId',
          repository: ({ search }) => {
            return new CampaignCrmRepository()
              .list({
                direction: 'asc',
                limit: 100,
                page: 0,
                active: true,
                search,
              })
              .then((campaigns) =>
                campaigns.items.map((campaign) => ({
                  value: campaign.id,
                  label: campaign.name,
                }))
              );
          },
        },
      ]}
    />
  );
}
