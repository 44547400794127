import { useUserState } from '@states/user/user.state';
import { Repository } from './repository.core';

export class AuthorizedRepository extends Repository {
  accessToken: string;
  constructor() {
    super();
    const accessToken = useUserState.getState().user.accessToken;
    this.accessToken = accessToken;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    this.headers = headers;
  }
}
