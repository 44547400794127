export const backgroundColors = [
  'rgba(255, 0, 0, 0.5)', // Rojo
  'rgba(0, 255, 0, 0.5)', // Verde
  'rgba(0, 0, 255, 0.5)', // Azul
  'rgba(255, 165, 0, 0.5)', // Naranja
  'rgba(128, 0, 128, 0.5)', // Púrpura

  'rgba(255, 20, 147, 0.5)', // Rosa fuerte
  'rgba(0, 128, 128, 0.6)', // Teal oscuro
  'rgba(218, 165, 32, 0.5)', // Dorado
  'rgba(0, 191, 255, 0.6)', // Azul cielo profundo
  'rgba(128, 128, 0, 0.5)', // Verde oliva

  'rgba(255, 69, 0, 0.5)', // Rojo anaranjado
  'rgba(0, 255, 127, 0.6)', // Verde esmeralda
  'rgba(138, 43, 226, 0.5)', // Azul violeta
  'rgba(255, 215, 0, 0.5)', // Amarillo oro
  'rgba(75, 0, 130, 0.6)', // Índigo

  'rgba(255, 99, 71, 0.5)', // Tomate
  'rgba(46, 139, 87, 0.5)', // Verde mar
  'rgba(123, 104, 238, 0.5)', // Azul medio pizarra
  'rgba(210, 105, 30, 0.5)', // Chocolate
  'rgba(220, 20, 60, 0.5)', // Carmesí

  'rgba(0, 255, 255, 0.5)', // Cian
  'rgba(255, 140, 0, 0.6)', // Naranja oscuro
  'rgba(34, 139, 34, 0.5)', // Verde bosque
  'rgba(255, 0, 255, 0.5)', // Magenta
  'rgba(70, 130, 180, 0.5)', // Azul acero

  'rgba(128, 0, 0, 0.5)', // Rojo oscuro
  'rgba(255, 228, 196, 0.5)', // Beige
  'rgba(0, 100, 0, 0.6)', // Verde oscuro
  'rgba(176, 196, 222, 0.5)', // Azul claro
  'rgba(139, 69, 19, 0.6)', // Café oscuro
];

export const graphicsColors = [
  '#F8DDEA',
  '#F1BBD5',
  '#e788b6',
  '#da478f',
  '#cfd6dd',
  '#A0ADBB',
  '#708499',
  '#4c5b6b',
  '#283038',
];

export const randomColor = (): string =>
  backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
