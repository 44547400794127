import { Dimensions, View } from 'react-native';
import { LeadEligibleChartProperties } from './leadEligibleChart.properties';
import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { LeadEligibleComparativeType } from '../leadEligibleComparativeChart/leadEligibleComparative.type';
import { LeadDetailBoardOvobankRepository } from '../../../../repositories/leadDetail/leadDetail.ovobank.repository';

const { height } = Dimensions.get('window');

export function LeadEligibleChartComponent(properties: LeadEligibleChartProperties) {
  const [data, setData] = useState<LeadEligibleComparativeType[]>([]);

  useEffect(() => {
    if (properties.month) {
      new LeadDetailBoardOvobankRepository()
        .leadsEligibleByYarAndMonth({
          clinicId: properties.clinicId,
          year: properties.year,
          month: properties.month,
          funnelId: properties.funnelId,
        })
        .then((response) => {
          setData(response);
        });
    } else {
      new LeadDetailBoardOvobankRepository()
        .leadsEligibleByYear({
          clinicId: properties.clinicId,
          year: properties.year,
          funnelId: properties.funnelId,
        })
        .then((response) => {
          setData(response);
        });
    }
  }, [properties]);

  const eligible = data.reduce((acc, item) => acc + item.lead_eligible_count, 0);

  const noEligible = data.reduce((acc, item) => acc + item.lead_no_eligible_count, 0);

  const dataset = {
    labels: ['Apto', 'No apto'],
    datasets: [
      {
        data: [eligible, noEligible],
        backgroundColor: ['#D33E3D', '#273038'],
        borderColor: ['#D33E3D', '#273038'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 4, padding: 20, height: height * 0.4 }}>
      <Pie
        data={dataset}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                color: '#283038',
              },
            },
            tooltip: {
              enabled: dataset.labels && dataset.labels.length > 0,
              callbacks: {
                label: (tooltipItem: any) => `${tooltipItem.label}: ${tooltipItem.raw} leads`,
              },
            },
          },
        }}
      />
    </View>
  );
}
