import { Pressable, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { IconInputImplementation } from './icon.implementation';
import { useState } from 'react';
import { IconInputStyles } from './icon.styles';
import { KeysIconsConstant } from '@components/icon/icon.constants';
import { IconComponent } from '@components/icon/icon.component';
import { IconType } from '@components/icon/icon.types';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { useDeviceHook } from '@hooks/device/device.hook';

export function IconInput<ITEM>(properties: IconInputImplementation<ITEM>) {
  const value = properties.value[properties.name];
  const [focus, setFocus] = useState(false);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const onChange = (value: string) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: value,
    };
    properties.onChange(newValue);
  };
  const iconFiltered = KeysIconsConstant.filter((icon) => icon.includes(search));

  const { phone } = useDeviceHook();

  return (
    <>
      <TouchableOpacity
        disabled={properties.readonly}
        onPress={() => {
          if (properties.readonly) return;
          setFocus(!focus);
        }}
        style={[
          IconInputStyles.iconInputContainer,
          properties.readonly ? IconInputStyles.readonly : {},
        ]}
      >
        {value ? (
          <IconComponent
            name={value as IconType}
            iconStyle={[IconInputStyles.iconShape, properties.readonly && IconInputStyles.readonly]}
          />
        ) : (
          <TextComponent
            translate
            text={`${properties.prefix}.${properties.name.toString()}.label`}
            style={IconInputStyles.text}
          />
        )}
        {!properties.readonly && (
          <IconComponent
            name={focus ? 'angle-up' : 'angle-down'}
            containerstyle={IconInputStyles.iconStatusContainer}
            iconStyle={IconInputStyles.iconStatus}
          />
        )}
      </TouchableOpacity>

      {focus && (
        <View style={IconInputStyles.options}>
          <IconComponent name="search" iconStyle={IconInputStyles.searchIcon} />
          <TextInput
            style={IconInputStyles.search}
            placeholder={`${t(`${properties.prefix}.icon.search.placeholder`)}`}
            onChangeText={(text) => setSearch(text)}
          />

          <ScrollView
            style={IconInputStyles.optionsScroll}
            scrollEnabled
            contentContainerStyle={[
              IconInputStyles.optionsContent,
              phone && { padding: 4, gap: 5 },
            ]}
          >
            {iconFiltered.map((option, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  setFocus(false);
                  onChange(option);
                }}
                style={[
                  IconInputStyles.option,
                  index === KeysIconsConstant.length - 1 ? IconInputStyles.lastOption : null,
                ]}
              >
                <IconComponent name={option as IconType} iconStyle={IconInputStyles.iconShape} />
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
