import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ProgramCrmType } from '@human/crm/types/program.crm.type';

export class ProgramCrmRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    limit: number;
    page: number;
  }): Promise<{ items: ProgramCrmType[]; total: number }> {
    return this.fetch('program/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          created: new Date(item.created),
          description: item.description,
          price: item.price,
          clinicName: item.clinicName,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  pick(properties: { id: string }): Promise<ProgramCrmType> {
    return this.fetch(`program/pick`, {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      ...data,
      price: data.price.toString(),
    }));
  }

  create(properties: { item: Partial<ProgramCrmType> }): Promise<void> {
    return this.fetch('program/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  update(properties: { item: Partial<ProgramCrmType> }): Promise<void> {
    return this.fetch(`program/update`, {
      method: 'PUT',
      params: {
        id: properties.item.id || '',
      },
      body: properties.item,
    });
  }

  enable(properties: { programId: string }): Promise<void> {
    return this.fetch(`program/enable`, {
      method: 'PUT',
      params: properties,
    });
  }

  disable(properties: { programId: string }): Promise<void> {
    return this.fetch(`program/disable`, {
      method: 'PUT',
      params: properties,
    });
  }
}
