import { NavigationComponent } from '@components/navigation/navigation.component';
import { CreateCountryScreen } from './screens/create/create.country.screen';
import { DeleteCountryScreen } from './screens/delete/delete.country.screen';
import { ListCountriesScreen } from './screens/list/list.country.screen';
import { UpdateCountryScreen } from './screens/update/update.country.screen';
import { CountriesRoutes } from './countries.routes';
import { EnableCountryScreen } from './screens/enable/enable.country.screen';
import { DisableCountryScreen } from './screens/disable/disable.country.screen';

const Navigation = NavigationComponent<CountriesRoutes>();

export function CountriesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListCountriesScreen} />
      <Navigation.Screen name="create" component={CreateCountryScreen} />
      <Navigation.Screen name="update" component={UpdateCountryScreen} />
      <Navigation.Screen name="delete" component={DeleteCountryScreen} />
      <Navigation.Screen name="enable" component={EnableCountryScreen} />
      <Navigation.Screen name="disable" component={DisableCountryScreen} />
    </Navigation.Navigator>
  );
}
