import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignsRoutes } from '../../campaigns.routes';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function EnableCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignEnable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.campaigns.enable">
      <FormComponent
        prefix="crm.campaigns.enable.form"
        padding
        repository={{
          send: () => {
            return new CampaignCrmRepository()
              .enable({
                campaignId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
