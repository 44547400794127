import { NavigationComponent } from '@components/navigation/navigation.component';
import { TransferTaskRoutes } from './transferTask.routes';
import { ListTransferTasksScreen } from './screens/list/list.transfer.task.screen';
import { ConfirmTransferTaskScreen } from './screens/confirmTransfer/confirm.transfer.task.screen';

const Navigation = NavigationComponent<TransferTaskRoutes>();

export function TransferTaskSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListTransferTasksScreen} />
      <Navigation.Screen name="confirmTransfer" component={ConfirmTransferTaskScreen} />
    </Navigation.Navigator>
  );
}
