import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { CycleCrmType } from '@human/crm/types/cycle.crm.type';

export class CycleCrmRepository extends AuthorizedRepository {
  async list(properties: {
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: CycleCrmType[]; total: number }> {
    return this.fetch('cycle/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((cycle: any) => ({
          id: cycle.id,
          name: cycle.name,
          complexity: cycle.complexity,
          status: cycle.status,
          created: new Date(cycle.created),
          code: cycle.code,
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: { cycleId: string }): Promise<Partial<CycleCrmType>> {
    return this.fetch('cycle/pick', {
      method: 'GET',
      params: properties,
    }).then((cycle) => ({
      id: cycle.id,
      name: cycle.name,
      complexity: cycle.complexity,
      code: cycle.code,
      description: cycle.description,
    }));
  }

  async update(properties: { item: Partial<CycleCrmType> }): Promise<void> {
    return this.fetch('cycle/update', {
      method: 'PUT',
      params: {
        cycleId: properties.item.id || '',
      },
      body: properties.item,
    });
  }

  async create(properties: { item: Partial<CycleCrmType> }): Promise<void> {
    return this.fetch('cycle/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async enable(properties: { cycleId: string }): Promise<void> {
    return this.fetch('cycle/enable', {
      method: 'PUT',
      params: properties,
    });
  }
  async disable(properties: { cycleId: string }): Promise<void> {
    return this.fetch('cycle/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
