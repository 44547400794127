import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { MemberAccessType } from '@human/access/types/member.access.type';

export class MemberAccessRepository extends AuthorizedRepository {
  async listByDepartment(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    clinicId: string;
    departmentId: string;
  }): Promise<{
    items: MemberAccessType[];
    total: number;
  }> {
    return this.fetch(`member/findByDepartment`, {
      method: 'GET',
      params: {
        clinicId: properties.clinicId,
        departmentId: properties.departmentId,
        ...(properties.search && { search: properties.search }),
        page: properties.page,
        limit: properties.limit,
      },
    }).then((data) => {
      return {
        items: data.items.map((member: any) => ({
          departmentId: member.departmentId,
          departmentName: member.departmentName,
          contractId: member.contractId,
          userName: member.userName,
        })),
        total: data.total,
      };
    });
  }

  create(properties: { clinicId: string; departmentId: string; contractId: string | undefined }) {
    return this.fetch(`member/create`, {
      method: 'POST',
      body: {
        clinicId: properties.clinicId,
        departmentId: properties.departmentId,
        contractId: properties.contractId,
      },
    });
  }

  delete(properties: { clinicId: string; departmentId: string; contractId: string }) {
    return this.fetch(`member/delete`, {
      method: 'DELETE',
      body: {
        clinicId: properties.clinicId,
        departmentId: properties.departmentId,
        contractId: properties.contractId,
      },
    });
  }
}
