import { CampaignsRoutes } from '../../campaigns.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { CoversComponent } from '../../../../../../../../../components/covers/covers.component';
import { useState } from 'react';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignList'>;
}) {
  const [search, setSearch] = useState<string>('');

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.campaigns.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('campaignCreate'),
    });
  }

  const columns: ColumnProperties<CampaignCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.campaigns.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('campaignUpdate', {
          id: row.id,
          languageId: row.languageId,
        });
      },
    },
    {
      type: 'text',
      name: 'name',
      onRender: (row) => row.name,
      width: 200,
    },
    {
      name: 'leads',
      type: 'bar',
      onTotal: (row) => row.leads || 0,
      onMax: (rows) => rows.reduce((max, row) => Math.max(max, row.leads || 0), 0),
      width: 140,
    },
    {
      type: 'text',
      name: 'leadsStatus',
      onRender: (row) => `${row.activeLeads} / ${row.inactiveLeads}`,
      width: 175,
    },
    {
      type: 'text',
      name: 'languageName',
      onRender: (row) => row.languageName || '',
      width: 200,
    },
    {
      type: 'text',
      name: 'funnelName',
      onRender: (row) => row.funnelName || '',
      width: 200,
    },
    {
      type: 'multipleBadge',
      name: 'clinics',
      onRender: (row) => row.participantsClinicNames,
      width: 325,
    },
    {
      type: 'multipleBadge',
      name: 'managers',
      onRender: (row) => row.managerNames,
      width: 325,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.campaigns.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'campaignDisable' : 'campaignEnable', {
          id: row.id,
        });
      },
    });
  }

  if (hasPermissionUtilty({ name: 'crm.campaigns.destructor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'trash',
      renderColor: () => 'red',
      onPress: (row) => {
        properties.navigation.navigate('campaignDelete', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.campaigns.icon} prefix={'crm.campaigns.list'} links={links}>
      {
        <CoversComponent
          infiniteScroll={true}
          search={search}
          repository={(settings: { search: string; page: number; limit: number }) => {
            return new CampaignCrmRepository()
              .listImageCampaigns({
                search: settings.search,
                limit: settings.limit,
                page: settings.page,
              })
              .then((campaigns) => {
                return {
                  items: campaigns.items.map((campaign) => ({
                    image: campaign.image || '',
                    title: campaign.name,
                    subtitle: campaign.code,
                    count: campaign.leads || 0,
                    detail: campaign.funnelName || '',
                    onPress: () =>
                      properties.navigation.navigate('campaignUpdate', {
                        id: campaign.id,
                        languageId: campaign.languageId,
                      }),
                  })),
                  total: campaigns.total,
                };
              });
          }}
        />
      }
      <TableComponent<CampaignCrmType, []>
        settingUpdate={setSearch}
        prefix={'crm.campaigns.list'}
        suffix={'list'}
        scrollable
        repository={(settings) =>
          new CampaignCrmRepository().list({
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
