export const EnLocale = {
  logotype: {
    horse: 'Ovohorse',
    human: 'Ovoclinic',
    ovobank: 'Ovobank',
    ovoclinic: 'Ovoclinic',
  },
  calendar: {
    hour: 'Hour',
    minutes: ':',
    save: 'Save',
    placeholder: 'Select a date',
  },
  historical: {
    deleted: 'Deleted',
    created: 'Created',
    updated: 'Updated',
  },
  chat: {
    placeholder: 'Write a message',
    title: 'Contact chat',
  },
  slicer: {
    confirm: 'Select',
  },
  export: {
    title: 'Export',
    description: 'Export table data to a CSV file',
  },
  import: {
    title: 'Matter',
  },
  transfer: {
    title: 'Transfer',
  },
  createTaskMassively: {
    title: 'Create tasks',
  },
  leadEvents: {
    informed: 'Commercial V.I carried out',
    contacted: 'Contacted',
    attended: 'Medical V.I performed',
    appointed: 'Cited V.I commercial',
    medicalAppointed: 'Cited V.I Medical',
    medicalEvaluated: 'Medical evaluation',
    comercialAttended: 'Budget delivered',
    converted: 'Converted',
  },
  form: {
    tabChange: {
      title: 'You are editing a form. ',
    },
    update: {
      successToast: 'Form updated successfully',
    },
    checkbox: {
      errors: {
        required: 'You must accept to continue',
      },
    },
    selectable: {
      placeholder: {
        title: 'Select an option',
        empty: 'There are no results',
      },
    },
    date: {
      placeholder: 'Filter by date',
    },
    json: {
      errors: {
        geojson: 'GeoJSON invalid format',
      },
    },
    text: {
      errors: {
        notString: 'The value must be a text string',
        ipIsRequired: 'IP address is required',
        ipMustBeAString: 'The IP address must be a text string',
        ipMustBeAValidIpAddress: 'IP address must be valid',
        countryNameEn: 'The name is required',
        countryNameEs: 'The name is required',
        nameIsRequired: 'The name is required',
        nameMustBeAString: 'The name must be a text string',
        nameMustNotContainNumbers: 'The name must not contain numbers',
        nameMustNotContainSpecialCharacters: 'The name must not contain special characters',
        nameMustBeAtMost50CharactersLong: 'The name must have a maximum of 50 characters',
        dniIsRequired: 'ID is required',
        dniMustBeAtLeast8CharactersLong: 'The ID must have at least 8 characters',
        dniMustBeAtMost20CharactersLong: 'The ID must have a maximum of 20 characters',
        surnameIsRequired: 'Last name is required',
        surnameMustBeAString: 'Last name must be a text string',
        surnameMustNotContainNumbers: 'The last name must not contain numbers',
        surnameMustNotContainSpecialCharacters: 'The last name must not contain special characters',
        surnameMustBeAtMost50CharactersLong: 'The last name must have a maximum of 50 characters',
        emailIsRequired: 'Email is required',
        emailMustBeAString: 'The email must be a text string',
        emailMustBeAValidEmail: 'Email must be valid',
        emailMustBeAtLeast3CharactersLong: 'Email must be at least 3 characters',
        emailMustBeAtMost50CharactersLong: 'The email must be a maximum of 30 characters',
        emailMustContainAnAtSymbol: 'The email must contain an @ symbol',
        emailMustNotContainWhitespace: 'The email must not contain whitespace',
        passwordIsRequired: 'Password is required',
        passwordMustBeAString: 'Password must be a text string',
        passwordMustBeAtLeast8CharactersLong: 'Password must be at least 8 characters',
        passwordMustBeAtMost30CharactersLong: 'The password must have a maximum of 30 characters',
        passwordMustContainAnUppercaseLetter: 'The password must contain a capital letter',
        passwordMustContainALowercaseLetter: 'Password must contain a lowercase letter',
        passwordMustContainANumber: 'Password must contain a number',
        passwordMustContainASpecialCharacter: 'Password must contain a special character',
        passwordMustNotContainWhitespace: 'Password must not contain blank spaces',
        passwordsDoNotMatch: 'Passwords do not match',
        valueMustBeAnInteger: 'The value must be an integer',
        valueMustBeAValidNumber: 'The value must be a valid number',
        valueMustBePositive: 'The value must be positive',
        TEXT_TOO_SHORT: 'The text is too short',
        WRONG_EMAIL: 'Email format is incorrect',
        REQUIRED: 'This field is required',
        titleMustBeAString: 'The title must be a text string',
        titleIsRequired: 'Title is required',
        titleMustBeAtLeast3CharactersLong: 'Title must be at least 3 characters',
        titleMustBeAtMost100CharactersLong: 'The title must have a maximum of 100 characters',
        codeMustBeAString: 'The code must be a text string',
        codeIsRequired: 'The code is required',
        codeMustBeAtLeast3CharactersLong: 'The code must be at least 3 characters',
        codeMustBeAtLeast10CharactersLong: 'The code must be at least 10 characters',
        codeMustBeAtMost30CharactersLong: 'The code must have a maximum of 30 characters',
        prefixIsRequired: 'The prefix is ​​required',
        prefixInvalidLength: 'The prefix must be 2 or 3 digits',
        prefixMustContainOnlyNumbers: 'The prefix must contain only numbers',
        prefixFirstCharacterMustBePlusSign: 'The prefix must begin with the + sign',
        prefixMustBeAtLeast1CharactersLong: 'The prefix has at least 1 digit',
        prefixMustBeAtMost3CharactersLong: 'The prefix has a maximum of 3 digits',
        countryCodeMustBeAString: 'The country code must be a text string',
        countryCodeIsRequired: 'Country code is required',
        countryCodeMustBe2CharactersLong: 'Country code must be 2 characters',
        countryCodeMustBeUppercase: 'The country code must be in capital letters',
        phoneIsRequired: 'Telephone is required',
        phoneMustBeAString: 'The phone must be a text string',
        phoneMustContainOnlyNumbers: 'The phone must contain only numbers',
        phoneMustNotContainWhitespace: 'The phone must not contain blank spaces',
        phoneMustBeAtLeast9CharactersLong: 'The phone number must have at least 9 digits',
        phoneMustBeAtMost12CharactersLong: 'The telephone number must have a maximum of 12 digits',
        extensionIsRequired: 'Extension is required',
        extensionMustContainOnlyNumbers: 'The extension must contain only numbers',
        extensionMustBeAtLeast4CharactersLong: 'The extension must be at least 4 digits',
        descriptionIsRequired: 'Description is required',
        descriptionMustBeAString: 'Description must be a text string',
        descriptionMustBeAtMost500CharactersLong:
          'The description must have a maximum of 500 characters',
        observationIsRequired: 'Observation is required',
        observationMustBeAString: 'The observation must be a text string',
        observationMustBeAtLeast1CharactersLong: 'The observation must have at least 1 character',
        observationMustBeAtMost150CharactersLong:
          'The comment must have a maximum of 150 characters',
        observationMustBeAtMost500CharactersLong:
          'The comment must have a maximum of 500 characters',
        usernameIsRequired: 'Username is required',
        usernameMustBeAString: 'Username must be a text string',
        usernameMustBeAtLeast3CharactersLong: 'Username must be at least 3 characters',
        usernameMustBeAtMost30CharactersLong: 'Username must be a maximum of 30 characters',
        usernameMustNotContainSpecialCharacters: 'Username must not contain special characters',
        usernameMustNotContainWhitespace: 'Username must not contain whitespace',
        zipIsRequired: 'Zip code is required',
        zipMustBeAString: 'The zip code must be a text string',
        zipMustBe5CharactersLong: 'Zip code must be 5 characters',
        zipMustContainOnlyNumbers: 'The zip code must contain only numbers',
        zipMustNotContainWhitespace: 'The zip code must not contain blank spaces',
        zipMustNotContainSpecialCharacters: 'The zip code must not contain special characters',
        streetIsRequired: 'The street is required',
        streetMustBeAString: 'The street must be a text string',
        streetMustBeAtLeast8CharactersLong: 'The street must be at least 8 characters',
        cityIsRequired: 'The city is required',
        cityMustBeAString: 'The city must be a text string',
        stateIsRequired: 'The state is required',
        stateMustBeAString: 'The status must be a text string',
        coinIsRequired: 'Currency is required',
        coinMustBeAString: 'The currency must be a text string',
        flagIsRequired: 'The flag is required',
        flagMustBeAString: 'The flag must be a text string',
        subjectIsRequired: 'The matter is required',
        subjectMustBeAString: 'The subject must be a text string',
        subjectMustBeAtLeast3CharactersLong: 'The subject must be at least 3 characters',
        subjectMustBeAtMost30CharactersLong: 'The subject must be a maximum of 30 characters',
        messageIsRequired: 'The message is required',
        messageMustBeAString: 'The message must be a text string',
        messageMustBeAtLeast3CharactersLong: 'The message must be at least 3 characters',
        messageMustBeAtMost500CharactersLong: 'The message must have a maximum of 500 characters',
        hourIsRequired: 'The time is required',
        hourMustBeValidFormat: 'The time must be in a valid format: HH:MM',
        documentIsRequired: 'The document is required',
        documentMustBeAString: 'The document must be a text string',
        documentMustBeAtLeast8CharactersLong: 'The document must be at least 8 characters',
        codeMustBeAtLeast2CharactersLong: 'The code must be at least 2 characters',
        required: 'This field is required',
        name: {
          notString: 'The name must be a text string',
          tooShort: 'The name is too short',
          tooLong: 'The name is too long',
          invalidCharacters: 'The name contains invalid characters',
        },
        email: {
          notValid: 'The email is not valid',
          notString: 'The email must be a text string',
        },
        phone: {
          invalid: 'The phone is invalid',
          notString: 'The phone must be a text string',
        },
        surname: {
          notString: 'Last name must be a text string',
          tooShort: 'The last name is too short',
          tooLong: 'Last name is too long',
          invalidCharacters: 'Last name contains invalid characters',
        },
        boolean: {
          notBoolean: 'The value must be a boolean',
          truthy: {
            notTruthy: 'The value must be true',
          },
        },
        password: {
          notString: 'Password must be a text string',
          tooShort: 'Password is too short',
          tooLong: 'Password is too long',
          invalidCharacters: 'Password contains invalid characters',
          noUpperCase: 'Password must contain at least one uppercase letter',
          noLowerCase: 'Password must contain at least one lowercase letter',
          noNumber: 'Password must contain at least one number',
          noSpecialCharacter: 'Password must contain at least one special character',
        },
        number: {
          notNumber: 'The value must be a number',
        },
        positive: {
          notPositive: 'The value must be positive',
          notNumber: 'The value must be a number',
        },
        price: {
          notNumber: 'The value must be a number',
          notPositive: 'The value must be positive',
          notString: 'The value must be a text string',
        },
        negative: {
          notNegative: 'The value must be negative',
          notNumber: 'The value must be a number',
        },
        uuid: {
          notUuid: 'The value must be a UUID',
          notString: 'The value must be a text string',
          empty: 'The value cannot be empty',
        },
        object: {
          notValid: 'The value must be an object',
        },
        description: {
          notString: 'Description must be a text string',
          tooShort: 'The description is too short',
          tooLong: 'The description is too long',
        },
        observation: {
          notString: 'The observation must be a text string',
          tooShort: 'The observation is too short',
          tooLong: 'The observation is too long',
        },
        date: {
          notValid: 'The date is not valid',
          notString: 'The date must be a text string',
        },
        color: {
          notString: 'Color must be a text string',
          notHex: 'Color must be a hexadecimal code',
        },
        code: {
          notString: 'The code must be a text string',
          tooShort: 'The code is too short',
          tooLong: 'The code is too long',
        },
        image: {
          notString: 'The image must be a text string',
          notBase64: 'The image must be a base64 code',
        },
        phonePrefix: {
          notString: 'The prefix must be a text string',
          notValid: 'The prefix is ​​not valid',
        },
        countryCode: {
          notString: 'The country code must be a text string',
          notValid: 'Country code is invalid',
        },
        zip: {
          notString: 'The zip code must be a text string',
          notLength: 'Zip code must be 5 characters',
          notNumber: 'The zip code must contain only numbers',
          hasSpace: 'The zip code must not contain blank spaces',
          hasLetter: 'The zip code must not contain letters',
          hasSymbol: 'The zip code must not contain symbols',
        },
        title: {
          notString: 'The title must be a text string',
          tooShort: 'The title is too short',
          tooLong: 'The title is too long',
        },
        username: {
          notString: 'Username must be a text string',
          tooShort: 'Username is too short',
          tooLong: 'Username is too long',
          invalidCharacters: 'Username contains invalid characters',
        },
        ip: {
          notString: 'The IP address must be a text string',
          notValid: 'IP address is invalid',
        },
        state: {
          notString: 'The status must be a text string',
        },
        city: {
          notString: 'The city must be a text string',
        },
        street: {
          notString: 'The street must be a text string',
        },
        coin: {
          notString: 'The currency must be a text string',
        },
        flag: {
          notString: 'The flag must be a text string',
        },
      },
    },
  },
  analytics: {
    title: 'Analytics',
    filter: 'Comparison between last week and this week',
    activities: {
      title: 'Tasks',
    },
    leads: {
      title: 'Leads',
    },
    results: {
      title: 'Results',
    },
  },
  history: {
    title: 'Record',
  },
  performance: {
    title: 'Performance',
  },
  sessions: {
    title: 'Active Sessions',
  },
  security: {
    title: 'Secured System',
  },
  launch: {
    title: 'The future of assisted reproduction',
    subtitle: 'Traceability and security for your clinic',
    description: {
      ovoclinic:
        'Secured software owned by Ovoclinic for the management of assisted reproduction clinics. ',
      ovobank: 'Secured software owned by Ovobank for the management of egg banks. ',
    },
    crm: {
      description: 'Space for customer management and improving the relationship with them. ',
    },
    clinic: {
      description: 'Everything you need to manage your clinic, hospital or medical center. ',
    },
    access: {
      description: 'Access control and user management. ',
    },
    board: {
      description: 'Control board and monitoring of indicators. ',
    },
    tory: {
      description: 'Clinical results and traceability of laboratory tests. ',
    },
    profile: {
      description: 'Management of personal and account information. ',
    },
  },
  access: {
    menu: {
      users: 'Users',
      roles: 'Roles',
      specialists: 'Specialists',
      clinics: 'Clinics',
      planning: 'Planning',
      timetables: 'Schedules',
      bulletins: 'News',
      departments: 'Departments',
      languages: 'Languages',
      countries: 'Countries',
      settings: 'Configuration',
      directory: 'Directory',
      locations: 'Locations',
      globalization: 'Globalization',
      communications: 'Communications',
      communicationChannels: 'Communication routes',
      ovopoints: 'Ovopoints',
      landbot: 'Landbot',
      templates: 'Templates',
    },
    users: {
      list: {
        chart: {
          user: 'Users',
          movement: 'Movements',
        },
        month: {
          '0': 'January',
          '1': 'February',
          '2': 'March',
          '3': 'April',
          '4': 'May',
          '5': 'June',
          '6': 'July',
          '7': 'August',
          '8': 'September',
          '9': 'October',
          '10': 'November',
          '11': 'December',
          '12': 'Total',
        },
        year: {
          '0': '2020',
          '1': '2021',
          '2': '2022',
          '3': '2023',
          '4': '2024',
          '5': '2025',
          '6': '2026',
          '7': '2027',
          '8': '2028',
          '9': '2029',
          '10': '2030',
          '11': '2031',
        },
        columns: {
          name: 'Name',
          email: 'Email',
          phone: 'Phone',
          status: 'State',
          createdAt: 'Created',
          updatedAt: 'Updated',
          expiration: 'Expiration',
          created: 'Creation',
          lastMove: 'Last move',
        },
        title: 'Users',
        description: 'Personnel management and their access to the system',
        create: 'User',
        one: 'User',
        many: 'Users',
        cloneConfirm: 'Are you sure you want to clone this user?',
        cloneSuccess: 'Successfully cloned user',
      },
      create: {
        title: 'New user',
        subtitle: 'Registration of a new user in the system',
        form: {
          name: {
            title: 'Name',
            description: 'User name',
          },
          surname: {
            title: 'Surnames',
            description: "User's last name",
          },
          password: {
            title: 'Password',
            description:
              'The password must be at least 8 characters, one uppercase letter, one lowercase letter, and one number.',
          },
          username: {
            title: 'Username',
            description: 'Username for system access',
          },
          email: {
            title: 'Email',
            description: 'Email address where the user will receive system notifications',
          },
          phone: {
            title: 'Phone',
            description: 'User contact number',
          },
          extension: {
            title: 'Extension',
            description: 'Identification extension within the department',
          },
          signature: {
            title: 'Password',
            description: 'User password',
          },
          expiration: {
            title: 'Expiration date',
            description: "Date when the user's account will expire",
          },
          color: {
            title: 'Color',
            description: 'User color in the system',
          },
          icon: {
            title: 'Icon',
            label: 'Select icon',
            description: 'User identification icon in the system',
            search: {
              placeholder: 'Search icon',
            },
          },
          status: {
            title: 'System access',
            description: 'Enable or disable system access for this user',
            label: 'Allow access to the system',
          },
          movementLimit: {
            title: 'Movement limit',
            description: 'Number of requests allowed for this user',
          },
          attemptLimit: {
            title: 'Attempt Limit',
            description: 'Number of attempts allowed to log in',
          },
          submit: 'Create user',
        },
      },
      delete: {
        title: 'Disable user',
        subtitle: 'This action is reversible',
        form: {
          status: {
            title: 'Are you sure you want to disable this user?',
            description: 'Disabling a user means that they will not be able to access the system. ',
            label: 'Yes, I want to deactivate this user',
          },
          submit: 'Disable',
        },
        passwordForm: {
          title: 'Enter your password',
          subtitle: 'To disable the user, enter your password',
          password: {
            title: 'Password',
            description: 'Password of the user who performs the action',
          },
          submit: 'Confirm',
        },
      },
      enable: {
        title: 'Enable user',
        subtitle: 'This action allows the user to access the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this user?',
            description: 'Enabling a user means that they will be able to access the system. ',
            label: 'Yes, I want to enable this user',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable user',
        subtitle: 'This action prevents the user from accessing the system',
        form: {
          status: {
            title: 'Are you sure you want to disable it?',
            description: 'Disabling a user means that they will not be able to access the system. ',
            label: 'Yes, I want to disable this user',
          },
          submit: 'Disable',
        },
      },
      update: {
        access: 'Access',
        account: {
          tab: 'Account',
          description: 'User account information',
        },
        tabs: {
          account: {
            title: 'Account',
            description: 'Personal account details of the user with access to the system.',
          },
          licenses: {
            title: 'Licenses',
            description:
              'A license determines the actions that a user can perform and the access they have in the system. ',
          },
          contracts: {
            title: 'Contracts',
            description: 'A contract is an agreement between the user and the clinic. ',
          },
          movements: {
            title: 'Traffic',
            description: 'The system monitors the movements of the users. ',
          },
          attempts: {
            title: 'Attempts',
            description: 'To safeguard system security, system access attempts are monitored.',
          },
          languages: {
            title: 'Languages',
            description: 'Languages ​​spoken by the user',
          },
          timetables: {
            title: 'Schedules',
            description:
              'Schedules are the time intervals in which the user has access to the system.',
          },
          changePassword: {
            title: 'Password',
            description: 'Change user password',
          },
          activity: {
            title: 'Activity',
            description:
              'If an activity is defined, when the person responsible for the Lead is assigned to this user, a task will be created automatically with the activity that has been defined.',
          },
          integrations: {
            title: 'Integrations',
            description:
              'User-level configuration to be able to carry out the different integrations implemented in the CRM. ',
          },
        },
        schedules: {
          create: {
            title: 'Add schedule',
            subtitle: 'Add schedule to user',
            form: {
              timetableId: {
                title: 'Select a time',
                description: 'Time template in which the user has access to the system',
              },
              submit: 'Add schedule',
            },
          },
          delete: {
            title: 'Unlink schedule',
            subtitle: 'Unlink user schedule',
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                label: 'Yes, I want to unlink this schedule to the user',
              },
              submit: 'Unlink schedule',
            },
          },
        },
        languages: {
          add: 'Add language',
          create: {
            title: 'Add language',
            subtitle: 'Add language spoken by user',
            form: {
              languageId: {
                title: 'Language',
                description: 'Language spoken by the user',
              },
              submit: 'Add',
            },
          },
          delete: {
            title: 'Delete language',
            subtitle: "Delete this user's language",
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                description: 'This action is irreversible.',
                label: 'Yes, I want to remove this language',
              },
              submit: 'Confirm',
            },
          },
        },
        roles: 'Roles',
        title: 'Update user',
        subtitle: "Modification of a user's data in the system",
        licenses: {
          tab: 'Licenses',
          description:
            'Licenses define the actions that users can perform at the group level, that is, they are permissions that do not involve the clinics.',
          add: 'Add',
          create: {
            title: 'New license',
            subtitle: 'Registration of a new license in the system.',
            form: {
              roleId: {
                title: 'Role',
                description:
                  'The role is the set of permissions that the user will have in the system',
              },
              submit: 'Create license',
            },
          },
          delete: {
            title: 'Delete license',
            subtitle: 'This action is irreversible',
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                description: 'You may prefer to deactivate this license',
                label: 'Yes, I want to delete this license',
              },
              submit: 'Delete license',
            },
          },
        },
        permissions: {
          add: 'Add license',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Landbot',
              description: 'You are a LandBot user',
              label: 'Yes, you are a LandBot user',
            },
            submit: 'Update integrations',
          },
        },
        contracts: {
          tab: 'Contracts',
          description: 'Contracts are agreements between the user and the clinic. ',
          add: 'Add contract',
          addAll: 'Add all contracts',
          create: {
            title: 'New contract',
            subtitle: 'Registration of a new contract in the system.',
            form: {
              clinicId: {
                title: 'Clinic',
              },
              submit: 'Create contract',
            },
          },
          disable: {
            title: 'Disable contract',
            subtitle: 'This action prevents the user from accessing the clinic',
            form: {
              status: {
                title: 'Are you sure you want to disable it?',
              },
              submit: 'Disable contract',
            },
          },
          enable: {
            title: 'Enable contract',
            subtitle: 'This action allows the user to access the clinic',
            form: {
              status: {
                title: 'Are you sure you want to enable it?',
              },
              submit: 'Enable contract',
            },
          },
        },
        timetables: {
          add: 'Add schedule',
          create: {
            title: 'New schedule',
            subtitle: 'Register new schedule for the user',
            form: {
              day: {
                title: 'Day',
                description: 'Day of the week on which the user has access to the system',
              },
              started: {
                title: 'Start',
                description: 'Start time when the user has access to the system',
              },
              ended: {
                title: 'End',
                description: 'End time when the user has access to the system',
              },
              submit: 'Create schedule',
            },
          },
          delete: {
            title: 'Delete schedule',
            subtitle: 'Delete user schedule',
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                label: 'Yes, I want to delete this schedule',
              },
              submit: 'Delete schedule',
            },
          },
        },
        movements: {
          tab: 'Movements',
          description: 'The movements are the requests that the user makes to the system. ',
        },
        attempts: {
          tab: 'Attempts',
          description: "Attempts are accesses to the user's login. ",
        },
        form: {
          name: {
            title: 'Name',
            description: 'User name',
          },
          surname: {
            title: 'Surnames',
            description: "User's last name",
          },
          password: {
            title: 'Password',
            description:
              'The password must be at least 8 characters, one uppercase letter, one lowercase letter, and one number.',
          },
          username: {
            title: 'Username',
            description: 'Username for system access',
          },
          email: {
            title: 'Email',
            description: 'User email to log in to the system',
          },
          phone: {
            title: 'Phone',
            description: 'User contact number',
          },
          extension: {
            title: 'Extension',
            description: 'Identification extension within the department',
          },
          signature: {
            title: 'Password',
            description: 'User password',
          },
          expiration: {
            title: 'Expiration date',
            description: "Date when the user's account will expire",
          },
          color: {
            title: 'Color',
            description: 'User color in the system',
          },
          status: {
            title: 'System access',
            description: 'Enable or disable system access for this user',
            label: 'Allow access to the system',
          },
          movementLimit: {
            title: 'Movement limit',
            description: 'Number of requests allowed for this user during 24 hours',
          },
          attemptLimit: {
            title: 'Attempt Limit',
            description: 'Number of attempts allowed to log in during 24 hours',
          },
          submit: 'Update user',
        },
        activity: {
          form: {
            activityId: {
              title: 'Activity',
            },
            submit: 'Update activity',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Update ovopoints',
        subtitle: "Modifying a user's ovopoints in the system",
        form: {
          appointedPoints: {
            title: 'Cited lead',
            description: 'Ovopoints for scheduling an appointment',
          },
          attendedPoints: {
            title: 'The lead has attended the medical appointment',
            description: 'Ovopoints for attending a medical appointment',
          },
          commercialAttendedPoints: {
            title: 'The lead has had a sales appointment',
            description: 'Ovopoints for attending a business appointment',
          },
          informedPoints: {
            title: 'Informed Lead',
            description: 'Ovopoints for informing a lead',
          },
          contactedPoints: {
            title: 'Lead contacted',
            description: 'Ovopoints for contacting a lead',
          },
          convertedPoints: {
            title: 'Convert lead',
            description: {
              ovoclinic: 'Ovopoints for converting a lead to a patient',
              ovobank: 'Ovopoints for converting a lead to a donor',
            },
          },
          submit: 'Update ovopoints',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Communication route',
        title: 'Communication routes',
        description: 'Management of communication channels',
        one: 'Communication route',
        many: 'Communication routes',
        columns: {
          name: 'Name',
          created: 'Creation date',
        },
      },
      create: {
        title: 'New communication route',
        subtitle: 'Registration of a new communication channel in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Name of the communication path',
          },
          submit: 'Create communication channel',
        },
      },
      update: {
        title: 'Update communication path',
        subtitle: 'Modification of the data of a communication path in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Name of the communication path',
          },
          submit: 'Update communication path',
        },
      },
      enable: {
        title: 'Enable communication path',
        subtitle: 'Enable communication path in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this communication channel?',
            label: 'Yes, I want to enable this communication channel',
          },
          submit: 'Enable communication path',
        },
      },
      disable: {
        title: 'Disable communication path',
        subtitle: 'Disable communication path in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this communication path?',
            label: 'Yes, I want to disable this communication channel',
          },
          submit: 'Disable communication path',
        },
      },
    },
    specialists: {
      list: {
        create: 'Specialist',
        title: 'Specialist',
        description: 'Specialist management',
        one: 'Specialist',
        many: 'Specialists',
        columns: {
          name: 'Name',
          surname: 'Surnames',
          dni: 'ID',
          type: 'Guy',
        },
      },
      create: {
        title: 'New specialist',
        subtitle: 'Registration of a new specialist in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Specialist name',
          },
          surname: {
            title: 'Surnames',
            description: "Specialist's surname",
          },
          dni: {
            title: 'ID',
            description: 'Specialist identity document',
          },
          type: {
            title: 'Guy',
            description: 'Type of specialist',
          },
          submit: 'Create specialist',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Specialist',
            description: 'Personal details of the specialist',
          },
          contracts: {
            title: 'Contracts',
            description: 'Specialist contracts',
          },
        },
        contracts: {
          add: 'Add contract',
          list: {
            one: 'Contract',
            many: 'Contracts',
          },
          create: {
            title: 'New contract',
            subtitle: 'Registration of a new specialist contract in the system',
            form: {
              clinicId: {
                title: 'Clinic',
                description: 'Clinic to which the contract belongs',
              },
              submit: 'Create contract',
            },
          },
          delete: {
            title: 'Delete contract',
            subtitle: 'Delete contract of this specialist',
            form: {
              submit: 'Delete contract',
            },
          },
        },
        title: 'Update specialist',
        subtitle: "Modification of a specialist's data in the system",
        form: {
          name: {
            title: 'Name',
            description: 'Specialist name',
          },
          surname: {
            title: 'Surnames',
            description: "Specialist's surname",
          },
          dni: {
            title: 'ID',
            description: 'Specialist identity document',
          },
          type: {
            title: 'Guy',
            description: 'Type of specialist',
          },
          submit: 'Update specialist',
        },
      },
      enable: {
        title: 'Enable specialist',
        subtitle: 'This action enables the specialist in the system',
        form: {
          submit: 'Enable specialist',
        },
      },
      disable: {
        title: 'Disable specialist',
        subtitle: 'This action disables the specialist in the system',
        form: {
          submit: 'Disable specialist',
        },
      },
    },
    templates: {
      list: {
        title: 'Templates',
        description: 'Template management for Landbot',
        create: 'Template',
        columns: {
          label: 'Name',
        },
        one: 'Template',
        many: 'Templates',
      },
      create: {
        title: 'New template',
        subtitle: 'Registering a new template for Landbot',
        form: {
          label: {
            title: 'Name',
            description: 'Template name',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'Landbot ID to which the template is associated',
          },
          submit: 'Create template',
        },
      },
      update: {
        title: 'Update template',
        subtitle: 'Modifying template data for Landbot',
        tabs: {
          template: {
            title: 'Template',
            description: 'Template details',
          },
          templateScope: {
            title: 'Scope',
            description: 'Template Scope',
          },
          templateParam: {
            title: 'Parameters',
            description: 'Template parameters',
          },
        },
        form: {
          label: {
            title: 'Name',
            description: 'Template name',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'Landbot ID to which the template is associated',
          },
          id: {
            title: 'Template',
            description: 'Template to use in Landbot to send the message',
          },
          submit: 'Update template',
        },
      },
      disable: {
        title: 'Disable template',
        subtitle: 'Disable Template for Landbot',
        form: {
          submit: 'Disable template',
        },
      },
      enable: {
        title: 'Enable template',
        subtitle: 'Enable template for Landbot',
        form: {
          submit: 'Enable template',
        },
      },
      delete: {
        title: 'Delete template',
        subtitle: 'Delete Landbot Template',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            label: 'Yes, I want to delete this template',
          },
          submit: 'Delete template',
        },
      },
      send: {
        title: 'Send template',
        subtitle: 'Send template to contact',
        form: {
          id: {
            title: 'Template',
            description: 'Template to use in Landbot to send the message',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'ID of the Landbot bot that the template is associated with',
          },
          submit: 'Send template',
        },
      },
      templateScopes: {
        list: {
          title: 'Scopes',
          description: 'Management of template scopes',
          create: 'Scope',
          columns: {
            label: 'Name',
          },
          one: 'Scope',
          many: 'Scopes',
        },
        create: {
          title: 'New scope',
          subtitle: 'Registering a new scope for templates',
          form: {
            label: {
              title: 'Name',
              description: 'Scope name',
            },
            departmentId: {
              title: 'Department',
              description: 'Department to which the template will have scope',
            },
            submit: 'Create scope',
          },
        },
        update: {
          title: 'Update scope',
          subtitle: 'Modifying data in a template scope',
          form: {
            label: {
              title: 'Name',
              description: 'Scope name',
            },
            submit: 'Update scope',
          },
        },
        delete: {
          title: 'Delete scope',
          subtitle: 'Remove scope from this template',
          form: {
            status: {
              title: 'Are you sure you want to delete it?',
              label: 'Yes, I want to delete this scope',
            },
            submit: 'Delete scope',
          },
        },
        add: 'Add scope',
      },
      templateParams: {
        list: {
          title: 'Parameters',
          description: 'Management of template parameters',
          create: 'Parameter',
          columns: {
            label: 'Name',
            order: 'Order',
            templateKeyName: 'Parameter',
          },
          one: 'Parameter',
          many: 'Parameters',
        },
        create: {
          title: 'New parameter',
          subtitle: 'Registering a new parameter for templates',
          form: {
            templateKeyId: {
              title: 'Parameter',
              description: 'Possible parameters to attach to the template',
            },
            order: {
              title: 'Order',
              description: 'Order in which the parameter will be displayed in the template',
            },
            submit: 'Create parameter',
          },
        },
        delete: {
          title: 'Delete parameter',
          subtitle: 'Remove parameter from this template',
          form: {
            status: {
              title: 'Are you sure you want to delete it?',
              label: 'Yes, I want to remove this parameter',
            },
            submit: 'Delete parameter',
          },
        },
        add: 'Add parameter',
      },
    },
    languages: {
      list: {
        create: 'Language',
        title: 'Language',
        description: 'Language management',
        one: 'Language',
        many: 'Languages',
        columns: {
          name: 'Name',
          country: 'Country',
          code: 'Code',
        },
      },
      create: {
        title: 'New language',
        subtitle: 'Registering a new language in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Language name',
          },
          status: {
            title: 'State',
            label: 'State',
          },
          countryCode: {
            title: 'Country',
            description: 'Country to which the language belongs',
          },
          code: {
            title: 'Code',
            description: 'Language code',
          },
          submit: 'Create language',
        },
      },
      update: {
        title: 'Update language',
        subtitle: 'Modifying language data in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Language name',
          },
          countryCode: {
            title: 'Country',
            description: 'Country to which the language belongs',
          },
          code: {
            title: 'Code',
            description: 'Language code',
          },
          submit: 'Update language',
        },
      },
      enable: {
        title: 'Enable language',
        subtitle: 'Enable language in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this language?',
            label: 'Yes, I want to enable this language',
          },
          submit: 'Enable language',
        },
      },
      disable: {
        title: 'Disable language',
        subtitle: 'Disable language in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this language?',
            label: 'Yes, I want to disable this language',
          },
          submit: 'Disable language',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'We have good news',
      },
      create: {
        title: 'New news',
        subtitle: 'Register new news in the clinic',
        steps: {
          clinic: {
            form: {
              title: 'Clinic',
              clinicId: {
                title: 'Clinic',
                description: 'Clinic to which the news belongs',
              },
            },
          },
          actor: {
            form: {
              title: 'Buddy',
              actorId: {
                title: 'Buddy',
                description: 'Partner the news is about',
              },
            },
          },
        },
        form: {
          description: {
            title: 'Description',
            description:
              'To celebrate achievements within the clinic, you can share information such as: success stories, personal recognition and gratitude.',
          },
          image: {
            title: 'Image',
            description: 'News image',
          },
          submit: 'Create news',
        },
      },
      update: {
        title: 'News details',
        subtitle: 'Clinic news details',
        form: {
          clinicId: {
            title: 'Clinic',
            description: 'Clinic to which the news belongs',
          },
          authorName: {
            title: 'Author',
            description: 'Author of the news',
          },
          actorName: {
            title: 'Buddy',
            description: 'Partner the news is about',
          },
          description: {
            title: 'Description',
            description:
              'To celebrate achievements within the clinic, you can share information such as: success stories, personal recognition and gratitude.',
          },
          image: {
            title: 'Image',
            description: 'News image',
          },
          submit: 'Update news',
        },
      },
      list: {
        title: 'News',
        description: 'Clinic news',
        create: 'News',
        one: 'News',
        many: 'News',
        columns: {
          clinicName: 'Clinic',
          actorName: 'Buddy',
          authorName: 'Author',
          created: 'Creation',
        },
      },
      delete: {
        title: 'Disable news',
        subtitle: 'Disable clinic news',
        button: 'Disable',
        form: {
          status: {
            title: 'Are you sure you want to disable this news?',
            label: 'Yes, I want to disable this news',
          },
          submit: 'Disable',
        },
        passwordForm: {
          title: 'Enter your password',
          subtitle: 'To disable the news, enter your password',
          password: {
            title: 'Password',
            description: 'Password of the user who performs the action',
          },
          submit: 'Confirm',
        },
      },
      enable: {
        title: 'Enable news',
        subtitle: 'Enable clinic news',
        button: 'Enable',
        form: {
          status: {
            title: 'Are you sure you want to enable this news?',
            label: 'Yes, I want to enable this news',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable news',
        subtitle: 'Disable clinic news',
        button: 'Disable',
        form: {
          status: {
            title: 'Are you sure you want to disable this news?',
            label: 'Yes, I want to disable this news',
          },
          submit: 'Disable',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Role',
          created: 'Creation',
        },
        title: 'Roles',
        description: 'Management of roles and their permissions',
        create: 'Role',
        one: 'Role',
        many: 'Roles',
      },
      create: {
        title: 'New role',
        subtitle: 'Registering a new role in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the role',
          },
          general: {
            accordion: 'General permissions',
            title: 'General permissions',
            description: 'General permissions for the role',
          },
          viewDisabledRecords: {
            title: 'View disabled logs',
            description: 'This option enables the display of disabled logs',
            label: 'Enable display of disabled logs',
          },
          viewDisabledLeadRecords: {
            title: 'View disabled leads',
            description: 'This option enables the display of disabled leads',
            label: 'Enable display of disabled leads',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'View disabled patients',
              ovobank: 'View disabled donors',
            },
            description: {
              ovoclinic: 'This option enables the display of disabled patients',
              ovobank: 'This option enables the display of disabled donors',
            },
            label: {
              ovoclinic: 'Enable display of disabled patients',
              ovobank: 'Enable display of disabled donors',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permission to the OVOAccess module',
            description: 'This option enables access to the OVOAccess module',
            label: 'Enable access',
          },
          user: {
            accordion: 'Users',
          },
          accessUser: {
            title: 'See users',
            description: 'This option enables the display of users in the system',
            label: 'Enable user display',
          },
          accessUserCreator: {
            title: 'Create users',
            description: 'This option enables the creation of users in the system',
            label: 'Enable user creation',
          },
          accessUserEditor: {
            title: 'Edit users',
            description: 'This option enables editing of users in the system',
            label: 'Enable user editing',
          },
          accessUserDestructor: {
            title: 'Delete users',
            description: 'This option enables the deletion of users in the system',
            label: 'Enable user deletion',
          },
          accessUserChangePassword: {
            title: 'Change password',
            description: 'This option enables changing the password of users in the system',
            label: 'Enable password change',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'View roles',
            description: 'This option enables the display of roles in the system',
            label: 'Enable role display',
          },
          accessRoleCreator: {
            title: 'Create roles',
            description: 'This option enables the creation of roles in the system',
            label: 'Enable role creation',
          },
          accessRoleEditor: {
            title: 'Edit roles',
            description: 'This option enables editing of roles in the system',
            label: 'Enable role editing',
          },
          accessRoleDestructor: {
            title: 'Delete roles',
            description: 'This option enables the deletion of roles in the system',
            label: 'Enable role deletion',
          },
          timetable: {
            accordion: 'Schedules',
          },
          accessTimetable: {
            title: 'See schedules',
            description: 'This option enables the display of schedules in the system',
            label: 'Enable schedule display',
          },
          accessTimetableCreator: {
            title: 'Create schedules',
            description: 'This option enables the creation of schedules in the system',
            label: 'Enable schedule creation',
          },
          accessTimetableEditor: {
            title: 'Edit schedules',
            description: 'This option enables the editing of schedules in the system',
            label: 'Enable schedule editing',
          },
          accessTimetableDestructor: {
            title: 'Delete schedules',
            description: 'This option enables the deletion of schedules in the system',
            label: 'Enable schedule deletion',
          },
          bulletin: {
            accordion: 'News',
          },
          accessBulletin: {
            title: 'See news',
            description: 'This option enables the display of news in the system',
            label: 'Enable news display',
          },
          accessBulletinCreator: {
            title: 'Create news',
            description: 'This option enables the creation of news in the system',
            label: 'Enable news creation',
          },
          accessBulletinEditor: {
            title: 'Edit news',
            description: 'This option enables editing of news in the system',
            label: 'Enable news editing',
          },
          accessBulletinDestructor: {
            title: 'Delete news',
            description: 'This option enables the deletion of news in the system',
            label: 'Enable news deletion',
          },
          accessClinic: {
            title: 'See clinics',
            description: 'This option enables the visualization of clinics in the system',
            label: 'Enable clinic viewing',
          },
          accessClinicCreator: {
            title: 'Create clinics',
            description: 'This option enables the creation of clinics in the system',
            label: 'Enable clinic creation',
          },
          accessClinicEditor: {
            title: 'Edit clinics',
            description: 'This option enables editing of clinics in the system',
            label: 'Enable clinic editing',
          },
          accessClinicDestructor: {
            title: 'Delete clinics',
            description: 'This option enables the deletion of clinics in the system',
            label: 'Enable clinic deletion',
          },
          department: {
            accordion: 'Departments',
          },
          accessDepartment: {
            title: 'See departments',
            description: 'This option enables the visualization of the departments in the system',
            label: 'Enable department display',
          },
          accessDepartmentCreator: {
            title: 'Create departments',
            description: 'This option enables the creation of departments in the system',
            label: 'Enable department creation',
          },
          accessDepartmentEditor: {
            title: 'Edit departments',
            description: 'This option enables the editing of departments in the system',
            label: 'Enable department editing',
          },
          accessDepartmentDestructor: {
            title: 'Delete departments',
            description: 'This option enables the deletion of departments in the system',
            label: 'Enable deletion of departments',
          },
          language: {
            accordion: 'Languages',
          },
          accessLanguage: {
            title: 'See languages',
            description: 'This option enables the display of languages ​​in the system',
            label: 'Enable language display',
          },
          accessLanguageCreator: {
            title: 'Create languages',
            description: 'This option enables the creation of languages ​​in the system',
            label: 'Enable language creation',
          },
          accessLanguageEditor: {
            title: 'Edit languages',
            description: 'This option enables editing of languages ​​in the system',
            label: 'Enable language editing',
          },
          accessLanguageDestructor: {
            title: 'Delete languages',
            description: 'This option enables the removal of languages ​​on the system',
            label: 'Enable language removal',
          },
          country: {
            accordion: 'Countries',
          },
          accessCountry: {
            title: 'See countries',
            description: 'This option enables the display of countries in the system',
            label: 'Enable country display',
          },
          accessCountryCreator: {
            title: 'Create countries',
            description: 'This option enables the creation of countries in the system',
            label: 'Enable country creation',
          },
          accessCountryEditor: {
            title: 'Edit countries',
            description: 'This option enables the editing of countries in the system',
            label: 'Enable country editing',
          },
          accessCountryDestructor: {
            title: 'Delete countries',
            description: 'This option enables the deletion of countries in the system',
            label: 'Enable country deletion',
          },
          accessSettings: {
            title: 'View settings',
            description: 'This option enables the display of settings in the system',
            label: 'Enable configuration display',
          },
          specialists: {
            accordion: 'Specialists',
          },
          accessSpecialist: {
            title: 'See specialists',
            description: 'This option enables the display of specialists in the system',
            label: 'Enable viewing of specialists',
          },
          accessSpecialistCreator: {
            title: 'Create specialists',
            description: 'This option enables the creation of specialists in the system',
            label: 'Enable creation of specialists',
          },
          accessSpecialistEditor: {
            title: 'Edit specialists',
            description: 'This option enables the editing of specialists in the system',
            label: 'Enable specialist editing',
          },
          accessSpecialistDestructor: {
            title: 'Eliminate specialists',
            description: 'This option enables the removal of specialists from the system',
            label: 'Enable specialist removal',
          },
          commsChannels: {
            accordion: 'Communication channels',
          },
          accessCommsChannel: {
            title: 'See communication channels',
            description:
              'This option enables the visualization of communication channels in the system',
            label: 'Enable visualization of communication channels',
          },
          accessCommsChannelCreator: {
            title: 'Create communication channels',
            description: 'This option enables the creation of communication channels in the system',
            label: 'Enable creation of communication channels',
          },
          accessCommsChannelEditor: {
            title: 'Edit communication channels',
            description: 'This option enables the editing of communication channels in the system',
            label: 'Enable editing of communication channels',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminate communication channels',
            description:
              'This option enables the elimination of communication channels in the system',
            label: 'Enable deletion of communication channels',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'This option enables the display of ovopoints in the system',
            label: 'Enable display of ovopoints',
          },
          accessOvopointEditor: {
            title: 'Edit ovopoints',
            description: 'This option enables the editing of ovopoints in the system',
            label: 'Enable ovopoint editing',
          },
          templates: {
            accordion: 'Templates',
          },
          accessTemplate: {
            title: 'See WhatsApp templates',
            description: 'This option enables the display of WhatsApp templates',
            label: 'Enable template display',
          },
          accessTemplateCreator: {
            title: 'Create WhatsApp templates',
            description: 'This option enables the creation of WhatsApp templates',
            label: 'Enable templating',
          },
          accessTemplateEditor: {
            title: 'Edit WhatsApp templates',
            description: 'This option enables editing of WhatsApp templates',
            label: 'Enable template editing',
          },
          accessTemplateDestructor: {
            title: 'Delete WhatsApp templates',
            description: 'This option enables the deletion of WhatsApp templates',
            label: 'Enable template deletion',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Application permission',
            description: 'This option enables access to the OVOCrm module',
            label: 'Enable access',
          },
          tasks: {
            accordion: 'Tasks',
          },
          crmCalendar: {
            title: 'Calendar',
            description: 'This option enables calendar display on the system',
            label: 'Enable calendar display',
          },
          crmTask: {
            title: 'Tasks',
            description: 'This option enables the display of tasks in the system',
            label: 'Enable task display',
          },
          crmTaskCreator: {
            title: 'Create tasks',
            description: 'This option enables the creation of tasks in the system',
            label: 'Enable task creation',
          },
          crmTaskEditor: {
            title: 'Edit tasks',
            description: 'This option enables editing of tasks in the system',
            label: 'Enable task editing',
          },
          crmTaskDestructor: {
            title: 'Delete tasks',
            description: 'This option enables the deletion of tasks in the system',
            label: 'Enable task deletion',
          },
          crmTaskSeeAll: {
            title: 'See all tasks',
            description: 'This option enables the display of all tasks in the system',
            label: 'Enable display of all tasks',
          },
          crmTaskSeeCoworkers: {
            title: "View colleagues' tasks",
            description: "This option enables the display of colleagues' tasks in the system",
            label: 'Enable viewing of peer tasks',
          },
          crmTaskUpdateResponsible: {
            title: 'Update the person assigned to a task',
            description: 'This option enables changing the person responsible for a task',
            label: 'Enable task manager editing',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'This option enables the visualization of leads in the system',
            label: 'Enable lead visualization',
          },
          crmLeadCreator: {
            title: 'Create leads',
            description: 'This option enables the creation of leads in the system',
            label: 'Enable lead creation',
          },
          crmLeadEditor: {
            title: 'Edit leads',
            description: 'This option enables the editing of leads in the system',
            label: 'Enable lead editing',
          },
          crmLeadDestructor: {
            title: 'Delete leads',
            description: 'This option enables the deletion of leads in the system',
            label: 'Enable lead deletion',
          },
          crmLeadSeeAll: {
            title: 'See all leads',
            description: 'This option enables the display of all leads in the system',
            label: 'Enable display of all leads',
          },
          crmLeadSeeCoworkers: {
            title: 'See leads from colleagues',
            description: "This option enables the display of colleagues' leads",
            label: "Enable visualization of colleagues' leads",
          },
          crmLeadUpdateCampaign: {
            title: 'Update campaign assigned to leads',
            description: 'This option enables the change of multiple leads campaign',
            label: 'Enable lead campaign editing',
          },
          patients: {
            accordion: {
              ovoclinic: 'Patients',
              ovobank: 'Donors',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Patients',
              ovobank: 'Donors',
            },
            description: {
              ovoclinic: 'This option enables the visualization of patients in the system',
              ovobank: 'This option enables the display of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient viewing',
              ovobank: 'Enable donor display',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Create patients',
              ovobank: 'Create donors',
            },
            description: {
              ovoclinic: 'This option enables the creation of patients in the system',
              ovobank: 'This option enables the creation of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient creation',
              ovobank: 'Enable donor creation',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Edit patients',
              ovobank: 'Edit donors',
            },
            description: {
              ovoclinic: 'This option enables editing of patients in the system',
              ovobank: 'This option enables editing of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient editing',
              ovobank: 'Enable donor editing',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Delete patients',
              ovobank: 'Delete donors',
            },
            description: {
              ovoclinic: 'This option enables the deletion of patients in the system',
              ovobank: 'This option enables the removal of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient deletion',
              ovobank: 'Enable donor removal',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'See all patients',
              ovobank: 'See all donors',
            },
            description: {
              ovoclinic: 'This option enables viewing of all patients in the system',
              ovobank: 'This option enables the display of all donors in the system',
            },
            label: {
              ovoclinic: 'Enable viewing of all patients',
              ovobank: 'Enable display of all donors',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'See patients of colleagues',
              ovobank: 'View peer donors',
            },
            description: {
              ovoclinic: 'This option enables viewing of peer patients',
              ovobank: 'This option enables the display of peer donors',
            },
            label: {
              ovoclinic: 'Enable viewing of patients from colleagues',
              ovobank: 'Enable viewing of peer donors',
            },
          },
          contacts: {
            accordion: 'Contacts',
          },
          crmContact: {
            title: 'Contacts',
            description: 'This option enables the display of contacts in the system',
            label: 'Enable contact display',
          },
          crmContactCreator: {
            title: 'Create contacts',
            description: 'This option enables the creation of contacts in the system',
            label: 'Enable contact creation',
          },
          crmContactEditor: {
            title: 'Edit contacts',
            description: 'This option enables editing of contacts in the system',
            label: 'Enable contact editing',
          },
          crmContactDestructor: {
            title: 'Delete contacts',
            description: 'This option enables deletion of contacts in the system',
            label: 'Enable contact deletion',
          },
          activity: {
            accordion: 'Activities',
          },
          crmActivity: {
            title: 'Activities',
            description: 'This option enables the visualization of activities in the system',
            label: 'Enable activity viewing',
          },
          crmActivityCreator: {
            title: 'Create activities',
            description: 'This option enables the creation of activities in the system',
            label: 'Enable activity creation',
          },
          crmActivityEditor: {
            title: 'Edit activities',
            description: 'This option enables editing of activities in the system',
            label: 'Enable activity editing',
          },
          crmActivityDestructor: {
            title: 'Delete activities',
            description: 'This option enables the deletion of activities in the system',
            label: 'Enable deletion of activities',
          },
          crmActivitySeeAll: {
            title: 'See all activities',
            description: 'This option enables viewing of all activities in the system',
            label: 'Enable viewing of all activities',
          },
          results: {
            accordion: 'Results',
          },
          crmResult: {
            title: 'Results',
            description: 'This option enables the display of results in the system',
            label: 'Enable results display',
          },
          crmResultCreator: {
            title: 'Create results',
            description: 'This option enables the creation of the results in the system',
            label: 'Enable result creation',
          },
          crmResultEditor: {
            title: 'Edit results',
            description: 'This option enables editing of results in the system',
            label: 'Enable results editing',
          },
          crmResultDestructor: {
            title: 'Delete results',
            description: 'This option enables deletion of results in the system',
            label: 'Enable deletion of results',
          },
          crmResultCCEvent: {
            title: 'Edit Call Center milestones',
            description: 'This option enables the editing of Call Center milestones in the system',
            label: 'Enable Call Center milestone editing',
          },
          crmResultATPCEvent: {
            title: 'Edit ATP Commercial milestones',
            description:
              'This option enables the editing of ATP Comercial milestones in the system',
            label: 'Enable ATP Commercial milestone editing',
          },
          crmResultATPIEvent: {
            title: 'Edit ATP International milestones',
            description:
              'This option enables the editing of ATP International milestones in the system',
            label: 'Enable editing of ATP International milestones',
          },
          crmResultReactivity: {
            title: 'Edit reactivity',
            description: 'This option enables editing of reactivity in the system',
            label: 'Enable reactivity editing',
          },
          reasons: {
            accordion: 'Reasons',
          },
          crmReason: {
            title: 'Reasons',
            description: 'This option enables the display of reasons in the system',
            label: 'Enable reason display',
          },
          crmReasonCreator: {
            title: 'Create reasons',
            description: 'This option enables the creation of reasons in the system',
            label: 'Enable reason creation',
          },
          crmReasonEditor: {
            title: 'Edit reasons',
            description: 'This option enables editing of reasons in the system',
            label: 'Enable reason editing',
          },
          crmReasonDestructor: {
            title: 'Delete reasons',
            description: 'This option enables deletion of reasons in the system',
            label: 'Enable reason deletion',
          },
          campaigns: {
            accordion: 'Campaigns',
          },
          crmCampaign: {
            title: 'Campaigns',
            description: 'This option enables the visualization of campaigns in the system',
            label: 'Enable campaign viewing',
          },
          crmCampaignCreator: {
            title: 'Create campaigns',
            description: 'This option enables the creation of campaigns in the system',
            label: 'Enable campaign creation',
          },
          crmCampaignEditor: {
            title: 'Edit campaigns',
            description: 'This option enables the editing of campaigns in the system',
            label: 'Enable campaign editing',
          },
          crmCampaignDestructor: {
            title: 'Delete campaigns',
            description: 'This option enables the deletion of campaigns in the system',
            label: 'Enable campaign deletion',
          },
          funnels: {
            accordion: 'Funnels',
          },
          crmFunnel: {
            title: 'Funnels',
            description: 'This option enables the visualization of funnels in the system',
            label: 'Enable funnel visualization',
          },
          crmFunnelCreator: {
            title: 'Create funnels',
            description: 'This option enables the creation of funnels in the system',
            label: 'Enable funnel creation',
          },
          crmFunnelEditor: {
            title: 'Edit funnels',
            description: 'This option enables editing of funnels in the system',
            label: 'Enable funnel editing',
          },
          crmFunnelDestructor: {
            title: 'Delete funnels',
            description: 'This option enables the removal of funnels in the system',
            label: 'Enable funnel removal',
          },
          channels: {
            accordion: 'Channels',
          },
          crmChannel: {
            title: 'Channels',
            description: 'This option enables the viewing of channels on the system',
            label: 'Enable channel display',
          },
          crmChannelCreator: {
            title: 'Create channels',
            description: 'This option enables the creation of channels in the system',
            label: 'Enable channel creation',
          },
          crmChannelEditor: {
            title: 'Edit channels',
            description: 'This option enables editing of channels in the system',
            label: 'Enable channel editing',
          },
          crmChannelDestructor: {
            title: 'Delete channels',
            description: 'This option enables the deletion of channels in the system',
            label: 'Enable channel deletion',
          },
          programs: {
            accordion: 'Treatments',
          },
          crmProgram: {
            title: 'Treatments',
            description: 'This option enables the display of catalog treatments in the system',
            label: 'Enable display of catalog treatments',
          },
          crmProgramCreator: {
            title: 'Create treatments',
            description: 'This option enables the creation of treatments in the system',
            label: 'Enable treatment creation',
          },
          crmProgramEditor: {
            title: 'Edit treatments',
            description: 'This option enables the editing of treatments in the system',
            label: 'Enable treatment editing',
          },
          crmProgramDestructor: {
            title: 'Delete treatments',
            description: 'This option enables the deletion of treatments in the system',
            label: 'Enable treatment removal',
          },
          services: {
            accordion: 'Services',
          },
          crmService: {
            title: 'Services',
            description: 'This option enables the visualization of services in the system',
            label: 'Enable service display',
          },
          crmServiceCreator: {
            title: 'Create services',
            description: 'This option enables the creation of services in the system',
            label: 'Enable service creation',
          },
          crmServiceEditor: {
            title: 'Edit services',
            description: 'This option enables editing of services in the system',
            label: 'Enable service editing',
          },
          crmServiceDestructor: {
            title: 'Delete services',
            description: 'This option enables the removal of services on the system',
            label: 'Enable service removal',
          },
          techniques: {
            accordion: 'Techniques',
          },
          crmTechnique: {
            title: 'Complementary techniques',
            description:
              'This option enables the visualization of complementary techniques in the system',
            label: 'Enable visualization of complementary techniques',
          },
          crmTechniqueCreator: {
            title: 'Create complementary techniques',
            description:
              'This option enables the creation of complementary techniques in the system',
            label: 'Enable creation of complementary techniques',
          },
          crmTechniqueEditor: {
            title: 'Edit complementary techniques',
            description:
              'This option enables the editing of complementary techniques in the system',
            label: 'Enable editing of complementary techniques',
          },
          crmTechniqueDestructor: {
            title: 'Eliminate complementary techniques',
            description:
              'This option enables the removal of complementary techniques in the system',
            label: 'Enable removal of add-on techniques',
          },
          maintenances: {
            accordion: 'Maintenance',
          },
          crmMaintenance: {
            title: 'Maintenance',
            description: 'This option enables the visualization of maintenance in the system',
            label: 'Enable maintenance visualization',
          },
          crmMaintenanceCreator: {
            title: 'Create maintenance',
            description: 'This option enables the creation of maintenance in the system',
            label: 'Enable maintenance creation',
          },
          crmMaintenanceEditor: {
            title: 'Edit maintenance',
            description: 'This option enables editing of maintenance in the system',
            label: 'Enable maintenance editing',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminate maintenance',
            description: 'This option enables the elimination of maintenance in the system',
            label: 'Enable maintenance removal',
          },
          treatments: {
            accordion: 'Treatments',
          },
          crmTreatment: {
            title: 'Treatments',
            description: 'This option enables the visualization of treatments in the system',
            label: 'Enable visualization of treatments',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Create treatments for patient',
              ovobank: 'Create donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the creation of patient treatments in the system',
              ovobank: 'This option enables the creation of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable creation of treatments for patient',
              ovobank: 'Enable creation of treatments for donor',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Edit treatments for patient',
              ovobank: 'Edit donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the editing of patient treatments in the system',
              ovobank: 'This option enables the editing of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable editing of treatments for patient',
              ovobank: 'Enable treatment editing for donor',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Delete treatments for patient',
              ovobank: 'Delete donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the deletion of patient treatments in the system',
              ovobank: 'This option enables the deletion of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable deletion of treatments for patient',
              ovobank: 'Enable deletion of treatments for donor',
            },
          },
          phases: {
            accordion: 'Stages',
          },
          crmPhase: {
            title: 'Stages',
            description: 'This option enables the visualization of the stages in the system',
            label: 'Enable stage visualization',
          },
          crmPhaseCreator: {
            title: 'Create stages',
            description: 'This option enables the creation of stages in the system',
            label: 'Enable staging',
          },
          crmPhaseEditor: {
            title: 'Edit stages',
            description: 'This option enables the editing of the stages in the system',
            label: 'Enable stage editing',
          },
          crmPhaseDestructor: {
            title: 'Delete stages',
            description: 'This option enables the elimination of stages in the system',
            label: 'Enable stage deletion',
          },
          causes: {
            accordion: 'Reasons',
          },
          crmCause: {
            title: 'Reasons for cancellation',
            description: 'This option enables the display of cancellation reasons in the system',
            label: 'Enable display of cancellation reasons',
          },
          crmCauseCreator: {
            title: 'Create cancellation reasons',
            description: 'This option enables the creation of cancellation reasons in the system',
            label: 'Enable creation of cancellation reasons',
          },
          crmCauseEditor: {
            title: 'Edit cancellation reasons',
            description: 'This option enables editing of cancellation reasons in the system',
            label: 'Enable editing of cancellation reasons',
          },
          crmCauseDestructor: {
            title: 'Eliminate cancellation reasons',
            description: 'This option enables the removal of cancellation reasons in the system',
            label: 'Enable removal of cancellation reasons',
          },
          cycles: {
            accordion: 'Treatment cycles',
          },
          crmCycle: {
            title: 'Treatment cycles',
            description: 'This option enables the visualization of treatment cycles in the system',
            label: 'Enable visualization of treatment cycles',
          },
          crmCycleCreator: {
            title: 'Create treatment cycles',
            description: 'This option enables the creation of treatment cycles in the system',
            label: 'Enable creation of treatment cycles',
          },
          crmCycleEditor: {
            title: 'Edit treatment cycles',
            description: 'This option enables editing of treatment cycles in the system',
            label: 'Enable editing of treatment cycles',
          },
          crmCycleDestructor: {
            title: 'Delete treatment cycles',
            description: 'This option enables the deletion of treatment cycles in the system',
            label: 'Enable deletion of treatment cycles',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'This option enables access to the OVOClinic module',
            label: 'Enable access',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'This option enables access to the OVOBoard module',
            label: 'Enable access',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'This option enables access to the OVOTory module',
            label: 'Enable access',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'This option enables access to the OVOProfile module',
            label: 'Enable access',
          },
          status: {
            title: 'Active role',
            description: 'Enable or disable the role in the system',
            label: 'Active role',
          },
          submit: 'Create role',
        },
      },
      update: {
        title: 'Update role',
        subtitle: 'Modifying the data of a role in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the role',
          },
          general: {
            accordion: 'General',
            title: 'General permissions',
            description: 'General permissions for the role',
          },
          viewDisabledRecords: {
            title: 'View disabled logs',
            description: 'This option enables the display of disabled logs',
            label: 'Enable display of disabled logs',
          },
          viewDisabledLeadRecords: {
            title: 'View disabled leads',
            description: 'This option enables the display of disabled leads',
            label: 'Enable display of disabled leads',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'View disabled patients',
              ovobank: 'View disabled donors',
            },
            description: {
              ovoclinic: 'This option enables the display of disabled patients',
              ovobank: 'This option enables the display of disabled donors',
            },
            label: {
              ovoclinic: 'Enable display of disabled patients',
              ovobank: 'Enable display of disabled donors',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permission to the OVOAccess module',
            description: 'This option enables access to the OVOAccess module',
            label: 'Enable access',
          },
          user: {
            accordion: 'Users',
          },
          accessUser: {
            title: 'See users',
            description: 'This option enables the display of users in the system',
            label: 'Enable user display',
          },
          accessUserCreator: {
            title: 'Create users',
            description: 'This option enables the creation of users in the system',
            label: 'Enable user creation',
          },
          accessUserEditor: {
            title: 'Edit users',
            description: 'This option enables editing of users in the system',
            label: 'Enable user editing',
          },
          accessUserDestructor: {
            title: 'Delete users',
            description: 'This option enables the deletion of users in the system',
            label: 'Enable user deletion',
          },
          accessUserChangePassword: {
            title: 'Change password',
            description: 'This option enables changing the password of users in the system',
            label: 'Enable password change',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'View roles',
            description: 'This option enables the display of roles in the system',
            label: 'Enable role display',
          },
          accessRoleCreator: {
            title: 'Create roles',
            description: 'This option enables the creation of roles in the system',
            label: 'Enable role creation',
          },
          accessRoleEditor: {
            title: 'Edit roles',
            description: 'This option enables editing of roles in the system',
            label: 'Enable role editing',
          },
          accessRoleDestructor: {
            title: 'Delete roles',
            description: 'This option enables the deletion of roles in the system',
            label: 'Enable role deletion',
          },
          timetable: {
            accordion: 'Schedules',
          },
          accessTimetable: {
            title: 'See schedules',
            description: 'This option enables the display of schedules in the system',
            label: 'Enable schedule display',
          },
          accessTimetableCreator: {
            title: 'Create schedules',
            description: 'This option enables the creation of schedules in the system',
            label: 'Enable schedule creation',
          },
          accessTimetableEditor: {
            title: 'Edit schedules',
            description: 'This option enables the editing of schedules in the system',
            label: 'Enable schedule editing',
          },
          accessTimetableDestructor: {
            title: 'Delete schedules',
            description: 'This option enables the deletion of schedules in the system',
            label: 'Enable schedule deletion',
          },
          bulletin: {
            accordion: 'News',
          },
          accessBulletin: {
            title: 'See news',
            description: 'This option enables the display of news in the system',
            label: 'Enable news display',
          },
          accessBulletinCreator: {
            title: 'Create news',
            description: 'This option enables the creation of news in the system',
            label: 'Enable news creation',
          },
          accessBulletinEditor: {
            title: 'Edit news',
            description: 'This option enables editing of news in the system',
            label: 'Enable news editing',
          },
          accessBulletinDestructor: {
            title: 'Delete news',
            description: 'This option enables the deletion of news in the system',
            label: 'Enable news deletion',
          },
          accessClinic: {
            title: 'See clinics',
            description: 'This option enables the visualization of clinics in the system',
            label: 'Enable clinic viewing',
          },
          accessClinicCreator: {
            title: 'Create clinics',
            description: 'This option enables the creation of clinics in the system',
            label: 'Enable clinic creation',
          },
          accessClinicEditor: {
            title: 'Edit clinics',
            description: 'This option enables editing of clinics in the system',
            label: 'Enable clinic editing',
          },
          accessClinicDestructor: {
            title: 'Delete clinics',
            description: 'This option enables the deletion of clinics in the system',
            label: 'Enable clinic deletion',
          },
          department: {
            accordion: 'Departments',
          },
          accessDepartment: {
            title: 'See departments',
            description: 'This option enables the visualization of the departments in the system',
            label: 'Enable department display',
          },
          accessDepartmentCreator: {
            title: 'Create departments',
            description: 'This option enables the creation of departments in the system',
            label: 'Enable department creation',
          },
          accessDepartmentEditor: {
            title: 'Edit departments',
            description: 'This option enables the editing of departments in the system',
            label: 'Enable department editing',
          },
          accessDepartmentDestructor: {
            title: 'Delete departments',
            description: 'This option enables the deletion of departments in the system',
            label: 'Enable deletion of departments',
          },
          language: {
            accordion: 'Languages',
          },
          accessLanguage: {
            title: 'See languages',
            description: 'This option enables the display of languages ​​in the system',
            label: 'Enable language display',
          },
          accessLanguageCreator: {
            title: 'Create languages',
            description: 'This option enables the creation of languages ​​in the system',
            label: 'Enable language creation',
          },
          accessLanguageEditor: {
            title: 'Edit languages',
            description: 'This option enables editing of languages ​​in the system',
            label: 'Enable language editing',
          },
          accessLanguageDestructor: {
            title: 'Delete languages',
            description: 'This option enables the removal of languages ​​on the system',
            label: 'Enable language removal',
          },
          country: {
            accordion: 'Countries',
          },
          accessCountry: {
            title: 'See countries',
            description: 'This option enables the display of countries in the system',
            label: 'Enable country display',
          },
          accessCountryCreator: {
            title: 'Create countries',
            description: 'This option enables the creation of countries in the system',
            label: 'Enable country creation',
          },
          accessCountryEditor: {
            title: 'Edit countries',
            description: 'This option enables the editing of countries in the system',
            label: 'Enable country editing',
          },
          accessCountryDestructor: {
            title: 'Delete countries',
            description: 'This option enables the deletion of countries in the system',
            label: 'Enable country deletion',
          },
          accessSettings: {
            title: 'View settings',
            description: 'This option enables the display of settings in the system',
            label: 'Enable configuration display',
          },
          specialists: {
            accordion: 'Specialists',
          },
          accessSpecialist: {
            title: 'See specialists',
            description: 'This option enables the display of specialists in the system',
            label: 'Enable viewing of specialists',
          },
          accessSpecialistCreator: {
            title: 'Create specialists',
            description: 'This option enables the creation of specialists in the system',
            label: 'Enable creation of specialists',
          },
          accessSpecialistEditor: {
            title: 'Edit specialists',
            description: 'This option enables the editing of specialists in the system',
            label: 'Enable specialist editing',
          },
          accessSpecialistDestructor: {
            title: 'Eliminate specialists',
            description: 'This option enables the removal of specialists from the system',
            label: 'Enable specialist removal',
          },
          commsChannels: {
            accordion: 'Communication channels',
          },
          accessCommsChannel: {
            title: 'See communication channels',
            description:
              'This option enables the visualization of communication channels in the system',
            label: 'Enable visualization of communication channels',
          },
          accessCommsChannelCreator: {
            title: 'Create communication channels',
            description: 'This option enables the creation of communication channels in the system',
            label: 'Enable creation of communication channels',
          },
          accessCommsChannelEditor: {
            title: 'Edit communication channels',
            description: 'This option enables the editing of communication channels in the system',
            label: 'Enable editing of communication channels',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminate communication channels',
            description:
              'This option enables the elimination of communication channels in the system',
            label: 'Enable deletion of communication channels',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'This option enables the display of ovopoints in the system',
            label: 'Enable display of ovopoints',
          },
          accessOvopointEditor: {
            title: 'Edit ovopoints',
            description: 'This option enables the editing of ovopoints in the system',
            label: 'Enable ovopoint editing',
          },
          templates: {
            accordion: 'Templates',
          },
          accessTemplate: {
            title: 'See WhatsApp templates',
            description: 'This option enables the display of WhatsApp templates',
            label: 'Enable template display',
          },
          accessTemplateCreator: {
            title: 'Create WhatsApp templates',
            description: 'This option enables the creation of WhatsApp templates',
            label: 'Enable templating',
          },
          accessTemplateEditor: {
            title: 'Edit WhatsApp templates',
            description: 'This option enables editing of WhatsApp templates',
            label: 'Enable template editing',
          },
          accessTemplateDestructor: {
            title: 'Delete WhatsApp templates',
            description: 'This option enables the deletion of WhatsApp templates',
            label: 'Enable template deletion',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Application permission',
            description: 'This option enables access to the OVOCrm module',
            label: 'Enable access',
          },
          tasks: {
            accordion: 'Tasks',
          },
          crmCalendar: {
            title: 'Calendar',
            description: 'This option enables calendar display on the system',
            label: 'Enable calendar display',
          },
          crmTask: {
            title: 'Tasks',
            description: 'This option enables the display of tasks in the system',
            label: 'Enable task display',
          },
          crmTaskCreator: {
            title: 'Create tasks',
            description: 'This option enables the creation of tasks in the system',
            label: 'Enable task creation',
          },
          crmTaskEditor: {
            title: 'Edit tasks',
            description: 'This option enables editing of tasks in the system',
            label: 'Enable task editing',
          },
          crmTaskDestructor: {
            title: 'Delete tasks',
            description: 'This option enables the deletion of tasks in the system',
            label: 'Enable task deletion',
          },
          crmTaskSeeAll: {
            title: 'See all tasks',
            description: 'This option enables the display of all tasks in the system',
            label: 'Enable display of all tasks',
          },
          crmTaskSeeCoworkers: {
            title: "View colleagues' tasks",
            description: "This option enables the display of colleagues' tasks in the system",
            label: 'Enable viewing of peer tasks',
          },
          crmTaskUpdateResponsible: {
            title: 'Update the person assigned to a task',
            description: 'This option enables changing the person responsible for a task',
            label: 'Enable task manager editing',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'This option enables the visualization of leads in the system',
            label: 'Enable lead visualization',
          },
          crmLeadCreator: {
            title: 'Create leads',
            description: 'This option enables the creation of leads in the system',
            label: 'Enable lead creation',
          },
          crmLeadEditor: {
            title: 'Edit leads',
            description: 'This option enables the editing of leads in the system',
            label: 'Enable lead editing',
          },
          crmLeadDestructor: {
            title: 'Delete leads',
            description: 'This option enables the deletion of leads in the system',
            label: 'Enable lead deletion',
          },
          crmLeadSeeAll: {
            title: 'See all leads',
            description: 'This option enables the display of all leads in the system',
            label: 'Enable display of all leads',
          },
          crmLeadSeeCoworkers: {
            title: 'See leads from colleagues',
            description: "This option enables the display of colleagues' leads",
            label: "Enable visualization of colleagues' leads",
          },
          crmLeadUpdateCampaign: {
            title: 'Update campaign assigned to leads',
            description: 'This option enables the change of multiple leads campaign',
            label: 'Enable lead campaign editing',
          },
          patients: {
            accordion: {
              ovoclinic: 'Patients',
              ovobank: 'Donors',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Patients',
              ovobank: 'Donors',
            },
            description: {
              ovoclinic: 'This option enables the visualization of patients in the system',
              ovobank: 'This option enables the display of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient viewing',
              ovobank: 'Enable donor display',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Create patients',
              ovobank: 'Create donors',
            },
            description: {
              ovoclinic: 'This option enables the creation of patients in the system',
              ovobank: 'This option enables the creation of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient creation',
              ovobank: 'Enable donor creation',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Edit patients',
              ovobank: 'Edit donors',
            },
            description: {
              ovoclinic: 'This option enables editing of patients in the system',
              ovobank: 'This option enables editing of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient editing',
              ovobank: 'Enable donor editing',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Delete patients',
              ovobank: 'Delete donors',
            },
            description: {
              ovoclinic: 'This option enables the deletion of patients in the system',
              ovobank: 'This option enables the removal of donors in the system',
            },
            label: {
              ovoclinic: 'Enable patient deletion',
              ovobank: 'Enable donor removal',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'See all patients',
              ovobank: 'See all donors',
            },
            description: {
              ovoclinic: 'This option enables viewing of all patients in the system',
              ovobank: 'This option enables the display of all donors in the system',
            },
            label: {
              ovoclinic: 'Enable viewing of all patients',
              ovobank: 'Enable display of all donors',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'See patients of colleagues',
              ovobank: 'View peer donors',
            },
            description: {
              ovoclinic: 'This option enables viewing of peer patients',
              ovobank: 'This option enables the display of peer donors',
            },
            label: {
              ovoclinic: 'Enable viewing of patients from colleagues',
              ovobank: 'Enable viewing of peer donors',
            },
          },
          contacts: {
            accordion: 'Contacts',
          },
          crmContact: {
            title: 'Contacts',
            description: 'This option enables the display of contacts in the system',
            label: 'Enable contact display',
          },
          crmContactCreator: {
            title: 'Create contacts',
            description: 'This option enables the creation of contacts in the system',
            label: 'Enable contact creation',
          },
          crmContactEditor: {
            title: 'Edit contacts',
            description: 'This option enables editing of contacts in the system',
            label: 'Enable contact editing',
          },
          crmContactDestructor: {
            title: 'Delete contacts',
            description: 'This option enables deletion of contacts in the system',
            label: 'Enable contact deletion',
          },
          activity: {
            accordion: 'Activities',
          },
          crmActivity: {
            title: 'Activities',
            description: 'This option enables the visualization of activities in the system',
            label: 'Enable activity viewing',
          },
          crmActivityCreator: {
            title: 'Create activities',
            description: 'This option enables the creation of activities in the system',
            label: 'Enable activity creation',
          },
          crmActivityEditor: {
            title: 'Edit activities',
            description: 'This option enables editing of activities in the system',
            label: 'Enable activity editing',
          },
          crmActivityDestructor: {
            title: 'Delete activities',
            description: 'This option enables the deletion of activities in the system',
            label: 'Enable deletion of activities',
          },
          crmActivitySeeAll: {
            title: 'See all activities',
            description: 'This option enables viewing of all activities in the system',
            label: 'Enable viewing of all activities',
          },
          results: {
            accordion: 'Results',
          },
          crmResult: {
            title: 'Results',
            description: 'This option enables the display of results in the system',
            label: 'Enable results display',
          },
          crmResultCreator: {
            title: 'Create results',
            description: 'This option enables the creation of the results in the system',
            label: 'Enable result creation',
          },
          crmResultEditor: {
            title: 'Edit results',
            description: 'This option enables editing of results in the system',
            label: 'Enable results editing',
          },
          crmResultDestructor: {
            title: 'Delete results',
            description: 'This option enables deletion of results in the system',
            label: 'Enable deletion of results',
          },
          crmResultCCEvent: {
            title: 'Edit Call Center milestones',
            description: 'This option enables the editing of Call Center milestones in the system',
            label: 'Enable Call Center milestone editing',
          },
          crmResultATPCEvent: {
            title: 'Edit ATP Commercial milestones',
            description:
              'This option enables the editing of ATP Comercial milestones in the system',
            label: 'Enable ATP Commercial milestone editing',
          },
          crmResultATPIEvent: {
            title: 'Edit ATP International milestones',
            description:
              'This option enables the editing of ATP International milestones in the system',
            label: 'Enable editing of ATP International milestones',
          },
          crmResultReactivity: {
            title: 'Edit reactivity',
            description: 'This option enables editing of reactivity in the system',
            label: 'Enable reactivity editing',
          },
          reasons: {
            accordion: 'Reasons',
          },
          crmReason: {
            title: 'Reasons',
            description: 'This option enables the display of reasons in the system',
            label: 'Enable reason display',
          },
          crmReasonCreator: {
            title: 'Create reasons',
            description: 'This option enables the creation of reasons in the system',
            label: 'Enable reason creation',
          },
          crmReasonEditor: {
            title: 'Edit reasons',
            description: 'This option enables editing of reasons in the system',
            label: 'Enable reason editing',
          },
          crmReasonDestructor: {
            title: 'Delete reasons',
            description: 'This option enables deletion of reasons in the system',
            label: 'Enable reason deletion',
          },
          campaigns: {
            accordion: 'Campaigns',
          },
          crmCampaign: {
            title: 'Campaigns',
            description: 'This option enables the visualization of campaigns in the system',
            label: 'Enable campaign viewing',
          },
          crmCampaignCreator: {
            title: 'Create campaigns',
            description: 'This option enables the creation of campaigns in the system',
            label: 'Enable campaign creation',
          },
          crmCampaignEditor: {
            title: 'Edit campaigns',
            description: 'This option enables the editing of campaigns in the system',
            label: 'Enable campaign editing',
          },
          crmCampaignDestructor: {
            title: 'Delete campaigns',
            description: 'This option enables the deletion of campaigns in the system',
            label: 'Enable campaign deletion',
          },
          funnels: {
            accordion: 'Funnels',
          },
          crmFunnel: {
            title: 'Funnels',
            description: 'This option enables the visualization of funnels in the system',
            label: 'Enable funnel visualization',
          },
          crmFunnelCreator: {
            title: 'Create funnels',
            description: 'This option enables the creation of funnels in the system',
            label: 'Enable funnel creation',
          },
          crmFunnelEditor: {
            title: 'Edit funnels',
            description: 'This option enables editing of funnels in the system',
            label: 'Enable funnel editing',
          },
          crmFunnelDestructor: {
            title: 'Delete funnels',
            description: 'This option enables the removal of funnels in the system',
            label: 'Enable funnel removal',
          },
          channels: {
            accordion: 'Channels',
          },
          crmChannel: {
            title: 'Channels',
            description: 'This option enables the viewing of channels on the system',
            label: 'Enable channel display',
          },
          crmChannelCreator: {
            title: 'Create channels',
            description: 'This option enables the creation of channels in the system',
            label: 'Enable channel creation',
          },
          crmChannelEditor: {
            title: 'Edit channels',
            description: 'This option enables editing of channels in the system',
            label: 'Enable channel editing',
          },
          crmChannelDestructor: {
            title: 'Delete channels',
            description: 'This option enables the deletion of channels in the system',
            label: 'Enable channel deletion',
          },
          programs: {
            accordion: 'Treatments',
          },
          crmProgram: {
            title: 'Treatments',
            description: 'This option enables the display of catalog treatments in the system',
            label: 'Enable display of catalog treatments',
          },
          crmProgramCreator: {
            title: 'Create treatments',
            description: 'This option enables the creation of treatments in the system',
            label: 'Enable treatment creation',
          },
          crmProgramEditor: {
            title: 'Edit treatments',
            description: 'This option enables the editing of treatments in the system',
            label: 'Enable treatment editing',
          },
          crmProgramDestructor: {
            title: 'Delete treatments',
            description: 'This option enables the deletion of treatments in the system',
            label: 'Enable treatment removal',
          },
          services: {
            accordion: 'Services',
          },
          crmService: {
            title: 'Services',
            description: 'This option enables the visualization of services in the system',
            label: 'Enable service display',
          },
          crmServiceCreator: {
            title: 'Create services',
            description: 'This option enables the creation of services in the system',
            label: 'Enable service creation',
          },
          crmServiceEditor: {
            title: 'Edit services',
            description: 'This option enables editing of services in the system',
            label: 'Enable service editing',
          },
          crmServiceDestructor: {
            title: 'Delete services',
            description: 'This option enables the removal of services on the system',
            label: 'Enable service removal',
          },
          techniques: {
            accordion: 'Techniques',
          },
          crmTechnique: {
            title: 'Complementary techniques',
            description:
              'This option enables the visualization of complementary techniques in the system',
            label: 'Enable visualization of complementary techniques',
          },
          crmTechniqueCreator: {
            title: 'Create complementary techniques',
            description:
              'This option enables the creation of complementary techniques in the system',
            label: 'Enable creation of complementary techniques',
          },
          crmTechniqueEditor: {
            title: 'Edit complementary techniques',
            description:
              'This option enables the editing of complementary techniques in the system',
            label: 'Enable editing of complementary techniques',
          },
          crmTechniqueDestructor: {
            title: 'Eliminate complementary techniques',
            description:
              'This option enables the removal of complementary techniques in the system',
            label: 'Enable removal of add-on techniques',
          },
          maintenances: {
            accordion: 'Maintenance',
          },
          crmMaintenance: {
            title: 'Maintenance',
            description: 'This option enables the visualization of maintenance in the system',
            label: 'Enable maintenance visualization',
          },
          crmMaintenanceCreator: {
            title: 'Create maintenance',
            description: 'This option enables the creation of maintenance in the system',
            label: 'Enable maintenance creation',
          },
          crmMaintenanceEditor: {
            title: 'Edit maintenance',
            description: 'This option enables editing of maintenance in the system',
            label: 'Enable maintenance editing',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminate maintenance',
            description: 'This option enables the elimination of maintenance in the system',
            label: 'Enable maintenance removal',
          },
          treatments: {
            accordion: 'Treatments',
          },
          crmTreatment: {
            title: 'Treatments',
            description: 'This option enables the visualization of treatments in the system',
            label: 'Enable visualization of treatments',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Create treatments for patient',
              ovobank: 'Create donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the creation of patient treatments in the system',
              ovobank: 'This option enables the creation of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable creation of treatments for patient',
              ovobank: 'Enable creation of treatments for donor',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Edit treatments for patient',
              ovobank: 'Edit donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the editing of patient treatments in the system',
              ovobank: 'This option enables the editing of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable editing of treatments for patient',
              ovobank: 'Enable treatment editing for donor',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Delete treatments for patient',
              ovobank: 'Delete donor treatments',
            },
            description: {
              ovoclinic: 'This option enables the deletion of patient treatments in the system',
              ovobank: 'This option enables the deletion of donor treatments in the system',
            },
            label: {
              ovoclinic: 'Enable deletion of treatments for patient',
              ovobank: 'Enable deletion of treatments for donor',
            },
          },
          phases: {
            accordion: 'Stages',
          },
          crmPhase: {
            title: 'Stages',
            description: 'This option enables the visualization of the stages in the system',
            label: 'Enable stage visualization',
          },
          crmPhaseCreator: {
            title: 'Create stages',
            description: 'This option enables the creation of stages in the system',
            label: 'Enable staging',
          },
          crmPhaseEditor: {
            title: 'Edit stages',
            description: 'This option enables the editing of the stages in the system',
            label: 'Enable stage editing',
          },
          crmPhaseDestructor: {
            title: 'Delete stages',
            description: 'This option enables the elimination of stages in the system',
            label: 'Enable stage deletion',
          },
          causes: {
            accordion: 'Reasons',
          },
          crmCause: {
            title: 'Reasons for cancellation',
            description: 'This option enables the display of cancellation reasons in the system',
            label: 'Enable display of cancellation reasons',
          },
          crmCauseCreator: {
            title: 'Create cancellation reasons',
            description: 'This option enables the creation of cancellation reasons in the system',
            label: 'Enable creation of cancellation reasons',
          },
          crmCauseEditor: {
            title: 'Edit cancellation reasons',
            description: 'This option enables editing of cancellation reasons in the system',
            label: 'Enable editing of cancellation reasons',
          },
          crmCauseDestructor: {
            title: 'Eliminate cancellation reasons',
            description: 'This option enables the removal of cancellation reasons in the system',
            label: 'Enable removal of cancellation reasons',
          },
          cycles: {
            accordion: 'Treatment cycles',
          },
          crmCycle: {
            title: 'Treatment cycles',
            description: 'This option enables the visualization of treatment cycles in the system',
            label: 'Enable visualization of treatment cycles',
          },
          crmCycleCreator: {
            title: 'Create treatment cycles',
            description: 'This option enables the creation of treatment cycles in the system',
            label: 'Enable creation of treatment cycles',
          },
          crmCycleEditor: {
            title: 'Edit treatment cycles',
            description: 'This option enables editing of treatment cycles in the system',
            label: 'Enable editing of treatment cycles',
          },
          crmCycleDestructor: {
            title: 'Delete treatment cycles',
            description: 'This option enables the deletion of treatment cycles in the system',
            label: 'Enable deletion of treatment cycles',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'This option enables access to the OVOClinic module',
            label: 'Enable access',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'This option enables access to the OVOBoard module',
            label: 'Enable access',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'This option enables access to the OVOTory module',
            label: 'Enable access',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'This option enables access to the OVOProfile module',
            label: 'Enable access',
          },
          status: {
            title: 'Active role',
            description: 'Enable or disable the role in the system',
            label: 'Active role',
          },
          submit: 'Update role',
        },
      },
      delete: {
        title: 'Delete role',
        subtitle: 'This action is irreversible',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to disable this role',
            label: 'Yes, I want to delete this role',
          },
          submit: 'Delete role',
        },
      },
      enable: {
        title: 'Enable role',
        subtitle: 'This action allows the role to be active in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable it?',
            description: 'This action will allow the role to be active in the system',
            label: 'Yes, I want to enable this role',
          },
          submit: 'Enable role',
        },
      },
      disable: {
        title: 'Disable role',
        subtitle: 'This action disables the role in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable it?',
            description: 'This action will disable the role in the system',
            label: 'Yes, I want to disable this role',
          },
          submit: 'Disable role',
        },
      },
    },
    clinics: {
      list: {
        title: 'Clinics',
        description: 'Clinic management',
        create: 'Clinic',
        one: 'Clinic',
        many: 'Clinics',
        columns: {
          name: 'Name',
          city: 'City',
          document: 'CIF',
        },
      },
      create: {
        title: 'New clinic',
        subtitle: 'Registration of a new clinic in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the clinic',
          },
          country: {
            title: 'Country',
            description: 'Country where clinic is located',
          },
          state: {
            title: 'State',
            description: 'State or province of the clinic',
          },
          city: {
            title: 'City',
            description: 'City where the clinic is located',
          },
          street: {
            title: 'Street',
            description: 'Street where the clinic is located',
          },
          zip: {
            title: 'Zip code',
            description: 'Clinic zip code',
          },
          timezone: {
            title: 'time zone',
            description: 'Clinic time zone',
          },
          center: {
            title: 'Location',
            description: 'Clinic Location',
          },
          status: {
            title: 'Active clinic',
            description: {
              ovoclinic: 'Enable or disable the clinic in the system for patient use',
              ovobank: 'Enable or disable the clinic in the system for donor use',
            },
            label: 'Active clinic',
          },
          avatar: {
            title: 'Avatar',
            description: 'Associated image/Avatar of the clinic. ',
          },
          document: {
            title: 'Document',
            description: 'CIF, NIF or clinic identification document',
          },
          title: {
            title: 'Qualification',
            description: 'Clinic title',
          },
          description: {
            title: 'Description',
            description: 'Clinic Description',
          },
          color: {
            title: 'Color',
            description: 'Color associated with the clinic in the system.',
          },
          submit: 'Create clinic',
        },
      },
      update: {
        title: 'Update clinic',
        subtitle: 'Modification of clinic data in the system',
        tabs: {
          details: {
            title: 'Details',
            description:
              'Clinics are first-level groupings of the system and serve to group user activity. ',
          },
          departments: {
            title: 'Departments',
            description:
              "The main objective of the departments is to divide the clinic's activity for better organization and control. ",
            add: 'Add department',
          },
        },
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the clinic',
          },
          country: {
            title: 'Country',
            description: 'Country where clinic is located',
          },
          state: {
            title: 'State',
            description: 'State or province of the clinic',
          },
          city: {
            title: 'City',
            description: 'City where the clinic is located',
          },
          street: {
            title: 'Street',
            description: 'Street where the clinic is located',
          },
          zip: {
            title: 'Zip code',
            description: 'Clinic zip code',
          },
          timezone: {
            title: 'time zone',
            description: 'Clinic time zone',
          },
          center: {
            title: 'Location',
            description: 'Clinic Location',
          },
          status: {
            title: {
              ovoclinic: 'Active clinic',
              ovobank: 'Active clinic',
            },
            description: {
              ovoclinic: 'Enable or disable the clinic in the system for patient use',
              ovobank: 'Enable or disable the clinic in the system for donor use',
            },
            label: {
              ovoclinic: 'Active clinic',
              ovobank: 'Active clinic',
            },
          },
          avatar: {
            title: 'Avatar',
            description: 'Associated image/Avatar of the clinic. ',
          },
          document: {
            title: 'Document',
            description: 'CIF, NIF or clinic identification document',
          },
          title: {
            title: 'Qualification',
            description: 'Clinic title',
          },
          description: {
            title: 'Description',
            description: 'Clinic Description',
          },
          color: {
            title: 'Color',
            description: 'Color associated with the clinic in the system',
          },
          submit: 'Update clinic',
        },
      },
      disable: {
        title: 'Disable clinic',
        subtitle: 'Disable clinic in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this clinic?',
            description: 'Disabling a clinic means that it cannot be used in the system',
            label: 'Yes, I want to disable this clinic',
          },
          submit: 'Disable clinic',
        },
      },
      enable: {
        title: 'Enable clinic',
        subtitle: 'Enable clinic in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this clinic?',
            description: 'Enabling a clinic means that it can be used in the system',
            label: 'Yes, I want to enable this clinic',
          },
          submit: 'Enable clinic',
        },
      },
      delete: {
        title: 'Disable clinic',
        subtitle: 'Disable clinic in the system',
        button: 'Disable',
        form: {
          status: {
            title: 'Are you sure you want to disable this clinic?',
            description: 'Disabling a clinic means that it cannot be used in the system',
            label: 'Yes, I want to disable this clinic',
          },
          submit: 'Disable clinic',
        },
        passwordForm: {
          title: 'Enter your password',
          subtitle: 'To disable the clinic, enter your password',
          password: {
            title: 'Password',
            description: 'Password of the user who performs the action',
          },
          submit: 'Confirm',
        },
      },
    },
    licenses: {
      list: {
        one: 'License',
        many: 'Licenses',
      },
    },
    movements: {
      list: {
        one: 'Motion',
        many: 'Movements',
      },
    },
    attempts: {
      list: {
        one: 'Tried',
        many: 'Attempts',
      },
    },
    contracts: {
      list: {
        one: 'Contract',
        many: 'Contracts',
      },
    },
    timetables: {
      list: {
        title: 'Schedules',
        description: 'Management of user schedules',
        create: 'Schedule',
        one: 'Schedule',
        many: 'Schedules',
        columns: {
          name: 'Name',
          day: 'Day',
          range: 'Range',
        },
      },
      create: {
        title: 'New schedule',
        subtitle: 'Registration of a new schedule in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the schedule',
          },
          day: {
            title: 'Day',
            description: 'Day of the week on which the schedule applies',
          },
          started: {
            title: 'Start',
            description: 'Schedule start time',
          },
          ended: {
            title: 'End',
            description: 'Schedule end time',
          },
          submit: 'Create schedule',
        },
      },
      update: {
        title: 'Update schedule',
        subtitle: 'Modifying schedule data in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the schedule',
          },
          submit: 'Save schedule',
        },
        tabs: {
          timetable: {
            title: 'Schedule',
            description: 'Schedule details',
          },
          timetableDay: {
            title: 'Day',
            description: 'Days of the week on which the schedule applies',
          },
        },
        timetableDays: {
          create: {
            title: 'Add day',
            subtitle: 'Add day to schedule',
            form: {
              day: {
                title: 'Day',
                description: 'Day of the week on which the schedule applies',
              },
              started: {
                title: 'Start',
                description: 'Schedule start time',
              },
              ended: {
                title: 'End',
                description: 'Schedule end time',
              },
              submit: 'Add day',
            },
          },
          update: {
            title: 'Update day',
            subtitle: 'Modifying the data of a day in the schedule',
            form: {
              day: {
                title: 'Day',
                description: 'Day of the week on which the schedule applies',
              },
              started: {
                title: 'Start',
                description: 'Schedule start time',
              },
              ended: {
                title: 'End',
                description: 'Schedule end time',
              },
              submit: 'Update day',
            },
          },
          delete: {
            title: 'Delete day',
            subtitle: 'Delete day from schedule',
            form: {
              status: {
                title: 'Are you sure you want to delete this day?',
                label: 'Yes, I want to delete this day',
              },
              submit: 'Delete day',
            },
          },
          add: 'Add day',
        },
      },
      delete: {
        title: 'Delete schedule',
        subtitle: 'Disable schedule in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this schedule?',
            description: 'Disabling a schedule means that it cannot be used in the system',
            label: 'Yes, I want to disable this schedule',
          },
          submit: 'Disable schedule',
        },
      },
      disable: {
        title: 'Disable schedule',
        subtitle: 'Disable schedule in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this schedule?',
            description: 'Disabling a schedule means that it cannot be used in the system',
            label: 'Yes, I want to disable this schedule',
          },
          submit: 'Disable schedule',
        },
      },
      enable: {
        title: 'Enable schedule',
        subtitle: 'Enable schedule in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this schedule?',
            description: 'Enabling a schedule means that it can be used in the system',
            label: 'Yes, I want to enable this schedule',
          },
          submit: 'Enable schedule',
        },
      },
    },
    departments: {
      list: {
        title: 'Departments',
        description: 'Department management',
        create: 'Department',
        one: 'Department',
        many: 'Departments',
        columns: {
          name: 'Name',
          responsible: 'Responsible',
          language: 'Language',
          country: 'Country',
          clinic: 'Clinic',
          created: 'Creation',
        },
      },
      create: {
        title: 'New department',
        subtitle: 'Registration of a new department in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the department',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic to which the department belongs',
          },
          status: {
            title: 'Active department',
            description: 'Enable or disable the department in the system',
            label: 'Active department',
          },
          languageId: {
            title: 'Language',
            description: 'Department language',
          },
          responsibleId: {
            title: 'Responsible',
            description: 'User responsible for the department',
          },
          submit: 'Create',
        },
      },
      enable: {
        title: 'Enable department',
        subtitle: 'Enable department in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this department?',
            label: 'Yes, I want to enable this department',
          },
          submit: 'Enable department',
        },
      },
      disable: {
        title: 'Disable department',
        subtitle: 'Disable department in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this department?',
            label: 'Yes, I want to disable this department',
          },
          submit: 'Disable department',
        },
      },
      update: {
        title: 'Update department',
        subtitle: 'Modifying the data of a department in the system',
        department: {
          tab: 'Department',
          description: 'Department details',
        },
        clinic: {
          tab: 'Clinic',
          description: 'Clinic to which the department belongs',
        },
        responsible: {
          tab: 'Responsible',
          description: 'Department Manager',
        },
        language: {
          tab: 'Language',
          description: 'Department language',
        },
        tabs: {
          department: {
            title: 'Department',
            description: 'Department details',
          },
          members: {
            title: 'Members',
            description: 'Department members. ',
          },
        },
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the department',
          },
          languageId: {
            title: 'Language',
            description: 'Department language',
          },
          responsibleId: {
            title: 'Responsible',
            description: 'User responsible for the department',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic to which the department belongs',
          },
          status: {
            title: 'Active department',
            description: 'Enable or disable the department in the system',
            label: 'Active department',
          },
          submit: 'Update department',
        },
        members: {
          one: 'Member',
          many: 'Members',
          add: 'Add member',
          create: {
            title: 'Add member',
            subtitle: 'A member is a user who belongs to a department. ',
            form: {
              contractId: {
                title: 'User',
              },
              submit: 'Add member',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Delete member',
        subtitle: 'Delete member from department. ',
        form: {
          submit: 'Delete member',
        },
      },
    },
    countries: {
      list: {
        title: 'Countries',
        description: 'Country management',
        create: 'Country',
        one: 'Country',
        many: 'Countries',
        columns: {
          name: 'Name',
          code: 'Code',
          coin: 'Currency',
          updated: 'Update',
          created: 'Creation',
        },
      },
      update: {
        title: 'Edit country',
        subtitle: 'Edit country in the system',
        form: {
          countryNameEn: {
            title: 'English name',
            description: 'English name of the country',
          },
          countryNameEs: {
            title: 'Spanish name',
            description: 'Spanish name of the country',
          },
          flag: {
            title: 'Flag',
            description: 'Country flag represented with an emoticon',
          },
          coin: {
            title: 'Currency',
            description: 'Currency used in the country',
          },
          code: {
            title: 'country code',
            description: 'Country code represented in 2 characters (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefix',
            description: 'Country telephone code',
          },
          center: {
            title: 'Location',
            description: 'Country location',
          },
          geometry: {
            title: 'Geometry in json format',
            description: 'Country geometry in json format',
          },
          latitude: {
            title: 'Latitude coordinate',
            description: 'Latitude coordinate of the country',
          },
          longitude: {
            title: 'Length coordinate',
            description: 'Longitude coordinate of the country',
          },
          description: {
            title: 'Description',
            description: 'Country Description',
          },
          color: {
            title: 'Color format #ffffff, network, rgb(0,0,0)',
            description: 'Country color in hexadecimal, RGB or name in English format',
          },
          submit: 'Save Country',
        },
      },
      delete: {
        title: 'Delete country',
        subtitle: 'Disable country in the system',
        form: {
          submit: 'Delete country',
        },
      },
      disable: {
        title: 'Disable country',
        subtitle: 'Disable country in the system',
        form: {
          submit: 'Disable country',
        },
      },
      enable: {
        title: 'Enable country',
        subtitle: 'Enable country in the system',
        form: {
          submit: 'Enable country',
        },
      },
      create: {
        title: 'new country',
        subtitle: 'Registration of a new country in the system',
        form: {
          countryNameEn: {
            title: 'English name',
            description: 'English name of the country',
          },
          countryNameEs: {
            title: 'Spanish name',
            description: 'Spanish name of the country',
          },
          flag: {
            title: 'Flag',
            description: 'Country flag represented with an emoticon',
          },
          coin: {
            title: 'Currency',
            description: 'Currency used in the country',
          },
          code: {
            title: 'country code',
            description: 'Country code represented in 2 characters (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefix',
            description: 'Country telephone code',
          },
          center: {
            title: 'Location',
            description: 'Country location',
          },
          geometry: {
            title: 'Geometry in json format',
            description: 'Country geometry in json format',
          },
          latitude: {
            title: 'Latitude coordinate',
            description: 'Latitude coordinate of the country',
          },
          longitude: {
            title: 'Length coordinate',
            description: 'Longitude coordinate of the country',
          },
          description: {
            title: 'Description',
            description: 'Country Description',
          },
          color: {
            title: 'Color format #ffffff, network, rgb(0,0,0)',
            description: 'Country color in hexadecimal, RGB or name in English format',
          },
          submit: 'Create Country',
        },
      },
    },
    access: {
      create: {
        title: 'New access',
        subtitle: 'Registration of a new access in the system',
        form: {
          ip: {
            title: 'IP address',
            description: 'IP address of the device accessing the system',
          },
          status: {
            title: 'System access',
            description: 'Enable or disable system access for this user and this ip',
            label: 'Allow access to the system',
          },
          submit: 'Create access',
        },
      },
    },
    settings: {
      title: 'Configuration',
      subtitle: 'Platform configuration',
      form: {
        loginEnabled: {
          title: 'Enable access',
          description: 'If access is enabled, any user can access the system',
          label: 'Allow access',
        },
        attemptLimitEnabled: {
          title: 'Enable attempt limit',
          description:
            'If attempt limit is enabled, the user will be locked out if more than the number of attempts allowed for the user fail to log in. ',
          label: 'Allow attempts limit',
        },
        movementLimitEnabled: {
          title: 'Enable movement limit',
          description:
            'If the movement limit is enabled, the user will be blocked if they make more requests than the user is allowed. ',
          label: 'Allow movement limit',
        },
        submit: 'Save settings',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Activities',
      contacts: 'Contacts',
      marketing: 'Marketing',
      sales: 'Sales',
      directory: 'Directory',
      customers: 'Customers',
      commercial: 'Commercial',
      campaigns: 'Campaigns',
      funnels: 'Funnels',
      channels: 'Channels',
      leads: 'Leads',
      transferLead: 'Lead transfer',
      patients: {
        ovoclinic: 'Patients',
        ovobank: 'Donors',
      },
      programs: 'Programs',
      maintenances: 'Maintenance',
      tasks: 'Tasks',
      transferTask: 'Task transfer',
      createTaskMassively: 'Create tasks in bulk',
      actions: 'Calendar',
      origins: 'Origins',
      techniques: 'Techniques',
      services: 'Services',
      activities: 'Activities',
      results: 'Results',
      reasons: 'Reasons',
      products: 'Products',
      categories: 'Categories',
      dashboard: 'Dashboard',
      catalog: 'Catalog',
      phases: 'Stages',
      causes: 'Causes',
      treatments: 'Treatments',
      cycles: 'Cycles',
    },
    faq: {
      title: 'FAQ',
      description: 'Frequently asked questions',
      list: {
        '1': {
          question: 'How can I create a lead?',
          answer: 'To create a lead, follow these steps:',
        },
        '2': {
          question: 'How can I create a campaign?',
          answer: 'To create a campaign, follow these steps:',
        },
        '3': {
          question: 'How can I create a task?',
          answer: 'To create a task, follow these steps:',
        },
        '4': {
          question: 'How can I transfer a lead?',
          answer: 'To transfer a lead, follow these steps:',
        },
      },
    },
    information: {
      list: {
        '0': {
          title: 'NATIONAL department workflow',
          sublists: {
            '0': {
              title: 'Call Center',
            },
            '1': {
              title: 'Patient care Commercial',
            },
            '2': {
              title: 'Patient care Doctor',
            },
          },
        },
        '1': {
          title: 'INTERNATIONAL department workflow',
        },
      },
    },
    dashboard: {
      title: 'Dashboard',
      description: 'Control panel of your activity',
      actions: {
        title: 'Tasks',
        description: {
          ovoclinic: 'Management of the tasks to which you are assigned. ',
          ovobank: 'Management of the tasks to which you are assigned. ',
        },
      },
      receipts: {
        title: 'Latest receipts',
        description: 'Payments made to your leads',
        list: {
          columns: {
            contactName: 'Name',
            payments: 'Payments',
            updated: 'F. update',
          },
          many: 'Receipts',
          one: 'Receipt',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Leads',
          ovobank: 'Donors',
        },
        description: {
          ovoclinic: 'Management of your leads/patients',
          ovobank: 'Management of your leads/donors',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campaigns',
          ovobank: 'Campaigns',
        },
        description: {
          ovoclinic: 'Management of your campaigns',
          ovobank: 'Management of your campaigns',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Lead transfer',
        description: 'Transfer campaigns from leads',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        required: 'You must select at least one lead',
        columns: {
          name: 'Name',
          activity: 'Activity',
          responsible: 'Responsible',
          campaignName: 'Campaign',
          started: 'Estimated date',
          clinicName: 'Clinic',
          created: 'Creation',
          resultId: 'Progress',
          phones: 'Phone',
          emails: 'E-mail',
        },
      },
      confirm: {
        title: 'Transfer leads',
        subtitle: 'Reassign campaign to multiple leads',
        success: 'Leads transferred successfully',
        steps: {
          campaign: {
            form: 'Selected campaign',
          },
        },
        form: {
          campaignId: {
            title: 'Select campaign',
            description: 'Campaign to which leads will be reassigned',
          },
          responsibleId: {
            title: 'Select responsible',
            description:
              'Responsible person to whom the leads will be reassigned when changing campaigns',
          },
          submit: 'Transfer',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Task transfer',
          ovobank: 'Task transfer',
        },
        description: {
          ovoclinic: 'Transfer tasks to other users. ',
          ovobank: 'Transfer tasks to other users. ',
        },
        create: 'Task',
        one: 'Task',
        many: 'Tasks',
        columns: {
          activity: 'Activity',
          phone: 'Phone',
          contact: 'Contact',
          subject: 'Subject',
          resultId: 'Progress',
          campaign: 'Campaign',
          clinic: 'Clinic',
          contactTime: 'Time preference',
          created: 'Creation',
          user: 'Responsible',
          started: 'Estimated start date',
          finished: 'Duration',
        },
      },
      confirm: {
        title: 'Transfer tasks',
        subtitle: 'Reassign responsible to multiple tasks',
        success: 'Tasks transferred successfully',
        form: {
          responsibleId: {
            title: 'Select responsible',
            description: 'Person to whom tasks will be reassigned',
          },
          submit: 'Transfer',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Create tasks in bulk',
        description: 'This screen allows you to create an activity type for multiple leads',
        columns: {
          name: 'Name',
          clinicName: 'Clinic',
          campaignName: 'Campaign',
          phones: 'Phone',
          emails: 'E-mail',
          created: 'Creation',
          lastAction: 'Last task',
          subject: 'Subject',
          lastActionDate: 'Pending task date',
        },
        noItemSelected: 'There are no leads selected',
        one: 'Lead',
        many: 'Leads',
      },
      selectActivity: {
        title: 'Select activity',
        subtitle: 'Select the activity you want to create for the selected leads',
        form: {
          activityId: {
            title: 'Activity',
            description: 'Activity you want to create for the selected leads',
            required: 'You must select an activity',
          },
          subject: {
            title: 'Subject',
            description:
              'The subject that will be set to all the tasks that will be created for the selected leads',
          },
          submit: 'Create tasks',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Tasks',
          ovobank: 'Tasks',
        },
        description: {
          ovoclinic: 'Management of the tasks to which you are assigned. ',
          ovobank: 'Management of the tasks to which you are assigned. ',
        },
        create: {
          ovoclinic: 'Task',
          ovobank: 'Task',
        },
        one: 'Task',
        many: 'Tasks',
        columns: {
          activity: 'Activity',
          contact: 'Contact',
          responsible: 'Responsible',
          subject: 'Subject',
          resultId: 'Progress',
          resultName: 'Result',
          campaign: 'Campaign',
          clinic: 'Clinic',
          contactTime: 'Time preference',
          created: 'Creation',
          user: 'Responsible',
          started: 'Estimated start date',
          finished: 'Duration',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'New action',
              subtitle: 'Registration of a new action in the system. ',
              form: {
                clinicId: {
                  title: 'Clinic',
                  description: 'Clinic to which the action is directed',
                },
                submit: 'Following',
              },
            },
            lead: {
              title: 'New action',
              subtitle: 'Registration of a new action in the system. ',
              form: {
                leadId: {
                  title: 'Lead',
                  description: 'Lead to whom the action is directed',
                },
                submit: 'Following',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'All day',
        previous: 'Previous',
        next: 'Following',
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        agenda: 'Agenda',
        date: 'Date',
        time: 'Hour',
        event: 'Event',
      },
    },
    channels: {
      list: {
        title: 'Channels',
        description: 'Management of communication channels',
        create: 'Channel',
        one: 'Channel',
        many: 'Channels',
        columns: {
          name: 'Name',
          funnels: 'Funnels',
        },
      },
      create: {
        title: 'New channel',
        subtitle: 'Registration of a new channel in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Channel identification name',
          },
          status: {
            title: 'Active channel',
            description: 'Enable or disable the channel in the system',
            label: 'Active channel',
          },
          submit: 'Create channel',
        },
      },
      update: {
        title: 'Update channel',
        subtitle: 'Modifying the data of a channel in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Channel identification name',
          },
          status: {
            title: 'Active channel',
            description: 'Enable or disable the channel in the system',
            label: 'Active channel',
          },
          submit: 'Update channel',
        },
      },
      disable: {
        title: 'Disable channel',
        subtitle: 'Disable channel in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this channel?',
            description: 'Disabling a channel means that it cannot be used in the system',
            label: 'Yes, I want to disable this channel',
          },
          submit: 'Disable channel',
        },
      },
      enable: {
        title: 'Enable channel',
        subtitle: 'Enable channel in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this channel?',
            description: 'Enabling a channel means that it can be used in the system',
            label: 'Yes, I want to enable this channel',
          },
          submit: 'Enable channel',
        },
      },
      delete: {
        title: 'Delete channel',
        subtitle: 'This action is irreversible',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to disable this channel',
            label: 'Yes, I want to delete this channel',
          },
          submit: 'Eliminate',
        },
      },
    },
    cycles: {
      list: {
        title: 'Cycles',
        description: 'Management of treatment cycles',
        create: 'Cycle',
        one: 'Cycle',
        many: 'Cycles',
        columns: {
          name: 'Name',
          complexity: 'Complexity',
          created: 'Creation date',
          code: 'Code',
        },
      },
      create: {
        title: 'New cycle',
        subtitle: 'Registration of a new cycle in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Cycle identification name',
          },
          complexity: {
            title: 'Complexity',
            description: 'Cycle complexity',
          },
          code: {
            title: 'Code',
            description: 'Cycle identification code',
          },
          description: {
            title: 'Description',
            description: 'Cycle Description',
          },
          submit: 'Create cycle',
        },
      },
      update: {
        title: 'Update cycle',
        subtitle: 'Modifying the data of a cycle in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Cycle identification name',
          },
          complexity: {
            title: 'Complexity',
            description: 'Cycle complexity',
          },
          code: {
            title: 'Code',
            description: 'Cycle identification code',
          },
          description: {
            title: 'Description',
            description: 'Cycle Description',
          },
          submit: 'Update cycle',
        },
      },
      enable: {
        title: 'Enable cycle',
        subtitle: 'Enable cycle in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this cycle?',
            description: 'Enabling a cycle means that it can be used in the system',
            label: 'Yes, I want to enable this cycle',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable cycle',
        subtitle: 'Disable cycle in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this cycle?',
            description: 'Disabling a cycle means that it cannot be used in the system',
            label: 'Yes, I want to disable this cycle',
          },
          submit: 'Disable',
        },
      },
    },
    funnels: {
      list: {
        title: 'Funnels',
        description: 'Management of conversion funnels. ',
        create: 'Funnel',
        one: 'Funnel',
        many: 'Funnels',
        params: {
          channelId: 'Funnels filtered by communication channel',
        },
        columns: {
          name: 'Name',
          channelName: 'Channel',
        },
      },
      create: {
        title: 'New funnel',
        subtitle: 'Registration of a new funnel in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identifying name of the funnel',
          },
          channelId: {
            title: 'Channel',
            description: 'Communication channel associated with the funnel',
          },
          status: {
            title: 'Active funnel',
            description: 'Enable or disable the funnel in the system',
            label: 'Active funnel',
          },
          submit: 'Create funnel',
        },
      },
      update: {
        title: 'Update funnel',
        subtitle: 'Modifying the data of a funnel in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identifying name of the funnel',
          },
          channelId: {
            title: 'Channel',
            description: 'Communication channel associated with the funnel',
          },
          status: {
            title: 'Active funnel',
            description: 'Enable or disable the funnel in the system',
            label: 'Active funnel',
          },
          submit: 'Update funnel',
        },
      },
      delete: {
        title: 'Delete funnel',
        subtitle: 'This action is irreversible',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to disable this funnel',
            label: 'Yes, I want to delete this funnel',
          },
          submit: 'Eliminate',
        },
      },
      enable: {
        title: 'Enable funnel',
        subtitle: 'Enable funnel in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this funnel?',
            description: 'Enabling a funnel means that it can be used in the system',
            label: 'Yes, I want to enable this funnel',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable funnel',
        subtitle: 'Disable funnel in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this funnel?',
            description: 'Disabling a funnel means that it cannot be used in the system',
            label: 'Yes, I want to disable this funnel',
          },
          submit: 'Disable',
        },
      },
    },
    leads: {
      import: {
        title: 'Lead import',
        subtitle: 'Importing leads into the system',
        description: 'Loading leads into the system through a csv file. ',
        list: {
          columns: {
            name: 'Name',
            errors: 'Number of errors',
            created: 'Import date',
          },
          one: 'Import',
          many: 'Imports',
        },
        delete: {
          title: 'Delete import record',
          subtitle: 'Delete import record in the system',
          form: {
            submit: 'Eliminate',
          },
        },
      },
      list: {
        title: 'Leads',
        description: 'Management of potential customers generated through campaigns',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        columns: {
          name: 'Name',
          activity: 'Activity',
          responsible: 'Responsible',
          campaignName: 'Campaign',
          started: 'Estimated date',
          clinicName: 'Clinic',
          created: 'Creation',
          resultId: 'Progress',
          phones: 'Phone',
          emails: 'E-mail',
        },
        csv: {
          select: 'IMPORT .CSV',
          somethingwentwrong: 'Leads not loaded',
          wrongdata: 'the file is wrong, or the data does not correspond to the correct format',
          loaded: 'Leads loaded correctly',
          downloadCsv:
            'Some leads were not uploaded, you can download the list of bad leads on the download button',
        },
      },
      create: {
        title: 'New lead',
        subtitle: 'Registration of a new lead in the system',
        form: {
          description: {
            title: 'Description',
            description: 'A brief description to detail the lead.',
          },
          clinicId: {
            title: 'Clinic',
            description: "The lead's preferred clinic. ",
          },
          contactId: {
            title: 'Contact',
            description: 'Contact associated with the lead. ',
          },
          campaignId: {
            title: 'Campaign',
            description: "The lead's current marketing campaign.",
          },
          submit: 'Create lead',
        },
      },
      update: {
        title: 'Update lead',
        subtitle: 'Modifying lead data in the system',
        disabledBanner: {
          title: 'Inactive lead',
        },
        tabs: {
          lead: {
            title: 'Lead',
            description:
              'The lead details are aimed at understanding the origin of the lead and its follow-up. ',
          },
          actions: {
            title: 'Tasks',
            description: 'Tasks are the actions taken to convert a lead into a patient. ',
          },
          responsible: {
            title: 'Responsible',
            description:
              'The person in charge is the user who is in charge of carrying out the lead follow-up tasks.',
          },
          contact: {
            title: 'Contact',
            description: 'A lead originates from a contact. ',
          },
          notes: {
            title: 'Observations',
            description:
              'Observations are comments that are added to leads to remember important details or to share information with other salespeople.',
          },
          specialist: {
            title: 'Specialist',
            description: 'The specialist assigned to the lead',
          },
          communications: {
            title: 'Communications',
            description:
              'Integration with the messaging system to monitor communications with leads.',
          },
        },
        leadPassport: {
          birthCountry: 'Country of birth',
          birthDate: 'Birthdate',
          height: 'Height',
          weight: 'Weight',
          contactTime: 'Time preference',
          created: 'Creation date',
          consent: {
            title: 'Consent',
            type: {
              legal: 'Legal',
              marketing: 'Marketing',
            },
          },
        },
        form: {
          description: {
            title: 'Description',
            description: 'A brief description to detail the lead.',
          },
          clinicId: {
            title: 'Clinic',
            description: "The lead's preferred clinic. ",
          },
          contactId: {
            title: 'Contact',
            description: 'Contact associated with the lead. ',
          },
          campaignId: {
            title: 'Campaign',
            description: "The lead's current marketing campaign",
          },
          reasonId: {
            title: 'Reason',
            description: 'Reason',
          },
          submit: 'Update lead',
        },
        lead: {
          tab: 'Lead',
          description: 'Lead details',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsible',
              description: 'User responsible for the lead',
            },
            submit: 'Assign responsible',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Specialist',
              description: 'Specialist assigned to the lead',
            },
            submit: 'Assign specialist',
          },
        },
        actions: {
          tab: 'Tasks',
          description: 'Lead tasks',
          add: 'Add task',
          create: {
            title: 'New task',
            subtitle: 'Registration of a new task in the system',
            confirmationMessage: 'Unscheduled task. \n\n\n',
            form: {
              activityId: {
                title: 'Activity',
                description: 'Activity to do',
              },
              responsibleId: {
                title: 'Responsible',
                description: 'Responsible for carrying out the activity',
              },
              resultId: {
                title: 'Result',
                description: 'Activity result',
              },
              started: {
                title: 'Estimated date',
                description: 'Date on which the activity is expected to take place',
              },
              subject: {
                title: 'Subject',
                description: 'Task subject',
              },
              observation: {
                title: 'Note',
                description: 'A little comment about the activity',
              },
              submit: 'Create task',
            },
            steps: {
              clinic: {
                form: 'Selected clinic',
              },
              lead: {
                form: 'Selected lead',
              },
            },
          },
          update: {
            title: 'Update task',
            subtitle: 'Modifying the data of a task in the system',
            form: {
              activityId: {
                title: 'Activity',
                description: 'Activity to do',
              },
              responsibleId: {
                title: 'Responsible',
                description: 'Responsible for carrying out the activity',
              },
              resultId: {
                title: 'Result',
                description: 'Activity result',
              },
              started: {
                title: 'Estimated date',
                description: 'Date on which the activity is expected to take place',
              },
              subject: {
                title: 'Subject',
                description: 'Task subject',
              },
              observation: {
                title: 'Note',
                description: 'A little comment about the activity',
              },
              submit: 'Update task',
            },
            tabs: {
              action: {
                title: 'Task',
                description: 'Information associated with the task',
              },
              actionEnding: {
                title: 'Realization',
                description: 'Information associated with the completion of the task',
              },
              alerts: {
                title: 'Alerts',
                description: 'Alerts associated with the task. ',
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Result',
                },
                result: {
                  title: 'Result',
                  description: 'Result of the activity. ',
                },
                communicationChannel: {
                  title: 'Communication route',
                  description: 'Communication route used for the activity',
                },
                submit: 'Finish task',
                submitNextSuggestion: 'Finish',
              },
            },
            alert: {
              list: {
                title: 'Alerts',
                columns: {
                  expectedAlarmTime: 'Alarm date',
                },
                many: 'Alerts',
                one: 'Alert',
              },
              add: 'Add alert',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observations',
              },
              contactNotes: {
                title: 'Observations',
              },
            },
            fields: {
              newLead: 'New lead',
              active: 'Active lead',
              inactive: 'Inactive lead',
              canceled: 'Lead canceled',
              converted: 'Converted Lead',
              clinic: 'Clinic',
              contactTime: 'Time preference',
              responsible: 'Responsible',
              funnel: 'Funnel',
              consent: 'Consent',
              campaign: 'Campaign',
              originCampaign: 'Origin campaign',
              created: 'Creation date',
              legal: 'Legal',
              marketing: 'Marketing',
              donationPreference: 'City',
              birthCountry: 'Country of birth',
              birthDate: 'Birthdate',
              height: '(CM)',
              weight: '(KG)',
            },
          },
        },
      },
      delete: {
        title: 'Delete lead',
        subtitle: 'This action is irreversible',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to deactivate this lead',
            label: 'Yes, I want to delete this lead',
          },
          confirm: 'This action will delete everything related to the lead, are you sure?',
          submit: 'Eliminate',
        },
      },
      disable: {
        title: 'Disable lead',
        subtitle: 'Disable lead in the system',
        form: {
          reasonId: {
            title: 'Reason',
            description: 'Reason why the lead is disabled',
          },
          submit: 'Disable',
        },
        confirmationMessage: 'Reason not attached. ',
      },
      enable: {
        title: 'Enable lead',
        subtitle: 'Enable lead in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this lead?',
            description: 'Enabling a lead means that it can be used in the system',
            label: 'Yes, I want to enable this lead',
          },
          submit: 'Enable',
        },
      },
      addSpecialist: {
        title: 'Assign specialist',
        subtitle: 'Assign specialist to lead',
        form: {
          specialistId: {
            title: 'Specialist',
            description: 'Specialist assigned to the lead',
          },
          submit: 'Assign specialist',
        },
      },
    },
    reasons: {
      list: {
        title: 'Reasons',
        description:
          'Management of reasons why a potential client may unsubscribe or be considered invalid',
        create: 'Reason',
        one: 'Reason',
        many: 'Reasons',
        columns: {
          name: 'Qualification',
        },
      },
      create: {
        title: 'New reason',
        subtitle: 'Registration of a new reason in the system',
        form: {
          name: {
            title: 'Qualification',
            description: 'Identification title of the reason',
          },
          description: {
            title: 'Description',
            description: 'Detailed description of lead cancellation',
          },
          color: {
            title: 'Color',
            description: 'Color associated with the reason',
          },
          notify: {
            title: 'Notify',
            description: 'Whether or not to notify the reason in the system',
            label: 'Notify reason',
          },
          icon: {
            title: 'Icon',
            description: 'Icon associated with the reason',
            label: 'Select icon...',
            search: {
              placeholder: 'Search icon...',
            },
          },
          canceled: {
            title: 'Low',
            description:
              'By selecting this option, it is indicated that this reason attributes the status of permanent withdrawal. ',
            label: 'Reason for withdrawal',
          },
          subject: {
            title: 'Subject',
            description: 'Reason issue',
          },
          message: {
            title: 'Message',
            description: 'Reason message',
          },
          submit: 'Create reason',
        },
      },
      enable: {
        title: 'Enable reason',
        subtitle: 'Enable reason in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this reason?',
            description: 'Enabling a reason means that it can be used in the system',
            label: 'Yes, I want to enable this reason',
          },
          submit: 'Enable reason',
        },
      },
      disable: {
        title: 'Disable reason',
        subtitle: 'Disable reason in system',
        form: {
          status: {
            title: 'Are you sure you want to disable this reason?',
            description: 'Disabling a reason means that it cannot be used in the system',
            label: 'Yes, I want to disable this reason',
          },
          submit: 'Disable reason',
        },
      },
      update: {
        title: 'Update reason',
        subtitle: 'Modifying the data of a reason in the system',
        tabs: {
          reason: {
            title: 'Reason',
            description: 'Reason information',
          },
          notification: {
            title: 'Set notification',
            description:
              'Setting the notification to be sent when a cancellation occurs for this particular reason',
          },
          activity: {
            title: 'Next activity',
            description:
              'If this reason section is configured, once the Lead with this reason is disabled, an activity to be performed will be suggested. ',
          },
        },
        reason: {
          form: {
            name: {
              title: 'Qualification',
              description: 'Identification title of the reason',
            },
            description: {
              title: 'Description',
              description: 'Detailed description of lead cancellation',
            },
            color: {
              title: 'Color',
              description: 'Color associated with the reason',
            },
            notify: {
              title: 'Notify',
              description: 'Whether or not to notify the reason in the system',
              label: 'Notify reason',
            },
            icon: {
              title: 'Icon',
              description: 'Icon associated with the reason',
              label: 'Select icon...',
              search: {
                placeholder: 'Search icon...',
              },
            },
            canceled: {
              title: 'Low',
              description:
                'By selecting this option, it is indicated that this reason attributes the status of permanent withdrawal. ',
              label: 'Reason for withdrawal',
            },
            subject: {
              title: 'Subject',
              description: 'Reason issue',
            },
            message: {
              title: 'Message',
              description: 'Reason message',
            },
            submit: 'Update reason',
          },
          notification: {
            form: {
              notify: {
                title: 'Activate notification',
                description: 'Notify or not when a cancellation occurs for this reason',
                label: 'Activate notification system',
              },
              subject: {
                title: 'Subject',
                description: 'Notification subject',
              },
              message: {
                title: 'Message',
                description: 'Message to be displayed in the notification',
              },
              submit: 'Save settings',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Next activity',
              description: 'Suggested activity to do when a cancellation occurs',
            },
            nextActivityDelay: {
              title: 'Waiting time',
              description: 'Waiting time to carry out the suggested activity, in days',
            },
            submit: 'Save',
          },
        },
      },
    },
    patients: {
      list: {
        title: {
          ovoclinic: 'Patients',
          ovobank: 'Donors',
        },
        description: {
          ovoclinic: 'Patient management in the system',
          ovobank: 'Donor management in the system',
        },
        create: {
          ovoclinic: 'Patient',
          ovobank: 'Donor',
        },
        one: {
          ovoclinic: 'Patient',
          ovobank: 'Donor',
        },
        many: {
          ovoclinic: 'Patients',
          ovobank: 'Donors',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contact',
              ovobank: 'Contact',
            },
          },
          description: {
            ovoclinic: 'Description',
            ovobank: 'Description',
          },
          phones: {
            ovoclinic: 'Phone',
            ovobank: 'Phone',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'cycle clinic',
              ovobank: 'cycle clinic',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clinic',
              ovobank: 'Clinic',
            },
          },
          created: {
            ovoclinic: 'Creation',
            ovobank: 'Creation',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'New patient',
          ovobank: 'New donor',
        },
        subtitle: {
          ovoclinic: 'Registration of a new patient in the system',
          ovobank: 'Registration of a new donor in the system',
        },
        form: {
          description: {
            title: {
              ovobank: 'Description',
              ovoclinic: 'Description',
            },
            description: {
              ovoclinic: 'A brief description to detail the patient.',
              ovobank: 'A brief description to detail the donor.',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead associated with the patient',
              ovobank: 'Lead associated with the donor',
            },
          },
          clinicId: {
            title: 'Clinic',
            description: {
              ovoclinic: "The patient's preferred clinic.",
              ovobank: "The donor's preferred clinic.",
            },
          },
          campaignId: {
            title: 'Campaign',
            description: {
              ovoclinic: 'The patient home marketing campaign. ',
              ovobank: "The donor's home marketing campaign. ",
            },
          },
          contactId: {
            title: 'Contact',
            description: {
              ovoclinic: 'The contact through which you have become a patient.',
              ovobank: 'The contact through which you have become a donor.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Warranty agreement',
              ovobank: 'Warranty agreement',
            },
            description: {
              ovoclinic: 'The patient accepts the guarantee agreement',
              ovobank: 'The donor accepts the guarantee agreement',
            },
            label: {
              ovoclinic: 'Yes, I accept the warranty agreement',
              ovobank: 'Yes, I accept the warranty agreement',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consent',
              ovobank: 'Consent',
            },
            description: {
              ovoclinic: 'The patient gives consent to be treated',
              ovobank: 'The donor gives consent to be treated',
            },
            label: {
              ovoclinic: 'Yes, I give my consent',
              ovobank: 'Yes, I give my consent',
            },
          },
          submit: {
            ovoclinic: 'Create patient',
            ovobank: 'Create donor',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Treatment',
              },
              phase: {
                form: 'Stage',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Stage',
                description: 'Stage of treatment',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Program',
                description: 'Program name',
              },
              phaseId: {
                title: 'Stage',
                description: 'Stage of treatment',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Update patient',
          ovobank: 'Update donor',
        },
        subtitle: {
          ovoclinic: 'Modifying patient data in the system',
          ovobank: "Modification of a donor's data in the system",
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Patient',
              ovobank: 'Donor',
            },
            description: {
              ovoclinic: 'Patient details',
              ovobank: 'Donor details',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contact',
              ovobank: 'Contact',
            },
            description: {
              ovoclinic: 'Patient contact details',
              ovobank: 'Donor Contact Details',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsible',
              ovobank: 'Responsible',
            },
            description: {
              ovoclinic: 'Patient Responsible',
              ovobank: 'Responsible for the donor',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Specialist',
              ovobank: 'Specialist',
            },
            description: {
              ovoclinic: 'Specialist assigned to the patient',
              ovobank: 'Specialist assigned to the donor',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Receipts',
              ovobank: 'Receipts',
            },
            description: 'List of receipts associated with the customer',
            list: {
              create: 'Receipt',
            },
            many: 'Receipts',
            one: 'Receipt',
            columns: {
              receipt: {
                name: 'Name',
                clinic: 'Clinic',
                created: 'Creation',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Create payment',
                  },
                  many: 'Payments',
                  one: 'Pay',
                  columns: {
                    payment: {
                      price: 'Price',
                      clinic: 'Clinic',
                      name: 'Name',
                      created: 'Creation',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Hiring',
              ovobank: 'Hiring',
            },
            description: {
              ovoclinic: 'Patient hires',
              ovobank: 'Donor Hiring',
            },
            one: 'Hiring',
            many: 'Hiring',
            columns: {
              price: 'Price',
              purchaseType: 'Guy',
              treatmentName: 'Name',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Observations',
              ovobank: 'Observations',
            },
            description: {
              ovoclinic: 'Patient observations',
              ovobank: 'Donor observations',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documents',
              ovobank: 'Documents',
            },
            description: {
              ovoclinic: 'Patient uploaded documents',
              ovobank: 'Donor uploaded documents',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Treatment',
              ovobank: 'Treatment',
            },
            description: {
              ovoclinic: 'Patient program',
              ovobank: 'Donor Program',
            },
            one: 'Program',
            many: 'Programs',
            submit: 'Add program',
            columns: {
              programName: 'Program',
              estimated: 'Estimated date',
              finished: 'End date',
            },
            document: {
              delete: {
                title: 'Delete file',
                subtitle:
                  'Are you sure you want to delete the file? It will also be deleted from the cloud',
                submit: 'Delete',
              },
              columns: {
                programName: 'Program',
                estimated: 'Estimated date',
                finished: 'End date',
                documentName: 'Document',
                created: 'Created',
                size: 'Size',
                vRepoUploaded: 'Synchronized with Vrepo',
                type: 'Guy',
              },
              select: 'Upload a pdf file',
              unselected: 'No file selected',
              unvalid: 'Please select a valid pdf file.',
              submit: 'Send',
              many: 'Files',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Tasks',
              ovobank: 'Tasks',
            },
            description: {
              ovoclinic: 'Management of the tasks to which you are assigned. ',
              ovobank: 'Management of the tasks to which you are assigned. ',
            },
            add: 'Add task',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observations',
              },
              contactNotes: {
                title: 'Observations',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'New patient',
                ovobank: 'New donor',
              },
              active: {
                ovoclinic: 'Active patient',
                ovobank: 'Active donor',
              },
              inactive: {
                ovoclinic: 'Inactive patient',
                ovobank: 'Inactive donor',
              },
              clinic: 'Clinic',
              contactTime: 'Time preference',
              responsible: 'Responsible',
              funnel: 'Origin',
              consent: 'Approval',
              consentCheck: 'Consent',
              warrantyAgreement: 'Guarantee agreement',
              campaign: 'Campaign',
              originCampaign: 'Origin campaign',
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Patient',
            ovobank: 'Donor',
          },
          description: {
            ovoclinic: 'Patient details',
            ovobank: 'Donor details',
          },
        },
        contact: {
          tab: 'Contact',
          description: {
            ovoclinic: 'Patient contact details',
            ovobank: 'Donor Contact Details',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsible',
                ovobank: 'Responsible',
              },
              description: {
                ovoclinic: 'Responsible patient user',
                ovobank: 'Responsible donor user',
              },
            },
            submit: 'Assign responsible',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Specialist',
              description: {
                ovoclinic: 'Specialist assigned to the patient',
                ovobank: 'Specialist assigned to the donor',
              },
            },
            submit: 'Assign specialist',
          },
        },
        receipts: {
          create: {
            title: 'Create receipt',
            subtitle: {
              ovoclinic: 'Create receipt associated with the patient',
              ovobank: 'Create receipt associated with the donor',
            },
            form: {
              clinicId: {
                title: 'Clinic',
              },
              submit: 'Create receipt',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Receipt',
                description: {
                  ovoclinic: 'Update receipt associated with the patient',
                  ovobank: 'Update receipt associated with the donor',
                },
              },
              payments: {
                title: 'Payments',
                description: 'Payments of the bill associated with the client',
                list: {
                  create: 'Add payment',
                },
              },
            },
            title: 'Update receipt',
            subtitle: {
              ovoclinic: 'Update receipt associated with the patient',
              ovobank: 'Update receipt associated with the donor',
            },
            form: {
              clinicId: {
                title: 'Clinic',
              },
              submit: 'Save receipt',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Description',
              ovobank: 'Description',
            },
            description: {
              ovoclinic: 'A brief description to add any comments about the patient.',
              ovobank: 'A brief description to add any comments about the donor.',
            },
          },
          clinicId: {
            title: 'Clinic',
            description: {
              ovoclinic: 'The clinic in which the patient wishes to be treated.',
              ovobank: 'The clinic where the donor wishes to be treated.',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead associated with the patient',
              ovobank: 'Lead associated with the donor',
            },
          },
          contactId: {
            title: 'Contact',
            description: {
              ovoclinic:
                'The contact information through which the patient has arrived at the clinic.',
              ovobank: 'The contact details by which the donor has come to the clinic.',
            },
          },
          campaignId: {
            title: 'Campaign',
            description: {
              ovoclinic: 'The patient home marketing campaign. ',
              ovobank: "The donor's home marketing campaign. ",
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Warranty agreement',
              ovobank: 'Warranty agreement',
            },
            description: {
              ovoclinic: 'The patient accepts the guarantee agreement',
              ovobank: 'The donor accepts the guarantee agreement',
            },
            label: {
              ovoclinic: 'Yes, I accept the warranty agreement',
              ovobank: 'Yes, I accept the warranty agreement',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consent',
              ovobank: 'Consent',
            },
            description: {
              ovoclinic: 'The patient gives consent to be treated',
              ovobank: 'The donor gives consent to be treated',
            },
            label: {
              ovoclinic: 'Yes, I give my consent',
              ovobank: 'Yes, I give my consent',
            },
          },
          submit: {
            ovoclinic: 'Update patient',
            ovobank: 'Update donor',
          },
        },
        payments: {
          create: {
            title: 'Create payment',
            subtitle: {
              ovoclinic: "Add payment associated with the patient's receipt",
              ovobank: 'Add payment associated with the donor receipt',
            },
            form: {
              price: {
                title: 'Price',
              },
              submit: 'Save price',
            },
          },
          update: {
            title: 'Update payment',
            subtitle: {
              ovoclinic: "Update payment associated with the patient's receipt",
              ovobank: 'Update payment associated with the donor receipt',
            },
            form: {
              price: {
                title: 'Price',
              },
              submit: 'Save price',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Treatment',
              },
              phase: {
                form: 'Phase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Treatment',
              },
              phase: {
                form: 'Phase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Disable patient',
          ovobank: 'Disable donor',
        },
        subtitle: {
          ovoclinic: 'Disable patient in the system',
          ovobank: 'Disable donor in the system',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Are you sure you want to disable this patient?',
              ovobank: 'Are you sure you want to disable this donor?',
            },
            description: {
              ovoclinic: 'Disabling a patient means that they cannot be used in the system',
              ovobank: 'Disabling a donor means that it cannot be used in the system',
            },
            label: {
              ovoclinic: 'Yes, I want to disable this patient',
              ovobank: 'Yes, I want to disable this donor',
            },
          },
          submit: 'Disable',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Enable patient',
          ovobank: 'Enable donor',
        },
        subtitle: {
          ovoclinic: 'Enable patient in the system',
          ovobank: 'Enable donor in the system',
        },
        form: {
          status: {
            title: {
              ovoclinic: 'Are you sure you want to enable this patient?',
              ovobank: 'Are you sure you want to enable this donor?',
            },
            description: {
              ovoclinic: 'Enabling a patient means that it can be used in the system',
              ovobank: 'Enabling a donor means that it can be used in the system',
            },
            label: {
              ovoclinic: 'Yes, I want to enable this patient',
              ovobank: 'Yes, I want to enable this donor',
            },
          },
          submit: 'Enable',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Delete patient',
          ovobank: 'Delete donor',
        },
        subtitle: {
          ovoclinic: 'This action is irreversible',
          ovobank: 'This action is irreversible',
        },
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: {
              ovoclinic: 'You may prefer to deactivate this patient',
              ovobank: 'You may prefer to deactivate this donor',
            },
            label: {
              ovoclinic: 'Yes, I want to delete this patient',
              ovobank: 'Yes, I want to delete this donor',
            },
          },
          confirm: {
            ovoclinic:
              'This action will eliminate everything related to the patient, are you sure?',
            ovobank: 'This action will delete everything related to the donor, are you sure?',
          },
          submit: 'Eliminate',
        },
      },
      actions: {
        create: {
          title: 'New task',
          subtitle: 'Registration of a new task in the system',
          form: {
            activityId: {
              title: 'Activity',
              description: 'Activity to do',
            },
            responsibleId: {
              title: 'Responsible',
              description: 'User responsible for the task',
            },
            started: {
              title: 'Estimated date',
              description: 'Date on which the activity is expected to take place',
            },
            subject: {
              title: 'Subject',
              description: 'Task subject',
            },
            observation: {
              title: 'Note',
              description: 'A little comment about the activity',
            },
            submit: 'Create task',
          },
        },
        update: {
          title: 'Update task',
          subtitle: 'Modifying the data of a task in the system',
          actionEnding: {
            form: {
              submit: 'Finish task',
              submitNextSuggestion: 'Finalize and plan',
            },
          },
          form: {
            result: {
              false: {
                title: 'Result',
              },
              result: {
                title: 'Result',
                description: 'Result of the activity. ',
              },
            },
            communicationChannel: {
              title: 'Communication route',
              description: 'Communication route used for the activity',
            },
            activityId: {
              title: 'Activity',
              description: 'Activity to do',
            },
            responsibleId: {
              title: 'Responsible',
              description: 'User responsible for the task',
            },
            started: {
              title: 'Estimated date',
              description: 'Date on which the activity is expected to take place',
            },
            subject: {
              title: 'Subject',
              description: 'Task subject',
            },
            observation: {
              title: 'Note',
              description: 'A little comment about the activity',
            },
            submit: 'Update task',
          },
          tabs: {
            action: {
              title: 'Task',
              description: 'Information associated with the task',
            },
            actionEnding: {
              title: 'Realization',
              description: 'Information associated with the completion of the task',
            },
            alerts: {
              title: 'Alerts',
              description: 'Alerts associated with the task',
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: 'Update hiring',
        subtitle: {
          ovoclinic: 'Modification of the data of a patient contract',
          ovobank: 'Modification of the data of a donor contract',
        },
        form: {
          price: {
            title: 'Price',
            description: 'Contract price',
          },
          submit: 'Update hiring',
        },
      },
      disable: {
        title: 'Disable hiring',
        subtitle: {
          ovoclinic: 'Disable patient recruitment',
          ovobank: 'Disable donor recruitment',
        },
        form: {
          submit: 'Disable',
        },
      },
      enable: {
        title: 'Enable hiring',
        subtitle: {
          ovoclinic: 'Enable patient recruitment',
          ovobank: 'Enable donor contracting',
        },
        form: {
          submit: 'Enable',
        },
      },
    },
    treatments: {
      create: {
        title: 'New program',
        subtitle: 'Registration of a new program in the system',
        form: {
          programId: {
            title: 'Program',
            description: 'Program to be carried out',
          },
          estimated: {
            title: 'Estimated date',
            description: 'Date on which the program is expected to take place',
          },
          finished: {
            title: 'End date',
            description: 'Date the program was scheduled',
          },
          phaseId: {
            title: 'Phase',
            description: 'Phase in which the program is',
          },
          discount: {
            title: 'Discount percentage',
            description: 'Discount applied to the program',
          },
          submit: 'Create program',
        },
      },
      update: {
        title: 'Update program',
        subtitle: 'Modifying program data in the system',
        form: {
          programId: {
            title: 'Program',
            description: 'Program to be carried out',
          },
          phaseId: {
            title: 'Phase',
            description: 'Phase in which the program is',
          },
          estimated: {
            title: 'Estimated date',
            description: 'Date on which the program is expected to take place',
          },
          finished: {
            title: 'End date',
            description: 'Date the program was scheduled',
          },
          discount: {
            title: 'Discount percentage',
            description: 'Discount applied to the program',
          },
          submit: 'Update program',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Program',
              ovobank: 'Program',
            },
            description: {
              ovoclinic: 'Program information',
              ovobank: 'Program information',
            },
          },
          program: {
            title: {
              ovoclinic: 'Treatment',
            },
            description: {
              ovoclinic: 'Treatment information',
              ovobank: 'Treatment information',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Techniques',
              ovobank: 'Techniques',
            },
            description: {
              ovoclinic:
                'They are the complementary techniques that have been created in relation to each program. ',
              ovobank:
                'They are the complementary techniques that have been created in relation to each program. ',
            },
            many: 'Techniques',
            one: 'Technique',
            add: 'Add technique',
          },
          maintenances: {
            title: {
              ovoclinic: 'Maintenance',
              ovobank: 'Maintenance',
            },
            description: {
              ovoclinic: 'Treatment maintenance. ',
              ovobank: 'Treatment maintenance. ',
            },
            many: 'Maintenance',
            one: 'Maintenance',
            add: 'Add maintenance',
          },
          interventions: {
            title: {
              ovoclinic: 'Treatments',
              ovobank: 'Treatments',
            },
            description: {
              ovoclinic: 'Cycles carried out in the treatments. ',
              ovobank: 'Cycles carried out in the treatments. ',
            },
            many: 'Treatments',
            one: 'Treatment',
            columns: {
              interventionClinicName: 'Clinic',
              observation: 'Observation',
              expectedDate: 'Estimated date',
              interventionDate: 'Intervention date',
              cycleName: 'Cycle',
            },
            add: 'Add treatment',
          },
        },
      },
      disable: {
        title: 'Disable treatment',
        subtitle: 'Disable treatment in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this treatment?',
            description: 'Disabling a treatment means that it cannot be used in the system',
            label: 'Yes, I want to disable this treatment',
          },
          submit: 'Disable treatment',
        },
      },
      enable: {
        title: 'Enable treatment',
        subtitle: 'Enable treatment in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this treatment?',
            description: 'Enabling a treatment means that it can be used in the system',
            label: 'Yes, I want to enable this treatment',
          },
          submit: 'Enable treatment',
        },
      },
      list: {
        title: {
          ovoclinic: 'Treatments',
          ovobank: 'Treatments',
        },
        description: {
          ovoclinic: 'Management of treatments performed on patients',
          ovobank: 'Management of treatments performed on donors',
        },
        create: 'Treatment',
        one: 'Treatment',
        many: {
          ovoclinic: 'Treatments',
          ovobank: 'Treatments',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Cycle',
            ovobank: 'Cycle',
          },
          expectedDate: {
            ovoclinic: 'Estimated date',
            ovobank: 'Estimated date',
          },
          clinicName: {
            ovoclinic: 'Clinic',
            ovobank: 'Clinic',
          },
          patientName: {
            ovoclinic: 'Patient',
            ovobank: 'Donor',
          },
          programName: {
            ovoclinic: 'Program',
            ovobank: 'Program',
          },
          phaseName: {
            ovoclinic: 'Stage',
            ovobank: 'Stage',
          },
          estimated: {
            ovoclinic: 'Estimated date',
            ovobank: 'Estimated date',
          },
          finished: {
            ovoclinic: 'End date',
            ovobank: 'End date',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: 'Enable intervention',
        subtitle: 'Enable system intervention',
        form: {
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable intervention',
        subtitle: 'Disable intervention in the system',
        form: {
          submit: 'Disable',
        },
      },
      create: {
        title: 'New treatment',
        subtitle: 'Registration of a new treatment',
        form: {
          interventionClinicId: {
            title: 'Clinic',
            description: 'Clinic in which the treatment was performed',
          },
          interventionDate: {
            title: 'Intervention date',
            description: 'Date on which the cycle was carried out',
          },
          observation: {
            title: 'Note',
            description: 'Observation on treatment',
          },
          cycleId: {
            title: 'Cycle',
            description: 'Type of cycle to perform',
          },
          expectedDate: {
            title: 'Estimated date',
            description: 'Estimated cycle date',
          },
          submit: 'Create treatment',
        },
      },
      update: {
        title: 'Update treatment',
        subtitle: 'Modification of treatment data in the system',
        form: {
          interventionClinicId: {
            title: 'Clinic',
            description: 'Clinic in which the treatment was performed',
          },
          interventionDate: {
            title: 'Intervention date',
            description: 'Date on which the cycle was carried out',
          },
          observation: {
            title: 'Note',
            description: 'Observation on treatment',
          },
          cycleId: {
            title: 'Cycle',
            description: 'Type of cycle to perform',
          },
          expectedDate: {
            title: 'Estimated date',
            description: 'Estimated cycle date',
          },
          submit: 'Update treatment',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Treatment',
              ovobank: 'Treatment',
            },
            description: {
              ovoclinic: 'Treatment information',
              ovobank: 'Treatment information',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Techniques',
              ovobank: 'Techniques',
            },
            description: {
              ovoclinic: 'Intervention techniques. ',
              ovobank: 'Intervention techniques. ',
            },
          },
        },
        techniques: {
          delete: {
            title: 'Clear technique',
            subtitle: 'Delete intervention technique',
            form: {
              submit: 'Clear technique',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'New procedure',
        subtitle: 'Registration of a new procedure for intervention in the system',
        form: {
          techniqueId: {
            title: 'Technique',
            description: 'Technique associated with the procedure',
          },
          name: {
            title: 'Name',
            description: 'Identification name of the procedure',
          },
          description: {
            title: 'Description',
            description: 'Detailed description of the procedure',
          },
          submit: 'Create procedure',
        },
      },
      update: {
        title: 'Update procedure',
        subtitle: 'Modifying the data of a procedure in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the procedure',
          },
          description: {
            title: 'Description',
            description: 'Detailed description of the procedure',
          },
          price: {
            title: 'Price',
            description: 'Procedure price',
          },
          submit: 'Update procedure',
        },
      },
      delete: {
        title: 'Delete procedure',
        subtitle: 'Delete procedure in the system',
        form: {
          submit: 'Delete procedure',
        },
      },
    },
    maintenances: {
      create: {
        title: 'New maintenance',
        subtitle: 'Registration of a new maintenance for treatment in the system',
        form: {
          serviceId: {
            title: 'Service',
            description: 'Service associated with maintenance',
          },
          submit: 'Create maintenance',
        },
      },
      update: {
        title: 'Update maintenance',
        subtitle: 'Modification of maintenance data in the system',
        form: {
          serviceId: {
            title: 'Service',
            description: 'Service associated with maintenance',
          },
          amount: {
            title: 'Price',
            description: 'Maintenance price',
          },
          paymentStatus: {
            title: 'Payment status',
            description: 'Maintenance payment status',
          },
          estimatedPaymentDate: {
            title: 'Estimated payment date',
            description: 'Estimated maintenance payment date',
          },
          submit: 'Update maintenance',
        },
        tabs: {
          maintenance: {
            title: 'Maintenance',
            description: 'Maintenance information',
          },
          action: {
            title: 'Task',
            description: 'Information associated with the task',
          },
        },
      },
      delete: {
        title: 'Delete maintenance',
        subtitle: 'Delete maintenance on the system',
        form: {
          submit: 'Delete maintenance',
        },
      },
      list: {
        title: {
          ovoclinic: 'Maintenance',
          ovobank: 'Maintenance',
        },
        description: {
          ovoclinic: 'Management of maintenance performed on patients',
          ovobank: 'Management of maintenance carried out on donors',
        },
        create: 'Maintenance',
        one: {
          ovoclinic: 'Maintenance',
          ovobank: 'Maintenance',
        },
        many: {
          ovoclinic: 'Maintenance',
          ovobank: 'Maintenance',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clinic',
            ovobank: 'Clinic',
          },
          patientName: {
            ovoclinic: 'Patient',
            ovobank: 'Donor',
          },
          serviceName: {
            ovoclinic: 'Service',
            ovobank: 'Service',
          },
          amount: {
            ovoclinic: 'Price',
            ovobank: 'Price',
          },
          paymentStatus: {
            ovoclinic: 'Payment status',
            ovobank: 'Payment status',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Estimated payment date',
            ovobank: 'Estimated payment date',
          },
          programName: {
            ovoclinic: 'Program',
            ovobank: 'Program',
          },
          estimated: {
            ovoclinic: 'Estimated date',
            ovobank: 'Estimated date',
          },
          finished: {
            ovoclinic: 'End date',
            ovobank: 'End date',
          },
        },
      },
      enable: {
        title: 'Enable maintenance',
        subtitle: 'Enable maintenance on the system',
        form: {
          submit: 'Enable maintenance',
        },
      },
      disable: {
        title: 'Disable maintenance',
        subtitle: 'Disable maintenance on the system',
        form: {
          submit: 'Disable maintenance',
        },
      },
    },
    actions: {
      enable: {
        title: 'Enable action',
        subtitle: 'Enable action in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this action?',
            description: 'Enabling an action means that it can be used in the system',
            label: 'Yes, I want to enable this action',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable action',
        subtitle: 'Disable action in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this action?',
            description: 'Disabling an action means that it cannot be used in the system',
            label: 'Yes, I want to disable this action',
          },
          submit: 'Disable',
        },
      },
      list: {
        title: 'Calendar',
        description: 'Task calendar related to leads',
      },
      delete: {
        title: 'Delete task',
        subtitle: 'This action is irreversible',
        form: {
          submit: 'Eliminate',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Alerts',
            subtitle: 'Add alerts to the task',
            form: {
              alertTime: {
                title: 'Alert time',
                description: 'Enter the number of minutes in advance to receive the task notice',
              },
              submit: 'Add alert',
            },
          },
          delete: {
            title: 'Delete alert',
            subtitle: 'Delete task alert',
            form: {
              submit: 'Delete alert',
            },
          },
          update: {
            title: 'Update alert',
            subtitle: 'Update task alert',
            form: {
              alertTime: {
                title: 'Alert time',
                description: 'Enter the number of minutes in advance to receive the task notice',
              },
              submit: 'Update alert',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Name',
          phones: 'Phone',
          contactTime: 'Fringe',
          consents: 'Consents',
          marketing: 'Marketing',
          legal: 'Legal',
          created: 'Creation date',
        },
        title: {
          ovoclinic: 'Contacts',
          ovobank: 'Contacts',
        },
        description: {
          ovoclinic: 'Management of existing contacts in the application. ',
          ovobank: 'Management of existing contacts in the application. ',
        },
        create: {
          ovoclinic: 'Contact',
          ovobank: 'Contact',
        },
        one: 'Contact',
        many: 'Contacts',
      },
      create: {
        title: {
          ovoclinic: 'New contact',
          ovobank: 'New contact',
        },
        subtitle: {
          ovoclinic: 'Person and then associate it with a lead or patient',
          ovobank: 'Person and then associate it with a lead or donor',
        },
        form: {
          name: {
            title: 'Name',
          },
          surname: {
            title: 'Surnames',
          },
          prefix: {
            title: 'Prefix',
            description: 'Contact prefix',
          },
          phone: {
            title: 'Phone',
            description: 'Contact phone',
          },
          email: {
            title: 'E-mail',
            description: 'Contact email',
          },
          zip: {
            title: 'Zip code',
            description: 'Contact zip code',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'The contact agrees to receive marketing information',
          },
          birthDate: {
            title: 'Birthdate',
            description: "Contact's date of birth",
          },
          birthCountry: {
            title: 'Country of birth',
            description: "Contact's country of birth",
          },
          weight: {
            title: 'Weight (kg)',
            description: 'Contact weight in kilograms',
          },
          height: {
            title: 'Height (cm)',
            description: 'Contact height in centimeters',
          },
          donationPreference: {
            title: 'City preference',
            description: 'City preference where to make the donation',
          },
          legal: {
            title: 'Legal Conditions',
            label: 'Accept the legal conditions',
            description: 'The contact accepts the legal conditions',
          },
          contactTime: {
            title: 'Contact time slot',
            description: 'Time in which the contact wishes to be contacted',
          },
          clinicId: {
            title: 'Clinic',
            description: 'OvoCRM allows you to create contacts for multiple clinics. ',
          },
          campaignId: {
            title: 'Campaign',
            description: 'The contact origin marketing campaign. ',
          },
          center: {
            title: 'Location',
            description: 'Contact location',
          },
          photo: {
            title: 'Photo',
            description: 'Contact photo',
          },
          submit: 'Create contact',
        },
      },
      update: {
        title: {
          ovoclinic: 'Update contact',
          ovobank: 'Update contact',
        },
        subtitle: {
          ovoclinic: 'Personal information of the lead or patient',
          ovobank: 'Personal information of the lead or donor',
        },
        tabs: {
          account: {
            title: 'Personal Data',
            description: 'Detailed contact information',
          },
          actions: {
            title: 'Activities',
            description: 'List of activities created on the contact',
          },
          leads: {
            title: 'Campaigns',
            description: 'Campaigns through which this contact has entered. ',
          },
          contact: {
            title: 'Contact',
            description: 'Detailed contact information',
          },
          receipts: {
            title: 'Receipts',
            description: {
              ovoclinic: 'Receipts created on the contact. ',
              ovobank: 'Receipts created on the contact. ',
            },
          },
          notes: {
            title: 'Observations',
            description: 'Information or comments about the contact. ',
          },
          purchases: {
            title: 'Hiring',
            description: 'Hiring made by the contact. ',
          },
        },
        form: {
          name: {
            title: 'Name',
          },
          surname: {
            title: 'Surnames',
          },
          dni: {
            title: 'ID',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'The contact agrees to receive marketing information',
          },
          legal: {
            title: 'Legal Conditions',
            label: 'Accept the legal conditions',
            description: 'The contact accepts the legal conditions',
          },
          contactTime: {
            title: 'Contact time slot',
            description: 'Time in which the contact wishes to be contacted',
          },
          center: {
            title: 'Location',
            description: 'Contact location',
          },
          prefix: {
            title: 'Prefix',
            description: 'Contact prefix',
          },
          phone: {
            title: 'Phone',
            description: 'Contact phone',
          },
          email: {
            title: 'E-mail',
            description: 'Contact email',
          },
          birthDate: {
            title: 'Birthdate',
            description: "Contact's date of birth",
          },
          birthCountry: {
            title: 'Country of birth',
            description: "Contact's country of birth",
          },
          weight: {
            title: 'Weight (kg)',
            description: 'Contact weight in kilograms',
          },
          height: {
            title: 'Height (cm)',
            description: 'Contact height in centimeters',
          },
          donationPreference: {
            title: 'City preference',
            description: 'City preference where to make the donation',
          },
          photo: {
            title: 'Photo',
            description: 'Contact photo',
          },
          submit: 'Update contact',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Guy',
              treatmentName: 'Treatment',
              price: 'Price',
            },
            one: 'Hiring',
            many: 'Hiring',
          },
        },
      },
      delete: {
        title: 'Delete contact',
        subtitle: 'This action is irreversible',
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to deactivate this contact',
            label: 'Yes, I want to delete this contact',
          },
          confirm: 'This action will delete everything related to the contact, are you sure?',
          submit: 'Eliminate',
        },
      },
      enable: {
        title: 'Enable contact',
        subtitle: 'Enable contact in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this contact?',
            description: 'Enabling a contact means that it can be used in the system',
            label: 'Yes, I want to enable this contact',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable contact',
        subtitle: 'Disable contact in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this contact?',
            description: 'Disabling a contact means that it cannot be used in the system',
            label: 'Yes, I want to disable this contact',
          },
          submit: 'Disable',
        },
      },
      notes: {
        source: {
          LEAD: 'Lead',
          PATIENT: {
            ovoclinic: 'Patient',
            ovobank: 'Donor',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campaigns',
        description: 'Management of marketing campaigns. ',
        userId: {
          title: 'In charge',
          description: 'Campaign manager',
        },
        submit: 'Assign manager',
        create: 'Campaign',
        one: 'Campaign',
        many: 'Campaigns',
        columns: {
          name: 'Name',
          leads: 'Leads',
          leadsStatus: 'Active/Inactive',
          funnelName: 'Funnel',
          languageName: 'Language',
          clinics: 'Clinics',
          managers: 'Managers',
        },
      },
      create: {
        title: 'New campaign',
        subtitle: 'Registration of a new campaign in the system',
        form: {
          image: {
            title: 'Cover',
            description: 'We encourage you to upload an image to improve the commercial experience',
          },
          name: {
            title: 'Qualification',
            description: 'Marketing campaign title',
          },
          facebookId: {
            title: 'Facebook ID',
            description: 'Marketing campaign Facebook ID',
          },
          code: {
            title: 'Code',
            description: 'Marketing campaign code',
          },
          budget: {
            title: 'Budget',
            description: 'Budget invested by the clinic in the campaign',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic that finances the campaign',
          },
          firstActivityId: {
            title: 'First activity',
            description: 'First activity assigned when creating a lead',
          },
          funnelId: {
            title: 'Funnel',
            description: 'Marketing campaign conversion funnel',
          },
          status: {
            title: 'Active campaign',
            description: 'Enable or disable the campaign in the system',
            label: 'Active campaign',
          },
          languageId: {
            title: 'Language',
            description:
              'Select a language so that all leads in this campaign are created in that language',
          },
          startDate: {
            title: 'Start date',
            description: 'Campaign start date',
          },
          submit: 'Create campaign',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Channel',
                description: 'Marketing campaign channel',
              },
            },
          },
        },
      },
      enable: {
        title: 'Enable campaign',
        subtitle: 'Enable campaign in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable it?',
            description: 'This way it will be available in the system',
            label: 'Yes, I want to enable this campaign',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable campaign',
        subtitle: 'Disable campaign in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable it?',
            description: 'This way it will not be available in the system',
            label: 'Yes, I want to disable this campaign',
          },
          submit: 'Disable',
        },
      },
      update: {
        title: 'Update campaign',
        subtitle: 'The changes will affect all integrations',
        tabs: {
          general: {
            title: 'General',
            description: 'Campaign details',
          },
          contact: {
            title: 'Contact',
            description: 'Using this form you can add a contact to the campaign. ',
          },
          managers: {
            title: 'Managers',
            description: 'Campaign managers',
          },
          financing: {
            title: 'Financing',
            description: 'Campaign financing details',
          },
          webhook: {
            title: 'webhook',
            description:
              'A webhook is a way to integrate your application with other applications. ',
            toast: 'Link copied to clipboard',
          },
          participants: {
            title: 'Receiving clinics',
            description: 'These are the clinics that the marketing campaign is aimed at. ',
          },
        },
        participants: {
          list: {
            one: 'Receiving clinic',
            many: 'Receiving clinics',
            columns: {
              clinicName: 'Clinic',
            },
          },
          clinicsAdded: 'Clinics added',
          create: {
            title: 'Add receiving clinic',
            subtitle: 'Add a receiving clinic to the campaign',
            form: {
              clinicId: {
                title: 'Clinic',
                description: 'Add receiving clinic to the campaign',
              },
              submit: 'Add receiving clinic',
            },
          },
          delete: {
            title: 'Delete receiving clinic',
            subtitle: 'This action is irreversible',
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                description: 'You may prefer to deactivate this participant',
                label: 'Yes, I want to delete this receiving clinic',
              },
              submit: 'Eliminate',
            },
          },
          add: 'Add',
          addAll: 'Add all',
          clinicIdCopied: 'Clinic ID copied to clipboard',
        },
        financing: {
          form: {
            budget: {
              title: 'Budget',
              description: 'Budget invested by the clinic in the campaign',
            },
            financingClinicId: {
              title: 'Funding clinic',
              description: 'Clinic that financed the campaign',
            },
            submit: 'Update financing',
          },
        },
        managers: {
          list: {
            one: 'in charge',
            many: 'managers',
            columns: {
              name: 'Name',
            },
          },
          add: 'Add manager',
        },
        manager: {
          title: 'Assign manager',
          subtitle: 'Assign a manager to the campaign',
        },
        contact: {
          name: {
            title: 'Name',
          },
          surname: {
            title: 'Surnames',
          },
          prefix: {
            title: 'Prefix',
          },
          phone: {
            title: 'Phone',
          },
          email: {
            title: 'E-mail',
          },
          zip: {
            title: 'Zip code',
          },
          legal: {
            title: 'Legal Conditions',
            label: 'Accept the legal conditions',
            description: 'The contact accepts the legal conditions',
          },
          marketing: {
            title: 'Marketing',
            label: 'Marketing',
            description: 'The contact agrees to receive marketing information',
          },
          contactTime: {
            title: 'Contact time slot',
            description: 'Time in which the contact wishes to be contacted',
          },
          clinicId: {
            title: 'Clinic',
            description: 'The clinic where the contact wishes to be treated',
          },
          center: {
            title: 'Location',
            description: 'Contact location',
          },
          birthDate: {
            title: 'Birthdate',
            description: "Contact's date of birth",
          },
          birthCountry: {
            title: 'Country of birth',
            description: "Contact's country of birth",
          },
          weight: {
            title: 'Weight (kg)',
            description: 'Contact weight in kilograms',
          },
          height: {
            title: 'Height (cm)',
            description: 'Contact height in centimeters',
          },
          donationPreference: {
            title: 'City preference',
            description: 'City preference where to make the donation',
          },
          photo: {
            title: 'Photo',
            description: 'Contact photo',
          },
          submit: 'Add contact',
        },
        form: {
          name: {
            title: 'Qualification',
            description: 'Marketing campaign title',
          },
          facebookId: {
            title: 'Facebook ID',
            description: 'Marketing campaign Facebook ID',
          },
          code: {
            title: 'Code',
            description: 'Marketing campaign identification code',
          },
          budget: {
            title: 'Budget',
            description: 'Budget invested by the clinic in the campaign',
          },
          image: {
            title: 'Cover',
            description: 'We encourage you to upload an image to improve the commercial experience',
          },
          funnelId: {
            title: 'Funnel',
            description: 'Marketing campaign conversion funnel',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic that finances the campaign',
          },
          startDate: {
            title: 'Start date',
            description: 'Campaign start date',
          },
          endDate: {
            title: 'End date',
            description: 'Campaign end date',
          },
          firstActivityId: {
            title: 'First activity',
            description: 'First activity assigned when creating a lead',
          },
          status: {
            title: 'Active campaign',
            description: 'Enable or disable the campaign in the system',
            label: 'Active campaign',
          },
          languageId: {
            title: 'Language',
            description:
              'Select a language so that all leads in this campaign are created in that language',
          },
          submit: 'Update campaign',
        },
      },
      delete: {
        title: 'Delete campaign',
        subtitle: 'This action is irreversible',
        manager: {
          title: 'Delete manager',
          subtitle: 'This action is irreversible',
          form: {
            submit: 'Eliminate',
          },
        },
        form: {
          status: {
            title: 'Are you sure you want to delete it?',
            description: 'You may prefer to disable this campaign',
            label: 'Yes, I want to delete this campaign',
          },
          success: 'Successfully deleted campaign',
          error: 'Error deleting campaign',
          confirm: 'This action will delete everything related to the campaign, are you sure?',
          submit: 'Eliminate',
        },
      },
    },
    activities: {
      list: {
        title: 'Activities',
        description: 'Activity types allow salespeople to track tasks performed with leads',
        create: 'Activity',
        one: 'activity',
        many: 'activities',
        columns: {
          name: 'Name',
          description: 'Description',
          position: 'Position',
          icon: 'Icon',
          color: 'Color',
          time: 'Time',
        },
      },
      update: {
        subtitle: 'Modify activity in the system',
        title: 'Modify activity',
        form: {
          submit: 'Save',
          name: {
            title: 'Qualification',
            description: 'Identification title of the activity',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the activity. ',
            label: 'Select icon...',
            search: {
              placeholder: 'Search icon...',
            },
          },
          position: {
            title: 'Position',
            description: 'Position in which the activity is displayed in the activity list.',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the activity.',
          },
          color: {
            title: 'Color',
            description: 'Identification color of the activity. ',
          },
          time: {
            title: 'Time',
            description: 'Estimated time it takes to carry out the activity.',
          },
          primary: {
            title: 'Main activity',
            description:
              "The main activities are those that are shown on the salesperson's dashboard to allow quick interaction with contacts.",
            label: 'Show on dashboard',
          },
        },
        tabs: {
          activity: {
            title: 'Activity',
            description: 'Information associated with the activity',
          },
          results: {
            title: 'Results',
            description: 'The results describe what happened after carrying out the activity. ',
          },
          scopes: {
            title: 'Scopes',
            description: 'The scopes describe which departments can perform which activity.',
          },
          communicationChannels: {
            title: 'Communication routes',
            description: 'Communication channels associated with the activity',
          },
          integrations: {
            title: 'Integrations',
            description: 'External integrations that will be done when creating these tasks',
          },
        },
        results: {
          one: 'result',
          many: 'results',
          add: 'Add result',
        },
        scopes: {
          one: 'Scope',
          many: 'Scopes',
          add: 'Add scope',
        },
        communicationChannels: {
          add: 'Add communication path',
          list: {
            one: 'Communication route',
            many: 'Communication routes',
            columns: {
              channelName: 'Name',
            },
          },
          create: {
            title: 'Add communication path',
            subtitle: 'Add communication route to the activity',
            form: {
              title: 'Communication route',
              subtitle: 'Communication route associated with the activity',
              submit: 'Add',
              id: {
                title: 'Communication route',
                description: 'Communication route associated with the activity',
              },
            },
          },
          delete: {
            title: 'Delete communication path',
            subtitle: 'Eliminate communication channel of the activity',
            form: {
              submit: 'Eliminate',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'LandBot',
            description: 'Integration with LandBot. ',
            label: 'Activate LandBot integration',
          },
          landbotTemplateId: {
            title: 'LandBot Template',
            description: 'Integration with LandBot. ',
          },
          landbotActivityId: {
            title: 'LandBot Activity',
            description: 'Activity that will be generated by the LandBot',
          },
          landbotResultId: {
            title: 'LandBot Result',
            description: 'Result that LandBot will enter if the activity is completed',
          },
          submit: 'Save',
        },
      },
      enable: {
        title: 'Enable activity',
        subtitle: 'Enable system activity',
        form: {
          status: {
            title: 'Are you sure you want to enable this activity?',
            description: 'Enabling an activity means that it can be used in the system',
            label: 'Yes, I want to enable this activity',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable activity',
        subtitle: 'Disable system activity',
        form: {
          status: {
            title: 'Are you sure you want to disable this activity?',
            description: 'Disabling an activity means that it cannot be used in the system',
            label: 'Yes, I want to disable this activity',
          },
          submit: 'Disable',
        },
      },
      create: {
        title: 'Create activity',
        subtitle: 'Create activity in the system',
        form: {
          submit: 'Create',
          name: {
            title: 'Qualification',
            description: 'Identification title of the activity',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the activity. ',
            label: 'Select icon...',
            search: {
              placeholder: 'Search icon...',
            },
          },
          position: {
            title: 'Position',
            description: 'Position in which the activity is displayed in the activity list.',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the activity.',
          },
          color: {
            title: 'Color',
            description: 'Identification color of the activity. ',
          },
          time: {
            title: 'Time',
            description: 'Estimated time it takes to carry out the activity.',
          },
          primary: {
            title: 'Main activity',
            description:
              "The main activities are those that are shown on the salesperson's dashboard to allow quick interaction with contacts.",
            label: 'Show on dashboard',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Scopes',
        description: 'Management of the areas of activities in the system',
        create: 'Scope',
        one: 'Scope',
        many: 'Scopes',
        columns: {
          clinicName: 'Clinic',
          departmentName: 'Department',
        },
      },
      create: {
        title: 'Create scope',
        subtitle: 'Create scope in the system',
        form: {
          submit: 'Create',
          clinicId: {
            title: 'Clinic',
            description: 'Clinic to which the area belongs',
          },
          departmentId: {
            title: 'Department',
            description: 'Department to which the scope belongs',
          },
          departmentIds: {
            title: 'Departments',
            description: 'Departments to which the template will have scope',
          },
        },
      },
      delete: {
        title: 'Delete scope',
        subtitle: 'This action is irreversible',
        form: {
          submit: 'Eliminate',
        },
      },
    },
    results: {
      list: {
        title: 'Results',
        description: 'Management of activity results in the system. ',
        create: 'Add Result',
        one: 'result',
        many: 'results',
        columns: {
          name: 'Name',
          description: 'Description',
          icon: 'Icon',
          color: 'Color',
          time: 'Estimated time',
          position: 'Position',
          activityName: 'Activity',
        },
      },
      update: {
        subtitle: 'Modify activity result in the system',
        title: 'Modify result',
        form: {
          submit: 'Update',
          name: {
            title: 'Name',
            description: 'Identification name of the result',
          },
          icon: {
            title: 'Icon',
            label: 'Icon',
            description: 'Icon to identify the result. ',
            search: {
              placeholder: 'Search icon...',
            },
          },
          position: {
            title: 'Position',
            description: 'Position in which the result is displayed in the results list.',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the result.',
          },
          color: {
            title: 'Color',
            description: 'Identification color of the result. ',
          },
          time: {
            title: 'Time',
            description: 'Estimated time it takes to produce the result.',
          },
          activityId: {
            title: 'Activity',
            description: 'Activity to which the result belongs.',
          },
          nextActivityId: {
            title: 'Next activity',
            description: 'Activity that will be performed after performing this result.',
          },
          nextActivityDelay: {
            title: 'Waiting time',
            description: 'Waiting time to perform the next activity. ',
          },
          disableLead: {
            title: 'Disable lead',
            description: 'Disable the lead after performing this result',
            label: 'Yes, disable the lead',
          },
          contacted: {
            title: 'Lead contacted',
            description: 'Contact has been established with the lead',
            label: 'Yes, the lead has been contacted',
          },
          informed: {
            title: 'Informed Lead',
            description: 'The lead has received information about the service',
            label: 'Yes, the lead has been informed',
          },
          converted: {
            title: {
              ovoclinic: 'Lead turned into patient',
              ovobank: 'Lead converted to donor',
            },
            description: {
              ovoclinic: 'The lead has been converted into a patient. ',
              ovobank: 'The lead has been converted into a donor. ',
            },
            label: {
              ovoclinic: 'Yes, the lead has been converted',
              ovobank: 'Yes, the lead has been converted',
            },
          },
          appointed: {
            title: 'Lead cited for commercial information visit',
            description: 'An appointment has been scheduled with the lead',
            label: 'Yes, the lead has been cited',
          },
          attended: {
            title: 'Medical information visit carried out',
            description:
              'With this information it will be possible to count when the lead attended the medical appointment.',
            label: 'Yes, the lead has had a medical appointment',
          },
          comercialAttended: {
            title: 'Budget delivered',
            description:
              'With this information it will be possible to count when a budget is delivered to the lead.',
            label: 'Yes, the lead has received a quote',
          },
          medicalAppointed: {
            title: 'Lead scheduled for medical information visit',
            description: 'A medical appointment has been scheduled with the lead',
            label: 'Yes, the lead has been cited',
          },
          medicalEvaluated: {
            title: 'Medical evaluation',
            description: 'The lead has been evaluated by a doctor',
            label: 'Yes, the lead has been evaluated',
          },
          templateId: {
            title: 'Name',
            description: 'Template name',
          },
          landbotId: {
            title: 'LandBot',
            description: 'The ID of the bot to send to the contact along with the template',
          },
          landbotAppointed: {
            title: 'Commercial appointment arranged by LandBot',
            description:
              'If an activity is carried out with this result it means that the bot has made an appointment',
            label: 'Yes, the bot has made an appointment',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead cited for commercial information visit',
            description: 'An appointment has been scheduled with the lead',
            label: 'Yes, the lead has been cited',
          },
          comercialAttended: {
            title: 'Commercial information visit carried out',
            description:
              'With this information it will be possible to count when the lead attended the commercial appointment.',
            label: 'Yes, the lead has had a sales appointment',
          },
          medicalAppointed: {
            title: 'Lead scheduled for medical information visit',
            description: 'A medical appointment has been scheduled with the lead',
            label: 'Yes, the lead has been cited',
          },
          attended: {
            title: 'Medical information visit carried out',
            description:
              'With this information it will be possible to count when the lead attended the medical appointment.',
            label: 'Yes, the lead has had a medical appointment',
          },
          converted: {
            title: 'Medical fit',
            description: 'The lead has been considered suitable to donate',
            label: 'Yes, the lead has been considered suitable',
          },
          submit: 'Update',
        },
        tabs: {
          result: {
            title: 'Result',
            description: 'Management of activity results in the system. ',
          },
          template: {
            title: 'Template',
            description: 'Template to use for Landbot.',
          },
          nextActivity: {
            title: 'Next activity',
            description:
              'The next activity is the activity that will be performed after the completion of this result.',
          },
          ccAchievement: {
            title: 'Call Center Milestones',
            description: "Milestones that must be met for the result to be considered 'achieved'",
          },
          atpcAchievement: {
            title: 'Commercial ATP Milestones',
            description: "Milestones that must be met for the result to be considered 'achieved'",
          },
          atpiAchievement: {
            title: 'ATP International Milestones',
            description: "Milestones that must be met for the result to be considered 'achieved'",
          },
          ovoBankAtpAchievement: {
            title: 'Donor Milestones',
            description: "Milestones that must be met for the result to be considered 'achieved'",
          },
          integrationAchievement: {
            title: 'Integration Milestones',
            description: 'The milestones that could be met by some integration. ',
          },
          reactivity: {
            title: 'Reactivity',
            description: 'Reactivity of the result. ',
          },
        },
      },
      enable: {
        title: 'Enable result',
        subtitle: 'Enable result in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this result?',
            description: 'Enabling a result means that it can be used in the system',
            label: 'Yes, I want to enable this result',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable result',
        subtitle: 'Disable result in system',
        form: {
          status: {
            title: 'Are you sure you want to disable this result?',
            description: 'Disabling a result means that it cannot be used in the system',
            label: 'Yes, I want to disable this result',
          },
          submit: 'Disable',
        },
      },
      create: {
        title: 'Create result',
        subtitle: 'Create activity result in the system',
        form: {
          submit: 'Create',
          name: {
            title: 'Name',
            description: 'Identification name of the result',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the result. ',
            label: 'Icon',
            search: {
              placeholder: 'Search icon...',
            },
          },
          position: {
            title: 'Position',
            description: 'Position in which the result is displayed in the results list.',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the result.',
          },
          color: {
            title: 'Color',
            description: 'Identification color of the result. ',
          },
          time: {
            title: 'Time',
            description: 'Estimated time it takes to produce the result.',
          },
          activityId: {
            title: 'Activity',
            description: 'Activity to which the result belongs.',
          },
          nextActivityId: {
            title: 'Next activity',
            description: 'Activity that will be performed after performing this result.',
          },
          nextActivityDelay: {
            title: 'Waiting time',
            description: 'Waiting time to perform the next activity. ',
          },
          contacted: {
            title: 'Lead contacted',
            description: 'Contact has been established with the lead',
            label: 'Yes, the lead has been contacted',
          },
          informed: {
            title: 'Informed Lead',
            description: 'The lead has received information about the service',
            label: 'Yes, the lead has been informed',
          },
          converted: {
            title: 'Convert lead',
            description: {
              ovoclinic: 'The lead has been converted into a patient. ',
              ovobank: 'The lead has been converted into a donor. ',
            },
            label: 'Yes, the lead has been converted',
          },
          appointed: {
            title: 'Cited lead',
            description: 'An appointment has been scheduled with the lead',
            label: 'Yes, the lead has been cited',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Services',
        description: 'Management of services in the system. ',
        create: 'Service',
        one: 'service',
        many: 'services',
        columns: {
          name: 'Name',
          price: 'Price',
          nextActivityName: 'Next activity',
          nextActivityDelay: 'Postponed activity time',
        },
      },
      update: {
        subtitle: 'Modify system service',
        title: 'Modify service',
        form: {
          submit: 'Update',
          name: {
            title: 'Name',
            description: 'Identification name of the service',
          },
          price: {
            title: 'Price',
            description: 'Service price',
          },
          nextActivityId: {
            title: 'Next activity',
            description: 'Next activity',
          },
          nextActivityDelay: {
            title: 'Postponed activity time',
            description: 'Time the activity is postponed',
          },
        },
        tabs: {
          service: {
            title: 'Service',
            description: 'Management of activity services in the system. ',
          },
          nextActivity: {
            title: 'Next activity',
            description:
              'The following activity is the activity that will be carried out after the completion of this service.',
          },
        },
      },
      enable: {
        title: 'Enable service',
        subtitle: 'Enable service on the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this service?',
            description: 'Enabling a service means that it can be used on the system',
            label: 'Yes, I want to enable this service',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable service',
        subtitle: 'Disable service in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this service?',
            description: 'Disabling a service means that it cannot be used on the system',
            label: 'Yes, I want to disable this service',
          },
          submit: 'Disable',
        },
      },
      create: {
        title: 'Create service',
        subtitle: 'Create activity service in the system',
        form: {
          submit: 'Create',
          name: {
            title: 'Name',
            description: 'Identification name of the service',
          },
          price: {
            title: 'Price',
            description: 'Service price',
          },
          nextActivityId: {
            title: 'Next activity',
            description: 'Next activity',
          },
          nextActivityDelay: {
            title: 'Postponed activity time',
            description: 'Time the activity is postponed',
          },
        },
      },
    },
    products: {
      list: {
        title: 'Products',
        description: 'Product management in the system',
        create: 'Add Product',
        one: 'product',
        many: 'products',
        columns: {
          name: 'Name',
          price: 'Price',
          tax: 'Taxes',
          categoryName: 'Category',
          clinicName: 'Clinic',
        },
      },
      update: {
        subtitle: 'Modify product in the system',
        title: 'Modify product',
        form: {
          submit: 'Save',
          name: {
            title: 'Name',
            description: 'Product identification name',
          },
          icon: {
            title: 'Icon',
            description: 'Product identification icon. ',
          },
          description: {
            title: 'Description',
            description: 'A brief description of the product to detail its features',
          },
          color: {
            title: 'Color',
            description: 'Product identification color. ',
          },
          price: {
            title: 'Price',
            description: 'Product sales price',
          },
          tax: {
            title: 'tax',
            description: 'Taxes applied to the product',
          },
          path: {
            title: 'Path',
            description: 'Path where the product is located',
          },
          categoryId: {
            title: 'Category',
            description: 'Category to which the product belongs',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic to which this category belongs',
          },
        },
      },
      enable: {
        title: 'Enable product',
        subtitle: 'Enable product in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this product?',
            description: 'Enabling a product means that it can be used in the system',
            label: 'Yes, I want to enable this product',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable product',
        subtitle: 'Disable product in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this product?',
            description: 'Disabling a product means that it cannot be used on the system',
            label: 'Yes, I want to disable this product',
          },
          submit: 'Disable',
        },
      },
      create: {
        title: 'Create product',
        subtitle: 'Create product in the system',
        steps: {
          category: {
            form: {
              selected: 'Selected category',
              categoryId: {
                title: 'Category',
                description: 'Category to which the product belongs',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Selected clinic',
              clinicId: {
                title: 'Clinic',
                description: 'Clinic to which this category belongs',
              },
            },
          },
        },
        form: {
          submit: 'Create',
          name: {
            title: 'Name',
            description: 'Product identification name',
          },
          icon: {
            title: 'Icon',
            description: 'Product identification icon. ',
          },
          description: {
            title: 'Description',
            description: 'A brief description of the product to detail its features',
          },
          color: {
            title: 'Color',
            description: 'Product identification color. ',
          },
          price: {
            title: 'Price',
            description: 'Product sales price',
          },
          tax: {
            title: 'tax',
            description: 'Taxes applied to the product',
          },
          path: {
            title: 'Path',
            description: 'Path where the product is located',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Categories',
        description: 'Product grouping',
        create: 'Add Category',
        one: 'category',
        many: 'categories',
        columns: {
          name: 'Name',
          clinicName: 'Clinic',
          created: 'Creation date',
        },
      },
      update: {
        subtitle: 'Modify category in the system',
        title: 'Modify category',
        form: {
          submit: 'Save',
          name: {
            title: 'Name',
            description: 'Identification name of the category',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the category',
          },
        },
      },
      create: {
        title: 'Create category',
        subtitle: 'Create category in the system',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clinic',
                description: 'Clinic to which this category belongs',
              },
            },
          },
        },
        form: {
          submit: 'Create',
          name: {
            title: 'Name',
            description: 'Identification name of the category',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the category',
          },
        },
      },
      enable: {
        title: 'Enable category',
        subtitle: 'Enable category in the system',
        form: {
          status: {
            title: 'Are you sure you want to enable this category?',
            description: 'Enabling a category means that it can be used in the system',
            label: 'Yes, I want to enable this category',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable category',
        subtitle: 'Disable category in the system',
        form: {
          status: {
            title: 'Are you sure you want to disable this category?',
            description: 'Disabling a category means that it cannot be used in the system',
            label: 'Yes, I want to disable this category',
          },
          submit: 'Disable',
        },
      },
    },
    techniques: {
      list: {
        title: 'Techniques',
        description: 'Management of treatment techniques in the system',
        create: 'Technique',
        one: 'Technique',
        many: 'Techniques',
        columns: {
          name: 'Name',
          clinic: 'Clinic',
          created: 'Creation date',
          price: 'Price',
        },
      },
      create: {
        title: 'Create technique',
        subtitle: 'Registration of a new technique in the system',
        form: {
          id: {
            title: 'Name',
            description: 'Identification name of the technique',
          },
          name: {
            title: 'Name',
            description: 'Identification name of the technique',
          },
          price: {
            title: 'Price',
            description: 'Sales price of the technique',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the technique',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic where the technique is performed',
          },
          submit: 'Create technique',
        },
      },
      update: {
        title: 'Update technique',
        subtitle: 'Modification of a technique in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Identification name of the technique',
          },
          price: {
            title: 'Price',
            description: 'Sales price of the technique',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the technique',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic where the technique is performed',
          },
          submit: 'Update technique',
        },
      },
      enable: {
        title: 'Enable technique',
        subtitle: 'Enable technique in the system',
        form: {
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable technique',
        subtitle: 'Disable technique in the system',
        form: {
          submit: 'Disable',
        },
      },
      add: 'Add',
    },
    programs: {
      list: {
        title: 'Programs',
        description: 'Management of catalog programs',
        create: 'Program',
        one: 'Program',
        many: 'Programs',
        columns: {
          name: 'Name',
          created: 'Creation date',
          price: 'Price',
          techniques: 'Techniques',
        },
      },
      create: {
        title: 'Create program for the catalog',
        subtitle: 'Registration of a new program in the catalog',
        form: {
          name: {
            title: 'Name',
            description: 'Program identification name',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the program',
          },
          complexity: {
            title: 'Complexity',
            description: 'Program complexity level',
          },
          price: {
            title: 'Price',
            description: 'Program sales price',
          },
          code: {
            title: 'Code',
            description: 'Program identification code',
          },
          submit: 'Create program',
        },
      },
      enable: {
        title: 'Enable program',
        subtitle: 'Enable program in the catalog',
        form: {
          status: {
            title: 'Are you sure you want to enable it?',
            description: 'Enabling a program means that it can be used on the system',
            label: 'Yes, I want to enable this program',
          },
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable program',
        subtitle: 'Disable program in the catalog',
        form: {
          status: {
            title: 'Are you sure you want to disable it?',
            description: 'Disabling a program means that it cannot be used on the system',
            label: 'Yes, I want to disable this program',
          },
          submit: 'Disable',
        },
      },
      update: {
        title: 'Update program',
        subtitle: 'Modifying a program in the catalog',
        form: {
          name: {
            title: 'Name',
            description: 'Program identification name',
          },
          description: {
            title: 'Description',
            description: 'A brief description to detail the program',
          },
          complexity: {
            title: 'Complexity',
            description: 'Program complexity level',
          },
          clinicId: {
            title: 'Clinic',
            description: 'Clinic in which the program is carried out',
          },
          price: {
            title: 'Price',
            description: 'Program sales price',
          },
          code: {
            title: 'Code',
            description: 'Program identification code',
          },
          submit: 'Update program',
        },
        tabs: {
          program: {
            title: 'Programs',
            description: 'Modifying a program in the catalog',
          },
          techniques: {
            title: 'Techniques',
            description: 'Techniques associated with the program',
          },
          phases: {
            title: 'Stages',
            description: 'Stages associated with the program',
          },
          cycles: {
            title: 'Cycles',
            description: 'Cycles associated with the program',
          },
        },
        cycles: {
          list: {
            title: 'Cycles',
            description: 'Cycles associated with the program',
            columns: {
              cycleName: 'Name',
              cycleCreated: 'Creation date',
            },
            one: 'Cycle',
            many: 'Cycles',
          },
          create: {
            title: 'Add cycle',
            subtitle: 'Add cycle to program',
            form: {
              cycleId: {
                title: 'Cycle',
                description: 'Cycle associated with the program',
              },
              submit: 'Add',
            },
          },
          delete: {
            title: 'Delete cycle',
            subtitle: 'This action is irreversible',
            form: {
              submit: 'Eliminate',
            },
          },
        },
        phases: {
          create: {
            title: 'Add stage',
            subtitle: 'Add stage to program',
            form: {
              phaseId: {
                title: 'Stage',
                description: 'Stage associated with the program',
              },
              submit: 'Add',
            },
          },
          delete: {
            title: 'Delete stage',
            subtitle: 'This action is irreversible',
            form: {
              status: {
                title: 'Are you sure you want to delete it?',
                description: 'You may prefer to disable this stage',
                label: 'Yes, I want to eliminate this stage',
              },
              submit: 'Eliminate',
            },
          },
          list: {
            title: 'Stages',
            description: 'Stages associated with the program',
            columns: {
              phaseName: 'Name',
              phaseCreated: 'Creation date',
            },
            one: 'Stage',
            many: 'Stages',
          },
        },
        techniques: {
          create: {
            title: 'Add technique',
            subtitle: 'Add technique to program',
            form: {
              id: {
                title: 'Technique',
                description: 'Technique associated with the program',
              },
              submit: 'Add',
            },
          },
          delete: {
            title: 'Delete technique',
            subtitle: 'This action is irreversible',
            form: {
              submit: 'Eliminate',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Stages',
        description: 'Management of the stages of a treatment',
        create: 'Stage',
        one: 'Stage',
        many: 'Stages',
        columns: {
          name: 'Name',
          created: 'Creation date',
        },
      },
      create: {
        title: 'Create stage',
        subtitle: 'Registration of a new stage in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Stage name',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the stage.',
          },
          icon: {
            title: 'Icon',
            label: 'Icon',
            description: 'Icon to identify the stage. ',
            search: {
              placeholder: 'Search icon...',
            },
          },
          color: {
            title: 'Color',
            description: 'Identifying color of the stage. ',
          },
          submit: 'Create stage',
        },
      },
      update: {
        title: 'Update stage',
        subtitle: 'Modification of a stage in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Stage name',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the stage.',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the stage. ',
            search: {
              placeholder: 'Search icon...',
            },
            label: 'Icon',
          },
          color: {
            title: 'Color',
            description: 'Identifying color of the stage. ',
          },
          submit: 'Save',
        },
        tabs: {
          phase: {
            title: 'Stage',
            description: 'Stage information',
          },
          causes: {
            title: 'Causes',
            description: 'Causes for which a treatment can be canceled',
            one: 'Cause',
            many: 'Causes',
          },
        },
      },
      enable: {
        title: 'Enable stage',
        subtitle: 'Enable stage in the system',
        form: {
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable stage',
        subtitle: 'Disable stage in the system',
        form: {
          submit: 'Disable',
        },
      },
    },
    causes: {
      list: {
        title: 'Causes',
        description: 'Management of the causes of cancellation of a treatment',
        create: 'Cause',
        one: 'Cause',
        many: 'Causes',
        columns: {
          name: 'Name',
          created: 'Creation date',
        },
      },
      create: {
        title: 'Create cause',
        subtitle: 'Registration of a new cause in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Cause name',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the cause.',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the cause. ',
            search: {
              placeholder: 'Search icon...',
            },
            label: 'Icon',
          },
          color: {
            title: 'Color',
            description: 'Identifying color of the cause. ',
          },
          submit: 'Create cause',
        },
      },
      update: {
        title: 'Update cause',
        subtitle: 'Modification of a cause in the system',
        form: {
          name: {
            title: 'Name',
            description: 'Cause name',
          },
          description: {
            title: 'Description',
            description: 'A brief summary to give more details about the cause.',
          },
          icon: {
            title: 'Icon',
            description: 'Icon to identify the cause. ',
            search: {
              placeholder: 'Search icon...',
            },
            label: 'Icon',
          },
          color: {
            title: 'Color',
            description: 'Identifying color of the cause. ',
          },
          submit: 'Save',
        },
      },
      enable: {
        title: 'Enable cause',
        subtitle: 'Enable cause in the system',
        form: {
          submit: 'Enable',
        },
      },
      disable: {
        title: 'Disable cause',
        subtitle: 'Disable cause in system',
        form: {
          submit: 'Disable',
        },
      },
    },
  },
  board: {
    total: 'Total',
    months: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Sea',
      apr: 'Apr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dec',
    },
    noData: {
      title: 'No data available',
      description: 'You can try selecting other filters',
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Leads by campaign and status',
        description: 'Leads by campaign and status',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
      leadPerClinicAndState: {
        title: 'Leads by clinic and state',
        description: 'Leads by clinic and state',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
    },
    direction: {
      title: 'Dashboards address',
      description: 'Executive view of CRM information in real time',
      cost: 'Lead cost',
      leadCostByClinicAndMonth: {
        title: 'Cost per lead per clinic',
        description: 'Cost per lead per clinic',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
      leadCostByFunnelAndMonth: {
        title: 'Distribution of leads by clinics and department',
        description: 'Distribution of leads by clinics and department',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
      leadCostByCampaignAndMonth: {
        title: 'Cost per lead per campaign',
        description: 'Cost per lead per campaign',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
    },
    marketing: {
      title: 'Marketing Dashboards',
      description: 'Real-time CRM information marketing insight',
      secondary: {
        title: 'Marketing campaigns',
        description: 'Visualization of lead information regarding marketing campaigns.',
      },
      convertionRateByCampaign: {
        title: 'Conversion rate per campaign',
        description: 'Conversion rate per campaign',
        totalLeads: 'Total leads',
        totalConvertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
      },
      totalLeads: 'TOTAL LEADS',
      leadsState: 'LEAD STATUS',
      leadActiveInactive: 'ACTIVE/INACTIVE LEADS',
      leadsPerCampaign: {
        title: 'Leads per campaign',
        description: 'Leads per campaign',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
        filters: {
          campaign: 'Campaign',
          funnel: 'Funnel',
        },
      },
      leadCostDetail: {
        title: 'Detail',
        selectedClinic: 'Selected clinic',
        selectedCampaign: 'Selected campaign',
        budget: 'Budget',
      },
      costCampaign: {
        '0': 'Selected clinic: ',
        '1': 'Selected campaign: ',
        '2': 'Budget: ',
        '3': 'Number of leads: ',
        '4': 'Cited Leads: ',
        '5': 'Total cost of leads: ',
        '6': 'Total cost quoted: ',
      },
      leadsPerFunnel: {
        title: 'Leads per funnel',
        description: 'Leads per funnel',
        totalLeads: 'Total leads',
        totalCost: 'Total cost',
        costPerLead: 'Cost per lead',
      },
      filters: {
        year: 'Year',
        yearSubtitle: 'Filter by selected year',
        by: 'Filter by',
      },
      generalTable: {
        totalLeads: 'Total leads',
        informed: 'V.I performed',
        total: 'Cumulative total',
        costPerLead: 'Cost per lead',
        totalRow: 'Total',
      },
    },
    subordinates: {
      title: 'My team performance',
      description: "Viewing my team's performance",
      performance: {
        title: 'Milestones achieved',
        description: 'Viewing milestones achieved by my team',
      },
      performanceSubordinates: {
        title: 'PERFORMANCE OF MY TEAM',
      },
      performanceSubordinate: {
        title: "MY PARTNER'S PERFORMANCE",
      },
      filters: {
        clinics: {
          all: 'All',
        },
        subordinate: {
          title: 'Employees',
          subtitle: 'Filter by selected employee',
        },
      },
    },
    intervention: {
      title: 'Treatment forecast',
      description: 'Viewing estimated treatments',
      expectedInterventions: {
        monthsTable: 'TREATMENT FORECAST',
        yearsTable: 'FORECAST OF ACCUMULATED TREATMENTS',
      },
      segmention: 'Breakdown of treatments',
      lossGainSegmentation: 'Breakdown of profit and loss',
      one: 'Treatment',
      many: 'Treatments',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Losses',
        total_first_attempt: 'Guarantees (1st attempt)',
        total: 'Total',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Losses',
        total_first_attempt: 'Earnings',
        total: 'Total',
      },
      filters: {
        complexity: {
          title: 'Complexity',
          subtitle: 'Filter by selected complexity',
          types: {
            ALL: 'All',
            HIGH: 'High',
            LOW: 'Low',
          },
        },
      },
    },
    callcenter: {
      title: 'Call center dashboards',
      description: 'Call center view of CRM information in real time',
    },
    leads: {
      title: 'Dashboards leads',
      description: 'View of leads from CRM information in real time',
      totalLeads: {
        title: 'Total leads',
        description: 'Summary of leads by clinic and department',
        filters: {
          clinic: 'Clinic',
          clinicSubtitle: 'Filter by selected clinic',
          campaign: 'Campaign',
          campaignSubtitle: 'Filter by selected campaign',
          optionA: 'Option A',
          optionASubtitle: '1st option of the comparison',
          optionB: 'Option B',
          optionBSubtitle: '2nd option of the comparison',
          funnel: 'Origin',
          funnelSubtitle: 'Filter by origin',
          all: 'All',
          department: 'Department',
          departmentSubtitle: 'Filter by selected department',
          year: 'Year',
          yearSubtitle: 'Filter by selected year',
          month: 'Month',
          monthSubtitle: 'Filter by selected month',
          allMonths: 'All',
        },
        comparativeGraphicTitle: 'LEADS COMPARISON',
        comparativeAcumulatedGraphicTitle: 'COMPARISON OF LEADS/ ACCUMULATED',
        totalLeads: 'TOTAL LEADS',
        summary: 'SUMMARY',
        export: 'EXPORT CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Sales funnel',
      description: 'Sales funnel view of CRM information in real time',
      totalSales: {
        graphicTitle: 'SALES FUNNEL',
      },
    },
    sales: {
      title: 'Dashboard sales',
      description: 'Sales view of CRM information in real time',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Treatments, techniques and services per clinic',
        description: 'Treatments, techniques and services per clinic',
        totalTreatments: 'Total treatments',
        totalTechniques: 'Total techniques',
        totalServices: 'Total services',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Treatments, techniques and services by department',
        description: 'Treatments, techniques and services by department',
        totalTreatments: 'Total treatments',
        totalTechniques: 'Total techniques',
        totalServices: 'Total services',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Sales summary',
        ovobank: 'Sales summary',
      },
      description: {
        ovoclinic: 'Summary of leads converted into patients',
        ovobank: 'Summary of leads converted into donors',
      },
      totalSales: {
        title: 'Sales',
        description: 'Viewing sales made',
        filters: {
          clinic: 'Clinic',
          clinicSubtitle: 'Filter by selected clinic',
          optionA: 'Option A',
          optionASubtitle: '1st option of the comparison',
          optionB: 'Option B',
          optionBSubtitle: '2nd option of the comparison',
          department: 'Department',
          departmentSubtitle: 'Filter by selected department',
          month: 'Month',
          monthSubtitle: 'Filter by selected month',
        },
        comparativeGraphicTitle: 'SALES COMPARISON',
        export: 'EXPORT CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'COMPARISON OF SALES/ ACCUMULATED',
      },
    },
    medical: {
      title: 'Medical Dashboards',
      description: 'Medical department view of CRM information in real time',
      leadConversionByFunnelAndMonth: {
        title: 'Lead conversion rate by origin and month',
        description: 'Lead conversion rate by origin and month',
        totalLeads: 'Total leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Lead conversion percentage by origin and month',
        description: 'Lead conversion percentage by origin and month',
        totalLeads: 'Total leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion percentage',
      },
      estimatedTreatmentByYear: {
        title: 'Scheduled treatments',
        description:
          'Comparison of treatments scheduled in the selected year and the previous year',
        estimated: 'Scheduled treatment',
        finished: 'Treatment completed',
        previousEstimated: 'Scheduled treatments',
        previousFinished: 'Completed treatments',
      },
      finishedTreatmentByYear: {
        title: 'Completed treatments',
        description:
          'Comparison of treatments completed in the selected year and the previous year',
        estimated: 'Scheduled treatment',
        finished: 'Treatment completed',
        previousEstimated: 'Scheduled treatments',
        previousFinished: 'Completed treatments',
      },
      leadConversionBySpecialist: {
        title: 'Lead conversion rate per specialist',
        description: 'Lead conversion rate per specialist',
        totalLeads: 'Total leads',
        appointedLeads: 'Cited Leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
        treatmentSold: 'Treatments sold',
        treatmentFinished: 'Completed treatments',
        specialistData: 'Specialist information',
      },
      leadConversionBySpecialistKPI: {
        title: 'KPI Lead conversion rate per specialist',
        conversionRate: 'Conversion rate',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Lead conversion rate per specialist and month',
        description: 'Lead conversion rate per specialist and month',
        totalLeads: 'Total leads',
        appointedLeads: 'Cited Leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
        treatmentSold: 'Treatments sold',
        treatmentFinished: 'Completed treatments',
        specialistData: 'Specialist information',
        leadsAttended: 'Leads served',
        leadsAttendedConverted: 'Served leads converted',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'KPI Lead conversion rate per specialist and month',
        description: 'KPI Lead conversion rate per specialist and month',
        conversionRate: 'Conversion rate',
      },
      leadConversionByFunnel: {
        title: 'Lead conversion rate by origin',
        description: 'Lead conversion rate by origin',
        totalLeads: 'Total leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
        appointedLeads: 'Cited Leads',
        treatmentSold: 'Treatments sold',
        treatmentFinished: 'Completed treatments',
      },
      leadConversionByFunnelKPI: {
        title: 'KPI Lead conversion rate by origin',
        description: 'KPI Lead conversion rate by origin',
        totalLeads: 'Total leads',
        convertedLeads: 'Converted leads',
        conversionRate: 'Conversion rate',
        appointedLeads: 'Cited Leads',
        treatmentSold: 'Treatments sold',
        treatmentFinished: 'Completed treatments',
        convertedLeadsRate: 'Lead conversion rate',
        treatmentSoldRate: 'Conversion rate of treatments sold',
      },
    },
    menu: {
      leads: 'Lead Summary',
      directory: 'Directory',
      direction: 'Address',
      expectedIntervention: 'Treatment forecast',
      general: 'General',
      callcenter: 'Call center',
      medical: 'Doctor',
      marketing: 'Marketing',
      sale: 'Sales',
      saleFunnel: 'Sales funnel',
      summarySales: 'Sales summary',
      subordinates: 'Employee performance',
    },
    sections: {
      marketing: {
        actives: 'Assets',
        inactives: 'Inactive',
        converted: 'Converted',
        appointed: 'Cited',
        informed: 'Informed',
        contacted: 'Contacted',
        leadPerCampaign: {
          title: 'Leads per marketing campaign',
        },
        leadPerFunnel: {
          title: 'Leads by campaign origin',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Lead-patient conversion rate per funnel',
            ovobank: 'Lead-donor conversion rate per funnel',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Lead-patient conversion rate per campaign',
            ovobank: 'Lead-donor conversion rate per campaign',
          },
          patients: {
            ovoclinic: 'Patients',
            ovobank: 'Donors',
          },
          leads: 'Leads',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Lead-patient conversion rate by origin',
            ovobank: 'Lead-donor conversion rate by origin',
          },
          patients: {
            ovoclinic: 'Patients',
            ovobank: 'Donors',
          },
          leads: 'Leads',
        },
        leadPerClinicPerState: {
          total: 'Total',
          title: 'Leads per clinic per state',
          actives: 'Assets',
          inactives: 'Inactive',
          converted: 'Converted',
          appointed: 'Cited',
          appointedMedic: 'Cited (doctor)',
          contacted: 'Contacted',
          informed: 'Informed',
          loss: 'Low',
          cancelled: 'Canceled',
        },
        leadPerCampaignPerState: {
          total: 'Total',
          title: 'Leads per campaign per state',
          actives: 'Assets',
          inactives: 'Inactive',
          converted: 'Converted',
          appointed: 'Cited',
          contacted: 'Contacted',
          informed: 'Informed',
          loss: 'Low',
        },
        totalLeadsPerState: {
          total: 'Total',
          appointed: 'Cited',
          percentage: 'Percentage',
          cost: 'Cost',
          actives: 'Assets',
          inactives: 'Inactive',
        },
        filter: {
          campaignId: {
            title: 'Filter by campaign',
          },
          languageId: {
            title: 'Filter by language',
          },
          funnelId: {
            title: 'Filter by origin',
          },
          clinicId: {
            title: 'Filter by clinic',
          },
        },
        listLeadsByCampaign: {
          title: 'Campaign Breakdown',
          campaign: 'Marketing campaign',
          funnel: 'Origin',
          financingClinic: 'Funding clinic',
          language: 'Department',
          budget: 'Campaign budget',
          nLeads: 'No. of leads',
          costPerLead: 'Cost per lead',
        },
        all: 'All',
      },
      callcenter: {
        listLeadsByReason: {
          title: 'Leads due to cancellation',
          reason: 'Reason',
          nLeads: 'No. of leads',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Lead cost per clinic',
          monthFilter: 'Filter by month',
        },
        costsLeadPerFunnel: {
          title: 'Lead cost by origin',
        },
        costsLeadPerCampaign: {
          title: 'Lead cost per campaign',
        },
        costsLeadPerLanguage: {
          title: 'Lead cost per department',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Treatments, techniques and services per clinic',
        },
        salePerLanguage: {
          title: 'Treatments, techniques and services by department',
        },
      },
      saleFunnel: {
        title: 'Business funnel',
        description: 'Visualization of the general status of the leads during the sales process',
      },
    },
  },
  profile: {
    menu: {
      profile: 'Profile',
      account: 'my account',
      changePassword: 'Change password',
    },
    changePassword: {
      title: 'Change password',
      subtitle: 'Change your account password',
      form: {
        password: {
          title: 'Password',
          description: 'New password for the user',
        },
        repeatPassword: {
          title: 'Repeat password',
          description: 'Repeat the password to confirm it',
        },
        submit: 'Change password',
      },
    },
    account: {
      title: 'my account',
      subtitle: 'Personal and account information',
      form: {
        name: {
          title: 'Name',
          description: 'Enter your name',
        },
        surname: {
          title: 'Surnames',
          description: 'Enter your last name',
        },
        username: {
          title: 'User name',
          description: 'Enter your username',
        },
        phone: {
          title: 'Phone',
          description: 'Enter your phone number',
        },
        submit: 'Update account',
      },
    },
  },
  common: {
    search: {
      placeholder: 'Look for...',
    },
    actions: {
      create: 'Create',
      update: 'Update',
      delete: 'Eliminate',
      save: 'Save',
      cancel: 'Cancel',
    },
    tasks: {
      disabled: 'Show inactive',
      noResult: 'No result',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Delete note',
          description: 'Are you sure you want to delete this note?',
          cancel: 'Cancel',
          submit: 'Eliminate',
        },
      },
      noteType: {
        title: 'See observations of the',
        patient: {
          ovoclinic: 'Patient',
          ovobank: 'Donor',
        },
        lead: {
          ovoclinic: 'Lead',
          ovobank: 'Lead',
        },
      },
      filter: {
        title: 'Filter by',
        tags: {
          medical: 'Doctor',
          nursing: 'Nursing',
          followUp: 'Follow-up',
        },
      },
    },
    time: {
      seconds: 'seconds',
      second: 'second',
      minutes: 'minutes',
      minute: 'minute',
      hour: 'hour',
      hours: 'hours',
      days: 'days',
      day: 'day',
      weekDays: {
        '0': 'Monday',
        '1': 'Tuesday',
        '2': 'Wednesday',
        '3': 'Thursday',
        '4': 'Friday',
        '5': 'Saturday',
        '6': 'Sunday',
      },
      timeMessage: {
        expiresIn: 'Expires in {{value}} {{unit}}',
        expired: 'Expired',
        ago: 'Does',
      },
    },
    status: {
      success: 'Correct',
      fail: 'Failed',
    },
    notification: {
      title: 'You have a new notification',
    },
    contactTime: {
      MORNING: 'Tomorrow',
      AFTERNOON: 'Late',
      NO_PREFERENCE: 'No preference',
    },
    complexity: {
      HIGH: 'High',
      LOW: 'Low',
    },
    specialistType: {
      GYNECOLOGIST: 'Gynecologist',
      EMBRYOLOGIST: 'embryologist',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: 'Earrings',
      COMPLETED: 'Made',
      ALL: 'All',
    },
    plannedStatus: {
      ALL: 'All',
      PLANNED: 'Planned',
      UNPLANNED: 'Unplanned',
    },
    taskProgress: {
      PENDING: 'Earring',
      COMPLETED: 'Made',
    },
    leadEntryStatus: {
      NEW: 'New',
      DUPLICATED: 'Duplicate',
      REENTRY: 'Re-entry',
    },
    paymentStatus: {
      PENDING: 'Earring',
      PAID: 'Paid',
    },
    purchaseType: {
      MAINTENANCE: 'Maintenance',
      TREATMENT: 'Program',
      TECHNIQUE: 'Technique',
    },
    notifications: {
      list: {
        title: 'Notifications',
        description: 'Management of notifications in the system',
        columns: {
          title: 'Qualification',
          type: 'Notification type',
          created: 'Creation date',
        },
        one: 'Notification',
        many: 'Notifications',
      },
      types: {
        ACTION_ALERT: 'Task Alert',
      },
      new: 'You have a new notification',
    },
    information: {
      title: 'Resources',
      description: 'Platform information and resources',
    },
  },
  components: {
    fileUploader: {
      title: 'Upload file',
      invalidType: 'File type not allowed',
    },
  },
  login: {
    title: 'Login',
    subtitle: 'Access to the management platform',
    form: {
      email: {
        title: 'Email',
      },
      signature: {
        title: 'Password',
      },
      submit: 'Login',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: 'Incorrect username or password',
      WRONG_PERMISSIONS: 'You do not have permissions to execute this action',
      service: {
        group: {
          findByDomain: {
            error: 'FATAL: The group could not be found',
          },
        },
      },
    },
  },
};
