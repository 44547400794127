import { StyleSheet } from 'react-native';

export const CopyToClipboardStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  text: {
    flex: 1,
    marginRight: 10,
    fontSize: 16,
    color: '#ccc',
  },
  iconContainer: {
    height: 30,
    width: 30,
    backgroundColor: '#ffdef0',
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconStyle: {
    color: '#ed3591',
    fontSize: 18,
  },
});
