import { t } from 'i18next';

export function ExpireUtility(properties: { date: string | Date }) {
  const currentDate = new Date();
  const expirationDate = new Date(properties.date);

  const timeDifference = Math.abs(expirationDate.getTime() - currentDate.getTime());

  if (timeDifference <= 0) {
    return {
      value: -1,
      unit: '',
    };
  }

  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days > 0) {
    if (days === 1)
      return {
        value: days,
        unit: t('common.time.day'),
      };
    return {
      value: days,
      unit: t('common.time.days'),
    };
  } else if (hours > 0) {
    if (hours === 1)
      return {
        value: hours,
        unit: t('common.time.hour'),
      };

    return {
      value: hours,
      unit: t('common.time.hours'),
    };
  } else if (minutes > 0) {
    if (minutes === 1)
      return {
        value: minutes,
        unit: t('common.time.minute'),
      };

    return {
      value: minutes,
      unit: t('common.time.minutes'),
    };
  } else {
    if (seconds === 1)
      return {
        value: seconds,
        unit: t('common.time.second'),
      };

    return {
      value: seconds,
      unit: t('common.time.seconds'),
    };
  }
}
