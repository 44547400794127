import { NavigationComponent } from '@components/navigation/navigation.component';
import { CreateUserScreen } from './screens/create/create.user.screen';
import { DeleteUserScreen } from './screens/delete/delete.user.screen';
import { ListUsersScreen } from './screens/list/list.users.screen';
import { UpdateUserScreen } from './screens/update/update.user.screen';
import { UsersRoutes } from './users.routes';
import { AccessRoutes } from '@human/access/access.routes';
import { RouteProp } from '@react-navigation/native';
import { CreateLicenseScreen } from './screens/licenses/create/create.license.screen';
import { DeleteLicenseScreen } from './screens/licenses/delete/delete.license.screen';
import { CreateContractScreen } from './screens/contracts/create/create.contract.screen';
import { DisableContractScreen } from './screens/contracts/disable/disable.contract.screen';
import { CreateLanguageScreen } from './screens/languages/create/create.language.screen';
import { DeleteLanguageScreen } from './screens/languages/delete/delete.language.screen';
import { PasswordDeleteUserScreen } from './screens/delete/password.delete.user.screen';
import { DeleteScheduleScreen } from './screens/schedule/delete/delete.schedule.screen';
import { CreateScheduleScreen } from './screens/schedule/create/create.schedule.screen';
import { EnableContractScreen } from './screens/contracts/enable/enable.contract.screen';
import { DisableUserScreen } from './screens/disable/disable.user.screen';
import { EnableUserScreen } from './screens/enable/enable.user.screen';

const Navigation = NavigationComponent<UsersRoutes>();

export function UsersSection(properties: { route: RouteProp<AccessRoutes, 'users'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListUsersScreen} />
      <Navigation.Screen name="create" component={CreateUserScreen} />
      <Navigation.Screen name="update" component={UpdateUserScreen} />
      <Navigation.Screen name="delete" component={DeleteUserScreen} />
      <Navigation.Screen name="disable" component={DisableUserScreen} />
      <Navigation.Screen name="enable" component={EnableUserScreen} />
      <Navigation.Screen
        name="licenseCreate"
        component={CreateLicenseScreen}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Navigation.Screen name="licenseDelete" component={DeleteLicenseScreen} />
      <Navigation.Screen name="contractCreate" component={CreateContractScreen} />
      <Navigation.Screen name="contractDisable" component={DisableContractScreen} />
      <Navigation.Screen name="contractEnable" component={EnableContractScreen} />

      <Navigation.Screen name="createUserLanguage" component={CreateLanguageScreen} />
      <Navigation.Screen name="removeUserLanguage" component={DeleteLanguageScreen} />
      <Navigation.Screen name="password" component={PasswordDeleteUserScreen} />
      <Navigation.Screen name="scheduleCreate" component={CreateScheduleScreen} />
      <Navigation.Screen name="scheduleDelete" component={DeleteScheduleScreen} />
    </Navigation.Navigator>
  );
}
