import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { PatientsRoutes } from '../../patient.routes';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';

export function DisablePatientsScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientDisable'>;
}) {
  return (
    <ModalComponent multiWorkspace icon="trash" prefix="crm.patients.disable">
      <FormComponent
        prefix="crm.patients.disable.form"
        padding
        repository={{
          send: () => {
            return new PatientCrmRepository()
              .disable({
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
