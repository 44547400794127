import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { InterventionTechniqueCrmRepository } from '@human/crm/repositories/interventionTechnique/interventionTechnique.repository';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { InterventionsRoutes } from '../../../interventions.routes';
import { ProgramTechniqueCrmRepository } from '@human/crm/repositories/programTechnique/programTechnique.repository';
import { ProcedureCrmRepository } from '@human/crm/repositories/procedure/procedure.repository';

export function CreateInterventionTechniqueScreen(properties: {
  navigation: NavigationProp<InterventionsRoutes>;
  route: RouteProp<InterventionsRoutes, 'createInterventionTechnique'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="stethoscope"
      prefix="crm.techniques.create"
    >
      <FormComponent<TechniqueCrmType>
        prefix="crm.techniques.create.form"
        padding
        repository={{
          send: (settings) => {
            return new InterventionTechniqueCrmRepository()
              .create({
                techniqueId: settings.item.id || '',
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
                interventionId: properties.route.params.interventionId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'id',
            required: true,
            description: true,
            repository: (settings) =>
              new ProcedureCrmRepository()
                .list({
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  treatmentId: properties.route.params.treatmentId,
                  search: settings.search,
                  limit: 100,
                  active: true,
                })
                .then((procedures) =>
                  procedures.items.map((procedure) => ({
                    label: procedure.techniqueName,
                    value: procedure.techniqueId,
                  }))
                ),
          },
        ]}
      />
    </ModalComponent>
  );
}
