import { useEffect, useState } from 'react';
import { View, Text, Dimensions } from 'react-native';
import { LeadActiveInactiveType } from './leadActiveInactiveComparative.type';
import { LeadDetailBoardRepository } from '../../../../repositories/leadDetail/leadDetail.repository';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { LeadActiveInactiveComparativeProperties } from './leadActiveInactiveComparative.properties';

const { height } = Dimensions.get('window');

export function LeadActiveInactiveComparativeWeb(
  properties: LeadActiveInactiveComparativeProperties
) {
  const [data, setData] = useState<LeadActiveInactiveType[]>();
  const { t } = useTranslation();

  const months = Array.from({ length: 12 }, (_, i) => t(`access.users.list.month.${i}`));

  useEffect(() => {
    new LeadDetailBoardRepository()
      .leadActiveInactiveComparativeByYear({
        year: properties.year,
        funnelId: properties.funnelId,
        languageId: properties.departmentId,
        clinicId: properties.clinicId,
      })
      .then((response) => {
        setData(response);
      });
  }, [properties]);

  const datasets = [
    {
      label: 'Activos',
      data: data?.map((item) => item.active_leads_count) ?? [],
      backgroundColor: '#D33E3D',
      borderColor: '#D33E3D',
      borderWidth: 1,
    },
    {
      label: 'Inactivos',
      data: data?.map((item) => item.inactive_leads_count) ?? [],
      backgroundColor: '#273038',
      borderColor: '#273038',
      borderWidth: 1,
    },
  ];

  if (!datasets) {
    return (
      <View>
        <Text>{t('No data available')}</Text>
      </View>
    );
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 20,
        height: height * 0.4,
      }}
    >
      <Bar
        data={{ labels: months, datasets }}
        options={{
          aspectRatio: 3,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                color: '#283038',
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Total de Leads',
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 2,
              borderColor: 'rgba(0, 0, 0, 0.6)',
              borderRadius: 6,
            },
          },
        }}
      />
    </View>
  );
}
