export const colors = [
  '#003F5C',
  '#58508D',
  '#BC5090',
  '#FF6361',
  '#FFA600',

  '#D65076',
  '#45B8AC',
  '#EFC050',
  '#5B5EA6',
  '#9B2335',
];
