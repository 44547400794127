import { NavigationComponent } from '@components/navigation/navigation.component';
import { DashboardRoutes } from './dashboard.routes';
import { DashboardScreen } from './screens/dashboard/dashboard.screen';
import { UpdateLeadScreen } from '../leads/screens/update/update.lead.screen';
import { CreateActionScreen } from '../actions/screens/create/create.action.screen';
import { UpdateActionScreen } from '../actions/screens/update/update.action.screen';
import { UpdatePatientScreen } from '../patients/screens/update/update.patient.screen';
import { CreateReceiptScreen } from '../patients/screens/receipts/screens/create/create.receipt.screen';
import { CreatePaymentScreen } from '../patients/screens/receipts/screens/payment/create/create.payment.screen';
import { UpdatePaymentScreen } from '../patients/screens/receipts/screens/payment/update/update.payment.screen';
import { UpdateReceiptScreen } from '../patients/screens/receipts/screens/update/update.receipt.screen';
import { UpdateCampaignScreen } from '../campaings/screens/update/update.campaign.screen';
import { CreateManagerCampaignScreen } from '../campaings/screens/update/parts/create.manager.campaign.screen';
import { CreateParticipantCampaignScreen } from '../campaings/screens/update/parts/create.participant.campaign.screen';
import { DeleteManagerCampaignPart } from '../campaings/screens/update/parts/delete.manager.campaign.update.part';
import { DeleteParticipantCampaignPart } from '../campaings/screens/update/parts/delete.participant.campaign.screen';

const Navigation = NavigationComponent<DashboardRoutes>();

export function DashboardSection() {
  return (
    <Navigation.Navigator initialRouteName="dashboard">
      <Navigation.Screen name="dashboard" component={DashboardScreen} />
      <Navigation.Screen name="leadUpdate" component={UpdateLeadScreen} />
      <Navigation.Screen name="patientUpdate" component={UpdatePatientScreen} />
      <Navigation.Screen name="actionCreate" component={CreateActionScreen} />
      <Navigation.Screen name="actionUpdate" component={UpdateActionScreen} />
      <Navigation.Screen name="campaignUpdate" component={UpdateCampaignScreen} />

      <Navigation.Screen name="createManager" component={CreateManagerCampaignScreen} />
      <Navigation.Screen name="deleteManager" component={DeleteManagerCampaignPart} />
      <Navigation.Screen name="createParticipant" component={CreateParticipantCampaignScreen} />
      <Navigation.Screen name="deleteParticipant" component={DeleteParticipantCampaignPart} />

      <Navigation.Screen name="receiptCreate" component={CreateReceiptScreen} />
      <Navigation.Screen name="receiptUpdate" component={UpdateReceiptScreen} />

      <Navigation.Screen name="paymentCreate" component={CreatePaymentScreen} />
      <Navigation.Screen name="paymentUpdate" component={UpdatePaymentScreen} />
    </Navigation.Navigator>
  );
}
