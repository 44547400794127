import { IconType } from '../../../components/icon/icon.types';
import { AuthorizedSpaces } from './authorized.spaces';
export const AuthorizedSections: {
  [key in keyof AuthorizedSpaces]: {
    icon: IconType;
    keywords: string[];
    version: string;
    time: string;
    permission?: string;
  };
} = {
  desktop: {
    icon: 'desktop',
    keywords: [],
    version: '',
    time: '',
  },
  crm: {
    icon: 'users',
    keywords: ['clients', 'customers'],
    version: '1.3',
    time: '2024-04-21',
    permission: 'crm',
  },
  // clinic: {
  //   icon: 'clinic-medical',
  //   keywords: ['clinic', 'medical'],
  //   version: '1.6',
  //   time: '2024-03-25',
  //   permission: 'clinic',
  // },
  access: {
    icon: 'lock',
    keywords: ['directory', 'contacts'],
    version: '1.8',
    time: '2024-02-25',
    permission: 'access',
  },
  board: {
    icon: 'tachometer-alt',
    keywords: ['dashboard'],
    version: '1.4',
    time: '2024-01-25',
    permission: 'board',
  },
  // tory: {
  //   icon: 'flask',
  //   keywords: ['laboratory', 'lab', 'science'],
  //   version: '1.3',
  //   time: '2024-05-21',
  //   permission: 'tory',
  // },
  profile: {
    icon: 'user',
    keywords: ['account', 'user profile'],
    version: '1.7',
    time: '2024-06-21',
    permission: 'profile',
  },
};
