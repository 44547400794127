import { OrderTableType } from '@components/table/table.properties';
import { ButtonColumnImplementation } from './button.column.properties';
import { TouchableOpacity, Text, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { ButtonColumnStyles } from './button.column.styles';

export function ButtonColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: ButtonColumnImplementation<ITEM, ORDER>
) {
  const onPress = properties.onPress?.(properties.item);

  const text = properties.text(properties.item);

  return onPress ? (
    <View style={ButtonColumnStyles.container}>
      <View style={ButtonColumnStyles.buttonWrapper}>
        <TouchableOpacity onPress={onPress} style={ButtonColumnStyles.buttonContainer}>
          <TextComponent text={text} style={ButtonColumnStyles.buttonLabel} />
          {properties.icon && (
            <IconComponent
              name={properties.icon}
              containerstyle={ButtonColumnStyles.iconContainer}
              iconStyle={ButtonColumnStyles.icon}
            />
          )}
        </TouchableOpacity>
      </View>
    </View>
  ) : (
    <View />
  );
}
