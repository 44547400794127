import { NavigationComponent } from '@components/navigation/navigation.component';
import { ActivitiesRoutes } from './activity.routes';
import { ListActivitiesScreen } from './screens/list/list.activity.screen';
import { CreateActivityScreen } from './screens/create/create.activity.screen';
import { UpdateActivityScreen } from './screens/update/update.activity.screen';
import { UpdateResultScreen } from '../result/screens/update/update.result.screen';
import { CreateResultScreen } from '../result/screens/create/create.result.screen';
import { DisableActivityScreen } from './screens/disable/disable.activity.screen';
import { EnableActivityScreen } from './screens/enable/enable.activity.screen';
import { CreateActivityScopeScreen } from './screens/createScope/create.activity.scope.screen';
import { DeleteScopeScreen } from './screens/deleteScope/delete.scope.screen';
import { DeleteCommunicationChannelScreen } from './screens/deleteChannel/delete.communication.channel.screen';
import { CreateCommunicationChannelScreen } from './screens/createChannel/create.communication.channel.screen';

const Navigation = NavigationComponent<ActivitiesRoutes>();

export function ActivitiesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListActivitiesScreen} />
      <Navigation.Screen name="create" component={CreateActivityScreen} />
      <Navigation.Screen name="update" component={UpdateActivityScreen} />
      <Navigation.Screen name="disable" component={DisableActivityScreen} />
      <Navigation.Screen name="enable" component={EnableActivityScreen} />
      <Navigation.Screen name="resultUpdate" component={UpdateResultScreen} />
      <Navigation.Screen name="resultCreate" component={CreateResultScreen} />
      <Navigation.Screen name="createScope" component={CreateActivityScopeScreen} />
      <Navigation.Screen name="deleteScope" component={DeleteScopeScreen} />
      <Navigation.Screen
        name="deleteCommunicationChannel"
        component={DeleteCommunicationChannelScreen}
      />
      <Navigation.Screen
        name="createCommunicationChannel"
        component={CreateCommunicationChannelScreen}
      />
    </Navigation.Navigator>
  );
}
