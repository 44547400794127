import { ValidationDateInput } from "./date.properties";

export function DateInputValidations(properties: {
    validations: ValidationDateInput[],
    required?: boolean,
    value?: string,

}) {
    const errors: string[] = [];

    if (properties.validations.includes('future')) {
        const date = new Date(properties.value || '');
        const now = new Date();
        if (date < now) {
            errors.push('form.date.errors.future');
        }
    }


    return errors
}