import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';

export function SelectChannelStep(properties: {
  channelId?: string;
  onSuccess: (settings: { channelId: string; channelName: string }) => void;
}) {
  return (
    <FieldContainer
      prefix="crm.campaigns.create.steps.channel.form"
      type="select"
      required
      padding
      name="channelId"
      description
    >
      <SelectInput<{ channelId: string }>
        type={'select'}
        name="channelId"
        required
        value={{
          channelId: properties.channelId || undefined,
        }}
        description
        onChange={(value, option) => {
          if (!value.channelId) return;
          properties.onSuccess({ channelId: value.channelId, channelName: option.label });
        }}
        pick={(props: { id: string }) => {
          return new ChannelCrmRepository().pick({ id: props.id }).then((channel) => {
            return `${channel.name}`;
          });
        }}
        repository={(properties: { search?: string }) => {
          return new ChannelCrmRepository()
            .list({
              search: properties.search,
              direction: 'desc',
              page: 0,
              limit: 100,
              active: true,
            })
            .then((channels) => {
              return channels.items.map((channel) => ({
                label: channel.name,
                value: channel.id,
              }));
            });
        }}
      />
    </FieldContainer>
  );
}
