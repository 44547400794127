import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ActivitiesRoutes } from '../../activity.routes';
import { ActivityCrmType } from '@human/crm/types/activity.crm.type';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
export function CreateActivityScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="tasks" prefix="crm.activities.create">
      <FormComponent<ActivityCrmType & { signature: string }>
        prefix="crm.activities.create.form"
        padding
        repository={{
          send: (settings) => {
            return new ActivityCrmRepository()
              .create({
                item: settings.item,
              })
              .then(() => {
                properties.navigation.navigate('list');
              });
          },
          get: async () => ({
            primary: false,
          }),
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['title'],
          },
          {
            name: 'icon',
            type: 'icon',
            description: true,
            required: true,
          },
          {
            name: 'position',
            type: 'number',
            description: true,
            required: true,
            validations: ['positive'],
          },
          {
            name: 'description',
            type: 'text',
            description: true,
            validations: ['description'],
            lines: 6,
          },
          {
            name: 'color',
            type: 'color',
            required: true,
            description: true,
          },
          {
            name: 'time',
            type: 'number',
            description: true,
            required: true,
            validations: ['positive'],
          },
        ]}
      />
    </ModalComponent>
  );
}
