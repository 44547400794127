import { View, TextInput as TextInputNative, TouchableOpacity } from 'react-native';
import { NumberInputImplementation } from './number.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useState } from 'react';
import { TextInputValidations } from './number.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { NumberInputStyles } from './number.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function NumberInput<ITEM>(properties: NumberInputImplementation<ITEM>) {
  const value = (properties.value[properties.name] || 0) as string;
  const [focus, setFocus] = useState(false);
  const onChange = (text: string) => {
    if (properties.readonly) return;
    const isNan = [...text].some((char) => isNaN(parseInt(char)));
    if (isNan) return;
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: text ? parseInt(text) : 0,
    };
    properties.onChange(newValue);
  };

  // convert value to number
  const conversionValue = parseInt(value);
  const errors =
    properties.validations &&
    TextInputValidations({
      validations: properties.validations,
      value: conversionValue,
      required: properties.required,
    });

  const handleIncrementDecrement = (incrementDecrementValue: number) => {
    if (properties.readonly) return;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [properties.name]: conversionValue + incrementDecrementValue,
    };
    properties.onChange(newValue);
  };

  return (
    <>
      <View
        style={
          properties.readonly ? NumberInputStyles.containerReadonly : NumberInputStyles.container
        }
      >
        {properties.icon && (
          <IconComponent
            name={properties.icon}
            containerstyle={NumberInputStyles.iconControl}
            iconStyle={NumberInputStyles.shapeIconControl}
          />
        )}
        {!properties.readonly && (
          <View style={NumberInputStyles.controls}>
            <TouchableOpacity
              onPress={() => handleIncrementDecrement(1)}
              style={NumberInputStyles.controll}
            >
              <IconComponent
                name="arrow-up"
                containerstyle={NumberInputStyles.iconControl}
                iconStyle={NumberInputStyles.shapeIconControl}
              />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => handleIncrementDecrement(-1)}
              style={NumberInputStyles.controll}
            >
              <IconComponent
                name="arrow-down"
                containerstyle={NumberInputStyles.iconControl}
                iconStyle={NumberInputStyles.shapeIconControl}
              />
            </TouchableOpacity>
          </View>
        )}

        <TextInputNative
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          focusable={!properties.readonly}
          editable={!properties.readonly}
          value={conversionValue ? conversionValue.toString() : '0'}
          style={[
            properties.readonly ? NumberInputStyles.inputReadonly : NumberInputStyles.input,
            properties.icon ? NumberInputStyles.inputIcon : NumberInputStyles.inputNoIcon,
            !properties.readonly && focus
              ? NumberInputStyles.inputFocus
              : NumberInputStyles.inputUnfocus,
          ]}
          onChangeText={onChange}
        />
      </View>
      {errors && <ErrorsComponent prefix={'form.text.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
