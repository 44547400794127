import { NavigationProp, RouteProp } from '@react-navigation/native';
import { InterventionsRoutes } from '../../interventions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { InterventionCrmRepository } from '@human/crm/repositories/intervention/intervention.repository';

export function EnableInterventionScreen(properties: {
  navigation: NavigationProp<InterventionsRoutes>;
  route: RouteProp<InterventionsRoutes, 'enableIntervention'>;
}) {
  return (
    <ModalComponent icon="trash" prefix={'crm.interventions.enable'}>
      <FormComponent
        prefix="crm.interventions.enable.form"
        padding
        repository={{
          send: () => {
            return new InterventionCrmRepository()
              .enable({
                interventionId: properties.route.params.interventionId,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
