import { NavigationComponent } from '@components/navigation/navigation.component';
import { ProgramsRoutes } from './programs.routes';
import { CreateProgramForm } from './screens/create/create.program.screen';
import { CreateTechniqueScreen } from '../technique/screens/create/create.technique.screen';

import { EnableProgramsScreen } from './screens/enable/enable.program.screen';
import { DisableProgramScreen } from './screens/disable/disable.program.screen';
import { ListProgramsScreen } from './screens/list/list.programs.screen';
import { UpdateProgramscreen } from './screens/update/update.program.screen';
import { CreateProgramPhaseScreen } from './screens/update/parts/program/create/create.programPhase.screen';
import { DeleteProgramPhaseScreen } from './screens/update/parts/program/delete/delete.programPhase.screen';
import { CreateProgramTechniqueScreen } from './screens/update/parts/techniques/create.program.technique.screen';
import { DeleteProgramTechniqueScreen } from './screens/update/parts/techniques/delete.program.technique.screen';
import { CreateProgramCycleScreen } from './screens/update/parts/cycle/create/create.programCycle.screen';
import { DeleteProgramCycleScreen } from './screens/update/parts/cycle/delete/delete.programCycle.screen';

const Navigation = NavigationComponent<ProgramsRoutes>();
export function ProgramsSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListProgramsScreen} />
      <Navigation.Screen name="create" component={CreateProgramForm} />
      <Navigation.Screen name="update" component={UpdateProgramscreen} />
      <Navigation.Screen name="techniqueCreate" component={CreateTechniqueScreen} />
      <Navigation.Screen name="disable" component={DisableProgramScreen} />
      <Navigation.Screen name="enable" component={EnableProgramsScreen} />
      <Navigation.Screen name="programPhaseCreate" component={CreateProgramPhaseScreen} />
      <Navigation.Screen name="programPhaseDelete" component={DeleteProgramPhaseScreen} />
      <Navigation.Screen name="programTechniqueCreate" component={CreateProgramTechniqueScreen} />
      <Navigation.Screen name="programTechniqueDelete" component={DeleteProgramTechniqueScreen} />
      <Navigation.Screen name="programCycleDelete" component={DeleteProgramCycleScreen} />
      <Navigation.Screen name="programCycleCreate" component={CreateProgramCycleScreen} />
    </Navigation.Navigator>
  );
}
