import { leadperdepartmentandmonth } from 'screens/authorized/human/spaces/board/screens/funnels/sale.funnel.screen';
import { ItemByDepartmentYearsComparativeType } from 'screens/authorized/human/spaces/board/types/itemByDepartmentYearsComparative.type';

interface CSVDownloadProperties {
  headers: string[];
  data:
    | ItemByDepartmentYearsComparativeType[]
    | leadperdepartmentandmonth[]
    | Record<string, number | string>[];
  type: 'yearly' | 'monthly' | 'byEvent';
  fileName: string;
  eventFields?: string[];
}

export const handleExportTableCSV = (properties: CSVDownloadProperties) => {
  const { headers, data, type = 'monthly', fileName } = properties;

  if (type === 'byEvent') {
    const eventData = data as leadperdepartmentandmonth[];

    // Construir el contenido CSV
    let csvContent = headers.join(',') + '\n';
    const eventFields = properties.eventFields ? properties.eventFields : [];
    // Procesar cada fila
    eventData.forEach((row) => {
      const rowValues = [
        row.department_name,
        ...eventFields.map((field) => row[field as keyof leadperdepartmentandmonth]),
      ];
      csvContent += rowValues.join(',') + '\n';
    });

    downloadCSV(csvContent, fileName);
    return;
  }

  if (type === 'yearly') {
    const yearlyData = data as ItemByDepartmentYearsComparativeType[];
    const departments = [...new Set(yearlyData.map((item) => item.department_name))];
    const years = [...new Set(yearlyData.map((item) => item.year))];

    let csvContent = headers.join(',') + '\n';

    // Calcular totales por departamento y año
    departments.forEach((department) => {
      const row = [department];
      years.forEach((year) => {
        const yearTotal = yearlyData
          .filter((d) => d.department_name === department && d.year === year)
          .reduce((sum, current) => sum + (current.total || 0), 0);
        row.push(yearTotal.toString());
      });
      csvContent += row.join(',') + '\n';
    });

    downloadCSV(csvContent, fileName);
    return;
  }

  // Código para tabla mensual
  const monthlyData = data as ItemByDepartmentYearsComparativeType[];
  const uniqueYears = [...new Set(monthlyData.map((d) => d.year))];
  const dynamicHeader = headers[0];
  const monthNames = headers.slice(1);

  const monthHeaders = [dynamicHeader];
  const yearHeaders = [''];

  monthNames.forEach((month) => {
    uniqueYears.forEach((year) => {
      monthHeaders.push(month);
    });
  });

  monthNames.forEach(() => {
    uniqueYears.forEach((year) => {
      yearHeaders.push(year.toString());
    });
  });

  const departments = [...new Set(monthlyData.map((item) => item.department_name))];

  const rows = departments.map((department) => {
    const row = [department];
    monthNames.forEach((_, monthIndex) => {
      uniqueYears.forEach((year) => {
        const found = monthlyData.find(
          (d) => d.department_name === department && d.year === year && d.month === monthIndex + 1
        );
        row.push(found ? found.total.toString() : '0');
      });
    });
    return row;
  });

  let csvContent = monthHeaders.join(',') + '\n';
  csvContent += yearHeaders.join(',') + '\n';
  rows.forEach((rowArray) => {
    csvContent += rowArray.join(',') + '\n';
  });

  downloadCSV(csvContent, fileName);
};

// Función auxiliar para manejar la descarga
const downloadCSV = (content: string, filename: string) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
