import { View } from 'react-native';
import { AnalyticsStyles } from './analytics.styles';
import { TextComponent } from '@components/text/text.component';
import { getPercentage } from '@utils/percentage/getPercentage.utility';
import { IconComponent } from '@components/icon/icon.component';

export function AnalyticChartComponent(settings: {
  prefix: string;
  lastWeek: number;
  thisWeek: number;
}) {
  const isPositive = settings.lastWeek < settings.thisWeek;
  const max = Math.max(settings.lastWeek, settings.thisWeek);
  const widthPercentage = 90 / max;

  return (
    <View style={AnalyticsStyles.chartContainer}>
      <View style={AnalyticsStyles.chartHeader}>
        <TextComponent
          bold
          translate
          text={`analytics.${settings.prefix}.title`}
          style={AnalyticsStyles.chartHeaderTitleText}
        />
        <TextComponent
          bold
          translate
          text={`${getPercentage({ lastWeek: settings.lastWeek, thisWeek: settings.thisWeek })}%`}
          style={[
            AnalyticsStyles.chartHeaderPercentageText,
            isPositive
              ? AnalyticsStyles.chartHeaderPercentageTextPositive
              : AnalyticsStyles.chartHeaderPercentageTextNegative,
          ]}
        />
        <IconComponent
          name={isPositive ? 'arrow-up' : 'arrow-down'}
          containerstyle={[
            AnalyticsStyles.chartHeaderIconContainer,
            isPositive
              ? AnalyticsStyles.chartHeaderIconContainerPositive
              : AnalyticsStyles.chartHeaderIconContainerNegative,
          ]}
          iconStyle={[
            AnalyticsStyles.chartHeaderIcon,
            isPositive
              ? AnalyticsStyles.chartHeaderIconPositive
              : AnalyticsStyles.chartHeaderIconNegative,
          ]}
        />
      </View>
      <View style={AnalyticsStyles.chartWeekContainer}>
        <TextComponent
          translate
          text={`${settings.lastWeek}`}
          bold
          style={AnalyticsStyles.chartWeekText}
        />
        <IconComponent name="arrow-right" iconStyle={AnalyticsStyles.chartWeekIcon} />
        <TextComponent bold text={`${settings.thisWeek}`} style={AnalyticsStyles.chartWeekText} />
      </View>
      {/* bars */}
      <View style={AnalyticsStyles.barsContainer}>
        <View
          style={[
            AnalyticsStyles.barThisWeekContainer,
            {
              width: `${widthPercentage * settings.thisWeek}%`,
            },
          ]}
        >
          <View style={AnalyticsStyles.barThisWeek} />
        </View>
        <View
          style={[
            AnalyticsStyles.barLastWeekContainer,
            {
              width: `${widthPercentage * settings.lastWeek}%`,
            },
          ]}
        >
          <View style={AnalyticsStyles.barLastWeek} />
        </View>
      </View>
    </View>
  );
}
