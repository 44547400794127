import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SpecialistsRoutes } from '../../specialists.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { t } from 'i18next';

export function CreateSpecialistsScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes>;
  route: RouteProp<SpecialistsRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="user" prefix="access.specialists.create">
      <FormComponent<{
        name: string;
        surname: string;
        dni: string;
        type: 'GYNECOLOGIST' | 'EMBRYOLOGIST' | 'ATP';
      }>
        prefix={'access.specialists.create.form'}
        padding
        repository={{
          send: (data) => {
            return new SpecialistAccessRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.navigate('list');
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            name: 'surname',
            type: 'text',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            name: 'dni',
            type: 'text',
            description: true,
            required: true,
            validations: ['dni'],
          },
          {
            name: 'type',
            type: 'select',
            description: true,
            options: [
              {
                label: t('common.specialistType.GYNECOLOGIST'),
                value: 'GYNECOLOGIST',
              },
              {
                label: t('common.specialistType.EMBRYOLOGIST'),
                value: 'EMBRYOLOGIST',
              },
              {
                label: t('common.specialistType.ATP'),
                value: 'ATP',
              },
            ],
          },
        ]}
      />
    </ModalComponent>
  );
}
