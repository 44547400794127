import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ItemByDepartmentYearsComparativeType } from '../../../../types/itemByDepartmentYearsComparative.type';
import { ChartData } from 'chart.js';

export function ExpectedInterventionsYearLine(properties: {
  data: ItemByDepartmentYearsComparativeType[];
  selectedYear: number;
}) {
  const { t } = useTranslation();

  const colors = {
    'Garantias (Primer intento)': '#A5A5A5',
    'Perdidas (+1 intento)': '#FF3B3A',
    Total: '#333333',
  };

  const months = Array.from({ length: 12 }, (_, i) => t('access.users.list.month.' + i));

  const comparingRows = properties.data.filter(
    (row) =>
      row.year === properties.selectedYear &&
      (row.department_name === 'Garantias (Primer intento)' ||
        row.department_name === 'Perdidas (+1 intento)' ||
        row.department_name === 'Total')
  );

  const legend = new Set(comparingRows.map((department) => department.department_name));

  const data: ChartData<'line', number[], string> = {
    labels: months,
    datasets: Array.from(legend).map((departmentName) => {
      return {
        label: departmentName,
        data: months.map((_, i) => {
          const row = comparingRows.find(
            (row) => row.month === i + 1 && row.department_name === departmentName
          );
          return row ? row.total : 0;
        }),
        fill: false,
        borderColor: colors[departmentName as keyof typeof colors],
        backgroundColor: colors[departmentName as keyof typeof colors],
      };
    }),
  };

  return (
    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 4 }}>
      <Line
        data={data}
        options={{
          responsive: true,
          aspectRatio: 3,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              borderWidth: 4, // Grosor de la línea
            },
            point: {
              radius: 6, // Tamaño de los puntos
              hoverRadius: 8, // Tamaño de los puntos al hacer hover
            },
          },
        }}
      />
    </View>
  );
}
