import { IconComponent } from '@components/icon/icon.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { SceneComponent } from '@components/scene/scene.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { useState, useEffect } from 'react';
import { View, Platform, TouchableOpacity, Text } from 'react-native';
import { BoardRoutes } from '../../../board.routes';
import { BoardHeaderComponent } from '../../../components/header/board.header.component';
import { LeadDetailBoardOvobankRepository } from '../../../repositories/leadDetail/leadDetail.ovobank.repository';
import { LeadActiveInactiveChart } from '../components/leadActiveInactiveChart/leadActiveInactiveChart';
import { LeadActiveInactiveComparative } from '../components/leadActiveInactiveComparative/leadActiveInactiveComparative';
import { NewLeadsByDepartmentChart } from '../components/newLeadsByDepartmentChart/newLeadsByDepartmentChart';
import { ItemByDepartmentYearsComparativeType } from '../components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { NewLeadsByDepartmentYearsComparative } from '../components/newLeadsByDepartmentYearsComparative/newLeadsByDepartmentYearsComparative';
import { TwoYearColumnLeadsCirle } from '../components/twoYearColumnLeadsCirle/twoYearColumnLeadsCircle';
import { TwoYearColumnLeadsComparative } from '../components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative';
import { LeadsScreenStyles } from '../leads.screen.styles';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@components/text/text.component';
import { EligibleChartComponent } from '../../funnels/components/eligibleChart/eligibleChart.component';
import { LeadEligibleComparativeChartComponent } from '../components/leadEligibleComparativeChart/leadEligibleComparativeChart.component';
import { LeadEligibleChartComponent } from '../components/leadEligibleChart/leadEligibleChart.component';

export function OvobankLeadsScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'leads'>;
}) {
  const { t } = useTranslation();
  const [selectedClinic, setSelectedClinic] = useState<string>();
  const [clinics, setClinics] = useState<{ label: string; value: string }[]>([]);

  //Datos para mostrar el listado de clinicas y sus datos en la comparativa de 2 años
  const [clinicsData, setClinicsData] = useState<ItemByDepartmentYearsComparativeType[]>();

  const [firstYear, setFirstYear] = useState<number>(new Date().getFullYear() - 1);
  const [secondYear, setSecondYear] = useState<number>(new Date().getFullYear());

  const [yearsSelection, setYearsSelection] = useState<{ label: string; value: string }[]>([
    { label: firstYear.toString(), value: firstYear.toString() },
    { label: secondYear.toString(), value: secondYear.toString() },
  ]);

  const [year, setYear] = useState<number>(secondYear);
  const [month, setMonth] = useState<number>(0);

  const [selectedFunnel, setSelectedFunnel] = useState<string>();
  const [funnels, setFunnels] = useState<{ label: string; value: string }[]>([]);

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);

  useEffect(() => {
    new FunnelCrmRepository()
      .list({
        direction: 'asc',
        page: 0,
        limit: 100,
      })
      .then((funnels) => {
        setFunnels([
          {
            label: t('board.leads.totalLeads.filters.all'),
            value: 'all',
          },
          ...funnels.items.map((funnel) => {
            return {
              label: funnel.name,
              value: funnel.id,
            };
          }),
        ]);
      });
  }, []);

  useEffect(() => {
    setSelectedFunnel(funnels[0]?.value);
  }, [funnels]);

  useEffect(() => {
    new ClinicAccessRepository().list({}).then((clinics) => {
      setClinics(
        clinics.items.map((clinic) => {
          return {
            label: clinic.name,
            value: clinic.id,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    setSelectedClinic(clinics[0]?.value);
  }, [clinics]);

  useEffect(() => {
    if (!selectedClinic || !selectedFunnel) return;

    new LeadDetailBoardOvobankRepository()
      .newLeadCountYearsComparative({
        clinicId: selectedClinic,
        firstYear,
        secondYear,
        funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
      })
      .then((data) => {
        setData(data);
      });
    new LeadDetailBoardOvobankRepository()
      .newLeadCountYearsComparative({
        firstYear,
        secondYear,
        funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
      })
      .then((data) => {
        setClinicsData(data);
      });
  }, [selectedClinic, firstYear, secondYear, selectedFunnel]);

  useEffect(() => {
    setYearsSelection([
      { label: firstYear.toString(), value: firstYear.toString() },
      { label: secondYear.toString(), value: secondYear.toString() },
    ]);
    setYear(secondYear);
  }, [firstYear, secondYear]);

  const handleExport = (type: 'yearly' | 'monthly') => {
    if (confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      const headers =
        type === 'monthly'
          ? Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`))
          : [firstYear.toString(), secondYear.toString()];

      handleExportTableCSV({
        headers: ['Departamento', ...headers],
        data,
        type,
        fileName: type === 'monthly' ? 'monthly_leads.csv' : 'yearly_leads.csv',
      });
    }
    return;
  };

  if (!selectedClinic) return <LoaderComponent loading />;

  return (
    <SceneComponent icon={'search'} prefix={'board.leads'} links={[]}>
      <View style={LeadsScreenStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.leads.totalLeads.title"
          description="board.leads.totalLeads.description"
        />
        <View style={LeadsScreenStyles.fiterContainer}>
          <View style={{ width: 200 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.optionA')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.optionASubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + firstYear}
                name="fistYear"
                onChange={(value: any) => {
                  setFirstYear(+value.fistYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 8;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>

          <View style={{ width: 200 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.optionB')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.optionBSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + secondYear}
                name="secondYear"
                onChange={(value: any) => {
                  setSecondYear(+value.secondYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 7;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>

          <View style={{ width: 200 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.funnel')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.funnelSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedFunnel ?? ''}
                name="selectedFunnel"
                onChange={(value: any) => {
                  setSelectedFunnel(value.selectedFunnel);
                }}
                hideSearch
                pick={async ({ id }) =>
                  new FunnelCrmRepository()
                    .pick({
                      id,
                    })
                    .then((funnel) => {
                      return funnel.name ?? '';
                    })
                    .catch(() => t('board.leads.totalLeads.filters.all') ?? 'All')
                }
                repository={async () => {
                  return Promise.resolve(funnels);
                }}
              />
            </View>
          </View>
        </View>

        <View style={LeadsScreenStyles.graphicsContainer}>
          <View style={[LeadsScreenStyles.graphicContainer, { flex: 7 }]}>
            <View style={LeadsScreenStyles.graphicContainerHeader}>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={LeadsScreenStyles.uploadBox}
                  onPress={() => handleExport('monthly')}
                >
                  <View style={LeadsScreenStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={LeadsScreenStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={LeadsScreenStyles.iconContainer}
                      iconStyle={LeadsScreenStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {clinicsData && (
              <NewLeadsByDepartmentYearsComparative
                data={clinicsData}
                firstYear={firstYear}
                secondYear={secondYear}
              />
            )}
          </View>
          <View style={LeadsScreenStyles.graphicContainer}>
            <View style={LeadsScreenStyles.graphicContainerHeader}>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={LeadsScreenStyles.uploadBox}
                  onPress={() => handleExport('yearly')}
                >
                  <View style={LeadsScreenStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.csv')}
                      style={LeadsScreenStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={LeadsScreenStyles.iconContainer}
                      iconStyle={LeadsScreenStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {clinicsData && (
              <TwoYearColumnLeadsComparative
                data={clinicsData}
                firstYear={firstYear}
                secondYear={secondYear}
              />
            )}
          </View>
          {/* <View style={{ flex: 1 }} /> */}
        </View>

        {/**DEPARTMENT */}

        <View style={[LeadsScreenStyles.fiterContainer, { marginTop: 50 }]}>
          <View style={{ width: 250 }}>
            <View style={LeadsScreenStyles.filterTextContainer}>
              <Text style={LeadsScreenStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.clinic')}
              </Text>
              <Text style={LeadsScreenStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.clinicSubtitle')}
              </Text>
            </View>
            {selectedClinic && (
              <View>
                <FilterSelectInput
                  type="select"
                  value={selectedClinic ?? ''}
                  name="clinic"
                  onChange={(value: any) => {
                    setSelectedClinic(value.clinic);
                  }}
                  pick={async ({ id }) =>
                    new ClinicAccessRepository()
                      .pick({
                        id,
                      })
                      .then((clinic) => {
                        return clinic.name ?? '';
                      })
                  }
                  repository={async () => {
                    return Promise.resolve(clinics);
                  }}
                  hideSearch
                />
              </View>
            )}
          </View>
        </View>
        <View style={LeadsScreenStyles.graphicsContainer}>
          <View
            style={{ gap: 30, backgroundColor: 'white', padding: 15, borderRadius: 5, flex: 2 }}
          >
            <View>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeGraphicTitle')}
              </Text>
            </View>

            {selectedClinic && (
              <NewLeadsByDepartmentChart
                selectedDepartment={selectedClinic}
                departmentName={clinics.find((clinic) => clinic.value === selectedClinic)?.label!}
                firstYear={firstYear}
                secondYear={secondYear}
                data={data}
              />
            )}
          </View>
          <View
            style={{ gap: 30, backgroundColor: 'white', padding: 15, borderRadius: 5, flex: 1 }}
          >
            <View>
              <Text style={LeadsScreenStyles.graphicTitle}>
                {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
              </Text>
            </View>

            {selectedClinic && (
              <TwoYearColumnLeadsCirle
                selectedDepartment={selectedClinic}
                departmentName={
                  clinics.find((clinic) => clinic.value === selectedClinic)?.label as string
                }
                firstYear={firstYear}
                secondYear={secondYear}
                data={data}
              />
            )}
          </View>
        </View>

        {/**Active/Inactive */}
        <View style={LeadsScreenStyles.graphicsContainer}>
          <View style={{ flex: 2 }}>
            <View style={{ width: 250, marginVertical: 10, zIndex: 5 }}>
              <View style={LeadsScreenStyles.filterTextContainer}>
                <Text style={LeadsScreenStyles.filterTitle}>
                  {t('board.leads.totalLeads.filters.year')}
                </Text>
                <Text style={LeadsScreenStyles.filterSubtitle}>
                  {t('board.leads.totalLeads.filters.yearSubtitle')}
                </Text>
              </View>
              {selectedClinic && (
                <View>
                  <FilterSelectInput
                    type="select"
                    value={year.toString() ?? ''}
                    name="year"
                    onChange={(value: any) => {
                      setYear(parseInt(value.year));
                    }}
                    options={yearsSelection}
                    hideSearch
                  />
                </View>
              )}
            </View>
            <View
              style={{ gap: 30, backgroundColor: 'white', padding: 15, borderRadius: 5, flex: 1 }}
            >
              <View>
                <Text style={LeadsScreenStyles.graphicTitle}>
                  {t('board.leads.totalLeads.comparativeGraphicTitle')}
                </Text>
              </View>

              {selectedClinic && (
                <LeadEligibleComparativeChartComponent
                  year={year}
                  clinicId={selectedClinic}
                  funnelId={selectedFunnel === 'all' ? undefined : selectedFunnel}
                />
              )}
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ width: 250, marginVertical: 10, zIndex: 5 }}>
              <View style={LeadsScreenStyles.filterTextContainer}>
                <Text style={LeadsScreenStyles.filterTitle}>
                  {t('board.leads.totalLeads.filters.month')}
                </Text>
                <Text style={LeadsScreenStyles.filterSubtitle}>
                  {t('board.leads.totalLeads.filters.monthSubtitle')}
                </Text>
              </View>
              {selectedClinic && (
                <View>
                  <FilterSelectInput
                    type="select"
                    value={month.toString()}
                    name="month"
                    onChange={(value: any) => {
                      setMonth(parseInt(value.month));
                    }}
                    options={Array.from({ length: 13 }, (_, i) => {
                      return {
                        label:
                          i === 0
                            ? t('board.leads.totalLeads.filters.allMonths')
                            : t(`access.users.list.month.${i - 1}`),
                        value: i.toString(),
                      };
                    })}
                    hideSearch
                  />
                </View>
              )}
            </View>
            <View
              style={{ gap: 30, backgroundColor: 'white', padding: 15, borderRadius: 5, flex: 1 }}
            >
              <View>
                <Text style={LeadsScreenStyles.graphicTitle}>
                  {t('board.leads.totalLeads.comparativeAcumulatedGraphicTitle')}
                </Text>
              </View>

              {selectedClinic && (
                <LeadEligibleChartComponent
                  year={year}
                  month={month}
                  clinicId={selectedClinic}
                  funnelId={selectedFunnel === 'all' ? undefined : selectedFunnel}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
