import { View } from 'react-native';
import { TextComponent } from '../text/text.component';
import { useEffect, useState } from 'react';
import { ClockStyles } from './clock.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * ClockComponent renders a clock component that displays the current time.
 *
 * @returns {JSX.Element} The rendered JSX element containing the clock component.
 *
 * @example
 * <ClockComponent />
 */

export function ClockComponent() {
  const { device } = useDeviceHook();
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);

  const updateClock = () => {
    const date = new Date();
    setHour(date.getHours());
    setMinute(date.getMinutes());
  };

  useEffect(() => {
    updateClock();
    const interval = setInterval(() => {
      updateClock();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const hourText = hour < 10 ? `0${hour}` : `${hour}`;
  const minuteText = minute < 10 ? `0${minute}` : `${minute}`;

  return (
    <View
      style={[
        ClockStyles.container,
        (device === 'phone' || device === 'tablet') && ClockStyles.cornerContainer,
      ]}
    >
      <TextComponent
        light
        text={hourText}
        style={[
          ClockStyles.hourText,
          (device === 'phone' || device === 'tablet' || device === 'laptop') &&
            ClockStyles.littleTimeText,
        ]}
      />
      <TextComponent bold text={':'} style={[ClockStyles.separatorText, ClockStyles.littleText]} />
      <TextComponent
        light
        text={minuteText}
        style={[
          ClockStyles.minuteText,
          (device === 'phone' || device === 'tablet' || device === 'laptop') &&
            ClockStyles.littleTimeText,
        ]}
      />
      <TextComponent
        light
        text={'Marbella'}
        style={[
          ClockStyles.locationText,
          (device === 'phone' || device === 'tablet' || device === 'laptop') &&
            ClockStyles.littleText,
        ]}
      />
    </View>
  );
}
