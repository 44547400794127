import { TextComponent } from '@components/text/text.component';
import { StyleProp, View, ViewStyle } from 'react-native';
import { FieldFormType } from './fields.properties';
import { FieldsStyles } from './fields.styles';

export function FieldContainer<ITEM>(properties: {
  style?: StyleProp<ViewStyle>;
  type: FieldFormType<ITEM>['type'];
  required?: boolean;
  name?: string | number | symbol;
  prefix: string;
  children: JSX.Element;
  description?: boolean;
  padding?: boolean;
  multiWorkspace?: boolean;
}) {
  return (
    <View style={[properties.style, properties.padding && FieldsStyles.paddingContainer]}>
      {properties.type !== 'pair' && properties.type !== 'accordion' && (
        <View style={FieldsStyles.unpairContainer}>
          <TextComponent
            multiWorkspace={properties.multiWorkspace}
            bold
            translate
            text={`${properties.prefix}.${'name' in properties && properties.name?.toString()}.title`}
            style={FieldsStyles.unpairText}
          />
          {'required' in properties && properties.required && (
            <View style={FieldsStyles.unpairRequiredTextContainer}>
              <TextComponent
                text="required"
                translate
                bold
                style={FieldsStyles.unpairRequiredText}
              />
            </View>
          )}
        </View>
      )}
      {properties.type !== 'pair' &&
        properties.type !== 'accordion' &&
        'description' in properties &&
        properties.description !== false && (
          <TextComponent
            multiWorkspace={properties.multiWorkspace}
            translate
            text={`${properties.prefix}.${properties.name?.toString()}.description`}
            style={FieldsStyles.unpairDescriptionText}
          />
        )}
      {properties.children}
    </View>
  );
}
