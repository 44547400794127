import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ProgramCycleCrmRepository } from '@human/crm/repositories/programCycle/programCycle.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';

export function DeleteProgramCycleScreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'programCycleDelete'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="crm.programs.update.cycles.delete">
      <FormComponent
        prefix="crm.programs.update.cycles.delete.form"
        padding
        repository={{
          send: () => {
            return new ProgramCycleCrmRepository()
              .delete({
                programId: properties.route.params.programId,
                cycleId: properties.route.params.cycleId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
