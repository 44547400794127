import { OrderTableType } from '@components/table/table.properties';
import { TextComponent } from '@components/text/text.component';
import { ContactTimeColumnImplementation } from './contactTime.column.properties';
import { View } from 'react-native';
import { ContactTimeColumnStyles } from './contacTime.column.styles';
import { IconComponent } from '@components/icon/icon.component';

export function ContactTimeColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: ContactTimeColumnImplementation<ITEM, ORDER>
) {
  const icon =
    properties.onRender?.(properties.item) === 'MORNING'
      ? 'sun'
      : properties.onRender?.(properties.item) === 'AFTERNOON'
        ? 'moon'
        : 'infinity';

  return (
    <>
      {properties.onRender?.(properties.item) && (
        <View style={ContactTimeColumnStyles.container}>
          <View style={ContactTimeColumnStyles.wrapper}>
            <IconComponent name={icon} iconStyle={{ fontSize: 20 }} />
            <TextComponent
              lines={1}
              bold
              translate
              text={`common.contactTime.${properties.onRender?.(properties.item)}`}
              style={ContactTimeColumnStyles.text}
            />
          </View>
        </View>
      )}
    </>
  );
}
