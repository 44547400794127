import { View, TextInput, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { TimetStyles } from './time.styles';
import { TextComponent } from '@components/text/text.component';
import { TimeProperties } from './time.properties';

export function TimeComponent(properties: TimeProperties) {
  return (
    <View style={TimetStyles.time}>
      <View style={TimetStyles.timeColumn}>
        <TextComponent translate text={'calendar.hour'} bold style={TimetStyles.timeLabel} />
      </View>
      <View style={[TimetStyles.timeColumn]}>
        <TextInput
          style={TimetStyles.timeText}
          value={('0' + properties.hour).slice(-2)}
          onChange={(val) => {
            const regex = /^[0-9]+$/;
            if (!val.nativeEvent.text) properties.setHour(0);
            if (regex.test(val.nativeEvent.text)) {
              const number = Number(val.nativeEvent.text);
              properties.setHour(number < 0 ? 0 : number > 23 ? 23 : number);
            }
          }}
          onKeyPress={(key) => {
            if ('key' in key) {
              if (key.key === 'ArrowDown') {
                properties.setHour(properties.hour < 23 ? properties.hour + 1 : 0);
              }
              if ('key' in key && key.key === 'ArrowUp') {
                properties.setHour(properties.hour > 0 ? properties.hour - 1 : 23);
              }
            }
          }}
        />
        <TextComponent
          translate
          text={'calendar.minutes'}
          bold
          style={TimetStyles.timeSeparatorLabel}
        />
        <TextInput
          style={TimetStyles.timeText}
          value={('0' + properties.minutes).slice(-2)}
          onChange={(val: NativeSyntheticEvent<TextInputChangeEventData>) => {
            const regex = /^[0-9]+$/;
            if (!val.nativeEvent.text) properties.setMinutes(0);
            if (regex.test(val.nativeEvent.text)) {
              const number = Number(val.nativeEvent.text);
              properties.setMinutes(number < 0 ? 0 : number > 59 ? 59 : number);
            }
          }}
          onKeyPress={(key) => {
            if ('key' in key) {
              if (key.key === 'ArrowDown') {
                properties.setMinutes(properties.minutes < 59 ? properties.minutes + 1 : 0);
              }
              if (key.key === 'ArrowUp') {
                properties.setMinutes(properties.minutes > 0 ? properties.minutes - 1 : 59);
              }
            }
          }}
        />
      </View>
    </View>
  );
}
