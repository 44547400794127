// Modules
import { StyleSheet } from 'react-native';

/**
 * Covers Styles
 * @constant {StyleSheet} CoversStyles
 */
export const CoversStyles = StyleSheet.create({
  container: {
    marginBottom: 20,
    flex: 1,
  },
  content: {
    gap: 10,
    alignItems: 'center',
  },
  cover: {
    overflow: 'hidden',
    height: 350,
    width: 260,
    backgroundColor: '#000',
    borderRadius: 10,
  },
  image: {
    height: 350,
    width: 260,
    borderRadius: 10,
  },
  title: {
    color: '#fff',
    fontSize: 30,
    marginLeft: 20,
    marginBottom: 10,
    zIndex: 2,
    fontWeight: '700',
  },
  subtitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    zIndex: 2,
    marginTop: 'auto',
  },
  subtitlePrefix: {
    fontWeight: '100',
    fontSize: 17,
    opacity: 0.5,
    color: '#fff',
  },
  subtitleText: {
    fontWeight: '400',
    fontSize: 17,
    color: '#fff',
  },
  count: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 2,
    flexDirection: 'row',
    gap: 6,
  },
  countPrefix: {
    fontWeight: '100',
    fontSize: 17,
    opacity: 0.5,
    color: '#fff',
    textTransform: 'uppercase',
  },
  countText: {
    fontWeight: '400',
    fontSize: 17,
    color: '#fff',
  },

  detailText: {
    color: '#fff',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '700',
    fontSize: 9,
  },
  detail: {
    borderRadius: 100,
    borderWidth: 2,
    zIndex: 2,
    marginLeft: 20,
    marginBottom: 20,
    padding: 5,
    paddingHorizontal: 10,
    alignSelf: 'flex-start',
    borderColor: 'rgb(237, 53, 145)',
    backgroundColor: 'rgba(237, 53, 145, 0.63)',
  },
});
