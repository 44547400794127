import { Pressable, TouchableOpacity, View } from 'react-native';
import { ColorInputImplementation } from './color.implementation';
import { useState } from 'react';
import { ColorInputStyles } from './color.styles';
import { colorValues } from './color.constants';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';
import { IconComponent } from '@components/icon/icon.component';

export function ColorInput<ITEM>(properties: ColorInputImplementation<ITEM>) {
  const value = properties.value[properties.name] as string;
  const [focus, setFocus] = useState(false);

  const onChange = (value: string) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: value,
    };
    properties.onChange(newValue);
  };

  return (
    <>
      <View style={ColorInputStyles.options}>
        {colorValues.map((option, index) => {
          const isActived = value === option;
          return (
            <TouchableOpacity
              disabled={properties.readonly}
              key={index}
              onPress={() => {
                setFocus(false);
                onChange(option);
              }}
              style={[
                ColorInputStyles.optionItem,
                index === colorValues.length - 1 ? ColorInputStyles.lastOption : null,
                { backgroundColor: option },
                properties.readonly ? ColorInputStyles.readonly : null,
              ]}
            >
              {isActived && (
                <IconComponent
                  name="check"
                  iconStyle={ColorInputStyles.icon}
                  containerstyle={ColorInputStyles.iconBox}
                />
              )}
            </TouchableOpacity>
          );
        })}
      </View>

      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
