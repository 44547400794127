import { IconComponent } from '@components/icon/icon.component';
import { IconType } from '@components/icon/icon.types';
import { TextComponent } from '@components/text/text.component';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Pressable, View } from 'react-native';

interface ToasterState {
  updateToaster: (props?: {
    content: string;
    title: string;
    icon: IconType;
    color: string;
  }) => void;
  toaster?: {
    content: string;
    title: string;
    icon: IconType;
    color: string;
  };
}

const ToasterContext = createContext<ToasterState | undefined>(undefined);

export const ToasterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toaster, setToaster] = useState<{
    content: string;
    title: string;
    icon: IconType;
    color: string;
  }>();

  const updateToaster = (props?: {
    content: string;
    title: string;
    icon: IconType;
    color: string;
  }) => {
    setToaster(props);
  };

  return (
    <ToasterContext.Provider value={{ updateToaster, toaster }}>
      {toaster?.content ? (
        <View
          style={{
            position: 'absolute',
            zIndex: 1,
            backgroundColor: toaster.color,
            opacity: 0.8,
            borderRadius: 4,
            minHeight: 50,
            width: 300,
            left: 'calc(50% - 150px)',
            top: 20,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: 'auto',
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <View
              style={{
                width: 24,
                margin: 'auto',
                marginLeft: 10,
              }}
            >
              <IconComponent
                name={toaster.icon}
                containerstyle={{
                  backgroundColor: 'white',
                  borderRadius: 13,
                  width: 26,
                }}
                iconStyle={{
                  color: toaster.color,
                  fontSize: 20,
                  margin: 3,
                }}
              />
            </View>
            <View
              style={{
                flexGrow: 1,
                marginLeft: 16,
              }}
            >
              <TextComponent
                text={toaster.title}
                style={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              />
              <TextComponent text={toaster.content} style={{ color: 'white', fontSize: 12 }} />
            </View>
            <Pressable
              style={{
                alignContent: 'flex-end',
                marginRight: 10,
              }}
              onPress={() => updateToaster()}
            >
              <IconComponent
                name={'times'}
                iconStyle={{
                  color: 'white',
                  fontSize: 12,
                }}
              />
            </Pressable>
          </View>
        </View>
      ) : null}
      {children}
    </ToasterContext.Provider>
  );
};

export const useToaster = (): ToasterState => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error('useToaster must be used within an ToasterProvider');
  }
  return context;
};
