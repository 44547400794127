import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionsRoutes } from '../../../actions.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ButtonComponent } from '@components/button/button.component';
import { ActionAlertCrmRepository } from '@human/crm/repositories/action/alert/action.alert.repository';
import { ListActionAlertScreen } from '@human/crm/sections/alerts/screens/list/list.alert.screen';

export function UpdateActionAlertsPart(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionUpdate'>;
}): TabType {
  return {
    name: 'alerts',
    icon: 'bell',
    content: () => (
      <ListActionAlertScreen
        actionId={properties.route.params.actionId}
        clinicId={properties.route.params.clinicId}
        leadId={properties.route.params.leadId}
        onAdd={() =>
          properties.navigation.navigate('alertCreate', {
            actionId: properties.route.params.actionId,
            clinicId: properties.route.params.clinicId,
            leadId: properties.route.params.leadId,
            level: 2,
          })
        }
        onDelete={({ actionAlertId }) =>
          properties.navigation.navigate('alertDelete', {
            actionAlertId,
            actionId: properties.route.params.actionId,
            clinicId: properties.route.params.clinicId,
            leadId: properties.route.params.leadId,
            level: 2,
          })
        }
        onUpdate={({ actionAlertId }) =>
          properties.navigation.navigate('alertUpdate', {
            actionAlertId,
            actionId: properties.route.params.actionId,
            clinicId: properties.route.params.clinicId,
            leadId: properties.route.params.leadId,
            level: 2,
          })
        }
      />
    ),
  };
}
