import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ResultTemplateCrmRepository extends AuthorizedRepository {
  pick(properties: { resultId: string; activityId: string }): Promise<{
    templateId: string;
    templateLabel: string;
  }> {
    return this.fetch('result/pickTemplate', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: {
    id: string;
    item: Partial<{
      activityId: string;
      templateId: string;
    }>;
  }) {
    return this.fetch(`result/updateTemplate?id=${properties.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }
}
