// Constants
import { MILLISECS_TO_YEAR } from './is.date.older.than.constants';

/** check if date is valid */
export function isDateOlderThan(date: Date, value: number): boolean {
  const now = new Date();
  const momentOfReference = now.getTime() - value * MILLISECS_TO_YEAR;
  // has date happened before moment of reference (is older than the value passed as parameter)
  return date.getTime() < momentOfReference;
}
