import { NavigationProp, RouteProp } from '@react-navigation/native';
import { BoardRoutes } from '../../../board.routes';
import { useTranslation } from 'react-i18next';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import { AnalyticsComponent } from '../components/analytics/analytics.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { useEffect, useMemo, useState } from 'react';
import { ClinicAccessType } from '@human/access/types/clinic.access.type';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { graphicsColors, randomColor } from '../../../utilities/board.color.utility';
import { MarketingBoardRepository } from '../../../repositories/marketing/marketing.repository';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { SceneComponent } from '@components/scene/scene.component';
import { SaleFunnelStyles } from '../sale.funnel.styles';
import { BoardHeaderComponent } from '../../../components/header/board.header.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { LeadsScreenStyles } from '../../leads/leads.screen.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { SaleFunnelPerDepartmentTable } from '../components/saleFunnelPerDepartmentTable/saleFunnelPerDepartmentTable.component';
import { SaleFunnelPerDepartmentChart } from '../components/saleFunnelPerDepartmentChart/saleFunnelPerDepartmentChart.component';
import { PieComparationChart } from '../components/pieComparationChart/pieComparationChart.component';
import { BarChartComponent } from '../components/barChart/barChart.component';
import {
  leadperdepartmentandmonth,
  leadperdepartmentandmonthandevent,
} from '../sale.funnel.screen.web';

export function OvoclinicFunnelsScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'saleFunnel'>;
}) {
  const { t } = useTranslation();
  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  const [month, setMonth] = useState<number>(new Date().getMonth());

  const [clinics, setClinics] = useState<ClinicAccessType[]>();
  const [clinicId, setClinicId] = useState<string>();
  const [languages, setLanguages] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const [languageId, setLanguageId] = useState<string>();

  const [data, setData] = useState<leadperdepartmentandmonth[]>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const departmentData = data?.find((d) => d.language_id === languageId) ?? {
    total_leads: 0,
    lead_contacted_count: 0,
    department_id: '',
    language_id: '',
    department_name: '',
    lead_appointed_count: 0,
    lead_comercial_attended_count: 0,
    lead_converted_count: 0,
    lead_informed_count: 0,
    lead_medic_attended_count: 0,
    lead_medical_appointed_count: 0,
    lead_medical_evaluated_count: 0,
  };

  const [optionA, setOptionA] = useState<string>(Object.keys(leadEvent)[0]);
  const [optionB, setOptionB] = useState<string>(Object.keys(leadEvent)[1]);

  const [optionAResults, setOptionAResults] = useState<leadperdepartmentandmonthandevent[]>();
  const [loadingOptionAResults, setLoadingOptionAResults] = useState<boolean>(false);
  const [optionBResults, setOptionBResults] = useState<leadperdepartmentandmonthandevent[]>();
  const [loadingOptionBResults, setLoadingOptionBResults] = useState<boolean>(false);

  const colors = useMemo(
    () => optionAResults && optionAResults.map((item, i) => graphicsColors[i] || randomColor()),
    [optionAResults && optionAResults.length]
  );

  const leadStateByClinicIdAndMonth = async () => {
    if (!clinicId) return;
    setLoadingData(true);
    new MarketingBoardRepository()
      .leadStateByClinicIdAndMonth({
        clinicId,
        month: month + 1,
      })
      .then((data: any) => {
        setData(data);
        setLoadingData(false);
      });
  };

  const leadStateAndMonthByClinicAndEvent = async (properties: { option: 'A' | 'B' }) => {
    if (!clinicId) return;
    if (properties.option === 'A') {
      setLoadingOptionAResults(true);
      return new MarketingBoardRepository()
        .leadStateAndMonthByClinicAndEvent({
          clinicId,
          month: month + 1,
          event: optionA,
        })
        .then((data) => {
          setOptionAResults(data);
          setLoadingOptionAResults(false);
        });
    }

    setLoadingOptionBResults(true);
    return new MarketingBoardRepository()
      .leadStateAndMonthByClinicAndEvent({
        clinicId,
        month: month + 1,
        event: optionB,
      })
      .then((data) => {
        setOptionBResults(data);
        setLoadingOptionBResults(false);
      });
  };

  useEffect(() => {
    new ClinicAccessRepository()
      .list({
        page: 0,
        direction: 'asc',
        limit: 100,
      })
      .then((data) => {
        setClinicId(data.items[0].id);
        setClinics(data.items);
      });
    new LanguageAccessRepository()
      .list({
        limit: 100,
      })
      .then((language) => {
        setLanguages(
          language.items.map((lang) => {
            return {
              label: lang.name,
              value: lang.id,
            };
          })
        );
        setLanguageId(language.items[0].id);
      });
  }, []);

  useEffect(() => {
    if (!clinicId) return;

    leadStateByClinicIdAndMonth();
    leadStateAndMonthByClinicAndEvent({
      option: 'A',
    });
    leadStateAndMonthByClinicAndEvent({
      option: 'B',
    });
  }, [clinicId, month]);

  useEffect(() => {
    if (!clinicId) return;
    leadStateAndMonthByClinicAndEvent({
      option: 'A',
    });
  }, [optionA]);

  useEffect(() => {
    if (!clinicId) return;
    leadStateAndMonthByClinicAndEvent({
      option: 'B',
    });
  }, [optionB]);

  const handleExport = (type: 'yearly' | 'monthly' | 'byEvent') => {
    if (data && confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      handleExportTableCSV({
        headers: ['Departamento', 'Total Leads', ...Object.values(leadEvent)],
        data: data,
        type,
        fileName: 'sale_funnel.csv',
        eventFields: [
          'total_leads',
          'lead_contacted_count',
          'lead_appointed_count',
          'lead_informed_count',
          'lead_medical_appointed_count',
          'lead_medic_attended_count',
          'lead_comercial_attended_count',
          'lead_medical_evaluated_count',
          'lead_converted_count',
        ],
      });
    }
    return;
  };

  const topGraphicsTitle = `${t('board.saleFunnel.totalSales.graphicTitle')} /${clinics && clinics.find((clinic) => clinic.id === clinicId)?.name} /${t(`access.users.list.month.${month}`)}`;
  const bottomGraphicsTitle = `${t('board.saleFunnel.totalSales.graphicTitle')} /${clinics && clinics.find((clinic) => clinic.id === clinicId)?.name} /${languages?.length && languages.find((language) => language.value === languageId)?.label} /${t(`access.users.list.month.${month}`)}`;

  if (!clinics || !clinicId || !languageId) {
    return <LoaderComponent loading />;
  }

  return (
    <SceneComponent prefix={'board.saleFunnel'} icon={'search'} links={[]}>
      <View style={SaleFunnelStyles.container}>
        <BoardHeaderComponent
          title="board.sections.saleFunnel.title"
          description="board.sections.saleFunnel.description"
        />
        <View style={SaleFunnelStyles.filtersContainer}>
          <View style={[SaleFunnelStyles.graphicContainer, { zIndex: 1, flex: 2 }]}>
            <View style={[SaleFunnelStyles.filterContainer, { width: 250 }]}>
              <View style={SaleFunnelStyles.filterTextContainer}>
                <Text style={SaleFunnelStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.clinic')}
                </Text>
                <Text style={SaleFunnelStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.clinicSubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={clinicId}
                  name="clinic"
                  onChange={(value: any) => {
                    setClinicId(value.clinic);
                  }}
                  pick={async ({ id }) =>
                    new ClinicAccessRepository().pick({ id }).then((res) => res.name ?? '')
                  }
                  hideSearch
                  repository={async () => {
                    if (!clinics) return [];
                    return clinics.map((clinic) => {
                      return {
                        label: clinic.name,
                        value: clinic.id,
                      };
                    });
                  }}
                />
              </View>
            </View>
            <View style={SaleFunnelStyles.filterContainer}>
              <View style={SaleFunnelStyles.filterTextContainer}>
                <Text style={SaleFunnelStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.month')}
                </Text>
                <Text style={SaleFunnelStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.monthSubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={month.toString()}
                  name="month"
                  onChange={(value: any) => {
                    setMonth(parseInt(value.month));
                  }}
                  options={Array.from({ length: 12 }, (_, i) => {
                    return {
                      label: t(`access.users.list.month.${i}`),
                      value: i.toString(),
                    };
                  })}
                  hideSearch
                />
              </View>
            </View>
          </View>
          <View style={SaleFunnelStyles.graphicContainer}>
            <View style={[SaleFunnelStyles.filterContainer, { width: 200, paddingLeft: 10 }]}>
              <View style={SaleFunnelStyles.filterTextContainer}>
                <Text style={SaleFunnelStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.optionA')}
                </Text>
                <Text style={SaleFunnelStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.optionASubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={optionA}
                  name="optionA"
                  onChange={(value: any) => {
                    setOptionA(value.optionA);
                  }}
                  hideSearch
                  options={Object.keys(leadEvent).map((option) => ({
                    label: leadEvent[option],
                    value: option,
                  }))}
                />
              </View>
            </View>
            <View style={[SaleFunnelStyles.filterContainer, { width: 200 }]}>
              <View style={SaleFunnelStyles.filterTextContainer}>
                <Text style={SaleFunnelStyles.filterTitle}>
                  {t('board.summarySales.totalSales.filters.optionB')}
                </Text>
                <Text style={SaleFunnelStyles.filterSubtitle}>
                  {t('board.summarySales.totalSales.filters.optionBSubtitle')}
                </Text>
              </View>
              <View>
                <FilterSelectInput
                  type="select"
                  value={optionB}
                  name="optionB"
                  onChange={(value: any) => {
                    setOptionB(value.optionB);
                  }}
                  options={Object.keys(leadEvent).map((option) => ({
                    label: leadEvent[option],
                    value: option,
                  }))}
                  hideSearch
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ gap: 10, flex: 2, paddingRight: 20 }}>
            <View style={SaleFunnelStyles.minHeightGraphic}>
              <View style={SaleFunnelStyles.graphicContainerHeader}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
                {Platform.OS === 'web' && (
                  <TouchableOpacity
                    style={LeadsScreenStyles.uploadBox}
                    onPress={() => handleExport('byEvent')}
                  >
                    <View style={LeadsScreenStyles.buttonContainer}>
                      <TextComponent
                        text={t('board.leads.totalLeads.export')}
                        style={LeadsScreenStyles.buttonLabel}
                      />
                      <IconComponent
                        name="upload"
                        containerstyle={LeadsScreenStyles.iconContainer}
                        iconStyle={LeadsScreenStyles.icon}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
              {loadingData ? (
                <LoaderComponent loading />
              ) : (
                <SaleFunnelPerDepartmentTable data={data || []} total={true} />
              )}
            </View>
            <View style={SaleFunnelStyles.minHeightGraphic}>
              <View style={SaleFunnelStyles.graphicContainerHeader}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
              </View>
              {loadingData ? (
                <LoaderComponent loading />
              ) : (
                <SaleFunnelPerDepartmentChart data={data || []} colors={colors || []} />
              )}
            </View>
          </View>
          <View style={{ flex: 1, minHeight: 800, backgroundColor: 'white' }}>
            <View style={{ flex: 1 }}>
              <View style={[SaleFunnelStyles.graphicContainerHeader, { paddingTop: 30 }]}>
                <Text style={SaleFunnelStyles.graphicTitle}>{topGraphicsTitle}</Text>
              </View>
              {loadingOptionAResults || loadingOptionBResults ? (
                <LoaderComponent loading />
              ) : (
                <PieComparationChart
                  optionAData={optionAResults || []}
                  optionASelected={leadEvent[optionA]}
                  optionBData={optionBResults || []}
                  optionBSelected={leadEvent[optionB]}
                  colors={colors || []}
                />
              )}
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'column', paddingTop: 50 }}>
          <View style={{ marginBottom: 10, width: 250, zIndex: 1 }}>
            <View style={SaleFunnelStyles.filterTextContainer}>
              <Text style={SaleFunnelStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.department')}
              </Text>
              <Text style={SaleFunnelStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.departmentSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={languageId}
                name="department"
                onChange={(value: any) => {
                  setLanguageId(value.department);
                }}
                pick={async ({ id }) =>
                  new LanguageAccessRepository()
                    .pick({
                      languageId: id,
                    })
                    .then((res) => res.name ?? '')
                }
                hideSearch
                repository={async () => (languages ? languages : [])}
              />
            </View>
          </View>
          <View style={[SaleFunnelStyles.graphicContainer, SaleFunnelStyles.minHeightGraphic]}>
            {departmentData ? (
              <View style={{ flex: 2 }}>
                <View style={{ flex: 1 }}>
                  <View style={SaleFunnelStyles.graphicContainerHeader}>
                    <Text style={SaleFunnelStyles.graphicTitle}>{bottomGraphicsTitle}</Text>
                  </View>
                  <BarChartComponent
                    data={departmentData}
                    leadEvent={leadEvent}
                    selectedDepartmentName={languages?.find((d) => d.value === languageId)?.label}
                    total={true}
                  />
                </View>
              </View>
            ) : (
              <LoaderComponent loading />
            )}
            {departmentData && languages ? (
              <View style={{ flex: 1 }}>
                <AnalyticsComponent
                  clinicName={clinics.find((clinic) => clinic.id === clinicId)?.name || ''}
                  departmentName={
                    languages.find((language) => language.value === languageId)?.label || ''
                  }
                  month={t(`access.users.list.month.${month}`)}
                  chartProps={{
                    data: departmentData,
                    leadEvent,
                    total: true,
                  }}
                />
              </View>
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
