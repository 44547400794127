// Modules
import { StyleSheet } from 'react-native';

/**
 * Contacts Styles
 * @constant {StyleSheet} DockStyles
 */
export const ContactsStyles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(17, 27, 34,0.2)',
    marginLeft: -20,
    marginRight: -20,
    marginTop: -20,
    marginBottom: 20,
  },
  marker: {},
  campaign: {},
  content: {
    gap: 20,
  },
  item: {
    width: 220,
    height: 300,
    maxWidth: 280,

    borderRadius: 5,
    flex: 1,
    backgroundColor: 'rgb(255, 255, 255)',
  },
  name: {
    color: 'rgba(237, 53, 145,1)',
    fontSize: 18,
    marginLeft: 13,
    marginTop: 10,
  },
  email: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: 12,
    marginLeft: 20,
  },
  map: {
    height: 120,
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    overflow: 'hidden',
  },
  call: {
    color: 'white',
    fontSize: 12,
    letterSpacing: 2,
    textAlign: 'center',
    padding: 10,
    margin: 25,
    borderRadius: 35,
    marginTop: 'auto',
    backgroundColor: 'rgba(237, 53, 145,1)',
  },
});
