import { View, TextInput as TextInputNative, TouchableOpacity, Text } from 'react-native';
import { CheckboxInputImplementation } from './checkbox.implementation';
import { IconComponent } from '@components/icon/icon.component';
import { useState } from 'react';
import { CheckboxInputValidations } from './checkbox.validations';
import { ErrorsComponent } from '../errors/errors.component';
import { TextComponent } from '@components/text/text.component';
import { CheckboxStyles } from './checkbox.styles';
import { ExceptionsComponent } from '@components/exceptions/exceptions.component';

export function CheckboxInput<ITEM>(properties: CheckboxInputImplementation<ITEM>) {
  const value = properties.values?.[properties.name] as boolean;
  const [focus, setFocus] = useState(false);

  const onChange = (checked: boolean) => {
    const key = properties.name;
    // @ts-ignore
    const newValue: Partial<ITEM> = {
      [key]: checked,
    };
    properties.onChange(newValue);
  };

  const errors =
    properties.validations &&
    CheckboxInputValidations({
      validations: properties.validations,
      value: value,
      required: properties.required,
    });

  return (
    <>
      <TouchableOpacity
        disabled={properties.readonly}
        onPress={() => {
          onChange(!value);
        }}
        style={[
          CheckboxStyles.container,
          properties.readonly ? CheckboxStyles.inputReadonly : null,
        ]}
      >
        {properties.icon && (
          <IconComponent
            name={properties.icon}
            containerstyle={CheckboxStyles.iconContainer}
            iconStyle={CheckboxStyles.iconStyle}
          />
        )}
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          bold
          text={`${properties.prefix}.${properties.name.toString()}.label`}
          style={[
            CheckboxStyles.label,
            properties.icon ? CheckboxStyles.labelIcon : CheckboxStyles.labelNoIcon,
          ]}
        />
        <View style={CheckboxStyles.checkBoxContainer}>
          <View
            style={[
              CheckboxStyles.checkBoxCheckContainer,
              value ? CheckboxStyles.checkBoxCheckActive : CheckboxStyles.checkBoxCheckInactive,
            ]}
          >
            <View
              style={[
                CheckboxStyles.checkBoxCheckIconContainer,
                value
                  ? CheckboxStyles.checkBoxCheckIconActive
                  : CheckboxStyles.checkBoxCheckIconInactive,
              ]}
            >
              <IconComponent
                name={value ? 'check' : 'times'}
                containerstyle={{
                  height: 20,
                  width: 20,
                }}
                iconStyle={{
                  color: 'white',
                  fontSize: 14,
                  textAlign: 'center',
                  lineHeight: 20,
                }}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>

      {errors && <ErrorsComponent prefix={'form.checkbox.errors'} errors={errors} />}
      {properties.errors && (
        <ExceptionsComponent
          name={properties.name}
          prefix={'form.text.errors'}
          exceptions={properties.errors}
        />
      )}
    </>
  );
}
