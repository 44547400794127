import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Dimensions, View } from 'react-native';
import { ItemByDepartmentYearsComparativeType } from '../newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { ChartData, RadialLinearScale } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(RadialLinearScale);

const { height } = Dimensions.get('window');

export function TwoYearColumnLeadsCirle(properties: {
  firstYear: number;
  secondYear: number;
  departmentName: string;
  selectedDepartment: string;
  data: ItemByDepartmentYearsComparativeType[];
}) {
  const [circleData, setCircleData] = useState<ChartData<'pie', number[], string>>();

  useEffect(() => {
    if (
      properties.data.length === 0 ||
      !properties.firstYear ||
      !properties.secondYear ||
      !properties.departmentName
    )
      return;

    const firstYearData = properties.data
      .filter(
        (item) =>
          item.year === properties.firstYear && item.department_name === properties.departmentName
      )

      .reduce((acc, item) => acc + item.total, 0);

    const secondYearData = properties.data
      .filter(
        (item) =>
          item.year === properties.secondYear && item.department_name === properties.departmentName
      )
      .reduce((acc, item) => acc + item.total, 0);

    const isEmpty = firstYearData + secondYearData === 0;

    setCircleData({
      labels: isEmpty ? [] : [properties.firstYear.toString(), properties.secondYear.toString()],
      datasets: [
        {
          label: isEmpty ? undefined : 'Leads',
          data: isEmpty ? [100] : [firstYearData, secondYearData],
          borderColor: isEmpty ? ['rgba(211, 211, 211, 0.5)'] : ['rgba(0, 0, 0, 1)'],
          backgroundColor: isEmpty ? ['rgba(245, 245, 245, 0.9)'] : ['#d3d3d3', '#283038'], // Colores personalizados
          hoverBackgroundColor: ['#b0b0b0', '#111111'], // Colores al hacer hover
          borderWidth: 1,
        },
      ],
    });
  }, [properties.data, properties.firstYear, properties.secondYear, properties.selectedDepartment]);

  if (!circleData) return null;

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 4, padding: 20, height: height * 0.4 }}>
      <Pie
        data={circleData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                color: '#283038',
              },
            },
            tooltip: {
              enabled: circleData.labels && circleData.labels.length > 0,
              callbacks: {
                label: (tooltipItem: any) => `${tooltipItem.label}: ${tooltipItem.raw} leads`,
              },
            },
          },
        }}
      />
    </View>
  );
}
