// Modules
import { StyleSheet } from 'react-native';

/**
 * Fields Styles
 * @constant {StyleSheet} FieldsStyles
 */
export const FieldsStyles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  paddingContainer: {
    padding: 20,
  },
  unpairContainer: {
    flexDirection: 'row',
    paddingBottom: 10,
    alignItems: 'center',
  },
  unpairText: {
    padding: 5,
    fontSize: 13,
  },
  unpairRequiredTextContainer: {
    padding: 5,
    paddingVertical: 3,
    borderWidth: 1,
    borderRadius: 25,
    borderStyle: 'dashed',
    borderColor: 'rgba(255, 77, 0, 0.42)',
    backgroundColor: 'rgba(255, 228, 216, 0.62)',
  },
  unpairRequiredText: {
    fontSize: 8,
    textTransform: 'uppercase',
    letterSpacing: 0,
    color: 'orangered',
  },
  unpairDescriptionText: {
    padding: 5,
    paddingTop: 0,
    paddingBottom: 10,
    marginTop: -15,
    opacity: 0.6,
    fontSize: 12,
  },
});
