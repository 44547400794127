// Modules
import { StyleSheet } from 'react-native';

/**
 * IconColumnStyles Styles
 * @constant {StyleSheet} IconColumnStylesStyles
 */
export const IconColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    width: 41,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerIsTrash: {
    backgroundColor: 'rgb(255, 233, 233)',
  },
  containerIsNotTrash: {
    backgroundColor: '#eee',
  },
  iconContainer: {
    height: 42,
    width: 42,
  },
  iconStyle: {
    fontSize: 19,
    textAlign: 'center',
    lineHeight: 40,
  },
  iconIsTrash: {
    color: 'rgb(255, 59, 59)',
  },
  iconsIsNotTrash: {
    color: '#333',
  },
});
