import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { ManagerRepository } from '@human/crm/repositories/manager/manager.repository';

export function DeleteManagerCampaignPart(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'deleteManager'>;
}) {
  return (
    <ModalComponent
      icon="trash"
      level={properties.route.params.level}
      prefix="crm.campaigns.delete.manager"
    >
      <FormComponent<CampaignCrmType>
        padding
        prefix="crm.campaigns.delete.manager.form"
        repository={{
          send: () =>
            new ManagerRepository()
              .delete({
                campaignId: properties.route.params.campaignId,
                userId: properties.route.params.userId,
              })
              .then(() => properties.navigation.goBack()),
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
