import { OrderTableType } from '@components/table/table.properties';
import { PriceColumnImplementation } from './price.column.properties';
import { View, Text, TouchableOpacity } from 'react-native';
import { PriceColumnStyles } from './price.column.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';

export function PriceColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  props: PriceColumnImplementation<ITEM, ORDER>
): JSX.Element {
  const text = props.onRender?.(props.item) ?? props.name;
  const currency = props.onCurrency?.(props.item) ?? props.name;

  // // convert 10030 to 100.30
  // const textWithDecimal = text ? text.toString().replace(/(\d+)(\d{2})$/, '$1.$2') : '0.00'

  const content = (
    <>
      <TextComponent lines={1} bold text={`${text}`} style={PriceColumnStyles.text} />
      <TextComponent lines={1} text={`${currency}`} style={PriceColumnStyles.currency} />
    </>
  );

  if (props.onPress) {
    return (
      <TouchableOpacity
        onPress={() => props.onPress?.(props.item)}
        style={PriceColumnStyles.container}
      >
        {content}
        <IconComponent name="angle-right" iconStyle={PriceColumnStyles.icon} />
      </TouchableOpacity>
    );
  }
  return <View style={PriceColumnStyles.container}>{content}</View>;
}
