import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';

export function DisableContractScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'contractDisable'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash"
      prefix="access.users.update.contracts.disable"
    >
      <FormComponent
        prefix="access.users.update.contracts.disable.form"
        padding
        repository={{
          send: () => {
            return new ContractAccessRepository()
              .disable({
                contractId: properties.route.params.contractId,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
