import { Image } from 'react-native';
import { useState, useRef } from 'react';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { AvatarUploaderStyles } from './avatarUploader.styles';

export function AvatarUploaderComponent(properties: {
  onSubmit: (f: File | undefined) => Promise<void>;
  file: string | undefined;
}) {
  const [file, setFile] = useState<File>();
  const [url404, setUrl404] = useState(false);
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.includes('image')) {
      setFile(selectedFile);
      properties.onSubmit(selectedFile);
      setUrl404(false);
    } else {
      alert(t('components.fileUploader.invalidType'));
    }
  };

  return (
    <div
      onClick={() => fileInputRef.current?.click()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={AvatarUploaderStyles.container}
    >
      {hover && (
        <div style={AvatarUploaderStyles.overlay}>
          <IconComponent name="camera" iconStyle={AvatarUploaderStyles.iconCamera} />
        </div>
      )}
      {(properties.file || file) && !url404 ? (
        <Image
          source={{ uri: file ? URL.createObjectURL(file) : properties.file }}
          onError={() => {
            setUrl404(true);
          }}
          style={AvatarUploaderStyles.image}
        />
      ) : (
        <IconComponent
          name="user"
          containerstyle={AvatarUploaderStyles.iconUserContainer}
          iconStyle={AvatarUploaderStyles.iconUser}
        />
      )}
      {/* Elemento input de archivo oculto */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
