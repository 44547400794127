import { View, Text } from 'react-native';
import { TotalRowProperties } from './totalRow.properties';
import { GeneralTableStyles } from '../../general.table.styles';
import { calculatePercentage } from '@utils/percentage/getPercentage.utility';

export function TotalRowComponent(properties: TotalRowProperties) {
  //CALCULO TOTAL ANUAL
  const totalAnualData = {
    january_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_january,
      0
    ),
    january_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_january,
      0
    ),
    february_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_february,
      0
    ),
    february_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_february,
      0
    ),
    march_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_march,
      0
    ),
    march_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_march,
      0
    ),
    april_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_april,
      0
    ),
    april_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_april,
      0
    ),
    may_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_may,
      0
    ),
    may_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_may,
      0
    ),
    june_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_june,
      0
    ),
    june_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_june,
      0
    ),
    july_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_july,
      0
    ),
    july_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_july,
      0
    ),
    august_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_august,
      0
    ),
    august_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_august,
      0
    ),
    september_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_september,
      0
    ),
    september_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_september,
      0
    ),
    october_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_october,
      0
    ),
    october_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_october,
      0
    ),
    november_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_november,
      0
    ),
    november_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_november,
      0
    ),
    december_total_leads: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_leads_december,
      0
    ),
    december_total_informed: properties.data.clinics.reduce(
      (acc, clinic) => acc + clinic.total_vi_december,
      0
    ),
  };

  return (
    <View key={'Total'} style={[GeneralTableStyles.dataRow, GeneralTableStyles.total]}>
      <>
        {/**ENERO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.january_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.january_total_informed}(${calculatePercentage(totalAnualData.january_total_leads, totalAnualData.january_total_informed)}%)`}
          </Text>
        </View>
        {/**FEBRERO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>
            {totalAnualData.february_total_leads}
          </Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.february_total_informed}(${calculatePercentage(totalAnualData.february_total_leads, totalAnualData.february_total_informed)}%)`}
          </Text>
        </View>
        {/**MARZO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.march_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.march_total_informed}(${calculatePercentage(totalAnualData.march_total_leads, totalAnualData.march_total_informed)}%)`}
          </Text>
        </View>
        {/**ABRIL */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.april_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.april_total_informed}(${calculatePercentage(totalAnualData.april_total_leads, totalAnualData.april_total_informed)}%)`}
          </Text>
        </View>
        {/**MAYO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.may_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.may_total_informed}(${calculatePercentage(totalAnualData.may_total_leads, totalAnualData.may_total_informed)}%)`}
          </Text>
        </View>
        {/**JUNIO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.june_total_leads}</Text>
          <Text
            style={GeneralTableStyles.tableDataText}
          >{`${totalAnualData.june_total_informed}(${calculatePercentage(totalAnualData.june_total_leads, totalAnualData.june_total_informed)}%)`}</Text>
        </View>
        {/**JULIO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.july_total_leads}</Text>
          <Text
            style={GeneralTableStyles.tableDataText}
          >{`${totalAnualData.july_total_informed}(${calculatePercentage(totalAnualData.july_total_leads, totalAnualData.july_total_informed)}%)`}</Text>
        </View>
        {/**AGOSTO */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.august_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.august_total_informed}(${calculatePercentage(totalAnualData.august_total_leads, totalAnualData.august_total_informed)}%)`}
          </Text>
        </View>
        {/**SEPTIEMBRE */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>
            {totalAnualData.september_total_leads}
          </Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.september_total_informed}(${calculatePercentage(totalAnualData.september_total_leads, totalAnualData.september_total_informed)}%)`}
          </Text>
        </View>
        {/**OCTUBRE */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>{totalAnualData.october_total_leads}</Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.october_total_informed}(${calculatePercentage(totalAnualData.october_total_leads, totalAnualData.october_total_informed)}%)`}
          </Text>
        </View>
        {/**NOVIEMBRE */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>
            {totalAnualData.november_total_leads}
          </Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.november_total_informed}(${calculatePercentage(totalAnualData.november_total_leads, totalAnualData.november_total_informed)}%)`}
          </Text>
        </View>
        {/**DICIEMBRE */}
        <View style={GeneralTableStyles.rowContainer}>
          <Text style={GeneralTableStyles.tableDataText}>
            {totalAnualData.december_total_leads}
          </Text>
          <Text style={GeneralTableStyles.tableDataText}>
            {`${totalAnualData.december_total_informed}(${calculatePercentage(totalAnualData.december_total_leads, totalAnualData.december_total_informed)}%)`}
          </Text>
        </View>
      </>
    </View>
  );
}
