import { NavigationProp, RouteProp } from '@react-navigation/native';
import { OvopointsRoutes } from '../ovopoints.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { OvopointAccessType } from '@human/access/types/ovopoint.access.type';
import { OvopointAccessRepository } from '@human/access/repositories/ovopoint/ovopoint.repository';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateOvopointsScreen(properties: {
  navigation: NavigationProp<OvopointsRoutes>;
  route: RouteProp<OvopointsRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon="coins" prefix="access.ovopoints.update">
      <FormComponent<OvopointAccessType>
        padding
        readonly={!hasPermissionUtilty({ name: 'access.ovopoints.editor' })}
        prefix={'access.ovopoints.update.form'}
        repository={{
          get: () => new OvopointAccessRepository().pick(),
          send: (data) =>
            new OvopointAccessRepository()
              .update({ item: { ...data.item } })
              .then(() => properties.navigation.goBack()),
        }}
        fields={[
          {
            type: 'number',
            name: 'contactedPoints',
            description: true,
          },
          {
            type: 'number',
            name: 'informedPoints',
            description: true,
          },
          {
            type: 'number',
            name: 'appointedPoints',
            description: true,
          },
          {
            type: 'number',
            name: 'attendedPoints',
            description: true,
          },
          {
            type: 'number',
            name: 'commercialAttendedPoints',
            description: true,
          },
          {
            type: 'number',
            name: 'convertedPoints',
            description: true,
            multiWorkspace: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
