import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';

export function EnableCountryScreen(properties: {
  navigation: NavigationProp<CountriesRoutes>;
  route: RouteProp<CountriesRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash" prefix="access.countries.enable">
      <FormComponent
        prefix="access.countries.enable.form"
        padding
        repository={{
          send: () => {
            return new CountryAccessRepository()
              .enable({
                countryId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
