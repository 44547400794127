// Modules
import { StyleSheet } from 'react-native';

/**
 * TextColumn Styles
 * @constant {StyleSheet} TextColumnStyles
 */
export const ClinicColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 7,
    alignContent: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  bubble: {
    flex: 1,
    backgroundColor: 'rgba(237, 53, 145, 0.1)',
    borderRadius: 3,
    margin: 7,
    marginHorizontal: 0,
    paddingRight: 12,
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
  },
  icon: {
    padding: 10,
    borderRightColor: 'rgba(0, 0, 0, 0.2)',
    borderRightWidth: 1,
    fontSize: 10,
    paddingVertical: 10,
    color: 'rgb(237, 53, 145)',
    backgroundColor: 'rgb(227, 227, 227)',
  },
  iconShape: {
    color: 'rgb(255, 255, 255)',
    fontSize: 20,
  },
  text: {
    flex: 1,
    color: 'rgb(237, 53, 145)',
    paddingLeft: 10,
    textTransform: 'uppercase',
    overflow: 'hidden',
  },
});
