// Modules
import { StyleSheet } from 'react-native';

/**
 * DateColumnStyles Styles
 * @constant {StyleSheet} DateColumnStylesStyles
 */
export const DateColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    width: 250,
    paddingHorizontal: 15,
    paddingRight: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  textformatYYYYMMDD: {
    color: '#333',
  },
  textformatHHMM: {
    marginLeft: 10,
  },
  textformatHHMMDisabled: {
    color: '#9E9E9E',
  },
  textformatHHMMEnabled: {
    color: '#AAA',
  },
  agoComponent: {
    color: '#111',
    borderRadius: 45,
    padding: 6,
    paddingHorizontal: 10,
    marginLeft: 'auto',
    fontSize: 10,
  },
  agoComponentEnabled: {
    backgroundColor: '#D1D1D162',
  },
  agoComponentDisabled: {
    color: '#9E9E9E',
  },
});
