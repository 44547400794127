import { TableComponent } from '@components/table/table.component';
import { NotificationCommonRepository } from '../repositories/notification.repository';
import { NotificationType } from '../types/notification.type';
import { SceneComponent } from '@components/scene/scene.component';
import { useEffect, useState } from 'react';
import { LoaderComponent } from '@components/loader/loader.component';
import { useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useNotificationState } from '@states/notification/notification.state';

export function NotificationScreen() {
  const { readNotification } = useNotificationState();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const { t } = useTranslation();

  const focused = useIsFocused();

  const loadNotifications = () => {
    new NotificationCommonRepository().list().then((data) => {
      setNotifications(data.items);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!focused) return;

    loadNotifications();
  }, [focused]);

  const handleMarkAsRead = (notification: NotificationType) => {
    new NotificationCommonRepository()
      .markAsRead({
        notificationId: notification.id,
      })
      .then(() => {
        setNotifications((notifications) =>
          notifications.filter((item) => item.id !== notification.id)
        );
        readNotification();
      });
  };

  if (loading) return <LoaderComponent loading />;

  return (
    <SceneComponent icon={'bell'} prefix="common.notifications.list">
      <TableComponent<NotificationType, []>
        prefix={'common.notifications.list'}
        scrollable
        suffix={'list'}
        repository={async (settings) => ({
          items: notifications,
          total: notifications.length,
        })}
        search={false}
        columns={[
          {
            type: 'icon',
            renderIcon: () => 'check',
            onPress: (notification) => handleMarkAsRead(notification),
          },
          {
            type: 'text',
            name: 'title',
            onRender: (notification) => notification.title,
            width: 750,
          },
          {
            type: 'text',
            name: 'type',
            onRender: (notification) => t(`common.notifications.types.${notification.type}`),
            width: 150,
          },
          {
            type: 'date',
            name: 'created',
            renderDate: (notification) => notification.created,
          },
        ]}
      />
    </SceneComponent>
  );
}
