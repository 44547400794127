// Modules
import { TouchableOpacity } from 'react-native';

// Components
import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';

// Properties
import { ActionProperties } from './action.properties';

// Styles
import { ActionStyles } from './action.styles';

/**
 * Action Component
 * @description Botón horizontal renderizado generalmente en la parte superior
 * de los formularios similar a los botones de navegación de las aplicaciones móviles.
 * @returns {JSX.Element}
 */

export function ActionComponent(properties: ActionProperties) {
  return (
    <TouchableOpacity onPress={properties.onPress} style={ActionStyles.action}>
      <IconComponent name={properties.icon || 'angle-left'} iconStyle={ActionStyles.actionIcon} />
      <TextComponent translate text={`${properties.prefix}`} bold style={ActionStyles.actionText} />
      {properties.bubble && <TextComponent text={properties.bubble} style={ActionStyles.bubble} />}
    </TouchableOpacity>
  );
}
