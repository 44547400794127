import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../../contacts.routes';
import { ScrollView } from 'react-native';
import { TableComponent } from '@components/table/table.component';
import { PurchaseCrmType } from '@human/crm/types/purchase.crm.type';
import { TabType } from '@components/tabs/tabs.types';
import { PurchaseCrmRepository } from '@human/crm/repositories/purchase/purchase.repository';
import { useTranslation } from 'react-i18next';

export function PurchasesUpdatePart(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'update'>;
}): TabType {
  const { t } = useTranslation();

  return {
    name: 'purchases',
    icon: 'money-bill',
    content: () => (
      <ScrollView>
        <TableComponent<PurchaseCrmType, []>
          scrollable
          prefix="crm.contacts.update.purchases.list"
          suffix="list"
          repository={(setting) =>
            new PurchaseCrmRepository().listByContact({
              contactId: properties.route.params.contactId,
              search: setting.search,
              page: setting.page,
              limit: setting.limit,
            })
          }
          columns={[
            {
              type: 'icon',
              renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
              renderColor: (row) => (row.status ? 'green' : 'red'),
              onPress: (row) => {
                properties.navigation.navigate(row.status ? 'disablePurchase' : 'enablePurchase', {
                  clinicId: row.clinicId,
                  patientId: row.patientId ?? '',
                  purchaseId: row.id,
                  level: 1,
                });
              },
            },
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('updatePurchase', {
                  clinicId: row.clinicId,
                  patientId: row.patientId ?? '',
                  purchaseId: row.id,
                  level: 1,
                });
              },
            },
            {
              type: 'multipleBadge',
              name: 'purchaseType',
              onRender: (item) => [t(`common.purchaseType.${item.purchaseType}`)],
              width: 150,
            },
            {
              type: 'text',
              name: 'treatmentName',
              onRender: (item) => item.treatmentName,
              width: 200,
            },
            {
              type: 'price',
              name: 'price',
              onRender: (item) => item.price,
              onCurrency: () => '€',
              width: 100,
            },
            {
              type: 'clinic',
              name: 'clinicName',
              onRender: (item) => item.clinicName,
              width: 200,
            },
          ]}
        />
      </ScrollView>
    ),
  };
}
