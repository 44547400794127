// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const FloatInputStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  controll: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  controls: {
    position: 'absolute',
    left: 3,
  },
  iconControl: {
    height: 20,
    width: 20,
  },
  readonly: {
    backgroundColor: '#DDDDDD',
    color: '#777777',
    minHeight: 42,
    borderRadius: 5,
  },
  shapeIconControl: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  input: {
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: 'white',
    height: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
});
