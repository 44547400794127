import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';
import { dayjs } from '@utils/dayjs/dayjs.config';

export class TreatmentCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof TreatmentCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{
    items: TreatmentCrmType[];
    total: number;
  }> {
    return this.fetch('treatment/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          programId: item.programId,
          phaseId: item.phaseId,
          programName: item.programName,
          patientId: item.patientId,
          patientName: item.patientName,
          patientSurname: item.patientSurname,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          lastCycleClinicId: item.lastCycleClinicId,
          lastCycleClinicName: item.lastCycleClinicName,
          expectedDate: item.expectedDate ? new Date(item.expectedDate) : undefined,
          cycleName: item.cycleName,
          phaseName: item.phaseName,
          finished: item.finished ? new Date(item.finished) : undefined,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  async listByPatient(properties: {
    page: number;
    limit: number;
    search?: string;
    clinicId: string;
    patientId: string;
    order: keyof TreatmentCrmType;
    direction: 'asc' | 'desc';
  }): Promise<{ items: TreatmentCrmType[]; total: number }> {
    return this.fetch('treatment/findByPatient', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          programId: item.programId,
          phaseId: item.phaseId,
          programName: item.programName,
          patientId: item.patientId,
          patientName: item.patientName,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          estimated: new Date(item.estimated),
          finished: item.finished ? new Date(item.finished) : undefined,
          status: item.status,
        })),
        total: data.total,
      };
    });
  }

  async create(properties: {
    item: Partial<TreatmentCrmType>;
    clinicId: string;
    patientId: string;
    programId: string;
  }): Promise<void> {
    return this.fetch('treatment/create', {
      method: 'POST',
      body: {
        ...properties.item,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
        programId: properties.programId,
      },
    });
  }

  async update(properties: {
    item: Partial<TreatmentCrmType>;
    clinicId: string;
    patientId: string;
    treatmentId: string;
  }): Promise<void> {
    return this.fetch('treatment/update', {
      method: 'PUT',
      body: {
        ...properties.item,
        finished: properties.item.finished
          ? dayjs(properties.item.finished).tz('Europe/Madrid').toISOString()
          : undefined,

        clinicId: properties.clinicId,
        patientId: properties.patientId,
        treatmentId: properties.treatmentId,
      },
    });
  }

  async pick(properties: {
    treatmentId: string;
    patientId: string;
    clinicId: string;
  }): Promise<Partial<TreatmentCrmType>> {
    return this.fetch('treatment/pick', {
      method: 'GET',
      params: {
        treatmentId: properties.treatmentId,
        patientId: properties.patientId,
        clinicId: properties.clinicId,
      },
    }).then((item) => ({
      id: item.id,
      programId: item.programId,
      phaseName: item.phaseName,
      phaseId: item.phaseId,
      programName: item.programName,
      estimated: new Date(item.estimated),
      finished: item.finished ? new Date(item.finished) : undefined,
      status: item.status,
      discount: item.discount,
    }));
  }

  async disable(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
  }): Promise<void> {
    return this.fetch('treatment/disable', {
      method: 'PUT',
      body: {
        treatmentId: properties.treatmentId,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
      },
    });
  }

  async enable(properties: {
    treatmentId: string;
    clinicId: string;
    patientId: string;
  }): Promise<void> {
    return this.fetch('treatment/enable', {
      method: 'PUT',
      body: {
        treatmentId: properties.treatmentId,
        clinicId: properties.clinicId,
        patientId: properties.patientId,
      },
    });
  }
}
