import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { BulletinsRoutes } from '../../bulletins.routes';
import { BulletinAccessType } from '@human/access/types/bulletin.access.type';
import { BulletinAccesRepository } from '@human/access/repositories/bulletin/bulletin.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListBulletinsScreen(properties: {
  navigation: NavigationProp<BulletinsRoutes>;
  route: RouteProp<BulletinsRoutes, 'list'>;
}) {
  const { phone } = useDeviceHook();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.bulletins.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<BulletinAccessType, ['clinicId']>[] = [
    {
      name: 'clinicName',
      type: 'text',
      width: 150,
      onRender: (row) => row.clinicName,
    },
    {
      name: 'authorName',
      type: 'text',
      width: 150,
      onRender: (row) => `${row.authorName} ${row.authorSurname}`,
    },
    {
      name: 'actorName',
      type: 'text',
      width: 150,
      onRender: (row) => `${row.actorName} ${row.actorSurname}`,
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => new Date(row.created),
    },
  ];

  if (hasPermissionUtilty({ name: 'access.bulletins.editor' })) {
    columns.unshift({
      type: 'icon',
      renderIcon: (row) => (row.status ? 'unlock' : 'lock'),
      renderColor: (row) => (row.status ? 'green' : 'red'),
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          bulletinId: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  return (
    <SceneComponent
      icon={AccessSections.bulletins.icon}
      prefix={'access.bulletins.list'}
      links={links}
    >
      <TableComponent<BulletinAccessType, ['clinicId']>
        search
        prefix={'access.bulletins.list'}
        suffix={'list'}
        scrollable={phone ?? undefined}
        repository={(properties) => {
          return new BulletinAccesRepository().list({
            direction: properties.direction,
            page: properties.page,
            limit: properties.limit,
            search: properties.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
