import { AccordionComponent } from '@components/accordion/accordion.component';
import { IconType } from '@components/icon/icon.types';
import { SceneComponent } from '@components/scene/scene.component';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ScrollView, Text, View } from 'react-native';

export function InformationScreen() {
  const { t } = useTranslation();

  const { height } = Dimensions.get('window');

  const data: {
    icon?: IconType;
    title: string;
    description?: string;
    children: JSX.Element | JSX.Element[];
  }[] = [
    {
      title: t('crm.information.list.0.title'),
      icon: 'circle',
      children: (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <AccordionComponent
            title={t('crm.information.list.0.sublists.0.title')}
            icon="circle"
            isChild
            children={
              <ScrollView style={{ height: height * 0.8 }}>
                <Image
                  style={{
                    width: '100%',
                    aspectRatio: 0.6, // Relación de aspecto para mantener proporciones
                    resizeMode: 'contain',
                  }}
                  resizeMode="contain"
                  source={require('@assets/images/nacional-call-center.png')}
                />
              </ScrollView>
            }
          />
          <AccordionComponent
            title={t('crm.information.list.0.sublists.1.title')}
            icon="circle"
            children={
              <ScrollView style={{ height: height * 0.8 }}>
                <Image
                  style={{
                    width: '100%',
                    aspectRatio: 1.3, // Relación de aspecto para mantener proporciones
                    resizeMode: 'contain',
                  }}
                  resizeMode="contain"
                  source={require('@assets/images/nacional-atp-comercial.png')}
                />
              </ScrollView>
            }
            isChild
          />
          <AccordionComponent
            title={t('crm.information.list.0.sublists.2.title')}
            icon="circle"
            children={
              <ScrollView style={{ height: height * 0.8 }}>
                <Image
                  style={{
                    width: '100%',
                    aspectRatio: 0.6, // Relación de aspecto para mantener proporciones
                    resizeMode: 'contain',
                  }}
                  resizeMode="contain"
                  source={require('@assets/images/nacional-atp-medico.png')}
                />
              </ScrollView>
            }
            isChild
          />
        </ScrollView>
      ),
    },
    {
      title: t('crm.information.list.1.title'),
      icon: 'circle',
      children: (
        <ScrollView style={{ height: height * 0.8 }}>
          <Image
            style={{
              width: '100%',
              aspectRatio: 0.6, // Relación de aspecto para mantener proporciones
              resizeMode: 'contain',
            }}
            resizeMode="contain"
            source={require('@assets/images/internacional.png')}
          />
        </ScrollView>
      ),
    },
  ];

  return (
    <SceneComponent icon={'info-circle'} prefix="common.information">
      <View style={{ flex: 1, gap: 10 }}>
        {data.map((item, index: number) => (
          <AccordionComponent
            key={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
            children={item.children}
          />
        ))}
      </View>
    </SceneComponent>
  );
}
