import { ValidationImageInput } from './image.properties';

export function ImageInputValidations(properties: {
  validations: ValidationImageInput[];
  required?: boolean;
  value?: string;
}) {
  const errors: string[] = [];
  /**
   * Name Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * 4. Must not contain any special characters
   * 5. Must not contain any numbers
   */

  if (properties.validations.includes('name')) {
    if (typeof properties.value !== 'string') {
      errors.push('nameMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('nameIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('nameMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('nameMustBeAtMost30CharactersLong');
      }
      if (properties.value.match(/^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/)) {
        errors.push('nameMustNotContainSpecialCharacters');
      }
      if (properties.value.match(/[0-9]/)) {
        errors.push('nameMustNotContainNumbers');
      }
    }
  }

  /**
   * Surname Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * 4. Must not contain any special characters
   * 5. Must not contain any numbers
   */
  if (properties.validations.includes('surname')) {
    if (typeof properties.value !== 'string') {
      errors.push('surnameMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('surnameIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('surnameMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('surnameMustBeAtMost30CharactersLong');
      }
      if (properties.value.match(/^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/)) {
        errors.push('surnameMustNotContainSpecialCharacters');
      }
      if (properties.value.match(/[0-9]/)) {
        errors.push('surnameMustNotContainNumbers');
      }
    }
  }

  /**
   * Email Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * 4. Must contain an @ symbol
   */
  if (properties.validations.includes('email')) {
    if (typeof properties.value !== 'string') {
      errors.push('emailMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('emailIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('emailMustBeAtLeast3CharactersLong');
      }

      if (!properties.value.includes('@')) {
        errors.push('emailMustContainAnAtSymbol');
      }
      if (properties.value.match(/ /)) {
        errors.push('emailMustNotContainWhitespace');
      }
      // regex email
      if (!properties.value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/)) {
        errors.push('emailMustBeAValidEmail');
      }
    }
  }

  /**
   * Password Validation
   * 1. Must be a string
   * 2. Must be at least 8 characters long
   * 3. Must be at most 30 characters long
   * 4. Must contain at least one number
   * 5. Must contain at least one special character
   * 6. Must contain at least one uppercase letter
   * 7. Must contain at least one lowercase letter
   * 8. Must not contain any spaces
   */
  if (properties.validations.includes('password')) {
    if (typeof properties.value !== 'string') {
      errors.push('passwordMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('passwordIsRequired');
      }
    } else {
      if (properties.value.length < 8) {
        errors.push('passwordMustBeAtLeast8CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('passwordMustBeAtMost30CharactersLong');
      }
      if (!properties.value.match(/[0-9]/)) {
        errors.push('passwordMustContainANumber');
      }
      if (!properties.value.match(/[^a-zA-Z0-9]/)) {
        errors.push('passwordMustContainASpecialCharacter');
      }
      if (!properties.value.match(/[A-Z]/)) {
        errors.push('passwordMustContainAnUppercaseLetter');
      }
      if (!properties.value.match(/[a-z]/)) {
        errors.push('passwordMustContainALowercaseLetter');
      }
      if (properties.value.match(/ /)) {
        errors.push('passwordMustNotContainWhitespace');
      }
    }
  }

  /**
   * IP Address Validation
   * 1. Must be a string
   * 2. Must be a valid IP address
   */
  if (properties.validations.includes('ip')) {
    if (typeof properties.value !== 'string') {
      errors.push('ipMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('ipIsRequired');
      }
    } else {
      if (!properties.value.match(/^(\d{1,3}\.){3}\d{1,3}$/)) {
        errors.push('ipMustBeAValidIpAddress');
      }
    }
  }

  /**
   * Code Validation
   * 1. Must be a string
   * 2. Must be at least 10 characters long
   * 3. Must be at most 30 characters long
   */
  if (properties.validations.includes('code')) {
    if (typeof properties.value !== 'string') {
      errors.push('codeMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('codeIsRequired');
      }
    } else {
      if (properties.value.length < 10) {
        errors.push('codeMustBeAtLeast10CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('codeMustBeAtMost30CharactersLong');
      }
    }
  }

  /**
   * Description Validation
   * 1. Must be a string
   * 2. Una validación debería ser de 0 a 500 letras.
   */
  if (properties.validations.includes('description')) {
    if (typeof properties.value !== 'string') {
      errors.push('descriptionMustBeAString');
    }
    if (properties.value && properties.value.length > 500) {
      errors.push('descriptionMustBeAtMost500CharactersLong');
    }
  }

  return errors;
}
